import {Component} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {AbstractToastComponent} from '../abstract-toast.component';
import {ToastComponentsRegistry} from '../../../../core/service/toast.service';

@Component({
  selector: 'app-progress-bar-toast',
  templateUrl: './progress-bar-toast.component.html',
  styles: [`
    :host ::ng-deep .ui-toast-message-content {
      background: white;
      padding: 10px;
    }

    ::ng-deep .ui-dialog-mask {
      z-index: 4999;
    }
  `]
})
export class ProgressBarToastComponent extends AbstractToastComponent {

  public getKey(): string {
    return ToastComponentsRegistry.PROGRESS_BAR;
  }

  public constructor(
    protected translateService: TranslateService
  ) {
    super();
  }
}
