
import {of as observableOf, Observable} from 'rxjs';
import {AbstractExecutionStep} from '../../../core/executor/abstract-execution-step';
import {ExecutionStepStatus} from '../../../core/executor/execution-step-status';
import {GenericDynamicTableComponent} from '../../content-renderer/elements/generic-dynamic-table/generic-dynamic-table.component';
import {ElementContext, ElementType} from '../../content-renderer/services/ElementContext';
import {GenericElementAbstract} from '../../content-renderer/elements/generic-element-abstract.component';
import {InquiryPositionSalaryTypeDataGenerator} from './inquiry/service/inquiry-position-salary-type-data-generator.service';
import {GenericDynamicTreeOldComponent} from '../../content-renderer/elements/generic-dynamic-tree-old/generic-dynamic-tree-old.component';
import {InquiryPositionSalaryTypeTableGenerator} from './inquiry/service/inquiry-position-salary-type-table-generator';
import {Entity} from '../../helpers/entity';
import {ExecutorActionEvent} from '../../../core/executor/service/executor-actions/executor-action-event';
import {AbstractGenericGridComponent} from '../../content-renderer/elements/abstract-generic-grid.component';
import {GenericGridComponent} from '../../content-renderer/elements/generic-grid/generic-grid.component';

export class InitSelectableStep extends AbstractExecutionStep {

  doExecute(): Observable<ExecutionStepStatus> {
    const payload = this.getPayload();

    let component = payload.getValue();

    if (payload instanceof Object && payload.getValue().component) {
      component = payload.getValue().component;
    }

    if (!(component instanceof AbstractGenericGridComponent)) {
      return this.getFailObservable('You need to pass AbstractGenericGridComponent as Payload value!');
    }

    return this.doSelect(component);
  }

  private doSelect(component: AbstractGenericGridComponent): Observable<ExecutionStepStatus> {
    let entityCollectionName = '';
    if(component.element && component.element.datamodel && component.element.datamodel.entityCollectionName) {
      entityCollectionName = component.element.datamodel.entityCollectionName;
    }

    if(component.masterElementContext && component.masterElementContext.selectedEntity && entityCollectionName) {
      component.masterEntityEditingField = entityCollectionName;
      component.masterEntity = component.masterElementContext.selectedEntity;
      let selected = this.entityHydrator.getEntityPropertyValue(component.masterElementContext.selectedEntity,entityCollectionName);
      if(selected instanceof Array) {
        component.selectedEntities = selected;
      }
      return observableOf({status: true, content: null});
    }

    return this.getFailObservable('You need to pass AbstractGenericGridComponent as Payload value!');
  }
}
