/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./toolbar-item-open-new-offer.component";
import * as i2 from "../../../../../../services/generic-crud.service";
import * as i3 from "../../../../../services/modules-state.service";
import * as i4 from "../../../../../../../core/executor/builder/execution-step-builder.service";
import * as i5 from "../../../../../../../core/executor/executor.service";
import * as i6 from "../../../../../../../core/message/message.service";
import * as i7 from "@ngx-translate/core";
import * as i8 from "../../../../../services/double-click.service";
var styles_ToolbarItemOpenNewOfferComponent = [];
var RenderType_ToolbarItemOpenNewOfferComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_ToolbarItemOpenNewOfferComponent, data: {} });
export { RenderType_ToolbarItemOpenNewOfferComponent as RenderType_ToolbarItemOpenNewOfferComponent };
export function View_ToolbarItemOpenNewOfferComponent_0(_l) { return i0.ɵvid(2, [], null, null); }
export function View_ToolbarItemOpenNewOfferComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "toolbar-item-open-new-offer", [], null, null, null, View_ToolbarItemOpenNewOfferComponent_0, RenderType_ToolbarItemOpenNewOfferComponent)), i0.ɵdid(1, 245760, null, 0, i1.ToolbarItemOpenNewOfferComponent, [i2.GenericCrudService, i3.ModulesStateService, i4.ExecutionStepBuilderService, i5.ExecutorService, i6.MessageService, i7.TranslateService, i8.DoubleClickService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ToolbarItemOpenNewOfferComponentNgFactory = i0.ɵccf("toolbar-item-open-new-offer", i1.ToolbarItemOpenNewOfferComponent, View_ToolbarItemOpenNewOfferComponent_Host_0, { params: "params" }, {}, []);
export { ToolbarItemOpenNewOfferComponentNgFactory as ToolbarItemOpenNewOfferComponentNgFactory };
