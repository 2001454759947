import {ChangeDetectionStrategy, Component} from '@angular/core';
import {ToolbarItemAbstract} from '../../toolbar-item-abstract.component';
import {MessageGrowlService} from '../../../../../../../../core/message/message-growl.service';
import {GenericCrudService} from '../../../../../../../services/generic-crud.service';
import {ExecutorService} from '../../../../../../../../core/executor/executor.service';
import {MessageService} from '../../../../../../../../core/message/message.service';
import {TranslateService} from '@ngx-translate/core';
import {ExecutionStepFactoryService} from '../../../../../../../../core/executor/factory/execution-step-factory.service';
import {AbstractGenericGridComponent} from '../../../../../abstract-generic-grid.component';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'toolbar-item-ca-workhour-invoice-flatten',
  templateUrl: './toolbar-item-ca-workhour-invoice-flatten.component.html'
})
export class ToolbarItemCaWorkhourInvoiceFlattenComponent extends ToolbarItemAbstract {

  protected entity: any;

  constructor(
    protected messageGrowlService: MessageGrowlService,
    protected genericCrudService: GenericCrudService,
    protected executionStepFactoryService: ExecutionStepFactoryService,
    protected messageService: MessageService,
    protected translateService: TranslateService,
    protected executorService: ExecutorService
  ) {
    super();
  }

  public click() {
    const component = this.getComponent();

    if (component instanceof AbstractGenericGridComponent) {
      this.handleCalculate(component);
    }
  }

  protected handleCalculate(grid: AbstractGenericGridComponent) {
    if (grid.areAllFiltersDefined()) {
      const data = {
        workYear: grid.gridFilters['workYear']['value'],
        workMonth: grid.gridFilters['workMonth']['value'],
        leasedEmployee: grid.gridFilters['leasedEmployee']['value'][0]['id']
      };

      this.genericCrudService.customPut(`phoenix/flatten/invoices`, data).subscribe(
        (response) => {
          this.messageGrowlService.success('Erfolgreich berechnet. Success.');
        },
        errorResponse => {
          console.log(errorResponse);
          this.messageGrowlService.error(errorResponse.error.error);
        }
      );
    }
  }
}
