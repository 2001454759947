import { AbstractInlineEditor } from './abstract-inline-editor';
import {Observable, of} from 'rxjs';

export class DateTimeInlineEditor extends AbstractInlineEditor {

  public onEdit(entity: any, event: any) {
    this.changeEntityColumnProperty(event);
  }

  public onBlur(entity: any, event: any) {

  }

  public onFocus(entity: any, event: any): Observable<any> {
    return of(null);
  }
}
