import {AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit} from '@angular/core';
import {ElementLabelComponent} from './element-label.component';
import {ElementModuleElementGrid} from '../models';
import {FormService} from '../form.service';
import {FormViewerService} from '../form-viewer.service';
import {ElementsStackService} from '../../content-renderer/services/elements-stack.service';
import {ElementContext} from '../../content-renderer/services/ElementContext';
import {AbstractGenericGridComponent} from '../../content-renderer/elements/abstract-generic-grid.component';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-form-element-module-element-grid',
  template: `
    <div class="choose-module-container" *ngIf="!element.openingModuleId">Modul auswählen</div>
    <ng-container *ngIf="element.openingModuleId && entity && entity.id && element.datamodelField">
      <app-content-renderer [(entity)]="entity" [masterField]="element.datamodelField" [moduleId]="element.openingModuleId"></app-content-renderer>
    </ng-container>
    <ng-container *ngIf="element.openingModuleId && (!entity || !entity.id)">
        <app-content-renderer [moduleId]="element.openingModuleId" [masterField]="element.datamodelField"></app-content-renderer>
    </ng-container>
  `,
  styles: [`
  :host {
    width: 100%;
    height: 100%;
    background: white;
  }

  ::ng-deep .choose-module-container {
    text-align: center;
    margin-top: 25%;
  }
  `]
})
export class ElementModuleElementGridComponent extends ElementLabelComponent implements OnInit, AfterViewInit {

  @Input() element: ElementModuleElementGrid;

  selectedEntity: any;

  constructor(
    protected formService: FormService,
    public cdr: ChangeDetectorRef,
    protected formViewerService: FormViewerService,
  ) {
    super(formService, cdr, formViewerService);
  }

  onComponentInit() {
    this.filterActionAndHandleIt('oninit');
  }

  onComponentChanges() {
    const entity = this.entity;

    if (entity && entity.id && this.element.datamodelField) {
      this.reloadData(entity);
    }
  }

  ngAfterViewInit() {
  }

  private reloadData(entity: any): void {
    const elementsStackService: ElementsStackService = this.formService.getInjector().get(ElementsStackService),
      contexts: ElementContext[] = elementsStackService.getByModuleId(this.element.openingModuleId);

    for (const context of contexts) {
      if (context.component && context.component instanceof AbstractGenericGridComponent) {
        context.changeMasterConfigValue(this.element.datamodelField, entity);
        this.subscriptions.push(
          context.component.loadEntities().subscribe()
        );
      }
    }
  }
}
