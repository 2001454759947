import {AbstractExecutionStep} from '../../../../core/executor/abstract-execution-step';
import {Observable} from 'rxjs';
import {ExecutionStepStatus} from '../../../../core/executor/execution-step-status';
import {TOAST_LIFE_INFINITE, ToastComponentsRegistry, ToastService} from '../../../../core/service/toast.service';
import {catchError} from 'rxjs/operators';
import {of as observableOf} from 'rxjs/internal/observable/of';
import {EntityDirtyStoreService} from '../../../content-renderer/services/entity-dirty-store.service';
import {RequestCachingService} from '../../request-caching.service';
import {EntitySharingService} from '../../../content-renderer/services/entity-sharing.service';
import {TranslateService} from '@ngx-translate/core';
import {GenericDialogModuleService} from '../../../content-renderer/elements/generic-dialog/service/generic-dialog-module.service';

export class CloseTicketFromTicketAction extends AbstractExecutionStep {

  public doExecute(): Observable<ExecutionStepStatus> {
    const payload = this.getPayload(),
      component = payload.getValue().component ? payload.getValue().component : payload.getValue(),
      entities = component.getElementContext().getMasterEntities();

    this.injector.get(ToastService, null).custom(ToastComponentsRegistry.PROGRESS_BAR, {
      severity: 'success',
      life: TOAST_LIFE_INFINITE,
      closable: false,
      summary: this.injector.get(TranslateService, null).instant('COMMON.LOADING_PLEASE_WAIT')
    });

    for (const entity of entities) {
      this.genericCrudService.get('ticket/actions/close/' + entity.value)
        .pipe(
          catchError((status) => {
            this.injector.get(ToastService, null).clear(ToastComponentsRegistry.PROGRESS_BAR);

            return observableOf(false);
          })
        )
        .subscribe((result) => {
          this.injector.get(ToastService, null).clear(ToastComponentsRegistry.PROGRESS_BAR);

          this.injector.get(EntityDirtyStoreService, null).clear();
          this.injector.get(RequestCachingService, null).removeAllRequestResponses();
          this.injector.get(EntitySharingService, null).clear();

          component.triggerSlaves(entity);
        })
    };
    this.getDialogService().hideDialog();
    return Observable.of({status: true, content: null});
  }

  private getDialogService(): GenericDialogModuleService {
    return this.injector.get(GenericDialogModuleService, null);
  }
}
