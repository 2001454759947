import {
  DropdownQuestionComponent
} from '../../content-renderer/elements/custom/questionnaire/question/types/dropdown/dropdown-question.component';
import {
  AutocompleteQuestionComponent
} from '../../content-renderer/elements/custom/questionnaire/question/types/autocomplete/autocomplete-question.component';
import {
  CheckboxQuestionComponent
} from '../../content-renderer/elements/custom/questionnaire/question/types/checkbox/checkbox-question.component';
import {
  RadioQuestionComponent
} from '../../content-renderer/elements/custom/questionnaire/question/types/radio/radio-question.component';
import {
  AdminDropdownQuestionComponent
} from '../../../super-admin/questionnaire-management/questionnaire-details/question/types/admin-dropdown-question-component';
import {
  AdminAutocompleteQuestionComponent
} from '../../../super-admin/questionnaire-management/questionnaire-details/question/types/admin-autocomplete-question-component';
import {
  AdminCheckboxQuestionComponent
} from '../../../super-admin/questionnaire-management/questionnaire-details/question/types/admin-checkbox-question-component';
import {
  AdminRadioQuestionComponent
} from '../../../super-admin/questionnaire-management/questionnaire-details/question/types/admin-radio-question-component';

export interface QuestionTypeCodeComponent {
  code: string;
  adminComponent: any;
  component: any;
}

export class QuestionnaireQuestionTypeCodeComponentRegistry {

  private codes: QuestionTypeCodeComponent[] = [
    {
      code: 'dropdown',
      adminComponent: AdminDropdownQuestionComponent,
      component: DropdownQuestionComponent
    },
    {
      code: 'autocomplete',
      adminComponent: AdminAutocompleteQuestionComponent,
      component: AutocompleteQuestionComponent
    },
    {
      code: 'checkbox',
      adminComponent: AdminCheckboxQuestionComponent,
      component: CheckboxQuestionComponent
    },
    {
      code: 'radio',
      adminComponent: AdminRadioQuestionComponent,
      component: RadioQuestionComponent
    }
  ];

  public getCodes() {
    return this.codes;
  }

  public getAdminComponentByCode(code: string): any|null {
    const registryItem = this.codes.find((aRegistryItem: QuestionTypeCodeComponent) => {
      return aRegistryItem.code === code;
    });

    return registryItem ? registryItem.adminComponent : null;
  }

  public getComponentByCode(code: string): any|null {
    const registryItem = this.codes.find((aRegistryItem: QuestionTypeCodeComponent) => {
      return aRegistryItem.code === code;
    });

    return registryItem ? registryItem.component : null;
  }
}
