
import {throwError as observableThrowError,  Observable ,  Subscription } from 'rxjs';

import {map, catchError} from 'rxjs/operators';
import {
  Component, OnInit, OnDestroy, Output, EventEmitter, Input
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { MessageService } from '../../../../core/message/message.service';
import { GenericCrudService } from '../../../services/generic-crud.service';
import { ExpertSearch } from '../../../services/expert-search/expert-search';
import { Datamodel } from '../../../services/datamodel/datamodel';
import { MessageGrowlService } from '../../../../core/message/message-growl.service';
import { ExpertSearchService } from '../service/expert-search.service';
import {RequestCachingService} from '../../../services/request-caching.service';

@Component({
  selector: 'app-search-management-list',
  templateUrl: './search-management-list.component.html',
  providers: [ ExpertSearchService ]
})
export class SearchManagementListComponent implements OnInit, OnDestroy {

  @Input() public expertSearches: ExpertSearch[] = [];
  @Output() onExpertSearchChange: EventEmitter<ExpertSearch> = new EventEmitter();

  public selectedExpertSearch: ExpertSearch = null;

  public isDataLoading = false;

  protected subscriptions: Subscription[] = [];

  public constructor(
    protected genericCrudService: GenericCrudService,
    protected messageService: MessageService,
    protected translateService: TranslateService,
    protected messageGrowlService: MessageGrowlService,
    protected expertSearchService: ExpertSearchService,
    protected requestCachingService: RequestCachingService
  ) {

  }

  public ngOnInit(): void {
    this.loadExpertSearches()
      .subscribe((expertSearches: ExpertSearch[] = []) => {
        this.expertSearches = expertSearches;
      });
  }

  public ngOnDestroy() {
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }

  public onExpertSearchSelect(event: { data: ExpertSearch }): void {
    this.onExpertSearchChange.emit(event.data);
  }

  public onAddExpertSearch(event: any): void {
    this.onExpertSearchChange.emit({
      id: null,
      name: '',
      datamodel: new Datamodel(null),
      containers: []
    });
  }

  public loadExpertSearches(): Observable<ExpertSearch[]> {
    this.requestCachingService.removeByExpression('expertsearches');

    this.isDataLoading = true;

    this.expertSearches = [];

    return this.expertSearchService
      .load().pipe(
      catchError((error) => {
        this.isDataLoading = false;

        return observableThrowError(error);
      }),
      map((expertSearches: ExpertSearch[] = []) => {
        this.isDataLoading = false;

        return expertSearches;
      }),);
  }

  public setSelectedExpertSearch(expertSearch: ExpertSearch): void {
    for (const aExpertSearch of this.expertSearches) {
      if (aExpertSearch.id === expertSearch.id) {
        this.selectedExpertSearch = aExpertSearch;
        break;
      }
    }
  }

  public onRemoveExpertSearch() {
    this.onExpertSearchChange.emit(null);

    this.isDataLoading = true;

    this.genericCrudService
      .deleteEntity(`app/expertsearches/${this.selectedExpertSearch.id}`)
      .subscribe(() => {
        const index = this.expertSearches.findIndex((aExpertSearch: ExpertSearch) => {
          return aExpertSearch.id === this.selectedExpertSearch.id;
        });

        if (index !== -1) {
          this.expertSearches.splice(index, 1);
          this.selectedExpertSearch = null;
        }

        this.isDataLoading = false;

        this.messageGrowlService.showDataSaved();
      });
  }
}
