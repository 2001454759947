import {Injectable} from '@angular/core';
import {LocalStorageDataService} from '../../shared/services/local-storage-data.service';
import {Organisation} from '../../shared/services/organisation/organisation';
import {Group} from '../../shared/services/group/group';
import {SessionStorageDataService} from '../../shared/services/session-storage-data.service';
import {Branch} from '../../shared/services/branch/branch';

@Injectable({
  providedIn: 'root'
})
export class UserSessionService {

  public constructor(
    private localStorage: LocalStorageDataService,
    private sessionStorage: SessionStorageDataService
  ) {

  }

  public bootstrap(): void {

  }

  public set(key: string, options: {value: any}): this {
    this.localStorage.setItem(key, options.value);
    this.sessionStorage.setItem(key, options.value);

    return this;
  }

  public get(key: string): any {
    if (this.sessionStorage.itemExists(key)) {
      return this.sessionStorage.getItem(key);
    }

    if (this.localStorage.itemExists(key)) {
      return this.localStorage.getItem(key);
    }

    return null;
  }

  public remove(key: string): this {
    this.localStorage.removeItem(key);
    this.sessionStorage.removeItem(key);

    return this;
  }

}
