import {Injectable} from '@angular/core';
import {ExecutorService} from 'app/core/executor/executor.service';
import {TranslateService} from '@ngx-translate/core';
import {ConfirmationService} from 'primeng/components/common/confirmationservice';
import {EntityDataStoreService} from './entity-data-store.service';
import {ExecutionStepFactoryService} from '../../../core/executor/factory/execution-step-factory.service';
import {ExecutionStatus} from 'app/core/executor/execution-status';
import {GenericElementAbstract} from 'app/shared/content-renderer/elements/generic-element-abstract.component';
import {AbstractGenericGridComponent} from '../elements/abstract-generic-grid.component';
import {FormViewerComponent} from 'app/shared/form-viewer/form-viewer.component';
import {ExecutionStep} from '../../../core/executor/execution-step';
import {ComponentsHaveChangesExecutionStep} from '../../services/execution-step/cancel/components-have-changes-execution-step';
import {ExecutionStepPayload} from 'app/core/executor/execution-step-payload';
import {GridCancelChangesExecutionStep} from 'app/shared/services/execution-step/cancel/grid-cancel-changes-execution-step';
import {FormCancelChangesExecutionStep} from '../../services/execution-step/cancel/form-cancel-changes-execution-step';
import {ModulesStateService} from 'app/shared/content-renderer/services/modules-state.service';
import {ToolbarItemCheckService} from '../elements/generic-toolbar/services/check/toolbar-item-check.service';
import {ElementContext, ElementType} from './ElementContext';
import {ComponentRefreshExecutionStep} from '../../services/execution-step/component-refresh-execution-step';
import {GenericDynamicTreeOldComponent} from '../elements/generic-dynamic-tree-old/generic-dynamic-tree-old.component';
import {GenericDynamicTableComponent} from '../elements/generic-dynamic-table/generic-dynamic-table.component';
import {GenericDialogModuleService} from "../elements/generic-dialog/service/generic-dialog-module.service";
import {ExecutorActionEvent} from '../../../core/executor/service/executor-actions/executor-action-event';

@Injectable()
export class CancelComponentChangesService {

    private executorService: ExecutorService = null;

    public constructor(
        private translateService: TranslateService,
        private confirmationService: ConfirmationService,
        private entityDataStoreService: EntityDataStoreService,
        private executionStepFactoryService: ExecutionStepFactoryService,
        private modulesStateService: ModulesStateService,
        private toolbarItemCheckService: ToolbarItemCheckService,
        private dialogService: GenericDialogModuleService
    ) {

    }

    public cancel(components: any[] = []) {
        this.callDialogComponentAction(ExecutorActionEvent.BeforeCancel);

        if (components) {
            const executionStepCancelCheckChanges = this.createCheckChangesExecutionStep(components);

            this.executorService.addStep(executionStepCancelCheckChanges)
                .execute()
                .subscribe((status: ExecutionStatus) => {
                    if (status.isSuccess()) {
                        this.confirmationService.confirm({
                            acceptVisible: true,
                            header: this.translateService.instant('DIALOG_MESSAGES.UNSAVED_CHANGES_SAVE_QUESTION_HEADER'),
                            message: this.translateService.instant('DIALOG_MESSAGES.UNSAVED_CHANGES_DISCARD_QUESTION_BODY'),
                            icon: 'fa fa-trash',
                                accept: () => {
                                    this.doCancel(components);
                                },
                                reject: () => {}
                            });
                    } else {
                        this.doCancel(components);
                    }
                });
        } else {
            this.doCancel(components);
        }
    }

    private doCancel(components: GenericElementAbstract[] = []) {
        const executionStepsCancel = this.createCancelChangesExecutionStep(components);

        for (const step of executionStepsCancel) {
            this.executorService.addStep(step);
        }

        const componentsWithChanges = this.getChangedComponents(components);

        this.executorService.execute()
            .subscribe((status: ExecutionStatus) => {
                for (const component of componentsWithChanges) {

                    /*if (component instanceof AbstractGenericGridComponent) {
                        component.selectLastOrFirstEntity();
                    }*/

                    if (component instanceof FormViewerComponent && !component.getElementContext().isSlaveContext()) {
                        component.onFormViewerCancel();
                    }
                }

                this.callDialogComponentAction(ExecutorActionEvent.AfterCancel);

                this.dialogService.persistHide();

                this.toolbarItemCheckService.check(null);
            });
    }

    public setExecutorService(executorService: ExecutorService): this {
        this.executorService = executorService;
        return this;
    }

    private callDialogComponentAction(event: ExecutorActionEvent): void {
      const dialogCallerComponent = this.dialogService.getCallerComponent();

      if (dialogCallerComponent) {
        dialogCallerComponent.executeAction(event, dialogCallerComponent).subscribe();
      }
    }

    private createCheckChangesExecutionStep(components: any[] = []): ExecutionStep {
        const componentsToCheckChanges = [];

        for (const component of components) {

            if (component instanceof FormViewerComponent) {
                if (component.getElementContext().isSlaveContext() && component.getElementContext().getMasterElementContext()) {
                    componentsToCheckChanges.push(
                        component.getElementContext().getMasterElementContext().component
                    );
                } else {
                    componentsToCheckChanges.push(
                        component
                    );
                }
            }

            if (component instanceof AbstractGenericGridComponent) {
                componentsToCheckChanges.push(
                    component
                );
            }
        }

        return this.executionStepFactoryService.create(ComponentsHaveChangesExecutionStep,
            new ExecutionStepPayload(componentsToCheckChanges)
        );
    }

    private createCancelChangesExecutionStep(components: any[] = []): ExecutionStep[] {
        const steps: ExecutionStep[] = [];

        for (const component of components) {
            const componentContext: ElementContext = component.getElementContext();

            switch (componentContext.type) {
                case ElementType.Grid:
                case ElementType.Tree:
                  const gridStep = this.getGridComponentCancelChangesStep(component);

                  if (gridStep !== null) {
                    steps.push(gridStep);
                  }
                break;
                case ElementType.DynamicGrid:
                case ElementType.DynamicTree:
                    const dynamicStep = this.getDynamicComponentCancelChangesStep(component);

                    if (dynamicStep !== null) {
                        steps.push(dynamicStep);
                    }
                break;
                case ElementType.Form:
                  const formStep = this.getFormComponentCancelChangesStep(component);

                  if (formStep !== null) {
                    steps.push(formStep);
                  }
                break;
            }
        }

        return steps;
    }

    private getGridComponentCancelChangesStep(component: AbstractGenericGridComponent): ExecutionStep|null {
      let step = null;

      if (component.hasChanges()) {
        step = this.executionStepFactoryService.create(GridCancelChangesExecutionStep,
          new ExecutionStepPayload(component)
        );
      }

      return step;
    }

    private getFormComponentCancelChangesStep(component: FormViewerComponent): ExecutionStep|null {
      let step = null;

      if (component.getElementContext().isSlaveContext() && component.getElementContext().getMasterElementContext()) {
        const masterComponent = component.getElementContext().getMasterElementContext().component;

        step = this.getGridComponentCancelChangesStep(masterComponent);
      } else {
        if (component.isEntityDirty()) {
          step = this.executionStepFactoryService.create(FormCancelChangesExecutionStep,
            new ExecutionStepPayload(component)
          );
        }
      }

      return step;
    }

    private getDynamicComponentCancelChangesStep(component: GenericDynamicTreeOldComponent|GenericDynamicTableComponent):
      ExecutionStep|null {

      let step = null;

      if (component.hasChanges()) {
        step = this.executionStepFactoryService.create(ComponentRefreshExecutionStep,
          new ExecutionStepPayload(component)
        );
      }

      return step;
    }

    private getChangedComponents(components: GenericElementAbstract[] = []): GenericElementAbstract[] {
      const componentsWithChanges = [];

      for (const component of components) {
        if (component instanceof AbstractGenericGridComponent && component.hasChanges()) {
          componentsWithChanges.push(component);
        }

        if (component instanceof FormViewerComponent && !component.getElementContext().isSlaveContext() && component.isEntityDirty()) {
          componentsWithChanges.push(component);
        }
      }

      return componentsWithChanges;
    }
}
