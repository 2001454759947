import {
  Component,
  Input,
  AfterViewInit,
  OnInit, OnDestroy, ChangeDetectionStrategy, ChangeDetectorRef
} from '@angular/core';
import { environment } from '../../../../environments';
import { ElementInputComponent } from './element-input.component';
import { FormService } from '../form.service';
import { FormViewerService } from '../form-viewer.service';
import { ElementInput } from '../models';
import {TranslateService} from '@ngx-translate/core';
import { FormElementAction } from '../models/form';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-form-element-input-text-only',
  template: `
    <div [formGroup]="formGroup" fxLayout="row">
      <div fxFlex="80" [style.font-weight]="getFontWeight()">
        <ng-template [ngIf]="hasEmailValidation()">
          <input
            appSeleniumDirective
            [element]="element"
            class="email-input"
            type="email"
            readonly
            value="{{getValueFormatted()}}"
            tabindex="{{element.tabIndex}}"
          />
        </ng-template>

        <ng-template [ngIf]="!hasEmailValidation()">
          {{getValueFormatted()}}
        </ng-template>
      </div>
      <div fxFlex="20" *ngIf="((element?.icon | isDefined) && (element?.icon?.relativePath | isDefined))">
        <img height="16" width="16"
            [ngClass]="{
            'cur-pointer': isValid(),
            'cur-disabled': !isValid(),
            'disabled': !isValid()
          }"
            [src]="iconBaseUrl + '/' + element?.icon?.relativePath"
            [alt]="element?.icon?.name"
            (click)="onClickIcon()"
        />
      </div>
      <div fxFlex="20" *ngIf="hasUrlValidation() || hasEmailValidation()">
        <i class="fa fa-globe" aria-hidden="true" *ngIf="hasUrlValidation()"
          [ngClass]="{
            'cur-pointer': isValid(),
            'cur-disabled': !isValid(),
            'disabled': !isValid()
          }"
          (click)="onClickOpenUrl()"
        ></i>
        <i class="fa fa-envelope" aria-hidden="true" *ngIf="hasEmailValidation()"
          [ngClass]="{
            'cur-pointer': isValid(),
            'cur-disabled': !isValid(),
            'disabled': !isValid()
          }"
          (click)="onClickOpenEmail()"
        ></i>
      </div>
      <span class="hidden-content"></span>
    </div>
  `,
  styles: [`
    .email-input {
      background: transparent;
      border: none;
    }

    .email-input:focus {
      outline: none;
      box-shadow: none;
    }
  `]
})
export class ElementInputTextOnlyComponent extends ElementInputComponent implements OnInit, OnDestroy, AfterViewInit {

  @Input() element: ElementInput;

  iconBaseUrl: string = environment.baseUrl;

  constructor(
    protected formService: FormService,
    public cdr: ChangeDetectorRef,
    protected formViewerService: FormViewerService,
    protected translateService?: TranslateService
  ) {
    super(formService, cdr, formViewerService, translateService);
  }

  public onComponentInit() {
    this.setFormControlName();
    this.filterActionAndHandleIt('oninit');
  }

  public onComponentAfterViewInit() {
  }

  onFormElementAction(action: FormElementAction) {

  }
}
