import { Component, OnInit, AfterViewInit } from '@angular/core';
import { SelectItem } from 'primeng/primeng';
import { StyleService } from './../style.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit, AfterViewInit {
  constructor(
    public styleService: StyleService
  ) {
  }

  ngOnInit() {
  }

  ngAfterViewInit() {
  }

  getWindowFontSize() {
    const fontSize = window.getComputedStyle(document.body).getPropertyValue('font-size');
    let unit = fontSize.replace(new RegExp(/[0-9]*/g), '');
    const value = +fontSize.replace(unit, '');

    if (!unit) {
      unit = 'px';
    }
    return {
      value: value,
      unit: unit
    };
  }

  fontSizeUp(event) {
    event.preventDefault();

    const fontSize = this.getWindowFontSize();

    // this.styleService.options.global.fontSize = `${(fontSize.value + 1)}${fontSize.unit}`;
    // this.styleService.optionsChanged.next(this.styleService.options);
  }

  fontSizeDown(event) {
    event.preventDefault();

    const fontSize = this.getWindowFontSize();

  }
}
