import {AbstractFormActionHandler} from './abstract-form-action-handler';
import {Element} from '../../models';

export class LockFieldsActionHandler extends AbstractFormActionHandler {

    public handleAction(): void {
        const form = this.getForm(),
            formGroup = this.getFormGroup(),
            doUnlock = this.getParamValue('unlock') === true;

        if (this.isActionValid()) {
            const constrolsForLock = this.getControls();

            for (const key in constrolsForLock) {
                if (doUnlock) {
                    formGroup.controls[key].enable();
                } else {
                    formGroup.controls[key].disable();
                }
                this.markFormControlForCheck(key);
            }
        }
    }

    private isActionValid(): boolean {
        let isValid = this.getAction().params['formElements'] &&
            this.getAction().params['formElements'].value;

        if (this.getParamValue('lockAll') === true) {
            isValid = true;
        }

        return isValid;
    }

    private getControls(): Object {
        const form = this.getForm(),
            formGroup = this.getFormGroup();

        let controlsForLock: any = {};

        if (this.getParamValue('lockAll') === true) {
            controlsForLock = formGroup.controls;
        } else {
            const lockFieldsFormElementsHashIds = this.getParamValue('formElements');

            if (lockFieldsFormElementsHashIds && lockFieldsFormElementsHashIds instanceof Array) {
                for (const lockFieldElementHashId of lockFieldsFormElementsHashIds) {
                    const lockFieldElement = this.formService.getElementBy(form, 'objectHashId', lockFieldElementHashId),
                        controlName = lockFieldElement.datamodelField + '_h_r_f_e_' + lockFieldElementHashId;

                    if (lockFieldElement.datamodelField) {
                        controlsForLock[controlName] = formGroup.get(controlName);
                    }
                }
            }
        }

        return controlsForLock;
    }
}
