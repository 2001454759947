import { Injectable, ComponentFactoryResolver, ViewContainerRef, Component } from '@angular/core';
import { Action, ActionsEvent, ActionsAction } from '../../services/action/action';
import { FieldActionComponentAware } from '../elements/generic-grid/field-actions/field-action-abstract.component';
import { FieldActionOpenElementComponent } from '../elements/generic-grid/field-actions/open-element-action/field-action-open-element.component';
import { ViewContainerRefAware } from '../elements/generic-element-abstract.component';
import { FieldActionOpenModuleComponent } from '../elements/generic-grid/field-actions/open-module-action/field-action-open-module.component';
import { ActionRegistryService } from '../../services/action/action-registry.service';
import { FieldMetadataGrid } from '../../services/module/module-element-field-metadata-grid';


@Injectable()
export class FieldActionsService {

  private grid: ViewContainerRefAware = null;

  private field: FieldMetadataGrid = null;

  private pool = {};

  constructor(
      private componentFactory: ComponentFactoryResolver,
      private actionRegistryService: ActionRegistryService
  ) {
      this.populatePool();
  }

  public fire(event: ActionsEvent): void {

    for (let action of this.getActions()) {

        if (event === action.event) {
            let actionComponent = this.getActionComponent(action);

            actionComponent.fire();
        }
    }

  }

  public getActions(): Action[] {
      return this.field.actions || [];
  }

  private getActionComponent(fieldAction: Action): FieldActionComponentAware {
    let event = fieldAction.event,
        action = fieldAction.action,
        params = fieldAction.params;

    if (!this.pool[action]) {
        throw new Error(`ActionComponent with key ${action} does not exist.`)
    }

    let factory = this.componentFactory.resolveComponentFactory(this.pool[action]);

    let instance = this.grid.getViewContainerRef().createComponent(factory).instance;

    // pass the Inputs here
    instance.action = fieldAction;
    instance.field = this.getField();
    instance.grid = this.getGrid();

    return instance;
  }

  private resolveFactory(fieldAction: Action) {
    return this.componentFactory.resolveComponentFactory(this.pool[fieldAction.action]);
  }

  public setGrid(grid: ViewContainerRefAware): this {
      this.grid = grid;
      return this;
  }

  public getGrid(): ViewContainerRefAware {
      return this.grid;
  }

  public setField(field: FieldMetadataGrid): this {
      this.field = field;
      return this;
  }

  public getField(): FieldMetadataGrid {
      return this.field;
  }

  private populatePool(): void {
    const actions = this.actionRegistryService.getActions();

    for (let action of actions) {
        this.pool[action.action] = action.component;
    }
  }
}
