<div class="ui-splitbutton ui-buttonset ui-widget" style="white-space: nowrap;">
    <button (click)="toogleDropDownVisibility()" class="ui-splitbutton-menubutton ui-button ui-widget ui-state-default ui-corner-right ui-button-icon-only" cornerstyleclass="ui-corner-right" icon="fa-caret-down" pbutton="" type="button"><span class="ui-button-icon-left ui-c fa fa-fw fa-caret-down"></span><span class="ui-button-text ui-c">ui-button</span></button>

    <div *ngIf="isDropDownVisible" class="ui-menu ui-menu-dynamic ui-widget ui-widget-content ui-corner-all ui-helper-clearfix ui-shadow" style="display:block; visibility: visible; top: 21px; left: -102px; opacity: 1.16; z-index: 1002;" align="left">

        <ul class="ui-menu-list ui-helper-reset" *ngFor="let item of items">

            <li (click)="onItemClick(item)" class="ui-menuitem ui-widget ui-corner-all" role="menuitem"
                appSeleniumDirective
                [toolbarItem]="item"
            >
                <a class="ui-menuitem-link ui-corner-all" icon="fa">
                    <span class="ui-menuitem-icon fa fa-fw">
                        <img *ngIf="item.iconPath" src="{{item.iconPath}}" />
                    </span>
                    <span class="ui-menuitem-text">{{item.label}}</span>
                </a>
            </li>

        </ul>

    </div>
</div>
