
import {throwError as observableThrowError, Observable} from 'rxjs';

import {catchError} from 'rxjs/operators';
import { Component, OnInit, Input } from '@angular/core';
import {Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {TOAST_LIFE_INFINITE, ToastComponentsRegistry, ToastService} from '../../../../../../service/toast.service';
import {HttpErrorResponseService} from '../../../../../../../shared/services/http-error-response-message.service';
import {MessageGrowlService} from '../../../../../../message/message-growl.service';
import {GenericCrudService} from '../../../../../../../shared/services/generic-crud.service';
import {Menu} from '../../../../../../../shared/services/menu/menu';


@Component({
    selector: 'app-bdm-export-special-assignments-menu-item',
    templateUrl: './bdm-export-special-assignments-menu-item.component.html',
    styleUrls: ['./bdm-export-special-assignments-menu-item.component.scss']
})
export class BdmExportSpecialAssignmentsMenuItemComponent implements OnInit {

  @Input() iconsPath = '';
  @Input() item: Menu;
  @Input() menuPosition = 'left';

  public isDialogVisible = false;

  public exportMode = 'all';

  constructor(
    protected toastService: ToastService,
    protected translateService: TranslateService,
    protected genericCrudService: GenericCrudService,
    protected messageGrowlService: MessageGrowlService,
    protected httpErrorResponseService: HttpErrorResponseService,
  ) { }

  ngOnInit() {

  }

  public onItemClick(item) {
    this.isDialogVisible = true;

    return false;
  }

  onExport(event){

    this.toastService.custom(ToastComponentsRegistry.PROGRESS_BAR, {
      severity: 'info',
      life: TOAST_LIFE_INFINITE,
      closable: false,
      summary: this.translateService.instant('COMMON.LOADING_PLEASE_WAIT')
    });

    let url = 'phoenix/export/bmd/specialassignments';

    if (this.exportMode === 'latest') {
      url += '/latest';
    }

    this.genericCrudService.get(url).pipe(
      catchError((response) => {
        const errors = this.httpErrorResponseService.getErrors(response);
        this.messageGrowlService.error(errors.toString());
        this.toastService.clear(ToastComponentsRegistry.PROGRESS_BAR);

        return observableThrowError(response);
      }))
      .subscribe(() => {
        this.toastService.clear(ToastComponentsRegistry.PROGRESS_BAR);
      });
  }
}
