
import {of as observableOf, forkJoin as observableForkJoin, Observable} from 'rxjs';

import {catchError} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import {GenericCrudService} from '../../../../../services/generic-crud.service';
import {MessageGrowlService} from '../../../../../../core/message/message-growl.service';
import {ComponentService} from '../../../../services/component-highlight-stack.service';
import {TranslateService} from '@ngx-translate/core';
import {EntityFactoryService} from '../../../../../services/entity-factory.service';
import {ElementSaveStatus} from '../../../generic-element-abstract.component';
import {GenericGridBulkSaveService} from '../../../generic-grid/services/generic-grid-bulk-save.service';
import {MessageService} from '../../../../../../core/message/message.service';
import {Entity} from '../../../../../helpers/entity';
import {ConditionTreeService} from './condition-tree.service';

@Injectable()
export class ConditionTreeGridBulkSaveService extends GenericGridBulkSaveService {

  constructor(
    protected genericCrudService: GenericCrudService,
    protected messageGrowlService: MessageGrowlService,
    protected componentService: ComponentService,
    protected translateService: TranslateService,
    protected messageService: MessageService,
    protected entityFactory: EntityFactoryService
  ) {
    super(
      genericCrudService,
      messageGrowlService,
      componentService,
      translateService,
      messageService,
      entityFactory
    );
  }

  protected flushGridEntityChanges(): Observable<ElementSaveStatus> {
    const observables = [];

    for (const entity of this.commitedEntities) {
      const apiRoute = ConditionTreeService.getApiRoute(entity, this.grid.getElementDataModelApiRoute());

      if (entity.id) {
        observables.push(this.genericCrudService.editEntity(
          `${apiRoute}/${entity.id}`, entity, this.grid.apiRoutAdditionalParameters)
        );
      } else {
        if (ConditionTreeService.isOrCondition(entity)) {
          delete entity.conditions;
        }

        observables.push(
          this.genericCrudService.createEntity(
            apiRoute, entity, this.grid.apiRoutAdditionalParameters
          )
        );
      }
    }

    for (const entity of this.deletedEntities) {
      const apiRoute = ConditionTreeService.getApiRoute(entity, this.grid.getElementDataModelApiRoute());

      if (entity.id) {
        observables.push(this.genericCrudService.deleteEntity(`${apiRoute}/${entity.id}`));
      }
    }

    return Observable.create((observer) => {

      if (observables.length === 0) {
        observer.next({
          status: true,
          content: [],
          message: GenericGridBulkSaveService.SAVE_MESSAGE_NOTHING_TO_SAVE
        });
        observer.complete();
      }

      observableForkJoin(observables).pipe(
        catchError((response: any) => {
          return observableOf(response);
        }))
        .subscribe(results => {
          observer.next({
            status: true,
            content: results
          });
          observer.complete();
        });
    });
  }
}
