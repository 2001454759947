
import {of as observableOf,  Observable } from 'rxjs';

import {catchError} from 'rxjs/operators';
import { AbstractAsyncValidator } from './abstract-async-validator';
import { ValidationStatus } from '../services/validation';
import { cloneDeep } from 'lodash';
import { Constants } from 'app/constants';

export class EntityValidator extends AbstractAsyncValidator {

  public getConstraint(): any {
    return {
      'name': 'entityStrategy',
      'fields': ['notEmpty']
    };
  }

  public isValid(value: any): boolean {
    return true;
  }

  public getErrorTranslateKey(value: any): string {
    return 'ENTITY_IS_NOT_VALID';
  }

  public getErrorTranslateParams(value: any): any {
    const fields = Object.keys(value);

    return {
      'fieldNames': 'entity'
    };
  }

  public getErrorTranslated(errors) {
    let error = '';

    if (errors instanceof Object) {
        Object.keys(errors).forEach(function(field) {
            var fieldErrors = errors[field];

            error += `${field}: ${fieldErrors.join(',')} \n`;
        });
    }

    return error;
  }

  public validate(): Observable<ValidationStatus> {
    let observable = observableOf({
      isValid: true,
      errorTranslated: '',
      errorTranslateKey: '',
      errorTranslateParams: null,
      field: this.getField(),
      value: this.getEntityFieldValue()
    });

    const entityToCheck = cloneDeep(this.getEntity());

    observable = this.getGenericCrudService().createEntity(`${Constants.APP_API_ROUTE}/validate`, {
        'constraint': this.getConstraint(),
        'entity': this.getEntityHydrator().hydrate(entityToCheck)
        }, false).pipe(catchError((response, caught) => {
        const errors = response.error.errors;

        return observableOf({
            isValid: false,
            errorTranslated: this.getErrorTranslated(errors),
            errorTranslateKey: '',
            errorTranslateParams: {},
            field: this.getField(),
            value: this.getEntityFieldValue()
        });
    }));

    return observable;
  }
}
