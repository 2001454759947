
import {of as observableOf,  Observable } from 'rxjs';

import {map} from 'rxjs/operators';
import { BaseSetValueFromDialogStep } from '../../dialog-for-autocomplete/base-set-value-from-dialog-step';
import { FormViewerComponent } from '../../../../form-viewer/form-viewer.component';
import { FieldMetadataGrid } from '../../../module/module-element-field-metadata-grid';
import { ExecutionStepStatus } from '../../../../../core/executor/execution-step-status';
import { ElementInputAutocompleteComponent } from '../../../../form-viewer/element/element-input-autocomplete.component';

export class SetContactPersonToInquiryFromDialogStep extends BaseSetValueFromDialogStep {

    protected doSetValue(fromComponent: FormViewerComponent, toComponent: any, field: FieldMetadataGrid): Observable<ExecutionStepStatus> {
        const contactPerson = fromComponent.entity || null;
            
        let customer = toComponent && toComponent.entity ? toComponent.entity.customer : null;

        if (typeof customer === 'number') {
            customer = {
                id: customer
            }
        }

        if (toComponent instanceof ElementInputAutocompleteComponent && 
            contactPerson !== null && 
            customer !== null
        ) {
            return this.getContactPerson(customer, contactPerson).pipe(
                map((contactPerson: any) => {
                    toComponent.entity.contactPerson = contactPerson;
                    toComponent.entity._embedded = toComponent.entity._embedded || {};
                    toComponent.entity._embedded.contactPerson = contactPerson;

                    this.setValueToFormAutocomplete(contactPerson, toComponent);

                    return {status: true, content: null};
                }));
        }

        return observableOf({
            status: true,
            content: null
        });
    }

    private getContactPerson(customer: any, contactPerson: any): Observable<any> {
        return this.saveContactPerson(customer, contactPerson).pipe(
            map((customerCollectiveContract: any) => {
                return customerCollectiveContract;
            }));
    }

    private saveContactPerson(customer: any, contactPerson: any): Observable<any> {
        contactPerson.customer = customer;

        return this.genericCrudService.createEntity('phoenix/contactpersons', contactPerson).pipe(
            map((contactPerson: any) => {
                return contactPerson;
            }));
    }
}
