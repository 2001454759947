import {ModuleStateView} from './module-state-view';
import {ModuleState} from '../../module-state';
import {Observable} from 'rxjs';

export abstract class AbstractModuleStateView implements ModuleStateView {
  public moduleState: ModuleState = null;

  public abstract doOpenPrevious(): Observable<any>;

  public openPrevious(): Observable<any> {
    return this.doOpenPrevious();
  }
}
