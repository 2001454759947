
import { Injectable } from '@angular/core';
import { AbstractFormActionHandler } from '../abstract-form-action-handler';
import { FormService } from '../../../form.service';
import { HandlePhoneCountryCodeContactPersonActionHandler } from './handle-phone-country-code-contact-person-action-handler';

@Injectable()
export class HandleMobileCountryCodeContactPersonActionHandler extends HandlePhoneCountryCodeContactPersonActionHandler {

    protected getPhoneCountryCodeDatamodelField(): string {
        return 'mobileCountryCode';
    }

    protected getPhoneAreaCodeDatamodelField(): string {
        return 'mobileAreaCode';
    }

    protected getPhoneNumberDatamodelField(): string {
        return 'mobileNumber';
    }
}
