import {Observable} from 'rxjs';
import {AbstractExecutionStep} from '../../../../../core/executor/abstract-execution-step';
import {ExecutionStepStatus} from '../../../../../core/executor/execution-step-status';
import {WizardComponent} from '../../../../content-renderer/elements/custom/wizard/wizard.component';
import {WizardElement} from '../../../element/wizard';
import {WizardElementDetails} from '../../../../content-renderer/elements/custom/wizard/service/wizard.service';
import {
  ElementSaveStatus,
  GenericElementAbstract
} from '../../../../content-renderer/elements/generic-element-abstract.component';
import {ModuleState} from '../../../../content-renderer/services/module-state';
import {FormViewerComponent} from '../../../../form-viewer/form-viewer.component';
import {ChangeDetectorRefHelper} from '../../../../helpers/change-detector-ref.helper';

export class WizardSavePrecalculationExecutionStep extends AbstractExecutionStep {

  public doExecute(): Observable<ExecutionStepStatus> {
    const payloadValue = this.getPayload().getValue();

    const wizardComponent = payloadValue.wizardComponent,
      wizardElement = payloadValue.wizardElement;

    if (!(wizardComponent instanceof WizardComponent)) {
      return this.getFailObservable('You need to pass WizardComponent as Payload value!');
    }

    return this.doSet(wizardComponent, wizardElement);
  }

  protected doSet(wizardComponent: WizardComponent, wizardElement: WizardElement): Observable<ExecutionStepStatus> {
    const elementDetails: WizardElementDetails = wizardComponent.wizardService.getElementDetails(wizardElement),
      wizardElementModuleState = elementDetails.moduleState;

    const formComponent: any = this.getFormComponent(wizardElementModuleState);

    formComponent.emitEntitySaved = false;
    formComponent.performRefreshOnSave = false;
    formComponent.setIsNewEntityDirty(true);

    return formComponent.onSave()
      .map((status: ElementSaveStatus) => {
        if (status.status) {
          this.onSuccess(wizardComponent, elementDetails, status.content);
        } else {
          console.error(status.content);
        }

        return {status: status.status, content: null};
      });
  }

  private getFormComponent(moduleState: ModuleState): GenericElementAbstract|FormViewerComponent|null {
    return moduleState.getComponents().find((aComponent) => {
      return aComponent instanceof FormViewerComponent;
    });
  }

  public onSuccess(wizardComponent: WizardComponent, elementDetails: WizardElementDetails, entity: any): void {
    elementDetails.inputDetails.entity = entity;

    wizardComponent.wizardService.openNext().subscribe((wizardElementDetails: WizardElementDetails) => {
      wizardComponent.wizardElement = wizardElementDetails.wizardElement;

      ChangeDetectorRefHelper.detectChanges(wizardComponent);
    });
  }
}
