
import {of as observableOf,  Observable } from 'rxjs';

import {switchMap} from 'rxjs/operators';
import {AbstractExecutionStep} from '../../../../../core/executor/abstract-execution-step';
import {ExecutionStepStatus} from '../../../../../core/executor/execution-step-status';
import {FormViewerComponent} from '../../../../form-viewer/form-viewer.component';
import {ElementContext} from '../../../../content-renderer/services/ElementContext';
import {MessageGrowlService} from '../../../../../core/message/message-growl.service';
import {GenericDialogModuleService} from '../../../../content-renderer/elements/generic-dialog/service/generic-dialog-module.service';

export class SetSelectedWorkingTimeModelToInquiryPositionExecutionStep extends AbstractExecutionStep {

  doExecute(): Observable<ExecutionStepStatus> {
    const component = this.getPayload().getValue();

    if (!(component instanceof FormViewerComponent)) {
      return this.getFailObservable('You need to pass GenericElementAbstract as Payload value!');
    }

    return this.doSet(component);
  }

  private doSet(component: FormViewerComponent): Observable<ExecutionStepStatus> {
    const selectedWorkingTimeModel = component.entity,
      inquiryPosition = this.getInquiryPosition(component);

    if (selectedWorkingTimeModel && selectedWorkingTimeModel.id && inquiryPosition) {
      inquiryPosition.selectedWorkingTimeModel = selectedWorkingTimeModel;
      inquiryPosition._embedded = inquiryPosition._embedded || {};
      inquiryPosition._embedded.selectedWorkingTimeModel = selectedWorkingTimeModel;

      if (inquiryPosition.id) {
        return this.genericCrudService.editEntity(`phoenix/inquirypositions/${inquiryPosition.id}`, inquiryPosition).pipe(
          switchMap((savedInquiryPosition) => {
            this.getGrowl().showDataSaved();

            return this.getSuccessObservable();
          }));
      } else {
        this.getGrowl().showDataSaved();

        return this.getSuccessObservable();
      }
    }

    return observableOf({status: false, content: 'Something is missing!'});
  }

  private getInquiryPosition(component: FormViewerComponent): any|null {
    const componentContext: ElementContext = component.getElementContext();

    let inquiryPosition = null;

    if (componentContext && componentContext.getMasterElementContext() && componentContext.getMasterElementContext().component) {
      inquiryPosition = componentContext.getMasterElementContext().component.getSelectedEntity();
    }

    return inquiryPosition;
  }

  private getGrowl(): MessageGrowlService|null {
    return this.injector.get(MessageGrowlService, null);
  }

  private getDialog(): GenericDialogModuleService|null {
    return this.injector.get(GenericDialogModuleService, null);
  }
}
