import { of as observableOf } from 'rxjs';
import { map } from 'rxjs/operators';
import { EntityValidatorStatus } from '../../../validators/services/entity-validator';
import { DynamicTableComponent } from '../../../dynamic-table/dynamic-table.component';
import { GenericTurboGridComponent } from '../generic-turbo-grid/generic-turbo-grid.component';
export class GenericDynamicTableComponent extends GenericTurboGridComponent {
    constructor() {
        super(...arguments);
        this.element = null;
        this.toolbarItems = [];
        this.statusBarItems = [];
        this.masterEntity = null;
        this.masterField = null;
        this.isPart = false;
        this.elementType = 4 /* DynamicGrid */;
        this.filters = new Object();
        this.totalCount = 0;
        this.isLoadingData = false;
        this.entities = [];
        this.columns = [];
        this.rows = [];
        this.toolbarContextName = 'dynamicTableComponent';
    }
    onLazyLoad(event) {
        this.subscriptions.push(this.loadData(event).subscribe());
    }
    getTable() {
        return this.table;
    }
    onFilter(event) {
        console.log(event);
    }
    onValidate() {
        return observableOf({
            entity: null,
            isValid: true,
            error: '',
            errorFields: []
        });
    }
    onCellEdit(event) {
        const column = event.column, entity = event.entity, originalEvent = event.originalEvent;
        this.executorService
            .fire(this.moduleElement.id, 51 /* DynamicTableCellEdit */, {
            component: this,
            column: column,
            entity: entity,
            event: originalEvent
        })
            .subscribe((status) => {
            this.recheckToolbarItems();
        });
    }
    onRefresh() {
        return this.executorService
            .fire(this.moduleElement.id, 13 /* EntitiesRefresh */, this).pipe(map((status) => {
            this.recheckToolbarItems();
            return { status: status.getStepStatus(), content: status.getStepContent(), message: null };
        }));
    }
    onSave() {
        return this.executorService
            .fire(this.moduleElement.id, 50 /* DynamicTableSave */, this).pipe(map((status) => {
            this.recheckToolbarItems();
            return { status: status.getStepStatus(), content: status.getStepContent(), message: null };
        }));
    }
    loadData(event) {
        this.setGridScrollHeightAndWidth();
        return observableOf(true);
    }
    setGridScrollHeightAndWidth() {
        const dynamicTable = this.getTable();
        if (this.element && this._gridContainer && dynamicTable) {
            let containerHeight = this._gridContainer.nativeElement.clientHeight;
            console.log(containerHeight);
            if (this.element.toolbarItems && this.element.toolbarItems.length > 0) {
                containerHeight -= 30;
            }
            // header, footer, use calculation
            this.getTable().height = containerHeight;
        }
    }
}
