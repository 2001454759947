import { AbstractFormActionConditionHandler } from './abstract-form-action-condition-handler';
import { FormService } from '../../form.service';

export class IsElementValueEqualConditionHandler extends AbstractFormActionConditionHandler {

    public constructor(
        private formService: FormService
    ) {
        super();
    }

    public isValid(): boolean {
        let isValid = false;

        const formElementHashId = this.getParamValue('formElement'),
            isValidValueCondition = this.getParamValue('formElementValue') || null,
            formElement = this.formService.getElementBy(this.getForm(), 'objectHashId', formElementHashId),
            entity = this.getEntity();

        if (entity && formElement) {
            const datamodelField = formElement.datamodelField;

            isValid = entity[datamodelField] == isValidValueCondition;
        }

        return isValid;
    }
}
