/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./toolbar-item-back.component";
import * as i2 from "../../../../../services/navigation/module-navigation.service";
import * as i3 from "../../../../../services/modules-state.service";
var styles_ToolbarItemBack = [];
var RenderType_ToolbarItemBack = i0.ɵcrt({ encapsulation: 2, styles: styles_ToolbarItemBack, data: {} });
export { RenderType_ToolbarItemBack as RenderType_ToolbarItemBack };
export function View_ToolbarItemBack_0(_l) { return i0.ɵvid(2, [], null, null); }
export function View_ToolbarItemBack_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-toolbar-item-back", [], null, null, null, View_ToolbarItemBack_0, RenderType_ToolbarItemBack)), i0.ɵdid(1, 245760, null, 0, i1.ToolbarItemBack, [i2.ModuleNavigationService, i3.ModulesStateService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ToolbarItemBackNgFactory = i0.ɵccf("app-toolbar-item-back", i1.ToolbarItemBack, View_ToolbarItemBack_Host_0, { params: "params" }, {}, []);
export { ToolbarItemBackNgFactory as ToolbarItemBackNgFactory };
