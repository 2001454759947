<p-dialog
    *ngIf="isDialogVisible"
    [(visible)]="isDialogVisible"
    header="{{ 'COLLECTIVE_AGREEMENT.MANAGE_DEFAULT_PARAMS' | translate }}"
    showEffect="fade"
    [width]="800"
    appendTo="body"
>

    <div *ngIf="isDataLoading">
        {{ 'COMMON.LOADING_PLEASE_WAIT' | translate }}
    </div>

    <p-table
      [value]="parameters"
      *ngIf="!isDataLoading"
    >
      <ng-template pTemplate="caption">
        <div class="header">
          <button type="button" pTooltip="{{ 'COMMON.ADD' | translate }}" pButton icon="fa-plus" (click)="onAdd()"></button>
        </div>
      </ng-template>
      <ng-template pTemplate="header">
        <tr>
          <th>{{ 'COMMON.NAME' | translate }}</th>
          <th>{{ 'COLLECTIVE_AGREEMENT.TIME_SPECIFIC' | translate }}</th>
          <th>{{ 'COLLECTIVE_AGREEMENT.GENERAL' | translate }}</th>
          <th>{{ 'COLLECTIVE_AGREEMENT.CALCULATION_RELEVANT' | translate }}</th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-ca>
        <tr [pSelectableRow]="ca">
          <td>{{ ca.name }}</td>
          <td>{{ ca.isLocal }}</td>
          <td>{{ ca.isGlobal }}</td>
          <td>{{ ca.isEngineRelevant }}</td>
        </tr>
      </ng-template>
    </p-table>

</p-dialog>
<p-dialog
  *ngIf="isAddDialogVisible"
  [(visible)]="isAddDialogVisible"
  header="{{ 'COMMON.ADD' | translate }}"
  showEffect="fade"
  [width]="800"
  appendTo="body"
>
  <div class="ui-grid ui-grid-responsive ui-fluid" *ngIf="createdParam">
    <div class="ui-grid-row">
      <div class="ui-grid-col-4"><label for="parameterName">{{ 'COLLECTIVE_AGREEMENT.NAME' | translate }}:</label></div>
      <div class="ui-grid-col-8"><p-dropdown [options]="potentialParams" id="parameterName" [style]="{'width':'100%'}" [(ngModel)]="createdParam.name"></p-dropdown></div>
    </div>
    <div class="ui-grid-row">
      <div class="ui-grid-col-4"><label for="parameterIsLocal">{{ 'COLLECTIVE_AGREEMENT.TIME_SPECIFIC' | translate }}</label></div>
      <div class="ui-grid-col-8">
        <p-checkbox pInputCheckbox id="parameterIsLocal" [(ngModel)]="createdParam.isLocal" binary="true"></p-checkbox>
      </div>
    </div>
    <div class="ui-grid-row">
      <div class="ui-grid-col-4"><label for="parameterIsGlobal">{{ 'COLLECTIVE_AGREEMENT.GENERAL' | translate }}:</label></div>
      <div class="ui-grid-col-8">
        <p-checkbox pInputCheckbox id="parameterIsGlobal" [(ngModel)]="createdParam.isGlobal" binary="true"></p-checkbox>
      </div>
    </div>
    <div class="ui-grid-row">
      <div class="ui-grid-col-4"><label for="parameterIsEngineRelevant">{{ 'COLLECTIVE_AGREEMENT.CALCULATION_RELEVANT' | translate }}:</label></div>
      <div class="ui-grid-col-8">
        <p-checkbox pInputCheckbox id="parameterIsEngineRelevant" [(ngModel)]="createdParam.isEngineRelevant" binary="true"></p-checkbox>
      </div>
    </div>
  </div>
  <footer>
    <div class="ui-dialog-buttonpane ui-widget-content ui-helper-clearfix">
      <button type="button" pButton (click)="doAdd()" label="{{ 'COMMON.SAVE' | translate }}"><i class="fa fa-check" aria-hidden="true"></i></button>
    </div>
  </footer>
</p-dialog>
