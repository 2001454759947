
import {forkJoin as observableForkJoin, of as observableOf, Observable} from 'rxjs';

import {catchError} from 'rxjs/operators';
import {AbstractExecutionStep} from '../../../../../core/executor/abstract-execution-step';
import {ExecutionStepStatus} from '../../../../../core/executor/execution-step-status';
import {ExpertSearch, ExpertSearchFieldsContainer} from '../../../../services/expert-search/expert-search';
import {EntityStatus} from '../../../../services/entity/entity-status';

export class SaveExpertSearchContainersExecutionStep extends AbstractExecutionStep {

  public doExecute(): Observable<ExecutionStepStatus> {
    const expertSearch: ExpertSearch = this.getPayload().getValue().expertSearch,
      containers: ExpertSearchFieldsContainer[] = this.getPayload().getValue().containers;

    if (!expertSearch) {
      return this.getFailObservable('ExpertSearch not found!');
    }

    return this.doSave(expertSearch, containers);
  }

  public doSave(expertSearch: ExpertSearch, containers: ExpertSearchFieldsContainer[]): Observable<ExecutionStepStatus> {
    const observables = [];

    for (const aContainer of containers) {
      aContainer.expertSearch = expertSearch;

      for (const field of aContainer.fields) {
        field.fieldValue = JSON.stringify(field.fieldValue);
        field.parameters = JSON.stringify(field.parameters);
      }

      if (!aContainer[EntityStatus.ENTITY_DRAFT_BACKEND_DELETE_FLAG]) {
        if (aContainer.id) {
          aContainer[EntityStatus.ENTITY_CHANGED_FLAG] = true;
          aContainer[EntityStatus.EMBEDDED_ENTITY_CHANGED_FLAG] = true;

          observables.push(
            this.genericCrudService
              .customPut(`app/expertsearchcontainers/${aContainer.id}`, aContainer)
          );
        } else {
          observables.push(
            this.genericCrudService
              .createEntity('app/expertsearchcontainers', aContainer)
          );
        }
      }
    }

    if (observables.length === 0) {
      return observableOf({status: true, content: null});
    }

    return Observable.create((observer) => {
      observableForkJoin(observables).pipe(
        catchError((response: any) => {
          return observableOf(response);
        }))
        .subscribe((aContainers: ExpertSearchFieldsContainer[] = []) => {
          observer.next({status: true, content: null});
          observer.complete();
        });
    });
  }
}
