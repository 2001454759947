import { Injectable } from '@angular/core';
import { Form, FormAction, FormElementAction } from '../../models/form';
import { Element } from 'app/shared/form-viewer/models/element';
import { FormActionFactoryService } from './form-action-factory.service';
import { FormGroup } from '@angular/forms';

@Injectable()
export class FormActionsService {

    public constructor(
        private formActionFactoryService: FormActionFactoryService
    ) {

    }

    public fireFormAction(event: string /*ActionsEvent*/, form: Form, entity: any, formGroup: FormGroup, element: Element, component = null): void {

        if (form && form.actions.length > 0) {
            form.actions
                .filter((action: FormAction) => (action.command && action.command.toLowerCase() === event.toLowerCase()))
                .map((action: FormAction) => this.handleFormAction(action, form, entity, formGroup, element, component));
        }
    }

    public fireElementsAction(event: string, action: FormElementAction, elements: Element[], form: Form, formGroup: FormGroup): void {

        for (let element of elements) {
            this.fireElementAction(event, action, element, form, formGroup);
        }
    }

    public fireElementAction(event: string, action: FormElementAction, element: Element, form: Form, formGroup: FormGroup): void {

        if (element && element.actions.length > 0) {
            // action = element.actions.find((action: FormElementAction) => { return action.command && action.command.toLowerCase() === event.toLowerCase() })
        }

        if (action) {

        }
    }

    private handleFormAction(action: FormAction, form: Form, entity: any, formGroup: FormGroup, element, component): void {
        let canHandleFormAction = true;

        if (action.conditions.length > 0) {
            canHandleFormAction = this.canHandleFormAction(action, form, entity, formGroup, element);
        }

        if (canHandleFormAction) {
            const formActionHandler = this.formActionFactoryService.getFormActionHandler(action, form, entity, formGroup, element, component);

            if (formActionHandler) {
              formActionHandler.handleAction();
            }
        }
    }

    private canHandleFormAction(action: FormAction, form: Form, entity: any, formGroup: FormGroup, element): boolean {
        let canHandleFormAction = true;

        for (const condition of action.conditions) {
            const conditionHandler = this.formActionFactoryService.getFormActionConditionHandler(action, condition, form, entity, formGroup, element);

            if (!conditionHandler.isValid()) {
                canHandleFormAction = false;
                break;
            }
        }

        return canHandleFormAction;
    }
}
