import {Injectable} from '@angular/core';
import {Element, ElementInputAutocomplete, ElementInputDropdown, Form} from '../../form-viewer/models';
import {ElementInputAssociation} from '../../form-viewer/models/element';
import {ElementInputMultiAutocomplete} from '../../form-viewer/models/element-input-multi-autocomplete';

@Injectable()
export class ElementFormEmbeddedBuilderService {

  params: string[] = [];

  public buildParams(form: Form): this {
    this.params = ['self'];

    const elements = form.elements || [];

    this.addParams(elements);

    return this;
  }

  public getParams(): any {
    return this.params;
  }

  public getParamsAsString(): string {

    if (this.params.length === 0) {
      return 'self';
    }

    return this.params.join(',');
  }

  public addParam(name: string): void {
    this.params.push(name);
  }

  private addParams(elements: Element[] = []) {
    for (const element of elements) {

      if (element instanceof ElementInputAssociation) {

        if (element.displayConfig && element.displayConfig instanceof Array && element.displayConfig.length > 0) {
          for (const displayConfig of element.displayConfig) {
            if (displayConfig.fieldName && displayConfig.fieldName.includes('.')) {
              this.addParam(displayConfig.fieldName);
            }
          }
        }

        this.addParam(element.datamodelField);
      }
      if (element instanceof ElementInputDropdown || element instanceof ElementInputAutocomplete
        || element instanceof ElementInputMultiAutocomplete
      ) {
        if (element.masterFilterFieldValue) {
          this.addParam(element.masterFilterFieldValue);
        }
      }

      if (!(element instanceof ElementInputAssociation) && element.datamodelField && element.datamodelField.includes('.')) {
        this.addParam(element.datamodelField);
      }

      if (element.children && element.children instanceof Array) {
        this.addParams(element.children);
      }
    }
  }

}
