import { Observable } from 'rxjs';
import { AbstractExecutionStep } from '../../../../core/executor/abstract-execution-step';
import { ExecutionStepStatus } from '../../../../core/executor/execution-step-status';
import { AbstractGenericGridComponent } from 'app/shared/content-renderer/elements/abstract-generic-grid.component';
import {DoubleClickService} from '../../../content-renderer/services/double-click.service';
import {TOAST_LIFE_INFINITE, ToastComponentsRegistry, ToastService} from '../../../../core/service/toast.service';
import {TranslateService} from '@ngx-translate/core';

export class CopyInquiryJobStep extends AbstractExecutionStep {

    doExecute(): Observable<ExecutionStepStatus> {
        const component = this.getPayload().getValue();

        if (!(component instanceof AbstractGenericGridComponent)) {
            return this.getFailObservable('You need to pass AbstractGenericGridComponent as Payload value!');
        }

        return this.doRegister(component);
    }

    private doRegister(component: AbstractGenericGridComponent): Observable<ExecutionStepStatus> {
      return Observable.create((observer) => {
        const url = `phoenix/inquiries/copy/${component.getSelectedEntity().id}`;
        const doubleClickService: DoubleClickService = this.injector.get(DoubleClickService, null);

        this.getToast().custom(ToastComponentsRegistry.PROGRESS_BAR, {
          severity: 'info',
          life: TOAST_LIFE_INFINITE,
          closable: false,
          summary: this.getTranslate().instant('COMMON.LOADING_PLEASE_WAIT')
        });

        this.genericCrudService.customPost(url, {}).subscribe((inquiry: Record<string, any>) => {
          this.getToast().clear(ToastComponentsRegistry.PROGRESS_BAR, 300);
          this.genericCrudService.getEntity('superadmin/modules', 375).subscribe((module) => {
            doubleClickService.openModuleInNewTabCustom(module, 'inquiries', 'PhoenixBundle\\Entity\\Inquiry', component.moduleElement, inquiry.id);
          })
        });
        observer.next({
          status: true,
          content: null
        });
        observer.complete();
      });
    }

    private getToast(): ToastService|null {
      return this.injector.get(ToastService, null);
    }

    private getTranslate(): TranslateService|null {
      return this.injector.get(TranslateService, null);
    }
}
