import {Observable} from 'rxjs/Observable';
import {Branch} from '../../../../../services/branch/branch';
import {map, switchMap} from 'rxjs/operators';
import {Entity} from '../../../../../helpers/entity';
import {UserSessionService} from '../../../../../../core/service/user-session.service';
import {AuthenticationService} from '../../../../../../core/authentication/authentication.service';
import {GenericCrudService} from '../../../../../services/generic-crud.service';
import {DateHelper} from '../../../../../helpers/date.helper';

export class LeasedEmployeeStatCountService {

  public constructor(
    private userSession: UserSessionService,
    private authenticationService: AuthenticationService,
    private genericCrudService: GenericCrudService
  ) {

  }

  public getStats(): Observable<any[]> {
    const currentBranchOffice = this.userSession.get(Branch.LOCAL_STORAGE_NAME);
    // const currentUserId = this.authenticationService.currentUser.id;

    // return this.genericCrudService.getEntity('app/branchoffices', currentBranchOffice.id, '', {
    //   embedded: 'companyUser,branchManager'
    // }).pipe(
    //   map((branchOffice) => {
    //     const companyUserId = Entity.getValue(branchOffice, 'companyUser.id') || Entity.getValueInEmbedded(branchOffice, 'companyUser.id'),
    //       branchManagerId = Entity.getValue(branchOffice, 'branchManager.id') || Entity.getValueInEmbedded(branchOffice, 'branchManager.id');
    //
    //     return {
    //       companyUserId,
    //       branchManagerId,
    //     }
    //   }),
    //   switchMap(({ companyUserId, branchManagerId }) => {
    //     const params = {
    //       embedded: 'user',
    //       year: new Date().getFullYear()
    //     }
    //
    //     if (![companyUserId, branchManagerId].includes(currentUserId)) {
    //       params['user.id'] = currentUserId;
    //     }
    //
    //     return this.genericCrudService.getEntities('phoenix/leasedemployeestatcounts', '', params)
    //   }),
    // )
    return this.genericCrudService.getEntity('app/branchoffices', currentBranchOffice.id, '', {
      embedded: 'none'
    }).pipe(
      switchMap((currBranchOffice) => {
        const params = {
          embedded: 'user',
          branchOffice: currBranchOffice.id,
          year: new Date().getFullYear()
        }
        return this.genericCrudService.getEntities('intranet/userweeklyaccumulatedactivities', '', params)
      })
    );
  }

  public getAllStats(): Observable<any[]> {
    const currentBranchOffice = this.userSession.get(Branch.LOCAL_STORAGE_NAME);
    return this.genericCrudService.getEntity('app/branchoffices', currentBranchOffice.id, '', {
      embedded: 'none'
    }).pipe(
      switchMap((currBranchOffice) => {

        const params = {
          embedded: 'none',
          branchOffice: currBranchOffice.id,
          year: new Date().getFullYear()
        }
        return this.genericCrudService.getEntities('intranet/userweeklyaccumulatedactivities', '', params)
      })
    );

  }
}
