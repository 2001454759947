import { Observable } from 'rxjs';
import { AbstractGenericElementActionHandler } from './abstract-generic-element-action-handler';
import { GenericGridColumnBuilderService } from '../../../content-renderer/elements/generic-grid/services/generic-grid-column-builder.service';
import { GenericElementEvent } from '../event/generic-element-event';
import { ActionHandlerSubscribeResponse } from '../../../../core/events/interfaces/action-handler-subscribe-response';
import { AbstractGenericGridComponent } from '../../../content-renderer/elements/abstract-generic-grid.component';

export class GenericElementInitColumnsActionHandler extends AbstractGenericElementActionHandler {

    public constructor(
        private genericGridColumnBuilderService: GenericGridColumnBuilderService
    ) {
        super();
    }
    
    protected doAction(event: GenericElementEvent): Observable<ActionHandlerSubscribeResponse> {
        let component = event.getComponent();

        if (component instanceof AbstractGenericGridComponent) {
            this.doInitColumns(component);
        }

        return this.getObservable(true);
    }

    private doInitColumns(component: AbstractGenericGridComponent) {
        this.genericGridColumnBuilderService.firstSetGrid(component).thenSetupColumns();
    }
    
}
