
import {of as observableOf, Observable } from 'rxjs';
import {AbstractExecutionStep} from '../../../core/executor/abstract-execution-step';
import {ExecutionStepStatus} from '../../../core/executor/execution-step-status';
import {GenericElementAbstract} from '../../content-renderer/elements/generic-element-abstract.component';
import {GenericTreeGridComponent} from '../../content-renderer/elements/generic-tree-grid/generic-tree-grid.component';
import {AbstractGenericGridComponent} from '../../content-renderer/elements/abstract-generic-grid.component';

export class ComponentAddNewEntityExecutionStep extends AbstractExecutionStep {

  public doExecute(): Observable<ExecutionStepStatus> {
    const payload = this.getPayload();

    if (!(payload.getValue() instanceof GenericElementAbstract)) {
      return this.getFailObservable('You need to pass GenericElementAbstract as Payload value!');
    }

    return this.doAdd(payload.getValue());
  }

  protected doAdd(component: GenericElementAbstract): Observable<ExecutionStepStatus> {

    if (component instanceof GenericTreeGridComponent) {
      component.selectedNode = null;
      component.selectedEntity = null;

      component.startNewEntityAdd();
    }

    if (component instanceof AbstractGenericGridComponent) {
      component.startNewEntityAdd();
    }

    return observableOf({status: true, content: null });
  }
}
