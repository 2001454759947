import { AbstractValidator } from './abstract-validator';

export class EmailValidator extends AbstractValidator {

  public isValid(value: any): boolean {
    let isValid = true;

    if (typeof value === 'undefined' || value === null) {
      value = '';
    }

    let isValueRequired = this.getParamValue('required') || false;

    if (!value && !isValueRequired) {
      return true;
    }
    
    // https://stackoverflow.com/a/46181
    let pattern = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    
    return pattern.test(value.toString());
  }

  public getErrorTranslateKey(value: any): string {
    return 'EMAIL_FORMAT_IS_NOT_VALID';
  }

  public getErrorTranslateParams(value: any): any {
    return null;
  }
}
