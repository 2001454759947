    
    <p-autoComplete
      appAutocomplete
      appendTo="body"
      field="label"
      [placeholder]="'---'"
      [dropdown]="true"
      [(ngModel)]="selectedOption"
      [suggestions]="options"
      (onKeyUp)="onKeyUp($event)"
      (completeMethod)="onSearch($event)"
      (onDropdownClickNoQuery)="onSearch($event)"
      (onSelect)="onCustomerChanged($event)"
    ></p-autoComplete>
  