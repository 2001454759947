
import {of as observableOf, Observable} from 'rxjs';
import {AbstractExecutionStep} from '../../../../core/executor/abstract-execution-step';
import {ExecutionStepStatus} from '../../../../core/executor/execution-step-status';
import {ModuleState} from '../../../content-renderer/services/module-state';
import {EntityStatus} from '../../entity/entity-status';
import {EntityValidator} from '../../../validators/services/entity-validator';
import {ModuleNavigationService} from '../../../content-renderer/services/navigation/module-navigation.service';
import {AbstractGenericGridComponent} from '../../../content-renderer/elements/abstract-generic-grid.component';
import {FormViewerComponent} from '../../../form-viewer/form-viewer.component';
import {ChangeDetectorRefHelper} from '../../../helpers/change-detector-ref.helper';

export class DetailsViewMasterAfterSaveExecutionStep extends AbstractExecutionStep {

  public doExecute(): Observable<ExecutionStepStatus> {
    const payload = this.getPayload();

    const moduleState = payload.getValue();

    if (!(moduleState instanceof ModuleState)) {
      return observableOf({status: false, content: 'Payload should be modulestate, not something else.'});
    }

    if (!moduleState.entity) {
      return observableOf({status: false, content: 'Module state entity is not set.'});
    }

    return this.doSave(moduleState);
  }

  protected doSave(moduleState: ModuleState): Observable<ExecutionStepStatus> {
    const entity = moduleState.entity;

    const masterComponent = this.moduleStateInspectorService.getDetailsMasterComponent(moduleState);

    if (masterComponent && masterComponent.getSelectedEntity() && !masterComponent.getSelectedEntity().id) {
      masterComponent.getSelectedEntity().id = entity.id;
    }

    delete entity[EntityStatus.ENTITY_CHANGED_FLAG];
    this.entityDirtyStoreService.remove(entity);
    this.resetComponentChanges(moduleState);

    moduleState.url = this.getModuleNavigation().getModuleRouter().replaceRouteEntity(entity);

    const entityValidator = this.injector.get(EntityValidator, null);

    if (entityValidator) {
      entityValidator.clearCache();
    }

    return observableOf({status: true, content: null});
  }

  private getModuleNavigation(): ModuleNavigationService {
    return this.injector.get(ModuleNavigationService, null);
  }

  private resetComponentChanges(moduleState: ModuleState): void {
    const components = moduleState.getAllComponents();

    for (const component of components) {
      if (component instanceof FormViewerComponent && component.isNewEntityDirty) {
        component.setIsNewEntityDirty(false);
      }

      ChangeDetectorRefHelper.detectChanges(component);
    }
  }
}
