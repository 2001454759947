
import {catchError} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AppHttpService } from '../../../app-http.service';
import { EntityHydrator } from '../entity-hydrator.service';
import { GenericCrudRequestOptions } from '../generic-crud-request-options.service';
import { GenericCrudService } from '../generic-crud.service';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class WorkflowCrudService extends AppHttpService {

  private productsUrl: string = 'superadmin/products';

  private apiUrl: string = '';

  constructor(
    private httpClient: HttpClient,
    private genericCrudService: GenericCrudService,
    private entityHydrator: EntityHydrator,
    private requestOptions: GenericCrudRequestOptions
  ) {
    super();
    this.apiUrl = this.getApiUrl();
  }

  createWorkflow(apiRoute: string, entity: any): Observable<any> {
    if (apiRoute && entity) {
      const url = `${this.apiUrl}/${apiRoute}`;

      return this.httpClient.post(
        url,
        JSON.stringify(this.entityHydrator.hydrate(entity)),
        { params: this.genericCrudService.getRequestOptions().getHttpParams() }
      ).pipe(
        catchError(this.handleError));
    } else {
      throw new Error('No entity given.');
    }
  }

  editWorkflow(apiRoute: string, entity): Observable<any> {
    if (apiRoute && entity) {
      const url = `${this.apiUrl}/${apiRoute}`;

      return this.httpClient.put(
        url,
        JSON.stringify(this.entityHydrator.hydrate(entity)),
        { params: this.genericCrudService.getRequestOptions().getHttpParams() }
      ).pipe(
        catchError(this.handleError));
    }
    throw new Error('No entity given.');
  }
}
