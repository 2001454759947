import { ToolbarItemAbstract } from '../toolbar-item-abstract.component';
export class ToolbarItemAuditComponent extends ToolbarItemAbstract {
    constructor() {
        super(...arguments);
        this.isDialogVisible = false;
        this.element = null;
        this.entity = null;
    }
    click() {
        this.element = this.getComponent().element;
        this.entity = this.getComponent().getSelectedEntity();
        this.isDialogVisible = true;
    }
    onShow() {
        setTimeout(() => {
            this.dialog.maximize();
        }, 20);
        setTimeout(() => {
            window.dispatchEvent(new Event('resize'));
        }, 500);
    }
}
