import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {AuthenticationService} from '../authentication/authentication.service';
import {StyleService} from '../style.service';
import {LocalStorageDataService} from '../../shared/services/local-storage-data.service';
import {GenericCrudService} from '../../shared/services/generic-crud.service';
import {IsUserAuthenticatedExecutionStep} from './bootstrap-steps/is-user-authenticated-execution-step';
import {ExecutionStepPayload} from '../executor/execution-step-payload';
import {BootstrapDesignExecutionStep} from './bootstrap-steps/bootstrap-design-execution-step';
import {BootstrapCollectiveAgreementDataExecutionStep} from './bootstrap-steps/bootstrap-collective-agreement-data-execution-step';
import {ExecutorService} from '../executor/executor.service';
import {UserSessionService} from './user-session.service';

@Injectable()
export class BootstrapService {

  private executorService: ExecutorService = null;

  public constructor(
    private authenticationService: AuthenticationService,
    private localStorage: LocalStorageDataService,
    private genericCrudService: GenericCrudService,
    private styleService: StyleService,
    private userSession: UserSessionService
  ) {

  }

  public bootstrap(): Observable<any> {
    const steps = [];

    steps.push(new IsUserAuthenticatedExecutionStep(
      new ExecutionStepPayload({
        authenticationService: this.authenticationService
      }))
    );

    steps.push(new BootstrapDesignExecutionStep(
      new ExecutionStepPayload({
        userSession: this.userSession,
        styleService: this.styleService
      })
    ));

    steps.push(new BootstrapCollectiveAgreementDataExecutionStep(
      new ExecutionStepPayload({
        localStorage: this.localStorage,
        genericCrudService: this.genericCrudService,
        userSession: this.userSession
      })
    ));

    return this.executorService
      .setSteps(steps)
      .execute();
  }

  public setExecutorService(executorService: ExecutorService): BootstrapService {
    this.executorService = executorService;
    return this;
  }

}
