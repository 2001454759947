/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./sql-table.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../generic-loading/generic-loading.component.ngfactory";
import * as i3 from "../../generic-loading/generic-loading.component";
import * as i4 from "@angular/flex-layout/flex";
import * as i5 from "@angular/flex-layout/core";
import * as i6 from "../../../../../../../node_modules/primeng/components/toolbar/toolbar.ngfactory";
import * as i7 from "primeng/components/toolbar/toolbar";
import * as i8 from "../../generic-toolbar/generic-toolbar.component.ngfactory";
import * as i9 from "../../generic-toolbar/generic-toolbar.component";
import * as i10 from "../../generic-toolbar/services/toolbar-item-service-factory.service";
import * as i11 from "../../../services/elements-stack.service";
import * as i12 from "../../../services/entity-data-store.service";
import * as i13 from "../../generic-toolbar/services/check/toolbar-item-disabled-check-factory.service";
import * as i14 from "../../generic-toolbar/services/check/toolbar-item-check.service";
import * as i15 from "../../../../../core/job-runner/job-container.service";
import * as i16 from "../../generic-toolbar/services/color/toolbar-item-coloring-factory.service";
import * as i17 from "@angular/common";
import * as i18 from "../../../../dynamic-table/dynamic-table.component.ngfactory";
import * as i19 from "../../../../dynamic-table/dynamic-table.component";
import * as i20 from "../../../../../core/executor/executor.service";
import * as i21 from "../../../../../core/executor/service/executor-actions/executor-actions.service";
import * as i22 from "../../../services/generic/generic-element-validation-execution-steps-factory";
import * as i23 from "../../../../../core/executor/factory/execution-step-factory.service";
import * as i24 from "./sql-table.component";
import * as i25 from "../../../services/component-highlight-stack.service";
import * as i26 from "../../../services/modules-state.service";
import * as i27 from "../../../../services/generic-crud.service";
import * as i28 from "../../../../validators/services/entity-validator";
import * as i29 from "../../../../../core/service/user-session.service";
import * as i30 from "../../../../services/layout-service";
import * as i31 from "../../../../services/permission/permission.service";
import * as i32 from "@ngx-translate/core";
var styles_SqlTableComponent = [i0.styles];
var RenderType_SqlTableComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_SqlTableComponent, data: {} });
export { RenderType_SqlTableComponent as RenderType_SqlTableComponent };
function View_SqlTableComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-generic-loading", [], null, null, null, i2.View_GenericLoadingComponent_0, i2.RenderType_GenericLoadingComponent)), i1.ɵdid(1, 114688, null, 0, i3.GenericLoadingComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
function View_SqlTableComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [["fxFlex", "none"]], null, null, null, null, null)), i1.ɵdid(1, 671744, null, 0, i4.DefaultFlexDirective, [i1.ElementRef, i5.StyleUtils, i5.LAYOUT_CONFIG, i4.FlexStyleBuilder, i5.MediaMarshaller], { fxFlex: [0, "fxFlex"] }, null), (_l()(), i1.ɵeld(2, 0, null, null, 3, "p-toolbar", [["styleClass", "no-round-corner"]], null, null, null, i6.View_Toolbar_0, i6.RenderType_Toolbar)), i1.ɵdid(3, 49152, null, 0, i7.Toolbar, [i1.ElementRef], { styleClass: [0, "styleClass"] }, null), (_l()(), i1.ɵeld(4, 16777216, null, 0, 1, "app-generic-toolbar", [], null, null, null, i8.View_GenericToolbarComponent_0, i8.RenderType_GenericToolbarComponent)), i1.ɵdid(5, 245760, null, 0, i9.GenericToolbarComponent, [i10.ToolbarItemServiceFactory, i1.ViewContainerRef, i11.ElementsStackService, i12.EntityDataStoreService, i13.ToolbarItemDisabledCheckFactoryService, i14.ToolbarItemCheckService, i15.JobContainerService, i1.ChangeDetectorRef, i16.ToolbarItemColoringFactoryService], { items: [0, "items"], params: [1, "params"], additionalItems: [2, "additionalItems"], isDisabled: [3, "isDisabled"], entity: [4, "entity"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "none"; _ck(_v, 1, 0, currVal_0); var currVal_1 = "no-round-corner"; _ck(_v, 3, 0, currVal_1); var currVal_2 = _co.element.topToolbarItems; var currVal_3 = _co.getToolbarItemsExtraParams(); var currVal_4 = _co.element.additionalToolbarItems; var currVal_5 = _co.isToolbarDisabled; var currVal_6 = _co.entity; _ck(_v, 5, 0, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); }, null); }
function View_SqlTableComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [["class", "text-red"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var currVal_0 = _v.context.$implicit; _ck(_v, 1, 0, currVal_0); }); }
function View_SqlTableComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 5, "div", [["class", "error-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 4, "div", [["class", "error-text"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 3, "div", [["class", "error-message"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 2, "b", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SqlTableComponent_5)), i1.ɵdid(6, 278528, null, 0, i17.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.errorMessages; _ck(_v, 6, 0, currVal_0); }, null); }
function View_SqlTableComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "grid-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "shared-dynamic-table", [], null, [["window", "keyup"]], function (_v, en, $event) { var ad = true; if (("window:keyup" === en)) {
        var pd_0 = (i1.ɵnov(_v, 2).onKeyUp($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i18.View_DynamicTableComponent_0, i18.RenderType_DynamicTableComponent)), i1.ɵdid(2, 245760, [[1, 4], ["table", 4]], 0, i19.DynamicTableComponent, [i1.ElementRef, i1.ChangeDetectorRef], { selectedEntity: [0, "selectedEntity"], columns: [1, "columns"], rows: [2, "rows"], entities: [3, "entities"], totalCount: [4, "totalCount"], rowsCount: [5, "rowsCount"], paginator: [6, "paginator"], dataKey: [7, "dataKey"], showFilters: [8, "showFilters"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.selectedEntity; var currVal_1 = _co.columns; var currVal_2 = i1.ɵEMPTY_ARRAY; var currVal_3 = _co.entities; var currVal_4 = _co.totalCount; var currVal_5 = 100; var currVal_6 = false; var currVal_7 = _co.dataKey; var currVal_8 = false; _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8); }, null); }
function View_SqlTableComponent_7(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [["fxFlex", "none"]], null, null, null, null, null)), i1.ɵdid(1, 671744, null, 0, i4.DefaultFlexDirective, [i1.ElementRef, i5.StyleUtils, i5.LAYOUT_CONFIG, i4.FlexStyleBuilder, i5.MediaMarshaller], { fxFlex: [0, "fxFlex"] }, null), (_l()(), i1.ɵeld(2, 0, null, null, 3, "p-toolbar", [["styleClass", "no-round-corner"]], null, null, null, i6.View_Toolbar_0, i6.RenderType_Toolbar)), i1.ɵdid(3, 49152, null, 0, i7.Toolbar, [i1.ElementRef], { styleClass: [0, "styleClass"] }, null), (_l()(), i1.ɵeld(4, 16777216, null, 0, 1, "app-generic-toolbar", [], null, null, null, i8.View_GenericToolbarComponent_0, i8.RenderType_GenericToolbarComponent)), i1.ɵdid(5, 245760, null, 0, i9.GenericToolbarComponent, [i10.ToolbarItemServiceFactory, i1.ViewContainerRef, i11.ElementsStackService, i12.EntityDataStoreService, i13.ToolbarItemDisabledCheckFactoryService, i14.ToolbarItemCheckService, i15.JobContainerService, i1.ChangeDetectorRef, i16.ToolbarItemColoringFactoryService], { items: [0, "items"], params: [1, "params"], isDisabled: [2, "isDisabled"], entity: [3, "entity"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "none"; _ck(_v, 1, 0, currVal_0); var currVal_1 = "no-round-corner"; _ck(_v, 3, 0, currVal_1); var currVal_2 = _co.element.statusBarItems; var currVal_3 = _co.getToolbarItemsExtraParams(); var currVal_4 = _co.isToolbarDisabled; var currVal_5 = _co.entity; _ck(_v, 5, 0, currVal_2, currVal_3, currVal_4, currVal_5); }, null); }
function View_SqlTableComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 10, "div", [["class", "grid-container"], ["fxLayout", "column"], ["fxLayoutAlign", "start stretch"]], null, null, null, null, null)), i1.ɵdid(1, 671744, null, 0, i4.DefaultLayoutDirective, [i1.ElementRef, i5.StyleUtils, [2, i4.LayoutStyleBuilder], i5.MediaMarshaller], { fxLayout: [0, "fxLayout"] }, null), i1.ɵdid(2, 671744, null, 0, i4.DefaultLayoutAlignDirective, [i1.ElementRef, i5.StyleUtils, [2, i4.LayoutAlignStyleBuilder], i5.MediaMarshaller], { fxLayoutAlign: [0, "fxLayoutAlign"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SqlTableComponent_3)), i1.ɵdid(4, 16384, null, 0, i17.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SqlTableComponent_4)), i1.ɵdid(6, 16384, null, 0, i17.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SqlTableComponent_6)), i1.ɵdid(8, 16384, null, 0, i17.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SqlTableComponent_7)), i1.ɵdid(10, 16384, null, 0, i17.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "column"; _ck(_v, 1, 0, currVal_0); var currVal_1 = "start stretch"; _ck(_v, 2, 0, currVal_1); var currVal_2 = ((_co.element && _co.element.topToolbarItems) && (_co.element.topToolbarItems.length > 0)); _ck(_v, 4, 0, currVal_2); var currVal_3 = (_co.errorMessages.length > 0); _ck(_v, 6, 0, currVal_3); var currVal_4 = (_co.errorMessages.length === 0); _ck(_v, 8, 0, currVal_4); var currVal_5 = ((_co.element && _co.element.statusBarItems) && (_co.element.statusBarItems.length > 0)); _ck(_v, 10, 0, currVal_5); }, null); }
export function View_SqlTableComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(671088640, 1, { table: 0 }), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SqlTableComponent_1)), i1.ɵdid(2, 16384, null, 0, i17.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SqlTableComponent_2)), i1.ɵdid(4, 16384, null, 0, i17.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.isLoadingData; _ck(_v, 2, 0, currVal_0); var currVal_1 = !_co.isLoadingData; _ck(_v, 4, 0, currVal_1); }, null); }
export function View_SqlTableComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 16777216, null, null, 3, "app-custom-sql-table", [], null, [[null, "mouseover"], [null, "mouseleave"]], function (_v, en, $event) { var ad = true; if (("mouseover" === en)) {
        var pd_0 = (i1.ɵnov(_v, 3).onMouseOver($event) !== false);
        ad = (pd_0 && ad);
    } if (("mouseleave" === en)) {
        var pd_1 = (i1.ɵnov(_v, 3).onMouseLeave($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, View_SqlTableComponent_0, RenderType_SqlTableComponent)), i1.ɵprd(512, null, i20.ExecutorService, i20.ExecutorService, [i21.ExecutorActionsService]), i1.ɵprd(512, null, i22.GenericElementValidationExecutionStepsFactory, i22.GenericElementValidationExecutionStepsFactory, [i23.ExecutionStepFactoryService]), i1.ɵdid(3, 245760, null, 0, i24.SqlTableComponent, [i1.ElementRef, i21.ExecutorActionsService, i25.ComponentService, i1.ViewContainerRef, i26.ModulesStateService, i27.GenericCrudService, i12.EntityDataStoreService, i20.ExecutorService, i22.GenericElementValidationExecutionStepsFactory, i28.EntityValidator, i29.UserSessionService, i14.ToolbarItemCheckService, i15.JobContainerService, i23.ExecutionStepFactoryService, i30.LayoutService, i31.PermissionService, i1.ChangeDetectorRef, i32.TranslateService], null, null)], function (_ck, _v) { _ck(_v, 3, 0); }, null); }
var SqlTableComponentNgFactory = i1.ɵccf("app-custom-sql-table", i24.SqlTableComponent, View_SqlTableComponent_Host_0, { masterElementContext: "masterElementContext", moduleElement: "moduleElement", element: "element", parentComponent: "parentComponent", isDialog: "isDialog", isPart: "isPart", toolbarItems: "toolbarItems", statusBarItems: "statusBarItems", selectedMasterEntity: "selectedMasterEntity", additional: "additional", fields: "fields", masterEntity: "masterEntity", masterField: "masterField", entity: "entity" }, { componentInstantiated: "componentInstantiated" }, []);
export { SqlTableComponentNgFactory as SqlTableComponentNgFactory };
