<p-dialog *ngIf="isDialogVisible"
    [(visible)]="isDialogVisible"
    [maximizable]="false"
    [responsive]="false"
    [resizable]="false"
    [closable]="false"
    [modal]="true"
    [minWidth]="400"
    [baseZIndex]="10000"
    width="800"
    height="auto"
    showEffect="fade"
    appendTo="body"
    header="Compass-Suche"
    #dialog
>
  <div class="dialog-close-button-container ui-helper-clearfix ng-tns-c8-6">
    <a (click)="onDialogMaximize($event)" class="ng-tns-c8-6 ui-dialog-titlebar-close ui-corner-all">
      <span class="fa fa-fw fa-window-maximize"></span>
    </a>
    <a (click)="onCloseDialog($event)" class="ng-tns-c8-6 ui-dialog-titlebar-close ui-corner-all">
      <span class="dialog-close-button fa fa-fw fa-close"></span>
    </a>
  </div>
    <company-search [gridComponent]="gridComponent" [customerImportEndpoint]="customerImportEndpoint"></company-search>
</p-dialog>
