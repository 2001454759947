import {FieldMetadataGrid} from 'app/shared/services/module/module-element-field-metadata-grid';
import {GenericTreeGridComponent} from 'app/shared/content-renderer/elements/generic-tree-grid/generic-tree-grid.component';
import {AbstractGenericGridComponent} from 'app/shared/content-renderer/elements/abstract-generic-grid.component';
import {GenericCrudService} from 'app/shared/services/generic-crud.service';
import {DoubleClickService} from '../../double-click.service';
import {EntityHydrator} from '../../../../services/entity-hydrator.service';
import {GenericElementInlineEditorService} from '../generic-element-inline-editor.service';
import {EventHelper} from '../../../../helpers/event.helper';
import {GenericDialogModuleService} from '../../../elements/generic-dialog/service/generic-dialog-module.service';
import {Entity} from "../../../../helpers/entity";
import {Observable} from 'rxjs';

export abstract class AbstractInlineEditor {

  protected component: AbstractGenericGridComponent | null;
  protected column: any;
  protected entity: any;
  protected field: FieldMetadataGrid;
  protected genericCrudService: GenericCrudService;
  protected doubleClickService: DoubleClickService;
  protected genericDialogModuleService: GenericDialogModuleService;
  protected inlineEditorService: GenericElementInlineEditorService;

  public abstract onEdit(entity: any, event: any);

  public abstract onBlur(entity: any, event: any);

  public abstract onFocus(entity: any, event: any): Observable<any>;

  public getValue(entity: any): any|null {
    return Entity.getValue(entity, this.getEntityColumnProperty()) ||
      Entity.getValueInEmbedded(entity, this.getEntityColumnProperty());
  }

  public isEditDisabled(entity: any, column: any): boolean {
    return (
      (entity.deletedAt
      || entity.tmpIsDeleted
      || column.field.isReadOnly
      || (column.field.isReadOnlyByEntity && column.field.isReadOnlyByEntity[entity.id])))
      && !column.field.isForceUnlock;
  }

  public onBeforeEdit(entity: any, event: any): void {
    if (EventHelper.isEnterPressed(event)) {
      this.inlineEditorService.openNextEntitySameEditableColumnCellEditor();
    }
  }

  public setComponent(component: AbstractGenericGridComponent): this {
    this.component = component;
    return this;
  }

  public setEntity(entity: any): this {
    this.entity = entity;
    return this;
  }

  public setColumn(column: any): this {
    this.column = column;
    this.field = this.column.field;
    return this;
  }

  public setGenericCrudService(genericCrudService: GenericCrudService): this {
    this.genericCrudService = genericCrudService;
    return this;
  }

  public setDoubleClickService(doubleClickService: DoubleClickService): this {
    this.doubleClickService = doubleClickService;
    return this;
  }

  public setGenericDialogModuleService(genericDialogModuleService: GenericDialogModuleService): this {
    this.genericDialogModuleService = genericDialogModuleService;
    return this;
  }

  public setInlineEditorService(inlineEditorService: GenericElementInlineEditorService): this {
    this.inlineEditorService = inlineEditorService;
    return this;
  }

  protected getEntityColumnProperty(): string {
    return this.column.property;
  }

  protected changeEntityColumnProperty(value) {
    Entity.setValue(this.entity, this.getEntityColumnProperty(), value);

    if (this.entity._embedded && this.entity._embedded[this.getEntityColumnProperty()]) {
      this.entity._embedded[this.getEntityColumnProperty()] = value;
    }
  }
}
