import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';

import { environment } from './../../environments/environment';

@NgModule({
  imports: [CommonModule],
  declarations: [],
  providers: [{ provide: 'environment', useValue: environment }],
  exports: [],
  entryComponents: []
})
export class ServiceModule {
  static forRoot(): ModuleWithProviders {
    return {
      ngModule: ServiceModule,
      providers: [{ provide: 'environment', useValue: environment }]
    };
  }
}
