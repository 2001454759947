/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../../../../../../node_modules/primeng/components/listbox/listbox.ngfactory";
import * as i2 from "@angular/forms";
import * as i3 from "primeng/components/listbox/listbox";
import * as i4 from "./dialog-error.component";
import * as i5 from "primeng/components/dynamicdialog/dynamicdialog-ref";
import * as i6 from "primeng/components/dynamicdialog/dynamicdialog-config";
var styles_DialogErrorComponent = [];
var RenderType_DialogErrorComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_DialogErrorComponent, data: {} });
export { RenderType_DialogErrorComponent as RenderType_DialogErrorComponent };
export function View_DialogErrorComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 5, "p-listbox", [], null, null, null, i1.View_Listbox_0, i1.RenderType_Listbox)), i0.ɵprd(5120, null, i2.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i3.Listbox]), i0.ɵdid(2, 1097728, null, 3, i3.Listbox, [i0.ElementRef, i0.ChangeDetectorRef], { options: [0, "options"] }, null), i0.ɵqud(603979776, 1, { headerFacet: 0 }), i0.ɵqud(603979776, 2, { footerFacet: 0 }), i0.ɵqud(603979776, 3, { templates: 1 })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.errorOptions; _ck(_v, 2, 0, currVal_0); }, null); }
export function View_DialogErrorComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-dialog-error", [], null, null, null, View_DialogErrorComponent_0, RenderType_DialogErrorComponent)), i0.ɵdid(1, 114688, null, 0, i4.DialogErrorComponent, [i5.DynamicDialogRef, i6.DynamicDialogConfig], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var DialogErrorComponentNgFactory = i0.ɵccf("app-dialog-error", i4.DialogErrorComponent, View_DialogErrorComponent_Host_0, {}, {}, []);
export { DialogErrorComponentNgFactory as DialogErrorComponentNgFactory };
