/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./toolbar-item-salary-type-create-articles-from-selection.component";
import * as i2 from "../../../../../../../core/message/message-growl.service";
import * as i3 from "../../../../../../services/generic-crud.service";
import * as i4 from "../../../../../services/modules-state.service";
import * as i5 from "../../../../generic-dialog/service/generic-dialog-module.service";
var styles_ToolbarItemSalaryTypeCreateArticlesFromSelectionComponent = [];
var RenderType_ToolbarItemSalaryTypeCreateArticlesFromSelectionComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_ToolbarItemSalaryTypeCreateArticlesFromSelectionComponent, data: {} });
export { RenderType_ToolbarItemSalaryTypeCreateArticlesFromSelectionComponent as RenderType_ToolbarItemSalaryTypeCreateArticlesFromSelectionComponent };
export function View_ToolbarItemSalaryTypeCreateArticlesFromSelectionComponent_0(_l) { return i0.ɵvid(2, [], null, null); }
export function View_ToolbarItemSalaryTypeCreateArticlesFromSelectionComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-toolbar-item-salary-type-create-articles-from-selection", [], null, null, null, View_ToolbarItemSalaryTypeCreateArticlesFromSelectionComponent_0, RenderType_ToolbarItemSalaryTypeCreateArticlesFromSelectionComponent)), i0.ɵdid(1, 245760, null, 0, i1.ToolbarItemSalaryTypeCreateArticlesFromSelectionComponent, [i2.MessageGrowlService, i3.GenericCrudService, i4.ModulesStateService, i5.GenericDialogModuleService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ToolbarItemSalaryTypeCreateArticlesFromSelectionComponentNgFactory = i0.ɵccf("app-toolbar-item-salary-type-create-articles-from-selection", i1.ToolbarItemSalaryTypeCreateArticlesFromSelectionComponent, View_ToolbarItemSalaryTypeCreateArticlesFromSelectionComponent_Host_0, { params: "params" }, {}, []);
export { ToolbarItemSalaryTypeCreateArticlesFromSelectionComponentNgFactory as ToolbarItemSalaryTypeCreateArticlesFromSelectionComponentNgFactory };
