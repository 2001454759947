import {AbstractInlineEditor} from './abstract-inline-editor';

export abstract class AbstractAssociatedInlineEditor extends AbstractInlineEditor {

  protected getAssociatedOptions(): any[] {
    let options = [];
    const componentAssociatedOptions = this.component.associatedOptions;

    if (componentAssociatedOptions && componentAssociatedOptions[this.column.entityName]) {
      options = componentAssociatedOptions[this.column.entityName];
    }

    return options;
  }

  protected getEntityFromAssociatedOptions(id: number, isAutocomplete: boolean = false): any | null {
    const options = this.getAssociatedOptions();

    const found = options.find((option: any) => {
      let optionValue = option.value;

      if (isAutocomplete) {
        optionValue = option.id;
      }

      return optionValue === id;
    });

    if (!found) {
      throw new Error(`Entity with ID ${id} not found in grid associated options`);
    }

    if (isAutocomplete) {
      return found ? found : null;
    }

    return found ? found.entity : null;
  }

  protected getEntityColumnProperty(): string {
    return this.column.id.split('.')[0];
  }
}
