import {
  Component,
  Input,
  OnInit,
  OnDestroy,
  ElementRef,
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef, ViewChild
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ElementInputComponent } from './element-input.component';
import { ElementInputFileMultiUpload } from '../models/element-input-file-multi-upload';
import { FormService } from '../form.service';
import { FormViewerService } from '../form-viewer.service';
import { GenericCrudService } from '../../services/generic-crud.service';
import {DataModel} from '../../services/element/data-model';
import {EntityFqnHelper} from '../../helpers/entity-fqn.helper';
import {ChangeDetectorRefHelper} from '../../helpers/change-detector-ref.helper';
import {MultiUploadComponent} from '../../components/upload/multi-upload/multi-upload.component';
import {File} from '../../models/file';

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'app-form-element-input-file-multi-upload',
    template: `
      <app-multi-upload
        #upload
        [mode]="'advanced'"
        [disabled]="disabled"
        [filePath]="element.filePath"
        [fileContext]="element.fileContext"
        (fileSelect)="onFileSelect($event)"
        (uploadSuccess)="onUploadSuccess($event)"
        [tabIndex]="element.tabIndex"
      ></app-multi-upload>
    `,
    styles: [`
        :host {
          height: 100%;
          width: 100%;
        }
    `]
})
export class ElementInputFileMultiUploadComponent extends ElementInputComponent implements OnInit, OnDestroy, AfterViewInit {
    @Input() element: ElementInputFileMultiUpload;
    @ViewChild('upload', {static: false}) upload: MultiUploadComponent;

    public fileClassName: any;

    public constructor(
        protected formService: FormService,
        public cdr: ChangeDetectorRef,
        protected formViewerService: FormViewerService,
        protected genericCrudService: GenericCrudService,
        protected elementRef: ElementRef,
        protected translateService?: TranslateService
    ) {
        super(formService, cdr, formViewerService, translateService);
    }

    get disabled() {
      return !this.entity || !this.fileClassName || this.isReadOnlyOrDisabled();
    }

  public onComponentChanges(): void {
    this.setupValue();
  }

  public onComponentInit(): void {
      this.setFormControlName();
      this.filterActionAndHandleIt('oninit');
  }

  public ngAfterViewInit() { }

  public onFileSelect(file: any) {
    this.filterActionAndHandleIt('onSelect', {
      file
    });
  }

  public onUploadSuccess(files: File[]): void {
      for (const file of files) {
        this.filterActionAndHandleIt('onUploadSuccess', {
          file
        });
      }

    this.filterActionAndHandleIt('onMultiUploadSuccess', {
      files
    });
  }

  public setupValue(): void {
    if (this.element.datamodelId) {
      this.genericCrudService.getEntity(`superadmin/datamodels`, this.element.datamodelId)
        .subscribe((datamodel: DataModel) => {
          const fileClassName = EntityFqnHelper.getEntityFqn(datamodel);

          this.fileClassName = fileClassName;
          if (this.upload) {
            this.upload.fileEntity = this.entity;
            this.upload.fileClassName = fileClassName;
          }

          if (this.upload && this.upload.fileEntity && this.upload.fileClassName) {
            this.upload.loadFiles().subscribe();
          }

          ChangeDetectorRefHelper.detectChanges(this);
        });
    }
  }
}
