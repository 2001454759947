 <ng-container *ngIf="memoType === null && configuration.memoTypeDefinedOutside">
  <div class="error-container">
    <div class="error-text">
      <div class="error-message">
        <b><span class="text-red">ERROR</span></b>
      </div>
      <div class="error-detail">
        <span>Memo Type is not defined!</span>
      </div>
    </div>
  </div>
</ng-container>

<app-generic-loading *ngIf="isLoadingData"></app-generic-loading>

<div class="ui-g" *ngIf="!isLoadingData">
  <div class="ui-g-12 ui-mg-d6 ui-lg-12 toolbar-container">
    <p-header *ngIf="element.topToolbarItems && element.topToolbarItems.length > 0" class="header">
      <app-generic-toolbar [params]="getToolbarExtraParams()" [entity]="memo" [items]="element.topToolbarItems" [additionalItems]="element.additionalToolbarItems"></app-generic-toolbar>
    </p-header>
  </div>
</div>

<div class="main-container ui-g" *ngIf="!isLoadingData" [style.border-left]="getMemoBorderLeftColor()">

  <div class="ui-g-12" *ngIf="configuration.search">
    <div class="ui-g-12">
      <p-radioButton name="search" value="all" label="{{ 'COMMON.ALL' | translate }}" (onClick)="onSearchChange($event)" [(ngModel)]="selectedSearch"></p-radioButton>
      <p-radioButton [style.marginLeft.px]="10" name="search" value="type" label="{{ 'COMMON.TYPE' | translate }}" (onClick)="onSearchChange($event)" [(ngModel)]="selectedSearch"></p-radioButton>
    </div>
    <div class="ui-g-12">
      <app-custom-memo-type-dropdown
        *ngIf="configuration.memoTypeApi && selectedSearch === 'type'"
        [api]="configuration.memoTypeApi"
        [memoType]="memoType"
        [apiQueryParams]="{isVisible:1}"
        (memoTypeChanged)="onMemoTypeChanged($event)"
      ></app-custom-memo-type-dropdown>
    </div>
    <div class="ui-g-12">
      <button
        (click)="onSearch()"
        pButton
        label="{{ 'COMMON.SEARCH' | translate }}"
        class="common-button"
        icon="fa fa-search"
      ></button>
    </div>
  </div>

  <div class="ui-g-12">
    <app-custom-memo-type-dropdown
      *ngIf="configuration.memoTypeApi && !configuration.memoTypeDefinedOutside && !configuration.search"
      [api]="configuration.memoTypeApi"
      [memoType]="memoType"
      [apiQueryParams]="{isVisible:1}"
      (memoTypeChanged)="onMemoTypeChanged($event)"
    ></app-custom-memo-type-dropdown>

    <div class="ui-g-12" *ngIf="configuration.memoTypeApi && !configuration.memoTypeDefinedOutside && !configuration.search && memo">
      <div class="ui-g-2">
        {{ 'COMMON.NAME' | translate }}:
      </div>
      <div class="ui-g-10">
        <input
          [(ngModel)]="this.memo.name"
          (keyup)="onMemoNameChanged($event)"
          type="text"
          [readonly]="isReadOnly()"
          pInputText
        />
      </div>
    </div>

    <p-fieldset *ngIf="visibleMemoTypeFieldDefinitions && visibleMemoTypeFieldDefinitions.length > 0" legend="Zusatzfelder" class="fieldset">
      <ng-template ngFor let-memoTypeFieldDefinition [ngForOf]="visibleMemoTypeFieldDefinitions">
        <div *ngIf="memoTypeFieldDefinition.header" class="ui-g-12">
         <div class="ui-g-4"></div>
          <div class="ui-g-8">
            <strong>{{ memoTypeFieldDefinition.header }}</strong>
          </div>
        </div>
        <div class="label ui-g-12 ui-md-6 ui-lg-4">
          {{ memoTypeFieldDefinition.name }}:
        </div>
        <div class="ui-g-12 ui-md-6 ui-lg-8" [ngSwitch]="memoTypeFieldDefinition.fieldDefinitionTemplate?.dataType?.code">

          <ng-template ngSwitchCase="dropdown">
          <span *ngIf="!memoTypeFieldDefinition.fieldDefinitionTemplate.lookupFetchDatamodel">
            No datamodel lookupFetchDataModel assigned...
          </span>

            <p-dropdown
              *ngIf="memoTypeFieldDefinition.fieldDefinitionTemplate.lookupFetchDatamodel"
              [options]="lookupFetchDatamodelOptions[memoTypeFieldDefinition.fieldDefinitionTemplate.id]"
              placeholder="Select entity"
              [ngModel]="getFieldDefinitionValue(memoTypeFieldDefinition)"
              [showClear]="true"
              (onChange)="onFieldDefinitionValueEdit($event, memoTypeFieldDefinition)"
              [style]="{'width':'100%'}"
              [readonly]="!!memoTypeFieldDefinition.isReadonly"
              [disabled]="!!memoTypeFieldDefinition.isReadonly"
            ></p-dropdown>
          </ng-template>
          <ng-template ngSwitchCase="dateTime">
            <app-generic-calendar
              (onInputChangeValid)="onFieldDefinitionValueEdit($event, memoTypeFieldDefinition)"
              (onSelect)="onFieldDefinitionValueEdit($event, memoTypeFieldDefinition)"
              [value]="getFieldDefinitionValue(memoTypeFieldDefinition)"
              [yearRange]="'1950:2050'"
              [mask]="'99.99.9999 99:99'"
              [dateFormat]="'dd.mm.yy'"
              [showTime]="true"
              [style]="{'width':'100%'}"
              [disabled]="!!memoTypeFieldDefinition.isReadonly"
            ></app-generic-calendar>
          </ng-template>
          <ng-template ngSwitchCase="date">
            <app-generic-calendar
              (onInputChangeValid)="onFieldDefinitionValueEdit($event, memoTypeFieldDefinition)"
              (onSelect)="onFieldDefinitionValueEdit($event, memoTypeFieldDefinition)"
              [value]="getFieldDefinitionValue(memoTypeFieldDefinition)"
              [yearRange]="'1950:2050'"
              [mask]="'99.99.9999'"
              [dateFormat]="'dd.mm.yy'"
              [showTime]="false"
              [style]="{'width':'100%'}"
              [disabled]="!!memoTypeFieldDefinition.isReadonly"
            ></app-generic-calendar>
          </ng-template>
          <ng-template ngSwitchCase="dateFrom">
            <app-generic-calendar
              (onInputChangeValid)="onFieldDefinitionValueEdit($event, memoTypeFieldDefinition)"
              (onSelect)="onFieldDefinitionValueEdit($event, memoTypeFieldDefinition)"
              [value]="getFieldDefinitionValue(memoTypeFieldDefinition)"
              [yearRange]="'1950:2050'"
              [mask]="'99.99.9999'"
              [dateFormat]="'dd.mm.yy'"
              [showTime]="false"
              [style]="{'width':'100%'}"
              [disabled]="!!memoTypeFieldDefinition.isReadonly"
            ></app-generic-calendar>
          </ng-template>
          <ng-template ngSwitchCase="dateTo">
            <app-generic-calendar
              (onInputChangeValid)="onFieldDefinitionValueEdit($event, memoTypeFieldDefinition)"
              (onSelect)="onFieldDefinitionValueEdit($event, memoTypeFieldDefinition)"
              [value]="getFieldDefinitionValue(memoTypeFieldDefinition)"
              [yearRange]="'1950:2050'"
              [mask]="'99.99.9999'"
              [dateFormat]="'dd.mm.yy'"
              [showTime]="false"
              [style]="{'width':'100%'}"
              [disabled]="!!memoTypeFieldDefinition.isReadonly"
            ></app-generic-calendar>
          </ng-template>
          <ng-template ngSwitchCase="checkbox">
            <p-checkbox
              [ngModel]="getFieldDefinitionValue(memoTypeFieldDefinition)"
              (onChange)="onFieldDefinitionValueEdit($event, memoTypeFieldDefinition)"
              [readonly]="!!memoTypeFieldDefinition.isReadonly"
              [disabled]="!!memoTypeFieldDefinition.isReadonly"
              binary="true"
            ></p-checkbox>
          </ng-template>
          <ng-template ngSwitchCase="text">
            <input
              [ngModel]="getFieldDefinitionValue(memoTypeFieldDefinition)"
              (keyup)="onFieldDefinitionValueEdit($event, memoTypeFieldDefinition)"
              [readonly]="!!memoTypeFieldDefinition.isReadonly"
              [disabled]="!!memoTypeFieldDefinition.isReadonly"
              type="text"
              pInputText
            />
          </ng-template>
          <ng-template ngSwitchCase="integer">
            <p-inputNumber
              size="30"
              [useGrouping]="false"
              [ngModel]="getFieldDefinitionValue(memoTypeFieldDefinition)"
              (onInput)="onFieldDefinitionValueEdit($event, memoTypeFieldDefinition)"
              [style]="{'width':'100%'}"
              [disabled]="!!memoTypeFieldDefinition.isReadonly"
            ></p-inputNumber>
          </ng-template>
          <ng-template ngSwitchCase="decimal">
            <p-inputNumber
              [ngModel]="getFieldDefinitionValue(memoTypeFieldDefinition)"
              (onInput)="onFieldDefinitionValueEdit($event, memoTypeFieldDefinition)"
              locale="de-DE"
              mode="decimal"
              [minFractionDigits]="2"
              [style]="{'width':'100%'}"
              [disabled]="!!memoTypeFieldDefinition.isReadonly"
            >
            </p-inputNumber>
          </ng-template>
          <ng-template ngSwitchCase="currency">
            <p-inputNumber
              [ngModel]="getFieldDefinitionValue(memoTypeFieldDefinition)"
              (onInput)="onFieldDefinitionValueEdit($event, memoTypeFieldDefinition)"
              locale="de-DE"
              mode="decimal"
              [minFractionDigits]="2"
              [style]="{'width':'100%'}"
              [disabled]="!!memoTypeFieldDefinition.isReadonly"
            >
            </p-inputNumber>
          </ng-template>
          <ng-template ngSwitchCase="autocomplete">
            <p-autoComplete
              appGenericAutoCompleteHandler
              [dropdown]="true"
              [paginated]="true"
              (onClear)="onClear($event, memoTypeFieldDefinition)"
              [filters]="getAutocompleteFilters(memoTypeFieldDefinition)"
              [value]="getFieldDefinitionValue(memoTypeFieldDefinition)"
              (onSelect)="onFieldDefinitionValueEdit($event, memoTypeFieldDefinition)"
              [style]="{'width':'100%', 'white-space': 'nowrap'}"
              [apiRoute]="memoTypeFieldDefinition.fieldDefinitionTemplate.lookupFetchDatamodel.apiRoute"
              [orderByField]="memoTypeFieldDefinition.lookupFetchDatamodelSortField"
              [apiQueryParams]="{embedded:memoTypeFieldDefinition.embeddedFields ? memoTypeFieldDefinition.embeddedFields : 'none', clause: 'orWhere'}"
              appendTo="body"
              [labelField]="memoTypeFieldDefinition.fieldDefinitionTemplate.lookupFetchDatamodelLabel"
              [searchField]="memoTypeFieldDefinition.fieldDefinitionTemplate.lookupFetchDatamodelSearchField"
              [valueField]="'id'"
              [field]="'label'"
              [readonly]="!!memoTypeFieldDefinition.isReadonly"
              [disabled]="!!memoTypeFieldDefinition.isReadonly"
            ></p-autoComplete>
          </ng-template>

          <ng-template ngSwitchCase="branchOffice">
            <p-autoComplete
              appGenericAutoCompleteHandler
              [dropdown]="true"
              [paginated]="true"
              [value]="getFieldDefinitionValue(memoTypeFieldDefinition)"
              (onSelect)="onFieldDefinitionValueEdit($event, memoTypeFieldDefinition)"
              (onClear)="onClear($event, memoTypeFieldDefinition)"
              [style]="{'width':'100%', 'white-space': 'nowrap'}"
              [apiRoute]="memoTypeFieldDefinition.fieldDefinitionTemplate.lookupFetchDatamodel.apiRoute"
              [orderByField]="memoTypeFieldDefinition.lookupFetchDatamodelSortField"
              [apiQueryParams]="{embedded:'none'}"
              appendTo="body"
              [labelField]="memoTypeFieldDefinition.fieldDefinitionTemplate.lookupFetchDatamodelLabel"
              [searchField]="'search'"
              [valueField]="'id'"
              [field]="'label'"
              [filters]="getFilters()"
              [readonly]="!!memoTypeFieldDefinition.isReadonly"
              [orderByField]="'name'"
            ></p-autoComplete>
          </ng-template>

          <ng-template ngSwitchCase="leasedEmployee">
            <p-autoComplete
              appGenericAutoCompleteHandler
              [dropdown]="true"
              [paginated]="true"
              (onClear)="onClear($event, memoTypeFieldDefinition)"
              [value]="getFieldDefinitionValue(memoTypeFieldDefinition)"
              (onSelect)="onFieldDefinitionValueEdit($event, memoTypeFieldDefinition)"
              [style]="{'width':'100%', 'white-space': 'nowrap'}"
              [apiRoute]="memoTypeFieldDefinition.fieldDefinitionTemplate.lookupFetchDatamodel.apiRoute"
              [orderByField]="memoTypeFieldDefinition.lookupFetchDatamodelSortField"
              [apiQueryParams]="{embedded:'none'}"
              appendTo="body"
              [labelField]="memoTypeFieldDefinition.fieldDefinitionTemplate.lookupFetchDatamodelLabel"
              [searchField]="'search'"
              [valueField]="'id'"
              [field]="'label'"
              [filters]="getFilters()"
              [readonly]="!!memoTypeFieldDefinition.isReadonly"
            ></p-autoComplete>
          </ng-template>

          <ng-template ngSwitchCase="customer">
            <p-autoComplete
              appGenericAutoCompleteHandler
              [dropdown]="true"
              [paginated]="true"
              [value]="getFieldDefinitionValue(memoTypeFieldDefinition)"
              (onSelect)="onFieldDefinitionValueEdit($event, memoTypeFieldDefinition)"
              (onClear)="onClear($event, memoTypeFieldDefinition)"
              [style]="{'width':'100%', 'white-space': 'nowrap'}"
              [apiRoute]="memoTypeFieldDefinition.fieldDefinitionTemplate.lookupFetchDatamodel.apiRoute"
              [orderByField]="memoTypeFieldDefinition.lookupFetchDatamodelSortField"
              [apiQueryParams]="{embedded:'none'}"
              appendTo="body"
              [labelField]="memoTypeFieldDefinition.fieldDefinitionTemplate.lookupFetchDatamodelLabel"
              [searchField]="'search'"
              [valueField]="'id'"
              [field]="'label'"
              [filters]="getFilters()"
              [readonly]="!!memoTypeFieldDefinition.isReadonly"
            ></p-autoComplete>
          </ng-template>

          <ng-template ngSwitchDefault>
            No data type defined for {{memoTypeFieldDefinition.fieldDefinitionTemplate.name}}!
          </ng-template>
        </div>

        <ng-template
          [ngIf]="memoTypeFieldDefinition.readonlyMemoTypeFieldDefinitions && memoTypeFieldDefinition.readonlyMemoTypeFieldDefinitions.length > 0"
        >
          <ng-template ngFor let-readonlyMemoTypeFieldDefinition [ngForOf]="memoTypeFieldDefinition.readonlyMemoTypeFieldDefinitions">
            <div class="label ui-g-4"></div>
            <div class="ui-g-8"
              [style.padding.px]="0"
            >
              <div class="label ui-g-4">
                <strong>{{ readonlyMemoTypeFieldDefinition.name }}:</strong>
              </div>
              <div class="ui-g-8">
                {{ readonlyMemoTypeFieldDefinition.readonlyValue }}
              </div>
            </div>
          </ng-template>
        </ng-template>

      </ng-template>
    </p-fieldset>

    <p-fieldset *ngIf="
    memo &&
    !isLoadingData &&
    memoType &&
    (memoType.doUseCustomerAddress ||
    memoType.doUseCustomerContactPerson ||
    memoType.doUseContactUser ||
    memoType.doUseEmployeeAddress ||
    memoType.doUseContactOffice)
  "
                legend="Sonderfelder"
    >
      <ng-template ngFor let-memoTypeFieldDefinition [ngIf]="memo && !isLoadingData">

        <div *ngIf="memoType.doUseCustomerAddress" class="label ui-g-12 ui-md-6 ui-lg-4">
          Kundenadressen:
        </div>
        <div *ngIf="memoType.doUseCustomerAddress" class="ui-g-12 ui-md-6 ui-lg-8">
          <p-autoComplete
            appAutocomplete
            appendTo="body"
            field="label"
            [dropdown]="true"
            [(ngModel)]="selectedCustomerAddress"
            [suggestions]="customerAddresses"
            (completeMethod)="onMemoAutoCompleteSearch($event, 'phoenix/addresses', 'customerAddresses', 'fullAddress')"
            (onDropdownClick)="onMemoAutoCompleteSearch($event, 'phoenix/addresses', 'customerAddresses', 'fullAddress')"
            (onSelect)="onMemoAutoCompleteChange($event, 'customerAddress')"
          ></p-autoComplete>
        </div>

        <div *ngIf="memoType.doUseCustomerContactPerson" class="label ui-g-12 ui-md-6 ui-lg-4">
          Ansprechpartner Kunde:
        </div>
        <div *ngIf="memoType.doUseCustomerContactPerson" class="ui-g-12 ui-md-6 ui-lg-8">
          <p-autoComplete
            appAutocomplete
            appendTo="body"
            field="label"
            [dropdown]="true"
            [(ngModel)]="selectedContactPerson"
            [suggestions]="contactPersons"
            (completeMethod)="onMemoAutoCompleteSearch($event, 'phoenix/contactpersons', 'contactPersons', 'firstName,lastName')"
            (onDropdownClick)="onMemoAutoCompleteSearch($event, 'phoenix/contactpersons', 'contactPersons', 'firstName,lastName')"
            (onSelect)="onMemoAutoCompleteChange($event, 'contactPerson')"
          ></p-autoComplete>
        </div>

        <div *ngIf="memoType.doUseContactUser" class="label ui-g-12 ui-md-6 ui-lg-4">
          Kontakt Person:
        </div>
        <div *ngIf="memoType.doUseContactUser" class="ui-g-12 ui-md-6 ui-lg-8">
          <p-autoComplete
            appAutocomplete
            appendTo="body"
            field="label"
            [dropdown]="true"
            [(ngModel)]="selectedContactUser"
            [suggestions]="contactUsers"
            (completeMethod)="onMemoAutoCompleteSearch($event, 'app/users', 'contactUsers', 'firstName,lastName')"
            (onDropdownClick)="onMemoAutoCompleteSearch($event, 'app/users', 'contactUsers', 'firstName,lastName')"
            (onSelect)="onMemoAutoCompleteChange($event, 'contactUser')"
          ></p-autoComplete>
        </div>

        <div *ngIf="memoType.doUseEmployeeAddress" class="label ui-g-12 ui-md-6 ui-lg-4">
          Personaladdresse:
        </div>
        <div *ngIf="memoType.doUseEmployeeAddress" class="ui-g-12 ui-md-6 ui-lg-8">
          <p-autoComplete
            appAutocomplete
            appendTo="body"
            field="label"
            [dropdown]="true"
            [(ngModel)]="selectedEmployeeAddress"
            [suggestions]="employeeAddresses"
            (completeMethod)="onMemoAutoCompleteSearch($event, 'phoenix/addresses', 'employeeAddresses', 'fullAddress')"
            (onDropdownClick)="onMemoAutoCompleteSearch($event, 'phoenix/addresses', 'employeeAddresses', 'fullAddress')"
            (onSelect)="onMemoAutoCompleteChange($event, 'employeeAddress')"
          ></p-autoComplete>
        </div>

        <div *ngIf="memoType.doUseContactOffice" class="label ui-g-12 ui-md-6 ui-lg-4">
          Kontakt-Filiale:
        </div>
        <div *ngIf="memoType.doUseContactOffice" class="ui-g-12 ui-md-6 ui-lg-8">
          <p-autoComplete
            appAutocomplete
            appendTo="body"
            field="label"
            [dropdown]="true"
            [(ngModel)]="selectedContactBranchOffice"
            [suggestions]="contactBranchOffices"
            (completeMethod)="onMemoAutoCompleteSearch($event, 'app/branchoffices', 'contactBranchOffices', 'city,street')"
            (onDropdownClick)="onMemoAutoCompleteSearch($event, 'app/branchoffices', 'contactBranchOffices', 'city,street')"
            (onSelect)="onMemoAutoCompleteChange($event, 'contactBranchOffice')"
          ></p-autoComplete>
        </div>

      </ng-template>
    </p-fieldset>
  </div>

</div>
