
import {of as observableOf, Observable} from 'rxjs';
import {ChangeDetectionStrategy, Component, Input, OnInit} from '@angular/core';
import { ToolbarItemAbstract } from '../toolbar-item-abstract.component';
import { ToolbarItem } from '../../../../../../services/element/toolbar-item';
import {GenericCrudService} from '../../../../../../services/generic-crud.service';
import {DoubleClickService} from '../../../../../services/double-click.service';
import {AbstractGenericGridComponent} from '../../../../abstract-generic-grid.component';
import {ModuleElement} from '../../../../../../services/module/module-element';
import {ModuleState} from '../../../../../services/module-state';
import {FormViewerComponent} from '../../../../../../form-viewer/form-viewer.component';
import {GenericElementAbstract} from '../../../../generic-element-abstract.component';
import {ExecutionStatusSuccess} from '../../../../../../../core/executor/execution-status-success';
import {ExecutionStatusError} from '../../../../../../../core/executor/execution-status-error';
import {ComponentValidationExecutionStep} from '../../../../../../services/execution-step/validation/component-validation-execution-step';
import {EntityValidationExecutionStep} from '../../../../../../services/execution-step/validation/entity-validation-execution-step';
import {ModulesStateService} from '../../../../../services/modules-state.service';
import {TranslateService} from '@ngx-translate/core';
import {ExecutionStepBuilderService} from '../../../../../../../core/executor/builder/execution-step-builder.service';
import {MessageService} from '../../../../../../../core/message/message.service';
import {ExecutorService} from '../../../../../../../core/executor/executor.service';
import {ExecutionStatus} from '../../../../../../../core/executor/execution-status';

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'toolbar-item-open-new-offer',
    template: ''
})
export class ToolbarItemOpenNewOrderComponent extends ToolbarItemAbstract {

  constructor(
    protected genericCrudService: GenericCrudService,
    protected modulesStateService: ModulesStateService,
    protected executionStepBuilderService: ExecutionStepBuilderService,
    protected executorService: ExecutorService,
    protected messageService: MessageService,
    protected translateService: TranslateService,
    protected doubleClickService: DoubleClickService
  ) {
    super();
  }

  ngOnInit() {
  }

  public click() {
    const component = this.getComponent();
    const form = component.getElementContext().getMasterElementContext().component;

    if (this.getToolbarItem().actionModuleId && component instanceof AbstractGenericGridComponent && form && form.getSelectedEntity()) {
      this.saveModule().subscribe((status: ExecutionStatus) => {
        if (status.isSuccess()) {
          const entity = form.getSelectedEntity();
          this.genericCrudService.getEntities(`superadmin/modules/${this.getToolbarItem().actionModuleId}`).subscribe((targetModule) => {
            this.genericCrudService.get('phoenix/inquiries/' + entity.id + '/order').subscribe((result) => {
              this.doubleClickService
                .openModuleInNewTabCustom(targetModule, 'orders', 'PhoenixBundle\\Entity\\Order', component.moduleElement, result.id);
            });
          });
        } else {
          this.onExecutorFailure(status);
        }
      });
    }
  }

  private saveModule(): Observable<ExecutionStatusSuccess> {
    const mainModule = this.modulesStateService.getCurrent();

    if (null !== mainModule && this.hasChanges()) {
      const executionSteps = this.executionStepBuilderService.createExecutionSteps(mainModule);

      for (const step of executionSteps) {
        this.executorService.addStep(step);
      }

      return this.executorService.execute();
    }else{
      return observableOf(new ExecutionStatusSuccess({status: true, content: null}, null));
    }
  }

  protected hasChanges(): boolean {
    let hasChange = false,
      moduleState = this.modulesStateService.getCurrent(),
      componentsOnPage = [];

    if (moduleState) {
      componentsOnPage = moduleState.getAllComponents();
    }

    for (let component of componentsOnPage) {
      if (component instanceof AbstractGenericGridComponent) {
        hasChange = this.componentEntityHasBeenChanged(component);
      }

      if (component instanceof FormViewerComponent) {
        hasChange = this.componentEntityHasBeenChanged(component);
      }

      if (hasChange) {
        break;
      }
    }

    return hasChange;
  }

  private componentEntityHasBeenChanged(component: GenericElementAbstract): boolean {
    let entityHasBeenChanged = false;

    // Now a little twist - in case there are really no changes, check if we are in a grid AND there are changes to be saved:
    if (component && component instanceof AbstractGenericGridComponent) {
      entityHasBeenChanged = component.getUpdatedEntities().length > 0 || component.getCreatedEntities().length > 0 || component.getDraftDeletedEntities().length > 0;
    }

    if (component && component instanceof FormViewerComponent) {
      entityHasBeenChanged = component.isEntityDirty();
    }

    return entityHasBeenChanged;
  }

  public onExecutorFailure(status: ExecutionStatusError): void {
    if (status.getStep() instanceof ComponentValidationExecutionStep ||
      status.getStep() instanceof EntityValidationExecutionStep
    ) {
      this.onComponentValidationFailure(status);
    }
  }

  public onComponentValidationFailure(status: ExecutionStatusError): void {
    this.messageService.confirm({
      acceptVisible: true,
      rejectVisible: false,
      header: this.translateService.instant('COMMON.ERROR'),
      message: status.getStepContent(),
      accept: () => { }
    });
  }
}
