import { FormService } from './../../../shared/form-viewer/form.service';
import { ElementButton } from '../../../shared/form-viewer/models/element';
import { Injectable, EventEmitter } from '@angular/core';
import { Observable } from 'rxjs';
import { TreeNode, ConfirmationService } from 'primeng/primeng';
import { Form, Element, ElementLayoutColumns, ElementLayoutColumnsItem } from '../../../shared/form-viewer/models/index';
import { ElementService } from './element.service';

@Injectable()
export class FormEditorService {
  private _form: Form;
  private _selectedElement: Element;
  private _formObservable: Observable<Form>;

  public formChanged: EventEmitter<Form> = new EventEmitter<Form>();
  public draggedElement: Element;
  formTitleChanged: EventEmitter<string> = new EventEmitter<string>();
  elementAdded: EventEmitter<Element> = new EventEmitter<Element>();
  elementRemoved: EventEmitter<Element> = new EventEmitter<Element>();
  childElementRemoved: EventEmitter<Element> = new EventEmitter<Element>();
  selectedElementChanged: EventEmitter<Element> = new EventEmitter<Element>();
  selectedElementRemoved: EventEmitter<Element> = new EventEmitter<Element>();
  selectedElementTitleChanged: EventEmitter<string> = new EventEmitter<string>();
  selectedElementMoveTo: EventEmitter<number> = new EventEmitter<number>();

  datamodelIdChanged: EventEmitter<number> = new EventEmitter<number>();

  constructor(
    private elementService: ElementService,
    private formService: FormService
  ) {
  }

  get formObservable(): Observable<Form> {
    return this._formObservable;
  }
  set formObservable(formObservable: Observable<Form>) {
    this._formObservable = formObservable;
    this._formObservable.subscribe((value) => {
      this.form = value;
    });
  }

  addElement(element: Element, toElement: Element = undefined, toForm: Form = undefined): Form {
    if (!toForm) {
      toForm = this.form;
    }

    if (!toElement) {
      toElement = this.selectedElement;
    }
    // @todo: This is maybe a bit too complex
    if (toElement && !toElement['root']) {
      if (toElement['canHaveChildren']) {
        element.parent = toElement;
        if (toElement['children'].length <= 0) {
          toElement['children'] = [element];
        } else {
          toElement['children'].push(element);
        }
      } else {
        let selectedElementIndex: number;
        if (toElement['parent']) {
          element.parent = toElement['parent'];
          selectedElementIndex = toElement.parent['children'].indexOf(toElement);
          toElement.parent['children'].insert((selectedElementIndex + 1), element);
        } else {
          selectedElementIndex = toForm.elements.indexOf(toElement);
          toForm.elements.insert((selectedElementIndex + 1), element);
        }
      }
    } else {
      toForm.elements.push(element);
    }

    return toForm;
  }

  removeElement(toElement: Element = undefined) {
    if (!toElement) {
      toElement = this.selectedElement;
    }
    let index: number;
    if (toElement.parent) {
      index = toElement.parent['children'].indexOf(toElement);
      this.elementRemoved.next(toElement.parent['children'][index]);
      toElement.parent['children'].splice(index, 1);
      this.selectedElement = toElement.parent['children'][index];
    } else {
      index = this.form.elements.indexOf(toElement);
      this.elementRemoved.next(this.form.elements[index]);
      this.form.elements.splice(index, 1);
      this.selectedElement = this.form.elements[index];
    }
  }

  moveSelectedElement(old_index: number, new_index: number) {
    let arr: Element[];
    if (this.selectedElement.parent) {
      arr = this.selectedElement.parent['children'];
    } else {
      arr = this.form.elements;
    }
    while (old_index < 0) {
      old_index += arr.length;
    }
    while (new_index < 0) {
      new_index += arr.length;
    }
    if (new_index >= arr.length) {
      let k = new_index - arr.length;
      while ((k--) + 1) {
        arr.push(undefined);
      }
    }
    arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);

    this.selectedElementMoveTo.next(new_index);
    this.selectedElementChanged.next(this.selectedElement);
  }

  moveSelectedElementUp() {
    let oldIndex: number;
    if (this.selectedElement.parent) {
      oldIndex = this.selectedElement.parent['children'].indexOf(this.selectedElement);
    } else {
      oldIndex = this.form.elements.indexOf(this.selectedElement);
    }
    this.moveSelectedElement(oldIndex, (oldIndex - 1));
  }

  moveSelectedElementDown() {
    let oldIndex: number;
    if (this.selectedElement.parent) {
      oldIndex = this.selectedElement.parent['children'].indexOf(this.selectedElement);
    } else {
      oldIndex = this._form.elements.indexOf(this.selectedElement);
    }
    this.moveSelectedElement(oldIndex, (oldIndex + 1));
  }

  removeChildElement(index?: number) {
    if (!index) {
      this.selectedElement['children'].pop();

      this.childElementRemoved.next(this.selectedElement);
      this.formChanged.next(this._form);
      this.formService.formChanged.next(this._form);
    }
  }

  dragElement(element) {
    this.draggedElement = element;
    this.selectedElement = element;
  }

  dropElement(element: Element) {
    if (this.draggedElement) {
      this.removeElement(this.draggedElement);
      this.addElement(this.draggedElement, element);
      this.selectedElement = this.draggedElement;
    }
  }

  get form(): Form {
    return this._form;
  }

  set form(value: Form) {
    this._form = value;
    this.formChanged.next(this._form);
    this.formService.formChanged.next(this._form);
  }

  get selectedElement(): Element {
    return this._selectedElement;
  }

  set selectedElement(value: Element) {
    this._selectedElement = value;
    this.selectedElementChanged.next(this._selectedElement);
  }
}
