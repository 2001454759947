import {ChangeDetectionStrategy, Component, Injector, Input, OnInit} from '@angular/core';
import {ToolbarItemOpenModule} from './toolbar-item-open-module.component';
import {MessageGrowlService} from '../../../../../../../core/message/message-growl.service';
import {DoubleClickService} from '../../../../../services/double-click.service';
import {GenericDialogModuleService} from '../../../../generic-dialog/service/generic-dialog-module.service';
import {GenericCrudService} from '../../../../../../services/generic-crud.service';
import {EntityManagerService} from '../../../../../../../core/service/entity-manager/entity-manager.service';
import {JobContainerService} from '../../../../../../../core/job-runner/job-container.service';
import {SetEntityForMemoJob} from '../../../../../../job-runner/job/memo/set-entity-for-memo.job';
import {SetEntityForMemoCondition} from '../../../../../../job-runner/condition/memo/set-entity-for-memo.condition';
import {FormService} from '../../../../../../form-viewer/form.service';
import {ModulesStateService} from '../../../../../services/modules-state.service';
import {LocationService} from '../../../../../../services/location.service';
import {UserSessionService} from '../../../../../../../core/service/user-session.service';
import {AuthenticationService} from '../../../../../../../core/authentication/authentication.service';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'toolbar-item-open-module',
  templateUrl: './toolbar-item-open-module.component.html'
})
export class ToolbarItemOpenMemoModuleComponent extends ToolbarItemOpenModule {

  constructor(
    protected messageGrowlService: MessageGrowlService,
    protected doubleClickService: DoubleClickService,
    protected genericDialogModuleService: GenericDialogModuleService,
    protected genericCrudService: GenericCrudService,
    protected entityManager: EntityManagerService,
    protected jobContainerService: JobContainerService,
    protected injector: Injector
  ) {
    super(messageGrowlService, doubleClickService, genericDialogModuleService, genericCrudService, entityManager);
  }

  ngOnInit() {
  }

  public click() {
    const condition = new SetEntityForMemoCondition();
    if (this.getComponent().getModuleState().getCurrent().getParts()[0].getAllComponents()[0]['selectedEntity'] === null) {
      return this.messageGrowlService.info('You need to create todo !');
    }
    condition.setPayload({component: this.getComponent(), entity: this.getComponent().getModuleState().getCurrent().getParts()[0].getAllComponents()[0]['selectedEntity']});
    const job = new SetEntityForMemoJob(
      this.injector.get(EntityManagerService, null),
      this.injector.get(FormService, null),
      this.injector.get(LocationService),
      this.injector.get(ModulesStateService, null),
      this.genericCrudService,
      this.injector.get(UserSessionService, null),
      this.injector.get(AuthenticationService, null)
    );
    job.setIsImmutable(true);
    this.jobContainerService.registerJob(job, condition);

    super.click();
  }
}
