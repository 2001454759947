<app-generic-loading *ngIf="isDataLoading"></app-generic-loading>


<div *ngIf="!isDataLoading" class="p-grid p-dir-col">
  <div class="p-col">
    <app-search-management-edit-form
      (onSave)="onSave($event)"
      [(expertSearch)]="expertSearch"
    ></app-search-management-edit-form>
  </div>

  <div class="p-col">
    <button class="save-expert-search-button" [disabled]="!isValid()" (click)="onSave($event)" type="button" pButton>{{ 'COMMON.SAVE' | translate }}</button>
  </div>

  <div class="p-col">
    <app-search-management-edit-container-list
      [(expertSearch)]="expertSearch"
    ></app-search-management-edit-container-list>
  </div>
</div>
