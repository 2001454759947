/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../../../../../core/executor/builder/draft-save-builder.service";
import * as i2 from "../../../../../../../core/executor/service/module-state-inspector.service";
import * as i3 from "../../../../../../../core/executor/factory/execution-step-factory.service";
import * as i4 from "../../../../../../../core/executor/executor.service";
import * as i5 from "../../../../../../../core/executor/service/executor-actions/executor-actions.service";
import * as i6 from "./toolbar-item-draft-save.component";
import * as i7 from "../../../../../services/modules-state.service";
import * as i8 from "../../../../../../../core/message/message.service";
import * as i9 from "@ngx-translate/core";
import * as i10 from "../../../../generic-dialog/service/generic-dialog-module.service";
var styles_ToolbarItemDraftSave = [];
var RenderType_ToolbarItemDraftSave = i0.ɵcrt({ encapsulation: 2, styles: styles_ToolbarItemDraftSave, data: {} });
export { RenderType_ToolbarItemDraftSave as RenderType_ToolbarItemDraftSave };
export function View_ToolbarItemDraftSave_0(_l) { return i0.ɵvid(2, [], null, null); }
export function View_ToolbarItemDraftSave_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "toolbar-item-draft-save", [], null, null, null, View_ToolbarItemDraftSave_0, RenderType_ToolbarItemDraftSave)), i0.ɵprd(512, null, i1.DraftSaveBuilderService, i1.DraftSaveBuilderService, [i2.ModuleStateInspectorService, i3.ExecutionStepFactoryService]), i0.ɵprd(512, null, i4.ExecutorService, i4.ExecutorService, [i5.ExecutorActionsService]), i0.ɵdid(3, 245760, null, 0, i6.ToolbarItemDraftSave, [i7.ModulesStateService, i8.MessageService, i9.TranslateService, i1.DraftSaveBuilderService, i4.ExecutorService, i10.GenericDialogModuleService], null, null)], function (_ck, _v) { _ck(_v, 3, 0); }, null); }
var ToolbarItemDraftSaveNgFactory = i0.ɵccf("toolbar-item-draft-save", i6.ToolbarItemDraftSave, View_ToolbarItemDraftSave_Host_0, { params: "params" }, {}, []);
export { ToolbarItemDraftSaveNgFactory as ToolbarItemDraftSaveNgFactory };
