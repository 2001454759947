import {Component, OnInit} from '@angular/core';
import {AbstractGenericGridSimpleSearchFieldComponent} from './abstract-generic-grid-simple-search-field.component';
import {AbstractGridFilter} from '../../../generic-grid/filters/abstract-grid-filter';

@Component({
  selector: 'app-generic-grid-simple-search-field-tricheckbox',
  template: `
    <p-triStateCheckbox
      [(ngModel)]="this.field.value"
    ></p-triStateCheckbox>
  `
})
export class GenericGridSimpleSearchFieldTricheckboxComponent extends AbstractGenericGridSimpleSearchFieldComponent implements OnInit {

  public ngOnInit(): void {
    this.field.comparator = AbstractGridFilter.MATCH_MODE_IN;
  }
}
