<a (click)="onItemClick(item)" *ngIf="!item.isSeparator && item.isCustomMenuItem" [pTooltip]="item.tooltip" [tooltipPosition]="menuPosition == 'right' ? 'left' : 'right'">
  <div class="item-container item-text-container">
    {{item.label}}
  </div>
</a>
<p-dialog *ngIf="isDialogVisible" (onAfterHide)="dialogHide()" appendTo="body" [responsive]="true"
          [(visible)]="isDialogVisible" showEffect="fade" header="Parser"
          [modal]="true" [width]="400" [height]="250">
  <p-fieldset legend="Kennzahlen Berechnung">
  <div class="ui-grid ui-grid-responsive ui-fluid">
    <div class="ui-grid-row">
      <div class="ui-grid-col-4">
        <label for="branchOfficeId">
          BranchOffice: *
        </label>
      </div>
      <div class="ui-grid-col-8">
        <p-dropdown [options]="branchOfficeDropdown" [(ngModel)]="selectedBranchOffice" id="branchOfficeId" filter="filter" [style]="{'width':'100%'}" required></p-dropdown>
      </div>
    </div>
    <div class="ui-grid-row">
      <div class="ui-grid-col-4">
        <label for="previousPeriodId">
          Vorperiode:
        </label>
      </div>
      <div class="ui-grid-col-8">
        <p-dropdown [options]="periodsDropdown" [(ngModel)]="selectedPreviousPeriod" id="previousPeriodId" filter="filter" [style]="{'width':'100%'}"></p-dropdown>
      </div>
    </div>
    <div class="ui-grid-row">
      <div class="ui-grid-col-4">
        <label for="currentPeriodId">
          Aktuelle Periode:
        </label>
      </div>
      <div class="ui-grid-col-8">
        <p-dropdown [options]="periodsDropdown" [(ngModel)]="selectedCurrentPeriod" id="currentPeriodId" filter="filter" [style]="{'width':'100%'}"></p-dropdown>
      </div>
    </div>
    <div class="ui-grid-row">
      <button pButton type="button" (click)="onStart()">Start</button>
    </div>
  </div>
  <div class="ui-grid-row">
    <p-progressSpinner *ngIf="isDataLoading" styleClass="custom-spinner" [style]="{width: '50px', height: '50px'}" strokeWidth="8" fill="#EEEEEE"></p-progressSpinner>
  </div>
  </p-fieldset>
</p-dialog>
