import {HandleGeoapifyAutocompleteChangeActionHandler} from './handle-geoapify-autocomplete-change-action-handler';

export class HandleGeoapifyAutocompleteEntityChangedActionHandler extends HandleGeoapifyAutocompleteChangeActionHandler {

  public handleAction(): void {
    const entity = this.getEntity();

    if (entity) {
      setTimeout(() => {
        this.changeGeoapifyValue(entity);
      }, 10);
    }
  }
}
