import { AbstractInlineEditor } from './abstract-inline-editor';
import {Observable, of} from 'rxjs';

export class DateInlineEditor extends AbstractInlineEditor {

    public onEdit(entity: any, event: any) {

        // typing in calendar event
        if (event && event.target && event.target.value) {
            let value = event.target.value,
                pattern = /(\d{1,2})\.(\d{1,2})\.(\d{2,4})/;

            if (value.indexOf('.') === -1) {
                pattern = /(\d{2})(\d{2})(\d{4})/;
            }

            const parts = value.replace(pattern, '$3-$2-$1').split('-');

            if (parts[0] && parts[0].length === 2) {
              parts[0] = '20' + parts[0];
            }

            if (parts[1] && parts[1].length === 1) {
              parts[1] = '0' + parts[1];
            }

            if (parts[2] && parts[2].length === 1) {
              parts[2] = '0' + parts[2];
            }



            var date = new Date(parts.join('-'));

            if (!isNaN(date.getTime())) {
                this.changeEntityColumnProperty(date);
            }

            if (!value) {
                this.changeEntityColumnProperty(date);
            }
        } else if (event) {
            const offsetDate = (event instanceof Date) ? new Date(event.getTime() - event.getTimezoneOffset() * 60000) : event;
            this.changeEntityColumnProperty(offsetDate);
        }
    }

    public onBlur(entity: any, event: any) {

    }

    public onFocus(entity: any, event: any): Observable<any> {
      return of(null);
    }
}
