
import {of as observableOf, Observable} from 'rxjs';
import {AbstractExecutionStep} from '../../executor/abstract-execution-step';
import {ExecutionStepStatus} from '../../executor/execution-step-status';
import {StyleService} from '../../style.service';
import {UserSessionService} from '../user-session.service';

export class BootstrapDesignExecutionStep extends AbstractExecutionStep {

  public doExecute(): Observable<ExecutionStepStatus> {
    const payloadValue = this.getPayload().getValue(),
      userSession: UserSessionService = payloadValue.userSession,
      styleService: StyleService = payloadValue.styleService;

    const design = userSession.get(StyleService.CURRENT_STORAGE_DESIGN);

    if (design && styleService.design !== design) {
      styleService.designChanged.next({
        design: design,
        cache: true,
        css: userSession.get(StyleService.CURRENT_STORAGE_DESIGN_CSS)
      });
    }

    return observableOf({status: true, content: null});
  }
}
