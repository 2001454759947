import { AbstractInlineEditor } from './abstract-inline-editor';
import {Observable, of} from 'rxjs';

export class ExternalLinkInlineEditor extends AbstractInlineEditor {

    public onEdit(entity: any, event: any) {

    }

    public onBlur(entity: any, event: any) {

    }

    public onFocus(entity: any, event: any): Observable<any> {
        const url = entity[this.getEntityColumnProperty()];
        window.open(url, 'Docuware Document', 'fullscreen=yes');

        return of(null);
    }
}
