
import {of as observableOf, Observable} from 'rxjs';
import {AbstractExecutionStep} from '../../../core/executor/abstract-execution-step';
import {ExecutionStepStatus} from '../../../core/executor/execution-step-status';
import {GenericElementAbstract} from '../../content-renderer/elements/generic-element-abstract.component';
import {AbstractGenericGridComponent} from '../../content-renderer/elements/abstract-generic-grid.component';
import {FormViewerComponent} from '../../form-viewer/form-viewer.component';

export class SetModuleStateAsDetailsExecutionStep extends AbstractExecutionStep {

  public doExecute(): Observable<ExecutionStepStatus> {
    const payload = this.getPayload();

    if (!(payload.getValue() instanceof GenericElementAbstract)) {
      return this.getFailObservable('You need to pass GenericElementAbstract as Payload value!');
    }

    return this.doSet(payload.getValue());
  }

  protected doSet(component: GenericElementAbstract): Observable<ExecutionStepStatus> {
    const moduleState = this.modulesStateService.getCurrent(),
      entity = this.getEntity(component);

    if (!entity) {
      return observableOf({status: false, content: 'No entity to set!'});
    }

    moduleState.entity = entity;
    moduleState.isDetailsView = true;

    return observableOf({status: true, content: null});
  }

  private getEntity(component: GenericElementAbstract): any|null {
    let entity = null;

    if (component instanceof AbstractGenericGridComponent) {
      entity = component.getSelectedEntity();
    } else if (component instanceof FormViewerComponent) {
      entity = component.entity;
    }

    return entity;
  }
}
