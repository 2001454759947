import { ElementInput } from './element';

export class ElementInputNumber extends ElementInput {

  placeholder: string;

  min: number;

  max: number;

  constructor() {
    super();
    this.typeElement = 'number';
    this.defaultValue = null;
    this.placeholder = null;
  }
}
