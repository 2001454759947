import {ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Observable} from 'rxjs/Observable';
import {SelectItem, SelectItemGroup} from 'primeng/api';
import {CronJob} from '../../../../../services/cron/cron-job';
import {Command} from '../../../../../services/command/command';
import {GenericCrudService} from '../../../../../services/generic-crud.service';
import {ChangeDetectorRefHelper} from '../../../../../helpers/change-detector-ref.helper';

const BLACKLIST_COMMANDS: string[] = [
  'cron'
];

@Component({
  selector: 'app-custom-cron-job-command-dropdown',
  templateUrl: './cron-job-command-dropdown.component.html',
  styleUrls: ['./cron-job-command-dropdown.component.scss']
})
export class CronJobCommandDropdownComponent implements OnInit {

  private _cronJob: CronJob = null;
  @Input() public set cronJob(cronJob: CronJob) {
    this._cronJob = cronJob;
    this.setSelectedCommand();
  };
  public get cronJob() {
    return this._cronJob;
  }

  @Output() onCommandChange: EventEmitter<Command> = new EventEmitter();

  public selectedCommand: Command = null;
  public commandsOptions: SelectItemGroup[] = [];

  public constructor(
    private readonly genericCrudService: GenericCrudService,
    private readonly cdr: ChangeDetectorRef
  ) {

  }

  public ngOnInit(): void {
    this.loadCommands()
      .subscribe((commands: Command[] = []) => {
        this.commandsOptions = this.createGroupedOptions(commands);

        this.setSelectedCommand();
      });
  }

  public onCommandDropdownChange(event: {value: Command}) {
    this.onCommandChange.emit(event.value);
  }

  private loadCommands(): Observable<Command[]> {
    return this.genericCrudService.getEntities('app/commands');
  }

  private createGroupedOptions(commands: Command[] = []): SelectItemGroup[] {
    const options = [];

    for (const command of commands) {
      const commandName = command.name,
        namespaceName = Command.getNamespace(command);

      if (BLACKLIST_COMMANDS.includes(namespaceName)) {
        continue;
      }

      const groupIndex = options.findIndex((aItem: SelectItemGroup) => {
        return aItem.label === namespaceName;
      });

      if (groupIndex === -1) {
        const option: SelectItemGroup = {
          label: namespaceName,
          items: [{
            label: commandName,
            value: command
          }]
        };

        options.push(option);
      } else {
        const option: SelectItem = {
          label: commandName,
          value: command
        };

        options[groupIndex].items.push(option);
      }
    }

    return options;
  }

  private setSelectedCommand(): void {
    if (this.cronJob) {
      this.selectedCommand = this.getSelectedCommand(this.cronJob);

      // if we decide to add new options, arguments to the command we have to recreate cronJob with them
      if (this.selectedCommand && this._cronJob && this._cronJob.id) {
        this.recreateCronJobOptionsAndArguments(this.selectedCommand, this._cronJob);
      }
    }

    ChangeDetectorRefHelper.detectChanges(this);
  }

  private getSelectedCommand(cronJob: CronJob): Command {
    let selectedCommand = null;

    for (const option of this.commandsOptions) {
      const optionItems = option.items;

      for (const optionItem of optionItems) {
        if ((optionItem.value.name === cronJob.command)) {
          selectedCommand = optionItem.value;
          break;
        }
      }
    }

    return selectedCommand;
  }

  private recreateCronJobOptionsAndArguments(command: Command, cronJob: CronJob): void {
    const options = this.selectedCommand.definition.options || [];
    const aArguments = this.selectedCommand.definition.arguments || [];

    for (const key in options) {
      if (options.hasOwnProperty(key)) {
        const commandOption = options[key];

        this._cronJob.addOption({
          name: key,
          isRequired: commandOption.is_value_required,
          value: commandOption ? commandOption.value : null
        });
      }
    }

    for (const key in aArguments) {
      if (aArguments.hasOwnProperty(key)) {
        const commandArgument = aArguments[key];

        this._cronJob.addArgument({
          name: key,
          isRequired: commandArgument.is_required,
          value: commandArgument ? commandArgument.value : null
        });
      }
    }
  }
}
