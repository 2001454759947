<p-dropdown
  [filter]="true"
  [options]="organisationOptions"
  [(ngModel)]="selectedOrganisationOption"
  (onChange)="onOrganisationChanged($event)"
  [style]="{'width': '100%'}"
  appendTo="body"
  placeholder="---"
  [disabled]="disabled"
></p-dropdown>
