export class Datamodel {
  id: number;
  name?: string;
  apiRoute?: string;
  fullApiRoute?: string;
  entityCollectionName?: string;

  constructor(id?: number, name?: string, apiRoute?: string, fullApiRoute?: string, entityCollectionName?: string) {
    this.id = id;
    this.name = name;
    this.apiRoute = apiRoute;
    this.fullApiRoute = fullApiRoute;
    this.entityCollectionName = entityCollectionName;
  }

  isIndependent() {
    return !this.fullApiRoute;
  }
}
