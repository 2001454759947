/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./toolbar-item-details.component";
import * as i2 from "../../../../../services/component-highlight-stack.service";
import * as i3 from "../../../../../../../core/message/message-growl.service";
var styles_ToolbarItemDetails = [];
var RenderType_ToolbarItemDetails = i0.ɵcrt({ encapsulation: 2, styles: styles_ToolbarItemDetails, data: {} });
export { RenderType_ToolbarItemDetails as RenderType_ToolbarItemDetails };
export function View_ToolbarItemDetails_0(_l) { return i0.ɵvid(2, [], null, null); }
export function View_ToolbarItemDetails_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "toolbar-item-details", [], null, null, null, View_ToolbarItemDetails_0, RenderType_ToolbarItemDetails)), i0.ɵdid(1, 245760, null, 0, i1.ToolbarItemDetails, [i2.ComponentService, i3.MessageGrowlService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ToolbarItemDetailsNgFactory = i0.ɵccf("toolbar-item-details", i1.ToolbarItemDetails, View_ToolbarItemDetails_Host_0, { params: "params" }, {}, []);
export { ToolbarItemDetailsNgFactory as ToolbarItemDetailsNgFactory };
