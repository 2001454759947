import { Observable } from 'rxjs';
import {map} from 'rxjs/operators';
import {AbstractExecutionStep} from '../../../../core/executor/abstract-execution-step';
import {ExecutionStepStatus} from '../../../../core/executor/execution-step-status';
import {AbstractGenericGridComponent} from '../../../content-renderer/elements/abstract-generic-grid.component';
import {MessageGrowlService} from '../../../../core/message/message-growl.service';
import {TranslateService} from '@ngx-translate/core';
import {TOAST_LIFE_INFINITE, ToastComponentsRegistry, ToastService} from '../../../../core/service/toast.service';

export class WorkHourPeriodCalculateWorkHourEntriesExecutionStep extends AbstractExecutionStep {

  public doExecute(): Observable<ExecutionStepStatus> {
    const payload = this.getPayload(),
      component = payload.getValue().component ? payload.getValue().component : payload.getValue();

    const selectedEntity = component.getSelectedEntity(),
      index = component.findEntityIndex(selectedEntity);

    const entities = [];
    for (let i = 0; i <= index; i++) {
      entities.push(component.entities[i]);
    }

    if (entities.length === 0) {
      return this.getFailObservable('No entities to calculate');
    }

    return this.doGenerate(component, entities);
  }

  private doGenerate(component: AbstractGenericGridComponent, entities: any[] = []): Observable<ExecutionStepStatus> {
    const data = {
      workHourEntryIds: []
    };
    const employee = this.getCurrentFilterValue(component);
    const year = this.getFilterValue(component, 'workYear');
    const month = this.getFilterValue(component, 'workMonth');

    for (const selectedEntity of entities) {
      if (!selectedEntity.isSummary) {
        data.workHourEntryIds.push(selectedEntity.id);
      }
    }

    this.getToastService().custom(ToastComponentsRegistry.PROGRESS_BAR, {
      severity: 'info',
      life: TOAST_LIFE_INFINITE,
      closable: false,
      summary: 'Calculating...'
    });

    return this.genericCrudService.customPost(
      'phoenix/workhourperiodentries/calculate?year='
      + (year || '') + '&month=' + (month || '')
      + '&leasedEmployee=' + (employee ? employee.id : '')
      + '&embedded=none', data)
      .pipe(
        map((response) => {
          this.getToastService().clear(ToastComponentsRegistry.PROGRESS_BAR);
          if (response.errors) {
            this.injector.get(MessageGrowlService).info(
              this.injector.get(TranslateService).instant('WORK_HOUR.' + response.errors[0])
            );
          } else {
            this.injector.get(MessageGrowlService).info(
              this.injector.get(TranslateService).instant('WORK_HOUR.ENTRIES_GENERATED')
            );
            component.loadEntities().subscribe();
          }
          return { status: true, content: ''};
        })
      );
  }

  private getFilterValue(component: AbstractGenericGridComponent, item: string): string|null {
    return component.gridFilters[item] && component.gridFilters[item].value ? component.gridFilters[item].value : null;
  }

  private getCurrentFilterValue(component: AbstractGenericGridComponent): any|null {
    return component.gridFilters.leasedEmployee && component.gridFilters.leasedEmployee.value &&
      component.gridFilters.leasedEmployee.value[0] ?
      component.gridFilters.leasedEmployee.value[0] :
      null;
  }

  private getToastService(): ToastService {
    return this.injector.get(ToastService, null);
  }
}
