import {ChangeDetectionStrategy, Component} from '@angular/core';
import {ToolbarItemWorkHourMonthlyGridShowWageAndInvoiceColumnsComponent} from './toolbar-item-work-hour-monthly-grid-show-wage-and-invoice-columns.component';
import {AbstractGenericGridComponent} from '../../../../abstract-generic-grid.component';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-toolbar-item-work-hour-monthly-grid-toggle-between-wage-and-invoice-columns',
  template: ``
})
export class ToolbarItemWorkHourMonthlyGridToggleBetweenWageAndInvoiceColumnsComponent extends
  ToolbarItemWorkHourMonthlyGridShowWageAndInvoiceColumnsComponent {

  protected handleColumns(component: AbstractGenericGridComponent): void {
    const wageColumnsVisibleCount = this.getColumnsVisibleCount(component, this.wageColumns),
      invoiceColumnsVisibleCount = this.getColumnsVisibleCount(component, this.invoiceColumns);

    const showWage = wageColumnsVisibleCount < invoiceColumnsVisibleCount;

    for (const column of this.wageColumns) {
      const gridField = component.getColumnBuilder().findField(column);

      if (gridField) {
        gridField.visible = showWage;
      }
    }

    for (const column of this.invoiceColumns) {
      const gridField = component.getColumnBuilder().findField(column);

      if (gridField) {
        gridField.visible = !showWage;
      }
    }
  }

  private getColumnsVisibleCount(component, columnNames): number {
    let count = 0;

    for (const column of columnNames) {
      const gridField = component.getColumnBuilder().findField(column);

      if (gridField && gridField.visible) {
        count++;
      }
    }

    return count;
  }
}
