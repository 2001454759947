import { Injectable } from '@angular/core';
import { AuthenticationService } from '../../core/authentication/authentication.service';
import { environment } from '../../../environments/environment';

@Injectable()
export class LocalStorageDataService {

  private localStorage: any;
  private environment: Environment;

  constructor(
    private authenticationService: AuthenticationService
  ) {
    this.localStorage = localStorage;
    this.environment = environment;
  }

  public setItem(key: string, value: any) {
    this.localStorage.setItem(this.getUniqueKey(key), JSON.stringify(value));
    return this;
  }

  public getItem(key: string): any | null {
    return JSON.parse(this.localStorage.getItem(this.getUniqueKey(key)));
  }

  public removeItem(key) {
    this.localStorage.removeItem(this.getUniqueKey(key));
    return this;
  }

  public itemExists(key): boolean {
    return this.localStorage.getItem(this.getUniqueKey(key)) !== null;
  }

  private getUniqueKey(key: string): string {
    return this.authenticationService.currentUser.username + '-' + this.environment.key + '-' + key;
  }

}
