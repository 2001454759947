import { Injectable } from '@angular/core';
import { Log, LoggerInterface, LogLevel } from './logger';

@Injectable()
export class LoggerService implements LoggerInterface {

    public logs: Log[] = [];

    public get(level: LogLevel): Log[]|any {
        return this.logs.find((log: Log) => log.level === level);
    }

    public getAll(): Log[] {
        return this.logs;
    }

    public count(): number {
        return this.logs.length;
    }

    public emergency(message: string, context: Object = {}): LoggerInterface {
        this.log(LogLevel.Emergency, message, context);

        return this;
    }

    public alert(message: string, context: Object = {}): LoggerInterface {
        this.log(LogLevel.Alert, message, context);

        return this;
    }

    public critical(message: string, context: Object = {}): LoggerInterface {
        this.log(LogLevel.Critical, message, context);

        return this;
    }

    public error(message: string, context: Object = {}): LoggerInterface {
        this.log(LogLevel.Error, message, context);

        return this;
    }

    public warning(message: string, context: Object = {}): LoggerInterface {
        this.log(LogLevel.Warning, message, context);

        return this;
    }

    public notice(message: string, context: Object = {}): LoggerInterface {
        this.log(LogLevel.Notice, message, context);

        return this;
    }

    public info(message: string, context: Object = {}): LoggerInterface {
        this.log(LogLevel.Info, message, context);

        return this;
    }

    public debug(message: string, context: Object = {}): LoggerInterface {
        this.log(LogLevel.Debug, message, context);

        return this;
    }

    public log(level: LogLevel, message: string, context: Object): LoggerInterface {
        const log = new Log();

        log.level = level;
        log.message = message;
        log.context = context;

        this.logs.push(log);

        return this;
    }
}
