import {ChangeDetectionStrategy, Component} from '@angular/core';
import {ToolbarItemUploadDocument} from './toolbar-item-upload-document.component';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-toolbar-item-upload-xml-document',
  templateUrl: './toolbar-item-upload-xml-document.component.html',
  styles: [`
    ::ng-deep .ui-button-text {
      padding-left: 20px !important;
    }
  `]
})
export class ToolbarItemUploadXmlDocumentComponent extends ToolbarItemUploadDocument {

}
