import {Component, OnInit, Input} from '@angular/core';
import {Menu} from '../../../../../../shared/services/menu/menu';
import {TranslateService} from '@ngx-translate/core';
import {GenericCrudService} from '../../../../../../shared/services/generic-crud.service';
import {MessageGrowlService} from '../../../../../message/message-growl.service';
import {RequestCachingService} from '../../../../../../shared/services/request-caching.service';
import {environment} from '../../../../../../../environments';
import {File} from '../../../../../../shared/models/file';
import {forkJoin, Observable, of} from 'rxjs';
import {map} from 'rxjs/operators';
import {ChangeDetectorRefHelper} from '../../../../../../shared/helpers/change-detector-ref.helper';

@Component({
  selector: 'app-leased-employee-costs-import',
  templateUrl: './leased-employee-costs-import.component.html',
  styleUrls: ['./leased-employee-costs-import.component.scss'],
})
export class LeasedEmployeeCostsImportComponent implements OnInit {

  @Input() iconsPath = '';
  @Input() item: Menu;
  @Input() menuPosition = 'left';

  public isDialogVisible = false;
  public isDataLoading = false;

  public baseUrl = '';

  constructor(
    protected translateService: TranslateService,
    protected genericCrudService: GenericCrudService,
    protected messageGrowlService: MessageGrowlService,
    protected requestCachingService: RequestCachingService
  ) {
    this.baseUrl = environment.baseUrl;
  }

  ngOnInit() {
  }

  public onItemClick(item) {
    this.isDialogVisible = true;

    return false;
  }

  public onUpload({files}: { files: File[] }): void {
    this.upload(files).subscribe(() => {

    });
  }

  public upload(files): Observable<any> {
    const observables = [];

    this.isDataLoading = true;
    if (files.length === 0) {
      console.error('No files set for upload!');
      return of(null);
    }

    let i = 0;
    for (const file of files) {
      const fileEntity = {
        name: file.name,
        file: file,
        fileType: file.type,
        fileSize: file.size
      }

      observables.push(this.genericCrudService.upload(`intranet/leasedemployeecosts/import?`, fileEntity));
      i++;
    }

    return forkJoin(observables)
      .pipe(
        map(() => {
          this.dialogHide();
          ChangeDetectorRefHelper.detectChanges(this);
        }));
  }

  dialogHide() {
    this.isDialogVisible = false;
    this.isDataLoading = false;
  }

}
