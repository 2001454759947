import * as i0 from "@angular/core";
const STATE_LIMIT = 10;
export class ElementsStateService {
    /**
     *
     */
    constructor() {
        this.elementStates = [];
    }
    /**
     *
     * @returns {ElementState[]}
     */
    getAll() {
        return this.elementStates;
    }
    add(elementState) {
        if (!this.exists(elementState)) {
            this.elementStates.push(elementState);
        }
        // prevent memory leak
        if (this.elementStates.length >= STATE_LIMIT) {
            this.elementStates.splice(0, 1);
        }
        return this;
    }
    /**
     *
     * @param {ElementState} elementState
     * @returns {ElementsStateService}
     */
    remove(elementState) {
        if (elementState && this.exists(elementState)) {
            let elementStatesId = this.elementStates.findIndex((element) => {
                return elementState.id == element.id && elementState.elementType == element.elementType;
            });
            if (elementStatesId != -1) {
                this.elementStates.splice(elementStatesId, 1);
            }
        }
        return this;
    }
    /**
     *
     * @param {number} moduleId
     * @returns {ElementState[]}
     */
    findByModuleId(moduleId) {
        return this.elementStates.filter((elementState) => { return elementState.moduleElement && elementState.moduleElement.moduleId === moduleId; });
    }
    /**
     *
     * @param {number} moduleId
     * @returns {ElementsStateService}
     */
    removeByModuleId(moduleId) {
        let states = this.findByModuleId(moduleId);
        for (let state of states) {
            this.remove(state);
        }
        return this;
    }
    /**
     *
     * @param {number} moduleElementId
     * @returns {ElementsStateService}
     */
    removeById(moduleElementId) {
        const state = this.findById(moduleElementId);
        if (state) {
            this.remove(state);
        }
        return this;
    }
    /**
     *
     * @param {ElementState} elementState
     * @returns {boolean}
     */
    exists(elementState) {
        return this.findByIdAndType(elementState.id, elementState.elementType) != null;
    }
    /**
     *
     * @param {number} id
     * @returns {ElementState}
     */
    findById(id) {
        for (let state of this.elementStates) {
            if (state.id == id) {
                return state;
            }
        }
        return null;
    }
    /**
     *
     * @param {number} id
     * @param {ElementType} type
     * @returns {ElementState}
     */
    findByIdAndType(id, type) {
        for (let state of this.elementStates) {
            if (state.id == id && state.elementType == type) {
                return state;
            }
        }
        return null;
    }
    /**
     *
     * @param {number} id
     * @param entity
     * @returns {ElementsStateService}
     */
    setSelectedEntity(id, entity) {
        let state = this.findById(id);
        state.setSelectedEntity(entity);
        return this;
    }
}
ElementsStateService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ElementsStateService_Factory() { return new ElementsStateService(); }, token: ElementsStateService, providedIn: "root" });
