import { Injectable } from '@angular/core';
import { Datamodel } from './datamodel/datamodel';

@Injectable()
export class ApiBuilderService {

  constructor() {
  }

  public constructApiUrl(datamodel: Datamodel, entity: any, fieldName: string) {
    let url = datamodel.apiRoute;

    return url + '?' + fieldName + '=' + entity.id;
  }

  public getPaginatedApiRouteParams(paginatedApiRoute: string) {
    let offset = paginatedApiRoute.substring(paginatedApiRoute.lastIndexOf("offset/") + 7, paginatedApiRoute.lastIndexOf("/limit")),
      limit = paginatedApiRoute.substring(paginatedApiRoute.lastIndexOf("limit/") + 6, paginatedApiRoute.lastIndexOf("/orderby")),
      orderBy = paginatedApiRoute.substring(paginatedApiRoute.lastIndexOf("orderby/") + 8, paginatedApiRoute.lastIndexOf("/")),
      orderDirection = paginatedApiRoute.substring(1 + paginatedApiRoute.lastIndexOf("/")),
      apiParts = paginatedApiRoute.split('/offset'),
      apiRoute = apiParts[0];

    return {
      'apiRoute': apiRoute,
      'offset': offset,
      'limit': limit,
      'orderDirection': orderDirection,
      'orderBy': orderBy
    }
  }

  public getPaginateApiRoute(params: {
    apiRoute?: string,
    offset?: string|number,
    limit?: string|number,
    orderBy?: string,
    orderDirection?: string
  }) {
    return `${params.apiRoute}/offset/${params.offset}/limit/${params.limit}/orderby/${params.orderBy}/${params.orderDirection}`;
  }

  public getApiRoute(fromString: string): string {
    let apiRoute = fromString.replace('/api/', ''), // remove '/api/' part from a string
      apiRouteParts = apiRoute.split('/', 2);

    return `${apiRouteParts[0]}/${apiRouteParts[1]}`;
  }

  public getApiRouteWithoutBundle(fromString: string): string {
    let apiRoute = fromString.replace('/api/', ''), // remove '/api/' part from a string
      apiRouteParts = apiRoute.split('/', 2);

    return `${apiRouteParts[1]}`;
  }

}
