import {AbstractGenericElementActionHandler} from '../abstract-generic-element-action-handler';
import {Observable} from 'rxjs';
import {ActionHandlerSubscribeResponse} from '../../../../../core/events/interfaces/action-handler-subscribe-response';
import {MasterEntityImportEvent} from '../../event/master-entity-import-event';
import {AbstractGenericGridComponent} from '../../../../content-renderer/elements/abstract-generic-grid.component';

export class GenericGridImportMasterEntityDataActionHandler extends AbstractGenericElementActionHandler {

  /**
   * @param {MasterEntityImportEvent} event
   * @returns {Observable<ActionHandlerSubscribeResponse>}
   */
  protected doAction(event: MasterEntityImportEvent): Observable<ActionHandlerSubscribeResponse> {
    const masterEntity = event.getEntity();
    const component = event.getComponent();

    if (component instanceof AbstractGenericGridComponent && masterEntity
      && component.element && component.element.datamodel && component.element.datamodel.entityCollectionName) {
      const collectionName = component.element.datamodel.entityCollectionName;

      if (masterEntity[collectionName] instanceof Array) {
        for (const updatedEntity of masterEntity[collectionName]) {
          component.replaceEntity(updatedEntity);
        }
      }
    }

    return undefined;
  }
}
