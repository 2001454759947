import {TreeNode} from 'primeng/api';
import {AbstractGenericGridComponent} from '../../../../abstract-generic-grid.component';
import {EntityHydrator} from '../../../../../../services/entity-hydrator.service';

export abstract class AbstractSelectionService {
  public component: AbstractGenericGridComponent = null;

  protected abstract doOnSelectionInit();
  protected abstract doSelect(entity, node): void;
  protected abstract doUnSelect(entity, node): void;
  protected abstract doIsSelected(entity);

  public constructor(
    protected entityHydrator: EntityHydrator
  ) {

  }

  public onSelectionInit(): void {
    this.doOnSelectionInit();
  }

  public select(entity: any, node?: TreeNode): void {
    this.doSelect(entity, node);
  }

  public unSelect(entity: any, node?: TreeNode): void {
    this.doUnSelect(entity, node);
  }

  public isSelected(entity: any): boolean {
    return this.doIsSelected(entity);
  }

  public isSummary(entity: any): boolean {
    return entity && entity.isSummary;
  }
}
