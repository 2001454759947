import {ChangeDetectionStrategy, Component, OnDestroy} from '@angular/core';
import {ToolbarItemAbstract} from '../toolbar-item-abstract.component';
import {FormViewerComponent} from '../../../../../../form-viewer/form-viewer.component';
import {ContractOfEmploymentExitInformationService} from './service/contract-of-employment-exit-information.service';
import {ContractOfEmploymentContactInformationService} from './service/contract-of-employment-contact-information.service';
import {FormService} from '../../../../../../form-viewer/form.service';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-toolbar-item-show-or-hide-contract-of-employment-exit-reason',
  templateUrl: './toolbar-item-show-or-hide-contract-of-employment-exit-information.component.html'
})
export class ToolbarItemShowOrHideContractOfEmploymentExitInformationComponent extends ToolbarItemAbstract implements OnDestroy {

  public constructor(
    private formService: FormService
  ) {
    super();
  }

  public click() {
    const component = this.getComponent();

    if (component instanceof FormViewerComponent) {
      const contractOfEmploymentService = new ContractOfEmploymentExitInformationService();

      contractOfEmploymentService.setForm(component)
        .setFormService(this.formService)
        .showOrHideExitFields(true);

      const contractOfEmploymentContactService = new ContractOfEmploymentContactInformationService();

      contractOfEmploymentContactService.setForm(component)
        .setFormService(this.formService)
        .showOrHideContactInformationFieldsetElement(true);
    }
  }
}
