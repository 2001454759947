import {Component, EventEmitter, Input, Output} from '@angular/core';
import {Entity} from '../../../../../helpers/entity';

@Component({
  selector: 'app-generic-calendar-field-leased-employee-absence-date',
  templateUrl: './generic-grid-field-leased-employee-absence-date.component.html'
})
export class GenericGridFieldLeasedEmployeeAbsenceDateComponent {

  @Output() public onEdit: EventEmitter<Date> = new EventEmitter();
  @Output() public onFocus: EventEmitter<Date> = new EventEmitter();

  @Input() public entity: any = null;
  @Input() public column: any = null;

  @Input() public editor = false;
  @Input() public renderer = false;

  public onEditCalendar(event): void {
    this.onEdit.emit(event);
  }

  public onFocusCalendar(event): void {
    this.onFocus.emit(event);
  }
  public doShowTime(): boolean {
    return ((this.column.property === 'validFrom' && Entity.getValue(this.entity, 'isTimeEnabledInFrom') === true) ||
      (this.column.property === 'validTo' && Entity.getValue(this.entity, 'isTimeEnabledInTo') === true));
  }
}
