import {JobInterface} from './job.interface';
import {Guid} from 'guid-typescript';
import {JobExecutionStatus} from './job-execution-status';
import {JobContext} from '../context/job.context';
import {ConditionInterface} from '../condition/condition.interface';
import {Observable} from 'rxjs';

export abstract class AbstractJob implements JobInterface {
  protected jobId;
  protected immutable = false;

  constructor() {
    this.jobId = Guid.create().toString();
  }

  /**
   *
   * @param context
   * @param condition
   */
  abstract run(context: JobContext, condition: ConditionInterface): Observable<JobExecutionStatus>;

  getJobId(): string|null {
    return this.jobId;
  }

  isImmutable(): boolean {
    return this.immutable;
  }

  setIsImmutable(immutable: boolean): JobInterface {
    this.immutable = immutable;
    return this;
  }
}
