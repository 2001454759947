import {ChangeDetectionStrategy, Component} from '@angular/core';
import { ToolbarItemAbstract } from '../toolbar-item-abstract.component';
import { GenericElementAbstract } from '../../../../generic-element-abstract.component';
import { AbstractGenericGridComponent } from 'app/shared/content-renderer/elements/abstract-generic-grid.component';

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'toolbar-item-draft-delete',
    template: ''
})
export class ToolbarItemDraftDelete extends ToolbarItemAbstract {

    public click() {
        const component = this.getComponent();

        if (component instanceof AbstractGenericGridComponent) {
            this.doDraftDeleteInGridContext(component);
        }
    }

    private doDraftDeleteInGridContext(gridComponent: AbstractGenericGridComponent): void {
        // later on we will check can we do this delete or not 
        gridComponent.onDraftDelete().subscribe(() => {
            
        });
    }

}
