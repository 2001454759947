
import {map} from 'rxjs/operators';
import {Component, OnInit} from '@angular/core';
import {SelectItem} from 'primeng/api';
import {Observable} from 'rxjs';
import {AbstractQuestionComponent} from '../abstract-question-component';
import {Datamodel} from '../../../../../../../services/datamodel/datamodel';
import {GenericCrudService} from '../../../../../../../services/generic-crud.service';
import {Entity} from '../../../../../../../helpers/entity';
import {ExecutorService} from '../../../../../../../../core/executor/executor.service';
import {ExecutionStepFactoryService} from '../../../../../../../../core/executor/factory/execution-step-factory.service';
import {ExecutionStepPayload} from '../../../../../../../../core/executor/execution-step-payload';

@Component({
  selector: 'app-questionnaire-question-dropdown',
  template: `
    <div class="ui-g">
        <div class="ui-g-4">
          {{label}}:
        </div>
        <div class="ui-g-8">
          <p-dropdown
            appendTo="body" 
            (onChange)="onChange($event)"
            [style]="{'width':'100%'}" 
            [options]="options" 
            placeholder="---" 
            [(ngModel)]="selectedOption"
          ></p-dropdown>
        </div>
    </div>
  `,
  styles: [`
    :host {
      width: 100%;
    }
  `],
  providers: [ ExecutorService ]
})
export class DropdownQuestionComponent extends AbstractQuestionComponent implements OnInit {

  public datamodel: Datamodel = null;
  public options: SelectItem[] = [];
  public selectedOption: SelectItem = null;
  public label = '';

  public constructor(
    protected genericCrudService: GenericCrudService,
    protected executorService: ExecutorService,
    protected stepFactory: ExecutionStepFactoryService
  ) {
    super();
  }

  public ngOnInit(): void {
    super.ngOnInit();

    this.onComponentInit();
  }

  public onComponentInit(): void {
    const datamodelId = this.getParam('datamodelId');

    this.label = this.getParamValue('label');

    this.loadDatamodel()
      .subscribe((datamodel: Datamodel) => {
        this.loadOptions().subscribe();
      });
  }

  public onChange(event): void {
    const value = event.value;

    const onChange = this.getParamValue('onChangeAction');

    if (onChange !== null) {
      const step = this.stepFactory.createFromString(onChange, new ExecutionStepPayload({
        questionComponent: this,
        value: value
      }));

      this.executorService.setSteps([step]).execute().subscribe();
    }
  }

  protected loadOptions(extraParams?: Object): Observable<SelectItem[]> {
    const apiRoute = `${this.datamodel.apiRoute}/offset/0/limit/50/orderby/id/asc`;

    return this.genericCrudService.getEntities(apiRoute, '', extraParams).pipe(
      map((dmRecords: any) => {
        const records = (dmRecords.data) ? dmRecords.data : dmRecords;

        this.options = [];
        records.map((record) => {
          const label: string = this.figureOutLabel(record),
            value: any = record;

          const option = {
            label: label,
            value: value
          };
          this.options = [...this.options, option];
        });

        return this.options;
      }));
  }

  protected loadDatamodel(): Observable<Datamodel> {
    const datamodelId = +this.getParamValue('datamodelId');

    return this.genericCrudService.getEntity('superadmin/datamodels', +datamodelId).pipe(
      map((datamodel: Datamodel) => {
        this.datamodel = datamodel;

        return datamodel;
      }));
  }

  private figureOutLabel(entity: any): string {
    let label = entity.name || entity.id;

    const datamodelField = this.getParamValue('datamodelField');

    if (datamodelField) {
      label = Entity.getValue(entity, datamodelField) || Entity.getValueInEmbedded(entity, datamodelField);
    }

    return label;
  }
}
