
import {of as observableOf, Observable} from 'rxjs';
import {AbstractExecutionStep} from '../../../../core/executor/abstract-execution-step';
import {ExecutionStepStatus} from '../../../../core/executor/execution-step-status';
import {AbstractGenericGridComponent} from '../../../content-renderer/elements/abstract-generic-grid.component';
import {LocalStorageDataService} from '../../local-storage-data.service';
import {Constants} from '../../../../constants';
import {UserSessionService} from '../../../../core/service/user-session.service';

export class FilterSalaryTypesByIsActiveAndCollectiveAgreementStep extends AbstractExecutionStep {

  public doExecute(): Observable<ExecutionStepStatus> {
    const payload = this.getPayload(),
      component = payload.getValue().component ? payload.getValue().component : payload.getValue();

    if (!(component instanceof AbstractGenericGridComponent)) {
      return this.getFailObservable('You need to pass AbstractGenericGridComponent as Payload value!');
    }

    return this.doFilter(component);
  }

  doFilter(grid: AbstractGenericGridComponent): Observable<ExecutionStepStatus> {

    grid.embeddedFields = [...grid.embeddedFields, 'article'];
    grid.staticFilters.push({
      field: 'article',
      value: 'isNotNull:'
    });

    const userSession = this.injector.get(UserSessionService, null),
      extraPayCollectiveAgreement = userSession.get(Constants.COLLECTIVE_AGREEMENT_EXTRA_PAY);

    if (extraPayCollectiveAgreement) {
      grid.staticFilters.push({
        field: 'collectiveAgreement',
        value: extraPayCollectiveAgreement.id
      });
    }

    return observableOf({status: extraPayCollectiveAgreement !== null, content: !extraPayCollectiveAgreement ? 'No CA found' : null});
  }
}
