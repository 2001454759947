import { Observable } from 'rxjs';
import { AbstractGenericElementActionHandler } from './abstract-generic-element-action-handler';
import { ActionHandlerSubscribeResponse } from '../../../../core/events/interfaces/action-handler-subscribe-response';
import { GenericElementEvent } from '../event/generic-element-event';
import { LocationService } from '../../location.service';
import { ElementsStackService } from '../../../content-renderer/services/elements-stack.service';
import { GenericElementAbstract } from '../../../content-renderer/elements/generic-element-abstract.component';
import { GenericTreeGridComponent } from '../../../content-renderer/elements/generic-tree-grid/generic-tree-grid.component';
import {AbstractGenericGridComponent} from '../../../content-renderer/elements/abstract-generic-grid.component';

export class GenericElementCreateContextActionHandler extends AbstractGenericElementActionHandler {

    public constructor(
        private locationService: LocationService,
        private elementsStackService: ElementsStackService
    ) {
        super();
    }

    protected doAction(event: GenericElementEvent): Observable<ActionHandlerSubscribeResponse> {
        let component = event.getComponent();

        if (component instanceof GenericElementAbstract) {
            this.doCreateContext(component);
        }

        return this.getObservable(true);
    }

    private doCreateContext(component: GenericElementAbstract) {

    }

    private isGridComponent(component: GenericElementAbstract): boolean {
      return (component instanceof AbstractGenericGridComponent || component instanceof GenericTreeGridComponent);
    }

}
