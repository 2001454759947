<app-generic-loading *ngIf="isLoading"></app-generic-loading>

<div *ngIf="!isLoading">
  <shared-dynamic-table
    [header]="header"
    [columns]="columns"
    [entities]="data"
    [totalCount]="data.length"
    [showRows]="false"
    [showFilters]="false"
    [paginator]="false"
  ></shared-dynamic-table>
</div>
