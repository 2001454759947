export class JsonHelper {

  /**
   * @description - use it to avoid circular references
   * @param data
   */
  public static stringify(data) {
    const cache = new Map();
    return JSON.stringify(data, function (key, value) {
      if (typeof value === 'object' && value !== null) {
        if (cache.has(value)) {
          // Duplicate reference found
          try {
            // If this value does not reference a parent it can be deduped
            return JSON.parse(JSON.stringify(value));
          } catch (error) {
            // discard key if value cannot be deduped
            return;
          }
        }
        // Store value in our map
        cache.set(value, true);
      }
      return value;
    });
  }

  public static isJsonString(str) {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  }
}
