
import {of as observableOf, Observable} from 'rxjs';
import {AbstractExecutionStep} from '../../executor/abstract-execution-step';
import {ExecutionStepStatus} from '../../executor/execution-step-status';
import {Constants} from '../../../constants';
import {LocalStorageDataService} from '../../../shared/services/local-storage-data.service';
import {GenericCrudService} from '../../../shared/services/generic-crud.service';
import {UserSessionService} from '../user-session.service';

export class BootstrapCollectiveAgreementDataExecutionStep extends AbstractExecutionStep {

  public doExecute(): Observable<ExecutionStepStatus> {
    const payloadValue = this.getPayload().getValue(),
      genericCrudService: GenericCrudService = payloadValue.genericCrudService,
      userSession: UserSessionService = payloadValue.userSession;

    const workerAgreement = userSession.get(Constants.COLLECTIVE_AGREEMENT_HIRED_WORKER);

    if (!workerAgreement) {
      const urlPersonnel = Constants.COLLECTIVE_AGREEMENT_CUSTOM_LOADER_PATH + '/' + Constants.COLLECTIVE_AGREEMENT_HIRED_WORKER;
      genericCrudService.get(urlPersonnel).subscribe((collectiveAgreement) => {
        userSession.set(Constants.COLLECTIVE_AGREEMENT_HIRED_WORKER, {
          value: collectiveAgreement
        })
      });
    }

    const employeeAgreement = userSession.get(Constants.COLLECTIVE_AGREEMENT_EMPLOYEES);

    if (!employeeAgreement) {
      const urlEmployee = Constants.COLLECTIVE_AGREEMENT_CUSTOM_LOADER_PATH + '/' + Constants.COLLECTIVE_AGREEMENT_EMPLOYEES;
      genericCrudService.get(urlEmployee).subscribe((collectiveAgreement) => {
        userSession.set(Constants.COLLECTIVE_AGREEMENT_EMPLOYEES, {
          value: collectiveAgreement
        })
      });
    }

    const extraPayAgreement = userSession.get(Constants.COLLECTIVE_AGREEMENT_EXTRA_PAY);

    if (!extraPayAgreement) {
      const urlExtraPay = Constants.COLLECTIVE_AGREEMENT_CUSTOM_LOADER_PATH + '/' + Constants.COLLECTIVE_AGREEMENT_EXTRA_PAY;
      genericCrudService.get(urlExtraPay).subscribe((collectiveAgreement) => {
        userSession.set(Constants.COLLECTIVE_AGREEMENT_EXTRA_PAY, {
          value: collectiveAgreement
        })
      });
    }

    return observableOf({status: true, content: null});
  }
}
