<p-dialog
  *ngIf="isDialogVisible"
  [(visible)]="isDialogVisible"
  [responsive]="true"
  [modal]="false"
  [minWidth]="600"
  width="600"
  showEffect="fade"
  appendTo="body"
  header="{{ 'TOOLBARMANAGEMENT.SIMPLE_SEARCH' | translate }}"
>
  <app-generic-grid-simple-search
    (onSearch)="onSearch($event)"
    [component]="component"
  ></app-generic-grid-simple-search>
</p-dialog>
