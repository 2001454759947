
import {of as observableOf, Observable} from 'rxjs';

import {map} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {ExecutorService} from '../../executor/executor.service';
import {ModulesStateService} from '../../../shared/content-renderer/services/modules-state.service';
import {MessageService} from '../../message/message.service';
import {MessageGrowlService} from '../../message/message-growl.service';
import {ExecutionStepBuilderService} from '../../executor/builder/execution-step-builder.service';
import {ModuleState} from '../../../shared/content-renderer/services/module-state';
import {ExecutionStatus} from '../../executor/execution-status';
import {ExecutionStatusSuccess} from '../../executor/execution-status-success';
import {ExecutionStatusError} from '../../executor/execution-status-error';
import {ComponentValidationExecutionStep} from '../../../shared/services/execution-step/validation/component-validation-execution-step';
import {EntityValidationExecutionStep} from '../../../shared/services/execution-step/validation/entity-validation-execution-step';

@Injectable()
export class ModuleSaveService {

  public constructor(
    private modulesStateService: ModulesStateService,
    private messageService: MessageService,
    private messageGrowlService: MessageGrowlService,
    private translateService: TranslateService,
    private executionStepBuilderService: ExecutionStepBuilderService
  ) {

  }

  public saveModule(moduleState: ModuleState): Observable<ExecutionStatus|any> {
    const executorService = new ExecutorService();

    if (null !== moduleState) {
      const executionSteps = this.executionStepBuilderService.createExecutionSteps(moduleState);

      for (const step of executionSteps) {
        executorService.addStep(step);
      }

      return executorService.execute().pipe(
        map((status: ExecutionStatus) => {
          if (status.isSuccess()) {
            this.onExecutorSuccess(status);
          } else {
            this.onExecutorFailure(status);
          }

          return status;
        }));
    }

    return observableOf(
      {status: false, content: 'No module state provided!'},
      null
    );
  }

  public onExecutorSuccess(status: ExecutionStatusSuccess): void {
    this.messageGrowlService.success(
      this.translateService.instant('COMMON.DATA_SAVED'),
      this.translateService.instant('COMMON.SUCCESS')
    );
  }

  public onExecutorFailure(status: ExecutionStatusError): void {
    if (status.getStep() instanceof ComponentValidationExecutionStep ||
      status.getStep() instanceof EntityValidationExecutionStep
    ) {
      this.onComponentValidationFailure(status);
    }
  }

  public onComponentValidationFailure(status: ExecutionStatusError): void {
    this.messageService.confirm({
      acceptVisible: true,
      rejectVisible: false,
      header: this.translateService.instant('COMMON.ERROR'),
      message: status.getStepContent(),
      accept: () => { }
    });
  }
}
