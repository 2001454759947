import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {GenericCrudService} from '../../services/generic-crud.service';
import {cloneDeep} from 'lodash';

@Component({
    selector: 'app-dialog-choose-datamodel',
    templateUrl: './dialog-choose-datamodel.component.html',
    styleUrls: ['./dialog-choose-datamodel.component.scss']
})
export class DialogChooseDatamodelComponent implements OnInit {

  public filterValue = '';

  public _datamodelId: any;
  @Input() set datamodelId(datamodelId: number) {
    this._datamodelId = datamodelId;

    this.loadDatamodelFields();
  }

  @Input() disabled: boolean;
  @Input() datamodel: string;
  @Output() datamodelChange: EventEmitter<any> = new EventEmitter();

  display = false;
  private dataModelFields: TreeNode[] = [];
  public filteredDataModelFields: TreeNode[] = [];

  constructor(private genericCrudService: GenericCrudService) {}

  public ngOnInit(): void {
    this.loadDatamodelFields();
  }

  public nodeExpand(event): void {
    if (event.node && this._datamodelId) {
      this.genericCrudService
        .getEntities(`superadmin/datamodels/${this._datamodelId}/fields/${event.node.data.fieldName}/tree/level`)
        .subscribe((nodes) => event.node.children = nodes);
      }
  }

  public nodeSelect(event): void {
    this.datamodelChange.emit(event.node.data.fieldName);
    this.display = false;
  }

  public onFilter(event): void {
    this.filteredDataModelFields = this.filterNodes(this.filterValue, this.dataModelFields);
  }

  private filterNodes(query: string, nodes: TreeNode[]): TreeNode[] {
    const filtered = [];
    for (let i = 0; i < nodes.length; i++) {
      const node = nodes[i];
      if (node.label.toLowerCase().indexOf(query.toLowerCase()) === 0) {
        filtered.push(node);
      }
    }
    return filtered;
  }

  private loadDatamodelFields(): void {
    if (this._datamodelId) {
      this.dataModelFields = [];

      this.genericCrudService
        .getEntities(`superadmin/datamodels/${this._datamodelId}/fields/tree/level`)
        .subscribe((dmFields) => {
          this.dataModelFields = [{
            children: [],
            data: {fieldName: ''},
            label: 'Bitte wählen',
            leaf: true
          }];
          this.dataModelFields = [...this.dataModelFields, ...dmFields];
          this.filteredDataModelFields = cloneDeep(this.dataModelFields);
        });
    } else {
      this.dataModelFields = [{
        children: [],
        data: {fieldName: ''},
        label: 'Bitte wählen',
        leaf: true
      }];
      this.filteredDataModelFields = cloneDeep(this.dataModelFields);
    }
  }
}
