/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./toolbar-item-entity-import-dialog.component";
import * as i2 from "../../../../../../../core/message/message-growl.service";
import * as i3 from "../../../../../services/double-click.service";
var styles_ToolbarItemEntityImportDialog = [];
var RenderType_ToolbarItemEntityImportDialog = i0.ɵcrt({ encapsulation: 2, styles: styles_ToolbarItemEntityImportDialog, data: {} });
export { RenderType_ToolbarItemEntityImportDialog as RenderType_ToolbarItemEntityImportDialog };
export function View_ToolbarItemEntityImportDialog_0(_l) { return i0.ɵvid(2, [], null, null); }
export function View_ToolbarItemEntityImportDialog_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "toolbar-item-entity-import-dialog", [], null, null, null, View_ToolbarItemEntityImportDialog_0, RenderType_ToolbarItemEntityImportDialog)), i0.ɵdid(1, 245760, null, 0, i1.ToolbarItemEntityImportDialog, [i2.MessageGrowlService, i3.DoubleClickService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ToolbarItemEntityImportDialogNgFactory = i0.ɵccf("toolbar-item-entity-import-dialog", i1.ToolbarItemEntityImportDialog, View_ToolbarItemEntityImportDialog_Host_0, { params: "params" }, {}, []);
export { ToolbarItemEntityImportDialogNgFactory as ToolbarItemEntityImportDialogNgFactory };
