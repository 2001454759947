import {ElementInputDropdown} from './element-input-dropdown';

export class ElementInputRegionChooser extends ElementInputDropdown {
  public inputHeight: string = undefined;

  constructor() {
    super();
    this.typeElement = 'region-chooser';
    this.inputHeight = undefined;
  }
}
