
import {map} from 'rxjs/operators';
import {Observable} from 'rxjs';
import {AbstractExecutionStep} from '../../../../core/executor/abstract-execution-step';
import {ExecutionStepStatus} from '../../../../core/executor/execution-step-status';
import {AbstractGenericGridComponent} from '../../../content-renderer/elements/abstract-generic-grid.component';
import {Constants} from '../../../../constants';

export class FilterReportGridByUserPermissionExecutionStep extends AbstractExecutionStep {

  public doExecute(): Observable<ExecutionStepStatus> {
    const payload = this.getPayload(),
      component = payload.getValue().component ? payload.getValue().component : payload.getValue();

    if (!(component instanceof AbstractGenericGridComponent)) {
      return this.getFailObservable('You need to pass AbstractGenericGridComponent as Payload value!');
    }

    return this.doFilter(component);
  }

  doFilter(grid: AbstractGenericGridComponent): Observable<ExecutionStepStatus> {

    return this.genericCrudService.get(`${Constants.APP_API_ROUTE}/users/me`).pipe(
      map((user: any) => {

        grid.embeddedFields = [...grid.embeddedFields, 'permissions'];
        grid.staticFilters.push({
          field: 'permissions.user',
          value: 'equal:' + user.id
        });

        return {status: true, content: null};
      }));
  }

}
