import {AbstractCondition} from '../../../../core/job-runner/condition/abstract.condition';
import {JobContext} from '../../../../core/job-runner/context/job.context';
import {FormViewerComponent} from '../../../form-viewer/form-viewer.component';

export class SetEntityForMemoCondition extends AbstractCondition {
  isApplicable(context: JobContext): boolean {
    return context.component instanceof FormViewerComponent
      && context.component.moduleElement
      && context.component.moduleElement.element
      && context.component.moduleElement.element.datamodel
      && context.component.moduleElement.element.datamodel.name === 'PhoenixBundle.Memo';
  }
}
