import { AbstractFormActionHandler } from './abstract-form-action-handler';
import { Injectable } from '@angular/core';

const FILES_DATAMODEL_FIELD = 'files';

@Injectable()
export class HandleTicketBundleTicketDocumentUploadFieldsActionHandler extends AbstractFormActionHandler {

  public handleAction(): void {
    if (this.isActionValid()) {
      this.doHandleAction();
    }
  }

  private doHandleAction(): void {
    const entity = this.getEntity(),
      form = this.getForm(),
      filesElement: any = this.formService.getElementBy(form, 'datamodelField', FILES_DATAMODEL_FIELD),
      textOnlyElement: any = this.formService.getElementBy(form, 'textOnly', true);

    if (entity.id) {
      filesElement.readOnly = false;
      textOnlyElement.hidden = true;
    } else {
      filesElement.readOnly = true;
      textOnlyElement.hidden = false;
    }

    this.markElementForCheck(filesElement)
    this.markElementForCheck(textOnlyElement)
  }

  private isActionValid(): boolean {
    const form = this.getForm();

    return this.formService.getElementBy(form, 'datamodelField', FILES_DATAMODEL_FIELD) !== null &&
      this.formService.getElementBy(this.getForm(), 'textOnly', true) &&
      this.getEntity();
  }
}
