import {Injectable} from '@angular/core';
import {ModuleElementFieldDataConfig, ModuleElementFieldFilter} from '../../../../../../services/module/module-element-field';
import {AbstractGenericGridComponent} from '../../../../../elements/abstract-generic-grid.component';
import {GenericElementAbstract} from '../../../../../elements/generic-element-abstract.component';
import {FieldMetadataGrid} from '../../../../../../services/module/module-element-field-metadata-grid';

@Injectable()
export class AnotherEntityFieldFilterService {

  /**
   *
   * @param {ModuleElementFieldDataConfig} fieldDataConfig
   * @param {AbstractGenericGridComponent} grid
   * @returns {ModuleElementFieldFilter | null}
   */
  public static createFilter(
    fieldDataConfig: ModuleElementFieldDataConfig,
    grid: AbstractGenericGridComponent
  ): ModuleElementFieldFilter|null {
    const filter = new ModuleElementFieldFilter();

    filter.column = fieldDataConfig.values.split('.').shift();

    // take last occurrence before '.'
    if (fieldDataConfig.values.indexOf('.') !== -1) {
      const separated = fieldDataConfig.values.split('.');

      filter.column = separated[separated.length - 2];
    }

    filter.value = this.figureOutValue(fieldDataConfig.values, grid);

    return filter.value ? filter : null;
  }

  /**
   *
   * @param {string} field
   * @param {AbstractGenericGridComponent} grid
   * @returns {any}
   */
  protected static figureOutValue(field: string, grid: AbstractGenericGridComponent): any {

      /**
       * @type {GenericElementAbstract} masterComponent
       */
      const entity = grid.getSelectedEntity();

      if (entity) {
        return FieldMetadataGrid.getPropertyValue(entity, field);
      }

    return null;
  }
}
