
import {of as observableOf,  Observable } from 'rxjs';

import {catchError} from 'rxjs/operators';
import { AbstractAsyncValidator } from './abstract-async-validator';
import { ValidationStatus } from '../services/validation';
import { cloneDeep } from 'lodash';
import { Constants } from 'app/constants';

export class AsyncValidator extends AbstractAsyncValidator {

  public getConstraint(): any {
    const validators = this.getParams(),
      constraints = [];

    for (const validator of validators) {
      if (validator instanceof AbstractAsyncValidator && validator.shouldValidate(this.getEntity())) {
        constraints.push({
          'constraint': validator.getConstraint()
        });
      }
    }

    return constraints;
  }

  public isValid(value: any): boolean {
    return true;
  }

  public getErrorTranslateKey(value: any): string {
    return 'ENTITY_IS_NOT_VALID';
  }

  public getErrorTranslateParams(value: any): any {
    return {
      'fieldNames': 'entity'
    };
  }

  public getErrorTranslated(errors) {
    const me = this;

    let error = '';

    if (errors instanceof Object) {
      Object.keys(errors).forEach(function(field) {
        const validator = me.getValidatorByField(field),
          fieldErrors = errors[field];

        let errorMessage = fieldErrors.join(','),
          label = field;

        if (validator && validator.field && validator.field.label) {
          label = validator.field.label;
        }

        if (validator && validator.field && validator.field.validationsError) {
          errorMessage = validator.field.validationsError;
        }

        error += `${label}: ${errorMessage} \n`;
      });
    }

    return error;
  }

  public validate(): Observable<ValidationStatus> {
    const entityToCheck = cloneDeep(this.getEntity());

    return this.getGenericCrudService().createEntity(`${Constants.APP_API_ROUTE}/constraints/validate`, {
      'constraints': this.getConstraint(),
      'entity': this.getEntityHydrator().hydrate(entityToCheck)
    }, false).pipe(catchError((response, caught) => {
      const errors = response.error.errors;

      return observableOf({
        isValid: false,
        errorTranslated: this.getErrorTranslated(errors),
        errorTranslateKey: '',
        errorTranslateParams: {},
        field: this.getField(),
        value: this.getEntityFieldValue()
      });
    }));
  }

  private getValidatorByField(field: string) {
    const validators: any = this.getParams();

    for (const validator of validators) {
      if (validator.field && validator.field.datamodelField && validator.field.datamodelField === field) {
        return validator;
      }
    }

    return null;
  }
}
