<form class="placeholder-label" [formGroup]="form" (ngSubmit)="onSubmit($event)">
  <p-table>
    <ng-template pTemplate="caption">
    <div class="header">
      <div class="datatable-header-left">
        <div class="datatable-header-text">Search</div>
      </div>
    </div>
    </ng-template>
  </p-table>
  <div class="ui-g-2">
    <label class="inline-label" for="name">Name</label>
  </div>
  <div class="ui-g-10">
    <input
      id="name"
      pInputText type="text"
      formControlName="name"
      (keydown)="onNameChange($event)"
    >
  </div>
  <div class="ui-g-2">
    <label class="inline-label" for="datamodel">Datamodel</label>
  </div>
  <div class="ui-g-10">
    <p-dropdown
      [options]="datamodelOptions"
      [style]="{'width': '100%'}"
      [filter]="true"
      id="datamodel"
      (onChange)="onDatamodelChange($event)"
      formControlName="datamodel"
      appendTo="body"
      placeholder="---"
    ></p-dropdown>
  </div>

  <div class="clearfix"></div>
</form>
