import { Observable } from 'rxjs';
import { BaseSetValueFromDialogStep } from '../../dialog-for-autocomplete/base-set-value-from-dialog-step';
import { FormViewerComponent } from '../../../../form-viewer/form-viewer.component';
import { FieldMetadataGrid } from '../../../module/module-element-field-metadata-grid';
import { ExecutionStepStatus } from '../../../../../core/executor/execution-step-status';
import { ElementInputAutocompleteComponent } from '../../../../form-viewer/element/element-input-autocomplete.component';

export class SetContactPersonToOfferFromDialogExecutionStep extends BaseSetValueFromDialogStep {

  protected doSetValue(fromComponent: FormViewerComponent, toComponent: any, field: FieldMetadataGrid): Observable<ExecutionStepStatus> {
    const contactPerson = fromComponent.entity || null,
      customer = this.getCustomer(toComponent);

    if (toComponent instanceof ElementInputAutocompleteComponent &&
      contactPerson !== null &&
      customer !== null
    ) {
      return this.getContactPerson(customer, contactPerson)
        .map((savedContactPerson: any) => {
          toComponent.entity.contactPerson = savedContactPerson;
          toComponent.entity._embedded = {...toComponent.entity._embedded, ...{
              contactPerson: savedContactPerson
            }};

          this.setValueToFormAutocomplete(savedContactPerson, toComponent);
          this.showSuccess();

          return {status: true, content: null};
        });
    }

    return Observable.of({
      status: true,
      content: null
    });
  }

  private getContactPerson(customer: any, contactPerson: any): Observable<any> {
    return this.saveContactPerson(customer, contactPerson)
      .map((savedContactPerson: any) => {
        return savedContactPerson;
      });
  }

  private saveContactPerson(customer: any, contactPerson: any): Observable<any> {
    contactPerson.customer = customer;

    return this.genericCrudService.createEntity('phoenix/contactpersons', contactPerson)
      .map((savedContactPerson: any) => {
        return savedContactPerson;
      });
  }

  private getCustomer(toComponent: any): any|null {
    return toComponent && toComponent.entity ? toComponent.entity.customer : null;
  }
}
