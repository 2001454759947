import {ChangeDetectionStrategy, Component} from '@angular/core';
import {ToolbarItemAbstract} from '../toolbar-item-abstract.component';
import {AbstractGenericGridComponent} from '../../../../abstract-generic-grid.component';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-toolbar-item-expert-search',
  templateUrl: './toolbar-item-expert-search.component.html'
})
export class ToolbarItemExpertSearchComponent extends ToolbarItemAbstract {

  public isDialogVisible = true;
  public component: AbstractGenericGridComponent = null;

  public click() {
    const component = this.getComponent();

    if (component instanceof AbstractGenericGridComponent) {
      this.component = component;
      this.isDialogVisible = true;
    }
  }
}
