import {ChangeDetectionStrategy, Component, Input, OnInit} from '@angular/core';
import { ToolbarItemAbstract } from '../toolbar-item-abstract.component';
import { ToolbarItem } from '../../../../../../services/element/toolbar-item';
import {AbstractGenericGridComponent} from '../../../../abstract-generic-grid.component';

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'toolbar-item-download-bulk-docuware',
    template: ''
})
export class ToolbarItemDownloadBulkDocuwareComponent extends ToolbarItemAbstract {

    private toolbarItem: ToolbarItem;

    private url: string = 'http://ttidcw2.tti.local/DocuWare/PlatformRO/WebClient/1/Integration?p=RLV&fc=382f9cd7-45e5-4024-b65e-871507c5dd59&q=';

    ngOnInit() {

    }

    public click() {
      const component = this.getComponent();
      if(component instanceof AbstractGenericGridComponent){
        const entities = component.entities;
        let urlParams = [];
        for(let entity of entities){
          urlParams.push('[PHX_GUID]=' + entity.docuwareDocument.phxGuid);
        }
        let urlParamString = urlParams.join(' OR ');
        let url = this.url + btoa(urlParamString);

        window.open(url, '_blank');
      }
    }
}
