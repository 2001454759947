
import {of as observableOf, Observable} from 'rxjs';
import {AbstractExecutionStep} from '../../../../core/executor/abstract-execution-step';
import {ExecutionStepStatus} from '../../../../core/executor/execution-step-status';
import {AbstractGenericGridComponent} from '../../../content-renderer/elements/abstract-generic-grid.component';
import {Entity} from '../../../helpers/entity';
import {FieldMetadataGrid} from '../../module/module-element-field-metadata-grid';

export class SalaryTypeDisableFieldsStep extends AbstractExecutionStep {

  public doExecute(): Observable<ExecutionStepStatus> {
    const payload = this.getPayload(),
      component = payload.getValue().component ? payload.getValue().component : payload.getValue();

    if (!(component instanceof AbstractGenericGridComponent)) {
      return this.getFailObservable('You need to pass AbstractGenericGridComponent as Payload value!');
    }

    return this.doDisableFields(component);
  }

  doDisableFields(grid: AbstractGenericGridComponent): Observable<ExecutionStepStatus> {
    const article = this.getParamValue('article').id,
        fieldsToLock = this.getParamValue('fieldsToLock'),
        entity = grid.getSelectedEntity();

    const doLock = this.doLock(article, entity);

    for (const fieldToLock of fieldsToLock) {
      const gridField: FieldMetadataGrid = grid.findField(fieldToLock);

      if (gridField) {
        gridField.isReadOnly = doLock;
      }
    }

    return observableOf({status: true, content: null});
  }

  private doLock(article: any, entity: any): boolean {
    let doLock = false;

    const entityArticle = Entity.getValue(entity, 'article.id'),
      embeddedEntityArticle = Entity.getValueInEmbedded(entity, 'article.id');

    if ( (entityArticle && entityArticle === article) ||
      (!entityArticle && embeddedEntityArticle === article)) {
      doLock = true;
    }

    return doLock;
  }
}
