import {
  Component,
  OnInit,
  Input,
  OnDestroy,
  ChangeDetectorRef,
  ChangeDetectionStrategy,
  ViewChild
} from '@angular/core';
import {AbstractGenericGridComponent} from '../../../abstract-generic-grid.component';
import {FieldMetadataGrid} from '../../../../../services/module/module-element-field-metadata-grid';
import {Entity} from '../../../../../helpers/entity';
import {Debounce} from '../../../../../helpers/debounce';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {DecimalColumnComponent} from '../../../generic-grid/general-columns/decimal/decimal-column.component';
import {ChangeDetectorRefHelper} from '../../../../../helpers/change-detector-ref.helper';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-table-edit-column',
  templateUrl: './table-edit-column.component.html',
  styleUrls: ['./table-edit-column.component.scss']
})
export class TableEditColumnComponent implements OnInit, OnDestroy {

  @Input() column: any = null;
  @Input() entity: any = null;
  @Input() component: AbstractGenericGridComponent = null;

  @ViewChild(DecimalColumnComponent, {static: false}) decimalComponent: DecimalColumnComponent;
  public unsubscribe = new Subject<void>();

  public settingOptions: SelectItem[] = [
    {
      label: 'Flatten',
      value: 'flatten_strategy'
    },
    {
      label: 'Test',
      value: 'test'
    }
  ];

  public constructor(
    public cdr: ChangeDetectorRef
  ) {

  }

  public ngOnInit(): void {
  }

  public ngOnDestroy(): void {
  }

  public getEntityAssociatedOneEntityValueLabel(entity: any, column: any) {
    const associatedOneEntity = Entity.getValue(entity, column.entityName) ||
      Entity.getValueInEmbedded(entity, column.id);

    let label = associatedOneEntity ? FieldMetadataGrid.getOptionLabel(associatedOneEntity, column.field) : null;

    if (label === null) {
      label = Entity.getValue(entity, column.id) || Entity.getValueInEmbedded(entity, column.id);
    }

    return label;
  }

  public getEntityAssociatedOneEntityValue(entity: any, column: any) {
    const value = Entity.getValue(entity, column.id) || Entity.getValueInEmbedded(entity, column.id);

    if (column.fieldType === FieldMetadataGrid.TYPE_CHECKBOX
      || column.fieldType === FieldMetadataGrid.TYPE_TRICHECKBOX
    || column.fieldType === FieldMetadataGrid.TYPE_LOCK_STATE) {
      return value || false;
    }

    if (column.fieldType === FieldMetadataGrid.TYPE_DATE) {
      return value;
    }

    return typeof value === 'string' || typeof value === 'number' ? value : '';
  }

  public onEditCellSearch(event, entity: any, column: any) {
    Debounce.debounce(() => {
      this.component.getInlineEditService()
        .onSearch(event, entity, column)
        .pipe(takeUntil(this.unsubscribe))
        .subscribe((data) => {
          ChangeDetectorRefHelper.detectChanges(this);
        });
    }, 10);
  }

  public onEditCellFocus(event, entity, column): void {
    this.component.getInlineEditService()
      .onFocus(event, entity, column)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((data) => {
        ChangeDetectorRefHelper.detectChanges(this);
      });
  }

  public onInputMaskKeyUp(event, entity, column): void {
    if (event.target.value === '__:__') {
      this.component.onEditCell(null, entity, column);
    }
  }

  public getSettingValueOptions(entity) {
    let valueOptions = [];

    switch(entity['setting']) {
      case 'flatten_strategy':
        valueOptions = [
          {
            label: 'Woche',
            value: 'weekly'
          },
          {
            label: 'Tag',
            value: 'daily'
          },
          {
            label: 'Monat',
            value: 'monthly'
          }];
        break;
      case 'test':
        valueOptions = [
          {
            label: 'Test',
            value: 'test'
          }
        ];
        break;
      default:
        valueOptions = [];
    }

    return valueOptions;
  }
}
