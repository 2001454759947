<p-table
    #dataTable
    dataKey="{{dataKey}}"
    id="generic-table"
    [columns]="tableColumns"
    [value]="tableEntities"
    [customSort]="true"
    [lazy]="lazy"
    [paginator]="paginator"
    [rows]="rowsCount"
    [totalRecords]="totalCount"
    (onLazyLoad)="onLazyLoadEvent($event)"
    (onRowExpand)="onRowExpand($event)"
    [loading]="isLoadingData"
    [selectionMode]="selectionMode"
    [(selection)]="selectedEntities"
    [metaKeySelection]="true"
    [resizableColumns]="false"
    columnResizeMode="expand"
    styleClass="generic-grid"
    [scrollable]="true"
    [style]="{width: tableWidth + 'px' }"
    sortField="{{sortField}}"
    sortOrder="{{sortDirection}}"
    pDroppable="{{transferKey}}"
    (onDrop)="onDrop($event)"
    (onRowSelect)="onRowSelect($event)"
    (onRowUnselect)="onRowUnselect($event)"
    (selectionChange)="onSelectionChange($event)"
    [expandedRowKeys]="expandedRowKeys"
>
  <ng-template *ngIf="tableHeader" pTemplate="caption">

    <div class="buttons-container">
      <div class="item-spacer"></div>
      <div class="item-left">
        <span style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis">{{tableHeader.title}}</span>
      </div>
      <div *ngFor="let button of tableHeader.buttons" class="item-right">
        <button
          pButton
          *ngIf="button.condition === undefined || button.condition()"
          (click)="onTableButtonClick($event, button)"
          [disabled]="button.disabled !== undefined && button.disabled()"
          type="button"
          icon="{{button.icon}}"
          style="margin-left: .2em; margin-right: .2em; white-space: nowrap"
          pTooltip="{{button.tooltip | translate}}"
          class="{{button.class}}"
          [label]="button.text"
        ></button>
      </div>
    </div>

  </ng-template>

    <ng-template pTemplate="colgroup" let-columns>
        <colgroup>
            <col *ngIf="expander" [style.width.px]="20">
            <col *ngIf="tableRows.length > 0 && showRows" style="width: 100px">
            <col *ngFor="let column of columns" [ngStyle]="{width: column.style.width}">
        </colgroup>
    </ng-template>
    <ng-template pTemplate="header" let-columns>
        <tr>
            <th *ngIf="expander" [style.width.px]="20"></th>
            <th *ngIf="tableRows.length > 0 && showRows"></th>
            <th *ngFor="let column of columns" [pSortableColumn]="column.sort && column.sort.key ? column.sort.key : column.key">
                {{column.header}}
                <p-sortIcon *ngIf="column.sort" [field]="column.field"></p-sortIcon>
            </th>
        </tr>
        <tr *ngIf="showFilters">
            <th *ngIf="expander" [style.width.px]="20"></th>
            <th *ngIf="tableRows.length > 0 && showRows"></th>
            <ng-container *ngIf="showFilters">
              <th *ngFor="let column of columns">

                <ng-template [ngIf]="column.filter">
                    <span [ngSwitch]="column.filter.type" class="ui-filter ui-g ui-g-12">

                        <p-dropdown
                          *ngIf="column.filter.operator"
                          [(ngModel)]="operatorFilterValue[column.key]"
                          [options]="column.filter.operator.options | async"
                          placeholder="---"
                          (onChange)="onFilterOperatorEvent($event, column)"
                          class="ui-g ui-g-4"
                          [style]="{'width': '100%'}"
                          appendTo="body"
                        ></p-dropdown>

                        <p-checkbox
                          *ngSwitchCase="'checkbox'"
                          (onChange)="onFilterEvent($event, column)"
                          binary="true"
                        ></p-checkbox>

                        <p-calendar
                          *ngSwitchCase="'calendar'"
                          appCalendar
                          [showIcon]="true"
                          (onSelect)="onFilterEvent($event, column)"
                          inputStyleClass="ui-filter-calendar-input"
                          appendTo="body"
                        ></p-calendar>
                        <span *ngSwitchCase="'calendar-range'">
                          <p-calendar
                            appCalendar
                            (onSelect)="onFilterEvent($event, column, 'from')"
                            (onInput)="onFilterEvent($event, column, 'from')"
                            class="ui-g ui-g-5"
                            inputStyleClass="ui-filter-calendar-input"
                            appendTo="body"
                          ></p-calendar>
                          <span class="ui-g ui-g-1">-</span>
                          <p-calendar
                            appCalendar
                            [showIcon]="true"
                            (onSelect)="onFilterEvent($event, column, 'to')"
                            (onInput)="onFilterEvent($event, column, 'to')"
                            class="ui-g ui-g-5"
                            inputStyleClass="ui-filter-calendar-input"
                            appendTo="body"
                          ></p-calendar>

                        </span>

                        <p-dropdown
                          *ngSwitchCase="'dropdown'"
                          [options]="column.filter.options | async"
                          (onChange)="onFilterEvent($event, column)"
                          appendTo="body"
                        ></p-dropdown>

                        <span *ngSwitchCase="'number'" class="ui-g ui-g-8">
                            <p-spinner
                              size="30"
                              [(ngModel)]="spinnerFilterValue[column.key]"
                              (onChange)="onSpinnerFilterEvent($event, column)"
                              (input)="onFilterEvent($event, column)"
                            ></p-spinner>
                        </span>


                        <ng-template ngSwitchDefault>
                            <input
                              pInputText
                              type="text"
                              (input)="onFilterEvent($event, column)"
                            >
                        </ng-template>
                    </span>
                </ng-template>

              </th>
            </ng-container>
        </tr>
    </ng-template>
    <ng-template pTemplate="body" let-rowIndex="rowIndex" let-entity let-columns="columns" let-expanded="expanded">
        <tr
          pDraggable="{{transferKey}}"
          (onDragStart)="onDragStart($event, entity)"
          [ngClass]="tableRows[rowIndex] ? tableRows[rowIndex].getClass(entity) : ''" [pSelectableRow]="entity"
          (dblclick)="onRowDoubleClick($event)"
        >
            <td *ngIf="expander" style="text-align: center;">
              <a *ngIf="tableRows[rowIndex] && tableRows[rowIndex].showExpander" [pRowToggler]="entity">
                <i [ngClass]="expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-right'"></i>
              </a>
            </td>
            <td *ngIf="tableRows.length > 0 && showRows">
                <b>{{ tableRows[rowIndex] ? tableRows[rowIndex].name : '' }}</b>
            </td>

            <td *ngFor="let column of columns"
                [style.textAlign]="column.style.textAlign || ''"
                [style.overflow]="'hidden'"
                [style.textOverflow]="'ellipsis'"
                [style.whiteSpace]="'nowrap'"
                [ngStyle]="tableCells && tableCells[rowIndex] && tableCells[rowIndex].getStyle ? tableCells[rowIndex].getStyle(entity, column) : {}"
                [appTableEditableColumn]="entity"
                [pEditableColumnField]="column"
                [component]="this"
                [data]="entity"
            >

                <ng-template [ngIf]="!column.edit">
                        <ng-template [ngIf]="isColumnRendererDefined(column)">
                            <span [innerHTML]="column.renderer(entity, column)"></span>
                            <button
                              *ngIf="column.button"
                              class="column-button"
                              pButton
                              (click)="onTableColumnButtonClick(entity, column.button)"
                              [iconUrl]="column.button.iconUrl"
                            ></button>
                        </ng-template>
                        <ng-template [ngIf]="!isColumnRendererDefined(column)">
                            <span [ngSwitch]="column.renderType">

                                <p-checkbox
                                    *ngSwitchCase="'checkbox'"
                                    [ngModel]="getColumnEntityValue(column, entity)"
                                    binary="true"
                                    (onChange)="onCellEditEvent($event, column, entity)"
                                ></p-checkbox>

                                <ng-template [ngSwitchCase]="'date'">
                                    {{ entity[column.key] | appDate:'dd.mm.yy' }}
                                </ng-template>

                                <ng-template [ngSwitchCase]="'moduleElementColumn'">
                                  <app-generic-column [entity]="entity" [component]="parentComponent" [column]="column.moduleElementColumn"></app-generic-column>
                                </ng-template>

                                <ng-template [ngSwitchCase]="'embedded'">
                                    {{ extractValue(entity, column.key) }}
                                </ng-template>

                                <ng-template [ngSwitchCase]="'decimal'">
                                  {{ entity[column.key] | decimal: ',':'.' }}
                                </ng-template>

                                <ng-template ngSwitchDefault>
                                    {{entity[column.key]}}
                                </ng-template>
                            </span>

                            <span
                              *ngIf="column.button"
                              (click)="onTableColumnButtonClick(entity, column.button)"
                            >
                              <i
                                class="{{column.button.class}}"
                              ></i>
                            </span>
                        </ng-template>
                </ng-template>
                <span *ngIf="column.edit">
                    <p-cellEditor>
                        <ng-template pTemplate="input">

                                <span [ngSwitch]="column.edit.type">

                                    <p-dropdown
                                        *ngSwitchCase="'dropdown'"
                                        [ngModel]="getColumnEntityValue(column, entity)"
                                        [options]="column.edit.options | async"
                                        (onChange)="onCellEditEvent($event, column, entity)"
                                        appendTo="body"
                                        [style]="{'width': '100%', 'min-width': '100%'}"
                                    ></p-dropdown>

                                    <p-autoComplete
                                      appAutoCompleteHandler
                                      *ngSwitchCase="'autocomplete'"
                                      size="30"
                                      appendTo="body"
                                      [style]="{'width': '100%'}"
                                      [field]="column.edit.fieldName || 'label'"
                                      [handler]="column.edit.autocompleteHandler"
                                      [dropdown]="true"
                                      [forceSelection]="true"
                                      (onSelect)="onCellEditEvent($event, column, entity)"
                                    ></p-autoComplete>

                                    <p-calendar
                                        *ngSwitchCase="'calendar'"
                                        appCalendar
                                        [showIcon]="true"
                                        (onSelect)="onCellEditEvent($event, column, entity)"
                                        appendTo="body"
                                    ></p-calendar>

                                    <p-spinner
                                        *ngSwitchCase="'number'"
                                        size="30"
                                        appSpinner
                                        [step]="0.1"
                                        [disabled]="isInlineDisabled(column, entity)"
                                        [ngModel]="getColumnEntityValue(column, entity)"
                                        (valueChange)="onCellEditEvent($event, column, entity)"
                                    ></p-spinner>

                                    <p-inputNumber
                                      size="30"
                                      *ngSwitchCase="'number-v2'"
                                      [useGrouping]="false"
                                      locale="de-DE"
                                      mode="decimal"
                                      [minFractionDigits]="2"
                                      [disabled]="isInlineDisabled(column, entity)"
                                      [ngModel]="getColumnEntityValue(column, entity)"
                                      (onInput)="onCellEditEvent($event, column, entity)"
                                      [style]="{'width':'100%'}"
                                    ></p-inputNumber>

                                    <app-decimal-column
                                      *ngSwitchCase="'decimal'"
                                      [entity]="entity"
                                      [column]="column"
                                      [editMode]="true"
                                      [disabled]="isInlineDisabled(column, entity)"
                                      (valueChange)="onCellEditEvent($event, column, entity)"
                                      (onFocus)="onCellFocusEvent($event, column, entity)"
                                      (onBlur)="onCellBlurEvent($event, column, entity)"
                                      (tabOrEnter)="onCellTabOrEnterEvent($event, column, entity)"
                                    ></app-decimal-column>

                                    <p-checkbox
                                        *ngSwitchCase="'checkbox'"
                                        [ngModel]="getColumnEntityValue(column, entity)"
                                        binary="true"
                                        (onChange)="onCellEditEvent($event, column, entity)"
                                    ></p-checkbox>

                                    <ng-template ngSwitchDefault>
                                        <input
                                          type="text"
                                          pInputText
                                          style="height: 70%"
                                          (keyup)="onCellEditEvent($event, column, entity)"
                                          [ngModel]="getColumnEntityValue(column, entity)"
                                        >
                                    </ng-template>

                                </span>
                        </ng-template>
                        <ng-template pTemplate="output">
                                <ng-template [ngIf]="isColumnRendererDefined(column)">
                                    <span [innerHTML]="column.renderer(entity, column)"></span>
                                </ng-template>
                                <ng-template [ngIf]="!isColumnRendererDefined(column)">
                                    <span [ngSwitch]="column.renderType">

                                        <p-checkbox
                                            *ngSwitchCase="'checkbox'"
                                            [ngModel]="getColumnEntityValue(column, entity)"
                                            binary="true"
                                        ></p-checkbox>

                                        <ng-template [ngSwitchCase]="'decimal'">
                                          {{ entity[column.key] | decimal: ',':'.' }}
                                        </ng-template>

                                        <ng-template [ngSwitchCase]="'date'">
                                            {{ entity[column.key] | date }}
                                        </ng-template>

                                        <ng-template [ngSwitchCase]="'embedded'">
                                          {{ extractValue(entity, column.key) }}
                                        </ng-template>

                                        <ng-template ngSwitchDefault>
                                            {{ getValue(column, entity) }}
                                        </ng-template>
                                    </span>
                                </ng-template>
                        </ng-template>
                    </p-cellEditor>
                </span>
                <span *ngIf="column.menu">
                  <span *ngFor="let button of column.menu.buttons">
                    <button
                      *ngIf="button.condition === undefined || button.condition(entity)"
                      (click)="button.click(entity)"
                      pButton
                      type="button"
                      icon="{{button.icon}}"
                    ></button>
                  </span>
                </span>
            </td>
        </tr>
    </ng-template>
    <ng-template *ngIf="isPaginatorRightVisible" pTemplate="paginatorright">
      <div class="flex-row paginator-buttons-container">
        <button
          *ngFor="let button of paginatorItems.buttons"
          pButton
          type="button"
          [label]="button.text"
          [icon]="button.icon"
          (click)="onTableButtonClick($event, button)"
        ></button>
      </div>
    </ng-template>
    <ng-template pTemplate="rowexpansion" let-entity let-columns="columns">
      <tr>
        <td [attr.colspan]="columns.length + 1">
          <!-- todo should be done with transclusion -->
          <div class="ui-g ui-fluid" style="padding: 0 10px 15px;">
            <app-custom-customer-invoice-position-detail-table
              #expanderComponent
              (onExpanderChange)="onExpanderComponentChange($event)"
              *ngIf="expander === 'invoicePositionDetailTable'"
              [invoicePosition]="entity"
              [moduleElement]="expanderModuleElement"
            ></app-custom-customer-invoice-position-detail-table>
          </div>
        </td>
      </tr>
    </ng-template>
</p-table>
