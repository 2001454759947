<p-dialog 
    [(visible)]="isDialogVisible"
    *ngIf="isDialogVisible" 
    [modal]="true" 
    [width]="600" 
    [height]="400"
>
    <span>
        <p-dropdown 
            [style]="{'width':'100%', 'margin-bottom': '4px'}" 
            [options]="salaryTypeCategoryOptions" 
            [(ngModel)]="selectedSalaryTypeCategory"
            placeholder="---"
        ></p-dropdown>

        <p-tree 
            [style]="{'width':'100%', 'height': '300px'}" 
            [value]="nodes" 
            selectionMode="checkbox" 
            [(selection)]="selection"
        ></p-tree>
    </span>
    <footer>
        <div class="ui-dialog-buttonpane ui-helper-clearfix">
            <button type="button" pButton (click)="onImport($event)" label="{{ 'COMMON.IMPORT' | translate }}"></button>
        </div>
    </footer>
</p-dialog>
