import {Injectable} from '@angular/core';
import {ToolbarItemDisabledCheckInterface} from './toolbar-item-disabled-check-factory.service';
import {ToolbarItemSaveDisabledCheckService} from './toolbar-item-save-disabled-check.service';

@Injectable()
export class ToolbarItemAddWhenComponentIsNotChangedDisabledCheckService extends ToolbarItemSaveDisabledCheckService
  implements ToolbarItemDisabledCheckInterface {

  isDisabled(): boolean {
    const masterEntity = this.component.getElementContext().getMasterElementContext().component.getSelectedEntity();

    return this.isDisabledGlobally() || !masterEntity || !masterEntity['id'];
  }
}
