import { Branch } from '../branch/branch';
import { User } from '../user/user';

interface ObjectWithId {
  id: number;
}

interface EmbeddedEntities {
  branches?: Branch[];
}

export class Organisation {

  public static readonly LOCAL_STORAGE_NAME = 'organisation-local-storage';

  id: number;
  name: string;
  status: string;
  hasViewPermissions: boolean;
  defaultRole: string;
  additionalRoles: string[];
  branches: Branch[];
  currentlyUpdatedStart: Date;
  currentlyUpdatedBy: User;

  _embedded?: EmbeddedEntities;

  constructor(
    id?: number,
    name?: string,
    status?: string,
    defaultRole?: string,
    additionalRoles?: string[],
    branches?: Branch[],
    currentlyUpdatedStart?: Date,
    currentlyUpdatedBy?: User
  ) { }
}
