import {ChangeDetectorRef, Component, OnDestroy, OnInit} from '@angular/core';
import {takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs';
import {CalendarLocaleService} from '../../../../../../core/service/locale/calendar-locale.service';
import {Entity} from '../../../../../helpers/entity';
import {UserWeeklyStatService} from '../services/user-weekly-stat.service';

interface UserWeeklyStat {
  user: Record<string, any>;
  plan: number;
  real: number;
  holiday: number;
  ill: number;
  noAssignment: number;
}

@Component({
  selector: 'app-custom-staff-cockpit-user-weekly-stat',
  styleUrls: ['./staff-cockpit-user-weekly-stat.component.scss'],
  templateUrl: './staff-cockpit-user-weekly-stat.component.html',
  providers: [CalendarLocaleService, UserWeeklyStatService]
})
export class StaffCockpitUserWeeklyStatComponent implements OnInit, OnDestroy {

  public isLoading = true;
  public unsubscribe = new Subject<void>();
  public data: { [userId: number]: UserWeeklyStat} = {};

  ngOnInit(): void {
    this.userWeeklyStatService.getStats()
      .pipe(
        takeUntil(this.unsubscribe)
      ).subscribe((stats) => {
      this.data = this.buildData(stats);
      this.isLoading = false;
      this.cdr.detectChanges();
    });
  }

  constructor(
    private calendarLocaleService: CalendarLocaleService,
    private cdr: ChangeDetectorRef,
    private userWeeklyStatService: UserWeeklyStatService
  ) {
  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  private buildData(stats): { [userId: number]: UserWeeklyStat } {
    const data = {};

    for (const stat of stats) {
      const userId = Entity.getValue(stat, 'user.id');

      if (!data[userId]) {
        data[userId] = {
          user: Entity.getValue(stat, 'user'),
          plan: 0,
          real: 0,
          holiday: 0,
          ill: 0,
          noAssignment: 0,
          weekNumber: Entity.getValue(stat, 'weekNumber')
        };
      }

      data[userId].plan += stat.plan;
      data[userId].real += stat.real;
      data[userId].holiday += stat.holiday;
      data[userId].ill += stat.ill;
      data[userId].noAssignment += stat.noAssignment;
    }

    return data;
  }
}

