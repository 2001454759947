
import {map} from 'rxjs/operators';
import { AbstractExecutionStep } from '../../../core/executor/abstract-execution-step';
import { Observable } from 'rxjs';
import { ExecutionStepStatus } from '../../../core/executor/execution-step-status';
import {AbstractGenericGridComponent} from '../../content-renderer/elements/abstract-generic-grid.component';

export class ComponentRefreshSingleEntityExecutionStep extends AbstractExecutionStep {

  public doExecute(): Observable<ExecutionStepStatus> {
    const payloadValue = this.getPayload().getValue(),
      component = payloadValue.component,
      entity = payloadValue.entity;

    if (!(component instanceof AbstractGenericGridComponent)) {
      return this.getFailObservable('You need to pass AbstractGenericGridComponent as Payload value!');
    }

    return this.doRefresh(component, entity);
  }

  private doRefresh(component: AbstractGenericGridComponent, entity: any): Observable<ExecutionStepStatus> {
    return component.reloadEntities([entity]).pipe(
      map(() => {
        return {status: true, content: null};
      }));
  }
}
