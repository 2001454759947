
<div class="dialog-close-button-container ui-helper-clearfix ng-tns-c8-6">
  <a (click)="onCloseDialog($event)" class="ng-tns-c8-6 ui-dialog-titlebar-close ui-corner-all">
    <span class="dialog-close-button fa fa-fw fa-close"></span>
  </a>
</div>
<div class="module-in-dialog" style="height: 100%">
  <app-content-renderer
    *ngIf="module"
    appSeleniumDirective
    [isDialog]="true"
    [entity]="entity"
    [masterFilterField]="masterFilterField"
    [masterFilterValue]="masterFilterValue"
    [justAFilter]="justAFilter"
    [module]="module"
  ></app-content-renderer>
</div>
