import {Component, Input, OnInit, AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {ElementInputComponent} from './element-input.component';
import {FormElementAction} from '../models/form';
import {ElementInputNumber} from '../models';
import {FormService} from '../form.service';
import {FormViewerService} from '../form-viewer.service';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-form-element-input-number',
  template: `
    <div [formGroup]="formGroup">
      <p-spinner
        appSeleniumDirective
        [element]="element"
        appSpinner
        (valueChange)="onValueChange($event)"
        [formControlName]="formControlName"
        [placeholder]="element.placeholder"
        [readonly]="isReadOnly()"
        decimalSeparator= "decimalSeparator"
        thousandSeparator="thousandSeparator"
        [tabIndex]="element.tabIndex"
      ></p-spinner>
    </div>
  `,
  styles: [`
    :host {
      height: 100%;
    }

    ::ng-deep .ui-spinner {
      width: 100%;

      &-input {
        width: 100%;
      }
    }
  `]
})
export class ElementInputNumberComponent extends ElementInputComponent implements OnInit, AfterViewInit {
  @Input() element: ElementInputNumber;

  decimalSeparator = '.';
  thousandSeparator = ',';

  constructor(
    protected formService: FormService,
    public cdr: ChangeDetectorRef,
    protected formViewerService: FormViewerService,
    protected translateService?: TranslateService
  ) {
    super(formService, cdr, formViewerService, translateService);
  }

  onComponentInit() {
    this.setFormControlName();
    this.filterActionAndHandleIt('oninit');
  }

  onComponentChanges(): void {
    this.setupValue();
  }

  ngAfterViewInit() {
  }

  onFormElementAction(action: FormElementAction) {

  }

  public onValueChange(event) {
    const value = event.value;

    this.setValue(value);
  }
}
