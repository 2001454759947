/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "@angular/forms";
import * as i3 from "./generic-detailed-overview.component";
var styles_GenericDetailedOverviewComponent = ["#overview-form {\n        overflow-y: scroll;\n        height: 100%;\n      }"];
var RenderType_GenericDetailedOverviewComponent = i0.ɵcrt({ encapsulation: 0, styles: styles_GenericDetailedOverviewComponent, data: {} });
export { RenderType_GenericDetailedOverviewComponent as RenderType_GenericDetailedOverviewComponent };
function View_GenericDetailedOverviewComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "div", [], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "label", [], null, null, null, null, null)), (_l()(), i0.ɵted(2, null, ["", ":"])), (_l()(), i0.ɵeld(3, 0, null, null, 0, "input", [["class", "form-control"], ["disabled", "disabled"], ["type", "text"]], [[8, "value", 0]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _v.parent.context.$implicit; _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.entity[_v.parent.context.$implicit]; _ck(_v, 3, 0, currVal_1); }); }
function View_GenericDetailedOverviewComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "div", [["class", "form-group"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_GenericDetailedOverviewComponent_2)), i0.ɵdid(2, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.isComplex(_co.entity[_v.context.$implicit]); _ck(_v, 2, 0, currVal_0); }, null); }
export function View_GenericDetailedOverviewComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 12, "form", [["class", "form-vertical form-label-bold"], ["id", "overview-form"], ["novalidate", ""], ["role", "form"]], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "submit"], [null, "reset"]], function (_v, en, $event) { var ad = true; if (("submit" === en)) {
        var pd_0 = (i0.ɵnov(_v, 2).onSubmit($event) !== false);
        ad = (pd_0 && ad);
    } if (("reset" === en)) {
        var pd_1 = (i0.ɵnov(_v, 2).onReset() !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), i0.ɵdid(1, 16384, null, 0, i2.ɵangular_packages_forms_forms_z, [], null, null), i0.ɵdid(2, 4210688, null, 0, i2.NgForm, [[8, null], [8, null]], null, null), i0.ɵprd(2048, null, i2.ControlContainer, null, [i2.NgForm]), i0.ɵdid(4, 16384, null, 0, i2.NgControlStatusGroup, [[4, i2.ControlContainer]], null, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_GenericDetailedOverviewComponent_1)), i0.ɵdid(6, 278528, null, 0, i1.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i0.ɵeld(7, 0, null, null, 1, "button", [["class", "btn btn-default"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onPrevious() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵted(-1, null, ["Previous"])), (_l()(), i0.ɵeld(9, 0, null, null, 1, "button", [["class", "btn btn-default"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onNext() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵted(-1, null, ["Next"])), (_l()(), i0.ɵeld(11, 0, null, null, 1, "button", [["class", "btn btn-primary"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onClose() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵted(-1, null, ["Close"]))], function (_ck, _v) { var _co = _v.component; var currVal_7 = _co.properties; _ck(_v, 6, 0, currVal_7); }, function (_ck, _v) { var currVal_0 = i0.ɵnov(_v, 4).ngClassUntouched; var currVal_1 = i0.ɵnov(_v, 4).ngClassTouched; var currVal_2 = i0.ɵnov(_v, 4).ngClassPristine; var currVal_3 = i0.ɵnov(_v, 4).ngClassDirty; var currVal_4 = i0.ɵnov(_v, 4).ngClassValid; var currVal_5 = i0.ɵnov(_v, 4).ngClassInvalid; var currVal_6 = i0.ɵnov(_v, 4).ngClassPending; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); }); }
export function View_GenericDetailedOverviewComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-generic-detailed-overview", [], null, null, null, View_GenericDetailedOverviewComponent_0, RenderType_GenericDetailedOverviewComponent)), i0.ɵdid(1, 114688, null, 0, i3.GenericDetailedOverviewComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var GenericDetailedOverviewComponentNgFactory = i0.ɵccf("app-generic-detailed-overview", i3.GenericDetailedOverviewComponent, View_GenericDetailedOverviewComponent_Host_0, { element: "element", grid: "grid" }, { close: "close" }, []);
export { GenericDetailedOverviewComponentNgFactory as GenericDetailedOverviewComponentNgFactory };
