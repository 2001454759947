<div class="block-ui-container">
  <ng-container *ngIf="getFormLoadingError() | isDefined">
    <div class="error-container">
      <div *ngFor="let error of getFormLoadingError()" class="error-text">
        <div class="error-message" *ngIf="error.message">
          <b><span class="text-red">[ERROR]</span></b> <span [innerHTML]="error.message"></span>
        </div>
        <div class="error-detail" *ngIf="error.detail">
          <span [innerHTML]="error.detail"></span>
        </div>
      </div>
    </div>
  </ng-container>

  <app-generic-loading *ngIf="formIsLoading"></app-generic-loading>

  <ng-container *ngIf="form && form?.elements && formGroup">
    <div style="height: 100%" fxLayoutAlign="start stretch" fxLayout="column" [style.background]="formBackground">
      <!-- @todo Move out toolbar and create a new element for the combination of toolbars and form. After that we can
  also move out the save functions from a viewer -->
      <div fxFlex="none" *ngIf="element && element.topToolbarItems && element.topToolbarItems.length > 0">
        <p-toolbar styleClass="no-round-corner">
          <app-generic-toolbar [params]="getToolbarExtraParams()" [isDisabled]="isToolbarDisabled" [entity]="entity" [items]="element.topToolbarItems" [additionalItems]="element.additionalToolbarItems"></app-generic-toolbar>
        </p-toolbar>
      </div>

      <h2 fxFlex="nogrow" class="form-header" *ngIf="form.showTitle">
        {{form.title}}
      </h2>
      <form #formElement fxFlex="grow" [formGroup]="formGroup" [style.borderLeft]="getFormLeftBorder()" [style.borderRight]="getFormRightBorder()">
        <app-form-element *ngFor="let element of form.elements" [moduleElement]="moduleElement" [form]="form" [formGroup]="formGroup" [element]="element" [entity]="entity" [(selectedElement)]="selectedElement" [(actionParams)]="actionParams" [editMode]="editMode"></app-form-element>
      </form>

      <div fxFlex="none" *ngIf="element && element.statusBarItems && element.statusBarItems.length > 0">
        <p-toolbar styleClass="no-round-corner">
          <app-generic-toolbar [params]="getToolbarExtraParams()" [isDisabled]="isToolbarDisabled" [entity]="entity" [items]="element.statusBarItems"></app-generic-toolbar>
        </p-toolbar>
      </div>
    </div>
    <app-dialog-add-address *ngIf="showDialogAddAddress" [(visible)]="showDialogAddAddress" (onAdd)="onDialogAddAddress($event)" (onHide)="actionParams = undefined;"></app-dialog-add-address>
  </ng-container>
</div>
