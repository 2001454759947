import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {ModuleState} from '../../../shared/content-renderer/services/module-state';
import {GenericElementAbstract} from '../../../shared/content-renderer/elements/generic-element-abstract.component';

@Injectable()
export class ModuleStateInspectorService {
  /**
   * @param {ModuleState} mainModule
   * @param {boolean} isMaster
   * @param {boolean} isIndependant
   * @param {boolean} hasSlaves
   * @returns {GenericElementAbstract[]}
   */
  public getModuleComponents(mainModule: ModuleState,
                                isMaster: boolean = true,
                                isIndependant: boolean = true,
                                hasSlaves: boolean = true): GenericElementAbstract[] {
    const components = [];

    for (const component of mainModule.getComponents()) {
      const elementContext = component.getElementContext();

      if (elementContext && elementContext.isMaster === isMaster &&
        elementContext.isIndependant === isIndependant
      ) {

        if (hasSlaves && elementContext.slaveElementContexts.length > 0) {
          components.push(component);
        }

        if (!hasSlaves && elementContext.slaveElementContexts.length === 0) {
          components.push(component);
        }
      }
    }

    return components;
  }

  /**
   * @param {ModuleState} mainModule
   * @returns {GenericElementAbstract[]}
   */
  public getPartComponents(mainModule: ModuleState): GenericElementAbstract[] {
    const components = [];

    for (const part of mainModule.getParts()) {
      for (const component of part.getComponents()) {
        components.push(component);
      }
    }

    return components;
  }

  /**
   * @param {ModuleState} mainModule
   * @returns {GenericElementAbstract[]}
   */
  public getDetailsMasterComponent(mainModule: ModuleState): GenericElementAbstract|null {
    let found = null;

    for (const component of mainModule.getComponents()) {
      if (component.getElementContext() && component.getElementContext().isDetailsViewMaster) {
        found = component;
        break;
      }
    }

    return found;
  }

  public findFirstMainComponentByType(mainModule: ModuleState, componentType: any): GenericElementAbstract|null {
    let found = null;

    for (const component of mainModule.getComponents()) {
      if (component.getElementContext() && component.getElementContext().isMaster && component.getElementContext().isIndependant && component instanceof componentType) {
        found = component;
        break;
      }
    }

    return found;
  }

  public findFirstMainComponent(mainModule: ModuleState): GenericElementAbstract|null {
    let found = null;

    for (const component of mainModule.getComponents()) {
      if (component.getElementContext() && component.getElementContext().isMaster && component.getElementContext().isIndependant) {
        found = component;
        break;
      }
    }

    return found;
  }
}
