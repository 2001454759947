import {Injectable} from '@angular/core';
import {QuestionnaireComponent} from '../questionnaire.component';
import {ToolbarItem} from '../../../../../services/element/toolbar-item';
import {PermissionService} from '../../../../../services/permission/permission.service';

@Injectable()
export class QuestionnaireToolbarItemsService {

  public questionnaireComponent: QuestionnaireComponent;

  public constructor(
    private permissionService: PermissionService
  ) {

  }

  public initToolbarItems(): void {
    if (this.questionnaireComponent.element && this.questionnaireComponent.element.toolbarItems) {
      this.questionnaireComponent.toolbarItems = this.questionnaireComponent.element.toolbarItems.filter((toolbarItem) => {
        return toolbarItem.position === ToolbarItem.POSITION_TOP &&
          this.elementToolbarItemViewIsGranted(toolbarItem, this.questionnaireComponent.toolbarItems);
      });
    }

    if (this.questionnaireComponent.element && this.questionnaireComponent.element.statusBarItems) {
      this.questionnaireComponent.statusBarItems = this.questionnaireComponent.element.statusBarItems.filter((statusbarItem) => {
        return this.elementToolbarItemViewIsGranted(statusbarItem, this.questionnaireComponent.statusBarItems);
      });
    }
  }

  private elementToolbarItemViewIsGranted(elementToolbarItem, moduleElementToolbarItems) {
    let isViewGranted = false;

    const moduleElementToolbarItem = moduleElementToolbarItems.find(
      (aModuleElementToolbarItem) => aModuleElementToolbarItem.id === elementToolbarItem.id
    );

    if (moduleElementToolbarItem) {
      isViewGranted = moduleElementToolbarItem.visible && this.permissionService.isGranted(moduleElementToolbarItem, 'view');
    }

    return isViewGranted;
  }
}
