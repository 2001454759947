import {AbstractFormActionHandler} from '../abstract-form-action-handler';
import {GenericElementAbstract} from '../../../../content-renderer/elements/generic-element-abstract.component';
import {ElementContext} from '../../../../content-renderer/services/ElementContext';
import {FormService} from '../../../form.service';
import {ElementInputAutocomplete} from '../../../models';
import {FormViewerComponent} from '../../../form-viewer.component';

export class SetupContactPersonFormActionHandler extends AbstractFormActionHandler {

  public constructor(
    protected formService: FormService
  ) {
    super();
  }

  public handleAction(): void {
    const contactPerson = this.getEntity();

    if (this.isValid(contactPerson)) {
      this.setupWhenOfferIsMaster();
    }
  }

  private isValid(contactPerson): boolean {
    return contactPerson && contactPerson.fqn === 'PhoenixBundle\\Entity\\ContactPerson';
  }

  private setupWhenOfferIsMaster(): void {
    const entity = this.getEntity();

    const component: FormViewerComponent = this.getComponent(),
      masterElementContext: ElementContext = component ? component.masterElementContext : null,
      masterElementContextEntity = masterElementContext && masterElementContext.component &&
      masterElementContext.component instanceof GenericElementAbstract ?
        masterElementContext.component.getSelectedEntity() :
        null;

    if (masterElementContextEntity && masterElementContextEntity.fqn === 'PhoenixBundle\\Entity\\Offer' &&
      masterElementContextEntity.customer
    ) {
      const addressAutocomplete =  this.formService.getElementBy(this.getForm(), 'datamodelField', 'address') || null;

      if (addressAutocomplete && addressAutocomplete instanceof ElementInputAutocomplete) {
        component.getElementContext().addMasterEntity({
          datamodelId: 0,
          filterType: 'masterEntity',
          name: 'customer',
          value: masterElementContextEntity.customer.id,
          entity: masterElementContextEntity.customer
        });

        entity._embedded = {...entity._embedded, ...{
          customer: masterElementContextEntity.customer
        }};

        component.setEntity({...entity, ...{
          customer: masterElementContextEntity.customer
        }});

        component.emptyEntity = {...component.emptyEntity, ... {
          customer: masterElementContextEntity.customer
        }};
      }
    }
  }
}
