import {ChangeDetectionStrategy, Component} from '@angular/core';
import { AbstractGenericGridComponent } from 'app/shared/content-renderer/elements/abstract-generic-grid.component';
import {ToolbarItemOpenModule} from '../toolbar-item-open-module.component';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-toolbar-item-open-module-depending-on-order-is-mediation-value',
  template: ''
})
export class ToolbarItemOpenModuleDependingOnOrderIsMediationValueComponent extends ToolbarItemOpenModule {

  protected doOpenModuleInGridContext(gridComponent: AbstractGenericGridComponent) {
    this.toolbarItem = this.getToolbarItem();
    this.entity = gridComponent.getSelectedEntity();

    if (!this.validateInGridContext(gridComponent)) {
      return this.messageGrowlService.error(this.getValidateMessage());
    }

    const targetModule = this.entity.isMediation ? 923 : 177;

    this.doubleClickService.setComponent(gridComponent)
      .setDialogOptions({
        height: +this.getToolbarItem().targetDialogHeight,
        width: +this.getToolbarItem().targetDialogWidth
      })
      .handleOpenModule(gridComponent, this.getToolbarItem().target, targetModule, this.entity);
  }

}
