import {AbstractFormActionHandler} from '../abstract-form-action-handler';
import {FormActionsEvents} from '../../../models/form';
import {FormViewerComponent} from '../../../form-viewer.component';
import {LocationService} from '../../../../services/location.service';

export class HandleOneToOneEntityFormActionHandler extends AbstractFormActionHandler {

  public constructor(
    private locationService: LocationService
  ) {
    super();
  }

  public handleAction(): void {
    const action = this.getAction(),
      component = this.getComponent();

    if (action && action.command === FormActionsEvents.ON_INIT && component instanceof FormViewerComponent) {
      component.changeFormEntity({id: this.locationService.getParam('id')}).subscribe();
    }
  }
}
