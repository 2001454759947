import {JobInterface} from '../../../../core/job-runner/job/job.interface';
import {JobContext} from '../../../../core/job-runner/context/job.context';
import {Observable} from 'rxjs';
import {JobExecutionStatus} from '../../../../core/job-runner/job/job-execution-status';
import {ConditionInterface} from '../../../../core/job-runner/condition/condition.interface';
import {AbstractJob} from '../../../../core/job-runner/job/abstract.job';
import {AbstractGenericGridComponent} from '../../../content-renderer/elements/abstract-generic-grid.component';

export class SetupWorkHourDocuwareDocumentApprovalPeriodJob extends AbstractJob implements JobInterface {

  run(context: JobContext, condition: ConditionInterface): Observable<JobExecutionStatus> {
    return Observable.create((observer) => {
      const component = context.component,
        leasedEmployees: any[] = condition.getPayload().leasedEmployees,
        workYear: any[] = condition.getPayload().workYear,
        workMonth: any[] = condition.getPayload().workMonth;

      if (component instanceof AbstractGenericGridComponent) {
        component.embeddedFields = [...component.embeddedFields, 'docuwareDocument'];

        const ids = [];
        for (const leasedEmployee of leasedEmployees) {
          if (leasedEmployee.id) {
            ids.push(leasedEmployee.id);
          }
        }

        component.staticFilters.push({
          field: 'docuwareDocument.leasedEmployee',
          value: 'in:' + ids.join(',')
        });

        component.staticFilters.push({
          field: 'validityYear',
          value: 'equal:' + workYear
        });

        component.staticFilters.push({
          field: 'validityMonth',
          value: 'equal:' + workMonth
        });

        observer.next({
          status: true,
          content: null,
          jobId: this.jobId
        });

        observer.complete();
      } else {
        observer.next({
          status: false,
          content: 'Wrong component set',
          jobId: this.jobId
        });

        observer.complete();
      }
    });
  }
}
