import {map} from 'rxjs/operators';
import {Observable} from 'rxjs';
import {AbstractExecutionStep} from '../../../../../../core/executor/abstract-execution-step';
import {ExecutionStepStatus} from '../../../../../../core/executor/execution-step-status';

export class RecalculateWorkHourEntryExecutorStep extends AbstractExecutionStep {

  public doExecute(): Observable<ExecutionStepStatus> {
    const payloadValue = this.getPayload().getValue(),
      entity = payloadValue.entity,
      doRecalculate = payloadValue.doRecalculate;

    return this.doRecalculate(entity, doRecalculate);
  }

  private doRecalculate(entity: any, doRecalculate): Observable<ExecutionStepStatus> {
    return this.genericCrudService
      .customPut(`phoenix/workhourweeklyoverviews/recalculate/${doRecalculate}`, entity)
      .pipe(
        map(() => {
          return {status: true, content: null};
        }));
  }
}
