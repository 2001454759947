
import {map, takeUntil} from 'rxjs/operators';
import { FieldMetadataGrid } from 'app/shared/services/module/module-element-field-metadata-grid';
import { AbstractAssociatedInlineEditor } from './abstract-associated-inline-editor';
import {GenericColumnFilterService} from '../filter/grid/generic-column-filter.service';
import {Observable, of} from 'rxjs';
import {ChangeDetectorRefHelper} from '../../../../helpers/change-detector-ref.helper';

export class AutocompleteInlineEditor extends AbstractAssociatedInlineEditor {

    public getValue(entity: any) {
        let entityEmbeddedEntity = entity[this.getEntityColumnProperty()];

        if (!entityEmbeddedEntity && entity._embedded) {
            entityEmbeddedEntity = entity._embedded[this.getEntityColumnProperty()];
        }

        return entityEmbeddedEntity ? entityEmbeddedEntity : null;
    }

    public onEdit(entity: any, event: any) {

        if (this.column.isAssociatedField) {
            const value = event.id;

            const entityValue = this.getEntityFromAssociatedOptions(value, true);

            if (entityValue) {
                this.changeEntityColumnProperty(entityValue);
            }
        }
    }

    public onSearch(entity: any, event: any): Observable<any> {
        return this.doSearch(event);
    }

    public onBlur(entity: any, event: any) {

    }

    public onFocus(entity: any, event: any): Observable<any> {
      return of(null);
    }

    private doSearch(event: {query: string}): Observable<any[]> {
      const query = event.query,
        params = {};
      let apiRoute = `${this.column.associationEndpoint}/offset/0/limit/50`;

      if (this.column.firstAssociationOrderBy) {
        if (this.column.secondAssociationOrderBy) {
          apiRoute += `/orderby/${this.column.firstAssociationOrderBy},${this.column.secondAssociationOrderBy}/${this.column.firstAssociationOrderByOrientation},${this.column.secondAssociationOrderByOrientation}?search=${query}&clause=orWhere`;
        } else {
          apiRoute += `/orderby/${this.column.firstAssociationOrderBy}/${this.column.firstAssociationOrderByOrientation}?search=${query}&clause=orWhere`;
        }
      } else {
        apiRoute += `/orderby/id/asc?search=${query}&clause=orWhere`;
      }

      if (this.column.firstAssociationOrderType) {
        if (this.column.secondAssociationOrderType) {
          params['orderType'] = `${this.column.firstAssociationOrderType},${this.column.secondAssociationOrderType}`;
        } else {
          params['orderType'] = `${this.column.firstAssociationOrderType}`;
        }
      }

      if (this.column.customAutocompleteFilters) {
        for (const customFilter of this.column.customAutocompleteFilters) {
          if (customFilter.name && customFilter.value) {
            params[customFilter.name] = customFilter.value;
          }
        }
      }

      for (const masterEntity of this.component.getElementContext().getMasterEntities()) {
        if (masterEntity.name && masterEntity.value) {
          params[masterEntity.name] = masterEntity.value;
        }
      }

      const columnFilters = GenericColumnFilterService.createFilters(this.column.field, this.component);

      for (const columnFilter of columnFilters) {
        if (columnFilter.column) {
          params[columnFilter.column] = columnFilter.value;
        } else {
          for (const key of Object.keys(columnFilter.value)) {
            if (typeof columnFilter.value[key] !== 'undefined') {
              params[key] = columnFilter.value[key];
            }
          }
        }
      }

      return this.genericCrudService.getEntities(apiRoute, '', params).pipe(
        takeUntil(this.component.unsubscribe),
        map((entries) => {
        this.component.associatedOptions[this.column.entityName] = [];
        let entities = [];
        if (entries.data) {
          entities = entries.data;
        } else {
          entities = entries;
        }
        entities.map((entry) => {
          entry.label = FieldMetadataGrid.getOptionLabel(entry, this.column.field);

          if (entry.label) {
            this.component.associatedOptions[this.column.entityName].push(entry);
          }
        });

        this.component.associatedOptions[this.column.entityName] = [...this.component.associatedOptions[this.column.entityName]];

        ChangeDetectorRefHelper.detectChanges(this.component);
        return this.component.associatedOptions[this.column.entityName];
      }));
    }

}
