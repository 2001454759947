
    <div class="ui-g-12">
      <div class="ui-g-2">
          {{ 'COMMON.TYPE' | translate }}:
      </div>
      <div class="ui-g-10">
        <p-dropdown
          [(ngModel)]="selectedOption"
          [options]="options"
          (onChange)="memoTypeChanged.emit($event.value)"
          appendTo="body"
          placeholder="---"
          [filter]="true"
          [style]="{'width':'100%'}"
        ></p-dropdown>
      </div>
    </div>
  