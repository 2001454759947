import { AbstractFormActionConditionHandler } from './abstract-form-action-condition-handler';
import { FormService } from 'app/shared/form-viewer/form.service';

export class IsFormValueNotEmptyConditionHandler extends AbstractFormActionConditionHandler {

    public constructor(
        private formService: FormService
    ) {
        super();
    }

    public isValid(): boolean {
        let isValid = false,
            formGroup = this.getFormGroup(),
            formElementHashId = this.getParamValue('formElement'),
            formElement = this.formService.getElementBy(this.getForm(), 'objectHashId', formElementHashId)

        let controlField = this.getFormGroup().get(formElement.datamodelField + '_h_r_f_e_' + formElementHashId);

        if (controlField && controlField.value instanceof Object) {
          if(controlField.value.value) {
            isValid = true;
          }
        }else{
          if(controlField && controlField.value) {
            isValid = true;
          }
        }

        return isValid;
    }
}
