
    <div fxLayout="row">
        <div fxFlex="80"
           appSeleniumDirective
           [element]="element"
        >
            {{getTemplateText()}}
        </div>
        <div fxFlex="20" *ngIf="((element?.icon | isDefined) && (element?.icon?.relativePath | isDefined))">
            <img height="16" width="16"
              appSeleniumDirective
              [element]="element"
              [src]="iconBaseUrl + '/' + element?.icon?.relativePath"
              [alt]="element?.icon?.name"
              (click)="onClickIcon()"
            />
        </div>
    </div>
    <span class="hidden-content"></span>
  