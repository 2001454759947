import {Observable} from 'rxjs/Observable';
import {UserSessionService} from '../../../../../../core/service/user-session.service';
import {AuthenticationService} from '../../../../../../core/authentication/authentication.service';
import {GenericCrudService} from '../../../../../services/generic-crud.service';
import {DateHelper} from '../../../../../helpers/date.helper';
import {Branch} from '../../../../../services/branch/branch';
import {switchMap} from 'rxjs/operators';

export class StatHoursPlanRealsService {

  public constructor(
    private userSession: UserSessionService,
    private authenticationService: AuthenticationService,
    private genericCrudService: GenericCrudService
  ) {

  }

  public getStats(): Observable<any[]> {
    const branchOffice = this.userSession.get(Branch.LOCAL_STORAGE_NAME);
    return this.genericCrudService.getEntity('app/branchoffices', branchOffice.id, '', {
      embedded: 'none'
    }).pipe(
      switchMap((currBranchOffice) => {
        const params = {
          currentYear: new Date().getFullYear(),
          embedded: 'branchOffice',
          branchOffice: currBranchOffice.id,
        }
        return this.genericCrudService.getEntities('phoenix/usermonthlyemployeeactivitydatas', '', params)
      })
    );
  }
}
