import { Injectable } from '@angular/core';
import { Subject ,  Subscription } from 'rxjs';
import { Location } from '@angular/common';

@Injectable()
export class CompanyCommunicationService {

  constructor(
  ) {
  }

  private companyChanged = new Subject<any>();
  private companyAdded = new Subject<any>();

  companyChanged$ = this.companyChanged.asObservable();
  companyAdded$ = this.companyAdded.asObservable();

  onCompanyChanged(company: any) {
    this.companyChanged.next(company);
  }

  onCompanyAdded(company: any) {
    this.companyAdded.next(company);
  }

}
