import {ChangeDetectionStrategy, Component} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {ToolbarItemAbstract} from '../toolbar-item-abstract.component';
import {GenericCrudService} from '../../../../../../services/generic-crud.service';
import {AbstractGenericGridComponent} from '../../../../abstract-generic-grid.component';
import {MessageGrowlService} from '../../../../../../../core/message/message-growl.service';
import {FormViewerComponent} from '../../../../../../form-viewer/form-viewer.component';

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'app-toolbar-item-add-pre-calculation-article',
    template: ''
})
export class ToolbarItemAddPreCalculationArticleComponent extends ToolbarItemAbstract {

  constructor(
    protected genericCrudService: GenericCrudService,
    protected messageGrowlService: MessageGrowlService,
    protected translate: TranslateService
  ) {
    super();
  }

  public click() {
    const component = this.getComponent();

    if (component instanceof AbstractGenericGridComponent && component.getSelectedEntity()) {
      const masterForm = component.getElementContext().getMasterElementContext().component;

      if (masterForm instanceof FormViewerComponent && masterForm.getSelectedEntity()) {
        const entity = masterForm.getSelectedEntity();
        this.genericCrudService.customPost(`phoenix/precalculationarticles/${entity.id}/precalculation`, {}).subscribe((timeEntries) => {
          this.messageGrowlService.success(this.translate.instant('COMMON.SUCCESS') + '.');
          component.loadEntities().subscribe();
        });
      } else {
        return this.messageGrowlService.error('Something went wrong!');
      }
    }
  }
}
