import { ElementContext, MasterEntityConfig } from './ElementContext';
import * as i0 from "@angular/core";
import * as i1 from "../../services/entity-hydrator.service";
import * as i2 from "../../services/location.service";
const STACK_LIMIT = 10;
export class ElementsStackService {
    /**
     *
     */
    constructor(entityHydrator, locationService) {
        this.entityHydrator = entityHydrator;
        this.locationService = locationService;
        this.elementStack = [];
    }
    /**
     *
     * @returns {ElementContext[]}
     */
    getAll() {
        return this.elementStack;
    }
    /**
     *
     * @param {ElementContext} elementContext
     * @returns {ElementsStackService}
     */
    add(elementContext) {
        if (!this.exists(elementContext)) {
            this.elementStack.push(elementContext);
            if (typeof elementContext.moduleElement.master !== 'undefined' && elementContext.moduleElement.master !== null
                && elementContext.moduleElement.master.id > 0) {
                const masterElementContext = this.findById(elementContext.moduleElement.master.id);
                if (masterElementContext) {
                    elementContext.setMasterElementContext(masterElementContext);
                    masterElementContext.addSlaveElementContext(elementContext);
                }
            }
            this.crossCheckDependencies(elementContext);
        }
        // prevent memory leak
        if (this.elementStack.length >= STACK_LIMIT) {
            this.elementStack.splice(0, 1);
        }
        return this;
    }
    /**
     *
     * @param {ElementContext} elementContext
     * @returns {ElementsStackService}
     */
    remove(elementContext) {
        if (elementContext && this.exists(elementContext)) {
            const elementStackId = this.elementStack.findIndex((element) => {
                return elementContext.id == element.id && elementContext.type == element.type;
            });
            if (elementStackId !== -1) {
                this.elementStack.splice(elementStackId, 1);
            }
        }
        return this;
    }
    /**
     *
     * @param {ElementContext} elementContext
     * @returns {boolean}
     */
    exists(elementContext) {
        return this.findByIdAndType(elementContext.id, elementContext.type) != null;
    }
    /**
     *
     * @param {number} id
     * @returns {ElementContext}
     */
    findById(id) {
        for (const context of this.elementStack) {
            if (context.id == id) {
                return context;
            }
        }
        return null;
    }
    /**
     *
     * @param {number} id
     * @returns {ElementContext}
     */
    getByModuleId(id) {
        return this.elementStack.filter((elementContext) => { return elementContext.moduleElement.moduleId === id; });
    }
    findAllSubviewElements() {
        return this.elementStack.filter((elementContext) => { return elementContext.isSubView; });
    }
    /**
     *
     * @param {number} id
     * @returns {ElementsStackService}
     */
    deleteByModuleId(id) {
        const elements = this.getByModuleId(id);
        for (const element of elements) {
            this.remove(element);
        }
        return this;
    }
    /**
     *
     * @param {number} id
     * @param {ElementType} type
     * @returns {ElementContext}
     */
    findByIdAndType(id, type) {
        for (const context of this.elementStack) {
            if (context.id == id && context.type == type) {
                return context;
            }
        }
        return null;
    }
    /**
     *
     * @param {string} datamodelName
     * @param {ElementType} type
     * @returns {ElementContext}
     */
    findByComponentDatamodelAndType(datamodelName, type) {
        for (const context of this.elementStack) {
            if (context.component && context.component.getElementDatamodelEntityName() == datamodelName && context.type === type) {
                return context;
            }
        }
        return null;
    }
    /**
     *
     * @param {number} id
     * @param entity
     * @returns {ElementsStackService}
     */
    setSelectedEntity(id, entity) {
        const context = this.findById(id);
        if (context) {
            context.setSelectedEntity(entity);
            for (const slaveContext of context.getSlaveElementContexts()) {
                // Now we need to find the filter entity:
                this.setMasterConfig(slaveContext, entity, slaveContext.moduleElement.masterFilterField, slaveContext.moduleElement.masterFilterValueField);
            }
        }
        return this;
    }
    setMasterConfig(elementContext, entity, fieldName, fieldValeName = '') {
        let masterEntityConfig = elementContext.findMasterEntity(fieldName);
        if (masterEntityConfig == null) {
            masterEntityConfig = new MasterEntityConfig();
            masterEntityConfig.name = fieldName;
            masterEntityConfig.filterType = 'masterEntity';
            elementContext.addMasterEntity(masterEntityConfig);
        }
        if (fieldValeName && this.entityHydrator.getEntityPropertyValue(entity, fieldValeName)) {
            const value = this.entityHydrator.getEntityPropertyValue(entity, fieldValeName);
            if (typeof value === 'object' && value['id']) {
                masterEntityConfig.value = value['id'];
            }
            else {
                masterEntityConfig.value = value;
            }
        }
        else {
            masterEntityConfig.value = entity && entity.id ? entity.id : null;
        }
        masterEntityConfig.entity = entity;
        return this;
    }
    createContext(component) {
        const isSlave = component.moduleElement && typeof component.moduleElement.master !== 'undefined'
            && component.moduleElement.master !== null && component.moduleElement.master.id > 0;
        const isSubView = this.locationService.hasParam('parent-module')
            || (this.locationService.hasParam('id') && this.locationService.hasParam('master-entity'));
        const elementContext = new ElementContext(component.moduleElement.id, component.elementType, component, component.moduleElement, !isSlave, isSlave, !isSlave, isSubView, component.isPart, null, component.masterElementContext, null, null, null, component.isDialog, component.moduleElement.isMaster);
        if (isSubView) {
            const elementConfig = new MasterEntityConfig();
            elementConfig.value = this.locationService.getParam('id');
            elementConfig.name = this.locationService.hasParam('master-entity')
                ? this.locationService.getParam('master-entity') : component.moduleElement.masterFilterField;
            elementConfig.datamodelId = 0; // For now - no way to find out.
            elementConfig.filterType = 'subViewMasterEntity';
            elementContext.addMasterEntity(elementConfig);
        }
        // Now, some kinky stuff - parts in dialogs - hit it:
        if (component.isDialog && isSubView && component.selectedMasterEntity && component.moduleElement.masterFilterField) {
            const elementConfig = new MasterEntityConfig();
            elementConfig.value = component.selectedMasterEntity.id;
            elementConfig.name = component.moduleElement.masterFilterField;
            elementConfig.datamodelId = 0; // For now - no way to find out.
            elementConfig.filterType = 'subViewMasterEntity';
            elementContext.addMasterEntity(elementConfig);
        }
        if (component.selectedMasterEntity && component.masterEntityField) {
            const elementConfig = new MasterEntityConfig();
            elementConfig.value = component.selectedMasterEntity.id;
            elementConfig.name = component.masterEntityField;
            elementConfig.datamodelId = 0; // For now - no way to find out.
            elementConfig.filterType = 'masterEntity';
            elementContext.setSelectedMasterEntity(component.selectedMasterEntity).addMasterEntity(elementConfig);
        }
        if (component.masterFilterField && component.masterFilterValue) {
            const elementConfig = new MasterEntityConfig();
            elementConfig.value = component.masterFilterValue;
            elementConfig.name = component.masterFilterField;
            elementConfig.datamodelId = 0; // For now - no way to find out.
            elementConfig.filterType = 'masterEntity';
            elementContext.addMasterEntity(elementConfig);
        }
        return elementContext;
    }
    /**
     *
     * @param {number} id
     * @returns {any}
     */
    getSelectedMasterEntity(id) {
        const elementContext = this.findById(id);
        let masterContext = null;
        if (elementContext && elementContext.moduleElement && elementContext.moduleElement.master) {
            masterContext = this.findById(elementContext.moduleElement.master.id);
        }
        return (masterContext) ? masterContext.selectedEntity : null;
    }
    crossCheckDependencies(elementContext) {
        if (elementContext.isMaster && elementContext.slaveElementContexts.length === 0) {
            /**
             * @var ElementContext context
             */
            for (const context of this.elementStack) {
                if (typeof context.moduleElement.master !== 'undefined' && context.moduleElement.master !== null
                    && context.moduleElement.master.id === elementContext.moduleElement.id) {
                    context.setMasterElementContext(elementContext);
                    elementContext.addSlaveElementContext(context);
                }
            }
        }
    }
}
ElementsStackService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ElementsStackService_Factory() { return new ElementsStackService(i0.ɵɵinject(i1.EntityHydrator), i0.ɵɵinject(i2.LocationService)); }, token: ElementsStackService, providedIn: "root" });
