import { AbstractFormActionConditionHandler } from './abstract-form-action-condition-handler';
import { FormService } from '../../form.service';

export class IsChangingElementConditionHandler extends AbstractFormActionConditionHandler {

    public constructor(
        private formService: FormService
    ) {
        super();
    }

    public isValid(): boolean {
        let formElementHashId = this.getParamValue('formElement'),
            element = this.getElement();

        return formElementHashId === element.objectHashId;
    }
}
