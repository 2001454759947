
import {of as observableOf,  Observable } from 'rxjs';

import {catchError} from 'rxjs/operators';
import { AbstractAsyncValidator } from './abstract-async-validator';
import { ValidationStatus } from '../services/validation';
import { Constants } from 'app/constants';

export class CustomerDemandValidator extends AbstractAsyncValidator {

  public getConstraint(): any {
    return {
      'name': 'customerDemand',
      'fields': this.getParamValue('statesToCheck')
    };
  }

  public isValid(value: any): boolean {
    return true;
  }

  public getErrorTranslateKey(value: any): string {
    return 'CUSTOMER_DEMAND_EMPTY';
  }

  public getErrorTranslateParams(value: any): any {
    const fields = Object.keys(value);

    return {
      'fieldNames': fields.join()
    };
  }

  public validate(): Observable<ValidationStatus> {
    let observable = observableOf({
      isValid: true,
      errorTranslated: '',
      errorTranslateKey: '',
      errorTranslateParams: null,
      field: this.getField(),
      value: this.getEntityFieldValue()
    });

    if (this.shouldValidate(this.getEntity())) {

      const entityToCheck = Object.assign({}, this.getEntity());

      observable = this.getGenericCrudService().createEntity(`${Constants.APP_API_ROUTE}/validate`, {
        'constraint': this.getConstraint(),
        'entity': this.getEntityHydrator().hydrate(entityToCheck)
      }, false).pipe(catchError((response, caught) => {
        const errors = response.error.errors;

        return observableOf({
          isValid: false,
          errorTranslated: this.getErrorTranslated(errors),
          errorTranslateKey: this.getErrorTranslateKey(this.getEntityFieldValue()),
          errorTranslateParams: this.getErrorTranslateParams({}),
          field: this.getField(),
          value: this.getEntityFieldValue()
        })
      }));
    }

    return observable;
  }

  public shouldValidate(entity: any): boolean {
    const entityState = this.getState(entity),
      statesToCheck = this.getParamValue('statesToCheck') || [];

    const entityIsValid = entity && entity.id;

    return entityIsValid && entityState && statesToCheck.length > 0 && statesToCheck.includes(entityState);
  }

  private getState(entity: any): number | null {
    let state = entity.customerState;

    if (!state && entity._embedded) {
      state = entity._embedded.customerState;
    }

    if (state instanceof Object && state.id) {
      return state.id;
    }

    return state;
  }
}
