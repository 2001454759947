import {Observable, of as observableOf} from 'rxjs';
import {AbstractExecutionStep} from '../../../../core/executor/abstract-execution-step';
import {ExecutionStepStatus} from '../../../../core/executor/execution-step-status';
import {FormViewerComponent} from '../../../form-viewer/form-viewer.component';
import {ElementsStackService} from '../../../content-renderer/services/elements-stack.service';
import {ElementContext, ElementType} from '../../../content-renderer/services/ElementContext';

export class SetupOrganisationModulePartComponentExecutionStep extends AbstractExecutionStep {

  public doExecute(): Observable<ExecutionStepStatus> {
    const payload = this.getPayload();

    if (!(payload.getValue() instanceof FormViewerComponent)) {
      return this.getFailObservable('You need to pass FormViewerComponent as Payload value!');
    }

    return this.doSetup(payload.getValue());
  }

  protected doSetup(formComponent: FormViewerComponent): Observable<ExecutionStepStatus> {
    const elementsStackService = formComponent.elementsStackService,
      elementContext = elementsStackService.findById(formComponent.moduleElement.id)

    if (elementContext && elementContext.isPart) {
      const masterElementContext = this.getMasterElementContext(elementsStackService);

      if (masterElementContext) {
        elementContext.setMasterElementContext(masterElementContext);
        masterElementContext
          .removeSlaveElementContext(elementContext)
          .addSlaveElementContext(elementContext);
      }

      masterElementContext.component.onEntitySelected();
    }

    return observableOf({status: true, content: null });
  }

  protected getMasterElementContext(elementsStackService: ElementsStackService): ElementContext|null {
    for (const elementContext of elementsStackService.getAll()) {
      if (elementContext.type === ElementType.Grid) {
        return elementContext;
      }
    }

    return null;
  }
}
