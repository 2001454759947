import {FormViewerComponent} from '../../../../form-viewer/form-viewer.component';
import {Datamodel} from '../../../datamodel/datamodel';
import {ElementInputListBox} from '../../../../form-viewer/models/element-input-list-box';
import {AbstractLoadWorkplaceSelectedEntitiesStep} from './abstract-load-workplace-selected-entities-step';

export class LoadWorkplaceSelectedMedicalExaminationsStep extends AbstractLoadWorkplaceSelectedEntitiesStep {

  protected getDatamodelApiRout(): string {
    return 'phoenix/selectedmedicalexaminations';
  }

  protected getInput(form: FormViewerComponent): ElementInputListBox {
    for (let input of form.inputElements) {
      if (input instanceof ElementInputListBox && input.datamodel.name === 'PhoenixBundle.SelectedMedicalExamination') {
        return input;
      }
    }

    return null;
  }

}
