import {Component, OnInit, ViewChild} from '@angular/core';
import {AbstractCustomFilterClassComponent} from './abstract-custom-filter-class.component';

@Component({
  selector: 'app-custom-filter-class',
  templateUrl: './custom-filter-class.component.html'
})
export class CustomFilterClassComponent extends AbstractCustomFilterClassComponent implements OnInit {

  @ViewChild('filterComponent', {static: false}) public filterComponent: AbstractCustomFilterClassComponent = null;

  constructor(
  ) {
    super()
  }

  ngOnInit() {

  }
}
