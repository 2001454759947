export class EntityStatus {
  public static readonly ENTITY_CHANGED_FLAG = 'isChanged';
  public static readonly ENTITY_CHANGING_FLAG = 'isChanging';
  public static readonly ENTITY_LOCKED_FLAG = 'isLocked';
  public static readonly ENTITY_VALIDATED_FLAG = 'isValidated';
  public static readonly EMBEDDED_ENTITY_CHANGED_FLAG = 'isEmbeddedEntityChanged';
  public static readonly ENTITY_DRAFT_DELETED_FLAG = 'isDraftDeleted';
  public static readonly ENTITY_DRAFT_BACKEND_DELETE_FLAG = 'isFlaggedForBackendDelete';
  public static readonly ENTITY_INVALID_FLAG = 'isInvalid';
  public static readonly ENTITY_DRAFT_FLAG = 'uniqueId';
}
