
import {of as observableOf, Observable} from 'rxjs';
import {AbstractExecutionStep} from '../../../../core/executor/abstract-execution-step';
import {ExecutionStepStatus} from '../../../../core/executor/execution-step-status';
import {AbstractGenericGridComponent} from '../../../content-renderer/elements/abstract-generic-grid.component';
import {EntityManagerService} from '../../../../core/service/entity-manager/entity-manager.service';
import {MessageGrowlService} from '../../../../core/message/message-growl.service';
import {DatePipe} from '../../../pipes/date.pipe';
import {map} from 'rxjs/operators';

export class SetSubAssignmentStep extends AbstractExecutionStep {
  public doExecute(): Observable<ExecutionStepStatus> {
    const payload = this.getPayload(),
      component = payload.getValue().component,
      entity = payload.getValue().entity;

    if (component instanceof AbstractGenericGridComponent
      && entity.hasOwnProperty('subAssignment')) {
      return this.fetchSubAssignment(component, entity);
    }

    return this.getFailObservable('You need to pass AbstractGenericGridComponent or FormViewerComponent as Payload value!');
  }

  protected fetchSubAssignment(component: AbstractGenericGridComponent, entity) {
    this.getSubAssignment(component, entity).subscribe(() => {
      component.getInlineEditService().markForCheck(entity);
    });
    return observableOf({status: true, content: []});
  }

  private getSubAssignment(component: AbstractGenericGridComponent, entity): Observable<any> {
    component.apiRoutAdditionalParameters['embedded'] = 'none';
    return this.genericCrudService.getEntity('phoenix/leasedemployees', entity.leasedEmployee, '',
      {embedded: 'currentAssignment,currentAssignment.activeSubAssignment'})
      .pipe(map((leasedEmployee) => {
        if (leasedEmployee._embedded.currentAssignment && leasedEmployee._embedded.currentAssignment._embedded.activeSubAssignment) {
          this.getEntityManagerService().persist(entity, {property: 'subAssignment', newValue: leasedEmployee._embedded.currentAssignment._embedded.activeSubAssignment, force: true});
        }
      }));
  }
  private getEntityManagerService(): EntityManagerService|null {
    return this.injector.get(EntityManagerService, null);
  }

}
