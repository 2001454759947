/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./toolbar-item-work-hour-monthly-grid-toggle-between-wage-and-invoice-columns.component";
import * as i2 from "../../../../../../services/module/module-element-field.crud.service";
import * as i3 from "../../../../../../services/request-caching.service";
var styles_ToolbarItemWorkHourMonthlyGridToggleBetweenWageAndInvoiceColumnsComponent = [];
var RenderType_ToolbarItemWorkHourMonthlyGridToggleBetweenWageAndInvoiceColumnsComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_ToolbarItemWorkHourMonthlyGridToggleBetweenWageAndInvoiceColumnsComponent, data: {} });
export { RenderType_ToolbarItemWorkHourMonthlyGridToggleBetweenWageAndInvoiceColumnsComponent as RenderType_ToolbarItemWorkHourMonthlyGridToggleBetweenWageAndInvoiceColumnsComponent };
export function View_ToolbarItemWorkHourMonthlyGridToggleBetweenWageAndInvoiceColumnsComponent_0(_l) { return i0.ɵvid(2, [], null, null); }
export function View_ToolbarItemWorkHourMonthlyGridToggleBetweenWageAndInvoiceColumnsComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-toolbar-item-work-hour-monthly-grid-toggle-between-wage-and-invoice-columns", [], null, null, null, View_ToolbarItemWorkHourMonthlyGridToggleBetweenWageAndInvoiceColumnsComponent_0, RenderType_ToolbarItemWorkHourMonthlyGridToggleBetweenWageAndInvoiceColumnsComponent)), i0.ɵdid(1, 245760, null, 0, i1.ToolbarItemWorkHourMonthlyGridToggleBetweenWageAndInvoiceColumnsComponent, [i2.ModuleElementFieldCrudService, i3.RequestCachingService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ToolbarItemWorkHourMonthlyGridToggleBetweenWageAndInvoiceColumnsComponentNgFactory = i0.ɵccf("app-toolbar-item-work-hour-monthly-grid-toggle-between-wage-and-invoice-columns", i1.ToolbarItemWorkHourMonthlyGridToggleBetweenWageAndInvoiceColumnsComponent, View_ToolbarItemWorkHourMonthlyGridToggleBetweenWageAndInvoiceColumnsComponent_Host_0, { params: "params" }, {}, []);
export { ToolbarItemWorkHourMonthlyGridToggleBetweenWageAndInvoiceColumnsComponentNgFactory as ToolbarItemWorkHourMonthlyGridToggleBetweenWageAndInvoiceColumnsComponentNgFactory };
