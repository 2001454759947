export class NumberHelper {

  public static round(value: number|string, places = 2): string {
    const delimiter = +NumberHelper.appendZeros(1, places);

    return (Math.round(+value * delimiter) / delimiter).toFixed(places);
  }

  private static appendZeros(padTo: number, length: number) {
    let zerosString = padTo.toString();

    while (zerosString.length < length + 1) {
      zerosString += '0';
    }

    return zerosString;
  }

    public static formatNumber(value: string, decimalSeparator: string = ',', thousandSeparator: string = '.', decimalPlaces: number = 2, suffix: string = '', prefix: string = '') {
      if (!value) {
        return value;
      }
        value  = this.roundNumber(value, decimalPlaces).toString();

        let fractionalPart = value.split('.')[1],
            integerPart = value.split('.')[0],
            negativePart = '',
            userIntegerPart;

        if (!fractionalPart || fractionalPart === '0') {
          fractionalPart = '00';
        }

        if(integerPart.match(/^-/)) {
            negativePart = '-';
            integerPart = integerPart.substr(1, integerPart.length);
        }
        userIntegerPart = integerPart;

        for (let i = integerPart.length; i--;) {
            if (i % 3 === 0 && i !== 0) {
                const thousandPosition = userIntegerPart.length  - i;
                userIntegerPart = userIntegerPart.substr(0, thousandPosition) + thousandSeparator + userIntegerPart.substr(thousandPosition);
            }

        }
        if (fractionalPart) {
          return prefix + negativePart + userIntegerPart + decimalSeparator + fractionalPart + suffix;
        }else {
          return prefix + negativePart + userIntegerPart + suffix;
        }
    }

    private static roundNumber(num, scale) {
      if (Math.round(num) != num) {
        if (Math.pow(0.1, scale) > Math.abs(num)) {
          return 0;
        }
        var sign = Math.sign(num);
        var arr = ("" + Math.abs(num)).split(".");
        if (arr.length > 1) {
          if (arr[1].length > scale) {
            var integ = +arr[0] * Math.pow(10, scale);
            var dec = integ + (+arr[1].slice(0, scale) + Math.pow(10, scale));
            var proc = +arr[1].slice(scale, scale + 1)
            if (proc >= 5) {
              dec = dec + 1;
            }
            dec = sign * (dec - Math.pow(10, scale)) / Math.pow(10, scale);
            return dec;
          }
        }
      }
      return num;
    }

    public static getNumberFromString(value: string, decimalSeparator: string = ',', thousandSeparator: string = '.', suffix: string = '', prefix: string = '') {
      value = value.replace(suffix,'');
      value = value.replace(prefix,'');
      let integerPart = value.split(',')[0] || '0',
        fractionalPart = value.split(',')[1] || '0',
        integerParts = integerPart.split('.');

      integerPart = integerParts.join('');

      let number = integerPart + '.' + fractionalPart;

      return Number(number);
    }

}
