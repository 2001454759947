import {JobInterface} from '../../../core/job-runner/job/job.interface';
import {JobContext} from '../../../core/job-runner/context/job.context';
import {Observable} from 'rxjs';
import {JobExecutionStatus} from '../../../core/job-runner/job/job-execution-status';
import {ConditionInterface} from '../../../core/job-runner/condition/condition.interface';
import {AbstractJob} from '../../../core/job-runner/job/abstract.job';
import {GenericTreeGridComponent} from '../../content-renderer/elements/generic-tree-grid/generic-tree-grid.component';
import {Entity} from '../../helpers/entity';
import {EntityStatus} from '../../services/entity/entity-status';

export class SetPrecalculationArticleOtherIsActivePropertyJob extends AbstractJob implements JobInterface {
  run(context: JobContext, condition: ConditionInterface): Observable<JobExecutionStatus> {
    return Observable.create((observer) => {
      const component: GenericTreeGridComponent = context.component,
        requiredSalaryTypes = condition.getPayload().requiredSalaryTypes;

      let expandNodes = false;
      for (const entity of component.getEntities()) {
        const entitySalaryType = Entity.getValue(entity, 'salaryType') || Entity.getValueInEmbedded(entity, 'salaryType');

        if (this.isArticleSalaryTypeInRequired(entitySalaryType, requiredSalaryTypes) && !entity.isActive) {
          expandNodes = true;
          entity.isActive = true;
          entity[EntityStatus.ENTITY_CHANGED_FLAG] = true;
        }
      }

      if (expandNodes) {
        this.expandTreeNodes(component);
      }

      observer.next({
        status: true,
        content: null,
        jobId: this.jobId
      });
    });
  }

  private isArticleSalaryTypeInRequired(salaryType: any, requiredSalaryTypes: any[] = []): boolean {
    return requiredSalaryTypes.findIndex((aRequiredSalaryType) => {
      return salaryType && salaryType.id === aRequiredSalaryType.id;
    }) !== -1;
  }

  private expandTreeNodes(component): void {
    for (const node of component.getAllNodes()) {
      if (node.children && node.children.length > 0) {
        node.expanded = true;
      }
    }
  }
}
