import {Component, ViewChild} from '@angular/core';
import {ExpertSearch} from '../../services/expert-search/expert-search';
import {SearchManagementListComponent} from './list/search-management-list.component';

@Component({
  selector: 'app-search-management',
  templateUrl: './search-management.component.html',
  styleUrls: ['./search-management.component.scss']
})
export class SearchManagementComponent {

    @ViewChild('list', {static: false}) list: SearchManagementListComponent = null;

    public expertSearches: ExpertSearch[] = [];
    public expertSearch: ExpertSearch = null;

    public onExpertSearchChange(expertSearch: ExpertSearch = null) {
      this.expertSearch = expertSearch;
    }

    public onExpertSearchSave(expertSearch: ExpertSearch): void {
      this.list
        .loadExpertSearches()
        .subscribe((expertSearches: ExpertSearch[] = []) => {
          this.list.expertSearches = expertSearches;
          this.list.setSelectedExpertSearch(expertSearch);

          this.expertSearch = this.list.selectedExpertSearch;
        });
    }
}
