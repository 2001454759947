import {AbstractFormActionHandler} from '../abstract-form-action-handler';
import {GenericCrudService} from '../../../../services/generic-crud.service';
import {EntityDirtyStoreService} from '../../../../content-renderer/services/entity-dirty-store.service';
import {EntityManagerService} from '../../../../../core/service/entity-manager/entity-manager.service';
import {ElementInputAlgoliaPlacesComponent} from '../../../element/element-input-algolia-places.component';
import * as Places from 'places.js';
import {Element} from '../../../models';
import {ElementInputAutocompleteComponent} from '../../../element/element-input-autocomplete.component';
import {Entity} from '../../../../helpers/entity';
import {forEach} from 'lodash';
import {ElementInputGeoapifyAutocompleteComponent} from '../../../element/element-input-geoapify-autocomplete.component';
import {GeoapifyProperties} from '../../../models/geoapify-properties';

const ALWAYS_REMOVE = [
  'houseNumber',
  'doorNumber',
  'municipality',
  'city',
  'postalCode',
  'street',
  'country',
  'locality'
];

export class HandleGeoapifyAutocompleteChangeActionHandler extends AbstractFormActionHandler {

  public constructor(
    private genericCrudService: GenericCrudService,
    private entityDirtyStore: EntityDirtyStoreService,
    private entityManager: EntityManagerService
  ) {
    super();
  }

  public handleAction(): void {
    const entity = this.getEntity();

    const component = this.formService.getComponentByElement(this.getElement());

    if (entity && component instanceof ElementInputGeoapifyAutocompleteComponent) {
      const suggestion = component.selectedSuggestion;

      this.handleSuggestionChange(suggestion);
    }
  }

  protected getElementBy(options): Element|null {
    let element = this.formService.getElementByMany(this.getForm(), options);

    if (typeof options.datamodelField !== 'undefined') {
      options.datamodelField = 'mainAddress.' + options.datamodelField;
    }

    if (!element) {
      element = this.formService.getElementByMany(this.getForm(), options);
    }

    return element;
  }

  protected getElementByField(field: string): Element|null {
    return this.formService.getElementBy(this.getForm(), 'datamodelField', field) || this.formService.getElementBy(this.getForm(), 'datamodelField', 'mainAddress.' + field) || null;
  }

  protected getElementsByField(field: string): Element[]|null {
    return this.formService.getElementsBy(this.getForm(), 'datamodelField', field) || this.formService.getElementsBy(this.getForm(), 'datamodelField', 'mainAddress.' + field) || null;
  }

  protected changeGeoapifyValue(entity): void {
    const geoapifyElement = this.getElementBy({
      datamodelField: 'street',
      typeElement: 'geoapifyAutocomplete'
    }) || this.getElementBy({
      datamodelField: 'streetName',
      typeElement: 'geoapifyAutocomplete'
    });

    if (!geoapifyElement) {
      return;
    }

    const component = this.formService.getComponentByElement(geoapifyElement);

    if (entity && component instanceof ElementInputGeoapifyAutocompleteComponent) {
      const country = Entity.getValue(entity, 'country.name') ||
        Entity.getValueInEmbedded(entity, 'country.name');

      const street = Entity.getValue(entity, 'street') ||
        Entity.getValueInEmbedded(entity, 'street') || Entity.getValue(entity, 'streetName');

      const houseNumber = Entity.getValue(entity, 'houseNumber') ||
        Entity.getValueInEmbedded(entity, 'houseNumber') || Entity.getValue(entity, 'streetNumber');

      const postalCode = Entity.getValue(entity, 'postalCode') ||
        Entity.getValueInEmbedded(entity, 'postalCode');

      const city = Entity.getValue(entity, 'city') ||
        Entity.getValueInEmbedded(entity, 'city');

      component.setAutocompleteValue(`${street ? street + ' ' : ''}${houseNumber ? houseNumber + ', ' : ''}${postalCode ? postalCode + ' ' : ''}${city ? city + ', ' : ''}${country}`);
    }
  }

  private handleSuggestionChange(suggestion: GeoapifyProperties): this {
    this.removeCurrentEntries();

    if (suggestion) {
      this.setCountry(suggestion);
      this.setRegion(suggestion);
    }

    return this;
  }

  private removeCurrentEntries(): void {
    for (const removeField of ALWAYS_REMOVE) {
      const component = this.getComponentByField(removeField);

      if (!component) {
        return;
      }

      if (component instanceof ElementInputAutocompleteComponent) {
        component.setValue(null, false, true);
      } else {
        component.setValue('');
      }
    }
  }

  private setCountry(suggestion: GeoapifyProperties): void {
    const countryComponent = this.getComponentByField('country');

    if (countryComponent instanceof ElementInputAutocompleteComponent && suggestion.country_code) {
      this.genericCrudService.getEntities('phoenix/countries', '', {
        'code': suggestion.country_code
      }).subscribe((countries: any[]) => {
        const country = countries.length > 0 ? countries[0] : null,
          formEntity = this.getEntity();

        if (country) {
          const option = {
            label: country.name,
            value: country.id,
            entity: country
          };

          this.entityManager.persist(formEntity, {
            force: true,
            property: 'country',
            oldValue: null,
            newValue: country
          });
          formEntity._embedded = formEntity._embedded || {};
          formEntity._embedded.country = country;
          countryComponent.selectedOption = option;
          countryComponent
            .addOption(option)
            .setValue(option, true);
        }

        this.setPostalCode(suggestion);
        //this.setLocality(suggestion);
        this.setCity(suggestion);
        this.setStreet(suggestion);
        this.setGeoData(suggestion);

        this.changeGeoapifyValue(formEntity);
      })
    }
  }

  private setRegion(suggestion: GeoapifyProperties): void {
    const formEntity = this.getEntity()

    this.entityManager.persist(formEntity, {
      force: true,
      property: 'region',
      oldValue: null,
      newValue: null
    });

    if (suggestion.state) {
      this.genericCrudService.getEntities('phoenix/regions/offset/0/limit/1/orderby/id/asc', '', {
        'english_name': suggestion.state
      }).subscribe((response: {data: any[]}) => {
        const region = response.data && response.data.length > 0 ? response.data[0] : null;

        if (region) {
          this.entityManager.persist(formEntity, {
            force: true,
            property: 'region',
            oldValue: null,
            newValue: region
          });
          formEntity._embedded = formEntity._embedded || {};
          formEntity._embedded.region = region;
        }
      })
    }
  }

  private setGeoData(suggestion: GeoapifyProperties): void {
    if (suggestion.lat && suggestion.lon) {
      const longitudeComponent = this.getComponentByField('longitude');
      const latitudeComponent = this.getComponentByField('latitude');

      if (longitudeComponent && latitudeComponent) {
        longitudeComponent.setValue(suggestion.lon);
        latitudeComponent.setValue(suggestion.lat);
      }
    }
  }

  private setPostalCode(suggestion: GeoapifyProperties): void {
    const postalCodeComponents = this.getComponentsByField('postalCode');

    if (postalCodeComponents && suggestion.postcode) {
      this.getEntity().postalCode = suggestion.postcode;

      for (const postalCodeComponent of postalCodeComponents) {
        postalCodeComponent.setValue(suggestion.postcode);
      }
    }
  }

  private setStreet(suggestion: GeoapifyProperties): void {
    let streetHiddenElement = this.getElementBy({
      'datamodelField': 'street',
      'isHidden' : true
    }) || null;

    if (!streetHiddenElement) {
      streetHiddenElement = this.getElementBy({
        'datamodelField': 'streetName',
        'isHidden' : true
      }) || null;
    }

    let houseNumberComponents = this.getComponentsByField('houseNumber');

    if (!houseNumberComponents) {
      houseNumberComponents = this.getComponentsByField('streetNumber');
    }

    let street = suggestion.street;
    let houseNumber = suggestion.housenumber ? suggestion.housenumber : '';

    houseNumber = houseNumber.match(/^[0-9].*$/) ? houseNumber : null;

    if (!houseNumber) {
      street = suggestion.street;
    }

    if (streetHiddenElement) {
      const streetComponent = this.formService.getComponentByElement(streetHiddenElement);

      this.setStreetComponentValue(streetComponent, street);
    }

    let streetVisibleElement = this.getElementBy({
      'datamodelField': 'street',
      'isHidden' : false
    }) || null;

    if (!streetVisibleElement) {
      streetVisibleElement = this.getElementBy({
        'datamodelField': 'streetName',
        'isHidden' : false
      }) || null;
    }

    if (streetVisibleElement) {
      const streetComponent = this.formService.getComponentByElement(streetVisibleElement);

      this.setStreetComponentValue(streetComponent, street);
    }


    for (const houseNumberComponent of houseNumberComponents) {
      houseNumberComponent.setValue(houseNumber);
    }

    this.markElementForCheck(streetHiddenElement);
    this.markElementForCheck(streetVisibleElement);
  }

  private setStreetComponentValue(streetComponent: any, value): void {
    if (!streetComponent) {
      return;
    }
    if (streetComponent instanceof ElementInputAutocompleteComponent) {
      const option = {
        label: value,
        value: value
      };

      streetComponent.selectedOption = option;
      streetComponent
        .addOption(option)
        .setValue(option, false);

      streetComponent.setValue(option, false);
    } else {
      streetComponent.setValue(value);
    }
  }

  private setCity(suggestion: GeoapifyProperties): void {
    const cityComponents = this.getComponentsByField('city');

    const municipalityComponents = this.getComponentsByField('municipality');

    if (cityComponents && suggestion.city) {
      for (const cityComponent of cityComponents) {
        cityComponent.setValue(suggestion.city);
      }
    }

    if (municipalityComponents && suggestion.city) {
      for (const municipalityComponent of municipalityComponents) {
        municipalityComponent.setValue(suggestion.city);
      }
    }
  }

  private getComponentByField(field: string): Element|null {
    const element = this.getElementByField(field);

    if (element) {
      this.markElementForCheck(element);
      return this.formService.getComponentByElement(element);
    }

    return null;
  }

  private getComponentsByField(field: string): Element[]|null {
    const elements = this.getElementsByField(field);
    const components = [];
    if (elements) {
      for (const element of elements) {
        this.markElementForCheck(element);
        components.push(this.formService.getComponentByElement(element));
      }
    }

    return components.length > 0 ? components : null;
  }
}
