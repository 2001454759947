import {Component} from '@angular/core';
import {AbstractGenericGridSimpleSearchFieldComponent} from './abstract-generic-grid-simple-search-field.component';

@Component({
  selector: 'app-generic-grid-simple-search-field-time',
  template: ` 
    <app-generic-calendar
      (onInputChangeValid)="onFilter($event)"
      (onSelect)="onFilter($event)"
      [value]="field.value"
      [timeOnly]="true"
      [showOnFocus]="true"
    ></app-generic-calendar>
  `
})
export class GenericGridSimpleSearchFieldTimeComponent extends AbstractGenericGridSimpleSearchFieldComponent {

  public onFilter(date: Date) {
    this.field.value = date;
  }
}
