/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/flex-layout/flex";
import * as i2 from "@angular/flex-layout/core";
import * as i3 from "../../directives/selenium.directive";
import * as i4 from "./element-label-output-collective-agreement-aku-text.component";
import * as i5 from "../form.service";
import * as i6 from "../form-viewer.service";
var styles_ElementLabelOutputCollectiveAgreementAkuTextComponent = ["[_nghost-%COMP%] {\n      width: 100%;\n      padding: .334rem 0;\n    }"];
var RenderType_ElementLabelOutputCollectiveAgreementAkuTextComponent = i0.ɵcrt({ encapsulation: 0, styles: styles_ElementLabelOutputCollectiveAgreementAkuTextComponent, data: {} });
export { RenderType_ElementLabelOutputCollectiveAgreementAkuTextComponent as RenderType_ElementLabelOutputCollectiveAgreementAkuTextComponent };
export function View_ElementLabelOutputCollectiveAgreementAkuTextComponent_0(_l) { return i0.ɵvid(2, [(_l()(), i0.ɵeld(0, 0, null, null, 5, "div", [["fxLayout", "row"]], null, null, null, null, null)), i0.ɵdid(1, 671744, null, 0, i1.DefaultLayoutDirective, [i0.ElementRef, i2.StyleUtils, [2, i1.LayoutStyleBuilder], i2.MediaMarshaller], { fxLayout: [0, "fxLayout"] }, null), (_l()(), i0.ɵeld(2, 0, null, null, 3, "div", [["appSeleniumDirective", ""], ["fxFlex", "80"]], [[4, "font-weight", null]], null, null, null, null)), i0.ɵdid(3, 671744, null, 0, i1.DefaultFlexDirective, [i0.ElementRef, i2.StyleUtils, i2.LAYOUT_CONFIG, i1.FlexStyleBuilder, i2.MediaMarshaller], { fxFlex: [0, "fxFlex"] }, null), i0.ɵdid(4, 4210688, null, 0, i3.SeleniumDirective, [i0.ElementRef, i0.Renderer2], { element: [0, "element"] }, null), (_l()(), i0.ɵted(5, null, [" ", " "]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = "row"; _ck(_v, 1, 0, currVal_0); var currVal_2 = "80"; _ck(_v, 3, 0, currVal_2); var currVal_3 = _co.element; _ck(_v, 4, 0, currVal_3); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.getFontWeight(); _ck(_v, 2, 0, currVal_1); var currVal_4 = _co.collectiveAgreementName; _ck(_v, 5, 0, currVal_4); }); }
export function View_ElementLabelOutputCollectiveAgreementAkuTextComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "app-form-element-label-output-collective-agreement-aku-text", [], null, null, null, View_ElementLabelOutputCollectiveAgreementAkuTextComponent_0, RenderType_ElementLabelOutputCollectiveAgreementAkuTextComponent)), i0.ɵdid(1, 6012928, null, 1, i4.ElementLabelOutputCollectiveAgreementAkuTextComponent, [i5.FormService, i0.ChangeDetectorRef, i6.FormViewerService], null, null), i0.ɵqud(603979776, 1, { insideNgContent: 0 })], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ElementLabelOutputCollectiveAgreementAkuTextComponentNgFactory = i0.ɵccf("app-form-element-label-output-collective-agreement-aku-text", i4.ElementLabelOutputCollectiveAgreementAkuTextComponent, View_ElementLabelOutputCollectiveAgreementAkuTextComponent_Host_0, { form: "form", formGroup: "formGroup", entity: "entity", element: "element", moduleElement: "moduleElement", selectedElement: "selectedElement", showDialogAddAddress: "showDialogAddAddress", actionParams: "actionParams", editMode: "editMode" }, { selectedElementChange: "selectedElementChange", showDialogAddAddressChange: "showDialogAddAddressChange", actionParamsChange: "actionParamsChange" }, []);
export { ElementLabelOutputCollectiveAgreementAkuTextComponentNgFactory as ElementLabelOutputCollectiveAgreementAkuTextComponentNgFactory };
