/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./toolbar-item-open-link.component";
var styles_ToolbarItemOpenLink = [];
var RenderType_ToolbarItemOpenLink = i0.ɵcrt({ encapsulation: 2, styles: styles_ToolbarItemOpenLink, data: {} });
export { RenderType_ToolbarItemOpenLink as RenderType_ToolbarItemOpenLink };
export function View_ToolbarItemOpenLink_0(_l) { return i0.ɵvid(2, [], null, null); }
export function View_ToolbarItemOpenLink_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "toolbar-item-open-link", [], null, null, null, View_ToolbarItemOpenLink_0, RenderType_ToolbarItemOpenLink)), i0.ɵdid(1, 245760, null, 0, i1.ToolbarItemOpenLink, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ToolbarItemOpenLinkNgFactory = i0.ɵccf("toolbar-item-open-link", i1.ToolbarItemOpenLink, View_ToolbarItemOpenLink_Host_0, { params: "params" }, {}, []);
export { ToolbarItemOpenLinkNgFactory as ToolbarItemOpenLinkNgFactory };
