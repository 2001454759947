import { AbstractInlineEditor } from './abstract-inline-editor';
import {Observable, of} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

export class OpenmoduleInlineEditor extends AbstractInlineEditor {

    public getValue(entity: any) {
      const entityEmbeddedEntities = this.genericCrudService.getEntityHydrator().getEntityPropertyValue(entity, this.getEntityColumnProperty(), false, false);

      let value = this.column.header;

      if(entityEmbeddedEntities instanceof Array){
        value +=  '(' + entityEmbeddedEntities.length + ')';
      }
      return value;
    }

    public onEdit(entity: any, event: any) {
      this.genericCrudService.getEntities(`superadmin/modules/${this.column.moduleId}`)
        .pipe(takeUntil(this.component.unsubscribe))
        .subscribe((targetModule) => {
        this.genericDialogModuleService.showDialog(targetModule, {
          height: 600,
          width: 1200,
          entity: entity,
          masterEntity: entity,
          masterEntityEditingField: this.getEntityColumnProperty(),
          masterElementContext: this.component.getElementContext(),
          isAutocompleteModuleState: false
        })
      });
    }

    public onBlur(entity: any, event: any) {

    }

    public onFocus(entity: any, event: any): Observable<any> {
      return of(null);
    }
}
