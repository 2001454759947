
import {of as observableOf} from 'rxjs';
import {Injectable} from '@angular/core';
import {Observable} from '../../../../../node_modules/rxjs';
import {AbstractEntityFileTypeService} from './abstract-entity-file-type.service';
import {FileData} from '../file-data';

@Injectable()
export class DocumentEntityFileTypeService extends AbstractEntityFileTypeService {

  public getFile(entity: any): Observable<FileData> {
    return observableOf({
      fileName: null,
      filePath: null,
      fileType: null
    });
  }
}
