
import {of as observableOf, Observable} from 'rxjs';
import {AbstractExecutionStep} from '../../executor/abstract-execution-step';
import {ExecutionStepStatus} from '../../executor/execution-step-status';
import {Organisation} from '../../../shared/services/organisation/organisation';
import {StyleService} from '../../style.service';
import {RequestCachingService} from '../../../shared/services/request-caching.service';
import {TranslateService} from '@ngx-translate/core';

export class LoadDesignBasedOnOrganisationExecutionStep extends AbstractExecutionStep {

  public doExecute(): Observable<ExecutionStepStatus> {
    const organisation: Organisation = this.getPayload().getValue().organisation;

    if (!organisation) {
      console.error('Organisation not found!');
    }

    this.getStyleService().blockUI.start(this.getTranslateService().instant(
      'COMMON.LOADING_PLEASE_WAIT'
    ));
    this.getRequestCachingService().removeByExpression(`superadmin/organisations/${organisation.id}/designs`);
    this.getStyleService().setDesignBasedOnOrganisation(organisation);

    return observableOf({status: true, content: null});
  }

  private getStyleService(): StyleService {
    return this.injector.get(StyleService, null);
  }

  private getRequestCachingService(): RequestCachingService {
    return this.injector.get(RequestCachingService, null);
  }

  private getTranslateService(): TranslateService {
    return this.injector.get(TranslateService, null);
  }
}
