
import {tap} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent, HttpResponse
} from '@angular/common/http';

import {EntityManagerService} from '../../core/service/entity-manager/entity-manager.service';
import {SaveEntityRequestBodyBuilderService} from '../../core/service/request/save-entity-request-body-builder.service';
import {GenericCrudService} from '../services/generic-crud.service';
import {EntityHydrator} from '../services/entity-hydrator.service';

const BLACKLIST = [
  'validate',
  'lock',
  'groupedprecalculationarticles'
];

@Injectable()
export class RequestEntityManagerInterceptor implements HttpInterceptor {

  public constructor(
    protected entityManager: EntityManagerService,
    protected saveEntityRequestBuilder: SaveEntityRequestBodyBuilderService,
    protected genericCrudService: GenericCrudService,
    protected entityHydrator: EntityHydrator
  ) {

  }

  public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    if (this.doIntercept(request)) {
      const body = request.body ? JSON.parse(request.body) : {};

      request = request.clone({
        body: this.genericCrudService.stringify(
          this.entityHydrator.hydrate(this.saveEntityRequestBuilder.build(body))
        )
      });

      return next.handle(request).pipe(tap(response => {
        if (response instanceof HttpResponse) {
          const builtForSave = this.saveEntityRequestBuilder.getBuilt();

          for (const entity of builtForSave) {
            this.entityManager.getChangeSetContainer().removeChangeSet(entity);
          }

          this.saveEntityRequestBuilder.clearBuilt();
        }
      }));
    }

    return next.handle(request);
  }

  private doIntercept(request: HttpRequest<any>): boolean {
    if (request.body instanceof FormData) {
      return false;
    }

    const body = request.body ? JSON.parse(request.body) : {},
      isCorrectMethod = request.method === 'PUT' || request.method === 'POST',
      isCorrectBody = body instanceof Object && body.fqn && body.uniqueId,
      isInChangeSet = this.entityManager.getChangeSetContainer().hasChangeSet(body),
      isRequestBlacklisted = this.isRequestBlacklisted(request);

    return isCorrectMethod && isCorrectBody && isInChangeSet && !isRequestBlacklisted;
  }

  private isRequestBlacklisted(request: HttpRequest<any>): boolean {
    for (const url of BLACKLIST) {
      if (request.urlWithParams.indexOf(url) !== -1) {
        return true;
      }
    }

    return false;
  }
}
