import {Injectable, QueryList} from '@angular/core';
import {RgbColorAdapter} from '../../../../helpers/rgb-color-adapter';
import {GenericGridLayoutService} from '../../generic-grid/services/generic-grid-layout.service';
import {AbstractGenericGridComponent} from '../../abstract-generic-grid.component';

@Injectable()
export class GenericTurboGridLayoutService extends GenericGridLayoutService {

  public setScrollWidth(): GenericTurboGridLayoutService {

    if (this.grid && this.grid.element && this.grid._gridContainer) {
        const gridContainerWidth = this.grid._gridContainer.nativeElement.clientWidth;

        this.grid.scrollWidth = `${gridContainerWidth}px`;
    }

    return this;
  }

  public setScrollHeight(): GenericTurboGridLayoutService {

    if (this.grid && this.grid.element && this.grid._gridContainer) {
      const dataTableBody = this.elementRef.nativeElement.querySelector('.ui-table-scrollable-body'),
            dataTableHeader = this.elementRef.nativeElement.querySelector('.ui-table-scrollable-header');

      let gridContainerHeight = this.grid._gridContainer.nativeElement.clientHeight;

      // TODO::should we check for every element if it is visible or not to calculate height?
      // or use fixed heights like for header, pagination, footer bellow?

      // grid header (filters)
      if (dataTableHeader) {
          gridContainerHeight -= +dataTableHeader.clientHeight;
      }

      if (this.grid.element.isPaginable) {
          gridContainerHeight -= 25;
      }

      if (this.grid.element.topToolbarItems && this.grid.element.topToolbarItems.length > 0) {
          gridContainerHeight -= 34;
      }

      if (this.grid.element.statusBarItems && this.grid.element.statusBarItems.length > 0) {
          gridContainerHeight -= 34;
      }

      if (this.grid.filterComponent && this.grid.filterComponent.filterContainer &&
        this.grid.filterComponent.filterContainer.nativeElement
      ) {
          gridContainerHeight -= +this.grid.filterComponent.filterContainer.nativeElement.clientHeight;
      }

      this.grid.scrollHeight = `${gridContainerHeight}px`;

      // when datatable is empty
      if (dataTableBody) {
          dataTableBody.style.height = `${gridContainerHeight}px`;
          dataTableBody.style.maxHeight = '';
      }
    }

    return this;
  }

  public fixScrollHeightAndWidthOfExpanders(expanderContainers: QueryList<any>): this {
    expanderContainers.forEach((expanderContainer) => {
      if (expanderContainer && expanderContainer.elementRef && expanderContainer.elementRef.nativeElement) {
        const expanderContainerWrapper = expanderContainer.elementRef.nativeElement.querySelector('.wrapper-container');

        if (expanderContainerWrapper) {
          expanderContainerWrapper.style.height = '350px';

          if (this.grid && this.grid._gridContainer && this.grid._gridContainer.nativeElement.clientWidth) {
            expanderContainerWrapper.style.width = `${this.grid._gridContainer.nativeElement.clientWidth}px`;
          }
        }
      }
    });

    return this;
  }

  public adaptRowColor(): void {
    const editingCell = this.grid && this.grid.grid ? this.grid.grid.editingCell : null;

    if (editingCell) {
      const style = window.getComputedStyle(editingCell, ''),
        bgColor = style.getPropertyValue('background-color');

      const tr = editingCell.closest('tr'),
        adaptColor = RgbColorAdapter.adapt(bgColor, 'black');

      GenericGridLayoutService.adaptTrColor(tr, adaptColor);
    }
  }

  public adaptRowsColor(): void {
    const rows = this.elementRef.nativeElement.querySelectorAll('tr');

    for (const row of rows) {
      const style = window.getComputedStyle(row, ''),
        bgColor = style.getPropertyValue('background-color');

      const adaptColor = RgbColorAdapter.adapt(bgColor, 'black');

      GenericGridLayoutService.adaptTrColor(row, adaptColor);
    }
  }
}
