
import {of as observableOf, Observable} from 'rxjs';
import {AbstractExecutionStep} from '../../../../../core/executor/abstract-execution-step';
import {ExecutionStepStatus} from '../../../../../core/executor/execution-step-status';
import {DoubleClickService} from '../../../../content-renderer/services/double-click.service';
import {AbstractGenericGridComponent} from '../../../../content-renderer/elements/abstract-generic-grid.component';
import {FormViewerComponent} from '../../../../form-viewer/form-viewer.component';
import {SetupWorkHourMonthlyEntryGridComponentCondition} from '../../../../job-runner/condition/work-hour/setup-work-hour-monthly-entry-grid-component.condition';
import {SetupWorkHourMonthlyEntryGridComponentJob} from '../../../../job-runner/job/work-hour/setup-work-hour-monthly-entry-grid-component.job';
import {SetupWorkHourSubAssignmentGridCondition} from '../../../../job-runner/condition/work-hour/setup-work-hour-sub-assignment-grid.condition';
import {SetupWorkHourSubAssignmentGridJob} from '../../../../job-runner/job/work-hour/setup-work-hour-sub-assignment-grid.job';
import {Entity} from '../../../../helpers/entity';
import {LocalStorageDataService} from '../../../local-storage-data.service';
import {ExecutionStepParameterExecutor} from '../../../../../core/executor/execution-step-parameter';
import {SetupWorkHourDocuwareDocumentApprovalPeriodCondition} from '../../../../job-runner/condition/work-hour/setup-work-hour-docuware-document-approval-period.condition';
import {SetupWorkHourDocuwareDocumentApprovalPeriodJob} from '../../../../job-runner/job/work-hour/setup-work-hour-docuware-document-approval-period.job';
import {UserSessionService} from '../../../../../core/service/user-session.service';

export class ContextMenuWorkHourOpenMonthlyAndSubAssignmentInfoExecutionStep extends AbstractExecutionStep {

  protected doubleClickService: DoubleClickService;

  public doExecute(): Observable<ExecutionStepStatus> {
    const component = this.getPayload().getValue();

    if (!(component instanceof AbstractGenericGridComponent) && !(component instanceof FormViewerComponent)) {
      return this.getFailObservable('You need to pass AbstractGenericGridComponent or FormViewerComponent as Payload value!');
    }

    return this.doOpenModuleInGridContext(component);
  }

  protected doOpenModuleInGridContext(component: AbstractGenericGridComponent|FormViewerComponent) {
    const doubleClickService: DoubleClickService = this.injector.get(DoubleClickService, null),
      entity = component.getSelectedEntity();

      if (component instanceof AbstractGenericGridComponent) {
        this.setupWorkHourSubAssignmentJob(component, doubleClickService);
        this.setupWorkHourDocuwreDocumentApprovalPeriod(component);
      }

      if (component instanceof FormViewerComponent) {
        doubleClickService.setForm(component);
      }

      const condition = new SetupWorkHourMonthlyEntryGridComponentCondition();
      condition.setPayload({
        context: component.getElementContext(),
        workHour: entity
      });
      this.jobContainerService.registerJob(
        new SetupWorkHourMonthlyEntryGridComponentJob().setIsImmutable(true),
        condition
      );

      if (this.getParamValue('executor') === ExecutionStepParameterExecutor.ToolbarItem) {
        this.openInToolbarItem(component, entity, doubleClickService);
      } else {
        this.openInContextMenu(component, entity, doubleClickService);
      }

    return observableOf({status: true, content: null});
  }

  private openInToolbarItem(component, entity, doubleClickService): void {
    const toolbarItem = this.getParamValue('toolbarItem'),
      subAssignment = Entity.getValue(entity, 'subAssignment') ||
        Entity.getValueInEmbedded(entity, 'subAssignment');

    doubleClickService
      .setDialogOptions({
        height: +toolbarItem.targetDialogHeight || 600,
        width: +toolbarItem.targetDialogWidth || 1200,
        maximized: toolbarItem.isTargetDialogMaximized
      })
      .handleOpenModule(component, toolbarItem.target, toolbarItem.actionModuleId, subAssignment);
  }

  private openInContextMenu(component, entity, doubleClickService): void {
    const dialogWidth = +this.getParamValue('dialogWidth'),
      dialogHeight = +this.getParamValue('dialogHeight'),
      target = this.getParamValue('target'),
      module = this.getParamValue('module'),
      subAssignment = Entity.getValue(entity, 'subAssignment') ||
        Entity.getValueInEmbedded(entity, 'subAssignment');

    doubleClickService
      .setDialogOptions({
        height: dialogHeight,
        width: dialogWidth,
        maximized: this.getParamValue('maximized')
      })
      .handleOpenModule(component, target, module, subAssignment);
  }

  private setupWorkHourSubAssignmentJob(component, doubleClickService: DoubleClickService): void {
    component.triggerEditEntityActions();
    doubleClickService.setComponent(component);
    const subAssignmentIds = [];

    for (const aEntity of component.entities) {
      const subAssignmentId = Entity.getValue(aEntity, 'subAssignment.id') ||
        Entity.getValueInEmbedded(aEntity, 'subAssignment.id');

      if (subAssignmentId !== null && subAssignmentIds.indexOf(subAssignmentId) === -1) {
        subAssignmentIds.push(subAssignmentId);
      }
    }

    const conditionSubAssignment = new SetupWorkHourSubAssignmentGridCondition();
    conditionSubAssignment.setPayload({
      context: component.getElementContext(),
      subAssignmentIds: subAssignmentIds
    });
    this.jobContainerService.registerJob(
      new SetupWorkHourSubAssignmentGridJob(
        this.injector.get(UserSessionService, null),
        this.modulesStateService
      ).setIsImmutable(true),
      conditionSubAssignment
    );
  }

  private setupWorkHourDocuwreDocumentApprovalPeriod(workHourGrid): void {
    const conditionDocuware = new SetupWorkHourDocuwareDocumentApprovalPeriodCondition();
    conditionDocuware.setPayload({
      context: workHourGrid.getElementContext(),
      leasedEmployees: workHourGrid.gridFilters.leasedEmployee && workHourGrid.gridFilters.leasedEmployee.value ?
        workHourGrid.gridFilters.leasedEmployee.value : [],
      workYear: workHourGrid.gridFilters.workYear && workHourGrid.gridFilters.workYear.value ?
        workHourGrid.gridFilters.workYear.value : null,
      workMonth: workHourGrid.gridFilters.workMonth && workHourGrid.gridFilters.workMonth.value ?
        workHourGrid.gridFilters.workMonth.value : null
    });
    this.jobContainerService.registerJob(
      new SetupWorkHourDocuwareDocumentApprovalPeriodJob().setIsImmutable(true),
      conditionDocuware
    );
  }
}
