/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../generic-loading/generic-loading.component.ngfactory";
import * as i2 from "../../../generic-loading/generic-loading.component";
import * as i3 from "@angular/common";
import * as i4 from "./generic-file-preview-docx.component";
import * as i5 from "@angular/platform-browser";
var styles_GenericFilePreviewDocxComponent = ["[_nghost-%COMP%] {\n      height: 100%;\n      width: 100%;\n    }\n    \n    #iframe[_ngcontent-%COMP%] {\n      height: 100%;\n      width: 100%;\n    }"];
var RenderType_GenericFilePreviewDocxComponent = i0.ɵcrt({ encapsulation: 0, styles: styles_GenericFilePreviewDocxComponent, data: {} });
export { RenderType_GenericFilePreviewDocxComponent as RenderType_GenericFilePreviewDocxComponent };
function View_GenericFilePreviewDocxComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-generic-loading", [], null, null, null, i1.View_GenericLoadingComponent_0, i1.RenderType_GenericLoadingComponent)), i0.ɵdid(1, 114688, null, 0, i2.GenericLoadingComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
export function View_GenericFilePreviewDocxComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 0, "iframe", [["id", "iframe"]], [[8, "hidden", 0], [8, "src", 5]], [[null, "load"], [null, "error"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("load" === en)) {
        var pd_0 = (_co.onLoad() !== false);
        ad = (pd_0 && ad);
    } if (("error" === en)) {
        var pd_1 = (_co.onError() !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_GenericFilePreviewDocxComponent_1)), i0.ɵdid(2, 16384, null, 0, i3.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_2 = !_co.isIFrameLoaded; _ck(_v, 2, 0, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.isIFrameLoaded; var currVal_1 = _co.url; _ck(_v, 0, 0, currVal_0, currVal_1); }); }
export function View_GenericFilePreviewDocxComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-generic-file-preview-docx", [], null, null, null, View_GenericFilePreviewDocxComponent_0, RenderType_GenericFilePreviewDocxComponent)), i0.ɵdid(1, 114688, null, 0, i4.GenericFilePreviewDocxComponent, [i5.DomSanitizer], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var GenericFilePreviewDocxComponentNgFactory = i0.ɵccf("app-generic-file-preview-docx", i4.GenericFilePreviewDocxComponent, View_GenericFilePreviewDocxComponent_Host_0, { fileData: "fileData" }, {}, []);
export { GenericFilePreviewDocxComponentNgFactory as GenericFilePreviewDocxComponentNgFactory };
