import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';


import { Element } from './element';
import { AppHttpService } from '../../../app-http.service';
import { GenericCrudService } from '../generic-crud.service';

@Injectable()
export class ElementCrudService extends AppHttpService {

  private elementsUrl: string = 'superadmin/elements';

  constructor(
    private genericCrudService: GenericCrudService
  ) {
    super();
  }

  /**
   *
   * @param format
   * @returns {Observable<R>}
   */
  getElements(format = ''): Observable<Element[]> {
    return this.genericCrudService.getEntities(this.elementsUrl, format ? `/${format}` : '');
  }

  transferToolbarSet(elementId, toolbarSetId, type): Observable<Element> {
    const url = this.elementsUrl + '/' + elementId + '/transfer/' + toolbarSetId + '/type/' + type;

    return this.genericCrudService.getEntities(url);
  }

  /**
   *
   * @param format
   * @returns {Observable<R>}
   */
  getElement(id: number, format = ''): Observable<Element> {
    return this.genericCrudService.getEntity(this.elementsUrl, id, format ? `/${format}` : '');
  }

  /**
   *
   * @param element
   * @returns {Observable<Object>}
   */
  deleteElement(element: Element): Observable<Object> {
    if (!element || !element.id) {
      throw new Error('Element object or element id not given.');
    }

    return this.genericCrudService.deleteEntity(`${this.elementsUrl}/${element.id}`);

  }

  /**
   *
   * @param element
   * @returns {Observable<R>}
   */
  createElement(element: Element): Observable<any> {
    if (!element) {
      throw new Error('Element object not given.');
    }

    return this.genericCrudService.createEntity(this.elementsUrl, element);
  }

  /**
   *
   * @param element
   * @returns {Observable<R>}
   */
  editElement(element: Element): Observable<Element> {
    if (!element || !element.id) {
      throw new Error('Element object or element id not given.');
    }

    return this.genericCrudService.editEntity(`${this.elementsUrl}/${element.id}`, element);
  }
}
