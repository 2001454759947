<div class="ui-grid-row">
    <div class="ui-grid-col-2">
    {{ 'COMMON.NAME' | translate }} *:
    </div>
    <div class="ui-grid-col-6">
        <input type="text" pInputText placeholder="Minimum 3 characters..." [(ngModel)]="userSearch"/>
    </div>
</div>

<div class="ui-grid-row">
    <div class="ui-grid-col-2">
        <button pButton type="button" [disabled]="!userSearch || userSearch.length < 3" (click)="onSearch()" label="{{ 'COMMON.SEARCH' | translate }}"></button>
    </div>
  <div class="ui-grid-col-2">
    <button pButton type="button" [disabled]="selectedUsers.length === 0" (click)="onImport()" label="{{ 'COMMON.IMPORT' | translate }}"></button>
  </div>
</div>

<div *ngIf="isDataLoading">
    {{ loadingMessage }}
</div>

<p-table
  *ngIf="users.length > 0 && !isDataLoading"
  [value]="users"
  [rows]="10" [paginator]="true" [pageLinks]="3" [rowsPerPageOptions]="[10,20,50]"
  [lazy]="true" (onLazyLoad)="loadSearchResults($event)"
  [totalRecords]="totalRecords"
  selectionMode="multiple"
  [(selection)]="selectedUsers"
  scrollable="true"
  scrollHeight="400px"
>
  <ng-template pTemplate="header">
    <tr>
      <th>ID</th>
      <th>{{ 'COMMON.USER_MANAGEMENT.FIRST_NAME' | translate }}</th>
      <th>{{ 'COMMON.USER_MANAGEMENT.LAST_NAME' | translate }}</th>
      <th>{{ 'COMMON.USER_MANAGEMENT.EMAIL' | translate }}</th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-user let-rowIndex="rowIndex">
    <tr [pSelectableRow]="user" [pSelectableRowIndex]="rowIndex">
      <td>{{ user.firstName }}</td>
      <td>{{ user.lastName }}</td>
      <td>{{ user.email }}</td>
    </tr>
  </ng-template>
</p-table>
