import { DatamodelConfig } from '../datamodel-config/datamodel-config';

export class ToolbarItem {
  public static DEFAULT_ONCLICK = 'add';
  public static TYPE_TOOLBAR = 1;
  public static TYPE_STATUSBAR = 2;
  public static DEFAULT_POSITION = 'top';

  public static ACTION_ELEMENT_CUSTOM_GRID_ID = 2;
  public static ACTION_ELEMENT_CUSTOM_FORM_ID = 3;
  public static ACTION_ELEMENT_CUSTOM_TREE_ID = 10;

  public static readonly POSITION_TOP = 'top';
  public static readonly POSITION_LEFT = 'left';
  public static readonly POSITION_RIGHT = 'right';
  public static readonly POSITION_BOTTOM = 'bottom';

  public static readonly TARGET_DIALOG = 'dialog';
  public static readonly TARGET_CURRENT_TAB = 'current_tab';
  public static readonly TARGET_NEW_TAB = 'new_tab';

  public static readonly CONTEXT_TARGET_MODULE = 'module';
  public static readonly CONTEXT_TARGET_GRID = 'grid';
  public static readonly CONTEXT_TARGET_FORM = 'form';
  public static readonly CONTEXT_TARGET_TREE = 'tree';

  public static readonly PDF_OPEN = 'open';
  public static readonly PDF_DOWNLOAD = 'download';
  public static readonly PDF_PRINT = 'print';

  id: string;
  name: string;
  type: number;
  onclick: string;
  onmouseover: string;
  rank: number;
  isLeft: number;
  isText: number;
  isSeparator: number;
  image: string;
  uniqueId: string;
  url?: string;
  actionElementId?: number;
  icon?: any;
  actionModuleId?: number;
  module?: number;
  element?: number;
  target?: string;
  pdfTarget?: string;
  targetObject?: string;
  label?: string;
  targetDialogWidth?: string;
  targetDialogHeight?: string;
  isTargetDialogModal?: boolean;
  isTargetDialogMaximized?: boolean;
  position?: string;
  itemType?: string;
  parameter?: string;
  contextTarget?: string;
  datamodelConfigId?: string;
  datamodelConfig?: DatamodelConfig;
  backgroundColor?: string;
  executionStep?: string;
  disabled?: boolean;

  constructor(id?: string, name?: string, type?: number,
    onclick?: string, rank?: number,
    isLeft?: number, isText?: number, image?: string,
    actionElementId?: number,
    onmouseover?: string, actionModuleId?: number, target?: string, pdfTarget?: string, targetObject?: string,
    targetDialogWidth?: string, targetDialogHeight?: string, label?: string, isSeparator?: number,
    position?: string, itemType?: string, parameter?: string, contextTarget?: string, datamodelConfigId?: string) {
    this.id = id;
    this.name = name;
    this.type = type;
    this.onclick = onclick;
    this.rank = rank;
    this.isLeft = isLeft;
    this.isText = isText;
    this.image = image;

    this.actionElementId = actionElementId;
    this.onmouseover = onmouseover;
    this.isSeparator = isSeparator;

    this.actionModuleId = actionModuleId;
    this.target = target;
    this.targetObject = targetObject;
    this.pdfTarget = pdfTarget;
    this.label = label;
    this.targetDialogHeight = targetDialogHeight;
    this.targetDialogWidth = targetDialogWidth;
    this.position = position ? position : ToolbarItem.DEFAULT_POSITION;
    this.itemType = itemType;
    this.parameter = parameter;
    this.contextTarget = contextTarget;
    this.datamodelConfigId = datamodelConfigId;
  }
}
