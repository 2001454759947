import { Injectable } from '@angular/core';
import { GenericCrudService } from '../generic-crud.service';

@Injectable()
export class PermissionService {

  public static readonly PERMISSION_TYPE_OBJECT = 'object';
  public static readonly PERMISSION_TYPE_CLASS_FIELD = 'classfield';

  constructor(
    private genericCrudService: GenericCrudService
  ) {
  }

  public grant(action: string, params: Object, permissionType: string) {
    params['grant'] = action;

    return this.getPermissionRequest(permissionType, params);
  }

  public revoke(action: string, params: Object, permissionType: string) {
    params['revoke'] = action;

    return this.getPermissionRequest(permissionType, params);
  }

  public save(action: string, granted: boolean, params: Object, permissionType: string) {
    if (granted) {
      return this.grant(action, params, permissionType);
    } else {
      return this.revoke(action, params, permissionType);
    }
  }

  public isGranted(entity: any, action: string) {
    let isGranted = true;

    if (entity && entity.isGranted !== undefined) {
      isGranted = entity.isGranted[action] ? entity.isGranted[action] : false;
    }

    return isGranted;
  }

  private getPermissionRequest(permissionType, params) {
    return this.genericCrudService.createEntity(permissionType + 'permissions', params);
  }
}
