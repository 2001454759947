import { Component } from '@angular/core';
import { FieldActionAbstractComponent } from '../field-action-abstract.component';
import { GenericCrudService } from '../../../../../services/generic-crud.service';
import { DoubleClickService } from '../../../../services/double-click.service';

@Component({
  selector: 'field-action-set-extra-param-from-another-field',
  template: ''
})
export class FieldActionSetExtraParamFromAnotherFieldComponent extends FieldActionAbstractComponent {

  public constructor(
    protected genericCrudService: GenericCrudService,
    protected doubleClickService: DoubleClickService
  ) {
    super();
  }

  public fire() {
    let fieldName = this.getActionParamValue('fieldName');

    if (null !== fieldName) {
        this.setExtraParamWithField(fieldName);
    }
  }

  private setExtraParamWithField(fieldName: string) {
    let field = this.getField()

    if (field.isAssociatedField && field.filterType === 'dropdown') {
        this.setExtraParamToTheDropdownAndLoad(fieldName);
    }

    if (field.fieldType === 'autocomplete') {
      this.setExtraParamToTheAutocomplete(fieldName);
    }
  }

  private setExtraParamToTheDropdownAndLoad(fieldName: string) {
    let grid = this.getGrid(),
      field = this.getField(),
      entity = this.getGrid().getSelectedEntity(),
      apiRoute = `${field.associationEndpoint}/offset/0/limit/50`,
      params = {};
    if(field.firstAssociationOrderBy){
      if(field.secondAssociationOrderBy){
        apiRoute += `/orderby/${field.firstAssociationOrderBy},${field.secondAssociationOrderBy}/${field.firstAssociationOrderByOrientation},${field.secondAssociationOrderByOrientation}?${fieldName}=${entity.id}`;
      } else {
        apiRoute += `/orderby/${field.firstAssociationOrderBy}/${field.firstAssociationOrderByOrientation}?${fieldName}=${entity.id}`;
      }
    }else{
      apiRoute += `/orderby/id/asc/?${fieldName}=${entity.id}`;
    }

    if(field.firstAssociationOrderType){
      if(field.secondAssociationOrderType){
        params['orderType'] = `${field.firstAssociationOrderType},${field.secondAssociationOrderType}`;
      }else{
        params['orderType'] = `${field.firstAssociationOrderType}`;
      }
    }

    this.genericCrudService.getPaginated(apiRoute, params)
      .subscribe((combinedResponse) => {

      grid.associatedOptions[field.entityName] = [
        { label: '---', value: null }
      ];

      let entityData = (combinedResponse instanceof Array) ? combinedResponse : combinedResponse.data;

      if (typeof entityData != 'undefined') {
        for (let entity of entityData) {
          grid.associatedOptions[field.entityName] = [...grid.associatedOptions[field.entityName],
            {
              label: entity[field.entityFieldName],
              value: entity.id
            }
          ];
        }
      }
    });
  }

  private setExtraParamToTheAutocomplete(fieldName: string) {

  }
}
