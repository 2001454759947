import { AbstractInlineEditor } from './abstract-inline-editor';
import {Observable, of} from 'rxjs';

export class PermissionInformationInlineEditor extends AbstractInlineEditor {

    public getValue(entity: any) {

    }

    public isEditDisabled(entity: any, column: any): boolean {
      return false;
    }

    public onEdit(entity: any, event: any) {
      this.genericCrudService.updateViewPermission([entity], this.column.organisation, event).subscribe((data) => {

      });
    }

    public onBlur(entity: any, event: any) {

    }

    public onFocus(entity: any, event: any): Observable<any> {
      return of(null);
    }
}
