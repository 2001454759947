import {Component, EventEmitter, OnInit, Output, Input} from '@angular/core';
import {Observable} from 'rxjs';
import {GenericCrudService} from '../../services/generic-crud.service';
import {Organisation} from '../../services/organisation/organisation';

@Component({
  selector: 'app-organisation-dropdown',
  templateUrl: './organisation-dropdown.component.html'
})
export class OrganisationDropdownComponent implements OnInit {

  @Input() public selectedOrganisationOption: SelectItem = null;
  @Input() public forceSelectionOnLoad = false;
  @Input() public valueAsId = false;
  @Input() public disabled = false;
  @Output() public onOptionChanged: EventEmitter<SelectItem> = new EventEmitter();

  public organisationOptions: SelectItem[] = [];

  public constructor(
    protected readonly genericCrudService: GenericCrudService
  ) {
  }

  public ngOnInit(): void {

    this.loadOrganisations()
      .subscribe((organisations: Organisation[] = []) => {
        this.organisationOptions = [];

        for (const organisation of organisations) {
          const value = this.valueAsId ? organisation.id : organisation;

          this.organisationOptions = [...this.organisationOptions, {
            label: organisation.name,
            value: value
          }];
        }

        if (this.forceSelectionOnLoad) {
          this.forceSelection();
        }
      });
  }

  public onOrganisationChanged(event?: {value: any}): void {
    this.onOptionChanged.emit(this.selectedOrganisationOption);
  }

  private loadOrganisations(): Observable<Organisation[]> {
    return this.genericCrudService.getEntities('app/organisations');
  }

  private forceSelection(): void {
    for (const organisationOption of this.organisationOptions) {
      if (this.forceSelectionOnLoad && this.selectedOrganisationOption && this.selectedOrganisationOption.value &&
        this.selectedOrganisationOption.value.id === organisationOption.value.id
      ) {
        this.selectedOrganisationOption = organisationOption.value;
      }
    }
  }
}

