
import {of as observableOf,  Observable } from 'rxjs';
import { Injectable } from "@angular/core";
import { Event } from "app/core/events/interfaces/event";
import { EventAware } from "app/core/events/interfaces/event-aware";
import { ActionHandlerSubscribeResponse } from '../interfaces/action-handler-subscribe-response';

export abstract class AbstractActionHandler implements EventAware {

    private event: Event = null;

    public abstract handleAction(): Observable<ActionHandlerSubscribeResponse>;

    public constructor() { }

    public setEvent(event: Event): this {
        this.event = event;
        return this;
    }

    public getEvent(): Event {
        return this.event;
    }

    protected getEmptyObservable() {
        return observableOf(null);
    }

    protected getObservable(status: boolean, content: any[] = []): Observable<ActionHandlerSubscribeResponse> {
        return observableOf({
            status: status,
            content: content
        });
    }
}
