import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Injector, Input, OnInit} from '@angular/core';
import { ToolbarItemAbstract } from '../toolbar-item-abstract.component';
import { GenericCrudService } from '../../../../../../services/generic-crud.service';
import { MessageGrowlService } from '../../../../../../../core/message/message-growl.service';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '../../../../../../../../environments';
import {RequestCachingService} from '../../../../../../services/request-caching.service';
import {AbstractGenericGridComponent} from '../../../../abstract-generic-grid.component';
import {PreCalculation, PreCalculationArticle} from '../../../../../../services/precalculation/article/pre-calculation-article';
import {PreCalculationArticleCalculatorFactory} from '../../../../../../services/precalculation/article/calculation/type/pre-calculation-article-calculator.factory';
import {GenericTreeGridComponent} from '../../../../generic-tree-grid/generic-tree-grid.component';
import {FormViewerComponent} from '../../../../../../form-viewer/form-viewer.component';
import {ChangeDetectorRefHelper} from '../../../../../../helpers/change-detector-ref.helper';

export interface ArticleFactor {
  code?: string;
  name?: string;
  factor?: number;
  factorTargetInvoice?: number;
  factorInvoiceMin?: number;
  factorAncillaryWageCosts?: number;
}

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-toolbar-item-pre-calculation-factors',
  templateUrl: './toolbar-item-pre-calculation-show-factors.component.html'
})
export class ToolbarItemPreCalculationShowFactorsComponent extends ToolbarItemAbstract {

  public static readonly FACTORS_URL = 'phoenix/precalculationarticles/';

  public placeholders: any[];

  public isDialogVisible = false;
  public isDataLoading = false;
  protected baseUrl = '';

  public factors: ArticleFactor[] = [];
  public selectedEntity: any;

  constructor(
    protected genericCrudService: GenericCrudService,
    protected messageGrowlService: MessageGrowlService,
    protected translate: TranslateService,
    protected requestCachingService: RequestCachingService,
    protected injector: Injector,
    public cdr: ChangeDetectorRef
  ) {
    super();
    this.baseUrl = environment.baseUrl;
  }

  public click() {
    this.isDialogVisible = true;
    this.selectedEntity = this.getEntity();

    this.loadFactors();
  }

  public onExport() {
    const generalGrid = this.getGeneralArticleGrid();
    const precalculation = this.getPreCalculation();

    const normalFactorEntity = this.getFactorBasedOnCode('NORM');
    if (normalFactorEntity && generalGrid instanceof AbstractGenericGridComponent && generalGrid.getSelectedEntity()) {
      this.recalculateGeneralArticle(generalGrid.getSelectedEntity(), normalFactorEntity, precalculation);
    }

    const otherArticlesGrid = this.getOtherArticlesGrid();

    if (otherArticlesGrid) {
      for (const otherArticle of otherArticlesGrid.getEntities()) {
        const articleFactor = this.getFactorBasedOnCode(otherArticle.code);

        if (articleFactor) {
          this.recalculateOtherArticle(otherArticle, articleFactor, generalGrid.getSelectedEntity(), precalculation);
        }
      }
    }

    generalGrid.recheckToolbarItems();

    this.isDialogVisible = false;
  }

  protected recalculateOtherArticle(article: PreCalculationArticle, factor: ArticleFactor, generalArticle: PreCalculationArticle, precalculation: PreCalculation) {
    article.factor = factor.factor;
    article.factorTargetInvoice = factor.factorTargetInvoice;
    article.factorAncillaryWageCosts = factor.factorAncillaryWageCosts;
    article.isChanged = true;
    article.isManuallyChangedInvoice = false;

    if (!article.isUsingFactorIncrement) {
      article.invoiceHour = article.salaryHour * article.factor;
    } else {
      article.invoiceHour = generalArticle.invoiceHour * article.factor;
    }
    article.targetInvoiceHour = article.invoiceHour;

    const calculatorFactory = this.injector.get(PreCalculationArticleCalculatorFactory, null);
    const calculatorGeneral = calculatorFactory.spawnCalculator(article);
    calculatorGeneral.calculate(article, [generalArticle], precalculation);
  }

  protected recalculateGeneralArticle(generalArticle: PreCalculationArticle, normalFactor: ArticleFactor, precalculation: PreCalculation) {
    generalArticle.factor = normalFactor.factor;
    generalArticle.factorTargetInvoice = normalFactor.factorTargetInvoice;
    generalArticle.factorAncillaryWageCosts = normalFactor.factorAncillaryWageCosts;
    generalArticle.isChanged = true;
    generalArticle.isManuallyChangedInvoice = false;

    const calculatorFactory = this.injector.get(PreCalculationArticleCalculatorFactory, null);
    const calculatorGeneral = calculatorFactory.spawnCalculator(generalArticle);
    calculatorGeneral.calculate(generalArticle, [], precalculation);
  }

  protected loadFactors() {
    this.isDataLoading = true;

    this.genericCrudService
      .getEntities(ToolbarItemPreCalculationShowFactorsComponent.FACTORS_URL  + this.selectedEntity.id + '/factors')
      .subscribe((factors) => {
        this.factors = [...factors];
        this.isDataLoading = false;

        ChangeDetectorRefHelper.detectChanges(this);
    });
  }

  protected getGeneralArticleGrid(): AbstractGenericGridComponent|null {
    const formContext = this.getComponent().getElementContext();

    if (formContext.getSlaveElementContexts().length > 0) {
      const gridContext = formContext.getSlaveElementContexts()[0];
      if (gridContext.component instanceof AbstractGenericGridComponent) {
        return gridContext.component;
      }
    }

    return null;
  }

  protected getPreCalculation(): PreCalculation|null {
    const masterForm = this.getComponent();
    if (!(masterForm instanceof FormViewerComponent)) {
      return null;
    }

    return masterForm.getSelectedEntity();
  }

  protected getOtherArticlesGrid(): GenericTreeGridComponent| null {
    const genericGrid = this.getGeneralArticleGrid();

    if (genericGrid instanceof AbstractGenericGridComponent && genericGrid.getElementContext().getSlaveElementContexts().length > 0) {
      const treeContext = genericGrid.getElementContext().getSlaveElementContexts()[0];

      if (treeContext.component instanceof GenericTreeGridComponent) {
        return treeContext.component;
      }
    }

    return null;
  }

  protected getFactorBasedOnCode(code: string): ArticleFactor|null {
    for (const factoryEntity of this.factors) {
      if (factoryEntity.code === code) {
        return factoryEntity;
      }
    }
    return null;
  }
}
