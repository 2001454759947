import { Injectable } from '@angular/core';
import { ToolbarItem } from '../../../../../services/element/toolbar-item';
import { ToolbarItemDisabledCheckInterface } from './toolbar-item-disabled-check-factory.service';
import { ComponentService } from '../../../../services/component-highlight-stack.service';
import { ElementsStackService } from '../../../../services/elements-stack.service';
import { FormViewerComponent } from '../../../../../form-viewer/form-viewer.component';
import { GenericElementAbstract } from '../../../generic-element-abstract.component';
import { ModulesStateService } from '../../../../services/modules-state.service';
import { AbstractGenericGridComponent } from '../../../abstract-generic-grid.component';
import {MemoFieldDefinitionValueComponent} from '../../../custom/memo-field-definition-value/memo-field-definition-value.component';
import {AuthenticationService} from '../../../../../../core/authentication/authentication.service';
import {CronJobFormComponent} from '../../../custom/cron-job-form/cron-job-form.component';
import {TodoFormComponent} from '../../../custom/todo-form/todo-form.component';
import {EntityStatus} from '../../../../../services/entity/entity-status';


@Injectable()
export class ToolbarItemAbstractDisabledCheckService implements ToolbarItemDisabledCheckInterface {
  protected toolbarItem: ToolbarItem;
  protected entity: any;
  protected component: any;

  constructor(
    protected elementStackService: ElementsStackService,
    protected componentService: ComponentService,
    protected modulesStateService: ModulesStateService,
    protected authenticationService: AuthenticationService
  ) {

  }

  setToolbarItem(toolbarItem: ToolbarItem): ToolbarItemDisabledCheckInterface {
    this.toolbarItem = toolbarItem;
    return this;
  }

  setEntity(entity: any): ToolbarItemDisabledCheckInterface {
    this.entity = entity;
    return this;
  }

  setComponent(component: any): ToolbarItemDisabledCheckInterface {
    this.component = component;

    return this;
  }

  isDisabled(): boolean {
    return false;
  }

  protected isDisabledGlobally(): boolean {
    const component = this.component;

    if (typeof component.isToolbarDisabled !== 'undefined') {
      return !!component.isToolbarDisabled;
    }

    return false;
  }

  protected isEntityDisabled(entity: any): boolean {
    return (entity) ? (entity.tmpIsDeleted || entity.deletedAt) : false;
  }

  protected isDisabledInModuleContext(): boolean {
    let isDisabled = false,
      componentsOnPage = []

    const moduleState = this.modulesStateService.getCurrent();

    if (moduleState) {
      componentsOnPage = moduleState.getAllComponents();
    }

    for (const component of componentsOnPage) {
      if (component instanceof AbstractGenericGridComponent) {
        isDisabled = this.isDisabledInComponentContext(component.selectedEntity, component);
      }

      if (component instanceof FormViewerComponent) {
        isDisabled = this.isDisabledInComponentContext(component.entity, component);
      }

      if (component instanceof MemoFieldDefinitionValueComponent) {
        isDisabled = !component.hasChanges();
      }

      if (!isDisabled) {
        break;
      }
    }

    return isDisabled;
  }

  protected isDisabledInComponentContext(entity: any, component: GenericElementAbstract): boolean {
    let isDisabled = this.isEntityDisabled(entity) && this.isMasterEntityProper(component);

    if (!isDisabled) {
      isDisabled = !(this.componentEntityHasBeenChanged(component) || this.componentEntityIsChanging(component));
    }

    return isDisabled;
  }

  private isMasterEntityProper(component: any): boolean {
    let isDisabled = false;

    const elementContext = (component && component.moduleElement) ?
      this.elementStackService.findById(component.moduleElement.id) : null;

    // Now, we need to check a master entity in case one is present:
    if (elementContext && (elementContext.isSubView || elementContext.isSlaveContext())) {
      const selectedMasterEntity = this.elementStackService.getSelectedMasterEntity(component.moduleElement.id);

      isDisabled = this.isEntityDisabled(selectedMasterEntity);
    }

    if (!isDisabled) {
      isDisabled = !this.componentEntityHasBeenChanged(component);
    }

    return isDisabled;
  }

  private componentEntityHasBeenChanged(component: GenericElementAbstract): boolean {
    let entityHasBeenChanged = false;

    // Now a little twist - in case there are really no changes, check if we are in a grid AND there are changes to be saved:
    if (component && component instanceof AbstractGenericGridComponent) {
      entityHasBeenChanged = component.hasChanges(false);
    }

    if (component &&
      (component instanceof FormViewerComponent ||
        component instanceof CronJobFormComponent ||
        component instanceof TodoFormComponent)
    ) {
      entityHasBeenChanged = component.isEntityDirty();
    }

    if (component && component instanceof MemoFieldDefinitionValueComponent) {
      entityHasBeenChanged = component.hasChanges();
    }

    return entityHasBeenChanged;
  }

  private componentEntityIsChanging(component: GenericElementAbstract) {
    return component && component instanceof FormViewerComponent && component.entity &&
      component.entity[EntityStatus.ENTITY_CHANGING_FLAG] === true;
  }
}
