/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./module-content.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../shared/directives/selenium.directive";
import * as i3 from "@angular/common";
import * as i4 from "../shared/content-renderer/content-renderer.component.ngfactory";
import * as i5 from "../shared/content-renderer/content-renderer.component";
import * as i6 from "../shared/services/generic-crud.service";
import * as i7 from "../shared/services/generic-crud-bulk.service";
import * as i8 from "../shared/services/layout-service";
import * as i9 from "./module-content.component";
import * as i10 from "@angular/router";
import * as i11 from "../shared/content-renderer/services/modules-state.service";
import * as i12 from "../shared/content-renderer/services/elements-stack.service";
import * as i13 from "../shared/content-renderer/services/elements-state.service";
import * as i14 from "../shared/services/location.service";
import * as i15 from "@ngx-translate/core";
import * as i16 from "../shared/content-renderer/services/entity-dirty-store.service";
import * as i17 from "primeng/components/common/confirmationservice";
import * as i18 from "../shared/content-renderer/services/data-sharing.service";
import * as i19 from "../shared/services/request-caching.service";
import * as i20 from "../core/service/module-content-blocker/module-content-blocker.service";
import * as i21 from "../shared/validators/services/entity-validator";
import * as i22 from "../shared/content-renderer/services/navigation/module-close.service";
import * as i23 from "../shared/content-renderer/services/navigation/module-change.service";
var styles_ModuleContentComponent = [i0.styles];
var RenderType_ModuleContentComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ModuleContentComponent, data: {} });
export { RenderType_ModuleContentComponent as RenderType_ModuleContentComponent };
function View_ModuleContentComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "li", [], [[8, "className", 0]], null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "a", [["appSeleniumDirective", ""]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onTabChange(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(2, 4210688, null, 0, i2.SeleniumDirective, [i1.ElementRef, i1.Renderer2], { moduleTab: [0, "moduleTab"] }, null), (_l()(), i1.ɵted(3, null, ["", " "])), (_l()(), i1.ɵeld(4, 0, null, null, 1, "a", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onTabClose(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 0, "i", [["class", "fa fa-close"]], null, null, null, null, null))], function (_ck, _v) { var currVal_1 = _v.context.$implicit.module; _ck(_v, 2, 0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.isCurrentModule(_v.context.$implicit) ? "selected" : ""); _ck(_v, 0, 0, currVal_0); var currVal_2 = (_v.context.$implicit.module.label || _v.context.$implicit.module.name); _ck(_v, 3, 0, currVal_2); }); }
function View_ModuleContentComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "ul", [["class", "tabrow"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ModuleContentComponent_2)), i1.ɵdid(2, 278528, null, 0, i3.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.modulesStates; _ck(_v, 2, 0, currVal_0); }, null); }
function View_ModuleContentComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "app-content-renderer", [["appSeleniumDirective", ""]], null, [["window", "resize"]], function (_v, en, $event) { var ad = true; if (("window:resize" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).onResize($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i4.View_ContentRendererComponent_0, i4.RenderType_ContentRendererComponent)), i1.ɵdid(1, 245760, null, 0, i5.ContentRendererComponent, [i6.GenericCrudService, i7.GenericCrudBulkService, i1.ElementRef, i8.LayoutService, i1.ChangeDetectorRef], { entity: [0, "entity"], isPart: [1, "isPart"], module: [2, "module"] }, null), i1.ɵdid(2, 4210688, null, 0, i2.SeleniumDirective, [i1.ElementRef, i1.Renderer2], { module: [0, "module"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.entity; var currVal_1 = false; var currVal_2 = _co.module; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2); var currVal_3 = _co.module; _ck(_v, 2, 0, currVal_3); }, null); }
export function View_ModuleContentComponent_0(_l) { return i1.ɵvid(2, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [["style", "width: 100%; height: 100%"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "div", [["style", "width: 100%; height: 29px"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ModuleContentComponent_1)), i1.ɵdid(3, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(4, 0, null, null, 2, "div", [["id", "content-renderer-container"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ModuleContentComponent_3)), i1.ɵdid(6, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.modulesStates.length > 0); _ck(_v, 3, 0, currVal_0); var currVal_1 = _co.module; _ck(_v, 6, 0, currVal_1); }, null); }
export function View_ModuleContentComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-module-content", [], null, [["window", "beforeunload"]], function (_v, en, $event) { var ad = true; if (("window:beforeunload" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).onClose($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, View_ModuleContentComponent_0, RenderType_ModuleContentComponent)), i1.ɵdid(1, 245760, null, 0, i9.ModuleContentComponent, [i10.ActivatedRoute, i10.Router, i6.GenericCrudService, i11.ModulesStateService, i12.ElementsStackService, i13.ElementsStateService, i14.LocationService, i15.TranslateService, i16.EntityDirtyStoreService, i17.ConfirmationService, i18.DataSharingService, i19.RequestCachingService, i20.ModuleContentBlockerService, i21.EntityValidator, i22.ModuleCloseService, i23.ModuleChangeService, i1.ChangeDetectorRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ModuleContentComponentNgFactory = i1.ɵccf("app-module-content", i9.ModuleContentComponent, View_ModuleContentComponent_Host_0, {}, {}, []);
export { ModuleContentComponentNgFactory as ModuleContentComponentNgFactory };
