<div *ngIf="container && !container.isFlaggedForBackendDelete" fxLayout="row wrap" fxLayoutAlign="start stretch" >
  <ng-template ngFor let-field [ngForOf]="container.fields">
    <div *ngIf="!field.isFlaggedForBackendDelete">
      <app-search-management-inline-container-field
        [expertSearch]="expertSearch"
        [container]="container"
        [field]="field"
        (onOpenEditField)="onEditField($event)"
        (onFieldRemove)="onFieldRemove($event)"
        (onContainerRemove)="onContainerRemove($event)"
      ></app-search-management-inline-container-field>
    </div>
  </ng-template>

  <div>
    <button
      [disabled]="!container"
      (click)="onAddField($event)"
      pButton
      label="{{ 'COMMON.AND' | translate }}"
      icon="fa fa-plus"
      class="add-field-button"
    ></button>
  </div>
</div>

<p-dialog
  [(visible)]="isEditFieldVisible"
  *ngIf="isEditFieldVisible"
  [modal]="true"
  [width]="800"
  [responsive]="true"
  [style]="{minWidth: '500px'}"
  appendTo="body"
>
  <app-search-management-edit-container-field
    [expertSearch]="expertSearch"
    [container]="container"
    [field]="field"
    (onSave)="onSaveField($event)"
  ></app-search-management-edit-container-field>
</p-dialog>
