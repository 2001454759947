/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./toolbar-item-open-module-contract-of-employment.component";
import * as i2 from "../../../../../../../../core/message/message-growl.service";
import * as i3 from "../../../../../../services/double-click.service";
import * as i4 from "../../../../../generic-dialog/service/generic-dialog-module.service";
import * as i5 from "../../../../../../../services/generic-crud.service";
import * as i6 from "../../../../../../services/entity-dirty-store.service";
import * as i7 from "../../../../../../../../core/service/entity-manager/entity-manager.service";
import * as i8 from "../../../../../../services/modules-state.service";
var styles_ToolbarItemOpenModuleContractOfEmploymentComponent = [];
var RenderType_ToolbarItemOpenModuleContractOfEmploymentComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_ToolbarItemOpenModuleContractOfEmploymentComponent, data: {} });
export { RenderType_ToolbarItemOpenModuleContractOfEmploymentComponent as RenderType_ToolbarItemOpenModuleContractOfEmploymentComponent };
export function View_ToolbarItemOpenModuleContractOfEmploymentComponent_0(_l) { return i0.ɵvid(2, [], null, null); }
export function View_ToolbarItemOpenModuleContractOfEmploymentComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-toolbar-item-open-module-contract-of-employment", [], null, null, null, View_ToolbarItemOpenModuleContractOfEmploymentComponent_0, RenderType_ToolbarItemOpenModuleContractOfEmploymentComponent)), i0.ɵdid(1, 245760, null, 0, i1.ToolbarItemOpenModuleContractOfEmploymentComponent, [i2.MessageGrowlService, i3.DoubleClickService, i4.GenericDialogModuleService, i5.GenericCrudService, i6.EntityDirtyStoreService, i7.EntityManagerService, i8.ModulesStateService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ToolbarItemOpenModuleContractOfEmploymentComponentNgFactory = i0.ɵccf("app-toolbar-item-open-module-contract-of-employment", i1.ToolbarItemOpenModuleContractOfEmploymentComponent, View_ToolbarItemOpenModuleContractOfEmploymentComponent_Host_0, { params: "params" }, {}, []);
export { ToolbarItemOpenModuleContractOfEmploymentComponentNgFactory as ToolbarItemOpenModuleContractOfEmploymentComponentNgFactory };
