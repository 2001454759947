import { of as observableOf } from 'rxjs';
import { AbstractExecutionStep } from '../../../../core/executor/abstract-execution-step';
import { AbstractGenericGridComponent } from "../../../content-renderer/elements/abstract-generic-grid.component";
import { EntityManagerService } from "../../../../core/service/entity-manager/entity-manager.service";
export class WorkHourMonthlyEntrySetupNewEntityStep extends AbstractExecutionStep {
    constructor() {
        super(...arguments);
        this.entityManager = null;
    }
    doExecute() {
        const payloadValue = this.getPayload().getValue(), component = payloadValue.component ? payloadValue.component : payloadValue;
        if (!(component instanceof AbstractGenericGridComponent)) {
            return this.getFailObservable('You need to pass GenericElementAbstract as Payload value!');
        }
        this.entityManager = this.injector.get(EntityManagerService, null);
        return this.doSetup(component);
    }
    doSetup(component) {
        let selectedEntity = component.getSelectedEntity();
        let workHour = component.masterElementContext.selectedEntity;
        if (!selectedEntity || selectedEntity.fqn !== 'PhoenixBundle\\Entity\\WorkHourMonthlyEntry') {
            return this.getFailObservable('You need to pass WorkHourMonthlyEntry as entity!');
        }
        if (!workHour || workHour.fqn !== 'PhoenixBundle\\Entity\\WorkHour') {
            return this.getFailObservable('You need to pass WorkHour as entity!');
        }
        selectedEntity.assignment = workHour.assignment;
        this.entityManager.persist(selectedEntity, { property: 'assignment', newValue: workHour.assignment });
        selectedEntity._embedded.assignment = workHour.assignment;
        selectedEntity.leasedEmployee = workHour.leasedEmployee;
        this.entityManager.persist(selectedEntity, { property: 'leasedEmployee', newValue: workHour.leasedEmployee });
        selectedEntity._embedded.leasedEmployee = workHour.leasedEmployee;
        selectedEntity.year = workHour.workYear;
        this.entityManager.persist(selectedEntity, { property: 'year', newValue: workHour.workYear });
        selectedEntity.month = workHour.workMonth;
        this.entityManager.persist(selectedEntity, { property: 'month', newValue: workHour.workMonth });
        return observableOf({ status: true, content: null });
    }
}
