/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./inquiry-position-counter-column.component";
var styles_InquiryPositionCounterColumnComponent = [];
var RenderType_InquiryPositionCounterColumnComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_InquiryPositionCounterColumnComponent, data: {} });
export { RenderType_InquiryPositionCounterColumnComponent as RenderType_InquiryPositionCounterColumnComponent };
export function View_InquiryPositionCounterColumnComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "div", [], [[4, "background", null], [4, "height", "px"], [4, "lineHeight", "px"], [4, "textAlign", null]], null, null, null, null)), (_l()(), i0.ɵted(1, null, [" ", " ", "\n"]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.backgroundColor; var currVal_1 = 20; var currVal_2 = 20; var currVal_3 = "center"; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2, currVal_3); var currVal_4 = _co.preText; var currVal_5 = _co.entityValue; _ck(_v, 1, 0, currVal_4, currVal_5); }); }
export function View_InquiryPositionCounterColumnComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-inquiry-position-counter-column", [], null, null, null, View_InquiryPositionCounterColumnComponent_0, RenderType_InquiryPositionCounterColumnComponent)), i0.ɵdid(1, 4308992, null, 0, i1.InquiryPositionCounterColumnComponent, [i0.ElementRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var InquiryPositionCounterColumnComponentNgFactory = i0.ɵccf("app-inquiry-position-counter-column", i1.InquiryPositionCounterColumnComponent, View_InquiryPositionCounterColumnComponent_Host_0, { node: "node", entity: "entity", component: "component", column: "column" }, {}, []);
export { InquiryPositionCounterColumnComponentNgFactory as InquiryPositionCounterColumnComponentNgFactory };
