/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./toolbar-item-save-grouped-article.component";
import * as i2 from "../../../../../../services/generic-crud.service";
import * as i3 from "../../../../../../../core/message/message-growl.service";
import * as i4 from "@ngx-translate/core";
import * as i5 from "../../../../../services/entity-dirty-store.service";
var styles_ToolbarItemSaveGroupedArticleComponent = [];
var RenderType_ToolbarItemSaveGroupedArticleComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_ToolbarItemSaveGroupedArticleComponent, data: {} });
export { RenderType_ToolbarItemSaveGroupedArticleComponent as RenderType_ToolbarItemSaveGroupedArticleComponent };
export function View_ToolbarItemSaveGroupedArticleComponent_0(_l) { return i0.ɵvid(2, [], null, null); }
export function View_ToolbarItemSaveGroupedArticleComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-toolbar-item-save-grouped-article", [], null, null, null, View_ToolbarItemSaveGroupedArticleComponent_0, RenderType_ToolbarItemSaveGroupedArticleComponent)), i0.ɵdid(1, 245760, null, 0, i1.ToolbarItemSaveGroupedArticleComponent, [i2.GenericCrudService, i3.MessageGrowlService, i4.TranslateService, i5.EntityDirtyStoreService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ToolbarItemSaveGroupedArticleComponentNgFactory = i0.ɵccf("app-toolbar-item-save-grouped-article", i1.ToolbarItemSaveGroupedArticleComponent, View_ToolbarItemSaveGroupedArticleComponent_Host_0, { params: "params" }, {}, []);
export { ToolbarItemSaveGroupedArticleComponentNgFactory as ToolbarItemSaveGroupedArticleComponentNgFactory };
