import {AbstractCondition} from '../../../../../core/job-runner/condition/abstract.condition';
import {JobContext} from '../../../../../core/job-runner/context/job.context';
import {RunnableEventRegistry} from '../../../../../core/job-runner/type/runnable-event.registry';
import {AbstractGenericGridComponent} from '../../../../content-renderer/elements/abstract-generic-grid.component';

export class SetDataForLeasedEmployeeSearchCondition extends AbstractCondition {
  isApplicable(context: JobContext): boolean {
    return context.event === RunnableEventRegistry.SelectedEntitiesChanged
      && context.component instanceof AbstractGenericGridComponent
      && context.component.moduleElement
      && context.component.moduleElement.element
      && context.component.moduleElement.element.datamodel
      && (
        context.component.moduleElement.element.datamodel.name === 'PhoenixBundle.Skill'
        || context.component.moduleElement.element.datamodel.name === 'PhoenixBundle.Language'
        || context.component.moduleElement.element.datamodel.name === 'PhoenixBundle.EducationType'
      );
  }
}
