import {Injectable} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';

@Injectable()
export class CalendarLocaleService {

  public constructor(
    private translateService: TranslateService
  ) {

  }

  public getLocale() {
    return {
      firstDayOfWeek: this.translateService.instant('COMMON.DATE.FIRST_DAY_OF_WEEK'),
      dayNames: [
        this.translateService.instant('COMMON.DATE.DAY_NAMES.SUNDAY'),
        this.translateService.instant('COMMON.DATE.DAY_NAMES.MONDAY'),
        this.translateService.instant('COMMON.DATE.DAY_NAMES.TUESDAY'),
        this.translateService.instant('COMMON.DATE.DAY_NAMES.WEDNESDAY'),
        this.translateService.instant('COMMON.DATE.DAY_NAMES.THURSDAY'),
        this.translateService.instant('COMMON.DATE.DAY_NAMES.FRIDAY'),
        this.translateService.instant('COMMON.DATE.DAY_NAMES.SATURDAY')
      ],
      dayNamesShort: [
        this.translateService.instant('COMMON.DATE.DAY_NAMES_SHORT.SUNDAY'),
        this.translateService.instant('COMMON.DATE.DAY_NAMES_SHORT.MONDAY'),
        this.translateService.instant('COMMON.DATE.DAY_NAMES_SHORT.TUESDAY'),
        this.translateService.instant('COMMON.DATE.DAY_NAMES_SHORT.WEDNESDAY'),
        this.translateService.instant('COMMON.DATE.DAY_NAMES_SHORT.THURSDAY'),
        this.translateService.instant('COMMON.DATE.DAY_NAMES_SHORT.FRIDAY'),
        this.translateService.instant('COMMON.DATE.DAY_NAMES_SHORT.SATURDAY')
      ],
      dayNamesMin: [
        this.translateService.instant('COMMON.DATE.DAY_NAMES_MIN.SUNDAY'),
        this.translateService.instant('COMMON.DATE.DAY_NAMES_MIN.MONDAY'),
        this.translateService.instant('COMMON.DATE.DAY_NAMES_MIN.TUESDAY'),
        this.translateService.instant('COMMON.DATE.DAY_NAMES_MIN.WEDNESDAY'),
        this.translateService.instant('COMMON.DATE.DAY_NAMES_MIN.THURSDAY'),
        this.translateService.instant('COMMON.DATE.DAY_NAMES_MIN.FRIDAY'),
        this.translateService.instant('COMMON.DATE.DAY_NAMES_MIN.SATURDAY')
      ],
      monthNames: [
        this.translateService.instant('COMMON.DATE.MONTH_NAMES.JANUARY'),
        this.translateService.instant('COMMON.DATE.MONTH_NAMES.FEBRUARY'),
        this.translateService.instant('COMMON.DATE.MONTH_NAMES.MARCH'),
        this.translateService.instant('COMMON.DATE.MONTH_NAMES.APRIL'),
        this.translateService.instant('COMMON.DATE.MONTH_NAMES.MAY'),
        this.translateService.instant('COMMON.DATE.MONTH_NAMES.JUNE'),
        this.translateService.instant('COMMON.DATE.MONTH_NAMES.JULY'),
        this.translateService.instant('COMMON.DATE.MONTH_NAMES.AUGUST'),
        this.translateService.instant('COMMON.DATE.MONTH_NAMES.SEPTEMBER'),
        this.translateService.instant('COMMON.DATE.MONTH_NAMES.OCTOBER'),
        this.translateService.instant('COMMON.DATE.MONTH_NAMES.NOVEMBER'),
        this.translateService.instant('COMMON.DATE.MONTH_NAMES.DECEMBER')
      ],
      monthNamesShort: [
        this.translateService.instant('COMMON.DATE.MONTH_NAMES_SHORT.JANUARY'),
        this.translateService.instant('COMMON.DATE.MONTH_NAMES_SHORT.FEBRUARY'),
        this.translateService.instant('COMMON.DATE.MONTH_NAMES_SHORT.MARCH'),
        this.translateService.instant('COMMON.DATE.MONTH_NAMES_SHORT.APRIL'),
        this.translateService.instant('COMMON.DATE.MONTH_NAMES_SHORT.MAY'),
        this.translateService.instant('COMMON.DATE.MONTH_NAMES_SHORT.JUNE'),
        this.translateService.instant('COMMON.DATE.MONTH_NAMES_SHORT.JULY'),
        this.translateService.instant('COMMON.DATE.MONTH_NAMES_SHORT.AUGUST'),
        this.translateService.instant('COMMON.DATE.MONTH_NAMES_SHORT.SEPTEMBER'),
        this.translateService.instant('COMMON.DATE.MONTH_NAMES_SHORT.OCTOBER'),
        this.translateService.instant('COMMON.DATE.MONTH_NAMES_SHORT.NOVEMBER'),
        this.translateService.instant('COMMON.DATE.MONTH_NAMES_SHORT.DECEMBER')
      ],
      today: this.translateService.instant('COMMON.DATE.TODAY'),
      clear: this.translateService.instant('COMMON.DATE.CLEAR')
    };
  }
}
