import { Injectable } from '@angular/core';
import {EntityStatus} from '../../services/entity/entity-status';

export enum StoreType {
    Create = 1,
    Update = 2
}

@Injectable()
export class EntityDirtyStoreService {

    private entities: any = {
      // StoreType : {
      //    fqn: [entites...]
      // }
    };

    public constructor() {
        this.entities[StoreType.Create] = {};
        this.entities[StoreType.Update] = {};
    }

    public isEmpty() {
        return this.isTypeEmpty(StoreType.Create) && this.isTypeEmpty(StoreType.Update);
    }

    public isTypeEmpty(type: StoreType) {
        let empty = true;
        for (let fqnKey of Object.keys(this.entities[type])) {
            empty = empty && this.fqnClean(type, fqnKey);
        }

        return empty;
    }

    public fqnClean(type: StoreType, fqnKey: string) {
        return typeof this.entities[type][fqnKey] === 'undefined' || this.entities[type][fqnKey].length == 0;
    }

    public clear(): void {
      this.entities[StoreType.Create] = {};
      this.entities[StoreType.Update] = {};
    }

    public fetch(entity: any) {
        return this.getById(StoreType.Update, entity.fqn, entity.id);
    }

    public fetchCreated(entity: any) {
      let fqn = entity.fqn,
        dirtyEntity = null;
      if(this.entities[StoreType.Create] && this.entities[StoreType.Create][fqn]) {
        dirtyEntity = this.entities[StoreType.Create][fqn].find(aEntity => aEntity[EntityStatus.ENTITY_DRAFT_FLAG] === entity[EntityStatus.ENTITY_DRAFT_FLAG]);
      }
      return dirtyEntity;
    }

    public forceStore(entity: any): void {
      if (entity && entity.id) {
        this.prepare(StoreType.Update, entity.fqn).update(entity);
      } else if (entity && !entity.id) {
        this.prepare(StoreType.Create, entity.fqn).create(entity);
      }
    }

    public store(entity: any, opts?: any) {

        if (!entity || !entity.fqn) {
          if (opts) {
            return opts.success(false);
          }
          return;
        }

        if (!entity.id) {
            this.prepare(StoreType.Create, entity.fqn).create(entity);
            if (opts) {
                return opts.success(false);
            }
        }

        if (entity.id) {
            if (this.isDirty(entity)) {
                this.prepare(StoreType.Update, entity.fqn).update(entity);

                if (opts) {
                    return opts.success(false);
                }
            } else {
              this.prepare(StoreType.Update, entity.fqn).update(entity);

              if (opts) {
                return opts.success(true);
              }
            }
        }
    }

    public replace(entity: any): any {

        if (entity && entity.id && entity.fqn) {
            let fqn = entity.fqn,
                id = entity.id;

            // maybe type depends on if entity has id or not?
            let temp = this.prepare(StoreType.Update, fqn).getById(StoreType.Update, fqn, id);

            if (typeof temp !== 'undefined' && null !== temp) {
                entity = temp;
            }
        }

        return entity;
    }

    public remove(entity: any):void {
        this.doRemove(entity, StoreType.Create).doRemove(entity, StoreType.Update);
    }

    public doRemove(entity: any, type: StoreType): EntityDirtyStoreService {
        if (entity && entity.fqn) {
            const fqn = entity.fqn,
              id = entity.id;

            // maybe type depends on if entity has id or not?
            let index = this.prepare(type, fqn).getIndexById(type, fqn, id);

            if (index === -1 && entity[EntityStatus.ENTITY_DRAFT_FLAG]) {
              index = this.prepare(type, fqn).getIndexById(type, fqn, entity[EntityStatus.ENTITY_DRAFT_FLAG]);
            }

            if (index !== -1) {
              this.entities[type][fqn].splice(index, 1);
            }
        }

        return this;
    }

    public removeFQN(fqn: string): void {
        this.removeFQNByType(fqn, StoreType.Update).removeFQNByType(fqn, StoreType.Create);
    }

    public removeFQNByType(fqn: string, type: StoreType): EntityDirtyStoreService {
        if (type == StoreType.Update && this.entities[type] && this.entities[type][fqn]) {
            for (let entity of this.entities[type][fqn]) {
                this.doRemove(entity, type);
            }
        }

        this.entities[type][fqn] = [];

        return this;
    }

    public isDirty(entity): boolean {
        const fqn = entity.fqn,
            id = entity.id;

        if (!entity.id && entity[EntityStatus.ENTITY_DRAFT_FLAG]) {
            return this.prepare(StoreType.Create, fqn).getIndexById(StoreType.Create, fqn, entity[EntityStatus.ENTITY_DRAFT_FLAG]) !== -1;
        }

        const updatedEntity = this.prepare(StoreType.Update, fqn).getById(StoreType.Update, fqn, id);
        return updatedEntity && updatedEntity[EntityStatus.ENTITY_CHANGED_FLAG];
    }

    public getAll(type: StoreType, fqn: string) {
      return this.entities[type][fqn] ? this.entities[type][fqn] : [];
    }

    private prepare(type: StoreType, fqn: string) {
        if (!this.entities[type][fqn]) {
            this.entities[type][fqn] = [];
        }

        return this;
    }

    private create(entity: any) {
        let fqn = entity.fqn;

        let index = this.getIndexById(StoreType.Create, fqn, entity[EntityStatus.ENTITY_DRAFT_FLAG]);

        if (index != -1) {
            this.entities[StoreType.Create][fqn][index] = entity;
        } else {
            this.entities[StoreType.Create][fqn].push(entity);
        }
    }

    private update(entity: any) {
        let fqn = entity.fqn,
            id = entity.id;

        let index = this.getIndexById(StoreType.Update, fqn, id);

        if (index !== -1) {
            this.entities[StoreType.Update][fqn][index] = entity;
        } else {
            this.entities[StoreType.Update][fqn].push(entity);
        }
    }

    private getById(type: StoreType, fqn: string, id: number): any | null {
        return this.entities[type][fqn].find((entity) => { return entity.id === id; });
    }

    private getIndexById(type: StoreType, fqn: string, id: number|string): number {

        if (type === StoreType.Create) {
            return this.entities[type][fqn].findIndex(entity => entity[EntityStatus.ENTITY_DRAFT_FLAG] === id);
        }

        return this.entities[type][fqn].findIndex(entity => entity.id === id);
    }

}
