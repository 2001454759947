import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { Language } from './language';
import { LanguageCrudService } from './language-crud.service';
import {UserSessionService} from '../../../core/service/user-session.service';
import {AuthenticationService} from '../../../core/authentication/authentication.service';

@Injectable({
  providedIn: 'root'
})
export class LanguageService {

  private selectedLanguage: string;
  private languages: Observable<Language[]>;

  private static LOCAL_STORAGE_LANGUAGE_NAME = 'user-lang';

  constructor(
    private languageCrudService: LanguageCrudService,
    private userSession: UserSessionService,
    private authentication: AuthenticationService
  ) {
  }

  selectLanguage(language) {
    this.selectedLanguage = language;

    return this;
  }

  setLocalStorageLanguage(language) {
    this.userSession.set(LanguageService.LOCAL_STORAGE_LANGUAGE_NAME, {
      value: language
    });

    return this;
  }

  getSelectedLanguage() {
    return this.selectedLanguage;
  }

  getLocalStorageLanguage() {
    if (!this.authentication.authenticated()) {
      return 'de';
    }

    return this.userSession.get(LanguageService.LOCAL_STORAGE_LANGUAGE_NAME) || 'de';
  }

  getLanguages(): Observable<Language[]> {
    this.languages = this.languageCrudService.getLanguages();
    return this.languages;
  }

  getLanguageById(languageId = 0): Observable<Language> {
    return this.languageCrudService.getLanguage(languageId);
  }

}
