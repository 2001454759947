
import {of as observableOf, Observable} from 'rxjs';
import {AbstractGenericGridComponent} from '../../content-renderer/elements/abstract-generic-grid.component';
import {DoubleClickService} from '../../content-renderer/services/double-click.service';
import {FormViewerComponent} from '../../form-viewer/form-viewer.component';
import {ComponentOpenModuleExecutionStep} from './component-open-module-execution-step';

export class ComponentOpenDetailsViewExecutionStep extends ComponentOpenModuleExecutionStep {

  protected doOpenModuleInGridContext(component: AbstractGenericGridComponent|FormViewerComponent) {
    const doubleClickService: DoubleClickService = this.injector.get(DoubleClickService, null);

    if (component instanceof AbstractGenericGridComponent) {
      component.triggerEditEntityActions();
      doubleClickService.setComponent(component);
      doubleClickService.onDoubleClick(null);
    }

    return observableOf({status: true, content: null});
  }

}
