/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./toolbar-item-reset-pre-calculation-article.component";
import * as i2 from "../../../../../../services/generic-crud.service";
import * as i3 from "../../../../../../../core/message/message-growl.service";
import * as i4 from "@ngx-translate/core";
import * as i5 from "../../../../../services/entity-dirty-store.service";
var styles_ToolbarItemResetPreCalculationArticleComponent = [];
var RenderType_ToolbarItemResetPreCalculationArticleComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_ToolbarItemResetPreCalculationArticleComponent, data: {} });
export { RenderType_ToolbarItemResetPreCalculationArticleComponent as RenderType_ToolbarItemResetPreCalculationArticleComponent };
export function View_ToolbarItemResetPreCalculationArticleComponent_0(_l) { return i0.ɵvid(2, [], null, null); }
export function View_ToolbarItemResetPreCalculationArticleComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-toolbar-item-reset-pre-calculation-article", [], null, null, null, View_ToolbarItemResetPreCalculationArticleComponent_0, RenderType_ToolbarItemResetPreCalculationArticleComponent)), i0.ɵdid(1, 245760, null, 0, i1.ToolbarItemResetPreCalculationArticleComponent, [i2.GenericCrudService, i3.MessageGrowlService, i4.TranslateService, i5.EntityDirtyStoreService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ToolbarItemResetPreCalculationArticleComponentNgFactory = i0.ɵccf("app-toolbar-item-reset-pre-calculation-article", i1.ToolbarItemResetPreCalculationArticleComponent, View_ToolbarItemResetPreCalculationArticleComponent_Host_0, { params: "params" }, {}, []);
export { ToolbarItemResetPreCalculationArticleComponentNgFactory as ToolbarItemResetPreCalculationArticleComponentNgFactory };
