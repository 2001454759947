import {AbstractJob} from '../../../../../core/job-runner/job/abstract.job';
import {JobInterface} from '../../../../../core/job-runner/job/job.interface';
import {JobContext} from '../../../../../core/job-runner/context/job.context';
import {ConditionInterface} from '../../../../../core/job-runner/condition/condition.interface';
import {Observable} from 'rxjs';
import {JobExecutionStatus} from '../../../../../core/job-runner/job/job-execution-status';
import {LeasedEmployeeSearchComponent} from '../../../../content-renderer/elements/custom/leased-employee-search/leased-employee-search.component';
import {AbstractGenericGridComponent} from '../../../../content-renderer/elements/abstract-generic-grid.component';

export class SetDataForLeasedEmployeeSearchJob extends AbstractJob implements JobInterface {
  run(context: JobContext, condition: ConditionInterface): Observable<JobExecutionStatus> {
    return Observable.create((observer) => {
      const component = context.component;
      const searchComponent = condition.getPayload().component;

      if (component instanceof AbstractGenericGridComponent && searchComponent instanceof LeasedEmployeeSearchComponent) {
        const entityName = component.getElementDatamodelEntityName();

        let filterName = '';

        switch (entityName) {
          case 'PhoenixBundle\\Entity\\Skill':
              filterName = 'skills';
            break;
          case 'PhoenixBundle\\Entity\\Language':
              filterName = 'languages';
            break;
          case 'PhoenixBundle\\Entity\\EducationType':
              filterName = 'educationTypes';
            break;
        }

        searchComponent.getElementContext().selectedEntity[filterName] = component.selectedEntities;
      }
    });
  }
}
