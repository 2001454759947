import {Injectable} from '@angular/core';
import {ToolbarItemColoringInterface} from './toolbar-item-coloring-factory.service';
import {ToolbarItem} from '../../../../../services/element/toolbar-item';

@Injectable()
export class ToolbarItemAbstractColoringService implements ToolbarItemColoringInterface {
  protected toolbarItem: ToolbarItem;
  protected entity: any;
  protected component: any;

  setToolbarItem(toolbarItem: ToolbarItem): ToolbarItemColoringInterface {
    this.toolbarItem = toolbarItem;
    return this;
  }

  setEntity(entity: any): ToolbarItemColoringInterface {
    this.entity = entity;
    return this;
  }

  setComponent(component: any): ToolbarItemColoringInterface {
    this.component = component;

    return this;
  }

  getColor(): string|null {
    return this.toolbarItem.backgroundColor;
  }
}
