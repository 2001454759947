
import {of as observableOf, Observable} from 'rxjs';
import {AbstractExecutionStep} from '../../../../core/executor/abstract-execution-step';
import {ExecutionStepStatus} from '../../../../core/executor/execution-step-status';
import {WizardComponent} from '../../../content-renderer/elements/custom/wizard/wizard.component';
import {WizardElementDetails} from '../../../content-renderer/elements/custom/wizard/service/wizard.service';

export class WizardLastElementExecutionStep extends AbstractExecutionStep {

  public doExecute(): Observable<ExecutionStepStatus> {
    const wizardComponent = this.getPayload().getValue().wizardComponent;

    if (!(wizardComponent instanceof WizardComponent)) {
      return this.getFailObservable('You need to pass WizardComponent as Payload value!');
    }

    return this.openLast(wizardComponent);
  }

  protected openLast(component: WizardComponent): Observable<ExecutionStepStatus> {
    component.wizardService.openLast().subscribe((wizardElementDetails: WizardElementDetails) => {
      component.wizardElement = wizardElementDetails.wizardElement;
    });

    return observableOf({status: true, content: null});
  }
}
