<div class="ui-inputgroup">
  <input
    pInputText
    [placeholder]="'Module id'"
    [(ngModel)]="moduleId"
    class="module-opener-input"
    (keydown)="onKeydown($event)"
  >
  <button
    pButton
    (click)="onOpenModule($event)"
    class="module-opener-button"
    icon="fa fa-arrow-right"
  ></button>
</div>
