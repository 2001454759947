import * as tslib_1 from "tslib";
import { OnInit, OnDestroy, AfterViewInit, ElementRef } from '@angular/core';
import { Validators } from '@angular/forms';
import { CustomValidators } from 'ng2-validation';
import { UserCurrent } from '../../shared/services/user/user';
import { Organisation } from '../../shared/services/organisation/organisation';
import { Debounce } from '../../shared/helpers/debounce';
import { Constants } from 'app/constants';
import { ExecutorService } from '../executor/executor.service';
import { BlockUI } from 'ng-block-ui';
import { Group } from '../../shared/services/group/group';
import { filter, map, switchMap, tap } from 'rxjs/operators';
/**
 * @description Angular Login component
 * @export
 * @class LoginComponent
 * @implements {OnInit}
 * @implements {OnDestroy}
 */
export class LoginComponent {
    /**
     * Creates an instance of LoginComponent.
     * @param {FormBuilder} fb Angular FormBuilder
     * @param {Router} router Angular Router
     * @param {AuthenticationService} authenticationService Authentication service for handling jwt
     * @param {OrganisationCrudService} organisationCrudService Fetch organisations
     * @param {StyleService} styleService Set user interface styling
     * @param {LocalStorageDataService} localStorage Used to store something at local storage
     * @memberof LoginComponent
     */
    constructor(authenticationService, fb, router, organisationCrudService, styleService, localStorage, genericCrudService, bootstrapService, executorService, userSession) {
        this.authenticationService = authenticationService;
        this.fb = fb;
        this.router = router;
        this.organisationCrudService = organisationCrudService;
        this.styleService = styleService;
        this.localStorage = localStorage;
        this.genericCrudService = genericCrudService;
        this.bootstrapService = bootstrapService;
        this.executorService = executorService;
        this.userSession = userSession;
        this.selectedOrganisation = null;
        // we dont need ngModel if we use formBuilder
        // private selectedOrganisation: Organisation;
        /**
         * @description Observable subscriptions so we can easily unsubscribe.
         * @private
         * @type {Subscription[]}
         * @memberof LoginComponent
         */
        this.subscriptions = [];
        /**
         * @description Observable subscriptions for get organisations so we can easily unsubscribe.
         * @private
         * @type {Subscription[]}
         * @memberof LoginComponent
         */
        this.subscriptionsOrganisation = [];
    }
    /**
     * @description On init we check if user already authenticated, otherwise we create a login form.
     * @memberof LoginComponent
     */
    ngOnInit() {
        // If already authenticated we redirect to root path
        if (this.authenticationService.authenticated()) {
            this.router.navigate(['/']);
        }
        // Setting a constant with environment default values if available
        const email = '';
        const password = '';
        // Create login form and set some validators
        this.form = this.fb.group({
            email: this.fb.control(email, [Validators.required, CustomValidators.email]),
            password: this.fb.control(password, [Validators.required]),
            organisation: this.fb.control(null)
        });
        // Set a value change event to email control for getting organisations based on mail.
        this.form.get('email').valueChanges.subscribe((val) => this.onEmailChange(val));
        // Execute on init cause we can have a default email value
        this.onEmailChange(email);
    }
    ngAfterViewInit() {
        setTimeout(() => {
            if (this.loginContainer && this.loginContainer.nativeElement) {
                this.loginContainer.nativeElement.click();
            }
        }, 500);
    }
    onSelectOrganisation(event) {
        this.selectedOrganisation = event.value;
    }
    /**
     * @description Submitting the login form and check for authentication.
     * @param {Event} event Submit event
     * @param {FormGroup} form Our form group.
     * @memberof LoginComponent
     */
    doLogin(event, form) {
        // Prevent default submit. So it also make no submit if we have an error in our doLogin function.
        event.preventDefault();
        const email = form.value.email;
        const password = form.value.password;
        const organisation = form.value.organisation;
        // Add login block
        this.setLoginState(false, true);
        this.subscriptions.push(this.authenticationService
            .login(email, password)
            .pipe(map((data) => {
            const token = data.token || undefined;
            // Authorize user
            this.authenticationService.authorize(email, token);
            return data;
        }), filter((data) => this.authenticationService.currentUser !== null && this.authenticationService.currentUser !== undefined), switchMap((data) => {
            const token = data.token || undefined;
            return this.genericCrudService.get(`${Constants.APP_API_ROUTE}/users/me?embedded=defaultBranchOffice,group`)
                .pipe(tap((userData) => {
                // now set user again, but with defaultBranch assigned
                this.authenticationService.currentUser = new UserCurrent(email, token, userData.signature, userData.id, userData._embedded ? userData._embedded.defaultBranch : null, userData.isSuperUser);
                this.setUserGroup(userData);
            }), switchMap(() => {
                return this.bootstrapService.setExecutorService(this.executorService).bootstrap()
                    .pipe(map((bootStrapData) => {
                    return bootStrapData;
                }));
            }));
        }), tap((data) => {
            // Remove error & login block
            this.setLoginState();
            this.setDesignBasedOnOrganisation(organisation);
            // redirect to root path after successfull login
            this.router.navigate(['/']);
        }))
            .subscribe((data) => { }, error => {
            // If we had a login error we will show an error message
            this.setLoginState(true);
        }));
    }
    /**
     * @description Block login form or show a login error message or do both.
     * @param {boolean} [error=false] Shows an error message.
     * @param {boolean} [block=false] Blocks the login form.
     * @memberof LoginComponent
     */
    setLoginState(error = false, block = false) {
        this.loginError = error;
        this.blockActive = block;
        if (block) {
            this.blockUI.start('Bitte warten');
        }
        else {
            this.blockUI.stop();
        }
    }
    // @note Made private to public cause if we use it in templates it's technically not right as private. It works but angular prefers
    // public for variables or functions used in templates.
    /**
     * @description If email changes we will fetch organisations which can user select for design.
     * @param {string} email User e-mail.
     * @memberof LoginComponent
     */
    onEmailChange(email) {
        Debounce.debounce(() => this.fetchOrganisations(email), 500);
    }
    fetchOrganisations(email) {
        this.subscriptionsOrganisation.forEach((sub) => sub.unsubscribe());
        if (email) {
            this.subscriptionsOrganisation.push(this.organisationCrudService.getUserOrganisations(email, {
                embedded: 'none'
            }).subscribe((organisations) => {
                this.organisations = organisations;
                if (organisations.length > 0) {
                    this.onSelectOrganisation({ value: organisations[0] });
                }
                else {
                    this.onSelectOrganisation({ value: null });
                }
            }));
        }
    }
    setUserGroup(userData) {
        if (userData && userData._embedded && userData._embedded.group) {
            this.userSession.set(Group.LOCAL_STORAGE_NAME, {
                value: userData._embedded.group
            });
        }
    }
    /**
     * @description Set user interface design based on organisation given as parameter.
     * @private
     * @param {Organisation} organisation Organisation with design to load.
     * @memberof LoginComponent
     */
    setDesignBasedOnOrganisation(organisation) {
        if (this.organisations.length > 0 && !organisation) {
            organisation = this.organisations.shift();
        }
        if (organisation) {
            this.userSession.set(Organisation.LOCAL_STORAGE_NAME, {
                value: organisation
            });
            this.styleService.setDesignBasedOnOrganisation(organisation);
        }
        else {
            this.styleService.designChanged.next({
                design: null
            });
            this.userSession.remove(Organisation.LOCAL_STORAGE_NAME);
        }
    }
    /**
     * @description Get input css classes.
     * @param {string} inputValue Important to set ui-state-filled class
     * @returns {object} A javascript object with css classes.
     * @memberof LoginComponent
     */
    getInputClass(inputValue) {
        return {
            'ui-inputtext': true,
            'ui-corner-all': true,
            'ui-state-default': true,
            'ui-widget': true,
            'ui-state-filled': inputValue !== ''
        };
    }
    /**
     * @description On destroy unsubscribe saved subscriptions.
     * @memberof LoginComponent
     */
    ngOnDestroy() {
        this.subscriptions.forEach(s => s.unsubscribe());
        this.subscriptionsOrganisation.forEach(s => s.unsubscribe());
    }
}
tslib_1.__decorate([
    BlockUI('ui-login-block')
], LoginComponent.prototype, "blockUI", void 0);
