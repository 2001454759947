
    <div [formGroup]="formGroup" class="ui-g ui-g-12">

      <p-listbox
        appSeleniumDirective
        [element]="element"
        [options]="pulldownOptions"
        [formControlName]="formControlName"
        multiple="multiple"
        [checkbox]="true"
        filter="filter"
        (onChange)="onChange($event)"
        [tabIndex]="element.tabIndex"
      >
        <ng-template *ngIf="element.tooltipValue" let-option pTemplate="item">
          <span pTooltip="{{ option.entity[element.tooltipValue] }}" tooltipPosition="top">{{ option.label }}</span>
        </ng-template>
      </p-listbox>
    </div>
  