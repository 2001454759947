
import {forkJoin as observableForkJoin, Observable} from 'rxjs';
import {Injectable} from '@angular/core';
import {JobInterface} from './job/job.interface';
import {ConditionInterface} from './condition/condition.interface';
import {ExecutableJob} from './job/executable-job';
import {JobContext} from './context/job.context';
import {JobExecutionStatus} from './job/job-execution-status';

@Injectable()
export class JobContainerService {
  protected jobs: ExecutableJob[] = [];

  public registerJob(job: JobInterface, condition: ConditionInterface): JobContainerService {

    if (job.isImmutable()) {
      // First remove the old job:
      const existingJob = this.findJobByName(job.constructor.name);

      if (existingJob) {
        this.removeJob(existingJob.getJobId());
      }
    }

    this.jobs.push(
      new ExecutableJob(job, condition)
    );

    return this;
  }

  public runRelevantJobs(context: JobContext) {
    const jobs = [];
    for (const executableJob of this.jobs) {
      if (executableJob.condition.isApplicable(context)) {
        jobs.push(
          executableJob.job.run(context, executableJob.condition)
        );
      }
    }

    observableForkJoin(jobs).subscribe((res: Array<JobExecutionStatus>) => {
      for (const jobRes of res) {
        if (jobRes.status && jobRes.jobId) {
          const executedJob = this.findJob(jobRes.jobId);

          if (!executedJob.isImmutable()) {
            this.removeJob(jobRes.jobId);
          }
        }
      }
    });
  }

  public runRelevantJobsObservable(context: JobContext): Observable<boolean> {
    return Observable.create((observer) => {
      this.runRelevantJobs(context);

      observer.next(true);
      observer.complete();
    });
  }

  private findJob(jobId: string): JobInterface|null {
    for (const job of this.jobs) {
      if (job.job.getJobId() === jobId) {
        return job.job;
      }
    }

    return null;
  }

  private findJobByName(jobName: string): JobInterface|null {
    for (const job of this.jobs) {
      if (job.job.constructor.name === jobName) {
        return job.job;
      }
    }

    return null;
  }

  private removeJob(jobId: string) {
    let index = -1;
    for (const job of this.jobs) {
      if (job.job.getJobId() === jobId) {
        index = this.jobs.indexOf(job);
        break;
      }
    }

    if (index !== -1) {
      this.jobs.splice(index, 1);
    }
  }
}
