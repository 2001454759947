import {Observable, of} from 'rxjs';
import {AbstractExecutionStep} from '../../../core/executor/abstract-execution-step';
import {ExecutionStepStatus} from '../../../core/executor/execution-step-status';
import {SplitTemplateComponent} from '../../content-renderer/split-template/split-template.component';
import {AuthenticationService} from '../../../core/authentication/authentication.service';
import {map} from 'rxjs/operators';
import {Entity} from '../../helpers/entity';

export class BeforePhoenixActivePartsLoadExecutionStep extends AbstractExecutionStep {

  doExecute(): Observable<ExecutionStepStatus> {
    const payload = this.getPayload(),
      component = payload.getValue();

    if (!(component instanceof SplitTemplateComponent)) {
      return this.getFailObservable('You need to pass SplitTemplateComponent as Payload value!');
    }

    return this.doFilter(component);
  }

  protected doFilter(component: SplitTemplateComponent): Observable<ExecutionStepStatus> {
    const parts = component.moduleElementParts,
      userId = this.injector.get(AuthenticationService).currentUser.id;

    return this.genericCrudService.get(`phoenix/users/${userId}?embedded=ticketDepartments`).pipe(
      map((aUser: any) => {

        // todo

        return {status: true, content: null};
      }));
  }

  protected getCodesFromUser(aUser: any): string[] {
    return [];
  }

  protected handleVisibility(aUser: any, component: SplitTemplateComponent): void {
    const parts = component.moduleElementParts,
      availableCodes = this.getCodesFromUser(aUser);

    const availableParts = []
    for (const part of parts) {
      part.module = Entity.getValueInEmbedded(part, 'module')

      if (part.module.code && availableCodes.includes(part.module.code)) {
        availableParts.push(part)
      }

      if (!part.module.code) {
        availableParts.push(part);
      }
    }

    component.moduleElementParts = availableParts;
  }
}
