import {ChangeDetectionStrategy, Component} from '@angular/core';
import {ToolbarItemAbstract} from '../toolbar-item-abstract.component';
import {ComponentService} from '../../../../../services/component-highlight-stack.service';
import {MessageGrowlService} from '../../../../../../../core/message/message-growl.service';
import {MessageService} from '../../../../../../../core/message/message.service';
import {GenericCrudService} from '../../../../../../services/generic-crud.service';
import {ApiBuilderService} from '../../../../../../services/api.builder.service';
import {EntityDataStoreService} from '../../../../../services/entity-data-store.service';
import {LoggerService} from '../../../../../services/logger/logger.service';
import {AbstractGenericGridComponent} from '../../../../abstract-generic-grid.component';
import {FieldMetadataGrid} from '../../../../../../services/module/module-element-field-metadata-grid';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'toolbar-item-unlock-column',
  template: ''
})
export class ToolbarItemUnlockColumnComponent extends ToolbarItemAbstract {

  constructor() {
    super();
  }

  public click() {
    const grid = this.getComponent();
    if (grid instanceof AbstractGenericGridComponent) {
      const columns = this.getToolbarItem().parameter;
      if (columns && columns !== '') {
        const columnsCsv = columns.split(',');
        for (const column of grid.columns) {
          if (columnsCsv.indexOf(column.field.id) !== -1) {
            column.field.isForceUnlock = true;
          }
        }
      } else {
        for (const column of grid.columns) {
          column.field.isForceUnlock = true;
        }
      }

    }
  }

}
