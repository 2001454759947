import {Directive, HostListener} from '@angular/core';

@Directive({
  selector: '[stop-click-propagation]'
})
export class CalendarStopClickPropagationDirective {
  @HostListener('click', ['$event'])
  public onClick(event: any): void {
    if (!event.target.classList.contains('ui-monthpicker-month') && !event.target.classList.contains('ui-state-default')) {
      event.stopPropagation();
    }
  }

  @HostListener('mousedown', ['$event'])
  public onMousedown(event: any): void {
    if (!event.target.classList.contains('ui-monthpicker-month') && !event.target.classList.contains('ui-state-default')) {
      event.stopPropagation();
    }
  }
}
