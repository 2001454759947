import {ChangeDetectionStrategy, Component} from '@angular/core';
import {ToolbarItemAbstract} from '../toolbar-item-abstract.component';
import {GenericCrudBulkService} from '../../../../../../services/generic-crud-bulk.service';
import {MessageGrowlService} from '../../../../../../../core/message/message-growl.service';
import {ComponentService} from '../../../../../services/component-highlight-stack.service';
import {TranslateService} from '@ngx-translate/core';
import {MessageService} from '../../../../../../../core/message/message.service';
import {ToolbarItemSaveService} from '../../services/toolbar-item-save.service';
import {ExecutorService} from '../../../../../../../core/executor/executor.service';
import {ExecutionStepFactoryService} from '../../../../../../../core/executor/factory/execution-step-factory.service';
import {ModulesStateService} from 'app/shared/content-renderer/services/modules-state.service';
import {GenericDialogModuleService} from '../../../../generic-dialog/service/generic-dialog-module.service';
import {ToastService} from '../../../../../../../core/service/toast.service';
import {ToolbarItemSave} from '../save/toolbar-item-save.component';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'toolbar-item-master-save',
  template: '',
  providers: [
    ToolbarItemSaveService,
    ExecutorService
  ]
})
export class ToolbarItemMasterSaveComponent extends ToolbarItemSave {

  constructor(
    protected genericCrudBulkService: GenericCrudBulkService,
    protected messageGrowlService: MessageGrowlService,
    protected componentService: ComponentService,
    protected translateService: TranslateService,
    protected messageService: MessageService,
    protected toolbarItemSaveService: ToolbarItemSaveService,
    protected executorService: ExecutorService,
    protected executionStepFactoryService: ExecutionStepFactoryService,
    protected modulesStateService: ModulesStateService,
    protected genericDialogModuleService: GenericDialogModuleService,
    protected toastService: ToastService
  ) {
    super(
      genericCrudBulkService,
      messageGrowlService,
      componentService,
      translateService,
      messageService,
      toolbarItemSaveService,
      executorService,
      executionStepFactoryService,
      modulesStateService,
      genericDialogModuleService,
      toastService
    );
  }

  public click() {
    const mainModule = this.modulesStateService.getByComponent(this.getComponent());

    const components = mainModule.getComponents();

    let masterComponent = null;

    for (const component of components) {
      if (component.elementContext && component.elementContext.isMaster) {
        masterComponent = component;
        break;
      }
    }

    if (mainModule && mainModule.isAutocompleteView) {
      this.doSaveInAutocompleteModuleSate(this.getComponent());
    } else {
      if (this.isInCurrentContext(ToolbarItemAbstract.CONTEXT_FORM_VIEWER)) {
        this.doSaveFormViewerComponent(
          masterComponent
        );
      } else if (this.isInCurrentContext(ToolbarItemAbstract.CONTEXT_GRID) ||
        this.isInCurrentContext(ToolbarItemAbstract.CONTEXT_TREE)
      ) {
        this.doSaveChangedEntitiesInGridContext(
          masterComponent
        );
      } else if (this.isInCurrentContext(ToolbarItemAbstract.CONTEXT_MODULE)) {
        this.doSaveChangedEntitiesInModuleContext();
      } else {
        this.saveComponent(
          masterComponent
        );
      }
    }
  }
}
