import {JobInterface} from '../../../core/job-runner/job/job.interface';
import {JobContext} from '../../../core/job-runner/context/job.context';
import {Observable} from 'rxjs';
import {JobExecutionStatus} from '../../../core/job-runner/job/job-execution-status';
import {ConditionInterface} from '../../../core/job-runner/condition/condition.interface';
import {FormViewerComponent} from '../../form-viewer/form-viewer.component';
import {AbstractJob} from '../../../core/job-runner/job/abstract.job';
import {EntityManagerService} from '../../../core/service/entity-manager/entity-manager.service';
import {ChangeDetectorRefHelper} from '../../helpers/change-detector-ref.helper';

export class SetCustomerEntityForInquiryJob extends AbstractJob implements JobInterface {

  public constructor(
    private entityManager: EntityManagerService
  ) {
    super();
  }

  run(context: JobContext, condition: ConditionInterface): Observable<JobExecutionStatus> {
    return new Observable<JobExecutionStatus>((observer) => {
      const component = context.component;
      const customerEntity = condition.getPayload().entity;

      if (component instanceof FormViewerComponent && component.entity && customerEntity) {
        this.entityManager.persist(component.entity, {property: 'customer', newValue: customerEntity, force: true })
        if (!component.entity['_embedded']['customer']) {
          component.entity['_embedded']['customer'] = customerEntity;
        }
        this.entityManager.persist(component.entity, {property: 'isFromCustomer', newValue: true, force: true });
        this.entityManager.persist(component.entity, {property: 'customerAdvisor', newValue: customerEntity.supervisor, force: true});
        component.entity['_embedded']['customerAdvisor'] = customerEntity.supervisor;

        component.entity = {...component.entity};
        ChangeDetectorRefHelper.detectChanges(component);

        observer.next({
          status: true,
          content: null,
          jobId: this.jobId
        });

        observer.complete();
      } else {
        observer.next({
          status: false,
          content: 'wrong component or entity not set',
          jobId: this.jobId
        });

        observer.complete();
      }
    });
  }
}
