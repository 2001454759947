import {WorkHourCalculatorAbstract} from './work-hour-calculator-abstract';
import {DateHelper} from '../../../../../helpers/date.helper';

export class WorkHourCalculatorQuick extends WorkHourCalculatorAbstract {

  public calculateFields(): any {
    if (this.isIndustrialTime) {
      this.calculateFieldsIndustry(this.entity['workLengthIndustry']);
    } else {
      this.calculateFieldsTime(this.entity['workLength']);
    }

    return this.entity;
  }

  private calculateFieldsIndustry(workLengthIndustry) {
    const breakTime = 0.5,
      startTime = 8.0,
      preBreakTime = Math.ceil(workLengthIndustry/2);

    const endTime = startTime + preBreakTime,
      start2Time = endTime + breakTime,
      end2Time = startTime + workLengthIndustry + breakTime;

    this.entity['workEnd'] = DateHelper.convertFromDecimalToTime(endTime);
    this.entity['workEndIndustry'] = endTime;
    this.entity['workEnd2'] = DateHelper.convertFromDecimalToTime(end2Time);
    this.entity['workEnd2Industry'] = end2Time;

    this.entity['workStart'] = DateHelper.convertFromDecimalToTime(startTime);
    this.entity['workStartIndustry'] = startTime;
    this.entity['workStart2'] = DateHelper.convertFromDecimalToTime(start2Time);
    this.entity['workStart2Industry'] = start2Time;

    this.entity['workBreak'] = '00:30';
    this.entity['workBreakIndustry'] = 0.5;

    return this;
  }


  private calculateFieldsTime(workLength) {
    const workLengthTime = DateHelper.convertFromTimeToDecimal(workLength);

    return this.calculateFieldsIndustry(workLengthTime);
  }
}
