<p-dialog *ngIf="isDialogVisible"
          [(visible)]="isDialogVisible"
          [responsive]="true"
          [modal]="true"
          [minWidth]="200"
          width="1200"
          showEffect="fade"
          appendTo="body"
          header=""
>
  <app-ksv-company-search [gridComponent]="gridComponent"></app-ksv-company-search>
</p-dialog>
