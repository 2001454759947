declare var require: any;

const i18nDeJson = require('../../../assets/i18n/de.json');
const i18nDeJsonDate: DateTranslations = i18nDeJson.COMMON.DATE;

export const DEFAULT_DATE_LOCALES: DateLocales = {
  firstDayOfWeek: i18nDeJsonDate.FIRST_DAY_OF_WEEK,
  dayNames: i18nDeJsonDate.DAY_NAMES,
  dayNamesShort: i18nDeJsonDate.DAY_NAMES_SHORT,
  dayNamesMin: i18nDeJsonDate.DAY_NAMES_MIN,
  monthNames: i18nDeJsonDate.MONTH_NAMES,
  monthNamesShort: i18nDeJsonDate.MONTH_NAMES_SHORT
};

export class DateHelper {

  public static toDMY(date): string {
    const d = date.getDate();
    const m = date.getMonth() + 1;
    const y = date.getFullYear();

    return d + '.' + m + '.' + y;
  }

  // @todo 2008-09-25T00:00:00+0000 will not be parsed...
  public static parseDate(dateStr) {
    if (isNaN(dateStr)) { // Checked for numeric
      let dt = new Date(dateStr);
      if (!dt || isNaN(dt.getTime())) { // Checked for date
        return dateStr; // Return string if not date.
      } else {
        return dt; // Return date **Can do further operations here.
      }
    } else {
      return dateStr; // Return string as it is number
    }
  }

  private static isDateString(s) {
    // This does not match 1982-02-04
    // if (s.search(/^\d{1,2}[\/|\-|\.|_]\d{1,2}[\/|\-|\.|_]\d{4}/g) !== 0) {
    if (s.search(/^\d{1,4}[\/|\-|\.|_]\d{1,2}[\/|\-|\.|_]\d{1,4}/g) !== 0) {
      return false;
    }

    // @todo not sure why we need this, but maybe we should only return true for "isDateString"
    s = s.replace(/[\-|\.|_]/g, '/');
    const dt = new Date(Date.parse(s));
    const arrDateParts = s.split('/');
    return (
      dt.getMonth() === arrDateParts[0] - 1 &&
      dt.getDate() === arrDateParts[1] &&
      dt.getFullYear() === arrDateParts[2]
    );
  }

  // Ported from jquery-ui datepicker formatDate
  public static formatDate(date: Date, format: string, dateLocales?: DateLocales): string {
    if (!date) {
      return '';
    }

    // Set some default date locales if non given as param.
    if (!dateLocales) {
      dateLocales = DEFAULT_DATE_LOCALES;
    }

    let iFormat,
      output = '',
      literal = false;
    const lookAhead = (match) => {
      const matches = (iFormat + 1 < format.length && format.charAt(iFormat + 1) === match);
      if (matches) {
        iFormat++;
      }
      return matches;
    },
      formatNumber = (match, value, len) => {
        let num = '' + value;
        if (lookAhead(match)) {
          while (num.length < len) {
            num = '0' + num;
          }
        }
        return num;
      },
      formatName = (match, value, shortNames, longNames) => {
        return (lookAhead(match) ? longNames[value] : shortNames[value]);
      };

    if (date) {
      for (iFormat = 0; iFormat < format.length; iFormat++) {
        if (literal) {
          if (format.charAt(iFormat) === '\'' && !lookAhead('\'')) {
            literal = false;
          } else {
            output += format.charAt(iFormat);
          }
        } else {
          switch (format.charAt(iFormat)) {
            case 'd':
              output += formatNumber('d', date.getDate(), 2);
              break;
            case 'D':
              output += formatName('D', date.getDay(), dateLocales.dayNamesShort, dateLocales.dayNames);
              break;
            case 'o':
              output += formatNumber('o',
                Math.round((
                  new Date(
                    date.getFullYear(),
                    date.getMonth(),
                    date.getDate()).getTime() - new Date(date.getFullYear(), 0, 0).getTime()
                ) / 86400000
                ), 3);
              break;
            case 'm':
              output += formatNumber('m', date.getMonth() + 1, 2);
              break;
            case 'M':
              output += formatName('M', date.getMonth(), dateLocales.monthNamesShort, dateLocales.monthNames);
              break;
            case 'y':
              output += (lookAhead('y') ? date.getFullYear() :
                (date.getFullYear() % 100 < 10 ? '0' : '') + date.getFullYear() % 100);
              break;
            case '@':
              output += date.getTime();
              break;
            // case '!':
            //   output += date.getTime() * 10000 + this.ticksTo1970;
            //   break;
            case '\'':
              if (lookAhead('\'')) {
                output += '\'';
              } else {
                literal = true;
              }

              break;
            default:
              output += format.charAt(iFormat);
          }
        }
      }
    }
    return output;
  }

  public static formatTime(date: Date, hourFormatPmAm = false, showSeconds = false): string {
    if (!date) {
      return '';
    }

    let output = '';
    let hours = date.getHours();
    const minutes = date.getMinutes();
    const seconds = date.getSeconds();

    if (hourFormatPmAm === true && hours > 11 && hours !== 12) {
      hours -= 12;
    }

    output += (hours < 10) ? '0' + hours : hours;
    output += ':';
    output += (minutes < 10) ? '0' + minutes : minutes;

    if (showSeconds) {
      output += ':';
      output += (seconds < 10) ? '0' + seconds : seconds;
    }

    if (hourFormatPmAm === true) {
      output += date.getHours() > 11 ? ' PM' : ' AM';
    }

    return output;
  }

  public static isIso(value: string): boolean {
    let pattern = /(\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d:[0-5]\d\.\d+)|(\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d:[0-5]\d)|(\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d)/;

    return pattern.test(value);
  }

  public static getValueWithLeadingZeros(value: string, digitCount: number = 2): string {
    value = ("0" + value).slice(-digitCount);

    return value;
  }

  /**
   * Convert time "HH:mm" to date
   * @param time
   * @returns {Date}
   */
  public static getDateFromTimeString(time) {
    if (time === '') {
      return new Date(0, 0, 0, 0, 0, 0);
    }
    time = time.split(':');
    return new Date(0, 0, 0, time[0], time[1], 0);
  }

  public static toISOLocal(date: Date, withTime: boolean = true): string {
    const pad = (number) => {
      if (number < 10) {
        return '0' + number;
      }
      return number;
    };

    let dateString = date.getFullYear() +
      '-' + pad(date.getMonth() + 1) +
      '-' + pad(date.getDate());

    if (withTime) {
      dateString += 'T' + pad(date.getHours()) +
        ':' + pad(date.getMinutes()) +
        ':' + pad(date.getSeconds()) +
        '.' + (date.getMilliseconds() / 1000).toFixed(3).slice(2, 5) +
        'Z'
    }

    return dateString;
  }

  public static toISOLocalWithoutTimezone(date: Date): string {
    return DateHelper.toISOLocal(date).slice(0, -1);
  }

  public static removeTimezone(value): string {
    if (value.includes('+')) {
      value = value.substring(0, value.indexOf('+')); // remove after '+' (remove timezone)
    }

    return value;
  }

  public static convertFromDecimalToTime(decimalTimeValue: number): string {
    const hours = Math.floor(decimalTimeValue),
      tmp = Math.round((decimalTimeValue - hours) * 60.0 * 10000) / 10000,

      minutes = Math.ceil(tmp);

    if (hours < 0) {
      return '00:00';
    }

    const today = new Date();
    const dummyDate = new Date(today.getFullYear(), today.getMonth(), today.getDate(), hours, minutes);

    const hoursWithLeadingZero = dummyDate.getHours() < 10 ? `0${dummyDate.getHours()}` : dummyDate.getHours(),
      minutesWithLeadingZero = dummyDate.getMinutes() < 10 ? `0${dummyDate.getMinutes()}` : dummyDate.getMinutes();

    return `${hoursWithLeadingZero}:${minutesWithLeadingZero}`;
  }

  public static convertFromTimeToDecimal(timeValue): number {
    if (timeValue && timeValue.indexOf(':') !== -1) {
      const time = timeValue.split(':'),
        hours = time[0],
        minutes = time[1];
      return parseFloat(hours) + parseFloat(minutes) / 60.0;
    }

    return 0;
  }

  public static getCurrentWeek(date: Date): number {
    const oneJan = new Date(date.getFullYear(),0,1) as unknown as number;
    const today = new Date(date.getFullYear(),date.getMonth(),date.getDate()) as unknown as number;
    const dayOfYear = ((today - oneJan + 86400000)/86400000);
    return Math.ceil(dayOfYear/7)
  }
}
