import { AbstractValidator } from './abstract-validator';

export class PatternValidator extends AbstractValidator {

  public isValid(value: any): boolean {
    let isValid = true;

    if (typeof value === 'undefined' || value === null) {
      value = '';
    }

    let patternValue = this.getParamValue('pattern') || '';

    return new RegExp(patternValue).test(value.toString());
  }

  public getErrorTranslateKey(value: any): string {
    return 'VALUE_IS_NOT_VALID_FOR_ASSIGNED_PATTERN';
  }

  public getErrorTranslateParams(value: any): any {
    return null;
  }
}
