import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { GenericCrudService } from '../generic-crud.service';

@Injectable()
export class ElementFormEntityService {

  constructor(
    private genericCrudService: GenericCrudService
  ) {
  }

  saveEntity(apiRoute: string, entity: any, embedded: string = 'none') {
    let observable: Observable<any>;

    if (entity.id) {
      observable = this.genericCrudService.editEntity(apiRoute, entity, {embedded});
    } else {
      observable = this.genericCrudService.createEntity(apiRoute, entity, true, {embedded});
    }

    return observable;
  }

}
