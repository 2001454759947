<div style="width: 100%; height: 100%">
    <div style="width: 100%; height: 29px">
      <ul  class="tabrow" *ngIf="modulesStates.length > 0">
        <li *ngFor="let moduleState of modulesStates" [class]="isCurrentModule(moduleState) ? 'selected' : ''">
          <a appSeleniumDirective [moduleTab]="moduleState.module" (click)="onTabChange(moduleState)">{{moduleState.module.label || moduleState.module.name}} </a>
          <a (click)="onTabClose(moduleState)"><i class="fa fa-close"></i></a>
        </li>
      </ul>
    </div>
    <div id="content-renderer-container">
      <app-content-renderer *ngIf="module"
        appSeleniumDirective
        [entity]="entity" [module]="module" [isPart]="false"
      ></app-content-renderer>
    </div>
</div>
