import {Component} from '@angular/core';
import {
  SearchManagementAutocompleteEditExpertSearchFieldValueComponent
} from '../autocomplete/autocomplete-edit-expert-search-field-value.component';
import {FieldMetadataGrid} from '../../../../../../../../../services/module/module-element-field-metadata-grid';
import DisplayConfig = App.Form.Element.DisplayConfig;

const DISPLAY_CONFIG_PARAM_KEY = 'displayConfiguration';

@Component({
  selector: 'app-search-management-edit-autocomplete-multi-edit-expert-search-field-value',
  templateUrl: './autocomplete-multi-edit-expert-search-field-value.component.html',
  styles: [`
    p-autocomplete ::ng-deep .ui-autocomplete-multiple-container {
      width: calc(100% - 23px); // dropdown button
    }
  `]
})
export class SearchManagementAutocompleteMultiEditExpertSearchFieldValueComponent
  extends SearchManagementAutocompleteEditExpertSearchFieldValueComponent {

  public selectedFieldValue: any = [];

  public onAutoCompleteValueUnSelected(event): void {
    this.changeFieldValue(this.selectedFieldValue);
  }

  public onDisplayConfigSave(displayConfig: DisplayConfig[] = []): void {
    this.setParameter(DISPLAY_CONFIG_PARAM_KEY, displayConfig);

    // we have to restart value in order to keep labels correct
    this.selectedFieldValue = [];

    this.loadDropdownOptions()
      .subscribe((options: SelectItem[] = []) => {
        this.dropdownOptions = options;

        if (this.selectedFieldValue) {
          this.changeFieldValue(this.selectedFieldValue);
        }

        this.selectFieldValue();
      });
  }

  protected changeFieldValue(fieldValues): void {
    const values = [],
      labelValues = [];

    if (fieldValues instanceof Array) {
      for (const fieldValue of fieldValues) {
        const label = FieldMetadataGrid.getOptionLabel(
          fieldValue.value,
          { name: 'name', displayConfig: this.getParameterValue(DISPLAY_CONFIG_PARAM_KEY)
        });

        values.push({
          id: fieldValue.value.id,
          label: label
        });
        labelValues.push(label);
      }
    }

    this.onChange.emit({
      value: JSON.stringify(values),
      labelValue: labelValues.join(', ')
    });
  }

  protected selectFieldValue(): void {
    const values = this.field.fieldValue ? JSON.parse(this.field.fieldValue) : [];

    if (values && values instanceof Array) {
      const selectedFieldValue = [];

      for (const value of values) {
        this.addOption({
          value: {
            id: value.id
          },
          label: value.label
        });

        for (const option of this.dropdownOptions) {
          if (option.value.id === +value.id) {
            selectedFieldValue.push(option);
          }
        }
      }

      this.selectedFieldValue = selectedFieldValue;
    }
  }
}
