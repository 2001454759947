<p-dialog *ngIf="isDialogVisible" (onAfterHide)="dialogHide()" appendTo="body"
  [(visible)]="isDialogVisible" showEffect="fade" header="Project Creator" [resizable]="true"
  [modal]="true" [width]="450" [height]="380">
  <div class="ui-grid ui-grid-responsive ui-grid-pad ui-fluid" style="margin: 10px 0px">
    <div class="ui-grid-row">
      <div class="ui-grid-col-2"><label for="code">Code:</label></div>
      <div class="ui-grid-col-6">
        <input type="text" id="code" [(ngModel)]="phoneProjectGroup.code">
      </div>
    </div>

    <div class="ui-grid-row">
      <div class="ui-grid-col-2"><label for="name">{{ 'COMMON.NAME' | translate }}:</label></div>
      <div class="ui-grid-col-6">
        <input type="text" id="name" [(ngModel)]="phoneProjectGroup.name">
      </div>
    </div>

    <div class="ui-grid-row">
      <div class="ui-grid-col-2"><label for="isForCustomers">Kunden?:</label></div>
      <div class="ui-grid-col-6">
        <p-checkbox id="isForCustomers" [(ngModel)]="phoneProjectGroup.isForCustomers" binary="true"></p-checkbox>
      </div>
    </div>

    <div class="ui-grid-row">
      <div class="ui-grid-col-2"><label for="isForLeasedEmployees">Personen?:</label></div>
      <div class="ui-grid-col-6">
        <p-checkbox id="isForLeasedEmployees" [(ngModel)]="phoneProjectGroup.isForLeasedEmployees"
                    binary="true"></p-checkbox>
      </div>
    </div>

    <div class="ui-grid-row">
      <div class="ui-grid-col-2">
        <label for="description">{{ 'COMMON.DESCRIPTION' | translate }}:</label>
      </div>
      <div class="ui-grid-col-6">
        <textarea id="description" [(ngModel)]="phoneProjectGroup.description"></textarea>
      </div>
    </div>

    <div class="ui-grid-row">
      <div class="ui-grid-col-2">
        <label for="questionnaire">Fragebogen:</label>
      </div>
      <div class="ui-grid-col-6">
        <p-dropdown
          id="questionnaire" [options]="questionnaires" [(ngModel)]="phoneProjectGroup.questionnaireTemplate"></p-dropdown>
      </div>
    </div>
    <div class="ui-grid-row">
      <div class="ui-grid-col-2">
        <label for="description">CSV:</label>
      </div>
      <div class="ui-grid-col-6">
        <input type="file"
               id="customers"
          (change)="onChangeFile($event)"
        />
      </div>
    </div>
  </div>
  <p-footer>
    <button type="button" [disabled]="!customersFile"
            pButton (click)="onCreate($event)" label="{{ 'COMMON.CREATE' | translate }}"></button>
    <button type="button" pButton (click)="onCancel($event)" label="{{ 'COMMON.CANCEL' | translate }}"
            class="ui-button-secondary"></button>
  </p-footer>
</p-dialog>
