
import {of as observableOf, Observable} from 'rxjs';

import {map} from 'rxjs/operators';
import {Component, Input} from '@angular/core';
import {GenericTreeGridComponent} from '../generic-tree-grid/generic-tree-grid.component';
import {EntityValidatorStatus} from '../../../validators/services/entity-validator';
import {ExecutionStatus} from '../../../../core/executor/execution-status';
import {ExecutorActionEvent} from '../../../../core/executor/service/executor-actions/executor-action-event';
import {ElementSaveStatus} from '../generic-element-abstract.component';
import {EntityData} from '../../services/entity-data-store.service';
import {GenericElementFilterService} from '../../services/generic/filter/generic-element-filter.service';
import {GenericGridColumnBuilderService} from '../generic-grid/services/generic-grid-column-builder.service';
import {ExecutorService} from '../../../../core/executor/executor.service';
import {GenericElementInlineEditorService} from '../../services/generic/generic-element-inline-editor.service';
import {GenericGridBulkSaveService} from '../generic-grid/services/generic-grid-bulk-save.service';
import {GenericTreeLayoutService} from '../generic-tree-grid/service/generic-tree-layout-service';
import {GenericTreeEntityService} from '../../services/generic/entity/generic-tree-entity.service';
import {CancelComponentChangesService} from '../../services/cancel-components-changes.service';
import {GenericElementValidationExecutionStepsFactory} from '../../services/generic/generic-element-validation-execution-steps-factory';
import {GenericGridGlobalFilterService} from '../generic-grid/services/generic-grid-global-filter.service';
import {GenericGridRemoteFilterService} from '../generic-grid/services/generic-grid-remote-filter.service';
import {ElementType} from '../../services/ElementContext';
import {TreeNode} from 'primeng/api';

@Component({
  selector: 'app-generic-dynamic-tree-old',
  templateUrl: '../generic-tree-grid/generic-tree-grid.component.html',
  styleUrls: ['../generic-tree-grid/generic-tree-grid.component.scss'],
  providers: [
    CancelComponentChangesService,
    GenericGridColumnBuilderService,
    GenericGridGlobalFilterService,
    GenericGridRemoteFilterService,
    GenericTreeLayoutService,
    GenericElementInlineEditorService,
    GenericElementFilterService,
    GenericTreeEntityService,
    GenericGridBulkSaveService,
    GenericElementValidationExecutionStepsFactory,
    ExecutorService
  ]
})
export class GenericDynamicTreeOldComponent extends GenericTreeGridComponent {

  public elementType: ElementType = ElementType.DynamicTree;

  @Input() set nodes(nodes: TreeNode[]) {
    this.entities = nodes;
  }

  public onEntitiesChanged(): Observable<any> {
    return this.executeAction(ExecutorActionEvent.DynamicTreeEntitiesChanged, this);
  }

  public onValidate(): Observable<EntityValidatorStatus> {
    return observableOf({
      entity: null,
      isValid: true,
      error: '',
      errorFields: []
    });
  }

  public onEditCell(event, entity: any, column: any) {
    this.executorService
      .fire(this.moduleElement.id, ExecutorActionEvent.DynamicTreeCellEdit, {
        component: this,
        column: column,
        entity: entity,
        event: event
      })
      .subscribe((status: ExecutionStatus) => {
        this.recheckToolbarItems();
      });
  }

  public onRefresh(): Observable<any> {
    return this.executorService
      .fire(this.moduleElement.id, ExecutorActionEvent.EntitiesRefresh, this).pipe(
      map((status: ExecutionStatus) => {
        this.recheckToolbarItems();

        return {status: status.getStepStatus(), content: status.getStepContent(), message: null};
      }));
  }

  public onSave(): Observable<ElementSaveStatus> {
    return this.executorService
      .fire(this.moduleElement.id, ExecutorActionEvent.DynamicTreeSave, this).pipe(
      map((status: ExecutionStatus) => {
        this.recheckToolbarItems();

        return {status: status.getStepStatus(), content: status.getStepContent(), message: null};
      }));
  }

  public loadEntities(): Observable<EntityData> {
    return observableOf({data: [], count: 0});
  }
}
