import {ChangeDetectionStrategy, Component} from '@angular/core';
import {ToolbarItemAbstract} from '../toolbar-item-abstract.component';
import {FormViewerComponent} from '../../../../../../form-viewer/form-viewer.component';
import {GenericElementAbstract} from '../../../../generic-element-abstract.component';
import {TranslateService} from '@ngx-translate/core';
import {ModulesStateService} from '../../../../../services/modules-state.service';
import {MessageService} from 'app/core/message/message.service';
import { ExecutionStatusSuccess } from '../../../../../../../core/executor/execution-status-success';
import { ComponentValidationExecutionStep } from 'app/shared/services/execution-step/validation/component-validation-execution-step';
import { ExecutionStatusError } from 'app/core/executor/execution-status-error';
import { EntityValidationExecutionStep } from 'app/shared/services/execution-step/validation/entity-validation-execution-step';
import { ExecutionStatus } from 'app/core/executor/execution-status';
import {ModuleState} from '../../../../../services/module-state';
import {DraftSaveBuilderService} from '../../../../../../../core/executor/builder/draft-save-builder.service';
import {ExecutorService} from '../../../../../../../core/executor/executor.service';
import { GenericDialogModuleService } from '../../../../generic-dialog/service/generic-dialog-module.service';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'toolbar-item-draft-save',
  template: '',
  providers: [
    DraftSaveBuilderService,
    ExecutorService
  ]
})
export class ToolbarItemDraftSave extends ToolbarItemAbstract {

  constructor(private modulesStateService: ModulesStateService,
              private messageService: MessageService,
              private translateService: TranslateService,
              private draftSaveBuilderService: DraftSaveBuilderService,
              private executorService: ExecutorService,
              private genericDialogModuleService: GenericDialogModuleService
            ) {
    super();
  }

  public click() {
    const mainModule = this.modulesStateService.getByComponent(this.getComponent());

    if (mainModule && mainModule.isAutocompleteView) {
      this.genericDialogModuleService.hideDialogWithSave(this.getComponent());
    } else if (this.isInCurrentContext(ToolbarItemAbstract.CONTEXT_FORM_VIEWER)
      || this.isInCurrentContext(ToolbarItemDraftSave.CONTEXT_MODULE)) {
      this.doDraftSaveInModuleContext(mainModule);
      // this.doDraftSaveInFormContext(this.getParam('formViewerComponent'));
    }
  }

  private doDraftSaveInModuleContext(moduleState: ModuleState) {
    for (const executionStep of this.draftSaveBuilderService.createExecutionSteps(moduleState)) {
      this.executorService.addStep(executionStep);
    }

    this.executorService.execute().subscribe((executionStatus) => {
      const mainForm = executionStatus.getStepContent();

      if (mainForm instanceof FormViewerComponent) {
        this.saveDraftAndRefresh(mainForm);
      }
    });
  }

  private doDraftSaveInFormContext(formComponent: FormViewerComponent): void {
    this.saveDraftAndRefresh(formComponent);
  }

  private saveDraftAndRefresh(component: FormViewerComponent): void {
    component.onValidate().subscribe((status: ExecutionStatus) => {
      if (status.isSuccess()) {
        this.onExecutorSuccess(status, component);
      } else {
        this.onExecutorFailure(status);
      }
    });
  }

  public onExecutorFailure(status: ExecutionStatusError) {
    if (status.getStep() instanceof ComponentValidationExecutionStep ||
      status.getStep() instanceof EntityValidationExecutionStep
    ) {
      this.messageService.confirm({
        acceptVisible: true,
        rejectVisible: false,
        header: this.translateService.instant('COMMON.ERROR'),
        message: status.getStepContent(),
        accept: () => { }
      });
    }
  }

  public onExecutorSuccess(status: ExecutionStatusSuccess, component: FormViewerComponent) {
    component.onAddDraft().subscribe(() => {
      // this.refreshComponent(component);
    });
  }

  private refreshComponent(component: GenericElementAbstract): void {
    component.onRefresh().subscribe(() => {

    });
  }

}
