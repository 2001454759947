import {Injectable} from '@angular/core';
import {HandleCountryPhoneActionHandler} from '../address/handle-country-phone-action-handler';

@Injectable()
export class HandleGenericPhoneActionHandler extends HandleCountryPhoneActionHandler {

  protected getPhoneCountryCodeDatamodelField(): string {
    return this.getParamValue('phoneCountryCode');
  }

  protected getPhoneAreaCodeDatamodelField(): string {
    return this.getParamValue('phoneAreaCode');
  }

  protected getPhoneNumberDatamodelField(): string {
    return this.getParamValue('phoneNumber');
  }

  protected getCountryDatamodelField(): string {
    return this.getParamValue('country');
  }

  protected getPhonePrefixFromCountryComponent(): any {
    let value = null,
      countryEntity = this.formService.getEntityHydrator().getEntityPropertyValue(this.getEntity(),this.getCountryDatamodelField());

    if(null === countryEntity){
      countryEntity = this.formService.getEntityHydrator().getEntityPropertyValue(this.getEntity(),this.getCountryDatamodelField(),true,true);
    }
    if (null !== countryEntity &&
      typeof countryEntity === 'object' &&
      countryEntity['phonePrefix']
    ) {
      value = countryEntity['phonePrefix'];
    }

    return value;
  }

  protected isActionValid(): boolean {
    return this.getCountryPhoneElement() !== null && this.getCountryPhoneFormControl() !== null
      && this.getCountryDatamodelField() !== null;
  }
}
