
    <ng-container *ngIf="element.children.length > 0">
      <ng-container *ngFor="let elementChild of element.children">
        <app-form-element
          [(selectedElement)]="selectedElement"
          [(showDialogAddAddress)]="showDialogAddAddress"
          [(actionParams)]="actionParams"
          [form]="form"
          [formGroup]="formGroup"
          [element]="elementChild"
          [moduleElement]="moduleElement"
          [entity]="entity"
          [editMode]="editMode"
          #elementItem
        ></app-form-element>
      </ng-container>
  </ng-container>
  