import {ChangeDetectionStrategy, Component} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {ToolbarItemAbstract} from '../toolbar-item-abstract.component';
import {GenericCrudService} from '../../../../../../services/generic-crud.service';
import {MessageGrowlService} from '../../../../../../../core/message/message-growl.service';
import {FormViewerComponent} from '../../../../../../form-viewer/form-viewer.component';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-toolbar-item-export-report',
  template: ''
})
export class ToolbarItemExportReportComponent extends ToolbarItemAbstract {

  constructor(
    protected genericCrudService: GenericCrudService,
    protected messageGrowlService: MessageGrowlService,
    protected translate: TranslateService,
  ) {
    super();
  }

  public click() {
    const component = this.getComponent();

    if (component instanceof FormViewerComponent) {
      const entity = component.getSelectedEntity();

      const period = entity['_embedded']['period']['id'];
      const exportRecordType = entity['_embedded']['exportRecordType']['code'];
      const employee = entity['_embedded']['leasedEmployee'] ? entity['_embedded']['leasedEmployee']['id'] : null;
      const customer = entity['_embedded']['customer'] ? entity['_embedded']['customer']['id'] : null;
      const branchOffice = entity['_embedded']['branchOffice'] ? entity['_embedded']['branchOffice']['id'] : null;
      const isTest = entity['isTest'];
      const isAll = entity['isAll'];

      if (period && exportRecordType) {
        let url = 'phoenix/export/lga?period=' + period + '&exportType=' + exportRecordType + '&isTest=' + isTest + '&isAll=' + isAll;

        if (customer) {
          url += '&customer=' + customer;
        }

        if (employee) {
          url += '&employee=' + employee;
        }

        if (branchOffice) {
          url += '&branchOffice=' + branchOffice;
        }

        this.genericCrudService.get(url).subscribe(result => {
          this.messageGrowlService.success(
            this.translate.instant('COMMON.DATA_EXPORTED_LGA'),
            this.translate.instant('COMMON.SUCCESS')
          );
        });
      }
    }
  }
}
