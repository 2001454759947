import {Component, OnInit} from '@angular/core';
import {DomSanitizer} from '@angular/platform-browser';
import {EntityHydrator} from '../../../../../services/entity-hydrator.service';
import {Subscription} from 'rxjs';
import {PermissionService} from '../../../../../services/permission/permission.service';
import {AbstractGridGeneralColumnComponent} from '../abstract-grid-general-column.component';
import {environment} from '../../../../../../../environments';
import {Entity} from '../../../../../helpers/entity';


@Component({
  selector: 'app-image-column',
  templateUrl: './image-column.component.html',
  styleUrls: ['./image-column.component.scss']
})
export class ImageColumnComponent extends AbstractGridGeneralColumnComponent implements OnInit {

  public hashIdForUpload: string;

  private environment;

  public imageSrc = '';

  protected subscriptions: Subscription[] = [];

  constructor(
    private sanitizer: DomSanitizer,
    protected permissionService: PermissionService,
    private entityHydrator: EntityHydrator
  ) {
    super();
    this.environment = environment;
  }

  ngOnInit() {
    this.hashIdForUpload = `${new Date().getTime().toString(10)}bgUpload`;

    this.imageSrc = this.getImageUrl();
  }

  isReadOnly() {
    let isReadOnly = this.entity.deletedAt || this.entity.tmpIsDeleted || this.column['field']['isReadOnly'] || (this.column['field']['isReadOnlyByEntity'] && this.column['field']['isReadOnlyByEntity'][this.entity.id]);
    return !this.editMode || isReadOnly || !this.permissionService.isGranted(this.column, 'edit');
  }

  onChangeImage(event) {
    this.subscriptions.push(event.srcElement.files[0].toDataURL().subscribe((dataURL) => {
      this.imageSrc = dataURL;

      const oldValue = Entity.getValue(this.entity, this.column['property']);

      this.changeEntityColumnProperty(dataURL);
      this.component.flagEntityForBulkSave(this.entity, Entity.getValue(this.entity, this.column['property']), oldValue)
        .subscribe((canBeChanged: boolean) => {
          if (canBeChanged) {
            this.component.changeEntityValue(this.entity, this.column, Entity.getValue(this.entity, this.column['property']), oldValue);
          }
        });
    }));
  }

  getImageUrl() {
    let imageUrl = null;

    if (this.getImage()) {
      if (!this.hasUploadedImage()) {
        imageUrl = `${this.environment.uploadUrl}/${this.getImage()}`;
      } else {
        imageUrl = this.sanitizer.bypassSecurityTrustUrl(this.getImage());
      }
    }

    return imageUrl;
  }

  onRemoveImage(event) {
    event.preventDefault();
    if (this.getImage()) {
      this.imageSrc = null;

      this.entity[this.column['property']] = '';
    }
    this.component.flagEntityForBulkSave(this.entity).subscribe((status: boolean) => {
      if (status) {

      }
    });
  }

  private hasUploadedImage(): boolean {
    return this.getImage().indexOf('data:') !== -1;
  }

  private getImage(): any {
    return this.entityHydrator.getEntityPropertyValue(this.entity,this.column['property']);
  }

  protected changeEntityColumnProperty(value) {
    this.entity[this.column['property']] = value;

    if (this.entity._embedded && this.entity._embedded[this.column['property']]) {
      this.entity._embedded[this.column['property']] = value;
    }
  }
}
