import { Component } from '@angular/core';
import { FieldActionAbstractComponent } from '../field-action-abstract.component';
import { GenericCrudService } from '../../../../../services/generic-crud.service';
import { DoubleClickService } from '../../../../services/double-click.service';
import {FieldMetadataGrid} from '../../../../../services/module/module-element-field-metadata-grid';

@Component({
  selector: 'field-action-disable-self-base-on-another-field',
  template: ''
})
export class FieldActionDisableSelfBaseOnAnotherFieldComponent extends FieldActionAbstractComponent {

  public constructor(
    protected genericCrudService: GenericCrudService,
    protected doubleClickService: DoubleClickService,
  ) {
    super();
  }

  public fire() {
    const gridComponent = this.getGrid(),
      entity = gridComponent.getSelectedEntity();

    let columnToCheck = this.getActionParamValue('column'),
      onlyDisable = this.getActionParamValue('onlyDisable'),
      condition = this.getActionParamValue('condition'),
      notEqual = this.getActionParamValue('notEqual'),
      field = this.getField(),
      columnToCheckValue = 'null';

    if(entity[columnToCheck]){
      columnToCheckValue = entity[columnToCheck].toString();
    }
    
    if(((columnToCheckValue == condition && !notEqual) || (columnToCheckValue != condition && notEqual))
    ){
      if(typeof field.isReadOnlyByEntity === 'undefined'){
        field.isReadOnlyByEntity = {};
      }
      field.isReadOnlyByEntity[entity.id] = true;

    }else if(!onlyDisable){
      if(typeof field.isReadOnlyByEntity === 'undefined'){
        field.isReadOnlyByEntity = {};
      }
      field.isReadOnlyByEntity[entity.id] = false;
    }
  }
}
