
import {catchError, publishReplay, map, refCount} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';


import { AppHttpService } from '../../../app-http.service';
import { ToolbarSet } from './toolbar.set';
import { GenericCrudService } from '../generic-crud.service';

@Injectable()
export class ToolbarSetCrudService extends AppHttpService {

  private toolbarSetsUrl: string = 'superadmin/toolbarsets';

  constructor(
    private genericCrudService: GenericCrudService
  ) {
    super();
  }

  getToolbarSets(): Observable<ToolbarSet[]> {
    return this.genericCrudService.get(this.toolbarSetsUrl).pipe(
      publishReplay(1),
      refCount(),
      catchError(this.handleError));
  }

  getToolbarSet(toolbarSetId: number): Observable<ToolbarSet> {
    if (toolbarSetId < 1) {
      throw new Error('Invalid toolbarset id given.');
    }
    return this.genericCrudService.get(`${this.toolbarSetsUrl}/${toolbarSetId}`).pipe(
      map(this.extractEmbeddedEntities, this),
      publishReplay(1),
      refCount(),
      catchError(this.handleError));
  }

  deleteToolbarSet(toolbarSetId: number): Observable<Object> {
    if (toolbarSetId < 1) {
      throw new Error('Invalid toolbarSet id given.');
    }
    return this.genericCrudService.deleteEntity(`${this.toolbarSetsUrl}/${toolbarSetId}`);
  }

  createToolbarSet(toolbarSet: ToolbarSet): Observable<ToolbarSet> {
    if (toolbarSet) {
      return this.genericCrudService.createEntity(this.toolbarSetsUrl, toolbarSet).pipe(
        map((json) => { return <ToolbarSet>json; }));
    } else {
      throw new Error('No toolbarSet given.');
    }
  }

  editToolbarSet(toolbarSet: ToolbarSet): Observable<ToolbarSet> {
    if (toolbarSet) {
      return this.genericCrudService.editEntity(`${this.toolbarSetsUrl}/${toolbarSet.id}`, toolbarSet).pipe(
        map((json) => { return <ToolbarSet>json; }));
    } else {
      throw new Error('No toolbarSet given.');
    }
  }
}
