/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./generic-toggle-button.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "primeng/components/dom/domhandler";
import * as i3 from "primeng/components/button/button";
import * as i4 from "primeng/components/tooltip/tooltip";
import * as i5 from "../../../p-button/p-button.directive";
import * as i6 from "@angular/common";
import * as i7 from "./generic-toggle-button.component";
var styles_GenericToggleButtonComponent = [i0.styles];
var RenderType_GenericToggleButtonComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_GenericToggleButtonComponent, data: {} });
export { RenderType_GenericToggleButtonComponent as RenderType_GenericToggleButtonComponent };
function View_GenericToggleButtonComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "button", [["class", "image flex-item"], ["pButton", ""], ["tooltipPosition", "top"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onToggle($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵprd(4608, null, i2.DomHandler, i2.DomHandler, []), i1.ɵdid(2, 4341760, null, 0, i3.ButtonDirective, [i1.ElementRef], { label: [0, "label"] }, null), i1.ɵdid(3, 4341760, null, 0, i4.Tooltip, [i1.ElementRef, i1.NgZone], { tooltipPosition: [0, "tooltipPosition"], text: [1, "text"] }, null), i1.ɵdid(4, 4341760, null, 0, i5.PButtonDirective, [i1.ElementRef], { label: [0, "label"], iconUrl: [1, "iconUrl"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.title; _ck(_v, 2, 0, currVal_0); var currVal_1 = "top"; var currVal_2 = _co.tooltipText; _ck(_v, 3, 0, currVal_1, currVal_2); var currVal_3 = _co.title; var currVal_4 = _co.imageSource; _ck(_v, 4, 0, currVal_3, currVal_4); }, null); }
export function View_GenericToggleButtonComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [], [[8, "className", 0]], null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_GenericToggleButtonComponent_1)), i1.ɵdid(2, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.imageSource; _ck(_v, 2, 0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵinlineInterpolate(1, "", (!_co.isChecked ? "greyed-out" : ""), " image flex-item"); _ck(_v, 0, 0, currVal_0); }); }
export function View_GenericToggleButtonComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-generic-toggle-button", [], null, null, null, View_GenericToggleButtonComponent_0, RenderType_GenericToggleButtonComponent)), i1.ɵdid(1, 114688, null, 0, i7.GenericToggleButtonComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var GenericToggleButtonComponentNgFactory = i1.ɵccf("app-generic-toggle-button", i7.GenericToggleButtonComponent, View_GenericToggleButtonComponent_Host_0, { title: "title", imageSource: "imageSource", tooltipText: "tooltipText", isChecked: "isChecked" }, {}, []);
export { GenericToggleButtonComponentNgFactory as GenericToggleButtonComponentNgFactory };
