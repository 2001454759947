import {ChangeDetectionStrategy, Component} from '@angular/core';
import {environment} from '../../../../../../../../environments';
import {ToolbarItemAbstract} from '../toolbar-item-abstract.component';
import {AbstractGenericGridComponent} from 'app/shared/content-renderer/elements/abstract-generic-grid.component';
import {RequestCachingService} from '../../../../../../services/request-caching.service';
import {Organisation} from '../../../../../../services/organisation/organisation';
import {LocalStorageDataService} from '../../../../../../services/local-storage-data.service';
import {UserSessionService} from '../../../../../../../core/service/user-session.service';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-toolbar-item-upload-leased-employee-document',
  templateUrl: './toolbar-item-upload-leased-employee-document.component.html',
  styles: [`
    ::ng-deep .ui-button-text {
      padding-left: 20px !important;
    }
  `]
})
export class ToolbarItemUploadLeasedEmployeeDocumentComponent extends ToolbarItemAbstract {

  public isDialogVisible = false;
  public leasedEmployee: any = null;

  public organisationId = '';
  public baseUrl = '';

  constructor(
    protected requestCachingService: RequestCachingService,
    protected localStorage: LocalStorageDataService,
    protected userSession: UserSessionService
  ) {
    super();
    this.baseUrl = environment.baseUrl;
  }

  public click() {
    const component = this.getComponent(),
      organisation = this.userSession.get(Organisation.LOCAL_STORAGE_NAME);

    this.organisationId = organisation.id;

    if (component instanceof AbstractGenericGridComponent && component.selectedMasterEntity && component.selectedMasterEntity.fqn === 'PhoenixBundle\\Entity\\LeasedEmployee') {
      this.leasedEmployee = component.selectedMasterEntity;

      this.isDialogVisible = true;
    }

  }

  public onUpload(event): void {
    const component = this.getComponent();

    if (component instanceof AbstractGenericGridComponent) {
      const entity = event.originalEvent.body;
      component.addEntity(entity);
    }

    this.dialogHide();
  }

  dialogHide() {
    this.isDialogVisible = false;
  }
}
