import {AbstractExecutionStep} from '../../../../../core/executor/abstract-execution-step';
import {of as observableOf,  Observable } from 'rxjs';
import {ExecutionStepStatus} from '../../../../../core/executor/execution-step-status';
import {AbstractGenericGridComponent} from '../../../../content-renderer/elements/abstract-generic-grid.component';
import {TimeDecimalColumnSwitchService} from '../../../../content-renderer/services/time-decimal-column-switch-service';
import {WorkTimeModelColumnManipulatorData} from '../../../../web-worker/work-time-model-column.worker';

export class SelectedWorkingModelDayColumnSwitchStep extends AbstractExecutionStep {

  protected component: AbstractGenericGridComponent;
  protected timeDecimalColumnSwitchService: TimeDecimalColumnSwitchService;
  protected isDecimalView = false;

  public managingColumns: any = {
    start:                      {index: 1, industrialTime: false},
    startDecimal:               {index: 2, industrialTime: true},
    end:                        {index: 3, industrialTime: false},
    endDecimal:                 {index: 4, industrialTime: true},
    break:                      {index: 5, industrialTime: false},
    breakDecimal:               {index: 6, industrialTime: true},
    plannedWorkingTime:         {index: 7, industrialTime: false},
    plannedWorkingTimeDecimal:  {index: 8, industrialTime: true},
    overallWorkingTime:         {index: 8, industrialTime: false},
    overallWorkingTimeDecimal:  {index: 8, industrialTime: true}
  };

  public doExecute(): Observable<ExecutionStepStatus> {
    const payload = this.getPayload();

    const component = payload.getValue().component || payload.getValue();
    if (!(component instanceof AbstractGenericGridComponent)) {
        return this.getFailObservable('You need to pass AbstractGenericGridComponent as Payload value!');
    }
    this.component = component;
    this.timeDecimalColumnSwitchService = this.injector.get(TimeDecimalColumnSwitchService, null);

    const customer = this.findMasterEntity();

    if (customer && customer.fqn === 'PhoenixBundle\\Entity\\Customer') {
      this.isDecimalView = this.isDecimalViewStored(customer);

      this.runColumnManipulator();
    }

    return observableOf({status: true, content: []});
  }

  public runColumnManipulator(): void {
    this.component.isDataLoading = true;
    const worker = new Worker('../../../../web-worker/work-time-model-column.worker', { type: 'module' });

    worker.onmessage = ({ data }) => {
      this.onRunnerResolved(data);
      this.component.isDataLoading = false;
      worker.terminate();
    };

    worker.onerror = (error) => {
      console.error('Worker error:', error);
      this.component.isDataLoading = false;
      worker.terminate();
    };

    worker.postMessage({
      columns: this.component.columns,
      newColumns: [],
      fields: this.component.fields,
      managingColumns: this.managingColumns,
      visibleColumns: [],
      industrialTime: this.isDecimalView
    });
  }


  protected isDecimalViewStored(masterEntity = null): boolean {
    let isDecimalView = this.timeDecimalColumnSwitchService.isIndustrialTime(this.component.moduleElement.id);

    if ( masterEntity
      && masterEntity.fqn === 'PhoenixBundle\\Entity\\Customer'
      && isDecimalView === null) {
      isDecimalView = masterEntity['isIndustrialTime'] || false;
    }

    this.timeDecimalColumnSwitchService.setIndustrialTime(this.component.moduleElement.id, !!isDecimalView);
    return isDecimalView;
  }

  private onRunnerResolved(data: WorkTimeModelColumnManipulatorData): void {

    setTimeout(() => {
      this.component.columns = data.newColumns;
      this.managingColumns = data.managingColumns;

      this.component.isDataLoading = false;
      this.component.getChangeDetectorRef().detectChanges();
    }, 1);
  }

  private findMasterEntity(): any {
    return this.component.getModuleState().getCurrent().entity;
  }
}
