/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./toolbar-item-display-dms-notes.component";
var styles_ToolbarItemDisplayDmsNotesComponent = [];
var RenderType_ToolbarItemDisplayDmsNotesComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_ToolbarItemDisplayDmsNotesComponent, data: {} });
export { RenderType_ToolbarItemDisplayDmsNotesComponent as RenderType_ToolbarItemDisplayDmsNotesComponent };
export function View_ToolbarItemDisplayDmsNotesComponent_0(_l) { return i0.ɵvid(2, [], null, null); }
export function View_ToolbarItemDisplayDmsNotesComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-toolbar-item-display-dms-notes", [], null, null, null, View_ToolbarItemDisplayDmsNotesComponent_0, RenderType_ToolbarItemDisplayDmsNotesComponent)), i0.ɵdid(1, 245760, null, 0, i1.ToolbarItemDisplayDmsNotesComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ToolbarItemDisplayDmsNotesComponentNgFactory = i0.ɵccf("app-toolbar-item-display-dms-notes", i1.ToolbarItemDisplayDmsNotesComponent, View_ToolbarItemDisplayDmsNotesComponent_Host_0, { params: "params" }, {}, []);
export { ToolbarItemDisplayDmsNotesComponentNgFactory as ToolbarItemDisplayDmsNotesComponentNgFactory };
