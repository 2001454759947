import {EventEmitter, Input, Output} from '@angular/core';
import {AbstractGenericGridComponent} from '../../abstract-generic-grid.component';
import {ModuleElementColumn} from '../../../../services/module/module-element-column';

export abstract class AbstractGridGeneralColumnComponent  {

  @Input() entity: any = null;

  @Input() component: AbstractGenericGridComponent = null;

  @Input() column: ModuleElementColumn = null;

  @Input() editMode: boolean = false;
  @Input() disabled: boolean = false;

  @Output() onKeyUp: EventEmitter<any> = new EventEmitter();
  @Output() onBlur: EventEmitter<any> = new EventEmitter();
  @Output() onFocus: EventEmitter<any> = new EventEmitter();
  @Output() onBeforeFocus: EventEmitter<any> = new EventEmitter();
  @Output() valueChange: EventEmitter<any> = new EventEmitter();
  @Output() tabOrEnter: EventEmitter<any> = new EventEmitter();
}
