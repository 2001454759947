import {ChangeDetectionStrategy, Component} from '@angular/core';
import {ToolbarItemAbstract} from '../toolbar-item-abstract.component';
import {ComponentService} from '../../../../../services/component-highlight-stack.service';
import {MessageGrowlService} from '../../../../../../../core/message/message-growl.service';
import {MessageService} from '../../../../../../../core/message/message.service';
import {GenericCrudService} from '../../../../../../services/generic-crud.service';
import {map} from 'rxjs/operators';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'toolbar-item-unlock',
  templateUrl: './toolbar-item-unlock.component.html'
})
export class ToolbarItemUnlockComponent extends ToolbarItemAbstract {

  constructor(
      private componentService: ComponentService,
      private messageGrowlService: MessageGrowlService,
      private messageService: MessageService,
      private genericCrudService: GenericCrudService,
  ) {
    super();
  }

    public click() {
      const entity = this.getComponent().getSelectedEntity();

      if (!entity) {
        return this.messageGrowlService.error('Please select entity you want to unlock.');
      }

      if (entity.id) {
        this.doUnlockEntity(entity);
      }

    }
    private doUnlockEntity(entity) {
      const data = {
        'fqn': entity.fqn
      };

      this.genericCrudService.customPut(`app/unlock/${entity.id}`, data, {embedded: 'none'}).pipe(
        map(() => {
          this.messageGrowlService.success('Unlocked');
          this.getComponent().onRefresh().subscribe();
        })
      ).subscribe();
    }

}
