<a (click)="onItemClick(item)" *ngIf="!item.isSeparator && item.isCustomMenuItem" [pTooltip]="item.tooltip" [tooltipPosition]="menuPosition == 'right' ? 'left' : 'right'">
  <div class="item-container item-text-container">
    {{item.label}}
  </div>
</a>
<p-dialog *ngIf="isDialogVisible" (onAfterHide)="dialogHide()" appendTo="body" [responsive]="true"
          [(visible)]="isDialogVisible" showEffect="fade" header="Stundenimport"
          [modal]="true" [width]="600" [height]="350">
  <p-fieldset legend="Stundenimport">
    <div class="ui-grid ui-grid-responsive ui-fluid">
      <div class="ui-grid-row">
        <div class="ui-grid-col-4">
          <label for="company">
            Firma:
          </label>
        </div>
        <div class="ui-grid-col-8">
          <p-dropdown placeholder="{{ 'COMMON.PLEASE_SELECT' | translate }}" [options]="customersDropdown" [(ngModel)]="selectedCustomer" id="company" filter="filter" [style]="{'width':'100%'}"></p-dropdown>
        </div>
      </div>
      <div class="ui-grid-row">
        <div class="ui-grid-col-12">
          <p-fileUpload
            name="file[]"
            [style]="{'height':'200px'}"
            multiple="multiple"
            withCredentials="true"
            customUpload="true"
            (uploadHandler)="onUpload($event)"
            maxFileSize="100000000"
            chooseLabel="{{ 'COMMON.CHOOSE' | translate }}"
            cancelLabel="{{ 'COMMON.CANCEL' | translate }}"
            uploadLabel="{{ 'COMMON.UPLOAD' | translate }}"
            [disabled]="!selectedCustomer"
          >
          </p-fileUpload></div>
      </div>
    </div>
    <div class="ui-grid-row">
      <p-progressBar *ngIf="isDataLoading" mode="indeterminate" [style]="{'height': '6px', 'margin-top': '5px'}"></p-progressBar>
    </div>
  </p-fieldset>
</p-dialog>
