<app-workhour-dropdown-column-manipulator
  *ngIf="filterItem.customFilterClassName === 'workhourDropdownColumnManipulator'"
  #filterComponent
  [filterItem]="filterItem"
  [component]="component"
></app-workhour-dropdown-column-manipulator>

<app-workhour-leased-employee-autocomplete
  *ngIf="filterItem.customFilterClassName === 'workhourLeasedEmployeeAutocomplete'"
  #filterComponent
  [filterItem]="filterItem"
  [component]="component"
></app-workhour-leased-employee-autocomplete>

<app-workhour-assignment-autocomplete
  *ngIf="filterItem.customFilterClassName === 'workhourAssignmentAutocomplete'"
  #filterComponent
  [filterItem]="filterItem"
  [component]="component"
></app-workhour-assignment-autocomplete>

<app-workhour-leased-employee-next-button
  *ngIf="filterItem.customFilterClassName === 'workhourLeasedEmployeeNextButton'"
  #filterComponent
  [filterItem]="filterItem"
  [component]="component"
  [genericFilterComponent]="genericFilterComponent"
></app-workhour-leased-employee-next-button>

<app-workhour-generate-entries-button
  *ngIf="filterItem.customFilterClassName === 'workhourGenerateEntriesButton'"
  #filterComponent
  [filterItem]="filterItem"
  [component]="component"
  [genericFilterComponent]="genericFilterComponent"
></app-workhour-generate-entries-button>

<app-invoice-generate-button
  *ngIf="filterItem.customFilterClassName === 'invoiceGenerateButton'"
  #filterComponent
  [filterItem]="filterItem"
  [component]="component"
  [genericFilterComponent]="genericFilterComponent"
></app-invoice-generate-button>
