import { AbstractValidator } from './abstract-validator';

export class IsNotTmpDeletedValidator extends AbstractValidator {

  public isValid(value: any): boolean {
    return !this.getEntity().tmpIsDeleted;
  }

  public getErrorTranslateKey(value: any): string {
    return 'VALUE_IS_TEMPORARY_DELETED';
  }

  public getErrorTranslateParams(value: any): any {
    return null;
  }
}
