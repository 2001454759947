import {Injectable} from '@angular/core';
import {AbstractGenericElementEntityService} from './abstract-generic-element-entity.service';
import {EntityDirtyStoreService} from '../../entity-dirty-store.service';
import {MessageGrowlService} from 'app/core/message/message-growl.service';
import {TranslateService} from '@ngx-translate/core';
import {LoggerService} from 'app/shared/content-renderer/services/logger/logger.service';
import {EntityDataStoreService} from '../../entity-data-store.service';
import {EntityStatus} from '../../../../services/entity/entity-status';
import {EntityManagerService} from '../../../../../core/service/entity-manager/entity-manager.service';

@Injectable()
export class GenericTreeEntityService extends AbstractGenericElementEntityService {

  public constructor(protected entityDirtyStore: EntityDirtyStoreService,
                     protected entityDataStore: EntityDataStoreService,
                     protected messageGrowlService: MessageGrowlService,
                     protected translationService: TranslateService,
                     protected logger: LoggerService,
                     protected entityManager: EntityManagerService) {
    super(entityDirtyStore, entityDataStore, messageGrowlService, translationService, logger, entityManager);
  }

  public addNode(node: TreeNode, parent: TreeNode | null): this {

    if (parent && parent.data) {
      node.data.parent = parent.data;
    }

    if (null !== parent) {
      node.parent = parent;
      parent.children = [...parent.children, node];

      this.entityManager.persist(node.data, {property: 'parent', newValue: parent.data });

      parent.expanded = true;
    } else {
      this.component.entities = [...this.component.entities, node];
    }

    return this;
  }

  public getEntities(): Array<any> {
    var entities = [];

    const getEntities = (nodes: TreeNode[], parent: TreeNode[] = []) => {
      for (const node of nodes) {
        const entity = node.data;

        parent.push(entity);

        if (node.children) {
          getEntities(node.children, parent);
        }
      }
    };

    getEntities(this.component.entities, entities);

    return entities;
  }

  public addEntity(entity: any): void {

    let newNode = {
      data: entity,
      label: '',
      children: []
    };

    this.addNode(newNode, parent);

    this.refresh();
  }

  public replaceEntity(entity: any, forceReplace: boolean = false) {

    if (entity) {
      const treeNode: TreeNode = this.getTreeNode(entity);

      if (treeNode) {
        if (treeNode.data.id || forceReplace) {
          // This is the updated version - replace:
          treeNode.data = entity;
        }
      } else {
        this.addEntity(entity);
      }

      // when in edit mode, refresh method makes edit loose focus
      // this.refresh();
    }
  }

  public removeEntity(entity: any): void {

    if (entity) {
      let treeNode: TreeNode = this.getTreeNode(entity);

      if (treeNode) {
        let searchInNodes = this.component.entities;

        if (treeNode.parent) {
          searchInNodes = treeNode.parent.children;
        }

        let treeNodeIndex = this.getTreeNodeIndex(treeNode, searchInNodes);

        if (treeNodeIndex !== -1) {
          searchInNodes.splice(treeNodeIndex, 1);
        }
      }

      this.refresh();
    }
  }

  public getUpdatedEntities(shelved: boolean = false): any[] {
    if (shelved && this.component.updatedEntities.length > 0) {
      return this.component.updatedEntities;
    }

    var updatedEntities = [];

    const getUpdateEntities = (nodes: TreeNode[], parent: TreeNode[] = []) => {
      for (let node of nodes) {
        let entity = node.data;

        if (entity['id'] && entity[EntityStatus.ENTITY_CHANGED_FLAG]) {
          parent.push(entity);
        }

        if (node.children) {
          getUpdateEntities(node.children, parent);
        }
      }
    };

    getUpdateEntities(this.component.entities, updatedEntities);

    return updatedEntities;
  }

  public getCreatedEntities(shelved: boolean = false): any[] {
    if (shelved && this.component.createdEntities.length > 0) {
      return this.component.createdEntities;
    }

    var createdEntities = [];

    const getCreatedEntities = (nodes: TreeNode[], parent: TreeNode[] = []) => {
      for (let node of nodes) {
        let entity = node.data;

        if (!entity.id) {
          parent.push(entity);
        }

        if (node.children) {
          getCreatedEntities(node.children, parent);
        }
      }
    };

    getCreatedEntities(this.component.entities, createdEntities);

    return createdEntities;
  }

  public getDraftDeletedEntities(shelved: boolean = false): any[] {
    if (shelved && this.component.updatedEntities.length > 0) {
      return this.component.updatedEntities;
    }

    var draftDeletedEntities = [];

    const getDraftDeletedEntities = (nodes: TreeNode[], parent: TreeNode[] = []) => {
      for (let node of nodes) {
        let entity = node.data;

        if (entity[EntityStatus.ENTITY_DRAFT_DELETED_FLAG]) {
          parent.push(entity);
        }

        if (node.children) {
          getDraftDeletedEntities(node.children, parent);
        }
      }
    };

    getDraftDeletedEntities(this.component.entities, draftDeletedEntities);

    return draftDeletedEntities;
  }

  public getEmbeddedEntitiesChanged(): any[] {
    var createdEmbeddedEntities = [];

    const getCreatedEmbeddedEntities = (nodes: TreeNode[], parent: TreeNode[] = []) => {
      for (const node of nodes) {
        const entity = node.data;

        if (entity['id']
          && !entity[EntityStatus.ENTITY_CHANGED_FLAG]
          && entity[EntityStatus.EMBEDDED_ENTITY_CHANGED_FLAG]) {
          parent.push(entity);
        }

        if (node.children) {
          getCreatedEmbeddedEntities(node.children, parent);
        }
      }
    };

    getCreatedEmbeddedEntities(this.component.entities, createdEmbeddedEntities);

    return createdEmbeddedEntities;
  }

  public findEntity(entity: any): any {
    let foundEntity = null;

    for (const componentEntity of this.component.entities) {
      if ((entity.id && entity.id === componentEntity.id)
        || (!entity.id && entity === componentEntity)
        || (!entity.id
          && entity[EntityStatus.ENTITY_DRAFT_FLAG] === componentEntity[EntityStatus.ENTITY_DRAFT_FLAG])) {
        foundEntity = componentEntity;
        break;
      }
    }

    return foundEntity;
  }

  public findEntityById(entityId: number): any {
    var found = null;

    const getEntity = (nodes: TreeNode[], entityId) => {
      for (let node of nodes) {
        let entity = node.data;

        if (entity.id === entityId) {
          found = entity;
        }

        if (node.children) {
          getEntity(node.children, entityId);
        }
      }
    };

    getEntity(this.component.entities, entityId);

    return found;
  }

  public addShelved(): void {
    for (let createdEntity of this.getCreatedEntities(true)) {
      this.addEntity(createdEntity);
    }

    for (let updatedEntity of this.getUpdatedEntities(true)) {
      this.addEntity(updatedEntity);
    }
  }

  public getTreeNode(entity: any): TreeNode|null {
    let treeNode = null;

    const getTreeNode = (nodes: TreeNode[], toFindEntity: any) => {
      for (const node of nodes) {
        const entity = node.data;

        if (toFindEntity && toFindEntity.id && entity.id === toFindEntity.id) {
          treeNode = node;
          break;
        }

        if (toFindEntity && toFindEntity[EntityStatus.ENTITY_DRAFT_FLAG] &&
          entity[EntityStatus.ENTITY_DRAFT_FLAG] === toFindEntity[EntityStatus.ENTITY_DRAFT_FLAG]
        ) {
          treeNode = node;
          break;
        }

        if (node.children) {
          getTreeNode(node.children, toFindEntity);
        }
      }
    };

    getTreeNode(this.component.entities, entity);

    return treeNode;
  }

  public replaceTreeNode(treeNode: TreeNode, withTreeNode: TreeNode): void {

    if (treeNode) {
      let searchInNodes = this.component.entities;

      if (treeNode.parent) {
        searchInNodes = treeNode.parent.children;
      }

      const treeNodeIndex = this.getTreeNodeIndex(treeNode, searchInNodes);

      if (treeNodeIndex !== -1) {
        this.component.entities[treeNodeIndex] = withTreeNode;
      }
    }

    this.refresh();
  }

  private getTreeNodeIndex(toFindTreeNode: TreeNode, treeNodes: TreeNode[] = []): number {
    let index = -1;

    if (toFindTreeNode.data && toFindTreeNode.data.id) {
      index = treeNodes.findIndex((treeNode: TreeNode) => treeNode.data.id === toFindTreeNode.data.id);
    }

    if (index === -1 && toFindTreeNode.data && toFindTreeNode.data[EntityStatus.ENTITY_DRAFT_FLAG]) {
      index = treeNodes.findIndex((treeNode: TreeNode) => treeNode.data[EntityStatus.ENTITY_DRAFT_FLAG] === toFindTreeNode.data[EntityStatus.ENTITY_DRAFT_FLAG]);
    }

    return index;
  }
}
