import {map} from 'rxjs/operators';
import {ChangeDetectionStrategy, ChangeDetectorRef, Component} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {GenericCrudService} from '../../../../../../../services/generic-crud.service';
import {MessageGrowlService} from '../../../../../../../../core/message/message-growl.service';
import {EntityDirtyStoreService} from '../../../../../../services/entity-dirty-store.service';
import {EntityManagerService} from '../../../../../../../../core/service/entity-manager/entity-manager.service';
import {ToolbarItemAbstract} from '../../toolbar-item-abstract.component';
import {AbstractGenericGridComponent} from '../../../../../abstract-generic-grid.component';


@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-toolbar-item-validate-ca-entities',
  styleUrls: ['./toolbar-item-validate-ca-entities.component.scss'],
  templateUrl: './toolbar-item-validate-ca-entities.component.html'
})
export class ToolbarItemValidateCaEntitiesComponent extends ToolbarItemAbstract {
  public isDialogVisible = false;
  public collectiveAgreementOptions = [];
  public selectedCollectiveAgreement = null;

  constructor(
    protected genericCrudService: GenericCrudService,
    protected messageGrowlService: MessageGrowlService,
    protected entityDirtyStoreService: EntityDirtyStoreService,
    protected entityManager: EntityManagerService,
    protected translate: TranslateService,
    public cdr: ChangeDetectorRef
  ) {
    super();
  }

  public click() {
    const component = this.getComponent();

    if (component instanceof AbstractGenericGridComponent) {
      this.isDialogVisible = true;
    }
  }

  public onSearchCollectiveAgreements(event): void {
    const query = event.query;

    this.subscriptions.push(
      this.genericCrudService
        .getEntities(`phoenix/collectiveagreements/offset/0/limit/50/orderby/id/asc?search=${query}`)
        .subscribe((entities: any) => {
          this.collectiveAgreementOptions = entities.data;

          this.cdr.detectChanges();
        })
    );
  }

  public onRunCycle(event) {
    const component = this.getComponent();
    if (component instanceof AbstractGenericGridComponent
      && this.selectedCollectiveAgreement
    ) {
      this.isDialogVisible = false;
      this.genericCrudService.customPost(`app/commands/run/${this.getToolbarItem().parameter}`, {id: this.selectedCollectiveAgreement.id})
        .subscribe((result) => {
          this.messageGrowlService.success(this.translate.instant('COMMON.SUCCESS') + '.');
        });
    }
  }
}
