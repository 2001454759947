
import {of as observableOf,  Observable } from 'rxjs';
import { AbstractExecutionStepCondition } from '../../../../core/executor/abstract-execution-step-condition';
import { ExecutionStep } from '../../../../core/executor/execution-step';
import { GenericElementAbstract } from '../../../content-renderer/elements/generic-element-abstract.component';

export class IsInAutoCompleteViewModuleState extends AbstractExecutionStepCondition {

    public isValid(executionStep: ExecutionStep): Observable<boolean> {
        const component = executionStep.getPayload().getValue();

        if (component instanceof GenericElementAbstract) {
            const moduleState = component.getModuleState();

            return observableOf(moduleState.getCurrent().isAutocompleteView);
        }

        return observableOf(true);
    }
}
