/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../../../../../../../content-renderer/elements/generic-calendar/generic-calendar.component.ngfactory";
import * as i2 from "../../../../../../../../../content-renderer/elements/generic-calendar/generic-calendar.component";
import * as i3 from "./calendar-date-edit-expert-search-field-value.component";
var styles_SearchManagementCalendarDateEditExpertSearchFieldValueComponent = [];
var RenderType_SearchManagementCalendarDateEditExpertSearchFieldValueComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_SearchManagementCalendarDateEditExpertSearchFieldValueComponent, data: {} });
export { RenderType_SearchManagementCalendarDateEditExpertSearchFieldValueComponent as RenderType_SearchManagementCalendarDateEditExpertSearchFieldValueComponent };
export function View_SearchManagementCalendarDateEditExpertSearchFieldValueComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-generic-calendar", [], null, [[null, "valueChange"], [null, "onInputChangeValid"], [null, "onSelect"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("valueChange" === en)) {
        var pd_0 = ((_co.field.fieldValue = $event) !== false);
        ad = (pd_0 && ad);
    } if (("onInputChangeValid" === en)) {
        var pd_1 = (_co.onValueChange($event) !== false);
        ad = (pd_1 && ad);
    } if (("onSelect" === en)) {
        var pd_2 = (_co.onValueChange($event) !== false);
        ad = (pd_2 && ad);
    } return ad; }, i1.View_GenericCalendarComponent_0, i1.RenderType_GenericCalendarComponent)), i0.ɵdid(1, 114688, null, 0, i2.GenericCalendarComponent, [], { dateFormat: [0, "dateFormat"], placeholder: [1, "placeholder"], yearRange: [2, "yearRange"], mask: [3, "mask"], value: [4, "value"] }, { onInputChangeValid: "onInputChangeValid", onSelect: "onSelect" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = "dd.mm.yy"; var currVal_1 = "---"; var currVal_2 = "1950:2050"; var currVal_3 = "99.99.9999"; var currVal_4 = _co.field.fieldValue; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4); }, null); }
export function View_SearchManagementCalendarDateEditExpertSearchFieldValueComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-search-management-edit-calendar-date-expert-search-field-value", [], null, null, null, View_SearchManagementCalendarDateEditExpertSearchFieldValueComponent_0, RenderType_SearchManagementCalendarDateEditExpertSearchFieldValueComponent)), i0.ɵdid(1, 49152, null, 0, i3.SearchManagementCalendarDateEditExpertSearchFieldValueComponent, [], null, null)], null, null); }
var SearchManagementCalendarDateEditExpertSearchFieldValueComponentNgFactory = i0.ɵccf("app-search-management-edit-calendar-date-expert-search-field-value", i3.SearchManagementCalendarDateEditExpertSearchFieldValueComponent, View_SearchManagementCalendarDateEditExpertSearchFieldValueComponent_Host_0, { expertSearch: "expertSearch", container: "container", field: "field" }, { onChange: "onChange" }, []);
export { SearchManagementCalendarDateEditExpertSearchFieldValueComponentNgFactory as SearchManagementCalendarDateEditExpertSearchFieldValueComponentNgFactory };
