<a (click)="click()">
  <div class="item-container item-text-container">
  </div>
</a>
<p-dialog *ngIf="isDialogVisible" (onAfterHide)="dialogHide()" appendTo="body" [responsive]="true"
          [(visible)]="isDialogVisible" showEffect="fade" header="Verteilungsschlüssel import"
          [modal]="true" [width]="400" [height]="250">
  <p-fieldset legend="Import Defaults">
    <div class="ui-grid ui-grid-responsive ui-fluid">
      <div class="ui-grid-row">
        <div class="ui-grid-col-4">
          <label for="variantId">
            Variante: *
          </label>
        </div>
        <div class="ui-grid-col-8">
          <p-dropdown [options]="variantDropdown" [(ngModel)]="selectedVariant" id="variantId" filter="filter" [style]="{'width':'100%'}" required></p-dropdown>
        </div>
      </div>
      <div class="ui-grid-row">
         <button pButton type="button" (click)="onStart()">Importieren</button>
      </div>
    </div>
    <div class="ui-grid-row">
      <p-progressBar *ngIf="isDataLoading" mode="indeterminate" [style]="{'height': '6px', 'margin-top': '5px'}"></p-progressBar>
    </div>
  </p-fieldset>
</p-dialog>
