
import {publishReplay, refCount, catchError, map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';


import { AppHttpService } from '../../../app-http.service';
import { GenericCrudService } from '../generic-crud.service';
import { HttpClient } from '@angular/common/http';
import { cloneDeep } from 'lodash';
import { FieldMetadataGrid } from 'app/shared/services/module/module-element-field-metadata-grid';
import { Constants } from '../../../constants';
import { ModuleElementColumn } from './module-element-column';

@Injectable()
export class ModuleElementFieldCrudService extends AppHttpService {

  private fieldsUrl: string = 'superadmin/moduleelements/:moduleElementId/fields';
  private reorderFieldsUrl: string = 'superadmin/moduleelements/:moduleElementId/reorder';

  private apiUrl: string = '';

  constructor(
    private httpClient: HttpClient,
    private genericCrudService: GenericCrudService
  ) {
    super();
    this.apiUrl = this.getApiUrl();
  }

  /**
   * @param moduleElement
   * @returns {Observable<R>}
   */
  getFields(moduleElementId: number, format?: string): Observable<any[]> {
    if (!moduleElementId) {
      throw new Error('Element object or Element id not given.');
    }

    let url = this.createUrl(moduleElementId);

    if (format) {
      url += '/' + format
    }

    return this.genericCrudService.get(url).pipe(
      publishReplay(1),
      refCount(),
      catchError(this.handleError));
  }

  /**
   * @param moduleElement
   * @returns {Observable<R>}
   */
  saveFields(moduleElementId: number, fields: any[]): Observable<any> {
    if (!moduleElementId) {
      throw new Error('Element object or Element id not given.');
    }

    const url = this.apiUrl + '/' + this.createUrl(moduleElementId);

    return this.httpClient.post(
      url,
      JSON.stringify(fields),
      { params: this.genericCrudService.getRequestOptions().getHttpParams() }
    ).pipe(
      map(this.extractEmbeddedEntities, this));
  }

  /**
   * @param moduleElement
   * @returns {Observable<R>}
   */
  reorderFields(moduleElementId: number, fields: any[]): Observable<any> {
    if (!moduleElementId) {
      throw new Error('Element object or Element id not given.');
    }

    const url = this.apiUrl + '/' + this.createReorderUrl(moduleElementId);

    return this.httpClient.post(
      url,
      JSON.stringify(fields),
        { params: this.genericCrudService.getRequestOptions().getHttpParams() }
      ).pipe(
      map(this.extractEmbeddedEntities, this));
  }

  /**
   * @param moduleElement
   * @returns {Observable<R>}
   */
  reorderModuleElementColumns(moduleElementId: number, columns: ModuleElementColumn[]): Observable<any> {
    if (!moduleElementId) {
      throw new Error('Element object or Element id not given.');
    }

    return this.genericCrudService.editEntities('superadmin/moduleelementcolumns', columns);
  }

  private createUrl(moduleElementId: number) {
    const re = /\:moduleElementId/gi;

    return this.fieldsUrl.replace(re, String(moduleElementId));
  }

  private createReorderUrl(moduleElementId: number) {
    const re = /\:moduleElementId/gi;

    return this.reorderFieldsUrl.replace(re, String(moduleElementId));
  }

  private getFieldsWithoutModuleElementColumnFields(fields: FieldMetadataGrid[] = []): FieldMetadataGrid[] {
    const allFields = cloneDeep(fields);

    return allFields.filter((aField: FieldMetadataGrid) => { return aField.fieldType !== Constants.MODULE_ELEMENT_COLUMN_NAME });
  }
}
