
import {of as observableOf, Observable} from 'rxjs';
import {AbstractExecutionStep} from '../../../../../core/executor/abstract-execution-step';
import {ExecutionStepStatus} from '../../../../../core/executor/execution-step-status';
import {AbstractGenericGridComponent} from '../../../../content-renderer/elements/abstract-generic-grid.component';
import {Module} from '../../../module/module';

export class SetupContactPersonExpanderComponentExecutionStep extends AbstractExecutionStep {

  doExecute(): Observable<ExecutionStepStatus> {
    const component = this.getPayload().getValue();

    if (!(component instanceof AbstractGenericGridComponent)) {
      return this.getFailObservable('You need to pass AbstractGenericGridComponent as Payload value!');
    }

    if (!this.getParamValue('module')) {
      return this.getFailObservable('Module parameter missing!');
    }

    return this.doSetup(component);
  }

  private doSetup(component: AbstractGenericGridComponent): Observable<ExecutionStepStatus> {
    const moduleId = this.getParamValue('module');

    this.genericCrudService.getEntity('superadmin/modules', moduleId).subscribe((module: Module) => {
      component.expanderData = {
        module: module,
        masterField: 'address'
      };
    });

    return observableOf({status: true, content: null});
  }
}
