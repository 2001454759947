import { AbstractValidator } from './abstract-validator';
import {ElementInputAutocomplete, ElementInputDropdown} from '../../form-viewer/models';
import {ElementInputMultiAutocomplete} from '../../form-viewer/models/element-input-multi-autocomplete';

export class RequiredValidator extends AbstractValidator {

  public isValid(value: any): boolean {
    const field = this.getField();

    if (field && (field instanceof ElementInputDropdown || field instanceof ElementInputAutocomplete)) {
      return this.isAssociationFieldValueNotEmpty(value);
    }

    return this.isNotEmpty(value);
  }

  public getErrorTranslateKey(value: any): string {
    return 'VALUE_IS_REQUIRED';
  }

  public getErrorTranslateParams(value: any): any {
    return null;
  }

  private isNotEmpty(value): boolean {
    return typeof value !== 'undefined' && value !== null && value !== '';
  }

  private isAssociationFieldValueNotEmpty(value): boolean {
    let isNotEmpty = this.isNotEmpty(value);

    if (value instanceof Object && Object.keys(value).length === 0 && value.constructor === Object) {
      isNotEmpty = false;
    }

    return isNotEmpty;
  }
}
