
import {of as observableOf, Observable} from 'rxjs';

import {map, takeUntil} from 'rxjs/operators';
import {Component, OnInit, Input, ChangeDetectorRef} from '@angular/core';
import {GenericElementAbstract} from '../generic-element-abstract.component';
import {GenericCrudService} from '../../../services/generic-crud.service';
import {ContextMenu, ContextMenuParameter} from '../../../services/element/context-menu';
import {GenericElementContextMenuService} from '../../services/generic/generic-element-context-menu.service';
import {ExecutorService} from '../../../../core/executor/executor.service';
import {OverlayPanel} from 'primeng/primeng';
import {ChangeDetectorRefHelper} from '../../../helpers/change-detector-ref.helper';


@Component({
  selector: 'app-generic-context-menu',
  templateUrl: './generic-context-menu.component.html',
  styleUrls: ['./generic-context-menu.component.scss'],
  providers: [ ExecutorService ]
})
export class GenericContextMenuComponent implements OnInit {

  private _component: GenericElementAbstract = null;
  @Input() public set component(component: GenericElementAbstract) {
    this._component = component;
  };
  public get component(): GenericElementAbstract {
    return this._component;
  }

  @Input() public overlayPanel: OverlayPanel = null;

  public isDataLoading = false;
  public selectedContextMenu: ContextMenu = null;
  public contextMenus: ContextMenu[] = [];

  public constructor(
    private readonly genericCrudService: GenericCrudService,
    private readonly contextMenuService: GenericElementContextMenuService,
    private readonly executor: ExecutorService,
    private readonly cdr: ChangeDetectorRef
  ) {

  }

  public ngOnInit(): void {
    this.renderItems();
  }

  public onContextMenuClick(): void {
    this.contextMenuService
      .setExecutor(this.executor)
      .execute({
        component: this.component,
        contextMenu: this.selectedContextMenu
      }).subscribe();

    setTimeout(() => this.selectedContextMenu = null, 10);
    this.contextMenuService.hideOverlay(this.overlayPanel);
  }

  private renderItems(): void {
    this.loadContextMenus().pipe(
      takeUntil(this._component.unsubscribe))
      .subscribe((contextMenus: ContextMenu[] = []) => {
      this.contextMenus = contextMenus;

      ChangeDetectorRefHelper.detectChanges(this);
    });
  }

  private loadContextMenus(): Observable<ContextMenu[]> {
    const elementId = this.getElementId();

    if (elementId === null) {
      return observableOf([]);
    }

    this.isDataLoading = true;
    return this.genericCrudService.getEntities(`superadmin/contextmenus/offset/0/limit/50/orderby/id/asc?element=${elementId}`).pipe(
      map((paginated: {data: any[]}) => {
        const contextMenus = [],
          menus = paginated.data || [];

        for (const menu of menus) {
          const contextMenu = new ContextMenu(menu.id, menu.name, menu.description, menu.action);

          if (menu._embedded && menu._embedded.parameters && menu._embedded.parameters instanceof Array) {
            for (const parameter of menu._embedded.parameters) {
              contextMenu.addParameter(new ContextMenuParameter(parameter.id, parameter.name, parameter.value));
            }
          }

          contextMenus.push(contextMenu);
        }

        this.isDataLoading = false;
        return contextMenus;
      }));
  }

  private getElementId(): number|null {
    return this.component && this.component.moduleElement && this.component.moduleElement.element ?
      this.component.moduleElement.element.id : null;
  }
}
