import {ChangeDetectionStrategy, Component} from '@angular/core';
import { ToolbarItemAbstract } from '../toolbar-item-abstract.component';
import { ToolbarItemFormEntityService } from '../../services/toolbar-item-form-entity.service';
import { LocationService } from '../../../../../../services/location.service';
import { ElementsStackService } from '../../../../../services/elements-stack.service';
import { FormViewerComponent } from '../../../../../../form-viewer/form-viewer.component';
import {EntityDraftStoreService} from '../../../../../services/entity-draft-store.service';
import {ModulesStateService} from '../../../../../services/modules-state.service';
import {ModuleNavigationService} from '../../../../../services/navigation/module-navigation.service';
import {ModuleCloseComponentAction, ModuleCloseService} from '../../../../../services/navigation/module-close.service';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-toolbar-item-form-first',
  templateUrl: './toolbar-item-form.component.html',
  providers: [
    ToolbarItemFormEntityService
  ]
})
export class ToolbarItemFormFirst extends ToolbarItemAbstract {

  constructor(
    protected toolbarItemFormEntityService: ToolbarItemFormEntityService,
    protected locationService: LocationService,
    protected elementsStackService: ElementsStackService,
    protected entityDraftService: EntityDraftStoreService,
    protected modulesStateService: ModulesStateService,
    protected moduleNavigation: ModuleNavigationService,
    protected moduleCloseService: ModuleCloseService
  ) {
    super();
  }

  public click() {
    if (this.toolbarItemFormEntityService.isToolbarItemValid(this)) {
      this.handleClick(this.getParam('formViewerComponent'));
    }
  }

  protected handleClick(formViewer: FormViewerComponent) {
    const entityApiRoute = this.toolbarItemFormEntityService.getEntityApiRoute(formViewer.entity);

    this.moduleCloseService.close(this.modulesStateService.getCurrent(), {
      componentActions: [
        ModuleCloseComponentAction.ClearEntities,
        ModuleCloseComponentAction.RemoveRequestCache,
        ModuleCloseComponentAction.RemoveFromElementsState,
        ModuleCloseComponentAction.RemoveFromDirtyStore,
        ModuleCloseComponentAction.SetPerformedActionClose
      ],
      yes: () => {
        formViewer.getEntityDataStore().getFirst(entityApiRoute).subscribe((entity: any) => {
          const moduleState = this.modulesStateService.getByComponent(formViewer);

          if (this.locationService.getParam('id')) {
            moduleState.url = this.moduleNavigation.getModuleRouter().replaceRouteEntity(entity);
          }

          this.handleEntityLoaded(
            entity,
            formViewer,
            this.elementsStackService,
            this.locationService,
            this.entityDraftService,
            this.modulesStateService
          );
        });
      }
    });
  }
}
