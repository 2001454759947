
import {of as observableOf,  Observable } from 'rxjs';
import { AbstractExecutionStep } from '../../../../../core/executor/abstract-execution-step';
import { ExecutionStepStatus } from '../../../../../core/executor/execution-step-status';
import { FormViewerComponent } from '../../../../form-viewer/form-viewer.component';
import { SkipConditionsAware } from '../../../../../core/executor/type/skip-conditions-aware';
import { IsInAutoCompleteViewModuleState } from '../../conditions/is-in-autocomplete-view-module-state-condition';
import { AbstractExecutionStepCondition } from '../../../../../core/executor/abstract-execution-step-condition';
import { ElementContext, MasterEntityConfig } from '../../../../content-renderer/services/ElementContext';
import { ModuleState } from '../../../../content-renderer/services/module-state';
import { ElementInput } from '../../../../form-viewer/models/element';
import { GenericElementAbstract } from 'app/shared/content-renderer/elements/generic-element-abstract.component';
import { ElementInputAutocomplete } from 'app/shared/form-viewer/models/element-input-autocomplete';

export class FilterAddressAutocompleteInContactPersonFormStep extends AbstractExecutionStep implements SkipConditionsAware {

    public getSkipConditions(): AbstractExecutionStepCondition[] {
      return [
        new IsInAutoCompleteViewModuleState()
      ];
    }

    public doExecute(): Observable<ExecutionStepStatus> {
      const payload = this.getPayload();

      let component = payload.getValue();

      if (payload instanceof Object && payload.getValue().component) {
          component = payload.getValue().component;
      }

      if (!(component instanceof FormViewerComponent)) {
          return this.getFailObservable('You need to pass FormViewerComponent as Payload value!');
      }

      return this.doFilter(component);
    }

    private doFilter(component: FormViewerComponent): Observable<ExecutionStepStatus> {
      const parentModuleState = this.getParentModuleState(component);

      if (parentModuleState !== null) {
        const customerId = this.getParentFormCustomerId(parentModuleState),
          addressElement = this.getAddressElement(component);

        addressElement.datamodelFieldFilter = 'customer';
        addressElement.datamodelFieldFilterValue = customerId;
      }

      return observableOf({ status: true, content: '' });
    }

    private getParentModuleState(component: FormViewerComponent): ModuleState|null {
      const moduleState: ModuleState = component.getModuleState().getCurrent();
      
      if (moduleState && moduleState.parent) {
        return moduleState.parent;
      }

      return null;
    }

    private getParentFormCustomerId(moduleState: ModuleState|null): string|null {
      let form = this.getFormInComponents(moduleState.getAllComponents()),
        customerId = null;

      if (form !== null) {
        const formEntity = form.entity;

        customerId = formEntity.customer;

        if (!customerId && formEntity._embedded) {
          customerId = formEntity._embedded.customer;
        }
      }

      if (customerId instanceof Object && typeof customerId.id !== 'undefined') {
        customerId = customerId.id;
      }

      return customerId;
    }

    private getAddressElement(component: FormViewerComponent): ElementInputAutocomplete|null {
      let found = null;

      for (let element of component.inputElements) {
        if (element.datamodelField === 'address' && element instanceof ElementInputAutocomplete) {
          found = element;
          break;
        }
      }

      return found;
    }

    private getFormInComponents(components: GenericElementAbstract[]): FormViewerComponent|null {
      let found = null;

      for (let component of components) {
        if (component instanceof FormViewerComponent) {
          found = component;
          break;
        }
      }

      return found;
    }
}
