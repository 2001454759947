import {
  Component,
  Input,
  OnInit,
  OnDestroy,
  AfterViewInit,
  ChangeDetectionStrategy,
  ViewChild
} from '@angular/core';
import {ElementInputFileUpload} from '../models/element-input-file-upload';
import {ElementInputFileMultiUploadComponent} from './element-input-file-multi-upload.component';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-form-element-input-file-upload',
  template: `
      <app-multi-upload
        #upload
        [mode]="'advanced'"
        [disabled]="disabled"
        [filePath]="element.filePath"
        [fileContext]="element.fileContext"
        [fileLimit]="1"
        (fileSelect)="onFileSelect($event)"
        (uploadSuccess)="onUploadSuccess($event)"
        [tabIndex]="element.tabIndex"
      ></app-multi-upload>
    `,
    styles: [`
        :host {
          height: 100%;
          width: 100%;
        }
    `]
})
export class ElementInputFileUploadComponent extends ElementInputFileMultiUploadComponent implements OnInit, OnDestroy, AfterViewInit {
  @Input() element: ElementInputFileUpload;
  @ViewChild('upload', {static: false}) upload;
}
