/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "primeng/components/dom/domhandler";
import * as i2 from "primeng/components/button/button";
import * as i3 from "primeng/components/tooltip/tooltip";
import * as i4 from "@ngx-translate/core";
import * as i5 from "../../../../../p-button/p-button.directive";
import * as i6 from "./curriculum-action-column.component";
import * as i7 from "../../../../services/double-click.service";
var styles_CurriculumActionColumnComponent = [];
var RenderType_CurriculumActionColumnComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_CurriculumActionColumnComponent, data: {} });
export { RenderType_CurriculumActionColumnComponent as RenderType_CurriculumActionColumnComponent };
export function View_CurriculumActionColumnComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 15, "table", [], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 14, "tr", [], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 6, "td", [], null, null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 5, "button", [["icon", "fa fa-plus"], ["pButton", ""]], [[8, "disabled", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onAddNode() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵprd(4608, null, i1.DomHandler, i1.DomHandler, []), i0.ɵdid(5, 4341760, null, 0, i2.ButtonDirective, [i0.ElementRef], { icon: [0, "icon"] }, null), i0.ɵdid(6, 4341760, null, 0, i3.Tooltip, [i0.ElementRef, i0.NgZone], { text: [0, "text"] }, null), i0.ɵpid(131072, i4.TranslatePipe, [i4.TranslateService, i0.ChangeDetectorRef]), i0.ɵdid(8, 4341760, null, 0, i5.PButtonDirective, [i0.ElementRef], { icon: [0, "icon"] }, null), (_l()(), i0.ɵeld(9, 0, null, null, 6, "td", [], null, null, null, null, null)), (_l()(), i0.ɵeld(10, 0, null, null, 5, "button", [["pButton", ""]], [[4, "background-color", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.doOpenModule() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵprd(4608, null, i1.DomHandler, i1.DomHandler, []), i0.ɵdid(12, 4341760, null, 0, i2.ButtonDirective, [i0.ElementRef], { label: [0, "label"] }, null), i0.ɵpid(131072, i4.TranslatePipe, [i4.TranslateService, i0.ChangeDetectorRef]), i0.ɵdid(14, 4341760, null, 0, i5.PButtonDirective, [i0.ElementRef], { label: [0, "label"] }, null), i0.ɵpid(131072, i4.TranslatePipe, [i4.TranslateService, i0.ChangeDetectorRef])], function (_ck, _v) { var currVal_1 = "fa fa-plus"; _ck(_v, 5, 0, currVal_1); var currVal_2 = i0.ɵinlineInterpolate(1, "", i0.ɵunv(_v, 6, 0, i0.ɵnov(_v, 7).transform("COMMON.ADD_CHILD_NODE")), ""); _ck(_v, 6, 0, currVal_2); var currVal_3 = "fa fa-plus"; _ck(_v, 8, 0, currVal_3); var currVal_5 = i0.ɵinlineInterpolate(1, "", i0.ɵunv(_v, 12, 0, i0.ɵnov(_v, 13).transform("COMMON.DETAIL")), ""); _ck(_v, 12, 0, currVal_5); var currVal_6 = i0.ɵinlineInterpolate(1, "", i0.ɵunv(_v, 14, 0, i0.ɵnov(_v, 15).transform("COMMON.DETAIL")), ""); _ck(_v, 14, 0, currVal_6); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = (!_co.entity || !_co.entity.id); _ck(_v, 3, 0, currVal_0); var currVal_4 = _co.getColor(); _ck(_v, 10, 0, currVal_4); }); }
export function View_CurriculumActionColumnComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-curriculum-action-column", [], null, null, null, View_CurriculumActionColumnComponent_0, RenderType_CurriculumActionColumnComponent)), i0.ɵdid(1, 49152, null, 0, i6.CurriculumActionColumnComponent, [i0.ElementRef, i7.DoubleClickService], null, null)], null, null); }
var CurriculumActionColumnComponentNgFactory = i0.ɵccf("app-curriculum-action-column", i6.CurriculumActionColumnComponent, View_CurriculumActionColumnComponent_Host_0, { node: "node", entity: "entity", component: "component", column: "column" }, {}, []);
export { CurriculumActionColumnComponentNgFactory as CurriculumActionColumnComponentNgFactory };
