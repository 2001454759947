/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../../../../../../node_modules/primeng/components/checkbox/checkbox.ngfactory";
import * as i2 from "primeng/components/checkbox/checkbox";
import * as i3 from "@angular/forms";
import * as i4 from "./user-responsible-group-selection.component";
import * as i5 from "../../../../services/modules-state.service";
import * as i6 from "../../../../../../core/service/entity-manager/entity-manager.service";
var styles_UserResponsibleGroupSelectionComponent = [];
var RenderType_UserResponsibleGroupSelectionComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_UserResponsibleGroupSelectionComponent, data: {} });
export { RenderType_UserResponsibleGroupSelectionComponent as RenderType_UserResponsibleGroupSelectionComponent };
export function View_UserResponsibleGroupSelectionComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 5, "p-checkbox", [["binary", "true"], ["pInputCheckbox", ""]], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "onChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("onChange" === en)) {
        var pd_0 = (_co.onChange($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i1.View_Checkbox_0, i1.RenderType_Checkbox)), i0.ɵdid(1, 49152, null, 0, i2.Checkbox, [i0.ChangeDetectorRef], { binary: [0, "binary"] }, { onChange: "onChange" }), i0.ɵprd(1024, null, i3.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i2.Checkbox]), i0.ɵdid(3, 671744, null, 0, i3.NgModel, [[8, null], [8, null], [8, null], [6, i3.NG_VALUE_ACCESSOR]], { model: [0, "model"] }, null), i0.ɵprd(2048, null, i3.NgControl, null, [i3.NgModel]), i0.ɵdid(5, 16384, null, 0, i3.NgControlStatus, [[4, i3.NgControl]], null, null)], function (_ck, _v) { var _co = _v.component; var currVal_7 = "true"; _ck(_v, 1, 0, currVal_7); var currVal_8 = _co.isSelected(); _ck(_v, 3, 0, currVal_8); }, function (_ck, _v) { var currVal_0 = i0.ɵnov(_v, 5).ngClassUntouched; var currVal_1 = i0.ɵnov(_v, 5).ngClassTouched; var currVal_2 = i0.ɵnov(_v, 5).ngClassPristine; var currVal_3 = i0.ɵnov(_v, 5).ngClassDirty; var currVal_4 = i0.ɵnov(_v, 5).ngClassValid; var currVal_5 = i0.ɵnov(_v, 5).ngClassInvalid; var currVal_6 = i0.ɵnov(_v, 5).ngClassPending; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); }); }
export function View_UserResponsibleGroupSelectionComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-user-responsible-group-selection-column", [], null, null, null, View_UserResponsibleGroupSelectionComponent_0, RenderType_UserResponsibleGroupSelectionComponent)), i0.ɵdid(1, 114688, null, 0, i4.UserResponsibleGroupSelectionComponent, [i0.ChangeDetectorRef, i0.ElementRef, i5.ModulesStateService, i6.EntityManagerService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var UserResponsibleGroupSelectionComponentNgFactory = i0.ɵccf("app-user-responsible-group-selection-column", i4.UserResponsibleGroupSelectionComponent, View_UserResponsibleGroupSelectionComponent_Host_0, { node: "node", entity: "entity", component: "component", column: "column" }, {}, []);
export { UserResponsibleGroupSelectionComponentNgFactory as UserResponsibleGroupSelectionComponentNgFactory };
