<split direction="horizontal" [gutterSize]="5">
  
  <split-area [size]="30" class="overflow-y-hidden">
    <app-search-management-list #list [(expertSearches)]="expertSearches" (onExpertSearchChange)="onExpertSearchChange($event)"></app-search-management-list>
  </split-area>

  <split-area [size]="70">
    <app-search-management-edit (onExpertSearchSave)="onExpertSearchSave($event)" [expertSearch]="expertSearch"></app-search-management-edit>
  </split-area>

</split>
