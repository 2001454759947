import { of as observableOf } from 'rxjs';
import { AbstractExecutionStep } from '../../../../core/executor/abstract-execution-step';
import { AbstractGenericGridComponent } from '../../../content-renderer/elements/abstract-generic-grid.component';
export class WorkHourCalculateTimesStep extends AbstractExecutionStep {
    constructor() {
        super(...arguments);
        this.calculationRelevantFields = [
            'workStartIndustry',
            'workEndIndustry',
            'workStart2Industry',
            'workEnd2Industry',
            'workBreakIndustry',
            'workLengthIndustry',
            'workStart',
            'workEnd',
            'workStart2',
            'workEnd2',
            'workBreak',
            'workLength',
            'workLengthPlanned',
            'workLengthPlannedIndustry'
        ];
    }
    doExecute() {
        const payload = this.getPayload(), component = payload.getValue().component;
        if (component instanceof AbstractGenericGridComponent
            && payload.getValue().entityDataChangeMeta
            && payload.getValue().entityDataChangeMeta.entity
            && payload.getValue().entityDataChangeMeta.gridField
            && this.calculationRelevantFields.indexOf(payload.getValue().entityDataChangeMeta.gridField.id) !== -1) {
            return this.doCalculate(component, payload.getValue().entityDataChangeMeta.entity);
        }
        return this.getFailObservable('You need to pass AbstractGenericGridComponent or FormViewerComponent as Payload value!');
    }
    doCalculate(component, entity) {
        this.genericCrudService.editEntity(`phoenix/workhours/${entity.id}/calculate`, entity, component.apiRoutAdditionalParameters).subscribe(calculatedEntity => {
            let editingEntityIndex = component.entities.findIndex((aEntity) => {
                return aEntity.id === entity.id;
            });
            for (let field of this.calculationRelevantFields) {
                component.entities[editingEntityIndex][field] = calculatedEntity[field];
            }
        });
        return observableOf({ status: true, content: [] });
    }
}
