
import {of as observableOf,  Observable } from 'rxjs';

import {map, switchMap} from 'rxjs/operators';
import { BaseSetValueFromDialogStep } from '../../dialog-for-autocomplete/base-set-value-from-dialog-step';
import { FormViewerComponent } from '../../../../form-viewer/form-viewer.component';
import { FieldMetadataGrid } from '../../../module/module-element-field-metadata-grid';
import { ExecutionStepStatus } from '../../../../../core/executor/execution-step-status';
import { ElementInputAutocompleteComponent } from '../../../../form-viewer/element/element-input-autocomplete.component';

export class SetCollectiveAgreementToInquiryFromDialogStep extends BaseSetValueFromDialogStep {

    protected doSetValue(fromComponent: FormViewerComponent, toComponent: any, field: FieldMetadataGrid): Observable<ExecutionStepStatus> {
        const collectiveAgreement = fromComponent.entity ? fromComponent.entity.collectiveAgreement : null;

        let customer = toComponent && toComponent.entity ? toComponent.entity.customer : null;

        if (typeof customer === 'number') {
            customer = {
                id: customer
            }
        }

        if (toComponent instanceof ElementInputAutocompleteComponent &&
            collectiveAgreement !== null &&
            customer !== null
        ) {
            return this.getCustomerCollectiveContract(customer, collectiveAgreement).pipe(
                map((customerCollectiveContract: any) => {
                    toComponent.entity.collectiveAgreement = customerCollectiveContract.collectiveAgreement;
                    toComponent.entity._embedded.collectiveAgreement = customerCollectiveContract.collectiveAgreement;

                    this.setValueToFormAutocomplete(customerCollectiveContract.collectiveAgreement, toComponent);

                    return {status: true, content: null};
                }));
        }

        return observableOf({
            status: true,
            content: null
        });
    }

    protected getCustomerCollectiveContract(customer: any, collectiveAgreement: any): Observable<any> {
        const saveCustomerCollectiveContract: Observable<any> = this.saveCustomerCollectiveContract(customer, collectiveAgreement).pipe(
            map((customerCollectiveContract: any) => {
                return customerCollectiveContract;
            }));

        return this.findByCustomerAndCollectiveAgreement(customer, collectiveAgreement).pipe(
            switchMap((customerCollectiveContract: any) => {

                if (customerCollectiveContract === null) {
                    return saveCustomerCollectiveContract;
                }

                return observableOf(customerCollectiveContract);
            }));
    }

    private saveCustomerCollectiveContract(customer: any, collectiveAgreement: any): Observable<any> {
        const customerCollectiveContract: any = {
            customer: customer,
            collectiveAgreement: collectiveAgreement
        };

        return this.genericCrudService.createEntity('phoenix/customercollectivecontracts', customerCollectiveContract).pipe(
            map((customerCollectiveContract: any) => {
                return customerCollectiveContract;
            }));
    }

    private findByCustomerAndCollectiveAgreement(customer: any, collectiveAgreement: any): Observable<any> {
        const url: string = `phoenix/customercollectivecontracts/customerId/${customer.id}/collectiveAgreementId/${collectiveAgreement.id}`;

        return this.genericCrudService.get(url).pipe(
            map((customerCollectiveContract: any) => {
                return customerCollectiveContract;
            }));
    }


}
