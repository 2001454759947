import {Component, OnInit} from '@angular/core';
import {AbstractGenericGridSimpleSearchFieldComponent} from './abstract-generic-grid-simple-search-field.component';
import {AbstractGridFilter} from '../../../generic-grid/filters/abstract-grid-filter';

@Component({
  selector: 'app-generic-grid-simple-search-field-checkbox',
  template: ` 
    <p-checkbox
      [(ngModel)]="this.field.value"
      binary="true"
    ></p-checkbox>
  `
})
export class GenericGridSimpleSearchFieldCheckboxComponent extends AbstractGenericGridSimpleSearchFieldComponent implements OnInit {

  public ngOnInit(): void {
    this.field.comparator = AbstractGridFilter.MATCH_MODE_IN;
  }
}
