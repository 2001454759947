
import {throwError as observableThrowError, Observable} from 'rxjs';

import {catchError} from 'rxjs/operators';
import { Component, OnInit, Input } from '@angular/core';
import {Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {TOAST_LIFE_INFINITE, ToastComponentsRegistry, ToastService} from '../../../../../../service/toast.service';
import {HttpErrorResponseService} from '../../../../../../../shared/services/http-error-response-message.service';
import {MessageGrowlService} from '../../../../../../message/message-growl.service';
import {GenericCrudService} from '../../../../../../../shared/services/generic-crud.service';
import {Menu} from '../../../../../../../shared/services/menu/menu';
import {SelectItem} from 'primeng/primeng';
import {ChangeDetectorRefHelper} from '../../../../../../../shared/helpers/change-detector-ref.helper';
import {FileHelper} from '../../../../../../../shared/helpers/file-helper';
import {RequestCachingService} from '../../../../../../../shared/services/request-caching.service';
import {EntityDirtyStoreService} from '../../../../../../../shared/content-renderer/services/entity-dirty-store.service';
import {DatePipe} from '@angular/common';


@Component({
    selector: 'app-bdm-export-invoices-menu-item',
    templateUrl: './bdm-export-invoices-menu-item.component.html',
    styleUrls: ['./bdm-export-invoices-menu-item.component.scss']
})
export class BdmExportInvoicesMenuItemComponent implements OnInit {

  @Input() iconsPath = '';
  @Input() item: Menu;
  @Input() menuPosition = 'left';
  public isDialogVisible = false;
  public checkbox = true;
  public exportMode = 'latest';
  public invoices: SelectItem[] = [];
  public selectedInvoices: any[] = [];

  constructor(
    protected toastService: ToastService,
    protected translateService: TranslateService,
    protected genericCrudService: GenericCrudService,
    protected messageGrowlService: MessageGrowlService,
    protected httpErrorResponseService: HttpErrorResponseService,
    protected requestCachingService: RequestCachingService,
    protected entityDirtyStoreService: EntityDirtyStoreService
  ) { }

  ngOnInit() {

  }

  public onItemClick(item) {
    this.isDialogVisible = true;
    this.loadInvoices();
    return false;
  }

  onExport(event): void {
    this.toastService.custom(ToastComponentsRegistry.PROGRESS_BAR, {
      severity: 'info',
      life: TOAST_LIFE_INFINITE,
      closable: false,
      summary: this.translateService.instant('COMMON.LOADING_PLEASE_WAIT')
    });

    const url = 'phoenix/export/invoices';

    this.genericCrudService.get(url, {
      invoiceIds: this.selectedInvoices
    }).pipe(
      catchError((response) => {
        const errors = this.httpErrorResponseService.getErrors(response);
        this.messageGrowlService.error(errors.toString());
        this.toastService.clear(ToastComponentsRegistry.PROGRESS_BAR);

        return observableThrowError(response);
      }))
      .subscribe((response) => {
        this.toastService.clear(ToastComponentsRegistry.PROGRESS_BAR);
        this.loadInvoices();
        FileHelper.downloadFile(response);
      });
  }
  private loadInvoices(): void {
    this.invoices = [];
    this.genericCrudService.get('phoenix/invoices/unlocked', {embedded: 'customer'}).subscribe((data) => {
      if (data.length > 0) {
        this.checkbox = true;
        for (const invoice of data) {
          this.invoices.push({label: this.getLabel(invoice), value: invoice.id});
        }
      } else {
        this.checkbox = false;
        this.invoices.push({label: 'Es gibt keine freigegebene Rechnungen!', value: null, disabled: true})
      }
      ChangeDetectorRefHelper.detectChanges(this);
    });
  }

  private getLabel(invoice: any): string {
    const customer = invoice.customer;
    const date = new DatePipe('en').transform(invoice.invoiceDate, 'MM.yyyy');
    return date + ' / ' + customer.primaryName;
  }
}
