import { ElementInput } from './element';

export class ElementInputUpload extends ElementInput {
  constructor(
    public typeElement = 'upload',
    public fileName: string = undefined
  ) {
    super();
  }
}


