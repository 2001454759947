import {AbstractFormActionHandler} from '../abstract-form-action-handler';
import {GenericCrudService} from '../../../../services/generic-crud.service';
import {FormViewerComponent} from '../../../form-viewer.component';
import {ChangeDetectorRefHelper} from '../../../../helpers/change-detector-ref.helper';

export class HandlePrecalculationIsLockedActionHandler extends AbstractFormActionHandler {

  public constructor() {
    super();
  }

  public handleAction(): void {
    const entity = this.getEntity();

    if (this.isActionValid()) {
      this.toggleDisabledState(entity['isLocked']);
      this.doLockToolbarItems(entity['isLocked']);
    }
  }

  private isActionValid(): boolean {
    return this.getEntity();
  }

  private toggleDisabledState(disabled: boolean): void {
    const formGroup = this.getFormGroup(),
      controls = formGroup.controls;
    for (const key in controls) {
      if (disabled) {
        formGroup.controls[key].disable();
      } else {
        formGroup.controls[key].enable();
      }
    }
  }

  private doLockToolbarItems(doLock: boolean): void {
    const component = this.getComponent();

    if (component instanceof FormViewerComponent) {
      component.isToolbarDisabled = doLock;

      if (component.element.topToolbarItems instanceof Array) {
        for (const toolbarItem of component.element.topToolbarItems) {
          toolbarItem.disabled = doLock;
        }
      }

      if (component.element.statusbarItems instanceof Array) {
        for (const statusBarItem of component.element.statusbarItems) {
          statusBarItem.disabled = doLock;
        }
      }

      ChangeDetectorRefHelper.detectChanges(component);
    }
  }
}
