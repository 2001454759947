
import {of as observableOf, Observable} from 'rxjs';
import {AbstractExecutionStep} from '../../../../../core/executor/abstract-execution-step';
import {ExecutionStepStatus} from '../../../../../core/executor/execution-step-status';

export class OpenAddressHomepageWindowExecutionStep extends AbstractExecutionStep {

  doExecute(): Observable<ExecutionStepStatus> {
    const payload = this.getPayload(),
      entity = payload.getValue().entity;

    if (!entity) {
      return this.getFailObservable('You need to pass entity as part of Payload value!');
    }

    return this.doOpen(entity);
  }

  private doOpen(entity): Observable<ExecutionStepStatus> {
    const homepage = entity.homepage || entity.mainAddress.homepage || '';

    let href = '';

    if (homepage) {
      if ((homepage.match(/http:\/\//g) || homepage.match(/https:\/\//g) || []).length > 0) {
        href = homepage;
      } else {
        href = `http://${homepage}`;
      }

      const a = document.createElement('a');
      a.target = '_blank';
      a.href = href;
      a.click();
      a.remove();
    }

    return observableOf({status: true, content: null});
  }
}
