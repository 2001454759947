<ng-template [ngIf]="urlConfig.url">

    <p-toolbar>
        <div class="ui-toolbar-group-left">
            <label for="ddDocuware">Sicht : </label>
            <p-dropdown
              [options]="docuwareOptions"
              [(ngModel)]="docuware"
              (onChange)="refresh()"
              placeholder="Select"
              id="ddDocuware"
              [style]="{width: '200px'}"
            ></p-dropdown>
            <p-radioButton name="radiobtns" value=1 label=" Lesen " [(ngModel)]="moduleElement.docuwareOnlyRead"></p-radioButton>
            <p-radioButton name="radiobtns" value=0 label=" Schreiben " [(ngModel)]="moduleElement.docuwareOnlyRead"></p-radioButton>
            <button
              pButton
              icon="fa fa-refresh"
              [disabled]="docuware===null"
              (click)="refresh()"
            ></button>
        </div>
    </p-toolbar>

    <iframe id="generic-iframe" [src]="urlConfig.url" style="padding-bottom: 30px;"></iframe>

</ng-template>
