import {map} from 'rxjs/operators';
import {ChangeDetectionStrategy, ChangeDetectorRef, Component} from '@angular/core';
import {ToolbarItemAbstract} from '../toolbar-item-abstract.component';
import {GenericCrudService} from '../../../../../../services/generic-crud.service';
import {MessageGrowlService} from '../../../../../../../core/message/message-growl.service';
import {TranslateService} from '@ngx-translate/core';
import {Observable} from 'rxjs';
import {AbstractGenericGridComponent} from '../../../../abstract-generic-grid.component';
import {cloneDeep} from 'lodash';
import {Guid} from 'guid-typescript';
import {EntityDirtyStoreService} from '../../../../../services/entity-dirty-store.service';
import {EntityManagerService} from '../../../../../../../core/service/entity-manager/entity-manager.service';
import {ChangeDetectorRefHelper} from '../../../../../../helpers/change-detector-ref.helper';
import {EntityValidator} from '../../../../../../validators/services/entity-validator';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-toolbar-item-import-sftp-printer',
  styleUrls: ['./toolbar-item-import-sftp-printer.component.scss'],
  templateUrl: './toolbar-item-import-sftp-printer.component.html'
})
export class ToolbarItemImportSftpPrinterComponent extends ToolbarItemAbstract {
  public isDialogVisible = false;
  public sftpPrinterOptions = [];
  public selectedSftpPrinter = null;
  public printerName = '';
  public sftpPath = '';

  constructor(
    protected genericCrudService: GenericCrudService,
    protected messageGrowlService: MessageGrowlService,
    protected entityDirtyStoreService: EntityDirtyStoreService,
    protected entityManager: EntityManagerService,
    protected translate: TranslateService,
    public cdr: ChangeDetectorRef
  ) {
    super();
  }

  public click() {
    const component = this.getComponent();

    if (component instanceof AbstractGenericGridComponent) {
      this.isDialogVisible = true;
    }
  }

  public onSearchSftpPrinterOptions(event): void {
    const query = event.query;

    this.subscriptions.push(
      this.genericCrudService
        .getEntities(`phoenix/sftpprinters/offset/0/limit/50/orderby/id/asc?search=${query}`)
        .subscribe((entities: any) => {
          this.sftpPrinterOptions = entities.data;

          this.cdr.detectChanges();
        })
    );
  }

  public onSelectPrinter(event): void {
    if (this.selectedSftpPrinter) {
      this.sftpPath = this.selectedSftpPrinter.sftpPath;
      this.cdr.detectChanges();
    }
  }

  public onImport(event) {
    const component = this.getComponent();
    if (component instanceof AbstractGenericGridComponent
      && this.selectedSftpPrinter
      && this.sftpPath
      && this.printerName
    ) {
      const newEntity = cloneDeep(component.emptyEntity);
      newEntity.id = null;
      newEntity.uniqueId = Guid.create().toString();
      newEntity._links.self = null;
      newEntity._embedded = newEntity._embedded || {};
      this.entityManager.persist(newEntity, {property: 'name', newValue: this.printerName, force: true});
      this.entityManager.persist(newEntity, {property: 'sftpHost', newValue: this.selectedSftpPrinter.sftpHost, force: true});
      this.entityManager.persist(newEntity, {property: 'sftpHost', newValue: this.selectedSftpPrinter.sftpHost, force: true});
      this.entityManager.persist(newEntity, {property: 'sftpPort', newValue: this.selectedSftpPrinter.sftpPort, force: true});
      this.entityManager.persist(newEntity, {property: 'sftpUsername', newValue: this.selectedSftpPrinter.sftpUsername, force: true});
      this.entityManager.persist(newEntity, {property: 'sftpPassword', newValue: this.selectedSftpPrinter.sftpPassword, force: true});
      this.entityManager.persist(newEntity, {property: 'sftpPath', newValue: this.sftpPath, force: true});
      this.entityManager.persist(newEntity, {property: 'type', newValue: 'file', force: true});
      this.entityDirtyStoreService.store(newEntity);
      component.entities = [newEntity, ...component.entities];
      component.selectedEntity = newEntity;
      this.isDialogVisible = false;
      component.cdr.detectChanges();
    }
  }
}
