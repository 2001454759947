import {ChangeDetectionStrategy, Component} from '@angular/core';
import { ToolbarItemAbstract } from '../toolbar-item-abstract.component';
import { GenericCrudService } from '../../../../../../services/generic-crud.service';
import { MessageGrowlService } from '../../../../../../../core/message/message-growl.service';
import { TranslateService } from '@ngx-translate/core';
import { CompanyCommunicationService } from '../../../../../../company-search/services/company-communication.service';
import { Subscription } from 'rxjs';
import { AbstractGenericGridComponent } from 'app/shared/content-renderer/elements/abstract-generic-grid.component';
import { Element } from '../../../../../../services/element/element';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'toolbar-item-user-import',
  templateUrl: './toolbar-item-user-import.component.html'
})
export class ToolbarItemUserImportComponent extends ToolbarItemAbstract {

  public gridComponent: AbstractGenericGridComponent = null;

  public isDialogVisible = false;
  private companyAddedSubscription: Subscription;

  constructor(
    protected genericCrudService: GenericCrudService,
    protected messageGrowlService: MessageGrowlService,
    protected translate: TranslateService,
    protected companyCommunicationService: CompanyCommunicationService
  ) {
    super();
  }

  public click() {
    if (this.isInCurrentContext(ToolbarItemAbstract.CONTEXT_GRID)) {
      this.gridComponent = this.getParam('gridComponent');

      this.handleClickInGridContext();
    }
  }

  ngOnDestroy() {}


  protected handleClickInGridContext() {
    this.isDialogVisible = true;
  }
}
