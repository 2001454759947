import { AbstractExecutionStep } from '../../../core/executor/abstract-execution-step';
import { Observable } from 'rxjs/Observable';
import { ExecutionStepStatus } from '../../../core/executor/execution-step-status';
import { GenericElementAbstract } from '../../content-renderer/elements/generic-element-abstract.component';
import {EMPTY} from 'rxjs';
import {ElementType} from '../../content-renderer/services/ElementContext';

export class OnPreCalculationChangeExecutionStep extends AbstractExecutionStep {

  public doExecute(): Observable<ExecutionStepStatus> {
    const component = this.getPayload().getValue().component || this.getPayload().getValue();

    if (!(component instanceof GenericElementAbstract)) {
      return this.getFailObservable('You need to pass GenericElementAbstract as Payload value!');
    }

    return this.onChange(component);
  }

  private onChange(component: GenericElementAbstract): Observable<ExecutionStepStatus> {
    this.disableSaveWizardButton();

    return this.getSuccessObservable();
  }

  disableSaveWizardButton(): void {
    const wizardComponent: any = this.modulesStateService.getCurrent().getAllComponents().find((aComponent: GenericElementAbstract) => {
      return aComponent.elementContext && aComponent.elementContext.type === ElementType.Wizard
    });

    if (wizardComponent && wizardComponent.wizardElement) {
      const wizardElement = wizardComponent.wizardElement;
      const saveButton =  (wizardElement.buttons || [])[1];
      saveButton.isDisabled = true;
    }
  }
}
