import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-generic-split-button',
  templateUrl: './generic-split-button.component.html'
})
export class GenericSplitButtonComponent implements OnInit {

  public isDropDownVisible = false;

  @Input() items: any[] = [];

  constructor() { }

  ngOnInit() {

  }

  public toogleDropDownVisibility() {
    this.isDropDownVisible = !this.isDropDownVisible;
  }

  public onItemClick(item) {
    if (item.command && typeof item.command === 'function') {
      item.command();
    }

    this.toogleDropDownVisibility();
  }
}
