import { Component } from '@angular/core';
import { FieldActionAbstractComponent } from '../field-action-abstract.component';
import { GenericCrudService } from '../../../../../services/generic-crud.service';
import { DoubleClickService } from '../../../../services/double-click.service';
import {FieldMetadataGrid} from '../../../../../services/module/module-element-field-metadata-grid';
import {Entity} from "../../../../../helpers/entity";
import {ModulesStateService} from "../../../../services/modules-state.service";
import {EntityManagerService} from "../../../../../../core/service/entity-manager/entity-manager.service";

@Component({
  selector: 'field-action-set-value-to-another-field-from-entity',
  template: ''
})
export class FieldActionSetValueToAnotherFieldFromEntityComponent extends FieldActionAbstractComponent {

  public constructor(
    protected genericCrudService: GenericCrudService,
    protected doubleClickService: DoubleClickService,
    protected entityManager: EntityManagerService
  ) {
    super();
  }

  public fire() {
    const gridComponent = this.getGrid(),
      entity = gridComponent.getSelectedEntity();

    let columnToEdit = this.getActionParamValue('column'),
      fieldToEdit: FieldMetadataGrid = this.findField(columnToEdit);

    this.entityManager.persist(entity, {property: fieldToEdit.name, oldValue: entity[fieldToEdit.name], newValue: this.getValueToSet(fieldToEdit), force: true });
  }

  public findField(fieldId: string): any|null {
    for (const field of this.getGrid().fields) {
      if (field.id == fieldId) {
        return field;
      }
    }

    return null;
  }

  private getValueToSet(fieldToEdit){
    let filedName = this.getActionParamValue('valueToSet');
    let entity = this.getGrid().getSelectedEntity();
    let valueToSet = Entity.getValue(entity, filedName);

    switch (fieldToEdit.fieldType){
      case 'decimal':
      case 'float':
        valueToSet = parseFloat(valueToSet);
        break;
    }

    return valueToSet;
  }
}
