import {of as observableOf, Observable} from 'rxjs';
import {AbstractExecutionStep} from '../../executor/abstract-execution-step';
import {ExecutionStepStatus} from '../../executor/execution-step-status';
import {ModulesStateService} from '../../../shared/content-renderer/services/modules-state.service';
import {Router} from '@angular/router';

export class CloseAllTabsExecutionStep extends AbstractExecutionStep {

  public doExecute(): Observable<ExecutionStepStatus> {
    this.injector.get(ModulesStateService).clear();
    this.injector.get(Router).navigate(['']).then();

    return observableOf({status: true, content: null});
  }
}
