import {ChangeDetectionStrategy, Component} from '@angular/core';
import {ToolbarItemAbstract} from '../toolbar-item-abstract.component';
import {GenericCrudService} from '../../../../../../services/generic-crud.service';

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'app-toolbar-report-export',
    styleUrls: ['./toolbar-report-export.component.scss'],
    templateUrl: './toolbar-report-export.component.html'
})
export class ToolbarReportExportComponent extends ToolbarItemAbstract {

    public static readonly CLAUSES_URL = 'phoenix/reportclauses';
    public static readonly REPORT_EXPORT_URL = 'phoenix/reports/export';
    public static readonly REPORT_GET_DATA_URL = 'phoenix/reports/jsonexport';
    public static readonly PARAMETER_NAME = 'report';

    public isDialogVisible = false;
    public isDataLoading = false;

    public selectedEntity: any;
    public clauses;

    public clauseValues = {};
    constructor(
        protected genericCrudService: GenericCrudService
    ) {
        super();
    }

    public click() {
        this.selectedEntity = this.getEntity();
        this.genericCrudService
            .getEntities(ToolbarReportExportComponent.CLAUSES_URL
                + '?' + ToolbarReportExportComponent.PARAMETER_NAME + '=' + this.selectedEntity.id).subscribe((clauses) => {
            this.clauses = clauses;

            this.isDialogVisible = true;
            this.isDataLoading = false;
        });

    }

    public doExport() {
        const a = document.createElement('a');
        document.body.appendChild(a);
        a.style.setProperty('display', 'none');
        this.genericCrudService.customPost(ToolbarReportExportComponent.REPORT_EXPORT_URL,
            {'report': this.selectedEntity.id, 'params': this.clauseValues})
            .subscribe(data => {
                const blob = new Blob([data.data], { type: 'text/csv' });
                const url = window.URL.createObjectURL(blob);

                a.href = url;
                a.download = this.selectedEntity.name + '_' + (new Date()).toISOString() + '.csv';
                a.click();
                window.URL.revokeObjectURL(url);
            });
    }
}
