/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./leased-employee-region.component";
var styles_LeasedEmployeeRegionComponent = [".no-padding[_ngcontent-%COMP%] {\n      padding: 0;\n    }\n\n    .autocomplete-container[_ngcontent-%COMP%] {\n      position: relative;\n    }\n\n      .geoapify-autocomplete-input {\n      height: 20px;\n      width: 264px;\n      position: relative;\n    }\n\n      .geoapify-autocomplete-items {\n      width: 264px;\n    }"];
var RenderType_LeasedEmployeeRegionComponent = i0.ɵcrt({ encapsulation: 0, styles: styles_LeasedEmployeeRegionComponent, data: {} });
export { RenderType_LeasedEmployeeRegionComponent as RenderType_LeasedEmployeeRegionComponent };
export function View_LeasedEmployeeRegionComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "div", [["class", "ui-g-12 no-padding"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 0, "div", [["class", "autocomplete-container"], ["id", "autocomplete-container"]], null, null, null, null, null))], null, null); }
export function View_LeasedEmployeeRegionComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-leased-employee-region-search", [], null, null, null, View_LeasedEmployeeRegionComponent_0, RenderType_LeasedEmployeeRegionComponent)), i0.ɵdid(1, 114688, null, 0, i1.LeasedEmployeeRegionComponent, [i0.ElementRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var LeasedEmployeeRegionComponentNgFactory = i0.ɵccf("app-leased-employee-region-search", i1.LeasedEmployeeRegionComponent, View_LeasedEmployeeRegionComponent_Host_0, { field: "field" }, {}, []);
export { LeasedEmployeeRegionComponentNgFactory as LeasedEmployeeRegionComponentNgFactory };
