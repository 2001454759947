<div class="container" fxLayout="column" fxLayoutAlign="start stretch">

  <div fxFlex="30px" fxLayout="row">
    <div fxFlex="20">
      Module
    </div>

    <div fxFlex="70">
      <p-dropdown
        [filter]="true"
        [options]="moduleOptions"
        [(ngModel)]="selectedModuleOption"
        (onChange)="onModuleOptionChange($event)"
        [style]="{'width': '100%'}"
        appendTo="body"
        placeholder="---"
      ></p-dropdown>
    </div>

    <div fxFlex="10" *ngIf="isLoadingModules">
      <p-progressSpinner [style]="{width: '25px', height: '25px'}" strokeWidth="6" fill="#EEEEEE"></p-progressSpinner>
    </div>
  </div>

  <div fxFlex="30px" fxLayout="row">
    <div fxFlex="20">
      Module Element
    </div>

    <div fxFlex="70">
      <p-dropdown
        [filter]="true"
        [options]="moduleElementOptions"
        [(ngModel)]="selectedModuleElementOption"
        (onChange)="onModuleElementOptionChange($event)"
        [style]="{'width': '100%'}"
        appendTo="body"
        placeholder="---"
      ></p-dropdown>
    </div>

    <div fxFlex="10" *ngIf="isLoadingModuleElements">
      <p-progressSpinner [style]="{width: '25px', height: '25px'}" strokeWidth="6" fill="#EEEEEE"></p-progressSpinner>
    </div>
  </div>

  <div fxFlex="300px" fxLayout="row" *ngIf="moduleElement">
    <div fxFlex="100">
      <app-generic-grid
        (onSelectionChanged)="onSelectionChanged($event)"
        [moduleElement]="moduleElement"
        [element]="moduleElement.element"
        [fields]="moduleElement.fields"
        [(selectedEntities)]="selectedEntities"
        [toolbarItems]="[]"
        [statusBarItems]="[]"
      ></app-generic-grid>
    </div>
  </div>

</div>
