import {
  Component,
  OnInit,
  AfterViewInit,
  ChangeDetectionStrategy, ChangeDetectorRef
} from '@angular/core';
import { ElementComponent } from './element.component';
import { FormService } from '../form.service';
import { FormViewerService } from '../form-viewer.service';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-form-element-layout-tabs',
  template: `
    <div fxLayout="row" (click)="onClickElement($event)">
      <p-tabView>
        <p-tabPanel
          appSeleniumDirective
          [element]="element"
          *ngFor="let elementChild of element.children; let i = index;" [selected]="i === 0" header="{{elementChild.label}}">
          <app-form-element-layout-tabs-item
            [(selectedElement)]="selectedElement"
            [(showDialogAddAddress)]="showDialogAddAddress"
            [(actionParams)]="actionParams"
            [form]="form"
            [formGroup]="formGroup"
            [element]="elementChild"
            [moduleElement]="moduleElement"
            [entity]="entity"
            [editMode]="editMode"
          ></app-form-element-layout-tabs-item>
          <div class="clearfix"></div>
        </p-tabPanel>
      </p-tabView>
    </div>
  `,
  styles: [`
    p-tabView {
      ::ng-deep .ui-tabview-nav {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
      }

      ::ng-deep .ui-tabview-panel {}
    }
  `]
})
export class ElementLayoutTabsComponent extends ElementComponent implements OnInit, AfterViewInit {
  constructor(
    protected formService: FormService,
    public cdr: ChangeDetectorRef,
    protected formViewerService: FormViewerService,
  ) {
    super(formService, cdr, formViewerService);
  }

  onComponentInit() {
    this.filterActionAndHandleIt('oninit');
  }

  ngAfterViewInit() {
  }
}
