
import {throwError as observableThrowError, Observable, Subscription} from 'rxjs';

import {catchError} from 'rxjs/operators';
import { Component, OnInit, Input } from '@angular/core';
import {Menu} from '../../../../../../../shared/services/menu/menu';
import {TOAST_LIFE_INFINITE, ToastComponentsRegistry, ToastService} from '../../../../../../service/toast.service';
import {TranslateService} from '@ngx-translate/core';
import {GenericCrudService} from '../../../../../../../shared/services/generic-crud.service';
import {MessageGrowlService} from '../../../../../../message/message-growl.service';
import {HttpErrorResponseService} from '../../../../../../../shared/services/http-error-response-message.service';
import {environment} from '../../../../../../../../environments';
import {RequestCachingService} from '../../../../../../../shared/services/request-caching.service';
import {Constants} from '../../../../../../../constants';

@Component({
    selector: 'app-bdm-export-internal-salaries-menu-item',
    templateUrl: './bdm-export-internal-salaries-menu-item.component.html',
    styleUrls: ['./bdm-export-internal-salaries-menu-item.component.scss']
})
export class BdmExportInternalSalariesMenuItemComponent implements OnInit {

  @Input() iconsPath = '';
  @Input() item: Menu;
  @Input() menuPosition = 'left';

  public isDialogVisible = false;

  periodsDropdown: any[] = [];
  branchOfficeDropdown: any[] = [];
  selectedPeriod = null;
  selectedBranchOffice = null;
  protected subscriptions: Subscription[] = [];
  public baseUrl = environment.baseUrl;

  constructor(
    protected toastService: ToastService,
    protected translateService: TranslateService,
    protected genericCrudService: GenericCrudService,
    protected messageGrowlService: MessageGrowlService,
    protected requestCachingService: RequestCachingService,
    protected httpErrorResponseService: HttpErrorResponseService,
  ) { }

  ngOnInit() {
  }

  public onItemClick(item) {
    this.isDialogVisible = true;

    this.loadPeriods();
    this.loadBranchOffices();

    return false;
  }

  onExport(event) {

    this.toastService.custom(ToastComponentsRegistry.PROGRESS_BAR, {
      severity: 'info',
      life: TOAST_LIFE_INFINITE,
      closable: false,
      summary: this.translateService.instant('COMMON.LOADING_PLEASE_WAIT')
    });

    const url = 'phoenix/export/bmd/internalsalaries';
    const urlParams = {
      period: this.selectedPeriod,
      branchOffice: this.selectedBranchOffice
    };

    this.genericCrudService.get(url, urlParams).pipe(
      catchError((response) => {
        const errors = this.httpErrorResponseService.getErrors(response);
        this.messageGrowlService.error(errors.toString());
        this.toastService.clear(ToastComponentsRegistry.PROGRESS_BAR);

        return observableThrowError(response);
      }))
      .subscribe(() => {
        this.toastService.clear(ToastComponentsRegistry.PROGRESS_BAR);
        this.isDialogVisible = false;
        this.messageGrowlService.success(this.translateService.instant('COMMON.SUCCESS'), this.translateService.instant('COMMON.SUCCESSFULLY_EXPORTED'));
        this.requestCachingService.removeByExpression('phoenix');
      });
  }

  private loadPeriods() {
    this.subscriptions.push(
      this.genericCrudService.getEntities(`${Constants.PHOENIX_API_ROUTE}/periods`, '', {embedded: 'none'}).subscribe((periods) => {
        periods.unshift({ name: this.translateService.instant('COMMON.PLEASE_SELECT'), value: 0 });

        for (const period of periods) {
          if (period.isMonthly && period.startDate >= '2022-01-01') {
            this.periodsDropdown.push({label: period.name, value: period.id});
          }
        }
      })
    )
  }

  private loadBranchOffices() {
    this.subscriptions.push(
      this.genericCrudService.getEntities(`${Constants.APP_API_ROUTE}/branchoffices`, '', {embedded: 'none'}).subscribe((branchOffices) => {
        branchOffices.unshift({name: this.translateService.instant('COMMON.PLEASE_SELECT'), value: 0});

        for (const branchOffice of branchOffices) {
          this.branchOfficeDropdown.push({label: branchOffice.name, value: branchOffice.id });
        }
      })
    )
  }
}
