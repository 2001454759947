import {Component, Input} from '@angular/core';
import {ExecutorService} from '../../../../../core/executor/executor.service';
import {GenericElementValidationExecutionStepsFactory} from '../../../services/generic/generic-element-validation-execution-steps-factory';
import {CancelComponentChangesService} from '../../../services/cancel-components-changes.service';
import {GenericGridRemoteFilterService} from '../../generic-grid/services/generic-grid-remote-filter.service';
import {GenericGridGlobalFilterService} from '../../generic-grid/services/generic-grid-global-filter.service';
import {GenericGridColumnBuilderService} from '../../generic-grid/services/generic-grid-column-builder.service';
import {GenericGridBulkSaveService} from '../../generic-grid/services/generic-grid-bulk-save.service';
import {GenericGridEntityService} from '../../../services/generic/entity/generic-grid-entity.service';
import {GenericElementInlineEditorService} from '../../../services/generic/generic-element-inline-editor.service';
import {GenericElementFilterService} from '../../../services/generic/filter/generic-element-filter.service';
import {GenericTurboGridLayoutService} from '../../generic-turbo-grid/service/generic-turbo-grid-layout-service';
import {GenericGridSingleEntitySaveService} from '../../generic-grid/services/generic-grid-single-entity-save.service';
import {GenericGridLayoutService} from '../../generic-grid/services/generic-grid-layout.service';
import {InvoiceArticleAutocompleteHandlerService} from './autocomplete-handler/invoice-article-autocomplete-handler.service';
import {CostCenterAutocompleteHandlerService} from './autocomplete-handler/cost-center-autocomplete-handler.service';
import {SubAssignmentAutocompleteHandlerService} from './autocomplete-handler/sub-assignment-autocomplete-handler.service';
import {AssignmentAutocompleteHandlerService} from './autocomplete-handler/assignment-autocomplete-handler.service';
import {Entity} from '../../../../helpers/entity';
import {NumberHelper} from '../../../../helpers/number.helper';
import {CustomerInvoicePositionDetailTableComponent} from './customer-invoice-position-detail-table.component';
import {forkJoin as observableForkJoin, Observable, of as observableOf, of} from 'rxjs';
import {catchError, map, takeUntil} from 'rxjs/operators';
import {EntityStatus} from '../../../../services/entity/entity-status';
import {ChangeDetectorRefHelper} from '../../../../helpers/change-detector-ref.helper';
import {cloneDeep} from 'lodash';
import {Guid} from 'guid-typescript';
import {FormViewerComponent} from '../../../../form-viewer/form-viewer.component';

@Component({
  selector: 'app-custom-customer-invoice-position-detail-invoice-context-table',
  templateUrl: './customer-invoice-position-detail-invoice-context-table.component.html',
  providers: [
    CancelComponentChangesService,
    GenericGridRemoteFilterService,
    GenericGridGlobalFilterService,
    GenericGridColumnBuilderService,
    GenericGridBulkSaveService,
    GenericGridEntityService,
    GenericElementInlineEditorService,
    GenericElementFilterService,
    GenericElementValidationExecutionStepsFactory,
    GenericTurboGridLayoutService,
    ExecutorService,
    GenericGridSingleEntitySaveService,
    GenericGridLayoutService
  ],
  styles: [`
    :host {
      height: 100%;
    }
  `]
})
export class CustomerInvoicePositionDetailInvoiceContextTableComponent extends CustomerInvoicePositionDetailTableComponent {
  @Input() public invoice = null;
  public leasedEmployeeDialogVisible = false;
  public lastSelectedOrUnselectedEntity: any = null;

  public startNewEntityAdd(entity?: any): void {
    entity = entity || this.emptyEntity;
    entity.fqn = 'PhoenixBundle\\Entity\\InvoicePositionDetail';

    this.finishNewEntityAdd(entity);
  }

  protected finishNewEntityAdd(entity) {
    const newEntity = cloneDeep(entity);

    newEntity[EntityStatus.ENTITY_DRAFT_FLAG] = Guid.create().toString();
    this.entityManager.persist(newEntity, {property: 'invoicePosition', newValue: entity.invoicePosition, force: true });
    this.entityManager.persist(newEntity, {property: 'fqn', newValue: 'PhoenixBundle\\Entity\\InvoicePositionDetail', force: true });

    this.entities = [...this.entities, newEntity];
    this.selectedEntity = newEntity;

    this.updateRowsStyle();

    this.onExpanderChange.emit(entity);
  }

  public initColumns(): this {
    const invoiceHandler = new InvoiceArticleAutocompleteHandlerService(this.genericCrudService),
      costCenterHandler = new CostCenterAutocompleteHandlerService(this.genericCrudService, this.findMasterFormComponent()),
      subAssignmentHandler = new SubAssignmentAutocompleteHandlerService(this.genericCrudService, this.findMasterFormComponent()),
      editable = typeof this.invoice.isEditable === 'undefined' || this.invoice.isEditable === true;

    this.columns = [
      {
        key: 'invoiceArticle',
        header: this.translationService.instant('INVOICE.INVOICE_ARTICLE'),
        renderer: (entity) => {
          const invoiceArticle = Entity.getValue(entity, 'invoiceArticle') ||
            Entity.getValueInEmbedded(entity, 'invoiceArticle');

          if (invoiceArticle) {
            return `${invoiceArticle.code} ${invoiceArticle.name}`;
          }

          return '';
        },
        edit: editable ? {
          type: 'autocomplete',
          autocompleteHandler: invoiceHandler
        } : undefined
      },
      {
        key: 'amount',
        header: this.translationService.instant('INVOICE.AMOUNT'),
        renderer: (entity) => {
          const value = Entity.getValue(entity, 'amount');
          return NumberHelper.formatNumber(value, ',', '.', 2);
        },
        edit: editable ? {
          type: 'number-v2'
        } : undefined
      },
      {
        key: 'description',
        header: this.translationService.instant('INVOICE.DETAIL_DESCRIPTION'),
        edit: editable ? {} : undefined
      },
      {
        key: 'timeEntry',
        header: this.translationService.instant('INVOICE.TIME_ENTRY'),
        edit: editable ? {} : undefined
      },
      {
        key: 'value',
        header: this.translationService.instant('INVOICE.VALUE'),
        renderer: (entity) => {
          const value = Entity.getValue(entity, 'value');
          return NumberHelper.formatNumber(value, ',', '.', 2);
        },
        edit: editable ? {
          type: 'number-v2'
        } : undefined
      },
      {
        key: 'total',
        header: this.translationService.instant('INVOICE.TOTAL'),
        renderer: (entity) => {
          const value = Entity.getValue(entity, 'total');
          return NumberHelper.formatNumber(value, ',', '.', 2);
        }
      },
      {
        key: 'costCenter',
        header: this.translationService.instant('INVOICE.COST_CENTER'),
        edit: editable ? {
          type: 'autocomplete',
          autocompleteHandler: costCenterHandler
        } : undefined,
        renderer: (entity) => {
          return Entity.getValue(entity, 'costCenter.name');
        }
      },
      {
        key: 'subAssignment',
        header: this.translationService.instant('INVOICE.SUB_ASSIGNMENT'),
        edit: editable ? {
          type: 'autocomplete',
          autocompleteHandler: subAssignmentHandler
        } : undefined,
        renderer: (entity) => {
          const subAssignment = Entity.getValue(entity, 'subAssignment') ||
            Entity.getValueInEmbedded(entity, 'subAssignment');

          if (!subAssignment) {
            return '';
          }

          return Entity.getValue(subAssignment, 'subAssignmentNumber');
        }
      },
      {
        key: 'menu',
        header: '',
        style: {
          width: '30px'
        },
        menu: editable ? {
          buttons: [
            {click: this.onDelete.bind(this), icon: 'fa fa-trash'}
          ]
        } : undefined
      }
    ];

    return this;
  }

  public onLazyLoad(event): void {
    this.currentOffset = event.first;
    this.defaultPageSize = event.rows;
    this.sortField = event.sortField || 'id';
    this.sortDirection = event.sortOrder === 1 ? 'asc' : 'desc';

    if (this.invoice && this.invoice.id) {
      this.loadEntities().subscribe();
    }
  }

  public loadEntities(): Observable<any> {
    this.isLoadingData = true;

    const params = {
      offset: this.currentOffset,
      limit: this.defaultPageSize,
      orderBy: this.sortField,
      orderDirection: this.sortDirection
    };

    const route = `phoenix/invoicepositiondetails/offset/${params.offset}/limit/${params.limit}/orderby/${params.orderBy}/${params.orderDirection}`;

    return this.genericCrudService
      .getEntities(route, '', {
        'invoicePosition.invoice': this.invoice.id,
        embedded: 'invoiceArticle,assignment,subAssignment,invoicePosition,invoicePosition.leasedEmployee'
      }).pipe(
        map((paginated: any) => {
          this.genericCrudService.extractEmbeddedEntities(paginated.data)
          this.entities = paginated.data;
          this.totalCount = paginated.total;

          for (const entity of this.entities) {
            entity[EntityStatus.ENTITY_CHANGED_FLAG] = false;
          }

          this.updateRowsStyle();
          this.setTableScrollHeightAndWidth();

          this.isLoadingData = false;
          ChangeDetectorRefHelper.detectChanges(this);
        }));
  }

  protected findMasterFormComponent(): FormViewerComponent|null {
    for (const stashedComponent of this.componentService.getStashed()) {
      if (stashedComponent instanceof FormViewerComponent) {
        return stashedComponent;
      }
    }
    return null;
  }
}
