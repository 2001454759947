import { Component } from '@angular/core';
import { FieldActionAbstractComponent } from '../field-action-abstract.component';
import { GenericCrudService } from '../../../../../services/generic-crud.service';
import { DoubleClickService } from '../../../../services/double-click.service';
import {FieldMetadataGrid} from '../../../../../services/module/module-element-field-metadata-grid';

@Component({
  selector: 'field-action-disable-self-based-on-open-quantity',
  template: ''
})
export class FieldActionDisableSelfBasedOnOpenQuantityComponent extends FieldActionAbstractComponent {

  public constructor(
    protected genericCrudService: GenericCrudService,
    protected doubleClickService: DoubleClickService,
  ) {
    super();
  }

  public fire() {
    const gridComponent = this.getGrid(),
      entity = gridComponent.getSelectedEntity(),
      field = this.getField();

    if (typeof field.isReadOnlyByEntity === 'undefined') {
      field.isReadOnlyByEntity = {};
    }

    if (entity['id'] && parseFloat(entity['amount']) !== parseFloat(entity['openAmount'])) {
      field.isReadOnlyByEntity[entity.id] = true;
    } else {
      field.isReadOnlyByEntity[entity.id] = false;
    }
  }
}
