import {of as observableOf, Observable} from 'rxjs';
import {AbstractExecutionStep} from '../../../../core/executor/abstract-execution-step';
import {ExecutionStepStatus} from '../../../../core/executor/execution-step-status';
import {AbstractGenericGridComponent} from '../../../content-renderer/elements/abstract-generic-grid.component';
import {Entity} from '../../../helpers/entity';
import {Guid} from 'guid-typescript';

export class SetupWorkHourPeriodEntitiesStep extends AbstractExecutionStep {

  public doExecute(): Observable<ExecutionStepStatus> {
    const payload = this.getPayload(),
      component = payload.getValue();

    if (component instanceof AbstractGenericGridComponent) {
      return this.doSetup(component);
    }

    return this.getFailObservable('You need to pass AbstractGenericGridComponent or FormViewerComponent as Payload value!');
  }

  protected doSetup(component: AbstractGenericGridComponent) {
    const entities = component.entities.filter((entity) => !entity.isSummary),
      groupedEntities = this.getGroupedEntities(entities),
      groupedEntitiesWithSums = this.getGroupedEntitiesWithSums(groupedEntities),
      flattenEntities = this.getFlattenEntities(groupedEntitiesWithSums);

    component.entities = [...flattenEntities];

    return observableOf({status: true, content: []});
  }

  protected getGroupByKey(entity: { period: { startDate: string, endDate: string }, subAssignment: { id: number } }) {
    const periodId = Entity.getValue(entity, 'period.id') || Entity.getValueInEmbedded(entity, 'period.id');
    const subAssignmentId = Entity.getValue(entity, 'subAssignment.id') || Entity.getValueInEmbedded(entity, 'subAssignment.id');

    return `${periodId}-${subAssignmentId}`
  }

  protected getGroupedEntities(entities): Record<string, Record<string, any>[]> {
    return entities.reduce((r, entity) => {
      const groupKey = this.getGroupByKey(entity)
      r[groupKey] = r[groupKey] || [];
      r[groupKey].push(entity);
      return r;
    }, Object.create(null));
  }

  protected getGroupedEntitiesWithSums(groupedEntities: Record<string, Record<string, any>[]>): Record<string, Record<string, any>[]> {
    for (const groupKey in groupedEntities) {
      const entities = groupedEntities[groupKey];
      let calculatedInvoice = 0;
      let calculatedWage = 0;

      for (const entity of entities) {
        calculatedInvoice += !isNaN(+entity.calculatedInvoice) ? +entity.calculatedInvoice : 0;
        calculatedWage += !isNaN(+entity.calculatedWage) ? +entity.calculatedWage : 0;
      }

      groupedEntities[groupKey].push({
        isSummary: true,
        calculatedInvoice,
        calculatedWage,
        id: Guid.create().toString(),
      })
    }

    return groupedEntities;
  }

  protected getFlattenEntities(groupedEntitiesWithSums: Record<string, Record<string, any>[]>): Record<string, any>[] {
    const flattenEntities = [];

    for (const groupKey in groupedEntitiesWithSums) {
      const entities = groupedEntitiesWithSums[groupKey];

      for (const entity of entities) {
        flattenEntities.push(entity);
      }
    }

    return flattenEntities;
  }

}
