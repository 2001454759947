<button type="button" (click)="display=true" pButton icon="fa fa-search" iconPos="right"
        [disabled]="disabled"
        label="{{datamodel}}"
        style="padding: .25em 2.1em .25em 1em; width: 100%"
></button>

<p-dialog header="Choose Data Field" [(visible)]="display">
  <div [style.marginBottom.px]="4" class="ui-inputgroup">
    <input placeholder="{{ 'COMMON.FILTER' | translate }}" type="text" pInputText [(ngModel)]="filterValue" (keyup)="onFilter($event)" />
    <span class="ui-inputgroup-addon"><i class="fa fa-search"></i></span>
  </div>

  <p-tree [value]="filteredDataModelFields" (onNodeExpand)="nodeExpand($event)"
          (onNodeSelect)="nodeSelect($event)" selectionMode="single"
  ></p-tree>
</p-dialog>
