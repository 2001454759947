<div class="container" *ngIf="cronJob.options.length > 0" fxLayout="column" fxLayoutAlign="space-between stretch">

  <div fxFlex="30px">
    Options
  </div>

  <div fxFlex="30px" fxLayout="row" fxLayoutAlign="space-between stretch" *ngFor="let option of this.cronJob.options">

    <div fxFlex="30">
      {{option.name}}
    </div>

    <div fxFlex="70">
      <ng-container [ngSwitch]="true">
        <app-organisation-dropdown
          *ngSwitchCase="option.name === 'organisationId'"
          [valueAsId]="true"
          [selectedOrganisationOption]="currentOrganisationId"
          [forceSelectionOnLoad]="true"
          [style]="{'width' : '100%'}"
          [disabled]="true"
        ></app-organisation-dropdown>

        <input
          *ngSwitchCase="option.name === 'executed-by-user-id'"
          [disabled]="true"
          [required]="option.isRequired"
          type="text"
          [(ngModel)]="currentUserUsername"
          pInputText
        />

        <input *ngSwitchDefault (keyup)="onKeyUp($event)" [disabled]="isDisabled(option)" [required]="option.isRequired" type="text" [(ngModel)]="option.value" pInputText />
      </ng-container>
    </div>

  </div>

</div>
