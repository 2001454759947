
import {of as observableOf, forkJoin as observableForkJoin,  Observable } from 'rxjs';

import {catchError} from 'rxjs/operators';
import { AbstractExecutionStep } from '../../../../core/executor/abstract-execution-step';
import { ExecutionStepStatus } from '../../../../core/executor/execution-step-status';
import { InquiryPositionSalaryTypeMatrix } from './service/inquiry-position-salary-type-matrix';
import { AbstractGenericGridComponent } from 'app/shared/content-renderer/elements/abstract-generic-grid.component';
import { InquiryPositionSalaryType } from './service/inquiry-position-salary-type';
import { InquiryPositionSalaryTypeParser } from './service/inquiry-position-salary-type-parser';
import {EntityStatus} from '../../entity/entity-status';

export class SaveInquiryDynamicTableStep extends AbstractExecutionStep {

    private parser = new InquiryPositionSalaryTypeParser();

    doExecute(): Observable<ExecutionStepStatus> {
        const component = this.getPayload().getValue();

        if (!(component instanceof AbstractGenericGridComponent)) {
            return this.getFailObservable('You need to pass AbstractGenericGridComponent as Payload value!');
        }

        return this.doSave(component);
    }

    private doSave(component: AbstractGenericGridComponent): Observable<ExecutionStepStatus> {
        const matrix: InquiryPositionSalaryTypeMatrix[] = component.entities,
            updatedMatrixItems: InquiryPositionSalaryTypeMatrix[] = component.getUpdatedEntities();

        const toUpdateEntities: InquiryPositionSalaryType[] = this.parser.getInquiryPositionSalaryTypesFromMatrix(updatedMatrixItems);

        return this.saveUpdated(toUpdateEntities);
    }

    private saveUpdated(toUpdateEntities: InquiryPositionSalaryType[] = []): Observable<ExecutionStepStatus> {
        const observables: Observable<any>[] = [];

        for (const updatedEntity of toUpdateEntities) {
            updatedEntity[EntityStatus.ENTITY_CHANGED_FLAG] = true;
            observables.push(this.genericCrudService.editEntity(`phoenix/inquirypositionssalarytypes/${updatedEntity.id}`, updatedEntity));
        }

        return Observable.create((observer) => {

          if (observables.length === 0) {
            observer.next({
              status: true,
              content: null,
            });
            observer.complete();
          }

          observableForkJoin(observables).pipe(
            catchError((response: any) => {
              return observableOf(response);
            }))
            .subscribe(results => {
              observer.next({
                status: true,
                content: results
              });
              observer.complete();
            });
        });
      }
}
