/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../content-renderer/content-renderer.component.ngfactory";
import * as i2 from "../../content-renderer/content-renderer.component";
import * as i3 from "../../services/generic-crud.service";
import * as i4 from "../../services/generic-crud-bulk.service";
import * as i5 from "../../services/layout-service";
import * as i6 from "@angular/common";
import * as i7 from "./element-module-element-grid.component";
import * as i8 from "../form.service";
import * as i9 from "../form-viewer.service";
var styles_ElementModuleElementGridComponent = ["[_nghost-%COMP%] {\n    width: 100%;\n    height: 100%;\n    background: white;\n  }\n\n    .choose-module-container {\n    text-align: center;\n    margin-top: 25%;\n  }"];
var RenderType_ElementModuleElementGridComponent = i0.ɵcrt({ encapsulation: 0, styles: styles_ElementModuleElementGridComponent, data: {} });
export { RenderType_ElementModuleElementGridComponent as RenderType_ElementModuleElementGridComponent };
function View_ElementModuleElementGridComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "div", [["class", "choose-module-container"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Modul ausw\u00E4hlen"]))], null, null); }
function View_ElementModuleElementGridComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "app-content-renderer", [], null, [[null, "entityChange"], ["window", "resize"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("window:resize" === en)) {
        var pd_0 = (i0.ɵnov(_v, 2).onResize($event) !== false);
        ad = (pd_0 && ad);
    } if (("entityChange" === en)) {
        var pd_1 = ((_co.entity = $event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i1.View_ContentRendererComponent_0, i1.RenderType_ContentRendererComponent)), i0.ɵdid(2, 245760, null, 0, i2.ContentRendererComponent, [i3.GenericCrudService, i4.GenericCrudBulkService, i0.ElementRef, i5.LayoutService, i0.ChangeDetectorRef], { entity: [0, "entity"], masterField: [1, "masterField"], moduleId: [2, "moduleId"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.entity; var currVal_1 = _co.element.datamodelField; var currVal_2 = _co.element.openingModuleId; _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2); }, null); }
function View_ElementModuleElementGridComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "app-content-renderer", [], null, [["window", "resize"]], function (_v, en, $event) { var ad = true; if (("window:resize" === en)) {
        var pd_0 = (i0.ɵnov(_v, 2).onResize($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i1.View_ContentRendererComponent_0, i1.RenderType_ContentRendererComponent)), i0.ɵdid(2, 245760, null, 0, i2.ContentRendererComponent, [i3.GenericCrudService, i4.GenericCrudBulkService, i0.ElementRef, i5.LayoutService, i0.ChangeDetectorRef], { masterField: [0, "masterField"], moduleId: [1, "moduleId"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.element.datamodelField; var currVal_1 = _co.element.openingModuleId; _ck(_v, 2, 0, currVal_0, currVal_1); }, null); }
export function View_ElementModuleElementGridComponent_0(_l) { return i0.ɵvid(2, [(_l()(), i0.ɵand(16777216, null, null, 1, null, View_ElementModuleElementGridComponent_1)), i0.ɵdid(1, 16384, null, 0, i6.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ElementModuleElementGridComponent_2)), i0.ɵdid(3, 16384, null, 0, i6.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ElementModuleElementGridComponent_3)), i0.ɵdid(5, 16384, null, 0, i6.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.element.openingModuleId; _ck(_v, 1, 0, currVal_0); var currVal_1 = (((_co.element.openingModuleId && _co.entity) && _co.entity.id) && _co.element.datamodelField); _ck(_v, 3, 0, currVal_1); var currVal_2 = (_co.element.openingModuleId && (!_co.entity || !_co.entity.id)); _ck(_v, 5, 0, currVal_2); }, null); }
export function View_ElementModuleElementGridComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "app-form-element-module-element-grid", [], null, null, null, View_ElementModuleElementGridComponent_0, RenderType_ElementModuleElementGridComponent)), i0.ɵdid(1, 6012928, null, 1, i7.ElementModuleElementGridComponent, [i8.FormService, i0.ChangeDetectorRef, i9.FormViewerService], null, null), i0.ɵqud(603979776, 1, { insideNgContent: 0 })], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ElementModuleElementGridComponentNgFactory = i0.ɵccf("app-form-element-module-element-grid", i7.ElementModuleElementGridComponent, View_ElementModuleElementGridComponent_Host_0, { form: "form", formGroup: "formGroup", entity: "entity", element: "element", moduleElement: "moduleElement", selectedElement: "selectedElement", showDialogAddAddress: "showDialogAddAddress", actionParams: "actionParams", editMode: "editMode" }, { selectedElementChange: "selectedElementChange", showDialogAddAddressChange: "showDialogAddAddressChange", actionParamsChange: "actionParamsChange" }, []);
export { ElementModuleElementGridComponentNgFactory as ElementModuleElementGridComponentNgFactory };
