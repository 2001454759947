/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../services/toolbar-item-form-entity.service";
import * as i2 from "../../../../../../services/api.builder.service";
import * as i3 from "./toolbar-item-form-counter.component";
var styles_ToolbarItemFormCounter = [];
var RenderType_ToolbarItemFormCounter = i0.ɵcrt({ encapsulation: 2, styles: styles_ToolbarItemFormCounter, data: {} });
export { RenderType_ToolbarItemFormCounter as RenderType_ToolbarItemFormCounter };
export function View_ToolbarItemFormCounter_0(_l) { return i0.ɵvid(2, [], null, null); }
export function View_ToolbarItemFormCounter_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "toolbar-item-form-counter", [], null, null, null, View_ToolbarItemFormCounter_0, RenderType_ToolbarItemFormCounter)), i0.ɵprd(512, null, i1.ToolbarItemFormEntityService, i1.ToolbarItemFormEntityService, [i2.ApiBuilderService]), i0.ɵdid(2, 245760, null, 0, i3.ToolbarItemFormCounter, [i1.ToolbarItemFormEntityService], null, null)], function (_ck, _v) { _ck(_v, 2, 0); }, null); }
var ToolbarItemFormCounterNgFactory = i0.ɵccf("toolbar-item-form-counter", i3.ToolbarItemFormCounter, View_ToolbarItemFormCounter_Host_0, { params: "params" }, {}, []);
export { ToolbarItemFormCounterNgFactory as ToolbarItemFormCounterNgFactory };
