import { ElementInput } from './element';

export class ElementInputPassword extends ElementInput {

  placeholder: string;

  constructor() {
    super();
    this.typeElement = 'password';
    this.defaultValue = undefined;
    this.placeholder = undefined;
  }
}
