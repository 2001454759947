
import {map} from 'rxjs/operators';
import {Observable} from 'rxjs';
import {AbstractExecutionStep} from '../../../../../core/executor/abstract-execution-step';
import {ExecutionStepStatus} from '../../../../../core/executor/execution-step-status';
import {ExpertSearch} from '../../../../services/expert-search/expert-search';
import {Entity} from '../../../../helpers/entity';

export class GetExpertSearchExecutionStep extends AbstractExecutionStep {

  public doExecute(): Observable<ExecutionStepStatus> {
    const expertSearch: ExpertSearch = this.getPayload().getValue().expertSearch;

    if (!expertSearch) {
      return this.getFailObservable('ExpertSearch not found!');
    }

    return this.genericCrudService.getEntity('app/expertsearches', expertSearch.id).pipe(
      map((aExpertSearch: ExpertSearch) => {
        Entity.extractEmbedded(aExpertSearch);

        aExpertSearch.containers = aExpertSearch.containers || [];

        for (const container of aExpertSearch.containers) {
          container.fields = container.fields || [];

          for (const field of container.fields) {
            field.fieldValue = JSON.parse(field.fieldValue);
            field.parameters = JSON.parse(field.parameters);
          }
        }

        return { status: true, content: aExpertSearch };
      }));
  }
}
