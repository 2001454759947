/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./toolbar-item-take-address-to-master.component";
import * as i2 from "../../../../../../../core/message/message-growl.service";
import * as i3 from "../../../../../../../core/message/message.service";
import * as i4 from "../../../../../../services/generic-crud.service";
import * as i5 from "../../../../../services/entity-data-store.service";
import * as i6 from "../../../../../services/component-highlight-stack.service";
import * as i7 from "@ngx-translate/core";
import * as i8 from "../../../../generic-dialog/service/generic-dialog-module.service";
import * as i9 from "../../../../../services/modules-state.service";
import * as i10 from "../../../../../../../core/service/entity-manager/entity-manager.service";
var styles_ToolbarItemTakeAddressToMasterComponent = [];
var RenderType_ToolbarItemTakeAddressToMasterComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_ToolbarItemTakeAddressToMasterComponent, data: {} });
export { RenderType_ToolbarItemTakeAddressToMasterComponent as RenderType_ToolbarItemTakeAddressToMasterComponent };
export function View_ToolbarItemTakeAddressToMasterComponent_0(_l) { return i0.ɵvid(2, [], null, null); }
export function View_ToolbarItemTakeAddressToMasterComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-toolbar-item-take-address-to-master", [], null, null, null, View_ToolbarItemTakeAddressToMasterComponent_0, RenderType_ToolbarItemTakeAddressToMasterComponent)), i0.ɵdid(1, 245760, null, 0, i1.ToolbarItemTakeAddressToMasterComponent, [i2.MessageGrowlService, i3.MessageService, i4.GenericCrudService, i5.EntityDataStoreService, i6.ComponentService, i7.TranslateService, i8.GenericDialogModuleService, i9.ModulesStateService, i10.EntityManagerService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ToolbarItemTakeAddressToMasterComponentNgFactory = i0.ɵccf("app-toolbar-item-take-address-to-master", i1.ToolbarItemTakeAddressToMasterComponent, View_ToolbarItemTakeAddressToMasterComponent_Host_0, { params: "params" }, {}, []);
export { ToolbarItemTakeAddressToMasterComponentNgFactory as ToolbarItemTakeAddressToMasterComponentNgFactory };
