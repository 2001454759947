import {map} from 'rxjs/operators';
import {Design} from '../../../models/design';
import {StyleService} from '../style.service';
import {ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {ConfirmationService, MenuItem} from 'primeng/primeng';
import {Subscription} from 'rxjs';

import {LanguageService} from '../../shared/services/language/language.service';
import {AuthenticationService} from '../authentication/authentication.service';

import {CountryCodesEnum, LanguageCodeEnum, LanguagesEnum} from '../../languages.enum';
import {environment} from '../../../environments';
// @todo entity sharing service looks like the same of core/crudservice. Check it anytime...
import {EntitySharingService} from '../../shared/content-renderer/services/entity-sharing.service';
import {TranslateService} from '@ngx-translate/core';
import {Organisation} from '../../shared/services/organisation/organisation';
import {OrganisationCrudService} from '../../shared/services/organisation/organisation-crud.service';
import {LocalStorageDataService} from '../../shared/services/local-storage-data.service';
import {Branch} from '../../shared/services/branch/branch';
import {GenericCrudService} from '../../shared/services/generic-crud.service';
import {PermissionService} from '../../shared/services/permission/permission.service';
import {EntityDirtyStoreService} from '../../shared/content-renderer/services/entity-dirty-store.service';
import {Constants} from '../../constants';
import {RequestCachingService} from '../../shared/services/request-caching.service';
import {ExecutorService} from '../executor/executor.service';
import {ExecutionStepFactoryService} from '../executor/factory/execution-step-factory.service';
import {ExecutionStepPayload} from '../executor/execution-step-payload';
import {LoadProductsBasedOnOrganisationExecutionStep} from './steps/load-products-based-on-organisation-execution.step';
import {LoadDesignBasedOnOrganisationExecutionStep} from './steps/load-design-based-on-organisation-execution.step';
import {ClearCacheExecutionStep} from './steps/clear-cache-execution.step';
import {SetLocalStorageLanguageExecutionStep} from './steps/set-local-storage-language-execution.step';
import {ReselectProductExecutionStep} from './steps/reselect-product-execution.step';
import {SetLocalStorageOrganisationExecutionStep} from './steps/set-local-storage-organisation-execution.step';
import {UserSessionService} from '../service/user-session.service';
import {ProductCrudService} from '../../shared/services/product/product.crud.service';
import {HideDialogExecutionStep} from './steps/hide-dialog-execution-step';
import {ModulesStateService} from '../../shared/content-renderer/services/modules-state.service';
import {Group} from '../../shared/services/group/group';
import {CloseAllTabsExecutionStep} from './steps/close-all-tabs-execution.step';

@Component({
  selector: 'app-header',
  changeDetection: ChangeDetectionStrategy.Default,
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  providers: [ ExecutorService ]
})
export class HeaderComponent implements OnInit, OnDestroy {

  public menuLoading = false;
  public menuItemsLeft: MenuItem[] = [];
  public menuItemsRight: MenuItem[] = [];

  public branches: any[] | SelectItem[] = [];
  public selectedBranch: Branch;

  public organisations: Organisation[] | SelectItem[] = [];
  public selectedOrganisation: Organisation;

  public products: SelectItem[];
  public selectedProduct: Product;

  private selectedProductChanged$: EventEmitter<Product> = new EventEmitter<Product>();

  logoUrl = './assets/images/logo.png';
  logoPositionY: string;
  logoPositionX: string;

  environment;
  userLanguage;
  userCountryCode;

  languageKeys: any[];
  languages = LanguagesEnum;
  languageCodes = LanguageCodeEnum;

  countryCodeKeys: any[];
  countryCodes = CountryCodesEnum;
  // notificationItems: MenuItem[];
  // profileItems: MenuItem[];

  private subscriptions: Subscription[] = [];

  constructor(
    private styleService: StyleService,
    private genericCrudService: GenericCrudService,
    public authenticationService: AuthenticationService,
    private router: Router,
    private route: ActivatedRoute,
    private languageService: LanguageService,
    private entitySharingService: EntitySharingService,
    private translate: TranslateService,
    private organisationCrudService: OrganisationCrudService,
    private productCrudService: ProductCrudService,
    private localStorage: LocalStorageDataService,
    private entityDirtyStore: EntityDirtyStoreService,
    private translationService: TranslateService,
    public confirmationService: ConfirmationService,
    private permissionService: PermissionService,
    private requestCachingService: RequestCachingService,
    private executorService: ExecutorService,
    private stepsFactory: ExecutionStepFactoryService,
    private userSession: UserSessionService,
    private moduleStateService: ModulesStateService,
    private cdr: ChangeDetectorRef,
  ) { }

  ngOnInit() {
    this
      .loadProducts()
      .loadOrganisations();

    this.environment = environment;
    this.languageKeys = Object.keys(this.languages).filter(Number);
    this.countryCodeKeys = Object.keys(this.countryCodes).filter(Number);
    this.userLanguage = this.languageService.getLocalStorageLanguage();
    this.userCountryCode = this.countryCodes[this.languageCodes[this.userLanguage]];

    // this.profileItems = [
    //   { label: 'Angemeldet als {{authenticationService.currentUser.username}} am {{getDateNow() | date:"dd.MM.yyyy"}}' },
    //   { label: 'Sprache', icon: 'fa-language', items: [this.countryCodeKeys] },
    //   { label: 'Abmelden', icon: 'fa-sign-out', command: () => { this.authenticationService.logout(); } }
    // ];

    // @todo: There is a silly hack... at the moment the menu clears and loads every route change.
    // This is a performance leak cause we just have to load it in our first URL level (/:anyString/:productId).
    // At all childrens it is not necessary at the moment to change the menus
    // this.subscriptions[this.subscriptions.length - 1].unsubscribe();
    this.subscriptions.push(
      this.selectedProductChanged$.subscribe((selectedProduct) => {
        if (selectedProduct) {
          this.clearMenuItems();
          this.menuLoading = true;
          this.entitySharingService.fetchEntities('superadmin/products/' + selectedProduct.id + '/menus/menu', {
            success: ((menus) => {
              this.clearMenuItems();
              this.setMenusToMenuItems(menus);
            })
          });
        }
      })
    );

    this.subscriptions.push(
      this.styleService.designLogoChanged.subscribe((design: Design) => {
        if (design) {
          this.logoUrl = design.settings.header.logo && design.settings.header.logo.url || './assets/images/logo.png';
          this.logoPositionY = design.settings.header.logo && design.settings.header.logo.positionY || undefined;
          this.logoPositionX = design.settings.header.logo && design.settings.header.logo.positionX || undefined;
          this.cdr.detectChanges();
        } else {
          this.logoUrl = './assets/images/logo.png';
          this.logoPositionY = undefined;
          this.logoPositionX = undefined;
        }
      })
    );
  }

  loadProducts() {
    this.products = [{ label: 'Produkte werden geladen', value: undefined }];

    this.subscriptions.push(
      this.genericCrudService
        .getEntities('superadmin/products')
        .switchMap((products) => {
          return this.productCrudService.getUserDefaultProduct(this.authenticationService.currentUser.username).pipe(
            map((defaultProduct) => {
              const selectItems: SelectItem[] = [{ label: 'Bitte wählen', value: undefined }];

              products = products
                .filter((product) => (product.isValid && this.permissionService.isGranted(product, 'view')));
              products
                .map((product: Product) => selectItems.push({ label: product.name, value: product }));

              return {products: selectItems, defaultProduct: defaultProduct};
            })
          );
        })
        .subscribe((data) => {
          this.products = data.products;
          const urlSplit = this.router.url.split('/').filter((val) => (val !== ''));
          let urlProduct = null;
          if (urlSplit.length >= 2) {
            const productId = +urlSplit[1];
            urlProduct = this.products.find( (aProduct) => { return aProduct.value && aProduct.value.id === productId; });
            urlProduct = urlProduct ? urlProduct.value : null;
          }
          if (urlProduct && !this.selectedProduct) {
            this.selectedProduct = urlProduct;
          }
          if (urlProduct && ((this.selectedProduct && urlProduct.id !== this.selectedProduct.id))) {
            this.selectedProduct = urlProduct;
            this.onChangeProduct(
              this.selectedProduct ? { label: this.selectedProduct.name, value: this.selectedProduct } : this.products[0]
            );
          }
          if ((this.selectedProduct && !this.checkProductAvailability(this.selectedProduct)) || !this.selectedProduct) {
            this.selectedProduct = data.defaultProduct;
            this.onChangeProduct(
              this.selectedProduct ? { label: this.selectedProduct.name, value: this.selectedProduct } : this.products[0]
            )
          }
          this.selectedProductChanged$.next(this.selectedProduct);
        })
    );

    return this;
  }

  private checkProductAvailability(selectedProduct: Product) {
    if (selectedProduct === null) {
      return true;
    }
    for (const product of this.products) {
      if (selectedProduct.name === product.label) {
        return true;
      }
    }
    return false;
  }

  private loadOrganisations() {
    const localStorageOrganisation = this.userSession.get(Organisation.LOCAL_STORAGE_NAME);

    this.subscriptions.push(
      this.organisationCrudService.getUserOrganisations(this.authenticationService.currentUser.username, {embedded: 'none'}).subscribe(
        (organisations) => {

          // if there is just one organisation, show that one as text, no options to choose here...
          if (organisations.length === 1) {
            this.selectedOrganisation = organisations.find((org) => { return org; });
          }

          this.organisations = organisations;

          if (localStorageOrganisation) {
            this.selectedOrganisation = localStorageOrganisation;

            this.loadBranchOffices(this.selectedOrganisation);
          }
        })
    );
  }

  private loadBranchOffices(organisation: Organisation) {
    const userGroup = this.userSession.get(Group.LOCAL_STORAGE_NAME);
    if (userGroup === null || userGroup.name === 'Admin') {
      this.branches = [
        {
          name: 'Alle',
          id: 'all'
        }
      ];
    }

    const localStorageBranch = this.userSession.get(Branch.LOCAL_STORAGE_NAME);

    this.selectedBranch = null;

    this.genericCrudService.getEntities(`${Constants.APP_API_ROUTE}/userbranchoffices/mybranchoffices/organisation/` + organisation.id, null, {embedded: 'branchOffice'}).subscribe(
      (userBranchOffices) => {

        for (const userBranchOffice of userBranchOffices) {
          if (userBranchOffice._embedded && userBranchOffice._embedded['branchOffice']) {

            const branchOffice = userBranchOffice._embedded['branchOffice'];

            if (userBranchOffice.isMainOffice) {
              this.selectedBranch = this.selectedBranch || branchOffice;
              this.branches = [branchOffice, ...this.branches];
              this.sortBranches();
            } else {
              this.branches = [...this.branches, branchOffice];
              this.sortBranches();
            }

            if (localStorageBranch && localStorageBranch.id === branchOffice.id) {
              this.selectedBranch = branchOffice;
            }
          }
        }

        if (!this.selectedBranch && this.branches.length > 0) {
          this.selectedBranch = this.branches[0];
        }

        this.onChangeBranch({ value: this.selectedBranch }, false).setUserBranch(this.selectedBranch);
      });
  }

  private setUserBranch(userBranch) {
    this.authenticationService.currentUser.defaultBranch = userBranch;
  }

  onChangeSelectedProduct(selectedProduct: Product) {
    this.clearMenuItems();
    this.selectedProductChanged$.next(this.selectedProduct);
  }

  private sortBranches() {
    this.branches.sort(function (a, b) {
      if (a.name === b.name) { return 0; }
      if (a.name === 'Alle') { return -1; }
      if (b.name === 'Alle') { return 1; }
      if (a.name > b.name) {
        return 1;
      } else {
        return -1;
      }
    });
  }

  onChangeProduct(event) {
    if (event.value && event.value.plugin) {
      const nameSafe = event.value.plugin.replace(/[^a-z0-9]/gi, '-').toLowerCase();
      this.router.navigate([`${nameSafe}/${event.value.id}`]);
    } else {
      this.router.navigate(['']);
    }

    this.executorService
      .setSteps([
        this.stepsFactory.create(HideDialogExecutionStep, new ExecutionStepPayload({}))
      ])
      .execute()
      .subscribe();
  }

  onChangeOrganisation(event) {
    const organisation = event.value;

    if (!this.entityDirtyStore.isEmpty()) {
      this.confirmationService.confirm({
        acceptVisible: true,
        header: this.translationService.instant('DIALOG_MESSAGES.UNSAVED_CHANGES_SAVE_QUESTION_HEADER'),
        message: this.translationService.instant('DIALOG_MESSAGES.UNSAVED_CHANGES_DISCARD_QUESTION_BODY'),
        icon: 'fa fa-trash',
        accept: () => {
          this.doChangeOrganisation(organisation);
        },
        reject: () => {
          this.selectedOrganisation = this.userSession.get(Organisation.LOCAL_STORAGE_NAME);
        }
      });
    } else {
      this.doChangeOrganisation(organisation);
    }
  }

  private doChangeOrganisation(organisation: Organisation) {
    this.loadBranchOffices(organisation);

    if (organisation) {
      const payload = new ExecutionStepPayload({
        component: this,
        organisation: organisation
      });

      this.executorService
        .setSteps([
          this.stepsFactory.create(HideDialogExecutionStep, payload),
          this.stepsFactory.create(ClearCacheExecutionStep, payload),
          this.stepsFactory.create(SetLocalStorageOrganisationExecutionStep, payload),
          this.stepsFactory.create(LoadProductsBasedOnOrganisationExecutionStep, payload),
          this.stepsFactory.create(LoadDesignBasedOnOrganisationExecutionStep, payload)
        ])
        .execute()
        .subscribe();
    } else {
      this.styleService.designChanged.next({
        design: null
      });
      this.userSession.remove(Organisation.LOCAL_STORAGE_NAME);
      this.executorService
        .setSteps([
          this.stepsFactory.create(HideDialogExecutionStep, new ExecutionStepPayload({}))
        ])
        .execute()
        .subscribe();
    }

    this.loadProducts();
  }

  public onChangeBranch(event, reloadPage) {
    const branch = event.value;

    if (branch) {
      this.userSession.set(Branch.LOCAL_STORAGE_NAME, {
        value: branch
      });

      if (reloadPage) {
        const payload = new ExecutionStepPayload({
          component: this,
          product: this.selectedProduct
        });
        this.executorService
          .setSteps([
            this.stepsFactory.create(HideDialogExecutionStep, payload),
            this.stepsFactory.create(ClearCacheExecutionStep, payload),
            this.stepsFactory.create(CloseAllTabsExecutionStep, payload),
            this.stepsFactory.create(ReselectProductExecutionStep, payload),
          ])
          .execute()
          .subscribe();
      }
    } else {
      this.userSession.remove(Branch.LOCAL_STORAGE_NAME);
      this.executorService
        .setSteps([
          this.stepsFactory.create(HideDialogExecutionStep, new ExecutionStepPayload({}))
        ])
        .execute()
        .subscribe();
    }

    return this;
  }

  clickLogout(event) {
    this.entityDirtyStore.clear();
    this.requestCachingService.removeAllRequestResponses();
    this.entitySharingService.clear();
    this.moduleStateService.clear();

    event.preventDefault();
    this.authenticationService.logout();
  }

  selectLanguage(event, countryCode): void {
    const payload = new ExecutionStepPayload({
      component: this,
      product: this.selectedProduct,
      countryCode: countryCode
    });

    this.executorService
      .setSteps([
        this.stepsFactory.create(HideDialogExecutionStep, payload),
        this.stepsFactory.create(ClearCacheExecutionStep, payload),
        this.stepsFactory.create(SetLocalStorageLanguageExecutionStep, payload),
        this.stepsFactory.create(ReselectProductExecutionStep, payload)
      ])
      .execute()
      .subscribe();
  }

  getLogoUrl() {
    return this.logoUrl;
  }

  getLogoPositionVertical() {
    if (this.logoPositionY === 'center') {
      return 'center';
    }
    if (this.logoPositionY === 'bottom') {
      return 'flex-end';
    }
    return 'flex-start';
  }

  getLogoPositionHorizontal() {
    if (this.logoPositionX === 'center') {
      return 'center';
    }
    if (this.logoPositionX === 'right') {
      return 'flex-end';
    }
    return 'flex-start';
  }

  getDateNow() {
    return new Date();
  }

  setMenusToMenuItems(menus: any[]) {
    this.menuLoading = false;
    menus.map((menu) => {
      if (menu.isLeft) {
        this.menuItemsLeft.push(menu);
      } else {
        this.menuItemsRight.push(menu);
      }
    });
  }

  clearMenuItems() {
    this.menuItemsLeft = [];
    this.menuItemsRight = [];
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }
}
