
import {of as observableOf, Observable} from 'rxjs';
import {TranslateService} from '@ngx-translate/core';
import {AbstractGenericGridComponent} from '../../../content-renderer/elements/abstract-generic-grid.component';
import {FormViewerComponent} from '../../../form-viewer/form-viewer.component';
import {OpenCustomerDetailsModuleExecutionStep} from '../customer/open-customer-details-module-execution-step';
import {ModuleContentBlockerService} from '../../../../core/service/module-content-blocker/module-content-blocker.service';

export class OpenCollectiveAgreementDetailsModuleExecutionStep extends OpenCustomerDetailsModuleExecutionStep {

  protected doOpenModuleInGridContext(component: AbstractGenericGridComponent|FormViewerComponent) {

    const blocker: ModuleContentBlockerService = this.injector.get(ModuleContentBlockerService, null),
      translateService: TranslateService = this.injector.get(TranslateService, null);

    blocker.start(translateService.instant('COMMON.LOADING_PLEASE_WAIT'));

    this.removeCurrentModules()
      .clearCache('phoenix/collectiveagreements')
      .clearValidationsCache()
      .openNewModule(component);

    return observableOf({status: true, content: null});
  }
}
