<button
  pButton
  (click)="onOpenModule()"
  class="module-opener-button"
>Mitarbeiterübersicht</button>

<!--<button
  [style.marginTop.px]="10"
  pButton
  (click)="onOpenModuleTwo()"
  class="module-opener-button"
>Aktivitäten Plan / Ist</button>-->
