<p-dialog
  #dialog
  *ngIf="isDialogVisible"
  appendTo="body"
  header="Audit"
  [(visible)]="isDialogVisible"
  [responsive]="true"
  showEffect="fade"
  [modal]="true"
  [minWidth]="800"
  [height]="600"
  [minHeight]="600"
  [width]="800"
  [maximizable]="true"
  (onShow)="onShow()"
  styleClass="audit-dialog"
>
  <app-element-audit
    [entity]="entity"
    [element]="element"
  ></app-element-audit>
</p-dialog>
