import { SharedModule } from '../shared/shared.module';
import {
  NgModule,
  ModuleWithProviders,
  Optional,
  SkipSelf, ErrorHandler
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import {
  MenubarModule,
  ConfirmDialogModule,
  GrowlModule
} from 'primeng/primeng';

import { CoreProviders } from './core.providers';
import { CoreComponents } from './core.components';

import { AuthenticationGuard } from './authentication/authentication.guard';
import { AuthenticationService } from './authentication/authentication.service';
import { MessageService } from './message/message.service';
import { MessageGrowlService } from './message/message-growl.service';
import { MenuCommunicationService } from './header/services/header-menu-communication.service';
import {HttpClientModule} from '@angular/common/http';
import { BasicErrorHandler } from './error/basic-error-handler';
import {environment} from '../../environments';

const providers: any = [
  ...CoreProviders
];

if (!environment.consoleErrorReporting) {
  providers.push({
    provide: ErrorHandler,
    useClass: BasicErrorHandler,
  });
}

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    HttpClientModule,
    RouterModule,
    ConfirmDialogModule,
    GrowlModule,
    MenubarModule
  ],
  declarations: [
    ...CoreComponents
  ],
  exports: [
    ...CoreComponents
  ],
  providers: providers
})

export class CoreModule {

  static forRoot(): ModuleWithProviders {
    return {
      ngModule: CoreModule,
      providers: [
        AuthenticationGuard,
        AuthenticationService,
        MessageService,
        MessageGrowlService,
        MenuCommunicationService
        // ,
        // HeaderProductDetailResolve
        // ,
        // ProductDetailResolveService
      ]
    };
  }

  // @Optional() private languageService: LanguageService,
  constructor(
    @Optional() @SkipSelf() parentModule: CoreModule
  ) {
    if (parentModule) {
      throw new Error('CoreModule is already loaded. Import it in the AppModule only');
    }
  }
}
