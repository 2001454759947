import {Input, OnInit} from '@angular/core';
import {Question, Questionnaire, QuestionParameter} from '../../../../../../services/element/questionnaire';
import {QuestionnaireComponent} from "../../questionnaire.component";

export abstract class AbstractQuestionComponent implements OnInit {
  @Input() question: Question = null;
  @Input() questionnaire: Questionnaire = null;
  @Input() questionnaireComponent: QuestionnaireComponent = null;

  public abstract onComponentInit();

  public ngOnInit(): void {
    this.question.questionParameters = this.question.questionParameters || [];
  }

  protected getParam(key: string): QuestionParameter|null {
    let foundParam = null;

    for (const param of this.question.questionParameters) {
      if (param.name === key) {
        foundParam = param;
        break;
      }
    }

    return foundParam;
  }

  protected getParamValue(key: string): any|null {
    const param = this.getParam(key);

    return param && param.value ? param.value : null;
  }
}
