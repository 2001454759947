
import {catchError, publishReplay, refCount} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AppHttpService } from '../../../app-http.service';
import { DataModelFieldField } from './datamodelfield.field';
import { GenericCrudService } from '../generic-crud.service';

@Injectable()
export class DatamodelFieldFieldCrudService extends AppHttpService {

  private cachedDatamodels = null;
  private datamodelsUrl = 'superadmin/datamodelfields';

  constructor(private genericCrudService: GenericCrudService) {
    super();
  }

  /**
   * @argument {string} format Optional. If not set, then the entities are returned as is.
   *                            Selectable formats: formenu (MenuItem), fortree (TreeNode).
   */
  getDatamodelFields(datamodelId: number): Observable<DataModelFieldField[]> {
    const url = this.datamodelsUrl + '/' + datamodelId + '/fields';
    return this.genericCrudService.get(url).pipe(
      publishReplay(1),
      refCount(),
      catchError(this.handleError) );
  }

  /**
   * @argument {number} datamodelId The id of the datamodel to request.
   * @argument {string} format Optional. If not set, then the entities are returned as is.
   */
  getDatamodelField(id: number, datamodelId: number): Observable<DataModelFieldField> {
    const url = this.datamodelsUrl + '/' + datamodelId + '/fields/' + id;
    return this.genericCrudService.get(url).pipe(
      publishReplay(1),
      refCount(),
      catchError(this.handleError) );
  }

  deleteDatamodelField(id: number, datamodelId: number): Observable<any> {
    if (id > 0 && datamodelId > 0) {
      const url = this.datamodelsUrl + '/' + datamodelId + '/fields/' + id;
      return this.genericCrudService.deleteEntity(url).pipe(catchError(this.handleError));
    }
    throw new Error('Invalid datamodel id given.');
  }

  editDatamodelField(datamodelId: number, datamodelField: DataModelFieldField): Observable<DataModelFieldField> {
    if (datamodelField) {
      const url = this.datamodelsUrl + '/' + datamodelId + '/fields/' + datamodelField.id;
      return this.genericCrudService.editEntity(url, datamodelField).pipe(
        catchError(this.handleError));
    }
    throw new Error('No datamodel given.');
  }

  editDatamodelFields(datamodelId: number, datamodelFields): Observable<DataModelFieldField[]> {
    if (datamodelFields) {
      const url = this.datamodelsUrl + '/' + datamodelId + '/fields';
      return this.genericCrudService.editEntity(url, datamodelFields).pipe(
        catchError(this.handleError));
    }

    throw new Error('No datamodel given.');
  }
}
