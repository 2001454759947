<div class="ui-grid-row">
  <div class="ui-grid-col-2">
    {{ 'COMMON.NAME' | translate }} *:
  </div>
  <div class="ui-grid-col-6">
    <input type="text" pInputText placeholder="Minimum 3 characters..." [(ngModel)]="companyName"/>
  </div>
</div>

<div class="ui-grid-row">
  <div class="ui-grid-col-10">

  </div>
  <div class="ui-grid-col-2">
    <button pButton type="button" [disabled]="!companyName || companyName.length < 3" (click)="onSearch()" label="{{ 'COMMON.SEARCH' | translate }}"></button>
  </div>
</div>

<div *ngIf="isDataLoading">
  {{ 'COMMON.LOADING_PLEASE_WAIT' | translate }}
</div>

<div *ngIf="companies.length === 0 && !isDataLoading">
  {{ 'COMMON.NO_RECORDS_FOUND' | translate }}
</div>

<p-table
  *ngIf="companies.length > 0 && !isDataLoading"
  [value]="companies"
  selectionMode="single"
  [loading]="isDataLoading"
  scrollable="true"
  scrollHeight="400px"
>
  <ng-template pTemplate="header">
    <tr>
      <th pResizableColumn>
        <p-sortIcon [field]="'name'"></p-sortIcon>
        {{ 'COMMON.NAME' | translate }}
      </th>
      <th pResizableColumn>
        <p-sortIcon [field]="'ksvNumber'"></p-sortIcon>
        {{ 'KSV.KSV_NUMBER' | translate }}
      </th>
      <th pResizableColumn>
        <p-sortIcon [field]="'country'"></p-sortIcon>
        {{ 'COMMON.COUNTRY' | translate }}
      </th>
      <th pResizableColumn>
        <p-sortIcon [field]="'city'"></p-sortIcon>
        {{ 'COMMON.CITY' | translate }}
      </th>
      <th pResizableColumn>
        <p-sortIcon [field]="'postalCode'"></p-sortIcon>
        {{ 'COMMON.POSTAL_CODE' | translate }}
      </th>
      <th pResizableColumn>
        <p-sortIcon [field]="'name'"></p-sortIcon>
        {{ 'COMMON.CITY' | translate }}
      </th>
      <th pResizableColumn>
        <p-sortIcon [field]="'street'"></p-sortIcon>
        {{ 'COMMON.STREET' | translate }}
      </th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-company>
    <tr (dblclick)="onRowDoubleClick(company)" [pSelectableRowDblClick]="company">
      <td>{{company.name}}</td>
      <td>{{company.ksvNumber}}</td>
      <td>{{company.country}}</td>
      <td>{{company.city}}</td>
      <td>{{company.postalCode}}</td>
      <td>{{company.name}}</td>
      <td>{{company.street}}</td>
    </tr>
  </ng-template>
</p-table>
