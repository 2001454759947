import { AbstractFormActionHandler } from './abstract-form-action-handler';
import { Injectable } from '@angular/core';
import { DatamodelCrudService } from '../../../services/datamodel/datamodel.crud.service';
import { ElementInputDropdown } from 'app/shared/form-viewer/models/element-input-dropdown';

@Injectable()
export class SetFieldValueFromFieldOptions extends AbstractFormActionHandler {

    public constructor(
        private datamodelCrudService: DatamodelCrudService
    ) {
        super();
    }

    public handleAction(): void {
        let form = this.getForm();

        if(this.isActionValid()) {
            let formElementHashId = this.getParamValue('formElement'),
                optionValue = this.getParamValue('formElementOptionValue'),
                fieldElement = this.formService.getElementBy(form, 'objectHashId', formElementHashId);

            let controlField = this.getFormGroup().get(fieldElement.datamodelField + '_h_r_f_e_' + formElementHashId);

            if (fieldElement && controlField) {
                let value = optionValue.value;

                // element-input-dropdown.component sets values as { id: value }
                if (fieldElement instanceof ElementInputDropdown) {
                    value = { id: value };
                }

                fieldElement.setValue(value);
                controlField.setValue(value);
            }
        }
          
    }

    private isActionValid(): boolean {
        return this.getAction().params['formElement'] && 
            this.getAction().params['formElement'].value &&
            this.getAction().params['formElementOptionValue'] && 
            this.getAction().params['formElementOptionValue'].value
    }
}
