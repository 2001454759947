
    <div class="ui-g">
      <div class="ui-g ui-g-10">
        <p-autoComplete
          field="label"
          [(ngModel)]="selectedValues"
          [multiple]="true"
          [suggestions]="dropdownOptions"
          (completeMethod)="onCompleteMethod($event)"
          (onKeyUp)="onKeyUp($event)"
          (onSelect)="onSelect($event)"
          (onUnselect)="onUnSelect($event)"
          [dropdown]="true"
          [style]="{'width': '100%'}"
          [size]="30"
          [minLength]="2"
          placeholder="---"
          appendTo="body"
        ></p-autoComplete>
      </div>

      <div class="ui-g ui-g-2">
        <p-toggleButton
          [(ngModel)]="fieldComparator"
          (onChange)="onComparatorChange()"
          onLabel="Any"
          offLabel="All"
          onIcon="pi pi-check"
          offIcon="pi pi-times"
          [style]="{'width':'50px'}"
        ></p-toggleButton>
      </div>
    </div>
  