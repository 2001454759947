/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "primeng/components/dom/domhandler";
import * as i2 from "primeng/components/button/button";
import * as i3 from "../../../../../p-button/p-button.directive";
import * as i4 from "./working-hour-action-column.component";
import * as i5 from "../../../../services/double-click.service";
import * as i6 from "../../../../../services/generic-crud.service";
import * as i7 from "../../../../services/generic/generic-element-embedded.service";
import * as i8 from "../../../../../../core/service/toast.service";
import * as i9 from "@ngx-translate/core";
var styles_WorkingHourActionColumnComponent = [];
var RenderType_WorkingHourActionColumnComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_WorkingHourActionColumnComponent, data: {} });
export { RenderType_WorkingHourActionColumnComponent as RenderType_WorkingHourActionColumnComponent };
export function View_WorkingHourActionColumnComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 16, "table", [], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 15, "tr", [], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 4, "td", [], [[8, "hidden", 0]], null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 3, "button", [["icon", "fa fa-plus"], ["pButton", ""]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.copyWorkEntry($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵprd(4608, null, i1.DomHandler, i1.DomHandler, []), i0.ɵdid(5, 4341760, null, 0, i2.ButtonDirective, [i0.ElementRef], { icon: [0, "icon"] }, null), i0.ɵdid(6, 4341760, null, 0, i3.PButtonDirective, [i0.ElementRef], { icon: [0, "icon"] }, null), (_l()(), i0.ɵeld(7, 0, null, null, 4, "td", [], [[8, "hidden", 0]], null, null, null, null)), (_l()(), i0.ɵeld(8, 0, null, null, 3, "button", [["icon", "fa fa-minus"], ["pButton", ""]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.removeWorkEntry($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵprd(4608, null, i1.DomHandler, i1.DomHandler, []), i0.ɵdid(10, 4341760, null, 0, i2.ButtonDirective, [i0.ElementRef], { icon: [0, "icon"] }, null), i0.ɵdid(11, 4341760, null, 0, i3.PButtonDirective, [i0.ElementRef], { icon: [0, "icon"] }, null), (_l()(), i0.ɵeld(12, 0, null, null, 4, "td", [], [[8, "hidden", 0]], null, null, null, null)), (_l()(), i0.ɵeld(13, 0, null, null, 3, "button", [["icon", "fa fa-lock"], ["pButton", ""]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.lockWorkEntry($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵprd(4608, null, i1.DomHandler, i1.DomHandler, []), i0.ɵdid(15, 4341760, null, 0, i2.ButtonDirective, [i0.ElementRef], { icon: [0, "icon"] }, null), i0.ɵdid(16, 4341760, null, 0, i3.PButtonDirective, [i0.ElementRef], { icon: [0, "icon"] }, null)], function (_ck, _v) { var currVal_1 = "fa fa-plus"; _ck(_v, 5, 0, currVal_1); var currVal_2 = "fa fa-plus"; _ck(_v, 6, 0, currVal_2); var currVal_4 = "fa fa-minus"; _ck(_v, 10, 0, currVal_4); var currVal_5 = "fa fa-minus"; _ck(_v, 11, 0, currVal_5); var currVal_7 = "fa fa-lock"; _ck(_v, 15, 0, currVal_7); var currVal_8 = "fa fa-lock"; _ck(_v, 16, 0, currVal_8); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.isCopy() || _co.isSummary()); _ck(_v, 2, 0, currVal_0); var currVal_3 = (!_co.isCopy() || _co.isSummary()); _ck(_v, 7, 0, currVal_3); var currVal_6 = (_co.isLocked() || _co.isSummary()); _ck(_v, 12, 0, currVal_6); }); }
export function View_WorkingHourActionColumnComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-working-hour-column", [], null, null, null, View_WorkingHourActionColumnComponent_0, RenderType_WorkingHourActionColumnComponent)), i0.ɵdid(1, 114688, null, 0, i4.WorkingHourActionColumnComponent, [i0.ElementRef, i5.DoubleClickService, i6.GenericCrudService, i7.GenericElementEmbeddedService, i8.ToastService, i9.TranslateService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var WorkingHourActionColumnComponentNgFactory = i0.ɵccf("app-working-hour-column", i4.WorkingHourActionColumnComponent, View_WorkingHourActionColumnComponent_Host_0, { node: "node", entity: "entity", component: "component", column: "column" }, {}, []);
export { WorkingHourActionColumnComponentNgFactory as WorkingHourActionColumnComponentNgFactory };
