/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./toolbar-item-add-skills-to-leased-employee.component";
import * as i2 from "../../../../../../../services/generic-crud.service";
import * as i3 from "../../../../../../../../core/message/message-growl.service";
import * as i4 from "../../../../../../services/double-click.service";
import * as i5 from "../../../../../../services/modules-state.service";
import * as i6 from "../../../../../../services/navigation/module-navigation.service";
var styles_ToolbarItemAddSkillsToLeasedEmployeeComponent = [];
var RenderType_ToolbarItemAddSkillsToLeasedEmployeeComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_ToolbarItemAddSkillsToLeasedEmployeeComponent, data: {} });
export { RenderType_ToolbarItemAddSkillsToLeasedEmployeeComponent as RenderType_ToolbarItemAddSkillsToLeasedEmployeeComponent };
export function View_ToolbarItemAddSkillsToLeasedEmployeeComponent_0(_l) { return i0.ɵvid(2, [], null, null); }
export function View_ToolbarItemAddSkillsToLeasedEmployeeComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-toolbar-item-add-skills-to-leased-employee", [], null, null, null, View_ToolbarItemAddSkillsToLeasedEmployeeComponent_0, RenderType_ToolbarItemAddSkillsToLeasedEmployeeComponent)), i0.ɵdid(1, 245760, null, 0, i1.ToolbarItemAddSkillsToLeasedEmployeeComponent, [i2.GenericCrudService, i3.MessageGrowlService, i4.DoubleClickService, i5.ModulesStateService, i6.ModuleNavigationService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ToolbarItemAddSkillsToLeasedEmployeeComponentNgFactory = i0.ɵccf("app-toolbar-item-add-skills-to-leased-employee", i1.ToolbarItemAddSkillsToLeasedEmployeeComponent, View_ToolbarItemAddSkillsToLeasedEmployeeComponent_Host_0, { params: "params" }, {}, []);
export { ToolbarItemAddSkillsToLeasedEmployeeComponentNgFactory as ToolbarItemAddSkillsToLeasedEmployeeComponentNgFactory };
