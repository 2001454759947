
import {forkJoin as observableForkJoin, throwError as observableThrowError, of as observableOf, Observable, Subject} from 'rxjs';

import {catchError, map, takeUntil, tap} from 'rxjs/operators';
import {ChangeDetectorRef, Component, Input, ViewContainerRef} from '@angular/core';
import {ExecutorService} from '../../../../../core/executor/executor.service';
import {ElementSaveStatus, GenericElementAbstract} from '../../generic-element-abstract.component';
import {FieldMetadataGrid} from '../../../../services/module/module-element-field-metadata-grid';
import {ModuleElement} from '../../../../services/module/module-element';
import {EntityValidator, EntityValidatorStatus} from '../../../../validators/services/entity-validator';
import {Element} from '../../../../services/element/element';
import {GenericElementValidationExecutionStepsFactory} from '../../../services/generic/generic-element-validation-execution-steps-factory';
import {ComponentService} from '../../../services/component-highlight-stack.service';
import {ModulesStateService} from '../../../services/modules-state.service';
import {GenericCrudService} from '../../../../services/generic-crud.service';
import {EntityDataStoreService} from '../../../services/entity-data-store.service';
import {LocalStorageDataService} from '../../../../services/local-storage-data.service';
import {ToolbarItemCheckService} from '../../generic-toolbar/services/check/toolbar-item-check.service';
import {LayoutService} from '../../../../services/layout-service';
import {JobContainerService} from '../../../../../core/job-runner/job-container.service';
import {GenericElementFilterService} from '../../../services/generic/filter/generic-element-filter.service';
import {ElementContext, ElementType, MasterEntityConfig} from '../../../services/ElementContext';
import {LocationService} from '../../../../services/location.service';
import {ElementsStackService} from '../../../services/elements-stack.service';
import {Entity} from '../../../../helpers/entity';
import {EntityStatus} from '../../../../services/entity/entity-status';
import {MessageGrowlService} from '../../../../../core/message/message-growl.service';
import {Datamodel} from '../../../../services/datamodel/datamodel';
import {Guid} from 'guid-typescript';
import {ApiBuilderService} from '../../../../services/api.builder.service';
import {PermissionService} from '../../../../services/permission/permission.service';
import {EntityManagerService} from '../../../../../core/service/entity-manager/entity-manager.service';
import {ChangeDetectorRefHelper} from '../../../../helpers/change-detector-ref.helper';
import {UserSessionService} from '../../../../../core/service/user-session.service';
import {of} from 'rxjs/observable/of';
import {ExecutorActionsService} from '../../../../../core/executor/service/executor-actions/executor-actions.service';
import {ExecutorActionEvent} from '../../../../../core/executor/service/executor-actions/executor-action-event';
import {forkJoin} from 'rxjs';
import {EntityHydrator} from '../../../../services/entity-hydrator.service';
import {AutocompleteFilter} from '../../../../directives/generic-autocomplete-handler.directive';
import {ExecutionStepPayload} from '../../../../../core/executor/execution-step-payload';
import {ExecutionStepParameter, ExecutionStepParameterExecutor} from '../../../../../core/executor/execution-step-parameter';
import {ExecutionStatus} from '../../../../../core/executor/execution-status';
import {ExecutionStepFactoryService} from '../../../../../core/executor/factory/execution-step-factory.service';

interface FieldDefinitionTemplate {
  id: number;
  name: string;
  dataType: {
    code?: string;
  };
  lookupFetchDatamodel: Datamodel;
  lookupFetchDatamodelLabel: string;
  lookupFetchDatamodelSearchField: string;
  lookupFetchDatamodelFilter?: string;
  lookupFetchDatamodelFilterField?: string;
  embeddedFields?: string;
  embeddedTargetFieldValue?: string;
  embeddedTargetField?: string;
  customLookupFilter?: string;
  masterFormField?: string;
  masterFormFieldValue?: string;
  isReadonly?: boolean;
  width?: string;
  isVisibleInGlobalSearch?: boolean;
  _embedded: {
    dataType?: {
      code?: string;
    };
    lookupFetchDatamodel?: Datamodel;
  }
}

export interface MemoTypeFieldDefinition {
  id: number;
  name: string;
  header?: string;
  fieldDefinitionTemplate?: FieldDefinitionTemplate;
  _embedded?: {
    fieldDefinitionTemplate: FieldDefinitionTemplate;
  }
  searchValue?: any;
  readonlyValue?: string;
  searchMemoTypeFieldDefinitions?: MemoTypeFieldDefinition[];
  readonlyMemoTypeFieldDefinitions?: MemoTypeFieldDefinition[];
  lookupFetchDatamodelLabel?: string;
  lookupFetchDatamodelSearchField?: string;
  lookupFetchDatamodelFilter?: string;
  lookupFetchDatamodelFilterField?: string;
  lookupFetchDatamodelSortField?: string;
  embeddedFields?: string;
  embeddedTargetFieldValue?: string;
  embeddedTargetField?: string;
  masterFormField?: string;
  masterFormFieldValue?: string;
  customLookupFilter?: string;
  isReadonly?: boolean;
  isVisible?: boolean;
  onChangeAction?: string;
  remoteDataFilter?: string;
}

export interface MemoFieldDefinitionValue {
  id: number;
  owner: any;
  fieldDefinition: MemoTypeFieldDefinition;
  isChanged?: boolean;
  value: any;
  fqn: string;
  visible?: boolean;
  uniqueId?: string;
  _embedded: {
    fieldDefinition?: MemoTypeFieldDefinition;
  };
}

export class MemoTypeFieldDefinitionType {
  public static readonly CODE_DROPDOWN = 'dropdown';
  public static readonly CODE_AUTOCOMPLETE = 'autocomplete';
  public static readonly CODE_BRANCH_OFFICE = 'branchOffice';
  public static readonly CODE_LEASED_EMPLOYEE = 'leasedEmployee';
  public static readonly CODE_CUSTOMER = 'customer';
  public static readonly CODE_INTEGER = 'integer';
  public static readonly CODE_DECIMAL = 'decimal';
  public static readonly CODE_DATETIME = 'dateTime';
  public static readonly CODE_DATE = 'date';
  public static readonly CODE_DATE_FROM = 'dateFrom';
  public static readonly CODE_DATE_TO = 'dateTo';
  public static readonly CODE_CHECKBOX = 'checkbox';
  public static readonly CODE_TEXT = 'text';
  public static readonly SIMPLE_VALUES = [
    MemoTypeFieldDefinitionType.CODE_TEXT,
    MemoTypeFieldDefinitionType.CODE_DATE,
    MemoTypeFieldDefinitionType.CODE_DATETIME,
    MemoTypeFieldDefinitionType.CODE_CHECKBOX,
    MemoTypeFieldDefinitionType.CODE_DECIMAL,
    MemoTypeFieldDefinitionType.CODE_INTEGER,
  ];
}

interface SelectItemExtended {
  label: string;
  value: any;
  entity: any;
}

export const MEMO_EMBEDDED_ENTITIES = [
  'customerAddress',
  'contactPerson',
  'contactUser',
  'employeeAddress',
  'contactBranchOffice',
  'memoType'
];

@Component({
  selector: 'app-custom-memo-field-definition-value',
  styleUrls: ['./memo-field-definition-value.component.scss'],
  templateUrl: './memo-field-definition-value.component.html',
  providers: [
    ExecutorService,
    GenericElementValidationExecutionStepsFactory,
    GenericElementFilterService
  ]
})
export class MemoFieldDefinitionValueComponent extends GenericElementAbstract {
  @Input() element: Element;
  @Input() fields: Array<FieldMetadataGrid>;
  @Input() toolbarItems: any[] = [];
  @Input() statusBarItems: any[] = [];
  @Input() moduleElement: ModuleElement;
  @Input() masterEntity: any = null;
  @Input() masterField: any = null;
  @Input() isPart = false;

  public configuration = {
    ownerApi: 'phoenix/memos',
    fieldDefinitionValuesApi: 'phoenix/memofielddefinitionvalues',
    memoTypeFieldDefinitionsApi: 'phoenix/memotypefielddefinitions',
    memoTypeApi: 'phoenix/memotypes/offset/0/limit/50/orderby/name/asc',
    memoTypeRelation: 'memoType',
    memoTypeDefinedOutside: true,
    embeddedEntities: MEMO_EMBEDDED_ENTITIES,
    usesExternalRefresh: false,
    search: false,
  }

  public memoTypeFieldDefinitions: MemoTypeFieldDefinition[] = [];
  public visibleMemoTypeFieldDefinitions: MemoTypeFieldDefinition[] = [];
  public fieldDefinitionValues: MemoFieldDefinitionValue[] = [];

  public lookupFetchDatamodelOptions: { [id: number]: SelectItem[]; } = {};

  public selectedCustomerAddress: SelectItemExtended = null;
  public customerAddresses: SelectItemExtended[] = [];

  public selectedContactPerson: SelectItemExtended = null;
  public contactPersons: SelectItemExtended[] = [];

  public selectedContactUser: SelectItemExtended = null;
  public contactUsers: SelectItemExtended[] = [];

  public selectedEmployeeAddress: SelectItemExtended = null;
  public employeeAddresses: SelectItemExtended[] = [];

  public selectedContactBranchOffice: SelectItemExtended = null;
  public contactBranchOffices: SelectItemExtended[] = [];

  public memoDefinitionValuesUnsubscribe = new Subject();
  public memoTypeFieldDefinitionsUnsubscrube = new Subject();
  public memoUnsubscribe = new Subject();

  public memo = null;
  public memoType = null;

  protected allowedFqns = ['DmsBundle\\Entity\\DmsFile'];

  @Input() set entity(entity: any) {

    if (entity && entity.fqn !== this.getElementDatamodelEntityName() && this.allowedFqns.indexOf(entity.fqn) < 0) {
      return;
    }

    this.memoType = null;
    if (this.configuration.usesExternalRefresh) {
      return;
    }

    if (entity) {
      this.changeConfiguration(entity);
      this.memo = entity;
      this.memoType = Entity.getValue(this.memo, this.configuration.memoTypeRelation) || Entity.getValueInEmbedded(this.memo, this.configuration.memoTypeRelation);

      this.onRefresh()
        .pipe(
          tap(() => this.onRefreshed())
        )
        .subscribe(() => {
          ChangeDetectorRefHelper.detectChanges(this);
        });
    }

  };
  @Input() masterFilterField?: string;
  @Input() masterFilterValue?: string;
  @Input() isDialog = false;

  public elementType: ElementType = ElementType.MemoFieldDefinitionValue;
  public isLoadingData = false;
  public selectedSearch: 'type' | 'all' = 'all';

  public toolbarContextName = 'memoFieldDefinitionValue';

  public constructor(
    protected componentService: ComponentService,
    protected viewContainerRef: ViewContainerRef,
    protected modulesStateService: ModulesStateService,
    protected genericCrudService: GenericCrudService,
    protected entityDataStoreService: EntityDataStoreService,
    protected executorService: ExecutorService,
    protected genericElementValidationExecutionStepsFactory: GenericElementValidationExecutionStepsFactory,
    protected entityValidator: EntityValidator,
    protected userSession: UserSessionService,
    protected toolbarItemCheckService: ToolbarItemCheckService,
    protected layoutService: LayoutService,
    protected jobContainerService: JobContainerService,
    protected locationService: LocationService,
    protected elementsStackService: ElementsStackService,
    protected messageGrowlService: MessageGrowlService,
    protected apiBuilderService: ApiBuilderService,
    protected permissionService: PermissionService,
    protected entityManager: EntityManagerService,
    protected executorActionsService: ExecutorActionsService,
    protected entityHydratorService: EntityHydrator,
    protected stepFactory: ExecutionStepFactoryService,
    public cdr: ChangeDetectorRef
  ) {
    super(componentService, viewContainerRef, entityDataStoreService, modulesStateService, executorService,
      genericElementValidationExecutionStepsFactory, entityValidator, genericCrudService, userSession, permissionService,
      cdr);
  }

  public ngOnInit() {
    super.ngOnInit();

    this.onComponentInit();
  }

  public ngOnDestroy() {
    super.ngOnDestroy();

    this.onDestroyComponent();
  }

  public onComponentInit(): void {
    this.elementContext = this.createContext();
    this.executorActionsService
      .registerModuleElementActions(this.moduleElement)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe();
  }

  public onDestroyComponent(): void {
    this.subscriptions.forEach(s => s.unsubscribe());

    this.memoDefinitionValuesUnsubscribe.next();
    this.memoDefinitionValuesUnsubscribe.complete();

    this.memoTypeFieldDefinitionsUnsubscrube.next();
    this.memoTypeFieldDefinitionsUnsubscrube.complete();

    this.elementsStackService.remove(this.elementContext);
  }

  public onSearchChange(event: any): void {
    this.onRefresh()
      .pipe(
        tap(() => this.onRefreshed()),
        takeUntil(this.unsubscribe)
      )
      .subscribe();
  }

  public onSearch(): void {

  }

  public onMemoTypeChanged(memoType: unknown) {
    ChangeDetectorRefHelper.markForCheck(this);
    this.memoType = memoType;
    this.entityManager.persist(this.memo, {property: 'memoType', newValue: this.memoType, force: true});
    this.entityManager.persist(this.memo, {property: EntityStatus.ENTITY_CHANGED_FLAG, newValue: true, force: true});
    this.onRefresh()
      .pipe(
        tap(() => this.onRefreshed())
      )
      .subscribe(() => {
        ChangeDetectorRefHelper.detectChanges(this);
      });
  }

  public onMemoNameChanged(name: unknown) {
    ChangeDetectorRefHelper.markForCheck(this);
    this.entityManager.persist(this.memo, {property: 'name', newValue: this.memo.name, force: true});
    this.entityManager.persist(this.memo, {property: EntityStatus.ENTITY_CHANGED_FLAG, newValue: true, force: true});
    ChangeDetectorRefHelper.detectChanges(this);
  }

  public isReadOnly(): boolean {
    if (this.getMasterConfigName(this.elementContext.masterEntities) === 'leasedEmployee') {
      return false;
    }
    return !!this.memo.isWorkflowReady;
  }

  public getSelectedEntity(): any {
    return this.selectedMasterEntity || null;
  }

  public recheckToolbarItems(): void {
    this.toolbarItemCheckService.check(null);
  }

  public onSave(): Observable<ElementSaveStatus> {
    const observables = [];
    if (this.getMasterConfigName(this.elementContext.masterEntities) === 'leasedEmployee') {
      this.memo.fieldDefinitionValues = this.fieldDefinitionValues;
      this.entityManager.persist(this.memo, { property: 'fieldDefinitionValues', newValue: this.fieldDefinitionValues });
      observables.push(this.genericCrudService.editEntity(
        `dms/dmsfiles/${this.memo.id}`, this.memo
      ));
    } else if (this.memo.fqn === 'PhoenixBundle\\Entity\\LeasedEmployeeFile') {
      this.memo.fieldDefinitionValues = this.fieldDefinitionValues;
      this.entityManager.persist(this.memo, { property: 'fieldDefinitionValues', newValue: this.fieldDefinitionValues });
      observables.push(this.genericCrudService.editEntity(
        `phoenix/leasedemployeefiles/${this.memo.id}`,
        this.memo)
      );
    } else {
      for (const memoFieldDefinitionValue of this.fieldDefinitionValues) {
        memoFieldDefinitionValue._embedded['owner'] = {
          id: memoFieldDefinitionValue.owner.id,
          fqn: memoFieldDefinitionValue.owner.fqn,
          uniqueId: memoFieldDefinitionValue.owner.uniqueId
        };
        delete memoFieldDefinitionValue.owner;
        if (!memoFieldDefinitionValue.id && memoFieldDefinitionValue.fieldDefinition) {
          observables.push(this.genericCrudService.createEntity(this.configuration.fieldDefinitionValuesApi, memoFieldDefinitionValue));
        }

        if (memoFieldDefinitionValue.id && memoFieldDefinitionValue[EntityStatus.ENTITY_CHANGED_FLAG]) {
          observables.push(this.genericCrudService.editEntity(
            `${this.configuration.fieldDefinitionValuesApi}/${memoFieldDefinitionValue.id}`,
            memoFieldDefinitionValue)
          );
        }
      }
    }
    if (observables.length === 0) {
      return observableOf({status: true, content: null, message: null});
    }

    return observableForkJoin(observables).pipe(
      catchError((response: any) => {
        return observableThrowError(response);
      }),
      map(createdSubEntities => {
        this.messageGrowlService.showDataSaved();

        return {status: true, content: null, message: null};
      }));
  }

  public hasChanges(checkEmbedded: boolean = false): boolean {
    return this.memo && this.memo[EntityStatus.ENTITY_CHANGED_FLAG];
  }

  public getCreatedEntities(shelved: boolean = false): any[] {
    const createdEntities = [];

    for (const memoFieldDefinitionValue of this.fieldDefinitionValues) {
      if (!memoFieldDefinitionValue.id) {
        createdEntities.push(memoFieldDefinitionValue);
      }
    }

    return createdEntities;
  }

  public getUpdatedEntities(shelved: boolean = false): any[] {
    const updatedEntities = [];

    for (const memoFieldDefinitionValue of this.fieldDefinitionValues) {
      if (memoFieldDefinitionValue.id && memoFieldDefinitionValue[EntityStatus.ENTITY_CHANGED_FLAG]) {
        updatedEntities.push(memoFieldDefinitionValue);
      }
    }

    return updatedEntities;
  }

  public onAfterSave(): Observable<any> {
    return observableOf(null);
  }

  public onChange(): Observable<any> {
    return observableOf(null);
  }

  public onValidate(): Observable<EntityValidatorStatus> {
    return this.doValidate();
  }

  public onMemoAutoCompleteSearch(event: any, apiRoute: string, collection: string, labelParam?: string): void {
    const query = event.query,
      route = this.apiBuilderService.getPaginateApiRoute({
        apiRoute: apiRoute,
        offset: 0,
        limit: 50,
        orderBy: 'id',
        orderDirection: 'asc'
      }) + '?search=' + query;

    const params = {clause: 'orWhere', 'embedded': 'none'};

    if (collection === 'customerAddresses' || collection === 'contactPersons') {
      const memoCustomerId = Entity.getValue(this.memo, 'customer.id') || Entity.getValue(this.memo, 'customer') ||
        Entity.getValueInEmbedded(this.memo, 'customer.id');

      if (memoCustomerId) {
        params['customer.id'] = `contains:${memoCustomerId}`;
        params['embedded'] = 'customer';
      }
    }

    if (collection === 'employeeAddresses') {
      const memoLeasedEmployeeId = Entity.getValue(this.memo, 'leasedEmployee.id') || Entity.getValue(this.memo, 'leasedEmployee') ||
        Entity.getValueInEmbedded(this.memo, 'leasedEmployee.id');

      if (memoLeasedEmployeeId) {
        params['leasedEmployee.id'] = `contains:${memoLeasedEmployeeId}`;
        params['embedded'] = 'leasedEmployee';
      }
    }

    this.genericCrudService.getEntities(route, '', params)
      .subscribe((response: any) => {
        const data = response.data ? response.data : response,
          options = [{
            label: '---',
            value: null,
            entity: null
          }];

        const labelParamSplit = labelParam.split(',');

        for (const entity of data) {
          let labelValue = '';

          if (labelParamSplit.length > 0) {
            for (const aLabelParam of labelParamSplit) {
              if (entity[aLabelParam]) {
                labelValue += entity[aLabelParam] + ' '
              }
            }
          }

          options.push({
            label: labelParam ? labelValue : entity.name,
            value: entity.id,
            entity: entity
          });
        }

        this[collection] = options;
      });
  }

  public onMemoAutoCompleteChange(item: SelectItemExtended, memoProperty: string): void {
    this.entityManager.persist(this.memo, {property: memoProperty, newValue: item.entity, force: true});

    if (memoProperty === 'contactPerson') {
      this.entityManager.persist(this.memo, {property: 'smsTo', newValue: item.entity.fullMobileNumber, force: true});
      this.entityManager.persist(this.memo, {property: 'email', newValue: item.entity.email, force: true});
      this.elementContext.masterElementContext.component.entity = {...this.memo};
      // ChangeDetectorRefHelper.detectChanges(this.elementContext.masterElementContext.component);
    }

    this.entityManager.persist(this.memo, {property: EntityStatus.ENTITY_CHANGED_FLAG, newValue: true, force: true});

    this.toolbarItemCheckService.check(this);
  }

  public doValidate(): Observable<EntityValidatorStatus> {
    let isValid = true,
      error = '';

    if (!this.memo || !this.memo.id) {
      isValid = false;
      error = 'No Memo found!';
    }

    if (!this.memoType || !this.memoType.id) {
      isValid = false;
      error = 'No Memo Type found!';
    }

    return observableOf({
      entity: null,
      isValid: isValid,
      error: error,
      errorFields: []
    });
  }

  public onRefresh(): Observable<any> {
    const observables = [];
    this.memoTypeFieldDefinitions = [];
    this.visibleMemoTypeFieldDefinitions = [];
    this.fieldDefinitionValues = [];

    this.isLoadingData = true;

    if (this.memoType && this.memoType.id) {
      delete this.memoType[EntityStatus.ENTITY_CHANGED_FLAG];
      observables.push(this.loadMemoTypeFieldDefinitions(this.memoType));
    } else {
      observables.push(of(null));
    }

    if (this.memo && this.memo.id) {
      this.memo = Entity.extractEmbedded(this.memo);
      delete this.memo[EntityStatus.ENTITY_CHANGED_FLAG];
      observables.push(this.loadMemoDefinitionValues(this.memo));
      observables.push(this.loadMemoFull(this.memo));

      this.setupMemoAutoCompletes();
    }

    if (observables.length === 0) {
      this.isLoadingData = false;
      ChangeDetectorRefHelper.detectChanges(this);
      return observableOf(null);
    }

    return observableForkJoin(observables).pipe(
      catchError((response: any) => {
        return observableThrowError(response);
      }),
      map(entitiesData => {
        if (entitiesData[0]) {
          const memoTypeFieldDefinitions: any = entitiesData[0];

          this.memoTypeFieldDefinitions = memoTypeFieldDefinitions || [];
          this.visibleMemoTypeFieldDefinitions = memoTypeFieldDefinitions;
        }

        if (entitiesData[1]) {
          const memoTypeFieldDefinitionValues: any = entitiesData[1];

          this.fieldDefinitionValues = memoTypeFieldDefinitionValues || [];

          this.visibleMemoTypeFieldDefinitions = [...this.visibleMemoTypeFieldDefinitions.filter(fieldDefinition => this.isVisible(fieldDefinition))];
        }

        if (entitiesData[2]) {
          const fullMemo: any = entitiesData[2];

          for (const memoEmbedded of this.configuration.embeddedEntities) {
            Entity.setValue(this.memo, memoEmbedded, Entity.getValueInEmbedded(fullMemo, memoEmbedded));
          }

          this.setupMemoAutoCompletes();
        }

        this.isLoadingData = false;
        this.toolbarItemCheckService.check(this);

        ChangeDetectorRefHelper.detectChanges(this);

        return {status: true, content: null};
      }));
  }

  public getToolbarExtraParams() {
    return {
      'memoFieldDefinitionValueComponent': this
    };
  }

  public getFieldDefinitionValue(entity: MemoTypeFieldDefinition): any|null {
    const memoFieldDefinitionValue = this.findMemoFieldDefinitionValue(entity);
    if (memoFieldDefinitionValue && entity.fieldDefinitionTemplate.lookupFetchDatamodel && !MemoTypeFieldDefinitionType.SIMPLE_VALUES.includes(entity.fieldDefinitionTemplate.dataType.code)) {
      return +memoFieldDefinitionValue.value || null;
    }

    if (memoFieldDefinitionValue) {
      return memoFieldDefinitionValue.value || null;
    }

    return null;
  }

  public getFieldDefinitionUrl(entity: MemoTypeFieldDefinition): string {
    if (entity.lookupFetchDatamodelSortField) {
      return entity.fieldDefinitionTemplate.lookupFetchDatamodel.apiRoute + `offset/0/limit/25/orderby/${entity.lookupFetchDatamodelSortField}/asc`
    }

    return entity.fieldDefinitionTemplate.lookupFetchDatamodel.apiRoute;
  }

  public onFieldDefinitionValueEdit(event, entity: MemoTypeFieldDefinition): void {
    const dataType = entity.fieldDefinitionTemplate.dataType.code;

    for (const memotypeFieldDefinition of this.visibleMemoTypeFieldDefinitions) {
      if (parseInt(memotypeFieldDefinition.lookupFetchDatamodelFilter) === entity.fieldDefinitionTemplate.id
        || (memotypeFieldDefinition.customLookupFilter && memotypeFieldDefinition.customLookupFilter.indexOf(`:${entity.fieldDefinitionTemplate.id}`) > -1)) {
        this.loadLookupFetchDatamodelOptions(memotypeFieldDefinition.fieldDefinitionTemplate, memotypeFieldDefinition)
          .subscribe((options: SelectItem[] = []) => {
            this.lookupFetchDatamodelOptions[memotypeFieldDefinition.fieldDefinitionTemplate.id] = options;
          });
      }
    }

    let value = null;

    switch (dataType) {
      case MemoTypeFieldDefinitionType.CODE_DROPDOWN:
      case MemoTypeFieldDefinitionType.CODE_AUTOCOMPLETE:
      case MemoTypeFieldDefinitionType.CODE_BRANCH_OFFICE:
      case MemoTypeFieldDefinitionType.CODE_LEASED_EMPLOYEE:
      case MemoTypeFieldDefinitionType.CODE_CUSTOMER:
      case MemoTypeFieldDefinitionType.CODE_INTEGER:
      case MemoTypeFieldDefinitionType.CODE_DECIMAL:
        value = event.value;
        break;
      case MemoTypeFieldDefinitionType.CODE_DATETIME:
      case MemoTypeFieldDefinitionType.CODE_DATE:
      case MemoTypeFieldDefinitionType.CODE_DATE_FROM:
      case MemoTypeFieldDefinitionType.CODE_DATE_TO:
      case MemoTypeFieldDefinitionType.CODE_CHECKBOX:
        value = event;
        break;
      case MemoTypeFieldDefinitionType.CODE_TEXT:
        value = event.target.value;
        break;
    }

    this.clearFilteredFields(entity, event);

    this.registerEntityFieldValueChange(entity, value, event);

    if (entity.embeddedFields && entity.embeddedTargetField && entity.embeddedTargetFieldValue) {
      this.setValueToField(entity, entity.embeddedFields, entity.embeddedTargetFieldValue, entity.embeddedTargetField);
    }

    if (entity.onChangeAction) {
      const step = this.stepFactory.createFromString(entity.onChangeAction, new ExecutionStepPayload(value));

      this.executorService
        .setSteps([step])
        .execute()
        .subscribe((status: ExecutionStatus) => {
          if (!status.isSuccess()) {
            this.messageGrowlService.error(`Execution failed with message ${status.getStepContent()}`);
          }
        });
    }

    if (entity.masterFormField && entity.masterFormFieldValue) {

    }
  }

  public getMemoBorderLeftColor(): string {
    return this.hasChanges(true) ? '2px solid blue' : '';
  }

  public isVisible(memoTypeFieldDefinition: MemoTypeFieldDefinition): boolean {
    const memoFieldDefinitionValue = this.findMemoFieldDefinitionValue(memoTypeFieldDefinition);
    if (! memoFieldDefinitionValue) {
      return !!memoTypeFieldDefinition.isVisible;
    }

    return memoFieldDefinitionValue.visible !== false;
  }

  public getFilterFieldValue(memoTypeFieldDefinition: MemoTypeFieldDefinition): number|string|null {
    if (memoTypeFieldDefinition.lookupFetchDatamodelFilter) {
      const fieldDefinitionValue = this.findMemoFieldDefinitionValueByTemplateId(parseInt(memoTypeFieldDefinition.lookupFetchDatamodelFilter));

      if (fieldDefinitionValue) {
        return fieldDefinitionValue.value;
      }
    }

    return null;
  }

  public getAutocompleteFilters(memoTypeFieldDefinition: MemoTypeFieldDefinition): AutocompleteFilter[] {
    const filters = [];
    const shouldFilter = !this.configuration.search || (this.configuration.search && this.selectedSearch === 'type');

    if (memoTypeFieldDefinition.lookupFetchDatamodelFilterField && shouldFilter) {
      const value = this.getFilterFieldValue(memoTypeFieldDefinition);

      if (value !== null) {
        filters.push({
          name: memoTypeFieldDefinition.lookupFetchDatamodelFilterField,
          value: this.getFilterFieldValue(memoTypeFieldDefinition),
        });
      }
    }

    if (memoTypeFieldDefinition.customLookupFilter && shouldFilter) {
      const [filterName, filterFieldDefinition] = memoTypeFieldDefinition.customLookupFilter.split(':');
      const filterFieldValue = this.findMemoFieldDefinitionValueByTemplateId(parseInt(filterFieldDefinition));

      if (filterFieldValue) {
        filters.push({
          name: 'id',
          value: `${filterName}:${filterFieldValue.value}`
        });
      }
    }

    if (memoTypeFieldDefinition.remoteDataFilter && shouldFilter) {
      const [filterName, filterType] = memoTypeFieldDefinition.remoteDataFilter.split(':');
      filters.push({
        name: filterName,
        value: this.getFilterValue(filterType)
      });
    }

    return filters;
  }

  private getFilterValue(filterType: string): string {
    if (filterType === 'supplierApprovals') {
      let branchOfficeField = this.findMemoFieldDefinitionByName('Filiale');
      let supplierField = this.findMemoFieldDefinitionByName('Lieferant');

      if (!supplierField) {
        supplierField = this.findMemoFieldDefinitionByName('Kreditor');
      }

      if (!branchOfficeField) {
        branchOfficeField = this.findMemoFieldDefinitionByName('Gesellschaft');
      }

      if (branchOfficeField && supplierField) {
        const branchOfficeFieldValue = this.findMemoFieldDefinitionValue(branchOfficeField);
        const supplierFieldValue = this.findMemoFieldDefinitionValue(supplierField);

        if (supplierFieldValue && branchOfficeFieldValue) {
          return `${filterType}:${branchOfficeFieldValue.value}-${supplierFieldValue.value}`;
        }
      }
    }
    return `${filterType}:1`;
  }


  public findMemoFieldDefinitionValue(memoTypeFieldDefinition: MemoTypeFieldDefinition): MemoFieldDefinitionValue|null {
    const index = this.fieldDefinitionValues.findIndex((aMemoField: MemoFieldDefinitionValue) => {
      return memoTypeFieldDefinition && aMemoField.fieldDefinition && memoTypeFieldDefinition.id === aMemoField.fieldDefinition.id;
    });

    if (index !== -1) {
      return this.fieldDefinitionValues[index];
    }

    return null;
  }

  protected findMemoFieldDefinitionValueByTemplateId(templateId: number): MemoFieldDefinitionValue|null {
    const index = this.visibleMemoTypeFieldDefinitions.findIndex((fieldDefinition: MemoTypeFieldDefinition) => {
      return templateId === fieldDefinition.id;
    });

    if (index > -1) {
      const fieldDefinition = this.visibleMemoTypeFieldDefinitions[index];

      return this.findMemoFieldDefinitionValue(fieldDefinition);
    }

    return null;
  }

  protected findMemoFieldDefinitionByTemplateId(templateId: number): MemoTypeFieldDefinition|null {
    const index = this.visibleMemoTypeFieldDefinitions.findIndex((fieldDefinition: MemoTypeFieldDefinition) => {
      return templateId === fieldDefinition.id;
    });

    if (index > -1) {
      return this.visibleMemoTypeFieldDefinitions[index];
    }

    return null;
  }

  protected createContext() {
    const isSlave = this.moduleElement && typeof this.moduleElement.master !== 'undefined'
      && this.moduleElement.master !== null && this.moduleElement.master.id > 0;

    const isSubView = this.locationService.hasParam('parent-module')
      || (this.locationService.hasParam('id') && this.locationService.hasParam('master-entity'));

    const elementContext = new ElementContext(
      this.moduleElement.id,
      this.elementType,
      this,
      this.moduleElement,
      !isSlave,
      isSlave,
      !isSlave,
      isSubView,
      this.isPart,
      null,
      this.masterElementContext,
      null,
      null,
      null,
      this.isDialog,
      this.moduleElement.isMaster
    );

    if (isSubView) {
      const elementConfig = new MasterEntityConfig();
      elementConfig.value = this.locationService.getParam('id');
      elementConfig.name = this.locationService.hasParam('master-entity')
        ? this.locationService.getParam('master-entity') : this.moduleElement.masterFilterField;
      elementConfig.datamodelId = 0; // For now - no way to find out.
      elementConfig.filterType = 'subViewMasterEntity';

      elementContext.addMasterEntity(elementConfig);
    }

    // Now, some kinky stuff - parts in dialogs - hit it:
    if (this.isDialog && isSubView && this.selectedMasterEntity && this.moduleElement.masterFilterField) {
      const elementConfig = new MasterEntityConfig();
      elementConfig.value = this.selectedMasterEntity.id;
      elementConfig.name = this.moduleElement.masterFilterField;
      elementConfig.datamodelId = 0; // For now - no way to find out.
      elementConfig.filterType = 'subViewMasterEntity';

      elementContext.addMasterEntity(elementConfig);
    }

    if (this.selectedMasterEntity && this.masterEntityField) {
      const elementConfig = new MasterEntityConfig();
      elementConfig.value = this.selectedMasterEntity.id;
      elementConfig.name = this.masterEntityField;
      elementConfig.datamodelId = 0; // For now - no way to find out.
      elementConfig.filterType = 'masterEntity';

      elementContext.setSelectedMasterEntity(this.selectedMasterEntity).addMasterEntity(elementConfig);
    }

    if (this.masterFilterField && this.masterFilterValue) {
      const elementConfig = new MasterEntityConfig();
      elementConfig.value = this.masterFilterValue;
      elementConfig.name = this.masterFilterField;
      elementConfig.datamodelId = 0; // For now - no way to find out.
      elementConfig.filterType = 'masterEntity';

      elementContext.addMasterEntity(elementConfig);
    }

    // Now let's remove and re-add the grid context if it is already there:
    this.elementsStackService.remove(elementContext).add(elementContext);

    return elementContext;
  }

  protected loadMemoTypeFieldDefinitions(memoType: any): Observable<any[]> {
    const apiRoute = `${this.configuration.memoTypeFieldDefinitionsApi}/offset/0/limit/1000/orderby/sequencePosition/asc`,
      params = {
        'embedded': this.configuration.memoTypeRelation + ',fieldDefinitionTemplate,fieldDefinitionTemplate.dataType,fieldDefinitionTemplate.editType,fieldDefinitionTemplate.lookupFetchDatamodel',
        'isVisible': true
      };

    if (memoType) {
      params[this.configuration.memoTypeRelation + '.id'] = memoType.id;
    }

    this.memoTypeFieldDefinitionsUnsubscrube.next();
    this.memoTypeFieldDefinitionsUnsubscrube.complete();

    this.memoTypeFieldDefinitionsUnsubscrube = new Subject();

    return this.genericCrudService
      .getEntities(apiRoute, '', params).pipe(
      // .takeUntil(this.memoTypeFieldDefinitionsUnsubscribe)
      map((data) => {
        const parsed: MemoTypeFieldDefinition[] = data && data.data ? data.data : data;

        for (const entity of parsed) {
          const fieldDefinitionTemplate = Entity.getValue(entity, 'fieldDefinitionTemplate') ||
            Entity.getValueInEmbedded(entity, 'fieldDefinitionTemplate')

          if (fieldDefinitionTemplate) {
            entity.fieldDefinitionTemplate = fieldDefinitionTemplate;
            fieldDefinitionTemplate._embedded = fieldDefinitionTemplate._embedded || {};
            fieldDefinitionTemplate.dataType = fieldDefinitionTemplate._embedded.dataType;
            fieldDefinitionTemplate.lookupFetchDatamodel = fieldDefinitionTemplate._embedded.lookupFetchDatamodel;

            if (fieldDefinitionTemplate.dataType && fieldDefinitionTemplate.dataType.code === MemoTypeFieldDefinitionType.CODE_DROPDOWN &&
              fieldDefinitionTemplate.lookupFetchDatamodel
            ) {
              this.loadLookupFetchDatamodelOptions(fieldDefinitionTemplate, entity)
                .subscribe((options: SelectItem[] = []) => {
                  this.lookupFetchDatamodelOptions[fieldDefinitionTemplate.id] = options;

                  ChangeDetectorRefHelper.detectChanges(this);
                });
            }
          }
        }

        return parsed;
      }));
  }

  private loadMemoDefinitionValues(memo: any): Observable<any[]> {
    const apiRoute = `${this.configuration.fieldDefinitionValuesApi}/offset/0/limit/50/orderby/id/asc`,
      params = {
        'embedded': 'owner,fieldDefinition,fieldDefinition.fieldDefinitionTemplate',
        'owner.id': memo.id
      };

    this.memoDefinitionValuesUnsubscribe.next();
    this.memoDefinitionValuesUnsubscribe.complete();

    this.memoDefinitionValuesUnsubscribe = new Subject();

    return this.genericCrudService.getEntities(apiRoute, '', params).pipe(
      // .takeUntil(this.memoDefinitionValuesUnsubscribe)
      map((data) => {
        const parsed: MemoFieldDefinitionValue[] = data && data.data ? data.data : data;

        for (const entity of parsed) {
          entity._embedded = entity._embedded || {};
          entity.fieldDefinition = entity._embedded.fieldDefinition;
        }

        return parsed;
      }));
  }

  private loadMemoFull(memo: any): Observable<any[]> {
    this.memoUnsubscribe.next();
    this.memoUnsubscribe.complete();

    this.memoUnsubscribe = new Subject();

    return this.genericCrudService.getEntity(this.configuration.ownerApi, memo.id, '', {
      'embedded': this.configuration.embeddedEntities.join(',')
    })
      .map((fullMemo) => {
        return fullMemo
      });
  }

  private loadLookupFetchDatamodelOptions(entity: FieldDefinitionTemplate, memoTypeFieldDefinition: MemoTypeFieldDefinition): Observable<SelectItem[]> {
    const labelValue = entity.lookupFetchDatamodelLabel || 'name';

    const requestParams = {};

    if (entity.lookupFetchDatamodelFilter && entity.lookupFetchDatamodelFilterField) {
      const value = this.findMemoFieldDefinitionValueByTemplateId(parseInt(entity.lookupFetchDatamodelFilter));

      if (value) {
        requestParams[entity.lookupFetchDatamodelFilterField] = value.value;
      }
    }

    if (entity.customLookupFilter) {
      const [filterName, filterFieldDefinition] = entity.customLookupFilter.split(':');
      const filterFieldValue = this.findMemoFieldDefinitionValueByTemplateId(parseInt(filterFieldDefinition));

      if (filterFieldValue) {
        requestParams['id'] = `${filterName}:${filterFieldValue.value}`;
      }
    }

    if (memoTypeFieldDefinition.remoteDataFilter) {
      const [filterName, filterType] = memoTypeFieldDefinition.remoteDataFilter.split(':');
      requestParams[filterName] = `${filterType}:1`;
    }

    return this.genericCrudService.getEntities(this.getFieldDefinitionUrl(memoTypeFieldDefinition), null, requestParams).pipe(
      map((entities) => {
        const options = [{
          label: '---',
          value: null
        }];

        for (const datamodelEntity of entities) {
          const label = Entity.getValue(datamodelEntity, labelValue) || Entity.getValueInEmbedded(datamodelEntity, labelValue)
            || datamodelEntity.id;

          options.push({
            label: label,
            value: datamodelEntity.id
          });
        }

        return options;
      }));
  }

  private setupMemoAutoCompletes(): void {
    if (this.memo) {
      if (this.memo.customerAddress && this.memo.customerAddress instanceof Object) {
        this.selectedCustomerAddress = {
          label: this.memo.customerAddress.fullAddress,
          value: this.memo.customerAddress.id,
          entity: this.memo.customerAddress
        }
      }

      if (this.memo.contactPerson && this.memo.contactPerson instanceof Object) {
        this.selectedContactPerson = {
          label: this.memo.contactPerson.firstName + ' ' + this.memo.contactPerson.lastName,
          value: this.memo.contactPerson.id,
          entity: this.memo.contactPerson
        }
      }

      if (this.memo.contactUser && this.memo.contactUser instanceof Object) {
        this.selectedContactUser = {
          label: this.memo.contactUser.firstName + ' ' + this.memo.contactUser.lastName,
          value: this.memo.contactUser.id,
          entity: this.memo.contactUser
        }
      }

      if (this.memo.employeeAddress && this.memo.employeeAddress instanceof Object) {
        this.selectedEmployeeAddress = {
          label: this.memo.employeeAddress.fullAddress,
          value: this.memo.employeeAddress.id,
          entity: this.memo.employeeAddress
        }
      }

      if (this.memo.contactBranchOffice && this.memo.contactBranchOffice instanceof Object) {
        const city = this.memo.contactBranchOffice.city || '',
          street = this.memo.contactBranchOffice.street || '';

        this.selectedContactBranchOffice = {
          label: city + ' ' + street,
          value: this.memo.contactBranchOffice.id,
          entity: this.memo.contactBranchOffice
        }
      }
    }
  }

  changeConfiguration(memo: {fqn: string}) {
    if (memo.fqn === 'DmsBundle\\Entity\\DmsFile') {
      this.configuration = {
        ownerApi: 'dms/dmsfiles',
        fieldDefinitionValuesApi: 'dms/memofielddefinitionvalues',
        memoTypeFieldDefinitionsApi: 'dms/memotypefielddefinitions',
        memoTypeApi: 'dms/memotypes/offset/0/limit/50/orderby/name/asc',
        memoTypeDefinedOutside: false,
        memoTypeRelation: 'memoType',
        embeddedEntities: MEMO_EMBEDDED_ENTITIES,
        usesExternalRefresh: false,
        search: false,
      }
    }

    if (memo.fqn === 'PhoenixBundle\\Entity\\LeasedEmployeeFile') {
      this.configuration = {
        ownerApi: 'phoenix/leasedemployeefiles',
        fieldDefinitionValuesApi: 'dms/memofielddefinitionvalues',
        memoTypeFieldDefinitionsApi: 'dms/memotypefielddefinitions',
        memoTypeApi: 'dms/memotypes/offset/0/limit/50/orderby/name/asc',
        memoTypeDefinedOutside: false,
        memoTypeRelation: 'memoType',
        embeddedEntities: MEMO_EMBEDDED_ENTITIES,
        usesExternalRefresh: false,
        search: false,
      }
    }
  }

  private onRefreshed() {
    const observables = []
    for (const entity of this.visibleMemoTypeFieldDefinitions) {
      observables.push(
        this.executeAction(ExecutorActionEvent.EntityValueChanged, {
          component: this,
          entityDataChangeMeta: {
            entity,
          }
        })
      )
    }

    if (observables.length > 0) {
      forkJoin(observables)
        .pipe(takeUntil(this.unsubscribe))
        .subscribe();
    }
  }

  private setValueToField(memoFieldDefinition: MemoTypeFieldDefinition, embeddedField, embeddedFieldValue, targetField: string) {
    const value = this.findMemoFieldDefinitionValue(memoFieldDefinition);

    if (!value) {
      return;
    }

    this.genericCrudService.getEntity(memoFieldDefinition.fieldDefinitionTemplate.lookupFetchDatamodel.apiRoute, value.value, '', {
      'embedded': embeddedField
    })
      .subscribe(loadedEntity => {
        const embeddedTargetFields = targetField.split(',');
        const embeddedFieldValues = embeddedFieldValue.split(',');

        for (const embeddedFieldPart of embeddedTargetFields) {
          const targetFieldPart = embeddedTargetFields[embeddedTargetFields.indexOf(embeddedFieldPart)];
          const embeddedFieldValuePart = embeddedFieldValues[embeddedTargetFields.indexOf(embeddedFieldPart)];

          const entityFieldValue = this.entityHydratorService.getEntityPropertyValue(loadedEntity, embeddedFieldValuePart);
          const targetFieldDefinition = this.findMemoFieldDefinitionByTemplateId(parseInt(targetFieldPart));

          this.registerEntityFieldValueChange(targetFieldDefinition, entityFieldValue, null);

          // Detect changes, but only in case the field is something other then automplete (BUG with autocomplete handler):
          if (targetFieldDefinition
            && [MemoTypeFieldDefinitionType.CODE_AUTOCOMPLETE, MemoTypeFieldDefinitionType.CODE_LEASED_EMPLOYEE, MemoTypeFieldDefinitionType.CODE_CUSTOMER]
              .indexOf(targetFieldDefinition.fieldDefinitionTemplate.dataType.code) < 0) {
            ChangeDetectorRefHelper.markForCheck(this);
          }
        }
      });
  }

  public findMemoFieldDefinitionByName(memoTypeDefinitionName: string): MemoTypeFieldDefinition|null {
    const index = this.visibleMemoTypeFieldDefinitions.findIndex((fieldDefinition: MemoTypeFieldDefinition) => {
      return memoTypeDefinitionName === fieldDefinition.name;
    });

    if (index > -1) {
      return this.visibleMemoTypeFieldDefinitions[index];
    }

    return null;
  }

  public registerEntityFieldValueChange(entity: MemoTypeFieldDefinition, value, event = null) {
    if (!entity) {
      return;
    }
    const memoFieldDefinitionValue: any = this.findMemoFieldDefinitionValue(entity);

    if (memoFieldDefinitionValue) {
      this.entityManager.persist(memoFieldDefinitionValue, {
        property: 'value', newValue: value, force: true
      });
      this.entityManager.persist(memoFieldDefinitionValue, {
        property: EntityStatus.ENTITY_CHANGED_FLAG, newValue: true, force: true
      });
    } else {
      const newMemoFieldDefinitionValue: MemoFieldDefinitionValue = {
        id: null,
        fieldDefinition: entity,
        fqn: 'DmsBundle\\Entity\\MemoFieldDefinitionValue',
        value: value,
        isChanged: true,
        _embedded: {},
        uniqueId: Guid.create().toString(),
        owner: this.memo
      };
      if (this.memo.fqn === 'PhoenixBundle\\Entity\\Memo') {
        newMemoFieldDefinitionValue.fqn = 'PhoenixBundle\\Entity\\MemoFieldDefinitionValue';
      }

      if (this.memo && this.memo.id) {
        newMemoFieldDefinitionValue.owner = this.memo;
      }

      this.fieldDefinitionValues = [...this.fieldDefinitionValues, newMemoFieldDefinitionValue];
    }

    if (this.memo) {
      this.memo[EntityStatus.ENTITY_CHANGED_FLAG] = true;
    }

    this.toolbarItemCheckService.check(this);

    if (event) {
      this.executeAction(ExecutorActionEvent.EntityValueChanged, {
        component: this,
        entityDataChangeMeta: {
          entity,
          event
        }
      }).pipe(takeUntil(this.unsubscribe))
        .subscribe();
    }
  }

  public onClear(event, entity: MemoTypeFieldDefinition) {
    const memoFieldDefinitionValue = this.findMemoFieldDefinitionValue(entity);

    if (memoFieldDefinitionValue) {
      memoFieldDefinitionValue.value = null;
    }

    this.clearFilteredFields(entity, event);
  }

  protected clearFilteredFields(entity: MemoTypeFieldDefinition, event) {
    for (const memoTypeFieldDefinition of this.visibleMemoTypeFieldDefinitions) {
      if (memoTypeFieldDefinition.lookupFetchDatamodelFilter === entity.id.toString()) {
        const value = this.findMemoFieldDefinitionValue(memoTypeFieldDefinition);
        if (value) {
          this.registerEntityFieldValueChange(memoTypeFieldDefinition, null, event);
        }
      }
    }
    ChangeDetectorRefHelper.detectChanges(this);
  }

  private getMasterConfigName(masterConfigValues: MasterEntityConfig[]): string {
    let name = '';
    for (const masterConfigValue of masterConfigValues) {
      if (masterConfigValue.name === 'leasedEmployee') {
        name = masterConfigValue.name;
      }
    }

    return name;
  }

  public getFilters() {
    return [{name:'clause', value:'orWhere'}];
  }
}
