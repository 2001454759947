import {Injectable} from '@angular/core';
import {ModuleStateInspectorService} from '../service/module-state-inspector.service';
import {ExecutionStepFactoryService} from '../factory/execution-step-factory.service';
import {ExecutionStep} from '../execution-step';
import {ModuleState} from '../../../shared/content-renderer/services/module-state';
import {FormViewerComponent} from '../../../shared/form-viewer/form-viewer.component';
import {DialogFormMasterImportExecutionStep} from '../../../shared/services/execution-step/dialog/dialog-form-master-import-execution-step';
import {ExecutionStepPayload} from '../execution-step-payload';
import {ComponentValidationExecutionStep} from '../../../shared/services/execution-step/validation/component-validation-execution-step';

@Injectable()
export class DraftSaveBuilderService {
  doCreateSaveSteps = true;
  doCreateValidationSteps = true;

  /**
   * @param {ModuleStateInspectorService} moduleStateInspectorService
   * @param {ExecutionStepFactoryService} executionStepFactoryService
   */
  constructor(
    protected moduleStateInspectorService: ModuleStateInspectorService,
    protected executionStepFactoryService: ExecutionStepFactoryService
  ) {}

  /**
   * @param {boolean} createStep
   * @returns {DraftSaveBuilderService}
   */
  public setDoCreateSaveSteps(createStep: boolean): DraftSaveBuilderService {
    this.doCreateSaveSteps = createStep;
    return this;
  }

  /**
   * @param {boolean} createStep
   * @returns {DraftSaveBuilderService}
   */
  public setDoCreateValidationSteps(createStep: boolean): DraftSaveBuilderService {
    this.doCreateValidationSteps = createStep;
    return this;
  }

  public createExecutionSteps(currentModuleContext: ModuleState): ExecutionStep[] {
    const executionSteps = [];

    const mainForm = this.moduleStateInspectorService.findFirstMainComponentByType(currentModuleContext, FormViewerComponent);

    if (mainForm) {
      const payload = new ExecutionStepPayload(mainForm);
      executionSteps.push(
        this.executionStepFactoryService.create(DialogFormMasterImportExecutionStep, payload)
      );

      /*executionSteps.push(
        this.executionStepFactoryService.create(ComponentValidationExecutionStep, payload)
      );*/
    }

    return executionSteps;
  }
}
