import {Component, EventEmitter, Input, Output} from '@angular/core';
import {CronJob} from '../../../../../services/cron/cron-job';

@Component({
  selector: 'app-custom-cron-job-command-argument-list',
  templateUrl: './cron-job-command-argument-list.component.html',
  styleUrls: ['./cron-job-command-argument-list.component.scss']
})
export class CronJobCommandArgumentListComponent  {
  @Input() public cronJob: CronJob = null;
  @Output() public onArgumentChange: EventEmitter<any> = new EventEmitter();

  public onKeyUp(event): void {
    this.onArgumentChange.emit(event);
  }
}
