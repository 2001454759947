import {Component, EventEmitter, Input, Output} from '@angular/core';
import {GenericCrudService} from '../../services/generic-crud.service';
import {Datamodel} from '../../services/datamodel/datamodel';
import {ElementDisplayConfig} from '../../form-viewer/models/element';
import DisplayConfig = App.Form.Element.DisplayConfig;

@Component({
  selector: 'app-display-config',
  templateUrl: './display-config.component.html',
  styles: [`
    :host {
      height: 100%;
    }
    
    .container {
      height: 100%;
      overflow: auto;
    }
  `]
})
export class DisplayConfigComponent {

  @Input() public datamodel: Datamodel = null;
  @Input() public configuration: DisplayConfig[] = [];
  @Output() onSave: EventEmitter<DisplayConfig[]> = new EventEmitter();

  public constructor(
    protected readonly genericCrudService: GenericCrudService
  ) {
  }

  public onAddConfig(): void {
    const displayConfig = new ElementDisplayConfig();
    displayConfig.fieldName = null;
    displayConfig.postCharacter = '';
    displayConfig.preChacarter = '';

    this.configuration = [...this.configuration, displayConfig];

    this.onSave.emit(this.configuration);
  }

  public onRemoveConfig(config: DisplayConfig): void {
    const index = this.configuration.indexOf(config);

    if (index !== -1) {
      this.configuration.splice(index, 1);
    }

    this.onSave.emit(this.configuration);
  }

  public onFieldChange(fieldName: string, config: DisplayConfig): void {
    config.fieldName = fieldName;

    this.onSave.emit(this.configuration);
  }

  public onChange(event): void {
    this.onSave.emit(this.configuration);
  }
}

