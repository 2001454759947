
import {ChangeDetectionStrategy, Component} from '@angular/core';
import { ToolbarItemAbstract } from '../toolbar-item-abstract.component';
import {GenericCrudService} from '../../../../../../services/generic-crud.service';
import {DoubleClickService} from '../../../../../services/double-click.service';
import {ModulesStateService} from '../../../../../services/modules-state.service';
import {ExecutionStepBuilderService} from '../../../../../../../core/executor/builder/execution-step-builder.service';
import {ExecutorService} from '../../../../../../../core/executor/executor.service';
import {ExecutionStatusError} from '../../../../../../../core/executor/execution-status-error';
import {ComponentValidationExecutionStep} from '../../../../../../services/execution-step/validation/component-validation-execution-step';
import {EntityValidationExecutionStep} from '../../../../../../services/execution-step/validation/entity-validation-execution-step';
import {MessageService} from '../../../../../../../core/message/message.service';
import {TranslateService} from '@ngx-translate/core';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import {InvoicePdfContentService} from './Content/invoice-pdf-content.service';
import {map} from 'rxjs/operators';
import {Observable} from 'rxjs';
import {StyleService} from '../../../../../../../core/style.service';

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'toolbar-item-generate-pdf',
    templateUrl: './toolbar-item-generate-pdf.component.html'
})
export class ToolbarItemGeneratePdfComponent extends ToolbarItemAbstract {

  constructor(
    protected genericCrudService: GenericCrudService,
    protected modulesStateService: ModulesStateService,
    protected executionStepBuilderService: ExecutionStepBuilderService,
    protected executorService: ExecutorService,
    protected messageService: MessageService,
    protected translateService: TranslateService,
    protected doubleClickService: DoubleClickService,
    protected styleService: StyleService
  ) {
    super();
    pdfMake.vfs = pdfFonts.pdfMake.vfs;
  }

  ngOnInit() {
  }

  public click() {
    const component = this.getComponent();
    this.getDocumentDefinition(component).subscribe((pdfDocumentDefinition) => {
      const pdfTarget = this.getToolbarItem().pdfTarget;
      switch (pdfTarget) {
        case 'open':
          pdfMake.createPdf(pdfDocumentDefinition[0]).open();
          break;
        case 'print':
          pdfMake.createPdf(pdfDocumentDefinition[0]).print();
          break;
        case 'download':
          pdfMake.createPdf(pdfDocumentDefinition[0]).download();
          break;
        default:
          pdfMake.createPdf(pdfDocumentDefinition[0]).open();
          break;
      }
    });
  }

  private getDocumentDefinition(component): Observable<any> {
    const entity = component.getSelectedEntity();
    if (entity.fqn) {
      if (entity.fqn === 'PhoenixBundle\\Entity\\Invoice') {
        return this.genericCrudService.getEntities('phoenix/invoicepositions/pdf', '', {
          invoice: entity.id,
          embedded: 'none'
        }).pipe(
          map((data) => {
            data['logo'] = this.styleService.design.settings.global.favicon;
            return new InvoicePdfContentService().setData(data).getContent();
          })
        )
      }
    }
  }
  public onExecutorFailure(status: ExecutionStatusError): void {
    if (status.getStep() instanceof ComponentValidationExecutionStep ||
      status.getStep() instanceof EntityValidationExecutionStep
    ) {
      this.onComponentValidationFailure(status);
    }
  }

  public onComponentValidationFailure(status: ExecutionStatusError): void {
    this.messageService.confirm({
      acceptVisible: true,
      rejectVisible: false,
      header: this.translateService.instant('COMMON.ERROR'),
      message: status.getStepContent(),
      accept: () => { }
    });
  }
}
