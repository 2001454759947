import { AbstractInlineEditor } from './abstract-inline-editor';
import {Observable, of} from 'rxjs';
import {map, takeUntil} from 'rxjs/operators';
import {FileHelper} from '../../../../helpers/file-helper';

export class DocumentDownloadInlineEditor extends AbstractInlineEditor {

    public onEdit(entity: any, event: any) {

    }

    public onBlur(entity: any, event: any) {

    }

    public onFocus(entity: any, event: any): Observable<any> {
      if (entity.link) {
        return this.genericCrudService.download('phoenix/files/download/' + entity.id, {
          fileType: entity.fileType,
          fileClassName: entity.fqn
        })
          .pipe(
            takeUntil(this.component.unsubscribe),
            map((data: any) => {
              FileHelper.download({
                content: data,
                fileType: entity.fileType,
                name: entity.name
              })
            }));
      }

      let fileName = entity[this.getEntityColumnProperty()];
      fileName = fileName.split('.');
      let fileType = '';
      if (fileName.length > 1) {
        fileType = fileName[fileName.length - 1];
      }
      const a = document.createElement('a');
      document.body.appendChild(a);
      a.style.setProperty('display', 'none');

      return this.genericCrudService.download('app/download/document/' + entity.uploadedFileName,
        {
          fileType
        })
        .pipe(
          takeUntil(this.component.unsubscribe),
          map((data: any) => {
          // xcentric check this!
          const blob = new Blob([data],
          { type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' });
            const url = window.URL.createObjectURL(blob);

            a.href = url;
            a.download = entity[this.getEntityColumnProperty()];
            a.click();
            window.URL.revokeObjectURL(url);
          }));
    }
}
