import {Pipe, PipeTransform} from '@angular/core';
import {NumberHelper} from '../helpers/number.helper';
import {Constants} from '../../constants';

@Pipe({ name: 'decimal' })
export class DecimalPipeCustom implements PipeTransform {

    transform(value: string, decimalSeparator: string = ',', thousandSeparator: string = '.', decimalPlaces: number = 2): any {

        if (decimalPlaces === null) {
          decimalPlaces = Constants.DEFAULT_DECIMAL_PLACES;
        }

        if ('' === value || null === value || typeof value === 'undefined') {
            return '';
        }

        return NumberHelper.formatNumber(value, decimalSeparator, thousandSeparator, decimalPlaces);
    };
}
