import { Component, OnInit, Input, ViewContainerRef } from '@angular/core';
import { environment } from '../../../../../environments/environment';

@Component({
    selector: 'app-generic-loading',
    templateUrl: './generic-loading.component.html',
    styleUrls: ['./generic-loading.component.scss']
})
export class GenericLoadingComponent implements OnInit {

    ngOnInit() {

    }
}
