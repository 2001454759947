import {AfterViewInit, Component} from '@angular/core';
import {ExecutorService} from '../../../../../core/executor/executor.service';
import {GenericElementValidationExecutionStepsFactory} from '../../../services/generic/generic-element-validation-execution-steps-factory';
import {GenericElementFilterService} from '../../../services/generic/filter/generic-element-filter.service';
import {CancelComponentChangesService} from '../../../services/cancel-components-changes.service';
import {GenericGridColumnBuilderService} from '../../generic-grid/services/generic-grid-column-builder.service';
import {GenericGridGlobalFilterService} from '../../generic-grid/services/generic-grid-global-filter.service';
import {GenericGridRemoteFilterService} from '../../generic-grid/services/generic-grid-remote-filter.service';
import {GenericElementInlineEditorService} from '../../../services/generic/generic-element-inline-editor.service';
import {GenericGridBulkSaveService} from '../../generic-grid/services/generic-grid-bulk-save.service';
import {GenericGridEntityService} from '../../../services/generic/entity/generic-grid-entity.service';
import {CustomButtonCheckFactoryService} from '../../generic-grid/services/custom-button-check/custom-button-check-factory.service';
import {ExecutionStepBuilderService} from '../../../../../core/executor/builder/execution-step-builder.service';
import {ElementContext, ElementType} from '../../../services/ElementContext';
import {AbstractGenericGridComponent, StaticGridFilterField} from '../../abstract-generic-grid.component';
import {ModuleState} from '../../../services/module-state';
import {Observable} from 'rxjs';
import {GenericTurboGridComponent} from '../../generic-turbo-grid/generic-turbo-grid.component';

@Component({
  selector: 'app-custom-work-hour-docuware-grid',
  styleUrls: ['./../../generic-turbo-grid/generic-turbo-grid.component.scss'],
  templateUrl: './../../generic-turbo-grid/generic-turbo-grid.component.html',
  providers: [
    CancelComponentChangesService,
    GenericGridRemoteFilterService,
    GenericGridGlobalFilterService,
    GenericGridColumnBuilderService,
    GenericGridBulkSaveService,
    GenericGridEntityService,
    GenericElementInlineEditorService,
    GenericElementFilterService,
    GenericElementValidationExecutionStepsFactory,
    ExecutorService,
    ExecutionStepBuilderService,
    CustomButtonCheckFactoryService
  ]
})
export class WorkHourDocuwareGridComponent extends GenericTurboGridComponent implements AfterViewInit {

  public elementType: ElementType = ElementType.WorkHourDocuwareGrid;

  public ngAfterViewInit(): void {
    const workHourGrid = this.getWorkHourGrid();

    if (workHourGrid) {
      const filterComponent = workHourGrid.filterComponent;

      if (filterComponent) {
        filterComponent.onSearchExecuted.subscribe(() => {
          this.filterGrid(workHourGrid);

          this.entities = [];

          if (this.isGridFilterDefined('docuwareDocument.leasedEmployee')) {
            this.loadEntities().subscribe();
          }
        });
      }
    }
  }

  public onRefresh(): Observable<any> {
    const workHourGrid = this.getWorkHourGrid();
    
    this.entities = [];

    if (workHourGrid && this.isGridFilterDefined('docuwareDocument.leasedEmployee')) {
      workHourGrid.loadEntities().subscribe();
    }

    return super.onRefresh();
  }

  private getWorkHourGrid(): any|null {
    const moduleState: ModuleState = this.modulesStateService.getByComponent(this);

    if (moduleState) {
      const components = moduleState.getComponents();

      for (const component of components) {
        const context: ElementContext = component.getElementContext();

        if (context && context.type === ElementType.WorkHourGrid) {
          return component;
        }
      }
    }

    return null;
  }

  private filterGrid(workHourGrid): void {
    if (workHourGrid && workHourGrid.gridFilters) {
      this.removeGridFilter('docuwareDocument.leasedEmployee');
      this.removeGridFilter('validityYear');
      this.removeGridFilter('validityMonth');

      this.addLeasedEmployeeFilter(workHourGrid);
      this.addWorkMonthFilter(workHourGrid);
      this.addWorkYearFilter(workHourGrid);
    }
  }

  private removeGridFilter(filterName): void {
    const filterIndex = this.staticFilters.findIndex((aFilter: StaticGridFilterField) => {
      return aFilter.field === filterName;
    });

    if (filterIndex !== -1) {
      this.staticFilters.splice(filterIndex, 1);
    }
  }

  private addLeasedEmployeeFilter(workHourGrid: AbstractGenericGridComponent): void {
    const leasedEmployees = workHourGrid.gridFilters.leasedEmployee && workHourGrid.gridFilters.leasedEmployee.value ?
      workHourGrid.gridFilters.leasedEmployee.value : [];

    if (leasedEmployees.length > 0) {
      const ids = [];
      for (const leasedEmployee of leasedEmployees) {
        if (leasedEmployee.id) {
          ids.push(leasedEmployee.id);
        }
      }

      this.embeddedFields = [...this.embeddedFields, 'docuwareDocument'];

      this.staticFilters.push({
        field: 'docuwareDocument.leasedEmployee',
        value: 'in:' + ids.join(',')
      });
    }
  }

  private addWorkMonthFilter(workHourGrid: AbstractGenericGridComponent): void {
    let workMonth = workHourGrid.gridFilters.workMonth && workHourGrid.gridFilters.workMonth.value ?
      workHourGrid.gridFilters.workMonth.value : null;

    if (workMonth !== null) {
      workMonth = +workMonth;

      this.staticFilters.push({
        field: 'validityMonth',
        value: 'equal:' + workMonth
      });
    }
  }

  private addWorkYearFilter(workHourGrid: AbstractGenericGridComponent): void {
    let workYear = workHourGrid.gridFilters.workYear && workHourGrid.gridFilters.workYear.value ?
      workHourGrid.gridFilters.workYear.value : null;

    if (workYear !== null) {
      workYear = +workYear;

      this.staticFilters.push({
        field: 'validityYear',
        value: 'equal:' + workYear
      });
    }
  }

  private isGridFilterDefined(filterName): boolean {
    const filterIndex = this.staticFilters.findIndex((aFilter: StaticGridFilterField) => {
      return aFilter.field === filterName;
    });

    return filterIndex !== -1;
  }
}
