/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "primeng/components/dom/domhandler";
import * as i2 from "primeng/components/button/button";
import * as i3 from "../../../../../p-button/p-button.directive";
import * as i4 from "./disposition-action-column.component";
import * as i5 from "../../../../services/double-click.service";
import * as i6 from "../../../../../services/generic-crud.service";
import * as i7 from "../../../../../../core/job-runner/job-container.service";
import * as i8 from "../../../generic-dialog/service/generic-dialog-module.service";
import * as i9 from "@ngx-translate/core";
import * as i10 from "../../../../services/modules-state.service";
import * as i11 from "../../../../../../core/executor/builder/execution-step-builder.service";
import * as i12 from "../../../../../../core/executor/executor.service";
import * as i13 from "../../../../../../core/message/message.service";
import * as i14 from "../../../../../services/entity-hydrator.service";
var styles_DispositionActionColumnComponent = [];
var RenderType_DispositionActionColumnComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_DispositionActionColumnComponent, data: {} });
export { RenderType_DispositionActionColumnComponent as RenderType_DispositionActionColumnComponent };
export function View_DispositionActionColumnComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 6, "table", [], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 5, "tr", [], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 4, "td", [], null, null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 3, "button", [["pButton", ""]], [[4, "width", null], [8, "disabled", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.openDetails($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵprd(4608, null, i1.DomHandler, i1.DomHandler, []), i0.ɵdid(5, 4341760, null, 0, i2.ButtonDirective, [i0.ElementRef], { label: [0, "label"] }, null), i0.ɵdid(6, 4341760, null, 0, i3.PButtonDirective, [i0.ElementRef], { label: [0, "label"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.getButtonLabel(); _ck(_v, 5, 0, currVal_2); var currVal_3 = _co.getButtonLabel(); _ck(_v, 6, 0, currVal_3); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.getButtonWidth(); var currVal_1 = _co.isDisabled(); _ck(_v, 3, 0, currVal_0, currVal_1); }); }
export function View_DispositionActionColumnComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-disposition-column", [], null, null, null, View_DispositionActionColumnComponent_0, RenderType_DispositionActionColumnComponent)), i0.ɵdid(1, 114688, null, 0, i4.DispositionActionColumnComponent, [i0.ElementRef, i5.DoubleClickService, i6.GenericCrudService, i7.JobContainerService, i8.GenericDialogModuleService, i9.TranslateService, i10.ModulesStateService, i11.ExecutionStepBuilderService, i12.ExecutorService, i13.MessageService, i14.EntityHydrator], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var DispositionActionColumnComponentNgFactory = i0.ɵccf("app-disposition-column", i4.DispositionActionColumnComponent, View_DispositionActionColumnComponent_Host_0, { node: "node", entity: "entity", component: "component", column: "column" }, {}, []);
export { DispositionActionColumnComponentNgFactory as DispositionActionColumnComponentNgFactory };
