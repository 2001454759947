import { Injectable } from '@angular/core';
import { AuthenticationService } from '../../core/authentication/authentication.service';
import { Observable } from 'rxjs';
import { LanguageService } from '../services/language/language.service';

import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent
} from '@angular/common/http';

@Injectable()
export class HeadersInterceptor implements HttpInterceptor {

    constructor(
        public auth: AuthenticationService,
        public languageService: LanguageService
    ) {

    }

    public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        const headers = {
          'Authorization': this.auth.getToken(),
          'x-accept-language': this.languageService.getLocalStorageLanguage()
        };

        if (!(request.body instanceof FormData)) {
          headers['Content-Type'] = 'application/json';
        }

        const params = request.params.set('lang', this.languageService.getLocalStorageLanguage());

        request = request.clone({
            setHeaders: headers,
            params: params
        });

        return next.handle(request);
    }
}
