<div class="fx-row" fxLayout="row" fxLayoutAlign="start stretch">

  <div fxFlex="50">
    <div class="fx-row" fxLayout="column" fxLayoutAlign="start stretch">
      <div fxFlex="20">

        <p-dropdown
          (onChange)="onSelectOrderPosition($event)"
          placeholder=" --- Bitte wählen --- "
          [options]="orderPositionsOptions"
          styleClass="flex-layout">
        </p-dropdown>
      </div>
      <div fxFlex="80">
        <shared-dynamic-table
          #assignmentTable
          [transferKey]="'test'"
          (onEntityTransfer)="onEntityTransfer($event)"
          [columns]="assignmentColumns"
          [totalCount]="assignmentTotalCount"
          [rowsCount]="50"
          [entities]="assignmentEntities"
          [isLoadingData]="assignmentIsLoadingData"
        ></shared-dynamic-table>
      </div>
    </div>
  </div>

  <div fxFlex="50">
    <shared-dynamic-table
      #leasedEmployeeTable
      [transferKey]="'test'"
      [columns]="leasedEmployeeColumns"
      [totalCount]="leasedEmployeeTotalCount"
      [rowsCount]="50"
      [entities]="leasedEmployeeEntities"
      [isLoadingData]="leasedEmployeeIsLoadingData"
      (onFilter)="onLeasedEmployeesFilter($event)"
      (onLazyLoad)="onLeasedEmployeesLazyLoad($event)"
    ></shared-dynamic-table>
  </div>

</div>
