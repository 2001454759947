
import {refCount, catchError, publishReplay} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AppHttpService } from '../../../app-http.service';
import { Datamodel } from './datamodel';
import { GenericCrudService } from '../generic-crud.service';
import {DataModelAbbrevation} from './data-model-abbrevation';

@Injectable()
export class DataModelAbbrevationCrudService extends AppHttpService {

  private url = 'superadmin/datamodelabbrevations';

  constructor(private genericCrudService: GenericCrudService) {
    super();
  }

  /**
   *
   * @returns {Observable<DataModelAbbrevation[]>}
   */
  getAll(): Observable<DataModelAbbrevation[]> {
    return this.genericCrudService.get(this.url).pipe(
      publishReplay(1),
      refCount(),
      catchError(this.handleError));
  }

  getByDatamodel(datamodel: Datamodel): Observable<DataModelAbbrevation[]> {
    return this.genericCrudService.get(this.url + `/datamodel/${datamodel.id}`).pipe(
      publishReplay(1),
      refCount(),
      catchError(this.handleError));
  }

  /**
   *
   * @param {number} id
   * @returns {Observable<DataModelAbbrevation>}
   */
  getOne(id: number): Observable<DataModelAbbrevation> {
    return this.genericCrudService.get(this.url + `/${id}`).pipe(
      publishReplay(1),
      refCount(),
      catchError(this.handleError));
  }

  /**
   *
   * @param {number} id
   * @returns {Observable<any>}
   */
  delete(id: number): Observable<any> {
    if (id > 0) {
      return this.genericCrudService.deleteEntity(`${this.url}/${id}`).pipe(catchError(this.handleError));
    }
    throw new Error('Invalid datamodel id given.');
  }

  /**
   *
   * @param {DataModelAbbrevation} datamodelAbbrevation
   * @returns {Observable<DataModelAbbrevation>}
   */
  create(datamodelAbbrevation: DataModelAbbrevation): Observable<DataModelAbbrevation> {
    if (datamodelAbbrevation) {
      return this.genericCrudService.createEntity(this.url, datamodelAbbrevation).pipe(
        catchError(this.handleError));
    } else {
      throw new Error('No datamodel abbrev. given.');
    }
  }

  /**
   *
   * @param {DataModelAbbrevation} datamodelAbbrevation
   * @returns {Observable<DataModelAbbrevation>}
   */
  edit(datamodelAbbrevation: DataModelAbbrevation): Observable<DataModelAbbrevation> {
    if (datamodelAbbrevation) {
      return this.genericCrudService.editEntity(`${this.url}/${datamodelAbbrevation.id}`, datamodelAbbrevation).pipe(
        catchError(this.handleError));
    }
    throw new Error('No datamodel given.');
  }
}
