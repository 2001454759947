import {Component, Input, ChangeDetectionStrategy, ChangeDetectorRef} from '@angular/core';
import {AbstractGenericGridComponent} from '../../../abstract-generic-grid.component';
import {FieldMetadataGrid} from '../../../../../services/module/module-element-field-metadata-grid';
import {Entity} from '../../../../../helpers/entity';
import {EntityHydrator} from '../../../../../services/entity-hydrator.service';
import { registerLocaleData } from '@angular/common';
import localeFr from '@angular/common/locales/fr';
registerLocaleData(localeFr, 'fr');


@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-table-read-column',
  templateUrl: './table-read-column.component.html',
  styleUrls: ['./table-read-column.component.scss']
})
export class TableReadColumnComponent {

  @Input() columnIndex: any = null;
  @Input() column: any = null;
  @Input() entity: any = null;
  @Input() component: AbstractGenericGridComponent = null;

  public constructor(
    public cdr: ChangeDetectorRef,
    private entityHydrator: EntityHydrator
  ) {

  }

  public getEntityAssociatedOneEntityValue(entity: any, column: any) {
    let value = Entity.getValue(entity, column.id, {
      allowBoolean: true
    });

    if (value === null) {
      value = Entity.getValueInEmbedded(entity, column.id, {
        allowBoolean: true
      });
    }

    if (column.fieldType === FieldMetadataGrid.TYPE_TRICHECKBOX) {
      return value;
    }

    if (column.fieldType === FieldMetadataGrid.TYPE_CHECKBOX
      || column.fieldType === FieldMetadataGrid.TYPE_LOCK_STATE) {
      return value || false;
    }

    if (column.fieldType === FieldMetadataGrid.TYPE_DATE) {
      return value;
    }

    return typeof value === 'string' || typeof value === 'number' ? value : '';
  }

  public getEntityAssociatedOneEntityValueLabel(entity: any, column: any) {
    const entityName = column.field.name.substring(0, column.field.name.lastIndexOf('.')), // extract string until latest '.' occurrence
      associatedOneEntity = this.entityHydrator.getEntityPropertyValue(entity, entityName);

    let label = associatedOneEntity ? FieldMetadataGrid.getOptionLabel(associatedOneEntity, column.field) : null;

    if (label === null) {
      label = Entity.getValue(entity, column.id) || Entity.getValueInEmbedded(entity, column.id);
    }

    return label;
  }

  public getEntityAssociatedManyEntityValue(entity: any, column: any) {
    const associatedEntitiesPath = column.id.substr(0, column.id.indexOf('.')),
      associatedEntityPropertyPath = column.id.substring(column.id.indexOf('.') + 1);

    let value = '';

    if (associatedEntitiesPath) {
      let embeddedEntities = entity[associatedEntitiesPath] && entity[associatedEntitiesPath] instanceof Array ?
        entity[associatedEntitiesPath] :
        null;

      if (!embeddedEntities === null &&
        associatedEntitiesPath &&
        entity._embedded &&
        entity._embedded &&
        entity._embedded[associatedEntitiesPath] instanceof Array
      ) {
        embeddedEntities = entity._embedded[column.entityName];
      }

      if (embeddedEntities instanceof Array) {
        for (let i = 0; i < embeddedEntities.length; i++) {
          const embeddedEntity = embeddedEntities[i];

          value += Entity.getValue(embeddedEntity, associatedEntityPropertyPath) ||
            Entity.getValueInEmbedded(embeddedEntity, associatedEntityPropertyPath);

          if (i + 1 !== embeddedEntities.length) {
            value += ', ';
          }
        }
      }
    }

    return value;
  }

  public getDatasetBranchOffice(entity) {
    const branchOffices = entity.branchOffices ? entity.branchOffices : [];
    let label = '';

    if (branchOffices && branchOffices.length > 0) {
      for (const branchOffice of branchOffices) {
        label += (branchOffice.name + ',');
      }
    }

    return label.substring(0, label.lastIndexOf(','));
  }
}
