import {
  Component,
  Input,
  OnInit,
  OnDestroy,
  AfterViewInit,
  ChangeDetectionStrategy, ChangeDetectorRef
} from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ElementInputComponent } from './element-input.component';
import { ElementInputImageUpload } from '../models';
import { FormService } from '../form.service';
import { FormViewerService } from '../form-viewer.service';
import {TranslateService} from '@ngx-translate/core';
import { environment } from '../../../../environments/environment';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-form-element-input-image-upload',
  template: `
    <div class="form-element-input-image-upload" *ngIf="!imageSrc">
      <div class="simple-upload" [formGroup]="formGroup">
        <input
          type="file"
          [readonly]="isReadOnly()"
          id="{{hashIdForUpload | genHash}}"
          (change)="onChangeImage($event)"
          tabindex="{{element.tabIndex}}"
        />
        <label for="{{hashIdForUpload | genHash}}" *ngIf="!isReadOnly()">
          <span class="fa fa-fw fa-upload"></span><br />
          <span class="upload-text">Bild hochladen</span>
        </label>
      </div>
    </div>
    <div class="form-element-input-image-preview" style="width: 100px; height: 120px; display: grid; position: relative;" *ngIf="imageSrc">
      <img [src]="imageSrc" alt="Bild Vorschau" style="max-height: 100%; max-width: 100%;" />
      <div class="image-action" (click)="onRemoveImage($event)" *ngIf="!isReadOnly()">
        entfernen
      </div>
    </div>
  `,
  styles: [`
    :host {
      height: 100%;
    }

    ::ng-deep .form-element-input-image-upload {
      height: 100%;
      width: 100%;
      display: flex;
      flex-direction: column;
      flex-wrap: nowrap;
      justify-content: center;
      align-content: stretch;
      align-items: center;
      border-radius: 3px;
    }

    ::ng-deep .form-element-input-image-upload .simple-upload {
        text-align: center;
    }

    ::ng-deep .form-element-input-image-preview {
      position: relative;
      border: 1px solid #a8a8a8;
      border-radius: 3px;
    }

    ::ng-deep .form-element-input-image-preview .image-action {
        position: absolute;
        cursor: pointer;
        text-align: center;
        font-size: .9em;
        background: rgba(255, 255, 255, .5);
        padding: .15rem 0;
        bottom: 0;
        width: 100%;
        border-bottom-left-radius: 3px;
        border-bottom-right-radius: 3px;
      }

      ::ng-deep .form-element-input-image-preview .image-action:hover {
        background: rgba(255, 255, 255, 1);
      }
    }
  `]
})
export class ElementInputImageUploadComponent extends ElementInputComponent implements OnInit, OnDestroy, AfterViewInit {
  @Input() element: ElementInputImageUpload;

  public hashIdForUpload: string;

  private environment;

  public imageSrc = '';

  constructor(
    protected formService: FormService,
    public cdr: ChangeDetectorRef,
    protected formViewerService: FormViewerService,
    private sanitizer: DomSanitizer,
    protected translateService?: TranslateService
  ) {
    super(formService, cdr, formViewerService, translateService);

    this.environment = environment;
  }

  onComponentInit() {
    this.setFormControlName();
    this.hashIdForUpload = `${new Date().getTime().toString(10)}bgUpload`;

    this.imageSrc = this.getImageUrl();

    this.markElementForCheck(this.element);

    this.filterActionAndHandleIt('oninit');
  }

  ngAfterViewInit() {
  }

  onChangeImage(event) {
    this.subscriptions.push(event.srcElement.files[0].toDataURL().subscribe((dataURL) => {
      this.imageSrc = dataURL;

      this.setValue(dataURL);

      this.markElementForCheck(this.element);
    }));
  }

  getImageUrl() {
    let imageUrl = null;

    const value = this.getValue() || this.getEntityValue();

    if (value) {
      if (this.entityHasImage()) {
        imageUrl = `${this.environment.uploadUrl}/${value}`;
      } else {
        imageUrl = this.sanitizer.bypassSecurityTrustUrl(this.getValue());
      }
    }

    return imageUrl;
  }

  onRemoveImage(event) {
    event.preventDefault();
    this.imageSrc = null;

    this.setValue(null);
    this.markElementForCheck(this.element);
  }

  public onComponentChanges() {
    this.imageSrc = this.getImageUrl();
  }

  private entityHasImage(): boolean {
    if (this.element.datamodelField.includes('.')) {
      const datamodelField = this.element.datamodelField.split('.');
      if (datamodelField.includes('fileName')) {
        return true;
      }
    }

    return this.entity && this.element && this.entity[this.element.datamodelField];
  }
}
