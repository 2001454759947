/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./generic-file-preview-pdf.component";
import * as i2 from "@angular/platform-browser";
var styles_GenericFilePreviewPdfComponent = [];
var RenderType_GenericFilePreviewPdfComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_GenericFilePreviewPdfComponent, data: {} });
export { RenderType_GenericFilePreviewPdfComponent as RenderType_GenericFilePreviewPdfComponent };
export function View_GenericFilePreviewPdfComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 0, "iframe", [["style", "width: 100%;height: 100%;border: none;"]], [[8, "src", 5]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.url; _ck(_v, 0, 0, currVal_0); }); }
export function View_GenericFilePreviewPdfComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-generic-file-preview-pdf", [], null, null, null, View_GenericFilePreviewPdfComponent_0, RenderType_GenericFilePreviewPdfComponent)), i0.ɵdid(1, 114688, null, 0, i1.GenericFilePreviewPdfComponent, [i2.DomSanitizer], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var GenericFilePreviewPdfComponentNgFactory = i0.ɵccf("app-generic-file-preview-pdf", i1.GenericFilePreviewPdfComponent, View_GenericFilePreviewPdfComponent_Host_0, { fileData: "fileData" }, {}, []);
export { GenericFilePreviewPdfComponentNgFactory as GenericFilePreviewPdfComponentNgFactory };
