import {
  Component, EventEmitter, Input, Output
} from '@angular/core';
import {ExpertSearch} from '../../../../services/expert-search/expert-search';

@Component({
  selector: 'app-search-management-edit-container-list',
  templateUrl: './search-management-edit-container-list.component.html',
  styles: [`
    .add-container-wrapper {
      padding: 2px;
    }
  
    .add-container-button {
      height: 89%;
      top: 2px;
      width: 100%;
    }
  `]
})
export class SearchManagementEditContainerListComponent {

  @Input() public expertSearch: ExpertSearch = null;

  @Output() onChange: EventEmitter<any> = new EventEmitter();

  public onAddContainer(event): void {
    const container = {
      expertSearch: this.expertSearch,
      fields: []
    };

    this.expertSearch.containers = [...this.expertSearch.containers, container];

    this.onChange.emit({
      container: container,
      context: 'containerAdd'
    });
  }
}
