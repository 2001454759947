import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {Subscription} from 'rxjs';
import {ToolbarItem} from '../../../../../services/element/toolbar-item';
import {ElementsStackService} from '../../../../services/elements-stack.service';
import {LocationService} from '../../../../../services/location.service';
import {ElementType} from '../../../../services/ElementContext';
import {GenericElementAbstract} from '../../../generic-element-abstract.component';
import {EntityDraftStoreService} from '../../../../services/entity-draft-store.service';
import {ModulesStateService} from '../../../../services/modules-state.service';
import {FormViewerComponent} from '../../../../../form-viewer/form-viewer.component';
import {AbstractGenericGridComponent} from '../../../abstract-generic-grid.component';

interface ToolbarItemAware {
  click();
}

export abstract class ToolbarItemAbstract implements OnInit, OnDestroy, ToolbarItemAware {

  public static CONTEXT_GRID = 'grid';
  public static CONTEXT_TREE = 'tree';
  public static CONTEXT_MODULE = 'module';
  public static CONTEXT_FORM_VIEWER = 'form-viewer';
  public static CONTEXT_QUESTIONNAIRE = 'questionnaire';

  protected service;

  protected subscriptions: Subscription[] = [];

  private validateMessage: string;

  @Input() params: any[];

  public getBackgroundColor() {
    return this.getToolbarItem().backgroundColor;
  }

  protected getParams() {
    return this.params;
  }

  protected getParam(key) {
    return this.params[key];
  }

  protected getToolbarItem(): ToolbarItem {
    return this.params['toolbarItem'];
  }

  protected isInCurrentContext(context) {
    let isInCurrentContext = false;

    switch (context) {
      case ToolbarItemAbstract.CONTEXT_GRID:
        isInCurrentContext = this.getParam('gridComponent') && this.getToolbarItem().contextTarget !== 'module';
        break;
      case ToolbarItemAbstract.CONTEXT_TREE:
        isInCurrentContext = this.getParam('treeComponent') && this.getToolbarItem().contextTarget !== 'module';
        break;
      case ToolbarItemAbstract.CONTEXT_MODULE:
        isInCurrentContext = this.getToolbarItem().contextTarget === 'module';
        break;
      case ToolbarItemAbstract.CONTEXT_FORM_VIEWER:
        isInCurrentContext = this.getParam('formViewerComponent') && this.getToolbarItem().contextTarget !== 'module';
        break;
      case ToolbarItemAbstract.CONTEXT_QUESTIONNAIRE:
        isInCurrentContext = this.getParam('questionnaireComponent') && this.getToolbarItem().contextTarget !== 'module';
        break;
      default:
    }

    return isInCurrentContext;
  }

  protected figureOutContext() {
    let context = null;

    if (this.isInCurrentContext(ToolbarItemAbstract.CONTEXT_MODULE)
      || this.getToolbarItem().contextTarget === ToolbarItem.CONTEXT_TARGET_MODULE) {
      context = ToolbarItemAbstract.CONTEXT_MODULE;
    } else if (this.isInCurrentContext(ToolbarItemAbstract.CONTEXT_GRID)
      || this.getToolbarItem().contextTarget === ToolbarItem.CONTEXT_TARGET_GRID) {
      context = ToolbarItemAbstract.CONTEXT_GRID;
    } else if (this.isInCurrentContext(ToolbarItemAbstract.CONTEXT_TREE)
      || this.getToolbarItem().contextTarget === ToolbarItem.CONTEXT_TARGET_TREE) {
      context = ToolbarItemAbstract.CONTEXT_TREE;
    } else if (this.isInCurrentContext(ToolbarItemAbstract.CONTEXT_FORM_VIEWER)
      || this.getToolbarItem().contextTarget === ToolbarItem.CONTEXT_TARGET_FORM) {
      context = ToolbarItemAbstract.CONTEXT_FORM_VIEWER;
    }

    return context;
  }

  public abstract click();

  protected setValidateMessage(validateMessage: string) {
    this.validateMessage = validateMessage;

    return this;
  }

  protected handleEntityLoaded(
    entity: any,
    component: GenericElementAbstract,
    elementsStackService: ElementsStackService,
    locationService: LocationService,
    entityDraftService: EntityDraftStoreService,
    modulesStateService: ModulesStateService
  ) {
    const moduleState = modulesStateService.getByComponent(component);

    moduleState.entity = entity;

    for (const elementContext of elementsStackService.getAll()) {
      if (elementContext.isSubView && locationService.hasParam('master-entity')) {
        elementsStackService.setMasterConfig(elementContext, moduleState.entity, locationService.getParam('master-entity'));
        entityDraftService.clear();
      }

      if (elementContext.component && elementContext.component instanceof FormViewerComponent &&
        elementContext.component.getElementDataModelApiRoute() === component.getElementDataModelApiRoute()
      ) {
        elementContext.component.setEntity(moduleState.entity);
      }

      const components = moduleState.getAllComponents();

      for (const aComponent of components) {
        if (aComponent instanceof AbstractGenericGridComponent) {
          aComponent.setSelectedEntity(null);
          if (aComponent.elementContext.isMaster) {
            aComponent.selectEntity(moduleState.entity).onEntitySelected();
          }
        }
      }
    }

    // xcentric, basically, remove all these calls!
    component.getEntityDataStore().onEntityChanged({
      entity: moduleState.entity
    });
  }

  protected getValidateMessage(): string {
    return this.validateMessage;
  }

  protected getEntity() {
    let entity = null;

    if (this.isInCurrentContext(ToolbarItemAbstract.CONTEXT_FORM_VIEWER)) {
      entity = this.getParam('formViewerComponent').entity;
    } else if (this.isInCurrentContext(ToolbarItemAbstract.CONTEXT_GRID)) {
      entity = this.getParam('gridComponent').selectedEntity;
    } else if (this.isInCurrentContext(ToolbarItemAbstract.CONTEXT_TREE)) {
      entity = this.getParam('treeComponent').selectedEntity;
    } else if (this.getParam('memoFieldDefinitionValueComponent')) {
      entity = this.getParam('memoFieldDefinitionValueComponent').memo;
    }

    if (!entity) {
      const formComponent = this.getParam('formViewerComponent');
      if (formComponent && formComponent.entity) {
        entity = formComponent.entity;
      }
    }

    return entity;
  }

  protected getDatamodel() {
    const datamodel = this.getComponent().getElementDatamodel();

    return (datamodel) ? datamodel : null;
  }

  protected getComponent(): GenericElementAbstract {
    let component = null;

    if (this.getParam('gridComponent')) {
      component = this.getParam('gridComponent');
    } else if (this.getParam('formViewerComponent')) {
      component = this.getParam('formViewerComponent');
    } else if (this.getParam('treeComponent')) {
      component = this.getParam('treeComponent');
    } else if (this.getParam('memoFieldDefinitionValueComponent')) {
      component = this.getParam('memoFieldDefinitionValueComponent');
    }

    return component;
  }

  ngOnInit() { }

  ngOnDestroy() {
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }
}
