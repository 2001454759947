import { Component, Input, OnInit } from '@angular/core';
import { cloneDeep } from 'lodash';
import { AbstractGenericGridComponent } from 'app/shared/content-renderer/elements/abstract-generic-grid.component';
import {ToolbarItemAbstract} from '../../toolbar-item-abstract.component';
import {ToolbarItem} from '../../../../../../../services/element/toolbar-item';
import {MessageGrowlService} from '../../../../../../../../core/message/message-growl.service';
import {DoubleClickService} from '../../../../../../services/double-click.service';
import {MasterEntityConfig} from '../../../../../../services/ElementContext';
import {ReloadAssignmentsCondition} from '../../../../../../../job-runner/condition/reload-assignments.condition';
import {ReloadAssignmentsGridJob} from '../../../../../../../job-runner/job/reload-assignments-grid.job';
import {JobContainerService} from '../../../../../../../../core/job-runner/job-container.service';

@Component({
  selector: 'toolbar-item-open-module-assignments',
  template: ''
})
export class ToolbarItemOpenModuleAssignmentsComponent extends ToolbarItemAbstract {

  private toolbarItem: ToolbarItem;
  private entity: any;

  constructor(
    private messageGrowlService: MessageGrowlService,
    private doubleClickService: DoubleClickService,
    private jobContainerService: JobContainerService
  ) {
    super();
  }

  ngOnInit() {

  }

  public click() {
    const component = this.getComponent();

    if (this.getToolbarItem().actionModuleId && component instanceof AbstractGenericGridComponent) {
        this.doOpenModuleInGridContext(component);
    }
  }

  private doOpenModuleInGridContext(gridComponent: AbstractGenericGridComponent) {
    this.toolbarItem = this.getToolbarItem();

    if (!this.validateInGridContext(gridComponent)) {
      return this.messageGrowlService.error(this.getValidateMessage());
    }

    if (gridComponent.selectedEntity) {
      this.entity = gridComponent.selectedEntity;
    } else {
      this.entity = cloneDeep(gridComponent.emptyEntity);
    }

    const masterEntity = this.getMasterEntity(gridComponent);

    if (masterEntity) {
      const condition = new ReloadAssignmentsCondition();
      condition.setPayload({entity: null, component: gridComponent});
      const job = new ReloadAssignmentsGridJob();
      job.setIsImmutable(true);
      this.jobContainerService.registerJob(job, condition);

      this.doubleClickService.setComponent(gridComponent)
        .setDialogOptions({
          height: +this.getToolbarItem().targetDialogHeight,
          width: +this.getToolbarItem().targetDialogWidth
        })
        .handleOpenModule(gridComponent, this.getToolbarItem().target, this.getToolbarItem().actionModuleId, this.entity, masterEntity);
    }
  }

  private getMasterEntity(gridComponent: AbstractGenericGridComponent): MasterEntityConfig|null {
    const context = gridComponent.getElementContext();
    let result = null;

    for (const masterEntityConfig of context.masterEntities) {
      if (masterEntityConfig.name === 'order') {
        result = {
          id: masterEntityConfig.value,
          fqn: 'PhoenixBundle\\Entity\\Order'
        };
        break;
      }
    }

    return result;
  }

  private validateInGridContext(gridComponent) {
    let isValid = true;

    if (!this.toolbarItem.actionModuleId) {
      this.setValidateMessage('Toolbar Item action module not assigned.');

      isValid = false;
    }

    return isValid;
  }
}
