import {ChangeDetectionStrategy, Component} from '@angular/core';
import {ToolbarItemAbstract} from '../toolbar-item-abstract.component';
import {MessageGrowlService} from '../../../../../../../core/message/message-growl.service';
import {MessageService} from '../../../../../../../core/message/message.service';
import {GenericCrudService} from '../../../../../../services/generic-crud.service';
import {ComponentService} from '../../../../../services/component-highlight-stack.service';
import {EntityDataStoreService} from '../../../../../services/entity-data-store.service';
import {TranslateService} from '@ngx-translate/core';
import {GenericDialogModuleService} from '../../../../generic-dialog/service/generic-dialog-module.service';
import {ModulesStateService} from '../../../../../services/modules-state.service';
import {EntityManagerService} from '../../../../../../../core/service/entity-manager/entity-manager.service';
import {FormViewerComponent} from '../../../../../../form-viewer/form-viewer.component';
import {AbstractGenericGridComponent} from '../../../../abstract-generic-grid.component';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-toolbar-item-take-address-to-master',
  templateUrl: './toolbar-item-take-address-to-master.component.html'
})
export class ToolbarItemTakeAddressToMasterComponent extends ToolbarItemAbstract {

  private readonly ADDRESS_FIELD = 'address';

  private readonly entityNotSelectedError = 'Entity not selected';
  private readonly entityHasNoAddress = 'Selected Entity don`t have fullAddress';

  private masterEntity: any;
  private masterEntityEditingField: any;

  constructor(
    protected messageGrowlService: MessageGrowlService,
    protected messageService: MessageService,
    protected genericCrudService: GenericCrudService,
    private entityDataStore: EntityDataStoreService,
    protected componentService: ComponentService,
    protected translateService: TranslateService,
    protected genericModuleDialogService: GenericDialogModuleService,
    protected modulesStateService: ModulesStateService,
    protected entityManager: EntityManagerService
  ) {
    super();
  }

  public click() {
    const component = this.getComponent();

    if (component instanceof AbstractGenericGridComponent) {
      this.handleTakingAddressInGridContext(component);
    }
  }

  private handleTakingAddressInGridContext(component: AbstractGenericGridComponent) {
    const entity = component.selectedEntity;
    this.masterEntity = component.masterEntity;
    this.masterEntityEditingField = component.masterEntityEditingField;

    if (!entity) {
      return this.messageGrowlService.error(this.entityNotSelectedError);
    }
    if (!entity.fullAddress) {
      return this.messageGrowlService.error(this.entityHasNoAddress);
    }

    this.onSetMasterEntity(entity, component);
  }

  public onSetMasterEntity(entity, component) {
    this.setMasterEntity(entity, component);
    this.genericModuleDialogService.hideDialog();
    this.removeModuleState();
  }

  setMasterEntity(entity, component) {
    this.masterEntity[this.masterEntityEditingField] = entity.fullAddress;

    // TODO refactor this part to be handled by job
    this.masterEntity[this.ADDRESS_FIELD] = entity;

    this.masterEntity.isChanged = true;
    this.entityManager.persist(this.masterEntity, {property: this.ADDRESS_FIELD, newValue: entity });
    this.entityManager.persist(this.masterEntity, {property: this.masterEntityEditingField, newValue: entity.fullAddress });

    if (component.masterElementContext && component.masterElementContext.component &&
      component.masterElementContext.component instanceof FormViewerComponent
    ) {
      this.changeValueInForm(component.masterElementContext.component, this.ADDRESS_FIELD, entity);
      this.changeValueInForm(component.masterElementContext.component, this.masterEntityEditingField, entity.fullAddress);
    } else {
      this.entityDataStore.onEntityChanged({
        entity: this.masterEntity
      });
    }
  }

  private removeModuleState(): void {
    const moduleState = this.modulesStateService.getByComponent(this.getComponent());

    if (moduleState && moduleState.isDialog && moduleState.id === this.getComponent().moduleElement.moduleId) {
      this.modulesStateService.remove(moduleState);
    }
  }

  private changeValueInForm(component: FormViewerComponent, datamodelField: string, value: any): void {
    const addressElement = component.inputElements.find((aElement) => {
      return aElement.datamodelField === datamodelField;
    });

    if (addressElement) {
      const formControlName = FormViewerComponent.getFormControlName(addressElement.objectHashId, addressElement.datamodelField);

      component.onFormValueChange({
        formControlValue: value,
        element: addressElement,
        entityValue: value,
        formControlName: formControlName,
        formControlOptions: {},
        triggerChange: true,
        entity: this.masterEntity,
        component: null
      });
    }
  }
}
