import { Element, ElementLayout } from './element';

export class ElementLayoutColumns extends ElementLayout {
  constructor(
    public children: any[] = []
  ) {
    super();
    this.typeElement = 'columns';
    this.canHaveChildren = true;
  }
}

export class ElementLayoutColumnsItem extends ElementLayout {
  constructor(
    public children: any[] = [],
    public columnWidth: string = undefined
  ) {
    super();
    this.typeElement = 'columns-item';
    this.label = 'Spalte';
    this.canHaveChildren = true;
  }
}
