
    <div [formGroup]="formGroup" class="ui-g ui-g-12">
      <p-fieldset [legend]="element.label" [tabIndex]="element.tabIndex">
        <p-selectButton [options]="buttonOptions" optionLabel="label" optionValue="value" (onChange)="changeOption($event)"></p-selectButton>
        <div class="ui-g-12 ui-md-4" *ngIf="isDeploymentRangeEnabled">
          <div class="ui-inputgroup">
            <input type="text" pInputText placeholder="Arbeiten im Umkreis von" (keyup)="changeDistance($event)" formControlName="deploymentRange">
            <span class="ui-inputgroup-addon">KM</span>
          </div>
        </div>
        <div class="ui-g-12 ui-md-12" *ngIf="!isDeploymentRangeEnabled">
          <p-multiSelect [options]="countries" formControlName="countries" (onChange)="onChangeCountry($event)" optionLabel="name" appendTo="body"></p-multiSelect>
          <img alt="Österreich auswählen" src="assets/images/austria-flag-icon-16.png" style="padding-bottom: 4px; padding-left: 4px;" (click)="selectAustria()">
          <p-listbox
            appSeleniumDirective
            [element]="element"
            [options]="regions"
            [checkbox]="true" [filter]="true" [multiple]="true"
            formControlName="regions"
            (onChange)="onChangeRegions($event)"
            [listStyle]="{'max-height':'100px', 'min-height':'100px'}"
          >
          </p-listbox>
        </div>
      </p-fieldset>
    </div>
  