import { of as observableOf, forkJoin as observableForkJoin, Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AbstractExecutionStep } from '../../../core/executor/abstract-execution-step';
import { AbstractGenericGridComponent } from '../../content-renderer/elements/abstract-generic-grid.component';
import { EntityStatus } from '../entity/entity-status';
export class ComponentSlaveIndependantSaveExecutionStep extends AbstractExecutionStep {
    constructor() {
        super(...arguments);
        this.commitedEntities = [];
        this.deletedEntities = [];
    }
    doExecute() {
        const payload = this.getPayload();
        if (!(payload.getValue() instanceof AbstractGenericGridComponent)) {
            return this.getFailObservable('You need to pass AbstractGenericGridComponent as Payload value!');
        }
        this.prepareSave(payload.getValue());
        return this.doSave(payload.getValue());
    }
    prepareSave(grid) {
        const masterEntityField = this.getMasterEntityField(grid.getElementContext());
        const createdEntities = grid.getCreatedEntities(true);
        for (const entity of createdEntities) {
            if (!entity[EntityStatus.ENTITY_DRAFT_DELETED_FLAG] && this.isMasterEntityFieldSet(entity, masterEntityField)) {
                this.commitedEntities.push(entity);
            }
        }
        const updatedEntities = grid.getUpdatedEntities(true);
        for (const entity of updatedEntities) {
            if (!entity[EntityStatus.ENTITY_DRAFT_DELETED_FLAG] && this.isMasterEntityFieldSet(entity, masterEntityField)) {
                this.commitedEntities.push(entity);
            }
        }
        const deletedEntitiesDraft = grid.getDraftDeletedEntities();
        for (const entity of deletedEntitiesDraft) {
            this.deletedEntities.push(entity);
        }
    }
    isMasterEntityFieldSet(entity, masterEntityField) {
        return entity['_embedded'] ? entity['_embedded'].hasOwnProperty(masterEntityField) : false;
    }
    doSave(grid) {
        const observables = [];
        for (const entity of this.commitedEntities) {
            if (entity.id) {
                observables.push(grid.getGenericCrudService().editEntity(`${grid.getElementDataModelApiRoute()}/${entity.id}`, entity));
            }
            else {
                observables.push(grid.getGenericCrudService().createEntity(grid.getElementDataModelApiRoute(), entity));
            }
        }
        for (const entity of this.deletedEntities) {
            if (entity.id) {
                observables.push(grid.getGenericCrudService().deleteEntity(`${grid.getElementDataModelApiRoute()}/${entity.id}`));
            }
        }
        return new Observable((observer) => {
            if (observables.length === 0) {
                observer.next({
                    status: true,
                    content: null,
                });
                observer.complete();
            }
            observableForkJoin(observables).pipe(catchError((response) => {
                return observableOf(response);
            }))
                .subscribe(results => {
                observer.next({
                    status: true,
                    content: results
                });
                observer.complete();
            });
        });
    }
}
