import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { Data, Parameter } from 'app/shared/content-renderer/services/data';
import { GenericCrudService } from '../../services/generic-crud.service';
import {Guid} from 'guid-typescript';

@Injectable()
export class DataSharingService {

    private dataDictionary: { [hash: string] : Data; } = {};

    public constructor(
        protected genericCrudService: GenericCrudService
    ) {

    }

    /**
     *
     * @param parameters
     */
    public generate(parameters: Parameter[]): Data {
        const hash = Guid.create().toString();

        const data = new Data(hash);
        data.setParameters(parameters);

        this.add(data);

        return data;
    }

    public getAll(): {} {
        return this.dataDictionary;
    }

    public getOne(hash: string): Data {
        return this.dataDictionary[hash];
    }

    /**
     *
     * @param {Data} data
     * @returns {DataSharingService}
     */
    public remove(data: Data): DataSharingService {
        if (data && this.exists(data)) {
            this.removeByHash(data.hash);
        }

        return this;
    }

    /**
     *
     * @param {string} hash
     * @returns {DataSharingService}
     */
    public removeByHash(hash: string): DataSharingService {
        this.removeRemote(hash)
            .removeLocal(hash);

        return this;
    }

    /**
     *
     * @param {Data} data
     * @returns {boolean}
     */
    public exists(data: Data): boolean {
        return typeof this.dataDictionary[data.hash] !== 'undefined' && this.dataDictionary[data.hash] !== null;
    }

    public addParameter(data: Data, parameter: Parameter): DataSharingService {
        this.addParameterLocal(data, parameter)
            .editRemote(data);
        return this;
    }

    public editParameter(data: Data, parameter: Parameter, value: any): DataSharingService {
        this.editParameterLocal(data, parameter, value)
            .editRemote(data);
        return this;
    }

    public removeParameter(data: Data, parameter: Parameter): DataSharingService {
        this.removeParameterLocal(data, parameter)
            .editRemote(data);
        return this;
    }

    /**
     * @description Adds generated Data to dictionary
     * @param {Data} data
     *
     * @throws Error when Data with hash already exists
     *
     * @returns {DataSharingService}
     */
    private add(data: Data): DataSharingService {

        if (this.exists(data)) {
            throw new Error(`Data with hash ${data.hash} already exists`);
        }

        this.addRemote(data)
            .addLocal(data);

        return this;
    }

    private addRemote(data: Data): DataSharingService {
        /**
        this.genericCrudService.createEntity('data', data)
            .subscribe(() => {

        });
        */

        return this;
    }

    private editRemote(data: Data): DataSharingService {
        /**
        this.genericCrudService.editEntity('data', data)
            .subscribe(() => {

        });
        */

        return this;
    }

    private removeRemote(hash: string): DataSharingService {
        /**
        this.genericCrudService.deleteEntity(this.dataDictionary[hash])
            .subscribe(() => {

        });
        */

        return this;
    }

    private addLocal(data: Data): DataSharingService {
        this.dataDictionary[data.hash] = data;
        return this;
    }

    private removeLocal(hash: string): DataSharingService {
        delete this.dataDictionary[hash];
        return this;
    }

    private addParameterLocal(data: Data, parameter: Parameter): DataSharingService {
        data = data.addParameter(parameter);
        return this;
    }

    private editParameterLocal(data: Data, parameter: Parameter, value: any): DataSharingService {
        let newParameter = new Parameter(
            parameter.key,
            value
        );

        data = data.removeParameter(parameter).addParameter(newParameter);
        return this;
    }

    private removeParameterLocal(data: Data, parameter: Parameter): DataSharingService {
        data = data.removeParameter(parameter);
        return this;
    }
}
