/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "./precalculation-article-global-fields-column.component";
import * as i3 from "../../../../../services/generic-crud.service";
var styles_PrecalculationArticleGlobalFieldsColumnComponent = [];
var RenderType_PrecalculationArticleGlobalFieldsColumnComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_PrecalculationArticleGlobalFieldsColumnComponent, data: {} });
export { RenderType_PrecalculationArticleGlobalFieldsColumnComponent as RenderType_PrecalculationArticleGlobalFieldsColumnComponent };
function View_PrecalculationArticleGlobalFieldsColumnComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Calculating..."]))], null, null); }
function View_PrecalculationArticleGlobalFieldsColumnComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i0.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.salary; _ck(_v, 1, 0, currVal_0); }); }
export function View_PrecalculationArticleGlobalFieldsColumnComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵand(16777216, null, null, 1, null, View_PrecalculationArticleGlobalFieldsColumnComponent_1)), i0.ɵdid(1, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_PrecalculationArticleGlobalFieldsColumnComponent_2)), i0.ɵdid(3, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.isLoading; _ck(_v, 1, 0, currVal_0); var currVal_1 = !_co.isLoading; _ck(_v, 3, 0, currVal_1); }, null); }
export function View_PrecalculationArticleGlobalFieldsColumnComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-precalculation-article-global-fields-column", [], null, null, null, View_PrecalculationArticleGlobalFieldsColumnComponent_0, RenderType_PrecalculationArticleGlobalFieldsColumnComponent)), i0.ɵdid(1, 114688, null, 0, i2.PrecalculationArticleGlobalFieldsColumnComponent, [i0.ElementRef, i3.GenericCrudService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var PrecalculationArticleGlobalFieldsColumnComponentNgFactory = i0.ɵccf("app-precalculation-article-global-fields-column", i2.PrecalculationArticleGlobalFieldsColumnComponent, View_PrecalculationArticleGlobalFieldsColumnComponent_Host_0, { node: "node", entity: "entity", component: "component", column: "column" }, {}, []);
export { PrecalculationArticleGlobalFieldsColumnComponentNgFactory as PrecalculationArticleGlobalFieldsColumnComponentNgFactory };
