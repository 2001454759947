import {FormViewerComponent} from '../../../../../../../form-viewer/form-viewer.component';
import {Element, ElementInput} from '../../../../../../../form-viewer/models';
import {Entity} from '../../../../../../../helpers/entity';
import {ElementInputValidator} from '../../../../../../../form-viewer/models/element';

const GRADE_FIELD = 'grade';
const GRADE_NOTES_FIELD = 'gradeNotes';

export class ContractOfEmploymentGradeNotesService {

  private form: FormViewerComponent = null;

  public setForm(form: FormViewerComponent): this {
    this.form = form;
    return this;
  }

  public setupGradeNotes(): void {
    const entity = this.form.getSelectedEntity();

    if (entity && entity.fqn) {
      const grade = Entity.getValue(entity, GRADE_FIELD) ||
        Entity.getValueInEmbedded(entity, GRADE_FIELD);

      if (grade && grade && grade.code && +grade.code >= 3) {
        this.doSetGradeNotesElementRequired(entity.fqn, true);
      } else {
        this.doSetGradeNotesElementRequired(entity.fqn, false);
      }
    }
  }

  public doSetGradeNotesElementRequired(fqn: string, isRequired: boolean): void {
    const element = this.getGradeNotesElement();

    if (element) {
      const validatorIndex = element.validators.findIndex((aValidator: ElementInputValidator) => {
        return aValidator.key === ElementInputValidator.REQUIRED_KEY;
      });

      if (validatorIndex !== -1) {
        element.validators.splice(validatorIndex, 1);
      }

      this.form.getValidator().removeFormValidations(fqn, element);

      const fieldsetElement = this.getExitInformationFieldsetElement();

      if (isRequired && fieldsetElement !== null && !fieldsetElement.isHidden) {
        element.validators.push({
          key: ElementInputValidator.REQUIRED_KEY
        });

        this.form.getValidator().addFormValidations(fqn, element);
      }
    }
  }

  public getExitInformationFieldsetElement(): Element|null {
    const gradeNotes = this.getGradeNotesElement();

    if (typeof gradeNotes !== 'undefined' && gradeNotes !== null) {
      return gradeNotes.parent;
    }

    return null;
  }

  public getGradeNotesElement(): ElementInput|null {
    return this.form.inputElements.find((aElement: ElementInput) => {
      return aElement.datamodelField === GRADE_NOTES_FIELD;
    });
  }
}
