import {EMPTY, forkJoin as observableForkJoin, forkJoin, Observable, of, of as observableOf} from 'rxjs';
import {catchError, map, switchMap, take, tap} from 'rxjs/operators';
import {AbstractExecutionStep} from '../../../../core/executor/abstract-execution-step';
import {ExecutionStepStatus} from '../../../../core/executor/execution-step-status';
import {FormViewerComponent} from '../../../form-viewer/form-viewer.component';
import {ElementType} from '../../../content-renderer/services/ElementContext';
import {ElementSaveStatus} from '../../../content-renderer/elements/generic-element-abstract.component';
import {MessageGrowlService} from '../../../../core/message/message-growl.service';
import {TranslateService} from '@ngx-translate/core';
import {CustomerInvoicePositionTableComponent} from '../../../content-renderer/elements/custom/invoice/customer-invoice-position-table.component';
import {ElementFormEntityService} from '../../element-form/element-form.entity.service';
import {CustomerInvoicePositionDetailTableComponent} from '../../../content-renderer/elements/custom/invoice/customer-invoice-position-detail-table.component';
import {EntityStatus} from '../../entity/entity-status';
import {ModuleNavigationService} from '../../../content-renderer/services/navigation/module-navigation.service';
import {CustomerInvoicePositionDetailInvoiceContextTableComponent} from '../../../content-renderer/elements/custom/invoice/customer-invoice-position-detail-invoice-context-table.component';
import {ChangeDetectorRefHelper} from '../../../helpers/change-detector-ref.helper';
import {TOAST_LIFE_INFINITE, ToastComponentsRegistry, ToastService} from '../../../../core/service/toast.service';

export class SaveInvoiceExecutionStep extends AbstractExecutionStep {

  public doExecute(): Observable<ExecutionStepStatus> {
    const payload = this.getPayload(),
      component = payload.getValue().component ? payload.getValue().component : payload.getValue();

    if (component instanceof FormViewerComponent) {
      return this.doSave(component);
    }

    return observableOf({status: false, content: 'No component found!' });
  }

  private doSave(component: FormViewerComponent): Observable<ExecutionStepStatus> {
    this.getToastService().custom(ToastComponentsRegistry.PROGRESS_BAR, {
      severity: 'info',
      life: TOAST_LIFE_INFINITE,
      closable: false,
      summary: this.getTranslate().instant('COMMON.LOADING_PLEASE_WAIT')
    });
    if (component.getSelectedEntity().isSimpleView) {
      return this.saveSimpleInvoice(component);
    }

    return this.saveInvoice(component);
  }

  private saveSimpleInvoice(component: FormViewerComponent): Observable<ExecutionStepStatus> {
    return this.saveFormEntity(component).pipe(
      switchMap((invoice: any) => {
        component.setEntity(invoice);
        this.setUrl(component, invoice);
        return this.saveOrGetSingleInvoicePosition(component, invoice).pipe(
          map((invoicePosition) => {
            return { invoice, invoicePosition }
          })
        )
      }),
      switchMap(({ invoice, invoicePosition }) => {
        return this.saveInvoicePositionsDetailsInList(component, invoice, invoicePosition).pipe(
          switchMap(() => {
            const invoicePositionsDetailsComponent = this.getInvoicePositionsSingleDetailsComponent(component);

            if (!invoicePositionsDetailsComponent) {
              return of(null).pipe(take(1));
            }

            return invoicePositionsDetailsComponent.loadEntities();
          })
        )
      }),
      switchMap(() => {
        return component.onRefresh();
      }),
      map(() => {
        return { status: true, content: '' };
      }),
      tap(() => {
        this.getToastService().clear(ToastComponentsRegistry.PROGRESS_BAR);
        this.showSuccess();
      })
    );
  }

  private saveOrGetSingleInvoicePosition(component: FormViewerComponent, savedInvoice: any): Observable<any> {
    return this.genericCrudService.getEntities('phoenix/invoicepositions', '', {
      invoice: savedInvoice.id,
      embedded: 'none'
    }).pipe(
      switchMap((positions) => {
        if (positions.length > 0) {
          return of(positions[0]).pipe(take(1));
        }

        return this.genericCrudService.createEntity('phoenix/invoicepositions?embedded=none', {
          [EntityStatus.ENTITY_CHANGED_FLAG]: true,
          fqn: 'PhoenixBundle\\Entity\\InvoicePosition',
          invoice: savedInvoice,
        });
      })
    )
  }

  private saveInvoice(component: FormViewerComponent): Observable<ExecutionStepStatus> {
    const invoicePositionsComponent = this.getInvoicePositionsComponent(component);

    return this.saveFormEntity(component).pipe(
      switchMap((invoice: any) => {
        component.setEntity(invoice);
        this.setUrl(component, invoice);
        return this.saveInvoicePositions(component, invoice).pipe(
          switchMap(() => {
            return this.saveInvoicePositionsDetailsInExpander(invoicePositionsComponent)
          })
        )
      }),
      switchMap(() => {
        return invoicePositionsComponent.loadEntities();
      }),
      map(() => {
        return { status: true, content: '' };
      }),
      tap(() => {
        this.getToastService().clear(ToastComponentsRegistry.PROGRESS_BAR);
        this.showSuccess();
      })
    );
  }

  private saveFormEntity(component: FormViewerComponent): Observable<any> {
    const formEntity = component.getSelectedEntity();

    if (formEntity[EntityStatus.ENTITY_CHANGED_FLAG]) {
      return this.injector.get(ElementFormEntityService).saveEntity(`phoenix/invoices${formEntity.id ? '/' + formEntity.id : ''}`, formEntity)
    }

    return of(formEntity).pipe(take(1));
  }

  private saveInvoicePositions(component: FormViewerComponent, savedInvoice: any): Observable<any> {
    const invoicePositionsComponent = this.getInvoicePositionsComponent(component);

    invoicePositionsComponent.invoice = savedInvoice;
    invoicePositionsComponent.isEditable = savedInvoice.isEditable;
    return invoicePositionsComponent.onSave();
  }

  private saveInvoicePositionsDetailsInExpander(invoicePositionsComponent: CustomerInvoicePositionTableComponent): Observable<any> {
    const observables = [];

    if (invoicePositionsComponent.table && invoicePositionsComponent.table.expanderComponents) {
      const invoicePositionsDetailsComponents = invoicePositionsComponent.table.expanderComponents as CustomerInvoicePositionDetailTableComponent[];

      for (const invoicePositionsDetailsComponent of invoicePositionsDetailsComponents) {
        const currentInvoicePosition = invoicePositionsDetailsComponent.invoicePosition;
        invoicePositionsDetailsComponent.invoicePosition = invoicePositionsComponent.entities.find((aInvoicePosition) =>
          aInvoicePosition[EntityStatus.ENTITY_DRAFT_FLAG] === currentInvoicePosition[EntityStatus.ENTITY_DRAFT_FLAG]);

        observables.push(invoicePositionsDetailsComponent.onSave());
      }
    }

    if (observables.length === 0) {
      return of([]).pipe(take(1));
    }

    return forkJoin(observables);
  }

  private saveInvoicePositionsDetailsInList(component: FormViewerComponent, invoice: any, invoicePosition: any): Observable<any> {
    const invoicePositionsDetailsComponent = this.getInvoicePositionsSingleDetailsComponent(component);
    const invoicePositionsComponent = this.getInvoicePositionsComponent(component)

    invoicePositionsComponent.invoice = invoice;
    ChangeDetectorRefHelper.detectChanges(invoicePositionsComponent);

    if (!invoicePositionsDetailsComponent) {
      return of(null).pipe(take(1));
    }

    invoicePositionsDetailsComponent.invoice = invoice;
    invoicePositionsDetailsComponent.invoicePosition = invoicePosition;

    return invoicePositionsDetailsComponent.onSave()
  }

  private setUrl(component: FormViewerComponent, invoice: any): void {
    const moduleState = this.modulesStateService.getByComponent(component);

    if (invoice && invoice.id && !this.locationService.hasParam('id')) {
      const route = this.injector.get(ModuleNavigationService).getModuleRouter().replaceRouteEntity(invoice);

      if (moduleState) {
        moduleState.url = route;
      }
    }
  }

  private getInvoicePositionsComponent(component: FormViewerComponent): CustomerInvoicePositionTableComponent {
    const moduleState = this.modulesStateService.getByComponent(component);
    return moduleState.getAllComponents().find((aComponent) => {
      return aComponent.getElementContext().type === ElementType.Grid;
    }) as CustomerInvoicePositionTableComponent;
  }

  private getInvoicePositionsSingleDetailsComponent(component: FormViewerComponent): CustomerInvoicePositionDetailInvoiceContextTableComponent {
    return this.getInvoicePositionsComponent(component).invoicePositionsDetailsTable as CustomerInvoicePositionDetailInvoiceContextTableComponent;
  }

  protected showSuccess(): void {
    this.getGrowl().success(
      this.getTranslate().instant('COMMON.DATA_SAVED'),
      this.getTranslate().instant('COMMON.SUCCESS')
    );
  }

  private getGrowl(): MessageGrowlService {
    return this.injector.get(MessageGrowlService, null);
  }

  private getTranslate(): TranslateService {
    return this.injector.get(TranslateService, null);
  }

  private getToastService(): ToastService {
    return this.injector.get(ToastService, null);
  }
}
