import { AbstractValidator } from './abstract-validator';

export class NoWhiteSpaceValidator extends AbstractValidator {

  public isValid(value: any): boolean {

    if (typeof value === 'undefined' || value === null) {
        value = '';
    }

    let pattern = '\\s';

    return !(new RegExp(pattern).test(value.toString()));
  }

  public getErrorTranslateKey(value: any): string {
    return 'NO_WHITE_SPACE_ALLOWED';
  }

  public getErrorTranslateParams(value: any): any {
    return null;
  }
}
