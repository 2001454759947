import {Component, Input, OnInit, AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef} from '@angular/core';
import { environment } from '../../../../environments';
import { ElementButtonDefault } from '../models';
import { ElementComponent } from './element.component';
import { FormService } from '../form.service';
import { FormViewerService } from '../form-viewer.service';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-form-element-button-default',
  template: `
    <div class="button-wrapper">
      <button
        pButton
        [style.width]="getButtonWidth()"
        [iconUrl]="getButtonImageSource(element)"
        [label]="getButtonLabel(element.label)"
        (click)="onClickElement($event)"
        [disabled]="isReadOnlyOrDisabled() || !entity"
        [tabIndex]="element.tabIndex"
      ></button>
    </div>
  `,
  styles: [`
  :host .button-wrapper {
    padding: .5em;
  }
  `]
})
export class ElementButtonDefaultComponent extends ElementComponent implements OnInit, AfterViewInit {

  @Input() element: ElementButtonDefault;

  iconBaseUrl: string = environment.baseUrl;

  constructor(
    protected formService: FormService,
    public cdr: ChangeDetectorRef,
    protected formViewerService: FormViewerService,
  ) {
    super(formService, cdr, formViewerService);
  }

  onComponentInit() {
    this.filterActionAndHandleIt('oninit');
  }

  ngAfterViewInit() {
  }

  public getButtonImageSource(item) {
    const source = '';

    if (item.icon && item.icon.relativePath) {
      return environment.baseUrl + '/' + item.icon.relativePath;
    }

    // fallback for non-initialized icon items...
    if (item._embedded && item._embedded.icon && item._embedded.icon.relativePath) {
      return environment.baseUrl + '/' + item._embedded.icon.relativePath;
    }

    return source;
  }

  public getButtonLabel(itemLabel) {
    if (itemLabel && itemLabel === '-') {
      return ' ';
    }

    return itemLabel;
  }

  public getButtonWidth() {
    return this.element['width'] ? `${this.element['width']}%` : '';
  }

  public isReadOnly() {
    return this.element.readOnly;
  }

  public isReadOnlyOrDisabled(): boolean {
    return this.isReadOnly() || this.element.disabled;
  }
}
