
import {of as observableOf, Observable} from 'rxjs';

import {map} from 'rxjs/operators';
import {FormViewerComponent} from '../../../form-viewer/form-viewer.component';
import {
  PrecalculationDetailsToggleColumnsVisibilityStep
} from '../customer/condition/precalculation-details-toggle-columns-visibility-step';
import {AbstractGenericGridComponent} from '../../../content-renderer/elements/abstract-generic-grid.component';
import {ExecutionStepStatus} from '../../../../core/executor/execution-step-status';
import {Entity} from '../../../helpers/entity';

export class WorkHourEntryPrecalculationArticlesToggleColumnVisibilityExecutionStep extends
  PrecalculationDetailsToggleColumnsVisibilityStep {

  private preCalculation: any = null;

  protected doToggleVisibility(form: FormViewerComponent, grid: AbstractGenericGridComponent): Observable<ExecutionStepStatus> {
    const preCalculationId = Entity.getValue(form.entity, 'preCalculation.id') ||
      Entity.getValueInEmbedded(form.entity, 'preCalculation.id');

    grid.isDataLoading = true;

    if (preCalculationId === null) {
      return observableOf({status: false, content: 'Cannot find preCalculation from subAssignment!'});
    }

    return this.genericCrudService.getEntity('phoenix/precalculations', preCalculationId, '', {
        'embedded': 'billingUnit,salaryUnit'
      }).pipe(
      map((preCalculation) => {
        this.preCalculation = preCalculation;

        this.applyConditions(form, grid);

        grid.isDataLoading = false;

        return { status: true, content: null };
      }));
  }

  protected getPreCalculation(form: FormViewerComponent): any {
    return this.preCalculation;
  }
}
