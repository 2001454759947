import { ElementInputAutocompleteComponent } from './element-input-autocomplete.component';
import { environment } from '../../../../environments';
import { GeocoderAutocomplete } from '@geoapify/geocoder-autocomplete';
export class ElementInputGeoapifyAutocompleteComponent extends ElementInputAutocompleteComponent {
    constructor() {
        super(...arguments);
        this.selectedSuggestion = null;
        this.autocomplete = null;
    }
    ngOnInit() {
        super.ngOnInit();
        this.autocomplete = new GeocoderAutocomplete(this.elementRef.nativeElement.querySelector('#autocomplete-container'), environment.geoapifyApiKey);
        this.changeAutocompleteStyle();
        this.autocomplete.on('select', (location) => {
            if (location === null) {
                this.selectedSuggestion = null;
                this.setValue(null);
            }
            if (location) {
                this.selectedSuggestion = location.properties;
                this.setValue({
                    value: location.properties.address_line1,
                    label: location.properties[this.element.datamodelField]
                });
            }
        });
    }
    setValue(selectedOption, triggerChange = true, entityValueEmpty = false) {
        super.setValue(selectedOption, triggerChange, entityValueEmpty);
        return this;
    }
    setAutocompleteValue(value) {
        if (this.autocomplete) {
            this.autocomplete.setValue(value !== 'null' ? value : '');
            this.autocomplete.close();
        }
    }
    changeAutocompleteStyle() {
        const inputElement = this.elementRef.nativeElement.querySelector('.geoapify-autocomplete-input');
        if (inputElement) {
            inputElement.setAttribute('placeholder', 'Geben Sie hier eine Adresse ein!');
        }
    }
}
