import { Observable } from 'rxjs';
import { GenericElementEvent } from '../../../../../services/event/event/generic-element-event';
import { ActionHandlerSubscribeResponse } from '../../../../../../core/events/interfaces/action-handler-subscribe-response';
import { AbstractGenericElementActionHandler } from '../../../../../services/event/action-handler/abstract-generic-element-action-handler';
import { PerformedAction, ElementType } from 'app/shared/content-renderer/services/ElementContext';
import { ElementState } from '../../../../services/element-state';
import { ElementsStackService } from '../../../../services/elements-stack.service';
import { ElementsStateService } from '../../../../services/elements-state.service';
import {AbstractGenericGridComponent} from '../../../abstract-generic-grid.component';

export class GenericGridDestroyActionHandler extends AbstractGenericElementActionHandler {

    public constructor(
        private elementsStackService: ElementsStackService,
        private elementsStateService: ElementsStateService
    ) {
        super();
    }

    protected doAction(event: GenericElementEvent): Observable<ActionHandlerSubscribeResponse> {
        const component = event.getComponent(); // grid

        if (component instanceof AbstractGenericGridComponent) {
            const elementContext = this.elementsStackService.findById(component.moduleElement.id);

            if (elementContext.performedAction !== PerformedAction.Close) {
            // Now create a state of the component and add it:
              const componentState =
                new ElementState(component.moduleElement.id, ElementType.Grid,
                component.moduleElement, false, Math.floor(component.currentOffset / component.defaultPageSize),
                component.defaultPageSize, component.gridFilters, component.selectedEntity, component.dataLoaded);

                this.elementsStateService.remove(componentState).add(componentState);
            }

            this.elementsStackService.remove(elementContext);
        }

        return this.getObservable(true);
    }

}
