import { Component } from '@angular/core';
import { FieldActionAbstractComponent } from '../field-action-abstract.component';
import { GenericCrudService } from '../../../../../services/generic-crud.service';
import { DoubleClickService } from '../../../../services/double-click.service';
import {DateHelper} from "../../../../../helpers/date.helper";
import {EntityManagerService} from "../../../../../../core/service/entity-manager/entity-manager.service";

@Component({
  selector: 'field-action-calculate-salary',
  template: ''
})
export class FieldActionCalculateSalaryComponent extends FieldActionAbstractComponent {

  public constructor(
    protected genericCrudService: GenericCrudService,
    protected entityManager: EntityManagerService,
    protected doubleClickService: DoubleClickService
  ) {
    super();
  }

  public fire() {
    let entity = this.getGrid().getSelectedEntity();
    if(entity.fqn === "PhoenixBundle\\Entity\\WorkHourEntry"){
      if(this.getField().id === 'duration' || this.getField().id === 'salaryHour') {
        const oldCalculatedWage = entity.calculatedWage;
        entity.calculatedWage = entity.duration * entity.salaryHour;
        this.entityManager.persist(entity, {property: 'calculatedWage', oldValue: oldCalculatedWage, newValue: entity.calculatedWage });
      }
      if(this.getField().id === 'duration' || this.getField().id === 'invoiceHour') {
        const oldCalculatedWage = entity.calculatedWage;
        entity.calculatedInvoice = entity.duration * entity.invoiceHour;
        this.entityManager.persist(entity, {property: 'calculatedInvoice', oldValue: oldCalculatedWage, newValue: entity.calculatedInvoice });
      }
    }
  }

}
