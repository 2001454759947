import {Injectable} from '@angular/core';
import {ChangeSetContainer} from './change-set/change-set-container';
import {ChangeSetEntity, ChangeSetMeta} from './change-set/change-set';

@Injectable()
export class EntityManagerService {

  private readonly changeSetContainer: ChangeSetContainer = null;

  public constructor() {
    this.changeSetContainer = new ChangeSetContainer();
  }

  public persist(entity: ChangeSetEntity, meta: ChangeSetMeta) {
    if (!entity.fqn || !entity.uniqueId) {
      console.error(`FQN or UUID not found in entity ${entity}`);
    }

    if (meta.property) {
      if (meta.force) {
        entity[meta.property] = meta.newValue;
      }

      this.getChangeSetContainer().updateChangeSet(entity, meta);
    }

    if (meta.entire) {
      const exclude = meta.exclude || [];

      exclude.push('uniqueId');
      exclude.push('fqn');

      for (const property in entity) {
        if (entity.hasOwnProperty(property) && typeof entity[property] !== 'function' && !exclude.includes(property)) {
          this.getChangeSetContainer().updateChangeSet(entity, {property: property, newValue: entity[property] });
        }
      }
    }
  }

  public persistMore(entity: ChangeSetEntity, meta: ChangeSetMeta[] = []) {
    for (const m of meta) {
      this.persist(entity, m);
    }
  }

  public getChangeSetContainer(): ChangeSetContainer {
    return this.changeSetContainer;
  }
}
