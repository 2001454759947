
import {of as observableOf, Observable} from 'rxjs';
import {ChangeDetectorRef, Component, Input, ViewContainerRef} from '@angular/core';
import {Element} from '../../../services/element/element';
import {GenericCrudService} from '../../../services/generic-crud.service';
import {GenericElementAbstract} from '../generic-element-abstract.component';
import {ComponentService} from '../../services/component-highlight-stack.service';
import {EntityDataStoreService} from '../../services/entity-data-store.service';
import {FieldMetadataGrid} from '../../../services/module/module-element-field-metadata-grid';
import {ModuleElement} from '../../../services/module/module-element';
import {ModulesStateService} from '../../services/modules-state.service';
import {ExecutorService} from 'app/core/executor/executor.service';
import {
  GenericElementValidationExecutionStepsFactory
} from 'app/shared/content-renderer/services/generic/generic-element-validation-execution-steps-factory';
import {EntityValidator, EntityValidatorStatus} from '../../../validators/services/entity-validator';
import {LocalStorageDataService} from '../../../services/local-storage-data.service';
import {FileService} from '../../../../core/file/file.service';
import {FileData} from '../../../../core/file/file-data';
import {PermissionService} from '../../../services/permission/permission.service';
import {UserSessionService} from '../../../../core/service/user-session.service';

@Component({
  selector: 'app-generic-file-preview',
  styleUrls: ['./generic-file-preview.component.scss'],
  templateUrl: './generic-file-preview.component.html',
  providers: [
    ExecutorService,
    GenericElementValidationExecutionStepsFactory
  ]
})
export class GenericFilePreviewComponent extends GenericElementAbstract {
  @Input() element: Element;
  @Input() fields: Array<FieldMetadataGrid>;
  @Input() toolbarItems: any[] = [];
  @Input() statusBarItems: any[] = [];
  @Input() moduleElement: ModuleElement;
  @Input() masterEntity: any = null;
  @Input() masterField: any = null;
  @Input() isPart = false;
  @Input() entity: any = null;

  @Input() fileData: FileData = null;

  protected toolbarContextName = 'filePreviewComponent';

  constructor(
    protected componentService: ComponentService,
    protected viewContainerRef: ViewContainerRef,
    protected modulesStateService: ModulesStateService,
    protected genericCrudService: GenericCrudService,
    protected entityDataStoreService: EntityDataStoreService,
    protected executorService: ExecutorService,
    protected genericElementValidationExecutionStepsFactory: GenericElementValidationExecutionStepsFactory,
    protected entityValidator: EntityValidator,
    protected userSession: UserSessionService,
    protected fileService: FileService,
    protected permissionService: PermissionService,
    public cdr: ChangeDetectorRef
  ) {
    super(componentService, viewContainerRef, entityDataStoreService, modulesStateService, executorService,
      genericElementValidationExecutionStepsFactory, entityValidator, genericCrudService, userSession, permissionService,
      cdr);
  }

  onDestroyComponent() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  public ngOnInit() {
    super.ngOnInit();

    this.onComponentInit();
  }

  onComponentInit() {
    if (!this.fileData && this.entity) {
      this.fileService.getFile(this.entity, {
          masterEntity: this.selectedMasterEntity
        })
        .subscribe((fileData: FileData) => {
          this.fileData = fileData;
        });
    }
  }

  public recheckToolbarItems(): void {}

  public hasChanges(checkEmbedded: boolean = false): boolean {
    return false;
  }

  public getSelectedEntity(): any {
    return this.fileData;
  }

  public onSave(): Observable<any> {
    return observableOf(null);
  }

  public onAfterSave(): Observable<any> {
    return observableOf(null);
  }

  public onChange(): Observable<any> {
    return observableOf(null);
  }

  public doValidate(): Observable<EntityValidatorStatus> {
    return observableOf({
      entity: null,
      isValid: true,
      error: '',
      errorFields: []
    });
  }

  public onRefresh(): Observable<any> {
    return observableOf(null);
  }

  private getFileType(entity): any|null {
    const fqn = entity.fqn;

    let type = null;

    switch (fqn) {
      case 'PhoenixBundle\\Entity\\PrintTemplate':
        const reportDocumentName = entity.reportDocumentName;
        const reportDocumentNameParts = reportDocumentName.split('.');

        type = reportDocumentNameParts[reportDocumentNameParts.length - 1];
      break;
    }

    return type;
  }

  private getFile(entity): any|null {

  }
}
