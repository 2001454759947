/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../../../../../../../node_modules/primeng/components/dialog/dialog.ngfactory";
import * as i2 from "primeng/components/dialog/dialog";
import * as i3 from "../../../../../../components/element-audit/element-audit.component.ngfactory";
import * as i4 from "../../../../../../components/element-audit/element-audit.component";
import * as i5 from "../../../../../../services/generic-crud.service";
import * as i6 from "@angular/common";
import * as i7 from "./toolbar-item-audit.component";
var styles_ToolbarItemAuditComponent = [".audit-dialog > .ui-dialog-content {\n      overflow: auto;\n    }"];
var RenderType_ToolbarItemAuditComponent = i0.ɵcrt({ encapsulation: 0, styles: styles_ToolbarItemAuditComponent, data: {} });
export { RenderType_ToolbarItemAuditComponent as RenderType_ToolbarItemAuditComponent };
function View_ToolbarItemAuditComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 5, "p-dialog", [["appendTo", "body"], ["header", "Audit"], ["showEffect", "fade"], ["styleClass", "audit-dialog"]], null, [[null, "visibleChange"], [null, "onShow"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("visibleChange" === en)) {
        var pd_0 = ((_co.isDialogVisible = $event) !== false);
        ad = (pd_0 && ad);
    } if (("onShow" === en)) {
        var pd_1 = (_co.onShow() !== false);
        ad = (pd_1 && ad);
    } return ad; }, i1.View_Dialog_0, i1.RenderType_Dialog)), i0.ɵdid(1, 180224, [[1, 4], ["dialog", 4]], 2, i2.Dialog, [i0.ElementRef, i0.Renderer2, i0.NgZone], { visible: [0, "visible"], header: [1, "header"], modal: [2, "modal"], responsive: [3, "responsive"], appendTo: [4, "appendTo"], styleClass: [5, "styleClass"], maximizable: [6, "maximizable"], width: [7, "width"], height: [8, "height"], minWidth: [9, "minWidth"], minHeight: [10, "minHeight"] }, { onShow: "onShow", visibleChange: "visibleChange" }), i0.ɵqud(603979776, 2, { headerFacet: 1 }), i0.ɵqud(603979776, 3, { footerFacet: 1 }), (_l()(), i0.ɵeld(4, 0, null, 1, 1, "app-element-audit", [], null, null, null, i3.View_ElementAuditComponent_0, i3.RenderType_ElementAuditComponent)), i0.ɵdid(5, 114688, null, 0, i4.ElementAuditComponent, [i0.ChangeDetectorRef, i5.GenericCrudService], { element: [0, "element"], entity: [1, "entity"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.isDialogVisible; var currVal_1 = "Audit"; var currVal_2 = true; var currVal_3 = true; var currVal_4 = "body"; var currVal_5 = "audit-dialog"; var currVal_6 = true; var currVal_7 = 800; var currVal_8 = 600; var currVal_9 = 800; var currVal_10 = 600; _ck(_v, 1, 1, [currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8, currVal_9, currVal_10]); var currVal_11 = _co.element; var currVal_12 = _co.entity; _ck(_v, 5, 0, currVal_11, currVal_12); }, null); }
export function View_ToolbarItemAuditComponent_0(_l) { return i0.ɵvid(2, [i0.ɵqud(671088640, 1, { dialog: 0 }), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ToolbarItemAuditComponent_1)), i0.ɵdid(2, 16384, null, 0, i6.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.isDialogVisible; _ck(_v, 2, 0, currVal_0); }, null); }
export function View_ToolbarItemAuditComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "toolbar-item-audit", [], null, null, null, View_ToolbarItemAuditComponent_0, RenderType_ToolbarItemAuditComponent)), i0.ɵdid(1, 245760, null, 0, i7.ToolbarItemAuditComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ToolbarItemAuditComponentNgFactory = i0.ɵccf("toolbar-item-audit", i7.ToolbarItemAuditComponent, View_ToolbarItemAuditComponent_Host_0, { params: "params" }, {}, []);
export { ToolbarItemAuditComponentNgFactory as ToolbarItemAuditComponentNgFactory };
