export const ElementSubTypeMapping = {
    34: [
        "Pie" /* PieChart */,
        "Line" /* LineChart */,
        "Bar" /* BarChart */,
        "Donut" /* DonutChart */
    ]
};
export class ElementType {
    constructor(id, value, name, label) {
        this.id = id;
        this.value = value;
        this.name = name;
        this.label = label;
    }
}
