
import {throwError as observableThrowError, Observable} from 'rxjs';

import {map, catchError} from 'rxjs/operators';
import {Component, OnInit} from '@angular/core';
import {AbstractEditExpertSearchFieldValue} from '../abstract-edit-expert-search-field-value';
import {Datamodel} from '../../../../../../../../../services/datamodel/datamodel';
import {GenericCrudService} from '../../../../../../../../../services/generic-crud.service';
import {FieldMetadataGrid} from '../../../../../../../../../services/module/module-element-field-metadata-grid';
import DisplayConfig = App.Form.Element.DisplayConfig;
import {EntityStatus} from '../../../../../../../../../services/entity/entity-status';

interface DatamodelParamValue {
  id: number;
  apiRoute: string;
}

const DATAMODEL_PARAM_KEY = 'datamodel';
const DISPLAY_CONFIG_PARAM_KEY = 'displayConfiguration';

@Component({
  selector: 'app-search-management-edit-dropdown-edit-expert-search-field-value',
  templateUrl: './dropdown-edit-expert-search-field-value.component.html',
  styles: [`
    :host {
      height: 100%;
    }
    
    .container {
      height: 100%;
      overflow: auto;
    }
  `]
})
export class SearchManagementDropdownEditExpertSearchFieldValueComponent extends AbstractEditExpertSearchFieldValue implements OnInit {

  public dropdownOptions: SelectItem[] = [];

  public selectedDatamodelOption: SelectItem = null;
  public selectedFieldValue: {id: number} = null;

  public constructor(
    private readonly genericCrudService: GenericCrudService
  ) {
    super();
  }

  public ngOnInit(): void {
    if (this.field && this.field[EntityStatus.ENTITY_DRAFT_FLAG]) {
      this.initEdit();
    }
  }

  public onDatamodelOptionChanged(datamodel: Datamodel): void {
    this.setParameter(DATAMODEL_PARAM_KEY, {
      id: datamodel.id,
      apiRoute: datamodel.apiRoute
    });

    this.setParameter(DISPLAY_CONFIG_PARAM_KEY, []);

    this.loadDropdownOptions()
      .subscribe((options: SelectItem[] = []) => {
        this.dropdownOptions = options;
      });
  }

  public onDisplayConfigSave(displayConfig: DisplayConfig[] = []): void {
    this.setParameter(DISPLAY_CONFIG_PARAM_KEY, displayConfig);

    this.loadDropdownOptions()
      .subscribe((options: SelectItem[] = []) => {
        this.dropdownOptions = options;

        if (this.selectedFieldValue) {
          this.changeFieldValue(this.selectedFieldValue);
        }
      });
  }

  public onDropdownValueChange(event: any): void {
    this.changeFieldValue(this.selectedFieldValue);
  }

  private loadDropdownOptions(): Observable<SelectItem[]> {
    const datamodel = this.getParameterValue(DATAMODEL_PARAM_KEY),
      apiRoute = datamodel.apiRoute + '/offset/0/limit/50/orderby/id/asc';

    return this.genericCrudService.getEntities(apiRoute).pipe(
      catchError((response) => {
        return observableThrowError(response);
      }),
      map((dmRecords: any) => {
        const options = [],
          records = (dmRecords.data) ? dmRecords.data : dmRecords;

        for (const record of records) {
          options.push({
            label: FieldMetadataGrid.getOptionLabel(
              record,
              { name: 'name', displayConfig: this.getParameterValue(DISPLAY_CONFIG_PARAM_KEY)
            }),
            value: record
          });
        }

        return options;
      }),);
  }

  private initEdit(): void {
    const datamodel: DatamodelParamValue = this.getParameterValue(DATAMODEL_PARAM_KEY);

    if (datamodel && datamodel.id) {
      this.selectedDatamodelOption = {value: { id : datamodel.id }, label: ''};

      this.loadDropdownOptions()
        .subscribe((options: SelectItem[] = []) => {
          this.dropdownOptions = options;

          this.selectFieldValue();
        });
    }
  }

  private selectFieldValue(): void {
    if (this.field.fieldValue) {
      for (const option of this.dropdownOptions) {
        if (option.value.id === this.field.fieldValue) {
          this.selectedFieldValue = option.value;
        }
      }
    }
  }

  private changeFieldValue(fieldValue): void {
    this.onChange.emit({
      value: fieldValue.id,
      labelValue: FieldMetadataGrid.getOptionLabel(
        fieldValue,
        { name: 'name', displayConfig: this.getParameterValue(DISPLAY_CONFIG_PARAM_KEY)
        })
    });
  }
}
