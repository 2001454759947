import {
  Component, ElementRef, Input, OnInit
} from '@angular/core';
import {SimpleSearchField} from '../../../../../../services/simple-search/simple-search';
import {environment} from '../../../../../../../../environments';
import {AbstractGridFilter} from '../../../../generic-grid/filters/abstract-grid-filter';
import {GeocoderAutocomplete} from '@geoapify/geocoder-autocomplete';
import {GeoapifyProperties} from '../../../../../../form-viewer/models/geoapify-properties';

@Component({
  selector: 'app-distance-search',
  template: `
    <div class="ui-g-12 no-padding-left-right">
      <div id="autocomplete-container" class="ui-g-8 autocomplete-container"></div>

      <div class="ui-g-4 no-padding km-input">
        <div class="ui-inputgroup">
          <input type="text" [ngModel]="field?.value?.km" (keyup)="onKmChange($event)" pInputText>
          <span class="ui-inputgroup-addon">KM</span>
        </div>
      </div>
    </div>
  `,
  styles: [`
    ::ng-deep .ap-input {
      height: 20px;
    }

    .no-padding-left-right {
      padding: 2px 0;
    }

    ::ng-deep .algolia-places-container > .algolia-places {
      position: absolute !important;
      width: 264px;
    }

    ::ng-deep .ap-dropdown-menu {
      z-index: 999999 !important;
    }

    .km-input {
      padding-left: 6px;
    }

    ::ng-deep .ui-inputgroup .ui-inputgroup-addon {
      min-width: 4em;
    }

    .autocomplete-container {
      height: 20px;
      width: 264px;
      position: relative;
    }

    ::ng-deep .geoapify-autocomplete-input {
      height: 20px;
      width: 264px;
      position: relative;
    }
  `]
})
export class DistanceSearchComponent implements OnInit {
  @Input() public field: SimpleSearchField = null;
  public placesAutocompleteDistance: GeocoderAutocomplete = null;

  public constructor(private elementRef: ElementRef) {
  }

  public ngOnInit() {
    this.placesAutocompleteDistance = new GeocoderAutocomplete(
      this.elementRef.nativeElement.querySelector('#autocomplete-container'),
      environment.geoapifyApiKey
    );
    this.changeAutocompleteStyle();

    this.placesAutocompleteDistance.on('select', e => {
      if (e === null) {
        this.field.value = null;
        this.field.searchValue = null;
        this.field.labelValue = '';
      } else {
        const suggestion = e.properties;

        this.field.value.lat = suggestion.lat;
        this.field.value.lng = suggestion.lon;
        this.field.labelValue = this.getSuggestionLabel(suggestion);
      }
      this.changeValue();
    });

    this.initDefaultValues();
  }

  public onKmChange(event): void {
    this.field.value.km = event.target.value;

    this.changeValue();
  }

  public changeValue(): void {
    this.field.value = {
      lat: this.field.value.lat,
      lng: this.field.value.lng,
      km: this.field.value.km,
      fqnAware: this.getFqnAware(),
      context: 'distance'
    };

    this.field.searchValue = JSON.stringify(this.field.value);
    this.field.skipSearch = !this.field.value.lat;
  }

  private getSuggestionLabel({ address_line1, postcode, city, country }: GeoapifyProperties): string {
    return `${address_line1 ? address_line1 + ' ' : ''}${postcode ? postcode + ' ' : ''}${city ? city + ', ' : ''}${country}`;
  }

  private getFqnAware(): string {
    let entityName = this.field.meta.entityName;

    if (entityName === 'mainAddress') {
      entityName = 'Address';
    }

    return `PhoenixBundle\\Entity\\${this.capitalizeFirstLetter(entityName)}`
  }

  private capitalizeFirstLetter(string): string {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  private initDefaultValues() {
    this.field.comparator = AbstractGridFilter.MATCH_MODE_DISTANCE;
    this.field.value = this.field.value && this.field.value.context === 'distance' ? this.field.value : {};
    this.field.value.km = this.field.value.km || 40;
    this.field.value.fqnAware = this.getFqnAware();
    this.field.value.context = 'distance';
    this.field.searchKey = this.field.meta.entityName;

    if (this.field.labelValue) {
      this.placesAutocompleteDistance.setValue(this.field.labelValue);
    }

    this.field.skipSearch = !this.field.value.lat;
  }

  private changeAutocompleteStyle(): void {
    const inputElement = this.elementRef.nativeElement.querySelector('.geoapify-autocomplete-input');
    if (inputElement) {
      inputElement.setAttribute('placeholder', 'Geben Sie hier eine Adresse ein!');
    }
  }
}
