/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./radio-question.component";
import * as i2 from "../../../../../../../services/generic-crud.service";
var styles_RadioQuestionComponent = ["[_nghost-%COMP%] {\n      width: 100%;\n    }"];
var RenderType_RadioQuestionComponent = i0.ɵcrt({ encapsulation: 0, styles: styles_RadioQuestionComponent, data: {} });
export { RenderType_RadioQuestionComponent as RenderType_RadioQuestionComponent };
export function View_RadioQuestionComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "div", [["class", "ui-g"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "div", [["class", "ui-g-8"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, [" Radio Type "]))], null, null); }
export function View_RadioQuestionComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-questionnaire-question-radio", [], null, null, null, View_RadioQuestionComponent_0, RenderType_RadioQuestionComponent)), i0.ɵdid(1, 114688, null, 0, i1.RadioQuestionComponent, [i2.GenericCrudService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var RadioQuestionComponentNgFactory = i0.ɵccf("app-questionnaire-question-radio", i1.RadioQuestionComponent, View_RadioQuestionComponent_Host_0, { question: "question", questionnaire: "questionnaire", questionnaireComponent: "questionnaireComponent" }, {}, []);
export { RadioQuestionComponentNgFactory as RadioQuestionComponentNgFactory };
