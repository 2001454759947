import { Injectable } from '@angular/core';
import { AbstractGenericGridComponent } from '../../../elements/abstract-generic-grid.component';
import { GenericCrudService } from '../../../../services/generic-crud.service';
import { FieldMetadataGrid } from 'app/shared/services/module/module-element-field-metadata-grid';
import { Debounce } from 'app/shared/helpers/debounce';
import {TranslateService} from '@ngx-translate/core';
import {FilterItem} from '../../../../services/element/filter-item';
import {ChangeDetectorRefHelper} from '../../../../helpers/change-detector-ref.helper';
import {ElementState} from '../../element-state';
import {cloneDeep} from 'lodash';

@Injectable()
export class GenericElementFilterService {

    private component: AbstractGenericGridComponent = null;

    public constructor(
        private genericCrudService: GenericCrudService,
        private translationService: TranslateService
    ) {

    }

    public isEventFromTableHeader(event): boolean {
      let target: any = event.target;
      let inside = false;
      do {
        const isTokenList = target && target.classList && target.classList instanceof DOMTokenList;
        if (isTokenList && target.classList.contains('ui-datatable-thead')) {
          inside = true;
        }

        if (target) {
          target = target.parentNode;
        }
      } while (target);

      return inside;
    }

    public onFilter(event: any, filterItem: FilterItem, triggerFiltering: boolean = true) {
        let debounceTime = 1;

        if (!filterItem.fieldName) {
          filterItem.fieldName = filterItem.field.name;
        }

        switch (filterItem.filterType) {
            case FieldMetadataGrid.FILTER_TYPE_CONTAINS:
            case FieldMetadataGrid.FILTER_TYPE_EQUALS:
            case FieldMetadataGrid.FILTER_TYPE_STARTS_WITH:
            case FieldMetadataGrid.FILTER_TYPE_ENDS_WITH:
            case FieldMetadataGrid.FILTER_TYPE_IN:
                this.setFilterText(event, filterItem);

                debounceTime = 500;
            break;
            case FieldMetadataGrid.FILTER_TYPE_DATE:
                this.setFilterDate(event, filterItem);

                debounceTime = 500;
            break;
            case FieldMetadataGrid.FILTER_TYPE_RANGE_DATE:
                this.setFilterRangeDate(event, filterItem);
            break;
            case FieldMetadataGrid.FILTER_TYPE_CHECKBOX:
            case FieldMetadataGrid.FILTER_TYPE_LOCK_STATE:
                this.setFilterCheckbox(event, filterItem);
            break;
            case FieldMetadataGrid.FILTER_TYPE_TRICHECKBOX:
                this.setFilterTriCheckbox(event, filterItem);
            break;
            case FieldMetadataGrid.FILTER_TYPE_MONTHS:
                this.setFilterMonths(event, filterItem);
            break;
            case FieldMetadataGrid.FILTER_TYPE_DROPDOWN:
                this.setFilterDropdown(event, filterItem);
            break;
            case FieldMetadataGrid.FILTER_TYPE_MULTIDROPDOWN:
                this.setFilterMultiDropdown(event, filterItem);
            break;
            case FieldMetadataGrid.FILTER_TYPE_AUTOCOMPLETE:
                this.setFilterAutocomplete(event, filterItem);
            break;
            case FieldMetadataGrid.FILTER_TYPE_DISTANCE:
                this.setFilterDistance(event, filterItem);
            break;
            default:
                this.setFilterText(event, filterItem);

                debounceTime = 500;
            break;
        }

        if (triggerFiltering) {
          this.component.currentOffset = 0;

          Debounce.debounce(() => {
            this.component.loadEntities().subscribe();
          }, debounceTime);
        }
    }

    public onFilterAutocompleteSearch(event: any, column: any) {
        const query = event.query,
            params = {}

        let apiRoute = `${column.associationEndpoint}/offset/0/limit/50`;
        if (column.firstAssociationOrderBy) {
          if (column.secondAssociationOrderBy) {
            apiRoute += `/orderby/${column.firstAssociationOrderBy},${column.secondAssociationOrderBy}/${column.firstAssociationOrderByOrientation},${column.secondAssociationOrderByOrientation}?search=${query}&clause=orWhere`;
          } else {
            apiRoute += `/orderby/${column.firstAssociationOrderBy}/${column.firstAssociationOrderByOrientation}?search=${query}&clause=orWhere`;
          }
        } else {
          apiRoute += `/orderby/id/asc?search=${query}&clause=orWhere`;
        }

        if (column.firstAssociationOrderType){
          if (column.secondAssociationOrderType){
            params['orderType'] = `${column.firstAssociationOrderType},${column.secondAssociationOrderType}`;
          } else {
            params['orderType'] = `${column.firstAssociationOrderType}`;
          }
        }

        for (const masterEntity of this.component.getElementContext().getMasterEntities()) {
            if (masterEntity.name && masterEntity.value) {
                params[masterEntity.name] = masterEntity.value;
            }
        }

      if (column.customAutocompleteFilters) {
        for (const customFilter of column.customAutocompleteFilters){
          if (customFilter.name && customFilter.value) {
            params[customFilter.name] = customFilter.value;
          }
        }
      }

        const options = this.component.associatedFilterOptions;
        const embeddedFields = (params && params['embedded']) ? params['embedded'] : 'none';

        this.genericCrudService.getEntities(apiRoute, '', {
          ...params,
          ...{
            embedded: embeddedFields
          }
        }).subscribe((entries) => {
            const data = entries.data ? entries.data : entries;

            options[column.entityName] = [];

            data.map((entry) => {
                entry.label = FieldMetadataGrid.getOptionLabel(entry, column.field);

                if (entry.label) {
                    options[column.entityName].push(entry);
                }
            });

            options[column.entityName] = [...options[column.entityName]];

            ChangeDetectorRefHelper.detectChanges(this.component);
        });
    }

    public setComponent(component: AbstractGenericGridComponent): GenericElementFilterService {
        this.component = component;
        return this;
    }

    public getComponent(): AbstractGenericGridComponent | null {
        return this.component;
    }

    public setupDefaultFilters(): void {
        if (!this.component.componentState) {
          const componentState = new ElementState(
            this.component.moduleElement.id,
            this.component.elementType,
            this.component.moduleElement
          );

          for (const column of this.component.columns) {
            if (this.canSetupDefaultValue(column)) {
              const filterValue = this.getDefaultFilterValue(column),
                stateValue = this.getDefaultFilterValueState(column),
                matchMode = this.getDefaultFilterMatchMode(column),
                filterName = column.field.entityName || column.id;

              componentState.filters[filterName] = stateValue;

              this.component.gridFilters[filterName] = {
                  value: filterValue,
                  matchMode: matchMode,
                  type: column.filterType,
                  stateValue: stateValue,
                  labelValue: stateValue.labelValue
                }

              if (column.filterType === FieldMetadataGrid.FILTER_TYPE_AUTOCOMPLETE) {
                this.component.autocompleteFilterColumnValues[filterName] = stateValue.value;
              }
            }
          }

          this.component.componentState = componentState;
        }
    }

    private getDefaultFilterValue(column) {
      let filterValue = cloneDeep(column.defaultFilterValue)

      switch (column.filterType) {
        case FieldMetadataGrid.FILTER_TYPE_MULTIDROPDOWN:
        case FieldMetadataGrid.FILTER_TYPE_AUTOCOMPLETE:
          const ids = [];

            for (const v of filterValue) {
              ids.push(v.value);
            }

            filterValue = ids;
          break;
      }

      return filterValue;
    }

  private getDefaultFilterMatchMode(column) {
    let matchMode = '';

    switch (column.filterType) {
      case FieldMetadataGrid.FILTER_TYPE_MULTIDROPDOWN:
        matchMode = 'manyIn';
        break;
      case FieldMetadataGrid.FILTER_TYPE_AUTOCOMPLETE:
        matchMode = 'in';
        break;
    }

    return matchMode;
  }

    private getDefaultFilterValueState(column): {value: any, labelValue: string} {
      let stateValue = cloneDeep(column.defaultFilterValue),
        stateLabelValue = '';

      switch (column.filterType) {
        case FieldMetadataGrid.FILTER_TYPE_MULTIDROPDOWN:
          const ids = [],
            labels = [];

          for (const v of stateValue) {
            v.id = v.value;
            ids.push(v.value);
            labels.push(v.label);
          }

          stateValue = ids;
          stateLabelValue = labels.join(', ');
          break;
      }

      return {
        value: stateValue,
        labelValue: stateLabelValue
      }
    }

    private canSetupDefaultValue(column): boolean {
      if (column.defaultFilterValue instanceof Array) {
        return column.defaultFilterValue.length > 0;
      }

      return column.defaultFilterValue;
    }

    private setFilterText(event: any, filterItem: FilterItem): void {
        this.doSetFilterTextFilterType(event, filterItem, 'string');
    }

    private setFilterDate(event: any, filterItem: FilterItem): void {
        this.doSetFilterTextFilterType(event, filterItem, 'date');
    }

    private doSetFilterTextFilterType(event: any, filterItem: FilterItem, type: string): void {
        const value = event.target.value;

        filterItem.blockSearch = !event.target.value;

        delete this.component.gridFilters[filterItem.fieldName];

        if (value && value !== '') {
            this.component.gridFilters[filterItem.fieldName] = { value: value, matchMode: filterItem.filterType, type: type };
        }
    }

    private setFilterRangeDate(event: any, filterItem: FilterItem): void {
        const yearFrom = event.values[0],
            yearTo = event.values[1];
      filterItem.blockSearch = !yearFrom;

        const filterValue = `01.01.${yearFrom}-31.12.${yearTo}`;

        this.component.gridFilters[filterItem.fieldName] = { value: filterValue, matchMode: '', type: 'date' };
    }

    private setFilterCheckbox(event: any, filterItem: FilterItem): void {

        delete this.component.gridFilters[filterItem.fieldName];

        filterItem.blockSearch = !event;

        if (typeof event === 'boolean') {
            this.component.gridFilters[filterItem.fieldName] = { value: event, matchMode: 'equal', type: 'string' };
        }
    }

    private setFilterTriCheckbox(event: any, filterItem: FilterItem): void {
        delete this.component.gridFilters[filterItem.fieldName];
        filterItem.blockSearch = !event;

        if (typeof event.value === 'boolean') {
            this.component.gridFilters[filterItem.fieldName] = { value: event.value, matchMode: 'equal', type: 'string' };
        }
    }

    private setFilterMonths(event: any, filterItem: FilterItem): void {
      delete this.component.gridFilters[filterItem.fieldName];
      filterItem.blockSearch = !event.value;

      if (typeof event.value === 'number') {
        this.component.gridFilters[filterItem.fieldName] = { value: event.value, matchMode: 'equal' };
      }
    }

    private setFilterDistance(event: any, filterItem: FilterItem): void {
        delete this.component.gridFilters[filterItem.fieldName];

        if (typeof event.value === 'number') {
            this.component.gridFilters[filterItem.fieldName] = { value: event.value + '', matchMode: 'equal', type: 'distance' };
        }
    }

    private setFilterDropdown(event: any, filterItem: FilterItem): void {
        const columnName = filterItem.fieldName.indexOf('.') !== -1 ? filterItem.fieldName.split('.')[0] : filterItem.fieldName,
          options = this.component.associatedFilterOptions[columnName],
          selectedOption = options.find((aOption) => {
            return aOption.value === event.value;
          });
        filterItem.blockSearch = !event.value;

        this.component.gridFilters[columnName] = {
          value: event.value,
          matchMode: '',
          type: 'dropdown',
          labelValue: selectedOption ? selectedOption.label : ''
        };
    }

    private setFilterMultiDropdown(event: any, filterItem: FilterItem): void {
      const columnName = filterItem.fieldName.indexOf('.') !== -1 ? filterItem.fieldName.split('.')[0] : filterItem.fieldName,
        options = this.component.associatedFilterOptions[columnName];
      filterItem.blockSearch = !event.value;

      const selectedLabels = [];
      if (event.value instanceof Array) {
        for (const value of event.value) {
          const id = typeof value.id !== 'undefined' ? value.id : value,
            selectedOption = options.find((aOption) => {
              return aOption.value === id;
            });

          if (selectedOption && selectedOption.label) {
            selectedLabels.push(selectedOption.label);
          }
        }
      }

      this.component.gridFilters[columnName] = {
        value: event.value,
        matchMode: '',
        type: 'dropdown',
        labelValue: selectedLabels ? selectedLabels.join(', ') : ''
      };
    }

    private setFilterAutocomplete(event: any, filterItem: FilterItem): void {
        let columnName = filterItem.fieldName;
        if (columnName.indexOf('.') !== -1) {
          const filterFields = columnName.split('.');
          columnName = filterFields.slice(0, filterFields.length - 1).join('.');
        }

        const value = event,
            index = this.component.autocompleteFilterColumnValues[columnName].findIndex((aValue) => { return aValue.id === value.id; });

        // unselect
        if (index > -1) {
            this.component.autocompleteFilterColumnValues[columnName] =
              this.component.autocompleteFilterColumnValues[columnName].filter((val, i) => i !== index);
        } else {
            this.component.autocompleteFilterColumnValues[columnName] =
              [...this.component.autocompleteFilterColumnValues[columnName], value];
        }
        filterItem.blockSearch = !this.component.autocompleteFilterColumnValues[columnName];

        this.component.gridFilters[columnName] = {
          value: this.component.autocompleteFilterColumnValues[columnName], matchMode: '', type: 'autocomplete'
        };
    }

    public getSelectableMonths(): SelectItem[] {

    return [
        {value: '', label: ' --- '},
        {value: 1, label: this.translationService.instant('COMMON.DATE.MONTH_NAMES.JANUARY')},
        {value: 2, label: this.translationService.instant('COMMON.DATE.MONTH_NAMES.FEBRUARY')},
        {value: 3, label: this.translationService.instant('COMMON.DATE.MONTH_NAMES.MARCH')},
        {value: 4, label: this.translationService.instant('COMMON.DATE.MONTH_NAMES.APRIL')},
        {value: 5, label: this.translationService.instant('COMMON.DATE.MONTH_NAMES.MAY')},
        {value: 6, label: this.translationService.instant('COMMON.DATE.MONTH_NAMES.JUNE')},
        {value: 7, label: this.translationService.instant('COMMON.DATE.MONTH_NAMES.JULY')},
        {value: 8, label: this.translationService.instant('COMMON.DATE.MONTH_NAMES.AUGUST')},
        {value: 9, label: this.translationService.instant('COMMON.DATE.MONTH_NAMES.SEPTEMBER')},
        {value: 10, label: this.translationService.instant('COMMON.DATE.MONTH_NAMES.OCTOBER')},
        {value: 11, label: this.translationService.instant('COMMON.DATE.MONTH_NAMES.NOVEMBER')},
        {value: 12, label: this.translationService.instant('COMMON.DATE.MONTH_NAMES.DECEMBER')}
      ];
    }
}
