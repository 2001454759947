
import {of as observableOf, Observable} from 'rxjs';
import {Injectable} from '@angular/core';
import {GenericDialogModuleService} from '../../../elements/generic-dialog/service/generic-dialog-module.service';
import {AbstractModuleStateView} from './abstract-module-state-view';

@Injectable()
export class ModuleStateDialogView extends AbstractModuleStateView {

  public constructor(
    private readonly dialogService: GenericDialogModuleService
  ) {
    super();
  }

  public doOpenPrevious(): Observable<any> {
    this.dialogService.persistHide();

    return observableOf(null);
  }
}
