import {ChangeDetectorRef, Component, Input, OnInit} from '@angular/core';
import {AbstractGenericGridSimpleSearchFieldComponent} from './abstract-generic-grid-simple-search-field.component';
import {AbstractGenericGridComponent} from '../../../abstract-generic-grid.component';

@Component({
  selector: 'app-generic-grid-simple-search-field-distance',
  template: `
    <div class="ui-g no-padding" *ngIf="field.value">
      <div class="ui-g-12">
        <p-radioButton
          label="{{ 'SIMPLE_SEARCH.REGION' | translate }}"
          name="context"
          value="region"
          [(ngModel)]="field.value.context"
        ></p-radioButton>
        <p-radioButton
          label="{{ 'SIMPLE_SEARCH.DISTANCE' | translate }}"
          name="context"
          value="distance"
          [(ngModel)]="field.value.context"
        ></p-radioButton>
        <p-radioButton
          label="{{ 'SIMPLE_SEARCH.POSTAL_CODE'| translate }}"
          name="context"
          value="postalCode"
          [(ngModel)]="field.value.context"
        ></p-radioButton>
        <p-radioButton
          *ngIf="isLeasedEmployee"
          label="{{ 'SIMPLE_SEARCH.LEASED_EMPLOYEE_REGION'| translate }}"
          name="context"
          value="leasedEmployeeRegion"
          [(ngModel)]="field.value.context"
        ></p-radioButton>
      </div>

      <div class="ui-g-12">
        <app-region-search *ngIf="field.value.context === 'region'" [component]="component" [field]="field"></app-region-search>
        <app-distance-search *ngIf="field.value.context === 'distance'" [field]="field"></app-distance-search>
        <app-postal-code-search *ngIf="field.value.context === 'postalCode'" [field]="field"></app-postal-code-search>
        <app-leased-employee-region-search
          *ngIf="field.value.context === 'leasedEmployeeRegion'"
          [field]="field"
        ></app-leased-employee-region-search>
      </div>
    </div>
  `,
  styles: [`
    .no-padding {
      padding: 0;
    }
  `]
})
export class GenericGridSimpleSearchFieldDistanceComponent extends AbstractGenericGridSimpleSearchFieldComponent implements OnInit {

  @Input() public component: AbstractGenericGridComponent = null;

  public isLeasedEmployee = false;

  public constructor(
    public cdr: ChangeDetectorRef
  ) {
    super();
  }

  public ngOnInit(): void {
    this.field.value = this.field.value || {
      context: 'region'
    };

    this.isLeasedEmployee = this.component.getElementDatamodelEntityName() === 'PhoenixBundle\\Entity\\LeasedEmployee';
  }

}
