
import {of as observableOf, Observable} from 'rxjs';

import {ChangeDetectorRef, Component, Input, ViewContainerRef} from '@angular/core';
import {ExecutorService} from '../../../../../core/executor/executor.service';
import {GenericElementAbstract} from '../../generic-element-abstract.component';
import {FieldMetadataGrid} from '../../../../services/module/module-element-field-metadata-grid';
import {ModuleElement} from '../../../../services/module/module-element';
import {EntityValidator, EntityValidatorStatus} from '../../../../validators/services/entity-validator';
import {Element} from '../../../../services/element/element';
import {ToolbarItemCheckService} from '../../generic-toolbar/services/check/toolbar-item-check.service';
import {GenericElementValidationExecutionStepsFactory} from '../../../services/generic/generic-element-validation-execution-steps-factory';
import {EntityDataStoreService} from '../../../services/entity-data-store.service';
import {ModulesStateService} from '../../../services/modules-state.service';
import {ComponentService} from '../../../services/component-highlight-stack.service';
import {GenericCrudService} from '../../../../services/generic-crud.service';
import {LayoutService} from '../../../../services/layout-service';
import {JobContainerService} from '../../../../../core/job-runner/job-container.service';
import {ElementsStackService} from '../../../services/elements-stack.service';
import {ElementsStateService} from '../../../services/elements-state.service';
import {GenericDialogModuleService} from '../../generic-dialog/service/generic-dialog-module.service';
import {ExecutorActionsService} from '../../../../../core/executor/service/executor-actions/executor-actions.service';
import {PermissionService} from '../../../../services/permission/permission.service';
import {UserSessionService} from '../../../../../core/service/user-session.service';
import {GenericElementFilterService} from '../../../services/generic/filter/generic-element-filter.service';
import {TableColumn} from '../../../../dynamic-table/shared/table-column';
import {ChangeDetectorRefHelper} from '../../../../helpers/change-detector-ref.helper';
import {takeUntil} from 'rxjs/operators';
import {LeasedEmployeeCostsTableService} from './service/leased-employee-costs-table.service';

@Component({
  selector: 'app-custom-leased-employee-costs',
  styleUrls: ['./leased-employee-costs.component.scss'],
  templateUrl: './leased-employee-costs.component.html',
  providers: [
    ExecutorService,
    GenericElementValidationExecutionStepsFactory,
    GenericElementFilterService,
    LeasedEmployeeCostsTableService
  ]
})
export class LeasedEmployeeCostsComponent extends GenericElementAbstract {
  @Input() element: Element;
  @Input() fields: Array<FieldMetadataGrid>;
  @Input() toolbarItems: any[] = [];
  @Input() statusBarItems: any[] = [];
  @Input() moduleElement: ModuleElement;
  @Input() masterEntity: any = null;
  @Input() masterField: any = null;
  @Input() isPart = false;

  public module = null;

  public toolbarContextName = 'employeeCostReportComponent';

  public entities = [];
  public cells = [];
  public isLoading = false;
  public columns: TableColumn[] = [];
  public gridHeight = 500;
  public totalCount = 0;
  public currentOffset = 0;

  public selectedBranchOffice = null;
  public selectedPeriod = null;
  public branchOfficeOptions: SelectItem[] = [];
  public periodOptions: SelectItem[] = [];

  public constructor(
    protected componentService: ComponentService,
    protected viewContainerRef: ViewContainerRef,
    protected modulesStateService: ModulesStateService,
    protected genericCrudService: GenericCrudService,
    protected entityDataStoreService: EntityDataStoreService,
    protected executorService: ExecutorService,
    protected genericElementValidationExecutionStepsFactory: GenericElementValidationExecutionStepsFactory,
    protected entityValidator: EntityValidator,
    protected userSession: UserSessionService,
    protected toolbarItemCheckService: ToolbarItemCheckService,
    protected layoutService: LayoutService,
    protected jobContainerService: JobContainerService,
    protected elementsStackService: ElementsStackService,
    protected elementStateService: ElementsStateService,
    protected dialogService: GenericDialogModuleService,
    protected executorActionsService: ExecutorActionsService,
    protected permissionService: PermissionService,
    public cdr: ChangeDetectorRef,
    protected genericElementFilterService: GenericElementFilterService,
    protected leasedEmployeeCostsTableService: LeasedEmployeeCostsTableService
  ) {
    super(componentService, viewContainerRef, entityDataStoreService, modulesStateService, executorService,
      genericElementValidationExecutionStepsFactory, entityValidator, genericCrudService, userSession, permissionService,
      cdr);
  }

  public ngOnInit() {
    super.ngOnInit();

    this.layoutService.layoutSizeChanged$
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(() => {
        this.setGridScrollHeightAndWidth();
      })
  }

  public ngOnDestroy() {
    super.ngOnDestroy();
  }

  public getSelectedEntity(): any {
    return this.selectedMasterEntity || null;
  }

  public recheckToolbarItems(): void {
    this.toolbarItemCheckService.check(this);
  }

  public onSave(): Observable<any> {
    return observableOf(null);
  }

  public hasChanges(checkEmbedded: boolean = false): boolean {
    return false;
  }

  public onAfterSave(): Observable<any> {
    return observableOf(null);
  }

  public onChange(): Observable<any> {
    return observableOf(null);
  }

  public doValidate(): Observable<EntityValidatorStatus> {
    return observableOf({
      entity: null,
      isValid: true,
      error: '',
      errorFields: []
    });
  }

  public onRefresh(): Observable<any> {
    return observableOf(null);
  }

  public onLazyLoad(event) {
    this.currentOffset = event.first;
    if (this.selectedBranchOffice && this.selectedPeriod) {
      this.search();
    }
  }

  public onBranchOfficeChanged(event): void {
    this.selectedBranchOffice = event.value;
    if (this.selectedPeriod) {
      this.search();
    }
  }

  public onPeriodChanged(event): void {
    this.selectedPeriod = event.value;
    if (this.selectedBranchOffice) {
      this.search();
    }
  }

  public search(): void {
    this.isLoading = true;
    this.columns = this.leasedEmployeeCostsTableService.getColumns();
    const params = {
      branchOffice: this.selectedBranchOffice.id,
      period: this.selectedPeriod.id,
      embedded: 'period,branchOffice'
    };

    this.genericCrudService.getEntities(`intranet/leasedemployeecosts/offset/${this.currentOffset}/limit/50/orderby/id/asc`, '', params)
      .pipe(
        takeUntil(this.unsubscribe)
      )
      .subscribe((entities) => {
        this.entities = entities.data;
        this.totalCount = entities.total;
        this.isLoading = false;
        ChangeDetectorRefHelper.detectChanges(this);
      });
  }

  protected setGridScrollHeightAndWidth() {
    this.gridHeight = window.innerHeight - 175;
  }

  public loadPeriods(): void {
    this.genericCrudService.getEntities('phoenix/periods', '', {embedded: 'none'}).subscribe((periods) => {
      this.periodOptions = [];
      const firstDayOfYear = new Date(new Date().getFullYear(), 0, 1);
      for (const period of periods) {
        const periodStartDate = new Date(period.startDate);
        if (period.isMonthly && periodStartDate >= firstDayOfYear) {
          this.periodOptions.push({ label: period.name, value: period });
        }
      }
    });
  }

  public loadBranchOffices(): void {
    this.genericCrudService.getEntities('app/branchoffices', '', {embedded: 'none'}).subscribe((branchOffices) => {
      this.branchOfficeOptions = [];
      for (const branchOffice of branchOffices) {
        this.branchOfficeOptions.push({label: branchOffice.name, value: branchOffice})
      }
      this.sortBranchOffices();
    });
  }

  private sortBranchOffices() {
    this.branchOfficeOptions.sort(function (a, b) {
      if (a.label === b.label) { return 0; }
      if (a.label > b.label) {
        return 1;
      } else {
        return -1;
      }
    });
  }
}
