import {Component, OnInit, Input, ViewContainerRef, OnDestroy, ChangeDetectorRef, ChangeDetectionStrategy} from '@angular/core';
import {Subscription} from 'rxjs';
import {environment} from '../../../../../environments';
import {ToolbarItemServiceFactory} from './services/toolbar-item-service-factory.service';
import {EntityDataStoreService, EntityDataChangeMeta} from '../../services/entity-data-store.service';
import {ElementsStackService} from '../../services/elements-stack.service';
import {GenericElementAbstract} from '../generic-element-abstract.component';
import {ToolbarItemDisabledCheckFactoryService} from './services/check/toolbar-item-disabled-check-factory.service';
import {ToolbarItemCheckService} from 'app/shared/content-renderer/elements/generic-toolbar/services/check/toolbar-item-check.service';
import {JobContext} from '../../../../core/job-runner/context/job.context';
import {RunnableEventRegistry} from '../../../../core/job-runner/type/runnable-event.registry';
import {Guid} from 'guid-typescript';
import {JobContainerService} from '../../../../core/job-runner/job-container.service';
import {ToolbarItemColoringFactoryService} from './services/color/toolbar-item-coloring-factory.service';
import {ChangeDetectorRefHelper} from '../../../helpers/change-detector-ref.helper';
import {EntityFqnHelper} from '../../../helpers/entity-fqn.helper';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-generic-toolbar',
  templateUrl: './generic-toolbar.component.html',
  styleUrls: ['./generic-toolbar.component.scss'],
  providers: []
})
export class GenericToolbarComponent implements OnInit, OnDestroy {

  @Input() items: any[];
  @Input() params: any[];
  @Input() additionalItems: any[];
  @Input() isDisabled = false;

  @Input()
  set entity(entity) {
    if (entity) {
      this._entity = entity;
      this.handleItems('items');
      this.handleItems('additionalItems');

      const context = new JobContext();
      context.component = this;
      context.event = RunnableEventRegistry.ToolbarItemEntityChanged;
      context.identifier = Guid.create().toString();

      this.jobContainerService.runRelevantJobs(context);
    }
  }

  public adminItems: any[] = [];
  private _entity: any;

  protected subscriptions: Subscription[] = [];

  environment;

  constructor(
    private toolbarItemServiceFactory: ToolbarItemServiceFactory,
    private viewContainerRef: ViewContainerRef,
    private elementStackService: ElementsStackService,
    private entityDataStore: EntityDataStoreService,
    private toolbarItemDisabledCheckerFactory: ToolbarItemDisabledCheckFactoryService,
    private toolbarItemCheckService: ToolbarItemCheckService,
    private jobContainerService: JobContainerService,
    public cdr: ChangeDetectorRef,
    private toolbarItemColoringFactoryService: ToolbarItemColoringFactoryService
  ) {
    this.environment = environment;
  }

  public ngOnInit() {
    this.subscriptions.push(
      this.entityDataStore.entityValueChanged$.subscribe((entityDataChangeMeta: EntityDataChangeMeta) => {
        if (entityDataChangeMeta.entity && this._entity) {
          this.handleItems('items');
          this.handleItems('additionalItems');
        }
      }),
      this.toolbarItemCheckService.onCheck$.subscribe((component: GenericElementAbstract) => {
        this.handleItems('items');
        this.handleItems('additionalItems');
      })
    );

    this.handleItems('items');
    this.handleItems('additionalItems');
  }

  public ngOnDestroy() {
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }

  public getAttachedComponent(): GenericElementAbstract|null {
    let component = null;

    if (this.params['formViewerComponent']) {
      component = this.params['formViewerComponent'];
    } else if (this.params['gridComponent']) {
      component = this.params['gridComponent'];
    } else if (this.params['treeComponent']) {
      component = this.params['treeComponent'];
    } else if (this.params['memoFieldDefinitionValueComponent']) {
      component = this.params['memoFieldDefinitionValueComponent'];
    }

    return component;
  }

  public getToolbarItemImageSource(item) {
    if (item.icon && item.icon.relativePath) {
      return this.environment.baseUrl + '/' + item.icon.relativePath;
    }

    // fallback for non-initialized icon items...
    if (item._embedded && item._embedded.icon && item._embedded.icon.relativePath) {
      return this.environment.baseUrl + '/' + item._embedded.icon.relativePath;
    }

    return '';
  }

  public onItemClick(item) {
    this.toolbarItemServiceFactory.setParentComponent(this).get(item).click();
  }

  public isItemCheckboxEnabled(item) {
    return this.toolbarItemServiceFactory.setParentComponent(this).get(item).isItemCheckboxEnabled();
  }

  public getText(item) {
    return this.toolbarItemServiceFactory.setParentComponent(this).get(item).getText();
  }

  public isAdminToolbarItemButtonVisible(): boolean {
    const component = this.getAttachedComponent();

    if (component) {
      return component.moduleElement.isAdminToolbarItemsButtonVisible;
    }

    return true;
  }

  private handleItems(itemsPropertyName: string) {
    const items = this[itemsPropertyName];

    if (typeof items !== 'undefined') {
      this[itemsPropertyName] = [];

      for (const item of items) {
        item.disabled = this.isButtonDisabled(item);
        item.backgroundColor = this.getBackgroundColor(item);

        if (!item.label && '' === this.getToolbarItemImageSource(item)) {
          item.label = item.name;
        }

        const isAdminItem = this.isAdminItem(item);
        const canAddToAdmin = (isAdminItem && this.isToolbarItem(item)) ||
          (this.isToolbarSetItem(item) && item.isVisible)

        if (canAddToAdmin) {
          this.addToAdminItems(item);
        } else if (!isAdminItem) {
          this[itemsPropertyName].push(item);
        }
      }
    }

    ChangeDetectorRefHelper.detectChanges(this);
  }

  private getBackgroundColor(item: any): string|null {
    return this.toolbarItemColoringFactoryService.instance(item, this.getAttachedComponent(), this._entity).getColor();
  }

  private isButtonDisabled(item: any): boolean {
    let isDisabled = this.isButtonDisabledGlobally();

    if (this.isButtonDisableable(item)) {

      const component = this.getAttachedComponent();

      const checker = this.toolbarItemDisabledCheckerFactory.instance(item, component, this._entity);

      isDisabled = checker.isDisabled();
    }

    if (this.isDisabled) {
      return true;
    }

    return isDisabled;
  }

  private isButtonDisabledGlobally(): boolean {
    const component = this.getAttachedComponent();

    if (typeof component.isToolbarDisabled !== 'undefined') {
      return !!component.isToolbarDisabled;
    }

    return false;
  }

  private isButtonDisableable(item: any): boolean {
    return (
      item.onclick == 'save'
      || item.onclick == 'draftSave'
      || item.onclick == 'cancel'
      || item.onclick == 'wordexport'
      // || item.onclick == 'open_copy_of_contract_of_employment'
      || item.onclick == 'open_copy_of_contract_of_employment2'
      || item.onclick == 'ca_wage_calculation'
      || item.onclick === 'ca_workhour_flatten'
      || item.onclick === 'ca_workhour_unflatten'
      || item.onclick === 'ca_workhour_invoice_flatten'
      || item.onclick === 'ca_workhour_invoice_unflatten'
      || item.onclick == 'calculation_result'
      || item.onclick == 'caContractOfEmployment'
      || item.onclick === 'caBulkCalculate'
      || item.onclick == 'back'
      || item.onclick == 'refresh'
      || item.onclick == 'form_first'
      || item.onclick == 'form_previous'
      || item.onclick == 'form_next'
      || item.onclick == 'form_last'
      || item.onclick == 'delete'
      || item.onclick == 'deleteFromAll'
      || item.onclick == 'addWhenComponentIsNotChanged'
      || item.onclick == 'addPreCalculation'
      || item.onclick == 'savePreCalculationArticle'
      || item.onclick == 'validateSave'
      || item.onclick == 'saveGroupedArticle'
      || item.onclick == 'bulkAdd'
      || item.onclick == 'updateLeasedEmployeeByDocument'
      || item.onclick == 'open_questionnaire_by_phone_project_group'
      || item.onclick === 'reportExport'
      || item.onclick === 'validateEntities'
    );
  }

  private isAdminItem(item): boolean {
    return item.itemType === 'admin';
  }

  private isToolbarItem(item): boolean {
    return item.fqn.includes('ToolbarItem');
  }

  private isToolbarSetItem(item): boolean {
    return item.fqn.includes('ToolbarSetItem');
  }

  private isAdminToolbarItemSetVisible(item): boolean {
    return this.isAdminItem(item) && item.isVisible && item
  }

  private addToAdminItems(item): void {
    const me = this;

    if (item.icon) {
      item.iconPath = environment.baseUrl + '/' + item.icon.relativePath;
    }
    item.label = item.label || item.name;
    item.command = function() {
      me.onItemClick(item);
    };

    const isNotAdded = this.adminItems.findIndex((aItem) => {
      return aItem.id === item.id;
    }) === -1;

    if (isNotAdded) {
      this.adminItems.push(item);
    }
  }
}
