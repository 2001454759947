import {
  ChangeDetectorRef,
  Component, Input, OnInit
} from '@angular/core';
import {SimpleSearchField} from '../../../../../../services/simple-search/simple-search';
import {ChangeDetectorRefHelper} from '../../../../../../helpers/change-detector-ref.helper';
import {catchError, map} from 'rxjs/operators';
import {throwError as observableThrowError} from 'rxjs/internal/observable/throwError';
import {Observable} from 'rxjs/Observable';
import {GenericCrudService} from '../../../../../../services/generic-crud.service';
import {AbstractGridFilter} from '../../../../generic-grid/filters/abstract-grid-filter';
import {Entity} from '../../../../../../helpers/entity';
import {AbstractGenericGridComponent} from '../../../../abstract-generic-grid.component';

@Component({
  selector: 'app-region-search',
  template: `
    <div class="ui-g-12">
      <div class="ui-g-12 no-padding">
        <p-autoComplete
          field="label"
          [(ngModel)]="field.value.countries"
          [suggestions]="countries"
          (completeMethod)="onCompleteMethod($event)"
          (onKeyUp)="onKeyUp($event)"
          (onSelect)="onCountrySelect($event)"
          (onUnselect)="onCountryUnSelect($event)"
          [dropdown]="true"
          [style]="{'width': '80%'}"
          [size]="30"
          [minLength]="2"
          [multiple]="true"
          placeholder="---"
          appendTo="body"
        ></p-autoComplete>
      </div>

      <div
        *ngIf="(field.value.regions && field.value.regions.length > 0) || regions.length > 0"
        class="ui-g-12 no-padding"
      >
        <p-listbox
          [options]="regions"
          [(ngModel)]="field.value.regions"
          (onChange)="onRegionChange($event)"
          multiple="multiple"
          checkbox="checkbox"
          [listStyle]="{ 'max-height': '200px' }"
        >
        </p-listbox>
      </div>
    </div>
  `,
  styles: [`
    .no-padding {
      padding: 0;
    }

    p-autocomplete ::ng-deep .ui-autocomplete-multiple-container {
      width: calc(100% - 23px); // dropdown button
    }
  `]
})
export class RegionSearchComponent implements OnInit {
  @Input() public field: SimpleSearchField = null;
  @Input() public component: AbstractGenericGridComponent = null;

  public countries: SelectItem[] = [];
  public regions: SelectItem[] = [];

  public constructor(
    public cdr: ChangeDetectorRef,
    private genericCrudService: GenericCrudService
  ) {
  }

  public ngOnInit() {
    this.initDefaultValues();
    this.loadRegions();
  }

  public onCompleteMethod(event: {query: string}): void {

    this.loadCountriesOptions(event.query)
      .subscribe((options: SelectItem[] = []) => {
        this.countries = options;

        ChangeDetectorRefHelper.detectChanges(this);
      });
  }

  public onKeyUp(event): void {
    const value = event.target.value;

    if (value === '') {
      this.field.value = {
        regions: [],
        countries: [],
        context: 'region'
      };
      this.field.searchValue = null;
    }
  }

  public onCountrySelect(event: any): void {
    this.field.searchValue = this.getSearchValue();
    this.field.skipSearch = !this.field.searchValue;

    this.loadRegions();
  }

  public onCountryUnSelect(event: any): void {
    this.regions = [];
    this.field.value.regions = [];
    this.field.searchValue = this.getSearchValue();
    this.field.skipSearch = !this.field.searchValue;
    this.loadRegions();
  }

  public onRegionChange(event: any): void {
    this.field.searchKey = this.getSearchKey();
    this.field.searchValue = this.getSearchValue();
    this.field.skipSearch = !this.field.searchValue;
  }

  protected loadCountriesOptions(query: string = ''): Observable<SelectItem[]> {
    const apiRoute = `phoenix/countries/offset/0/limit/50/orderby/id/asc?search=${query}`,
      requestParams = {clause: 'orWhere'};

    return this.genericCrudService.getEntities(apiRoute, '', requestParams).pipe(
      catchError((response) => {
        return observableThrowError(response);
      }),
      map((dmRecords: any) => {
        const options = [],
          records = (dmRecords.data) ? dmRecords.data : dmRecords;

        for (const record of records) {
          options.push({
            label: `${record.name}`,
            value: record
          });
        }

        return options;
      }));
  }

  protected loadRegions(): void {
    if (this.field.value && this.field.value.countries instanceof Array &&
      this.field.value.countries.length > 0
    ) {
      this.loadRegionsOptions(this.field.value.countries)
        .subscribe((options: SelectItem[] = []) => {
          this.regions = options;

          ChangeDetectorRefHelper.detectChanges(this);
        });
    } else {
      this.regions = [];
    }

    ChangeDetectorRefHelper.detectChanges(this);
  }

  protected loadRegionsOptions(countries: SelectItem[] = []): Observable<SelectItem[]> {
    const apiRoute = `phoenix/regions/offset/0/limit/50/orderby/id/asc?embedded=country`,
      requestParams = {clause: 'orWhere'},
      countryIds = [];

    for (const country of countries) {
      countryIds.push(country.value.id);
    }

    requestParams['country'] = `in:${countryIds.join(',')}`;

    return this.genericCrudService.getEntities(apiRoute, '', requestParams).pipe(
      catchError((response) => {
        return observableThrowError(response);
      }),
      map((dmRecords: any) => {
        const options = [],
          records = (dmRecords.data) ? dmRecords.data : dmRecords;

        for (const record of records) {
          options.push({
            label: `${Entity.getValueInEmbedded(record, 'country.code')} - ${record.name}`,
            value: record
          });
        }

        return options;
      }));
  }

  private initDefaultValues(): void {
    this.field.comparator = AbstractGridFilter.MATCH_MODE_IN;

    this.field.value = this.field.value && this.field.value.context === 'region' ? this.field.value : {
      regions: [],
      countries: []
    };
    this.field.value.context = 'region';
    this.field.embed = this.getEmbed();
    this.field.searchKey = this.getSearchKey();
    this.field.searchValue = this.getSearchValue();
    this.field.skipSearch = !this.field.searchValue;
    // should be leasedEmployee.regions for leasedEmployee IF SEARCH IN DESIRED LOCATION
    // should be addresses.region or locality.region for the rest
  }

  private getEmbed(): string {
    const entityName = this.field.meta.entityName;

    // if (this.component.getElementDatamodelEntityName() === 'PhoenixBundle\\Entity\\LeasedEmployee') {
    //   return `regions`;
    // }

    if (entityName === 'mainAddress') {
      return 'addresses';
    }

    return 'locality';
  }

  private getSearchKey(): string {
    const entityName = this.field.meta.entityName,
      searchFor = this.isRegionSearch() ? 'region' : 'country';

    // if (this.component.getElementDatamodelEntityName() === 'PhoenixBundle\\Entity\\LeasedEmployee') {
    //   return `regions.${searchFor}`;
    // }

    if (entityName === 'mainAddress') {
      return `addresses.${searchFor}`;
    }

    return `locality.${searchFor}`;
  }

  private getSearchValue(): string {
    const ids = [],
      searchIn = this.isRegionSearch() ? 'regions' : 'countries';

    const values = this.field.value[searchIn];

    if (values instanceof Array) {
      for (const value of values) {
        ids.push(value.value && value.value.id ? value.value.id : value.id);
      }
    }

    if (ids.length === 0) {
      return '';
    }

    return ids.join(',');
  }

  private isRegionSearch(): boolean {
    return this.field.value.regions && this.field.value.regions.length > 0;
  }
}
