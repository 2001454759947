import {Component, OnInit, Input} from '@angular/core';
import {Menu} from '../../../../../../shared/services/menu/menu';
import {TranslateService} from '@ngx-translate/core';
import {GenericCrudService} from '../../../../../../shared/services/generic-crud.service';
import {MessageGrowlService} from '../../../../../message/message-growl.service';
import {Constants} from '../../../../../../constants';
import {Subscription} from 'rxjs/Rx';
import {map} from 'rxjs/operators';
import {File} from '../../../../../../shared/models/file';
import {ChangeDetectorRefHelper} from '../../../../../../shared/helpers/change-detector-ref.helper';
import {forkJoin, Observable, of} from 'rxjs';

@Component({
  selector: 'app-work-hours-import-menu-item',
  templateUrl: './work-hours-import-menu-item.component.html',
  styleUrls: ['./work-hours-import-menu-item.component.scss']
})
export class WorkHoursImportMenuItemComponent implements OnInit {

  @Input() iconsPath = '';
  @Input() item: Menu;
  @Input() menuPosition = 'left';

  public isDialogVisible = false;

  customersDropdown: any[] = [];
  selectedCustomer = null;
  protected subscriptions: Subscription[] = [];
  public isDataLoading = false;

  constructor(
    protected translateService: TranslateService,
    protected genericCrudService: GenericCrudService,
    protected messageGrowlService: MessageGrowlService
  ) {
  }

  ngOnInit() {
  }

  public onItemClick(item) {
    this.isDialogVisible = true;

    if (this.customersDropdown.length <= 0) {
      this.loadCustomers();
    }

    return false;
  }

  public onUpload({files}: { files: File[] }): void {
    this.upload(files).subscribe(() => {

    });
  }

  public upload(files): Observable<any> {
    const observables = [];

    this.isDataLoading = true;
    if (files.length === 0) {
      return of(null);
    }

    const parsedFiles = [];
    for (const file of files) {
      const fileEntity = {
        file: file,
        name: file.name,
        fileType: file.type,
        fileSize: file.size,
      }

      parsedFiles.push(fileEntity);
    }
    const params = {
      customer: this.selectedCustomer
    };

    observables.push(this.genericCrudService.uploadMany(`phoenix/workhourperiodentries/import/workhours?`, parsedFiles, params));
    return forkJoin(observables)
      .pipe(
        map(() => {
          this.dialogHide();
          ChangeDetectorRefHelper.detectChanges(this);
        }));
  }

  dialogHide() {
    this.isDialogVisible = false;
    this.isDataLoading = false;
  }

  private loadCustomers() {
    this.subscriptions.push(
      this.genericCrudService.getEntities(`${Constants.PHOENIX_API_ROUTE}/customers`, '', {embedded: 'none'}).subscribe((customers) => {
        this.customersDropdown.unshift({ label: this.translateService.instant('COMMON.PLEASE_SELECT'), value: 0 });

        for (const customer of customers) {
          this.customersDropdown.push({label: customer.primaryName, value: customer.id });
        }
      })
    )
  }
}
