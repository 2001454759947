import {Component, OnInit, Input, HostBinding} from '@angular/core';
import { TooltipModule } from 'primeng/primeng';

@Component({
  selector: 'app-generic-toggle-button',
  templateUrl: './generic-toggle-button.component.html',
  styleUrls: ['./generic-toggle-button.component.scss']
})
export class GenericToggleButtonComponent implements OnInit {

  @Input() title = '';
  @Input() imageSource = '';
  @Input() tooltipText = '';
  @Input() isChecked = false;

  constructor() { }

  ngOnInit() {

  }

  public onToggle(event){
    this.isChecked = !this.isChecked;
  }
}
