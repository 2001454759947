/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "./lock-column.component";
var styles_LockColumnComponent = [];
var RenderType_LockColumnComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_LockColumnComponent, data: {} });
export { RenderType_LockColumnComponent as RenderType_LockColumnComponent };
function View_LockColumnComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 0, "img", [], [[8, "src", 4]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.getLockIcon(); _ck(_v, 0, 0, currVal_0); }); }
export function View_LockColumnComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, "table", [], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 3, "tr", [], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 2, "td", [], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_LockColumnComponent_1)), i0.ɵdid(4, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.getLockIcon(); _ck(_v, 4, 0, currVal_0); }, null); }
export function View_LockColumnComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-lock-column", [], null, null, null, View_LockColumnComponent_0, RenderType_LockColumnComponent)), i0.ɵdid(1, 49152, null, 0, i2.LockColumnComponent, [i0.ElementRef], null, null)], null, null); }
var LockColumnComponentNgFactory = i0.ɵccf("app-lock-column", i2.LockColumnComponent, View_LockColumnComponent_Host_0, { node: "node", entity: "entity", component: "component", column: "column" }, {}, []);
export { LockColumnComponentNgFactory as LockColumnComponentNgFactory };
