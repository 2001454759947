
import {of as observableOf, Observable} from 'rxjs';
import {AbstractExecutionStep} from '../../../../core/executor/abstract-execution-step';
import {ExecutionStepStatus} from '../../../../core/executor/execution-step-status';
import {AbstractGenericGridComponent} from '../../../content-renderer/elements/abstract-generic-grid.component';
import {Constants} from '../../../../constants';
import {ModuleState} from '../../../content-renderer/services/module-state';
import {FormViewerComponent} from '../../../form-viewer/form-viewer.component';

export class FilterReportExecutionGridByReportExecutionStep extends AbstractExecutionStep {

  public doExecute(): Observable<ExecutionStepStatus> {
    const payload = this.getPayload(),
      component = payload.getValue().component ? payload.getValue().component : payload.getValue();

    if (!(component instanceof AbstractGenericGridComponent)) {
      return this.getFailObservable('You need to pass AbstractGenericGridComponent as Payload value!');
    }

    const reportId = this.getReportIdFromParentModule();

    if (reportId === null) {
      return this.getFailObservable('Could not find Report from parent module!');
    }

    return this.doFilter(component, reportId);
  }

  doFilter(grid: AbstractGenericGridComponent, reportId: number): Observable<ExecutionStepStatus> {
    grid.embeddedFields = [...grid.embeddedFields, 'report'];
    grid.staticFilters.push({
      field: 'report',
      value: 'equal:' + reportId
    });

    return observableOf({status: true, content: null});
  }

  private getReportIdFromParentModule(): number|null {
    const moduleState: ModuleState = this.modulesStateService.getCurrent();

    let reportId = null;

    if (moduleState.parent && moduleState.parent && moduleState.parent.components) {
      for (const component of moduleState.parent.components) {
        if (component instanceof FormViewerComponent && component.getSelectedEntity() && component.getSelectedEntity().id) {
          reportId = component.getSelectedEntity().id;
        }
      }
    }

    return reportId;
  }

}
