import { TemplateElement } from './template.element';

export class Template {

  public static readonly DIRECTION_HORIZONTAL = 'horizontal';
  public static readonly DIRECTION_VERTICAL = 'vertical';

  id: number;
  name: string;
  gutterSize: number;
  fieldsCount: number;
  templateElements: Array<TemplateElement>;
  value: string = '';
  label: string = '';
  direction: string;
  isLocked: boolean = false;

  constructor(id?: number, name?: string, fieldsCount?: number, elements?: Array<TemplateElement>, value?: string, label?: string, gutterSize?: number, direction?: string, isLocked?: boolean) {
    this.id = id;
    this.name = name;
    this.fieldsCount = fieldsCount;
    this.templateElements = elements;

    this.label = label;
    this.value = value;
    this.gutterSize = gutterSize;
    this.direction = direction;
    this.isLocked = isLocked;
  }
}
