import {WorkHourCalculatorAbstract} from './work-hour-calculator-abstract';
import {DateHelper} from '../../../../../helpers/date.helper';

export class WorkHourCalculatorPause extends WorkHourCalculatorAbstract {

  public calculateFields(): any {
    if (this.isIndustrialTime) {
      const preBreakStart = this.entity['workStartIndustry'] || 0,
        workLength = this.entity['workLengthIndustry'] || 0,
        postBreakEnd = this.entity['workEnd2Industry'] || 0;
      this.calculateDurationAndBreakIndustry(preBreakStart, postBreakEnd, workLength);
    } else {
      const preBreakStartTime = this.entity['workStart'] || '00:00',
        workLength = this.entity['workLength'] || '00:00',
        postBreakEndTime = this.entity['workEnd2'] || '00:00';
      this.calculateLengthNormally(preBreakStartTime, postBreakEndTime, workLength);
    }
    return this.entity;
  }

  private calculateDurationAndBreakIndustry(preBreakStart, postBreakEnd, workLength) {
    let breakTime = 0;
    let workingTimeTotal = 0;
    if (postBreakEnd > preBreakStart) {
      workingTimeTotal = postBreakEnd - preBreakStart;
    }

    if (workingTimeTotal > workLength) {
      breakTime = workingTimeTotal - workLength;
    }

    this.entity['workBreakIndustry'] = breakTime;
    this.entity['workBreak'] = DateHelper.convertFromDecimalToTime(this.entity['workBreakIndustry']);
    return this;
  }


  private calculateLengthNormally(preBreakStartTime, postBreakEndTime, workLengthTime) {
    const preBreakStart = DateHelper.convertFromTimeToDecimal(preBreakStartTime),
      workLength = DateHelper.convertFromTimeToDecimal(workLengthTime),
      postBreakEnd = DateHelper.convertFromTimeToDecimal(postBreakEndTime);

    return this.calculateDurationAndBreakIndustry(preBreakStart, postBreakEnd, workLength);
  }
}
