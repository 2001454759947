
import {throwError as observableThrowError, of as observableOf, Observable} from 'rxjs';

import {catchError, map, takeUntil, finalize, tap} from 'rxjs/operators';
import {ChangeDetectorRef, Component, ElementRef, Input, Renderer2, ViewChild, ViewContainerRef} from '@angular/core';
import {cloneDeep} from 'lodash';

import {Element} from './../../../services/element/element';
// import { TemplateElement } from './../../services/template/template.element';
import {GenericCrudService} from '../../../services/generic-crud.service';
import {ModuleElement} from '../../../services/module/module-element';
import {ComponentService} from '../../services/component-highlight-stack.service';
import {ConfirmationService} from 'primeng/primeng';
import {MessageGrowlService} from '../../../../core/message/message-growl.service';
import {PermissionService} from '../../../services/permission/permission.service';
import {GenericGridRemoteFilterService} from './services/generic-grid-remote-filter.service';
import {GenericGridColumnBuilderService} from './services/generic-grid-column-builder.service';
import {FieldMetadataGrid} from '../../../services/module/module-element-field-metadata-grid';
import {GenericGridGlobalFilterService} from './services/generic-grid-global-filter.service';
import {LayoutService} from '../../../services/layout-service';
import {LocationService} from '../../../services/location.service';
import {DoubleClickService} from '../../services/double-click.service';
import {ModulesStateService} from '../../services/modules-state.service';
import {FieldActionsService} from '../../services/field-actions.service';
import {ActionsEvent} from '../../../services/action/action';
import {ElementsStackService} from '../../services/elements-stack.service';
import {EntityFactoryService} from '../../../services/entity-factory.service';
import {ElementContext, ElementType, MasterEntityConfig, PerformedAction, RuntimeFlagName} from '../../services/ElementContext';
import {EntityDirtyStoreService, StoreType} from '../../services/entity-dirty-store.service';
import {ElementsStateService} from '../../services/elements-state.service';
import {ElementState} from '../../services/element-state';
import {TranslateService} from '@ngx-translate/core';
import {EntityChangedMeta, EntityData, EntityDataChangeMeta, EntityDataStoreService} from '../../services/entity-data-store.service';
import {HttpErrorResponseService} from '../../../services/http-error-response-message.service';

import {Constants} from '../../../../constants';
import {LoggerService} from 'app/shared/content-renderer/services/logger/logger.service';
import {GenericGridBulkSaveService} from './services/generic-grid-bulk-save.service';
import {RequestCachingService} from '../../../services/request-caching.service';
import {ToolbarItemCheckService} from 'app/shared/content-renderer/elements/generic-toolbar/services/check/toolbar-item-check.service';
import {EventHandlerService} from '../../../../core/events/event/event-handler.service';
import {EventActionsSubscribeResponse} from '../../../../core/events/interfaces/event-actions-subscribe-response';
import {GenericGridInitEvent} from './events/event/generic-grid-init-event';
import {GenericGridInitActionHandler} from './events/action-handler/generic-grid-init-action-handler';
import {GenericGridDestroyActionHandler} from './events/action-handler/generic-grid-destroy-action-handler';
import {GenericGridDestroyEvent} from './events/event/generic-grid-destroy-event';
import {GenericGridEntityService} from '../../services/generic/entity/generic-grid-entity.service';
import {AbstractGenericGridComponent} from '../abstract-generic-grid.component';
import {GenericElementsHaveChangesEvent} from '../../../services/event/event/generic-elements-have-changes-event';
import {GenericElementsHaveChangesActionHandler} from 'app/shared/services/event/action-handler/generic-elements-have-changes-action-handler';
import {GenericElementInlineEditorService} from 'app/shared/content-renderer/services/generic/generic-element-inline-editor.service';
import {EntityHydrator} from '../../../services/entity-hydrator.service';
import {GenericElementFilterService} from 'app/shared/content-renderer/services/generic/filter/generic-element-filter.service';
import {ExecutorService} from 'app/core/executor/executor.service';
import {GenericElementValidationExecutionStepsFactory} from '../../services/generic/generic-element-validation-execution-steps-factory';
import {EntityValidator} from '../../../validators/services/entity-validator';
import {ExecutionStepBuilderService} from '../../../../core/executor/builder/execution-step-builder.service';
import {ExecutionStatus} from '../../../../core/executor/execution-status';
import {Guid} from 'guid-typescript';
import {Debounce} from 'app/shared/helpers/debounce';
import {MasterEntityImportEvent} from '../../../services/event/event/master-entity-import-event';
import {GenericGridImportMasterEntityDataActionHandler} from '../../../services/event/action-handler/grid/generic-grid-import-master-entity-data-action-handler';
import {LocalStorageDataService} from '../../../services/local-storage-data.service';
import {CancelComponentChangesService} from 'app/shared/content-renderer/services/cancel-components-changes.service';
import {EventHelper} from '../../../helpers/event.helper';
import {ExecutorActionEvent} from '../../../../core/executor/service/executor-actions/executor-action-event';
import {ExecutorActionsService} from 'app/core/executor/service/executor-actions/executor-actions.service';
import {EntityStatus} from '../../../services/entity/entity-status';
import {GenericColumnFilterService} from '../../services/generic/filter/grid/generic-column-filter.service';
import {JobContext} from '../../../../core/job-runner/context/job.context';
import {RunnableEventRegistry} from '../../../../core/job-runner/type/runnable-event.registry';
import {JobContainerService} from '../../../../core/job-runner/job-container.service';
import {GenericDialogModuleService} from '../generic-dialog/service/generic-dialog-module.service';
import {GenericFilterComponent} from '../generic-filter/generic-filter.component';
import {CustomButtonCheckFactoryService} from './services/custom-button-check/custom-button-check-factory.service';
import {ExecutionStepFactoryService} from '../../../../core/executor/factory/execution-step-factory.service';
import {Entity} from '../../../helpers/entity';
import {GenericElementEmbeddedService} from '../../services/generic/generic-element-embedded.service';
import {GenericElementContextMenuService} from '../../services/generic/generic-element-context-menu.service';
import {EntityManagerService} from '../../../../core/service/entity-manager/entity-manager.service';
import {GenericGridSingleEntitySaveService} from './services/generic-grid-single-entity-save.service';

import {GenericGridLayoutService} from './services/generic-grid-layout.service';
import {ChangeDetectorRefHelper} from '../../../helpers/change-detector-ref.helper';
import {UserSessionService} from '../../../../core/service/user-session.service';

@Component({
  selector: 'app-generic-grid',
  styleUrls: ['./generic-grid.component.scss'],
  templateUrl: './generic-grid.component.html',
  providers: [
    CancelComponentChangesService,
    GenericGridRemoteFilterService,
    GenericGridGlobalFilterService,
    GenericGridColumnBuilderService,
    GenericGridBulkSaveService,
    GenericGridEntityService,
    GenericElementInlineEditorService,
    GenericElementFilterService,
    GenericElementValidationExecutionStepsFactory,
    ExecutorService,
    ExecutionStepBuilderService,
    CustomButtonCheckFactoryService,
    GenericGridSingleEntitySaveService,
    GenericGridLayoutService
  ]
})
export class GenericGridComponent extends AbstractGenericGridComponent {
  @Input() element: Element;
  @Input() moduleElement: ModuleElement;
  @Input() parentComponent: GenericGridComponent;
  @Input() isPart = false;
  @Input() isSlave = false;
  @Input() isInSubView = false;
  @Input() masterFilterField?: string;
  @Input() masterFilterValue?: string;

  @ViewChild('dt', {static: false}) grid;

  @Input() set masterField(masterField: string) {
    this.masterEntityField = masterField;
  };

  timeRegexCheck = '^([0-9]|0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$';

  public elementType: ElementType = ElementType.Grid;
  public contextMenuFake = { show: () => {}};

  protected toolbarContextName = 'gridComponent';

  protected gridMessage = '';

  private newEntityAddStarted = false;

  gridStyleClass = 'srollable-datatable-layout';
  showDetailedOverview = false;
  gridFilters: { [s: string]: any; } = {};
  totalCount = 0;

  sortField = 'id';
  sortDirection: string = Element.SORT_DIRECTION_ASC;

  defaultPageSize = 25;
  currentOffset = 0;
  currentPage = 0;
  firstEntry = 0;

  customFilters = ['date', 'number'];
  public dateRangeMinValue: number = (new Date).getFullYear() - 25;
  public dateRangeMaxValue: number = (new Date).getFullYear() + 25;
  public dateRangeValues: number[] = [];

  moduleElementTargetElement;

  emptyEntity: any = {};
  emptyEntityInitDone = false;

  protected updateRequested = false;

  /**
   * the internal state of the grid - current page, offset, filters etc.
   */
  gridState: ElementState;

  constructor(
    protected componentService: ComponentService,
    protected viewContainerRef: ViewContainerRef,
    protected modulesStateService: ModulesStateService,
    protected genericGridColumnBuilderService: GenericGridColumnBuilderService,
    protected genericGridBulkSaveService: GenericGridBulkSaveService,
    protected entityDirtyStore: EntityDirtyStoreService,
    protected entityDataStore: EntityDataStoreService,
    protected toolbarItemCheckService: ToolbarItemCheckService,
    protected requestCachingService: RequestCachingService,
    protected genericCrudService: GenericCrudService,
    protected messageGrowlService: MessageGrowlService,
    protected permissionService: PermissionService,
    protected genericGridRemoteFilterService: GenericGridRemoteFilterService,
    protected doubleClickService: DoubleClickService,
    protected fieldActionsService: FieldActionsService,
    protected genericGridGlobalFilterService: GenericGridGlobalFilterService,
    protected elementRef: ElementRef,
    protected renderer: Renderer2,
    protected layoutService: LayoutService,
    protected locationService: LocationService,
    protected elementsStackService: ElementsStackService,
    protected elementStateService: ElementsStateService,
    protected entityFactory: EntityFactoryService,
    protected confirmationService: ConfirmationService,
    protected translationService: TranslateService,
    protected logger: LoggerService,
    protected httpErrorResponseService: HttpErrorResponseService,
    protected eventHandlerService: EventHandlerService,
    protected genericElementEntityService: GenericGridEntityService,
    protected genericElementInlineEditorService: GenericElementInlineEditorService,
    protected genericElementFilterService: GenericElementFilterService,
    protected genericElementValidationExecutionStepsFactory: GenericElementValidationExecutionStepsFactory,
    protected entityHydrator: EntityHydrator,
    protected cancelComponentChangesService: CancelComponentChangesService,
    protected executorService: ExecutorService,
    protected entityValidator: EntityValidator,
    protected executionStepBuilderService: ExecutionStepBuilderService,
    protected localStorage: LocalStorageDataService,
    protected executorActionsService: ExecutorActionsService,
    protected jobContainerService: JobContainerService,
    protected genericDialogModuleService: GenericDialogModuleService,
    protected customButtonCheckFactoryService: CustomButtonCheckFactoryService,
    protected stepsFactory: ExecutionStepFactoryService,
    protected embedded: GenericElementEmbeddedService,
    protected contextMenu: GenericElementContextMenuService,
    protected entityManager: EntityManagerService,
    protected genericGridSingleEntitySaveService: GenericGridSingleEntitySaveService,
    protected userSession: UserSessionService,
    public genericGridLayoutService: GenericGridLayoutService,
    public cdr: ChangeDetectorRef
  ) {
    super(componentService, viewContainerRef, entityDataStore, modulesStateService,
      genericGridColumnBuilderService, genericElementEntityService, genericElementInlineEditorService,
      permissionService, genericGridBulkSaveService, entityDirtyStore, entityDataStore,
      requestCachingService, toolbarItemCheckService, eventHandlerService, confirmationService, translationService,
      genericElementFilterService, cancelComponentChangesService, executorService, genericElementValidationExecutionStepsFactory,
      entityValidator, elementStateService, genericCrudService, fieldActionsService,
      userSession, executionStepBuilderService, layoutService,
      genericDialogModuleService, genericGridRemoteFilterService, embedded, contextMenu, entityManager, jobContainerService,
      cdr
    );

    this.eventHandlerService.getRegistry()
      .register(GenericGridInitEvent, new GenericGridInitActionHandler())
      .register(GenericGridDestroyEvent, new GenericGridDestroyActionHandler(this.elementsStackService, this.elementStateService))
      .register(GenericElementsHaveChangesEvent, new GenericElementsHaveChangesActionHandler())
      .register(MasterEntityImportEvent, new GenericGridImportMasterEntityDataActionHandler());
  }

  public ngOnInit() {
    super.ngOnInit();

    this.onComponentInit();
  }

  public onComponentInit() {
    this.genericGridSingleEntitySaveService.setComponent(this);
    this.showGridEmptyMessage();
    this.executorActionsService
      .registerModuleElementActions(this.moduleElement)
      .subscribe();

    this.dateRangeValues = [this.dateRangeMinValue, this.dateRangeMaxValue];

    this.assignSubscriptions();
    this.elementContext = this.createContext();
    this.runJobs(RunnableEventRegistry.ContextCreated);

    this.sortField = this.element.sortField || this.sortField;
    this.sortDirection = this.element.sortDirection || this.sortDirection;

    this.initColumns();
    if (this.moduleElement.isPermissionAware) {
      this.addPermissionsColumns();
    }
    this.initToolbarItems();
    if (this.element.pageSize) {
      this.defaultPageSize = this.element.pageSize;
    }

    this.componentState = this.elementStateService.findById(this.moduleElement.id);

    if (this.componentState) {
      this.gridState = this.componentState;
      this.defaultPageSize = this.componentState.pageSize;
      this.gridFilters = this.componentState.filters;
      this.firstEntry = this.componentState.pageSize * this.componentState.currentPage;
      this.currentPage = this.componentState.currentPage;
      this.dataLoaded = this.componentState.dataLoaded;
    }

    if (this.element.datamodel && !this.hasMasterElement() && !this.element.isPaginable) {
      this.subscriptions.push(
        this.loadEntities().pipe(takeUntil(this.unsubscribe)).subscribe()
      );
    }

    if (this.element.datamodel && this.hasMasterElement()) {
      const context = new JobContext();
      context.identifier = Guid.create().toString();
      context.component = this;
      context.event = RunnableEventRegistry.PostInit;

      this.jobContainerService.runRelevantJobs(context);
    }

    this.eventHandlerService.handle(new GenericGridInitEvent(this))
      .subscribe((response: EventActionsSubscribeResponse) => {

      });

    this.executeAction(ExecutorActionEvent.Init, this).subscribe();
  }

  public onDestroyComponent() {
    let elementContext = this.elementsStackService.findById(this.moduleElement.id);

    if (elementContext.performedAction !== PerformedAction.Close
      && elementContext.performedAction !== PerformedAction.Back) {
      // Now create a state of the component and add it:
      let componentState =
        new ElementState(this.moduleElement.id, ElementType.Grid,
          this.moduleElement, false, Math.floor(this.currentOffset / this.defaultPageSize),
          this.defaultPageSize, this.gridFilters, this.selectedEntity, this.dataLoaded);
      const newEntities = this.entityDirtyStore.getAll(StoreType.Create, this.getElementDatamodelEntityName());
      for (const createdEntity of newEntities) {
        componentState.addCreatedElement(createdEntity);
      }

      this.elementStateService.remove(componentState).add(componentState);
    }

    this.elementsStackService.remove(elementContext);

    this.executeAction(ExecutorActionEvent.Destroy, this).subscribe();
  }

  public addCreatedEntityDraft(entity: any): this {
    if (!entity[EntityStatus.ENTITY_DRAFT_FLAG]) {
      entity[EntityStatus.ENTITY_DRAFT_FLAG] = Guid.create().toString();
    }

    this.finishNewEntityAdd(entity);

    return this;
  }

  protected assignSubscriptions() {
    this.subscriptions.push(
      this.entityDataStore.entitiesChanged$.pipe(takeUntil(this.unsubscribe)).subscribe((entries) => {
        if (this.updateRequested && this.getElementDataModelApiRoute() === entries['apiRoute'] && this.checkCurrentModule()) {
          this.updateRequested = false;
          this.handleLoadedEntities(entries);

          this.notifySlaves(ExecutorActionEvent.EntitiesChanged)
            .notifyMaster(ExecutorActionEvent.EntitiesChanged);

          this.executeAction(ExecutorActionEvent.EntitiesChanged, this).subscribe();

          this.toolbarItemCheckService.check(this);
        }
      }),
      this.entityDataStore.entityDeleted$.pipe(takeUntil(this.unsubscribe)).subscribe((entity) => {
        if (entity && this.getElementDatamodelEntityName() === entity['fqn']) {
          this.subscriptions.push(
            this.loadEntities().subscribe()
          );
        }
      }),
      this.entityDataStore.entityChanged$.pipe(takeUntil(this.unsubscribe)).subscribe((meta: EntityChangedMeta) => {
        if (meta.entity) {
          if (this.getElementDatamodelEntityName() === meta.entity['fqn']) {
            this.reselectEntity();
          } else {
            if (!this.elementContext.isSlaveContext()) {
              this.subscriptions.push(
                this.loadEntities().subscribe()
              );
            }
          }
        }

        this.toolbarItemCheckService.check(this);
      }),
      this.layoutService.layoutSizeChanged$.subscribe(() => {
        this.setGridScrollHeightAndWidth();
      })
    );

    this.assignEntityValueChangesSubscription();
  }

  protected assignEntityValueChangesSubscription(): void {
    this.subscriptions.push(
      this.entityDataStore.entityValueChanged$.subscribe((entityDataChangeMeta: EntityDataChangeMeta) => {
        if (this.getElementDatamodelEntityName() === entityDataChangeMeta.entity['fqn'] &&
          (!this.selectedEntity || entityDataChangeMeta.entity[EntityStatus.ENTITY_DRAFT_FLAG]
            === this.selectedEntity[EntityStatus.ENTITY_DRAFT_FLAG])) {

          this.onChange(entityDataChangeMeta).subscribe((entity) => {
            this.onValidate().subscribe((status: ExecutionStatus) => {
              this.executeAction(ExecutorActionEvent.EntityValidated, {
                component: this,
                entityValidationStatus: status
              }).subscribe();
            });
          });
        }
      })
    );
  }

  protected createContext() {
    const isSlave = this.moduleElement && typeof this.moduleElement.master !== 'undefined'
      && this.moduleElement.master !== null && this.moduleElement.master.id > 0;

    const isSubView = this.locationService.hasParam('parent-module')
      || (this.locationService.hasParam('id') && this.locationService.hasParam('master-entity'));

    const elementContext = new ElementContext(
      this.moduleElement.id,
      this.elementType,
      this,
      this.moduleElement,
      !isSlave,
      isSlave,
      !isSlave,
      isSubView,
      this.isPart,
      null,
      this.masterElementContext,
      null,
      null,
      null,
      this.isDialog,
      this.moduleElement.isMaster
    );

    if (isSubView) {
      const elementConfig = new MasterEntityConfig();
      elementConfig.value = this.locationService.getParam('id');
      elementConfig.name = this.locationService.hasParam('master-entity')
        ? this.locationService.getParam('master-entity') : this.moduleElement.masterFilterField;
      elementConfig.datamodelId = 0; // For now - no way to find out.
      elementConfig.filterType = 'subViewMasterEntity';

      elementContext.addMasterEntity(elementConfig);
    }

    // Now, some kinky stuff - parts in dialogs - hit it:
    if (this.isDialog && isSubView && this.selectedMasterEntity && this.moduleElement.masterFilterField) {
      const elementConfig = new MasterEntityConfig();
      elementConfig.value = this.selectedMasterEntity.id;
      elementConfig.name = this.moduleElement.masterFilterField;
      elementConfig.datamodelId = 0; // For now - no way to find out.
      elementConfig.filterType = 'subViewMasterEntity';

      elementContext.addMasterEntity(elementConfig);
    }

    if (this.selectedMasterEntity && this.masterEntityField) {
      const elementConfig = new MasterEntityConfig();
      elementConfig.value = this.selectedMasterEntity.id;
      elementConfig.name = this.masterEntityField;
      elementConfig.datamodelId = 0; // For now - no way to find out.
      elementConfig.filterType = 'masterEntity';

      elementContext.setSelectedMasterEntity(this.selectedMasterEntity).addMasterEntity(elementConfig);
    }

    if (this.masterFilterField && this.masterFilterValue){
      const elementConfig = new MasterEntityConfig();
      elementConfig.value = this.masterFilterValue;
      elementConfig.name = this.masterFilterField;
      elementConfig.datamodelId = 0; // For now - no way to find out.
      elementConfig.filterType = 'masterEntity';

      elementContext.addMasterEntity(elementConfig);
    }

    // Now let's remove and re-add the grid context if it is already there:
    this.elementsStackService.remove(elementContext).add(elementContext);

    return elementContext;
  }

  public selectLastOrFirstEntity(): void {
    if (this.selectedEntity && this.genericElementEntityService.findEntity(this.selectedEntity)) {
      this.selectEntity(this.selectedEntity).onEntitySelected();
    } else {
      this.selectFirstEntity();
    }
  }

  public onGridRendered() {
    this.setGridScrollHeightAndWidth();
  }

  protected setGridScrollHeight(): this {
    let gridContainerHeight = this._gridContainer.nativeElement.clientHeight,
      dataTableBody = this.elementRef.nativeElement.querySelector('.ui-datatable-scrollable-body'),
      dataTableHeader = this.elementRef.nativeElement.querySelector('.ui-datatable-scrollable-header');

    // TODO::should we check for every element if it is visible or not to calculate height?
    // or use fixed heights like for header, pagination, footer bellow?

    // grid header (filters)
    if (dataTableHeader) {
      gridContainerHeight -= +dataTableHeader.clientHeight;
    }

    if (this.element.isPaginable) {
      gridContainerHeight -= 25;
    }

    if (this.element.topToolbarItems && this.element.topToolbarItems.length > 0) {
      gridContainerHeight -= 30;
    }

    if (this.element.statusBarItems && this.element.statusBarItems.length > 0) {
      gridContainerHeight -= 30;
    }

    if ((this.isFilterContainerVisible || this.element.isFilterContainerAlwaysVisible) && this.filterComponent && this.filterComponent.filterContainer) {
      gridContainerHeight -= this.filterComponent.filterContainer.nativeElement.offsetHeight;
    }

    this.scrollHeight = `${gridContainerHeight}px`;

    // when datatable is empty
    if (dataTableBody) {
      dataTableBody.style.height = `${gridContainerHeight}px`;
      dataTableBody.style.maxHeight = '';
    }

    return this;
  }

  protected setGridScrollWidth(): this {
    const gridContainerWidth = this._gridContainer.nativeElement.clientWidth;

    this.scrollWidth = `${gridContainerWidth}px`;

    return this;
  }

  protected setGridScrollHeightAndWidth() {
    if (this.element && this._gridContainer) {
      this.setGridScrollHeight()
        .setGridScrollWidth();
    }
  }

  /**
   *
   * @returns {PermissionService}
   */
  public getPermissionService() {
    return this.permissionService;
  }

  private changeGridEntities(entities: any[] = [], count: number = 0) {
    this.entities = entities;
    this.totalCount = count;

    const isMasterFilterEnabled = this.getMasterFilterState();

    if (this.entities instanceof Array) {
      for (const entity of this.entities) {
        if (!isMasterFilterEnabled) {
          this.entityFactory.assignMasterEntities(entity, this.elementContext.getMasterEntities());
        }
      }
    }

    this.addShelved();

    this.layoutService.onLayoutSizeChanged();

    if (this.entities.length > 0 && !this.newEntityAddStarted) {
      if (this.selectedEntity && this.findEntity(this.selectedEntity)) {
        this.selectLastSelectedEntity();
      } else if (this.moduleElement.selectFirst) {
        this.selectFirstEntity();
      }

      this.onValidate().subscribe();
    }

    this.executeAction(ExecutorActionEvent.Load, this).subscribe();
  }

  public setEmptyEntity() {
    if (!this.emptyEntityInitDone) {
      this.subscriptions.push(
        this.entityFactory
          .buildComponentEntity(this).pipe(
          takeUntil(this.unsubscribe))
          .subscribe((entity) => {
            this.emptyEntity = entity;
            this.setEmptyEntityToSlaves(this.emptyEntity);
          })
      );
    }

    this.emptyEntityInitDone = true;
  }

  public loadEntities(): Observable<any> {
    if (this.element && this.element.datamodel) {
      return Observable.create((observer) => {
        this.doLoadEntities(this.getElementDataModelApiRoute());

        observer.next();
        observer.complete();
      });
    }

    return observableOf(null);
  }

  public getGlobalFilters(): Object {
    return this.genericGridGlobalFilterService.setGrid(this).getGridFilters(this.getElementDataModelApiRoute());
  }

  public getMasterFilterState(): boolean {
    return this.genericGridGlobalFilterService
      .setGrid(this)
      .getGlobalFilterValue(Constants.SHOW_WITHOUT_MASTER_FILTER_FIELD_FILTER_KEY, null, this.getElementDataModelApiRoute()) || false;
  }

  public setMasterFilterState(state: boolean) {
    const filterKey = this.genericGridGlobalFilterService
      .setGrid(this)
      .getFilterKeyInGridContext(
        Constants.SHOW_WITHOUT_MASTER_FILTER_FIELD_FILTER_KEY,
        this.getElementDataModelApiRoute()
      );

      this.userSession.set(filterKey, {
        value: state
      });
  }

  public getRemoteDynamicFilters(): Object {
    let remoteFilters = {};

    const showWithoutMasterFilter = this.getMasterFilterState();

    for (const masterElementConfig of this.elementContext.getMasterEntities()) {
      // skip master filter field
      if ((showWithoutMasterFilter && masterElementConfig.filterType === 'masterEntity' &&
        this.moduleElement.masterFilterField &&
        masterElementConfig.name === this.moduleElement.masterFilterField)
        || (remoteFilters[masterElementConfig.name] && !masterElementConfig.value)
      ) {
        continue;
      }

      remoteFilters[masterElementConfig.name] = masterElementConfig.value;
    }

    remoteFilters['embedded'] = this.embedded.getEmbeddedAsString(this);

    const groupByFieldNames = this.genericGridColumnBuilderService.getGroupByFieldNames();
    if (groupByFieldNames.length > 0) {
      remoteFilters['groupBy'] = groupByFieldNames.join(',');
    }

    for (const staticFilter of this.staticFilters) {
      remoteFilters[staticFilter.field] = staticFilter.value;
    }

    for (const simpleFilter of this.simpleSearchFilters) {
      remoteFilters[simpleFilter.field] = simpleFilter.value;
    }

    if(this.sortField === 'Selection' && this.selectedEntities){
      let sel = this.selectedEntities.map(function (item) {
        return item.id;
      });
      remoteFilters = Object.assign(remoteFilters,{'orderType':sel.join(',')});
    }

    remoteFilters = Object.assign(remoteFilters,
      this.genericGridRemoteFilterService
      .setGrid(this)
      .getModuleElementFilters(this.moduleElement)
    );
    remoteFilters = Object.assign(remoteFilters, this.genericGridRemoteFilterService.setGrid(this).getFilters(this.gridFilters));
    remoteFilters = Object.assign(remoteFilters, this.getGlobalFilters());

    return remoteFilters;
  }

  private checkMasterEntities() {
    let masterEntityConfigThere = true;
    if (this.hasMasterElement()) {
      masterEntityConfigThere = this.elementContext.findMasterEntity(this.moduleElement.masterFilterField) != null
        || !this.elementContext.masterElementContext
        || !this.elementContext.masterElementContext.moduleElement.selectFirst;
    }

    return masterEntityConfigThere;
  }

  private handleLoadedEntities(entries): void {
    this.changeGridEntities(entries['data'], entries['count']);

    if (this.newEntityAddStarted) {
      this.finishNewEntityAdd(this.emptyEntity);
    }

    const componentState = this.elementStateService.findById(this.moduleElement.id);

    if (componentState) {
      // Now let's load the newly added entities:
      for (const newEntity of componentState.getCreatedElements()) {
        if (!this.findEntity(newEntity)) {
          this.entities = [newEntity, ...this.entities];
        }
      }

      componentState.clearCreatedElements();
    }

    this.genericElementEntityService.mergeEntities();

    this.entityFactory.assignMasterEntities(this.emptyEntity, this.elementContext.getMasterEntities());

    setTimeout(() => {
      this.genericGridLayoutService
        .setElementRef(this.elementRef)
        .setRenderer(this.renderer)
        .setGrid(this)
        .adaptRowsColor();

      this.onEntitiesChanged();

      ChangeDetectorRefHelper.detectChanges(this);
    }, 5);
  }

  protected onEntitiesChanged(): void {

  }

  private doLoadEntities(apiRoute: string) {
    const grid = this;

    this.updateRequested = true;

    if (!this.checkMasterEntities()) {
      setTimeout(function() { grid.layoutService.onLayoutSizeChanged(); }, 1);
      return;
    }

    if (Object.keys(this.emptyEntity).length === 0) {
      this.setEmptyEntity();
    }

    this.isDataLoading = true;
    ChangeDetectorRefHelper.detectChanges(this);

    if (this.element.isPaginable) {
      apiRoute = apiRoute + '/offset/' + this.currentOffset
        + '/limit/' + this.defaultPageSize + '/orderby/'
        + this.sortField + '/' + this.sortDirection.toLowerCase();
    }

    if (this.getElementContext().getMasterElementContext()
      && EventHelper.isCustomEvent(event, Constants.EVENT_CLICK_GRID_ENTITY_SELECTED)) {
      this.setMasterFilterState(this.getElementContext().getMasterElementContext().component.selectedEntity === null);
    }

    this.shelveChanges();
    this.executeAction(ExecutorActionEvent.BeforeLoad, this).subscribe(() => {
      return this.entityDataStore
        .getAll(apiRoute, this.getRemoteDynamicFilters(), this.element.isPaginable ? true : false).pipe(
        takeUntil(this.unsubscribe),
        catchError((response) => {
          this.entities = [];

          const errors = this.httpErrorResponseService.getErrors(response);

          this.showGridErrorsMessage(errors);

          this.isDataLoading = false;

          return observableThrowError(response);
        }),map((entityData: EntityData) => {
          this.gridMessage = this.translationService.instant('COMMON.NO_RECORDS_FOUND');

          this.showGridEmptyMessage();

          this.isDataLoading = false;
          this.dataLoaded = true;

          return entityData;
        }),)
        .subscribe((entries) => {
          if (this.updateRequested) {
            this.updateRequested = false;
            this.handleLoadedEntities(entries);

            this.notifySlaves(ExecutorActionEvent.EntitiesChanged)
              .notifyMaster(ExecutorActionEvent.EntitiesChanged);

            this.executeAction(ExecutorActionEvent.EntitiesChanged, this).subscribe();

            this.toolbarItemCheckService.check(this);
          }
        });
    });
  }

  public reselectEntity(): void {
    if (this.selectedEntity) {
      this.resetSelectedEntity();

      this.selectEntity(this.selectedEntity).onEntitySelected();
    }
  }

  public selectEntity(entity): this {
    this.selectedEntity = entity;

    this.componentService.highlightEntity(this.selectedEntity);

    // Now let's announce this on the context as well:
    this.elementsStackService.setSelectedEntity(this.moduleElement.id, entity);

    return this;
  }

  public selectFirstEntity(): this {

    if (this.entities.length > 0) {
      const entity = this.entities[0];

      this.selectEntity(entity).onEntitySelected();
    }

    return this;
  }

  public selectLastSelectedEntity(): this {
    let entity = this.entities.find((foundEntity) => { return this.selectedEntity && this.selectedEntity.id === foundEntity.id; });

    if (entity) {
      this.selectEntity(entity).onEntitySelected();
    }

    return this;
  }

  public getEntityAssociatedOneEntityValue(entity: any, column: any) {
    const value = Entity.getValue(entity, column.id) || Entity.getValueInEmbedded(entity, column.id);

    if (column.fieldType === FieldMetadataGrid.TYPE_CHECKBOX || column.fieldType === FieldMetadataGrid.TYPE_TRICHECKBOX) {
      return value || false;
    }

    return typeof value === 'string' || typeof value === 'number' ? value : '';
  }

  public getEntityAssociatedOneEntityValueLabel(entity: any, column: any) {
    const entityName = column.field.name.substring(0, column.field.name.lastIndexOf('.')), // extract string until latest '.' occurrence
      associatedOneEntity = this.entityHydrator.getEntityPropertyValue(entity, entityName);

    let label = associatedOneEntity ? FieldMetadataGrid.getOptionLabel(associatedOneEntity, column.field) : null;

    if (label === null) {
      label = Entity.getValue(entity, column.id) || Entity.getValueInEmbedded(entity, column.id);
    }

    return label;
  }

  onEntityChangeInit(changeData) {
    event = new CustomEvent(Constants.EVENT_CLICK_GRID_ENTITY_SELECTED);

    const entity = changeData['data'];

    const fieldKey = changeData['column']['field'];

    this.executeAction(ExecutorActionEvent.BeforeInlineEditInit, this).subscribe();

    this.onBeforeSelect(this.selectedEntity, entity);

    this.selectEntity(entity).onEntitySelected(null, fieldKey);
  }

  private setEmptyEntityToSlaves(emptyEntity: any): void {
    for (const slaveContext of this.elementContext.getSlaveElementContexts()) {
      if (slaveContext.type === ElementType.Form) {
        slaveContext.component.emptyEntity = this.emptyEntity;
      }
    }
  }

  private shelveSlaveChanges(): void {
    const slaveComponents = this.elementContext.getSlaveElementContexts();
    for (const slaveComponent of slaveComponents) {
      if (slaveComponent.type === ElementType.Grid || slaveComponent.type === ElementType.Tree) {
        slaveComponent.component.shelveChanges();
      }
    }
  }

  public onRowClick(originalEvent, dt) {
    event = new CustomEvent(Constants.EVENT_CLICK_GRID_ENTITY_SELECTED);

    this.onBeforeSelect(this.selectedEntity, originalEvent.data);

    setTimeout(() => {
      this.genericGridLayoutService
        .setElementRef(this.elementRef)
        .setRenderer(this.renderer)
        .setGrid(this)
        .adaptRowsColor();
    }, 5);
  }

  public onRowSelect(event) {
    event = new CustomEvent(Constants.EVENT_CLICK_GRID_ENTITY_SELECTED);

    Debounce.isDoubleClick((isDoubleClick: boolean) => {
      if (false === isDoubleClick) {
        this.onEntitySelected(event, null, true);
      }
    }, 200);
  }

  public onEntitySelected(originalEvent = null, fieldKey = null, userTrigger: boolean = false): void {

    if (userTrigger) {
      event = new CustomEvent(Constants.EVENT_CLICK_GRID_ENTITY_SELECTED);

      this.executeAction(ExecutorActionEvent.Click, this).subscribe();
    }

    this.selectEntity(this.selectedEntity);

    this.triggerFieldActions(originalEvent, fieldKey, ActionsEvent.Click);
    this.triggerSlaves(this.selectedEntity);

    this.notifySlaves(ExecutorActionEvent.EntityChanged)
        .notifyMaster(ExecutorActionEvent.EntityChanged);

    this.executeAction(ExecutorActionEvent.EntityChanged, this).subscribe();
  }

  public onColumnResize(event: any) {
  }

  public onRowDblclick(event: any) {
    this.executeAction(ExecutorActionEvent.DoubleClick, this).subscribe();

    if (!this.elementContext.isDialog) {
      // onDoubleClick needs some refactoring, remove field double click, it will no longer exist... will be moved to field actions
      this.doubleClickService
        .setDialogOptions({
          height: +this.moduleElement.onDoubleClickTargetDialogHeight,
          width: +this.moduleElement.onDoubleClickTargetDialogWidth,
          isModal: this.moduleElement.isDoubleClickTargetDialogModal
        })
        .setComponent(this)
        .onDoubleClick(event);
    }

    this.triggerFieldActions(event, null, ActionsEvent.DoubleClick);
  }

  public onRowCustomButtonClick(event: any, column: any): void {
    const field: FieldMetadataGrid = column.field;

    this.triggerFieldActions(event, field.id, ActionsEvent.CustomButtonClick);
  }

  onGoBackToTable() {
    this.showDetailedOverview = false;
  }

  getColumnIsEditable(column) {
    let editable = this.element.isInlineEditable;

    /** check additional permission only if element is editable */
    if (editable) {
      editable = this.permissionService.isGranted(column, 'edit') ? 1 : 0;
    }

    return editable;
  }

  isDisabled(entity): boolean {
    return (entity.tmpIsDeleted || entity.deletedAt);
  }

  isReadOnly(field): boolean {
    return (field.isReadOnly);
  }

  public startNewEntityAdd(entity?: any): void {
    this.newEntityAddStarted = true;

    entity = entity || this.emptyEntity;

    if (this.element.isPaginable &&
      this.grid._first + this.grid.rows < this.grid.totalRecords
    ) {
      // not last page so load it
      this.elementRef.nativeElement.querySelector('.ui-paginator-last').click();
    } else {
      this.finishNewEntityAdd(entity);
    }
  }

  /**
   * @description add new entity, select it in the grid and notify child components about the change
   */
  protected finishNewEntityAdd(entity) {
    const newEntity = cloneDeep(entity);

    newEntity[EntityStatus.ENTITY_DRAFT_FLAG] = Guid.create().toString();

    this.entityFactory.assignMasterEntities(newEntity, this.getElementContext().getMasterEntities());

    this.entities = [newEntity, ...this.entities];

    this.onBeforeSelect(this.selectedEntity, newEntity);
    this.selectEntity(newEntity).onEntitySelected();

    if (this.element.isInlineEditable) {
      var grid = this;
      setTimeout(function() { grid.elementRef.nativeElement.querySelector('.ui-editable-column').click(); }, 1);
    }

    this.newEntityAddStarted = false;

    this.notifySlaves(ExecutorActionEvent.EntitiesChanged)
        .notifyMaster(ExecutorActionEvent.EntitiesChanged);

    this.executeAction(ExecutorActionEvent.AfterNewEntityAdded, {
      component: this,
      entity: newEntity
    }).subscribe();
    this.executeAction(ExecutorActionEvent.EntitiesChanged, this).subscribe();

    // Now attach the new entity to the parent, if needed:
    this.embedToParent(newEntity);
  }

  private embedToParent(entity: any) {
    if (this.getElementContext().isSlave && this.getElementContext().getMasterElementContext()) {
      const component = this.getElementContext().getMasterElementContext().component;

      if (component instanceof AbstractGenericGridComponent && this.getEntityCollectionName()) {
        component.embedEntity(entity, this.getEntityCollectionName());
      }
    }
  }

  public getToolbarExtraParams() {
    return {
      'gridComponent': this
    };
  }

  public onSort(event) {

  }

  public loadGridLazy(event) {
    let observableLoad = null;
    if(typeof this.element.autoloadData === 'undefined' || this.element.autoloadData === null || this.element.autoloadData === true) {
      observableLoad = this.loadEntities();
    }
    this.defaultPageSize = event.rows;
    this.currentOffset = event.first;

    if (event.sortField) {
      this.sortField = event.sortField;
    }

    if (event.sortOrder) {
      this.sortDirection = (+event.sortOrder === 1) ? Element.SORT_DIRECTION_ASC : Element.SORT_DIRECTION_DESC;
    }

    this.gridFilters = event.filters;

    if (observableLoad instanceof Observable) {
      this.subscriptions.push(
        observableLoad.pipe(takeUntil(this.unsubscribe)).subscribe()
      );
    }
  }

  protected onAutocompleteFilterSelect(event, column, datatable) {
    let columnName = column.field;
    if(column.field.indexOf('.') != -1){
      let filterFields = column.field.split('.');
      columnName = filterFields.slice(0,filterFields.length-1).join('.');
    }
    const value = event;

    this.autocompleteFilterColumnValues[columnName] = [...this.autocompleteFilterColumnValues[columnName], value];

    this.filterAutocomplete(column, datatable);
  }

  protected onAutocompleteFilterUnselect(event, column, datatable) {
    let columnName = column.field;
    if(column.field.indexOf('.') != -1){
      let filterFields = column.field.split('.');
      columnName = filterFields.slice(0,filterFields.length-1).join('.');
    }
    const value = event;

    const index = this.autocompleteFilterColumnValues[columnName].findIndex((aValue) => { return aValue.id === value.id; });
    if (index > -1) {
      this.autocompleteFilterColumnValues[columnName] = this.autocompleteFilterColumnValues[columnName].filter((val, i) => i != index);
    }

    this.filterAutocomplete(column, datatable);
  }

  protected filterAutocomplete(column, datatable) {
    let columnName = column.field;
    if(column.field.indexOf('.') != -1){
      let filterFields = column.field.split('.');
      columnName = filterFields.slice(0,filterFields.length-1).join('.');
    }

    this.currentOffset = 0;

    if (this.element.isPaginable) {
      this.gridFilters[columnName] = { value: this.autocompleteFilterColumnValues[columnName], matchMode: '', type: 'autocomplete' };
      this.subscriptions.push(
        this.loadEntities().pipe(takeUntil(this.unsubscribe)).subscribe()
      );
    } else {
      datatable.filter(this.autocompleteFilterColumnValues[columnName], column.field, this.mapFilter(column.filterMatchMode));
    }

    this.triggerFieldActions(event, column.field, ActionsEvent.FilterValueChange);
  }

  protected onAutocompleteFilterSearch(event, column, datatable, isFilterSearch: boolean = false) {
    let query = event.query,
      params = {},
      apiRoute = `${column.associationEndpoint}/offset/0/limit/50`;
    if (column.firstAssociationOrderBy) {
      if(column.secondAssociationOrderBy){
        apiRoute += `/orderby/${column.firstAssociationOrderBy},${column.secondAssociationOrderBy}/${column.firstAssociationOrderByOrientation},${column.secondAssociationOrderByOrientation}?search=${query}&clause=orWhere`;
      } else {
        apiRoute += `/orderby/${column.firstAssociationOrderBy}/${column.firstAssociationOrderByOrientation}?search=${query}&clause=orWhere`;
      }
    } else {
      apiRoute += `/orderby/id/asc?search=${query}&clause=orWhere`;
    }

    if(column.firstAssociationOrderType){
      if(column.secondAssociationOrderType){
        params['orderType'] = `${column.firstAssociationOrderType},${column.secondAssociationOrderType}`;
      }else{
        params['orderType'] = `${column.firstAssociationOrderType}`;
      }
    }

    for (const masterEntity of this.elementContext.getMasterEntities()) {
      if (masterEntity.name && masterEntity.value) {
        params[masterEntity.name] = masterEntity.value;
      }
    }

    if (column.field.dataConfig && column.field.dataConfig.length > 0) {
      const filterParams = GenericColumnFilterService.createFilters(column.field, this);

      for (const filterParam of filterParams) {
        if (filterParam.column) {
          params[filterParam.column] = filterParam.value;
        } else {
          for (const key of Object.keys(filterParam.value)) {
            params[key] = filterParam.value[key];
          }
        }
      }
    }

    if (column.field.name.indexOf('.') !== column.field.name.lastIndexOf('.') && column.field.usePrimaryAssociationEndpoint) {
      const entityFieldNameParts = column.field.name.split('.');

      // We need that middle part :) :
      params['embedded'] = entityFieldNameParts[1];
    }

    let options = this.associatedOptions;

    if (isFilterSearch) {
      options = this.associatedFilterOptions;
    }

    this.genericCrudService.getEntities(apiRoute, '', params).subscribe((entries) => {
      const data = entries.data ? entries.data : entries;

      options[column.entityName] = [];
      data.map((entry) => {
        entry.label = FieldMetadataGrid.getOptionLabel(entry, column.field);

        if (entry.label) {
          options[column.entityName].push(entry);
        }
      });

      options[column.entityName] = [...options[column.entityName]];
    });
  }

  protected filterMonths(event, column, datatable) {
    if (this.element.isPaginable) {
      this.genericElementFilterService.onFilter(event, {fieldName: column.property, filterType: column.field.filterType});
    } else {
      datatable.filter(event.value, column.field, this.mapFilter(column.filterMatchMode));
    }

    this.triggerFieldActions(event, column.field, ActionsEvent.FilterValueChange);
  }

  protected filterSelect(event, column, associatedEntity, datatable, isMultiDropdown: boolean = false) {
    const columnName = column.field.indexOf('.') !== -1 ? column.field.split('.')[0] : column.field;

    this.currentOffset = 0;

    if (this.element.isPaginable) {

      const labelValue = [];
      if (event.value instanceof Array) {
        const optionValues = this.associatedFilterOptions[columnName];

        for (const selectedValue of event.value) {
          const index = optionValues.findIndex((aOptionValue: SelectItem) => { return aOptionValue.value === selectedValue; });

          if (index !== -1) {
            labelValue.push(optionValues[index].label);
          }
        }
      }

      this.gridFilters[columnName] = { value: event.value, matchMode: '', type: 'dropdown', labelValue: labelValue.join(', ') };
      this.subscriptions.push(
        this.loadEntities().pipe(takeUntil(this.unsubscribe)).subscribe()
      );
    } else {
      if (!(event.value instanceof Array)) {
        event.value = [event.value];
      }

      datatable.filter(event.value, column.field, this.mapFilter(column.filterMatchMode));
    }

    this.triggerFieldActions(event, column.field, ActionsEvent.FilterValueChange);
  }

  protected filterYearRange(event, column, datatable) {
    this.currentOffset = 0;

    let yearFrom = event.values[0],
      yearTo = event.values[1];

    let filterValue = `01.01.${yearFrom}-31.12.${yearTo}`;

    if (this.element.isPaginable) {
      this.gridFilters[column.field] = { value: filterValue, matchMode: '', type: 'date' };
      this.subscriptions.push(
        this.loadEntities().pipe(takeUntil(this.unsubscribe)).subscribe()
      );
    } else {
      datatable.filter(filterValue, column.field, this.mapFilter(column.filterMatchMode));
    }

    this.triggerFieldActions(event, column.field, ActionsEvent.FilterValueChange);
  }

  protected filterText(event, column, associatedEntity, datatable, onSearch: boolean = false) {
    Debounce.debounce(() => {
      this.doFilterText(event, column, associatedEntity, datatable, onSearch);
    }, 500);
  }

  private doFilterText(event, column, associatedEntity, datatable, onSearch: boolean = false) {
    let value = event.target.value;

    this.currentOffset = 0;

    if (this.element.isPaginable) {

      if (value && value !== '') {
        this.gridFilters[column.field] = { value: value, matchMode: column.filterMatchMode, type: 'string' };
      } else {
        delete this.gridFilters[column.field];
      }
      this.subscriptions.push(
        this.loadEntities().pipe(takeUntil(this.unsubscribe)).subscribe()
      );
    } else {
      datatable.filter(value, column.field, this.mapFilter(column.filterMatchMode));
    }

    this.triggerFieldActions(event, column.field, ActionsEvent.FilterValueChange);
  }

  private mapFilter(filterName) {
    let mappedFilter = filterName;
    switch (filterName) {
      case 'dropdown':
      case 'autocomplete':
      case 'multidropdown':
        mappedFilter = 'in';
        break;
      case 'checkbox':
      case 'months':
        mappedFilter = 'equals';
        break;
      case 'range_date':
        mappedFilter = 'in';
        break;
    }

    return mappedFilter;
  }

  /**
   * xcentric dt was DataTable
   * @param event
   * @param dt
   */
  public onGridFilter(event, dt: any /**xcentric DataTable**/) {
    if (dt.lazy) {
      const doSomething = 'nothing';
    }
    else {
      dt.filteredValue = [];

      for (let i = 0; i < dt.value.length; i++) {
        let localMatch = true;
        let globalMatch = false;

        for (let j = 0; j < dt.columns.length; j++) {
          const col = dt.columns[j],
            filterMeta = dt.filters[col.field];

          // local
          if (filterMeta) {
            const filterField = col.field.indexOf('.') != -1 ? col.field.split('.')[0] : col.field;

            const filterMatchMode = this.determineMatchMode(filterMeta),
              dataFieldValue = dt.resolveFieldData(dt.value[i], filterField),
              filterValue = this.determineMatchValue(filterMeta),
              customFilter = this.checkCustomFilter(col);

            if (customFilter) {
              if (customFilter == 'date') {
                localMatch = this.filterDate(dataFieldValue, filterValue);
              } else if (customFilter == 'number') {
                localMatch = this.filterNumber(dataFieldValue, filterValue);
              }
            } else {
              const filterConstraint = dt.filterConstraints[filterMatchMode];

              if (!filterConstraint(dataFieldValue, filterValue)) {
                localMatch = false;
              }
            }

            if (!localMatch) {
              break;
            }
          }

          // global
          if (dt.globalFilter && !globalMatch) {
            globalMatch = dt.filterConstraints['contains'](dt.resolveFieldData(dt.value[i], col.field), dt.globalFilter.value);
          }
        }

        let matches = localMatch;
        if (dt.globalFilter) {
          matches = localMatch && globalMatch;
        }

        if (matches) {
          dt.filteredValue.push(dt.value[i]);
        }
      }

      if (dt.filteredValue.length === dt.value.length) {
        dt.filteredValue = null;
      }

      if (dt.paginator) {
        dt.totalRecords = dt.filteredValue ? dt.filteredValue.length : dt.value ? dt.value.length : 0;
      }

      dt.updateDataToRender(dt.filteredValue || dt.value);
    }
  }

  protected checkCustomFilter(column) {
    const columnDefinition = this.findColumnDefinition(column.field);

    if (columnDefinition) {
      const customFilterIndex = this.customFilters.indexOf(columnDefinition.fieldType);
      if (customFilterIndex != -1) {
        return this.customFilters[customFilterIndex];
      }
    }

    return null;
  }

  private findColumnDefinition(fieldName) {
    let columnFound = null;
    for (const aColumn of this.columns) {
      if (aColumn.property == fieldName) {
        columnFound = aColumn;
        break;
      }
    }

    return columnFound;
  }

  protected determineMatchMode(filterMeta) {
    let filterMatchMode = filterMeta.matchMode || 'startsWith';

    if (typeof filterMeta.value == 'string') {
      const asteriskPosition = filterMeta.value.indexOf('*');
      const asteriskCount = (filterMeta.value.match(/\*/gi) || []).length;

      if (asteriskPosition != -1) {
        if (asteriskCount == 1) {
          filterMatchMode = (asteriskPosition != 0) ? 'startsWith' : 'endsWith';
        } else {
          filterMatchMode = 'contains';
        }
      }
    }

    return filterMatchMode;
  }

  protected determineMatchValue(filterMeta) {
    let filterValue = filterMeta.value;

    if (typeof filterMeta.value == 'string' && filterMeta.value.indexOf('*') != -1) {
      filterValue = filterMeta.value.replace("*", "");
    }

    return filterValue;
  }

  protected filterDate(value: string, filterValue: string) {
    if (filterValue.indexOf('-') != -1) {
      return this.filterDateRange(value, filterValue);
    } else {
      return this.filterDateSimple(value, filterValue);
    }
  }

  private filterDateRange(value: string, filterValue: string) {
    const dateValue = new Date(value);
    const dateFilterParts = filterValue.split('-');

    if (dateFilterParts.length != 2) {
      return true;
    }

    const fromValueParts = dateFilterParts[0].split('.');
    const toValueParts = dateFilterParts[1].split('.');

    if (fromValueParts.length != 3 || toValueParts.length != 3 || fromValueParts[2].length != 4 || toValueParts[2].length != 4) {
      return true;
    }

    dateValue.setHours(0);
    dateValue.setMinutes(0);
    dateValue.setMilliseconds(0);
    dateValue.setSeconds(0);

    const fromDate = new Date(parseInt(fromValueParts[2]), parseInt(fromValueParts[1]) - 1, parseInt(fromValueParts[0]), 0, 0, 0, 0);
    const toDate = new Date(parseInt(toValueParts[2]), parseInt(toValueParts[1]) - 1, parseInt(toValueParts[0]), 0, 0, 0, 0);

    return fromDate.getTime() <= dateValue.getTime() && dateValue.getTime() <= toDate.getTime();
  }

  private filterDateSimple(value: string, filterValue: string) {
    const dateValue = new Date(value);
    const filterValueParsed = filterValue.split('.');

    if (filterValueParsed.length < 3 || filterValueParsed[2].length !== 4) {
      return true;
    }

    return dateValue.getDate() === parseInt(filterValueParsed[0])
      && dateValue.getMonth() === (parseInt(filterValueParsed[1]) - 1)
      && dateValue.getFullYear() === parseInt(filterValueParsed[2]);
  }

  protected filterNumber(value, filterValue) {
    if (filterValue.indexOf('-') !== -1) {
      return this.filterNumberRange(value, filterValue);
    } else {
      return this.filterNumberSimple(value, filterValue);
    }
  }

  private filterNumberRange(value, filterValue) {
    const numberFilterParts = filterValue.split('-');

    if (numberFilterParts.length !== 2) {
      return true;
    }

    return parseInt(numberFilterParts[0]) <= parseInt(value) && parseInt(value) <= parseInt(numberFilterParts[1]);
  }

  private filterNumberSimple(value, filterValue) {
    return parseInt(value) === parseInt(filterValue);
  }

  getFieldValue(entity, field, column) {
    return entity[field];
  }

  private showGridEmptyMessage(): void {
    const me = this;

    this.gridMessage = this.translationService.instant('COMMON.NO_RECORDS_FOUND');

    this.changeGridEmptyMessageColor('black');
  }

  private showGridErrorsMessage(errors: string[]): void {
    const me = this;

    this.gridMessage = errors.join();

    this.changeGridEmptyMessageColor('red');
  }

  private changeGridEmptyMessageColor(color: string): void {
    const me = this;

    setTimeout(function() {
      const emptyMessageElement = me.elementRef.nativeElement.querySelector('.ui-datatable-emptymessage');

      if (emptyMessageElement) {
        me.renderer.setStyle(emptyMessageElement, 'color', color);
      }
    }, 100);
  }

  public onBeforeSelect(previousEntity, currentEntity) {

    if (previousEntity && !previousEntity.id &&
      currentEntity[EntityStatus.ENTITY_DRAFT_FLAG] !== previousEntity[EntityStatus.ENTITY_DRAFT_FLAG] &&
      !this.entityDirtyStore.isDirty(previousEntity) && this.getCreatedEntities(true).length === 1
    ) {
      this.removeEntity(previousEntity);
    }

    // In case specified to not show the changes dialog - just skip:
    if (!this.moduleElement.isChangesCheckActivated) {
      return;
    }

    const isAnotherEntitySelected = previousEntity && currentEntity[EntityStatus.ENTITY_DRAFT_FLAG] !==
      previousEntity[EntityStatus.ENTITY_DRAFT_FLAG];

    if (isAnotherEntitySelected && (this.entityDirtyStore.isDirty(previousEntity) || this.slavesHaveChanges())) {
      this.shelveSlaveChanges();

      if (this.moduleElement.isAutoSaveActivated) {
        this.genericGridSingleEntitySaveService.save(previousEntity).subscribe();
      } else {
        this.confirmationService.confirm({
          acceptVisible: true,
          rejectVisible: true,
          header: this.translationService.instant('DIALOG_MESSAGES.UNSAVED_CHANGES_SAVE_QUESTION_HEADER'),
          message: this.translationService.instant('DIALOG_MESSAGES.UNSAVED_CHANGES_SAVE_QUESTION_BODY'),
          icon: 'fa fa-trash',
          accept: () => {
            this.genericGridSingleEntitySaveService.save(previousEntity).subscribe();
          },
          reject: () => {
            this.genericGridSingleEntitySaveService.cancel(previousEntity).subscribe();
          }
        });
      }
    }
  }

  public areAllFiltersDefined(): boolean {
    return true;
  }

  public hasCustomButton(entity: any, column: any) {
    let isVisible = false;

    if (column.field.hasCustomButton) {

      const checker = this.customButtonCheckFactoryService.instance(this, entity, column);

      isVisible = checker.isVisible();
    }

    return isVisible;
  }
}
