import { Event } from 'app/core/events/interfaces/event';
import { AbstractActionHandler } from '../action/abstract-action-handler';

export class EventRegistry {
    
    private registry: { [eventName: string]: AbstractActionHandler[]; } = {};

    public constructor() {

    }

    public register(eventConstructor: Event, actionHandler: AbstractActionHandler): EventRegistry {
        let eventName = eventConstructor['name'],
            actionHandlerName = actionHandler.constructor.name;
        
        this.registry[eventName] = this.registry[eventName] || [];

        if (!this.actionHandlerExists(eventName, actionHandlerName)) {
            this.registry[eventName].push(actionHandler);
        }

        return this;
    }

    public getActionHandlersForEvent(event: Event): AbstractActionHandler[] {
        let eventName = event.constructor.name;

        return this.registry[eventName] || [];
    }

    private actionHandlerExists(eventName: string, actionHandlerName: string): boolean {
        return this.registry[eventName].findIndex((registeredActionHandler: AbstractActionHandler) => {
            const registeredActionHandlerName = registeredActionHandler.constructor.name;

            return registeredActionHandlerName === actionHandlerName
        }) !== -1;

    }

}
