
    <div class="ui-g-12">
      <div class="ui-g-12 no-padding">
        <p-autoComplete
          field="label"
          [(ngModel)]="field.value.countries"
          [suggestions]="countries"
          (completeMethod)="onCompleteMethod($event)"
          (onKeyUp)="onKeyUp($event)"
          (onSelect)="onCountrySelect($event)"
          (onUnselect)="onCountryUnSelect($event)"
          [dropdown]="true"
          [style]="{'width': '80%'}"
          [size]="30"
          [minLength]="2"
          [multiple]="true"
          placeholder="---"
          appendTo="body"
        ></p-autoComplete>
      </div>

      <div
        *ngIf="(field.value.regions && field.value.regions.length > 0) || regions.length > 0"
        class="ui-g-12 no-padding"
      >
        <p-listbox
          [options]="regions"
          [(ngModel)]="field.value.regions"
          (onChange)="onRegionChange($event)"
          multiple="multiple"
          checkbox="checkbox"
          [listStyle]="{ 'max-height': '200px' }"
        >
        </p-listbox>
      </div>
    </div>
  