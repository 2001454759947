<p-dialog *ngIf="isDialogVisible"
    [(visible)]="isDialogVisible"
    [responsive]="true"
    [modal]="true"
    [minWidth]="200"
    width="1200"
    showEffect="fade"
    appendTo="body"
    header=""
>
    <app-user-import [gridComponent]="gridComponent"></app-user-import>
</p-dialog>
