import { Injectable } from '@angular/core';
import { ToolbarItem } from '../../../../../services/element/toolbar-item';
import { ToolbarItemEditColoringService } from './toolbar-item-edit-coloring.service';
import { ToolbarItemColoringService } from './toolbar-item-coloring.service';

export interface ToolbarItemColoringInterface {
  setToolbarItem(toolbarItem: ToolbarItem): ToolbarItemColoringInterface;
  setEntity(entity: any): ToolbarItemColoringInterface;
  setComponent(component: any): ToolbarItemColoringInterface;
  getColor(): string;
}

@Injectable()
export class ToolbarItemColoringFactoryService {

  public instance(item: ToolbarItem, component: any, entity?: any): ToolbarItemColoringInterface {
    let checker = null;

    switch (item.onclick) {
      case 'edit':
        checker = new ToolbarItemEditColoringService();
        break;
      default:
        checker = new ToolbarItemColoringService();
    }

    checker.setComponent(component);
    checker.setEntity(entity);
    checker.setToolbarItem(item);

    return checker;
  }

}
