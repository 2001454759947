import { SearchManagementAutocompleteEditExpertSearchFieldValueComponent } from '../autocomplete/autocomplete-edit-expert-search-field-value.component';
import { FieldMetadataGrid } from '../../../../../../../../../services/module/module-element-field-metadata-grid';
const DISPLAY_CONFIG_PARAM_KEY = 'displayConfiguration';
export class SearchManagementAutocompleteMultiEditExpertSearchFieldValueComponent extends SearchManagementAutocompleteEditExpertSearchFieldValueComponent {
    constructor() {
        super(...arguments);
        this.selectedFieldValue = [];
    }
    onAutoCompleteValueUnSelected(event) {
        this.changeFieldValue(this.selectedFieldValue);
    }
    onDisplayConfigSave(displayConfig = []) {
        this.setParameter(DISPLAY_CONFIG_PARAM_KEY, displayConfig);
        // we have to restart value in order to keep labels correct
        this.selectedFieldValue = [];
        this.loadDropdownOptions()
            .subscribe((options = []) => {
            this.dropdownOptions = options;
            if (this.selectedFieldValue) {
                this.changeFieldValue(this.selectedFieldValue);
            }
            this.selectFieldValue();
        });
    }
    changeFieldValue(fieldValues) {
        const values = [], labelValues = [];
        if (fieldValues instanceof Array) {
            for (const fieldValue of fieldValues) {
                const label = FieldMetadataGrid.getOptionLabel(fieldValue.value, { name: 'name', displayConfig: this.getParameterValue(DISPLAY_CONFIG_PARAM_KEY)
                });
                values.push({
                    id: fieldValue.value.id,
                    label: label
                });
                labelValues.push(label);
            }
        }
        this.onChange.emit({
            value: JSON.stringify(values),
            labelValue: labelValues.join(', ')
        });
    }
    selectFieldValue() {
        const values = this.field.fieldValue ? JSON.parse(this.field.fieldValue) : [];
        if (values && values instanceof Array) {
            const selectedFieldValue = [];
            for (const value of values) {
                this.addOption({
                    value: {
                        id: value.id
                    },
                    label: value.label
                });
                for (const option of this.dropdownOptions) {
                    if (option.value.id === +value.id) {
                        selectedFieldValue.push(option);
                    }
                }
            }
            this.selectedFieldValue = selectedFieldValue;
        }
    }
}
