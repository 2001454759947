
  <p-autoComplete
    field="label"
    [(ngModel)]="selectedValues"
    [multiple]="true"
    [suggestions]="dropdownOptions"
    (completeMethod)="onCompleteMethod($event)"
    (onKeyUp)="onKeyUp($event)"
    (onSelect)="onSelect($event)"
    (onUnselect)="onUnSelect($event)"
    [dropdown]="true"
    [style]="{'width': '100%'}"
    [size]="30"
    [minLength]="2"
    placeholder="---"
    appendTo="body"
  ></p-autoComplete>
  