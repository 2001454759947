import { Module } from '../../services/module/module';
import { GenericElementAbstract } from '../elements/generic-element-abstract.component';

export enum ModuleStateContext {
  Normal = 1,
  Autocomplete = 2,
  Wizard = 3,
  Details = 4,
  Dialog = 5,
  WizardElement = 6
}

export class ModuleState {
  id: number;
  module: Module;
  url: string;
  queryParams: Object;
  isTabVisible: boolean;
  entity: any;
  isPart: boolean;
  components: GenericElementAbstract[];

  parts: ModuleState[] = [];
  main: ModuleState = null;

  isDialog = false; // TODO :: set as context
  isDetailsView = false; // TODO :: set as context
  isAutocompleteView = false; // TODO :: set as to context

  parent: ModuleState;
  productId: number;

  private context: ModuleStateContext[] = [];

  public constructor(
    id: number,
    module: Module = null,
    url: string = '',
    queryParams: Object = {},
    isTabVisible: boolean = true,
    entity: any = null,
    isPart: boolean = false,
    components: GenericElementAbstract[] = [],
    isDialog: boolean = false,
    isDetailsView: boolean = false,
    isAutocompleteView: boolean = false,
    parent: ModuleState = null,
    productId: number = null
  ) {
    this.id = id;
    this.module = module;
    this.url = url;
    this.queryParams = queryParams;
    this.isTabVisible = isTabVisible;
    this.entity = entity;
    this.isPart = isPart;
    this.components = components;
    this.isDialog = isDialog;
    this.isDetailsView = isDetailsView;
    this.isAutocompleteView = isAutocompleteView; // TODO :: set as context
    this.parent = parent;
    this.productId = productId;
  }

  public setMain(moduleState: ModuleState): this {
    this.main = moduleState;
    return this;
  }

  public getParts(): ModuleState[] {
    return this.parts;
  }

  public addContext(context: ModuleStateContext): this {
    if (!this.context.includes(context)) {
      this.context.push(context);
    }

    return this;
  }

  public hasContext(context: ModuleStateContext): boolean {
    return this.context.includes(context);
  }

  public getPartById(moduleId): ModuleState {
    let moduleState = null;

    for (const partModuleState of this.parts) {
      if (partModuleState.id === moduleId) {
        moduleState = partModuleState;
        break;
      }
    }

    return moduleState;
  }

  public addPart(moduleState: ModuleState): this {

    if (!this.partExists(moduleState)) {
      this.parts.push(moduleState);
      moduleState.setMain(this);
    }

    return this;
  }

  public removePart(moduleState: ModuleState): this {
    const index = this.parts.findIndex((aModuleState) => aModuleState.id === moduleState.id);

    if (index !== -1) {
      this.parts.splice(index, 1);
    }

    return this;
  }

  public partExists(moduleState: ModuleState): boolean {
    const index = this.parts.findIndex((aModuleState) => aModuleState.id === moduleState.id);

    return index !== -1;
  }

  public addComponent(component: GenericElementAbstract): this {
    this.components.push(component);
    return this;
  }

  public removeComponent(component: GenericElementAbstract): this {
    const index = this.components.findIndex((aComponent) => aComponent.moduleElement.id === component.moduleElement.id);

    if (index !== -1) {
      this.components.splice(index, 1);
    }

    return this;
  }

  public getComponents(): GenericElementAbstract[] {
    return this.components;
  }

  public getAllComponents(): GenericElementAbstract[] {
    let components = this.components;

    for (const part of this.getParts()) {
      components = components.concat(part.getComponents());
    }

    return components;
  }
}
