import { Component } from '@angular/core';
import { FieldActionAbstractComponent } from '../field-action-abstract.component';
import { GenericCrudService } from '../../../../../services/generic-crud.service';
import { DoubleClickService } from '../../../../services/double-click.service';
import {FieldMetadataGrid} from '../../../../../services/module/module-element-field-metadata-grid';

@Component({
  selector: 'field-action-set-value-to-another-field',
  template: ''
})
export class FieldActionSetValueToAnotherFieldComponent extends FieldActionAbstractComponent {

  public constructor(
    protected genericCrudService: GenericCrudService,
    protected doubleClickService: DoubleClickService
  ) {
    super();
  }

  public fire() {
    const gridComponent = this.getGrid(),
      entity = gridComponent.getSelectedEntity();

    let columnToEdit = this.getActionParamValue('column'),
      condition = this.getActionParamValue('condition'),
      fieldToEdit: FieldMetadataGrid = this.findField(columnToEdit),
      field = this.getField();

    if(entity[field.name] && entity[field.name].toString() == condition) {
      entity[fieldToEdit.name] = this.getValueToSet(fieldToEdit);
    }
  }

  public findField(fieldId: string): any|null {
    for (const field of this.getGrid().fields) {
      if (field.id == fieldId) {
        return field;
      }
    }

    return null;
  }

  private getValueToSet(fieldToEdit){
    let valueToSet = this.getActionParamValue('valueToSet');

    switch (fieldToEdit.fieldType){
      case 'decimal':
        valueToSet = parseFloat(valueToSet);
        break;
    }

    return valueToSet;

  }
}
