<div class="container" fxLayout="column" fxLayoutAlign="start stretch">

  <div fxFlex="100" fxLayout="row">
    <app-content-renderer
      *ngIf="module"
      [isDialog]="true"
      [module]="module"
      [entity]="null"
      [masterEntity]="masterEntity"
      [masterElementContext]="elementContext"
    ></app-content-renderer>

    <div *ngIf="!module">
      {{ 'COMMON.LOADING_PLEASE_WAIT' | translate }}
    </div>
  </div>

  <div fxFlex="30px" fxLayout="row">
    <button
      type="button"
      (click)="onSearch()"
      pButton
      class="search-button"
      label="{{ 'COMMON.SEARCH' | translate }}"
      [style]="{'width': '100%'}"
    ></button>
  </div>

</div>
