import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { ModuleElementSelectionItem } from "./module.element.selection.item";

@Injectable()
export class ModuleElementSelectionCommunicationService {

  // Observable string sources
  private masterModuleElementSelected = new Subject<ModuleElementSelectionItem>();

  // Observable string streams
  moduleElementSelected$ = this.masterModuleElementSelected.asObservable();

  // Service message commands
  onModuleElementSelected(params: any) {
    this.masterModuleElementSelected.next(params);
  }

}
