import { Component } from '@angular/core';
import { AuthenticationService } from '../authentication/authentication.service';
import { environment } from '../../../environments/environment';
import { LoggerService } from '../../shared/content-renderer/services/logger/logger.service';
import {UserSessionService} from '../service/user-session.service';
import {Branch} from '../../shared/services/branch/branch';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent {

  public environment: Environment;

  public isLogVisible = false;

  public constructor(
    public authenticationService: AuthenticationService,
    private logger: LoggerService,
    protected readonly userSession: UserSessionService,
  ) {

  }

  public isLoggingVisible(): boolean {
    return environment.logging && this.logger.count() > 0;
  }

  public footerCountersVisible() {
    return !!this.authenticationService.currentUser && !!this.userSession.get(Branch.LOCAL_STORAGE_NAME);
  }

  public openLogging(event) {
    this.isLogVisible = true;
  }
}
