
import {of as observableOf, Observable} from 'rxjs';

import {takeUntil} from 'rxjs/operators';
import { Injectable } from '@angular/core';


import { MasterEntityConfig } from '../content-renderer/services/ElementContext';
import { EntitySharingService } from '../content-renderer/services/entity-sharing.service';
import { GenericElementAbstract } from 'app/shared/content-renderer/elements/generic-element-abstract.component';
import { FieldMetadataGrid } from './module/module-element-field-metadata-grid';
import { cloneDeep } from 'lodash';
import {GenericCrudService} from './generic-crud.service';
import {AbstractGenericGridComponent} from '../content-renderer/elements/abstract-generic-grid.component';
import {EntityManagerService} from '../../core/service/entity-manager/entity-manager.service';
import {EntityStatus} from './entity/entity-status';
import {Guid} from 'guid-typescript';

@Injectable()
export class EntityFactoryService {

  constructor(
    public entitySharingService: EntitySharingService,
    protected genericCrudService: GenericCrudService,
    private entityManager: EntityManagerService
  ) { }

  public buildComponentEntity(component: GenericElementAbstract): Observable<any> {
    return new Observable((observer) => {
      this.buildEntity(
        component.getElementDatamodel().id,
        component.getElementDataModelApiRoute(),
        component.getElementDatamodelEntityName(),
        component
      ).pipe(
        takeUntil(component.unsubscribe))
        .subscribe((entity) => {
        if (!entity[EntityStatus.ENTITY_DRAFT_FLAG]) {
          entity[EntityStatus.ENTITY_DRAFT_FLAG] = Guid.create().toString();
        }

        this.assignMasterEntities(entity, component.getElementContext().getMasterEntities());

        if (component instanceof AbstractGenericGridComponent) {
          this.assignDefaultValues(entity, component.fields);
        }

        observer.next(entity);
        observer.complete();
      });
    });
  }

  public buildEntity(datamodelId: number, apiRoute: string, entityFqn: string, component?): Observable<any> {
    return new Observable((observer) => {
      this.genericCrudService.getEntities('superadmin/datamodels/' + datamodelId + '/fields').pipe(
        takeUntil(component.unsubscribe))
        .subscribe((emptyEntityFields) => {
        const emptyEntity = {
          '_embedded': {}
        };

        for (const field of emptyEntityFields) {
          if (field.type === 'association') {
            emptyEntity[field.id] = {};
          } else if (field.type === 'associationMany') {
            emptyEntity[field.id] = [];
          } else {
            emptyEntity[field.id] = null;
          }
        }

        emptyEntity['_links'] = {
          'self': {
            'href': apiRoute
          }
        };
        emptyEntity['fqn'] = entityFqn;

        observer.next(emptyEntity);
        observer.complete();
      });
    });
  }

  public cloneEntity(entity: any): any {
    const clone = cloneDeep(entity);

    delete clone['id'];
    delete clone['_links'];

    if (entity._embedded && entity._embedded instanceof Object) {
      Object.keys(entity._embedded).forEach(function(key, index) {
        clone[key] = entity._embedded[key];
      });
    }

    return clone;
  }

  assignMasterEntities(entity: any, masterEntities: MasterEntityConfig[], persist: boolean = true) {
    if (entity) {
      for (const masterEntityConfig of masterEntities) {
        if (masterEntityConfig && masterEntityConfig.name && masterEntityConfig.value) {
          entity[masterEntityConfig.name] = masterEntityConfig.value;
        }

        if (masterEntityConfig && masterEntityConfig.name && masterEntityConfig.entity) {
          if (!masterEntityConfig.value) {
            delete entity[masterEntityConfig.name];
          }

          if (!entity['_embedded']) {
            entity['_embedded'] = {};
          }
          entity._embedded[masterEntityConfig.name] = masterEntityConfig.entity;
        }
      }

      if (persist) {
        this.persistMasterEntities(entity, masterEntities);
      }
    }

    return entity;
  }

  private assignDefaultValues(entity: any, fields: any[] = []): any {
    for (const field of fields) {
      if (field.defaultValue) {
        const propertyName = field.id;

        switch (field.fieldType) {
          case FieldMetadataGrid.TYPE_ASSOCIATION_SINGLE:
          case FieldMetadataGrid.TYPE_ASSOCIATION_MANY:
            this.getAssociationDefaultValue(field).subscribe((aEntity: any) => {
              if (aEntity !== null) {
                entity[field.entityName] = aEntity;
              }
            });
          break;
          default:
            entity[propertyName] = field.defaultValue;
        }
      }
    }

    return entity;
  }

  private getAssociationDefaultValue(field: FieldMetadataGrid): any {
    const apiRoute = field.associationEndpoint;

    if (apiRoute) {
      return this.genericCrudService.getEntity(apiRoute, +field.defaultValue);
    }

    return observableOf(null);
  }

  private persistMasterEntities(entity: any, masterEntities: MasterEntityConfig[] = []): this {
    for (const masterEntityConfig of masterEntities) {
      if (entity && entity.fqn && entity.uniqueId && masterEntityConfig && masterEntityConfig.name && masterEntityConfig.value) {
        this.entityManager.persist(entity, {property: masterEntityConfig.name, oldValue: null, newValue: masterEntityConfig.value, force: true });
      }
    }

    return this;
  }

}
