import {Observable} from 'rxjs';
import {AbstractExecutionStep} from '../../../../core/executor/abstract-execution-step';
import {ExecutionStepStatus} from '../../../../core/executor/execution-step-status';
import {FormViewerComponent} from '../../../form-viewer/form-viewer.component';
import {CustomerInvoicePositionTableComponent} from '../../../content-renderer/elements/custom/invoice/customer-invoice-position-table.component';
import {ElementType} from '../../../content-renderer/services/ElementContext';
import {ChangeDetectorRefHelper} from '../../../helpers/change-detector-ref.helper';

export class InvoiceToggleIsSimpleViewExecutionStep extends AbstractExecutionStep {

  public doExecute(): Observable<ExecutionStepStatus> {
    const payload = this.getPayload(),
      component = payload.getValue().component ? payload.getValue().component : payload.getValue();
    return this.onToggle(component);
  }

  private onToggle(component: FormViewerComponent): Observable<ExecutionStepStatus> {
    const invoicePositionsComponent = this.getInvoicePositionsComponent(component);
    const invoice = component.getSelectedEntity();

    if ((invoicePositionsComponent && !invoice.id) || (invoicePositionsComponent && invoice.id && invoicePositionsComponent.entities.length === 0)) {
      invoicePositionsComponent.invoice = invoice;
      ChangeDetectorRefHelper.detectChanges(invoicePositionsComponent);
    }

    return this.getSuccessObservable();
  }

  private getInvoicePositionsComponent(component: FormViewerComponent): CustomerInvoicePositionTableComponent {
    const moduleState = this.modulesStateService.getByComponent(component);
    return moduleState.getAllComponents().find((aComponent) => {
      return aComponent.getElementContext().type === ElementType.Grid;
    }) as CustomerInvoicePositionTableComponent;
  }
}
