/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./toolbar-item-open-module.component";
import * as i2 from "../../../../../../../core/message/message-growl.service";
import * as i3 from "../../../../../services/double-click.service";
import * as i4 from "../../../../generic-dialog/service/generic-dialog-module.service";
import * as i5 from "../../../../../../services/generic-crud.service";
import * as i6 from "../../../../../../../core/service/entity-manager/entity-manager.service";
var styles_ToolbarItemOpenModule = [];
var RenderType_ToolbarItemOpenModule = i0.ɵcrt({ encapsulation: 2, styles: styles_ToolbarItemOpenModule, data: {} });
export { RenderType_ToolbarItemOpenModule as RenderType_ToolbarItemOpenModule };
export function View_ToolbarItemOpenModule_0(_l) { return i0.ɵvid(2, [], null, null); }
export function View_ToolbarItemOpenModule_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "toolbar-item-open-module", [], null, null, null, View_ToolbarItemOpenModule_0, RenderType_ToolbarItemOpenModule)), i0.ɵdid(1, 245760, null, 0, i1.ToolbarItemOpenModule, [i2.MessageGrowlService, i3.DoubleClickService, i4.GenericDialogModuleService, i5.GenericCrudService, i6.EntityManagerService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ToolbarItemOpenModuleNgFactory = i0.ɵccf("toolbar-item-open-module", i1.ToolbarItemOpenModule, View_ToolbarItemOpenModule_Host_0, { params: "params" }, {}, []);
export { ToolbarItemOpenModuleNgFactory as ToolbarItemOpenModuleNgFactory };
