import { of as observableOf } from 'rxjs';
import { map } from 'rxjs/operators';
import { GenericTreeGridComponent } from '../generic-tree-grid/generic-tree-grid.component';
export class GenericDynamicTreeOldComponent extends GenericTreeGridComponent {
    constructor() {
        super(...arguments);
        this.elementType = 5 /* DynamicTree */;
    }
    set nodes(nodes) {
        this.entities = nodes;
    }
    onEntitiesChanged() {
        return this.executeAction(57 /* DynamicTreeEntitiesChanged */, this);
    }
    onValidate() {
        return observableOf({
            entity: null,
            isValid: true,
            error: '',
            errorFields: []
        });
    }
    onEditCell(event, entity, column) {
        this.executorService
            .fire(this.moduleElement.id, 56 /* DynamicTreeCellEdit */, {
            component: this,
            column: column,
            entity: entity,
            event: event
        })
            .subscribe((status) => {
            this.recheckToolbarItems();
        });
    }
    onRefresh() {
        return this.executorService
            .fire(this.moduleElement.id, 13 /* EntitiesRefresh */, this).pipe(map((status) => {
            this.recheckToolbarItems();
            return { status: status.getStepStatus(), content: status.getStepContent(), message: null };
        }));
    }
    onSave() {
        return this.executorService
            .fire(this.moduleElement.id, 55 /* DynamicTreeSave */, this).pipe(map((status) => {
            this.recheckToolbarItems();
            return { status: status.getStepStatus(), content: status.getStepContent(), message: null };
        }));
    }
    loadEntities() {
        return observableOf({ data: [], count: 0 });
    }
}
