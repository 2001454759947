
import {map} from 'rxjs/operators';
import {Component, OnInit, Input, Output, EventEmitter, ChangeDetectorRef} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {MessageGrowlService} from '../../../../core/message/message-growl.service';
import {GenericCrudService} from '../../../services/generic-crud.service';
import {Observable} from 'rxjs';
import {ChangeDetectorRefHelper} from '../../../helpers/change-detector-ref.helper';

@Component({
  selector: 'app-ksv-company-product-grid',
  templateUrl: './ksv-company-product-grid.component.html'
})
export class KsvCompanyProductGridComponent implements OnInit {

  private static readonly COMPANY_PRODUCTS_API_ROUTE = 'app/ksv/companies/products';

  @Output() public onProductChanged: EventEmitter<any> = new EventEmitter();

  @Input() public company: any = null;

  public products: any[] = [];

  public isDataLoading = false;

  public constructor(
    protected translate: TranslateService,
    protected messageGrowlService: MessageGrowlService,
    protected genericCrudService: GenericCrudService,
    public cdr: ChangeDetectorRef
  ) {

  }

  public ngOnInit() {
    this.loadCompanyProducts()
      .subscribe((products) => {
        this.products = products;
        ChangeDetectorRefHelper.detectChanges(this);
      });
  }

  public loadCompanyProducts(): Observable<any[]> {
    this.isDataLoading = true;

    return this.genericCrudService
      .getEntities(`${KsvCompanyProductGridComponent.COMPANY_PRODUCTS_API_ROUTE}/${this.company.ksvNumber}`).pipe(
      map((products: any[] = []) => {
        this.isDataLoading = false;
        return products;
      }));
  }

  public onRowDoubleClick(product) {
    this.onProductChanged.emit({
      company: this.company,
      product: product
    });
  }
}
