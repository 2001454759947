import {ChangeDetectionStrategy, Component, ViewChild} from '@angular/core';
import {ToolbarItemAbstract} from '../toolbar-item-abstract.component';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'toolbar-item-audit',
  templateUrl: './toolbar-item-audit.component.html',
  styles: [`
    ::ng-deep .audit-dialog > .ui-dialog-content {
      overflow: auto;
    }
  `]
})
export class ToolbarItemAuditComponent extends ToolbarItemAbstract {

  @ViewChild('dialog', {static: false}) public dialog;
  public isDialogVisible = false;
  public element = null;
  public entity = null;

  public click() {
    this.element = this.getComponent().element;
    this.entity = this.getComponent().getSelectedEntity();
    this.isDialogVisible = true;
  }

  public onShow(): void {
    setTimeout(() => {
      this.dialog.maximize();
    }, 20);

    setTimeout(() => {
      window.dispatchEvent(new Event('resize'));
    }, 500);
  }

}
