
import {of as observableOf, Observable} from 'rxjs';
import {AbstractExecutionStep} from '../../../core/executor/abstract-execution-step';
import {ExecutionStepStatus} from '../../../core/executor/execution-step-status';
import {AbstractGenericGridComponent} from '../../content-renderer/elements/abstract-generic-grid.component';
import {EntityStatus} from '../entity/entity-status';
import {GenericTreeGridComponent} from '../../content-renderer/elements/generic-tree-grid/generic-tree-grid.component';

export class ComponentCacheChangedEntitiesExecutionStep extends AbstractExecutionStep {

  public doExecute(): Observable<ExecutionStepStatus> {
    const payload = this.getPayload(),
      component = payload.getValue().component ? payload.getValue().component : payload.getValue();

    if (!(component instanceof AbstractGenericGridComponent)) {
      return this.getFailObservable('You need to pass AbstractGenericGridComponent as Payload value!');
    }

    return this.doCache(component);
  }

  protected doCache(component: AbstractGenericGridComponent): Observable<ExecutionStepStatus> {
    const entities: TreeNode[] = component.entities;

    component.runtimeData.cachedChangedTreeNodes = [];

    if (component instanceof GenericTreeGridComponent) {
      for (const node of entities) {
        const entity = node.data || null;

        if (entity.id && entity[EntityStatus.ENTITY_CHANGED_FLAG]) {
          component.runtimeData.cachedChangedTreeNodes.push(node);
        }
      }
    }

    return observableOf({status: true, content: null });
  }
}
