/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./search-management.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../angular-split/split.component.ngfactory";
import * as i3 from "../../angular-split/split.component";
import * as i4 from "../../angular-split/splitArea.directive";
import * as i5 from "./list/search-management-list.component.ngfactory";
import * as i6 from "./service/expert-search.service";
import * as i7 from "../../../core/executor/factory/execution-step-factory.service";
import * as i8 from "../../services/generic-crud.service";
import * as i9 from "./list/search-management-list.component";
import * as i10 from "../../../core/message/message.service";
import * as i11 from "@ngx-translate/core";
import * as i12 from "../../../core/message/message-growl.service";
import * as i13 from "../../services/request-caching.service";
import * as i14 from "./edit/search-management-edit.component.ngfactory";
import * as i15 from "./edit/search-management-edit.component";
import * as i16 from "./search-management.component";
var styles_SearchManagementComponent = [i0.styles];
var RenderType_SearchManagementComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_SearchManagementComponent, data: {} });
export { RenderType_SearchManagementComponent as RenderType_SearchManagementComponent };
export function View_SearchManagementComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(671088640, 1, { list: 0 }), (_l()(), i1.ɵeld(1, 0, null, null, 11, "split", [["direction", "horizontal"]], [[4, "flex-direction", null], [4, "width", null], [4, "height", null]], null, null, i2.View_SplitComponent_0, i2.RenderType_SplitComponent)), i1.ɵdid(2, 704512, null, 0, i3.SplitComponent, [i1.ChangeDetectorRef, i1.ElementRef, i1.Renderer], { direction: [0, "direction"], gutterSize: [1, "gutterSize"] }, null), (_l()(), i1.ɵeld(3, 0, null, 0, 4, "split-area", [["class", "overflow-y-hidden"]], [[4, "flex-grow", null], [4, "flex-shrink", null], [4, "overflow-x", null], [4, "overflow-y", null], [4, "height", null], [4, "display", null]], null, null, null, null)), i1.ɵdid(4, 4407296, null, 0, i4.SplitAreaDirective, [i1.ElementRef, i1.Renderer, i3.SplitComponent], { size: [0, "size"] }, null), (_l()(), i1.ɵeld(5, 0, null, null, 2, "app-search-management-list", [], null, [[null, "expertSearchesChange"], [null, "onExpertSearchChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("expertSearchesChange" === en)) {
        var pd_0 = ((_co.expertSearches = $event) !== false);
        ad = (pd_0 && ad);
    } if (("onExpertSearchChange" === en)) {
        var pd_1 = (_co.onExpertSearchChange($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i5.View_SearchManagementListComponent_0, i5.RenderType_SearchManagementListComponent)), i1.ɵprd(512, null, i6.ExpertSearchService, i6.ExpertSearchService, [i7.ExecutionStepFactoryService, i8.GenericCrudService]), i1.ɵdid(7, 245760, [[1, 4], ["list", 4]], 0, i9.SearchManagementListComponent, [i8.GenericCrudService, i10.MessageService, i11.TranslateService, i12.MessageGrowlService, i6.ExpertSearchService, i13.RequestCachingService], { expertSearches: [0, "expertSearches"] }, { onExpertSearchChange: "onExpertSearchChange" }), (_l()(), i1.ɵeld(8, 0, null, 0, 4, "split-area", [], [[4, "flex-grow", null], [4, "flex-shrink", null], [4, "overflow-x", null], [4, "overflow-y", null], [4, "height", null], [4, "display", null]], null, null, null, null)), i1.ɵdid(9, 4407296, null, 0, i4.SplitAreaDirective, [i1.ElementRef, i1.Renderer, i3.SplitComponent], { size: [0, "size"] }, null), (_l()(), i1.ɵeld(10, 0, null, null, 2, "app-search-management-edit", [], null, [[null, "onExpertSearchSave"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("onExpertSearchSave" === en)) {
        var pd_0 = (_co.onExpertSearchSave($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i14.View_SearchManagementEditComponent_0, i14.RenderType_SearchManagementEditComponent)), i1.ɵprd(512, null, i6.ExpertSearchService, i6.ExpertSearchService, [i7.ExecutionStepFactoryService, i8.GenericCrudService]), i1.ɵdid(12, 49152, null, 0, i15.SearchManagementEditComponent, [i10.MessageService, i12.MessageGrowlService, i6.ExpertSearchService], { expertSearch: [0, "expertSearch"] }, { onExpertSearchSave: "onExpertSearchSave" })], function (_ck, _v) { var _co = _v.component; var currVal_3 = "horizontal"; var currVal_4 = 5; _ck(_v, 2, 0, currVal_3, currVal_4); var currVal_11 = 30; _ck(_v, 4, 0, currVal_11); var currVal_12 = _co.expertSearches; _ck(_v, 7, 0, currVal_12); var currVal_19 = 70; _ck(_v, 9, 0, currVal_19); var currVal_20 = _co.expertSearch; _ck(_v, 12, 0, currVal_20); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 2).styleFlexDirection; var currVal_1 = i1.ɵnov(_v, 2).styleWidth; var currVal_2 = i1.ɵnov(_v, 2).styleHeight; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2); var currVal_5 = "0"; var currVal_6 = "0"; var currVal_7 = "hidden"; var currVal_8 = "auto"; var currVal_9 = "100%"; var currVal_10 = i1.ɵnov(_v, 4).visibility; _ck(_v, 3, 0, currVal_5, currVal_6, currVal_7, currVal_8, currVal_9, currVal_10); var currVal_13 = "0"; var currVal_14 = "0"; var currVal_15 = "hidden"; var currVal_16 = "auto"; var currVal_17 = "100%"; var currVal_18 = i1.ɵnov(_v, 9).visibility; _ck(_v, 8, 0, currVal_13, currVal_14, currVal_15, currVal_16, currVal_17, currVal_18); }); }
export function View_SearchManagementComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-search-management", [], null, null, null, View_SearchManagementComponent_0, RenderType_SearchManagementComponent)), i1.ɵdid(1, 49152, null, 0, i16.SearchManagementComponent, [], null, null)], null, null); }
var SearchManagementComponentNgFactory = i1.ɵccf("app-search-management", i16.SearchManagementComponent, View_SearchManagementComponent_Host_0, {}, {}, []);
export { SearchManagementComponentNgFactory as SearchManagementComponentNgFactory };
