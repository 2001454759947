import {Observable} from 'rxjs/Observable';
import {map, pluck} from 'rxjs/operators';
import {Entity} from '../../../../../helpers/entity';
import {UserSessionService} from '../../../../../../core/service/user-session.service';
import {AuthenticationService} from '../../../../../../core/authentication/authentication.service';
import {GenericCrudService} from '../../../../../services/generic-crud.service';
import {TableColumn} from '../../../../../dynamic-table/shared/table-column';
import {Injectable} from '@angular/core';
import {environment} from '../../../../../../../environments';
import {DoubleClickService} from '../../../../services/double-click.service';
import {LocationService} from '../../../../../services/location.service';
import {ComponentService} from '../../../../services/component-highlight-stack.service';
import {ModuleElement} from '../../../../../services/module/module-element';
import {DatePipe} from '@angular/common';

@Injectable()
export class CockpitUserBirthdayService {

  private static checkBirthDate(birthDate: Date): boolean {
    const today = new Date();
    const bDay = new Date(birthDate);
    const upcomingBirthDay = new Date(today.getFullYear(), bDay.getMonth(), bDay.getDate());

    if (today > upcomingBirthDay) {
      upcomingBirthDay.setFullYear(today.getFullYear() + 1);
    }
    const oneDay = 24 * 60 * 60 * 1000;
    const daysLeft = Math.ceil((upcomingBirthDay.getTime() - today.getTime()) / (oneDay));

    return daysLeft <= 7;
  }

  public constructor(
    private userSession: UserSessionService,
    private authenticationService: AuthenticationService,
    private genericCrudService: GenericCrudService,
    private locationService: LocationService,
    private doubleClickService: DoubleClickService,
    private moduleStateService: ComponentService
  ) {}

  public getLeasedEmployeeData(): Observable<any[]> {
    const params = {
      embedded: 'none'
    }
    return this.genericCrudService.getEntities('phoenix/leasedemployees', '', params).pipe(
      map((leasedEmployees) => {
        const filteredLeasedEmployees: any[] = [];
        for (const leasedEmployee of leasedEmployees) {
          if (leasedEmployee.birthDate && CockpitUserBirthdayService.checkBirthDate(leasedEmployee.birthDate)) {
            filteredLeasedEmployees.push(leasedEmployee);
          }
        }
        return filteredLeasedEmployees;
      })
    );
  }

  public getUsersData(): Observable<any[]> {
    const params = {
      embedded: 'none'
    };
    return this.genericCrudService.getEntities('app/users', '', params).pipe(
      map((users) => {
        const filteredUsers: any[] = [];
        for (const user of users) {
          if (user.birthDate && CockpitUserBirthdayService.checkBirthDate(user.birthDate)) {
            filteredUsers.push(user);
          }
        }
        return filteredUsers;
      })
    );
  }

  public getUsersColumns(): TableColumn[] {
    return [
      {
        key: 'firstName',
        header: 'Benutzer',
        renderer: (entity) => {
          const firstName = Entity.getValue(entity, 'firstName');
          const lastName = Entity.getValue(entity, 'lastName');

          return firstName + ' ' + lastName;
        },
        style: {
          width: '150px'
        }
      },
      {
        key: 'birthDate',
        header: 'Geburtstag',
        property: 'birthDate',
        renderType: 'date',
        style: {
          width: '100px'
        }
      },
      {
        key: 'id',
        header: '',
        renderer: () => {
          return '';
        },
        style: {
          width: '50px'
        },
        button: {
          class: 'fa fa-plus open-leased-employee',
          click: (entity) => {
            this.openUserModule();
          },
          iconUrl: environment.baseUrl + '/assets/hr-puzzle/images/icons/magnifier.png'
        }
      }
    ];
  }

  public getLeasedEmployeeColumns(): TableColumn[] {
    return [
      {
        key: 'firstName',
        header: 'Mitarbeiter',
        renderer: (entity) => {
          const firstName = Entity.getValue(entity, 'firstName');
          const lastName = Entity.getValue(entity, 'lastName');

          return firstName + ' ' + lastName;
        },
        style: {
          width: '150px'
        }
      },
      {
        key: 'birthDate',
        header: 'Geburtstag',
        property: 'birthDate',
        renderType: 'date',
        style: {
          width: '100px'
        }
      },
      {
        key: 'id',
        header: '',
        renderer: () => {
          return '';
        },
        style: {
          width: '50px'
        },
        button: {
          class: 'fa fa-plus open-leased-employee',
          click: (entity) => {
            this.openLeasedEmployeeModule(entity);
          },
          iconUrl: environment.baseUrl + '/assets/hr-puzzle/images/icons/magnifier.png'
        }
      }
    ];
  }

  private openUserModule(): void {
    this.locationService.getRouter().navigate(['phoenix/18/module/742/part-module/743']).then();
  }

  private openLeasedEmployeeModule(entity): void {
    this.genericCrudService.getEntity('superadmin/modules', 145).pipe(
      map((targetModule) => {
        return this.doubleClickService.openModuleInNewTab(targetModule, entity, this.getParentModule());
      })
    ).subscribe();
  }

  private getParentModule(): ModuleElement {
    return this.moduleStateService.getStashed()[0].moduleElement;
  }
}
