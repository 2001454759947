
import {of as observableOf,  Observable } from 'rxjs';
import { AbstractExecutionStep } from '../../../../core/executor/abstract-execution-step';
import { ExecutionStepStatus } from '../../../../core/executor/execution-step-status';
import {AbstractGenericGridComponent} from '../../../content-renderer/elements/abstract-generic-grid.component';
import {FormViewerComponent} from '../../../form-viewer/form-viewer.component';

export class HidePrecalculationColumnStep extends AbstractExecutionStep {

  private filterableFields = ['preCalculation'];

    doExecute(): Observable<ExecutionStepStatus> {
      const payload = this.getPayload();

      let component = payload.getValue();

      if (payload instanceof Object && payload.getValue().component) {
          component = payload.getValue().component;
      }

      if (!(component instanceof AbstractGenericGridComponent)) {
          return this.getFailObservable('You need to pass GenericElementAbstract as Payload value!');
      }

      return this.doHide(component);
    }

    private doHide(component: AbstractGenericGridComponent): Observable<ExecutionStepStatus> {
      const masterEntity = this.findMasterComponent(component).entity;

      if (masterEntity.preCalculationType.code === 'VER') {
        for (const column of component.columns) {
          if (column.isAssociatedField && this.filterableFields.indexOf(column.entityName) !== -1) {
            column.field.visible = false;
            component.initColumns();
          }
        }
      }
      return observableOf({ status: true, content: '' });
    }

    private findMasterComponent(component: AbstractGenericGridComponent): FormViewerComponent|null {
      for (const masterComponent of component.getComponentService().getStashed()) {
        if (masterComponent.elementContext.isMaster && masterComponent instanceof FormViewerComponent) {
          return masterComponent;
        }
      }
      return null;
    }
}
