import { Entity } from '../../helpers/entity';
import { ElementInputAutocompleteComponent } from './element-input-autocomplete.component';
export class ElementInputMultiAutocompleteComponent extends ElementInputAutocompleteComponent {
    constructor() {
        super(...arguments);
        this.inputElement = null;
    }
    onComponentChanges() {
        this.setupValueFromEntity();
    }
    onComponentAfterViewInit() {
    }
    setValue(selectedOption, triggerChange = true, entityValueEmpty = false) {
        return this;
    }
    onItemSelected(event) {
        this.setupValueFromChange();
    }
    onItemUnselected(event) {
        this.setupValueFromChange();
    }
    setupValueFromChange() {
        const selectedOptions = this.inputElement.value;
        const formControlValue = [], entityValue = [];
        for (const selectedOption of selectedOptions) {
            formControlValue.push({
                value: {
                    id: selectedOption.value.id
                },
                label: selectedOption.label,
                entity: selectedOption.value
            });
            entityValue.push(selectedOption.entity);
        }
        this.doTriggerElementValueChange(formControlValue, entityValue);
    }
    setupValueFromEntity() {
        const entityEmbeddedValues = this.getEntityValue();
        const formControlValue = [], entityValue = [];
        if (entityEmbeddedValues instanceof Array) {
            for (const entity of entityEmbeddedValues) {
                formControlValue.push({
                    value: {
                        id: entity.id
                    },
                    label: this.figureOutLabel(entity),
                    entity: entity
                });
                entityValue.push(entity);
            }
        }
        this.doTriggerElementValueChange(formControlValue, entityValue, false);
    }
    setupValue(ignoreResetCollection = false) {
        console.log(event);
    }
    setDefaultValue() {
        // todo
    }
    setFirstValue() {
        // todo
    }
    doTriggerElementValueChange(formControlValue = [], entityValue = [], triggerChange = true) {
        this.formService.onFormElementValueChange({
            formControlValue: formControlValue,
            element: this.element,
            entityValue: entityValue,
            formControlName: this.formControlName,
            formControlOptions: {},
            triggerChange: triggerChange,
            entity: this.entity,
            component: this,
            updateFormComponent: true
        });
    }
    getEntityValue(useEmbedded = true) {
        return Entity.getValue(this.entity, this.element.datamodelField) ||
            Entity.getValueInEmbedded(this.entity, this.element.datamodelField);
    }
}
