import {ChangeDetectionStrategy, Component, Input, OnInit} from '@angular/core';
import { ToolbarItemAbstract } from '../toolbar-item-abstract.component';
import { ComponentService } from '../../../../../services/component-highlight-stack.service';
import { MessageGrowlService } from '../../../../../../../core/message/message-growl.service';
import {AbstractGenericGridComponent} from '../../../../abstract-generic-grid.component';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'toolbar-item-details',
  templateUrl: './toolbar-item-details.component.html'
})
export class ToolbarItemDetails extends ToolbarItemAbstract {

  constructor(
    private componentService: ComponentService,
    private messageGrowlService: MessageGrowlService
  ) {
    super();
  }

  public click() {
    if (this.getParam('moduleComponent')) {
      this.doShowEntityDetails();
    }
  }

  private doShowEntityDetails() {
    const highlightedComponent = this.componentService.getHighlighted(),
      highlightedComponentEntity = this.componentService.getHighlightedEntity();

    if (!highlightedComponentEntity) {
      return this.messageGrowlService.error('Please select entity to show details.');
    }

    if (highlightedComponent instanceof AbstractGenericGridComponent) {
      highlightedComponent.showDetailedOverview = true;
    }
  }
}
