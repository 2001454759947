import { forkJoin as observableForkJoin, of as observableOf, Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AbstractExecutionStep } from '../../../../core/executor/abstract-execution-step';
import { ModuleState } from '../../../content-renderer/services/module-state';
import { AbstractGenericGridComponent } from '../../../content-renderer/elements/abstract-generic-grid.component';
export class ModuleEntityDeleteExecutionStep extends AbstractExecutionStep {
    constructor() {
        super(...arguments);
        this.deletedEntities = [];
    }
    doExecute() {
        const payload = this.getPayload();
        const moduleState = payload.getValue();
        if (!(moduleState instanceof ModuleState)) {
            return observableOf({ status: false, content: 'Payload should be modulestate, not something else.' });
        }
        return this.prepareEntities(moduleState).doDelete();
    }
    prepareEntities(moduleState) {
        const components = [];
        for (const component of moduleState.getAllComponents()) {
            if (component instanceof AbstractGenericGridComponent) {
                for (const deletedEntity of component.getDraftDeletedEntities(true)) {
                    if (this.deletedEntities.indexOf(deletedEntity) === -1) {
                        this.deletedEntities.push(deletedEntity);
                    }
                }
            }
        }
        return this;
    }
    doDelete() {
        const observables = [];
        for (const entity of this.deletedEntities) {
            if (entity.id) {
                observables.push(this.genericCrudService
                    .doDeleteEntity(this.constructUrl(entity)));
            }
        }
        return Observable.create((observer) => {
            if (observables.length === 0) {
                observer.next({
                    status: true,
                    content: null,
                });
                observer.complete();
            }
            observableForkJoin(observables).pipe(catchError((response) => {
                return observableOf(response);
            }))
                .subscribe(results => {
                observer.next({
                    status: true,
                    content: results
                });
                observer.complete();
            });
        });
    }
    constructUrl(entity) {
        return this.genericCrudService.getBaseUrl() + entity['_links']['self']['href'];
    }
}
