import {Injectable, Injector} from '@angular/core';
import { GenericCrudService } from 'app/shared/services/generic-crud.service';
import { EntityHydrator } from 'app/shared/services/entity-hydrator.service';
import { ExecutionStep } from '../execution-step';
import { ExecutionStepPayload } from 'app/core/executor/execution-step-payload';
import { ModuleStateInspectorService } from '../service/module-state-inspector.service';
import { MessageService } from 'app/core/message/message.service';
import { RequestCachingService } from '../../../shared/services/request-caching.service';
import { EntityDataStoreService } from 'app/shared/content-renderer/services/entity-data-store.service';
import { EntityDirtyStoreService } from 'app/shared/content-renderer/services/entity-dirty-store.service';
import { ModulesStateService } from '../../../shared/content-renderer/services/modules-state.service';
import {EntityDraftStoreService} from '../../../shared/content-renderer/services/entity-draft-store.service';
import { ExecutorRegistry } from '../service/executor.registry';
import {JobContainerService} from '../../job-runner/job-container.service';
import {LocationService} from '../../../shared/services/location.service';

@Injectable()
export class ExecutionStepFactoryService {

    private executorRegistry: ExecutorRegistry = new ExecutorRegistry();

    /**
     * @param {ModuleStateInspectorService} moduleStateInspectorService
     * @param {GenericCrudService} genericCrudService
     */
    constructor(
        protected genericCrudService: GenericCrudService,
        protected entityHydrator: EntityHydrator,
        protected moduleStateInspectorService: ModuleStateInspectorService,
        protected messageService: MessageService,
        protected requestCachingService: RequestCachingService,
        protected entityDataStoreService: EntityDataStoreService,
        protected entityDirtyStoreService: EntityDirtyStoreService,
        protected entityDraftStoreService: EntityDraftStoreService,
        protected modulesStateService: ModulesStateService,
        protected jobContainerService: JobContainerService,
        protected locationService: LocationService,
        protected injector: Injector
    ) {}

    /**
     *
     * @param {typeof ExecutionStep} executionStep
     * @param {ExecutionStepPayload}: payload
     * @returns {ExecutionStep}
     */
    public create(executionStep: any, payload: ExecutionStepPayload): ExecutionStep {
        /**
        * @type {ExecutionStep} step
        */
        const step = new executionStep(payload);

        // for now inject everything
        step.entityHydrator = this.entityHydrator;
        step.genericCrudService = this.genericCrudService;
        step.moduleStateInspectorService = this.moduleStateInspectorService;
        step.messageService = this.messageService;
        step.requestCachingService = this.requestCachingService;
        step.entityDataStoreService = this.entityDataStoreService;
        step.entityDirtyStoreService = this.entityDirtyStoreService;
        step.modulesStateService = this.modulesStateService;
        step.entityDraftStoreService = this.entityDraftStoreService;
        step.jobContainerService = this.jobContainerService;
        step.locationService = this.locationService;
        step.injector = this.injector;

        return step;
    }

    public createFromString(executionStepString: string, payload: ExecutionStepPayload): ExecutionStep {
        const stepType = this.executorRegistry.steps[executionStepString];

        if (!stepType) {
            console.error(`Step ${executionStepString} not found in registry!`);
        }

        return this.create(stepType, payload);
    }
}
