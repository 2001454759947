
import {of as observableOf, Observable} from 'rxjs';
import {AbstractExecutionStep} from '../../../../core/executor/abstract-execution-step';
import {ExecutionStepStatus} from '../../../../core/executor/execution-step-status';
import {FormViewerComponent} from '../../../form-viewer/form-viewer.component';
import {AbstractGenericGridComponent} from '../../../content-renderer/elements/abstract-generic-grid.component';

export class DialogFormMasterImportExecutionStep extends AbstractExecutionStep {
  /**
   * @returns {Observable<ExecutionStepStatus>}
   */
  doExecute(): Observable<ExecutionStepStatus> {
    const payload = this.getPayload().getValue();

    if (!(payload instanceof FormViewerComponent)) {
      return observableOf({status: false, content: null});
    }

    const entity = payload.getSelectedEntity();

    for (const context of payload.getElementContext().getSlaveElementContexts()) {
      if (context.component instanceof AbstractGenericGridComponent) {
        this.importData(entity, context.component);
      }
    }

    const mainModule = this.modulesStateService.getCurrent();

    for (const component of this.moduleStateInspectorService.getPartComponents(mainModule)) {
      if (component instanceof AbstractGenericGridComponent) {
        this.importData(entity, component);
      }
    }

    for (const context of payload.getElementContext().getSlaveElementContexts()) {
      if (context.component instanceof AbstractGenericGridComponent) {
        this.importData(entity, context.component);
      }
    }

    return observableOf({status: true, content: payload});
  }

  private importData(entity: any, component: AbstractGenericGridComponent) {
    const collectionName = component.element.datamodel.entityCollectionName;

    if (collectionName) {
      for (const createdEntity of component.getCreatedEntities()) {
        this.pushToMasterEntityCollection(entity, collectionName, createdEntity);

        component.removeEntity(createdEntity);
      }

      for (const updatedEntity of component.getUpdatedEntities()) {
        this.pushToMasterEntityCollection(entity, collectionName, updatedEntity);

        component.removeEntity(updatedEntity);
      }
    }
  }
}
