/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "primeng/components/dom/domhandler";
import * as i2 from "primeng/components/button/button";
import * as i3 from "../../../../../p-button/p-button.directive";
import * as i4 from "./work-hour-monthly-action-column.component";
import * as i5 from "../../../../services/double-click.service";
import * as i6 from "../../../../../services/generic-crud.service";
var styles_WorkHourMonthlyActionColumnComponent = [];
var RenderType_WorkHourMonthlyActionColumnComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_WorkHourMonthlyActionColumnComponent, data: {} });
export { RenderType_WorkHourMonthlyActionColumnComponent as RenderType_WorkHourMonthlyActionColumnComponent };
export function View_WorkHourMonthlyActionColumnComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 6, "table", [], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 5, "tr", [], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 4, "td", [], [[8, "hidden", 0]], null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 3, "button", [["icon", "fa fa-plus"], ["pButton", ""]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.copyWorkEntry() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵprd(4608, null, i1.DomHandler, i1.DomHandler, []), i0.ɵdid(5, 4341760, null, 0, i2.ButtonDirective, [i0.ElementRef], { icon: [0, "icon"] }, null), i0.ɵdid(6, 4341760, null, 0, i3.PButtonDirective, [i0.ElementRef], { icon: [0, "icon"] }, null)], function (_ck, _v) { var currVal_1 = "fa fa-plus"; _ck(_v, 5, 0, currVal_1); var currVal_2 = "fa fa-plus"; _ck(_v, 6, 0, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.isCopy() || _co.isSummary()); _ck(_v, 2, 0, currVal_0); }); }
export function View_WorkHourMonthlyActionColumnComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-work-hour-monthly-action-column", [], null, null, null, View_WorkHourMonthlyActionColumnComponent_0, RenderType_WorkHourMonthlyActionColumnComponent)), i0.ɵdid(1, 49152, null, 0, i4.WorkHourMonthlyActionColumnComponent, [i0.ElementRef, i5.DoubleClickService, i6.GenericCrudService], null, null)], null, null); }
var WorkHourMonthlyActionColumnComponentNgFactory = i0.ɵccf("app-work-hour-monthly-action-column", i4.WorkHourMonthlyActionColumnComponent, View_WorkHourMonthlyActionColumnComponent_Host_0, { node: "node", entity: "entity", component: "component", column: "column" }, {}, []);
export { WorkHourMonthlyActionColumnComponentNgFactory as WorkHourMonthlyActionColumnComponentNgFactory };
