
import {of as observableOf, Observable} from 'rxjs';
import {AbstractExecutionStep} from '../../../../core/executor/abstract-execution-step';
import {ExecutionStepStatus} from '../../../../core/executor/execution-step-status';
import {AbstractGenericGridComponent} from '../../../content-renderer/elements/abstract-generic-grid.component';
import {ChangeDetectorRefHelper} from '../../../helpers/change-detector-ref.helper';

export class LockSlaveGridStep extends AbstractExecutionStep {

  public doExecute(): Observable<ExecutionStepStatus> {
    const payload = this.getPayload(),
      component = payload.getValue();

    if (component instanceof AbstractGenericGridComponent) {
      return this.doLockToolbars(component);
    }

    return this.getFailObservable('You need to pass AbstractGenericGridComponent as Payload value!');
  }

  protected doLockToolbars(component: AbstractGenericGridComponent) {
    let masterEntity = component.masterElementContext ? component.masterElementContext.selectedEntity : component.elementContext.masterElementContext.selectedEntity;

    if (!masterEntity) {
      return observableOf({status: true, content: []});
    }

    if (typeof masterEntity.preCalculation !== 'undefined') {
      masterEntity = masterEntity.preCalculation;
    }

    if (typeof masterEntity.isLocked !== 'undefined') {
      component.isToolbarDisabled = masterEntity.isLocked;

      for (const toolbarItem of component.element.toolbarItems) {
        toolbarItem.disabled = masterEntity.isLocked;
      }

      ChangeDetectorRefHelper.detectChanges(component);
    }

    return observableOf({status: true, content: []});
  }
}
