import {ChangeDetectionStrategy, Component} from '@angular/core';
import {ToolbarItemAbstract} from '../toolbar-item-abstract.component';
import {AbstractGenericGridComponent} from '../../../../abstract-generic-grid.component';
import {ModuleElementFieldCrudService} from '../../../../../../services/module/module-element-field.crud.service';
import {RequestCachingService} from '../../../../../../services/request-caching.service';
import {cloneDeep} from 'lodash';
import {FieldMetadataGrid} from '../../../../../../services/module/module-element-field-metadata-grid';
import {Constants} from '../../../../../../../constants';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-toolbar-item-work-hour-monthly-grid-show-wage-and-invoice-columns',
  template: ``
})
export class ToolbarItemWorkHourMonthlyGridShowWageAndInvoiceColumnsComponent extends ToolbarItemAbstract {

  protected wageColumns = [
    'article.code',
    'article.name',
    'displayDuration',
    'durationActual',
    'salaryHour',
    'calculatedWage'
  ];

  protected invoiceColumns = [
    'invoiceArticle.code',
    'invoiceArticle.name',
    'durationInvoiceActual',
    'invoiceHour',
    'calculatedInvoice'
  ];

  public constructor(
    protected moduleElementsFieldsService: ModuleElementFieldCrudService,
    protected requestCachingService: RequestCachingService
  ) {
    super();
  }

  public click() {
    const component = this.getComponent();

    if (component instanceof AbstractGenericGridComponent) {
      this.handleColumns(component);
      this.saveColumns(component);
    }
  }

  protected handleColumns(component: AbstractGenericGridComponent): void {
    for (const column of this.wageColumns) {
      const gridField = component.getColumnBuilder().findField(column);

      if (gridField) {
        gridField.visible = true;
      }
    }

    for (const column of this.invoiceColumns) {
      const gridField = component.getColumnBuilder().findField(column);

      if (gridField) {
        gridField.visible = true;
      }
    }
  }

  protected getFieldsWithoutModuleElementColumnFields(fields: FieldMetadataGrid[] = []): FieldMetadataGrid[] {
    const allFields = cloneDeep(fields);

    return allFields.filter((aField: FieldMetadataGrid) => { return aField.fieldType !== Constants.MODULE_ELEMENT_COLUMN_NAME; });
  }

  protected saveColumns(component: AbstractGenericGridComponent): void {
    const fields = this.getFieldsWithoutModuleElementColumnFields(component.fields);

    this.moduleElementsFieldsService
      .reorderFields(component.moduleElement.id, fields)
      .subscribe(() => {
        component.emptyEntityInitDone = false;
        component.setEmptyEntity();

        component.fields = fields;

        this.requestCachingService.removeBundleRequestResponsesWithApi(`api/${component.getElementDataModelApiRoute()}`);
      });

    component.initColumns();
    component.loadEntities().subscribe();
  }
}
