import { Component } from '@angular/core';
import { FieldActionAbstractComponent } from '../field-action-abstract.component';
import { GenericCrudService } from '../../../../../services/generic-crud.service';
import { DoubleClickService } from '../../../../services/double-click.service';
import {FieldMetadataGrid} from '../../../../../services/module/module-element-field-metadata-grid';

@Component({
  selector: 'field-action-get-collective-agreement-valid-to',
  template: ''
})
export class FieldActionGetCollectiveAgreementValidToComponent extends FieldActionAbstractComponent {

  public constructor(
    protected genericCrudService: GenericCrudService,
    protected doubleClickService: DoubleClickService,
  ) {
    super();
  }

  public fire() {
    const gridComponent = this.getGrid(),
      entity = gridComponent.getSelectedEntity();

    let collectiveAgreementField = this.getActionParamValue('collectiveAgreement'),
      customerField = this.getActionParamValue('customer'),
      field = this.getField(),
      collectiveAgreement = this.genericCrudService.getEntityHydrator().getEntityPropertyValue(entity,collectiveAgreementField),
      customer = this.genericCrudService.getEntityHydrator().getEntityPropertyValue(entity,customerField);

    collectiveAgreement = collectiveAgreement['id'] || collectiveAgreement;
    customer = customer['id'] || customer;

    this.genericCrudService.getEntities(`phoenix/collectiveagreements/${collectiveAgreement}/customer/${customer}/validto`).subscribe((validTo) => {
      entity[field.name] = validTo;
    });
  }
}
