
import {map} from 'rxjs/operators';
import {Component, ElementRef, OnInit} from '@angular/core';
import {AbstractGridColumnComponent} from '../../../abstract-grid-column.component';
import {ElementContext} from '../../../../../../services/ElementContext';
import {GenericCrudService} from '../../../../../../../services/generic-crud.service';
import {Observable} from 'rxjs';

interface Parameter {
  id: number;
  name: string;
}

@Component({
  selector: 'app-parameter-column',
  template: `
    <p-dropdown
      *ngIf="isEditMode()"
      [(ngModel)]="entity.parameter"
      [options]="parametersOptions"
      placeholder="---"
      (onFocus)="onDropDownFocus()"
      (onChange)="onParameterChange($event)"
      [style]="{'width': '100%'}"
      appendTo="body"
    ></p-dropdown>
    
    <div *ngIf="!isEditMode()">
      {{ entity.parameter }}
    </div>
  `,
  styles: [`
    :host {
      width: 100%;
    }
  `]
})
export class ConditionTreeParameterColumnComponent extends AbstractGridColumnComponent implements OnInit {

  public parametersOptions: SelectItem[] = [];
  public selectedParameter: SelectItem = null;

  public collectiveAgreementId: number = null;
  public parentEntity: any = null;

  public constructor(
    protected elementRef: ElementRef,
    protected genericCrudService: GenericCrudService
  ) {
    super(elementRef);
  }

  public ngOnInit(): void {
    const elementContext: ElementContext = this.component.getElementContext(),
      collectiveAgreement = elementContext.getMasterEntityValue('collectiveAgreement'),
      parentEntity = elementContext.getMasterEntity('parent');

    this.collectiveAgreementId = +collectiveAgreement;
    this.parentEntity = parentEntity ? parentEntity.entity : null;
  }

  public onDropDownFocus(): void {
    if (this.entity.parameter) {
      this.parametersOptions = [{
        value: this.entity.parameter,
        label: this.entity.parameter
      }];
    }

    this.loadParameters().subscribe((parameters: Parameter[] = []) => {
      for (const parameter of parameters) {
        this.addOption({
          value: parameter.name,
          label: parameter.name
        });
      }
    });
  }

  public onParameterChange(event: {value: string}): void {
    this.component.flagEntityForBulkSave(this.entity).subscribe(() => {
      this.component.changeEntityValue(this.entity, this.column, this.entity['parameter'], '');
    });
  }

  private loadParameters(): Observable<Parameter[]> {
    const requestParams = {
        clause: 'orWhere',
        embedded: 'collectiveAgreement'
      },
      discriminator = this.parentEntity.discriminator;

    if (!discriminator || !this.collectiveAgreementId) {
      console.error('Discriminator or collective agreement id not found!');
    }

    const apiRoute = `phoenix/${discriminator}parameters/offset/0/limit/50/orderby/id/asc?
      collectiveAgreement=in:${this.collectiveAgreementId}`;

    return this.genericCrudService
      .getEntities(apiRoute, '', requestParams).pipe(
      map((paginated: {data: any[]}) => { return paginated.data; }));
  }

  private addOption(option: SelectItem): void {
    const index = this.parametersOptions.findIndex((aOption: SelectItem) => {
      return option.value === aOption.value;
    });

    if (index === -1) {
      this.parametersOptions = [...this.parametersOptions, option];
    }
  }
}
