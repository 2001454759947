
import {of as observableOf, Observable} from 'rxjs';
import {ExecutionStepPayload} from './execution-step-payload';
import {ExecutionStep} from './execution-step';
import {ExecutionStepStatus} from 'app/core/executor/execution-step-status';
import {ModuleStateInspectorService} from './service/module-state-inspector.service';
import {GenericCrudService} from '../../shared/services/generic-crud.service';
import {ElementContext} from '../../shared/content-renderer/services/ElementContext';
import {AbstractGenericGridComponent} from '../../shared/content-renderer/elements/abstract-generic-grid.component';
import { EntityHydrator } from 'app/shared/services/entity-hydrator.service';
import { ModuleStateInspectorServiceAware } from './type/module-state-inspector-service-aware';
import { GenericCrudServiceAware } from 'app/core/executor/type/generic-crud-service-aware';
import { EntityHydratorAware } from 'app/core/executor/type/entity-hydrator-aware';
import { ModulesStateService } from '../../shared/content-renderer/services/modules-state.service';
import {EntityStatus} from '../../shared/services/entity/entity-status';
import {EntityDraftStoreService} from '../../shared/content-renderer/services/entity-draft-store.service';
import {JobContainerService} from '../job-runner/job-container.service';
import {JobContainerAware} from './type/job-container-aware';
import {LocationService} from '../../shared/services/location.service';
import {ExecutionStepParameter} from './execution-step-parameter';
import {EntityDirtyStoreService} from '../../shared/content-renderer/services/entity-dirty-store.service';
import {Injector} from '@angular/core';
import {MemoFieldDefinitionValueComponent} from '../../shared/content-renderer/elements/custom/memo-field-definition-value/memo-field-definition-value.component';
import {EntityManagerService} from '../service/entity-manager/entity-manager.service';

export abstract class AbstractExecutionStep implements ExecutionStep, ModuleStateInspectorServiceAware, GenericCrudServiceAware, EntityHydratorAware, JobContainerAware {

  private parameters: ExecutionStepParameter[] = [];
  private payload: ExecutionStepPayload = null;

  private previousExecutionPayload: ExecutionStepStatus = null;

  public modulesStateService: ModulesStateService;
  public moduleStateInspectorService: ModuleStateInspectorService;
  public genericCrudService: GenericCrudService;
  public entityHydrator: EntityHydrator;
  public entityDraftStoreService: EntityDraftStoreService;
  public jobContainerService: JobContainerService;
  public locationService: LocationService;
  public entityDirtyStoreService: EntityDirtyStoreService;
  public injector: Injector;

  public abstract doExecute(): Observable<ExecutionStepStatus>;

  public constructor(payload?: ExecutionStepPayload) {
    this.payload = payload;
  }

  public setPayload(payload: ExecutionStepPayload): ExecutionStep {
    this.payload = payload;

    return this;
  }

  public getPayload(): ExecutionStepPayload {
    return this.payload;
  }

  public setParameters(parameters: ExecutionStepParameter[] = []): ExecutionStep {
    this.parameters = parameters;

    return this;
  }

  public getParameters(): ExecutionStepParameter[] {
    return this.parameters;
  }

  protected getParam(key: string): ExecutionStepParameter|null {
    let foundParam = null;

    for (const param of this.parameters) {
      if (param.getName() === key) {
        foundParam = param;
        break;
      }
    }

    return foundParam;
  }

  protected getParamValue(key: string): any|null {
    const param = this.getParam(key);

    return param && param.getValue() ? param.getValue() : null;
  }

  public getPreviousExecutionPayload(): ExecutionStepStatus {
    return this.previousExecutionPayload;
  }

  public setPreviousExecutionPayload(value: ExecutionStepStatus): ExecutionStep {
    this.previousExecutionPayload = value;
    return this;
  }

  public execute(): Observable<ExecutionStepStatus> {
    return this.doExecute();
  }

  protected getSuccessObservable(content = null): Observable<ExecutionStepStatus> {
    return observableOf({ status: true, content: content });
  }

  protected getFailObservable(content): Observable<ExecutionStepStatus> {
    return observableOf({ status: false, content: content });
  }

  protected getCollectionPropertyName(component: AbstractGenericGridComponent|MemoFieldDefinitionValueComponent) {
    return (component.element && component.element.datamodel) ? component.element.datamodel.entityCollectionName : null;
  }

  protected getMasterEntityField(elementContext: ElementContext): string|null {
    return (elementContext.moduleElement) ? elementContext.moduleElement.masterFilterField : null;
  }

  protected getMasterEntity(entity: any, masterEntityField: string): any {
    return (entity['_embedded'] && entity['_embedded'][masterEntityField]) ? entity['_embedded'][masterEntityField]
      : ((entity[masterEntityField]) ? entity[masterEntityField] : null);
  }

  protected pushToCollection(entity: any, collectionName: string, masterEntityField) {
    const masterEntity = this.getMasterEntity(entity, masterEntityField);

    this.pushToMasterEntityCollection(masterEntity, collectionName, entity);
  }

  protected pushToMasterEntityCollection(masterEntity: any, collectionName: string, entity: any) {

    if (masterEntity === null) {
      console.error("Master entity not found!");
      return;
    }

    if (!masterEntity.hasOwnProperty(collectionName)) {
      masterEntity[collectionName] = [];
    }

    if (entity[EntityStatus.ENTITY_DRAFT_DELETED_FLAG]) {
      return;
    }

    const oldValue = masterEntity[collectionName];

    const entityIndexInMasterCollection = this.findEntityIndexById(masterEntity[collectionName], entity);
    if (entityIndexInMasterCollection !== -1) {
      masterEntity[collectionName][entityIndexInMasterCollection] = this.entityHydrator.hydrate(entity);
    } else {
      masterEntity[collectionName].push(this.entityHydrator.hydrate(entity));
    }

    masterEntity[EntityStatus.EMBEDDED_ENTITY_CHANGED_FLAG] = true;

    const entityManager = this.injector.get(EntityManagerService, null);
    entityManager.persist(masterEntity, {property: collectionName, oldValue: oldValue, newValue: masterEntity[collectionName]});
  }

  protected removeFromMasterEntityCollection(masterEntity: any, collectionName: string) {
    masterEntity[collectionName] = [];
  }

  protected findEntityById(collection: any[], entity) {
    for (const collectionEntity of collection) {
      if ((collectionEntity.id && collectionEntity.id === entity.id)
        || collectionEntity[EntityStatus.ENTITY_DRAFT_FLAG] === entity[EntityStatus.ENTITY_DRAFT_FLAG]) {
        return collectionEntity;
      }
    }

    return null;
  }

  protected findEntityIndexById(collection: any[], entity): number {
    return collection.findIndex((collectionEntity) => {
      if ((collectionEntity.id && collectionEntity.id === entity.id)
        || collectionEntity[EntityStatus.ENTITY_DRAFT_FLAG] === entity[EntityStatus.ENTITY_DRAFT_FLAG]) {
        return collectionEntity;
      }
    });
  }
}
