/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../../../../services/module/module-crud.service";
import * as i2 from "../../../../../../services/generic-crud.service";
import * as i3 from "../../services/toolbar-item-form-entity.service";
import * as i4 from "../../../../../../services/api.builder.service";
import * as i5 from "./toolbar-item-refresh.component";
import * as i6 from "../../../../../../../core/message/message-growl.service";
import * as i7 from "../../../../../services/component-highlight-stack.service";
import * as i8 from "../../../../../services/entity-dirty-store.service";
import * as i9 from "../../../../../services/entity-data-store.service";
import * as i10 from "../../../../../../services/request-caching.service";
var styles_ToolbarItemRefresh = [];
var RenderType_ToolbarItemRefresh = i0.ɵcrt({ encapsulation: 2, styles: styles_ToolbarItemRefresh, data: {} });
export { RenderType_ToolbarItemRefresh as RenderType_ToolbarItemRefresh };
export function View_ToolbarItemRefresh_0(_l) { return i0.ɵvid(2, [], null, null); }
export function View_ToolbarItemRefresh_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "toolbar-item-refresh", [], null, null, null, View_ToolbarItemRefresh_0, RenderType_ToolbarItemRefresh)), i0.ɵprd(512, null, i1.ModuleCrudService, i1.ModuleCrudService, [i2.GenericCrudService]), i0.ɵprd(512, null, i3.ToolbarItemFormEntityService, i3.ToolbarItemFormEntityService, [i4.ApiBuilderService]), i0.ɵdid(3, 245760, null, 0, i5.ToolbarItemRefresh, [i1.ModuleCrudService, i6.MessageGrowlService, i3.ToolbarItemFormEntityService, i2.GenericCrudService, i7.ComponentService, i8.EntityDirtyStoreService, i9.EntityDataStoreService, i10.RequestCachingService], null, null)], function (_ck, _v) { _ck(_v, 3, 0); }, null); }
var ToolbarItemRefreshNgFactory = i0.ɵccf("toolbar-item-refresh", i5.ToolbarItemRefresh, View_ToolbarItemRefresh_Host_0, { params: "params" }, {}, []);
export { ToolbarItemRefreshNgFactory as ToolbarItemRefreshNgFactory };
