import {AfterViewInit, Directive, ElementRef, Input, OnInit, Renderer2} from '@angular/core';
import {Element} from '../form-viewer/models';
import {Module} from '../services/module/module';
import {ModuleElement} from '../services/module/module-element';
import {ToolbarItem} from '../services/element/toolbar-item';
import {MenuItem} from 'primeng/api';
import {ModuleElementColumn} from '../services/module/module-element-column';

interface CustomHtmlElement {
  type: string;
  id: string;
}

@Directive({
  selector: '[appSeleniumDirective]'
})
export class SeleniumDirective implements AfterViewInit {

  @Input() public element: Element = null;
  @Input() public module: Module = null;
  @Input() public moduleTab: Module = null;
  @Input() public moduleElement: ModuleElement = null;
  @Input() public toolbarItem: ToolbarItem = null;
  @Input() public menuItem: MenuItem = null;
  @Input() public filter: ModuleElementColumn = null;
  @Input() public column: ModuleElementColumn = null;
  @Input() public data: any = null;
  @Input() public customElement: CustomHtmlElement = null;

  public attribute = 'id';

  public constructor(
    private elementRef: ElementRef,
    private renderer: Renderer2
  ) {

  }

  public ngAfterViewInit () {
    const nativeElement = this.elementRef.nativeElement;

    if (nativeElement && this.element) {
      this.setFormElementId(nativeElement, this.element);
      return;
    }

    if (nativeElement && this.module) {
      this.setModuleId(nativeElement, this.module);
      return;
    }

    if (nativeElement && this.moduleElement) {
      this.setModuleElementId(nativeElement, this.moduleElement);
      return;
    }

    if (nativeElement && this.toolbarItem) {
      this.setToolbarItemId(nativeElement, this.toolbarItem);
      return;
    }

    if (nativeElement && this.menuItem) {
      this.setMenuItemId(nativeElement, this.menuItem);
      return;
    }

    if (nativeElement && this.moduleTab) {
      this.setModuleTabId(nativeElement, this.moduleTab);
      return;
    }

    if (nativeElement && this.filter) {
      this.setFilterId(nativeElement, this.filter);
      return;
    }

    if (nativeElement && this.column && this.data) {
      this.setRowId(nativeElement, this.column, this.data);
      return;
    }

    if (nativeElement && this.customElement) {
      this.setCustomElementId(nativeElement, this.customElement);
    }
  }

  private setFormElementId(nativeElement: any, aElement: Element): void {
    this.renderer.setAttribute(nativeElement, this.attribute, `FormElement-${aElement.label}`);

    if (aElement.typeElement === 'autocomplete' || aElement.typeElement === 'dropdown' || aElement.typeElement === 'multi-autocomplete') {
      setTimeout(() => {
        const input = this.elementRef.nativeElement.querySelector(`input`),
          button = this.elementRef.nativeElement.querySelector(`button`) ||
            this.elementRef.nativeElement.querySelector('.ui-multiselect-trigger') ||
            this.elementRef.nativeElement.querySelector('.ui-dropdown-trigger');

        if (input) {
          this.renderer.setAttribute(input, this.attribute, `FormElementInput-${aElement.label}`);
        }

        if (button) {
          this.renderer.setAttribute(button, this.attribute, `FormElementButton-${aElement.label}`);
        }
      }, 1000);
    }
  }

  private setModuleId(nativeElement: any, module: Module): void {
    this.renderer.setAttribute(nativeElement, this.attribute, `Module-${module.name}`);
  }

  private setModuleElementId(nativeElement: any, moduleElement: ModuleElement): void {
    this.renderer.setAttribute(nativeElement, this.attribute, `ModuleElement-${moduleElement.name}`);
  }

  private setToolbarItemId(nativeElement: any, toolbarItem: ToolbarItem): void {
    this.renderer.setAttribute(nativeElement, this.attribute, `ToolbarItem-${toolbarItem.name}-${toolbarItem.id}`);

    setTimeout(() => {
      const img = this.elementRef.nativeElement.querySelector(`img`);

      if (img) {
        this.renderer.setAttribute(img, this.attribute, `ToolbarItemImage-${toolbarItem.name}-${toolbarItem.id}`);
      }
    }, 5);
  }

  private setMenuItemId(nativeElement: any, menuItem: MenuItem): void {
    this.renderer.setAttribute(nativeElement, this.attribute, `MenuItem-${menuItem.label}`);

    setTimeout(() => {
      const img = this.elementRef.nativeElement.querySelector(`img`);

      if (img) {
        this.renderer.setAttribute(img, this.attribute, `MenuItemImage-${menuItem.label}`);
      }
    }, 5);
  }

  private setModuleTabId(nativeElement: any, module: Module): void {
    this.renderer.setAttribute(nativeElement, this.attribute, `ModuleTab-${module.name}`);
  }

  private setFilterId(nativeElement: any, column: ModuleElementColumn): void {
    this.renderer.setAttribute(nativeElement, this.attribute, `Filter-${column.header}`);

    if (column.filterType === 'autocomplete' || column.filterType === 'dropdown' || column.filterType === 'multidropdown') {
      setTimeout(() => {
        const input = this.elementRef.nativeElement.querySelector(`input`),
          button = this.elementRef.nativeElement.querySelector(`button`) ||
            this.elementRef.nativeElement.querySelector('.ui-multiselect-trigger') ||
            this.elementRef.nativeElement.querySelector('.ui-dropdown-trigger');

        if (input) {
          this.renderer.setAttribute(input, this.attribute, `FilterInput-${column.header}`);
        }

        if (button) {
          this.renderer.setAttribute(button, this.attribute, `FilterButton-${column.header}`);
        }
      }, 1000);
    }
  }

  private setRowId(nativeElement: any, column: ModuleElementColumn, entity: any): void {
    this.renderer.setAttribute(nativeElement, this.attribute, `Column-${column.header}-Entity-${entity.id}`);
  }

  private setCustomElementId(nativeElement: any, customData: CustomHtmlElement): void {
    if (customData.type === 'file-input') {
      const input = this.elementRef.nativeElement.querySelector(`input`);

      if (input) {
        this.renderer.setAttribute(input, this.attribute, `CustomElementFileInput-${customData.id}`);
      }
    }
  }
}
