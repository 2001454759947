import {ChangeDetectionStrategy, Component, Injector} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {ToolbarItemAbstract} from '../toolbar-item-abstract.component';
import {GenericCrudService} from '../../../../../../services/generic-crud.service';
import {AbstractGenericGridComponent} from '../../../../abstract-generic-grid.component';
import {MessageGrowlService} from '../../../../../../../core/message/message-growl.service';
import {PreCalculationArticleCalculatorFactory} from '../../../../../../services/precalculation/article/calculation/type/pre-calculation-article-calculator.factory';
import {PreCalculation} from '../../../../../../services/precalculation/article/pre-calculation-article';
import {FormViewerComponent} from '../../../../../../form-viewer/form-viewer.component';
import {ToolbarItemSavePrecalculationArticleService} from '../../../services/save/toolbar-item-save-precalculation-article.service';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-toolbar-item-sync-articles',
  template: ''
})
export class ToolbarItemSyncArticlesComponent extends ToolbarItemAbstract {

  constructor(
    protected genericCrudService: GenericCrudService,
    protected messageGrowlService: MessageGrowlService,
    protected translate: TranslateService,
    protected preCalculationArticleSaveService: ToolbarItemSavePrecalculationArticleService,
    protected injector: Injector
  ) {
    super();
  }

  public click() {
    const component = this.getComponent();

    const calculatorFactory = this.injector.get(PreCalculationArticleCalculatorFactory, null);

    if (component instanceof AbstractGenericGridComponent && component.getSelectedEntity()) {
      const entity = component.getSelectedEntity();
      const entities = component.getEntities();
      const params = {
        embedded: 'preCalculationArticlePeriod'
      };

      this.subscriptions.push(
        this.genericCrudService.customPost(`phoenix/precalculationarticles/sync`, {articles: [entity.id]})
          .subscribe((data) => {
            this.messageGrowlService.success(this.translate.instant('COMMON.SUCCESS') + '.');
            // for each changed entity, load other articles and recalculate:
            for (const otherGeneralArticle of entities) {
              if (otherGeneralArticle.id === entity.id) {
                continue;
              }
              params['masterPreCalculationArticle'] = otherGeneralArticle.id;
              this.subscriptions.push(
                this.genericCrudService.getPaginated('phoenix/precalculationotherarticles/offset/0/limit/50/orderby/sortPosition/asc', params).subscribe(
                  (results) => {
                    for (const otherArticle of results.data) {
                      const calculator = calculatorFactory.spawnCalculator(otherArticle);
                      calculator.calculate(otherArticle, [otherGeneralArticle], this.getPreCalculation(component));

                      this.subscriptions.push(
                        this.preCalculationArticleSaveService.saveArticles([otherGeneralArticle]).subscribe()
                      );
                    }
                  }
                )
              );
            }
          })
      );
    }
  }

  private getPreCalculation(grid: AbstractGenericGridComponent): PreCalculation|null {
    let masterForm = grid.getElementContext().getMasterElementContext().component;

    if (masterForm instanceof AbstractGenericGridComponent) {
      masterForm = masterForm.getElementContext().getMasterElementContext().component;
    }

    if (!(masterForm instanceof FormViewerComponent)) {
      return null;
    }

    return masterForm.getSelectedEntity();
  }
}
