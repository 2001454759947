<div style="height: 100%" fxLayoutAlign="start stretch" fxLayout="column">
  <div fxFlex="none" *ngIf="element && element.topToolbarItems && element.topToolbarItems.length > 0">
    <p-toolbar styleClass="no-round-corner">
      <app-generic-toolbar [params]="getToolbarExtraParams()" [items]="element.topToolbarItems"></app-generic-toolbar>
    </p-toolbar>
  </div>

  <div fxFlex="grow" class="ui-dataview-content ui-widget-content questions-container">
    <p>
      report: {{selectedMasterEntity.name}}
    </p>

    <div class="ui-g">
      <div *ngFor="let clause of clauses; let i = index" class="ui-g-12 ui-md-3 ui-lg-2" >

        <div class="ui-g-4"><label for="clause{{i}}">{{ clause.name }}</label></div>

        <div [ngSwitch]="clause.columnType.code">
          <ng-template ngSwitchCase="number">
            <div class="ui-g-8">
              <input
                pInputText
                id="clause{{i}}"
                [(ngModel)]="clauseValues[clause.code]"
                [textMask]="textMask"
              /></div>
          </ng-template>
          <ng-template ngSwitchCase="date">
            <div class="ui-g-8">
              <p-calendar
                appCalendar
                id="clause{{i}}"
                [dateFormat]="'dd.mm.yy'"
                [(ngModel)]="clauseValues[clause.code]"
                [showIcon]="true"
              ></p-calendar>
            </div>
          </ng-template>
          <ng-template ngSwitchCase="branchOffice">
            <div class="ui-g-8">
              <p-dropdown
                [(ngModel)]="clauseValues[clause.code]"
                (onClick)="loadBranchOffices()"
                (onChange)="onBranchOfficeChanged($event)"
                placeholder=" --- Bitte wählen --- "
                [options]="branchOffices"
                filter="true"
                [style]="{'width':'100%'}"
              ></p-dropdown>
            </div>
          </ng-template>
          <ng-template ngSwitchDefault>
            <div class="ui-g-8"><input pInputText id="clause{{i}}" [(ngModel)]="clauseValues[clause.code]" /></div>
          </ng-template>
        </div>
      </div>
      <div class="ui-g-8">
        <button type="button" pButton label="Go" (click)="doLoadData()"></button>
        <button
          pButton
          (click)="doExport()"
          label="CSV Export"
          pTooltip="Export"
          tooltipPosition="top"
          style="margin-left: .2em; margin-right: .2em; white-space: nowrap"
          [disabled]="!visibleValues"
        ></button>
      </div>
    </div>

    <div
      #gridContainer
      (click)="highlight()"
      [class]="isHighlighted() ? 'highlighted grid-container' : 'grid-container'" style="height: 92%;">

      <shared-dynamic-table
        #table
        [columns]="columns"
        [rows]="[]"
        [totalCount]="totalCount"
        [rowsCount]="100"
        [entities]="visibleValues"
        (onFilter)="onFilter($event)"
        (onLazyLoad)="onLazyLoad($event)"
        [paginator]="true"
        [selectFirstEntityWhenNothingIsSelected]="moduleElement.selectFirst"
      ></shared-dynamic-table>
    </div>
  </div>

</div>
