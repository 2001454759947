import {Component} from '@angular/core';
import {AbstractCustomFilterClassComponent} from '../abstract-custom-filter-class.component';
import {GenericCrudService} from '../../../../../services/generic-crud.service';
import {Observable} from 'rxjs';
import {CustomFilterClassComponent} from '../custom-filter-class.component';
import {WorkhourLeasedEmployeeAutocompleteComponent} from '../workhour-leased-employee-autocomplete/workhour-leased-employee-autocomplete.component';
import {MessageGrowlService} from '../../../../../../core/message/message-growl.service';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-workhour-leased-employee-next-button',
  templateUrl: './workhour-leased-employee-next-button.component.html'
})
export class WorkhourLeasedEmployeeNextButtonComponent extends AbstractCustomFilterClassComponent {

  public employees: number[] = [];

  public constructor(
    private genericCrudService: GenericCrudService,
    private growlService: MessageGrowlService,
    private translateService: TranslateService
  ) {
    super();
  }

  public onNextButtonClick(): void {
    this.loadNextEmployee().subscribe((employee) => {
      if (employee !== null) {
        this.changeEmployee(employee);
      } else {
        this.growlService.info(
          this.translateService.instant('WORK_HOUR.NEXT_LEASED_EMPLOYEE_NOT_FOUND')
        );
      }
    });
  }

  private changeEmployee(employee): void {
    const customFilterClass = this.genericFilterComponent.customGridFilters.find((aComponent: CustomFilterClassComponent) => {
      return aComponent.filterComponent instanceof WorkhourLeasedEmployeeAutocompleteComponent;
    });

    if (customFilterClass && customFilterClass.filterComponent instanceof WorkhourLeasedEmployeeAutocompleteComponent) {
      customFilterClass.filterComponent.options = [];
      employee.label = `${employee.firstName} ${employee.lastName}`;
      customFilterClass.filterComponent.addOptionAndSelect(employee);
    }
  }

  private loadNextEmployee(): Observable<any> {
    const apiRoute = 'phoenix/docuwaredocumentapprovalperiods/leasedemployee/next',
      params = {
        'embedded': 'none',
        'isSigned': '0'
      };

    if (this.component.gridFilters && this.component.gridFilters['workMonth']) {
      params['validityMonth'] = `${this.component.gridFilters['workMonth'].value}`;
    }
    if (this.component.gridFilters && this.component.gridFilters['workYear']) {
      params['validityYear'] = `${this.component.gridFilters['workYear'].value}`;
    }
    if (this.component.gridFilters && this.component.gridFilters['assignment'] && this.component.gridFilters['assignment'].value &&
      this.component.gridFilters['assignment'].value[0]
    ) {
      params['assignment'] = `${this.component.gridFilters['assignment'].value[0].id}`;
    }

    const currentValue = this.getCurrentFilterValue();
    if (currentValue) {
      this.employees.push(currentValue.id);
      params['leasedEmployee'] = this.employees.join(',');
    }

    return this.genericCrudService.get(apiRoute, params);
  }

  private getCurrentFilterValue(): any|null {
    return this.component.gridFilters.leasedEmployee && this.component.gridFilters.leasedEmployee.value &&
      this.component.gridFilters.leasedEmployee.value[0] ?
      this.component.gridFilters.leasedEmployee.value[0] :
      null;
  }
}
