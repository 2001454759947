import {JobInterface} from '../../../../core/job-runner/job/job.interface';
import {JobContext} from '../../../../core/job-runner/context/job.context';
import {Observable} from 'rxjs';
import {JobExecutionStatus} from '../../../../core/job-runner/job/job-execution-status';
import {ConditionInterface} from '../../../../core/job-runner/condition/condition.interface';
import {AbstractJob} from '../../../../core/job-runner/job/abstract.job';
import {AbstractGenericGridComponent} from '../../../content-renderer/elements/abstract-generic-grid.component';
import {GenericCrudRequestOptions} from '../../../services/generic-crud-request-options.service';
import {ModulesStateService} from '../../../content-renderer/services/modules-state.service';
import {UserSessionService} from '../../../../core/service/user-session.service';

export class SetupWorkHourSubAssignmentGridJob extends AbstractJob implements JobInterface {

  public constructor(
    private userSession: UserSessionService,
    private modulesState: ModulesStateService
  ) {
    super();
  }

  run(context: JobContext, condition: ConditionInterface): Observable<JobExecutionStatus> {
    return Observable.create((observer) => {
      const component = context.component,
        subAssignmentIds: any = condition.getPayload().subAssignmentIds;

      if (component instanceof AbstractGenericGridComponent && subAssignmentIds) {
        component.staticFilters.push({
          field: 'id',
          value: `in:${subAssignmentIds.join(',')}`
        });

        this.setShowExpiredFilter(component);

        observer.next({
          status: true,
          content: null,
          jobId: this.jobId
        });

        observer.complete();
      } else {
        observer.next({
          status: false,
          content: 'Wrong component set',
          jobId: this.jobId
        });

        observer.complete();
      }
    });
  }

  protected setShowExpiredFilter(component: AbstractGenericGridComponent): void {
    const filterKey = `${this.modulesState.getByComponent(component).id}.grid.${component.getElementDataModelApiRoute()}.${GenericCrudRequestOptions.SHOW_EXPIRED}`;

    this.userSession.set(filterKey, {
      value: true
    });
  }
}
