/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./field-action-open-module.component";
import * as i2 from "../../../../../services/generic-crud.service";
import * as i3 from "../../../../services/double-click.service";
import * as i4 from "../../../generic-dialog/service/generic-dialog-module.service";
import * as i5 from "../../../../../../core/executor/factory/execution-step-factory.service";
var styles_FieldActionOpenModuleComponent = [];
var RenderType_FieldActionOpenModuleComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_FieldActionOpenModuleComponent, data: {} });
export { RenderType_FieldActionOpenModuleComponent as RenderType_FieldActionOpenModuleComponent };
export function View_FieldActionOpenModuleComponent_0(_l) { return i0.ɵvid(0, [], null, null); }
export function View_FieldActionOpenModuleComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "field-action-open-module", [], null, null, null, View_FieldActionOpenModuleComponent_0, RenderType_FieldActionOpenModuleComponent)), i0.ɵdid(1, 245760, null, 0, i1.FieldActionOpenModuleComponent, [i2.GenericCrudService, i3.DoubleClickService, i4.GenericDialogModuleService, i5.ExecutionStepFactoryService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var FieldActionOpenModuleComponentNgFactory = i0.ɵccf("field-action-open-module", i1.FieldActionOpenModuleComponent, View_FieldActionOpenModuleComponent_Host_0, { action: "action", field: "field", grid: "grid" }, {}, []);
export { FieldActionOpenModuleComponentNgFactory as FieldActionOpenModuleComponentNgFactory };
