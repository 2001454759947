import {Directive, EventEmitter, Output, Input} from '@angular/core';
import {Calendar} from 'primeng/primeng';

@Directive({
  selector: '[appTimeMask]',
  host: {
    '(keyup)': 'onInputKeyUp($event)'
  }
})
export class TimeMaskDirective {
  @Output() onChange: EventEmitter<any> = new EventEmitter();
  @Input() value = '';
  private calendar;

  public constructor() {
    this.calendar = new Calendar(null, null, null, null);
    this.calendar.showTime = true;
    this.calendar.timeOnly = true;
  }

  public onInputKeyUp(event): void {
    this.value = event.target.value;

    if (this.value.length === 5 && this.value.indexOf('_') === -1) {
      let time = null;

      try {
        time = this.calendar.parseTime(this.value);
      } catch (e) {

      } finally {
        this.onChange.emit(time !== null ? this.value : '');
      }
    }
  }
}
