
import {of as observableOf, Observable} from 'rxjs';
import {AfterViewInit, ChangeDetectionStrategy, Component, OnInit} from '@angular/core';
import {ExecutorService} from '../../../../../core/executor/executor.service';
import {GenericElementValidationExecutionStepsFactory} from '../../../services/generic/generic-element-validation-execution-steps-factory';
import {GenericElementFilterService} from '../../../services/generic/filter/generic-element-filter.service';
import {CancelComponentChangesService} from '../../../services/cancel-components-changes.service';
import {GenericGridColumnBuilderService} from '../../generic-grid/services/generic-grid-column-builder.service';
import {GenericGridGlobalFilterService} from '../../generic-grid/services/generic-grid-global-filter.service';
import {GenericGridRemoteFilterService} from '../../generic-grid/services/generic-grid-remote-filter.service';
import {GenericElementInlineEditorService} from '../../../services/generic/generic-element-inline-editor.service';
import {GenericGridBulkSaveService} from '../../generic-grid/services/generic-grid-bulk-save.service';
import {GenericGridEntityService} from '../../../services/generic/entity/generic-grid-entity.service';
import {CustomButtonCheckFactoryService} from '../../generic-grid/services/custom-button-check/custom-button-check-factory.service';
import {ExecutionStepBuilderService} from '../../../../../core/executor/builder/execution-step-builder.service';
import {ElementType} from '../../../services/ElementContext';
import {EventHelper} from '../../../../helpers/event.helper';
import {WorkHourFrontendCalculateTimesStep} from '../../../../services/execution-step/work-hour/work-hour-frontend-calculate-times-step';
import {ExecutionStepPayload} from '../../../../../core/executor/execution-step-payload';
import {FieldMetadataGrid} from '../../../../services/module/module-element-field-metadata-grid';
import {EntityDataChangeMeta} from '../../../services/entity-data-store.service';
import {EntityStatus} from '../../../../services/entity/entity-status';
import {Element} from '../../../../services/element/element';
import {GenericTurboGridComponent} from '../../generic-turbo-grid/generic-turbo-grid.component';
import {AbstractGenericGridComponent} from '../../abstract-generic-grid.component';

@Component({
  selector: 'app-custom-work-hour-grid',
  styleUrls: ['./../../generic-turbo-grid/generic-turbo-grid.component.scss'],
  templateUrl: './../../generic-turbo-grid/generic-turbo-grid.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    CancelComponentChangesService,
    GenericGridRemoteFilterService,
    GenericGridGlobalFilterService,
    GenericGridColumnBuilderService,
    GenericGridBulkSaveService,
    GenericGridEntityService,
    GenericElementInlineEditorService,
    GenericElementFilterService,
    GenericElementValidationExecutionStepsFactory,
    ExecutorService,
    ExecutionStepBuilderService,
    CustomButtonCheckFactoryService
  ]
})
export class WorkHourGridComponent extends GenericTurboGridComponent implements OnInit, AfterViewInit {

  public static WORK_START_FIELD = 'workStart';
  public elementType: ElementType = ElementType.WorkHourGrid;

  public ngOnInit() {
    super.ngOnInit();
  }

  public ngAfterViewInit(): void {
    if (this.filterComponent) {
      this.filterComponent.onClear = () => {
        this.clearFilters()
          .initColumns();

        this.entities = [];
      };
      this.embeddedFields.push('leasedEmployee');

      /*this.filterComponent.onSearch = () => {
        this.currentOffset = 0;
        this.sortDirection = 'ASC';
        this.sortField = 'workDate';
        this.defaultPageSize = this.element.pageSize;

        Debounce.debounce(() => {
          this.loadEntities().subscribe(() => {
          });
        }, 500);

        this.filterComponent.onSearchExecuted.emit();
      };*/
    }
  }

  public onSort(event) {
    this.sortField = event.field;
    this.sortDirection = (+event.order === 1) ? Element.SORT_DIRECTION_ASC : Element.SORT_DIRECTION_DESC;

    this.loadEntities().subscribe();
  }

  public loadGridLazy(event) {

  }

  public clearFilters(): this {
    delete this.grid.filters['leasedEmployee'];

    delete this.gridFilters['leasedEmployee'];

    if (this.componentState) {
      this.componentState.filters = {};
    }

    return this;
  }

  public loadEntities(): Observable<any> {

    if (this.areAllFiltersDefined()) {

      if (this.element && this.element.datamodel) {
        return new Observable((observer) => {
          this.doLoadEntities('phoenix/workhours/summary');

          observer.next();
          observer.complete();
        });
      }

      return observableOf(null);
    } {
      this.messageGrowlService.error(
        this.translationService.instant('WORK_HOUR.FILTERS_MISSING'),
        this.translationService.instant('COMMON.ERROR')
      );

      this.entities = [];

      return observableOf(null);
    }
  }

  public isEditCellDisabled(entity: any, column: any) {
    const isEditCellDisabled = super.isEditCellDisabled(entity, column);

    return isEditCellDisabled || entity.isLocked || entity.isWageSettled;
  }

  public onEditCell(editEvent, entity: any, column: any) {
    const isChangingCell = EventHelper.isTabPressed(editEvent) || EventHelper.isEnterPressed(editEvent) || (editEvent && editEvent.code === 'blur');

    if (!isChangingCell && this.permissionService.isGranted(column, 'edit')) {
      const oldValue = this.genericElementInlineEditorService.getValue(entity, column);

      this.genericElementInlineEditorService.onEdit(editEvent, entity, column);

      const currentValue = this.genericElementInlineEditorService.getValue(entity, column);

      this.calculate(column)
        .subscribe(() => {
          this.flagEntityForBulkSave(entity, currentValue, oldValue).subscribe((canBeChanged: boolean) => {
            if (canBeChanged) {
              this.changeEntityValue(entity, column, currentValue, oldValue);
            }
          });
        });
    }
  }

  public addShelved(): AbstractGenericGridComponent {

    return this;
  }

  protected assignEntityValueChangesSubscription(): void {
    this.subscriptions.push(
      this.entityDataStore.entityValueChanged$.subscribe((entityDataChangeMeta: EntityDataChangeMeta) => {
        if (this.getElementDatamodelEntityName() === entityDataChangeMeta.entity['fqn'] &&
          (!this.selectedEntity || entityDataChangeMeta.entity[EntityStatus.ENTITY_DRAFT_FLAG]
            === this.selectedEntity[EntityStatus.ENTITY_DRAFT_FLAG])) {

          this.onChange(entityDataChangeMeta).subscribe((entity) => {
            this.genericElementInlineEditorService.markForCheck(entity);
          });
        }
      })
    );
  }

  private calculate(column): Observable<any> {
    const field: FieldMetadataGrid = (column && column.field) ? column.field : null;

    const payload = new ExecutionStepPayload({
      component: this,
      entityDataChangeMeta: {
        entity: this.selectedEntity,
        gridField: field
      }
    });

    return this.executorService.setSteps([
      this.stepsFactory.create(WorkHourFrontendCalculateTimesStep, payload)
    ]).execute();
  }

  public areAllFiltersDefined(): boolean {
    const filters = this.gridFilters;

    return typeof filters['leasedEmployee'] !== 'undefined' && filters['leasedEmployee'].value;
  }
}
