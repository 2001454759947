import {Injectable} from '@angular/core';
import {AbstractSelectionService} from './abstract-selection.service';
import {EntityHydrator} from '../../../../../../services/entity-hydrator.service';
import {GenericTurboGridComponent} from '../../../../generic-turbo-grid/generic-turbo-grid.component';

@Injectable()
export class GenericGridSelectionService extends AbstractSelectionService {

  public constructor(
    protected entityHydrator: EntityHydrator
  ) {
    super(entityHydrator);
  }

  protected doOnSelectionInit(): void {
    if (this.component instanceof GenericTurboGridComponent && this.component.masterEntityEditingField &&
      typeof this.component.selectedEntities === 'undefined'
    ) {
      const selectedEntities = this.entityHydrator.getEntityPropertyValue(
        this.component.masterEntity, this.component.masterEntityEditingField, false, false);
      if (selectedEntities instanceof Array) {
        this.component.selectedEntities = selectedEntities;
      }
    }
  }

  protected doSelect(entity, node?: TreeNode): void {
    if (!this.component.selectedEntities.find((aEntity) => {return aEntity.id === entity.id; })){
      this.component.selectedEntities.push(entity);
    }
  }

  protected doUnSelect(entity: any, node?: TreeNode): void {
    this.component.selectedEntities = this.component.selectedEntities.filter((element) => {return element.id !== entity.id; });
  }

  protected doIsSelected(entity: any): boolean {
    return this.component.selectedEntities && !!this.component.selectedEntities.find((element) => {
      return element.id === entity.id;
    });
  }
}
