import {Injectable} from '@angular/core';
import {ToolbarItem} from "../../../../../services/element/toolbar-item";
import {ToolbarItemDisabledCheckInterface} from "./toolbar-item-disabled-check-factory.service";
import {ToolbarItemAbstractDisabledCheckService} from "./toolbar-item-abstract-disabled-check.service";


@Injectable()
export class ToolbarItemGenericDisabledCheckService extends ToolbarItemAbstractDisabledCheckService implements ToolbarItemDisabledCheckInterface {

  isDisabled(): boolean {
    return this.isDisabledGlobally();
  }
}
