import { of as observableOf, Observable } from 'rxjs';
import { AfterViewInit, OnInit } from '@angular/core';
import { EventHelper } from '../../../../helpers/event.helper';
import { WorkHourFrontendCalculateTimesStep } from '../../../../services/execution-step/work-hour/work-hour-frontend-calculate-times-step';
import { ExecutionStepPayload } from '../../../../../core/executor/execution-step-payload';
import { EntityStatus } from '../../../../services/entity/entity-status';
import { Element } from '../../../../services/element/element';
import { GenericTurboGridComponent } from '../../generic-turbo-grid/generic-turbo-grid.component';
export class WorkHourGridComponent extends GenericTurboGridComponent {
    constructor() {
        super(...arguments);
        this.elementType = 13 /* WorkHourGrid */;
    }
    ngOnInit() {
        super.ngOnInit();
    }
    ngAfterViewInit() {
        if (this.filterComponent) {
            this.filterComponent.onClear = () => {
                this.clearFilters()
                    .initColumns();
                this.entities = [];
            };
            this.embeddedFields.push('leasedEmployee');
            /*this.filterComponent.onSearch = () => {
              this.currentOffset = 0;
              this.sortDirection = 'ASC';
              this.sortField = 'workDate';
              this.defaultPageSize = this.element.pageSize;
      
              Debounce.debounce(() => {
                this.loadEntities().subscribe(() => {
                });
              }, 500);
      
              this.filterComponent.onSearchExecuted.emit();
            };*/
        }
    }
    onSort(event) {
        this.sortField = event.field;
        this.sortDirection = (+event.order === 1) ? Element.SORT_DIRECTION_ASC : Element.SORT_DIRECTION_DESC;
        this.loadEntities().subscribe();
    }
    loadGridLazy(event) {
    }
    clearFilters() {
        delete this.grid.filters['leasedEmployee'];
        delete this.gridFilters['leasedEmployee'];
        if (this.componentState) {
            this.componentState.filters = {};
        }
        return this;
    }
    loadEntities() {
        if (this.areAllFiltersDefined()) {
            if (this.element && this.element.datamodel) {
                return new Observable((observer) => {
                    this.doLoadEntities('phoenix/workhours/summary');
                    observer.next();
                    observer.complete();
                });
            }
            return observableOf(null);
        }
        {
            this.messageGrowlService.error(this.translationService.instant('WORK_HOUR.FILTERS_MISSING'), this.translationService.instant('COMMON.ERROR'));
            this.entities = [];
            return observableOf(null);
        }
    }
    isEditCellDisabled(entity, column) {
        const isEditCellDisabled = super.isEditCellDisabled(entity, column);
        return isEditCellDisabled || entity.isLocked || entity.isWageSettled;
    }
    onEditCell(editEvent, entity, column) {
        const isChangingCell = EventHelper.isTabPressed(editEvent) || EventHelper.isEnterPressed(editEvent) || (editEvent && editEvent.code === 'blur');
        if (!isChangingCell && this.permissionService.isGranted(column, 'edit')) {
            const oldValue = this.genericElementInlineEditorService.getValue(entity, column);
            this.genericElementInlineEditorService.onEdit(editEvent, entity, column);
            const currentValue = this.genericElementInlineEditorService.getValue(entity, column);
            this.calculate(column)
                .subscribe(() => {
                this.flagEntityForBulkSave(entity, currentValue, oldValue).subscribe((canBeChanged) => {
                    if (canBeChanged) {
                        this.changeEntityValue(entity, column, currentValue, oldValue);
                    }
                });
            });
        }
    }
    addShelved() {
        return this;
    }
    assignEntityValueChangesSubscription() {
        this.subscriptions.push(this.entityDataStore.entityValueChanged$.subscribe((entityDataChangeMeta) => {
            if (this.getElementDatamodelEntityName() === entityDataChangeMeta.entity['fqn'] &&
                (!this.selectedEntity || entityDataChangeMeta.entity[EntityStatus.ENTITY_DRAFT_FLAG]
                    === this.selectedEntity[EntityStatus.ENTITY_DRAFT_FLAG])) {
                this.onChange(entityDataChangeMeta).subscribe((entity) => {
                    this.genericElementInlineEditorService.markForCheck(entity);
                });
            }
        }));
    }
    calculate(column) {
        const field = (column && column.field) ? column.field : null;
        const payload = new ExecutionStepPayload({
            component: this,
            entityDataChangeMeta: {
                entity: this.selectedEntity,
                gridField: field
            }
        });
        return this.executorService.setSteps([
            this.stepsFactory.create(WorkHourFrontendCalculateTimesStep, payload)
        ]).execute();
    }
    areAllFiltersDefined() {
        const filters = this.gridFilters;
        return typeof filters['leasedEmployee'] !== 'undefined' && filters['leasedEmployee'].value;
    }
}
WorkHourGridComponent.WORK_START_FIELD = 'workStart';
