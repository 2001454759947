/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./element-association-datamodel-data.component";
import * as i2 from "../form.service";
import * as i3 from "../form-viewer.service";
import * as i4 from "../../services/generic-crud.service";
import * as i5 from "../../content-renderer/services/component-highlight-stack.service";
import * as i6 from "../../content-renderer/services/entity-data-store.service";
var styles_ElementAssociationDatamodelDataComponent = ["[_nghost-%COMP%] {\n      justify-content: left;\n      width: 100%;\n    }\n\n    [_nghost-%COMP%]    > div[_ngcontent-%COMP%] {\n      position: relative;\n    }"];
var RenderType_ElementAssociationDatamodelDataComponent = i0.ɵcrt({ encapsulation: 0, styles: styles_ElementAssociationDatamodelDataComponent, data: {} });
export { RenderType_ElementAssociationDatamodelDataComponent as RenderType_ElementAssociationDatamodelDataComponent };
export function View_ElementAssociationDatamodelDataComponent_0(_l) { return i0.ɵvid(2, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "span", [["style", "text-align: left"]], null, null, null, null, null)), (_l()(), i0.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.slaveData; _ck(_v, 1, 0, currVal_0); }); }
export function View_ElementAssociationDatamodelDataComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "app-form-element-association-datamodel-data", [], null, null, null, View_ElementAssociationDatamodelDataComponent_0, RenderType_ElementAssociationDatamodelDataComponent)), i0.ɵdid(1, 6012928, null, 1, i1.ElementAssociationDatamodelDataComponent, [i2.FormService, i0.ChangeDetectorRef, i3.FormViewerService, i4.GenericCrudService, i5.ComponentService, i6.EntityDataStoreService], null, null), i0.ɵqud(603979776, 1, { insideNgContent: 0 })], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ElementAssociationDatamodelDataComponentNgFactory = i0.ɵccf("app-form-element-association-datamodel-data", i1.ElementAssociationDatamodelDataComponent, View_ElementAssociationDatamodelDataComponent_Host_0, { form: "form", formGroup: "formGroup", entity: "entity", element: "element", moduleElement: "moduleElement", selectedElement: "selectedElement", showDialogAddAddress: "showDialogAddAddress", actionParams: "actionParams", editMode: "editMode" }, { selectedElementChange: "selectedElementChange", showDialogAddAddressChange: "showDialogAddAddressChange", actionParamsChange: "actionParamsChange" }, []);
export { ElementAssociationDatamodelDataComponentNgFactory as ElementAssociationDatamodelDataComponentNgFactory };
