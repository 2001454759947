import {Input, OnInit} from '@angular/core';
import {SimpleSearchField} from '../../../../../services/simple-search/simple-search';

export abstract class AbstractGenericGridSimpleSearchFieldComponent implements OnInit {
  @Input() public field: SimpleSearchField = null;

  public ngOnInit(): void {
    this.field.comparator = 'contains';
  }
}
