import { Observable } from 'rxjs';
import { AbstractExecutionStep } from '../../../../core/executor/abstract-execution-step';
import { ExecutionStepStatus } from '../../../../core/executor/execution-step-status';
import { AbstractGenericGridComponent } from 'app/shared/content-renderer/elements/abstract-generic-grid.component';
import {TOAST_LIFE_INFINITE, ToastComponentsRegistry, ToastService} from '../../../../core/service/toast.service';
import {TranslateService} from '@ngx-translate/core';
import {GenericDialogModuleService} from '../../../content-renderer/elements/generic-dialog/service/generic-dialog-module.service';
import {map} from 'rxjs/operators';

export class CopyJobAdvertisementJobStep extends AbstractExecutionStep {

    doExecute(): Observable<ExecutionStepStatus> {
        const component = this.getPayload().getValue();

        if (!(component instanceof AbstractGenericGridComponent)) {
            return this.getFailObservable('You need to pass AbstractGenericGridComponent as Payload value!');
        }

        return this.doRegister(component);
    }

    private doRegister(component: AbstractGenericGridComponent): Observable<ExecutionStepStatus> {
      return new Observable((observer) => {
        const url = `phoenix/jobadvertisements/copy/${component.getSelectedEntity().id}`;
        const genericDialogModuleService: GenericDialogModuleService = this.injector.get(GenericDialogModuleService, null);

        this.getToast().custom(ToastComponentsRegistry.PROGRESS_BAR, {
          severity: 'info',
          life: TOAST_LIFE_INFINITE,
          closable: false,
          summary: this.getTranslate().instant('COMMON.LOADING_PLEASE_WAIT')
        });

        this.genericCrudService.customPost(url, {}).pipe(
          map((jobAdvertisement: Record<string, any>) => {
            component.entities.push(jobAdvertisement);

            this.genericCrudService.getEntity('superadmin/modules', 422).subscribe((module) => {
              genericDialogModuleService.showDialog(module, {
                entity: jobAdvertisement,
                height: 600,
                width: 1200,
                isModal: true,
                maximized: true,
                isAutocompleteModuleState: false,
                afterClose: () => {
                  component.onRefresh().subscribe();
                }
              });
            });
          })
        ).subscribe(() => {
          this.getToast().clear(ToastComponentsRegistry.PROGRESS_BAR, 300);
        });
        observer.next({
          status: true,
          content: null
        });
        observer.complete();
      });
    }

    private getToast(): ToastService|null {
      return this.injector.get(ToastService, null);
    }

    private getTranslate(): TranslateService|null {
      return this.injector.get(TranslateService, null);
    }
}
