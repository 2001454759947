
import {tap, map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Observable ,  Subject } from 'rxjs';

import { Element } from './../../services/element/element';
import { GenericCrudService } from './../../services/generic-crud.service';
import { EntityDirtyStoreService } from './entity-dirty-store.service';

export interface Provider {
  clearCacheForRoute(apiRoute: string);
  getEmptyEntity(apiRoute: string);
  fetchEntities(apiRoute: string, opts, filters?);
  setEntities(apiRoute: string, entities: Array<any>);
  getPrevious(apiRoute: string, currentEntity: any, callback: any);
  getNext(apiRoute: string, currentEntity: any, callback: any);
  getFirst(apiRoute: string, callback: any);
  getLast(apiRoute: string, callback: any);
  getIndex(apiRoute: string, currentEntity: any): number;
  getCount(apiRoute: string): number;
  routeExists(apiRoute: string): boolean;
  entityExists(apiRoute: string, entity: any): boolean;
  fetchNext(apiRoute: string, entity: any, callback: any);
  fetchPrevious(apiRoute: string, entity: any, callback: any);
  fetchFirst(apiRoute: string, callback: any);
  fetchLast(apiRoute: string, callback: any);

  onEntityChanged(entity: any);
  onEntitiesChanged(entities: any);
  onEntityDeleted(entity: any);

  entityChanged$;
  entityDeleted$;
  entitiesChanged$;
}

@Injectable()
export class EntitySharingService implements Provider {

  protected entityChangedSource = new Subject<any>();
  protected entityDeletedSource = new Subject<any>();
  protected entitiesChangedSource = new Subject<any>();

  public entityChanged$ = this.entityChangedSource.asObservable();
  public entityDeleted$ = this.entityDeletedSource.asObservable();
  public entitiesChanged$ = this.entitiesChangedSource.asObservable();

  /**
   * Associative array of a normal array with entities.
   * @protected
   * @type {Object}
   * @memberOf EntitySharingService
   */
  protected allEntities: Object = {};

  protected emptyEntity: Object = {};

  protected allSelectedRows: Object = {};
  protected selectedEntity: Observable<any>;

  protected counts: Object = {};

  protected index: Object = {};

  constructor(
    protected genericCrudService: GenericCrudService,
    protected entityDirtyStore: EntityDirtyStoreService
  ) { }

  public onEntityChanged(entity: any) {
    this.entityChangedSource.next(entity);
  }

  public onEntitiesChanged(entities: any) {
    this.entitiesChangedSource.next(entities);
  }

  public onEntityDeleted(entity: any) {
    this.entityDeletedSource.next(entity);
  }

  public unshift(apiRoute: string, entity: any) {
    if (!this.allEntities[apiRoute] || this.allEntities[apiRoute].length === 0) {
      this.allEntities[apiRoute] = [];
    }

    this.allEntities[apiRoute].unshift(entity);
  }

  public push(apiRoute: string, entity: any) {
    if (!this.allEntities[apiRoute] || this.allEntities[apiRoute].length === 0) {
      this.allEntities[apiRoute] = [];
    }

    this.allEntities[apiRoute].push(entity);
  }

  public getPrevious(apiRoute: string, currentEntity: any, callback: any) {
    const index = this.allEntities[apiRoute].findIndex(r => r === currentEntity);
    return callback(index > 0 ? this.allEntities[apiRoute][index - 1] : this.allEntities[apiRoute][index]);
  }

  public getNext(apiRoute: string, currentEntity: any, callback: any) {
    const index = this.allEntities[apiRoute].findIndex(r => r === currentEntity);
    return callback(this.allEntities[apiRoute].length > index + 1 ? this.allEntities[apiRoute][index + 1] : this.allEntities[apiRoute][index]);
  }

  public getFirst(apiRoute: string, callback: any) {
    return callback(this.allEntities[apiRoute][0]);
  }

  public getLast(apiRoute: string, callback: any) {
    return callback(this.allEntities[apiRoute][this.allEntities[apiRoute].length - 1]);
  }

  public getIndex(apiRoute: string, currentEntity: any): number {
    return this.allEntities[apiRoute].findIndex(r => r.id === currentEntity.id) || 0;
  }

  public getCount(apiRoute: string): number {
    return this.counts[apiRoute] ? this.counts[apiRoute] : (this.allEntities[apiRoute] ? this.allEntities[apiRoute].length : 0);
  }

  getEmptyEntity(apiRoute: string) {
    let response;

    response = this.genericCrudService.getEmptyEntity(apiRoute).pipe(
      tap((respondedEntity) => {
        this.emptyEntity = respondedEntity;
      }));

    return response;
  }

  fetchEntities(apiRoute: string, opts, filters?) {
    let response;
    const timeout = opts.timeout || 0; // really important as ng2 blocks more requests

    if (!apiRoute || !opts) {
      throw new Error('apiRoute or callbacks are not defined!');
    }

    if (!this.allEntities[apiRoute] || this.allEntities[apiRoute].length === 0) {

      response = this.genericCrudService.getEntities(apiRoute, '', filters).pipe(
      map((respondedEntities) => {

        if (respondedEntities instanceof Array) {
          respondedEntities = respondedEntities
            .map((entity: any) => {
              return this.entityDirtyStore.replace(entity);
            });
        }

        return respondedEntities;
      }),
        tap((respondedEntities) => {
          this.allEntities[apiRoute] = respondedEntities;
          this.counts[apiRoute] = respondedEntities.length;

          this.onEntitiesChanged({
            'data': this.allEntities[apiRoute],
            'count': this.allEntities[apiRoute].length,
            'apiRoute': apiRoute
          });

          return opts.success(this.allEntities[apiRoute]);
        }),);

      setTimeout(function() { response.subscribe(); }, timeout);
    } else {
      this.onEntitiesChanged({
        'data': this.allEntities[apiRoute],
        'count': this.allEntities[apiRoute].length,
        'apiRoute': apiRoute
      });

      return opts.success(this.allEntities[apiRoute]);
    }
  }

  fetchEntity(apiRoute: string, entityId: number, opts, filters?) {
    if (!apiRoute || !opts) {
      throw new Error('apiRoute or callbacks are not defined!');
    }

    const entityApiRoute = apiRoute + '/' + entityId;

    const timeout = opts.timeout || 0; // really important as ng2 blocks more requests

    if (!this.allEntities[entityApiRoute] || this.allEntities[entityApiRoute].length === 0) {
      let response = this.genericCrudService.getEntity(apiRoute, entityId, '', filters).pipe(
          tap((returnedEntity) => {
            this.allEntities[entityApiRoute] = returnedEntity;
            this.counts[entityApiRoute] = 1;
            return opts.success(this.allEntities[entityApiRoute]);
          }));

          setTimeout(function() { response.subscribe(); }, timeout);
    } else {
      return opts.success(this.allEntities[entityApiRoute]);
    }
  }

  setEntities(apiRoute: string, entities: Array<any>): this {
    this.allEntities[apiRoute] = entities;
    return this;
  }

  clearCacheForRoute(apiRoute: string): EntitySharingService {
    if (this.allEntities.hasOwnProperty(apiRoute)) {
      this.allEntities[apiRoute] = [];
    }
    return this;
  }

  public clear(): EntitySharingService {
    this.allEntities = {};
    this.emptyEntity = {};
    return this;
  }

  disableFilters(): EntitySharingService {
    this.genericCrudService.disableFilters();
    return this;
  }

  enableFilters(): EntitySharingService {
    this.genericCrudService.enableFilters();
    return this;
  }

  public entityExists(apiRoute: string, entity: any): boolean {
    return this.allEntities[apiRoute] && this.allEntities[apiRoute].findIndex(r => r.id === entity.id) !== -1;
  }

  public routeExists(apiRoute: string): boolean {
    return this.allEntities.hasOwnProperty(apiRoute);
  }

  public fetchNext(apiRoute: string, entity: any, callback: any) {
    apiRoute += '/next';

    this.genericCrudService.getEntity(apiRoute, entity.id).subscribe((entity) => {return callback(entity)});
  }

  public fetchPrevious(apiRoute: string, entity: any, callback: any) {
    apiRoute += '/previous';

    this.genericCrudService.getEntity(apiRoute, entity.id).subscribe((entity) => {return callback(entity)});
  }

  public fetchFirst(apiRoute: string, callback: any) {
    apiRoute += '/first';

    this.genericCrudService.getEntities(apiRoute).subscribe((entity) => {return callback(entity)});
  }

  public fetchLast(apiRoute: string, callback: any) {
    apiRoute += '/last';

    this.genericCrudService.getEntities(apiRoute).subscribe((entity) => {return callback(entity)});
  }
}
