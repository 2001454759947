import { Injectable } from '@angular/core';
import { AbstractFormActionHandler } from '../abstract-form-action-handler';
import { Element } from '../../../models';
import { AbstractControl } from '@angular/forms';
import { ComponentSelectedOptionAware } from '../../../element/abstract-element.component';

@Injectable()
export class HandlePhoneCountryCodeContactPersonActionHandler extends AbstractFormActionHandler {

    protected getPhoneCountryCodeDatamodelField(): string {
        return 'phoneCountryCode';
    }

    protected getPhoneAreaCodeDatamodelField(): string {
        return 'phoneAreaCode';
    }

    protected getPhoneNumberDatamodelField(): string {
        return 'phoneNumber';
    }

    protected getAddressDatamodelField(): string {
        return 'address';
    }

    protected isActionValid(): boolean {
        return this.getCountryPhoneElement() !== null && this.getCountryPhoneFormControl() !== null;
    }

    public handleAction(): void {
        if (this.isActionValid()) {

            const isChangingCodeOrNumber = this.isChangingPhoneAreaCode() || this.isChangingPhoneNumber();

            const codeOrNumberAreEmpty = !this.getEntity()[this.getPhoneAreaCodeDatamodelField()] ||
                !this.getEntity()[this.getPhoneNumberDatamodelField()];

            if (!this.isChangingPhoneCountry() && isChangingCodeOrNumber) {
                if (codeOrNumberAreEmpty) {
                    this.setPhoneCountryCodeValue('');
                } else {
                    this.setPhoneCountryFromAddress();
                }
            }
        }
    }

    private setPhoneCountryFromAddress(): void {
        const addressComponent = this.getAddressComponent();

        if (addressComponent && addressComponent.selectedOption && addressComponent.selectedOption.entity) {
            let country = addressComponent.selectedOption.entity.country;

            if (!country && addressComponent.selectedOption.entity._embedded && addressComponent.selectedOption.entity._embedded.country) {
                country = addressComponent.selectedOption.entity._embedded.country;
            }

            if (country) {
              this.setPhoneCountryCodeValue(country.phonePrefix);
            }
        }
    }

    private setPhoneCountryCodeValue(value: any): void {
        this.getCountryPhoneElement().setValue(value);
        this.getCountryPhoneFormControl().setValue(value);
        this.getEntity()[this.getPhoneCountryCodeDatamodelField()] = value;

        const element = this.getCountryPhoneElement(),
          formControlName = element ? element.datamodelField + '_h_r_f_e_' + element.objectHashId : '';

        this.formService.onFormElementValueChange({
            formControlValue: value,
            element: this.getCountryPhoneElement(),
            entityValue: value,
            formControlName: formControlName,
            formControlOptions: {},
            triggerChange: false,
            entity: this.getEntity(),
            component: null,
            updateFormComponent: false
        });
    }

    private isChangingPhoneCountry(): boolean {
        return this.getElement().datamodelField === this.getPhoneCountryCodeDatamodelField();
    }

    private isChangingPhoneAreaCode(): boolean {
        return this.getElement().datamodelField === this.getPhoneAreaCodeDatamodelField();
    }

    private isChangingPhoneNumber(): boolean {
        return this.getElement().datamodelField === this.getPhoneNumberDatamodelField();
    }

    private getCountryPhoneElement(): Element|null {
        return this.formService.getElementBy(this.getForm(), 'datamodelField', this.getPhoneCountryCodeDatamodelField()) || null;
    }

    private getAddressElement(): Element|null {
        return this.formService.getElementBy(this.getForm(), 'datamodelField', this.getAddressDatamodelField()) || null;
    }

    private getCountryPhoneFormControl(): AbstractControl {
        const element = this.getCountryPhoneElement();

        let control = null;

        if (null !== element) {
            control = this.getFormGroup().get(element.datamodelField + '_h_r_f_e_' + element.objectHashId);
        }

        return control;
    }

    private getAddressComponent(): ComponentSelectedOptionAware|null {
        const element = this.getAddressElement();

        let component = null;

        if (null !== element) {
            component = this.formService.getComponent(element.datamodelField + '_h_r_f_e_' + element.objectHashId);
        }

        return component;
    }
}
