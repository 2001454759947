import {of as observableOf, Observable} from 'rxjs';
import {AbstractExecutionStep} from '../../../core/executor/abstract-execution-step';
import {ExecutionStepStatus} from '../../../core/executor/execution-step-status';
import {FormViewerComponent} from '../../form-viewer/form-viewer.component';
import {FormService} from '../../form-viewer/form.service';
import {ChangeDetectorRefHelper} from '../../helpers/change-detector-ref.helper';
import {GenericDialogModuleService} from '../../content-renderer/elements/generic-dialog/service/generic-dialog-module.service';
import {EntityStatus} from '../entity/entity-status';
import {EntityManagerService} from '../../../core/service/entity-manager/entity-manager.service';
import {AbstractGenericGridComponent} from '../../content-renderer/elements/abstract-generic-grid.component';

export class SetDefaultTextToMasterFormElementExecutionStep extends AbstractExecutionStep {

  public doExecute(): Observable<ExecutionStepStatus> {
    const payload = this.getPayload();

    if (!(payload.getValue() instanceof AbstractGenericGridComponent)) {
      return this.getFailObservable('You need to pass AbstractGenericGridComponent as Payload value!');
    }

    return this.doSet(payload.getValue());
  }

  protected doSet(component: AbstractGenericGridComponent): Observable<ExecutionStepStatus> {
    const form: FormViewerComponent = component.getElementContext().getMasterElementContext().component,
      selectedEntities: {text: string}[] = component.selectedEntities.length > 0 ? component.selectedEntities : [component.getSelectedEntity()];

    if (form) {
      const formElement = component.additional && component.additional.element ?
        component.additional.element :
        this.injector.get(FormService).getElementByMany(form.form, {
          datamodelField: component.masterEntityEditingField
        });

      if (formElement) {
        const entity = form.getSelectedEntity();
        const text = selectedEntities.map((aEntity) => {
          return aEntity.text
        }).join('\r\n');

        const textAreaElement = this.injector.get(FormService).getElementByMany(form.form, {
          typeElement: 'textarea',
          datamodelField: formElement.datamodelField
        });
        this.injector.get(EntityManagerService, null).persistMore(entity, [
          {property: EntityStatus.ENTITY_CHANGED_FLAG, newValue: true, force: true},
          {property: textAreaElement.datamodelField, newValue: (textAreaElement.value || "") + text, force: true}
        ]);
        form.setEntity({...entity});
        form.recheckToolbarItems();
        ChangeDetectorRefHelper.detectChanges(form);
      }
    }

    this.injector.get(GenericDialogModuleService, null).persistHide();
    return observableOf({status: true, content: form});
  }
}
