
import {map} from 'rxjs/operators';
import {ChangeDetectionStrategy, Component} from '@angular/core';
import {ToolbarItemAbstract} from '../toolbar-item-abstract.component';
import {GenericCrudService} from '../../../../../../services/generic-crud.service';
import {MessageGrowlService} from '../../../../../../../core/message/message-growl.service';
import {TranslateService} from '@ngx-translate/core';
import {Observable} from 'rxjs';
import {AbstractGenericGridComponent} from '../../../../abstract-generic-grid.component';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-toolbar-item-import-cloud-printer',
  styleUrls: ['./toolbar-item-import-cloud-printer.component.scss'],
  templateUrl: './toolbar-item-import-cloud-printer.component.html'
})
export class ToolbarItemImportCloudPrinterComponent extends ToolbarItemAbstract {

  constructor(
    protected genericCrudService: GenericCrudService,
    protected messageGrowlService: MessageGrowlService,
    protected translate: TranslateService
  ) {
    super();
  }

  public click() {
    const component = this.getComponent();

    if (component instanceof AbstractGenericGridComponent) {
      this.importPrinters().subscribe((printers) => {
        component.loadEntities().subscribe();

        this.messageGrowlService.showDataSaved();
      });
    }
  }

  public importPrinters(): Observable<any[]> {
    return this.genericCrudService.createEntities('phoenix/cloudprinters/import', []).pipe(map((printers) => {
      return printers;
    }));
  }
}
