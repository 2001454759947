
import {of as observableOf, forkJoin as observableForkJoin, Observable} from 'rxjs';

import {catchError} from 'rxjs/operators';
import {AbstractJob} from '../../../../core/job-runner/job/abstract.job';
import {JobInterface} from '../../../../core/job-runner/job/job.interface';
import {JobExecutionStatus} from '../../../../core/job-runner/job/job-execution-status';
import {ConditionInterface} from '../../../../core/job-runner/condition/condition.interface';
import {JobContext} from '../../../../core/job-runner/context/job.context';
import {GenericToolbarComponent} from '../../../content-renderer/elements/generic-toolbar/generic-toolbar.component';
import {Entity} from '../../../helpers/entity';
import {AbstractGenericGridComponent} from '../../../content-renderer/elements/abstract-generic-grid.component';
import {ToolbarItem} from '../../../services/element/toolbar-item';
import {GenericCrudService} from '../../../services/generic-crud.service';
import {OfferState} from '../../../../core/inquiry/offer/offer-state';
import {OrderState} from '../../../../core/inquiry/order/order-state';

export class SetColorToInquiryPositionGridToolbarItemsJob extends AbstractJob implements JobInterface {

  private static readonly OFFER_TOOLBAR_ITEM_NAME = 'Angebot';
  private static readonly ORDER_TOOLBAR_ITEM_NAME = 'Auftrag';

  private genericCrudService: GenericCrudService = null;

  run(context: JobContext, condition: ConditionInterface): Observable<JobExecutionStatus> {
    return Observable.create((observer) => {
      const toolbarComponent = context.component;

      if (toolbarComponent instanceof GenericToolbarComponent) {
        const gridComponent = toolbarComponent.getAttachedComponent();

        this.genericCrudService = gridComponent.getGenericCrudService();

        this.setDefaultColors(toolbarComponent);

        this.getOfferStates(this.getGridEntities(gridComponent)).subscribe((offerStates: any[] = []) => {
          this.colorOfferToolbarItem(toolbarComponent, offerStates);
        });

        this.getOrderStates(this.getGridEntities(gridComponent)).subscribe((offerStates: any[] = []) => {
          this.colorOrderToolbarItem(toolbarComponent, offerStates);
        });
      }

      observer.next({
        status: true,
        content: null,
        jobId: this.jobId
      });
    });
  }

  private setDefaultColors(toolbarComponent: GenericToolbarComponent): void {
    const offerToolbarItem = this.getToolbarItemByName(
      SetColorToInquiryPositionGridToolbarItemsJob.OFFER_TOOLBAR_ITEM_NAME, toolbarComponent
    ), orderToolbarItem = this.getToolbarItemByName(
      SetColorToInquiryPositionGridToolbarItemsJob.ORDER_TOOLBAR_ITEM_NAME, toolbarComponent
    );

    if (offerToolbarItem) {
      offerToolbarItem.backgroundColor = 'red';
    }

    if (orderToolbarItem) {
      orderToolbarItem.backgroundColor = 'red';
    }
  }

  private colorOfferToolbarItem(toolbarComponent: GenericToolbarComponent, offerStates: any[] = []): void {
    const offerToolbarItem = this.getToolbarItemByName(
      SetColorToInquiryPositionGridToolbarItemsJob.OFFER_TOOLBAR_ITEM_NAME, toolbarComponent
    );

    if (offerToolbarItem) {
      const redColorOfferState = offerStates.find((aOfferState) => {
        return aOfferState && (aOfferState.code === OfferState.CODE_OFFEN || aOfferState.code === OfferState.CODE_ABGEL) &&
          aOfferState.cssColor;
      });
      const redColor = redColorOfferState ? redColorOfferState.cssColor : 'red';

      const yellowColorOfferState = offerStates.find((aOfferState) => {
        return aOfferState && aOfferState.code === OfferState.CODE_TEILVERH && aOfferState.cssColor;
      });
      const yellowColor = yellowColorOfferState ? yellowColorOfferState.cssColor : 'yellow';

      const greenColorOfferState = offerStates.find((aOfferState) => {
        return aOfferState && aOfferState.code === OfferState.CODE_VERH && aOfferState.cssColor;
      });
      const greenColor = greenColorOfferState ? greenColorOfferState.cssColor : 'green';

      let color = redColor;

      const areAllGreen = offerStates.length > 0 && this.areAllOfferStatesGreen(offerStates);

      if (areAllGreen) {
        color = greenColor;
      } else {
        for (const entity of offerStates) {
          if (!entity) {
            color = redColor;
            break;
          }

          if (entity.code === OfferState.CODE_TEILVERH) {
            color = yellowColor;
            break;
          }
        }
      }

      offerToolbarItem.backgroundColor = color;
    }
  }

  private colorOrderToolbarItem(toolbarComponent: GenericToolbarComponent, orderStates: any[] = []): void {
    const orderToolbarItem = this.getToolbarItemByName(
      SetColorToInquiryPositionGridToolbarItemsJob.ORDER_TOOLBAR_ITEM_NAME, toolbarComponent
    );

    if (orderToolbarItem) {
      const redColorOrderState = orderStates.find((aOrderState) => {
        return aOrderState && (aOrderState.code === OrderState.CODE_OFFEN || aOrderState.code === OrderState.CODE_VERF) &&
          aOrderState.cssColor;
      });
      const redColor = redColorOrderState ? redColorOrderState.cssColor : 'red';

      const yellowColorOrderState = orderStates.find((aOrderState) => {
        return aOrderState && aOrderState.code === OrderState.CODE_TWOFF && aOrderState.cssColor;
      });
      const yellowColor = yellowColorOrderState ? yellowColorOrderState.cssColor : 'yellow';

      const greenColorOrderState = orderStates.find((aOrderState) => {
        return aOrderState && aOrderState.code === OrderState.CODE_ERL && aOrderState.cssColor;
      });
      const greenColor = greenColorOrderState ? greenColorOrderState.cssColor : 'green';

      let color = redColor;

      const areAllGreen = orderStates.length > 0 && this.areAllOrderStatesGreen(orderStates);

      if (areAllGreen) {
        color = greenColor;
      } else {
        for (const entity of orderStates) {
          if (!entity) {
            color = redColor;
            break;
          }

          if (entity.code === OrderState.CODE_TWOFF) {
            color = yellowColor;
            break;
          }
        }
      }

      orderToolbarItem.backgroundColor = color;
    }
  }

  private areAllOfferStatesGreen(entities: any[] = []): boolean {
    let areAllGreen = true;

    for (const entity of entities) {
      if (!entity || entity.code !== OfferState.CODE_VERH) {
        areAllGreen = false;
        break;
      }
    }

    return areAllGreen;
  }

  private areAllOrderStatesGreen(entities: any[] = []): boolean {
    let areAllGreen = true;

    for (const entity of entities) {
      if (!entity || entity.code !== OrderState.CODE_ERL) {
        areAllGreen = false;
        break;
      }
    }

    return areAllGreen;
  }

  private getGridEntities(gridComponent: any): any[] {
    if (gridComponent instanceof AbstractGenericGridComponent) {
      return gridComponent.getEntities();
    }

    return [];
  }

  private getOfferStates(entities: any[] = []): Observable<any[]> {
    return this.getInquiryPositionsSubEntities(entities, 'phoenix/offers', 'offer').map((offers) => {
      const offerStates = [];

      for (const offer of offers) {
        let offerState = null;

        if (offer) {
          offerState = Entity.getValue(offer, 'offerState') || Entity.getValueInEmbedded(offer, 'offerState');
        }

        offerStates.push(offerState);
      }

      return offerStates;
    });
  }

  private getOrderStates(entities: any[] = []): Observable<any[]> {
    return this.getInquiryPositionsSubEntities(entities, 'phoenix/orders', 'order').map((orders) => {
      const orderStates = [];

      for (const order of orders) {
        let orderState = null;

        if (order) {
          orderState = Entity.getValue(order, 'orderState') || Entity.getValueInEmbedded(order, 'orderState');
        }

        orderStates.push(orderState);
      }

      return orderStates;
    });
  }

  private getInquiryPositionsSubEntities(entities: any[] = [], apiRoute: string, subEntityProperty: string) {
    const observables = [],
      subEntities = [];

    for (const inquiryPosition of entities) {
      const subEntity = this.getInquiryPositionPreCalculationSubEntity(inquiryPosition, subEntityProperty);

      if (subEntity && subEntity.id) {
        observables.push(this.genericCrudService.getEntity(apiRoute, subEntity.id));
      } else {
        subEntities.push(subEntity);
      }
    }

    return Observable.create((observer) => {

      if (observables.length === 0) {
        observer.next(subEntities);
        observer.complete();
      }

      observableForkJoin(observables).pipe(
        catchError((response: any) => {
          return observableOf(response);
        }))
        .subscribe(createdSubEntities => {
          for (const createdSubEntity of createdSubEntities) {
            subEntities.push(createdSubEntity);
          }

          observer.next(subEntities);
          observer.complete();
        });
    });
  }

  private getToolbarItemByName(toolbarItemName: string, toolbarComponent: GenericToolbarComponent): ToolbarItem|null {
    let foundToolbarItem = null;

    for (const toolbarItem of toolbarComponent.items) {
      if (toolbarItem.name === toolbarItemName) {
        foundToolbarItem = toolbarItem;
        break;
      }
    }

    return foundToolbarItem;
  }

  private getOffer(inquiryPosition: any): any|null {
    return this.getInquiryPositionPreCalculationSubEntity(inquiryPosition, 'offer');
  }

  private getOrder(inquiryPosition: any): any|null {
    return this.getInquiryPositionPreCalculationSubEntity(inquiryPosition, 'order');
  }

  private getInquiryPositionPreCalculationSubEntity(inquiryPosition: any, subEntityProperty: string): any|null {
    const preCalculation = Entity.getValue(inquiryPosition, 'preCalculation') ||
      Entity.getValueInEmbedded(inquiryPosition, 'preCalculation');

    if (preCalculation !== null) {
      return Entity.getValue(preCalculation, subEntityProperty) || Entity.getValueInEmbedded(preCalculation, subEntityProperty);
    }

    return null;
  }
}
