/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./field-action-open-element.component";
import * as i2 from "../../../../../services/generic-crud.service";
import * as i3 from "../../../../services/double-click.service";
var styles_FieldActionOpenElementComponent = [];
var RenderType_FieldActionOpenElementComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_FieldActionOpenElementComponent, data: {} });
export { RenderType_FieldActionOpenElementComponent as RenderType_FieldActionOpenElementComponent };
export function View_FieldActionOpenElementComponent_0(_l) { return i0.ɵvid(0, [], null, null); }
export function View_FieldActionOpenElementComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "field-action-open-element", [], null, null, null, View_FieldActionOpenElementComponent_0, RenderType_FieldActionOpenElementComponent)), i0.ɵdid(1, 245760, null, 0, i1.FieldActionOpenElementComponent, [i2.GenericCrudService, i3.DoubleClickService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var FieldActionOpenElementComponentNgFactory = i0.ɵccf("field-action-open-element", i1.FieldActionOpenElementComponent, View_FieldActionOpenElementComponent_Host_0, { action: "action", field: "field", grid: "grid" }, {}, []);
export { FieldActionOpenElementComponentNgFactory as FieldActionOpenElementComponentNgFactory };
