/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./staff-cockpit-user-birthday.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../generic-loading/generic-loading.component.ngfactory";
import * as i3 from "../../../generic-loading/generic-loading.component";
import * as i4 from "../../../../../dynamic-table/dynamic-table.component.ngfactory";
import * as i5 from "../../../../../dynamic-table/dynamic-table.component";
import * as i6 from "@angular/common";
import * as i7 from "../services/cockpit-user-birthday.service";
import * as i8 from "../../../../../../core/service/user-session.service";
import * as i9 from "../../../../../../core/authentication/authentication.service";
import * as i10 from "../../../../../services/generic-crud.service";
import * as i11 from "../../../../../services/location.service";
import * as i12 from "../../../../services/double-click.service";
import * as i13 from "../../../../services/component-highlight-stack.service";
import * as i14 from "./staff-cockpit-user-birthday.component";
var styles_StaffCockpitUserBirthdayComponent = [i0.styles];
var RenderType_StaffCockpitUserBirthdayComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_StaffCockpitUserBirthdayComponent, data: {} });
export { RenderType_StaffCockpitUserBirthdayComponent as RenderType_StaffCockpitUserBirthdayComponent };
function View_StaffCockpitUserBirthdayComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-generic-loading", [], null, null, null, i2.View_GenericLoadingComponent_0, i2.RenderType_GenericLoadingComponent)), i1.ɵdid(1, 114688, null, 0, i3.GenericLoadingComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
function View_StaffCockpitUserBirthdayComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "shared-dynamic-table", [], null, [["window", "keyup"]], function (_v, en, $event) { var ad = true; if (("window:keyup" === en)) {
        var pd_0 = (i1.ɵnov(_v, 2).onKeyUp($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i4.View_DynamicTableComponent_0, i4.RenderType_DynamicTableComponent)), i1.ɵdid(2, 245760, null, 0, i5.DynamicTableComponent, [i1.ElementRef, i1.ChangeDetectorRef], { header: [0, "header"], columns: [1, "columns"], showRows: [2, "showRows"], entities: [3, "entities"], totalCount: [4, "totalCount"], paginator: [5, "paginator"], isLoadingData: [6, "isLoadingData"], showFilters: [7, "showFilters"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.header; var currVal_1 = _co.columns; var currVal_2 = false; var currVal_3 = _co.data; var currVal_4 = _co.data.length; var currVal_5 = true; var currVal_6 = _co.isLoading; var currVal_7 = false; _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7); }, null); }
function View_StaffCockpitUserBirthdayComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["style", "margin-top: 10px"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "shared-dynamic-table", [], null, [["window", "keyup"]], function (_v, en, $event) { var ad = true; if (("window:keyup" === en)) {
        var pd_0 = (i1.ɵnov(_v, 2).onKeyUp($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i4.View_DynamicTableComponent_0, i4.RenderType_DynamicTableComponent)), i1.ɵdid(2, 245760, null, 0, i5.DynamicTableComponent, [i1.ElementRef, i1.ChangeDetectorRef], { header: [0, "header"], columns: [1, "columns"], showRows: [2, "showRows"], entities: [3, "entities"], totalCount: [4, "totalCount"], paginator: [5, "paginator"], isLoadingData: [6, "isLoadingData"], showFilters: [7, "showFilters"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.leasedEmployeeHeader; var currVal_1 = _co.leasedEmployeeColumns; var currVal_2 = false; var currVal_3 = _co.leasedEmployeeData; var currVal_4 = _co.leasedEmployeeData.length; var currVal_5 = true; var currVal_6 = _co.isLeasedEmployeeLoading; var currVal_7 = false; _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7); }, null); }
export function View_StaffCockpitUserBirthdayComponent_0(_l) { return i1.ɵvid(2, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_StaffCockpitUserBirthdayComponent_1)), i1.ɵdid(1, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_StaffCockpitUserBirthdayComponent_2)), i1.ɵdid(3, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_StaffCockpitUserBirthdayComponent_3)), i1.ɵdid(5, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.isLoading; _ck(_v, 1, 0, currVal_0); var currVal_1 = !_co.isLoading; _ck(_v, 3, 0, currVal_1); var currVal_2 = !_co.isLeasedEmployeeLoading; _ck(_v, 5, 0, currVal_2); }, null); }
export function View_StaffCockpitUserBirthdayComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "app-custom-staff-cockpit-user-birthday", [], null, null, null, View_StaffCockpitUserBirthdayComponent_0, RenderType_StaffCockpitUserBirthdayComponent)), i1.ɵprd(512, null, i7.CockpitUserBirthdayService, i7.CockpitUserBirthdayService, [i8.UserSessionService, i9.AuthenticationService, i10.GenericCrudService, i11.LocationService, i12.DoubleClickService, i13.ComponentService]), i1.ɵdid(2, 245760, null, 0, i14.StaffCockpitUserBirthdayComponent, [i7.CockpitUserBirthdayService, i1.ChangeDetectorRef], null, null)], function (_ck, _v) { _ck(_v, 2, 0); }, null); }
var StaffCockpitUserBirthdayComponentNgFactory = i1.ɵccf("app-custom-staff-cockpit-user-birthday", i14.StaffCockpitUserBirthdayComponent, View_StaffCockpitUserBirthdayComponent_Host_0, {}, {}, []);
export { StaffCockpitUserBirthdayComponentNgFactory as StaffCockpitUserBirthdayComponentNgFactory };
