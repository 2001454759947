
import {throwError as observableThrowError, Observable} from 'rxjs';

import {map, catchError} from 'rxjs/operators';
import {Component, OnInit} from '@angular/core';
import {AbstractEditExpertSearchFieldValue} from '../abstract-edit-expert-search-field-value';
import {Datamodel} from '../../../../../../../../../services/datamodel/datamodel';
import {FieldMetadataGrid} from '../../../../../../../../../services/module/module-element-field-metadata-grid';
import {GenericCrudService} from '../../../../../../../../../services/generic-crud.service';
import DisplayConfig = App.Form.Element.DisplayConfig;
import {EntityStatus} from '../../../../../../../../../services/entity/entity-status';

interface DatamodelParamValue {
  id: number;
  apiRoute: string;
}

const DATAMODEL_PARAM_KEY = 'datamodel';
const DISPLAY_CONFIG_PARAM_KEY = 'displayConfiguration';

@Component({
  selector: 'app-search-management-edit-autocomplete-edit-expert-search-field-value',
  templateUrl: './autocomplete-edit-expert-search-field-value.component.html',
  styles: [`
    :host {
      height: 100%;
    }
    
    .container {
      height: 100%;
      overflow: auto;
    }
  `]
})
export class SearchManagementAutocompleteEditExpertSearchFieldValueComponent extends AbstractEditExpertSearchFieldValue implements OnInit {

  public query = '';

  public selectedDatamodelOption: SelectItem = null;
  public dropdownOptions: SelectItem[] = [];
  public selectedFieldValue: SelectItem = null;

  public constructor(
    private readonly genericCrudService: GenericCrudService
  ) {
    super();
  }

  public ngOnInit(): void {
    if (this.field && this.field[EntityStatus.ENTITY_DRAFT_FLAG]) {
      this.initEdit();
    }
  }

  public onDatamodelOptionChanged(datamodel: Datamodel): void {
    this.setParameter(DATAMODEL_PARAM_KEY, {
      id: datamodel.id,
      apiRoute: datamodel.apiRoute
    });

    this.setParameter(DISPLAY_CONFIG_PARAM_KEY, []);
  }

  public onDisplayConfigSave(displayConfig: DisplayConfig[] = []): void {
    this.setParameter(DISPLAY_CONFIG_PARAM_KEY, displayConfig);

    this.loadDropdownOptions()
      .subscribe((options: SelectItem[] = []) => {
        this.dropdownOptions = options;

        if (this.selectedFieldValue) {
          this.changeFieldValue(this.selectedFieldValue);
        }

        this.selectFieldValue();
      });
  }

  public onCompleteMethod(event: {query: string}): void {
    this.query = event.query;

    this.loadDropdownOptions()
      .subscribe((options: SelectItem[] = []) => {
        this.dropdownOptions = options;
      });
  }

  public onAutoCompleteValueSelected(event: any): void {
    this.changeFieldValue(this.selectedFieldValue);
  }

  protected changeFieldValue(fieldValue): void {
    const label = FieldMetadataGrid.getOptionLabel(
      fieldValue.value,
      { name: 'name', displayConfig: this.getParameterValue(DISPLAY_CONFIG_PARAM_KEY)
      }),
      value = {
        id: fieldValue.value.id,
        label: label
      };

    this.onChange.emit({
      value: JSON.stringify(value),
      labelValue: label
    });
  }

  protected selectFieldValue(): void {
    const value = this.field.fieldValue ? JSON.parse(this.field.fieldValue) : null;

    if (value) {
      this.addOption({
        value: {
          id: value.id
        },
        label: value.label
      });

      for (const option of this.dropdownOptions) {
        if (option.value.id === +value.id) {
          this.selectedFieldValue = option;
        }
      }
    }
  }

  protected addOption(option: SelectItem): void {
    const index = this.dropdownOptions.findIndex((aOption: SelectItem) => {
      return aOption.value.id === option.value.id;
    });

    if (index === -1) {
      this.dropdownOptions = [...this.dropdownOptions, option];
    }
  }

  protected loadDropdownOptions(): Observable<SelectItem[]> {
    const datamodel = this.getParameterValue(DATAMODEL_PARAM_KEY),
      apiRoute = datamodel.apiRoute + `/offset/0/limit/50/orderby/id/asc?search=${this.query}`,
      requestParams = {clause: 'orWhere'};

    return this.genericCrudService.getEntities(apiRoute, '', requestParams).pipe(
      catchError((response) => {
        return observableThrowError(response);
      }),
      map((dmRecords: any) => {
        const options = [],
          records = (dmRecords.data) ? dmRecords.data : dmRecords;

        for (const record of records) {
          options.push({
            label: FieldMetadataGrid.getOptionLabel(
              record,
              { name: 'name', displayConfig: this.getParameterValue(DISPLAY_CONFIG_PARAM_KEY) }
            ),
            value: record
          });
        }

        return options;
      }),);
  }

  private initEdit(): void {
    const datamodel: DatamodelParamValue = this.getParameterValue(DATAMODEL_PARAM_KEY);

    if (datamodel && datamodel.id) {
      this.selectedDatamodelOption = {value: { id : datamodel.id }, label: ''};

      this.loadDropdownOptions()
        .subscribe((options: SelectItem[] = []) => {
          this.dropdownOptions = options;

          this.selectFieldValue();
        });
    }
  }
}
