/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./generic-loading.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./generic-loading.component";
var styles_GenericLoadingComponent = [i0.styles];
var RenderType_GenericLoadingComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_GenericLoadingComponent, data: {} });
export { RenderType_GenericLoadingComponent as RenderType_GenericLoadingComponent };
export function View_GenericLoadingComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "loading-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "div", [["class", "loading"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 2, "div", [["class", "spinner"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "div", [["class", "mask"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 0, "div", [["class", "maskedCircle"]], null, null, null, null, null))], null, null); }
export function View_GenericLoadingComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-generic-loading", [], null, null, null, View_GenericLoadingComponent_0, RenderType_GenericLoadingComponent)), i1.ɵdid(1, 114688, null, 0, i2.GenericLoadingComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var GenericLoadingComponentNgFactory = i1.ɵccf("app-generic-loading", i2.GenericLoadingComponent, View_GenericLoadingComponent_Host_0, {}, {}, []);
export { GenericLoadingComponentNgFactory as GenericLoadingComponentNgFactory };
