export const COMMAND_SYSTEM_OPTIONS = [
  'env',
  'format',
  'help',
  'ansi',
  'no-ansi',
  'debug',
  'no-debug',
  'interaction',
  'no-interaction',
  'quiet',
  'no-quiet',
  'verbose',
  'version'
];

export class CommandArgument {
  default: string;
  description: string;
  is_array: boolean;
  is_required: boolean;
  name: string;
  value: any;
}

export class CommandOption {
  default?: string;
  description?: string;
  is_array?: boolean;
  is_value_required: boolean;
  name: string;
  value: any;
}

export class CommandDefinition {
  arguments: { [name: string]: CommandArgument} = {};
  options: { [name: string]: CommandOption} = {};
}

export class Command {
  name: string;
  usage: string[];
  description: string;
  definition: CommandDefinition;

  public static getNamespace(command: Command): string {
    let namespaceName = command.name;

    if (command.name.indexOf(':') !== -1) {
      namespaceName = command.name.split(':')[0];
    }

    return namespaceName;
  }
}
