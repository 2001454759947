
import {of as observableOf, Observable} from 'rxjs';

import {map, switchMap} from 'rxjs/operators';
import {ExecutionStepStatus} from "../../../../../core/executor/execution-step-status";
import {WizardComponent} from "../../../../content-renderer/elements/custom/wizard/wizard.component";
import {WizardElement} from "../../../element/wizard";
import {WizardElementDetails} from "../../../../content-renderer/elements/custom/wizard/service/wizard.service";
import {WizardPrecalculationFinishExecutionStep} from "../inquiry/inquiry-position/wizard-precalculation-finish-execution-step";
import {ElementType} from '../../../../content-renderer/services/ElementContext';
import {ToolbarItemSavePrecalculationArticleService} from '../../../../content-renderer/elements/generic-toolbar/services/save/toolbar-item-save-precalculation-article.service';

export class WizardPrecalculationFinishInCustomerDetailsExecutionStep extends WizardPrecalculationFinishExecutionStep {

  public doExecute(): Observable<ExecutionStepStatus> {
    const payloadValue = this.getPayload().getValue();

    const wizardComponent = payloadValue.wizardComponent,
      wizardElement = payloadValue.wizardElement;

    if (!(wizardComponent instanceof WizardComponent)) {
      return this.getFailObservable('You need to pass WizardComponent as Payload value!');
    }

    return this.doSet(wizardComponent, wizardElement);
  }

  protected doSet(wizardComponent: WizardComponent, wizardElement: WizardElement): Observable<ExecutionStepStatus> {
    const elementDetails: WizardElementDetails = wizardComponent.wizardService.getElementDetails(wizardElement);

    const preCalculation = elementDetails.inputDetails.entity;

    if (preCalculation ) {
      return this.savePreCalculation(preCalculation).pipe(
          switchMap((savedInquiryPosition) => {
            let tree = null;
            let grid = null;
            for (const component of elementDetails.moduleState.getComponents()) {
              if (component.getElementContext() && component.getElementContext().type === ElementType.Tree) {
                tree = component;
              }

              if (component.getElementContext() && component.getElementContext().type === ElementType.Grid) {
                grid = component;
              }
            }

            const saveService = this.injector.get(ToolbarItemSavePrecalculationArticleService);

            return saveService.save(grid, tree).pipe(
              map(() => {
                this.getGrowl().success(
                  this.getTranslate().instant('COMMON.DATA_SAVED'),
                  this.getTranslate().instant('COMMON.SUCCESS')
                );

                this.getDialog().persistHide();

                return {status: true, content: null};
              }));
        }));
    }

    return observableOf({status: true, content: null});
  }
}
