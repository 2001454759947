import {ChangeDetectionStrategy, Component, Input, OnInit} from '@angular/core';
import { ToolbarItemAbstract } from '../toolbar-item-abstract.component';
import { MessageGrowlService } from '../../../../../../../core/message/message-growl.service';
import { MessageService } from '../../../../../../../core/message/message.service';
import { GenericCrudService } from '../../../../../../services/generic-crud.service';
import { ComponentService } from '../../../../../services/component-highlight-stack.service';
import { AbstractGenericGridComponent } from 'app/shared/content-renderer/elements/abstract-generic-grid.component';
import {EntityDataStoreService} from '../../../../../services/entity-data-store.service';
import {ModulesStateService} from '../../../../../services/modules-state.service';
import {ModuleState} from '../../../../../services/module-state';
import {GenericElementAbstract} from '../../../../generic-element-abstract.component';
import {GenericDialogModuleService} from '../../../../generic-dialog/service/generic-dialog-module.service';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'toolbar-item-import-multiselected-in-module-context',
  template: ''
})
export class ToolbarItemImportMultiselectedInModuleContext extends ToolbarItemAbstract {

  constructor(
    protected messageGrowlService: MessageGrowlService,
    protected messageService: MessageService,
    protected genericCrudService: GenericCrudService,
    private entityDataStore: EntityDataStoreService,
    protected genericDialogModuleService: GenericDialogModuleService,
    protected componentService: ComponentService,
    protected modulesStateService: ModulesStateService
  ) {
    super();
  }

  public click() {
    const component = this.getComponent();

    if (component instanceof GenericElementAbstract) {
      this.handleImportingMultiselectedEntitiesToMaster(component);
    }
  }

  private handleImportingMultiselectedEntitiesToMaster(component: GenericElementAbstract) {
    const moduleState = this.modulesStateService.getByComponent(component);
    for (let part of moduleState.parts){
      for (let partComponent of part.components){
        if(partComponent instanceof AbstractGenericGridComponent && partComponent.masterEntity && partComponent.masterEntityEditingField){

          partComponent.masterElementContext.selectedEntity[partComponent.masterEntityEditingField] = partComponent.selectedEntities;
          partComponent.masterElementContext.selectedEntity.isChanged = true;
          this.entityDataStore.onEntityChanged({
            entity: partComponent.masterElementContext.selectedEntity
          });
        }
      }
    }

    this.genericDialogModuleService.persistHide();
    this.removeModuleState(component);
  }

  private removeModuleState(component): void {
    const moduleState = this.modulesStateService.getByComponent(component);

    if (moduleState.isDialog && (
      moduleState.id === component.moduleElement.moduleId
      || this.componentIsPart(moduleState, component)
    )) {
      this.modulesStateService.remove(moduleState);
    }
  }

  private componentIsPart(moduleState: ModuleState, component: AbstractGenericGridComponent){
    return moduleState.parts.findIndex(part => {
      return part.id === component.moduleElement.moduleId;
    }) !== -1;
  }
}
