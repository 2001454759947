
import {of as observableOf,  Observable } from 'rxjs';

import {map} from 'rxjs/operators';
import { Component, OnInit, Input, ViewContainerRef, ViewChild, HostListener, ElementRef, AfterViewInit } from '@angular/core';
import { TreeNode } from 'primeng/primeng';

import { Datamodel } from './../../../services/datamodel/datamodel';
import { Element } from './../../../services/element/element';
import { GenericCrudService } from './../../../services/generic-crud.service';
import { ComponentService } from '../../services/component-highlight-stack.service';
import { EntityDataStoreService } from '../../services/entity-data-store.service';
import { ModuleElement } from "../../../services/module/module-element";
import { ModulesStateService } from '../../services/modules-state.service';
import { ExecutorService } from 'app/core/executor/executor.service';
import { GenericElementValidationExecutionStepsFactory } from 'app/shared/content-renderer/services/generic/generic-element-validation-execution-steps-factory';
import { EntityValidator, EntityValidatorStatus } from '../../../validators/services/entity-validator';
import { TableAware } from '../../../dynamic-table/shared/table-aware';
import { TableColumn } from '../../../dynamic-table/shared/table-column';
import { FieldMetadataGrid } from '../../../services/module/module-element-field-metadata-grid';
import { TableRow } from '../../../dynamic-table/shared/table-row';
import { DynamicTableComponent } from '../../../dynamic-table/dynamic-table.component';
import { ElementType } from '../../services/ElementContext';
import { GenericGridRemoteFilterService } from '../generic-grid/services/generic-grid-remote-filter.service';
import { GenericGridColumnBuilderService } from '../generic-grid/services/generic-grid-column-builder.service';
import { GenericGridBulkSaveService } from '../generic-grid/services/generic-grid-bulk-save.service';
import { GenericGridEntityService } from '../../services/generic/entity/generic-grid-entity.service';
import { GenericTurboGridLayoutService } from '../generic-turbo-grid/service/generic-turbo-grid-layout-service';
import { CancelComponentChangesService } from '../../services/cancel-components-changes.service';
import { GenericGridGlobalFilterService } from '../generic-grid/services/generic-grid-global-filter.service';
import { GenericElementInlineEditorService } from '../../services/generic/generic-element-inline-editor.service';
import { GenericElementFilterService } from '../../services/generic/filter/generic-element-filter.service';
import { DynamicTableColumn } from '../../services/dynamic-table-column';
import { ExecutorActionEvent } from '../../../../core/executor/service/executor-actions/executor-action-event';
import { ExecutionStatus } from '../../../../core/executor/execution-status';
import { ElementSaveStatus } from 'app/shared/content-renderer/elements/generic-element-abstract.component';
import {GenericTurboGridComponent} from '../generic-turbo-grid/generic-turbo-grid.component';

@Component({
    selector: 'app-generic-dynamic-table',
    styleUrls: ['./generic-dynamic-table.component.scss'],
    templateUrl: './generic-dynamic-table.component.html',
    providers: [
        CancelComponentChangesService,
        GenericGridRemoteFilterService,
        GenericGridGlobalFilterService,
        GenericGridColumnBuilderService,
        GenericGridBulkSaveService,
        GenericGridEntityService,
        GenericElementInlineEditorService,
        GenericElementFilterService,
        GenericElementValidationExecutionStepsFactory,
        GenericTurboGridLayoutService,
        ExecutorService
    ]
})
export class GenericDynamicTableComponent extends GenericTurboGridComponent implements TableAware {
    @Input() element: Element = null;
    @Input() toolbarItems: any[] = [];
    @Input() statusBarItems: any[] = [];
    @Input() moduleElement: ModuleElement;
    @Input() masterEntity: any = null;
    @Input() masterField: any = null;
    @Input() isPart: boolean = false;
    @Input() fields: Array<FieldMetadataGrid>;

    public elementType: ElementType = ElementType.DynamicGrid;

    @ViewChild(DynamicTableComponent, {static: false}) table: DynamicTableComponent;

    @Input() filters: Object = new Object();
    public totalCount: number = 0;
    public isLoadingData: boolean = false;

    public entities: any[] = [];
    public columns: DynamicTableColumn[] = [];
    public rows: TableRow[] = [];

    protected toolbarContextName = 'dynamicTableComponent';

    public onLazyLoad(event): void {
        this.subscriptions.push(
            this.loadData(event).subscribe()
        );
    }

    public getTable(): DynamicTableComponent {
        return this.table;
    }

    public onFilter(event): void {
        console.log(event);
    }

    public onValidate(): Observable<EntityValidatorStatus> {
        return observableOf({
            entity: null,
            isValid: true,
            error: '',
            errorFields: []
        });
    }

    public onCellEdit(event: any): void {
        const column = event.column,
            entity = event.entity,
            originalEvent = event.originalEvent;

        this.executorService
            .fire(this.moduleElement.id, ExecutorActionEvent.DynamicTableCellEdit, {
                component: this,
                column: column,
                entity: entity,
                event: originalEvent
            })
            .subscribe((status: ExecutionStatus) => {
                this.recheckToolbarItems();
            });
    }

    public onRefresh(): Observable<any> {
        return this.executorService
            .fire(this.moduleElement.id, ExecutorActionEvent.EntitiesRefresh, this).pipe(
            map((status: ExecutionStatus) => {
                this.recheckToolbarItems();

                return {status: status.getStepStatus(), content: status.getStepContent(), message: null}
            }));
    }

    public onSave(): Observable<ElementSaveStatus> {
        return this.executorService
            .fire(this.moduleElement.id, ExecutorActionEvent.DynamicTableSave, this).pipe(
            map((status: ExecutionStatus) => {
                this.recheckToolbarItems();

                return {status: status.getStepStatus(), content: status.getStepContent(), message: null}
            }));
    }

    protected loadData(event: any): Observable<any> {
        this.setGridScrollHeightAndWidth();

        return observableOf(true);
    }

    protected setGridScrollHeightAndWidth(): void {
        const dynamicTable = this.getTable();

        if (this.element && this._gridContainer && dynamicTable) { 
            let containerHeight = this._gridContainer.nativeElement.clientHeight;

            console.log(containerHeight);

            if (this.element.toolbarItems && this.element.toolbarItems.length > 0) {
                containerHeight -= 30;
            }
    
            // header, footer, use calculation
            this.getTable().height = containerHeight;
        }
    }
}
