
    <p-dropdown
        *ngIf="isEditMode()"
        [(ngModel)]="entity.comparator"
        [options]="comparatorsOptions"
        placeholder="---"
        (onChange)="onComparatorChange()"
        [style]="{'width': '100%'}"
        appendTo="body"
    ></p-dropdown>

    <div *ngIf="!isEditMode()">
      {{ entity.comparator }}
    </div>
  