
import {of as observableOf,  Observable } from 'rxjs';
import { AbstractExecutionStep } from '../../../../core/executor/abstract-execution-step';
import { ExecutionStepStatus } from '../../../../core/executor/execution-step-status';
import { AbstractGenericGridComponent } from '../../../content-renderer/elements/abstract-generic-grid.component';
import {ComponentValidationExecutionStep} from "../validation/component-validation-execution-step";
import {EntityValidationExecutionStep} from "../validation/entity-validation-execution-step";
import {Debounce} from "../../../helpers/debounce";

export class DisplayFailedValidationMessageStep extends AbstractExecutionStep {

  public doExecute(): Observable<ExecutionStepStatus> {
    const payload = this.getPayload();

    if (!(payload.getValue().component instanceof AbstractGenericGridComponent)) {
        return this.getFailObservable('You need to pass AbstractGenericGridComponent as Payload value!');
    }

    let status = payload.getValue().entityValidationStatus;

    if (status && !status.isSuccess()) {

      if (status.getStep() instanceof ComponentValidationExecutionStep ||
        status.getStep() instanceof EntityValidationExecutionStep
      ) {
          payload.getValue().component.messageGrowlService.info(status.getStepContent());
      }
    }

    return observableOf({status: true, content: []});
  }

}
