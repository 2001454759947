
    <div class="ui-g">
        <div class="ui-g-4">
          {{label}}:
        </div>
        <div class="ui-g-8">
          <p-autoComplete 
            appendTo="body"
            [(ngModel)]="brand" 
            [suggestions]="options" 
            (completeMethod)="onSearch($event)" 
            [size]="30"
            [style]="{'width':'100%'}" 
            [minLength]="1" 
            placeholder="---" 
            [dropdown]="true"
            field="label"
          >
          </p-autoComplete>
        </div>
    </div>
  