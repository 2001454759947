import {ChangeDetectorRef, Component, ViewChild} from '@angular/core';
import {MenuItem} from 'primeng/api';
import {Observable, of} from 'rxjs';
import {AbstractUploadComponent} from '../abstract-upload.component';
import {FileUpload} from 'primeng/fileupload';
import {File, File as FileModel} from '../../../models/file';
import {ChangeDetectorRefHelper} from '../../../helpers/change-detector-ref.helper';
import {GenericCrudService} from '../../../services/generic-crud.service';
import {MessageGrowlService} from '../../../../core/message/message-growl.service';
import {TranslateService} from '@ngx-translate/core';
import {UserSessionService} from '../../../../core/service/user-session.service';

interface MenuItemExtended extends MenuItem {
  file: FileModel;
}

@Component({
  selector: 'app-multi-upload',
  templateUrl: './multi-upload.component.html',
  styleUrls: ['./multi-upload.component.scss']
})
export class MultiUploadComponent extends AbstractUploadComponent {

  @ViewChild(FileUpload, {static: false}) public fileUpload: FileUpload = null;
  public selectedFiles: any[] = [];
  public items: MenuItemExtended[] = [];
  public isUploadVisible = false;

  public constructor(
    public cdr: ChangeDetectorRef,
    protected genericCrudService: GenericCrudService,
    protected message: MessageGrowlService,
    protected translate: TranslateService,
    protected userSession: UserSessionService
  ) {
    super(cdr, genericCrudService, message, translate, userSession);
    this.accept = '.xls,.xlsx,.doc,.docx,.jpg,.png,.jpeg,.pdf,.txt,.tif,.tiff,.gif,.odt,.rtf,.csv,.xml,.ppt,.pptx,.eml';
  }

  public onOpenUpload(event): void {
    this.isUploadVisible = true;
  }

  public onDropdownClick(event): void {
    event.preventDefault();
    event.stopPropagation();
  }

  public onItemRemoved(menuItem: { file: File }): void {
    const index = this.items.findIndex((aItem: MenuItemExtended) => {
      return aItem.file.id === menuItem.file.id ||
        aItem.file.name === menuItem.file.name;
    })

    if (index !== -1) {
      this.items.splice(index, 1);
    }

    ChangeDetectorRefHelper.detectChanges(this);

    event.preventDefault();
    event.stopPropagation();
  }

  public onUpload(event: { files: File[] }): void {
    this.selectedFiles = event.files;

    this.upload().subscribe(() => {
      this.isUploadVisible = false;
    });
  }

  public onSelect(event: { files: File[] }): void {
    for (const file of event.files) {
      file.uploadedFileName = file.name;
    }
  }

  public onClear(): void {
    this.isUploadVisible = false;
  }

  public loadFiles(): Observable<any> {
    return of();
    // const params = {
    //     fileClassName: this.fileClassName,
    //     owner: this.fileEntity.id,
    //     context: this.fileContext
    //   };
    //
    // this.isLoading = true;
    //
    // return this.genericCrudService.getEntities('phoenix/files', '', params)
    //   .pipe(
    //     map((files: FileModel[]) => {
    //       this.items = [];
    //
    //       for (const file of files) {
    //
    //         this.items.push({
    //           label: file.name,
    //           icon: 'pi pi-file',
    //           command: () => {
    //             this.onItemDownload({file: file});
    //           },
    //           file
    //         });
    //       }
    //
    //       this.isLoading = false;
    //       this.cdr.detectChanges();
    //
    //       return files;
    //     })
    //   );
  }

  public getErrorMessage(): string {
    return this.translate.instant('FILE.TYPE_MESSAGE_SUMMARY');
  }

  public getErrorMessageDetail(): string {
    return this.translate.instant('FILE.TYPE_MESSAGE_DETAIL') + this.accept;
  }
}
