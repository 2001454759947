import {ChangeDetectionStrategy, Component} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {ToolbarItemAbstract} from '../toolbar-item-abstract.component';
import {ComponentService} from '../../../../../services/component-highlight-stack.service';
import {MessageGrowlService} from '../../../../../../../core/message/message-growl.service';
import {MessageService} from '../../../../../../../core/message/message.service';
import {GenericCrudService} from '../../../../../../services/generic-crud.service';
import {ApiBuilderService} from '../../../../../../services/api.builder.service';
import {EntityDataStoreService} from '../../../../../services/entity-data-store.service';
import {LoggerService} from 'app/shared/content-renderer/services/logger/logger.service';
import {AbstractGenericGridComponent} from 'app/shared/content-renderer/elements/abstract-generic-grid.component';
import {GenericElementAbstract} from 'app/shared/content-renderer/elements/generic-element-abstract.component';
import {ToolbarItemAfterDeleteService} from './service/toolbar-item-after-delete-service';
import {ExecutorService} from '../../../../../../../core/executor/executor.service';
import {TOAST_LIFE_INFINITE, ToastComponentsRegistry, ToastService} from '../../../../../../../core/service/toast.service';
import {CostCenterInvoiceSplitComponent} from '../../../../custom/cost-center-invoice-split/cost-center-invoice-split.component';
import {ToolbarItemDelete} from './toolbar-item-delete.component';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'toolbar-item-delete-from-all',
  templateUrl: './toolbar-item-delete.component.html',
  providers: [ToolbarItemAfterDeleteService, ExecutorService]
})
export class ToolbarItemDeleteFromAll extends ToolbarItemDelete {

  constructor(
    protected componentService: ComponentService,
    protected messageGrowlService: MessageGrowlService,
    protected messageService: MessageService,
    protected genericCrudService: GenericCrudService,
    protected apiBuilder: ApiBuilderService,
    protected translationService: TranslateService,
    protected entityDataStore: EntityDataStoreService,
    protected logger: LoggerService,
    protected toolbarItemAfterDeleteService: ToolbarItemAfterDeleteService,
    protected executorService: ExecutorService,
    protected toastService: ToastService,
    protected translateService: TranslateService
  ) {
    super(
      componentService, messageGrowlService, messageService, genericCrudService, apiBuilder,
      translateService, entityDataStore, logger, toolbarItemAfterDeleteService, executorService,
      toastService, translateService
    );
  }

  public click() {
    super.click();
  }
}
