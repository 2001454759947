/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./field-action-set-extra-param-from-one-multiselect-filter-to-another.component";
import * as i2 from "../../../../../services/generic-crud.service";
import * as i3 from "../../../../services/double-click.service";
var styles_FieldActionSetExtraParamFromOneMultiSelectFilterToAnotherComponent = [];
var RenderType_FieldActionSetExtraParamFromOneMultiSelectFilterToAnotherComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_FieldActionSetExtraParamFromOneMultiSelectFilterToAnotherComponent, data: {} });
export { RenderType_FieldActionSetExtraParamFromOneMultiSelectFilterToAnotherComponent as RenderType_FieldActionSetExtraParamFromOneMultiSelectFilterToAnotherComponent };
export function View_FieldActionSetExtraParamFromOneMultiSelectFilterToAnotherComponent_0(_l) { return i0.ɵvid(0, [], null, null); }
export function View_FieldActionSetExtraParamFromOneMultiSelectFilterToAnotherComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-field-action-set-extra-param-from-one-multiselect-to-another", [], null, null, null, View_FieldActionSetExtraParamFromOneMultiSelectFilterToAnotherComponent_0, RenderType_FieldActionSetExtraParamFromOneMultiSelectFilterToAnotherComponent)), i0.ɵdid(1, 245760, null, 0, i1.FieldActionSetExtraParamFromOneMultiSelectFilterToAnotherComponent, [i2.GenericCrudService, i3.DoubleClickService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var FieldActionSetExtraParamFromOneMultiSelectFilterToAnotherComponentNgFactory = i0.ɵccf("app-field-action-set-extra-param-from-one-multiselect-to-another", i1.FieldActionSetExtraParamFromOneMultiSelectFilterToAnotherComponent, View_FieldActionSetExtraParamFromOneMultiSelectFilterToAnotherComponent_Host_0, { action: "action", field: "field", grid: "grid" }, {}, []);
export { FieldActionSetExtraParamFromOneMultiSelectFilterToAnotherComponentNgFactory as FieldActionSetExtraParamFromOneMultiSelectFilterToAnotherComponentNgFactory };
