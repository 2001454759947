export class WorkHourCalculatorAbstract {

  private _entity: any = {};
  private _isIndustrialTime = false;

  constructor() {
  }

  get entity(): any {
    return this._entity;
  }

  set entity(value: any) {
    this._entity = value;
  }

  get isIndustrialTime(): boolean {
    return this._isIndustrialTime;
  }

  set isIndustrialTime(value: boolean) {
    this._isIndustrialTime = value;
  }

  public calculateWorkEnd(calculateBy: 'workLengthIndustry'|'workLength'): any {
    return this.entity;
  }

  public calculateFields(): any {
    return this.entity;
  }

  public calculateLength(startTime: string, endTime: string, breakDuration: string): string {
    const startParts = startTime.split(':'),
      startHours = parseInt(startParts[0]),
      startMinutes = parseInt(startParts[1]);

    const endParts = endTime.split(':'),
      endHours = parseInt(endParts[0]),
      endMinutes = parseInt(endParts[1]);

    const breakParts = breakDuration.split(':'),
      breakHours = parseInt(breakParts[0]),
      breakMinutes = parseInt(breakParts[1]);

    const today = new Date();
    const dummyDate = new Date(today.getFullYear(), today.getMonth(), today.getDate(), endHours, endMinutes);
    dummyDate.setMinutes(dummyDate.getMinutes() - breakMinutes - breakHours * 60 - startMinutes - startHours * 60);

    const hoursWithLeadingZero = dummyDate.getHours() < 10 ? `0${dummyDate.getHours()}` : dummyDate.getHours(),
      minutesWithLeadingZero = dummyDate.getMinutes() < 10 ? `0${dummyDate.getMinutes()}` : dummyDate.getMinutes();

    return `${hoursWithLeadingZero}:${minutesWithLeadingZero}`;
  }
}
