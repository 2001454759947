import {Injectable} from '@angular/core';
import {PreCalculation, PreCalculationArticle} from '../../pre-calculation-article';
import {AbstractPreCalculationArticleCalculatorService} from './abstract-pre-calculation-article-calculator.service';

@Injectable()
export class PreCalculationArticleCalculatorAllowanceService extends AbstractPreCalculationArticleCalculatorService {
  calculate(otherArticle: PreCalculationArticle, articlesToForceRecalculate: PreCalculationArticle[], precalculation: PreCalculation): void {

    const differenceCalculated = (otherArticle.targetInvoiceHour - otherArticle.invoiceHour)
      / otherArticle.standardWeeklyHours * otherArticle.weeklyHours;

    if ((differenceCalculated || otherArticle.isIncludedInNormalWorkhours)
      && otherArticle.isActive
      && !otherArticle.isOccasional
      && !otherArticle.isUsingFactorIncrement) {
      for (const generalArticle of articlesToForceRecalculate) {
        this.recalculateGeneralArticle(generalArticle, differenceCalculated, otherArticle, precalculation, true);
      }
    }
  }

  protected isApplicable(article: PreCalculationArticle): boolean {
    return true;
  }
}
