import {Observable} from 'rxjs';
import {AbstractExecutionStep} from '../../../../../core/executor/abstract-execution-step';
import {ExecutionStepStatus} from '../../../../../core/executor/execution-step-status';
import {AbstractGenericGridComponent} from '../../../../content-renderer/elements/abstract-generic-grid.component';
import {
  SetColorToInquiryPositionGridToolbarItemsCondition
} from '../../../../job-runner/condition/inquiry/set-color-to-inquiry-position-grid-toolbar-items.condition';
import {
  SetColorToInquiryPositionGridToolbarItemsJob
} from '../../../../job-runner/job/inquiry/set-color-to-inquiry-position-grid-toolbar-items.job';

export class RegisterInquiryPositionGridToolbarItemsColorStep extends AbstractExecutionStep {

  doExecute(): Observable<ExecutionStepStatus> {
    const component = this.getPayload().getValue();

    if (!(component instanceof AbstractGenericGridComponent)) {
      return this.getFailObservable('You need to pass AbstractGenericGridComponent as Payload value!');
    }

    return this.doRegister(component);
  }

  private doRegister(component: AbstractGenericGridComponent): Observable<ExecutionStepStatus> {
    return Observable.create((observer) => {

      const condition = new SetColorToInquiryPositionGridToolbarItemsCondition();
      condition.setPayload({
        component: component
      });
      this.jobContainerService.registerJob(
        new SetColorToInquiryPositionGridToolbarItemsJob().setIsImmutable(true),
        condition
      );

      observer.next({
        status: true,
        content: null
      });
      observer.complete();
    });
  }
}
