
import {map, catchError} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';

import {AppHttpService} from '../../app-http.service';
import {HttpClient} from '@angular/common/http';

@Injectable()
export class LockingService extends AppHttpService {

    private lockingUrl: string = '/app';
    private url: string = '';

    constructor(private http: HttpClient) {
        super();
        this.url = this.getApiUrl() + this.lockingUrl;
    }

    /**
     *
     * @param entity
     * @returns {Observable<boolean>}
     */
    lock(entity: any): Observable<boolean> {
        let postData = {
            fqn: entity.fqn,
            id: entity.id
        };

        return this.http.post(`${this.url}/lock`, JSON.stringify(postData)).pipe(
            map((response: any) => {
                // xcentric, check this!
                return response;
            }),
            map((json) => {
                return json['isHit'];
            }),
            catchError(this.handleError));
    }

    /**
     *
     * @param entity
     * @returns {Observable<boolean>}
     */
    isLocked(entity: any): Observable<boolean> {
        let postData = {
            fqn: entity.fqn,
            id: entity.id
        };

        return this.http.post(`${this.url}/locked`, JSON.stringify(postData)).pipe(
            map((response: any) => {
                // xcentric, check this!
                return response;
            }),
            map((json) => {
                return json['isHit'];
            }),
            catchError(this.handleError));
    }

    /**
     *
     * @param entity
     * @returns {Observable<boolean>}
     */
    release(entity: any): Observable<boolean> {
        let postData = {
            fqn: entity.fqn,
            id: entity.id
        };

        return this.http.post(`${this.url}/release`, JSON.stringify(postData)).pipe(
            map((response: any) => {
                // xcentric, check this!
                return response;
            }),
            map((json) => {
                return true;
            }),
            catchError(this.handleError));
    }
}
