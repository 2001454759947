
import {catchError, map, refCount, publishReplay} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import {observable, Observable, of as observableOf} from 'rxjs';

import { AppHttpService } from '../../../app-http.service';
import { Form } from '../../form-viewer/models';
import { Element } from '../../form-viewer/models';

// import { ElementForm } from './element-form.model';
import { ElementCrudService } from '../element/element.crud.service';
import { EntitySharingService } from '../../content-renderer/services/entity-sharing.service';
import { GenericCrudService } from '../generic-crud.service';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class ElementFormCrudService extends AppHttpService {

  private elementFormsUrl = 'superadmin/form';

  private elementsUrl = 'elements';

  private apiUrl: string = '';

  constructor(
    private elementCrudService: ElementCrudService,
    private entitySharingService: EntitySharingService,
    private genericCrudService: GenericCrudService,
    private httpClient: HttpClient
  ) {
    super();
    this.apiUrl = this.getApiUrl();
  }

  /**
   *
   * @returns {Observable<ElementForm[]>}
   */
  getElementForms(): Observable<Form[]> {
    const url = `${this.elementFormsUrl}s`;
    return this.genericCrudService.get(url).pipe(
      publishReplay(1),
      refCount(),
      catchError(this.handleError));
  }

  getElementForm(elementFormId: number): Observable<Form> {
    return <Observable<Form>>this.elementCrudService.getElement(elementFormId);
  }

  getElementFormFields(elementFormId: number): Observable<any[]> {
    const url = `${this.elementFormsUrl}/${elementFormId}/fields`;
    return this.genericCrudService.get(url).pipe(
      publishReplay(1),
      refCount(),
      catchError(this.handleError));
  }

  saveElementFormElements(elementFormId: string, elementFormElements): Observable<any> {
    if (!elementFormElements || !elementFormId) {
      throw new Error('Elements or form id not given.');
    }

    const url = `${this.apiUrl}/${this.elementFormsUrl}/${elementFormId}/fields`;

    if (!elementFormId) {

      return this.httpClient.post(
        url,
        JSON.stringify(elementFormElements),
        { params: this.genericCrudService.getRequestOptions().getHttpParams() }
      ).pipe(
        map(this.extractEmbeddedEntities, this));
    } else {
      return this.httpClient.put(
        url,
        JSON.stringify(elementFormElements)).pipe(
        catchError(this.handleError));
    }
  }

  saveElementForm(element: Form): Observable<any> {
    if (!element) {
      throw new Error('Elements object not given.');
    }

    if (!element.id) {
      const url = `${this.apiUrl}/${this.elementFormsUrl}s`;

      return this.httpClient.post(
        url,
        JSON.stringify(element)).pipe(
        catchError(this.handleError));
    } else {
      const url = `${this.apiUrl}/${this.elementFormsUrl}s/${element.id}`;

      return this.httpClient.put(
        url,
        JSON.stringify(element)).pipe(
        catchError(this.handleError));
    }
  }

  deleteElementForm(elementForm: Form): Observable<any> {
    if (!elementForm || !elementForm.id) {
      throw new Error('Element object or id not given.');
    }

    return this.genericCrudService.deleteEntity(`${this.elementFormsUrl}s/${elementForm.id}`).pipe(
      catchError(this.handleError));
  }

  deleteElementForms(elementForms: Form[]): Observable<any> {
    if (!elementForms) {
      throw new Error('Element object or id not given.');
    }
    const ids = [];
    for (const elementForm of elementForms) {
      ids.push(elementForm.id);
    }
    return this.genericCrudService.deleteEntities(`${this.elementFormsUrl}s`, ids).pipe(
      catchError(this.handleError));
  }

  clearElementCache(): void {
    this.entitySharingService.clearCacheForRoute(this.elementsUrl);
  }
}
