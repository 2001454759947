import {GenericElementEvent} from './generic-element-event';
import {GenericElementAbstract} from '../../../content-renderer/elements/generic-element-abstract.component';

export class MasterEntityImportEvent extends GenericElementEvent {
  private entity: any = null;

  public constructor(component: GenericElementAbstract, entity: any) {
    super(component);
    this.entity = entity;
  }

  public getEntity(): any {
    return this.entity;
  }

  public setEntity(entity: any): MasterEntityImportEvent {
    this.entity = entity;
    return this;
  }
}
