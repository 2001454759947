import {Component} from '@angular/core';
import {AbstractEditExpertSearchFieldValue} from '../abstract-edit-expert-search-field-value';

@Component({
  selector: 'app-search-management-edit-checkbox-edit-expert-search-field-value',
  templateUrl: './checkbox-edit-expert-search-field-value.component.html'
})
export class SearchManagementCheckboxEditExpertSearchFieldValueComponent extends AbstractEditExpertSearchFieldValue {

  public onCheckboxChange(event): void {
    this.onChange.emit({
      value: this.field.fieldValue,
      labelValue: this.field.fieldValue ? 'true' : 'false'
    });
  }
}
