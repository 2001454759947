/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../content-renderer.component.ngfactory";
import * as i2 from "../../content-renderer.component";
import * as i3 from "../../../services/generic-crud.service";
import * as i4 from "../../../services/generic-crud-bulk.service";
import * as i5 from "../../../services/layout-service";
import * as i6 from "../../../directives/selenium.directive";
import * as i7 from "../../../../../../node_modules/primeng/components/dialog/dialog.ngfactory";
import * as i8 from "primeng/components/dialog/dialog";
import * as i9 from "@angular/common";
import * as i10 from "./generic-dialog-module.component";
import * as i11 from "../../services/modules-state.service";
var styles_GenericDialogModuleComponent = [".dialog-close-button-container {\n      float: right;\n      cursor: pointer;\n      margin-top: -28px;\n    }\n\n      .ui-dialog-titlebar-maximize {\n      margin-right: 13px;\n    }"];
var RenderType_GenericDialogModuleComponent = i0.ɵcrt({ encapsulation: 0, styles: styles_GenericDialogModuleComponent, data: {} });
export { RenderType_GenericDialogModuleComponent as RenderType_GenericDialogModuleComponent };
function View_GenericDialogModuleComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "app-content-renderer", [["appSeleniumDirective", ""]], null, [["window", "resize"]], function (_v, en, $event) { var ad = true; if (("window:resize" === en)) {
        var pd_0 = (i0.ɵnov(_v, 1).onResize($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i1.View_ContentRendererComponent_0, i1.RenderType_ContentRendererComponent)), i0.ɵdid(1, 245760, null, 0, i2.ContentRendererComponent, [i3.GenericCrudService, i4.GenericCrudBulkService, i0.ElementRef, i5.LayoutService, i0.ChangeDetectorRef], { entity: [0, "entity"], additional: [1, "additional"], masterField: [2, "masterField"], masterEntity: [3, "masterEntity"], masterEntityEditingField: [4, "masterEntityEditingField"], masterElementContext: [5, "masterElementContext"], isDialog: [6, "isDialog"], masterFilterField: [7, "masterFilterField"], masterFilterValue: [8, "masterFilterValue"], module: [9, "module"] }, null), i0.ɵdid(2, 4210688, null, 0, i6.SeleniumDirective, [i0.ElementRef, i0.Renderer2], { module: [0, "module"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.options.entity; var currVal_1 = _co.options.additional; var currVal_2 = _co.options.masterField; var currVal_3 = _co.options.masterEntity; var currVal_4 = _co.options.masterEntityEditingField; var currVal_5 = _co.options.masterElementContext; var currVal_6 = true; var currVal_7 = _co.options.masterFilterField; var currVal_8 = _co.options.masterFilterValue; var currVal_9 = _co.module; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8, currVal_9); var currVal_10 = _co.module; _ck(_v, 2, 0, currVal_10); }, null); }
function View_GenericDialogModuleComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 10, "p-dialog", [["appendTo", "body"], ["showEffect", "fade"], ["styleClass", "module-in-dialog"]], null, [[null, "visibleChange"], [null, "onShow"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("visibleChange" === en)) {
        var pd_0 = ((_co.isVisible = $event) !== false);
        ad = (pd_0 && ad);
    } if (("onShow" === en)) {
        var pd_1 = (_co.onShow($event, i0.ɵnov(_v, 1)) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i7.View_Dialog_0, i7.RenderType_Dialog)), i0.ɵdid(1, 180224, [[1, 4], ["dialog", 4]], 2, i8.Dialog, [i0.ElementRef, i0.Renderer2, i0.NgZone], { visible: [0, "visible"], header: [1, "header"], resizable: [2, "resizable"], modal: [3, "modal"], closable: [4, "closable"], responsive: [5, "responsive"], appendTo: [6, "appendTo"], styleClass: [7, "styleClass"], maximizable: [8, "maximizable"], width: [9, "width"], height: [10, "height"] }, { onShow: "onShow", visibleChange: "visibleChange" }), i0.ɵqud(603979776, 2, { headerFacet: 1 }), i0.ɵqud(603979776, 3, { footerFacet: 1 }), (_l()(), i0.ɵeld(4, 0, null, 1, 4, "div", [["class", "dialog-close-button-container ui-helper-clearfix ng-tns-c8-6"]], null, null, null, null, null)), (_l()(), i0.ɵeld(5, 0, null, null, 1, "a", [["class", "ng-tns-c8-6 ui-dialog-titlebar-close ui-corner-all"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onDialogMaximize($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(6, 0, null, null, 0, "span", [["class", "fa fa-fw fa-window-maximize"]], null, null, null, null, null)), (_l()(), i0.ɵeld(7, 0, null, null, 1, "a", [["class", "ng-tns-c8-6 ui-dialog-titlebar-close ui-corner-all"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onCloseDialog($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(8, 0, null, null, 0, "span", [["class", "dialog-close-button fa fa-fw fa-close"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, 1, 1, null, View_GenericDialogModuleComponent_2)), i0.ɵdid(10, 16384, null, 0, i9.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.isVisible; var currVal_1 = i0.ɵinlineInterpolate(1, "", _co.module.label, ""); var currVal_2 = false; var currVal_3 = ((_co.options && _co.options.isModal) ? _co.options.isModal : false); var currVal_4 = false; var currVal_5 = false; var currVal_6 = "body"; var currVal_7 = "module-in-dialog"; var currVal_8 = false; var currVal_9 = _co.dialogWidth; var currVal_10 = _co.dialogHeight; _ck(_v, 1, 1, [currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8, currVal_9, currVal_10]); var currVal_11 = _co.module; _ck(_v, 10, 0, currVal_11); }, null); }
export function View_GenericDialogModuleComponent_0(_l) { return i0.ɵvid(0, [i0.ɵqud(671088640, 1, { dialog: 0 }), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_GenericDialogModuleComponent_1)), i0.ɵdid(2, 16384, null, 0, i9.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.isVisible; _ck(_v, 2, 0, currVal_0); }, null); }
export function View_GenericDialogModuleComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-generic-dialog-module", [], null, null, null, View_GenericDialogModuleComponent_0, RenderType_GenericDialogModuleComponent)), i0.ɵdid(1, 245760, null, 0, i10.GenericDialogModuleComponent, [i3.GenericCrudService, i11.ModulesStateService, i5.LayoutService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var GenericDialogModuleComponentNgFactory = i0.ɵccf("app-generic-dialog-module", i10.GenericDialogModuleComponent, View_GenericDialogModuleComponent_Host_0, { module: "module", isAutocompleteModuleState: "isAutocompleteModuleState", dialogWidth: "dialogWidth", dialogHeight: "dialogHeight", beforeClose: "beforeClose", afterClose: "afterClose", options: "options", dialogService: "dialogService" }, { close: "close" }, []);
export { GenericDialogModuleComponentNgFactory as GenericDialogModuleComponentNgFactory };
