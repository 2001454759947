import {Injectable} from '@angular/core';
import {EntityManagerService} from '../entity-manager/entity-manager.service';

@Injectable()
export class SaveEntityRequestBodyBuilderService {
  protected quirkyProperties = ['locality'];

  protected builtForSave: {uniqueId: string, fqn: string}[] = [];

  public constructor(
    private entityManager: EntityManagerService
  ) {

  }

  public build(originalEntity: any) {
    const entity = originalEntity.id ? this.buildDefault(originalEntity) : originalEntity,
      changeSet = this.entityManager.getChangeSetContainer().getChangeSet(entity);

    // Only in case we have an update this is required - when saving a new entity no need for changesets:
    if (entity.id) {
      for (const changeSetMeta of changeSet) {
        const type = typeof originalEntity[changeSetMeta.property] !== 'undefined' && originalEntity[changeSetMeta.property] !== null ?
          originalEntity[changeSetMeta.property].constructor :
          String;

        switch (type) {
          case Array:
            if (this.isArrayOfEntities(originalEntity[changeSetMeta.property])) {
              entity[changeSetMeta.property] = this.buildArrayOfEntities(changeSetMeta.newValue);
            } else {
              entity[changeSetMeta.property] = changeSetMeta.newValue;
            }
            break;
          case Object:
            if(changeSetMeta.newValue && changeSetMeta.newValue.uniqueId && changeSetMeta.newValue.fqn) {
              entity[changeSetMeta.property] = this.build(changeSetMeta.newValue);
            }else{
              entity[changeSetMeta.property] = changeSetMeta.newValue;
            }
            break;
          default:
            entity[changeSetMeta.property] = changeSetMeta.newValue;
        }
      }
    }

    this.builtForSave.push({
      fqn: entity.fqn,
      uniqueId: entity.uniqueId
    });

    return entity;
  }

  public getBuilt(): {fqn: string, uniqueId: string}[] {
    return this.builtForSave;
  }

  public clearBuilt(): this {
    this.builtForSave = [];
    return this;
  }

  private buildArrayOfEntities(data: {fqn: string, uniqueId: string}[] = []): {fqn: string, uniqueId: string}[] {
    const built = [];

    for (const d of data) {
      built.push(this.build(d));
    }

    return built;
  }

  private isArrayOfEntities(data: any[] = []): boolean {
    for (const d of data) {
      if (d.uniqueId && d.fqn) {
        return true;
      }
    }

    return false;
  }

  private buildDefault(originalEntity): any {
    const preparedEntity = {
      id: originalEntity.id,
      fqn: originalEntity.fqn,
      uniqueId: originalEntity.uniqueId,
      isChanged: originalEntity.isChanged,
      isEmbeddedEntityChanged: originalEntity.isEmbeddedEntityChanged,
      _embedded: {}
    };

    for (const quirkyProperty of this.quirkyProperties) {
      if (originalEntity['_embedded'] && typeof originalEntity['_embedded'][quirkyProperty] !== 'undefined') {
        preparedEntity['_embedded'][quirkyProperty] = originalEntity['_embedded'][quirkyProperty];
      }
    }

    return preparedEntity;
  }
}
