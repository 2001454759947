import {Directive, ElementRef, Input, AfterViewInit, ChangeDetectorRef} from '@angular/core';
import { ElementState } from '../../../services/element-state';
import { NgModel } from '@angular/forms';

@Directive({
    selector: '[gridFilter]',
    host: {
        '(input)': 'onInput($event)',
        '(onChange)': 'onChange($event)',
        '(onSelect)': 'onSelect($event)',
        '(onUnselect)': 'onSelect($event)',
        '[style.background-color]': '"#ffffba"',
    },
    providers: [NgModel]
})
export class GridFilterDirective implements AfterViewInit {

    private valueNotEmptyColor = 'yellow';
    private valueEmptyColor = '#ffffba';

    private _componentState: ElementState = null;

    @Input() public set componentState(componentState: ElementState) {
      this._componentState = componentState;
      if (componentState) {
        this.setValue(componentState);
      }
    };
    public get componentState() {
      return this._componentState;
    }
    @Input() column: any = null;

    public constructor(
        protected elementRef: ElementRef,
        protected ngModel: NgModel,
        public cdr: ChangeDetectorRef
    ) {

    }

    public ngAfterViewInit() {
        if (this.componentState) {
            this.setValue(this.componentState);
        }
    }

    public setValue(componentState: ElementState): void {

        this.ngModel.valueAccessor.writeValue('');

        if (componentState && this.column && this.column.property) {
            const filters = componentState ? componentState.filters : {},
                columnName = this.column.property.indexOf('.') !== -1 ? this.column.property.split('.')[0] : this.column.property;

            if (columnName && filters[columnName] && filters[columnName].value) {
                this.ngModel.valueAccessor.writeValue(filters[columnName].value);
            }
        }
    }

    public getFilter(componentState: ElementState): any|any[] {
      if (componentState && this.column && this.column.property) {
        const filters = componentState ? componentState.filters : {},
          columnName = this.column.property.indexOf('.') !== -1 ? this.column.property.split('.')[0] : this.column.property;

        return filters[columnName];
      }

      return null;
    }

    public onInput(event) {

        if (this.elementRef.nativeElement.querySelector('.ui-autocomplete-multiple-container')) {
            this.onAutocompleteInputChange(event);
        } else {
            this.onTextChange(event);
        }
    }

    public onChange(event) {

        if (event.value instanceof Array) {
            this.onMultiDropdownChange(event);
        } else {
            this.onSingleDropdownChange(event);
        }
    }

    public onSelect(event) {

        if (this.elementRef.nativeElement.querySelector('.ui-autocomplete-multiple-container')) {
            this.onAutocompleteSelectChange(event);
        }
    }

    public onTextChange(event) {
        event.target.style.background = this.getBackgroundColor(event.target.value);
    }

    public onAutocompleteInputChange(event) {
        const label = this.elementRef.nativeElement.querySelector('.ui-autocomplete-multiple-container');

        label.style.background = this.getBackgroundColor(event.target.value);
    }

    public onAutocompleteSelectChange(event) {
      const me = this;

        setTimeout(() => {
            const li = me.elementRef.nativeElement.querySelectorAll('li.ui-corner-all'),
                label = this.elementRef.nativeElement.querySelector('.ui-autocomplete-multiple-container');

            label.style.background = this.getBackgroundColor(li.length > 0);
        }, 100);
    }

    public onSingleDropdownChange(event) {
        const label = this.elementRef.nativeElement.querySelector('.ui-dropdown-label');

        if (label) {
            label.style.background = this.getBackgroundColor(event.value);
        }
    }

    public onMultiDropdownChange(event) {
        const label = this.elementRef.nativeElement.querySelector('.ui-multiselect-label-container');

        label.style.background = this.getBackgroundColor(event.value && event.value.length > 0);
    }

    private getBackgroundColor(value): string {
        return value ? this.valueNotEmptyColor : this.valueEmptyColor;
    }
}
