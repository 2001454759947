import { AbstractValidator } from './abstract-validator';

export class DateValueSmallerThanValidator extends AbstractValidator {

  public isValid(value: any): boolean {
    let isValid = true;

    const fieldToCompare = this.getParamValue('fieldName'),
      canBeEqual = this.getParamValue('canBeEqual'),
      entity = this.getEntity();

    let valueToCompare = entity[fieldToCompare];

    if (typeof value === 'undefined') {
      value = '';
    }

    if (typeof value === 'string' && '' !== value) {
        value = new Date(value);
    }

    if (typeof valueToCompare === 'string' && '' !== valueToCompare) {
        valueToCompare = new Date(valueToCompare);
    }

    if (value instanceof Date && valueToCompare && valueToCompare instanceof Date) {
      isValid = value < valueToCompare;

      if (canBeEqual) {
        isValid = value < valueToCompare || this.isSameDate(value, valueToCompare);
      }
    }

    return isValid;
  }

  public getErrorTranslateKey(value: any): string {
    return 'DATE_NOT_SMALLER_THAN_OTHERS_DATE_VALUE';
  }

  public getErrorTranslateParams(value: any): any {
    return null;
  }

  private isSameDate(a, b) {
    return Math.abs(a - b) < (1000 * 3600 * 24) && a.getDay() === b.getDay();
  }
}
