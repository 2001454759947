import {Component} from '@angular/core';
import {ToolbarItemAbstract} from '../toolbar-item-abstract.component';
import {AbstractGenericGridComponent} from '../../../../abstract-generic-grid.component';
import {ModuleElementFieldCrudService} from '../../../../../../services/module/module-element-field.crud.service';
import {RequestCachingService} from '../../../../../../services/request-caching.service';
import {cloneDeep} from 'lodash';
import {FieldMetadataGrid} from '../../../../../../services/module/module-element-field-metadata-grid';
import {Constants} from '../../../../../../../constants';
import {GenericCrudService} from '../../../../../../services/generic-crud.service';
import {Column} from "primeng/shared";
import {ExecutorService} from "../../../../../../../core/executor/executor.service";
import {ExecutionStepFactoryService} from "../../../../../../../core/executor/factory/execution-step-factory.service";
import {ExecutionStepPayload} from "../../../../../../../core/executor/execution-step-payload";
import {
  ExecutionStepParameter,
  ExecutionStepParameterExecutor
} from "../../../../../../../core/executor/execution-step-parameter";
import {ExecutionStatus} from "../../../../../../../core/executor/execution-status";

@Component({
  selector: 'app-toolbar-item-work-hour-monthly-grid-selection',
  template: ``
})
export class ToolbarItemWorkHourMonthlyGridSelectionComponent extends ToolbarItemAbstract {

  public constructor(
    protected requestCachingService: RequestCachingService,
    protected genericCrudService: GenericCrudService,
    protected executorService: ExecutorService,
    protected stepFactory: ExecutionStepFactoryService
  ) {
    super();
  }

  public click() {
    const component = this.getComponent();

    if (component instanceof AbstractGenericGridComponent) {
      for (let column of component.moduleElement.columns){
        if(column.columnType === 'SelectionColumnComponent'){
          column.visible = !column.visible;
          component.selectedEntities = component.entities;
        }
      }
      component.initColumns();

      const step = this.stepFactory.createFromString('WorkHourMonthlyEntryGridColorEntitiesStep', new ExecutionStepPayload(this.getComponent()));
      this.executorService
        .setSteps([step])
        .execute()
        .subscribe((status: ExecutionStatus) => {});
    }
  }
}
