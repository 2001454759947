
import {of as observableOf,  Observable } from 'rxjs';
import { AbstractExecutionStep } from '../../../../../core/executor/abstract-execution-step';
import { ExecutionStepStatus } from '../../../../../core/executor/execution-step-status';
import { GenericElementAbstract } from '../../../../content-renderer/elements/generic-element-abstract.component';
import { MasterEntityConfig } from '../../../../content-renderer/services/ElementContext';
import { AbstractGenericGridComponent } from '../../../../content-renderer/elements/abstract-generic-grid.component';
import { Constants } from '../../../../../constants';
import {FormViewerComponent} from '../../../../form-viewer/form-viewer.component';
import {ElementInputListBox} from '../../../../form-viewer/models/element-input-list-box';
import {Datamodel} from '../../../datamodel/datamodel';
import {FormService} from '../../../../form-viewer/form.service';

export abstract class AbstractLoadWorkplaceSelectedEntitiesStep extends AbstractExecutionStep {

    protected abstract getDatamodelApiRout(): string;
    protected abstract getInput(form: FormViewerComponent): ElementInputListBox;

    public doExecute(): Observable<ExecutionStepStatus> {
        const payload = this.getPayload();

        if (!(payload.getValue() instanceof FormViewerComponent)) {
            return this.getFailObservable('You need to pass FormViewerComponent as Payload value!');
        }

        return this.doGenerate(payload.getValue());
    }

    protected doGenerate(component: FormViewerComponent): Observable<ExecutionStepStatus> {
        const customerId = this.getMasterConfigValue(component, 'customer'),
            workplaceId = this.getMasterConfigValue(component, 'workplace');

        if (!customerId) {
            return this.getFailObservable('Customer ID not found');
        }

        if (workplaceId !== null || !component.entity || (component.entity && component.entity.id)) {
          return observableOf({status: true, content: []});
        }

        this.genericCrudService.getEntities(`${this.getDatamodelApiRout()}`, '',{
            customer: customerId,
            isForAllWorkplaces: true
        }).subscribe((entities: any[] = []) => {

            const input = this.getInput(component);

            if (input) {
              component.entity[input.datamodelField] = entities;

          let formControlFieldName = FormViewerComponent.getFormControlName(input.objectHashId, input.datamodelField),
            formControl = component.getInputElementComponent(formControlFieldName);
          if(formControl){
            formControl.setupValueFromEntity();
          }

              component.recheckToolbarItems();
            }
        });

        return observableOf({status: true, content: []});
    }

    private getMasterConfigValue(component: GenericElementAbstract, configName: string): string|null {
        let masterConfigValue = null;

        if (component && component.getElementContext() && component.getElementContext().getMasterEntities()) {
            const masterConfig = component.getElementContext().getMasterEntity(configName);

            if (masterConfig !== null) {
                masterConfigValue = masterConfig.value;
            }
        }

        return masterConfigValue;
    }
}
