import {Observable} from 'rxjs';
import {AbstractJob} from '../../../../core/job-runner/job/abstract.job';
import {JobInterface} from '../../../../core/job-runner/job/job.interface';
import {JobExecutionStatus} from '../../../../core/job-runner/job/job-execution-status';
import {ConditionInterface} from '../../../../core/job-runner/condition/condition.interface';
import {JobContext} from '../../../../core/job-runner/context/job.context';
import {ElementInputDropdownComponent} from '../../../form-viewer/element/element-input-dropdown.component';

export class SetElementFiltersJob extends AbstractJob implements JobInterface {
  run(context: JobContext, condition: ConditionInterface): Observable<JobExecutionStatus> {
    return Observable.create((observer) => {
      const element = context.component;
      const entity = condition.getPayload()['entity'];

      if (element && element instanceof ElementInputDropdownComponent && entity) {
        element.element.staticFilterField = 'customer';
        element.element.staticFilterValue = entity['customer'].id;
      }

      observer.next({
        status: true,
        content: null,
        jobId: this.jobId
      });
    });
  }
}
