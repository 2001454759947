
import { Injectable } from '@angular/core';
import { AbstractFormActionHandler } from '../abstract-form-action-handler';
import { FormService } from '../../../form.service';
import { HandlePhoneCountryCodeContactPersonActionHandler } from './handle-phone-country-code-contact-person-action-handler';

@Injectable()
export class HandleFaxCountryCodeContactPersonActionHandler extends HandlePhoneCountryCodeContactPersonActionHandler {

    protected getPhoneCountryCodeDatamodelField(): string {
        return 'faxCountryCode';
    }

    protected getPhoneAreaCodeDatamodelField(): string {
        return 'faxAreaCode';
    }

    protected getPhoneNumberDatamodelField(): string {
        return 'faxNumber';
    }
}
