import {Directive, Self, Input} from '@angular/core';
import {Dropdown} from 'primeng/primeng';

@Directive({
  selector: '[appDropdownLoadingIndicator]',
  host: {
    '(onFocus)': 'onFocus($event)',
    '(onBlur)': 'onBlur($event)'
  },
})
export class DropdownLoadingIndicatorDirective {

  private dropdown: Dropdown = null;

  @Input() set options(options) {
    setTimeout(() => { this.dropdown.dropdownIcon = 'pi pi-caret-down'; }, 10);
  }

  public constructor(
    @Self() dropdown: Dropdown
  ) {

    if (dropdown) {
      this.dropdown = dropdown;
    }
  }

  public onFocus(event): void {
    this.dropdown.dropdownIcon = 'pi pi-refresh';
  }

  public onBlur(event): void {
    this.dropdown.dropdownIcon = 'pi pi-caret-down';
  }
}
