import {Component, EventEmitter, Output, Input, ChangeDetectorRef} from '@angular/core';
import {Observable} from 'rxjs';
import {GenericCrudService} from '../../services/generic-crud.service';
import {ChangeDetectorRefHelper} from '../../helpers/change-detector-ref.helper';

interface LeasedEmployee {
  firstName: string;
  lastName: string;
}

@Component({
  selector: 'app-leased-employee-autocomplete',
  template: `
    <p-autoComplete
      appAutocomplete
      appendTo="body"
      field="label"
      [dropdown]="true"
      [placeholder]="'---'"
      [(ngModel)]="selectedOption"
      [suggestions]="options"
      (onKeyUp)="onKeyUp($event)"
      (completeMethod)="onSearch($event)"
      (onDropdownClickNoQuery)="onSearch($event)"
      (onSelect)="onLeasedEmployeeChanged($event)"
    ></p-autoComplete>
  `,
  styles: [`
    p-autocomplete ::ng-deep .ui-autocomplete-input {
      width: calc(100% - 26px); // dropdown button
    }
  `]
})
export class LeasedEmployeeAutocompleteComponent {

  @Input() public params = {};
  @Input() public apiRoute = 'phoenix/leasedemployees';
  @Input() public selectedOption: SelectItem = null;
  @Output() public onLeasedEmployeeOptionChanged: EventEmitter<LeasedEmployee> = new EventEmitter();

  public options: SelectItem[] = [];

  public constructor(
    protected readonly genericCrudService: GenericCrudService,
    public cdr: ChangeDetectorRef
  ) {
  }

  public onKeyUp(event): void {
    if (!event.target.value) {
      this.onLeasedEmployeeOptionChanged.emit(null);
    }
  }

  public onSearch(event: {query: string}): void {
    this.loadLeasedEmployees(event.query)
      .subscribe((paginated: {data: LeasedEmployee[]}) => {
        const data = paginated instanceof Array ? paginated : paginated.data;

        this.options = [];

        for (const leasedEmployee of data) {
          this.options = [...this.options, {
            label: leasedEmployee.lastName + ' ' + leasedEmployee.firstName,
            value: leasedEmployee
          }];
        }

        ChangeDetectorRefHelper.detectChanges(this);
      });
  }

  public onLeasedEmployeeChanged(event?: {value: any}): void {
    this.onLeasedEmployeeOptionChanged.emit(this.selectedOption.value);
  }

  private loadLeasedEmployees(query): Observable<any> {
    const params = this.params;

    params['embedded'] = 'none';
    params['clause'] = 'orWhere';

    return this.genericCrudService.getEntities(
      this.apiRoute + '/offset/0/limit/30/orderby/id/asc?search=' + query,
      '',
      params
    );
  }
}

