import { AbstractFormActionHandler } from './abstract-form-action-handler';
import { Injectable } from '@angular/core';
import { MessageGrowlService } from '../../../../core/message/message-growl.service';
import { TranslateService } from '@ngx-translate/core';
import { Element } from 'app/shared/form-viewer/models/element';

@Injectable()
export class CheckFieldsMessageActionHandler extends AbstractFormActionHandler {

    public constructor(
        private messageGrowlService: MessageGrowlService,
        private translateService: TranslateService
    ) {
        super();
    }

    public handleAction(): void {
        let form = this.getForm();

        if (this.isActionValid()) {
            const triggerFormElementHashId = this.getParamValue('formElement'),
                checkFieldsFormElementsHashIds = this.getParamValue('formElements');

            if (triggerFormElementHashId && checkFieldsFormElementsHashIds && checkFieldsFormElementsHashIds instanceof Array) {
                const triggerFieldElement = this.formService.getElementBy(form, 'objectHashId', triggerFormElementHashId),
                    checkFieldsLabels = this.getCheckElementsLabels(checkFieldsFormElementsHashIds);

                if (triggerFieldElement && checkFieldsLabels.length > 0) {

                    let infoMessage = this.translateService.instant("FORMEDITOR.ACTIONS.CHECK_FIELDS_MESSAGE", {
                        'triggerFieldLabel': this.getElementLabel(triggerFieldElement),
                        'checkFieldsLabel': checkFieldsLabels
                    });

                    this.messageGrowlService.info(infoMessage, this.translateService.instant("COMMON.INFO"));
                }
            }
        }
    }

    private isActionValid(): boolean {
        const form = this.getForm(),
            element = this.getElement();

        let isValid = this.getAction().params['formElement'] &&
            this.getAction().params['formElement'].value &&
            this.getAction().params['formElements'] &&
            this.getAction().params['formElements'].value;

        const triggerElement = this.formService.getElementBy(form, 'objectHashId', this.getAction().params['formElement'].value);

        return isValid && this.isTriggerElement(triggerElement, element);
    }

    private getElementLabel(element: Element): string {
        return element.label ? element.label : element.datamodelField;
    }

    private getCheckElementsLabels(checkFieldsFormElementsHashIds: string[]): string {
        const form = this.getForm(),
            checkFieldsLabels = [];

        for (let checkFieldFormElementHashId of checkFieldsFormElementsHashIds) {
            const checkFieldElement = this.formService.getElementBy(form, 'objectHashId', checkFieldFormElementHashId);

            if (checkFieldElement && checkFieldElement.getValue()) {
                checkFieldsLabels.push(this.getElementLabel(checkFieldElement));
            }
        }

        return checkFieldsLabels.join();
    }

    private isTriggerElement(triggerElement: Element, elementTriggeredTheChange: Element): boolean {
        return triggerElement.objectHashId === elementTriggeredTheChange.objectHashId;
    }
}
