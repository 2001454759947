import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {ExecutorActionEvent} from './executor-action-event';
import {ExecutionStepPayload} from '../../execution-step-payload';
import {ExecutionStep} from 'app/core/executor/execution-step';
import {ExecutionStepFactoryService} from 'app/core/executor/factory/execution-step-factory.service';
import {ExecutorRegistry} from '../executor.registry';
import {ModuleElementAction} from '../../../../shared/services/module/module-element-action';
import {GenericCrudService} from '../../../../shared/services/generic-crud.service';
import {ModuleElement} from 'app/shared/services/module/module-element';
import {ExecutionStepParameter} from '../../execution-step-parameter';
import {ExecutionStepEventParameter} from '../../execution-step-event-parameter';

@Injectable()
export class ExecutorActionsService {

    public actionsDictionary: { [moduleElementId: number]: { [event: string]: any[]; }} = {};

    private registry = new ExecutorRegistry();

    public constructor(
        private httpClient: HttpClient,
        private genericCrudService: GenericCrudService,
        private executionStepFactoryService: ExecutionStepFactoryService
    ) {

    }

    public registerModuleElementActions(moduleElement: ModuleElement): Observable<any> {
        const moduleElementId: number = moduleElement.id;

        let actions = moduleElement.actions;

        if (!actions && moduleElement._embedded && moduleElement._embedded.actions) {
            actions = moduleElement._embedded.actions;
        }

        return new Observable((observer) => {
            this.registerActions(moduleElementId, actions);

            observer.next(this.actionsDictionary);
            observer.complete();
        });
    }

    public registerActions(moduleElementId: number, actions: ModuleElementAction[] = []) {

        if (actions.length > 0) {
            this.actionsDictionary[moduleElementId] = this.actionsDictionary[moduleElementId] || {};
        }

        for (const action of actions) {
            this.actionsDictionary[moduleElementId][action.event] = this.actionsDictionary[moduleElementId][action.event] || [];

            const steps = action.actionTypes;

            if (this.actionsDictionary[moduleElementId][action.event].length > 0) {
                break;
            }

            for (const step of steps) {
                this.actionsDictionary[moduleElementId][action.event].push({
                    name: step.name,
                    parameters: step.parameters || []
                });
            }
        }
    }

    public getSteps(moduleElementId: number, event: ExecutorActionEvent, payload: any): ExecutionStep[] {
        const steps: ExecutionStep[] = [],
            actions = this.getEventActions(moduleElementId, event);

        if (actions.length === 0) {
            return [];
        }

        for (const action of actions) {
            const registryStep = this.registry.steps[action.name];

            if (registryStep) {
                const step = this.executionStepFactoryService.create(registryStep, new ExecutionStepPayload(payload));

                const parameters = [];
                for (const parameter of action.parameters) {
                  parameters.push(new ExecutionStepParameter(parameter.name, JSON.parse(parameter.value)));
                }

                parameters.push(new ExecutionStepEventParameter(event));

                step.setParameters(parameters);

                steps.push(step);
            } else {
                console.error(`Step ${action.name} not found, be sure that it's registered!`);
            }
        }

        return steps;
    }

    private getEventActions(moduleElementId: number, event: ExecutorActionEvent): any[] {
      const areActionsDefined = this.actionsDictionary && this.actionsDictionary[moduleElementId] &&
        this.actionsDictionary[moduleElementId][event];

        let eventActions = [];

        if (areActionsDefined && this.actionsDictionary[moduleElementId][event] instanceof Array) {
            eventActions = this.actionsDictionary[moduleElementId][event];
        }

        return eventActions;
    }

}
