import {Injectable} from '@angular/core';
import {AppComponent} from '../../../app.component';

@Injectable()
export class ModuleContentBlockerService {

  public component: AppComponent = null;

  public start(message: string): void {
    this.component.blockUI.start(message);
  }

  public stop(): void {
    this.component.blockUI.stop();
    this.component.blockUI.reset();
  }
}
