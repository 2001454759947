import { Component } from '@angular/core';
import { FieldActionAbstractComponent } from '../field-action-abstract.component';
import { GenericCrudService } from '../../../../../services/generic-crud.service';
import { DoubleClickService } from '../../../../services/double-click.service';

@Component({
  selector: 'field-action-open-element',
  template: ''
})
export class FieldActionOpenElementComponent extends FieldActionAbstractComponent {

  public constructor(
    protected genericCrudService: GenericCrudService,
    protected doubleClickService: DoubleClickService
  ) {
    super();
  }

  public fire() {
    let gridComponent = this.getGrid(),
      elementId = this.getActionParamValue('elementId');

    if (null !== elementId) {
      this.doubleClickService.handleOpenElement(gridComponent, elementId);
    }
  }
}
