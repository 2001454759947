<app-generic-loading *ngIf="isLoading"></app-generic-loading>

<div *ngIf="!isLoading">
  <shared-dynamic-table
    [header]="header"
    [columns]="columns"
    [entities]="data"
    [totalCount]="data.length"
    [showRows]="false"
    [isLoadingData]="isLoading"
    [showFilters]="false"
    [paginator]="true"
  ></shared-dynamic-table>
</div>

<div style="margin-top: 10px" *ngIf="!isLeasedEmployeeLoading">
  <shared-dynamic-table
    [header]="leasedEmployeeHeader"
    [columns]="leasedEmployeeColumns"
    [entities]="leasedEmployeeData"
    [totalCount]="leasedEmployeeData.length"
    [showRows]="false"
    [isLoadingData]="isLeasedEmployeeLoading"
    [showFilters]="false"
    [paginator]="true"
  ></shared-dynamic-table>
</div>
