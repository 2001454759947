import { Injectable } from '@angular/core';
import { AbstractFormActionHandler } from '../abstract-form-action-handler';
import { Element } from '../../../models';
import { AbstractControl } from '@angular/forms';
import { ComponentSelectedOptionAware } from '../../../element/abstract-element.component';

@Injectable()
export class HandleCountryPhoneActionHandler extends AbstractFormActionHandler {


    public handleAction(): void {
        if (this.isActionValid()) {

            const isChangingCodeOrNumber = this.isChangingPhoneAreaCode() || this.isChangingPhoneNumber();

            const codeAndNumberAreEmpty = !this.getEntity()[this.getPhoneAreaCodeDatamodelField()] &&
                    !this.getEntity()[this.getPhoneNumberDatamodelField()];

            const codeOrNumberAreEmpty = !this.getEntity()[this.getPhoneAreaCodeDatamodelField()] ||
                !this.getEntity()[this.getPhoneNumberDatamodelField()];

            const countryPhoneCodeIsEmpty = !this.getEntity()[this.getPhoneCountryCodeDatamodelField()];

            if (this.isChangingCountry() && !codeAndNumberAreEmpty) {
                this.setPhoneCountryCodeValueFromCountryComponent();
            }

            if (!this.isChangingPhoneCountry() && !this.isChangingCountry() && isChangingCodeOrNumber) {

                if (codeOrNumberAreEmpty) {
                    this.setPhoneCountryCodeValue('');
                } else if (countryPhoneCodeIsEmpty) {
                    this.setPhoneCountryCodeValueFromCountryComponent();
                }
            }
        }
    }

    protected getPhoneCountryCodeDatamodelField(): string {
        return 'phoneCountryCode';
    }

    protected getPhoneAreaCodeDatamodelField(): string {
        return 'phoneAreaCode';
    }

    protected getPhoneNumberDatamodelField(): string {
        return 'phoneNumber';
    }

    protected getCountryDatamodelField(): string {
        return 'country';
    }

    protected isActionValid(): boolean {
        return this.getCountryPhoneElement() !== null && this.getCountryPhoneFormControl() !== null &&
            this.getCountryElement() !== null && this.getCountryComponent() !== null;
    }

    private setPhoneCountryCodeValue(value: any): void {
        this.getCountryPhoneElement().setValue(value);
        this.getCountryPhoneFormControl().setValue(value);
        this.getEntity()[this.getPhoneCountryCodeDatamodelField()] = value;

        const element = this.getCountryPhoneElement(),
          formControlName = element ? this.getDatamodelFieldName(element.datamodelField, element.objectHashId) : '';

        this.formService.onFormElementValueChange({
            formControlValue: value,
            element: this.getCountryPhoneElement(),
            entityValue: value,
            formControlName: formControlName,
            formControlOptions: {},
            triggerChange: false,
            entity: this.getEntity(),
            component: this.getCountryComponent(),
            updateFormComponent: false
        });
    }

    private setPhoneCountryCodeValueFromCountryComponent(): void {
        const value = this.getPhonePrefixFromCountryComponent();

        this.setPhoneCountryCodeValue(value);
    }

    protected getPhonePrefixFromCountryComponent(): any {
        let value = null;

        const countryComponent = this.getCountryComponent();

        if (null !== countryComponent &&
            countryComponent.selectedOption &&
            countryComponent.selectedOption.entity
        ) {
            value = countryComponent.selectedOption.entity.phonePrefix;
        }

        return value;
    }

    private isChangingPhoneCountry(): boolean {
        return this.getElement().datamodelField === this.getPhoneCountryCodeDatamodelField();
    }

    private isChangingPhoneAreaCode(): boolean {
        return this.getElement().datamodelField === this.getPhoneAreaCodeDatamodelField();
    }

    private isChangingPhoneNumber(): boolean {
        return this.getElement().datamodelField === this.getPhoneNumberDatamodelField();
    }

    private isChangingCountry(): boolean {
        return this.getElement().datamodelField === this.getCountryDatamodelField();
    }

    protected getCountryPhoneElement(): Element|null {
        return this.formService.getElementBy(this.getForm(), 'datamodelField', this.getPhoneCountryCodeDatamodelField()) || null;
    }

    private getCountryElement(): Element|null {
        return this.formService.getElementBy(this.getForm(), 'datamodelField', this.getCountryDatamodelField()) || null;
    }

    protected getCountryPhoneFormControl(): AbstractControl {
        const element = this.getCountryPhoneElement();

        let control = null;

        if (null !== element) {
            control = this.getFormGroup().get(this.getDatamodelFieldName(element.datamodelField, element.objectHashId));
        }

        return control;
    }

    protected getCountryComponent(): ComponentSelectedOptionAware|any {
        const element = this.getCountryElement();

        let component = null;

        if (null !== element) {
            component = this.formService.getComponent(this.getDatamodelFieldName(element.datamodelField, element.objectHashId));
        }

        return component;
    }

    private getDatamodelFieldName(datamodel, hashId) {
      datamodel = datamodel.split('.').join('-p-');
      return datamodel + '_h_r_f_e_' + hashId;
    }
}
