<div *ngIf="isDataLoading">
  {{ 'COMMON.LOADING_PLEASE_WAIT' | translate }}
</div>

<p-table
  *ngIf="!isDataLoading"
  [value]="products"
  selectionMode="single"
  [loading]="isDataLoading"
  scrollable="true"
  scrollHeight="400px"
>
  <ng-template pTemplate="header">
    <tr>
      <th pResizableColumn>
        <p-sortIcon [field]="'type'"></p-sortIcon>
        {{ 'COMMON.TYPE' | translate }}
      </th>
      <th pResizableColumn>
        <p-sortIcon [field]="'description'"></p-sortIcon>
        {{ 'COMMON.DESCRIPTION' | translate }}
      </th>
      <th pResizableColumn>
        <p-sortIcon [field]="'version'"></p-sortIcon>
        {{ 'COMMON.VERSION' | translate }}
      </th>
      <th pResizableColumn>
        <p-sortIcon [field]="'price'"></p-sortIcon>
        {{ 'COMMON.PRICE' | translate }}
      </th>
      <th pResizableColumn>
        <p-sortIcon [field]="'group'"></p-sortIcon>
        {{ 'COMMON.GROUP' | translate }}
      </th>
      <th pResizableColumn>
        <p-sortIcon [field]="'enabled'"></p-sortIcon>
        {{ 'COMMON.ENABLED' | translate }}
      </th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-product>
    <tr (dblclick)="onRowDoubleClick(product)" [pSelectableRowDblClick]="product">
      <td>{{product.type}}</td>
      <td>{{product.description}}</td>
      <td>{{product.version}}</td>
      <td>{{product.price}}</td>
      <td>{{product.group}}</td>
      <td>{{product.enabled}}</td>
    </tr>
  </ng-template>
</p-table>
