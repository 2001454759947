import { AbstractGridFilter } from './abstract-grid-filter';

export class GridFilterDefault extends AbstractGridFilter {

  public onFocus(entity: any, event: any) {

  }

  public parseRemoteValue() {
    return this.meta.value;
  }

  public parseLocalValue() {
    return this.meta.value;
  }

  public isValid(): boolean {
    return true;
  }
}
