/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./toolbar-item-open-module-assignments-leased-employee.component";
import * as i2 from "../../../../../../../services/generic-crud.service";
import * as i3 from "../../../../../../../../core/message/message-growl.service";
import * as i4 from "../../../../../../services/double-click.service";
import * as i5 from "../../../../../../services/modules-state.service";
import * as i6 from "../../../../../../services/navigation/module-navigation.service";
var styles_ToolbarItemOpenModuleAssignmentsLeasedEmployeeComponent = [];
var RenderType_ToolbarItemOpenModuleAssignmentsLeasedEmployeeComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_ToolbarItemOpenModuleAssignmentsLeasedEmployeeComponent, data: {} });
export { RenderType_ToolbarItemOpenModuleAssignmentsLeasedEmployeeComponent as RenderType_ToolbarItemOpenModuleAssignmentsLeasedEmployeeComponent };
export function View_ToolbarItemOpenModuleAssignmentsLeasedEmployeeComponent_0(_l) { return i0.ɵvid(0, [], null, null); }
export function View_ToolbarItemOpenModuleAssignmentsLeasedEmployeeComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-toolbar-item-open-module-assignments-leased-employee", [], null, null, null, View_ToolbarItemOpenModuleAssignmentsLeasedEmployeeComponent_0, RenderType_ToolbarItemOpenModuleAssignmentsLeasedEmployeeComponent)), i0.ɵdid(1, 245760, null, 0, i1.ToolbarItemOpenModuleAssignmentsLeasedEmployeeComponent, [i2.GenericCrudService, i3.MessageGrowlService, i4.DoubleClickService, i5.ModulesStateService, i6.ModuleNavigationService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ToolbarItemOpenModuleAssignmentsLeasedEmployeeComponentNgFactory = i0.ɵccf("app-toolbar-item-open-module-assignments-leased-employee", i1.ToolbarItemOpenModuleAssignmentsLeasedEmployeeComponent, View_ToolbarItemOpenModuleAssignmentsLeasedEmployeeComponent_Host_0, { params: "params" }, {}, []);
export { ToolbarItemOpenModuleAssignmentsLeasedEmployeeComponentNgFactory as ToolbarItemOpenModuleAssignmentsLeasedEmployeeComponentNgFactory };
