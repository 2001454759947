/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./element.component.ngfactory";
import * as i2 from "@angular/forms";
import * as i3 from "./element.component";
import * as i4 from "../form.service";
import * as i5 from "../form-viewer.service";
import * as i6 from "@ngx-translate/core";
import * as i7 from "../../services/datamodel/datamodel.crud.service";
import * as i8 from "@angular/common";
import * as i9 from "./element-layout-columns-item.component";
var styles_ElementLayoutColumnsItemComponent = [];
var RenderType_ElementLayoutColumnsItemComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_ElementLayoutColumnsItemComponent, data: {} });
export { RenderType_ElementLayoutColumnsItemComponent as RenderType_ElementLayoutColumnsItemComponent };
function View_ElementLayoutColumnsItemComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 5, null, null, null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 4, "app-form-element", [], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "actionParamsChange"], [null, "selectedElementChange"], [null, "showDialogAddAddressChange"], [null, "submit"], [null, "reset"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("submit" === en)) {
        var pd_0 = (i0.ɵnov(_v, 2).onSubmit($event) !== false);
        ad = (pd_0 && ad);
    } if (("reset" === en)) {
        var pd_1 = (i0.ɵnov(_v, 2).onReset() !== false);
        ad = (pd_1 && ad);
    } if (("actionParamsChange" === en)) {
        var pd_2 = ((_co.actionParams = $event) !== false);
        ad = (pd_2 && ad);
    } if (("selectedElementChange" === en)) {
        var pd_3 = ((_co.selectedElement = $event) !== false);
        ad = (pd_3 && ad);
    } if (("showDialogAddAddressChange" === en)) {
        var pd_4 = ((_co.showDialogAddAddress = $event) !== false);
        ad = (pd_4 && ad);
    } return ad; }, i1.View_ElementComponent_0, i1.RenderType_ElementComponent)), i0.ɵdid(2, 540672, null, 0, i2.FormGroupDirective, [[8, null], [8, null]], { form: [0, "form"] }, null), i0.ɵprd(2048, null, i2.ControlContainer, null, [i2.FormGroupDirective]), i0.ɵdid(4, 16384, null, 0, i2.NgControlStatusGroup, [[4, i2.ControlContainer]], null, null), i0.ɵdid(5, 4964352, null, 0, i3.ElementComponent, [i4.FormService, i0.ChangeDetectorRef, i5.FormViewerService, i6.TranslateService, i7.DatamodelCrudService], { form: [0, "form"], formGroup: [1, "formGroup"], entity: [2, "entity"], element: [3, "element"], moduleElement: [4, "moduleElement"], selectedElement: [5, "selectedElement"], showDialogAddAddress: [6, "showDialogAddAddress"], actionParams: [7, "actionParams"], editMode: [8, "editMode"] }, { selectedElementChange: "selectedElementChange", showDialogAddAddressChange: "showDialogAddAddressChange", actionParamsChange: "actionParamsChange" })], function (_ck, _v) { var _co = _v.component; var currVal_7 = _co.formGroup; _ck(_v, 2, 0, currVal_7); var currVal_8 = _co.form; var currVal_9 = _co.formGroup; var currVal_10 = _co.entity; var currVal_11 = _v.context.$implicit; var currVal_12 = _co.moduleElement; var currVal_13 = _co.selectedElement; var currVal_14 = _co.showDialogAddAddress; var currVal_15 = _co.actionParams; var currVal_16 = _co.editMode; _ck(_v, 5, 0, currVal_8, currVal_9, currVal_10, currVal_11, currVal_12, currVal_13, currVal_14, currVal_15, currVal_16); }, function (_ck, _v) { var currVal_0 = i0.ɵnov(_v, 4).ngClassUntouched; var currVal_1 = i0.ɵnov(_v, 4).ngClassTouched; var currVal_2 = i0.ɵnov(_v, 4).ngClassPristine; var currVal_3 = i0.ɵnov(_v, 4).ngClassDirty; var currVal_4 = i0.ɵnov(_v, 4).ngClassValid; var currVal_5 = i0.ɵnov(_v, 4).ngClassInvalid; var currVal_6 = i0.ɵnov(_v, 4).ngClassPending; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); }); }
function View_ElementLayoutColumnsItemComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ElementLayoutColumnsItemComponent_2)), i0.ɵdid(2, 278528, null, 0, i8.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i0.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.element.children; _ck(_v, 2, 0, currVal_0); }, null); }
export function View_ElementLayoutColumnsItemComponent_0(_l) { return i0.ɵvid(2, [(_l()(), i0.ɵand(16777216, null, null, 1, null, View_ElementLayoutColumnsItemComponent_1)), i0.ɵdid(1, 16384, null, 0, i8.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.element.children.length > 0); _ck(_v, 1, 0, currVal_0); }, null); }
export function View_ElementLayoutColumnsItemComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-form-element-layout-columns-item", [], null, null, null, View_ElementLayoutColumnsItemComponent_0, RenderType_ElementLayoutColumnsItemComponent)), i0.ɵdid(1, 4964352, null, 0, i9.ElementLayoutColumnsItemComponent, [i4.FormService, i0.ChangeDetectorRef, i5.FormViewerService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ElementLayoutColumnsItemComponentNgFactory = i0.ɵccf("app-form-element-layout-columns-item", i9.ElementLayoutColumnsItemComponent, View_ElementLayoutColumnsItemComponent_Host_0, { form: "form", formGroup: "formGroup", entity: "entity", element: "element", moduleElement: "moduleElement", selectedElement: "selectedElement", showDialogAddAddress: "showDialogAddAddress", actionParams: "actionParams", editMode: "editMode" }, { selectedElementChange: "selectedElementChange", showDialogAddAddressChange: "showDialogAddAddressChange", actionParamsChange: "actionParamsChange" }, []);
export { ElementLayoutColumnsItemComponentNgFactory as ElementLayoutColumnsItemComponentNgFactory };
