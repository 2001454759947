import {AbstractFormActionHandler} from '../abstract-form-action-handler';
import {GenericCrudService} from '../../../../services/generic-crud.service';
import {Branch} from '../../../../services/branch/branch';
import {UserSessionService} from '../../../../../core/service/user-session.service';
import {FormViewerComponent} from '../../../form-viewer.component';

interface ContingentInfo {
  free: number;
  type: string;
  available: number;
}

export class HandleJobAdvertisementContingentsActionHandler extends AbstractFormActionHandler {

  private mapping = {
    'Regionaljobs': [
      'regionalJobs'
    ],
    'Steirerjobs': [
      'steierJobs'
    ],
    'Salzburgerjobs': [
      'salzburgJobs'
    ],
    'Rheintaljob': [
      'rheintalJobs'
    ],
    'Willhaben': [
      'willhaben'
    ],
    'Karriere': [
      'karriereAt'
    ],
    'Stepstone': [
      'stepstone'
    ]
  };

  private mappingText = {
    'Regionaljobs': 'regionalJobContingent',
    'Steirerjobs': 'steierJobContingent',
    'Salzburgerjobs': 'salzburgJobContingent',
    'Rheintaljob': 'rheintalJobContingent',
    'Willhaben': 'willhabenContingent',
    'Karriere': 'karriereContingent',
    'Stepstone': 'stepstoneContingent'
  };

  public constructor(protected genericCrudService: GenericCrudService, protected userSession: UserSessionService) {
    super();
  }

  public handleAction(): void {
    const jobAdvertisement = this.getEntity();

    if (this.isValid()) {
      const currentBranchOffice = this.userSession.get(Branch.LOCAL_STORAGE_NAME);
      this.genericCrudService.getEntity('phoenix/publishercontigents/publisheroverview', currentBranchOffice.id).subscribe(contingentData => {
        for (const publisherName in contingentData) {
          if (contingentData.hasOwnProperty(publisherName) && this.mapping[publisherName]) {
            if (contingentData[publisherName]['free'] > 0) {
              this.showFields(this.mapping[publisherName]);
            } else {
              this.hideFields(this.mapping[publisherName]);
            }

            this.writeMessage(this.mappingText[publisherName], contingentData[publisherName]);
          }
        }
      });
    }
  }

  private isValid(): boolean {
    return this.getEntity();
  }

  private showFields(datamodelFieldNames: string[] = []): void {
    for (const datamodelFieldName of datamodelFieldNames) {
      const element = this.formService.getElementBy(this.getForm(), 'datamodelField', datamodelFieldName);

      if (element) {
        element.isHidden = false;
        this.markElementForCheck(element);
      }
    }
  }

  private hideFields(datamodelFieldNames: string[] = []): void {
    for (const datamodelFieldName of datamodelFieldNames) {
      const element = this.formService.getElementBy(this.getForm(), 'datamodelField', datamodelFieldName);

      if (element) {
        element.isHidden = true;
        this.markElementForCheck(element);
      }
    }
  }

  private writeMessage(datamodelFieldName: string, contingentData: ContingentInfo): void {
    const element = this.formService.getElementBy(this.getForm(), 'datamodelField', datamodelFieldName);
    const elementControlFieldName = FormViewerComponent.getFormControlName(element.objectHashId, datamodelFieldName);
    const elementControlField = this.getFormGroup().get(elementControlFieldName);
    const elementComponent = this.formService.getComponentByElement(element);

    const message = this.getMessage(contingentData);
    elementControlField.setValue(message);
    elementComponent.setValue(message, false);
    this.formService.validate(this.getForm());
    this.markElementForCheck(element);
  }

  private getMessage(contingentData: ContingentInfo): string {
    if (contingentData.free === 0) {
      return contingentData.type === 'kont' ? 'Es sind keine Inserate verbleibend' : `Es sind ${contingentData.available} von ${contingentData.available} verbraucht`;
    }
    const remaining = contingentData.available - contingentData.free;
    return contingentData.type === 'kont' ? `Es sind ${contingentData.free} von ${contingentData.available} verbleibend` : `Es sind ${remaining} von ${contingentData.available} verbraucht`;
  }
}
