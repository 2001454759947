import {Injectable} from '@angular/core';
import {FormViewerComponent} from '../form-viewer.component';
import {Constants} from '../../../constants';

@Injectable()
export class FormViewerFocusService {

  public formComponent: FormViewerComponent = null;

  public focusNext(): void {
    const activeElement = this.formComponent.elementRef.nativeElement.ownerDocument.activeElement,
      formElement = this.formComponent.formElement;

    if (activeElement && formElement.nativeElement && formElement.nativeElement.elements) {
      const formElements = Array.from(formElement.nativeElement.elements);

      if (this.isElementWithinFormElements(activeElement, formElements)) {
        const nextElement = this.getNextElement(activeElement, formElements);

        if (nextElement) {
          this.invokeElementMethod(nextElement, 'focus');
        }
      }
    }
  }

  public focusPrevious(): void {
    const activeElement = this.formComponent.elementRef.nativeElement.ownerDocument.activeElement,
      formElement = this.formComponent.formElement;

    if (activeElement && formElement.nativeElement && formElement.nativeElement.elements) {
      const formElements = Array.from(formElement.nativeElement.elements);

      if (this.isElementWithinFormElements(activeElement, formElements)) {
        const previousElement = this.getPreviousElement(activeElement, formElements);

        if (previousElement) {
          this.invokeElementMethod(previousElement, 'focus');
        }
      }
    }
  }

  private getNextElement(activeElement, formElements): any {
    const index = formElements.indexOf(activeElement),
      nextElement = formElements[index + 1];

    if (this.isElementValidToFocusOn(nextElement)) {
      return nextElement;
    }

    if (nextElement) {
      return this.getNextElement(nextElement, formElements);
    }

    return null;
  }

  private getPreviousElement(activeElement, formElements): any {
    const index = formElements.indexOf(activeElement),
      previousElement = formElements[index - 1];

    if (this.isElementValidToFocusOn(previousElement)) {
      return previousElement;
    }

    if (previousElement) {
      return this.getPreviousElement(previousElement, formElements);
    }

    return null;
  }

  private isElementValidToFocusOn(element): boolean {
    let isValidToFocusOn = element && !element.hidden && element.style.display !== 'hidden' &&
      element.classList && element.classList.contains(Constants.UI_FORM_ELEMENT_INPUT_CLASS) &&
      !element.readOnly && element.offsetParent !== null;

    if (isValidToFocusOn) {
      const parent = this.findAncestor(element, Constants.UI_FORM_ELEMENT_INPUT_CONTAINER_CLASS);

      if (parent && parent.classList && parent.classList.contains(Constants.UI_FORM_ELEMENT_INPUT_CONTAINER_HIDDEN_CLASS)) {
        isValidToFocusOn = false;
      }
    }

    return isValidToFocusOn;
  }

  private isElementWithinFormElements(element, formElements): boolean {
    return formElements.indexOf(element) !== -1;
  }

  private invokeElementMethod(element: any, methodName: string, args?: any[]): void {
    (element as any)[methodName].apply(element, args);
  }

  private findAncestor (element, className) {
    while ((element = element.parentElement) && !element.classList.contains(className));
    return element;
  }
}
