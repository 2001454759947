import {Pipe, PipeTransform} from '@angular/core';

@Pipe({ name: 'miniHtml', pure: true })
export class MiniHtmlPipe implements PipeTransform {

  transform(value: string): any {
    if ('' === value || null === value || typeof value === 'undefined') {
      return '';
    }

    value = value.replace(/<img[^>]*>/g, '');

    return value.substring(0, 400);
  };
}
