import { AbstractFormActionConditionHandler } from './abstract-form-action-condition-handler';
import { Entity } from '../../../helpers/entity';

export class IsEntityValueNotEqualConditionHandler extends AbstractFormActionConditionHandler {

    public isValid(): boolean {
        let isValid = false;

        const datamodelField = this.getParamValue('datamodelField'),
            entity = this.getEntity();

        let isValidValueCondition = this.getParamValue('entityValue') || null;

        if (isValidValueCondition === 'true') {
          isValidValueCondition = true;
        }

        if (isValidValueCondition === 'false') {
          isValidValueCondition = false;
        }

        if (entity && datamodelField) {
            isValid = Entity.getValue(entity, datamodelField) !== isValidValueCondition;
        }

        return isValid;
    }
}
