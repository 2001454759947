import { of as observableOf } from 'rxjs';
import { map } from 'rxjs/operators';
import { AbstractExecutionStep } from '../../../../core/executor/abstract-execution-step';
import { GenericTreeGridComponent } from '../../../content-renderer/elements/generic-tree-grid/generic-tree-grid.component';
import { EntityManagerService } from '../../../../core/service/entity-manager/entity-manager.service';
export class CurriculumTreeAddEntryPositionToAddedEntityExecutionStep extends AbstractExecutionStep {
    constructor() {
        super(...arguments);
        this.entityManager = null;
    }
    doExecute() {
        const payload = this.getPayload(), component = payload.getValue().component ? payload.getValue().component : payload.getValue();
        this.entityManager = this.injector.get(EntityManagerService, null);
        const node = payload.getValue().node, entity = payload.getValue().entity;
        if (!(component instanceof GenericTreeGridComponent)) {
            return this.getFailObservable('You need to pass GenericTreeGridComponent as Payload value!');
        }
        if (entity && entity.leasedEmployee && node) {
            return this.doSetup(component, entity, node);
        }
        return this.getFailObservable('Configuration is wrong!');
    }
    doSetup(component, entity, node) {
        let route = `phoenix/curriculums/nextentryposition/${entity.leasedEmployee}`;
        if (node && node.parent && node.parent.data && node.parent.data.id) {
            route += `/${node.parent.data.id}`;
        }
        if (this.parentIsNew(node)) {
            const newNodesCount = this.getNewNodesCount(component, node);
            this.assignEntryPosition(entity, node, newNodesCount);
            return observableOf({ status: true, content: null });
        }
        else {
            const newNodesCount = this.getNewNodesCount(component, node) - 1;
            return this.genericCrudService.get(route).pipe(map((nextEntryPosition) => {
                const calculatedEntryPosition = nextEntryPosition + newNodesCount;
                this.assignEntryPosition(entity, node, calculatedEntryPosition);
                return { status: true, content: null };
            }));
        }
    }
    getNewNodesCount(component, node) {
        if (node.parent && node.parent.data && node.parent.data.uniqueId) {
            return this.getNewNodesCountInParentNode(node.parent);
        }
        return this.getNewNodesCountInFirstLevel(component);
    }
    getNewNodesCountInFirstLevel(component) {
        const nodes = component.entities;
        let count = 0;
        for (const node of nodes) {
            if (node && node.data && !node.data.id) {
                count++;
            }
        }
        return count;
    }
    getNewNodesCountInParentNode(parent) {
        let count = 0;
        if (parent.children) {
            for (const node of parent.children) {
                if (node && node.data && !node.data.id) {
                    count++;
                }
            }
        }
        return count;
    }
    parentIsNew(node) {
        return node && node.parent && node.parent.data && !node.parent.data.id;
    }
    assignEntryPosition(entity, node, entryPosition) {
        this.entityManager.persist(entity, { property: 'entryPosition', newValue: entryPosition, force: true });
        if (node.data) {
            this.entityManager.persist(node.data, { property: 'entryPosition', newValue: entryPosition, force: true });
        }
    }
}
