
import {map} from 'rxjs/operators';
import {Observable} from 'rxjs';
import {AbstractExecutionStep} from '../../../../core/executor/abstract-execution-step';
import {ExecutionStepStatus} from '../../../../core/executor/execution-step-status';
import {ElementSaveStatus, GenericElementAbstract} from '../../../content-renderer/elements/generic-element-abstract.component';
import {MemoFieldDefinitionValueComponent} from '../../../content-renderer/elements/custom/memo-field-definition-value/memo-field-definition-value.component';
import {Entity} from '../../../helpers/entity';
import {SetupMemoFieldDefinitionValueComponentExecutionStep} from '../memo/setup-memo-field-definition-value-component-execution.step';

export class CloseWorkflowStepExecutionStep extends AbstractExecutionStep {

  public doExecute(): Observable<ExecutionStepStatus> {
    const payload = this.getPayload();
    const entity = payload.getValue();

    return Observable.create(observer => {
      this.genericCrudService.get('workflow/execute/' + entity.id).subscribe();
    });
  }
}
