
import {of as observableOf, Observable} from 'rxjs';
import {AbstractExecutionStep} from '../../../core/executor/abstract-execution-step';
import {ExecutionStepStatus} from '../../../core/executor/execution-step-status';
import {GenericGridComponent} from '../../content-renderer/elements/generic-grid/generic-grid.component';
import {AbstractGenericGridComponent} from '../../content-renderer/elements/abstract-generic-grid.component';

export class ReplaceEntitiesWithSelectedStep extends AbstractExecutionStep {

  doExecute(): Observable<ExecutionStepStatus> {
    const payload = this.getPayload();

    let component = payload.getValue();

    if (payload instanceof Object && payload.getValue().component) {
      component = payload.getValue().component;
    }

    if (!(component instanceof AbstractGenericGridComponent)) {
      return this.getFailObservable('You need to pass AbstractGenericGridComponent as Payload value!');
    }

    return this.doReplaceSelected(component);
  }

  private doReplaceSelected(component: AbstractGenericGridComponent): Observable<ExecutionStepStatus> {

    if(component.entities && component.selectedEntities) {
      for (let selectedEntity of component.selectedEntities ) {
        let entityToReplace = component.entities.findIndex((entity) => {
          return entity.id === selectedEntity.id
        });
        component.entities[entityToReplace] = selectedEntity;
      }
      return observableOf({status: true, content: null});
    }

    return this.getFailObservable('You need to pass AbstractGenericGridComponent as Payload value!');
  }
}
