
import {map} from 'rxjs/operators';
import {Observable} from 'rxjs';
import {AbstractExecutionStep} from '../../../../core/executor/abstract-execution-step';
import {ExecutionStepStatus} from '../../../../core/executor/execution-step-status';
import {GenericElementAbstract} from '../../../content-renderer/elements/generic-element-abstract.component';
import {MessageGrowlService} from '../../../../core/message/message-growl.service';
import {TranslateService} from '@ngx-translate/core';

export class ToolbarItemCollectiveAgreementActivateExecutionStep extends AbstractExecutionStep {

  public doExecute(): Observable<ExecutionStepStatus> {
    const payload = this.getPayload(),
      component = payload.getValue().component ? payload.getValue().component : payload.getValue();

    if (!(component instanceof GenericElementAbstract)) {
      return this.getFailObservable('You need to pass GenericElementAbstract as Payload value!');
    }

    const entity = component.getSelectedEntity();
    if (entity && (entity.fqn !== 'PhoenixBundle\\Entity\\CollectiveAgreement' || !entity.id)) {
      return this.getFailObservable('You need to pass existing CollectiveAgreement entity!');
    }

    return this.doActivate(component);
  }

  doActivate(component: GenericElementAbstract): Observable<ExecutionStepStatus> {
    const collectiveAgreement = component.getSelectedEntity();

    return this.genericCrudService.customPut(`phoenix/collectiveagreements/${collectiveAgreement.id}/activate`, collectiveAgreement).pipe(
      map(() => {
        this.getGrowl().success(
          this.getTranslate().instant('COMMON.DATA_SAVED'),
          this.getTranslate().instant('COMMON.SUCCESS')
        );

        return {status: true, content: null};
      }));
  }

  private getGrowl(): MessageGrowlService {
    return this.injector.get(MessageGrowlService, null);
  }

  private getTranslate(): TranslateService {
    return this.injector.get(TranslateService, null);
  }
}
