<div id="main-container" *ngIf="fileData">
  <div id="file-container" [ngSwitch]="fileData.fileType">
    <ng-template ngSwitchCase="docx">
      <app-generic-file-preview-docx [fileData]="fileData"></app-generic-file-preview-docx>
    </ng-template>
    <ng-template ngSwitchCase="image">
      <app-generic-file-preview-image [fileData]="fileData"></app-generic-file-preview-image>
    </ng-template>
    <ng-template ngSwitchCase="pdf">
      <app-generic-file-preview-pdf [fileData]="fileData"></app-generic-file-preview-pdf>
    </ng-template>
    <ng-template ngSwitchCase="text">
      <app-generic-file-preview-text [fileData]="fileData"></app-generic-file-preview-text>
    </ng-template>
  </div>
</div>

<div *ngIf="!fileData.fileType">
  Cannot find file type!
</div>
