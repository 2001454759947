import {ChangeDetectionStrategy, Component} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {MessageGrowlService} from '../../../../../../../core/message/message-growl.service';
import {ExecutionStepPayload} from '../../../../../../../core/executor/execution-step-payload';
import {ExecutionStepFactoryService} from '../../../../../../../core/executor/factory/execution-step-factory.service';
import {ExecutorService} from '../../../../../../../core/executor/executor.service';
import {ToolbarItemSave} from '../save/toolbar-item-save.component';
import {ToolbarItemSaveService} from '../../services/toolbar-item-save.service';
import {GenericCrudBulkService} from '../../../../../../services/generic-crud-bulk.service';
import {ComponentService} from '../../../../../services/component-highlight-stack.service';
import {MessageService} from '../../../../../../../core/message/message.service';
import {ModulesStateService} from '../../../../../services/modules-state.service';
import {GenericDialogModuleService} from '../../../../generic-dialog/service/generic-dialog-module.service';
import {ToastService} from '../../../../../../../core/service/toast.service';
import {EntityManagerService} from '../../../../../../../core/service/entity-manager/entity-manager.service';
import {ExecutionStep} from '../../../../../../../core/executor/execution-step';
import {
  RegisterSendMemoMessageExecutionStep
} from '../../../../../../services/execution-step/memo/register-send-memo-message-execution.step';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-toolbar-item-save-and-send-workflow',
  template: '',
  providers: [
    ToolbarItemSaveService,
    ExecutorService
  ]
})
export class ToolbarItemSaveAndSendWorkflowComponent extends ToolbarItemSave {

  constructor(
    protected genericCrudBulkService: GenericCrudBulkService,
    protected messageGrowlService: MessageGrowlService,
    protected componentService: ComponentService,
    protected translateService: TranslateService,
    protected messageService: MessageService,
    protected toolbarItemSaveService: ToolbarItemSaveService,
    protected executorService: ExecutorService,
    protected executionStepFactoryService: ExecutionStepFactoryService,
    protected modulesStateService: ModulesStateService,
    protected genericDialogModuleService: GenericDialogModuleService,
    protected toastService: ToastService,
    protected entityManager: EntityManagerService
  ) {
    super(
      genericCrudBulkService,
      messageGrowlService,
      componentService,
      translateService,
      messageService,
      toolbarItemSaveService,
      executorService,
      executionStepFactoryService,
      modulesStateService,
      genericDialogModuleService,
      toastService
    );
  }


  protected getAdditionalSteps(): Array<ExecutionStep> {
    const steps = super.getAdditionalSteps();

    steps.push(this.executionStepFactoryService.create(RegisterSendMemoMessageExecutionStep, new ExecutionStepPayload(this.getComponent())));

    return steps;
  }
}
