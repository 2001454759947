import {AfterViewInit, Component, ElementRef, OnInit} from '@angular/core';
import {AbstractGridColumnComponent} from '../../abstract-grid-column.component';
import {Entity} from '../../../../../../helpers/entity';

@Component({
  selector: 'app-inquiry-position-counter-column',
  templateUrl: './inquiry-position-counter-column.component.html'
})
export class InquiryPositionCounterColumnComponent extends AbstractGridColumnComponent implements OnInit, AfterViewInit {

  public backgroundColor = '';
  public preText = '';
  public entityValue = null;

  public constructor(
    protected elementRef: ElementRef
  ) {
    super(elementRef);
  }

  public ngOnInit(): void {
    this.backgroundColor = this.getBackgroundColor();
    this.preText = this.getPretext();
    this.entityValue = this.getEntityValue();
  }

  public ngAfterViewInit(): void {
    const td = this.elementRef.nativeElement.closest('td');

    if (td) {
      td.style.padding = 0;
      td.style.margin = 0;
      td.style.verticalAlign = 'top';
    }
  }

  public getBackgroundColor(): string {
    const colorValueConfig = this.findConfig('colorValue');

    return colorValueConfig && colorValueConfig.value ? colorValueConfig.value : '';
  }

  public getPretext(): string {
    const preTextConfig = this.findConfig('preText');

    return preTextConfig && preTextConfig.value ? preTextConfig.value : '';
  }

  public getEntityValue(): any {
    const entityProperty = this.getConfigValue('entityProperty');

    return Entity.getValue(this.entity, entityProperty);
  }
}
