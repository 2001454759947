import {ChangeDetectionStrategy, Component} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {LoggerService} from 'app/shared/content-renderer/services/logger/logger.service';
import {AbstractGenericGridComponent} from 'app/shared/content-renderer/elements/abstract-generic-grid.component';
import {GenericElementAbstract} from 'app/shared/content-renderer/elements/generic-element-abstract.component';
import {ToolbarItemAbstract} from '../../toolbar-item-abstract.component';
import {ComponentService} from '../../../../../../services/component-highlight-stack.service';
import {MessageGrowlService} from '../../../../../../../../core/message/message-growl.service';
import {MessageService} from '../../../../../../../../core/message/message.service';
import {GenericCrudService} from '../../../../../../../services/generic-crud.service';
import {ApiBuilderService} from '../../../../../../../services/api.builder.service';
import {EntityDataStoreService} from '../../../../../../services/entity-data-store.service';
import {ExecutorService} from '../../../../../../../../core/executor/executor.service';
import {TOAST_LIFE_INFINITE, ToastComponentsRegistry, ToastService} from '../../../../../../../../core/service/toast.service';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'toolbar-item-entity-preview',
  template: ``
})
export class ToolbarEntityPreviewComponent extends ToolbarItemAbstract {
  constructor(
    private componentService: ComponentService,
    private messageGrowlService: MessageGrowlService,
    private messageService: MessageService,
    private genericCrudService: GenericCrudService,
    private apiBuilder: ApiBuilderService,
    private translationService: TranslateService,
    private entityDataStore: EntityDataStoreService,
    private logger: LoggerService,
    private executorService: ExecutorService,
    private toastService: ToastService,
    private translateService: TranslateService
  ) {
    super();
  }

  public click() {
    const component = this.getComponent();

    if (!(component instanceof AbstractGenericGridComponent)) {
      return;
    }

    this.validateEntities(component, component.entities.filter(entity => entity.isValidated));
  }

  private validateEntities(component: GenericElementAbstract, entities: any[]) {

    if (entities.length === 0) {
      return this.messageGrowlService.error(this.translationService.instant('COMMON.SELECTION_EMPTY'));
    }

    this.messageService.confirm({
      header: this.translateService.instant('COMMON.VALIDATION_HEADER'),
      message: this.translateService.instant('COMMON.VALIDATION_MESSAGE'),
      icon: 'fa fa-plus',
      accept: () => {
        this.doValidateEntities(entities);
      }
    });
  }

  private doValidateEntities(entities: any[]) {
    const baseApiRoute = this.getComponent().getElementDataModelApiRoute();

    this.toastService.custom(ToastComponentsRegistry.PROGRESS_BAR, {
      severity: 'info',
      life: TOAST_LIFE_INFINITE,
      closable: false,
      summary: this.translateService.instant('COMMON.VALIDATING_PLEASE_WAIT')
    });
    this.genericCrudService.customPost(`${baseApiRoute}/validate`, entities).toPromise()
      .then(() => {
        this.toastService.clear(ToastComponentsRegistry.PROGRESS_BAR, 300);
        this.onEntitiesValidated();
        this.messageGrowlService.success('Success.');
      }).catch((error) => {
        this.toastService.clear(ToastComponentsRegistry.PROGRESS_BAR, 300);
        console.error(error);
      });
  }

  public onEntitiesValidated() {
    const component = this.getComponent();
    if (component instanceof AbstractGenericGridComponent) {
      component.loadEntities().subscribe();
    }
  }
}
