<p-dialog *ngIf="isDialogVisible" (onAfterHide)="dialogHide()" appendTo="body"
          [(visible)]="isDialogVisible" showEffect="fade" header="Upload"
          [modal]="true" [width]="500" [height]="550">
  <div class="ui-grid ui-grid-responsive ui-fluid">
    <div class="ui-grid-row">
      <div class="ui-grid-col-8">
        <p-fileUpload
          name="file[]"
          multiple="multiple"
          withCredentials="true"
          (onUpload)="onUpload($event)"
          url="{{baseUrl}}/api/phoenix/printtemplates/{{selectedEntity.id}}/xmlupload?organisationId={{organisationId}}"
          accept=".xml"
          maxFileSize="100000000"
          chooseLabel="{{ 'COMMON.CHOOSE' | translate }}"
          cancelLabel="{{ 'COMMON.CANCEL' | translate }}"
          uploadLabel="{{ 'COMMON.UPLOAD' | translate }}"
        >
      </p-fileUpload></div>
    </div>
  </div>
</p-dialog>
