/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./field-action-open-condition-module.component";
import * as i2 from "../../../../../services/generic-crud.service";
import * as i3 from "../../../../services/double-click.service";
import * as i4 from "../../../generic-dialog/service/generic-dialog-module.service";
import * as i5 from "../../../../../../core/executor/factory/execution-step-factory.service";
import * as i6 from "../../../../../../core/job-runner/job-container.service";
import * as i7 from "../../../../services/modules-state.service";
import * as i8 from "../../../../../../core/service/entity-manager/entity-manager.service";
var styles_FieldActionOpenConditionModuleComponent = [];
var RenderType_FieldActionOpenConditionModuleComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_FieldActionOpenConditionModuleComponent, data: {} });
export { RenderType_FieldActionOpenConditionModuleComponent as RenderType_FieldActionOpenConditionModuleComponent };
export function View_FieldActionOpenConditionModuleComponent_0(_l) { return i0.ɵvid(0, [], null, null); }
export function View_FieldActionOpenConditionModuleComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "field-action-open-condition-module", [], null, null, null, View_FieldActionOpenConditionModuleComponent_0, RenderType_FieldActionOpenConditionModuleComponent)), i0.ɵdid(1, 245760, null, 0, i1.FieldActionOpenConditionModuleComponent, [i2.GenericCrudService, i3.DoubleClickService, i4.GenericDialogModuleService, i5.ExecutionStepFactoryService, i6.JobContainerService, i7.ModulesStateService, i8.EntityManagerService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var FieldActionOpenConditionModuleComponentNgFactory = i0.ɵccf("field-action-open-condition-module", i1.FieldActionOpenConditionModuleComponent, View_FieldActionOpenConditionModuleComponent_Host_0, { action: "action", field: "field", grid: "grid" }, {}, []);
export { FieldActionOpenConditionModuleComponentNgFactory as FieldActionOpenConditionModuleComponentNgFactory };
