import {
  Component, Input, OnInit
} from '@angular/core';
import {SimpleSearchField} from '../../../../../../services/simple-search/simple-search';
import {AbstractGridFilter} from '../../../../generic-grid/filters/abstract-grid-filter';

@Component({
  selector: 'app-postal-code-search',
  template: `
    <div class="ui-g-6">
      <input
        pInputText
        type="text"
        placeholder="---"
        [(ngModel)]="field.value.value"
        (keyup)="onPostalCodeChange($event)"
      />
    </div>
  `
})
export class PostalCodeSearchComponent implements OnInit {
  @Input() public field: SimpleSearchField = null;

  public ngOnInit() {
    this.initDefaultValues();
  }

  public onPostalCodeChange(event): void {
    this.field.value.value = event.target.value;
    this.field.searchValue = event.target.value;
    this.field.skipSearch = !this.field.searchValue;
  }

  private initDefaultValues(): void {
    this.field.comparator = AbstractGridFilter.MATCH_MODE_CONTAINS;
    this.field.value = this.field.value && this.field.value.context === 'postalCode' ? this.field.value : {};
    this.field.value.context = 'postalCode';

    this.field.searchKey = this.getSearchKey();
    this.field.embed = this.getEmbed();
    this.field.skipSearch = !this.field.searchValue;
  }

  private getEmbed(): string {
    const entityName = this.field.meta.entityName;

    if (entityName === 'mainAddress') {
      return 'addresses';
    }

    return 'locality';
  }

  private getSearchKey(): string {
    const entityName = this.field.meta.entityName;

    if (entityName === 'mainAddress') {
      return 'addresses.postalCode';
    }

    return 'locality.postalCode';
  }
}
