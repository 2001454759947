import {ChangeDetectionStrategy, Component} from '@angular/core';
import { ToolbarItemAbstract } from '../toolbar-item-abstract.component';
import { MessageGrowlService } from '../../../../../../../core/message/message-growl.service';
import { DoubleClickService } from '../../../../../services/double-click.service';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'toolbar-item-open-element',
  templateUrl: './toolbar-item-open-element.component.html'
})
export class ToolbarItemOpenElement extends ToolbarItemAbstract {

  private entity: any;

  constructor(
    private messageGrowlService: MessageGrowlService,
    private doubleClickService: DoubleClickService
  ) {
    super();
  }

  public click() {
    if (this.isInCurrentContext(ToolbarItemAbstract.CONTEXT_GRID)) {
      this.handleClickInGridContext();
    }
  }

  protected handleClickInGridContext() {
    if (this.getToolbarItem().onclick === 'edit') {
      this.entity = this.getParam('gridComponent').selectedEntity;
    } else {
      // Let's get the empty entity from the grid:
      this.entity = this.getParam('gridComponent').emptyEntity;
    }

    if (!this.isToolbarItemValidInGridContext(this.getParam('gridComponent'))) {
      return this.messageGrowlService.error(this.getValidateMessage());
    }

    this.doubleClickService.handleOpenElement(this.getParam('gridComponent'), this.getToolbarItem().actionElementId);
  }

  protected isToolbarItemValidInGridContext(gridComponent) {
    let isValid = true;

    if (!this.getParam('gridComponent').element.datamodel || !this.getParam('gridComponent').element.datamodel.apiRoute) {
      console.log('Module element has no datamodel assigned!');

      isValid = false;
    } else if (!this.getToolbarItem().actionElementId) {
      this.setValidateMessage('Action element not assigned!');

      isValid = false;
    } else if (this.getToolbarItem().onclick === 'edit' && !this.entity) {
      this.setValidateMessage('Edit called but no entity has been selected!');

      isValid = false;
    }

    return isValid;
  }
}
