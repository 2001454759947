import {ChangeDetectionStrategy, Component, Input, OnInit} from '@angular/core';
import {ToolbarItemAbstract} from '../toolbar-item-abstract.component';
import {ToolbarItem} from '../../../../../../services/element/toolbar-item';
import {AbstractGenericGridComponent} from '../../../../abstract-generic-grid.component';
import {ExecutionStatus} from '../../../../../../../core/executor/execution-status';
import {FormViewerComponent} from '../../../../../../form-viewer/form-viewer.component';
import {MessageGrowlService} from '../../../../../../../core/message/message-growl.service';
import {FormService} from '../../../../../../form-viewer/form.service';
import {ElementInputCheckboxComponent} from '../../../../../../form-viewer/element/element-input-checkbox.component';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'toolbar-item-unlock-form',
  template: ''
})
export class ToolbarItemUnlockFormComponent extends ToolbarItemAbstract {

  private entity: any;

  constructor(
    protected formService: FormService,
  ) {
    super();
  }

  ngOnInit() {

  }

  public click() {
    const component = this.getComponent();

    if (component instanceof FormViewerComponent) {
      this.entity = component.getSelectedEntity();
      if (this.entity.fqn === 'PhoenixBundle\\Entity\\ContractOfEmployment') {
        const fieldElement = this.formService.getElementBy(component.form, 'datamodelField', 'isFrontEndLocked'),
          elementControlFieldName = FormViewerComponent.getFormControlName(fieldElement.objectHashId, fieldElement.datamodelField),
          elementFieldComponent = this.formService.getComponent(elementControlFieldName);

        if (elementFieldComponent instanceof ElementInputCheckboxComponent) {
          elementFieldComponent.setValue(false, true);
        }
      }
    }
  }
}
