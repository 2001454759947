import { Element } from './../element/element';
// import { ElementType } from './element-type';
// import { ToolbarItem } from './toolbar-item';
// import { DataModelFilter } from './data-model-filter';
// import { Datamodel } from './../datamodel/datamodel';

// @todo i think this is useless..

export class ElementForm extends Element {
  // id;
  // name: string;
  // label: string;
  // content: string;
  // datamodelId: number;
  // elementType: ElementType;
  // elementTypeId: number;
  // datamodel: Datamodel;
  // toolbarItems: ToolbarItem[];
  // datamodelFilters: DataModelFilter[];
  constructor() {
    super();
  }
}
