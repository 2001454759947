
import {throwError as observableThrowError, Subject, Observable} from 'rxjs';

import {catchError, takeUntil, map} from 'rxjs/operators';
import {
  Component,
  OnDestroy,
  AfterViewInit,
  Renderer,
  ElementRef,
  OnInit,
  Input,
  ViewChild,
  ChangeDetectionStrategy,
  ChangeDetectorRef
} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {ElementInputComponent} from './element-input.component';
import {FormService} from '../form.service';
import {FormViewerService} from '../form-viewer.service';
import {ElementInputAutocomplete} from '../models';
import {GenericCrudService} from '../../services/generic-crud.service';
import {ElementService} from '../../../form-editor/shared/services';
import {ElementsStackService } from '../../content-renderer/services/elements-stack.service';
import {ElementContext, ElementType} from 'app/shared/content-renderer/services/ElementContext';
import {ComponentSelectedOptionAware } from './abstract-element.component';
import {EntityDraftStoreService} from '../../content-renderer/services/entity-draft-store.service';
import {EntityStatus} from '../../services/entity/entity-status';
import {Entity} from '../../helpers/entity';
import {DatamodelCrudService} from '../../services/datamodel/datamodel.crud.service';
import {AutoComplete} from 'primeng/primeng';
import {GenericElementAbstract} from '../../content-renderer/elements/generic-element-abstract.component';
import {ChangeDetectorRefHelper} from '../../helpers/change-detector-ref.helper';
import {AuthenticationService} from '../../../core/authentication/authentication.service';
import {UserSessionService} from '../../../core/service/user-session.service';
import {Branch} from '../../services/branch/branch';

export interface SelectItemExtended {
    label: string;
    value: any;
    entity?: any;
    id?: any;
}

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-form-element-input-autocomplete',
  template: `
    <div [formGroup]="formGroup" class="ui-g ui-g-12">
      <div
        [class]="element.hasCustomButton ||
        ((element?.icon | isDefined) &&
        (element?.icon?.relativePath | isDefined))? 'ui-g ui-g-11' : 'ui-g ui-g-12'"
      >
        <p-autoComplete
            appSeleniumDirective
            [element]="element"
            appAutocomplete
            [formControlName]="formControlName"
            [dropdown]="!isReadOnlyOrDisabled()"
            [readonly]="isReadOnly()"
            [suggestions]="suggestions"
            (completeMethod)="onSearch($event)"
            (onSelect)="onItemSelected($event)"
            (onKeyUp)="onKeyUpSearch($event)"
            [delay]="500"
            field="label"
            [placeholder]="this.element ? this.element.placeholder : ''"
            [minLength]="1"
            [inputTabIndex]="element.tabIndex"
            appendTo="body"
            #inputElement
        >
          <ng-template let-item pTemplate="item">
            <span
              [id]="'FormElementInput-' + element.label + '-Value-' + item.label"
            >
            {{ item.label }}
          </span>
          </ng-template>
        </p-autoComplete>
      </div>
      <div class="icon-container ui-g ui-g-1" *ngIf="((element?.icon | isDefined) && (element?.icon?.relativePath | isDefined))">
        <img height="16" width="16"
             [ngClass]="{
          'cur-pointer': isValid(),
          'cur-disabled': !isValid(),
          'disabled': !isValid()
        }"
             [src]="iconBaseUrl + '/' + element?.icon?.relativePath"
             [alt]="element?.icon?.name"
             (click)="onClickIcon()"
        />
      </div>
      <div *ngIf="element.hasCustomButton" class="custom-button-container ui-g ui-g-1">
        <i
          (click)="onCustomButtonClick($event)"
          [class]="isReadOnlyOrDisabled() ? 'fa fa-plus fa-disabled' : 'fa fa-plus'"
          aria-hidden="true"
        >
        </i>
      </div>
    </div>
  `,
  styles: [`
    :host {
      height: 100%;
    }

    .ui-g {
      height: 100%;
    }

    p-autocomplete ::ng-deep input[readonly].ui-widget-header .ui-inputtext.ui-state-default,
    p-autocomplete ::ng-deep input[readonly].ui-widget-content .ui-inputtext.ui-state-default,
    p-autocomplete ::ng-deep input[readonly].ui-inputtext.ui-state-default,
    p-autocomplete ::ng-deep input[readonly].ui-widget-header .ui-inputtext.ui-state-default:focus:not(:active):not(:hover),
    p-autocomplete ::ng-deep input[readonly].ui-widget-content .ui-inputtext.ui-state-default:focus:not(:active):not(:hover),
    p-autocomplete ::ng-deep input[readonly].ui-inputtext.ui-state-default:focus:not(:active):not(:hover){
      background-color: lightgrey;
      border-color: lightgrey;
      color: black;
      box-shadow: none;
      width: 100%;
      -moz-box-shadow: none;
      -webkit-box-shadow: none;
    }
    p-autocomplete[ng-reflect-readonly="true"] ::ng-deep button {
      cursor: none;
      pointer-events: none;
    }
    :host {
      white-space: nowrap
    }

    p-autocomplete,
    p-autocomplete ::ng-deep .ui-autocomplete {
      height: 100%;
      width: 100%;
    }

    p-autocomplete ::ng-deep .ui-autocomplete-input {
      height: 100%;
      width: calc(100% - 20px); // dropdown button
    }

    p-autocomplete ::ng-deep .ui-autocomplete-dropdown {
      width: 20px;
    }

    .fa {
      margin: auto auto;
    }

    .ui-g {
      margin: 0;
      padding: 0;
    }

    .fa-disabled {
      color: grey;
      cursor: default;
    }

    .custom-button-container {
      cursor: pointer;
    }

    .icon-container {
      cursor: pointer;
    }
  `]
})
export class ElementInputAutocompleteComponent extends ElementInputComponent implements
  OnInit, OnDestroy, AfterViewInit, ComponentSelectedOptionAware {

  @Input() element: ElementInputAutocomplete;

  @ViewChild('inputElement', {static: true}) public inputElement: AutoComplete = null;

  public autocompleteSearchUnsubscribe = new Subject<void>();

  public suggestions: SelectItemExtended[] = [];

  constructor(
    protected formService: FormService,
    protected formViewerService: FormViewerService,
    protected genericCrudService: GenericCrudService,
    protected elementService: ElementService,
    protected elementRef: ElementRef,
    protected renderer: Renderer,
    protected translateService: TranslateService,
    protected elementsStackService: ElementsStackService,
    protected entityDraftService: EntityDraftStoreService,
    protected datamodelCrudService: DatamodelCrudService,
    protected authenticationService: AuthenticationService,
    public cdr: ChangeDetectorRef
  ) {
    super(formService, cdr, formViewerService, translateService, datamodelCrudService);
  }

  public onComponentInit() {
    this.subscriptions.push(
      this.elementService.elementMetaChanged$.subscribe((element) => {
        // GU :: this should be handled in more abstract way :)
        if (this.element === element) {
          this.setupValue();
        }
      }),
      this.formViewerService.inputValueChanged.subscribe((params) => {
          const element = params.formField;
          if (element.objectHashId === this.element.objectHashId) {

          }
      })
    );

    this.setFormControlName();

    if (this.element.autoload) {
      this.loadSuggestions().pipe(
        takeUntil(this.autocompleteSearchUnsubscribe))
        .subscribe();
    } else {
      this.setupValue();
    }

    this.filterActionAndHandleIt('oninit');
  }

  public onComponentDestroy() {
    super.onComponentDestroy();
    this.autocompleteSearchUnsubscribe.next();
    this.autocompleteSearchUnsubscribe.complete();
  }

  public onComponentChanges() {
    this.setupValue();
  }

  public onComponentAfterViewInit() {

  }

  public onCustomButtonClick(event): void {
    if (!this.isReadOnlyOrDisabled()) {
      this.filterActionAndHandleIt('onCustomButtonClick');
    }
  }

  public getSelectedOption(value: any) {
      if (!this.selectedOption || this.selectedOptionChanged(value) || (!this.selectedOption.entity && value.entity)) {
          const collection = this.suggestions;

          this.selectedOption = collection.find((option) => {
              if (value.entity && option.entity) {
                  return value.entity.id === option.entity.id;
              } else {
                  return value.value === option.value;
              }
          });
      }

      return this.selectedOption;
  }


  public onKeyUpSearch(event): void {
    this.autocompleteSearchUnsubscribe.next();
    this.autocompleteSearchUnsubscribe.complete();

    this.autocompleteSearchUnsubscribe = new Subject();

    this.onSearch(event);
  }

  public setValue(selectedOption: SelectItemExtended, triggerChange: boolean = true, entityValueEmpty: boolean = false) {
    const entityValue = this.getEntityValueFromChange(selectedOption, entityValueEmpty);

    if (typeof (selectedOption) !== 'undefined') {
      this.formService.onFormElementValueChange({
        formControlValue: selectedOption,
        element: this.element,
        entityValue: entityValue,
        formControlName: this.formControlName,
        formControlOptions: {},
        triggerChange: triggerChange,
        entity: this.entity,
        component: this,
        updateFormComponent: true
      });
    }

    return this;
  }

  public addOption(option: SelectItemExtended): this {
    let alreadyExists = false;

    for (const suggestion of this.suggestions) {
      if (suggestion.value === option.value) {
        alreadyExists = true;
        break;
      }
    }

    if (!alreadyExists) {
      this.suggestions = [...this.suggestions, option];
    }

    return this;
  }

  public onItemSelected(event) {
      const selectedOption = this.getSelectedOption(event);
      if (selectedOption && selectedOption.entity && this.element.getEntity()._embedded) {
        this.element.getEntity()._embedded[this.element.datamodelField] = selectedOption.entity;
      }

      this.setValue(selectedOption);
  }

  public onSearch(event): void {

    if (event && event.originalEvent) {
        const query = event.query;

        if (this.element.datamodel) {
          this.loadSuggestions(query).pipe(
            takeUntil(this.autocompleteSearchUnsubscribe))
            .subscribe();
        }

        if (event.originalEvent.type === 'input' && event.originalEvent.type !== 'click') {
            this.setValue({
                label: query,
                value: query,
                entity: null
            });
        }
    } else if (event && typeof event.originalEvent === 'undefined' && event.target && !event.target.value) {

      // primeng does not send originalEvent when value is empty
      this.setValue({
        label: null,
        value: null,
        entity: null
      }, true, true);
    }
  }

  private loadSuggestions(query: string = '', opts = { setupValue: true }): Observable<any[]> {
      const requestParams = {clause: 'orWhere'};

    this.filterActionAndHandleIt('onBeforeDatamodelLoad');

    if (this.element && this.element.datamodel && this.element.datamodel.apiRoute) {
      let url = `${this.element.datamodel.apiRoute}/offset/0/limit/50`;

      if (this.element.firstSortingField && this.element.firstSortingDirectionField) {
        if(this.element.secondSortingField && this.element.secondSortingDirectionField){
          url += `/orderby/${this.element.firstSortingField},${this.element.secondSortingField}/${this.element.firstSortingDirectionField},${this.element.secondSortingDirectionField}?search=${query}`;
        } else {
          url += `/orderby/${this.element.firstSortingField}/${this.element.firstSortingDirectionField}?search=${query}`;
        }
      }else{
        url += `/orderby/id/asc?search=${query}`;
      }

      url = this.element.customApiRoute ? this.element.customApiRoute : url;

      if(this.element.firstSortingTypeField){
        if(this.element.secondSortingTypeField){
          requestParams['orderType'] = `${this.element.firstSortingTypeField},${this.element.secondSortingTypeField}`;
        }else{
          requestParams['orderType'] = `${this.element.firstSortingTypeField}`;
        }
      }

      if (this.element.staticFilterField && this.element.staticFilterValue) {
        if (this.element.staticFilterValue == 'null' || this.element.staticFilterValue == 'isNull:') {
          requestParams[this.element.staticFilterField] = `isNull:`;
        } else if (this.element.staticFilterValue == 'me' && this.element.staticFilterField) {
          const currentUser = this.authenticationService.currentUser;
          requestParams[this.element.staticFilterField] = `inversedManyIn:${currentUser.id}`;
          requestParams['tempEmbedded'] = `users`;
        } else if (this.element.staticFilterValue == 'branchOffice' && this.element.staticFilterField) {
          const userSession = this.formService.getInjector().get(UserSessionService, null);
          if (userSession) {
            const currentBranchOffice = userSession.get(Branch.LOCAL_STORAGE_NAME);
            if (currentBranchOffice !== 'all') {
              requestParams[this.element.staticFilterField] = `inversedManyIn:${currentBranchOffice.id}`;
              requestParams['tempEmbedded'] = `branchOffices`;
            }
          }
        } else if (this.element.staticFilterValue == 'branchOfficeManager' && this.element.staticFilterField) {
          const userSession = this.formService.getInjector().get(UserSessionService, null);
          if (userSession) {
            const currentBranchOffice = userSession.get(Branch.LOCAL_STORAGE_NAME);
            if (currentBranchOffice !== 'all') {
              requestParams['userBranchOffices.branchOffice'] = `inversedManyIn:${currentBranchOffice.id}`;
              requestParams['tempEmbedded'] = `userBranchOffices.branchOffice`;
            }
            requestParams[this.element.staticFilterField] = `contains:1`
          }
        } else {
          requestParams[this.element.staticFilterField] = `contains:${this.element.staticFilterValue}`;
        }

      }

      if (this.element.masterFilterField && null !== this.getFormContextMasterEntityValue() && !this.element.masterFilterFieldValue && !this.element.isManyInMasterFilterField && !this.element.isInversedManyInMasterFilterField) {
        requestParams[this.element.masterFilterField] = `${this.getFormContextMasterEntityValue()}`;
      } else if (this.element.masterFilterField) {
        let value = {};
        if (this.element.masterFilterFieldValue) {
          value = this.formService.getEntityHydrator().getEntityPropertyValue(this.entity, this.element.masterFilterFieldValue);
        } else {
          value = this.formService.getEntityHydrator().getEntityPropertyValue(this.entity, this.element.masterFilterField);
        }
        if (value && typeof value === 'object' && value['id']) {
          value = value['id'];
        }

        if (value) {
          const filterField = this.element.masterFilterFieldReal ? this.element.masterFilterFieldReal : this.element.masterFilterField;
          if (this.element.isManyInMasterFilterField) {
            requestParams['tempEmbedded'] = filterField;
            requestParams[filterField] = 'manyIn:' + value.toString();
          } else if (this.element.isInversedManyInMasterFilterField) {
            requestParams['tempEmbedded'] = filterField;
            requestParams[filterField] = 'inversedManyIn:' + value.toString();
          } else {
            requestParams[filterField] = value.toString();
          }
        }
      }
      if (this.element.isEmbeddedNone) {
        requestParams['embedded'] = 'none';
      }

      if (this.element.embeddedFields && this.element.embeddedFields !== '') {
        requestParams['embedded'] = this.element.embeddedFields;
      }

      if (this.element.datamodelFieldFilter && this.element.datamodelFieldFilterValue) {
        requestParams[this.element.datamodelFieldFilter]
          = (typeof this.element.datamodelFieldFilterValue['id'] !== 'undefined')
          ? this.element.datamodelFieldFilterValue['id'] : this.element.datamodelFieldFilterValue;
      }

      return this.genericCrudService
            .getEntities(url, '', requestParams).pipe(
            takeUntil(this.autocompleteSearchUnsubscribe),
            map((entities: any) => {
                this.suggestions = [];

                if (this.element.isEmptyValueAllowed) {
                    this.suggestions = [...this.suggestions, {
                        value: null,
                        label: '---'
                    }];
                }

                const entityList = (entities && entities.data) ? entities.data : entities;

                this.extendByDraftEntities(entityList);

                entityList.map((entity) => {
                    let label: string = this.figureOutLabel(entity);
                    let value: any = undefined;

                    if (this.element.dropdownFieldValue && entity[this.element.dropdownFieldValue]) {
                        // value = {};
                        // value[this.element.dropdownFieldValue] = record[this.element.dropdownFieldValue];
                        value = entity[this.element.dropdownFieldValue];
                    } else {
                        value = { id: entity.id };
                    }

                    const option = {
                        label: label,
                        value: value,
                        entity: entity
                    };

                    this.suggestions = [...this.suggestions, option];
                });

                if (opts.setupValue) {
                  this.setupValue(true);
                }

                ChangeDetectorRefHelper.detectChanges(this);

                return this.suggestions;
        }));
    }
  }

  private extendByDraftEntities(entityList: Array<any>) {
    const draftSaveEntities = this.entityDraftService.getEntities(this.buildFqn());

    for (const draftEntity of draftSaveEntities) {
      if (!this.inList(draftEntity, entityList)) {
        entityList.push(draftEntity);
      }
    }
  }

  private buildFqn() {
    if (!this.element || !this.element.datamodel) {
      return '';
    }

    const nameParts = this.element.datamodel.name.split('.');

    return `${nameParts[0]}\\Entity\\${nameParts[1]}`;
  }

  private inList(entity: any, entityList: Array<any>): boolean {
    for (const listEntity of entityList) {
      if (entity[EntityStatus.ENTITY_DRAFT_FLAG] === listEntity[EntityStatus.ENTITY_DRAFT_FLAG]) {
        return true;
      }
    }

    return false;
  }

  public setupValue(ignoreResetCollection: boolean = false) {
    const entity = this.element.getEntity(),
        label = this.getEntityLabel(entity),
        value = this.getEntityValue();

    if (value && !ignoreResetCollection) {
      this.suggestions = [];

      if (this.element.isEmptyValueAllowed) {
          this.suggestions = [...this.suggestions, {
              value: null,
              label: '---'
          }];
      }

      let selectedOptionEntity = null;

      if (this.element.getEntity() && this.element.getEntity()._embedded && this.element.getEntity()._embedded[this.element.datamodelField]) {
          selectedOptionEntity = this.element.getEntity()._embedded[this.element.datamodelField];
      }

      const selectedOption = {
          label: label,
          value: value,
          entity: selectedOptionEntity
      };

      this.suggestions = [...this.suggestions, selectedOption];

      this.setValue(selectedOption, false).getSelectedOption(selectedOption);

      if (event && event.type !== 'load') {
        this.setValue(selectedOption, false);
      }
    } else if (!value && this.element.defaultValue) {
      this.setDefaultValue();
    } else if (!value && !this.element.defaultValue && this.element.selectIfSingleValue) {
      this.setFirstValue();
    }else if (value === null || value === '' || typeof value === 'undefined') {
      this.setValue(null, false);
    }
  }

  protected setDefaultValue() {
    let value = this.element.defaultValue;

    if (this.element.datamodel && value) {
      this.genericCrudService.getEntity(this.element.datamodel.apiRoute, value).subscribe((loadedEntity) => {
        const entityValue = this.getEntityValue();

        // double check, as it is async
        if (!entityValue) {
          value = loadedEntity[this.getDropdownFieldValue()];

          if (value instanceof Object) {
            value = '';
          }

          const selectedOption = {
            label: this.figureOutLabel(loadedEntity),
            value: value,
            entity: loadedEntity
          };

          this.suggestions = [...this.suggestions, selectedOption];

          this.setValue(selectedOption, false).getSelectedOption(selectedOption);

          if (this.selectedOption) {
            this.filterActionAndHandleIt('onselect');
          }
        }

        ChangeDetectorRefHelper.detectChanges(this);
      });
    }
  }

  protected setFirstValue() {
    this.autocompleteSearchUnsubscribe.next();
    this.autocompleteSearchUnsubscribe.complete();

    this.autocompleteSearchUnsubscribe = new Subject();

    this.inputElement.loading = true;
    this.loadSuggestions('', {
        setupValue: false
      }).pipe(
      takeUntil(this.autocompleteSearchUnsubscribe),
      catchError((error) => {
        this.inputElement.loading = false;

        return observableThrowError(error);
      }))
      .subscribe(() => {
        let lengthToCheck = 1;

        if (this.element.isEmptyValueAllowed && this.suggestions.length === 2) {
          lengthToCheck = 2;
        }

        if (this.suggestions.length === lengthToCheck) {
          const option = this.suggestions[lengthToCheck - 1];

          this.setValue(option, false).getSelectedOption(option);

          if (this.selectedOption) {
            this.filterActionAndHandleIt('onselect');
          }
        }

        ChangeDetectorRefHelper.detectChanges(this);
    });
  }

    getDropdownFieldLabel(): string {
        return this.element.dropdownFieldLabel || 'id';
    }

    getDropdownFieldValue(): string {
        return this.element.dropdownFieldValue || 'id';
    }

    getEntityLabel(entity: any): string {
        let label = this.element.value;

        if (this.element.datamodelField && this.element.datamodelField.indexOf('.') !== -1
          || (entity && entity._embedded && entity._embedded[this.element.datamodelField])) {
          const fields = this.element.datamodelField.split('.');
          for (const item of fields) {
            if (entity && entity._embedded && entity._embedded[item]) {
              entity = entity._embedded[item];
            }
          }

          if (entity) {
            label = this.figureOutLabel(entity);
          }
        }

      return label;
    }

    getEntityValue(): string {
      let value = this.element.value;

        if (this.element.datamodelField) {
          let fields = this.element.datamodelField.split('.');
          let entity = this.element.getEntity();
          for (let item of fields) {
            if (entity && entity._embedded && entity._embedded[item]) {
              entity = entity._embedded[item];
            }
          }

          if (entity && entity !== this.element.getEntity() && entity[this.getDropdownFieldValue()]) {
            value = entity[this.getDropdownFieldValue()];
          }
        }

        if (value instanceof Object) {
          value = '';
        }

        return value;
    }

    protected getEntityValueFromChange(selectedOption: SelectItemExtended, entityValueEmpty: boolean = false): any {
      let entityValue: any = entityValueEmpty ? null : selectedOption;

      if (selectedOption instanceof Object && typeof selectedOption.entity !== 'undefined' && selectedOption.entity !== null) {
        if (this.element.dropdownFieldValue) {
          entityValue = Entity.getValue(selectedOption.entity, this.element.dropdownFieldValue) ||
            Entity.getValueInEmbedded(selectedOption.entity, this.element.dropdownFieldValue);
        } else {
          entityValue = selectedOption.entity;
        }
      }

      if (selectedOption instanceof Object && selectedOption.value &&
        (typeof selectedOption.entity === 'undefined' || selectedOption.entity === null)
      ) {
        entityValue = selectedOption.value;
      }

      return entityValue;
    }

    private getFormContextMasterEntityValue(): any|null {
        let value = null,
            formContext = null;

        if (this.moduleElement && this.element.masterFilterField) {
            formContext = this.elementsStackService.findByIdAndType(this.moduleElement.id, ElementType.Form);
        }

        if (null !== formContext) {
          let masterEntity = formContext.findMasterEntity(this.element.masterFilterField);

          if (masterEntity) {
            value = masterEntity.value;
          }
        }

        if (value === null && null !== formContext) {
          const masterElementContext: ElementContext = formContext.masterElementContext;

          if (masterElementContext && masterElementContext.component instanceof GenericElementAbstract) {
            value = Entity.getValue(masterElementContext.component.getSelectedEntity(), this.element.masterFilterField) ||
              Entity.getValueInEmbedded(masterElementContext.component.getSelectedEntity(), this.element.masterFilterField);
          }

          if (value && value.id) {
            value = value.id;
          }
        }

        return value;
    }

    private isValueLegal(key, value): boolean {
      return value && typeof value.value !== 'undefined';
    }

    public focusInput() {
      if (this.inputElement) {
        this.inputElement.focusInput();
      }
    }
}
