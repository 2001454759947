import { environment } from '../../../environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "../../core/authentication/authentication.service";
export class SessionStorageDataService {
    constructor(authenticationService) {
        this.authenticationService = authenticationService;
        this.sessionStorage = sessionStorage;
        this.environment = environment;
    }
    setItem(key, value) {
        this.sessionStorage.setItem(this.getUniqueKey(key), JSON.stringify(value));
        return this;
    }
    getItem(key) {
        return JSON.parse(this.sessionStorage.getItem(this.getUniqueKey(key)));
    }
    removeItem(key) {
        this.sessionStorage.removeItem(this.getUniqueKey(key));
        return this;
    }
    itemExists(key) {
        return this.sessionStorage.getItem(this.getUniqueKey(key)) !== null;
    }
    getUniqueKey(key) {
        return this.authenticationService.currentUser.username + '-' + this.environment.key + '-' + key;
    }
}
SessionStorageDataService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function SessionStorageDataService_Factory() { return new SessionStorageDataService(i0.ɵɵinject(i1.AuthenticationService)); }, token: SessionStorageDataService, providedIn: "root" });
