
    <div [hidden]="hidden">
      <input
        type="number"
        [style]="{'width':'80%'}"
        (change)="onChangePeriod()"
        [(ngModel)]="periodValue"
      >
      <p-dropdown
        [options]="periodOptions"
        [autoWidth]="false"
        [autoDisplayFirst]="true"
        (onChange)="onChangePeriod()"
        [(ngModel)]="periodUnit"
        [style]="{'width':'20%'}"
        appendTo="body"
        #inputElement
      ></p-dropdown>
    </div>
  