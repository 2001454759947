/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./toolbar-item-import-cloud-printer.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./toolbar-item-import-cloud-printer.component";
import * as i3 from "../../../../../../services/generic-crud.service";
import * as i4 from "../../../../../../../core/message/message-growl.service";
import * as i5 from "@ngx-translate/core";
var styles_ToolbarItemImportCloudPrinterComponent = [i0.styles];
var RenderType_ToolbarItemImportCloudPrinterComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ToolbarItemImportCloudPrinterComponent, data: {} });
export { RenderType_ToolbarItemImportCloudPrinterComponent as RenderType_ToolbarItemImportCloudPrinterComponent };
export function View_ToolbarItemImportCloudPrinterComponent_0(_l) { return i1.ɵvid(2, [], null, null); }
export function View_ToolbarItemImportCloudPrinterComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-toolbar-item-import-cloud-printer", [], null, null, null, View_ToolbarItemImportCloudPrinterComponent_0, RenderType_ToolbarItemImportCloudPrinterComponent)), i1.ɵdid(1, 245760, null, 0, i2.ToolbarItemImportCloudPrinterComponent, [i3.GenericCrudService, i4.MessageGrowlService, i5.TranslateService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ToolbarItemImportCloudPrinterComponentNgFactory = i1.ɵccf("app-toolbar-item-import-cloud-printer", i2.ToolbarItemImportCloudPrinterComponent, View_ToolbarItemImportCloudPrinterComponent_Host_0, { params: "params" }, {}, []);
export { ToolbarItemImportCloudPrinterComponentNgFactory as ToolbarItemImportCloudPrinterComponentNgFactory };
