export class EventHelper {

    public static isCustomEvent(event, type: string): boolean {
        return event instanceof CustomEvent && event.type === type;
    }

    public static isEnterPressed(event): boolean {
      return event && event.code && (event.code === 'Enter' || event.code === 'NumpadEnter');
    }

    public static isTabPressed(event): boolean {
      return event && event.code && event.code === 'Tab';
    }

}
