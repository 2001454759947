<p-dialog *ngIf="isDialogVisible" (onAfterHide)="dialogHide()" appendTo="body"
          [(visible)]="isDialogVisible" showEffect="fade"
          [modal]="true" [resizable]="false" [height]="750"
          [maximizable]="true"
>
  <p-header>
    <div class="ui-helper-clearfix">
      <div class="ui-grid-row">
        <div class="ui-grid-col-1">&nbsp;</div>
        <div class="ui-grid-col-1" style="width: 8%;">Width</div>
        <div *ngIf="!isUserView" class="ui-grid-col-1" style="width: 12%;">Field Type</div>
        <div *ngIf="!isUserView" class="ui-grid-col-2" style="width: 13%;">Title</div>
        <div *ngIf="!isUserView" class="ui-grid-col-1" style="width: 7%;">FilterType</div>
        <div *ngIf="!isUserView" class="ui-grid-col-1" style="width: 5%;">GroupBy</div>
        <div *ngIf="!isUserView" class="ui-grid-col-1" style="width: 6%;">ReadOnly</div>
        <div class="ui-grid-col-1" style="width: 14%;">Order</div>
        <div *ngIf="!isUserView" class="ui-grid-col-1" style="width: 8%;">Default</div>
        <div *ngIf="!isUserView" class="ui-grid-col-1" style="width: 8%;">Default Filter</div>
        <div class="ui-grid-col-1" style="width: 14%;">Name</div>
        <button type="button" icon="fa fa-save" pButton (click)="saveFields($event)"></button>
      </div>
    </div>
  </p-header>
  <div class="ui-g">
    <div class="ui-g-12 ui-md-12">
      <p-orderList [value]="visibleFields" [listStyle]="{'height':'630px'}">
        <ng-template let-row pTemplate="item">
          <input type="hidden" class="input-hidden-value" value="{{row.id}}"/>
          <div class="ui-helper-clearfix">
            <div class="ui-grid-col-1" style="width: 3%;">
              <p-checkbox [disabled]="row.fieldType == moduleElementColumnName" [(ngModel)]="row.visible" binary="true" ></p-checkbox>
            </div>
            <div class="ui-grid-col-1" style="width: 10%;">
              <input type="number" [(ngModel)]="row.width" class="ui-grid-col-10">
            </div>
            <div class="ui-grid-col-1" *ngIf="!isUserView"  style="width: 10%;">
              <p-dropdown [disabled]="row.fieldType == moduleElementColumnName" [options]="fieldTypes" [(ngModel)]="row.fieldType" [style]="{'width':'100%'}"></p-dropdown>
              <p-dropdown *ngIf="row.fieldType == 'openmodule'" filter="true" [disabled]="row.fieldType == moduleElementColumnName" [options]="modulesOptions" [(ngModel)]="row.moduleId" [style]="{'width':'100%'}"></p-dropdown>
            </div>
            <div class="ui-grid-col-2">
              <input type="text" [(ngModel)]="row.title" class="ui-grid-col-10">
            </div>
            <div class="ui-grid-col-1" *ngIf="!isUserView">
              <p-dropdown [disabled]="row.fieldType == moduleElementColumnName" [options]="filterTypes" [(ngModel)]="row.filterType" [style]="{'width':'100%'}"></p-dropdown>
            </div>
            <div class="ui-grid-col-1" *ngIf="!isUserView">
              <div class="ui-grid-col-6">
                <p-checkbox [disabled]="row.fieldType == moduleElementColumnName" class="reorder-grid-checkbox" [(ngModel)]="row.isGroupByField" binary="true" ></p-checkbox>
              </div>
              <div class="ui-grid-col-6">
                <p-checkbox [disabled]="row.fieldType == moduleElementColumnName" class="reorder-grid-checkbox" [(ngModel)]="row.isReadOnly" binary="true" ></p-checkbox>
              </div>
            </div>

            <div class="ui-grid-col-2" [ngSwitch]="true" *ngIf="!isUserView">
              <div class="ui-grid-row" *ngSwitchCase="row.fieldType === 'association' || row.fieldType === 'dropdown'">
                <div class="ui-grid-col-6">
                  <input
                    *ngSwitchCase="row.fieldType === 'association' || row.fieldType === 'dropdown'"
                    type="text"
                    [disabled]="row.fieldType == moduleElementColumnName"
                    [(ngModel)]="row.firstAssociationOrderBy"
                  >
                </div>
                <div class="ui-grid-col-3">
                  <p-dropdown
                    *ngSwitchCase="row.fieldType === 'association' || row.fieldType === 'dropdown'"
                    [(ngModel)]="row.firstAssociationOrderByOrientation"
                    [options]="orderOrientation"
                    [disabled]="row.fieldType == moduleElementColumnName"
                    appendTo="body"
                    filter="false"
                    [style]="{'width':'100%'}"
                  ></p-dropdown>
                </div>
                <div class="ui-grid-col-3">
                  <p-dropdown
                    *ngSwitchCase="row.fieldType === 'association' || row.fieldType === 'dropdown'"
                    [(ngModel)]="row.firstAssociationOrderType"
                    [options]="orderType"
                    [disabled]="row.fieldType == moduleElementColumnName"
                    appendTo="body"
                    filter="false"
                    [style]="{'width':'100%'}"
                  ></p-dropdown>
                </div>
              </div>
              <div class="ui-grid-row" *ngSwitchCase="row.fieldType === 'association' || row.fieldType === 'dropdown'">
                <div class="ui-grid-col-6">
                  <input
                    *ngSwitchCase="row.fieldType === 'association' || row.fieldType === 'dropdown'"
                    type="text"
                    [disabled]="row.fieldType == moduleElementColumnName"
                    [(ngModel)]="row.secondAssociationOrderBy"
                  >
                </div>
                <div class="ui-grid-col-3">
                  <p-dropdown
                    *ngSwitchCase="row.fieldType === 'association' || row.fieldType === 'dropdown'"
                    [(ngModel)]="row.secondAssociationOrderByOrientation"
                    [options]="orderOrientation"
                    [disabled]="row.fieldType == moduleElementColumnName"
                    appendTo="body"
                    filter="false"
                    [style]="{'width':'100%'}"
                  ></p-dropdown>
                </div>
                <div class="ui-grid-col-3">
                  <p-dropdown
                    *ngSwitchCase="row.fieldType === 'association' || row.fieldType === 'dropdown'"
                    [(ngModel)]="row.secondAssociationOrderType"
                    [options]="orderType"
                    [disabled]="row.fieldType == moduleElementColumnName"
                    appendTo="body"
                    filter="false"
                    [style]="{'width':'100%'}"
                  ></p-dropdown>
                </div>
              </div>
              <input type="text" disabled *ngSwitchCase="row.fieldType !== 'association' && row.fieldType !== 'dropdown'"/>
            </div>

            <div class="ui-grid-col-1" [ngSwitch]="true" *ngIf="!isUserView">
              <p-spinner
                *ngSwitchCase="row.fieldType === 'number'"
                [(ngModel)]="row.defaultValue"
                [disabled]="row.fieldType == moduleElementColumnName"
              ></p-spinner>

              <p-checkbox
                *ngSwitchCase="row.fieldType === 'checkbox'"
                [(ngModel)]="row.defaultValue"
                [binary]="true"
                [disabled]="row.fieldType == moduleElementColumnName"
                class="reorder-grid-checkbox"
              ></p-checkbox>

              <p-triStateCheckbox
                *ngSwitchCase="row.fieldType === 'tricheckbox'"
                [(ngModel)]="row.defaultValue"
                [disabled]="row.fieldType == moduleElementColumnName"
                class="reorder-grid-checkbox"
              ></p-triStateCheckbox>

              <p-dropdown
                *ngSwitchCase="row.fieldType === 'association' || row.fieldType === 'dropdown'"
                [(ngModel)]="row.defaultValue"
                [options]="getFieldDefaultOptions(row)"
                [disabled]="row.fieldType == moduleElementColumnName"
                appendTo="body"
                placeholder="---"
                filter="true"
                [style]="{'width':'100%'}"
              ></p-dropdown>

              <p-multiSelect
                *ngSwitchCase="row.fieldType === 'associationMany'"
                [(ngModel)]="row.defaultValue"
                appendTo="body"
                [options]="getFieldDefaultOptions(row)"
                [disabled]="row.fieldType == moduleElementColumnName"
                filter="true"
                [style]="{'width':'100%'}"
              ></p-multiSelect>

              <input
                *ngSwitchDefault
                [(ngModel)]="row.defaultValue"
                [disabled]="row.fieldType == moduleElementColumnName"
                type="text"
                pInput
              >
            </div>
            <div class="ui-grid-col-1" [ngSwitch]="true" *ngIf="!isUserView">
              <p-checkbox
                *ngSwitchCase="
                  row.filterType === 'checkbox' ||
                  row.filterType === 'lockState'
                "
                [(ngModel)]="row.defaultFilterValue"
                [binary]="true"
                [disabled]="row.fieldType == moduleElementColumnName"
                class="reorder-grid-checkbox"
              ></p-checkbox>

              <p-triStateCheckbox
                *ngSwitchCase="row.filterType === 'tricheckbox'"
                [(ngModel)]="row.defaultFilterValue"
                [disabled]="row.fieldType == moduleElementColumnName"
                class="reorder-grid-checkbox"
              ></p-triStateCheckbox>

              <p-dropdown
                *ngSwitchCase="row.filterType === 'dropdown'"
                [(ngModel)]="row.defaultFilterValue"
                [options]="getFieldDefaultOptions(row)"
                [disabled]="row.fieldType == moduleElementColumnName"
                appendTo="body"
                placeholder="---"
                filter="true"
                [style]="{'width':'100%'}"
              ></p-dropdown>

              <p-autoComplete
                *ngSwitchCase="row.filterType === 'autocomplete'"
                [(ngModel)]="row.defaultFilterValue"
                [disabled]="row.fieldType == moduleElementColumnName"
                [style]="{'width':'100%'}"
                appAutocomplete
                [dropdown]="true"
                [ngModel]="row.defaultFilterValue"
                [suggestions]="associatedFilterOptions[row.id]"
                [multiple]="true"
                (completeMethod)="onFilterAutocompleteSearch($event, row)"
                (onDropdownClick)="onFilterAutocompleteSearch($event, row)"
                appendTo="body"
                field="label"
              ></p-autoComplete>

              <p-multiSelect
                *ngSwitchCase="row.filterType === 'multidropdown'"
                [(ngModel)]="row.defaultFilterValue"
                appendTo="body"
                optionLabel="label"
                [options]="getFieldDefaultOptions(row)"
                [disabled]="row.fieldType == moduleElementColumnName"
                [style]="{'width':'100%'}"
              ></p-multiSelect>

              <input
                *ngSwitchDefault
                [(ngModel)]="row.defaultFilterValue"
                [disabled]="row.fieldType == moduleElementColumnName"
                type="text"
                pInput
              >
            </div>
            <div class="ui-grid-col-1">
              <span style="font-size:14px;">{{row.name}}</span>
            </div>
          </div>
        </ng-template>
      </p-orderList>
    </div>
  </div>
</p-dialog>
