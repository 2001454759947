
import {Observable, Subscription} from 'rxjs';
import {ChangeDetectorRef, Component, ElementRef, EventEmitter, OnChanges, OnDestroy, OnInit, ViewChild, ViewContainerRef} from '@angular/core';
import {EntityValidator} from '../../../../../validators/services/entity-validator';
import {EntityDataStoreService} from '../../../../services/entity-data-store.service';
import {ComponentService} from '../../../../services/component-highlight-stack.service';
import {ModulesStateService} from '../../../../services/modules-state.service';
import {GenericCrudService} from '../../../../../services/generic-crud.service';
import {ExecutorService} from '../../../../../../core/executor/executor.service';
import {GenericElementValidationExecutionStepsFactory} from '../../../../services/generic/generic-element-validation-execution-steps-factory';
import {ToolbarItemCheckService} from '../../../generic-toolbar/services/check/toolbar-item-check.service';
import {JobContainerService} from '../../../../../../core/job-runner/job-container.service';
import {ExecutionStepFactoryService} from '../../../../../../core/executor/factory/execution-step-factory.service';
import {LayoutService} from '../../../../../services/layout-service';
import {ToolbarReportExportComponent} from '../../../generic-toolbar/items/components/report-export/toolbar-report-export.component';
import {environment} from '../../../../../../../environments';
import {createNumberMask} from 'text-mask-addons/dist/textMaskAddons';
import {DynamicTableComponent} from '../../../../../dynamic-table/dynamic-table.component';
import {PermissionService} from '../../../../../services/permission/permission.service';
import {UserSessionService} from '../../../../../../core/service/user-session.service';
import {ChangeDetectorRefHelper} from '../../../../../helpers/change-detector-ref.helper';
import {BranchOffice} from '../../../../../services/branch-office/branch-office';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {map} from 'rxjs/operators';
import {TableHeader} from '../../../../../dynamic-table/shared/table-header';
import jsPDF from 'jspdf';
import 'jspdf-autotable';

@Component({
  selector: 'app-custom-report',
  templateUrl: './custom-report.component.html',
  styleUrls: ['./custom-report.component.scss'],
  providers: [
    GenericElementValidationExecutionStepsFactory
  ]
})
export class CustomReportComponent implements OnInit, OnChanges, OnDestroy {
  @ViewChild(DynamicTableComponent, {static: false}) table: DynamicTableComponent;
  @ViewChild('gridContainer', {static: false}) set gridContainer(gridContainer: ElementRef) {
    this._gridContainer = gridContainer;
  }

  public _gridContainer: ElementRef = null;

  public clauses: any;
  public clauseValues = {};
  public columns: any[] = [{header: '', key: ''}];
  public defaultPageSize = 25;
  public totalCount = 0;
  public allValues: any[] = [];
  public visibleValues: any[] = [];
  public filteredValues: any[] = [];
  public activeFilters: any[] = [];
  public branchOffices: SelectItem[] = [];
  public selectedBranchOffice: SelectItem = null;
  protected toolbarItems: any[] = [];
  protected reportId: number = null;
  public isReportTableVisible = false;
  public isLoadingReportData = false;
  public onBranchOfficeOptionChanged: EventEmitter<BranchOffice> = new EventEmitter();
  protected subscriptions: Subscription[] = [];
  textMask: any;
  protected baseUrl = '';
  public currentOffset = 0;
  public exportButtons: TableHeader = {
    buttons: [
      {
        icon: 'fa fa-csv',
        click: this.doExport.bind(this),
        text: 'CSV erstellen'
      },
      {
        icon: 'fa fa-pdf',
        click: this.doExportPdf.bind(this),
        text: 'PDF erstellen'
      }
    ]
  };

  constructor(
    public elementRef: ElementRef,
    protected componentService: ComponentService,
    protected viewContainerRef: ViewContainerRef,
    protected modulesStateService: ModulesStateService,
    protected genericCrudService: GenericCrudService,
    protected entityDataStoreService: EntityDataStoreService,
    protected executorService: ExecutorService,
    protected genericElementValidationExecutionStepsFactory: GenericElementValidationExecutionStepsFactory,
    protected entityValidator: EntityValidator,
    protected userSession: UserSessionService,
    protected toolbarItemCheckService: ToolbarItemCheckService,
    protected jobContainerService: JobContainerService,
    protected stepFactory: ExecutionStepFactoryService,
    protected layoutService: LayoutService,
    protected permissionService: PermissionService,
    public cdr: ChangeDetectorRef,
    protected router: Router,
    protected activatedRoute: ActivatedRoute
  ) {
    this.baseUrl = environment.baseUrl;
  }

  public ngOnChanges() {
      this.loadClause();
  }

  public ngOnInit() {
    this.onComponentInit();
  }

  public loadData(): void {
    this.isLoadingReportData = true;
    this.subscriptions.push(
      this.doLoadData().subscribe(() => {
        ChangeDetectorRefHelper.detectChanges(this);
        this.setGridScrollHeightAndWidth();
        this.isLoadingReportData = false;
      })
    );
  }

  public ngOnDestroy() {
    this.onDestroyComponent();
  }

  public getToolbarExtraParams() {
    return {
      'gridComponent': this
    }
  }

  onComponentInit() {
    this.activatedRoute.params.subscribe((urlParams: Params) => {
      this.reportId = urlParams['reportId'];
      this.loadClause();
    });
    this.textMask = this.getTextMask();
  }

  onTabClose() {
    this.router.navigate(['']).then();
  }

  private loadClause() {
    this.subscriptions.push(
      this.genericCrudService
        .getEntities(ToolbarReportExportComponent.CLAUSES_URL
          + '?' + ToolbarReportExportComponent.PARAMETER_NAME + '=' + this.reportId).subscribe((clauses) => {
        this.clauses = clauses;
      })
    );
  }

  onDestroyComponent() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  public doExport(): void {
    const a = document.createElement('a');
    document.body.appendChild(a);
    a.style.setProperty('display', 'none');

    this.subscriptions.push(
      this.genericCrudService.customPost(this.getUrl('report'),
        {'report': this.reportId, 'params': this.clauseValues})
        .subscribe(fileResponse => {
          a.href = this.baseUrl + '/' + fileResponse.file;
          a.download = fileResponse.fileName;
          a.target = '_blank';
          a.click();
        })
    );
  }

  public doExportPdf(): void {
    const doc = new jsPDF({
      orientation: 'landscape',
    });
    doc['autoTable'](this.columns, this.allValues);
    doc.save('Auswertung.pdf');
  }

  public doLoadData(): Observable<any> {
    return this.genericCrudService.customPost(this.getUrl('data'), {'report': this.reportId, 'params': this.clauseValues})
      .pipe(map(data => {
        this.columns = data.fields;
        this.allValues = data.data;
        this.filteredValues = data.data;
        this.visibleValues = this.allValues.slice(0, 100);
        this.totalCount = data.totalCount;
        this.isReportTableVisible = true;
      }));
  }

  onLazyLoad(event) {
    if (event.rows < this.defaultPageSize) {
      event.rows = this.defaultPageSize;
    }
    this.visibleValues = this.filteredValues.slice(event.first, event.first + event.rows);
    this.setGridScrollHeightAndWidth();
  }

  onFilter(event) {
    const filterValue = event.originalEvent.target.value,
      filterColumn = event.column.id,
      filter = this.getFilterColumn(filterColumn);
    if (!filter) {
      this.activeFilters.push({
        column: filterColumn,
        value: filterValue
      });
    } else {
      filter.value = filterValue;
    }
    this.filterData();
    this.totalCount = this.filteredValues.length;
  }

  private filterData() {
    for (const filter of this.activeFilters) {
      this.filteredValues =  this.allValues.filter((value) => {
        return value[filter.column].toLowerCase().indexOf(filter.value.toLowerCase()) !== -1;
      });
    }
    this.visibleValues = this.filteredValues.slice(0, 100);
    this.setGridScrollHeightAndWidth();
  }

  private getFilterColumn(key) {
    return this.activeFilters.find((value) => {
      return value.column === key;
    })
  }

  public onBranchOfficeChanged(event?: {value: any}): void {
    this.onBranchOfficeOptionChanged.emit(this.selectedBranchOffice.value);
  }

  public loadBranchOffices(): void {
    this.subscriptions.push(
      this.genericCrudService.getEntities('app/branchoffices', '', {embedded: 'none'}).subscribe((branchOffices) => {
        this.branchOffices = [];
        for (const branchOffice of branchOffices) {
          this.branchOffices.push({label: branchOffice.name, value: branchOffice})
        }
      })
    );
  }

  getTextMask() {
    return {mask: createNumberMask({
        prefix: '',
        suffix: '',
        allowDecimal: false,
        requireDecimal: false,
        allowNegative: true,
      })};
  }

  protected setGridScrollHeightAndWidth(): void {
    const dynamicTable = this.getTable();

    if (this._gridContainer && dynamicTable) {
      // header, footer, use calculation
      dynamicTable.height = this._gridContainer.nativeElement.clientHeight;
    }
  }

  protected getUrl(urlData: string): string {
    if (urlData === 'data') {
      return ToolbarReportExportComponent.REPORT_GET_DATA_URL;
    }

    if (urlData === 'report') {
      return ToolbarReportExportComponent.REPORT_EXPORT_URL;
    }

    return ToolbarReportExportComponent.REPORT_EXPORT_URL;
  }

  public getTable(): DynamicTableComponent {
    return this.table;
  }
}
