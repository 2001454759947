
    <div class="ui-g">
        <div class="ui-g-4">
          {{label}}:
        </div>
        <div class="ui-g-8">
          <p-dropdown
            appendTo="body" 
            (onChange)="onChange($event)"
            [style]="{'width':'100%'}" 
            [options]="options" 
            placeholder="---" 
            [(ngModel)]="selectedOption"
          ></p-dropdown>
        </div>
    </div>
  