import { Component, Input, OnInit } from '@angular/core';
import { ToolbarItemAbstract } from '../toolbar-item-abstract.component';
import { ToolbarItem } from '../../../../../../services/element/toolbar-item';
import {GenericCrudService} from "../../../../../../services/generic-crud.service";
import {MessageGrowlService} from "../../../../../../../core/message/message-growl.service";
import {TranslateService} from "@ngx-translate/core";
import {FileService} from "../../../../../../../core/file/file.service";
import {RequestCachingService} from "../../../../../../services/request-caching.service";
import {ToastService} from "../../../../../../../core/service/toast.service";
import {environment} from "../../../../../../../../environments";

@Component({
    selector: 'toolbar-item-download-templates',
    template: ''
})
export class ToolbarItemDownloadTemplatesComponent extends ToolbarItemAbstract {

  protected baseUrl = '';

  public constructor(
    protected genericCrudService: GenericCrudService,
    protected messageGrowlService: MessageGrowlService,
    protected translate: TranslateService,
    protected fileService: FileService,
    protected requestCachingService: RequestCachingService,
    protected toastService: ToastService
  ) {
    super();
    this.baseUrl = environment.baseUrl;
  }

  public click() {
    this.genericCrudService.customPost('phoenix/printtemplates/all/export', {}).subscribe((fileResponse) => {
      this.requestCachingService.removeByExpression('phoenix');
      this.downloadFile(fileResponse);
    });
  }

  private downloadFile(fileResponse) {
    const a = document.createElement('a');
    document.body.appendChild(a);
    a.style.setProperty('display', 'none');
    a.href = this.baseUrl + '/' + fileResponse.file;
    a.target = '_blank';
    a.download = fileResponse.fileName;
    a.click();
  }
}
