
import {of as observableOf, Observable} from 'rxjs';

import {AbstractExecutionStep} from '../../../../core/executor/abstract-execution-step';
import {ExecutionStepStatus} from '../../../../core/executor/execution-step-status';
import {TOAST_LIFE_INFINITE, ToastComponentsRegistry, ToastService} from '../../../../core/service/toast.service';
import {TranslateService} from '@ngx-translate/core';
import {FormViewerComponent} from '../../../form-viewer/form-viewer.component';
import {RequestCachingService} from '../../request-caching.service';
import {Entity} from '../../../helpers/entity';
import {FileHelper} from '../../../helpers/file-helper';

export class InvoiceToolbarItemInvoiceTemplateGenerateExecutionStep extends AbstractExecutionStep {

  public doExecute(): Observable<ExecutionStepStatus> {
    const payload = this.getPayload(),
      component = payload.getValue().component ? payload.getValue().component : payload.getValue();

    if (component instanceof FormViewerComponent && (!component.getSelectedEntity() ||
      !component.getSelectedEntity().id)
    ) {
      return observableOf({status: false, content: 'Can generate template only on existing entities!' });
    }

    if (component instanceof FormViewerComponent) {
      return this.doPrint(component);
    }

    return observableOf({status: false, content: 'Not configurated correctly!' });
  }

  private doPrint(component: FormViewerComponent): Observable<ExecutionStepStatus> {
    this.doExport('pdf', component).subscribe((fileResponse) => {
      this.getRequestCachingService().removeByExpression('phoenix');

      FileHelper.downloadFile(fileResponse);
      this.getToast().clear(ToastComponentsRegistry.PROGRESS_BAR, 300);
    });
    return observableOf({status: true, content: null});
  }

  private doExport(key: string, component: FormViewerComponent): Observable<any> {
    this.getToast().custom(ToastComponentsRegistry.PROGRESS_BAR, {
      severity: 'info',
      life: TOAST_LIFE_INFINITE,
      closable: false,
      summary: this.getTranslate().instant('COMMON.LOADING_PLEASE_WAIT')
    });

    const entity = component.getSelectedEntity();
    if (entity && entity.id) {
      const printTemplate = Entity.getValueInEmbedded(entity, 'invoicePossibility.invoiceTemplate');
      if (printTemplate && printTemplate.invoicePreview) {
        const datamodelId = component.getElementDatamodel().id;
        const url = `app/export/entity/${datamodelId}/file/${entity.id}/template/${printTemplate.invoicePreview.id}/${key}`;

        return this.genericCrudService.customPost(url, {});
      }
    }
  }

  private getRequestCachingService(): RequestCachingService|null {
    return this.injector.get(RequestCachingService, null);
  }

  private getToast(): ToastService|null {
    return this.injector.get(ToastService, null);
  }

  private getTranslate(): TranslateService|null {
    return this.injector.get(TranslateService, null);
  }
}
