/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./staff-cockpit-stat-summed-count-chart.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../generic-loading/generic-loading.component.ngfactory";
import * as i3 from "../../../generic-loading/generic-loading.component";
import * as i4 from "../../../../../../../../node_modules/primeng/components/chart/chart.ngfactory";
import * as i5 from "primeng/components/chart/chart";
import * as i6 from "@angular/common";
import * as i7 from "../../../../../../core/service/locale/calendar-locale.service";
import * as i8 from "@ngx-translate/core";
import * as i9 from "../services/stat-hours-plan-reals.service";
import * as i10 from "../../../../../../core/service/user-session.service";
import * as i11 from "../../../../../../core/authentication/authentication.service";
import * as i12 from "../../../../../services/generic-crud.service";
import * as i13 from "./staff-cockpit-stat-summed-count-chart.component";
var styles_StaffCockpitStatSummedCountChartComponent = [i0.styles];
var RenderType_StaffCockpitStatSummedCountChartComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_StaffCockpitStatSummedCountChartComponent, data: {} });
export { RenderType_StaffCockpitStatSummedCountChartComponent as RenderType_StaffCockpitStatSummedCountChartComponent };
function View_StaffCockpitStatSummedCountChartComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-generic-loading", [], null, null, null, i2.View_GenericLoadingComponent_0, i2.RenderType_GenericLoadingComponent)), i1.ɵdid(1, 114688, null, 0, i3.GenericLoadingComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
function View_StaffCockpitStatSummedCountChartComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "p-chart", [["type", "bar"]], null, null, null, i4.View_UIChart_0, i4.RenderType_UIChart)), i1.ɵdid(1, 4374528, null, 0, i5.UIChart, [i1.ElementRef], { type: [0, "type"], options: [1, "options"], data: [2, "data"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "bar"; var currVal_1 = _co.options; var currVal_2 = _co.data; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2); }, null); }
export function View_StaffCockpitStatSummedCountChartComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_StaffCockpitStatSummedCountChartComponent_1)), i1.ɵdid(1, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_StaffCockpitStatSummedCountChartComponent_2)), i1.ɵdid(3, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.isLoading; _ck(_v, 1, 0, currVal_0); var currVal_1 = !_co.isLoading; _ck(_v, 3, 0, currVal_1); }, null); }
export function View_StaffCockpitStatSummedCountChartComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "app-custom-staff-cockpit-stat-summed-count-chart", [], null, null, null, View_StaffCockpitStatSummedCountChartComponent_0, RenderType_StaffCockpitStatSummedCountChartComponent)), i1.ɵprd(512, null, i7.CalendarLocaleService, i7.CalendarLocaleService, [i8.TranslateService]), i1.ɵprd(512, null, i9.StatHoursPlanRealsService, i9.StatHoursPlanRealsService, [i10.UserSessionService, i11.AuthenticationService, i12.GenericCrudService]), i1.ɵdid(3, 245760, null, 0, i13.StaffCockpitStatSummedCountChartComponent, [i7.CalendarLocaleService, i1.ChangeDetectorRef, i9.StatHoursPlanRealsService], null, null)], function (_ck, _v) { _ck(_v, 3, 0); }, null); }
var StaffCockpitStatSummedCountChartComponentNgFactory = i1.ɵccf("app-custom-staff-cockpit-stat-summed-count-chart", i13.StaffCockpitStatSummedCountChartComponent, View_StaffCockpitStatSummedCountChartComponent_Host_0, {}, {}, []);
export { StaffCockpitStatSummedCountChartComponentNgFactory as StaffCockpitStatSummedCountChartComponentNgFactory };
