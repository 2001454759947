import { User } from '../user/user';

interface EmbeddedEntities {
  users?: User[];
}

export class Group {

  public static readonly LOCAL_STORAGE_NAME = 'group-local-storage';

  id: number;
  name: string;
  description: string;
  users: User[];

  _embedded?: EmbeddedEntities;

  constructor(
    id?: number,
    name?: string,
    description?: string,
    users?: User[]
  ) { }
}
