import { Injectable } from '@angular/core';
import { ToolbarItemDisabledCheckInterface } from "./toolbar-item-disabled-check-factory.service";
import { ToolbarItemAbstractDisabledCheckService } from "./toolbar-item-abstract-disabled-check.service";
@Injectable()
export class ToolbarItemCalculationResultDisabledCheckService extends ToolbarItemAbstractDisabledCheckService implements ToolbarItemDisabledCheckInterface {

  isDisabled(): boolean {
    let disabled = true;
    if ((this.entity && this.entity.calculationResults) || (this.entity && this.entity._embedded && this.entity._embedded.calculationResults)) {
      disabled = false;
    }

    return this.isDisabledGlobally() || disabled;
  }

}
