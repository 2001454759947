<p-dialog
  [(visible)]="isDialogVisible"
  *ngIf="isDialogVisible"
  [modal]="true"
  [width]="600"
  [height]="200"
>
  <p-toolbar styleClass="no-round-corner">
    <div class="buttons-container">
      <div class="item-spacer"></div>
      <div class="item-right">
        <button
          type="button"
          pButton
          (click)="onImport($event)"
          label="{{ 'COMMON.IMPORT' | translate }}"
        ></button>
      </div>
    </div>
  </p-toolbar>

  <div fxLayout="column" fxLayoutAlign="right left" class="input-block">

    <div class="fx-row" fxLayout="row" fxLayoutAlign="center stretch">
      <div fxFlex="30">Printer Name*:</div>
      <div fxFlex="60">
        <input
          type="text"
          [(ngModel)]="printerName"
          pInputText
          size="50"
          placeholder="Name"
        >
      </div>
    </div>

    <div class="fx-row" fxLayout="row" fxLayoutAlign="center stretch">
      <div fxFlex="30">SFTP Printer*:</div>
      <div fxFlex="60">
        <p-autoComplete
          [(ngModel)]="selectedSftpPrinter"
          [dropdown]="true"
          [suggestions]="sftpPrinterOptions"
          (completeMethod)="onSearchSftpPrinterOptions($event)"
          (onSelect)="onSelectPrinter($event)"
          [minLength]="1"
          field="name"
          appendTo="body"
          styleClass="flex-layout"
        ></p-autoComplete>
      </div>
    </div>

    <div class="fx-row" fxLayout="row" fxLayoutAlign="center stretch">
      <div fxFlex="30">SFTP Path*:</div>
      <div fxFlex="60">
        <input
          type="text"
          [(ngModel)]="sftpPath"
          pInputText
          size="50"
          placeholder="SFTP Path"
        >
      </div>
    </div>

  </div>
</p-dialog>
