<p-dropdown
  [filter]="true"
  [options]="options"
  [(ngModel)]="selectedOption"
  (onChange)="onOptionChanged($event)"
  [style]="{'width': '100%'}"
  appendTo="body"
  placeholder="---"
  [disabled]="disabled"
></p-dropdown>
