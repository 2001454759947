import {AbstractFormActionHandler} from './abstract-form-action-handler';
import {LocationService} from '../../../services/location.service';
import {ModuleNavigationService} from '../../../content-renderer/services/navigation/module-navigation.service';
import {ModulesStateService} from '../../../content-renderer/services/modules-state.service';

export class SetIdToUrlActionHandler extends AbstractFormActionHandler {

    public constructor(
        private locationService: LocationService,
        private moduleNavigation: ModuleNavigationService,
        private modulesStateService: ModulesStateService
    ) {
        super();
    }

    public handleAction(): void {
        const entity = this.getEntity();

      const moduleState = this.modulesStateService.getByComponent(this.getComponent());

      if (entity && entity.id && !this.locationService.hasParam('id')) {
        const route = this.moduleNavigation.getModuleRouter().replaceRouteEntity(entity);

        if (moduleState) {
          moduleState.url = route;
        }
      }
    }

    private isActionValid(): boolean {
      return true;
    }
}
