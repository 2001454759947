import {ChangeDetectionStrategy, Component, Input, OnInit} from '@angular/core';
import { ToolbarItemAbstract } from '../toolbar-item-abstract.component';
import { ToolbarItem } from '../../../../../../services/element/toolbar-item';

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'toolbar-item-open-link',
    templateUrl: './toolbar-item-open-link.component.html'
})
export class ToolbarItemOpenLink extends ToolbarItemAbstract {

    private toolbarItem: ToolbarItem;
    private entity: any;

    ngOnInit() {

    }

    public click() {
      let url = this.getToolbarItem().parameter;

      window.open(url, '_blank');
    }
}
