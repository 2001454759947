/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "primeng/components/dom/domhandler";
import * as i2 from "primeng/components/button/button";
import * as i3 from "@ngx-translate/core";
import * as i4 from "../../../../../p-button/p-button.directive";
import * as i5 from "./workhour-generate-entries-button.component";
import * as i6 from "../../../../../services/generic-crud.service";
import * as i7 from "../../../../../../core/message/message-growl.service";
var styles_WorkhourGenerateEntriesButtonComponent = [];
var RenderType_WorkhourGenerateEntriesButtonComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_WorkhourGenerateEntriesButtonComponent, data: {} });
export { RenderType_WorkhourGenerateEntriesButtonComponent as RenderType_WorkhourGenerateEntriesButtonComponent };
export function View_WorkhourGenerateEntriesButtonComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 6, "div", [["class", "ui-g-12"], ["style", "padding: 0px;"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 5, "button", [["class", "float-right btn"], ["pButton", ""]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onGenerateButtonClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵprd(4608, null, i1.DomHandler, i1.DomHandler, []), i0.ɵdid(3, 4341760, null, 0, i2.ButtonDirective, [i0.ElementRef], { label: [0, "label"] }, null), i0.ɵpid(131072, i3.TranslatePipe, [i3.TranslateService, i0.ChangeDetectorRef]), i0.ɵdid(5, 4341760, null, 0, i4.PButtonDirective, [i0.ElementRef], { label: [0, "label"] }, null), i0.ɵpid(131072, i3.TranslatePipe, [i3.TranslateService, i0.ChangeDetectorRef])], function (_ck, _v) { var currVal_0 = i0.ɵinlineInterpolate(1, "", i0.ɵunv(_v, 3, 0, i0.ɵnov(_v, 4).transform("WORK_HOUR.GENERATE_ENTRIES")), ""); _ck(_v, 3, 0, currVal_0); var currVal_1 = i0.ɵinlineInterpolate(1, "", i0.ɵunv(_v, 5, 0, i0.ɵnov(_v, 6).transform("WORK_HOUR.GENERATE_ENTRIES")), ""); _ck(_v, 5, 0, currVal_1); }, null); }
export function View_WorkhourGenerateEntriesButtonComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-workhour-generate-entries-button", [], null, null, null, View_WorkhourGenerateEntriesButtonComponent_0, RenderType_WorkhourGenerateEntriesButtonComponent)), i0.ɵdid(1, 49152, null, 0, i5.WorkhourGenerateEntriesButtonComponent, [i6.GenericCrudService, i7.MessageGrowlService, i3.TranslateService], null, null)], null, null); }
var WorkhourGenerateEntriesButtonComponentNgFactory = i0.ɵccf("app-workhour-generate-entries-button", i5.WorkhourGenerateEntriesButtonComponent, View_WorkhourGenerateEntriesButtonComponent_Host_0, { component: "component", filterItem: "filterItem", genericFilterComponent: "genericFilterComponent" }, {}, []);
export { WorkhourGenerateEntriesButtonComponentNgFactory as WorkhourGenerateEntriesButtonComponentNgFactory };
