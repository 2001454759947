import { Injectable } from '@angular/core';
import { ElementsStackService } from '../../../../services/elements-stack.service';
import { ComponentService } from '../../../../services/component-highlight-stack.service';
import {ModulesStateService} from '../../../../services/modules-state.service';
import {CustomButtonGenericCheckService} from './custom-button-generic-check.service';
import {CustomButtonPreCalculationCheckService} from './custom-button-pre-calculation-check.service';
import {AbstractGenericGridComponent} from '../../../abstract-generic-grid.component';

export interface CustomButtonCheckInterface {
  setComponent(component: AbstractGenericGridComponent): CustomButtonCheckInterface;
  setEntity(entity: any): CustomButtonCheckInterface;
  setColumn(column: any): CustomButtonCheckInterface;
  isVisible(): boolean;
}

@Injectable()
export class CustomButtonCheckFactoryService {

  constructor(
    private elementStackService: ElementsStackService,
    private componentService: ComponentService,
    private modulesStateService: ModulesStateService
  ) { }

  public instance(component: AbstractGenericGridComponent, entity: any, column: any): CustomButtonCheckInterface {
    let checker: CustomButtonCheckInterface = null;

    switch (column.field.customButtonChecker) {
      case 'InquiryPositionPreCalculation':
        checker = new CustomButtonPreCalculationCheckService(
          this.elementStackService,
          this.componentService,
          this.modulesStateService
        );
        break;
      default:
        checker = new CustomButtonGenericCheckService(this.elementStackService, this.componentService, this.modulesStateService);
    }

    checker.setComponent(component);
    checker.setEntity(entity);
    checker.setColumn(column);

    return checker;
  }

  public getCheckers() {
    return [
      {label: 'InquiryPositionPreCalculation', value: 'InquiryPositionPreCalculation'}
    ];
  }
}
