export class Constants {
  public static readonly DEFAULT_COLUMN_WIDTH: number = 150;
  public static readonly DEFAULT_DECIMAL_PLACES = 2;
  public static readonly MODULE_ELEMENT_COLUMN_NAME = 'moduleElementColumn';
  public static readonly VALIDATION_ERROR_TRANSLATIONS_PATH = 'VALIDATON_MESSAGE.ERRORS';
  public static readonly SHOW_WITHOUT_MASTER_FILTER_FIELD_FILTER_KEY = 'showWithoutMasterFilterField';

  public static readonly APP_API_ROUTE = 'app';
  public static readonly SUPERADMIN_API_ROUTE = 'superadmin';
  public static readonly PHOENIX_API_ROUTE = 'phoenix';
  public static readonly INTRANET_API_ROUTE = 'intranet';

  public static readonly EVENT_CLICK_FILTER_TOOLBAR_ITEM = 'filterToolbarItemMouseClick';
  public static readonly EVENT_CLICK_GRID_ENTITY_SELECTED = 'gridEntitySelectedMouseClick';


  public static readonly COLLAPSE_DIRECTION_UP = 'up';
  public static readonly COLLAPSE_DIRECTION_DOWN = 'down';
  public static readonly COLLAPSE_DIRECTION_LEFT = 'left';
  public static readonly COLLAPSE_DIRECTION_RIGHT = 'right';

  public static readonly COLLECTIVE_AGREEMENT_EMPLOYEES = 'employee';
  public static readonly COLLECTIVE_AGREEMENT_HIRED_WORKER = 'worker';
  public static readonly COLLECTIVE_AGREEMENT_EXTRA_PAY = 'extraPay';
  public static readonly COLLECTIVE_AGREEMENT_CUSTOM_LOADER_PATH = 'phoenix/collectiveagreements/special';

  public static readonly UI_CELL_DISABLED_FLAG = 'ui-editable-disabled';
  public static readonly UI_FORM_ELEMENT_INPUT_CONTAINER_CLASS = 'element-input-container';
  public static readonly UI_FORM_ELEMENT_INPUT_CONTAINER_HIDDEN_CLASS = 'element-hidden';
  public static readonly UI_FORM_ELEMENT_INPUT_CLASS = 'ui-inputtext';
}
