/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./field-action-set-extra-param-from-another-field.component";
import * as i2 from "../../../../../services/generic-crud.service";
import * as i3 from "../../../../services/double-click.service";
var styles_FieldActionSetExtraParamFromAnotherFieldComponent = [];
var RenderType_FieldActionSetExtraParamFromAnotherFieldComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_FieldActionSetExtraParamFromAnotherFieldComponent, data: {} });
export { RenderType_FieldActionSetExtraParamFromAnotherFieldComponent as RenderType_FieldActionSetExtraParamFromAnotherFieldComponent };
export function View_FieldActionSetExtraParamFromAnotherFieldComponent_0(_l) { return i0.ɵvid(0, [], null, null); }
export function View_FieldActionSetExtraParamFromAnotherFieldComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "field-action-set-extra-param-from-another-field", [], null, null, null, View_FieldActionSetExtraParamFromAnotherFieldComponent_0, RenderType_FieldActionSetExtraParamFromAnotherFieldComponent)), i0.ɵdid(1, 245760, null, 0, i1.FieldActionSetExtraParamFromAnotherFieldComponent, [i2.GenericCrudService, i3.DoubleClickService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var FieldActionSetExtraParamFromAnotherFieldComponentNgFactory = i0.ɵccf("field-action-set-extra-param-from-another-field", i1.FieldActionSetExtraParamFromAnotherFieldComponent, View_FieldActionSetExtraParamFromAnotherFieldComponent_Host_0, { action: "action", field: "field", grid: "grid" }, {}, []);
export { FieldActionSetExtraParamFromAnotherFieldComponentNgFactory as FieldActionSetExtraParamFromAnotherFieldComponentNgFactory };
