import {ChangeDetectionStrategy, Component} from '@angular/core';
import {ToolbarItemAbstract} from '../toolbar-item-abstract.component';
import {ModulesStateService} from '../../../../../services/modules-state.service';
import {ModuleNavigationService} from '../../../../../services/navigation/module-navigation.service';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-toolbar-item-back',
  templateUrl: './toolbar-item-back.component.html'
})
export class ToolbarItemBack extends ToolbarItemAbstract {

  constructor(
    private navigationService: ModuleNavigationService,
    private modulesStateService: ModulesStateService
  ) {
    super();
  }

  public click() {
    const moduleState = this.modulesStateService.getByComponent(this.getComponent());

    this.navigationService
      .openPrevious(moduleState)
      .subscribe();
  }
}
