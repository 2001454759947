/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../../../../../../../node_modules/primeng/components/dialog/dialog.ngfactory";
import * as i2 from "primeng/components/dialog/dialog";
import * as i3 from "@ngx-translate/core";
import * as i4 from "../../../../generic-grid-simple-search/generic-grid-simple-search.component.ngfactory";
import * as i5 from "../../../../generic-grid-simple-search/generic-grid-simple-search.component";
import * as i6 from "../../../../../../services/simple-search/simple-search.service";
import * as i7 from "@angular/common";
import * as i8 from "./toolbar-item-simple-search.component";
var styles_ToolbarItemSimpleSearchComponent = [];
var RenderType_ToolbarItemSimpleSearchComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_ToolbarItemSimpleSearchComponent, data: {} });
export { RenderType_ToolbarItemSimpleSearchComponent as RenderType_ToolbarItemSimpleSearchComponent };
function View_ToolbarItemSimpleSearchComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 6, "p-dialog", [["appendTo", "body"], ["showEffect", "fade"], ["width", "600"]], null, [[null, "visibleChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("visibleChange" === en)) {
        var pd_0 = ((_co.isDialogVisible = $event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i1.View_Dialog_0, i1.RenderType_Dialog)), i0.ɵdid(1, 180224, null, 2, i2.Dialog, [i0.ElementRef, i0.Renderer2, i0.NgZone], { visible: [0, "visible"], header: [1, "header"], modal: [2, "modal"], responsive: [3, "responsive"], appendTo: [4, "appendTo"], width: [5, "width"], minWidth: [6, "minWidth"] }, { visibleChange: "visibleChange" }), i0.ɵqud(603979776, 1, { headerFacet: 1 }), i0.ɵqud(603979776, 2, { footerFacet: 1 }), i0.ɵpid(131072, i3.TranslatePipe, [i3.TranslateService, i0.ChangeDetectorRef]), (_l()(), i0.ɵeld(5, 0, null, 1, 1, "app-generic-grid-simple-search", [], null, [[null, "onSearch"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("onSearch" === en)) {
        var pd_0 = (_co.onSearch($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i4.View_GenericGridSimpleSearchComponent_0, i4.RenderType_GenericGridSimpleSearchComponent)), i0.ɵdid(6, 4440064, null, 0, i5.GenericGridSimpleSearchComponent, [i6.SimpleSearchService, i0.ChangeDetectorRef], { component: [0, "component"] }, { onSearch: "onSearch" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.isDialogVisible; var currVal_1 = i0.ɵinlineInterpolate(1, "", i0.ɵunv(_v, 1, 1, i0.ɵnov(_v, 4).transform("TOOLBARMANAGEMENT.SIMPLE_SEARCH")), ""); var currVal_2 = false; var currVal_3 = true; var currVal_4 = "body"; var currVal_5 = "600"; var currVal_6 = 600; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); var currVal_7 = _co.component; _ck(_v, 6, 0, currVal_7); }, null); }
export function View_ToolbarItemSimpleSearchComponent_0(_l) { return i0.ɵvid(2, [(_l()(), i0.ɵand(16777216, null, null, 1, null, View_ToolbarItemSimpleSearchComponent_1)), i0.ɵdid(1, 16384, null, 0, i7.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.isDialogVisible; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_ToolbarItemSimpleSearchComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-toolbar-item-simple-search", [], null, null, null, View_ToolbarItemSimpleSearchComponent_0, RenderType_ToolbarItemSimpleSearchComponent)), i0.ɵdid(1, 245760, null, 0, i8.ToolbarItemSimpleSearchComponent, [i0.ChangeDetectorRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ToolbarItemSimpleSearchComponentNgFactory = i0.ɵccf("app-toolbar-item-simple-search", i8.ToolbarItemSimpleSearchComponent, View_ToolbarItemSimpleSearchComponent_Host_0, { params: "params" }, {}, []);
export { ToolbarItemSimpleSearchComponentNgFactory as ToolbarItemSimpleSearchComponentNgFactory };
