
import {forkJoin as observableForkJoin, of as observableOf,  Observable } from 'rxjs';

import {catchError} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Event } from 'app/core/events/interfaces/event';
import { EventActionsSubscribeResponse } from '../interfaces/event-actions-subscribe-response';
import { ActionHandlerSubscribeResponse } from '../interfaces/action-handler-subscribe-response';
import { EventRegistry } from './event-registry';
import { EventActionHandlerFactory } from './event-action-handler-factory';
import { AbstractActionHandler } from '../action/abstract-action-handler';

@Injectable()
export class EventHandlerService {

    private eventRegistry: EventRegistry;
    private eventHandlerFactory: EventActionHandlerFactory;

    public constructor() {
        this.eventRegistry = new EventRegistry();
        this.eventHandlerFactory = new EventActionHandlerFactory(this);
    }  

    public handle(event: Event, additionalActionHandlers: AbstractActionHandler[] = []): Observable<EventActionsSubscribeResponse> {
        let actionHandlerObservers = [];

        for (let actionHandler of this.eventHandlerFactory.getActionsHandler(event, additionalActionHandlers)) {
            actionHandlerObservers.push(actionHandler.handleAction());
        }

        if (actionHandlerObservers.length === 0) {
            return observableOf({
                status: true,
                content: []
            });
        }

        return Observable.create((observer) => {
            observableForkJoin(actionHandlerObservers).pipe(
                catchError((response: any) => {
                    return observableOf(false);
                }))
                .subscribe((response: ActionHandlerSubscribeResponse[]) => {
                    let content = [],
                        status = true;

                    for (let actionObserver of response) {
                        if (!actionObserver.status) {
                            status = actionObserver.status;
                            content.push(actionObserver.content);
                        }
                    }

                    observer.next({
                        status: status,
                        content: content
                    });
                    observer.complete();
                });
        })
        
    }

    public getRegistry(): EventRegistry {
        return this.eventRegistry;
    }
}
