
import {of as observableOf, Observable} from 'rxjs';

import {switchMap} from 'rxjs/operators';
import {AbstractExecutionStep} from '../../../../../core/executor/abstract-execution-step';
import {ExecutionStepStatus} from '../../../../../core/executor/execution-step-status';
import {ExpertSearch} from '../../../../services/expert-search/expert-search';
import {cloneDeep} from 'lodash';

export class SaveExpertSearchExecutionStep extends AbstractExecutionStep {

  public doExecute(): Observable<ExecutionStepStatus> {
    const expertSearch: ExpertSearch = this.getPayload().getValue().expertSearch;

    if (!expertSearch) {
      return this.getFailObservable('ExpertSearch not found!');
    }

    return this.doSave(expertSearch);
  }

  public doSave(expertSearch: ExpertSearch): Observable<ExecutionStepStatus> {
    const cloneExpertSearch = cloneDeep(expertSearch);

    delete cloneExpertSearch.containers;
    delete cloneExpertSearch._embedded;

    if (cloneExpertSearch.id) {
      return this.genericCrudService
        .editEntity(`app/expertsearches/${cloneExpertSearch.id}`, cloneExpertSearch).pipe(
        switchMap((response) => {
          return observableOf({status: true, content: null});
        }));
    } else {
      return this.genericCrudService
        .createEntity('app/expertsearches', cloneExpertSearch).pipe(
        switchMap((aExpertSearch: ExpertSearch) => {
          expertSearch.id = aExpertSearch.id;

          return observableOf({status: true, content: null});
        }));
    }
  }
}
