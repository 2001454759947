
import {of as observableOf, Observable} from 'rxjs';
import {AbstractExecutionStep} from '../../../../core/executor/abstract-execution-step';
import {ExecutionStepStatus} from '../../../../core/executor/execution-step-status';
import {AbstractGenericGridComponent} from '../../../content-renderer/elements/abstract-generic-grid.component';
import {Entity} from '../../../helpers/entity';

export class OfferGtcTextDisableFieldsExecutionStep extends AbstractExecutionStep {

  public doExecute(): Observable<ExecutionStepStatus> {
    const payload = this.getPayload(),
      component = payload.getValue().component ? payload.getValue().component : payload.getValue();

    if (!(component instanceof AbstractGenericGridComponent)) {
      return this.getFailObservable('You need to pass AbstractGenericGridComponent as Payload value!');
    }

    return this.doDisableFields(component);
  }

  doDisableFields(grid: AbstractGenericGridComponent): Observable<ExecutionStepStatus> {

    for (const entity of grid.entities) {
      const doLock = !Entity.getValue(entity, 'canBeChanged');

      for (const fieldToLock of grid.fields) {
        fieldToLock.isReadOnlyByEntity = fieldToLock.isReadOnlyByEntity || {};

        fieldToLock.isReadOnlyByEntity[entity.id] = doLock;
      }
    }

    return observableOf({status: true, content: null});
  }
}
