import {
  Component, EventEmitter, Input, Output
} from '@angular/core';
import {MessageService} from '../../../../core/message/message.service';
import {ExpertSearch} from '../../../services/expert-search/expert-search';
import {MessageGrowlService} from '../../../../core/message/message-growl.service';
import {ExpertSearchService} from '../service/expert-search.service';

@Component({
  selector: 'app-search-management-edit',
  templateUrl: './search-management-edit.component.html',
  providers: [
    ExpertSearchService
  ],
  styles: [`
    .save-expert-search-button {
      margin: 2px;
      margin-bottom: 20px;
      width: 100%;
    }
  `]
})
export class SearchManagementEditComponent {

  @Input() public expertSearch: ExpertSearch = null;

  @Output() public onExpertSearchSave: EventEmitter<ExpertSearch> = new EventEmitter();

  public isDataLoading = false;

  public constructor(
    protected messageService: MessageService,
    protected messageGrowlService: MessageGrowlService,
    protected expertSearchService: ExpertSearchService
  ) {

  }

  public isValid(): boolean {
    return this.expertSearch && this.expertSearch.name && this.expertSearch.datamodel !== null;
  }

  public onSave(event: any): void {
    this.isDataLoading = true;

    this.expertSearchService
      .save(this.expertSearch)
      .subscribe(() => {
        this.onExpertSearchSave.emit(this.expertSearch);
        this.isDataLoading = false;

        this.messageGrowlService.showDataSaved();
      });
  }
}
