<table>
  <tr>
    <td [hidden]="isHidden()">
      <button pButton
              [style.width]="'100%'"
              label="Action Parameters"
              (click)="openForm($event)">
      </button>
    </td>
  </tr>
</table>
