export class Parameter {

    key: string;
    value: any;

    public constructor(key: string, value: any) {
        this.key = key;
        this.value = value;
    }
}

export class Data {

    hash: string;

    parameters: Parameter[];

    public constructor(hash: string) {
        this.hash = hash;
        this.parameters = [];
    }

    public setParameters(parameters: Parameter[]): Data {
        this.parameters = parameters;
        return this;
    }

    public getParameters(parameters: Parameter[]) {
        return this.parameters;
    }

    public addParameter(parameter: Parameter): Data {
        this.parameters.push(parameter);
        return this;
    }

    public getParameter(key: string): Parameter|null {
      for (const parameter of this.parameters) {
        if (key === parameter.key) {
          return parameter;
        }
      }

      return null;
    }

  public getParameterValue(key: string): any|null {
    const parameter = this.getParameter(key);

    return parameter ? parameter.value : null;
  }

    public removeParameter(parameter: Parameter): Data {
        if (parameter && this.parameters.length > 0) {
            let parameterIndex = this.parameters.findIndex((aParameter) => {
                return parameter.key === aParameter.key;
            });

            if (parameterIndex !== -1) {
                this.parameters.splice(parameterIndex, 1);
            }
        }

        return this;
    }
}
