import {Datamodel} from './datamodel';

export class DataModelAbbrevation {
  id: number;
  datamodel: Datamodel;
  abbrevation: string;
  conditionalProperty: string;
  conditionalPropertyValue: string;
  abbrevationValue: string;
  comparator: string;
  isDirty: boolean;

    constructor(id?: number,
                abbrevation?: string,
                conditionalProperty?: string,
                conditionalPropertyValue?: string,
                abbrevationValue?: string) {
        this.id = id;
        this.abbrevation = abbrevation;
        this.conditionalProperty = conditionalProperty;
        this.conditionalPropertyValue = conditionalPropertyValue;
        this.abbrevationValue = abbrevationValue;
        this.isDirty = false;
    }
}
