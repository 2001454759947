
import {of as observableOf, Observable} from 'rxjs';
import { Injectable } from '@angular/core';
import {ModulesStateService} from './modules-state.service';
import {GenericElementAbstract} from '../elements/generic-element-abstract.component';

@Injectable()
export class ElementsSubscriptionService {

  public constructor(
    protected modulesStateService: ModulesStateService
  ) {

  }

  public destroyPartsByCurrentModule(): Observable<void> {
    const moduleState = this.modulesStateService.getCurrent();

    if (moduleState) {
      const partModuleStates = moduleState.getParts();

      for (const partModuleState of partModuleStates) {
        for (const component of partModuleState.getAllComponents()) {
          if (component instanceof GenericElementAbstract) {
            component.destroySubscriptions();
          }
        }
      }
    }

    return observableOf(null);
  }

}
