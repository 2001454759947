import {ExecutorActionEvent} from './service/executor-actions/executor-action-event';
import {ExecutionStepParameter} from './execution-step-parameter';

export class ExecutionStepEventParameter extends ExecutionStepParameter {
  protected readonly value: ExecutorActionEvent;

  public constructor(value: any) {
    super('event', value);
    this.value = value;
  }
}
