import {Component, OnInit} from '@angular/core';
import {AbstractGridColumnComponent} from '../abstract-grid-column.component';
import {GenericGridComponent} from '../../generic-grid.component';
import {EntityHydrator} from '../../../../../services/entity-hydrator.service';
import {GenericTreeGridSelectionService} from './service/generic-tree-grid-selection.service';
import {GenericGridSelectionService} from './service/generic-grid-selection.service';
import {AbstractSelectionService} from './service/abstract-selection.service';
import {SelectionColumnComponent} from './selection-column.component';

@Component({
  selector: 'app-parent-only-selection-column',
  template: `    
    <p-checkbox
      [disabled]="node && node.parent"
      pInputCheckbox
      binary="true"
      (onChange)="onChange($event)"
      [ngModel]="isSelected()"
    ></p-checkbox>
  `,
  providers: [GenericTreeGridSelectionService, GenericGridSelectionService]
})
export class ParentOnlySelectionColumnComponent extends SelectionColumnComponent implements OnInit {

  ngOnInit() {
    super.ngOnInit();

    if (!this.node) {
      console.error('Node missing!');
    }
  }

}
