import {ElementRef, Injectable, Renderer2} from '@angular/core';
import {AbstractGenericGridComponent} from '../../abstract-generic-grid.component';
import {RgbColorAdapter} from '../../../../helpers/rgb-color-adapter';

@Injectable()
export class GenericGridLayoutService {

  public static SUMMARY_ENTITY_ROW_STYE = 'summary-entity-row';
  public static LOCKED_ENTITY_ROW_STYE = 'locked-entity-row';
  protected static DELETED_ENTITY_ROW_STYE = 'deleted-entity-row';
  protected static EXPIRED_ENTITY_ROW_STYE = 'expired-entity-row';

  protected grid: AbstractGenericGridComponent = null;
  protected elementRef: ElementRef = null;
  protected renderer: Renderer2 = null;

  public static adaptTrColor(tr, color): void {
    if (tr) {
      const currentTds = Array.prototype.slice.call( tr.getElementsByTagName('td') ) || [];

      if (!tr.classList.contains(GenericGridLayoutService.DELETED_ENTITY_ROW_STYE) &&
        !tr.classList.contains(GenericGridLayoutService.EXPIRED_ENTITY_ROW_STYE) &&
        !tr.classList.contains(GenericGridLayoutService.LOCKED_ENTITY_ROW_STYE) &&
        !tr.classList.contains(GenericGridLayoutService.SUMMARY_ENTITY_ROW_STYE)
      ) {
        for (const td of currentTds) {
          td.style.color = color;
        }
      }
    }
  }

  public static getRowAdaptColor(row): string {
    const style = window.getComputedStyle(row, ''),
      bgColor = style.getPropertyValue('background-color');

    return RgbColorAdapter.adapt(bgColor, 'black');
  }

  public adaptRowColor(): void {
    const editingCell = this.grid && this.grid.grid ? this.grid.grid.editingCell : null;

    if (editingCell) {
      const tr = editingCell.closest('tr');

      if (tr) {
        GenericGridLayoutService.adaptTrColor(tr, GenericGridLayoutService.getRowAdaptColor(tr));
      }
    }
  }

  public adaptRowsColor(): void {
    const oddRows = this.elementRef.nativeElement.querySelectorAll('.ui-datatable-odd'),
      evenRows = this.elementRef.nativeElement.querySelectorAll('.ui-datatable-even');

    for (const row of oddRows) {
      GenericGridLayoutService.adaptTrColor(row, GenericGridLayoutService.getRowAdaptColor(row));
    }

    for (const row of evenRows) {
      GenericGridLayoutService.adaptTrColor(row, GenericGridLayoutService.getRowAdaptColor(row));
    }
  }

  public setGrid(grid: AbstractGenericGridComponent): this {
    this.grid = grid;
    return this;
  }

  public getGrid(): AbstractGenericGridComponent | null {
    return this.grid || null;
  }

  public setElementRef(elementRef: ElementRef): this {
    this.elementRef = elementRef;
    return this;
  }

  public getElementRef(): ElementRef|null {
    return this.elementRef;
  }

  public setRenderer(renderer: Renderer2): this {
    this.renderer = renderer;
    return this;
  }

  public getRenderer(): Renderer2|null {
    return this.renderer;
  }
}
