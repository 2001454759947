
    <div [formGroup]="formGroup">
      <p-checkbox
        *ngIf="!this.element.isNullable"
        appSeleniumDirective
        [element]="element"
        binary="true"
        [formControlName]="formControlName"
        (onChange)="onElementValueChange($event)"
        [hidden]="this.element.isHidden"
        [tabIndex]="this.element.tabIndex"
      ></p-checkbox>
      <p-triStateCheckbox
        appSeleniumDirective
        [element]="element"
        *ngIf="this.element.isNullable"
        binary="true"
        [formControlName]="formControlName"
        (onChange)="onElementValueChange($event)"
        [hidden]="this.element.isHidden"
        [tabIndex]="this.element.tabIndex"
      ></p-triStateCheckbox>
    </div>
  