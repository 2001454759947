import {Injectable} from '@angular/core';
import {ToolbarItem} from "../../../../../services/element/toolbar-item";
import {ToolbarItemDisabledCheckInterface} from "./toolbar-item-disabled-check-factory.service";
import {ToolbarItemAbstractDisabledCheckService} from "./toolbar-item-abstract-disabled-check.service";
import {AbstractGenericGridComponent} from '../../../abstract-generic-grid.component';

@Injectable()
export class ToolbarItemSelectionColumnSelectedDisabledCheckService extends ToolbarItemAbstractDisabledCheckService implements ToolbarItemDisabledCheckInterface {

  isDisabled(): boolean {
    return this.isDisabledGlobally() || ((this.toolbarItem.contextTarget === ToolbarItem.CONTEXT_TARGET_GRID
      || this.toolbarItem.contextTarget === ToolbarItem.CONTEXT_TARGET_FORM
      || this.toolbarItem.contextTarget === ToolbarItem.CONTEXT_TARGET_TREE) ? this.checkEntity() : true);
  }

  private checkEntity(): boolean {
    if(this.component instanceof AbstractGenericGridComponent && this.component.selectedEntities && this.component.selectedEntities.length > 0){
      return false;
    }
    return true;
  }
}
