import {of as observableOf, Observable} from 'rxjs';
import {ExecutionStepStatus} from '../../../../core/executor/execution-step-status';
import {Entity} from '../../../helpers/entity';
import {TodoFormComponent} from '../../../content-renderer/elements/custom/todo-form/todo-form.component';
import {ComponentOpenModuleExecutionStep} from '../component-open-module-execution-step';
import {DoubleClickService} from '../../../content-renderer/services/double-click.service';

export class OpenTodoOwnerModuleExecutionStep extends ComponentOpenModuleExecutionStep {

  protected static getModuleParamKey(entity): string {
    const fqn = entity.fqn;

    let moduleParamKey = '';

    switch (fqn) {
      case 'PhoenixBundle\\Entity\\Customer':
        moduleParamKey = 'customerModule';
        break;
      case 'PhoenixBundle\\Entity\\LeasedEmployee':
        moduleParamKey = 'leasedEmployeeModule';
        break;
      case 'PhoenixBundle\\Entity\\Memo':
        moduleParamKey = 'memoModule';
        break;
      default:
        moduleParamKey = 'todoModule';
        break;
    }

    return moduleParamKey;
  }

  public doExecute(): Observable<ExecutionStepStatus> {
    const payload = this.getPayload(),
      component = payload.getValue().component ? payload.getValue().component : payload.getValue();

    if (!(component instanceof TodoFormComponent)) {
      return this.getFailObservable('You need to pass TodoFormComponent as Payload value!');
    }

    return this.doClick(component);
  }

  protected doClick(component: TodoFormComponent|any):
    Observable<ExecutionStepStatus> {

    const doubleClickService: DoubleClickService = this.injector.get(DoubleClickService, null);

    const entity = component.getSelectedEntity(),
      owner = Entity.getValue(entity, 'owner') || Entity.getValueInEmbedded(entity, 'owner');

    const dialogWidth = +this.getParamValue('dialogWidth'),
      dialogHeight = +this.getParamValue('dialogHeight'),
      target = this.getParamValue('target'),
      module = this.getParamValue(OpenTodoOwnerModuleExecutionStep.getModuleParamKey(owner));

    doubleClickService
      .setDialogOptions({
        height: dialogHeight,
        width: dialogWidth,
        maximized: this.getParamValue('maximized')
      })
      .handleOpenModule(component, target, module, owner);

    return observableOf({status: true, content: null});
  }
}
