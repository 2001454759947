<span *ngIf="dmsUser && dmsUser.isActive">
  <span style="color: black;">{{ 'MODULEMANAGEMENT.DOCUMENTS' | translate }}:</span>
  <span style="color: black;"
    (click)="openModule(1)"
    [ngClass]="documentCounterData.countNew === 0 ? 'disabled' : ''"
  >
    {{documentCounterData.countNew.toString()}}
  </span>
  <span style="color: black;">/</span>
  <span style="color: black;"
    (click)="openModule(2)"
    [ngClass]="documentCounterData.countMy === 0 ? 'disabled' : ''"
  >
    {{documentCounterData.countMy.toString()}}
  </span>
  <span style="color: black;">/</span>
  <span style="color: black;"
        (click)="openModule(3)"
        [ngClass]="documentCounterData.countGroup === 0 ? 'disabled' : ''"
  >
    {{documentCounterData.countGroup.toString()}}
  </span>
</span>
