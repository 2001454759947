<p-toast key="progressBar" position="top-center" [modal]="true" [baseZIndex]="5000">
  <ng-template let-message pTemplate="message">
    <div style="text-align: center">
      <i class="pi pi-exclamation-info" style="font-size: 3em"></i>
      <h3 *ngIf="message.summary">{{ message.summary }}</h3>
      <p *ngIf="message.detail">{{ message.detail }}</p>
    </div>
    <div>
      <p-progressBar mode="indeterminate" [style]="{'height': '10px'}"></p-progressBar>
    </div>
  </ng-template>
</p-toast>
