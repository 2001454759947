<a (click)="onItemClick(item)" *ngIf="!item.isSeparator && item.isCustomMenuItem" [pTooltip]="item.tooltip" [tooltipPosition]="menuPosition == 'right' ? 'left' : 'right'">
  <div class="item-container item-text-container">
    {{item.label}}
  </div>
</a>
<p-dialog *ngIf="isDialogVisible" (onAfterHide)="dialogHide()" appendTo="body" [responsive]="true"
          [(visible)]="isDialogVisible" showEffect="fade" header="Mitarbeiterkostenauswertung import"
          [modal]="true" [width]="400" [height]="250">
  <div class="ui-grid ui-grid-responsive ui-fluid">
    <div class="ui-grid-row">
      <div class="ui-grid-col-4">
        <label for="branchOfficeId">
          BranchOffice: *
        </label>
      </div>
      <div class="ui-grid-col-8">
        <p-dropdown [options]="branchOfficeDropdown" [(ngModel)]="selectedBranchOffice" id="branchOfficeId" filter="filter" [style]="{'width':'100%'}" required></p-dropdown>
      </div>
    </div>
    <div class="ui-grid-row">
      <div class="ui-grid-col-4">
        <label for="periodId">
          Periode:
        </label>
      </div>
      <div class="ui-grid-col-8">
        <p-dropdown [options]="periodsDropdown" [(ngModel)]="selectedPeriod" (onChange)="checkPeriodAndBranchOffice()" id="periodId" filter="filter" [style]="{'width':'100%'}"></p-dropdown>
      </div>
    </div>
    <div class="ui-grid-row">
      <div class="ui-grid-col-12">
        <p-fileUpload
          name="file[]"
          multiple="multiple"
          withCredentials="true"
          customUpload="true"
          (uploadHandler)="onUpload($event)"
          accept=".csv"
          maxFileSize="100000000"
          chooseLabel="{{ 'COMMON.CHOOSE' | translate }}"
          cancelLabel="{{ 'COMMON.CANCEL' | translate }}"
          uploadLabel="{{ 'COMMON.UPLOAD' | translate }}"
          [disabled]="!selectedPeriod"
        >
        </p-fileUpload></div>
    </div>
  </div>
  <div class="ui-grid-row">
    <p-progressBar *ngIf="isDataLoading" mode="indeterminate" [style]="{'height': '6px', 'margin-top': '5px'}"></p-progressBar>
  </div>
</p-dialog>
