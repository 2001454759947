import { Component } from '@angular/core';
import { AbstractGenericGridComponent } from 'app/shared/content-renderer/elements/abstract-generic-grid.component';
import {ToolbarItemAbstract} from '../../toolbar-item-abstract.component';
import {MessageGrowlService} from '../../../../../../../../core/message/message-growl.service';
import {DoubleClickService} from '../../../../../../services/double-click.service';
import {MasterEntityConfig} from '../../../../../../services/ElementContext';
import {GenericCrudService} from '../../../../../../../services/generic-crud.service';
import {ModulesStateService} from '../../../../../../services/modules-state.service';
import {ModuleNavigationService} from '../../../../../../services/navigation/module-navigation.service';

@Component({
  selector: 'app-toolbar-item-open-module-assignments-leased-employee',
  template: ''
})
export class ToolbarItemOpenModuleAssignmentsLeasedEmployeeComponent extends ToolbarItemAbstract {

  constructor(
    protected genericCrudService: GenericCrudService,
    private messageGrowlService: MessageGrowlService,
    private doubleClickService: DoubleClickService,
    protected modulesStateService: ModulesStateService,
    protected navigationService: ModuleNavigationService
  ) {
    super();
  }

  public click() {
    const component = this.getComponent();

    if (!this.validateInGridContext(component)) {
        return this.messageGrowlService.error(this.getValidateMessage());
    }
  }

  private doOpenModuleInGridContext(gridComponent: AbstractGenericGridComponent) {
    const masterEntity = this.getMasterEntity(gridComponent),
      moduleState = this.modulesStateService.getByComponent(gridComponent);

    if (masterEntity) {
        const assignments = {
          inquiryPosition: masterEntity,
          leasedEmployees: gridComponent.selectedEntities
        };

        this.genericCrudService.customPost('phoenix/assignments/fromLeasedEmployee', assignments, {embedded: 'none'}).subscribe(() => {
            try {
                gridComponent.masterElementContext.component.loadEntities().subscribe();
            } catch (e) {}
            this.navigationService
              .openPrevious(moduleState)
              .subscribe();
        });

    }
  }

  private getMasterEntity(gridComponent: AbstractGenericGridComponent): MasterEntityConfig|null {
    const context = gridComponent.getElementContext();
    let result = null;

    for (const masterEntityConfig of context.masterEntities) {
      if (masterEntityConfig.name === 'inquiryPosition') {
        result = {
          id: masterEntityConfig.value,
          fqn: 'PhoenixBundle\\Entity\\InquiryPosition'
        };
        break;
      }
    }

    return result;
  }

  private validateInGridContext(gridComponent) {
    if (gridComponent instanceof AbstractGenericGridComponent) {
        this.doOpenModuleInGridContext(gridComponent);
        return true;
    }
    return false;
  }

}
