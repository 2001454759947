import { Element, ElementLayout } from './element';

export class ElementLayoutFieldset extends ElementLayout {
  constructor(
    public children: any[] = [],
    public showLabel: boolean = true
  ) {
    super();
    this.typeElement = 'fieldset';
    this.canHaveChildren = true;
  }
}
