import {ChangeDetectionStrategy, Component, Input, OnInit} from '@angular/core';
import { ToolbarItemAbstract } from '../toolbar-item-abstract.component';
import { MessageGrowlService } from '../../../../../../../core/message/message-growl.service';
import { MessageService } from '../../../../../../../core/message/message.service';
import { GenericCrudService } from '../../../../../../services/generic-crud.service';
import { ComponentService } from '../../../../../services/component-highlight-stack.service';
import { AbstractGenericGridComponent } from 'app/shared/content-renderer/elements/abstract-generic-grid.component';
import {EntityDataStoreService} from '../../../../../services/entity-data-store.service';
import {DoubleClickService} from '../../../../../services/double-click.service';
import {ModulesStateService} from '../../../../../services/modules-state.service';
import {ModuleNavigationService} from '../../../../../services/navigation/module-navigation.service';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'toolbar-item-import-multiselected-to-master',
  templateUrl: './toolbar-item-import-multiselected-to-master.component.html'
})
export class ToolbarItemImportMultiselectedToMasterComponent extends ToolbarItemAbstract {

  private masterEntity: any;

  constructor(
    protected messageGrowlService: MessageGrowlService,
    protected messageService: MessageService,
    protected genericCrudService: GenericCrudService,
    private entityDataStore: EntityDataStoreService,
    protected doubleClickService: DoubleClickService,
    protected componentService: ComponentService,
    protected modulesStateService: ModulesStateService,
    protected navigationService: ModuleNavigationService
  ) {
    super();
  }

  public click() {
    const component = this.getComponent();

    if (component instanceof AbstractGenericGridComponent) {
      this.handleImportingMultiselectedEntitiesToMaster(component);
    }
  }

  private handleImportingMultiselectedEntitiesToMaster(component: AbstractGenericGridComponent) {
    const entities = component.selectedEntities;
    this.masterEntity = component.masterEntity;
    const masterEntityField = component.masterEntityEditingField;

    this.masterEntity[masterEntityField] = entities;
    this.masterEntity.isChanged = true;
    this.entityDataStore.onEntityChanged({
      entity: this.masterEntity
    });

    const moduleState = this.modulesStateService.getByComponent(component);

    this.navigationService
      .openPrevious(moduleState)
      .subscribe();
  }
}
