/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "primeng/components/dom/domhandler";
import * as i2 from "primeng/components/button/button";
import * as i3 from "../../../../../p-button/p-button.directive";
import * as i4 from "primeng/components/dynamicdialog/dialogservice";
import * as i5 from "./workflow-action-parameter-column.component";
import * as i6 from "../../../../../services/generic-crud.service";
import * as i7 from "../../../generic-dialog/service/generic-dialog-module.service";
import * as i8 from "../../../../../../core/service/entity-manager/entity-manager.service";
import * as i9 from "../../../../services/entity-data-store.service";
var styles_WorkflowActionParameterColumnComponent = [];
var RenderType_WorkflowActionParameterColumnComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_WorkflowActionParameterColumnComponent, data: {} });
export { RenderType_WorkflowActionParameterColumnComponent as RenderType_WorkflowActionParameterColumnComponent };
export function View_WorkflowActionParameterColumnComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 6, "table", [], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 5, "tr", [], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 4, "td", [], [[8, "hidden", 0]], null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 3, "button", [["label", "Action Parameters"], ["pButton", ""]], [[4, "width", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.openForm($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵprd(4608, null, i1.DomHandler, i1.DomHandler, []), i0.ɵdid(5, 4341760, null, 0, i2.ButtonDirective, [i0.ElementRef], { label: [0, "label"] }, null), i0.ɵdid(6, 4341760, null, 0, i3.PButtonDirective, [i0.ElementRef], { label: [0, "label"] }, null)], function (_ck, _v) { var currVal_2 = "Action Parameters"; _ck(_v, 5, 0, currVal_2); var currVal_3 = "Action Parameters"; _ck(_v, 6, 0, currVal_3); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.isHidden(); _ck(_v, 2, 0, currVal_0); var currVal_1 = "100%"; _ck(_v, 3, 0, currVal_1); }); }
export function View_WorkflowActionParameterColumnComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "app-workflow-action-parameter-column", [], null, null, null, View_WorkflowActionParameterColumnComponent_0, RenderType_WorkflowActionParameterColumnComponent)), i0.ɵprd(512, null, i4.DialogService, i4.DialogService, [i0.ComponentFactoryResolver, i0.ApplicationRef, i0.Injector]), i0.ɵdid(2, 114688, null, 0, i5.WorkflowActionParameterColumnComponent, [i0.ElementRef, i6.GenericCrudService, i7.GenericDialogModuleService, i8.EntityManagerService, i4.DialogService, i9.EntityDataStoreService, i0.ChangeDetectorRef], null, null)], function (_ck, _v) { _ck(_v, 2, 0); }, null); }
var WorkflowActionParameterColumnComponentNgFactory = i0.ɵccf("app-workflow-action-parameter-column", i5.WorkflowActionParameterColumnComponent, View_WorkflowActionParameterColumnComponent_Host_0, { node: "node", entity: "entity", component: "component", column: "column" }, {}, []);
export { WorkflowActionParameterColumnComponentNgFactory as WorkflowActionParameterColumnComponentNgFactory };
