import { AbstractInlineEditor } from './abstract-inline-editor';
import { DateHelper } from '../../../../helpers/date.helper';
import {Observable, of} from 'rxjs';

export class TimeInlineEditor extends AbstractInlineEditor {

    public onEdit(entity: any, event: any) {

        // typing in calendar event
        if (event && event.target && event.target.value) {
            let value = event.target.value;

            let time = value.split(':');
            var date = new Date(0);

            date.setHours(time[0]);
            date.setMinutes(time[1]);

            if (!isNaN(date.getTime())) {
                this.changeEntityColumnProperty(this.getDateValueWithLeadingZeros(date));
            }

            if (!value) {
                this.changeEntityColumnProperty(null);
            }
        } else {
            this.changeEntityColumnProperty(event);
        }

    }

    public onBlur(entity: any, event: any) {

    }

    public onFocus(entity: any, event: any): Observable<any> {

        if (event.srcElement) {
            event.srcElement.select();
        }

        return of(null);
    }

    private getDateValueWithLeadingZeros(date: Date) {
        let hours = DateHelper.getValueWithLeadingZeros(String(date.getHours()), 2),
            minutes = DateHelper.getValueWithLeadingZeros(String(date.getMinutes()), 2);

        return `${hours}:${minutes}`
    }
}
