
    <div class="ui-g">
      <div class="ui-g-2">
        {{ 'COMMON.FROM' | translate }}:
      </div>
      <div class="ui-g-4">
        <app-generic-calendar
          (onInputChangeValid)="onFilterFrom($event)"
          (onSelect)="onFilterFrom($event)"
          [value]="this.field.value ? this.field.value.from : null"
          [yearRange]="'1950:2050'"
          [mask]="'99.99.9999'"
          [dateFormat]="'dd.mm.yy'"
          [showOnFocus]="true"
        ></app-generic-calendar>
      </div>

      <div class="ui-g-2">
        {{ 'COMMON.TO' | translate }}:
      </div>
      <div class="ui-g-4">
        <app-generic-calendar
          (onInputChangeValid)="onFilterTo($event)"
          (onSelect)="onFilterTo($event)"
          [value]="this.field.value ? this.field.value.to : null"
          [yearRange]="'1950:2050'"
          [mask]="'99.99.9999'"
          [dateFormat]="'dd.mm.yy'"
          [showOnFocus]="true"
        ></app-generic-calendar>
      </div>
    </div>
  