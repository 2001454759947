import {ElementInput, ElementLabel} from './element';
import {Icon} from "../../models/Icon";

export class ElementLabelOutputCollectiveAgreementAkuText extends ElementLabel {

  constructor(
    public collectiveAgreementField: string = undefined,
    public boldText: boolean = false
  ) {
    super();
    this.type = 'collectiveAgreementAku';
    this.typeElement = 'collective-agreement-aku-text';
    this.collectiveAgreementField = '';
  }
}
