/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./service/toolbar-item-after-delete-service";
import * as i2 from "../../../../../services/modules-state.service";
import * as i3 from "../../../../../../services/location.service";
import * as i4 from "../../../../../../../core/executor/factory/execution-step-factory.service";
import * as i5 from "../../../../../../../core/executor/executor.service";
import * as i6 from "../../../../../../../core/executor/service/executor-actions/executor-actions.service";
import * as i7 from "./toolbar-item-delete.component";
import * as i8 from "../../../../../services/component-highlight-stack.service";
import * as i9 from "../../../../../../../core/message/message-growl.service";
import * as i10 from "../../../../../../../core/message/message.service";
import * as i11 from "../../../../../../services/generic-crud.service";
import * as i12 from "../../../../../../services/api.builder.service";
import * as i13 from "@ngx-translate/core";
import * as i14 from "../../../../../services/entity-data-store.service";
import * as i15 from "../../../../../services/logger/logger.service";
import * as i16 from "../../../../../../../core/service/toast.service";
var styles_ToolbarItemDelete = [];
var RenderType_ToolbarItemDelete = i0.ɵcrt({ encapsulation: 2, styles: styles_ToolbarItemDelete, data: {} });
export { RenderType_ToolbarItemDelete as RenderType_ToolbarItemDelete };
export function View_ToolbarItemDelete_0(_l) { return i0.ɵvid(2, [], null, null); }
export function View_ToolbarItemDelete_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "toolbar-item-delete", [], null, null, null, View_ToolbarItemDelete_0, RenderType_ToolbarItemDelete)), i0.ɵprd(512, null, i1.ToolbarItemAfterDeleteService, i1.ToolbarItemAfterDeleteService, [i2.ModulesStateService, i3.LocationService, i4.ExecutionStepFactoryService]), i0.ɵprd(512, null, i5.ExecutorService, i5.ExecutorService, [i6.ExecutorActionsService]), i0.ɵdid(3, 245760, null, 0, i7.ToolbarItemDelete, [i8.ComponentService, i9.MessageGrowlService, i10.MessageService, i11.GenericCrudService, i12.ApiBuilderService, i13.TranslateService, i14.EntityDataStoreService, i15.LoggerService, i1.ToolbarItemAfterDeleteService, i5.ExecutorService, i16.ToastService, i13.TranslateService], null, null)], function (_ck, _v) { _ck(_v, 3, 0); }, null); }
var ToolbarItemDeleteNgFactory = i0.ɵccf("toolbar-item-delete", i7.ToolbarItemDelete, View_ToolbarItemDelete_Host_0, { params: "params" }, {}, []);
export { ToolbarItemDeleteNgFactory as ToolbarItemDeleteNgFactory };
