export class FieldDisplayConfig {
    /**
     * the name of the datamodel field to display.
     * @type string
     */
    fieldName: string;

    /**
     * In case there is a need to separate fields, or to wrap the field name into something (like parantheses etc.)
     *
     * @type string
     */
    postCharacter: string;

    /**
     * In case there is a need to separate fields, or to wrap the field name into something (like parantheses etc.)
     *
     * @type string
     */
    preChacarter: string;
}
