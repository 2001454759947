<div #container fxLayout="column" fxLayoutAlign="stretch stretch" fxLayoutGap="10px" class="simple-search-container">

  <div fxFlex fxLayout="column" fxLayoutAlign="start start" fxLayoutGap="10px" class="fields-container">
    <ng-template ngFor let-field [ngForOf]="this.simpleSearchFields">
      <app-generic-grid-simple-search-field
        [component]="component"
        [field]="field"
      ></app-generic-grid-simple-search-field>
    </ng-template>
  </div>

  <div fxFlex="30px">
    <button
      (click)="onSearchButtonClick($event)"
      pButton
      label="{{ 'COMMON.SEARCH' | translate }}"
      class="search-button"
      icon="fa fa-search"
    ></button>
  </div>
</div>
