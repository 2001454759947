import { Message } from 'primeng/primeng';
import { Injectable, EventEmitter } from '@angular/core';
import {TranslateService} from '@ngx-translate/core';

@Injectable()
export class MessageGrowlService {

  private _options: GrowlOptions;

  public add: EventEmitter<Message> = new EventEmitter<Message>();
  public optionsChanged: EventEmitter<GrowlOptions> = new EventEmitter<GrowlOptions>();

  public GROWL_SNIPPETS = {
    'SAVED': {},
    'DELETED': {}
  };

  constructor(
    private translateService: TranslateService
  ) {
    this._options = {
      sticky: false,
      life: 5000
    };

    this.GROWL_SNIPPETS.SAVED = { severity: 'success', summary: 'Erfolgreich gespeichert', detail: 'Erfolgreich gespeichert.....' };
    this.GROWL_SNIPPETS.DELETED = { severity: 'success', summary: 'Erfolgreich gelöscht', detail: 'Erfolgreich gelöscht.....' };
  }

  message(message: Message, options?: GrowlOptions) {
    if (options) {
      this._options = options;
      this.optionsChanged.next(this._options);
    }

    this.add.next(message);
  }

  info(message: string, title?: string, options?: GrowlOptions) {
    if (!title) {
      title = 'Info';
    }
    this.message({ severity: 'info', summary: title, detail: message }, options);
  }

  error(message: string, title?: string, options?: GrowlOptions) {
    if (!title) {
      title = 'Fehler';
    }
    this.message({ severity: 'error', summary: title, detail: message }, options);
  }

  success(message: string, title?: string, options?: GrowlOptions) {
    if (!title) {
      title = 'Erfolgreich';
    }
    this.message({ severity: 'success', summary: title, detail: message }, options);
  }

  snippet(snippet: string, options?: GrowlOptions) {
    if (this.GROWL_SNIPPETS[snippet.toUpperCase()]) {
      this.message(this.GROWL_SNIPPETS[snippet.toUpperCase()], options);
    }
  }

  showDataSaved(): void {
    this.success(
      this.translateService.instant('COMMON.DATA_SAVED'),
      this.translateService.instant('COMMON.SUCCESS')
    );
  }

  get options(): GrowlOptions {
    return this._options;
  }

  set options(value: GrowlOptions) {
    this._options = value;
    this.optionsChanged.next(this._options);
  }
}
