<p-dialog
  *ngIf="isVisible"
  [(visible)]="isVisible"
  [maximizable]="false"
  [responsive]="false"
  [resizable]="false"
  [width]="dialogWidth"
  [height]="dialogHeight"
  [closable]="false"
  (onShow)="onShow($event, dialog)"
  [modal]="options && options.isModal ? options.isModal: false"
  appendTo="body"
  header="{{module.label}}"
  showEffect="fade"
  styleClass="module-in-dialog"
  #dialog
>
  <div class="dialog-close-button-container ui-helper-clearfix ng-tns-c8-6">
    <a (click)="onDialogMaximize($event)" class="ng-tns-c8-6 ui-dialog-titlebar-close ui-corner-all">
      <span class="fa fa-fw fa-window-maximize"></span>
    </a>
    <a (click)="onCloseDialog($event)" class="ng-tns-c8-6 ui-dialog-titlebar-close ui-corner-all">
      <span class="dialog-close-button fa fa-fw fa-close"></span>
    </a>
  </div>

  <app-content-renderer
    *ngIf="module"
    appSeleniumDirective
    [isDialog]="true"
    [module]="module"
    [entity]="options.entity"
    [additional]="options.additional"
    [masterEntity]="options.masterEntity"
    [masterElementContext]="options.masterElementContext"
    [masterFilterField]="options.masterFilterField"
    [masterFilterValue]="options.masterFilterValue"
    [masterField]="options.masterField"
    [masterEntityEditingField]="options.masterEntityEditingField"
  ></app-content-renderer>
</p-dialog>
