
import {of as observableOf, Observable} from 'rxjs';

import {map} from 'rxjs/operators';
import {AbstractExecutionStep} from '../../../../../core/executor/abstract-execution-step';
import {ExecutionStepStatus} from '../../../../../core/executor/execution-step-status';
import {AbstractGenericGridComponent} from '../../../../content-renderer/elements/abstract-generic-grid.component';
import {EntityManagerService} from '../../../../../core/service/entity-manager/entity-manager.service';
import {ChangeDetectorRefHelper} from '../../../../helpers/change-detector-ref.helper';

export class PrecalculationDetailsColorEntitiesStep extends AbstractExecutionStep {

  doExecute(): Observable<ExecutionStepStatus> {
    let component = this.getPayload().getValue();

    if (component instanceof Object && component.component) {
      component = component.component;
    }

    if (!(component instanceof AbstractGenericGridComponent)) {
      return this.getFailObservable('You need to pass AbstractGenericGridComponent as Payload value!');
    }

    return this.doColorEntities(component);
  }

  private doColorEntities(component: AbstractGenericGridComponent): Observable<ExecutionStepStatus> {
    const entities = component.getEntities(),
      codes = [];

    for (const entity of entities) {
      if (entity.code && !codes.includes(entity.code)) {
        codes.push(entity.code);
      }
    }

    const entityManager = this.injector.get(EntityManagerService, null);

    let codesString = '';
    if (codes.length > 0) {
      codesString = codes.join(',');

      return this.genericCrudService.getEntities(
        `phoenix/precalculationarticlecolors/offset/0/limit/50/orderby/id/asc?articleCode=in:${codesString}`
      ).pipe(map((paginated: any) => {
        const articleColors: {
          articleCode: string,
          backgroundColor: string,
          disabledColor: string
        }[] = paginated.data;

        for (const articleColor of articleColors) {
          for (const entity of entities) {
            if (articleColor.articleCode === entity.code) {
              entityManager.persist(entity, {property: 'backgroundColor', newValue: articleColor.backgroundColor, force: true });
              entityManager.persist(entity, {property: 'disabledColor', newValue: articleColor.disabledColor, force: true });

              component.getInlineEditService().markForCheck(entity);
            }
          }
        }

        ChangeDetectorRefHelper.detectChanges(component);

        return {status: true, content: null};
      }));
    }

    return observableOf({status: false, content: 'No article codes found!'});
  }
}
