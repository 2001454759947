
import {of as observableOf, Observable} from 'rxjs';

import {map, switchMap} from 'rxjs/operators';
import {ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, Input, ViewChild, ViewContainerRef} from '@angular/core';
import {
  GenericElementValidationExecutionStepsFactory
} from '../../../../services/generic/generic-element-validation-execution-steps-factory';
import {ElementSaveStatus, GenericElementAbstract} from '../../../generic-element-abstract.component';
import {EntityValidator, EntityValidatorStatus} from '../../../../../validators/services/entity-validator';
import {EntityDataChangeMeta, EntityDataStoreService} from '../../../../services/entity-data-store.service';
import {ElementContext, ElementType} from '../../../../services/ElementContext';
import {ComponentService} from '../../../../services/component-highlight-stack.service';
import {ModulesStateService} from '../../../../services/modules-state.service';
import {GenericCrudService} from '../../../../../services/generic-crud.service';
import {ExecutorService} from '../../../../../../core/executor/executor.service';
import {LocalStorageDataService} from '../../../../../services/local-storage-data.service';
import {ToolbarItemCheckService} from '../../../generic-toolbar/services/check/toolbar-item-check.service';
import {JobContainerService} from '../../../../../../core/job-runner/job-container.service';
import {ExecutionStepFactoryService} from '../../../../../../core/executor/factory/execution-step-factory.service';
import {LayoutService} from '../../../../../services/layout-service';
import {PermissionService} from '../../../../../services/permission/permission.service';
import {FieldMetadataGrid} from '../../../../../services/module/module-element-field-metadata-grid';
import {TableColumn} from '../../../../../dynamic-table/shared/table-column';
import {DatePipe} from '@angular/common';
import {DynamicTableComponent} from '../../../../../dynamic-table/dynamic-table.component';
import {MessageGrowlService} from '../../../../../../core/message/message-growl.service';
import {TranslateService} from '@ngx-translate/core';
import {TableHeader} from '../../../../../dynamic-table/shared/table-header';
import {EntityStatus} from '../../../../../services/entity/entity-status';
import {EntityManagerService} from '../../../../../../core/service/entity-manager/entity-manager.service';
import {TOAST_LIFE_INFINITE, ToastComponentsRegistry, ToastService} from '../../../../../../core/service/toast.service';
import {AbstractGenericGridComponent} from '../../../abstract-generic-grid.component';
import {of} from 'rxjs/observable/of';
import {ArticleAutocompleteHandler} from '../autocomplete-handler/article-autocomplete-handler';
import {Entity} from '../../../../../helpers/entity';
import {RecalculateWorkHourEntryExecutorStep} from '../steps/recalculate-work-hour-entry-executor.step';
import {ExecutionStepPayload} from '../../../../../../core/executor/execution-step-payload';
import {SalaryTypeAutocompleteHandler} from '../autocomplete-handler/salary-type-autocomplete-handler';
import {ChangeDetectorRefHelper} from '../../../../../helpers/change-detector-ref.helper';
import {UserSessionService} from '../../../../../../core/service/user-session.service';

class TableColumnWorkHour implements TableColumn {
  key: string;
  header: string;
  invoice: boolean;
  visible: boolean;
  code: boolean;
}

class TableBackendColumn {
  key: string;
  isCodeColumn: boolean;
  invoice: boolean;
  header: string;
}

const IS_DURATION_CHANGED = 'isDurationChanged';
const IS_INVOICE_DURATION_CHANGED = 'isInvoiceDurationChanged';
const WORK_TYPE_NORMAL = 'Normal';

@Component({
  selector: 'app-work-hour-time-entry-weekly-overview',
  templateUrl: './work-hour-time-entry-weekly-overview.component.html',
  styleUrls: ['./work-hour-time-entry-weekly-overview.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    ExecutorService,
    GenericElementValidationExecutionStepsFactory
  ]
})
export class WorkHourTimeEntryWeeklyOverviewComponent extends GenericElementAbstract {

  @Input() public fields: Array<FieldMetadataGrid>;
  @Input() public masterEntity: any = null;
  @Input() public masterField: any = null;
  public updatedEntities: any[] = [];

  public isDialogVisible = false;
  public articleHandler = null;
  public invoiceArticleHandler = null;
  public salaryTypeHandler = null;
  public selectedArticle = null;
  public selectedInvoiceArticle = null;
  public selectedSalaryType = null;

  @ViewChild('table', {static: false}) public table: DynamicTableComponent;

  public _gridContainer: ElementRef = null;
  @ViewChild('gridContainer', {static: false}) set gridContainer(gridContainer: ElementRef) {
    this._gridContainer = gridContainer;

    if (this._gridContainer && this._gridContainer.nativeElement) {
      this.setTableScrollHeightAndWidth();
    }
  }

  public columns: TableColumnWorkHour[] = [];
  public header: TableHeader = null;
  public entities: any[] = [];
  public backendColumns: any[] = [];
  public rows: any[] = [];
  public isDataLoading = false;

  public showColumns = 'duration';

  public toolbarContextName = 'workHourTimeEntryWeeklyOverviewComponent';

  protected fixedColumns: string[] = [
    'workDate',
    'workDateDay',
    'workStart',
    'workEnd',
    'workType',
    'workLength',
    'workLengthPlanned',
    'customer',
    'assignment',
    'customerCostCenter'
    // 'duration'
  ];

  public constructor(
    public elementRef: ElementRef,
    protected componentService: ComponentService,
    protected viewContainerRef: ViewContainerRef,
    protected modulesStateService: ModulesStateService,
    protected genericCrudService: GenericCrudService,
    protected entityDataStoreService: EntityDataStoreService,
    protected executorService: ExecutorService,
    protected genericElementValidationExecutionStepsFactory: GenericElementValidationExecutionStepsFactory,
    protected entityValidator: EntityValidator,
    protected userSession: UserSessionService,
    protected toolbarItemCheckService: ToolbarItemCheckService,
    protected jobContainerService: JobContainerService,
    protected stepFactory: ExecutionStepFactoryService,
    protected layoutService: LayoutService,
    protected permissionService: PermissionService,
    public cdr: ChangeDetectorRef,
    protected messageGrowl: MessageGrowlService,
    protected translate: TranslateService,
    protected entityManager: EntityManagerService,
    protected toast: ToastService
  ) {
    super(componentService, viewContainerRef, entityDataStoreService, modulesStateService, executorService,
      genericElementValidationExecutionStepsFactory, entityValidator, genericCrudService, userSession, permissionService,
      cdr);
  }

  public doValidate(): Observable<EntityValidatorStatus> {
    return observableOf(null);
  }

  public getSelectedEntity(): any {
  }

  public hasChanges(checkEmbedded: boolean): boolean {
    return false;
  }

  public onAfterSave(): Observable<any> {
    return observableOf(null);
  }

  public onChange(changeMeta: EntityDataChangeMeta): Observable<any> {
    return observableOf(null);
  }

  public ngOnInit() {
    super.ngOnInit();

    this.articleHandler = new ArticleAutocompleteHandler(this.genericCrudService, true, false);
    this.invoiceArticleHandler = new ArticleAutocompleteHandler(this.genericCrudService, false, true);
    this.salaryTypeHandler = new SalaryTypeAutocompleteHandler(this.genericCrudService);

    this.onComponentInit();
  }

  public onComponentInit() {
    this.elementContext = this.createContext();
    this.header = this.buildHeader();

    if (this.isValidForLoad()) {
      this.loadWorkHours().subscribe(() => {
        this.setTableScrollHeightAndWidth();
        ChangeDetectorRefHelper.detectChanges(this);
      });
    }

    this.layoutService.layoutSizeChanged$.subscribe(() => {
      setTimeout(() => {
        this.setTableScrollHeightAndWidth();
      }, 50);
    });
  }

  public onDestroyComponent(): void {

  }

  public onRefresh(): Observable<any> {
    return observableOf(null);
  }

  public onSave(): Observable<ElementSaveStatus> {
    return undefined;
  }

  public recheckToolbarItems(): void {
  }

  public onCellBlur(event: {originalEvent: any, column: TableColumnWorkHour, entity: any}): void {
    this.onCellEdit(event);
  }

  public onCellEdit(event: {originalEvent: any, column: TableColumnWorkHour, entity: any}): void {
    const columnDuration = event.column.invoice === false ? 'duration' : 'durationInvoice';

    event.entity[event.column.key][columnDuration] = event.originalEvent.target ?
      event.originalEvent.target.value :
      event.originalEvent.value;
    event.entity[event.column.key][EntityStatus.ENTITY_CHANGED_FLAG] = true;
    event.entity[EntityStatus.ENTITY_CHANGED_FLAG] = true;

    if (columnDuration === 'duration') {
      event.entity[event.column.key][IS_DURATION_CHANGED] = true;
    } else {
      event.entity[event.column.key][IS_INVOICE_DURATION_CHANGED] = true;
    }

    const index = this.updatedEntities.findIndex((aEntry) => {
      return event.entity.id === aEntry.id;
    });

    if (index === -1) {
      this.updatedEntities.push(event.entity);
    } else {
      this.updatedEntities[index] = event.entity;
    }
  }

  public onCellFocus(event): void {
    if (event.originalEvent && event.originalEvent.originalEvent &&
      event.originalEvent.originalEvent.srcElement
    ) {
      event.originalEvent.originalEvent.srcElement.select();
    }
  }

  public onCancelAddType(): void {
    this.isDialogVisible = false;
  }

  public onAddType(): void {
    const workTypeCode = Entity.getValue(this.selectedSalaryType, 'code'),
      workType = Entity.getValue(this.selectedSalaryType, 'name'),
      articleCode = Entity.getValue(this.selectedArticle, 'code'),
      articleName = Entity.getValue(this.selectedArticle, 'name'),
      invoiceArticleCode = Entity.getValue(this.selectedInvoiceArticle, 'code');

    this.backendColumns = [...this.backendColumns, {
      key: workTypeCode,
      header: articleName,
      invoice: false
    }];

    this.backendColumns = [...this.backendColumns, {
      key: workTypeCode,
      header: articleName,
      invoice: true
    }];

    this.columns = this.buildColumns(this.backendColumns);

    for (const entity of this.entities) {
      if (typeof entity === 'object') {
        entity[workTypeCode] = {
          articleCode: articleCode,
          invoiceArticleCode: invoiceArticleCode,
          workType: workType
        };

        if (entity.workTypeCodes instanceof Array) {
          entity.workTypeCodes.push(workTypeCode);
        }
      }

    }

    this.onShowWageOrInvoiceColumns();
    this.updateRowsStyle();
    this.isDialogVisible = false;
    ChangeDetectorRefHelper.detectChanges(this);
  }

  private createContext(): ElementContext {
    return new ElementContext(
      this.moduleElement.id,
      ElementType.WorkHourTimeEntryOverview,
      this,
      this.moduleElement,
      true,
      false,
      false,
      false,
      false,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      true
    );
  }

  private buildColumns(columns: TableBackendColumn[]): TableColumnWorkHour[] {
    const builtColumns = [];
    const me = this;
    const dynamicColumnCount = columns.filter(function (item: { key: string, isCodeColumn: boolean }) {
      return me.fixedColumns.indexOf(item.key) === -1;
    }, me).length || 1;

    const masterContext: ElementContext = this.masterElementContext || null;

    for (const column of columns) {
      switch (column.key) {
        case 'workDate':
          builtColumns.push({
            key: column.key,
            header: 'Datum',
            style: {
              width: '3%'
            },
            renderer: (entity) => {
              if (entity.workDate) {
                let value = new DatePipe('en').transform(entity.workDate, 'dd.MM.yyyy');

                if (entity.entriesCount > 1) {
                  value += ` <b>(${entity.entriesCount} entries)</b>`;
                }

                return value;
              }

              if (entity.isWeeklySummary) {
                return '<b>WS</b>';
              }

              if (entity.isTotal) {
                return '<b>GESAMT</b>';
              }

              return '';
            }
          });
          break;
        case 'workDateDay':
          builtColumns.push({
            key: column.key,
            header: 'Tag',
            style: {
              width: '3%'
            },
            renderer: (entity) => {
              if (entity.workDate) {
                return new DatePipe('en').transform(entity.workDate, 'EEEEEE');
              }

              return '';
            }
          });
          break;
        case 'duration':
          builtColumns.push({
            key: column.key,
            header: 'Dauer',
            invoice: false,
            style: {
              width: '6%'
            },
            renderer: (entity) => {
              let value = entity[column.key] !== 0 ? entity[column.key] : '';

              if (entity.isWeeklySummary) {
                value = entity['durationActual'] ? entity['durationActual'] : value;
                return '<b>' + value.toString().replace(/\./, ',') + '</b>';
              }

              return value ? value.toString().replace(/\./, ',') : value;
            }
          });
          break;
        case 'durationInvoice':
          builtColumns.push({
            key: column.key,
            header: 'Dauer',
            invoice: column.invoice,
            style: {
              width: '6%'
            },
            renderer: (entity) => {
              let value = entity[column.key] !== 0 ? entity[column.key] : '';

              if (entity.isWeeklySummary) {
                value = entity['durationInvoiceActual'] ? entity['durationInvoiceActual'] : value;
                return '<b>' + value.toString().replace(/\./, ',') + '</b>';
              }

              return value ? value.toString().replace(/\./, ',') : value;
            }
          });
          break;
        case 'workStart':
        case 'workEnd2':
          builtColumns.push({
            key: column.key,
            style: {
              width: '3%'
            },
            header: column.key === 'workStart' ? 'Von' : 'Bis'
          });
          break;
        case 'workBreak':
          builtColumns.push({
            key: column.key,
            style: {
              width: '3%'
            },
            header: 'Pause'
          });
          break;
        case 'workType':
          builtColumns.push({
            key: column.key,
            style: {
              width: '3%'
            },
            header: 'Typ'
          });
          break;
        case 'workLength':
          builtColumns.push({
            key: column.key,
            invoice: column.invoice,
            style: {
              width: '3%'
            },
            header: 'Arbeitsdauer',
            renderer: (entity) => {
              const value = entity[column.key] !== 0 ? entity[column.key] : '';

              if (entity.isWeeklySummary) {
                return '<b>' + value.toString().replace(/\./, ',') + '</b>';
              }

              return value ? value.toString().replace(/\./, ',') : value;
            }
          });
          break;
        case 'workLengthPlanned':
          builtColumns.push({
            key: column.key,
            invoice: column.invoice,
            style: {
              width: '3%'
            },
            header: 'Geplannte Arbeitsdauer',
            renderer: (entity) => {
              const value = entity[column.key] !== 0 ? entity[column.key] : '';

              if (entity.isWeeklySummary) {
                return '<b>' + value.toString().replace(/\./, ',') + '</b>';
              }

              return value ? value.toString().replace(/\./, ',') : value;
            }
          });
          break;
        case 'customer':
          builtColumns.push({
            key: column.key,
            invoice: column.invoice,
            style: {
              width: '6%'
            },
            header: 'Kunde',
            renderer: (entity) => {
              return entity[column.key] && entity[column.key]['primaryName']  ? entity[column.key]['primaryName'] : '';
            }
          });
          break;
        case 'assignment':
          builtColumns.push({
            key: column.key,
            invoice: column.invoice,
            style: {
              width: '6%'
            },
            header: 'Überlassung',
            renderer: (entity) => {
              return entity[column.key] && entity[column.key]['assignmentNumber']  ? entity[column.key]['assignmentNumber'] : '';
            }
          });
          break;
        case 'customerCostCenter':
          builtColumns.push({
            key: column.key,
            invoice: column.invoice,
            style: {
              width: '6%'
            },
            header: 'Kostenstelle',
            renderer: (entity) => {
              return entity[column.key] && entity[column.key]['code']  ? entity[column.key]['code'] : '';
            }
          });
          break;
        default:
          // code columns
          builtColumns.push({
            key: column.key,
            header: column.header,
            invoice: column.invoice,
            style: {
              width: (49 / dynamicColumnCount).toString() + '%'
            },
            property: `${column.key}.${column.invoice ? 'durationInvoice' : 'duration'}`,
            edit: {
              type: 'decimal',
              isDisabled: (entity) => {
                let isDisabled = entity.isWeeklySummary || entity.isTotal;

                if (this.showColumns === 'duration' && entity.isWageSettled) {
                  return true;
                }

                if (this.showColumns === 'invoice' && entity.isInvoiced) {
                  return true;
                }

                if (!isDisabled) {
                  isDisabled =  entity.workType !== WORK_TYPE_NORMAL;
                }

                if (!isDisabled) {
                  isDisabled = entity.workMonth !== masterContext.component.gridFilters.workMonth.value;
                }

                return isDisabled;
              }
            },
            getValue: (entity) => {
              let value = entity[column.key] !== '0' && typeof entity[column.key] !== 'undefined' ? entity[column.key] : '';

              if (entity[column.key] && column.invoice === false) {
                value = typeof entity[column.key]['duration'] !== 'undefined' && entity[column.key]['duration'] !== '0' ?
                  entity[column.key]['duration'] :
                  '';
              }

              if (entity[column.key] && column.invoice === true) {
                value = typeof entity[column.key]['durationInvoice'] !== 'undefined' && entity[column.key]['durationInvoice'] !== '0' ?
                  entity[column.key]['durationInvoice'] :
                  '';
              }

              return value || 0;
            },
            renderer: (entity) => {
              let value = entity[column.key] !== '0' && typeof entity[column.key] !== 'undefined' ? entity[column.key] : '';

              if (entity[column.key] && column.invoice === false) {
                value = typeof entity[column.key]['duration'] !== 'undefined' && entity[column.key]['duration'] !== '0' ?
                  entity[column.key]['duration'] :
                  '';
              }

              if (entity[column.key] && column.invoice === true) {
                value = typeof entity[column.key]['durationInvoice'] !== 'undefined' && entity[column.key]['durationInvoice'] !== '0' ?
                  entity[column.key]['durationInvoice'] :
                  '';
              }

              if (entity['isWeeklySummary']) {
                value = typeof entity[column.key]['durationActual'] !== 'undefined' ? entity[column.key]['durationActual'] : value;
                return '<b>' + value.toString().replace(/\./, ',') + '</b>';
              }

              return value ? value.toString().replace(/\./, ',') : value;
            }
          });
          break;
      }
    }

    return builtColumns;
  }

  private buildHeader(): TableHeader {
    return {
      buttons: [
        {
          click: this.onSaveUpdatedEntities.bind(this),
          text: this.translate.instant('COMMON.SAVE'),
          disabled: () => {
            return this.updatedEntities.length === 0;
          }
        },
        {
          click: this.onOpenTypeModal.bind(this),
          text: this.translate.instant('WORK_HOUR.ADD_TYPE')
        },
        {
          click: this.toggleShowWageOrInvoiceColumns.bind(this),
          text: this.showColumns === 'duration' ?
            this.translate.instant('WORK_HOUR.SHOW_INVOICE_COLUMNS') :
            this.translate.instant('WORK_HOUR.SHOW_WAGE_COLUMNS')
        },
        {
          click: this.onDownloadAllDocuwares.bind(this),
          text: this.translate.instant('WORK_HOUR.DOWNLOAD_ALL_DOCUWARES')
        }
      ],
      title: this.showColumns === 'duration' ?
        this.translate.instant('WORK_HOUR.SHOW_WAGE_COLUMNS') :
        this.translate.instant('WORK_HOUR.SHOW_INVOICE_COLUMNS')
    };
  }

  private onDownloadAllDocuwares(): void {
    const masterComponent = this.masterElementContext.component;
    const data = {
      leasedEmployee: masterComponent.gridFilters.leasedEmployee.value[0].id,
      month: masterComponent.gridFilters.workMonth.value,
      year: masterComponent.gridFilters.workYear.value
    };

    this.genericCrudService.customPost('phoenix/docuwaredocuments/bulkdownload', data).subscribe((urlData) => {

      window.open(urlData.url, '_blank');
    });
  }

  private onOpenTypeModal(): void {
    this.selectedArticle = null;
    this.selectedInvoiceArticle = null;
    this.selectedSalaryType = null;
    this.isDialogVisible = true;

    ChangeDetectorRefHelper.detectChanges(this);
  }

  private onSaveUpdatedEntities(): void {
    this.toast.custom(ToastComponentsRegistry.PROGRESS_BAR, {
      severity: 'info',
      life: TOAST_LIFE_INFINITE,
      closable: false,
      summary: this.translate.instant('COMMON.SAVING_PLEASE_WAIT')
    });
    this.doSave().subscribe(() => {
      this.afterSave();
    });
  }

  private afterSave(): void {
    this.toast.clear(ToastComponentsRegistry.PROGRESS_BAR, 300);
    this.messageGrowl.showDataSaved();

    this.loadWorkHours().subscribe();
    this.loadMonthlyGrid().subscribe();
  }

  private doSave(): Observable<any> {
    const observables = [],
      recalculateEntities = [],
      masterComponent = this.masterElementContext.component;

    const recalculateWages = this.showColumns === 'duration' ? 1 : 0;

    for (const entity of this.updatedEntities) {
      const toUpdateEntity = {
        leasedEmployee: masterComponent.gridFilters.leasedEmployee.value[0].id,
        customer: entity.customer.id,
        assignment: entity.assignment.id,
        workDate: entity.workDate,
        codes: {}
      };

      for (const code of entity.workTypeCodes) {
        if (entity[code][EntityStatus.ENTITY_CHANGED_FLAG]) {
          if (this.showColumns === 'duration' && entity[code][IS_DURATION_CHANGED]) {
            toUpdateEntity.codes[code] = {
              duration: entity[code]['duration'],
              workType: entity[code]['workType'],
              articleCode: entity[code]['articleCode'],
              invoiceArticleCode: entity[code]['articleCode']
            };
          }

          if (this.showColumns === 'invoice' && entity[code][IS_INVOICE_DURATION_CHANGED]) {
            toUpdateEntity.codes[code] = {
              durationInvoice: entity[code]['durationInvoice'],
              workType: entity[code]['workType'],
              articleCode: entity[code]['articleCode'],
              invoiceArticleCode: entity[code]['articleCode']
            };
          }
        }
      }

      observables.push(
        this.genericCrudService
          .customPut('phoenix/workhourweeklyoverviews/regenerate', toUpdateEntity)
      );

      recalculateEntities.push(toUpdateEntity);
    }

    return this.regenerate(observables)
      .pipe(switchMap(() => {
        return this.recalculate(recalculateEntities, recalculateWages)
      }));
  }

  private regenerate(observables): Observable<any> {
    return Observable.create((observer) => {

      Observable.forkJoin(observables)
        .catch((response: any) => {
          return Observable.of(response);
        })
        .subscribe(results => {
          observer.next({
            status: true,
            content: results
          });
          observer.complete();
        });
    });
  }

  private recalculate(entities, doRecalculate): Observable<any> {
    const steps = [];

    for (const entity of entities) {
      steps.push(
        this.stepFactory.create(RecalculateWorkHourEntryExecutorStep, new ExecutionStepPayload({
          entity: entity,
          doRecalculate: doRecalculate
        }))
      )
    }

    return this.executorService.setSteps(steps).execute();
  }

  private toggleShowWageOrInvoiceColumns(): void {
    if (this.showColumns === 'duration') {
      this.showColumns = 'invoice';
    } else {
      this.showColumns = 'duration';
    }

    this.onShowWageOrInvoiceColumns();
  }

  private onShowWageOrInvoiceColumns(): void {

    for (const column of this.columns) {
      if (column.invoice === true) {
        column.visible = this.showColumns === 'invoice';
      } else if (column.invoice === false) {
        column.visible = this.showColumns === 'duration';
      }
    }

    this.columns = [...this.columns];
    this.header = this.buildHeader();
  }

  private isValidForLoad(): boolean {
    const masterContext: ElementContext = this.masterElementContext || null;

    let errorMessage = '';

    if (!masterContext || !masterContext.component) {
      errorMessage = 'Something is wrong with configuration, master element context not found';
    }

    const selectedEntity = masterContext.component.getSelectedEntity(),
      gridFilters = masterContext.component.gridFilters;

    if (!selectedEntity) {
      errorMessage = 'Something is wrong with configuration, WorkHour entity not found!';
    }

    if (!gridFilters.workYear || !gridFilters.workMonth ||
      !gridFilters.leasedEmployee
    ) {
      errorMessage = 'Something is wrong with configuration, filters are missing';
    }

    if (errorMessage) {
      this.messageGrowl.error(
        errorMessage,
        this.translate.instant('COMMON.ERROR')
      );
    }

    return errorMessage === '';
  }

  public loadWorkHours(): Observable<any[]> {
    this.isDataLoading = true;

    const masterComponent = this.masterElementContext.component;

    return this.genericCrudService.getEntities('phoenix/workhourweeklyoverviews', '', {
      'workYear': masterComponent.gridFilters.workYear.value,
      'workMonth': masterComponent.gridFilters.workMonth.value,
      'leasedEmployee': masterComponent.gridFilters.leasedEmployee.value[0].id,
      // 'assignment': masterComponent.gridFilters.assignment.value[0].id,
      'workHour': masterComponent.getSelectedEntity().id
    }).pipe(map((data: { entities: any[], columns: any[] }) => {
      this.isDataLoading = false;

      this.entities = data.entities;
      this.backendColumns = data.columns;
      this.columns = this.buildColumns(data.columns);

      this.onShowWageOrInvoiceColumns();
      this.updateRowsStyle();

      return this.entities;
    }));
  }

  private loadMonthlyGrid(): Observable<any> {
    const moduleState = this.modulesStateService.getByComponent(this),
      partModuleState = moduleState.getPartById(this.moduleElement.moduleId);

    let components = [];
    if (partModuleState) {
      components = partModuleState.getComponents();
    }

    let componentToReload = null;

    for (const component of components) {
      if (component.getElementContext().id !== this.getElementContext().id) {
        componentToReload = component;
      }
    }

    if (componentToReload && componentToReload instanceof AbstractGenericGridComponent) {
      return componentToReload.loadEntities();
    }

    return of(null);
  }

  private setTableScrollHeightAndWidth(): void {
    const containerHeight = this._gridContainer.nativeElement.clientHeight,
      containerWidth = this._gridContainer.nativeElement.clientWidth;

    if (this.table && containerHeight) {
      this.table.height = containerHeight + 5;
      this.table.width = containerWidth;
      ChangeDetectorRefHelper.detectChanges(this);
    }
  }

  private updateRowsStyle(): void {
    this.rows = [];

    const masterContext: ElementContext = this.masterElementContext || null;

    for (const entity of this.entities) {
      this.rows.push({
        entity: entity,
        name: 'description',
        getClass: () => {
          let style = '';

          style += entity && entity[EntityStatus.ENTITY_CHANGED_FLAG] ? ' dirty-entity-row' : '';
          style += entity && entity.workMonth !== masterContext.component.gridFilters.workMonth.value ? ' previous-month-entity-row' : '';

          return style;
        }
      });
    }
  }
}
