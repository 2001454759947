import {Injectable} from '@angular/core';
import {GenericElementAbstract} from '../../elements/generic-element-abstract.component';
import {ExecutionStep} from '../../../../core/executor/execution-step';
import {AbstractGenericGridComponent} from 'app/shared/content-renderer/elements/abstract-generic-grid.component';
import {ExecutionStepPayload} from '../../../../core/executor/execution-step-payload';
import {FormViewerComponent} from 'app/shared/form-viewer/form-viewer.component';
import {MasterEntityImportSubEntitiesExecutionStep} from '../../../services/execution-step/master-entity-import-sub-entities-execution.step';
import { ExecutionStepFactoryService } from '../../../../core/executor/factory/execution-step-factory.service';
import {BlankValidationExecutionStep} from '../../../services/execution-step/validation/blank-validation-execution-step';
import {ComponentValidationOptimisedExecutionStep} from '../../../services/execution-step/validation/component-validation-optimised-execution.step';

@Injectable()
export class GenericElementValidationExecutionStepsFactory {

  private component: GenericElementAbstract = null;

  public constructor(
    private executionStepFactoryService: ExecutionStepFactoryService
  ) {

  }

  public getExecutionSteps(): ExecutionStep[] {
    const executionSteps: ExecutionStep[] = [];

    if (this.component.getElementContext().isMaster && this.component instanceof AbstractGenericGridComponent) {
      executionSteps.push(
        this.executionStepFactoryService.create(MasterEntityImportSubEntitiesExecutionStep, new ExecutionStepPayload(this.component))
      );
    }

    if (this.component instanceof AbstractGenericGridComponent && this.component.selectedEntity) {
      executionSteps.push(
        this.executionStepFactoryService.create(ComponentValidationOptimisedExecutionStep, new ExecutionStepPayload(this.component))
      );
    }

    if (this.component instanceof FormViewerComponent && this.component.entity) {
      executionSteps.push(
        this.executionStepFactoryService.create(ComponentValidationOptimisedExecutionStep, new ExecutionStepPayload(this.component))
      );
    }

    return executionSteps;
  }

  public createBlankExecutionStep(): ExecutionStep {
    return this.executionStepFactoryService.create(BlankValidationExecutionStep, new ExecutionStepPayload(this.component));
  }

  public setComponent(component: GenericElementAbstract): GenericElementValidationExecutionStepsFactory {
    this.component = component;
    return this;
  }

  public getComponent(): GenericElementAbstract {
    return this.component;
  }
}
