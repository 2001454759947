import {CheckboxInlineEditor} from './checkbox-inline-editor';
import {Entity} from '../../../../helpers/entity';

export class TriCheckboxInlineEditor extends CheckboxInlineEditor {

  public onEdit(entity: any, event: any) {
    const value = event.value;

    if (this.field.isAssociatedField) {
      const associatedEntity = Entity.getValue(entity, this.field.entityName) ||
        Entity.getValueInEmbedded(entity, this.field.entityName);

      if (associatedEntity) {
        this.changeAssociatedEntityColumnProperty(associatedEntity, this.field.entityFieldName, value);
      }
    } else {
      this.changeEntityColumnProperty(value);
    }
  }

}
