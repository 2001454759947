
import {of as observableOf,  Observable } from 'rxjs';
import { AbstractExecutionStep } from '../../../../core/executor/abstract-execution-step';
import { ExecutionStepStatus } from '../../../../core/executor/execution-step-status';
import { AbstractGenericGridComponent } from '../../../content-renderer/elements/abstract-generic-grid.component';

export class RemoveSelectedEntityStep extends AbstractExecutionStep {

  doExecute(): Observable<ExecutionStepStatus> {
    const payload = this.getPayload();

    const component = payload.getValue();

    if (!(component instanceof AbstractGenericGridComponent)) {
      return this.getFailObservable('You need to pass AbstractGenericGridComponent as Payload value!');
    }

    component.removeEntity(component.getSelectedEntity());
    component.setSelectedEntity(null);

    return observableOf({status: true, content: null});
  }
}
