import {JobInterface} from '../../../core/job-runner/job/job.interface';
import {JobContext} from '../../../core/job-runner/context/job.context';
import {Observable} from 'rxjs';
import {JobExecutionStatus} from '../../../core/job-runner/job/job-execution-status';
import {ConditionInterface} from '../../../core/job-runner/condition/condition.interface';
import {FormViewerComponent} from '../../form-viewer/form-viewer.component';
import {AbstractJob} from '../../../core/job-runner/job/abstract.job';
import {AbstractGenericGridComponent} from '../../content-renderer/elements/abstract-generic-grid.component';
import {MasterEntityConfig} from '../../content-renderer/services/ElementContext';

export class LoadSlaveDataJob extends AbstractJob implements JobInterface {
  run(context: JobContext, condition: ConditionInterface): Observable<JobExecutionStatus> {
    return Observable.create((observer) => {
      const component = context.component;
      const entity = condition.getPayload()['entity'];

      if (component && component instanceof AbstractGenericGridComponent && entity) {
        const elementConfig = new MasterEntityConfig();
        elementConfig.value = entity.id;
        elementConfig.name = component.moduleElement.masterFilterField;
        elementConfig.datamodelId = 0; // For now - no way to find out.
        elementConfig.filterType = 'masterEntity';

        component.getElementContext().setSelectedMasterEntity(entity).addMasterEntity(elementConfig);

        component.loadEntities().subscribe();
      }

      observer.next({
        status: true,
        content: null,
        jobId: this.jobId
      });
    });
  }
}
