
import {of as observableOf,  Observable } from 'rxjs';

import {tap} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { RequestCachingService } from '../services/request-caching.service';
import { Constants } from '../../constants';

import {
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
  HttpHandler,
  HttpEvent
} from '@angular/common/http';


@Injectable()
export class RequestCachingInterceptor implements HttpInterceptor {

  private blacklist: string[] = [
    'validate',
    'lock',
    'memos/count'
  ];

  private forceCacheClear: string[] = [
    'reset'
  ];

  public constructor(
    protected requestCachingService: RequestCachingService
  ) {

  }

  /**
   * @description cache every request with method of 'GET' and returned cached based on URL
   * @param request 
   * @param next 
   */
  public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    if (!this.isRequestMethodGet(request)) {
      // clearing entire bundle cache for now,
      // example we save something like `phoenix/1' we clear every request with `phoenix` api route
      this.clearBundleCache(request);

      if (this.isSuperadminBundle(request)) {
        this.requestCachingService.removeAllRequestResponses();
      }
    }

    if (this.isForceClearRequest(request)) {
      this.clearBundleCache(request);
    }

    let cached = this.requestCachingService.findOne(request.urlWithParams);

    if (cached !== null && this.isCacheable(request)) {
      return observableOf(cached.getResponse());
    }

    return next.handle(request).pipe(tap(response => {
      if (response instanceof HttpResponse && this.isCacheable(request)) {
        this.requestCachingService.add(request, response);
      }
    }));
  }

  private clearBundleCache(request: HttpRequest<any>): void {
    let bundleRequests = this.requestCachingService.findBundleRequests(request.urlWithParams);

    if (bundleRequests.length > 0) {
      this.requestCachingService.removeRequestResponses(bundleRequests);
    }
  }

  private isCacheable(request: HttpRequest<any>): boolean {
    return this.isRequestMethodGet(request) && !this.isRequestBlacklisted(request);
  }

  private isRequestMethodGet(request: HttpRequest<any>): boolean {
    return request.method === 'GET';
  }

  private isRequestBlacklisted(request: HttpRequest<any>): boolean {
    let isBlacklisted = false;

    for (let blacklistUrl of this.blacklist) {
      if (request.urlWithParams.indexOf(blacklistUrl) !== -1) {
        isBlacklisted = true;
        break;
      }
    }

    return isBlacklisted;
  }

  private isForceClearRequest(request: HttpRequest<any>): boolean {
    let isBlacklisted = false;

    for (const clearUrl of this.forceCacheClear) {
      if (request.urlWithParams.indexOf(clearUrl) !== -1) {
        isBlacklisted = true;
        break;
      }
    }

    return isBlacklisted;
  }

  private isSuperadminBundle(request: HttpRequest<any>): boolean {
    let superadminApiRoute = Constants.SUPERADMIN_API_ROUTE;

    return request.urlWithParams.indexOf(superadminApiRoute) !== -1;
  }
}
