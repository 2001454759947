
import {of as observableOf,  Observable } from 'rxjs';
import { AbstractExecutionStep } from '../../../../core/executor/abstract-execution-step';
import { ExecutionStepStatus } from '../../../../core/executor/execution-step-status';
import { ExecutionStatusError, ExecutionStatusErrorAware } from '../../../../core/executor/execution-status-error';
import { EntityValidatorStatus } from '../../../validators/services/entity-validator';

export class BlankValidationExecutionStep extends AbstractExecutionStep implements ExecutionStatusErrorAware {

    public doExecute(): Observable<ExecutionStepStatus> {
      return observableOf({status: true, content: null});
    }

    public onError(executionStatusError: ExecutionStatusError) {
        // console.log(executionStatusError);
    }
}
