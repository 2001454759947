/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./container/search-management-edit-container.component.ngfactory";
import * as i2 from "./container/search-management-edit-container.component";
import * as i3 from "@angular/common";
import * as i4 from "@angular/flex-layout/flex";
import * as i5 from "@angular/flex-layout/core";
import * as i6 from "primeng/components/dom/domhandler";
import * as i7 from "primeng/components/button/button";
import * as i8 from "@ngx-translate/core";
import * as i9 from "../../../../p-button/p-button.directive";
import * as i10 from "./search-management-edit-container-list.component";
var styles_SearchManagementEditContainerListComponent = [".add-container-wrapper[_ngcontent-%COMP%] {\n      padding: 2px;\n    }\n  \n    .add-container-button[_ngcontent-%COMP%] {\n      height: 89%;\n      top: 2px;\n      width: 100%;\n    }"];
var RenderType_SearchManagementEditContainerListComponent = i0.ɵcrt({ encapsulation: 0, styles: styles_SearchManagementEditContainerListComponent, data: {} });
export { RenderType_SearchManagementEditContainerListComponent as RenderType_SearchManagementEditContainerListComponent };
function View_SearchManagementEditContainerListComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "app-search-management-edit-container", [], null, [[null, "onChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("onChange" === en)) {
        var pd_0 = (_co.onChange.emit($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i1.View_SearchManagementEditContainerComponent_0, i1.RenderType_SearchManagementEditContainerComponent)), i0.ɵdid(2, 114688, null, 0, i2.SearchManagementEditContainerComponent, [], { expertSearch: [0, "expertSearch"], container: [1, "container"] }, { onChange: "onChange" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.expertSearch; var currVal_1 = _v.parent.context.$implicit; _ck(_v, 2, 0, currVal_0, currVal_1); }, null); }
function View_SearchManagementEditContainerListComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵand(16777216, null, null, 1, null, View_SearchManagementEditContainerListComponent_3)), i0.ɵdid(1, 16384, null, 0, i3.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(0, null, null, 0))], function (_ck, _v) { var currVal_0 = !_v.context.$implicit.isFlaggedForBackendDelete; _ck(_v, 1, 0, currVal_0); }, null); }
function View_SearchManagementEditContainerListComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 11, "div", [["fxLayout", "column"], ["fxLayoutAlign", "start stretch"]], null, null, null, null, null)), i0.ɵdid(1, 671744, null, 0, i4.DefaultLayoutDirective, [i0.ElementRef, i5.StyleUtils, [2, i4.LayoutStyleBuilder], i5.MediaMarshaller], { fxLayout: [0, "fxLayout"] }, null), i0.ɵdid(2, 671744, null, 0, i4.DefaultLayoutAlignDirective, [i0.ElementRef, i5.StyleUtils, [2, i4.LayoutAlignStyleBuilder], i5.MediaMarshaller], { fxLayoutAlign: [0, "fxLayoutAlign"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_SearchManagementEditContainerListComponent_2)), i0.ɵdid(4, 278528, null, 0, i3.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i0.ɵeld(5, 0, null, null, 6, "div", [["class", "add-container-wrapper"]], null, null, null, null, null)), (_l()(), i0.ɵeld(6, 0, null, null, 5, "button", [["class", "add-container-button"], ["icon", "fa fa-arrow-down"], ["pButton", ""]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onAddContainer($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵprd(4608, null, i6.DomHandler, i6.DomHandler, []), i0.ɵdid(8, 4341760, null, 0, i7.ButtonDirective, [i0.ElementRef], { label: [0, "label"], icon: [1, "icon"] }, null), i0.ɵpid(131072, i8.TranslatePipe, [i8.TranslateService, i0.ChangeDetectorRef]), i0.ɵdid(10, 4341760, null, 0, i9.PButtonDirective, [i0.ElementRef], { label: [0, "label"], icon: [1, "icon"] }, null), i0.ɵpid(131072, i8.TranslatePipe, [i8.TranslateService, i0.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_0 = "column"; _ck(_v, 1, 0, currVal_0); var currVal_1 = "start stretch"; _ck(_v, 2, 0, currVal_1); var currVal_2 = _co.expertSearch.containers; _ck(_v, 4, 0, currVal_2); var currVal_3 = i0.ɵinlineInterpolate(1, "", i0.ɵunv(_v, 8, 0, i0.ɵnov(_v, 9).transform("COMMON.OR")), ""); var currVal_4 = "fa fa-arrow-down"; _ck(_v, 8, 0, currVal_3, currVal_4); var currVal_5 = i0.ɵinlineInterpolate(1, "", i0.ɵunv(_v, 10, 0, i0.ɵnov(_v, 11).transform("COMMON.OR")), ""); var currVal_6 = "fa fa-arrow-down"; _ck(_v, 10, 0, currVal_5, currVal_6); }, null); }
export function View_SearchManagementEditContainerListComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵand(16777216, null, null, 1, null, View_SearchManagementEditContainerListComponent_1)), i0.ɵdid(1, 16384, null, 0, i3.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.expertSearch; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_SearchManagementEditContainerListComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-search-management-edit-container-list", [], null, null, null, View_SearchManagementEditContainerListComponent_0, RenderType_SearchManagementEditContainerListComponent)), i0.ɵdid(1, 49152, null, 0, i10.SearchManagementEditContainerListComponent, [], null, null)], null, null); }
var SearchManagementEditContainerListComponentNgFactory = i0.ɵccf("app-search-management-edit-container-list", i10.SearchManagementEditContainerListComponent, View_SearchManagementEditContainerListComponent_Host_0, { expertSearch: "expertSearch" }, { onChange: "onChange" }, []);
export { SearchManagementEditContainerListComponentNgFactory as SearchManagementEditContainerListComponentNgFactory };
