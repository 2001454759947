<p-dialog
  header="{{ 'COMMON.IMPORT' | translate }}"
  [(visible)]="isDialogVisible"
  *ngIf="isDialogVisible"
  [width]="400"
  [minHeight]="250"
  [maximizable]="false"
  [responsive]="false"
  [resizable]="false"
  [closable]="false"
  [modal]="true"
  [baseZIndex]="10000"
  showEffect="fade"
  appendTo="body"
  #dialog
>
  <div class="dialog-close-button-container ui-helper-clearfix ng-tns-c8-6">
    <a (click)="onDialogMaximize($event)" class="ng-tns-c8-6 ui-dialog-titlebar-close ui-corner-all">
      <span class="fa fa-fw fa-window-maximize"></span>
    </a>
    <a (click)="onCloseDialog($event)" class="ng-tns-c8-6 ui-dialog-titlebar-close ui-corner-all">
      <span class="dialog-close-button fa fa-fw fa-close"></span>
    </a>
  </div>
  <div class="ui-g">
    <div class="ui-grid-col-10">
      <p-fileUpload
        #fileUpload
        url="./upload.php"
        accept=".docx, .pdf"
        (onSelect)="onSelectCV($event)"
        customUpload="true"
        chooseLabel="{{ 'COMMON.CHOOSE' | translate }}"
        cancelLabel="{{ 'COMMON.CANCEL' | translate }}"
        uploadLabel="{{ 'COMMON.UPLOAD' | translate }}"
        (uploadHandler)="onUpdate($event)"
        appSeleniumDirective
        [customElement]="customElementData"
      >
      </p-fileUpload>
    </div>
  </div>
</p-dialog>
