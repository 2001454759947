import {ErrorHandler, Injectable, Injector} from '@angular/core';
import {GenericCrudService} from '../../shared/services/generic-crud.service';

@Injectable()
export class BasicErrorHandler implements ErrorHandler {
  constructor(protected injector: Injector) {
  }

  handleError(error: any): void {
    this.injector.get(GenericCrudService).customPost('app/logentries', {
      error: error.name,
      message: error.message,
      stacktrace: error.stack,
      checked: false,
      url: window.location.href
    }).subscribe();
  }
}
