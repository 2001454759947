import {Injectable} from '@angular/core';
import {EntityStatus} from '../../services/entity/entity-status';

@Injectable()
export class EntityDraftStoreService {

  /**
   * @type {{}}
   */
  protected entities = {};

  public push(entity: any): boolean {
    if (entity['fqn']) {
      return this.appendEntity(entity);
    }

    return false;
  }

  public clear(): void {
    this.entities = {};
  }

  public clearFQN(fqn: string): void {
    this.checkFqn(fqn);
    this.entities[fqn] = [];
  }


  public getEntities(fqn: string) {
    this.checkFqn(fqn);
    return this.entities[fqn];
  }

  protected appendEntity(entity: any): boolean {
    this.checkFqn(entity['fqn']);

    if (!this.isRegistered(entity)) {
      this.entities[entity['fqn']].push(entity);
    }

    return true;
  }

  protected isRegistered(entity: any): boolean {
    for (const existingEntity of this.entities[entity['fqn']]) {
      if (entity[EntityStatus.ENTITY_DRAFT_FLAG] === existingEntity[EntityStatus.ENTITY_DRAFT_FLAG]) {
        return true;
      }
    }

    return false;
  }

  protected checkFqn(fqn: string) {
    if (!this.entities[fqn]) {
      this.entities[fqn] = [];
    }
  }
}
