
import {map, publishReplay, refCount, catchError} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';


import { AppHttpService } from '../../../app-http.service';
import { ModuleElementToolbar } from './module-element-toolbar';

import { GenericCrudService } from '../generic-crud.service';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class ModuleElementToolbarCrudService extends AppHttpService {

  private toolbarItemsUrl: string = 'superadmin/moduleelements/:moduleElementId/toolbaritems';
  private apiUrl: string = '';

  constructor(
    private genericCrudService: GenericCrudService,
    private httpClient: HttpClient
  ) {
    super();
    this.apiUrl = this.getApiUrl();
  }

  /**
   * @param moduleElement
   * @returns {Observable<R>}
   */
  getToolbarItems(moduleElementId: number): Observable<ModuleElementToolbar[]> {
    if (!moduleElementId) {
      throw new Error('Element object or Element id not given.');
    }

    const url = this._createUrl(moduleElementId);

    return this.genericCrudService.get(url).pipe(
      publishReplay(1),
      refCount(),
      catchError(this.handleError));
  }

  /**
   * @param moduleElementId
   * @returns {Observable<R>}
   */
  saveItems(moduleElementId: number, items: any[]): Observable<any> {
    if (!moduleElementId) {
      throw new Error('Element object or Element id not given.');
    }

    const url = this.apiUrl + '/' + this._createUrl(moduleElementId);

    return this.httpClient.post(
      url,
      JSON.stringify(items),
      { params: this.genericCrudService.getRequestOptions().getHttpParams() }
    ).pipe(
      map(this.extractEmbeddedEntities, this));
  }

  _createUrl(moduleElementId: number) {
    const re = /\:moduleElementId/gi;

    return this.toolbarItemsUrl.replace(re, String(moduleElementId));
  }
}
