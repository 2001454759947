import {Component, ElementRef, OnInit} from '@angular/core';
import {AbstractGridColumnComponent} from '../abstract-grid-column.component';
import {EntityHydrator} from '../../../../../services/entity-hydrator.service';
import {GenericTreeGridSelectionService} from './service/generic-tree-grid-selection.service';
import {GenericGridSelectionService} from './service/generic-grid-selection.service';
import {AbstractSelectionService} from './service/abstract-selection.service';
import {JobContext} from '../../../../../../core/job-runner/context/job.context';
import {RunnableEventRegistry} from '../../../../../../core/job-runner/type/runnable-event.registry';
import {Guid} from 'guid-typescript';
import {JobContainerService} from '../../../../../../core/job-runner/job-container.service';
import {GenericTurboGridComponent} from '../../../generic-turbo-grid/generic-turbo-grid.component';

@Component({
  selector: 'app-selection-column',
  template: `
    <p-checkbox
      pInputCheckbox
      binary="true"
      (onChange)="onChange($event)"
      [ngModel]="isSelected()"
      [hidden]="isSummary()"
    ></p-checkbox>
  `,
  providers: [GenericTreeGridSelectionService, GenericGridSelectionService]
})
export class SelectionColumnComponent extends AbstractGridColumnComponent implements OnInit {

  public selectionAdapter: AbstractSelectionService = null;

  constructor(
    protected entityHydrator: EntityHydrator,
    protected treeSelectionService: GenericTreeGridSelectionService,
    protected gridSelectionService: GenericGridSelectionService,
    protected jobContainerService: JobContainerService,
    protected elementRef: ElementRef
  ) {
    super(elementRef);
  }

  ngOnInit() {
    this.selectionAdapter = this.component instanceof GenericTurboGridComponent ? this.gridSelectionService : this.treeSelectionService;
    this.selectionAdapter.component = this.component;

    this.selectionAdapter.onSelectionInit();
  }

  onChange(event) {
    if (event) {
      this.selectEntity(this.entity, this.node);
    } else {
      this.unSelectEntity(this.entity, this.node);
    }

    this.component.onSelectionChanged.emit(this.component.selectedEntities);

    const context = new JobContext();
    context.component = this.component;
    context.event = RunnableEventRegistry.SelectedEntitiesChanged;
    context.identifier = Guid.create().toString();

    this.jobContainerService.runRelevantJobs(context);

    this.component.recheckToolbarItems();
  }

  private selectEntity(entity, node?: TreeNode) {
    this.selectionAdapter.select(entity, node);
  }

  private unSelectEntity(entity, node?: TreeNode) {
    this.selectionAdapter.unSelect(entity, node);
  }

  public isSelected(): boolean {
    return this.selectionAdapter.isSelected(this.entity);
  }

  public isSummary(): boolean {
    return this.selectionAdapter.isSummary(this.entity);
  }
}
