<input *ngIf="textMask"
   type="text"
   pInputText
   [disabled]="disabled"
   [textMask]="textMask"
   (keyup)="keyUp.next($event)"
   (keydown)="keyDown.next($event)"
   (blur)="blur.next($event)"
   (focus)="focus.next($event)"
   [(ngModel)]="displayValue"
   #inputElement
/>
