import {OnInit, OnDestroy, AfterViewInit, OnChanges, Input, ChangeDetectorRef, SimpleChanges, SimpleChange} from '@angular/core';
import {FormService} from '../form.service';
import {FormAction, FormElementAction, Form} from '../models/form';
import {FormGroup} from '@angular/forms';
import {Element} from '../models';
import {SelectItemTmpIsDeleted} from './element-input-dropdown.component';
import {SelectItemExtended} from './element-input-autocomplete.component';
import {FormViewerComponent} from '../form-viewer.component';
import {ModuleElement} from '../../services/module/module-element';
import {ElementsStackService} from '../../content-renderer/services/elements-stack.service';
import {ElementContext, ElementType} from '../../content-renderer/services/ElementContext';

export interface ComponentSelectedOptionAware {
  selectedOption: any;
  addOption(option: SelectItemTmpIsDeleted|SelectItemExtended);
}

export abstract class AbstractElementComponent implements OnInit, OnDestroy, AfterViewInit, OnChanges {

  @Input() form: Form;
  @Input() formGroup: FormGroup;
  @Input() entity: any;
  @Input() element: Element;
  @Input() moduleElement: ModuleElement = null;

  public abstract onFormAction(action: FormAction);
  public abstract onFormElementAction(action: FormElementAction);

  protected abstract onComponentInit();
  protected abstract onComponentChanges();
  protected abstract onComponentDestroy();
  protected abstract onComponentAfterViewInit();

  protected constructor(
    protected formService: FormService,
    public cdr: ChangeDetectorRef
  ) {
    this.formService = formService;
  }

  public ngOnInit() {

    if (this.formService.componentExists(this)) {
      this.formService.removeComponent(this);
    }

    this.formService.addComponent(this);

    this.onComponentInit();
  }

  public ngOnChanges() {
    this.onComponentChanges();
  }

  public ngOnDestroy() {
    this.formService.removeComponent(this);

    this.onComponentDestroy();
  }

  public ngAfterViewInit() {
    this.onComponentAfterViewInit();
  }

  public getFormComponent(): FormViewerComponent|null {
    const elementsStackService = this.formService.getInjector().get(ElementsStackService, null);

    if (elementsStackService && this.moduleElement.id) {
      const formContext: ElementContext = elementsStackService.findByIdAndType(this.moduleElement.id, ElementType.Form);

      return formContext.component;
    }

    return null;
  }

  public getChangeDetectorRef() {
    return this.cdr;
  }

  public markElementForCheck(element: Element): void {
    if (element) {
      const formElementToHideFieldName = FormViewerComponent.getFormControlName(
        element.objectHashId,
        element.datamodelField
        );

      let elementComponent = this.formService.getComponent(formElementToHideFieldName);

      if (!elementComponent) {
        elementComponent = this.formService.getComponentByElement(element);
      }

      if (elementComponent) {
        elementComponent.getChangeDetectorRef().markForCheck();
      }
    }
  }

  public setupValue() {

  }
}
