import { NgModule, Directive, ElementRef, AfterViewInit, OnDestroy, HostBinding, HostListener, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DomHandler, ButtonDirective } from 'primeng/primeng';

@Directive({
  selector: '[pButton]',
  providers: [DomHandler]
})
export class PButtonDirective extends ButtonDirective implements AfterViewInit {

  public _iconUrl: string;

  public _iconUrlMaxPixel: number;

  constructor(public el: ElementRef) {
    super(el);
  }

  ngAfterViewInit() {
    // ngAfterViewInit from primeNg set this to true, but we have to do some more so we set it again to false at the moment
    this.initialized = false;

    const labelElement = DomHandler.findSingle(this.el.nativeElement, '.ui-button-text');

    // Hack to remove
    if (labelElement && (labelElement.textContent === 'ui-button' || labelElement.textContent === 'ui-btn')) {
      labelElement.textContent = '';
    }

    if (this.iconUrl) {
      this.createIcon();
    }

    DomHandler.addMultipleClasses(this.el.nativeElement, this.getStyleClass());

    this.initialized = true;
  }

  @Input() get iconUrl(): string {
    return this._iconUrl;
  }

  private createIcon(): void {
    const labelElement = DomHandler.findSingle(this.el.nativeElement, '.ui-button-text');

    const imgElement = document.createElement('img');
      const iconPosClass = (this.iconPos === 'right') ? 'ui-button-icon-right' : 'ui-button-icon-left';
      imgElement.className = iconPosClass + ' ui-c ico-url';
      imgElement.src = this.iconUrl;

      if (this.iconUrlMaxPixel) {
        imgElement.style.maxWidth = `${this.iconUrlMaxPixel}px`;
        imgElement.style.maxHeight = `${this.iconUrlMaxPixel}px`;
      }

      if (this.iconPos === 'left' && labelElement && labelElement.textContent !== '') {
        labelElement.className += ' ui-button-text-left-icon';
      }

      this.el.nativeElement.appendChild(imgElement);
  }

  set iconUrl(val: string) {
    this._iconUrl = val;

    if (this.initialized) {

      if (null === DomHandler.findSingle(this.el.nativeElement, '.ico-url')) {
        this.createIcon();
      }

      const imgElement = DomHandler.findSingle(this.el.nativeElement, '.ico-url');
      const iconPosClass = (this.iconPos === 'right') ? 'ui-button-icon-right' : 'ui-button-icon-left';
      imgElement.className = iconPosClass + ' ui-c ico-url';
      imgElement.src = this.iconUrl;

      if (this.iconUrlMaxPixel) {
        imgElement.style.maxWidth = `${this.iconUrlMaxPixel}px`;
        imgElement.style.maxHeight = `${this.iconUrlMaxPixel}px`;
      }

      this.el.nativeElement.className = '';
      DomHandler.addMultipleClasses(this.el.nativeElement, this.getStyleClass());
    }
  }

  @Input() get iconUrlMaxPixel(): number {
    return this._iconUrlMaxPixel;
  }

  set iconUrlMaxPixel(val: number) {
    this._iconUrlMaxPixel = val;

    if (this.initialized) {
      const imgElement = DomHandler.findSingle(this.el.nativeElement, '.ico-url');

      if (this.iconUrlMaxPixel) {
        imgElement.style.maxWidth = `${this.iconUrlMaxPixel}px`;
        imgElement.style.maxHeight = `${this.iconUrlMaxPixel}px`;
      }
    }
  }

  set label(val: string) {
    this._label = val;
    if (this.initialized) {
      // Hack to remove
      if (this._label && (this._label === 'ui-button' || this._label === 'ui-btn')) {
        this._label = '';
      }
      DomHandler.findSingle(this.el.nativeElement, '.ui-button-text').textContent = this._label;

      this.el.nativeElement.className = '';
      DomHandler.addMultipleClasses(this.el.nativeElement, this.getStyleClass());
    }
  }

  getStyleClass(): string {
    let styleClass = 'ui-button ui-widget ui-state-default ' + this.cornerStyleClass;
    if (this.icon || this.iconUrl) {
      if (this._label != null) {
        if (this.iconPos === 'left') {
          styleClass = styleClass + ' ui-button-text-icon-left';
        } else {
          styleClass = styleClass + ' ui-button-text-icon-right';
        }
      } else {
        styleClass = styleClass + ' ui-button-icon-only';
      }
    } else {
      styleClass = styleClass + ' ui-button-text-only';
    }

    styleClass = styleClass + ' ui-button-flex';

    return styleClass;
  }
}
@NgModule({
  imports: [CommonModule],
  exports: [PButtonDirective],
  declarations: [PButtonDirective]
})
export class ButtonModule { }
