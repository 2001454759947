/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../generic-calendar/generic-calendar.component.ngfactory";
import * as i2 from "../../../generic-calendar/generic-calendar.component";
import * as i3 from "./generic-grid-simple-search-field-time.component";
var styles_GenericGridSimpleSearchFieldTimeComponent = [];
var RenderType_GenericGridSimpleSearchFieldTimeComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_GenericGridSimpleSearchFieldTimeComponent, data: {} });
export { RenderType_GenericGridSimpleSearchFieldTimeComponent as RenderType_GenericGridSimpleSearchFieldTimeComponent };
export function View_GenericGridSimpleSearchFieldTimeComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-generic-calendar", [], null, [[null, "onInputChangeValid"], [null, "onSelect"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("onInputChangeValid" === en)) {
        var pd_0 = (_co.onFilter($event) !== false);
        ad = (pd_0 && ad);
    } if (("onSelect" === en)) {
        var pd_1 = (_co.onFilter($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i1.View_GenericCalendarComponent_0, i1.RenderType_GenericCalendarComponent)), i0.ɵdid(1, 114688, null, 0, i2.GenericCalendarComponent, [], { showOnFocus: [0, "showOnFocus"], timeOnly: [1, "timeOnly"], value: [2, "value"] }, { onInputChangeValid: "onInputChangeValid", onSelect: "onSelect" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = true; var currVal_1 = true; var currVal_2 = _co.field.value; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2); }, null); }
export function View_GenericGridSimpleSearchFieldTimeComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-generic-grid-simple-search-field-time", [], null, null, null, View_GenericGridSimpleSearchFieldTimeComponent_0, RenderType_GenericGridSimpleSearchFieldTimeComponent)), i0.ɵdid(1, 114688, null, 0, i3.GenericGridSimpleSearchFieldTimeComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var GenericGridSimpleSearchFieldTimeComponentNgFactory = i0.ɵccf("app-generic-grid-simple-search-field-time", i3.GenericGridSimpleSearchFieldTimeComponent, View_GenericGridSimpleSearchFieldTimeComponent_Host_0, { field: "field" }, {}, []);
export { GenericGridSimpleSearchFieldTimeComponentNgFactory as GenericGridSimpleSearchFieldTimeComponentNgFactory };
