import {
  Component, OnInit, Input, Output, EventEmitter
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ExpertSearch } from '../../../../services/expert-search/expert-search';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Datamodel} from '../../../../services/datamodel/datamodel';
import {Observable} from 'rxjs';
import {GenericCrudService} from '../../../../services/generic-crud.service';

@Component({
  selector: 'app-search-management-edit-form',
  templateUrl: './search-management-edit-form.component.html'
})
export class SearchManagementEditFormComponent implements OnInit {

  @Output() onSave: EventEmitter<FormGroup> = new EventEmitter();

  private _expertSearch: ExpertSearch = null;
  @Input() public set expertSearch(expertSearch: ExpertSearch) {
    this._expertSearch = expertSearch;

    this.initForm();
  };
  public get expertSearch() {
    return this._expertSearch;
  }

  public datamodelOptions: SelectItem[] = [];

  public form: FormGroup;

  public constructor(
    protected formBuilder: FormBuilder,
    protected translateService: TranslateService,
    protected genericCrudService: GenericCrudService
  ) {

  }

  public ngOnInit(): void {
    this.initForm();

    this.loadDatamodels()
      .subscribe((datamodels: Datamodel[] = []) => {
        this.datamodelOptions = [];

        for (const datamodel of datamodels) {
          this.datamodelOptions = [...this.datamodelOptions, {
            label: datamodel.name,
            value: datamodel
          }];
        }
      });
  }

  public initForm(): void {
    const name = this.expertSearch ? this.expertSearch.name : '',
      datamodel = this.expertSearch && this.expertSearch.datamodel ? this.expertSearch.datamodel : null;

    this.form = this.formBuilder.group({
      name: this.formBuilder.control(name, [Validators.required]),
      datamodel: this.formBuilder.control(datamodel, [Validators.required]),
    });
  }

  public onDatamodelChange(event: {value: Datamodel}) {
    this.expertSearch.datamodel = event.value;
  }

  public onNameChange(event: {target: any}) {
    this.expertSearch.name = event.target.value;
  }

  public onSubmit(event): void {
    this.onSave.emit(this.form);
  }

  private loadDatamodels(): Observable<Datamodel[]> {
    return this.genericCrudService.getEntities('superadmin/datamodels');
  }
}
