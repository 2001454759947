/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./toolbar-item-csv-export.component";
import * as i2 from "../../../../../../services/generic-crud.service";
var styles_ToolbarItemCsvExportComponent = [];
var RenderType_ToolbarItemCsvExportComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_ToolbarItemCsvExportComponent, data: {} });
export { RenderType_ToolbarItemCsvExportComponent as RenderType_ToolbarItemCsvExportComponent };
export function View_ToolbarItemCsvExportComponent_0(_l) { return i0.ɵvid(2, [], null, null); }
export function View_ToolbarItemCsvExportComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-toolbar-item-csv-export", [], null, null, null, View_ToolbarItemCsvExportComponent_0, RenderType_ToolbarItemCsvExportComponent)), i0.ɵdid(1, 245760, null, 0, i1.ToolbarItemCsvExportComponent, [i2.GenericCrudService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ToolbarItemCsvExportComponentNgFactory = i0.ɵccf("app-toolbar-item-csv-export", i1.ToolbarItemCsvExportComponent, View_ToolbarItemCsvExportComponent_Host_0, { params: "params" }, {}, []);
export { ToolbarItemCsvExportComponentNgFactory as ToolbarItemCsvExportComponentNgFactory };
