import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Input} from '@angular/core';
import {ElementAudit} from '../../../services/element/element-audit';
import {GenericCrudService} from '../../../services/generic-crud.service';
import {Observable} from 'rxjs/Rx';
import {map} from 'rxjs/operators';
import {ChangeDetectorRefHelper} from '../../../helpers/change-detector-ref.helper';
import {Datamodel} from '../../../services/datamodel/datamodel';

interface AuditAssociateDiff {
  id: number;
  label: string;
  class: string;
  table: string;
}

interface AuditDiff {
  source?: AuditAssociateDiff;
  target?: AuditAssociateDiff;
}

interface Audit {
  id: number;
  type: 'insert'|'update'|'associate'|'dissociate'|'remove';
  object_id: number;
  diffs: any|AuditDiff|{ [propertyName: string]: string|AuditAssociateDiff; };
  blame_id: number;
  blame_user: string;
  created_at: string;
  ip: string;
  oldValue: any;
  newValue: any;
  propertyName: string;
}

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-element-audit-tree',
  templateUrl: './element-audit-tree.component.html',
  styleUrls: ['./element-audit-tree.component.scss']
})
export class ElementAuditTreeComponent {

  @Input() public masterDatamodel: Datamodel;
  @Input() public datamodel: Datamodel;
  @Input() public masterAudit: ElementAudit;
  @Input() public audit: ElementAudit;
  @Input() public entity = null;

  public isLoading = true;
  public nodes: TreeNode[] = [];
  public totalCount = 0;

  public constructor(
    public cdr: ChangeDetectorRef,
    private genericCrudService: GenericCrudService
  ) {

  }

  public onLazyLoad(event: {first: number, rows: number}): void {
    let page = 1;

    if (event.first !== 0) {
      page = (event.first / event.rows) + 1;
    }

    this.isLoading = true;

    this.loadAuditNodes(this.audit, page).subscribe((paginated: {nodes: TreeNode[], total: number}) => {
      this.nodes = paginated.nodes;
      this.totalCount = paginated.total;
      this.isLoading = false;
      ChangeDetectorRefHelper.detectChanges(this);
    });
  }

  public getEntityFqn(datamodel): string {
    let name = '';

    if (datamodel) {
      const nameParts = datamodel.name.split('.');

      name = `${nameParts[0]}\\Entity\\${nameParts[1]}`;
    }

    return name;
  }

  public loadAuditNodes(elementAudit: ElementAudit, page = 1): Observable<{nodes: TreeNode[], total: number}> {
    let apiRoute = 'app/audits',
      auditsRoute = `?entityFqn=${this.getEntityFqn(this.datamodel)}&page=${page}&masterEntityFqn=${this.getEntityFqn(this.masterDatamodel)}`;

    if (this.masterAudit && this.masterAudit.context === 'current') {
      apiRoute = `app/audits/master`;
    }

    const isCurrentContext = elementAudit.context === 'current' || (this.masterAudit && this.masterAudit.context === 'current');

    if (isCurrentContext) {
      const entityRoute = this.masterAudit ? 'masterEntityId' : 'entityId';
      auditsRoute += `&${entityRoute}=${this.entity.id}`;
    }

    return this.genericCrudService.get(apiRoute + auditsRoute)
      .pipe(
        map(((data: any) => {
            const nodes: TreeNode[] = [],
              audits = data.results;

            for (const audit of audits) {
              nodes.push({
                data: audit,
                expanded: true,
                children: this.createAuditChildrenNodes(audit)
              })
            }

            return {
              nodes,
              total: data.total
            };
          })
        ));
  }

  private createAuditChildrenNodes(audit: Audit): TreeNode[] {
    const diffs = audit.diffs !== '' ? JSON.parse(audit.diffs) : '';

    const children = [],
      associateTypes = ['associate', 'dissociate'];

    if (typeof diffs === 'object') {
      if (associateTypes.includes(audit.type)) {
        children.push({
          data: {
            type: audit.type,
            blame_user: audit.blame_user,
            oldValue: this.parseAuditDiffValue(diffs.source),
            newValue: this.parseAuditDiffValue(diffs.target)
          }
        })
      }

      if (!associateTypes.includes(audit.type)) {
        for (const propertyName in diffs) {
          if (diffs.hasOwnProperty(propertyName)) {
            children.push({
              data: {
                type: audit.type,
                blame_user: audit.blame_user,
                oldValue: this.parseAuditDiffValue(diffs[propertyName].old),
                newValue: this.parseAuditDiffValue(diffs[propertyName].new),
                propertyName: propertyName
              }
            });
          }
        }
      }
    }

    return children;
  }

  private parseAuditDiffValue(diff: AuditAssociateDiff|string): string {

    if (diff !== null && typeof diff === 'string') {
      return diff;
    }

    if (diff !== null && typeof diff === 'object') {
      return diff.label;
    }

    return '';
  }
}
