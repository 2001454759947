import {ChangeDetectionStrategy, Component, Input, OnInit} from '@angular/core';
import { ToolbarItemAbstract } from '../toolbar-item-abstract.component';
import { ModuleCrudService } from '../../../../../../services/module/module-crud.service';
import { MessageGrowlService } from '../../../../../../../core/message/message-growl.service';
import { ToolbarItemFormEntityService } from '../../services/toolbar-item-form-entity.service';
import { GenericCrudService } from '../../../../../../services/generic-crud.service';
import { GenericElementAbstract } from '../../../../generic-element-abstract.component';
import { ComponentService } from '../../../../../services/component-highlight-stack.service';
import { EntityDirtyStoreService } from '../../../../../services/entity-dirty-store.service';
import { EntityDataStoreService } from '../../../../../services/entity-data-store.service';
import { RequestCachingService } from 'app/shared/services/request-caching.service';
import { AbstractElementComponent } from '../../../../../../form-viewer/element/abstract-element.component';
import { AbstractGenericGridComponent } from 'app/shared/content-renderer/elements/abstract-generic-grid.component';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'toolbar-item-refresh',
  templateUrl: './toolbar-item-refresh.component.html',
  providers: [ModuleCrudService, ToolbarItemFormEntityService]
})
export class ToolbarItemRefresh extends ToolbarItemAbstract {

  constructor(
    private moduleCrudService: ModuleCrudService,
    private messageGrowlService: MessageGrowlService,
    private toolbarItemFormEntityService: ToolbarItemFormEntityService,
    private genericCrudService: GenericCrudService,
    private componentService: ComponentService,
    private entityDirtyStore: EntityDirtyStoreService,
    private entityDataStore: EntityDataStoreService,
    private requestCachingService: RequestCachingService
  ) {
    super();
  }

  public click() {
    const component = this.getComponent();

    if (this.isInCurrentContext(ToolbarItemAbstract.CONTEXT_MODULE)) {
      this.doRefreshModuleComponent(this.getParam('moduleComponent'));
    } else if (component instanceof AbstractGenericGridComponent) {
      this.doRefreshGridComponent(component);
    } else if (this.isInCurrentContext(ToolbarItemAbstract.CONTEXT_FORM_VIEWER)) {
      this.doRefreshForm(this.getParam('formViewerComponent'));
    }
  }

  private doRefreshModuleComponent(moduleComponent) {
    const componentsOnPage = this.componentService.getStashed();

    componentsOnPage.forEach((component) => {
      if (component instanceof AbstractGenericGridComponent) {
        this.doRefreshGridComponent(component);
      }
    });
  }

  private doRefreshGridComponent(gridComponent: AbstractGenericGridComponent) {
    gridComponent.onForceRefresh().subscribe(() => {

    });
  }

  private doRefreshForm(formViewerComponent) {
    this.refreshComponent(formViewerComponent);
  }

  private refreshComponent(component: GenericElementAbstract): void {
    component.onRefresh().subscribe(() => {

    });
  }
}
