<div style="height: 100%" fxLayoutAlign="start stretch" fxLayout="column">
  <div fxFlex="none" *ngIf="element && element.topToolbarItems && element.topToolbarItems.length > 0">
    <p-toolbar styleClass="no-round-corner">
      <app-generic-toolbar [params]="getToolbarExtraParams()" [isDisabled]="isToolbarDisabled" [entity]="entity" [items]="element.topToolbarItems" [additionalItems]="element.additionalToolbarItems"></app-generic-toolbar>
    </p-toolbar>
  </div>

  <form class="container" *ngIf="entity && form" [formGroup]="form">

    <div class="ui-g">
      <div class="ui-g-9">
        <div class="ui-g-12" *ngIf="!entity.id">
          <div class="ui-g-2">
            <label class="inline-label">{{ 'TODO.TYPE' | translate }}:</label>
          </div>
          <div class="ui-g-4">
            <p-dropdown
              appDropdownLoadingIndicator
              [formControlName]="'type'"
              dataKey="id"
              [options]="typeOptions"
              [placeholder]="'---'"
              [autoWidth]="false"
              appendTo="body"
            ></p-dropdown>
          </div>

          <input type="hidden" formControlName="owner" />

          <div class="ui-g-2" *ngIf="form.get('type').value === 'customer'">
            <label class="inline-label">{{ 'TODO.CUSTOMER' | translate }}:</label>
          </div>
          <div class="ui-g-4" *ngIf="form.get('type').value === 'customer'">
            <app-customer-autocomplete
              (onCustomerOptionChanged)="onCustomerChanged($event)"
            ></app-customer-autocomplete>
          </div>

          <div class="ui-g-2" *ngIf="form.get('type').value === 'leasedEmployee'">
            <label class="inline-label">{{ 'TODO.LEASED_EMPLOYEE' | translate }}:</label>
          </div>
          <div class="ui-g-4" *ngIf="form.get('type').value === 'leasedEmployee'">
            <app-leased-employee-autocomplete
              (onLeasedEmployeeOptionChanged)="onLeasedEmployeeChanged($event)"
            ></app-leased-employee-autocomplete>
          </div>
        </div>

        <div class="ui-g-12" *ngIf="entity.id">
          <div class="ui-g-6" *ngIf="entity.fqn === 'PhoenixBundle\\Entity\\CustomerTodo'">
            <input
              pInputText type="text"
              [value]="getCustomerLabel()"
              [readOnly]="true"
              [disabled]="true"
            >
          </div>

          <div class="ui-g-6" *ngIf="entity.fqn === 'PhoenixBundle\\Entity\\LeasedEmployeeTodo'">
            <input
              pInputText type="text"
              [value]="getLeasedEmployeeLabel()"
              [readOnly]="true"
              [disabled]="true"
            >
          </div>

          <div class="ui-g-1"
             *ngIf="
                entity.fqn === 'PhoenixBundle\\Entity\\LeasedEmployeeTodo' ||
                entity.fqn === 'PhoenixBundle\\Entity\\CustomerTodo'
            ">
            <img height="16" width="16"
                 class="ui-icon"
                 [src]="iconBaseUrl + '/assets/hr-puzzle/images/icons/eye.png'"
                 (click)="onIconClick()"
            />
          </div>
        </div>

        <div class="ui-g-12">
          <div class="ui-g-2">
            <label class="inline-label" for="name">{{ 'TODO.SUBJECT' | translate }}:</label>
          </div>
          <div class="ui-g-10">
            <input
              id="name"
              pInputText type="text"
              formControlName="subject"
            >
          </div>
        </div>

        <div class="ui-g-12">
          <div class="ui-g-2">
            <label class="inline-label">{{ 'TODO.START_DATE' | translate }}:</label>
          </div>
          <div class="ui-g-4">
            <input type="hidden" formControlName="startDate" />
            <app-generic-calendar
              [value]="entity.startDate"
              (onInputChangeValid)="onDateChange($event, 'startDate')"
              (onSelect)="onDateChange($event, 'startDate')"
              [mask]="'99.99.9999'"
              [dateFormat]="'dd.mm.yy'"
              [showIcon]="true"
              [yearRange]="'1950:2050'"
            ></app-generic-calendar>
          </div>

          <div class="ui-g-2">
            <label class="inline-label">{{ 'TODO.STATE' | translate }}:</label>
          </div>
          <div class="ui-g-4">
            <p-dropdown
              appDropdownLoadingIndicator
              [formControlName]="'todoState'"
              dataKey="id"
              [options]="stateOptions"
              [placeholder]="'---'"
              [autoWidth]="false"
              appendTo="body"
            ></p-dropdown>
          </div>
        </div>

        <div class="ui-g-12">
          <div class="ui-g-2">
            <label class="inline-label">{{ 'TODO.EXECUTION_DATE' | translate }}:</label>
          </div>
          <div class="ui-g-4">
            <input type="hidden" formControlName="executionDate" />
            <app-generic-calendar
              [value]="entity.executionDate"
              (onInputChangeValid)="onDateChange($event, 'executionDate')"
              (onSelect)="onDateChange($event, 'executionDate')"
              [mask]="'99.99.9999'"
              [dateFormat]="'dd.mm.yy'"
              [showIcon]="true"
              [yearRange]="'1950:2050'"
            ></app-generic-calendar>
          </div>
          <div class="ui-g-2">
            <label class="inline-label">{{ 'TODO.PRIORITY' | translate }}:</label>
          </div>
          <div class="ui-g-4">
            <p-dropdown
              appDropdownLoadingIndicator
              [formControlName]="'todoPriority'"
              dataKey="id"
              [options]="priorityOptions"
              [placeholder]="'---'"
              [autoWidth]="false"
              appendTo="body"
            ></p-dropdown>
          </div>
        </div>

        <div class="ui-g-12">
          <div class="ui-g-1">
            <p-checkbox
              [formControl]="form.controls['hasReminder']"
              binary="true"
            ></p-checkbox>
          </div>

          <div class="ui-g-1">
            <label class="inline-label">{{ 'TODO.REMINDER' | translate }}:</label>
          </div>
          <div class="ui-g-4">
            <input type="hidden" formControlName="reminderDate" />
            <app-generic-calendar
              [value]="entity.reminderDate"
              (onInputChangeValid)="onDateChange($event, 'reminderDate')"
              (onSelect)="onDateChange($event, 'reminderDate')"
              [mask]="'99.99.9999'"
              [dateFormat]="'dd.mm.yy'"
              [showIcon]="true"
              [yearRange]="'1950:2050'"
            ></app-generic-calendar>
          </div>

          <div class="ui-g-2">
            <label class="inline-label">{{ 'TODO.RESPONSIBLE_USER' | translate }}:</label>
          </div>
          <div class="ui-g-4">
            <p-autoComplete
              [dropdown]="true"
              [suggestions]="users"
              dataKey="id"
              field="label"
              formControlName="responsibleUser"
              (completeMethod)="onUserSearch($event)"
              (onDropdownClick)="onUserSearch($event)"
            ></p-autoComplete>
          </div>
        </div>

        <div class="ui-g-12">
          <div
            class="editor-container"
            [froalaEditor]="editorOptions"
            [formControlName]="'note'"
          >
          </div>
        </div>
      </div>
    </div>

  </form>

  <div fxFlex="none" *ngIf="element && element.statusBarItems && element.statusBarItems.length > 0">
    <p-toolbar styleClass="no-round-corner">
      <app-generic-toolbar [params]="getToolbarExtraParams()" [isDisabled]="isToolbarDisabled" [entity]="entity" [items]="element.statusBarItems"></app-generic-toolbar>
    </p-toolbar>
  </div>
</div>
