
import {of as observableOf, Observable} from 'rxjs';
import {AbstractExecutionStep} from '../../executor/abstract-execution-step';
import {ExecutionStepStatus} from '../../executor/execution-step-status';
import {RequestCachingService} from '../../../shared/services/request-caching.service';
import {EntitySharingService} from '../../../shared/content-renderer/services/entity-sharing.service';
import {EntityDirtyStoreService} from '../../../shared/content-renderer/services/entity-dirty-store.service';

export class ClearCacheExecutionStep extends AbstractExecutionStep {

  public doExecute(): Observable<ExecutionStepStatus> {
    this.getEntityDirtyStoreService().clear();
    this.getRequestCachingService().removeAllRequestResponses();
    this.getEntitySharingService().clear();

    return observableOf({status: true, content: null});
  }

  private getEntityDirtyStoreService(): EntityDirtyStoreService {
    return this.injector.get(EntityDirtyStoreService, null);
  }

  private getEntitySharingService(): EntitySharingService {
    return this.injector.get(EntitySharingService, null);
  }

  private getRequestCachingService(): RequestCachingService {
    return this.injector.get(RequestCachingService, null);
  }
}
