import {ChangeDetectionStrategy, Component} from '@angular/core';
import { ToolbarItemAbstract } from '../toolbar-item-abstract.component';
import { MessageGrowlService } from '../../../../../../../core/message/message-growl.service';
import { ComponentService } from '../../../../../services/component-highlight-stack.service';
import { AbstractGenericGridComponent } from '../../../../abstract-generic-grid.component';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'toolbar-item-open-filters',
  template: ''
})
export class ToolbarItemOpenFilters extends ToolbarItemAbstract {

  constructor(
    private messageGrowlService: MessageGrowlService,
    private componentService: ComponentService
  ) {
    super();
  }

  public click() {
    const component = this.getComponent();

    if ((this.isInCurrentContext(ToolbarItemAbstract.CONTEXT_GRID) || this.isInCurrentContext(ToolbarItemAbstract.CONTEXT_TREE))
        && component instanceof AbstractGenericGridComponent
    ) {
        this.doOpenFilters(component);
    }
  }

  private doOpenFilters(component: AbstractGenericGridComponent) {
    component.isFilterContainerVisible = true;

    component.onGridRendered();
  }
}
