import { Injectable } from '@angular/core';
import { ToolbarItemAbstract } from '../components/toolbar-item-abstract.component';
import { ApiBuilderService } from '../../../../../services/api.builder.service';

@Injectable()
export class ToolbarItemFormEntityService {

  constructor(
    private apiBuilder: ApiBuilderService
  ) { }

  public isToolbarItemValid(toolbarItem: ToolbarItemAbstract): boolean {
    const formViewer = toolbarItem.params['formViewerComponent'];

    return formViewer && formViewer.entity && formViewer.entity.id;
  }

  public getEntityApiRoute(entity): string {
    if (entity && entity._links && entity._links.self && entity._links.self.href) {
      return this.apiBuilder.getApiRoute(entity._links.self.href);
    }

    return '';
  }
}
