import {Injectable} from '@angular/core';
import {SimpleSearchField} from './simple-search';

@Injectable()
export class SimpleSearchCacheService {

  public cacheDictionary: { [moduleElementId: number]: SimpleSearchField[]} = {};

  public add(moduleElementId: number, fields: SimpleSearchField[] = []): this {
    this.cacheDictionary[moduleElementId] = fields;
    return this;
  }

  public remove(moduleElementId: number): this {
    delete this.cacheDictionary[moduleElementId];
    return this;
  }

  public get(moduleElementId: number): SimpleSearchField[] {
    return this.cacheDictionary[moduleElementId] || [];
  }

  public isHit(moduleElementId: number): boolean {
    return typeof this.cacheDictionary[moduleElementId] !== 'undefined';
  }
}
