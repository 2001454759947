import {ChangeDetectionStrategy, Component} from '@angular/core';
import { ToolbarItemAbstract } from '../toolbar-item-abstract.component';
import { MessageGrowlService } from '../../../../../../../core/message/message-growl.service';
import { GenericCrudService } from '../../../../../../services/generic-crud.service';
import { GenericTreeGridComponent } from '../../../../generic-tree-grid/generic-tree-grid.component';
import {AbstractGenericGridComponent} from '../../../../abstract-generic-grid.component';
import {GenericTurboGridComponent} from '../../../../generic-turbo-grid/generic-turbo-grid.component';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'toolbar-item-add',
  templateUrl: './toolbar-item-add.component.html'
})
export class ToolbarItemAdd extends ToolbarItemAbstract {

  public propertiesToIgnore = new Array<string>('createdAt', 'modifiedAt', 'deletedAt', 'permissionInformation', '_links',
    '_embedded', 'id', 'fqn', 'allowedRoles');

  public checkboxProperties = new Array<string>('isBadWeather', 'weekendIsAbsenceTime', 'isHeavyLabourType', 'doExportLabourGradingDate');
  public integerProperties = new Array<string>('color', 'orderPosition', 'doEldaRegistration', 'doExportWage');

  public isEntityDialogVisible = false;
  public entity: any;
  public fields: any[] = [];

  constructor(
    protected messageGrowlService: MessageGrowlService,
    protected genericCrudService: GenericCrudService
  ) {
    super();
  }

  public click() {
    if (this.isInCurrentContext(ToolbarItemAbstract.CONTEXT_MODULE)) {
      const component = this.getComponent();

      if (component instanceof AbstractGenericGridComponent) {
        this.handleClickBasedOnGenericGrid(component);
      }
    } else if (this.isInCurrentContext(ToolbarItemAbstract.CONTEXT_GRID)) {
      this.handleClickBasedOnGenericGrid(this.getParam('gridComponent'));
    } else if (this.isInCurrentContext(ToolbarItemAbstract.CONTEXT_TREE)) {
      this.handleClickBasedOnGenericTree(this.getParam('treeComponent'));
    } else if (this.isInCurrentContext(ToolbarItemAbstract.CONTEXT_FORM_VIEWER)) {
      this.doSaveFormViewerComponent(this.getParam('formViewerComponent'));
    }
  }

  protected doSaveFormViewerComponent(formViewerComponent) {
    const component = this.getComponent();

    if (component instanceof AbstractGenericGridComponent) {
      formViewerComponent.saveUrl = component.element.datamodel.apiRoute;
    }

    // todo :: this is probably never used, as this would not work.
    formViewerComponent._entity = null;
    formViewerComponent.onSave();
  }

  protected handleClickBasedOnGenericGrid(gridComponent) {
    if (!gridComponent.element.datamodel || !gridComponent.element.datamodel.apiRoute) {
      console.log('Module element has no datamodel assigned!');
    }

    this.addGenericGridNewRow(gridComponent);
  }

  protected handleClickBasedOnGenericTree(treeComponent) {
    if (!treeComponent.element.datamodel || !treeComponent.element.datamodel.apiRoute) {
      console.log('Module element has no datamodel assigned!');
    }

    this.addGenericGridNewRow(treeComponent);
  }

  protected addGenericGridNewRow(component: GenericTurboGridComponent|GenericTreeGridComponent) {
    if (component instanceof GenericTreeGridComponent) {
      component.selectedNode = null;
      component.selectedEntity = null;
    }

    component.startNewEntityAdd();
  }

  protected showGenericGridEntityForm(gridComponent) {
    if (gridComponent.columns instanceof Array) {
      gridComponent.columns.forEach((column) => {
        if (this.propertiesToIgnore.indexOf(column.property) === -1) {
          column.value = '';

          this.fields.push(column);
        }
      });
    }

    this.isEntityDialogVisible = true;
  }

  public saveEntity() {
    const component = this.getComponent();

    if (component instanceof AbstractGenericGridComponent) {

      this.createResponse(component).subscribe((entity) => {
        if (component instanceof AbstractGenericGridComponent) {
          this.subscriptions.push(
            component.loadEntities().subscribe()
          );
        }

        this.isEntityDialogVisible = false;
      });
    }
  }

  public createRequestBody(fields): Object {
    const requestBody: Object = {};
    for (const field of fields) {
      requestBody[field.property] = field.value;
    }
    return requestBody;
  }

  public createResponse(highlightedComponent?) {
    return this.genericCrudService.createEntity(highlightedComponent.element.datamodel.apiRoute, this.createRequestBody(this.fields));
  }

  public isStringField(field) {
    return !this.isCheckboxField(field) && !this.isIntegerField(field);
  }

  public isCheckboxField(field) {
    return field.isCheckbox || this.checkboxProperties.indexOf(field.property) !== -1;
  }

  public isIntegerField(field) {
    return this.integerProperties.indexOf(field.property) !== -1;
  }
}
