import {Injectable} from '@angular/core';
import {ModuleElementFieldDataConfig, ModuleElementFieldFilter} from '../../../../../../services/module/module-element-field';
import {AbstractGenericGridComponent} from '../../../../../elements/abstract-generic-grid.component';
import {GenericElementAbstract} from '../../../../../elements/generic-element-abstract.component';
import {FieldMetadataGrid} from '../../../../../../services/module/module-element-field-metadata-grid';
import {Constants} from '../../../../../../../constants';

@Injectable()
export class InquiryPositionConditionFilterService {

  /**
   *
   * @param {ModuleElementFieldDataConfig} fieldDataConfig
   * @param {AbstractGenericGridComponent} grid
   * @returns {ModuleElementFieldFilter | null}
   */
  public static createFilter(
    fieldDataConfig: ModuleElementFieldDataConfig,
    grid: AbstractGenericGridComponent
  ): ModuleElementFieldFilter|null {
    const filter = new ModuleElementFieldFilter();

    filter.column = null;
    filter.value = InquiryPositionConditionFilterService.figureOutValue(fieldDataConfig.values, grid);

    return filter.value ? filter : null;
  }

  /**
   *
   * @param {string} field
   * @param {AbstractGenericGridComponent} grid
   * @returns {any}
   */
  protected static figureOutValue(field: string, grid: AbstractGenericGridComponent): any {
    const filters = {};

    if (grid.getElementContext().getMasterElementContext()
      && grid.getElementContext().getMasterElementContext().component instanceof GenericElementAbstract) {

      /**
       *
       * @type {GenericElementAbstract} masterComponent
       */
      const masterComponent = grid.getElementContext().getMasterElementContext().component;
      const masterEntity = masterComponent.getSelectedEntity();

      const selectedEntity = grid.getSelectedEntity();

      if (masterEntity && selectedEntity) {
        const collectiveAgreement =  FieldMetadataGrid.getPropertyValue(masterEntity, 'collectiveAgreement');
        const preCalculationType = FieldMetadataGrid.getPropertyValue(masterEntity, 'preCalculationType');
        const workingMode = FieldMetadataGrid.getPropertyValue(selectedEntity, 'workingMode');
        const salaryClassification = FieldMetadataGrid.getPropertyValue(selectedEntity, 'assignmentCollectiveAgreementClassification');

        if (collectiveAgreement) {
          filters['collectiveAgreement'] = collectiveAgreement.id;
        }

        if (preCalculationType) {
          filters['preCalculationType'] = preCalculationType.id;
        }

        if (workingMode) {
          filters['workingMode'] = workingMode.id;
        }

        if (salaryClassification) {
          filters['salaryClassification'] = salaryClassification.id;
        }
      }
    }

    return filters;
  }
}
