/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./toolbar-item-elba-csv-export.component";
import * as i2 from "../../../../../../services/generic-crud.service";
import * as i3 from "../../../../../../services/request-caching.service";
import * as i4 from "../../../../../../../core/service/toast.service";
import * as i5 from "@ngx-translate/core";
var styles_ToolbarItemElbaCsvExportComponent = [];
var RenderType_ToolbarItemElbaCsvExportComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_ToolbarItemElbaCsvExportComponent, data: {} });
export { RenderType_ToolbarItemElbaCsvExportComponent as RenderType_ToolbarItemElbaCsvExportComponent };
export function View_ToolbarItemElbaCsvExportComponent_0(_l) { return i0.ɵvid(2, [], null, null); }
export function View_ToolbarItemElbaCsvExportComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-toolbar-item-elba-csv-export", [], null, null, null, View_ToolbarItemElbaCsvExportComponent_0, RenderType_ToolbarItemElbaCsvExportComponent)), i0.ɵdid(1, 245760, null, 0, i1.ToolbarItemElbaCsvExportComponent, [i2.GenericCrudService, i3.RequestCachingService, i4.ToastService, i5.TranslateService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ToolbarItemElbaCsvExportComponentNgFactory = i0.ɵccf("app-toolbar-item-elba-csv-export", i1.ToolbarItemElbaCsvExportComponent, View_ToolbarItemElbaCsvExportComponent_Host_0, { params: "params" }, {}, []);
export { ToolbarItemElbaCsvExportComponentNgFactory as ToolbarItemElbaCsvExportComponentNgFactory };
