import {of as observableOf, Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {AbstractExecutionStep} from '../../../../core/executor/abstract-execution-step';
import {ExecutionStepStatus} from '../../../../core/executor/execution-step-status';
import {AbstractGenericGridComponent} from '../../../content-renderer/elements/abstract-generic-grid.component';
import {MessageGrowlService} from '../../../../core/message/message-growl.service';
import {TranslateService} from '@ngx-translate/core';
import {TOAST_LIFE_INFINITE, ToastComponentsRegistry, ToastService} from '../../../../core/service/toast.service';

export class WorkHourDeleteEntriesExecutionStep extends AbstractExecutionStep {

  public doExecute(): Observable<ExecutionStepStatus> {
    const payload = this.getPayload(),
      component = payload.getValue().component ? payload.getValue().component : payload.getValue();

    const selectedEntity = component.getSelectedEntity(),
      index = component.findEntityIndex(selectedEntity);

    const entities = [];
    for (let i = 0; i <= index; i++) {
      entities.push(component.entities[i]);
    }

    if (entities.length === 0) {
      return this.getFailObservable('No entities to calculate');
    }

    return this.doDelete(component, entities);
  }

  private doDelete(component: AbstractGenericGridComponent, entities: any[] = []): Observable<ExecutionStepStatus> {
    const data = {
      workHourIds: []
    };

    for (const selectedEntity of entities) {
      if (!selectedEntity.isSummary) {
        data.workHourIds.push(selectedEntity.id);
      }
    }

    return this.genericCrudService.customPost(
      'phoenix/workhourentries/calculated/delete', data)
      .pipe(
        map(() => {
          this.injector.get(MessageGrowlService).info(
            this.injector.get(TranslateService).instant('COMMON.ENTRIES_DELETED')
          );
          component.loadEntities().subscribe();
          return { status: true, content: ''};
        })
      );
  }
}
