/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./generic-checkbox.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../../node_modules/primeng/components/checkbox/checkbox.ngfactory";
import * as i3 from "primeng/components/checkbox/checkbox";
import * as i4 from "@angular/forms";
import * as i5 from "primeng/components/tooltip/tooltip";
import * as i6 from "@angular/common";
import * as i7 from "./generic-checkbox.component";
var styles_GenericCheckboxComponent = [i0.styles];
var RenderType_GenericCheckboxComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_GenericCheckboxComponent, data: {} });
export { RenderType_GenericCheckboxComponent as RenderType_GenericCheckboxComponent };
function View_GenericCheckboxComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "img", [], [[8, "className", 0], [8, "src", 4], [8, "alt", 0]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵinlineInterpolate(1, "", (!_co.isChecked ? "greyed-out" : ""), " image flex-item"); var currVal_1 = _co.imageSource; var currVal_2 = _co.imageSource; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2); }); }
function View_GenericCheckboxComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "p-checkbox", [["binary", "true"], ["class", "flex-item"]], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "ngModelChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("ngModelChange" === en)) {
        var pd_0 = ((_co.isChecked = $event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_Checkbox_0, i2.RenderType_Checkbox)), i1.ɵdid(1, 49152, null, 0, i3.Checkbox, [i1.ChangeDetectorRef], { binary: [0, "binary"], label: [1, "label"] }, null), i1.ɵprd(1024, null, i4.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i3.Checkbox]), i1.ɵdid(3, 671744, null, 0, i4.NgModel, [[8, null], [8, null], [8, null], [6, i4.NG_VALUE_ACCESSOR]], { model: [0, "model"] }, { update: "ngModelChange" }), i1.ɵprd(2048, null, i4.NgControl, null, [i4.NgModel]), i1.ɵdid(5, 16384, null, 0, i4.NgControlStatus, [[4, i4.NgControl]], null, null)], function (_ck, _v) { var _co = _v.component; var currVal_7 = "true"; var currVal_8 = _co.title; _ck(_v, 1, 0, currVal_7, currVal_8); var currVal_9 = _co.isChecked; _ck(_v, 3, 0, currVal_9); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 5).ngClassUntouched; var currVal_1 = i1.ɵnov(_v, 5).ngClassTouched; var currVal_2 = i1.ɵnov(_v, 5).ngClassPristine; var currVal_3 = i1.ɵnov(_v, 5).ngClassDirty; var currVal_4 = i1.ɵnov(_v, 5).ngClassValid; var currVal_5 = i1.ɵnov(_v, 5).ngClassInvalid; var currVal_6 = i1.ɵnov(_v, 5).ngClassPending; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); }); }
export function View_GenericCheckboxComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [["class", "checkbox-button"], ["tooltipPosition", "top"]], null, null, null, null, null)), i1.ɵdid(1, 4341760, null, 0, i5.Tooltip, [i1.ElementRef, i1.NgZone], { tooltipPosition: [0, "tooltipPosition"], text: [1, "text"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_GenericCheckboxComponent_1)), i1.ɵdid(3, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_GenericCheckboxComponent_2)), i1.ɵdid(5, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "top"; var currVal_1 = _co.tooltipText; _ck(_v, 1, 0, currVal_0, currVal_1); var currVal_2 = _co.imageSource; _ck(_v, 3, 0, currVal_2); var currVal_3 = !_co.imageSource; _ck(_v, 5, 0, currVal_3); }, null); }
export function View_GenericCheckboxComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-generic-checkbox", [], null, null, null, View_GenericCheckboxComponent_0, RenderType_GenericCheckboxComponent)), i1.ɵdid(1, 114688, null, 0, i7.GenericCheckboxComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var GenericCheckboxComponentNgFactory = i1.ɵccf("app-generic-checkbox", i7.GenericCheckboxComponent, View_GenericCheckboxComponent_Host_0, { title: "title", imageSource: "imageSource", tooltipText: "tooltipText", isChecked: "isChecked" }, {}, []);
export { GenericCheckboxComponentNgFactory as GenericCheckboxComponentNgFactory };
