
import {of as observableOf, Observable} from 'rxjs';
import {AbstractExecutionStep} from '../../executor/abstract-execution-step';
import {ExecutionStepStatus} from '../../executor/execution-step-status';
import {Organisation} from '../../../shared/services/organisation/organisation';
import {UserSessionService} from '../../service/user-session.service';

export class SetLocalStorageOrganisationExecutionStep extends AbstractExecutionStep {

  public doExecute(): Observable<ExecutionStepStatus> {
    const organisation: Organisation = this.getPayload().getValue().organisation;

    if (!organisation) {
      console.error('Organisation not found!');
    }

    this.getUserSession().set(Organisation.LOCAL_STORAGE_NAME, {
      value: organisation
    });

    return observableOf({status: true, content: null});
  }

  private getUserSession(): UserSessionService {
    return this.injector.get(UserSessionService, null);
  }
}
