/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "@angular/flex-layout/extended";
import * as i3 from "@angular/flex-layout/core";
import * as i4 from "angular-pipes";
import * as i5 from "./element-input-geoapify-autocomplete.component";
import * as i6 from "../form.service";
import * as i7 from "../form-viewer.service";
import * as i8 from "../../services/generic-crud.service";
import * as i9 from "../../../form-editor/shared/services/element.service";
import * as i10 from "@ngx-translate/core";
import * as i11 from "../../content-renderer/services/elements-stack.service";
import * as i12 from "../../content-renderer/services/entity-draft-store.service";
import * as i13 from "../../services/datamodel/datamodel.crud.service";
import * as i14 from "../../../core/authentication/authentication.service";
var styles_ElementInputGeoapifyAutocompleteComponent = ["[_nghost-%COMP%] {\n        height: 100%;\n      }\n\n      .autocomplete-container[_ngcontent-%COMP%] {\n        position: relative;\n      }"];
var RenderType_ElementInputGeoapifyAutocompleteComponent = i0.ɵcrt({ encapsulation: 0, styles: styles_ElementInputGeoapifyAutocompleteComponent, data: {} });
export { RenderType_ElementInputGeoapifyAutocompleteComponent as RenderType_ElementInputGeoapifyAutocompleteComponent };
function View_ElementInputGeoapifyAutocompleteComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 6, "div", [["class", "icon-container ui-g ui-g-1"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 5, "img", [["height", "16"], ["width", "16"]], [[8, "src", 4], [8, "alt", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onClickIcon() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵprd(512, null, i1.ɵNgClassImpl, i1.ɵNgClassR2Impl, [i0.IterableDiffers, i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2]), i0.ɵdid(3, 278528, null, 0, i1.NgClass, [i1.ɵNgClassImpl], { ngClass: [0, "ngClass"] }, null), i0.ɵpod(4, { "cur-pointer": 0, "cur-disabled": 1, "disabled": 2 }), i0.ɵdid(5, 933888, null, 0, i2.DefaultClassDirective, [i0.ElementRef, i3.StyleUtils, i3.MediaMarshaller, i1.ɵNgClassImpl, [6, i1.NgClass]], { ngClass: [0, "ngClass"] }, null), i0.ɵpod(6, { "cur-pointer": 0, "cur-disabled": 1, "disabled": 2 })], function (_ck, _v) { var _co = _v.component; var currVal_2 = _ck(_v, 4, 0, _co.isValid(), !_co.isValid(), !_co.isValid()); _ck(_v, 3, 0, currVal_2); var currVal_3 = _ck(_v, 6, 0, _co.isValid(), !_co.isValid(), !_co.isValid()); _ck(_v, 5, 0, currVal_3); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_co.iconBaseUrl + "/") + ((_co.element == null) ? null : ((_co.element.icon == null) ? null : _co.element.icon.relativePath))); var currVal_1 = ((_co.element == null) ? null : ((_co.element.icon == null) ? null : _co.element.icon.name)); _ck(_v, 1, 0, currVal_0, currVal_1); }); }
function View_ElementInputGeoapifyAutocompleteComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "div", [["class", "custom-button-container ui-g ui-g-1"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 0, "i", [["aria-hidden", "true"]], [[8, "className", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onCustomButtonClick($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.isReadOnlyOrDisabled() ? "fa fa-plus fa-disabled" : "fa fa-plus"); _ck(_v, 1, 0, currVal_0); }); }
export function View_ElementInputGeoapifyAutocompleteComponent_0(_l) { return i0.ɵvid(2, [i0.ɵpid(0, i4.IsDefinedPipe, []), i0.ɵqud(402653184, 1, { inputElement: 0 }), (_l()(), i0.ɵeld(2, 0, null, null, 6, "div", [["class", "autocomplete-container"], ["id", "autocomplete-container"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 3, null, View_ElementInputGeoapifyAutocompleteComponent_1)), i0.ɵdid(4, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), i0.ɵppd(5, 1), i0.ɵppd(6, 1), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ElementInputGeoapifyAutocompleteComponent_2)), i0.ɵdid(8, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (i0.ɵunv(_v, 4, 0, _ck(_v, 5, 0, i0.ɵnov(_v, 0), ((_co.element == null) ? null : _co.element.icon))) && i0.ɵunv(_v, 4, 0, _ck(_v, 6, 0, i0.ɵnov(_v, 0), ((_co.element == null) ? null : ((_co.element.icon == null) ? null : _co.element.icon.relativePath))))); _ck(_v, 4, 0, currVal_0); var currVal_1 = _co.element.hasCustomButton; _ck(_v, 8, 0, currVal_1); }, null); }
export function View_ElementInputGeoapifyAutocompleteComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "app-form-element-input-geoapify-autocomplete", [], null, null, null, View_ElementInputGeoapifyAutocompleteComponent_0, RenderType_ElementInputGeoapifyAutocompleteComponent)), i0.ɵdid(1, 6012928, null, 1, i5.ElementInputGeoapifyAutocompleteComponent, [i6.FormService, i7.FormViewerService, i8.GenericCrudService, i9.ElementService, i0.ElementRef, i0.Renderer, i10.TranslateService, i11.ElementsStackService, i12.EntityDraftStoreService, i13.DatamodelCrudService, i14.AuthenticationService, i0.ChangeDetectorRef], null, null), i0.ɵqud(603979776, 1, { insideNgContent: 0 })], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ElementInputGeoapifyAutocompleteComponentNgFactory = i0.ɵccf("app-form-element-input-geoapify-autocomplete", i5.ElementInputGeoapifyAutocompleteComponent, View_ElementInputGeoapifyAutocompleteComponent_Host_0, { form: "form", formGroup: "formGroup", entity: "entity", element: "element", moduleElement: "moduleElement", selectedElement: "selectedElement", showDialogAddAddress: "showDialogAddAddress", actionParams: "actionParams", editMode: "editMode" }, { selectedElementChange: "selectedElementChange", showDialogAddAddressChange: "showDialogAddAddressChange", actionParamsChange: "actionParamsChange" }, []);
export { ElementInputGeoapifyAutocompleteComponentNgFactory as ElementInputGeoapifyAutocompleteComponentNgFactory };
