import { Injectable } from '@angular/core';
import { ComponentService } from '../../../../services/component-highlight-stack.service';
import { ElementsStackService } from '../../../../services/elements-stack.service';
import { ModulesStateService } from '../../../../services/modules-state.service';
import {ToolbarItemAbstractDisabledCheckService} from './toolbar-item-abstract-disabled-check.service';
import {AuthenticationService} from '../../../../../../core/authentication/authentication.service';


@Injectable()
export class ToolbarItemModifiedDisabledCheckService extends ToolbarItemAbstractDisabledCheckService {

  constructor(
    protected elementStackService: ElementsStackService,
    protected componentService: ComponentService,
    protected modulesStateService: ModulesStateService,
    protected authenticationService: AuthenticationService

  ) {
      super(elementStackService, componentService, modulesStateService, authenticationService);
  }

  isDisabled(): boolean {
    return this.isDisabledGlobally() || this.component.hasChanges(true);
  }

}
