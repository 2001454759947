import {ChangeDetectionStrategy, Component, Input, OnInit} from '@angular/core';
import { ToolbarItemAbstract } from '../toolbar-item-abstract.component';
import { ToolbarItem } from '../../../../../../services/element/toolbar-item';
import { MessageGrowlService } from '../../../../../../../core/message/message-growl.service';
import { DoubleClickService } from '../../../../../services/double-click.service';
import { cloneDeep } from 'lodash';
import { AbstractGenericGridComponent } from 'app/shared/content-renderer/elements/abstract-generic-grid.component';
import {ElementContext} from '../../../../../services/ElementContext';
import {GenericCrudService} from '../../../../../../services/generic-crud.service';
import {RequestCachingService} from '../../../../../../services/request-caching.service';
import {GenericDialogModuleService} from '../../../../generic-dialog/service/generic-dialog-module.service';
import {ModulesStateService} from '../../../../../services/modules-state.service';
import {ModuleNavigationService} from '../../../../../services/navigation/module-navigation.service';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'toolbar-item-entity-import',
  template: ''
})
export class ToolbarItemEntityImport extends ToolbarItemAbstract {

  private toolbarItem: ToolbarItem;
  private entity: any;

  constructor(
    private messageGrowlService: MessageGrowlService,
    private doubleClickService: DoubleClickService,
    private genericCrudService: GenericCrudService,
    private requestCachingService: RequestCachingService,
    private dialogService: GenericDialogModuleService,
    private modulesStateService: ModulesStateService,
    private navigationService: ModuleNavigationService
  ) {
    super();
  }

  public click() {
    const component = this.getComponent();

    if (component instanceof AbstractGenericGridComponent && component.getSelectedEntity()) {
        this.doImportSelectedEntity(component);
    }
  }

  private doImportSelectedEntity(gridComponent: AbstractGenericGridComponent) {
    this.toolbarItem = this.getToolbarItem();

    if (gridComponent.getElementContext().masterElementContext
      && gridComponent.getElementContext().masterElementContext.masterElementContext
    ) {
      const mappings = this.prepareMappingConfigs(gridComponent.getElementContext().masterElementContext.masterElementContext);

      const request = {
        'mapping': mappings,
        'entities': [gridComponent.getSelectedEntity().id],
        'entityFqn': gridComponent.getElementDatamodelEntityName()
      };

      this.genericCrudService.customPost('app/copy/entity', request).subscribe((results) => {
        const parentGrid = gridComponent.getElementContext().masterElementContext.masterElementContext.component;
        if (parentGrid instanceof AbstractGenericGridComponent) {
          const moduleState = this.modulesStateService.getByComponent(gridComponent);

          this.navigationService
            .openPrevious(moduleState)
            .subscribe(() => {
              this.requestCachingService.removeByExpression(parentGrid.getElementDataModelApiRoute());
              parentGrid.loadEntities().subscribe();
            });
        }
      });
    }
  }

  private prepareMappingConfigs(elementContext: ElementContext): Array<any> {
    const mappings = [];

    for (const masterEntityConfig of elementContext.getMasterEntities()) {
      if (masterEntityConfig.value) {
        mappings.push(
          {
            'propertyName': masterEntityConfig.name,
            'uniqueId': masterEntityConfig.value
          }
        );
      }
    }

    return mappings;
  }
}
