import {ElementInputListBox} from '../../../../form-viewer/models/element-input-list-box';
import {FormViewerComponent} from '../../../../form-viewer/form-viewer.component';
import {Datamodel} from '../../../datamodel/datamodel';
import {AbstractLoadWorkplaceSelectedEntitiesStep} from './abstract-load-workplace-selected-entities-step';

export class LoadWorkplaceSelectedWorkEquipmentsStep extends AbstractLoadWorkplaceSelectedEntitiesStep {

  protected getDatamodelApiRout(): string {
    return 'phoenix/workequipments';
  }

  protected getInput(form: FormViewerComponent): ElementInputListBox {
    for(let input of form.inputElements){
      if(input instanceof ElementInputListBox && input.datamodel.name === 'PhoenixBundle.WorkEquipment'){
        return input;
      }
    }

    return null;
  }
}
