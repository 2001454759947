import {ChangeDetectionStrategy, Component} from '@angular/core';
import {ToolbarItemAbstract} from '../toolbar-item-abstract.component';
import {MessageGrowlService} from '../../../../../../../core/message/message-growl.service';
import {ComponentService} from '../../../../../services/component-highlight-stack.service';
import {AbstractGenericGridComponent} from 'app/shared/content-renderer/elements/abstract-generic-grid.component';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'toolbar-item-delete-filters',
  templateUrl: './toolbar-item-delete-filters.component.html'
})
export class ToolbarItemDeleteFilters extends ToolbarItemAbstract {

  constructor(
    private messageGrowlService: MessageGrowlService,
    private componentService: ComponentService
  ) {
    super();
  }

  public click() {
    const component = this.getComponent();

    if (this.isInCurrentContext(ToolbarItemAbstract.CONTEXT_MODULE)) {
      this.doRefreshModuleComponent(this.getParam('moduleComponent'));
    } else if (component instanceof AbstractGenericGridComponent) {
      this.doRefreshGridComponent(component);
    }
  }

  private doRefreshModuleComponent(moduleComponent) {
    const componentsOnPage = this.componentService.getStashed();

    componentsOnPage.forEach((component) => {
      if (component instanceof AbstractGenericGridComponent) {
          this.doRefreshGridComponent(component);
      }
    });
  }

  private doRefreshGridComponent(gridComponent: AbstractGenericGridComponent) {
    if (!gridComponent.element.datamodel || !gridComponent.element.datamodel.apiRoute) {
      console.log('Module element has no datamodel assigned!');
    }

    gridComponent.isFilterContainerVisible = false;
    gridComponent.clearFilters()
      .initColumns()
      .loadEntities().subscribe();
  }
}
