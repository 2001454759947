
import {catchError, share, tap, map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import 'rxjs/Rx';

import { AppHttpService } from '../../../app-http.service';
import { TemplateElement } from './template.element';
import { GenericCrudService } from '../generic-crud.service';

@Injectable()
export class TemplateElementCrudService extends AppHttpService {

  private cachedTemplateElements = null;
  private templateElementsUrl: string = 'superadmin/templates/:templateId/elements';

  constructor(private genericCrudService: GenericCrudService) {
    super();
  }

  /**
   * @argument {number} productId The id of the selected product.
   * @argument {string} format Optional. If not set, then the entities are returned as is.
   *                            Selectable formats: formenu (MenuItem), fortree (TreeNode).
   */
  getTemplateElements(templateId: number, format: string = null): Observable<TemplateElement[]> {
    format = format ? `/${format}` : '';
    const urlWithIdandFormat = this.templateElementsUrl.replace(/\:templateId/g, String(templateId)) + format;
    return this.genericCrudService.get(urlWithIdandFormat).pipe(
      map((templates: Array<any>) => {
        return <Array<TemplateElement>>templates;
      }),
      tap((data) => { this.cachedTemplateElements = data; }),
      share(),
      catchError(this.handleError));
  }

  deleteTemplateElement(templateId: number, templateElementId: number): Observable<Object> {
    if (templateId > 0 && templateElementId > 0) {
      this.cachedTemplateElements = null;
      const urlWithId = this.templateElementsUrl.replace(/\:templateId/g, String(templateId));
      return this.genericCrudService.deleteEntity(urlWithId + '/' + templateElementId);
    } else {
      throw new Error('Invalid templateElement id given.');
    }
  }

  /**
   * @argument {number} productId The id of the selected product.
   * @argument {TemplateElement} newTemplateElement The new template element to create.
   * @argument {TemplateElement} parentTemplateElement Optional. The parent template element to add to.
   * @argument {string} format Optional. If not set, then the entities are returned as is.
   *                            Selectable formats: formenu (MenuItem), fortree (TreeNode).
   */
  createTemplateElement(templateId: number, newTemplateElement: TemplateElement, parentTemplateElement?: TemplateElement, format: string = null): Observable<any> {
    if (templateId > 0 && newTemplateElement) {
      this.cachedTemplateElements = null;
      if (parentTemplateElement) {
        newTemplateElement.parentId = parentTemplateElement.id;
      }
      format = format ? `/${format}` : '';
      const urlWithIdandFormat = this.templateElementsUrl.replace(/\:templateId/g, String(templateId)) + format;
      return this.genericCrudService.createEntity(urlWithIdandFormat, newTemplateElement);
    } else {
      throw new Error('Invalid templateElement given. Template id: ' + templateId
        + '; new templateElement: ' + newTemplateElement + '; parentTemplateElement: ' + parentTemplateElement);
    }
  }

  /**
   * @argument {number} productId The id of the selected product.
   * @argument {TemplateElement} templateElement The template element to edit.
   * @argument {string} format Optional. If not set, then the entities are returned as is.
   *                            Selectable formats: formenu (MenuItem), fortree (TreeNode).
   */
  editTemplateElement(templateId: number, templateElement: TemplateElement, format: string = null): Observable<any> {
    if (templateId > 0 && templateElement) {
      this.cachedTemplateElements = null;
      format = format ? `/${format}` : '';
      const urlWithIdandFormat = this.templateElementsUrl.replace(/\:templateId/g, String(templateId)) + '/' + templateElement.id + format;
      return this.genericCrudService.editEntity(urlWithIdandFormat, templateElement);
    } else {
      throw new Error('Invalid templateElement given. Template id: ' + templateId + '; new templateElement: ' + templateElement);
    }
  }

}
