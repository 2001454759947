<form id="overview-form" class="form-vertical form-label-bold" role="form">
  <div class="form-group" *ngFor="let key of properties">
    <div *ngIf="!isComplex(entity[key])">
      <label>{{key}}:</label>
      <input type="text" class="form-control" [value]="entity[key]" disabled="disabled">
    </div>
  </div>
  <button (click)="onPrevious()" type="button" class="btn btn-default">Previous</button>
  <button (click)="onNext()" type="button" class="btn btn-default">Next</button>
  <button (click)="onClose()" type="button" class="btn btn-primary">Close</button>
</form>
