import { AbstractAssociatedInlineEditor } from './abstract-associated-inline-editor';
import {Entity} from '../../../../helpers/entity';
import {FieldMetadataGrid} from '../../../../services/module/module-element-field-metadata-grid';
import {EntityStatus} from '../../../../services/entity/entity-status';
import {map, takeUntil} from 'rxjs/operators';
import {ChangeDetectorRefHelper} from '../../../../helpers/change-detector-ref.helper';

export class MultiselectInlineEditor extends AbstractAssociatedInlineEditor {

    public onEdit(entity: any, event: any) {
        const values = event.value,
            entityValue = [];

        if (values instanceof Array) {
            for (const value of values) {
                const entityEmbeddedValue = this.getEntityFromAssociatedOptions(value);

                if (entityEmbeddedValue) {
                    entityValue.push(entityEmbeddedValue);
                }
            }
        }

        this.changeEntityColumnProperty(entityValue);
    }

    public onBlur(entity: any, event: any) {

    }

    public onFocus(entity: any, event: any) {

      return this.component.getColumnBuilder()
        .loadOptions(this.field)
        .pipe(
          takeUntil(this.component.unsubscribe),
          map((options) => {
          this.component.associatedOptions[this.field.entityName] = [];

          if (this.field.filterType === FieldMetadataGrid.FILTER_TYPE_DROPDOWN) {
            this.component.associatedOptions[this.field.entityName] = [{ label: '---', value: null }];
          }

          this.component.associatedOptions[this.field.entityName] = this.component.associatedOptions[this.field.entityName].concat(options);

          const selectedOptions = this.getSelectedOptions(entity);

          this.component.associatedOptionsValues[this.field.entityName] = this.component.associatedOptionsValues[this.field.entityName] || {};
          this.component.associatedOptionsValues[this.field.entityName][entity[EntityStatus.ENTITY_DRAFT_FLAG]] = selectedOptions;

          ChangeDetectorRefHelper.detectChanges(this.component);
        }));
    }

    private getSelectedOptions(entity: any): any[] {
      const entityEmbeddedEntities = Entity.getValue(entity, this.getEntityColumnProperty()) ||
        Entity.getValueInEmbedded(entity, this.getEntityColumnProperty());

      const ids = [];
      if (entityEmbeddedEntities && entityEmbeddedEntities instanceof Array) {
        for (const embeddedEntity of entityEmbeddedEntities) {
          ids.push(embeddedEntity.id);
        }
      }

      return ids;
    }
}
