/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./toolbar-item-open-questionnaire-by-phone-project-group.component";
import * as i2 from "../../../../../../services/generic-crud.service";
import * as i3 from "../../../../../services/double-click.service";
import * as i4 from "../../../../../../../core/message/message-growl.service";
import * as i5 from "@ngx-translate/core";
var styles_ToolbarItemOpenQuestionnaireByPhoneProjectGroupComponent = [];
var RenderType_ToolbarItemOpenQuestionnaireByPhoneProjectGroupComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_ToolbarItemOpenQuestionnaireByPhoneProjectGroupComponent, data: {} });
export { RenderType_ToolbarItemOpenQuestionnaireByPhoneProjectGroupComponent as RenderType_ToolbarItemOpenQuestionnaireByPhoneProjectGroupComponent };
export function View_ToolbarItemOpenQuestionnaireByPhoneProjectGroupComponent_0(_l) { return i0.ɵvid(2, [], null, null); }
export function View_ToolbarItemOpenQuestionnaireByPhoneProjectGroupComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "toolbar-item-open-questionnaire-by-phone-project-group", [], null, null, null, View_ToolbarItemOpenQuestionnaireByPhoneProjectGroupComponent_0, RenderType_ToolbarItemOpenQuestionnaireByPhoneProjectGroupComponent)), i0.ɵdid(1, 245760, null, 0, i1.ToolbarItemOpenQuestionnaireByPhoneProjectGroupComponent, [i2.GenericCrudService, i3.DoubleClickService, i4.MessageGrowlService, i5.TranslateService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ToolbarItemOpenQuestionnaireByPhoneProjectGroupComponentNgFactory = i0.ɵccf("toolbar-item-open-questionnaire-by-phone-project-group", i1.ToolbarItemOpenQuestionnaireByPhoneProjectGroupComponent, View_ToolbarItemOpenQuestionnaireByPhoneProjectGroupComponent_Host_0, { params: "params" }, {}, []);
export { ToolbarItemOpenQuestionnaireByPhoneProjectGroupComponentNgFactory as ToolbarItemOpenQuestionnaireByPhoneProjectGroupComponentNgFactory };
