export enum LanguagesEnum {
  Deutsch = 1,
  Englisch = 2
}

export enum LanguageCodeEnum {
  de = 1,
  en = 2
}

export enum CountryCodesEnum {
  de = 1,
  gb = 2
}
