import {ChangeDetectionStrategy, Component, Input, OnInit} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { ToolbarItemAbstract } from '../toolbar-item-abstract.component';
import { GenericTreeGridComponent } from '../../../../generic-tree-grid/generic-tree-grid.component';
import { GenericCrudService } from '../../../../../../services/generic-crud.service';
import { MessageGrowlService } from '../../../../../../../core/message/message-growl.service';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'toolbar-item-salary-type-split',
  template: ''
})
export class ToolbarItemSalaryTypeSplit extends ToolbarItemAbstract {

  public constructor(
    private genericCrudService: GenericCrudService,
    private messageGrowlService: MessageGrowlService,
    private translateService: TranslateService
  ) {
    super();
  }

  public click() {
    const component = this.getComponent();

    if (component instanceof GenericTreeGridComponent) {
      this.splitChildren(component);
    }
  }

  private splitChildren(component: GenericTreeGridComponent): void {
    const selectedNode = component.getSelectedNode();

    if (selectedNode.children && selectedNode.children.length > 0) {
        this.saveChildren(selectedNode.children)
            .subscribe(() => {

                this.messageGrowlService.success(
                    this.translateService.instant("COMMON.DATA_SAVED"),
                    this.translateService.instant("COMMON.SUCCESS")
                );

                component.loadEntities().subscribe();
            });
    }
  }

  private saveChildren(children: TreeNode[] = []): Observable<any> {
    const childrenEntities = this.getChildrenEntities(children); 

    return this.genericCrudService.editEntities('phoenix/salarytypes', childrenEntities);
  }

  private getChildrenEntities(children: TreeNode[] = []): any[] {
    const entities = [];

    for (let node of children) {
        const entity = node.data;

        entity.salaryTypeCategory = null;
        entity.parent = null;

        entities.push(entity);
    }

    return entities;
  }
}
