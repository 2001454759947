<div class="loading-container">
  <!-- Loading animation container -->
  <div class="loading">
    <!-- We make this div spin -->
    <div class="spinner">
      <!-- Mask of the quarter of circle -->
      <div class="mask">
        <!-- Inner masked circle -->
        <div class="maskedCircle"></div>
      </div>
    </div>
  </div>
</div>
