import { Injectable } from '@angular/core';
import { ToolbarItemDisabledCheckInterface } from './toolbar-item-disabled-check-factory.service';
import { ToolbarItemAbstractDisabledCheckService } from './toolbar-item-abstract-disabled-check.service';
import {AbstractGenericGridComponent} from '../../../abstract-generic-grid.component';

@Injectable()
export class ToolbarItemContractOfEmploymentDisabledCheckService
  extends ToolbarItemAbstractDisabledCheckService
  implements ToolbarItemDisabledCheckInterface {

  isDisabled(): boolean {
    let disabled = false;
    if (this.toolbarItem.onclick === 'open_copy_of_contract_of_employment') {
      disabled = this.entity ? this.entity.exitAgreementDate !== null && typeof this.entity.exitAgreementDate !== 'undefined' : true;
    }else if (this.toolbarItem.onclick === 'open_copy_of_contract_of_employment2') {
      disabled = this.entity ? (this.entity.exitAgreementDate === null || typeof this.entity.exitAgreementDate === 'undefined')
        && this.isLatestContract(this.entity) : true;
    }

    return this.isDisabledGlobally() || disabled;
  }

  private isLatestContract(contract: any): boolean {
    let latestContract = contract;

    if (!(typeof this.entity.exitAgreementDate === 'undefined' || this.entity.exitAgreementDate === null)
      && this.component instanceof AbstractGenericGridComponent) {
      for (const entity of this.component.getEntities()) {
        if (entity.id === contract.id) {
          continue;
        }
        if (typeof entity.exitAgreementDate === 'undefined' || entity.exitAgreementDate === null) {
          latestContract = entity;
          break;
        }

        if (entity.exitAgreementDate > contract.exitAgreementDate) {
          latestContract = entity;
          break;
        }
      }
    }

    return latestContract.id === contract.id;
  }
}
