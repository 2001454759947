
import {of as observableOf, Observable} from 'rxjs';

import {map} from 'rxjs/operators';
import {AbstractExecutionStep} from '../../../../core/executor/abstract-execution-step';
import {ExecutionStepStatus} from '../../../../core/executor/execution-step-status';
import {AbstractGenericGridComponent} from '../../../content-renderer/elements/abstract-generic-grid.component';
import {MessageGrowlService} from '../../../../core/message/message-growl.service';
import {TOAST_LIFE_INFINITE, ToastComponentsRegistry, ToastService} from '../../../../core/service/toast.service';
import {TranslateService} from '@ngx-translate/core';
import {
  WorkHourTimeEntryWeeklyOverviewComponent
} from '../../../content-renderer/elements/custom/work-hour/time-entry-weekly-overview/work-hour-time-entry-weekly-overview.component';

export class WorkHourMonthlyEntryToolbarItemWageSettledExecutionStep extends AbstractExecutionStep {

  public doExecute(): Observable<ExecutionStepStatus> {
    const payload = this.getPayload(),
      component = payload.getValue().component ? payload.getValue().component : payload.getValue();

    if (component instanceof AbstractGenericGridComponent) {
      return this.doSet(component);
    }

    return observableOf({status: false, content: 'Not configurated correctly!' });
  }

  private doSet(component: AbstractGenericGridComponent): Observable<ExecutionStepStatus> {
    const masterComponent = this.getMasterComponent(component);

    if (masterComponent && masterComponent.selectedEntity && masterComponent.selectedEntity.id) {
      this.getToast().custom(ToastComponentsRegistry.PROGRESS_BAR, {
        severity: 'info',
        life: TOAST_LIFE_INFINITE,
        closable: false,
        summary: this.getTranslate().instant('COMMON.SAVING_PLEASE_WAIT')
      });

      return this.genericCrudService.editEntity(
        `phoenix/workhours/wagesettled/${masterComponent.selectedEntity.id}`, {}
        ).pipe(
        map(() => {

          component.loadEntities().subscribe();
          masterComponent.entities = [];
          masterComponent.loadEntities().subscribe();

          this.reloadWeeklyOverviewGrid(component);

          this.getToast().clear(ToastComponentsRegistry.PROGRESS_BAR, 300);
          this.getGrowl().showDataSaved();

          return {status: true, content: null };
        }));
    }

    return observableOf({status: false, content: 'Not configurated correctly!' });
  }

  private getMasterComponent(component: AbstractGenericGridComponent): AbstractGenericGridComponent|null {
    if (component.masterElementContext && component.masterElementContext.component &&
      component.masterElementContext.component instanceof AbstractGenericGridComponent
    ) {
      return component.masterElementContext.component;
    }

    return null;
  }

  private getGrowl(): MessageGrowlService|null {
    return this.injector.get(MessageGrowlService, null);
  }

  private getToast(): ToastService|null {
    return this.injector.get(ToastService, null);
  }

  private getTranslate(): TranslateService|null {
    return this.injector.get(TranslateService, null);
  }

  private reloadWeeklyOverviewGrid(component: AbstractGenericGridComponent): void {
    const moduleState = this.modulesStateService.getByComponent(component),
      partModuleState = moduleState.getPartById(component.moduleElement.moduleId);

    let components = [];
    if (partModuleState) {
      components = partModuleState.getComponents();
    }

    let componentToReload = null;

    for (const aComponent of components) {
      if (aComponent instanceof WorkHourTimeEntryWeeklyOverviewComponent) {
        componentToReload = aComponent;
      }
    }

    if (componentToReload && componentToReload instanceof WorkHourTimeEntryWeeklyOverviewComponent) {
      componentToReload.loadWorkHours().subscribe();
    }
  }
}
