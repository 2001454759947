<div class="ui-g">
  <div class="ui-g-2">
    <div class="ui-g-4">
      {{ 'COST_CENTER.COST_CENTER' | translate }}:
    </div>

    <div class="ui-g-8">
      <app-generic-dropdown
        [api]="'intranet/costcenters/unique'"
        (optionChanged)="onCostCenterChange($event)"
        [labelKey]="'name'"
        [showEmptyOption]="true"
      ></app-generic-dropdown>
    </div>
  </div>

  <div class="ui-g-2">
    <div class="ui-g-4">
      {{ 'COST_CENTER.YEAR' | translate }}:
    </div>

    <div class="ui-g-8">
      <app-generic-dropdown
        [api]="'intranet/babs/years'"
        (optionChanged)="onYearChanged($event)"
        [labelKey]="'label'"
        [showEmptyOption]="true"
      ></app-generic-dropdown>
    </div>
  </div>

  <div class="ui-g-12">
    <shared-dynamic-table
      [dataKey]="'title'"
      [columns]="columns"
      [parentComponent]="this"
      [cells]="cells"
      [lazy]="false"
      [rowsCount]="9999"
      [entities]="entities"
      [isLoadingData]="isLoading"
      [selectFirstEntityWhenNothingIsSelected]="moduleElement.selectFirst"
      [showFilters]="false"
      [paginator]="false"
      [height]="gridHeight"
    ></shared-dynamic-table>
  </div>
</div>
