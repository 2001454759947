
import {of as observableOf, Observable} from 'rxjs';
import {AbstractExecutionStep} from '../../../../core/executor/abstract-execution-step';
import {ExecutionStepStatus} from '../../../../core/executor/execution-step-status';
import {AbstractGenericGridComponent} from '../../../content-renderer/elements/abstract-generic-grid.component';
import {ExecutorActionEvent} from '../../../../core/executor/service/executor-actions/executor-action-event';

export class CopyEntityStep extends AbstractExecutionStep {

  public doExecute(): Observable<ExecutionStepStatus> {
    const payload = this.getPayload(),
      component = payload.getValue();

    if (component instanceof AbstractGenericGridComponent) {

      return this.doCopy(component);
    }

    return this.getFailObservable('You need to pass AbstractGenericGridComponent as Payload value!');
  }

  protected doCopy(component: AbstractGenericGridComponent) {
    const request = {
      'entities': [component.getSelectedEntity().id],
      'entityFqn': component.getElementDatamodelEntityName()
    };

    this.genericCrudService.customPost('app/copy/entity', request).subscribe((results) => {
      for (let newEntity of results) {
        component.addEntity(newEntity);
        component.setSelectedEntity(newEntity);
        component.executeAction(ExecutorActionEvent.EntityImported, component).subscribe();
      }
    });
    return observableOf({status: true, content: []});
  }

}
