import { of as observableOf } from 'rxjs';
import { map } from 'rxjs/operators';
import { PrecalculationDetailsToggleColumnsVisibilityStep } from '../customer/condition/precalculation-details-toggle-columns-visibility-step';
import { Entity } from '../../../helpers/entity';
export class WorkHourEntryPrecalculationArticlesToggleColumnVisibilityExecutionStep extends PrecalculationDetailsToggleColumnsVisibilityStep {
    constructor() {
        super(...arguments);
        this.preCalculation = null;
    }
    doToggleVisibility(form, grid) {
        const preCalculationId = Entity.getValue(form.entity, 'preCalculation.id') ||
            Entity.getValueInEmbedded(form.entity, 'preCalculation.id');
        grid.isDataLoading = true;
        if (preCalculationId === null) {
            return observableOf({ status: false, content: 'Cannot find preCalculation from subAssignment!' });
        }
        return this.genericCrudService.getEntity('phoenix/precalculations', preCalculationId, '', {
            'embedded': 'billingUnit,salaryUnit'
        }).pipe(map((preCalculation) => {
            this.preCalculation = preCalculation;
            this.applyConditions(form, grid);
            grid.isDataLoading = false;
            return { status: true, content: null };
        }));
    }
    getPreCalculation(form) {
        return this.preCalculation;
    }
}
