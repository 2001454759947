import {Injectable} from '@angular/core';
import {ModuleState} from '../module-state';
import {GenericElementAbstract} from '../../elements/generic-element-abstract.component';
import {EntityValidator} from '../../../validators/services/entity-validator';
import {GenericDialogModuleService} from '../../elements/generic-dialog/service/generic-dialog-module.service';
import {Router} from '@angular/router';

export enum ModuleChangeComponentAction {

}

export enum ModuleChangeAction {
  ClearEntityValidatorCache = 1,
  HideModuleDialog = 2,
  ChangeRoute = 3
}

@Injectable()
export class ModuleChangeService {

  public constructor(
    private readonly entityValidator: EntityValidator,
    private readonly genericModuleDialogService: GenericDialogModuleService,
    private readonly router: Router
  ) {

  }

  public change(moduleState: ModuleState, opts: {componentActions?: ModuleChangeComponentAction[],
    moduleActions?: ModuleChangeAction[]}): void {

    const componentActions = opts ? opts.componentActions : [],
      moduleActions = opts ? opts.moduleActions : [];

    this.performComponentActions(moduleState, componentActions);
    this.performModuleActions(moduleState, moduleActions);
  }

  private performComponentActions(moduleState: ModuleState, actions: ModuleChangeComponentAction[] = []): void {
    const components = moduleState.getAllComponents();

    for (const component of components) {
      if (component instanceof GenericElementAbstract) {
        for (const action of actions) {
          this.performComponentAction(component, action);
        }
      }
    }
  }

  private performModuleActions(moduleState: ModuleState, actions: ModuleChangeAction[]): void {
    for (const action of actions) {
      this.performModuleAction(moduleState, action);
    }
  }

  private performComponentAction(component: GenericElementAbstract, action: ModuleChangeComponentAction): void {

  }

  private performModuleAction(moduleState: ModuleState, action: ModuleChangeAction): void {
    switch (action) {
      case ModuleChangeAction.ClearEntityValidatorCache:
        this.clearEntityValidatorCache(moduleState);
        break;
      case ModuleChangeAction.HideModuleDialog:
        this.hideModuleDialog(moduleState);
        break;
      case ModuleChangeAction.ChangeRoute:
        this.changeRoute(moduleState);
        break;
    }
  }

  private clearEntityValidatorCache(moduleState: ModuleState): void {
    this.entityValidator.clearDictionaries().clearCache();
  }

  private hideModuleDialog(moduleState: ModuleState): void {
    this.genericModuleDialogService.hideDialog();
  }

  private changeRoute(moduleState: ModuleState): void {
    this.router.navigate([moduleState.url]).then();
  }
}
