import {ChangeDetectionStrategy, Component, Input, OnInit} from '@angular/core';
import { GenericCrudRequestOptions } from '../../../../../../services/generic-crud-request-options.service';
import { ToolbarItemFilter } from '../toolbar-item-filter.component';
import { GenericGridGlobalFilterService } from '../../../../generic-grid/services/generic-grid-global-filter.service';

interface ToolbarItemCheckboxAware {
  click();
}

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'toolbar-item-show-expired',
  templateUrl: './toolbar-item-show-expired.component.html',
  providers: [ GenericGridGlobalFilterService ]
})
export class ToolbarItemShowExpired extends ToolbarItemFilter {

  protected getFilterKey(): string {
    return GenericCrudRequestOptions.SHOW_EXPIRED;
  }
}
