
import {map} from 'rxjs/operators';
import {Observable} from 'rxjs';
import {AbstractExecutionStep} from '../../../../core/executor/abstract-execution-step';
import {ExecutionStepStatus} from '../../../../core/executor/execution-step-status';
import {GenericElementAbstract} from '../../../content-renderer/elements/generic-element-abstract.component';
import {MemoFieldDefinitionValueComponent} from '../../../content-renderer/elements/custom/memo-field-definition-value/memo-field-definition-value.component';
import {Entity} from '../../../helpers/entity';
import {SetupMemoFieldDefinitionValueComponentExecutionStep} from '../memo/setup-memo-field-definition-value-component-execution.step';

export class SetupQuestionnaireFieldDefinitionValueComponentExecutionStep extends SetupMemoFieldDefinitionValueComponentExecutionStep {

  protected doSetup(component: GenericElementAbstract, memoComponent: MemoFieldDefinitionValueComponent): Observable<ExecutionStepStatus> {
    const todo = component.getSelectedEntity();

    memoComponent.memo = todo.questionnaire;

    memoComponent.memoType = todo.questionnaire.questionnaireType;
    memoComponent.configuration.memoTypeRelation = 'questionnaireType';
    memoComponent.configuration.fieldDefinitionValuesApi = 'app/questionnairefielddefinitionvalues';
    memoComponent.configuration.memoTypeApi = 'app/questionnairetypes';
    memoComponent.configuration.memoTypeFieldDefinitionsApi = 'app/questionnairefielddefinitions';
    memoComponent.configuration.ownerApi = 'app/questionnaires';
    memoComponent.configuration.memoTypeDefinedOutside = true;
    memoComponent.configuration.embeddedEntities = ['questionnaireType'];
    memoComponent.configuration.usesExternalRefresh = true;

    return memoComponent.onRefresh().pipe(
      map(() => {
        return {status: true, content: null};
      }));
  }
}
