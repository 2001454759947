import { AbstractGridFilter } from './abstract-grid-filter';

export class GridFilterNumber extends AbstractGridFilter {

  public onFocus(entity: any, event: any) {

  }

  protected parseRemoteMatchMode() {
    let filterMatchMode = this.meta.matchMode || AbstractGridFilter.MATCH_MODE_STARTS_WITH;

    if (typeof this.meta.value == 'string') {
      const asteriskPosition = this.meta.value.indexOf('*');
      const asteriskCount = (this.meta.value.match(/\*/gi) || []).length;

      if (asteriskPosition != -1) {
        if (asteriskCount == 1) {
          filterMatchMode = (asteriskPosition != 0) ? AbstractGridFilter.MATCH_MODE_STARTS_WITH : AbstractGridFilter.MATCH_MODE_ENDS_WITH;
        } else {
          filterMatchMode = AbstractGridFilter.MATCH_MODE_CONTAINS;
        }
      }
    }

    if (this.isRange()) {
      filterMatchMode = AbstractGridFilter.MATCH_MODE_RANGE;
    }

    return filterMatchMode;
  }

  private getRangeValue(value) {
    let valueParts = value.split('-'),
      rangeSeparator = AbstractGridFilter.RANGE_FILTER_TYPE_SEPARATOR;

    let valuePartOne = valueParts[0] || 0;
    let valuePartTwo = valueParts[1] || 0;

    return `${valuePartOne}${rangeSeparator}${valuePartTwo}`;
  }

  public parseRemoteValue() {
    let filterValue = this.meta.value;

    if (this.isRange()) {
      filterValue = this.getRangeValue(this.meta.value);
    }

    return filterValue;
  }

  public parseLocalValue() {

  }

  private isRange(): boolean {
    return this.meta.value.includes('-');
  }

  public isValid(): boolean {
    // (GU) :: if isRange check it!
    return true;
  }
}
