
import {of as observableOf,  Observable } from 'rxjs';
import { AbstractExecutionStep } from '../../../../core/executor/abstract-execution-step';
import { ExecutionStepStatus } from '../../../../core/executor/execution-step-status';
import { GenericElementAbstract } from '../../../content-renderer/elements/generic-element-abstract.component';
import { EntityStatus } from '../../entity/entity-status';
import { InquiryPositionSalaryTypeMatrix, InquiryPositionMatrixItem } from './service/inquiry-position-salary-type-matrix';
import { DynamicTableColumn } from '../../../content-renderer/services/dynamic-table-column';

export class InlineEditInquiryDynamicTableStep extends AbstractExecutionStep {


    doExecute(): Observable<ExecutionStepStatus> {
        const payload = this.getPayload();

        let component = null;

        if (payload instanceof Object && payload.getValue().component) {
            component = payload.getValue().component;
        }

        if (!(component instanceof GenericElementAbstract)) {
            return this.getFailObservable('You need to pass GenericElementAbstract as Payload value!');
        }

        return this.doEdit(payload.getValue());
    }

    private doEdit(payloadValue: any): Observable<ExecutionStepStatus> {
        const column: DynamicTableColumn = payloadValue.column,
            entity: InquiryPositionSalaryTypeMatrix = payloadValue.entity,
            isChecked = payloadValue.event;

        const columnKey = column.key;

        entity[EntityStatus.ENTITY_CHANGED_FLAG] = true;

        if (columnKey === '*') {
            for (const inquiryPositionId in entity.inquiryPositions) {
                const inquiryPositionItem: InquiryPositionMatrixItem = entity.inquiryPositions[inquiryPositionId];

                inquiryPositionItem.isRequired = isChecked;
            }
        } else {
            entity.inquiryPositions[column.key].isRequired = isChecked;
        }

        return observableOf({status: true, content: null});
    }
}
