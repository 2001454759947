import { Component, OnInit, Input } from '@angular/core';

@Component({
    selector: 'app-generic-menu',
    templateUrl: './generic-menu.component.html',
    styleUrls: ['./generic-menu.component.scss']
})
export class GenericMenuComponent implements OnInit {

    @Input() items: any[];
    @Input() iconsPath: string = '';
    @Input() menuPosition: string = 'right';

    constructor() { }

    ngOnInit() {

    }
}
