import {ChangeDetectionStrategy, Component} from '@angular/core';
import { ToolbarItemAbstract } from '../toolbar-item-abstract.component';
import { MessageGrowlService } from '../../../../../../../core/message/message-growl.service';
import { MessageService } from '../../../../../../../core/message/message.service';
import { GenericCrudService } from '../../../../../../services/generic-crud.service';
import { ComponentService } from '../../../../../services/component-highlight-stack.service';
import { GenericGridComponent } from '../../../../generic-grid/generic-grid.component';
import { AbstractGenericGridComponent } from 'app/shared/content-renderer/elements/abstract-generic-grid.component';
import { EntityFactoryService } from '../../../../../../services/entity-factory.service';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'toolbar-item-copy',
  templateUrl: './toolbar-item-copy.component.html'
})
export class ToolbarItemCopy extends ToolbarItemAbstract {

  private readonly entityNotSelectedError = 'Entity not selected';

  constructor(
    protected messageGrowlService: MessageGrowlService,
    protected messageService: MessageService,
    protected genericCrudService: GenericCrudService,
    protected componentService: ComponentService,
    protected entityFactoryService: EntityFactoryService
  ) {
    super();
  }

  public click() {
    const component = this.getComponent();

    if (component instanceof AbstractGenericGridComponent) {
      this.handleCopyInGridContext(component);
    } else if (this.isInCurrentContext(ToolbarItemAbstract.CONTEXT_MODULE)) {
      this.handleCopyInModuleContext();
    }
  }

  private handleCopyInGridContext(component: AbstractGenericGridComponent) {
    const entity = component.selectedEntity;

    if (!entity) {
      return this.messageGrowlService.error(this.entityNotSelectedError);
    }

    this.onCloneEntity(entity);
  }

  private handleCopyInModuleContext() {
    const highlightedEntity = this.componentService.getHighlightedEntity();

    if (!highlightedEntity) {
      return this.messageGrowlService.error(this.entityNotSelectedError);
    }

    this.onCloneEntity(highlightedEntity);
  }

  public onCloneEntity(entity) {
    this.messageService.confirm({
      header: 'Copy Entity',
      message: 'Are you sure you want to copy this entity?',
      icon: 'fa fa-plus',
      accept: () => {
        this.cloneEntity(entity);
      }
    });
  }

  cloneEntity(entity) {
    let clone = this.entityFactoryService.cloneEntity(entity);
    let cloneUrl = this.getPostRequestUrlFromEntity(entity);

    this.genericCrudService.createEntity(cloneUrl, clone).subscribe((entity) => {
      this.handleEntityCopied(entity);
    });
  }

  private getPostRequestUrlFromEntity(entity): string {
    let postUrl = entity._links.self.href.replace('/api/', ''); // remove '/api/' part from a string

    return postUrl.substr(0, postUrl.lastIndexOf("/")); // remove id from the string
  }

  private handleEntityCopied(entity): void {
    const component = this.getComponent();

    if (component instanceof AbstractGenericGridComponent) {
      this.onEntityCopiedInGridContext(component, entity);
    } else if (this.isInCurrentContext(ToolbarItemAbstract.CONTEXT_MODULE)) {
      this.onEntityAddedInModuleContext(entity);
    }
  }

  public onEntityCopiedInGridContext(component: AbstractGenericGridComponent, entity): void {
    component.addEntity(entity);
    component.selectedEntity = null;
  }

  public onEntityAddedInModuleContext(entity): void {
    const highlightedComponent = this.componentService.getHighlighted();

    if (highlightedComponent instanceof AbstractGenericGridComponent) {
      highlightedComponent.addEntity(entity);
      highlightedComponent.selectedEntity = null;
    }
  }
}
