import {Injectable} from '@angular/core';
import {ModuleElementFieldDataConfig, ModuleElementFieldFilter} from '../../../../../../services/module/module-element-field';
import {AbstractGenericGridComponent} from '../../../../../elements/abstract-generic-grid.component';
import {GenericElementAbstract} from '../../../../../elements/generic-element-abstract.component';
import {FieldMetadataGrid} from '../../../../../../services/module/module-element-field-metadata-grid';
import {Constants} from '../../../../../../../constants';

@Injectable()
export class CollectiveAgreementTypeFilterService {

  /**
   *
   * @param {ModuleElementFieldDataConfig} fieldDataConfig
   * @param {AbstractGenericGridComponent} grid
   * @returns {ModuleElementFieldFilter | null}
   */
  public static createFilter(
    fieldDataConfig: ModuleElementFieldDataConfig,
    grid: AbstractGenericGridComponent
  ): ModuleElementFieldFilter|null {
    const filter = new ModuleElementFieldFilter();

    filter.column = fieldDataConfig.values.split('.').shift();
    filter.value = CollectiveAgreementTypeFilterService.figureOutValue(fieldDataConfig.values, grid);

    return filter.value ? filter : null;
  }

  /**
   *
   * @param {string} field
   * @param {AbstractGenericGridComponent} grid
   * @returns {any}
   */
  protected static figureOutValue(field: string, grid: AbstractGenericGridComponent): any {
    if (grid.getElementContext().getMasterElementContext()
      && grid.getElementContext().getMasterElementContext().component instanceof GenericElementAbstract) {

      /**
       *
       * @type {GenericElementAbstract} masterComponent
       */
      const masterComponent = grid.getElementContext().getMasterElementContext().component;
      const masterEntity = masterComponent.getSelectedEntity();

      if (masterEntity) {
        const collectiveAgreement =  FieldMetadataGrid.getPropertyValue(masterEntity, field);

        if (collectiveAgreement) {
          return (collectiveAgreement['isForWorkpersons'])
            ? CollectiveAgreementTypeFilterService.getWorkerCollectiveAgreementId(grid) :
            ((collectiveAgreement['isForEmployees'])
              ? CollectiveAgreementTypeFilterService.getEmployeeCollectiveAgreementId(grid) : collectiveAgreement['id']);
        }
      }
    }

    return null;
  }

  protected static getEmployeeCollectiveAgreementId(grid: AbstractGenericGridComponent): number|null {
    const employeeCA = grid.getUserSession().get(Constants.COLLECTIVE_AGREEMENT_EMPLOYEES);

    if (employeeCA && employeeCA.id) {
      return employeeCA.id;
    }

    return null;
  }

  protected static getWorkerCollectiveAgreementId(grid: AbstractGenericGridComponent): number|null {
    const workerCA = grid.getUserSession().get(Constants.COLLECTIVE_AGREEMENT_HIRED_WORKER);

    if (workerCA && workerCA.id) {
      return workerCA.id;
    }

    return null;
  }
}
