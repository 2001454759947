import {ChangeSet, ChangeSetEntity, ChangeSetMeta} from './change-set';

export class ChangeSetContainer {

  public dictionary: { [fqn: string]: { [uniqueId: string]: ChangeSet[]; }} = {};

  public updateChangeSet(entity: ChangeSetEntity, meta: ChangeSetMeta) {
    this.prepare(entity);

    this.dictionary[entity.fqn][entity.uniqueId].push(new ChangeSet(meta.property, meta.oldValue, meta.newValue));
  }

  public getChangeSet(entity: ChangeSetEntity): ChangeSet[] {
    return entity && entity.fqn && this.dictionary[entity.fqn] && this.dictionary[entity.fqn][entity.uniqueId] ?
      this.dictionary[entity.fqn][entity.uniqueId] : [];
  }

  public hasChangeSet(entity: ChangeSetEntity): boolean {
    if (entity && entity.fqn && entity.uniqueId) {
      return this.getChangeSet(entity).length > 0;
    }

    return false;
  }

  public removeChangeSet(entity: ChangeSetEntity): this {
    if (this.hasChangeSet(entity)) {
      delete this.dictionary[entity.fqn][entity.uniqueId];
    }

    return this;
  }

  public clear(): this {
    this.dictionary = {};
    return this;
  }

  public all(): ChangeSet[] {
    const changeSets = [];

    for (const fqn in this.dictionary) {
      if (this.dictionary.hasOwnProperty(fqn) && this.dictionary[fqn] instanceof Object) {
        for (const uniqueId in this.dictionary[fqn]) {
          if (this.dictionary[fqn].hasOwnProperty(uniqueId) && this.dictionary[fqn][uniqueId] instanceof Array) {
            for (const changeSet of this.dictionary[fqn][uniqueId]) {
              changeSets.push(changeSet);
            }
          }
        }
      }
    }

    return changeSets;
  }

  private prepare(entity: ChangeSetEntity): this {
    this.dictionary[entity.fqn] = this.dictionary[entity.fqn] || {};
    this.dictionary[entity.fqn][entity.uniqueId] = this.dictionary[entity.fqn][entity.uniqueId] || [];
    return this;
  }

}
