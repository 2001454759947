
import {of as observableOf, Observable} from 'rxjs';
import {Component, Input, ElementRef, ViewContainerRef, ViewChild, ChangeDetectorRef} from '@angular/core';
import {ExecutorService} from '../../../../../core/executor/executor.service';
import {GenericElementAbstract} from '../../generic-element-abstract.component';
import {FieldMetadataGrid} from '../../../../services/module/module-element-field-metadata-grid';
import {ModuleElement} from '../../../../services/module/module-element';
import {EntityValidator, EntityValidatorStatus} from '../../../../validators/services/entity-validator';
import {Element} from '../../../../services/element/element';
import {ToolbarItemCheckService} from '../../generic-toolbar/services/check/toolbar-item-check.service';
import {GenericElementValidationExecutionStepsFactory} from '../../../services/generic/generic-element-validation-execution-steps-factory';
import {EntityDataStoreService} from '../../../services/entity-data-store.service';
import {ModulesStateService} from '../../../services/modules-state.service';
import {ComponentService} from '../../../services/component-highlight-stack.service';
import {GenericCrudService} from '../../../../services/generic-crud.service';
import {LocalStorageDataService} from '../../../../services/local-storage-data.service';
import {JobContainerService} from '../../../../../core/job-runner/job-container.service';
import {ExecutionStepFactoryService} from '../../../../../core/executor/factory/execution-step-factory.service';
import {LayoutService} from '../../../../services/layout-service';
import {ModuleStateContext} from '../../../services/module-state';
import {QuestionnaireService} from './service/questionnaire.service';
import {ExecutorActionsService} from '../../../../../core/executor/service/executor-actions/executor-actions.service';
import {ElementContext, ElementType} from '../../../services/ElementContext';
import {Question, Questionnaire} from '../../../../services/element/questionnaire';
import {Entity} from '../../../../helpers/entity';
import {QuestionnaireToolbarItemsService} from './service/questionnaire-toolbar-items.service';
import {QuestionnaireLayoutService} from './service/questionnaire-layout.service';
import {PermissionService} from '../../../../services/permission/permission.service';
import {UserSessionService} from '../../../../../core/service/user-session.service';

@Component({
  selector: 'app-custom-questionnaire',
  styleUrls: ['./questionnaire.component.scss'],
  templateUrl: './questionnaire.component.html',
  providers: [
    ExecutorService,
    GenericElementValidationExecutionStepsFactory,
    QuestionnaireService,
    QuestionnaireToolbarItemsService,
    QuestionnaireLayoutService
  ]
})
export class QuestionnaireComponent extends GenericElementAbstract {
  @Input() element: Element;
  @Input() fields: Array<FieldMetadataGrid>;
  @Input() toolbarItems: any[] = [];
  @Input() statusBarItems: any[] = [];
  @Input() moduleElement: ModuleElement;
  @Input() masterEntity: any = null;
  @Input() masterField: any = null;
  @Input() isPart = false;
  @Input() entity: any = null;

  public toolbarContextName = 'questionnaireComponent';

  public questionnaire: Questionnaire = null;
  public question: Question = null;

  @ViewChild('questionnaireContainer', {static: false}) set questionnaireContainer(questionnaireContainer: ElementRef) {
    if (questionnaireContainer && questionnaireContainer.nativeElement) {
      this.questionnaireLayoutService.setQuestionsContainerHeight();
    }
  }

  public constructor(
    public questionnaireService: QuestionnaireService,
    public elementRef: ElementRef,
    public executorActionsService: ExecutorActionsService,
    protected componentService: ComponentService,
    protected viewContainerRef: ViewContainerRef,
    protected modulesStateService: ModulesStateService,
    protected genericCrudService: GenericCrudService,
    protected entityDataStoreService: EntityDataStoreService,
    protected executorService: ExecutorService,
    protected genericElementValidationExecutionStepsFactory: GenericElementValidationExecutionStepsFactory,
    protected entityValidator: EntityValidator,
    protected userSession: UserSessionService,
    protected toolbarItemCheckService: ToolbarItemCheckService,
    protected jobContainerService: JobContainerService,
    protected stepFactory: ExecutionStepFactoryService,
    protected layoutService: LayoutService,
    protected questionnaireLayoutService: QuestionnaireLayoutService,
    protected questionnaireToolbarItemsService: QuestionnaireToolbarItemsService,
    protected permissionService: PermissionService,
    public cdr: ChangeDetectorRef
  ) {
    super(componentService, viewContainerRef, entityDataStoreService, modulesStateService, executorService,
      genericElementValidationExecutionStepsFactory, entityValidator, genericCrudService, userSession, permissionService,
      cdr);
  }

  public ngOnInit() {
    super.ngOnInit();

    this.onComponentInit();
  }

  public ngOnDestroy() {
    super.ngOnDestroy();

    this.onDestroyComponent();
  }

  public onComponentInit(): void {
    this.questionnaireService.questionnaireComponent = this;
    this.questionnaireService.executorService = this.executorService;
    this.questionnaireToolbarItemsService.questionnaireComponent = this;
    this.questionnaireLayoutService.questionnaireComponent = this;
    this.questionnaireToolbarItemsService.initToolbarItems();

    this.elementContext = this.createContext();

    this.modulesStateService.getCurrent().addContext(ModuleStateContext.Wizard);

    this.executorActionsService
      .registerModuleElementActions(this.moduleElement)
      .subscribe();

    this.questionnaire = this.extractQuestionnaire(
      Entity.getValue(this.entity, 'questionnaire') || Entity.getValueInEmbedded(this.entity, 'questionnaire')
    );

    this.layoutService.layoutSizeChanged$.subscribe(() => {
      setTimeout(() => { this.questionnaireLayoutService.setQuestionsContainerHeight(); }, 50);
    });
  }

  public onDestroyComponent(): void {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  public getSelectedEntity(): any {
    return this.selectedMasterEntity || null;
  }

  public recheckToolbarItems(): void {
    this.toolbarItemCheckService.check(this);
  }

  public onSave(): Observable<any> {
    console.log('SAVE');
    return observableOf(null);
  }

  public hasChanges(checkEmbedded: boolean = false): boolean {
    return false;
  }

  public onAfterSave(): Observable<any> {
    return observableOf(null);
  }

  public onChange(): Observable<any> {
    return observableOf(null);
  }

  public doValidate(): Observable<EntityValidatorStatus> {
    return observableOf({
      entity: null,
      isValid: true,
      error: '',
      errorFields: []
    });
  }

  public onRefresh(): Observable<any> {
    return observableOf(null);
  }

  public getToolbarItemsExtraParams() {
    return {
      'questionnaireComponent': this
    };
  }

  private createContext(): ElementContext {

    const elementContext = new ElementContext(
      this.moduleElement.id,
      ElementType.Questionnaire,
      this,
      this.moduleElement,
      true,
      false,
      false,
      false,
      false
    );

    return elementContext;
  }

  private extractQuestionnaire(aQuestionnaire: Questionnaire): Questionnaire {
    const questionnaire = Entity.extractEmbedded(aQuestionnaire);

    for (const question of questionnaire.questions) {
      if (question.questionParameters && question.questionParameters instanceof Array) {
        for (const questionParameter of question.questionParameters) {
          questionParameter.value = JSON.parse(questionParameter.value);
        }
      }
    }

    return questionnaire;
  }


}
