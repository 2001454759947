import { AbstractFormActionHandler } from './abstract-form-action-handler';
import { ReferenceAssociationFactorParameters } from '../../models/element-layout-global-fields';
import { FormViewerComponent } from '../../form-viewer.component';
export class CalculateReferenceAssociationFactorActionHandler extends AbstractFormActionHandler {
    constructor() {
        super(...arguments);
        this.referenceAssociationParameters = new ReferenceAssociationFactorParameters();
    }
    handleAction() {
        const form = this.getForm();
        let toFormControlField = null, toFormControlFieldName = null, toFormControlFieldComponent = null;
        if (this.isActionValid()) {
            if (this.checkAndApplyNewValuesOfReferenceAssociationProperties(this.getEntity())) {
                const toFormElementHashId = this.getParamValue('toFormElement'), toFieldElement = this.formService.getElementBy(form, 'objectHashId', toFormElementHashId);
                if (toFieldElement) {
                    toFormControlField = this.getFormGroup().get(toFieldElement.datamodelField + '_h_r_f_e_' + toFormElementHashId),
                        toFormControlFieldName = FormViewerComponent.getFormControlName(toFormElementHashId, toFieldElement.datamodelField),
                        toFormControlFieldComponent = this.formService.getComponent(toFormControlFieldName);
                }
                if (toFieldElement && this.referenceAssociationParameters.referenceAssociation
                    && this.referenceAssociationParameters.referenceAssociationType
                    && this.isProperReferenceAssociationType(this.getEntity())) {
                    this.formService.getGenericCrudService().customPost('phoenix/referenceassociations/2/referencefactory', this.referenceAssociationParameters)
                        .subscribe((elements) => {
                        if (elements && elements['referenceAssociationFactor']) {
                            this.referenceAssociationFactor = elements['referenceAssociationFactor'];
                        }
                        else {
                            this.referenceAssociationFactor = null;
                        }
                        if (toFieldElement && toFormControlField) {
                            toFieldElement.getEntity()[toFieldElement.datamodelField] = this.referenceAssociationFactor;
                            toFieldElement.setValue(this.referenceAssociationFactor);
                            toFormControlField.setValue(this.referenceAssociationFactor);
                            if (toFormControlFieldComponent) {
                                toFormControlFieldComponent.setValue(this.referenceAssociationFactor, false);
                            }
                            this.markElementForCheck(toFieldElement);
                        }
                    });
                }
                else {
                    this.referenceAssociationFactor = null;
                    if (toFieldElement && toFormControlField) {
                        toFieldElement.getEntity()[toFieldElement.datamodelField] = this.referenceAssociationFactor;
                        toFieldElement.setValue(this.referenceAssociationFactor);
                        toFormControlField.setValue(this.referenceAssociationFactor);
                        if (toFormControlFieldComponent) {
                            toFormControlFieldComponent.setValue(this.referenceAssociationFactor, false);
                        }
                        this.markElementForCheck(toFieldElement);
                    }
                }
            }
        }
    }
    isProperReferenceAssociationType(selectedEntity) {
        let workingMode = selectedEntity.workingMode ? selectedEntity.workingMode : null;
        if (selectedEntity._embedded && selectedEntity._embedded.workingMode) {
            workingMode = selectedEntity._embedded.workingMode;
        }
        return !(this.referenceAssociationParameters.referenceAssociationType
            && this.referenceAssociationParameters.referenceAssociationType === 2 && workingMode && workingMode.code === 'MONTAGE');
    }
    isActionValid() {
        return this.getParamValue('toFormElement')
            && this.getParamValue('hasCompanyAgreement')
            && this.getParamValue('referenceAssociation')
            && this.getParamValue('referenceAssociationType')
            && this.getParamValue('workingMode');
    }
    checkAndApplyNewValuesOfReferenceAssociationProperties(selectedEntity) {
        const hydrator = this.formService.getEntityHydrator();
        const hasCompanyAgreement = hydrator.getEntityPropertyValue(this.getEntity(), this.getParamValue('hasCompanyAgreement')), workingMode = hydrator.getEntityPropertyValue(this.getEntity(), this.getParamValue('workingMode')), referenceAssociation = hydrator.getEntityPropertyValue(this.getEntity(), this.getParamValue('referenceAssociation')), referenceAssociationType = hydrator.getEntityPropertyValue(this.getEntity(), this.getParamValue('referenceAssociationType'));
        let hasChanged = false;
        if (hasCompanyAgreement !== this.referenceAssociationParameters.hasCompanyAgreement && typeof hasCompanyAgreement === 'boolean') {
            this.referenceAssociationParameters.hasCompanyAgreement = hasCompanyAgreement;
            hasChanged = true;
        }
        if (workingMode !== this.referenceAssociationParameters.workingMode && typeof workingMode === 'number') {
            this.referenceAssociationParameters.workingMode = workingMode;
            hasChanged = true;
        }
        if (referenceAssociation !== this.referenceAssociationParameters.referenceAssociation && typeof referenceAssociation === 'number') {
            this.referenceAssociationParameters.referenceAssociation = referenceAssociation;
            hasChanged = true;
        }
        if (referenceAssociationType !== this.referenceAssociationParameters.referenceAssociationType
            && typeof referenceAssociationType === 'number') {
            this.referenceAssociationParameters.referenceAssociationType = referenceAssociationType;
            hasChanged = true;
        }
        return hasChanged;
    }
}
