<div *ngIf="isDataLoading">
  {{ 'COMMON.LOADING_PLEASE_WAIT' | translate }}
</div>

<div *ngIf="!isDataLoading && product">

  <div class="ui-grid-row">
    <div class="ui-grid-col-2">
      {{ 'COMMON.TYPE' | translate }}:
    </div>
    <div class="ui-grid-col-6">
      {{product.type}}
    </div>
  </div>

  <div class="ui-grid-row">
    <div class="ui-grid-col-2">
      {{ 'COMMON.DESCRIPTION' | translate }}:
    </div>
    <div class="ui-grid-col-6">
      {{product.description}}
    </div>
  </div>

  <div class="ui-grid-row">
    <div class="ui-grid-col-2">
      {{ 'COMMON.VERSION' | translate }}:
    </div>
    <div class="ui-grid-col-6">
      {{product.version}}
    </div>
  </div>

  <div class="ui-grid-row">
    <div class="ui-grid-col-2">
      {{ 'COMMON.PRICE' | translate }}:
    </div>
    <div class="ui-grid-col-6">
      {{product.price}}
    </div>
  </div>

  <div class="ui-grid-row">
    <div class="ui-grid-col-2">
      {{ 'COMMON.GROUP' | translate }}:
    </div>
    <div class="ui-grid-col-6">
      {{product.group}}
    </div>
  </div>

  <div class="ui-grid-row">
    <div class="ui-grid-col-2">
      {{ 'COMMON.GROUP' | translate }}:
    </div>
    <div class="ui-grid-col-6">
      {{product.group}}
    </div>
  </div>

  <div class="ui-grid-row">
    <div class="ui-grid-col-2">
      {{ 'COMMON.ENABLED' | translate }}:
    </div>
    <div class="ui-grid-col-6">
      {{product.enabled}}
    </div>
  </div>

</div>
