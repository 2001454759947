import { AbstractGridFilter } from './abstract-grid-filter';

export class GridFilterAutocomplete extends AbstractGridFilter {

  public onFocus(entity: any, event: any) {

  }

  protected parseRemoteMatchMode() {
    return AbstractGridFilter.MATCH_MODE_IN;
  }

  public parseRemoteValue() {
    let value = this.meta.value;

    if (value instanceof Array) {
      const remoteValue = [];

      for (const v of value) {
        remoteValue.push(v.id ? v.id : v);
      }

      value = remoteValue.join(',');
    }

    return value;
  }

  public parseLocalValue() {
    return AbstractGridFilter.MATCH_MODE_IN;
  }

  public isValid(): boolean {
    let isValid = this.meta.value;

    if (this.meta.value instanceof Array) {
      isValid = this.meta.value.length > 0;
    }

    return isValid;
  }
}
