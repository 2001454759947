import {Component, EventEmitter, OnInit, Output, Input} from '@angular/core';
import {Observable} from 'rxjs';
import {GenericCrudService} from '../../services/generic-crud.service';
import {Datamodel} from '../../services/datamodel/datamodel';

@Component({
  selector: 'app-datamodel-dropdown',
  templateUrl: './datamodel-dropdown.component.html'
})
export class DataModelDropdownComponent implements OnInit {

  @Input() public selectedDatamodelOption: SelectItem = null;
  @Input() public forceSelectionOnLoad = false;
  @Output() public onOptionChanged: EventEmitter<SelectItem> = new EventEmitter();

  public datamodelOptions: SelectItem[] = [];

  public constructor(
    protected readonly genericCrudService: GenericCrudService
  ) {
  }

  public ngOnInit(): void {

    this.loadDatamodels()
      .subscribe((datamodels: Datamodel[] = []) => {
        this.datamodelOptions = [];

        for (const datamodel of datamodels) {
          this.datamodelOptions = [...this.datamodelOptions, {
            label: datamodel.name,
            value: datamodel
          }];
        }

        if (this.forceSelectionOnLoad) {
          this.forceSelection();
        }
      });
  }

  public onDatamodelChanged(event?: {value: any}): void {
    this.onOptionChanged.emit(this.selectedDatamodelOption);
  }

  private loadDatamodels(): Observable<Datamodel[]> {
    return this.genericCrudService.getEntities('superadmin/datamodels');
  }

  private forceSelection(): void {
    for (const datamodelOption of this.datamodelOptions) {
      if (this.forceSelectionOnLoad && this.selectedDatamodelOption && this.selectedDatamodelOption.value &&
        this.selectedDatamodelOption.value.id === datamodelOption.value.id
      ) {
        this.selectedDatamodelOption = datamodelOption.value;
      }
    }
  }
}

