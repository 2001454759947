/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../../../../../../../node_modules/primeng/components/dialog/dialog.ngfactory";
import * as i2 from "primeng/components/dialog/dialog";
import * as i3 from "../../../../../../company-search/company-search.component.ngfactory";
import * as i4 from "../../../../../../company-search/company-search.component";
import * as i5 from "@angular/common";
import * as i6 from "./toolbar-item-company-search.component";
import * as i7 from "../../../../../../services/generic-crud.service";
import * as i8 from "../../../../../../../core/message/message-growl.service";
import * as i9 from "@ngx-translate/core";
import * as i10 from "../../../../../../company-search/services/company-communication.service";
var styles_ToolbarItemCompanySearch = [".dialog-close-button-container {\n      float: right;\n      cursor: pointer;\n      margin-top: -28px;\n    }\n\n      .ui-dialog-titlebar-maximize {\n      margin-right: 13px;\n    }"];
var RenderType_ToolbarItemCompanySearch = i0.ɵcrt({ encapsulation: 0, styles: styles_ToolbarItemCompanySearch, data: {} });
export { RenderType_ToolbarItemCompanySearch as RenderType_ToolbarItemCompanySearch };
function View_ToolbarItemCompanySearch_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 10, "p-dialog", [["appendTo", "body"], ["header", "Compass-Suche"], ["height", "auto"], ["showEffect", "fade"], ["width", "800"]], null, [[null, "visibleChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("visibleChange" === en)) {
        var pd_0 = ((_co.isDialogVisible = $event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i1.View_Dialog_0, i1.RenderType_Dialog)), i0.ɵdid(1, 180224, [[1, 4], ["dialog", 4]], 2, i2.Dialog, [i0.ElementRef, i0.Renderer2, i0.NgZone], { visible: [0, "visible"], header: [1, "header"], resizable: [2, "resizable"], modal: [3, "modal"], closable: [4, "closable"], responsive: [5, "responsive"], appendTo: [6, "appendTo"], baseZIndex: [7, "baseZIndex"], maximizable: [8, "maximizable"], width: [9, "width"], height: [10, "height"], minWidth: [11, "minWidth"] }, { visibleChange: "visibleChange" }), i0.ɵqud(603979776, 2, { headerFacet: 1 }), i0.ɵqud(603979776, 3, { footerFacet: 1 }), (_l()(), i0.ɵeld(4, 0, null, 1, 4, "div", [["class", "dialog-close-button-container ui-helper-clearfix ng-tns-c8-6"]], null, null, null, null, null)), (_l()(), i0.ɵeld(5, 0, null, null, 1, "a", [["class", "ng-tns-c8-6 ui-dialog-titlebar-close ui-corner-all"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onDialogMaximize($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(6, 0, null, null, 0, "span", [["class", "fa fa-fw fa-window-maximize"]], null, null, null, null, null)), (_l()(), i0.ɵeld(7, 0, null, null, 1, "a", [["class", "ng-tns-c8-6 ui-dialog-titlebar-close ui-corner-all"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onCloseDialog($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(8, 0, null, null, 0, "span", [["class", "dialog-close-button fa fa-fw fa-close"]], null, null, null, null, null)), (_l()(), i0.ɵeld(9, 0, null, 1, 1, "company-search", [], null, null, null, i3.View_CompanySearch_0, i3.RenderType_CompanySearch)), i0.ɵdid(10, 245760, null, 0, i4.CompanySearch, [i0.ChangeDetectorRef], { gridComponent: [0, "gridComponent"], customerImportEndpoint: [1, "customerImportEndpoint"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.isDialogVisible; var currVal_1 = "Compass-Suche"; var currVal_2 = false; var currVal_3 = true; var currVal_4 = false; var currVal_5 = false; var currVal_6 = "body"; var currVal_7 = 10000; var currVal_8 = false; var currVal_9 = "800"; var currVal_10 = "auto"; var currVal_11 = 400; _ck(_v, 1, 1, [currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8, currVal_9, currVal_10, currVal_11]); var currVal_12 = _co.gridComponent; var currVal_13 = _co.customerImportEndpoint; _ck(_v, 10, 0, currVal_12, currVal_13); }, null); }
export function View_ToolbarItemCompanySearch_0(_l) { return i0.ɵvid(2, [i0.ɵqud(671088640, 1, { dialog: 0 }), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ToolbarItemCompanySearch_1)), i0.ɵdid(2, 16384, null, 0, i5.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.isDialogVisible; _ck(_v, 2, 0, currVal_0); }, null); }
export function View_ToolbarItemCompanySearch_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "toolbar-item-company-search", [], null, null, null, View_ToolbarItemCompanySearch_0, RenderType_ToolbarItemCompanySearch)), i0.ɵdid(1, 245760, null, 0, i6.ToolbarItemCompanySearch, [i7.GenericCrudService, i8.MessageGrowlService, i9.TranslateService, i10.CompanyCommunicationService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ToolbarItemCompanySearchNgFactory = i0.ɵccf("toolbar-item-company-search", i6.ToolbarItemCompanySearch, View_ToolbarItemCompanySearch_Host_0, { params: "params" }, {}, []);
export { ToolbarItemCompanySearchNgFactory as ToolbarItemCompanySearchNgFactory };
