import { AbstractGridFilter } from './abstract-grid-filter';
import {FieldMetadataGrid} from '../../../../services/module/module-element-field-metadata-grid';
import {ChangeDetectorRefHelper} from '../../../../helpers/change-detector-ref.helper';

export class GridFilterDropdown extends AbstractGridFilter {

  public onFocus(entity: any, event: any) {
    this.component.getColumnBuilder()
      .loadOptions(this.field).subscribe((options: any[] = []) => {
        this.component.associatedFilterOptions[this.field.entityName] = [];

        if (this.field.filterType === FieldMetadataGrid.FILTER_TYPE_DROPDOWN) {
          this.component.associatedFilterOptions[this.field.entityName] = [{ label: '---', value: null }];
        }

        this.component.associatedFilterOptions[this.field.entityName] =
          this.component.associatedFilterOptions[this.field.entityName].concat(options);

        ChangeDetectorRefHelper.detectChanges(this.component);
      });
  }

  protected parseRemoteMatchMode() {
    let remoteMode = AbstractGridFilter.MATCH_MODE_IN;

    /*if (this.meta.value && this.meta.value instanceof Array) {
      remoteMode = AbstractGridFilter.MATCH_MODE_MANY_IN;
    }*/

    return remoteMode;
  }

  public parseRemoteValue() {
    let value = this.meta.value;

    if (value instanceof Array) {
      value = this.meta.value.join(',');
    }

    return value;
  }

  public parseLocalValue() {
    return AbstractGridFilter.MATCH_MODE_IN;
  }

  public isValid(): boolean {
    let isValid = this.meta.value;

    if (this.meta.value instanceof Array) {
      isValid = this.meta.value.length > 0;
    }

    return isValid;
  }
}
