import {ChangeDetectionStrategy, Component} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {MessageGrowlService} from '../../../../../../../core/message/message-growl.service';
import {ExecutionStepFactoryService} from '../../../../../../../core/executor/factory/execution-step-factory.service';
import {ExecutorService} from '../../../../../../../core/executor/executor.service';
import {AbstractGenericGridComponent} from '../../../../abstract-generic-grid.component';
import {Entity} from '../../../../../../helpers/entity';
import {ToolbarItemSave} from '../save/toolbar-item-save.component';
import {ToolbarItemSaveService} from '../../services/toolbar-item-save.service';
import {GenericCrudBulkService} from '../../../../../../services/generic-crud-bulk.service';
import {ComponentService} from '../../../../../services/component-highlight-stack.service';
import {MessageService} from '../../../../../../../core/message/message.service';
import {ModulesStateService} from '../../../../../services/modules-state.service';
import {GenericDialogModuleService} from '../../../../generic-dialog/service/generic-dialog-module.service';
import {ToastService} from '../../../../../../../core/service/toast.service';
import {EntityManagerService} from '../../../../../../../core/service/entity-manager/entity-manager.service';
import {EntityStatus} from '../../../../../../services/entity/entity-status';

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'app-toolbar-item-save-and-trigger-workflow',
    template: '',
    providers: [
      ToolbarItemSaveService,
      ExecutorService
    ]
})
export class ToolbarItemSaveAndTriggerWorkflowComponent extends ToolbarItemSave {

  constructor(
    protected genericCrudBulkService: GenericCrudBulkService,
    protected messageGrowlService: MessageGrowlService,
    protected componentService: ComponentService,
    protected translateService: TranslateService,
    protected messageService: MessageService,
    protected toolbarItemSaveService: ToolbarItemSaveService,
    protected executorService: ExecutorService,
    protected executionStepFactoryService: ExecutionStepFactoryService,
    protected modulesStateService: ModulesStateService,
    protected genericDialogModuleService: GenericDialogModuleService,
    protected toastService: ToastService,
    protected entityManager: EntityManagerService
  ) {
    super(
      genericCrudBulkService,
      messageGrowlService,
      componentService,
      translateService,
      messageService,
      toolbarItemSaveService,
      executorService,
      executionStepFactoryService,
      modulesStateService,
      genericDialogModuleService,
      toastService
    );
  }

  public click() {
    const component = this.getComponent();

    if (component instanceof AbstractGenericGridComponent) {
      const entity = component.getSelectedEntity();
      Entity.setValue(entity, 'isWorkflowReady', true);
      this.entityManager.persist(entity, {property: 'isWorkflowReady', newValue: true, force: true});
      this.entityManager.persist(entity, {property: EntityStatus.ENTITY_CHANGED_FLAG, newValue: true, force: true});
      super.click();
    }
  }
}
