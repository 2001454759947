
import {refCount, publishReplay, catchError, map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';


import { Template } from './template';
import { AppHttpService } from '../../../app-http.service';
import { GenericCrudService } from '../generic-crud.service';

@Injectable()
export class TemplateCrudService extends AppHttpService {

  private templatesUrl: string = 'superadmin/templates';

  constructor(private genericCrudService: GenericCrudService) {
    super();
  }

  getTemplates(): Observable<Template[]> {
    return this.genericCrudService.get(this.templatesUrl).pipe(
      publishReplay(1),
      refCount(),
      catchError(this.handleError));
  }

  deleteTemplate(templateId: number): Observable<Object> {
    if (templateId > 0) {
      return this.genericCrudService.deleteEntity(`${this.templatesUrl}/${templateId}`);
    } else {
      throw new Error('Invalid template id given.');
    }
  }

  createTemplate(template: Template): Observable<Template> {
    if (template) {
      return this.genericCrudService.createEntity(this.templatesUrl, template).pipe(
        map((json) => { return <Template>json; }));
    } else {
      throw new Error('No template given.');
    }
  }

  editTemplate(template: Template): Observable<Template> {
    if (template) {
      return this.genericCrudService.editEntity(`${this.templatesUrl}/${template.id}`, template).pipe(
        map((json) => { return <Template>json; }));
    } else {
      throw new Error('No template given.');
    }
  }
}
