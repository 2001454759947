import {ChangeDetectorRef, Component, ElementRef, Injector, OnDestroy} from '@angular/core';
import {AbstractGridColumnComponent} from '../abstract-grid-column.component';
import {DoubleClickService} from '../../../../services/double-click.service';
import {takeUntil} from 'rxjs/operators';
import {FileHelper} from '../../../../../helpers/file-helper';
import {GenericCrudService} from '../../../../../services/generic-crud.service';
import {ChangeDetectorRefHelper} from '../../../../../helpers/change-detector-ref.helper';
import {Subject} from 'rxjs';
import {WindowService} from '../../../../../../core/service/window.service';
import {environment} from '../../../../../../../environments';

@Component({
  selector: 'app-open-file-module-column',
  templateUrl: `./open-file-module-column.component.html`,
  styles: [`
    @keyframes ui-progress-spinner-color {
      100%,
      0% {
        stroke: #111;
      }
    }
  `]
})
export class OpenFileModuleColumnComponent extends AbstractGridColumnComponent implements OnDestroy {

  public isLoading = false;
  public unsubscribe = new Subject<void>();

  public constructor(
    public cdr: ChangeDetectorRef,
    protected elementRef: ElementRef,
    protected doubleClickService: DoubleClickService,
    protected genericCrudService: GenericCrudService,
    protected injector: Injector
  ) {
    super(elementRef);
  }

  public ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  public doOpenModule() {
    const targetModule = this.entity && this.showPreview(this.entity, 'pdf') ?
      this.getConfigValue('targetModulePdf') :
      this.getConfigValue('targetModuleImage');

    this.component.triggerEditEntityActions();
    const height = this.getConfigValue('dialogHeight'),
      width = this.getConfigValue('dialogWidth');

    const file = this.entity.dmsFile ? this.entity.dmsFile : this.entity;

    this.doubleClickService.setComponent(this.component)
      .setDialogOptions({
        height: height || 300,
        width: width || 600,
        isModal: true,
        maximized: false
      })
      .handleOpenModule(this.component, 'dialog', targetModule, file);
  }

  public doDownload() {
    this.isLoading = true;

    const file = this.entity.dmsFile ? this.entity.dmsFile : this.entity;

    this.genericCrudService.download('phoenix/files/download/' + file.id, {
      fileType: file.fileType,
      fileClassName: file.fqn
    })
      .pipe(
        takeUntil(this.unsubscribe),
      ).subscribe((data) => {
      FileHelper.download({
        content: data,
        fileType: file.fileType,
        name: file.name
      })
      this.isLoading = false;
      ChangeDetectorRefHelper.detectChanges(this);
    });
  }

  public doOpenFile() {
    if (this.entity && this.entity.contentUrl) {
      this.injector.get<WindowService>(WindowService).replace('dmsFile', {
        url: environment.baseUrl + '/' + this.entity.contentUrl,
        target: '_blank',
        features: 'toolbar=0,location=0,menubar=0'
      })
    }
  }

  public showOpenFile(): boolean {
    return this.getConfigValue('useOpenFile');
  }

  public showPreview(entity, type: string) {
    const file = entity.dmsFile ? entity.dmsFile : entity;
    if (typeof file.fileType !== 'undefined') {
      return type === 'pdf' ? file.fileType.includes('pdf') : file.fileType.includes('image');
    }

    if (typeof file.fileName !== 'undefined') {
      return type === 'pdf' ? file.fileName.includes('.pdf') : (file.fileName.includes('.jpg') || file.fileName.includes('.png') || file.fileName.includes('.jpeg'));
    }

    return false;
  }
}
