import {Component, OnInit} from '@angular/core';
import {AbstractQuestionComponent} from '../abstract-question-component';
import {GenericCrudService} from '../../../../../../../services/generic-crud.service';

@Component({
  selector: 'app-questionnaire-question-radio',
  template: `
    <div class="ui-g">
        <div class="ui-g-8">
          Radio Type
        </div>
    </div>
  `,
  styles: [`
    :host {
      width: 100%;
    }
  `]
})
export class RadioQuestionComponent extends AbstractQuestionComponent implements OnInit {

  public constructor(
    protected genericCrudService: GenericCrudService
  ) {
    super();
  }

  public onComponentInit(): void {

  }
}
