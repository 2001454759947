import {RunnableEventRegistry} from '../../../../core/job-runner/type/runnable-event.registry';
import {AbstractCondition} from '../../../../core/job-runner/condition/abstract.condition';
import {JobContext} from '../../../../core/job-runner/context/job.context';
import {GenericToolbarComponent} from '../../../content-renderer/elements/generic-toolbar/generic-toolbar.component';

export class SetColorToInquiryPositionGridToolbarItemsCondition extends AbstractCondition {
  isApplicable(context: JobContext): boolean {
    const toolbarComponent = context.component;
    if (context.event === RunnableEventRegistry.ToolbarItemEntityChanged
      && toolbarComponent instanceof GenericToolbarComponent
      && toolbarComponent.getAttachedComponent() &&
      toolbarComponent.getAttachedComponent().element.id === this.getPayload()['component'].element.id) {
      return true;
    }

    return false;
  }
}
