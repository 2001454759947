import {Observable, of as observableOf} from 'rxjs';
import {Component} from '@angular/core';
import {ExecutorService} from '../../../../../core/executor/executor.service';
import {GenericElementAbstract} from '../../generic-element-abstract.component';
import {EntityValidatorStatus} from '../../../../validators/services/entity-validator';
import {GenericElementValidationExecutionStepsFactory} from '../../../services/generic/generic-element-validation-execution-steps-factory';

@Component({
  selector: 'app-custom-staff-cockpit',
  styleUrls: ['./staff-cockpit.component.scss'],
  templateUrl: './staff-cockpit.component.html',
  providers: [
    ExecutorService,
    GenericElementValidationExecutionStepsFactory
  ]
})
export class StaffCockpitComponent extends GenericElementAbstract {
  public toolbarContextName = 'staffCockpitTableComponent';

  public onComponentInit(): void {

  }

  public onDestroyComponent(): void {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  public getSelectedEntity(): any {
    return this.selectedMasterEntity || null;
  }

  public onSave(): Observable<any> {
    return observableOf(null);
  }

  public recheckToolbarItems(): void {

  }

  public hasChanges(checkEmbedded: boolean = false): boolean {
    return false;
  }

  public onAfterSave(): Observable<any> {
    return observableOf(null);
  }

  public onChange(): Observable<any> {
    return observableOf(null);
  }

  public doValidate(): Observable<EntityValidatorStatus> {
    return observableOf({
      entity: null,
      isValid: true,
      error: '',
      errorFields: []
    });
  }

  public onRefresh(): Observable<any> {
    return observableOf(null);
  }

  public getToolbarItemsExtraParams() {
    return {
      'staffCockpitComponent': this
    };
  }
}

