import {Injectable} from '@angular/core';
import {ModulesStateService} from '../../../../services/modules-state.service';
import {MessageService} from 'app/core/message/message.service';
import {MessageGrowlService} from '../../../../../../core/message/message-growl.service';
import {ExecutorService} from '../../../../../../core/executor/executor.service';
import {ExecutionStepBuilderService} from '../../../../../../core/executor/builder/execution-step-builder.service';
import {ExecutionStatusSuccess} from '../../../../../../core/executor/execution-status-success';
import {TranslateService} from '@ngx-translate/core';
import {ComponentValidationExecutionStep} from '../../../../../services/execution-step/validation/component-validation-execution-step';
import {ExecutionStatusError} from '../../../../../../core/executor/execution-status-error';
import {EntityValidationExecutionStep} from 'app/shared/services/execution-step/validation/entity-validation-execution-step';
import {ExecutionStatus} from 'app/core/executor/execution-status';
import {GenericElementAbstract} from '../../../generic-element-abstract.component';
import {ExecutorActionEvent} from '../../../../../../core/executor/service/executor-actions/executor-action-event';
import {TOAST_LIFE_INFINITE, ToastComponentsRegistry, ToastService} from '../../../../../../core/service/toast.service';
import {ComponentValidationOnSaveExecutionStep} from '../../../../../services/execution-step/validation/component-validation-on-save-execution-step';

@Injectable()
export class ToolbarItemSaveService {

  private executorService: ExecutorService = null;
  private component: GenericElementAbstract = null;

  public constructor(
    private modulesStateService: ModulesStateService,
    private messageService: MessageService,
    private messageGrowlService: MessageGrowlService,
    private translateService: TranslateService,
    private executionStepBuilderService: ExecutionStepBuilderService,
    private toastService: ToastService
  ) {

  }

  public saveModule() {
    const mainModule = this.modulesStateService.getByComponent(this.component);

    if (null !== mainModule) {
      const executionSteps = this.executionStepBuilderService.createExecutionSteps(mainModule);

      for (const step of executionSteps) {
        this.executorService.addStep(step);
      }

      this.toastService.custom(ToastComponentsRegistry.PROGRESS_BAR, {
        severity: 'info',
        life: TOAST_LIFE_INFINITE,
        closable: false,
        summary: this.translateService.instant('COMMON.SAVING_PLEASE_WAIT')
      });
      this.executorService.execute()
        .subscribe((status: ExecutionStatus) => {
          this.toastService.clear(ToastComponentsRegistry.PROGRESS_BAR, 300);

          if (status.isSuccess()) {
            this.onExecutorSuccess(status);
          } else {
            this.onExecutorFailure(status);
          }
        });
    }
  }

  public setExecutorService(executorService: ExecutorService): this {
    this.executorService = executorService;
    return this;
  }

  public setComponent(component: GenericElementAbstract): this {
    this.component = component;
    return this;
  }

  public onExecutorSuccess(status: ExecutionStatusSuccess): void {
    this.component.executeAction(ExecutorActionEvent.ComponentSaved, this.component).subscribe();

    this.messageGrowlService.success(
      this.translateService.instant('COMMON.DATA_SAVED'),
      this.translateService.instant('COMMON.SUCCESS')
    );

    this.component.recheckToolbarItems();
  }

  public onExecutorFailure(status: ExecutionStatusError): void {
    if (status.getStep() instanceof ComponentValidationExecutionStep ||
      status.getStep() instanceof EntityValidationExecutionStep ||
      status.getStep() instanceof ComponentValidationOnSaveExecutionStep
    ) {
      this.onComponentValidationFailure(status);
    }
  }

  public onComponentValidationFailure(status: ExecutionStatusError): void {
    this.messageService.confirm({
      acceptVisible: true,
      rejectVisible: false,
      header: this.translateService.instant('COMMON.ERROR'),
      message: status.getStepContent(),
      accept: () => { }
    });
  }
}
