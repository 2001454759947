/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./toolbar-item-ca-contract-of-employment.component";
import * as i2 from "../../../../../../services/component-highlight-stack.service";
import * as i3 from "../../../../../../../../core/message/message-growl.service";
import * as i4 from "../../../../../../../services/generic-crud.service";
var styles_ToolbarItemCaContractOfEmploymentComponent = [];
var RenderType_ToolbarItemCaContractOfEmploymentComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_ToolbarItemCaContractOfEmploymentComponent, data: {} });
export { RenderType_ToolbarItemCaContractOfEmploymentComponent as RenderType_ToolbarItemCaContractOfEmploymentComponent };
export function View_ToolbarItemCaContractOfEmploymentComponent_0(_l) { return i0.ɵvid(2, [], null, null); }
export function View_ToolbarItemCaContractOfEmploymentComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "toolbar-item-ca-contract-of-employment", [], null, null, null, View_ToolbarItemCaContractOfEmploymentComponent_0, RenderType_ToolbarItemCaContractOfEmploymentComponent)), i0.ɵdid(1, 245760, null, 0, i1.ToolbarItemCaContractOfEmploymentComponent, [i2.ComponentService, i3.MessageGrowlService, i4.GenericCrudService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ToolbarItemCaContractOfEmploymentComponentNgFactory = i0.ɵccf("toolbar-item-ca-contract-of-employment", i1.ToolbarItemCaContractOfEmploymentComponent, View_ToolbarItemCaContractOfEmploymentComponent_Host_0, { params: "params" }, {}, []);
export { ToolbarItemCaContractOfEmploymentComponentNgFactory as ToolbarItemCaContractOfEmploymentComponentNgFactory };
