import { of as observableOf } from 'rxjs';
import { GenericElementAbstract } from '../../generic-element-abstract.component';
export class StaffCockpitComponent extends GenericElementAbstract {
    constructor() {
        super(...arguments);
        this.toolbarContextName = 'staffCockpitTableComponent';
    }
    onComponentInit() {
    }
    onDestroyComponent() {
        this.subscriptions.forEach(s => s.unsubscribe());
    }
    getSelectedEntity() {
        return this.selectedMasterEntity || null;
    }
    onSave() {
        return observableOf(null);
    }
    recheckToolbarItems() {
    }
    hasChanges(checkEmbedded = false) {
        return false;
    }
    onAfterSave() {
        return observableOf(null);
    }
    onChange() {
        return observableOf(null);
    }
    doValidate() {
        return observableOf({
            entity: null,
            isValid: true,
            error: '',
            errorFields: []
        });
    }
    onRefresh() {
        return observableOf(null);
    }
    getToolbarItemsExtraParams() {
        return {
            'staffCockpitComponent': this
        };
    }
}
