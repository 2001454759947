<div *ngIf="entities" class="employee-overview">
  <table *ngFor="let user of entities | keyvalue" class="margin-5">
    <th [colSpan]="colspanLength(user)">{{user.value.firstName}} {{user.value.lastName}}</th>
    <tr>
      <td *ngFor="let customer of user.value.customers | keyvalue" class="width-200 align-top">
        <table class="fullWidth">
          <th>        {{customer.value.primaryName}}
          </th>
          <tr *ngFor="let leasedEmployee of customer.value.employees | keyvalue">
            <td [ngStyle]="{background: leasedEmployee.value.color}" class="employee">
              <span class="title">{{leasedEmployee.value.firstName}} {{leasedEmployee.value.lastName}}</span>
              <br>
              <span class="date">{{leasedEmployee.value.contractEntryDate.date | date:'dd.MM.yyyy'}}</span>
            </td>
          </tr>
        </table>
      </td>
    </tr>
  </table>

  <table class="margin-5 legend-table">
    <th [colSpan]="3">
      Legend
    </th>
      <tr>
        <td>AKÜ</td>
        <td>AIIGEW</td>
        <td class="width-200">Farbe</td>
      </tr>
      <tr *ngFor="let instance of legendData">
        <td>{{ instance.aku }}</td>
        <td>{{ instance.aiigew }}</td>
        <td [ngStyle]="{background: instance.color}"></td>
      </tr>

  </table>
</div>



