<form class="placeholder-label" [formGroup]="form" (ngSubmit)="onSubmit()">
  <div class="ui-g-12 form-input">
    <p-dropdown
      [filter]="true"
      [options]="customerOptions"
      (onChange)="onCustomerChange($event)"
      [style]="{'width': '100%'}"
      appendTo="body"
      placeholder="---"
      formControlName="customer"
    ></p-dropdown>
  </div>
  <div class="ui-g-12 form-input">
    <textarea
      pInputTextarea
      formControlName="internalNotes"
    ></textarea>
  </div>

  <div class="ui-g-12 form-input">
    <button pButton type="submit" label="{{ 'COMMON.SAVE' | translate }}" [disabled]="!form.valid"></button>
  </div>
</form>
