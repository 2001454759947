<div class="activity-details-table-wrapper ui-g-12" *ngFor="let table of dataCustomer; let i = index">
  <div class="ui-g-12 title-name"><h3>{{ table.customerActivityEntities[0].plan}}</h3></div>
  <div class="ui-g-6">
    <div class="ui-g-12">

      <div class="ui-g-12" *ngIf="table.customerActivityEntities.length > 2">
        <h3>
          {{ 'USER_ACTIVITY_REPORT.ANALYSIS_CUSTOMER' | translate }}:
        </h3>
      </div>
      <div class="ui-g-12" *ngIf="table.customerActivityEntities.length > 2">
        <shared-dynamic-table
          [dataKey]="'title'"
          [columns]="table.customerActivityColumns"
          [parentComponent]="this"
          [cells]="table.cellsCustomer"
          [lazy]="false"
          [rowsCount]="9999"
          [entities]="table.customerActivityEntities"
          [isLoadingData]="isLoading"
          [showFilters]="false"
          [paginator]="false"
        ></shared-dynamic-table>
      </div>
    </div>
  </div>
  <div class="ui-g-6">
    <div class="ui-g-12">
      <div class="ui-g-12" *ngIf="dataEmployee[i].employeeActivityEntities.length > 2">
        <h3>
          {{ 'USER_ACTIVITY_REPORT.ANALYSIS_EMPLOYEE' | translate }}:
        </h3>
      </div>
      <div class="ui-g-12" *ngIf="dataEmployee[i].employeeActivityEntities.length > 2">
        <shared-dynamic-table
          [dataKey]="'title'"
          [columns]="dataEmployee[i].employeeActivityColumns"
          [parentComponent]="this"
          [cells]="dataEmployee[i].cellsEmployee"
          [lazy]="false"
          [rowsCount]="9999"
          [entities]="dataEmployee[i].employeeActivityEntities"
          [isLoadingData]="isLoading"
          [showFilters]="false"
          [paginator]="false"
        ></shared-dynamic-table>
      </div>
    </div>
  </div>
</div>
