import {ToolbarItemOpenModuleUseMasterEntityExecutionStep} from './toolbar-item-open-module-use-master-entity-execution-step';
import {ElementContext} from '../../content-renderer/services/ElementContext';
import {AbstractGenericGridComponent} from '../../content-renderer/elements/abstract-generic-grid.component';
import {GenericTurboGridComponent} from '../../content-renderer/elements/generic-turbo-grid/generic-turbo-grid.component';

export class ToolbarItemOpenModuleUseMasterEmptyEntityExecutionStep extends ToolbarItemOpenModuleUseMasterEntityExecutionStep {
  protected getEntity(context: ElementContext) {
    if (context.component instanceof GenericTurboGridComponent) {
      context.component.startNewEntityAdd(context.component.emptyEntity);

      return context.component.getSelectedEntity();
    }
    return context.component.emptyEntity ? context.component.emptyEntity : context.component.emptyEntity
  }
}
