<div *ngIf="questionnaire" #questionnaireContainer>

  <div *ngIf="questionnaire" style="height: 100%" fxLayoutAlign="start stretch" fxLayout="column">

    <div fxFlex="none" *ngIf="toolbarItems && toolbarItems.length > 0">
      <p-toolbar styleClass="no-round-corner">
        <app-generic-toolbar [params]="getToolbarItemsExtraParams()" [entity]="entity" [items]="toolbarItems"></app-generic-toolbar>
      </p-toolbar>
    </div>

    <h2 fxFlex="nogrow" class="questionnaire-header">
      {{questionnaire.name}}
    </h2>

    <div fxFlex="grow" class="ui-dataview-content ui-widget-content questions-container">

      <div *ngFor="let question of questionnaire.questions" class="ui-g questions-container">

        <div class="ui-g-12">
          <h3>{{question.title}}</h3>
        </div>

        <div *ngIf="question.subTitle" class="ui-g-12">
          <span>{{question.subTitle}}</span>
        </div>

        <div class="question-configuration-container ui-g-12" [ngSwitch]="question.questionType.code">
          <app-custom-questionnaire-question
            [questionnaire]="questionnaire"
            [question]="question"
            [questionnaireComponent]="this"
          ></app-custom-questionnaire-question>
        </div>
      </div>

    </div>

    <div fxFlex="none" *ngIf="statusBarItems && statusBarItems.length > 0">
      <p-toolbar styleClass="no-round-corner">
        <app-generic-toolbar [params]="getToolbarItemsExtraParams()" [entity]="entity" [items]="statusBarItems"></app-generic-toolbar>
      </p-toolbar>
    </div>
  </div>
</div>

<div *ngIf="!questionnaire" id="outer-info">
  <div id="inner-info">
    No questionnaire for given element
  </div>
</div>

