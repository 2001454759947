import {AbstractFormActionHandler} from '../abstract-form-action-handler';
import {GenericElementAbstract} from '../../../../content-renderer/elements/generic-element-abstract.component';
import {ElementContext} from '../../../../content-renderer/services/ElementContext';
import {FormViewerComponent} from '../../../form-viewer.component';

export class SetupInvoicePossibilityFormActionHandler extends AbstractFormActionHandler {

  public handleAction(): void {
    const invoicePossibility = this.getEntity();

    if (this.isValid(invoicePossibility)) {
      this.setupWhenOfferIsMaster();
      this.setupWhenOrderIsMaster();
    }
  }

  private isValid(invoicePossibility): boolean {
    return invoicePossibility && invoicePossibility.fqn === 'PhoenixBundle\\Entity\\InvoicePossibility';
  }

  private setupWhenOfferIsMaster(): void {
    this.setupWhenThereIsMaster('PhoenixBundle\\Entity\\Offer');
  }

  private setupWhenOrderIsMaster(): void {
    this.setupWhenThereIsMaster('PhoenixBundle\\Entity\\Order');
  }

  private setupWhenThereIsMaster(masterFqn: string): void {
    const entity = this.getEntity();

    const component: FormViewerComponent = this.getComponent(),
      masterElementContext: ElementContext = component ? component.masterElementContext : null,
      masterElementContextEntity = masterElementContext && masterElementContext.component &&
      masterElementContext.component instanceof GenericElementAbstract ?
        masterElementContext.component.getSelectedEntity() :
        null;

    if (masterElementContextEntity && masterElementContextEntity.fqn === masterFqn &&
      masterElementContextEntity.customer
    ) {
      entity._embedded = {...entity._embedded, ...{
          customer: masterElementContextEntity.customer
        }};

      component.setEntity({...entity, ...{
          customer: masterElementContextEntity.customer
        }});

      component.emptyEntity = {...component.emptyEntity, ... {
          customer: masterElementContextEntity.customer
        }};
    }
  }
}
