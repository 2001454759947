import { AfterViewInit } from '@angular/core';
import { GenericTurboGridComponent } from '../../generic-turbo-grid/generic-turbo-grid.component';
export class WorkHourDocuwareGridComponent extends GenericTurboGridComponent {
    constructor() {
        super(...arguments);
        this.elementType = 14 /* WorkHourDocuwareGrid */;
    }
    ngAfterViewInit() {
        const workHourGrid = this.getWorkHourGrid();
        if (workHourGrid) {
            const filterComponent = workHourGrid.filterComponent;
            if (filterComponent) {
                filterComponent.onSearchExecuted.subscribe(() => {
                    this.filterGrid(workHourGrid);
                    this.entities = [];
                    if (this.isGridFilterDefined('docuwareDocument.leasedEmployee')) {
                        this.loadEntities().subscribe();
                    }
                });
            }
        }
    }
    onRefresh() {
        const workHourGrid = this.getWorkHourGrid();
        this.entities = [];
        if (workHourGrid && this.isGridFilterDefined('docuwareDocument.leasedEmployee')) {
            workHourGrid.loadEntities().subscribe();
        }
        return super.onRefresh();
    }
    getWorkHourGrid() {
        const moduleState = this.modulesStateService.getByComponent(this);
        if (moduleState) {
            const components = moduleState.getComponents();
            for (const component of components) {
                const context = component.getElementContext();
                if (context && context.type === 13 /* WorkHourGrid */) {
                    return component;
                }
            }
        }
        return null;
    }
    filterGrid(workHourGrid) {
        if (workHourGrid && workHourGrid.gridFilters) {
            this.removeGridFilter('docuwareDocument.leasedEmployee');
            this.removeGridFilter('validityYear');
            this.removeGridFilter('validityMonth');
            this.addLeasedEmployeeFilter(workHourGrid);
            this.addWorkMonthFilter(workHourGrid);
            this.addWorkYearFilter(workHourGrid);
        }
    }
    removeGridFilter(filterName) {
        const filterIndex = this.staticFilters.findIndex((aFilter) => {
            return aFilter.field === filterName;
        });
        if (filterIndex !== -1) {
            this.staticFilters.splice(filterIndex, 1);
        }
    }
    addLeasedEmployeeFilter(workHourGrid) {
        const leasedEmployees = workHourGrid.gridFilters.leasedEmployee && workHourGrid.gridFilters.leasedEmployee.value ?
            workHourGrid.gridFilters.leasedEmployee.value : [];
        if (leasedEmployees.length > 0) {
            const ids = [];
            for (const leasedEmployee of leasedEmployees) {
                if (leasedEmployee.id) {
                    ids.push(leasedEmployee.id);
                }
            }
            this.embeddedFields = [...this.embeddedFields, 'docuwareDocument'];
            this.staticFilters.push({
                field: 'docuwareDocument.leasedEmployee',
                value: 'in:' + ids.join(',')
            });
        }
    }
    addWorkMonthFilter(workHourGrid) {
        let workMonth = workHourGrid.gridFilters.workMonth && workHourGrid.gridFilters.workMonth.value ?
            workHourGrid.gridFilters.workMonth.value : null;
        if (workMonth !== null) {
            workMonth = +workMonth;
            this.staticFilters.push({
                field: 'validityMonth',
                value: 'equal:' + workMonth
            });
        }
    }
    addWorkYearFilter(workHourGrid) {
        let workYear = workHourGrid.gridFilters.workYear && workHourGrid.gridFilters.workYear.value ?
            workHourGrid.gridFilters.workYear.value : null;
        if (workYear !== null) {
            workYear = +workYear;
            this.staticFilters.push({
                field: 'validityYear',
                value: 'equal:' + workYear
            });
        }
    }
    isGridFilterDefined(filterName) {
        const filterIndex = this.staticFilters.findIndex((aFilter) => {
            return aFilter.field === filterName;
        });
        return filterIndex !== -1;
    }
}
