import {FormViewerComponent} from '../../../../../../../form-viewer/form-viewer.component';
import {ElementInput, ElementInputValidator} from '../../../../../../../form-viewer/models/element';
import {TranslateService} from '@ngx-translate/core';

const VALID_FROM_FIELD = 'validFrom';
const CONTRACT_ENTRY_DATE_FIELD = 'contractEntryDate';

export class ContractOfEmploymentValidFromService {

  private form: FormViewerComponent = null;
  private translate: TranslateService = null;

  public setForm(form: FormViewerComponent): this {
    this.form = form;
    return this;
  }

  public setTranslate(translate: TranslateService): this {
    this.translate = translate;
    return this;
  }

  public setupValidation(): void {
    const entity = this.form.getSelectedEntity();

    if (entity && entity.fqn) {
      this.doSetValidator(entity.fqn);
    }
  }

  public doSetValidator(fqn: string): void {
    const element = this.getContractEntryDateElement();

    if (element && !element.isHidden) {
      const validatorIndex = element.validators.findIndex((aValidator: ElementInputValidator) => {
        return aValidator.key === ElementInputValidator.DATE_SMALLER_THAN;
      });

      if (validatorIndex !== -1) {
        element.validators.splice(validatorIndex, 1);
      }

      this.form.getValidator().removeFormValidations(fqn, element);

      element.validationsError = this.translate.instant('VALIDATON_MESSAGE.ERRORS.CONTRACT_ENTRY_DATE_MUST_BE_SMALLER_THAN_VALID_FROM');
      element.validators.push({
        key: ElementInputValidator.DATE_SMALLER_THAN,
        params: [
          {'value': VALID_FROM_FIELD},
          {'value': true}
        ]
      });

      this.form.getValidator().addFormValidations(fqn, element);
    }
  }

  public getContractEntryDateElement(): ElementInput|null {
    return this.form.inputElements.find((aElement: ElementInput) => {
      return aElement.datamodelField === CONTRACT_ENTRY_DATE_FIELD;
    });
  }
}
