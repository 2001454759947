import {Component} from '@angular/core';
import {ToolbarItemWorkingModelTimeDecimalSwitchComponent} from './toolbar-item-working-model-time-decimal-switch.component';

@Component({
  selector: 'app-toolbar-item-working-model-time-switch',
  template: ``,
  providers: []
})
export class ToolbarItemSelectedWorkingModelDayDecimalSwitchComponent extends ToolbarItemWorkingModelTimeDecimalSwitchComponent {

  public managingColumns: any = {
    start:                      {index: 1, industrialTime: false},
    startDecimal:               {index: 2, industrialTime: true},
    end:                        {index: 3, industrialTime: false},
    endDecimal:                 {index: 4, industrialTime: true},
    break:                      {index: 5, industrialTime: false},
    breakDecimal:               {index: 6, industrialTime: true},
    plannedWorkingTime:         {index: 7, industrialTime: false},
    plannedWorkingTimeDecimal:  {index: 8, industrialTime: true},
    overallWorkingTime:         {index: 8, industrialTime: false},
    overallWorkingTimeDecimal:  {index: 8, industrialTime: true}
  };
}
