import {GenericCrudService} from '../../../../../services/generic-crud.service';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {Entity} from '../../../../../helpers/entity';

export class ArticleAutocompleteHandler {

  public constructor(
    private genericCrudService: GenericCrudService,
    private isWageArticle?: boolean,
    private isInvoiceArticle?: boolean
  ) {

  }

  public getData(query: string): Observable<any[]> {
    const route = `phoenix/articles/offset/0/limit/30/orderby/id/asc?search=${query}`,
      requestParams = {
        clause: 'orWhere',
        isInvoiceArticle: this.isInvoiceArticle,
        isWageArticle: this.isWageArticle
      };

    return this.genericCrudService.getEntities(route, '', requestParams)
      .pipe(map((paginated: {data: any[]}) => {
        const data = paginated.data;

        for (const type of data) {
          type.label = `${Entity.getValue(type, 'name')} (${Entity.getValue(type, 'code')})`;
        }

        return data;
      }));
  }
}
