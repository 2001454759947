import { AbstractValidator } from './abstract-validator';

export class DateValueBiggerThanValidator extends AbstractValidator {

  public isValid(value: any): boolean {
    let isValid = true,
      fieldToCompare = this.getParamValue('fieldName'),
      canBeEmpty = this.getParamValue('canBeEmpty') || false,
      entity = this.getEntity(),
      valueToCompare = entity[fieldToCompare];

    if (typeof value === 'undefined') {
      value = '';
    }

    if (typeof value === 'string' && '' !== value) {
        value = new Date(value);
    }

    if (typeof valueToCompare === 'string' && '' !== valueToCompare) {
        valueToCompare = new Date(valueToCompare);
    }

    if (value instanceof Date && valueToCompare && valueToCompare instanceof Date) {
      isValid = value > valueToCompare;
    }

    if (canBeEmpty && value === '') {
        isValid = true;
    }
    
    return isValid;
  }

  public getErrorTranslateKey(value: any): string {
    return 'DATE_NOT_BIGGER_THAN_OTHERS_DATE_VALUE';
  }

  public getErrorTranslateParams(value: any): any {
    return null;
  }
}
