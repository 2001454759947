import {Component, ElementRef} from '@angular/core';
import {AbstractGridColumnComponent} from '../abstract-grid-column.component';
import {environment} from '../../../../../../../environments';

@Component({
  selector: 'app-lock-column',
  templateUrl: `lock-column.component.html`,
})
export class LockColumnComponent extends AbstractGridColumnComponent {

  environment;

  public constructor(
    protected elementRef: ElementRef
  ) {
    super(elementRef);
    this.environment = environment;
  }

  public getLockIcon(): string {
    if (this.entity && this.entity.id && this.entity.isEntityLocked) {
      return this.environment.baseUrl + '/' + 'assets/hr-puzzle/images/icons/lock.png';
    }
    if (this.entity && this.entity.id && !this.entity.isDeletable) {
      return this.environment.baseUrl + '/' + 'assets/hr-puzzle/images/icons/lock_delete.png';
    }

    return '';
  }
}
