import {AbstractFormActionHandler} from './abstract-form-action-handler';
import {FormViewerComponent} from '../../form-viewer.component';
import {Entity} from '../../../helpers/entity';

export class SetupPhoneProjectFormActionHandler extends AbstractFormActionHandler {

  public handleAction(): void {
    const entity = Entity.extractEmbedded(this.getEntity()),
      component = this.getComponent();

    if (component instanceof FormViewerComponent) {
      const inputElements = component.inputElements;

      const customerElement = inputElements.find((aElement) => { return aElement.datamodelField === 'customer'; }),
        leasedEmployeeElement = inputElements.find((aElement) => { return aElement.datamodelField === 'leasedEmployee'; });

      if (this.isEmptyEntityProperty(entity, 'customer')) {
        customerElement.isHidden = true;
        this.markElementForCheck(customerElement);
      }

      if (this.isEmptyEntityProperty(entity, 'leasedEmployee')) {
        leasedEmployeeElement.isHidden = true;
        this.markElementForCheck(leasedEmployeeElement);
      }
    }
  }

  private isEmptyEntityProperty(entity: any, property: string): boolean {
    const subEntity = Entity.getValue(entity, property) || Entity.getValueInEmbedded(entity, property);

    return !subEntity || (Object.keys(subEntity).length === 0 && subEntity.constructor === Object);
  }
}
