import {ChangeDetectorRef, Directive, ElementRef, Self} from '@angular/core';
import {NgModel} from '@angular/forms';
import {GridFilterDirective} from './grid-filter.directive';
import {MultiSelect} from 'primeng/primeng';
import {ElementState} from '../../../services/element-state';
import {ChangeDetectorRefHelper} from '../../../../helpers/change-detector-ref.helper';

@Directive({
  selector: '[gridFilterMultiSelect]',
  host: {
    '(input)': 'onInput($event)',
    '(onChange)': 'onChange($event)',
    '(onSelect)': 'onSelect($event)',
    '(onUnselect)': 'onSelect($event)'
  },
  providers: [NgModel]
})
export class GridFilterMultiSelectDirective extends GridFilterDirective {

  private readonly multiSelect: MultiSelect = null;
  public constructor(
    protected elementRef: ElementRef,
    protected ngModel: NgModel,
    public cdr: ChangeDetectorRef,
    @Self() multiSelect: MultiSelect
  ) {
    super(elementRef, ngModel, cdr);

    if (multiSelect) {
      this.multiSelect = multiSelect;
    }
  }

  public setValue(componentState: ElementState): void {
    super.setValue(componentState);

    const filterState = this.getFilter(componentState);

    if (this.multiSelect && filterState && filterState.value) {
      this.multiSelect.defaultLabel = filterState.labelValue;
      this.multiSelect.updateLabel();

      ChangeDetectorRefHelper.detectChanges(this);
    }
  }
}
