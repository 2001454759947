import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit, ViewChild, ViewContainerRef} from '@angular/core';
import {ExecutorService} from '../../../../../core/executor/executor.service';
import {GenericElementAbstract} from '../../generic-element-abstract.component';
import {FieldMetadataGrid} from '../../../../services/module/module-element-field-metadata-grid';
import {ModuleElement} from '../../../../services/module/module-element';
import {Observable} from 'rxjs/Observable';
import {EntityValidator, EntityValidatorStatus} from '../../../../validators/services/entity-validator';
import {Element} from '../../../../services/element/element';
import {GenericElementValidationExecutionStepsFactory} from '../../../services/generic/generic-element-validation-execution-steps-factory';
import {ComponentService} from '../../../services/component-highlight-stack.service';
import {ModulesStateService} from '../../../services/modules-state.service';
import {GenericCrudService} from '../../../../services/generic-crud.service';
import {EntityDataStoreService} from '../../../services/entity-data-store.service';
import {LocalStorageDataService} from '../../../../services/local-storage-data.service';
import {ToolbarItemCheckService} from '../../generic-toolbar/services/check/toolbar-item-check.service';
import {LayoutService} from '../../../../services/layout-service';
import {JobContainerService} from '../../../../../core/job-runner/job-container.service';
import {GenericElementFilterService} from '../../../services/generic/filter/generic-element-filter.service';
import {ExecutorActionsService} from '../../../../../core/executor/service/executor-actions/executor-actions.service';
import {ElementType} from '../../../services/ElementContext';
import {LocationService} from '../../../../services/location.service';
import {ElementsStackService} from '../../../services/elements-stack.service';
import {PermissionService} from '../../../../services/permission/permission.service';
import {ChangeDetectorRefHelper} from '../../../../helpers/change-detector-ref.helper';
import {EntityStatus} from '../../../../services/entity/entity-status';
import {Customer} from '../../../../../data-generator/services/customer';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Entity} from '../../../../helpers/entity';
import {MessageGrowlService} from '../../../../../core/message/message-growl.service';
import {EntityManagerService} from '../../../../../core/service/entity-manager/entity-manager.service';
import {UserSessionService} from '../../../../../core/service/user-session.service';

@Component({
  selector: 'app-custom-customer-internal-notes-form',
  styleUrls: ['./customer-internal-notes-form.component.scss'],
  templateUrl: './customer-internal-notes-form.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    ExecutorService,
    GenericElementValidationExecutionStepsFactory,
    GenericElementFilterService
  ]
})
export class CustomerInternalNotesFormComponent extends GenericElementAbstract implements OnInit, OnDestroy {

  @Input() element: Element;
  @Input() fields: Array<FieldMetadataGrid>;
  @Input() toolbarItems: any[] = [];
  @Input() statusBarItems: any[] = [];
  @Input() moduleElement: ModuleElement;
  @Input() masterEntity: any = null;
  @Input() masterField: any = null;
  @Input() isPart = false;
  @Input() isSlave = false;
  @Input() masterFilterField?: string;
  @Input() masterFilterValue?: string;
  private _customer: any = null;
  @Input() public set entity(customer: Customer) {
    if (customer) {
      this._customer = customer;

      ChangeDetectorRefHelper.detectChanges(this);
    }

  };
  public get entity() {
    return this._customer;
  }

  public elementType: ElementType = ElementType.CustomerInternalNotesForm;
  public customerOptions: SelectItem[] = [];

  public isDataLoading = false;

  public toolbarContextName = 'formComponent';
  public form: FormGroup = null;

  public constructor(
    protected componentService: ComponentService,
    protected viewContainerRef: ViewContainerRef,
    protected modulesStateService: ModulesStateService,
    protected genericCrudService: GenericCrudService,
    protected entityDataStoreService: EntityDataStoreService,
    protected executorService: ExecutorService,
    protected genericElementValidationExecutionStepsFactory: GenericElementValidationExecutionStepsFactory,
    protected entityValidator: EntityValidator,
    protected userSession: UserSessionService,
    protected toolbarItemCheckService: ToolbarItemCheckService,
    protected layoutService: LayoutService,
    protected jobContainerService: JobContainerService,
    protected genericElementFilterService: GenericElementFilterService,
    protected executorActionsService: ExecutorActionsService,
    protected locationService: LocationService,
    protected elementsStackService: ElementsStackService,
    protected permissionService: PermissionService,
    public cdr: ChangeDetectorRef,
    private readonly formBuilder: FormBuilder,
    private message: MessageGrowlService,
    private entityManager: EntityManagerService
  ) {
    super(componentService, viewContainerRef, entityDataStoreService, modulesStateService, executorService,
      genericElementValidationExecutionStepsFactory, entityValidator, genericCrudService, userSession, permissionService,
      cdr);
  }

  public ngOnInit(): void {
    super.ngOnInit();

    this.elementContext = this.elementsStackService.createContext(this);

    this.elementsStackService.remove(this.elementContext).add(this.elementContext);

    this.initToolbarItems();
    this.initForm();
  }

  public ngOnDestroy(): void {
    super.ngOnDestroy();

    this.elementsStackService.remove(this.elementContext);
  }

  public getToolbarExtraParams() {
    return {
      'formViewerComponent': this
    };
  }

  public isEntityDirty(): boolean {
    return this.entity && this.entity[EntityStatus.ENTITY_CHANGED_FLAG];
  }

  public onComponentInit() {
  }

  public onDestroyComponent() {
  }

  public getSelectedEntity(): any {
    return this.selectedMasterEntity || null;
  }

  public recheckToolbarItems(): void {
    this.toolbarItemCheckService.check(null);
  }

  public onSave(): Observable<any> {
    return Observable.of(null);
  }

  public hasChanges(checkEmbedded: boolean = false): boolean {
    return false;
  }

  public onAfterSave(): Observable<any> {
    return Observable.of(null);
  }

  public onChange(): Observable<any> {
    return Observable.of(null);
  }

  public doValidate(): Observable<EntityValidatorStatus> {

    return Observable.of({
      entity: null,
      isValid: true,
      error: '',
      errorFields: []
    });
  }

  public onRefresh(): Observable<any> {


    return Observable.of(null);
  }

  public onSubmit(): void {
    const customer = {
      id: this.form.get('customer').value.id,
      internalNotes: this.form.get('internalNotes').value,
      fqn: 'PhoenixBundle\\Entity\\Customer',
      uniqueId: this.form.get('customer').value.uniqueId
    };

    this.entityManager.persist(customer, {property: EntityStatus.ENTITY_CHANGED_FLAG, newValue: true, force: true});
    this.entityManager.persist(customer, {property: 'internalNotes', newValue: customer.internalNotes, force: true});

    this.genericCrudService.editEntity(`phoenix/customers/${customer.id}`, customer)
      .subscribe((aCustomer: any) => {
        this.replaceOption(aCustomer);
        this.replaceCustomer(aCustomer);

        this.message.showDataSaved();
      });
  }

  public onCustomerChange(event: {value: any}): void {
    const customer = event.value;

    this.form.get('internalNotes').patchValue(customer.internalNotes);
  }

  private initForm(): void {
    this.initOptions();

    this.form = this.formBuilder.group({
      customer: this.formBuilder.control('', [Validators.required]),
      internalNotes: this.formBuilder.control(''),
    });
  }

  private getCustomers(): any[] {
    const masterComponent = this.elementContext.masterElementContext.component,
      customers = [];

    for (const entity of masterComponent.entities) {
      const customer = Entity.getValue(entity, 'customer') ||
        Entity.getValueInEmbedded(entity, 'customer');

      if (customer && customers.findIndex((aCustomer) => {
        return customer && aCustomer.id === customer.id
      }) === -1) {
        customers.push(customer);
      }
    }

    return customers;
  }

  private initOptions(): void {
    const customers = this.getCustomers();

    for (const customer of customers) {
      this.customerOptions = [...this.customerOptions, {
        label: customer.primaryName,
        value: customer
      }]
    }
  }

  private replaceOption(customer): void {
    const index = this.customerOptions.findIndex((aOption: SelectItem) => {
      return aOption.value.id === customer.id;
    });

    if (index !== -1) {
      this.customerOptions[index] = {
        label: customer.primaryName,
        value: customer
      };

      this.form.get('customer').patchValue(customer);
    }

    this.customerOptions = [...this.customerOptions];
  }

  private replaceCustomer(aCustomer): void {
    const masterComponent = this.elementContext.masterElementContext.component;

    for (const entity of masterComponent.entities) {
      const customer = Entity.getValue(entity, 'customer') ||
        Entity.getValueInEmbedded(entity, 'customer');

      if (customer && customer.id === aCustomer.id) {
        customer.internalNotes = aCustomer.internalNotes;
      }
    }
  }
}
