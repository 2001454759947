import {DatePipe} from '../pipes/date.pipe';
import {PipeTransform} from '@angular/core';

const formatters: Record<string, PipeTransform> = {
  date: new DatePipe()
}

export class Entity {

    public static getValue(entity: any, propertyName: string, { allowBoolean }: { allowBoolean: boolean } = { allowBoolean: false }): any|null {
        let value = null;

        if (typeof entity === 'undefined' || typeof propertyName === 'undefined' || entity === null) {
            return null;
        }

        if (propertyName.indexOf('.') === -1) {
            value = entity[propertyName];

            if (typeof value === 'undefined') {
              value = null;
            }

            return value;
        }

        const splitParts = propertyName.split('.');

        value = entity;

        for (const part of splitParts) {
            const propertyPart = value[part],
                entityPart = value && value['_embedded'] ? value['_embedded'][part] : null;

            if (propertyPart || (allowBoolean ? propertyPart === false : true)) {
                value = propertyPart;
            }

            if (entityPart) {
                value = entityPart;
            }

            if ((!propertyPart && (allowBoolean ? propertyPart !== false : true)) && !entityPart) {
                value = null;
                break;
            }
        }

        if (typeof value === 'undefined') {
          value = null;
        }

        return value;
    }

    public static  getValueInEmbedded(entity: any, propertyName: string, { allowBoolean }: { allowBoolean: boolean } = { allowBoolean: false }): any|null {

        if (entity === null || typeof entity === 'undefined' || typeof entity._embedded === 'undefined') {
            return null;
        }

        return Entity.getValue(entity._embedded, propertyName, { allowBoolean });
    }

    public static getValueForcePropertyName(entity: any, propertyName: string): any|null {
        if (typeof entity === 'undefined') {
            return null;
        }

        return entity[propertyName];
    }

    public static setValue(entity: any, propertyName: string, value: any): any|null {
      if (entity && entity instanceof Object) {
        const fieldName = !propertyName.includes('.') ?
          propertyName :
          propertyName.substring(0, propertyName.lastIndexOf('.'));

        if (propertyName.includes('.')) {
          const associatedEntityPropertyName = propertyName.substring(propertyName.lastIndexOf('.') + 1),
            associatedEntity = Entity.getValue(entity, fieldName);

          Entity.setValue(associatedEntity, associatedEntityPropertyName, value);
        } else {
          entity[fieldName] = value;
        }
      }
    }

    public static extractEmbedded(entity: any): any|null {
      if (typeof entity === 'undefined') {
        return null;
      }

      if (entity && entity._embedded && entity._embedded instanceof Object) {
        for (const property in entity._embedded) {
          if (entity._embedded.hasOwnProperty(property)) {
            entity[property] = entity._embedded[property];

            entity._embedded[property] = Entity.extractEmbedded(entity._embedded[property]);
          }
        }
      }

      return entity;
    }

  public static getValueFromPlaceholder(entity: any, placeholder: string) {
    const regExpCurlyBrackets = /\{(.*?)\}/g;
    const config = [];
    let found;
    let value = placeholder;
    while (found = regExpCurlyBrackets.exec(placeholder)) {
      config.push(found[1]);
    }

    for (const fieldName of config) {
      let parsedValue = Entity.getValue(entity, fieldName)
      if (fieldName.includes('|')) {
        const parts = fieldName.split('|');
        const format = parts[1];
        parsedValue = formatters[format].transform(Entity.getValue(entity, parts[0]))
      }

      value = value.replace(`{${fieldName}}`, parsedValue)
    }

    return value;
  }

    public static getValueFromConfig(entity: any, config: { fieldName: string, preCharacter: string, postCharacter; string; }[]) {
      let displayLabel = '';

      for (const configOption of config) {
        if (!configOption || !configOption.fieldName) {
          continue;
        }

        const fieldLabel = Entity.getValue(entity, configOption.fieldName);

        if (fieldLabel) {
          displayLabel += configOption.preCharacter + fieldLabel.toString() + configOption.postCharacter;
        }
      }

      return displayLabel;
    }
}
