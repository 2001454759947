/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./toolbar-item-selected-working-model-day-decimal-switch.component";
import * as i2 from "../../../../../../services/permission/permission.service";
import * as i3 from "../../../../../services/entity-data-store.service";
import * as i4 from "../../../../../../services/local-storage-data.service";
import * as i5 from "../../../../../services/time-decimal-column-switch-service";
var styles_ToolbarItemSelectedWorkingModelDayDecimalSwitchComponent = [];
var RenderType_ToolbarItemSelectedWorkingModelDayDecimalSwitchComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_ToolbarItemSelectedWorkingModelDayDecimalSwitchComponent, data: {} });
export { RenderType_ToolbarItemSelectedWorkingModelDayDecimalSwitchComponent as RenderType_ToolbarItemSelectedWorkingModelDayDecimalSwitchComponent };
export function View_ToolbarItemSelectedWorkingModelDayDecimalSwitchComponent_0(_l) { return i0.ɵvid(0, [], null, null); }
export function View_ToolbarItemSelectedWorkingModelDayDecimalSwitchComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-toolbar-item-working-model-time-switch", [], null, null, null, View_ToolbarItemSelectedWorkingModelDayDecimalSwitchComponent_0, RenderType_ToolbarItemSelectedWorkingModelDayDecimalSwitchComponent)), i0.ɵdid(1, 245760, null, 0, i1.ToolbarItemSelectedWorkingModelDayDecimalSwitchComponent, [i2.PermissionService, i3.EntityDataStoreService, i4.LocalStorageDataService, i5.TimeDecimalColumnSwitchService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ToolbarItemSelectedWorkingModelDayDecimalSwitchComponentNgFactory = i0.ɵccf("app-toolbar-item-working-model-time-switch", i1.ToolbarItemSelectedWorkingModelDayDecimalSwitchComponent, View_ToolbarItemSelectedWorkingModelDayDecimalSwitchComponent_Host_0, { params: "params" }, {}, []);
export { ToolbarItemSelectedWorkingModelDayDecimalSwitchComponentNgFactory as ToolbarItemSelectedWorkingModelDayDecimalSwitchComponentNgFactory };
