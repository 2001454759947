import {of as observableOf, Observable} from 'rxjs';
import {AbstractExecutionStep} from '../../../core/executor/abstract-execution-step';
import {ExecutionStepStatus} from '../../../core/executor/execution-step-status';
import {FormViewerComponent} from '../../form-viewer/form-viewer.component';

export class SetFormSaveEmbeddedFieldsExecutionStep extends AbstractExecutionStep {

  public doExecute(): Observable<ExecutionStepStatus> {
    const payload = this.getPayload();

    if (!(payload.getValue() instanceof FormViewerComponent)) {
      return this.getFailObservable('You need to pass FormViewerComponent as Payload value!');
    }

    return this.doSet(payload.getValue());
  }

  protected doSet(component: FormViewerComponent): Observable<ExecutionStepStatus> {
    component.saveEmbeddedFields = this.getParamValue('embedded') || 'none';

    return observableOf({status: true, content: null});
  }
}
