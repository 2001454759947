import { ElementInput } from './element';

export class ElementInputCheckbox extends ElementInput {
  
  isNullable: boolean = false;
  
  constructor() {
    super();
    this.typeElement = 'checkbox';
    this.defaultValue = false;
    this.isNullable = false;
  }
}
