import {
  Component,
  Input,
  OnInit,
  AfterViewInit,
  ChangeDetectionStrategy, ChangeDetectorRef
} from '@angular/core';
import { ElementInputComponent } from './element-input.component';
import { ElementInputPassword } from '../models';
import { FormService } from '../form.service';
import { FormViewerService } from '../form-viewer.service';
import {TranslateService} from '@ngx-translate/core';
import { FormElementAction } from '../models/form';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-form-element-input-password',
  template: `
    <div [formGroup]="formGroup">
      <input
        appSeleniumDirective
        [element]="element"
        type="password"
        pPassword
        [formControlName]="formControlName"
        [readonly]="isReadOnly()"
        [placeholder]="element.placeholder"
        (keyup)="onInputKeyUp($event)"
        tabindex="{{element.tabIndex}}"
        #elementItem
      />
    </div>
  `,
  styles: [`
    :host {
      height: 100%;
    }

    input[readonly].ui-inputtext.ui-state-focus, input[readonly].ui-inputtext:focus {
      pointer-events: none;
      border-color: lightgrey;
      color: black;
      box-shadow: none;
      -moz-box-shadow: none;
      -webkit-box-shadow: none;
    }
    `]
})
export class ElementInputPasswordComponent extends ElementInputComponent implements OnInit, AfterViewInit {
  @Input() element: ElementInputPassword;

  constructor(
    protected formService: FormService,
    public cdr: ChangeDetectorRef,
    protected formViewerService: FormViewerService,
    protected translateService?: TranslateService
  ) {
    super(formService, cdr, formViewerService, translateService);
  }

  onComponentInit() {
    this.setFormControlName();
    this.filterActionAndHandleIt('oninit');
  }

  onComponentChanges(): void {
    this.setupValue();
  }

  ngAfterViewInit() {
  }

  onFormElementAction(action: FormElementAction) {

  }

  public onInputKeyUp(event) {
    this.setValue(event.target.value);
  }
}
