import {ChangeDetectionStrategy, Component} from '@angular/core';
import {ToolbarItemAbstract} from '../toolbar-item-abstract.component';
import {GenericCrudBulkService} from '../../../../../../services/generic-crud-bulk.service';
import {MessageGrowlService} from '../../../../../../../core/message/message-growl.service';
import {ComponentService} from '../../../../../services/component-highlight-stack.service';
import {TranslateService} from '@ngx-translate/core';
import {MessageService} from '../../../../../../../core/message/message.service';
import {ToolbarItemSaveService} from '../../services/toolbar-item-save.service';
import {GenericElementAbstract} from 'app/shared/content-renderer/elements/generic-element-abstract.component';
import {ExecutorService} from '../../../../../../../core/executor/executor.service';
import {ComponentValidationExecutionStep} from '../../../../../../services/execution-step/validation/component-validation-execution-step';
import {ExecutionStepPayload} from 'app/core/executor/execution-step-payload';
import {ComponentSaveExecutionStep} from '../../../../../../services/execution-step/component-save-execution-step';
import {ComponentRefreshExecutionStep} from '../../../../../../services/execution-step/component-refresh-execution-step';
import {ExecutionStatusError} from '../../../../../../../core/executor/execution-status-error';
import {EntityValidationExecutionStep} from 'app/shared/services/execution-step/validation/entity-validation-execution-step';
import {ExecutionStatus} from 'app/core/executor/execution-status';
import {ExecutionStep} from '../../../../../../../core/executor/execution-step';
import {ExecutionStepFactoryService} from '../../../../../../../core/executor/factory/execution-step-factory.service';
import {BlankValidationExecutionStep} from '../../../../../../services/execution-step/validation/blank-validation-execution-step';
import {ExecutorActionEvent} from '../../../../../../../core/executor/service/executor-actions/executor-action-event';
import {ModulesStateService} from 'app/shared/content-renderer/services/modules-state.service';
import {GenericDialogModuleService} from '../../../../generic-dialog/service/generic-dialog-module.service';
import {QuestionnaireComponent} from '../../../../custom/questionnaire/questionnaire.component';
import {ComponentCloseDialogExecutionStep} from '../../../../../../services/execution-step/component-close-dialog-execution-step';
import {ModuleState, ModuleStateContext} from '../../../../../services/module-state';
import {TOAST_LIFE_INFINITE, ToastComponentsRegistry, ToastService} from '../../../../../../../core/service/toast.service';
import {ComponentValidationOnSaveExecutionStep} from '../../../../../../services/execution-step/validation/component-validation-on-save-execution-step';
import {AbstractGenericGridComponent} from '../../../../abstract-generic-grid.component';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'toolbar-item-save',
  templateUrl: './toolbar-item-save.component.html',
  providers: [
    ToolbarItemSaveService,
    ExecutorService
  ]
})
export class ToolbarItemSave extends ToolbarItemAbstract {
  constructor(
    protected genericCrudBulkService: GenericCrudBulkService,
    protected messageGrowlService: MessageGrowlService,
    protected componentService: ComponentService,
    protected translateService: TranslateService,
    protected messageService: MessageService,
    protected toolbarItemSaveService: ToolbarItemSaveService,
    protected executorService: ExecutorService,
    protected executionStepFactoryService: ExecutionStepFactoryService,
    protected modulesStateService: ModulesStateService,
    protected genericDialogModuleService: GenericDialogModuleService,
    protected toastService: ToastService
  ) {
    super();
  }

  public click() {
    const mainModule = this.modulesStateService.getByComponent(this.getComponent());

    if (mainModule && mainModule.isAutocompleteView) {
      this.doSaveInAutocompleteModuleSate(this.getComponent());
    } else {
      if (this.isInCurrentContext(ToolbarItemAbstract.CONTEXT_FORM_VIEWER)) {
        this.doSaveFormViewerComponent(this.getParam('formViewerComponent'));
      } else if (this.isInCurrentContext(ToolbarItemAbstract.CONTEXT_GRID)) {
        this.doSaveChangedEntitiesInGridContext(this.getParam('gridComponent'));
      } else if (this.isInCurrentContext(ToolbarItemAbstract.CONTEXT_TREE)) {
        this.doSaveChangedEntitiesInGridContext(this.getParam('treeComponent'));
      } else if (this.isInCurrentContext(ToolbarItemAbstract.CONTEXT_MODULE)) {
        this.doSaveChangedEntitiesInModuleContext();
      } else if (this.isInCurrentContext(ToolbarItemAbstract.CONTEXT_QUESTIONNAIRE)) {
        this.doSaveQuestionnaire(this.getParam('questionnaireComponent'));
      } else {
        this.saveComponent(this.getComponent());
      }
    }
  }

  protected doSaveFormViewerComponent(formViewerComponent) {

    const highlightedComponent = this.componentService.getHighlighted();

    if (formViewerComponent.element && formViewerComponent.element.datamodel && formViewerComponent.element.datamodel.apiRoute) {
      formViewerComponent.saveUrl = formViewerComponent.element.datamodel.apiRoute;
    } else {
      if (highlightedComponent instanceof AbstractGenericGridComponent) {
        formViewerComponent.saveUrl = highlightedComponent.element.datamodel.apiRoute;
      }
    }

    this.saveComponent(formViewerComponent);
  }

  protected doSaveChangedEntitiesInGridContext(gridComponent) {
    this.saveComponent(gridComponent);
  }

  protected doSaveChangedEntitiesInModuleContext() {
    const component = this.getComponent();

    this.toolbarItemSaveService.setComponent(component).setExecutorService(this.executorService).saveModule();
  }

  protected saveComponent(component: GenericElementAbstract) {
    const steps = [
      this.getComponentValidationStep(component),
      this.executionStepFactoryService.create(ComponentSaveExecutionStep, new ExecutionStepPayload(component))
    ];

    const moduleState: ModuleState = this.modulesStateService.getByComponent(component);

    if (ComponentCloseDialogExecutionStep.isApplicable(component, moduleState)) {
      steps.push(this.executionStepFactoryService.create(ComponentCloseDialogExecutionStep,
        new ExecutionStepPayload(component)
      ));
    } else {
      steps.push(this.executionStepFactoryService.create(ComponentRefreshExecutionStep,
        new ExecutionStepPayload(component)
      ));
    }

    for (const step of this.getAdditionalSteps()) {
      steps.push(step);
    }

    this.toastService.custom(ToastComponentsRegistry.PROGRESS_BAR, {
      severity: 'info',
      life: TOAST_LIFE_INFINITE,
      closable: false,
      summary: this.translateService.instant('COMMON.SAVING_PLEASE_WAIT')
    });
    this.executorService
      .setSteps(steps)
      .execute()
      .subscribe((status: ExecutionStatus) => {
        this.toastService.clear(ToastComponentsRegistry.PROGRESS_BAR, 300);

        if (!status.isSuccess()) {
          this.onExecutorFailure(status);
        } else {
          component.executeAction(ExecutorActionEvent.ComponentSaved, component).subscribe();
        }
      });
  }

  protected getAdditionalSteps(): Array<ExecutionStep> {
    return [];
  }

  protected getComponentValidationStep(component: GenericElementAbstract): ExecutionStep {
    return (component.ignoreValidations())
      ? this.executionStepFactoryService.create(BlankValidationExecutionStep, new ExecutionStepPayload(component))
      : this.executionStepFactoryService.create(ComponentValidationExecutionStep, new ExecutionStepPayload(component));
  }

  protected refreshComponent(component: GenericElementAbstract) {
    component.onRefresh().subscribe();
  }

  public onExecutorFailure(status: ExecutionStatusError) {
    if (status.getStep() instanceof ComponentValidationExecutionStep ||
      status.getStep() instanceof EntityValidationExecutionStep ||
      status.getStep() instanceof ComponentValidationOnSaveExecutionStep
    ) {
      this.messageService.confirm({
        acceptVisible: true,
        rejectVisible: false,
        header: this.translateService.instant('COMMON.ERROR'),
        message: status.getStepContent(),
        accept: () => {

        }
      });
    }
  }

  protected doSaveInAutocompleteModuleSate(component: GenericElementAbstract): void {
    this.executorService
      .addStep(this.getComponentValidationStep(component))
      .execute()
      .subscribe((status: ExecutionStatus) => {
        if (!status.isSuccess()) {
          this.onExecutorFailure(status);
        } else {
          this.genericDialogModuleService.hideDialogWithSave(this.getComponent());
        }
      });
  }

  protected doSaveQuestionnaire(component: QuestionnaireComponent): void {
    this.saveComponent(component);
  }
}
