import {
  Component,
  Input,
  Output,
  EventEmitter,
  OnInit,
  AfterViewInit,
  Optional,
  Renderer,
  Injector,
  ChangeDetectionStrategy, ChangeDetectorRef
} from '@angular/core';

import { environment } from './../../../../environments/environment';

import { ElementLabelComponent } from './element-label.component';
import { ElementLabelOutputCollectiveAgreementAkuText } from '../models/index';

import { FormService } from './../form.service';
import { FormViewerService } from './../form-viewer.service';
import {ElementInput} from '../models/element';
import {Constants} from '../../../constants';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-form-element-label-output-collective-agreement-aku-text',
  template: `
    <div fxLayout="row">
        <div
          appSeleniumDirective
          [element]="element"
          fxFlex="80" [style.font-weight]="getFontWeight()"
        >
            {{collectiveAgreementName}}
        </div>
    </div>
  `,
  styles: [`
    :host {
      width: 100%;
      padding: .334rem 0;
    }
  `]
})
export class ElementLabelOutputCollectiveAgreementAkuTextComponent extends ElementLabelComponent implements OnInit, AfterViewInit {
  @Input() element: ElementLabelOutputCollectiveAgreementAkuText;

  public collectiveAgreementName = '';

  constructor(
    protected formService: FormService,
    public cdr: ChangeDetectorRef,
    protected formViewerService: FormViewerService,
  ) {
    super(formService, cdr, formViewerService);
  }

  onComponentInit() {
    this.filterActionAndHandleIt('oninit');
    this.loadCollectiveAgreeementName();
    this.subscriptions.push(
      this.formService.formElementAfterValueChanged$.subscribe((element) => {
        this.collectiveAgreementName = '';
        this.loadCollectiveAgreeementName();
      })
    );
    this.subscriptions.push(
      this.formService.formEntityChanged$.subscribe(() => {
        this.collectiveAgreementName = '';
        this.loadCollectiveAgreeementName();
      })
    );
  }

  ngAfterViewInit() {
  }

  getFontWeight() {

    return this.element.boldText ? 'bold' : 'normal';
  }

  loadCollectiveAgreeementName() {
    this.collectiveAgreementName = this.getCollectiveAgreementName() || '';

    this.markElementForCheck(this.element);
  }

  getCollectiveAgreementName() {
    let collectiveAgreement = null,
      field = this.element.collectiveAgreementField;

    if(this.entity && field) {
      collectiveAgreement = this.formService.getEntityHydrator().getEntityPropertyValue(this.entity, field, false, true)
    }

    if(collectiveAgreement && collectiveAgreement.isForEmployees){
      collectiveAgreement = this.formService.getUserSession().get(Constants.COLLECTIVE_AGREEMENT_EMPLOYEES);
    }else if(collectiveAgreement && collectiveAgreement.isForWorkpersons){
      collectiveAgreement = this.formService.getUserSession().get(Constants.COLLECTIVE_AGREEMENT_HIRED_WORKER);
    }else{
      collectiveAgreement = null;
    }

    if(collectiveAgreement && collectiveAgreement.fqn == 'PhoenixBundle\\Entity\\CollectiveAgreement') {
      return collectiveAgreement.name;
    }else{
      return false;
    }
  }
}
