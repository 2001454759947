
import {of as observableOf, Observable} from 'rxjs';
import {Injectable} from '@angular/core';
import {ModuleState} from '../module-state';
import {ModuleStateViewFactoryService} from './module-state-strategy/module-state-view-factory.service';
import {ModuleRouter} from './module-router.service';
import {LocationService} from '../../../services/location.service';

@Injectable()
export class ModuleNavigationService {

  private readonly  moduleRouter: ModuleRouter = null;

  public constructor(
    private readonly moduleStateViewFactoryService: ModuleStateViewFactoryService,
    private readonly locationService: LocationService
  ) {
    this.moduleRouter = new ModuleRouter(this.locationService);
  }

  public openPrevious(currentModuleState: ModuleState): Observable<void> {
    const strategy = this.moduleStateViewFactoryService.instance(currentModuleState);

    if (!strategy) {
      return observableOf(null);
    }

    return strategy.openPrevious();
  }

  public getModuleRouter(): ModuleRouter {
    return this.moduleRouter;
  }
}
