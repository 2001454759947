import {
  Component, EventEmitter, Input, OnInit, Output
} from '@angular/core';
import {ExpertSearch, ExpertSearchField, ExpertSearchFieldsContainer} from '../../../../../services/expert-search/expert-search';
import {cloneDeep} from 'lodash';
import {Guid} from 'guid-typescript';
import {EntityStatus} from '../../../../../services/entity/entity-status';

@Component({
  selector: 'app-search-management-edit-container',
  templateUrl: './search-management-edit-container.component.html',
  styles: [`
    ::ng-deep .ui-dialog-content {
      height: calc(100% - 38px);
    }
    
    .add-field-button {
      height: 87%;
      top: 2px;
      left: 2px;
    }
  `]
})
export class SearchManagementEditContainerComponent implements OnInit {
  @Input() public expertSearch: ExpertSearch = null;
  @Input() public container: ExpertSearchFieldsContainer = null;
  @Input() public field: ExpertSearchField = null;

  @Output() onChange: EventEmitter<any> = new EventEmitter();

  public isEditFieldVisible = false;

  public ngOnInit(): void {
    if (this.container.fields.length === 0) {
      this.onAddField();
    }
  }

  public onAddField(event?: any): void {
    this.field = {
      fieldKey: '',
      fieldValue: null,
      typeValue: 'text',
      comparator: '',
      labelValue: '',
      parameters: []
    };
    this.field[EntityStatus.ENTITY_DRAFT_FLAG] = Guid.create().toString();
    this.isEditFieldVisible = true;
  }

  public onEditField(field: ExpertSearchField): void {
    this.field = cloneDeep(field);
    this.isEditFieldVisible = true;
  }

  public onSaveField(field: ExpertSearchField): void {
    const index = this.container.fields.findIndex((aField: ExpertSearchField) => {
      return field[EntityStatus.ENTITY_DRAFT_FLAG] && field[EntityStatus.ENTITY_DRAFT_FLAG] === aField[EntityStatus.ENTITY_DRAFT_FLAG];
    });

    if (index !== -1) {
      field[EntityStatus.ENTITY_CHANGED_FLAG] = true;
      this.container.fields[index] = field;
    } else {
      this.container.fields = [...this.container.fields, field];
    }

    this.isEditFieldVisible = false;

    this.onChange.emit({
      field: field,
      context: 'fieldAdd'
    });
  }

  public onContainerRemove(container: ExpertSearchFieldsContainer): void {
    this.onChange.emit({
      container: container,
      context: 'containerRemove'
    });
  }

  public onFieldRemove(field: ExpertSearchField): void {
    this.onChange.emit({
      field: field,
      context: 'fieldRemove'
    });
  }
}
