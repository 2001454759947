import { Injectable } from '@angular/core';
import { ToolbarItemDisabledCheckInterface } from './toolbar-item-disabled-check-factory.service';
import { ToolbarItemAbstractDisabledCheckService } from './toolbar-item-abstract-disabled-check.service';

@Injectable()
export class ToolbarItemDeleteDisabledCheckService extends ToolbarItemAbstractDisabledCheckService implements ToolbarItemDisabledCheckInterface {

  isDisabled(): boolean {
    return this.isDisabledGlobally() || !(this.entity && this.entity.isDeletable !== false) || !this.authenticationService.currentUser.isSuperUser;
  }

}
