import {ChangeDetectionStrategy, Component} from '@angular/core';
import {ToolbarItemSaveService} from '../../services/toolbar-item-save.service';
import {ExecutorService} from '../../../../../../../core/executor/executor.service';
import {ToolbarItemSave} from './toolbar-item-save.component';
import {GenericCrudBulkService} from '../../../../../../services/generic-crud-bulk.service';
import {MessageGrowlService} from '../../../../../../../core/message/message-growl.service';
import {ComponentService} from '../../../../../services/component-highlight-stack.service';
import {TranslateService} from '@ngx-translate/core';
import {MessageService} from '../../../../../../../core/message/message.service';
import {ExecutionStepFactoryService} from '../../../../../../../core/executor/factory/execution-step-factory.service';
import {ModulesStateService} from '../../../../../services/modules-state.service';
import {GenericDialogModuleService} from '../../../../generic-dialog/service/generic-dialog-module.service';
import {ToastService} from '../../../../../../../core/service/toast.service';
import {GenericCrudService} from '../../../../../../services/generic-crud.service';
import {catchError} from 'rxjs/operators';
import {EntityStatus} from '../../../../../../services/entity/entity-status';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'toolbar-item-validate-save',
  templateUrl: './toolbar-item-save.component.html',
  providers: [
    ToolbarItemSaveService,
    ExecutorService
  ]
})
export class ToolbarItemValidateSaveComponent extends ToolbarItemSave {

  constructor(
    protected genericCrudBulkService: GenericCrudBulkService,
    protected messageGrowlService: MessageGrowlService,
    protected componentService: ComponentService,
    protected translateService: TranslateService,
    protected messageService: MessageService,
    protected toolbarItemSaveService: ToolbarItemSaveService,
    protected executorService: ExecutorService,
    protected executionStepFactoryService: ExecutionStepFactoryService,
    protected modulesStateService: ModulesStateService,
    protected genericDialogModuleService: GenericDialogModuleService,
    protected toastService: ToastService,
    protected genericCrudService: GenericCrudService
  ) {
    super(genericCrudBulkService,
      messageGrowlService,
      componentService,
      translateService,
      messageService,
      toolbarItemSaveService,
      executorService,
      executionStepFactoryService,
      modulesStateService,
      genericDialogModuleService,
      toastService);
  }

  public click() {
    const entity = this.getEntity();

    if (entity[EntityStatus.ENTITY_CHANGED_FLAG]) {
      this.genericCrudService.customPut(`phoenix/leasedemployees/${entity.id}/validate/social-security-number`, entity, {embedded: 'none'}).pipe(catchError((response, caught) => {
        const errors = response.error.errors;

        this.onComponentValidationFailure(this.getErrorTranslated(errors));

        return response;
      })).subscribe(result => {
        super.click();
      });
    } else {
      super.click();
    }
  }

  public onComponentValidationFailure(text: string): void {
    this.messageService.confirm({
      acceptVisible: true,
      rejectVisible: false,
      header: this.translateService.instant('COMMON.ERROR'),
      message: text,
      accept: () => { }
    });
  }

  public getErrorTranslated(errors) {
    const me = this;

    let error = '';

    if (errors instanceof Object) {
      Object.keys(errors).forEach(function(field) {
        const fieldErrors = errors[field];

        const errorMessage = fieldErrors.join(',');
        error += `${field}: ${errorMessage} \n`;
      });
    }

    return error;
  }
}
