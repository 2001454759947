
import {map} from 'rxjs/operators';
import {AbstractExecutionStep} from '../../../../core/executor/abstract-execution-step';
import {Observable} from 'rxjs';
import {ExecutionStepStatus} from '../../../../core/executor/execution-step-status';
import {WizardComponent} from '../../../content-renderer/elements/custom/wizard/wizard.component';
import {WizardElementDetails} from '../../../content-renderer/elements/custom/wizard/service/wizard.service';
import {WizardElement} from '../../element/wizard';
import {ModuleSaveService} from '../../../../core/service/module-save/module-save.service';
import {ExecutionStatus} from '../../../../core/executor/execution-status';
import {FormViewerComponent} from '../../../form-viewer/form-viewer.component';
import {ChangeDetectorRefHelper} from '../../../helpers/change-detector-ref.helper';

export class WizardSaveModuleExecutionStep extends AbstractExecutionStep {

  public doExecute(): Observable<ExecutionStepStatus> {
    const payloadValue = this.getPayload().getValue();

    const wizardComponent = payloadValue.wizardComponent,
      wizardElement = payloadValue.wizardElement;

    if (!(wizardComponent instanceof WizardComponent)) {
      return this.getFailObservable('You need to pass WizardComponent as Payload value!');
    }

    return this.doSave(wizardComponent, wizardElement);
  }

  protected doSave(wizardComponent: WizardComponent, wizardElement: WizardElement): Observable<ExecutionStepStatus> {
    const elementDetails: WizardElementDetails = wizardComponent.wizardService.getElementDetails(wizardElement),
      wizardElementModuleState = elementDetails.moduleState;

    const moduleSave: ModuleSaveService = this.injector.get(ModuleSaveService, null);

    for (const component of wizardElementModuleState.getAllComponents()) {
      if (component instanceof FormViewerComponent) {
        component.emitEntitySaved = false;
        component.performRefreshOnSave = false;
      }
    }

    return moduleSave
      .saveModule(wizardElementModuleState).pipe(
      map((executionStatus: ExecutionStatus) => {

        wizardComponent.wizardService.openNext().subscribe((wizardElementDetails: WizardElementDetails) => {
          wizardComponent.wizardElement = wizardElementDetails.wizardElement;

          ChangeDetectorRefHelper.detectChanges(wizardComponent);
        });

        return {status: true, content: null};
      }));
  }
}
