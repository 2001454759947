
import {of as observableOf,  Observable } from 'rxjs';

import {map} from 'rxjs/operators';
import { BaseSetValueFromDialogStep } from '../../dialog-for-autocomplete/base-set-value-from-dialog-step';
import { FormViewerComponent } from '../../../../form-viewer/form-viewer.component';
import { FieldMetadataGrid } from '../../../module/module-element-field-metadata-grid';
import { ExecutionStepStatus } from '../../../../../core/executor/execution-step-status';
import { ElementInputAutocompleteComponent } from '../../../../form-viewer/element/element-input-autocomplete.component';
import {SetCollectiveAgreementToInquiryFromDialogStep} from './set-collective-agreement-to-inquiry-from-dialog-step';

export class SetCustomerCollectiveContractToInquiryFromDialogStep extends SetCollectiveAgreementToInquiryFromDialogStep {

    protected doSetValue(fromComponent: FormViewerComponent, toComponent: any, field: FieldMetadataGrid): Observable<ExecutionStepStatus> {
        const collectiveAgreement = fromComponent.entity ? fromComponent.entity.collectiveAgreement : null;

        let customer = toComponent && toComponent.entity ? toComponent.entity.customer : null;

        if (typeof customer === 'number') {
            customer = {
                id: customer
            }
        }

        if (toComponent instanceof ElementInputAutocompleteComponent &&
            collectiveAgreement !== null &&
            customer !== null
        ) {
            return this.getCustomerCollectiveContract(customer, collectiveAgreement).pipe(
                map((customerCollectiveContract: any) => {
                    toComponent.entity.customerCollectiveContract = customerCollectiveContract;
                    toComponent.entity._embedded.customerCollectiveContract = customerCollectiveContract;

                    this.setValueToFormAutocomplete(customerCollectiveContract, toComponent);

                    return {status: true, content: null};
                }));
        }

        return observableOf({
            status: true,
            content: null
        });
    }
}
