import {
  Component,
  OnInit,
  AfterViewInit,
  ChangeDetectionStrategy, ChangeDetectorRef
} from '@angular/core';
import { ElementComponent } from './element.component';
import { FormService } from '../form.service';
import { FormViewerService } from '../form-viewer.service';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-form-element-layout-columns-item',
  template: `
    <ng-container *ngIf="element.children.length > 0">
      <ng-container *ngFor="let elementChild of element.children">
        <app-form-element
          [(actionParams)]="actionParams"
          [(selectedElement)]="selectedElement"
          [(showDialogAddAddress)]="showDialogAddAddress"
          [form]="form"
          [formGroup]="formGroup"
          [element]="elementChild"
          [moduleElement]="moduleElement"
          [entity]="entity"
          [editMode]="editMode"
        ></app-form-element>
      </ng-container>
  </ng-container>
  `
})
export class ElementLayoutColumnsItemComponent extends ElementComponent implements OnInit, AfterViewInit {
  constructor(
    protected formService: FormService,
    public cdr: ChangeDetectorRef,
    protected formViewerService: FormViewerService,
  ) {
    super(formService, cdr, formViewerService);
  }

  onComponentInit() {
    this.filterActionAndHandleIt('oninit');
  }

  ngAfterViewInit() {
  }
}
