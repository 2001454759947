/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./toolbar-item-copy.component";
import * as i2 from "../../../../../../../core/message/message-growl.service";
import * as i3 from "../../../../../../../core/message/message.service";
import * as i4 from "../../../../../../services/generic-crud.service";
import * as i5 from "../../../../../services/component-highlight-stack.service";
import * as i6 from "../../../../../../services/entity-factory.service";
var styles_ToolbarItemCopy = [];
var RenderType_ToolbarItemCopy = i0.ɵcrt({ encapsulation: 2, styles: styles_ToolbarItemCopy, data: {} });
export { RenderType_ToolbarItemCopy as RenderType_ToolbarItemCopy };
export function View_ToolbarItemCopy_0(_l) { return i0.ɵvid(2, [], null, null); }
export function View_ToolbarItemCopy_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "toolbar-item-copy", [], null, null, null, View_ToolbarItemCopy_0, RenderType_ToolbarItemCopy)), i0.ɵdid(1, 245760, null, 0, i1.ToolbarItemCopy, [i2.MessageGrowlService, i3.MessageService, i4.GenericCrudService, i5.ComponentService, i6.EntityFactoryService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ToolbarItemCopyNgFactory = i0.ɵccf("toolbar-item-copy", i1.ToolbarItemCopy, View_ToolbarItemCopy_Host_0, { params: "params" }, {}, []);
export { ToolbarItemCopyNgFactory as ToolbarItemCopyNgFactory };
