<a (click)="onItemClick(item)" *ngIf="!item.isSeparator && item.isCustomMenuItem" [pTooltip]="item.tooltip" [tooltipPosition]="menuPosition == 'right' ? 'left' : 'right'">
  <div class="item-container item-text-container">
    {{item.label}}
  </div>
</a>
<p-dialog *ngIf="isDialogVisible" appendTo="body" [responsive]="true"
          [(visible)]="isDialogVisible" showEffect="fade" header="Aktivität import"
          [modal]="true" [width]="600" [height]="500">
  <p-fieldset class="fieldset" legend="Import">
    <div fxLayout="row wrap" class="editor-sidebar-inner">
      <div fxFlex="100">
        <div class="input-group">
          <div class="input-group-input">
            <p-radioButton [(ngModel)]="importMode" value="week" label="Wöchentliche Benutzeraktivitäten"></p-radioButton>
          </div>
        </div>
      </div>
      <div fxFlex="100">
        <div class="input-group">
          <div class="input-group-input">
            <p-radioButton [(ngModel)]="importMode" value="month" label="Monatliche Filialenaktivitäten"></p-radioButton>
          </div>
        </div>
      </div>
    </div>
    <div class="ui-grid ui-grid-responsive ui-fluid">
      <div class="ui-grid-row">
        <div class="ui-grid-col-12">
          <p-fileUpload
            name="file[]"
            multiple="multiple"
            withCredentials="true"
            customUpload="true"
            (uploadHandler)="onUpload($event)"
            accept=".csv"
            maxFileSize="100000000"
            chooseLabel="{{ 'COMMON.CHOOSE' | translate }}"
            cancelLabel="{{ 'COMMON.CANCEL' | translate }}"
            uploadLabel="{{ 'COMMON.UPLOAD' | translate }}"
          >
          </p-fileUpload></div>
      </div>
    </div>
  <div class="ui-grid-row">
    <p-progressBar *ngIf="isDataLoading" mode="indeterminate" [style]="{'height': '6px', 'margin-top': '5px'}"></p-progressBar>
  </div>
  </p-fieldset>
</p-dialog>
