import {Component} from '@angular/core';
import {ExecutorService} from '../../../../../core/executor/executor.service';
import {GenericElementValidationExecutionStepsFactory} from '../../../services/generic/generic-element-validation-execution-steps-factory';
import {GenericElementFilterService} from '../../../services/generic/filter/generic-element-filter.service';
import {CancelComponentChangesService} from '../../../services/cancel-components-changes.service';
import {GenericGridColumnBuilderService} from '../../generic-grid/services/generic-grid-column-builder.service';
import {GenericGridGlobalFilterService} from '../../generic-grid/services/generic-grid-global-filter.service';
import {GenericGridRemoteFilterService} from '../../generic-grid/services/generic-grid-remote-filter.service';
import {GenericElementInlineEditorService} from '../../../services/generic/generic-element-inline-editor.service';
import {GenericGridBulkSaveService} from '../../generic-grid/services/generic-grid-bulk-save.service';
import {GenericGridEntityService} from '../../../services/generic/entity/generic-grid-entity.service';
import {CustomButtonCheckFactoryService} from '../../generic-grid/services/custom-button-check/custom-button-check-factory.service';
import {ExecutionStepBuilderService} from '../../../../../core/executor/builder/execution-step-builder.service';
import {ElementType} from '../../../services/ElementContext';
import {EventHelper} from '../../../../helpers/event.helper';
import {
  FieldActionSetPlannedWorkingTimeComponent
} from '../../generic-grid/field-actions/set-planned-working-time/field-action-set-planned-working-time.component';
import {GenericTurboGridComponent} from '../../generic-turbo-grid/generic-turbo-grid.component';

@Component({
  selector: 'app-custom-working-time-model-position-grid',
  styleUrls: ['./../../generic-turbo-grid/generic-turbo-grid.component.scss'],
  templateUrl: './../../generic-turbo-grid/generic-turbo-grid.component.html',
  providers: [
    CancelComponentChangesService,
    GenericGridRemoteFilterService,
    GenericGridGlobalFilterService,
    GenericGridColumnBuilderService,
    GenericGridBulkSaveService,
    GenericGridEntityService,
    GenericElementInlineEditorService,
    GenericElementFilterService,
    GenericElementValidationExecutionStepsFactory,
    ExecutorService,
    ExecutionStepBuilderService,
    CustomButtonCheckFactoryService
  ]
})
export class WorkingTimeModelPositionGridComponent extends GenericTurboGridComponent {

  public onKeyDownEvent(event: KeyboardEvent): void {
    const isInFilter = this.genericElementFilterService.isEventFromTableHeader(event);

    // check TreeEditableColumnDirective
    if (this.getElementContext().type !== ElementType.Tree && !isInFilter) {
      if (EventHelper.isEnterPressed(event)) {
        this.calculatePlannedWorkingHours();
        this.genericElementInlineEditorService.openNextEntityEditableColumnCellEditor();

        event.preventDefault();
      }

      if (event.shiftKey && EventHelper.isTabPressed(event)) {
        this.genericElementInlineEditorService.openPreviousEditableColumnCellEditor();

        event.preventDefault();
      }

      if (!event.shiftKey && EventHelper.isTabPressed(event)) {
        this.genericElementInlineEditorService.openNextEditableColumnCellEditor();

        event.preventDefault();
      }
    }
  }

  private calculatePlannedWorkingHours(): void {
    const action = new FieldActionSetPlannedWorkingTimeComponent(
      this.genericCrudService,
      this.doubleClickService,
      this.entityManager
    ),
    field = this.getColumnBuilder().findField('plannedWorkingHours');

    action.field = field;
    action.grid = this;

    action.fire();
  }
}
