import {Injectable} from '@angular/core';

interface WindowOptions {
  url: string
  target?: string;
  features?: string;
}

@Injectable({
  providedIn: 'root'
})
export class WindowService {

  windows: Record<string, Window> = {};

  open(key: string, { url, target, features }: WindowOptions): Window {
    this.windows[key] = window.open(url, target, features)

    return this.windows[key];
  }

  close(key: string): void {
    if (this.windows[key]) {
      this.windows[key].close();
    }
  }

  replace(key: string, options: WindowOptions): void {
    if (this.windows[key] && this.windows[key].closed === false) {
      this.replaceDocument(key, options);
    } else if (this.windows[key] && this.windows[key].closed === true) {
      this.open(key, options);
    }

    if (!this.windows[key]) {
      this.open(key, options);
    }
  }

  replaceDocument(key: string, { url }: WindowOptions): void {
    this.windows[key].location.replace(url);
  }
}
