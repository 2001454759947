/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./generic-file-preview-image.component";
var styles_GenericFilePreviewImageComponent = [];
var RenderType_GenericFilePreviewImageComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_GenericFilePreviewImageComponent, data: {} });
export { RenderType_GenericFilePreviewImageComponent as RenderType_GenericFilePreviewImageComponent };
export function View_GenericFilePreviewImageComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵted(-1, null, ["IMAGE\n"]))], null, null); }
export function View_GenericFilePreviewImageComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-generic-file-preview-image", [], null, null, null, View_GenericFilePreviewImageComponent_0, RenderType_GenericFilePreviewImageComponent)), i0.ɵdid(1, 114688, null, 0, i1.GenericFilePreviewImageComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var GenericFilePreviewImageComponentNgFactory = i0.ɵccf("app-generic-file-preview-image", i1.GenericFilePreviewImageComponent, View_GenericFilePreviewImageComponent_Host_0, { fileData: "fileData" }, {}, []);
export { GenericFilePreviewImageComponentNgFactory as GenericFilePreviewImageComponentNgFactory };
