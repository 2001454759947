/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "primeng/components/dom/domhandler";
import * as i2 from "primeng/components/button/button";
import * as i3 from "../../p-button/p-button.directive";
import * as i4 from "../../directives/selenium.directive";
import * as i5 from "./element-button-open-module";
import * as i6 from "../form.service";
import * as i7 from "../form-viewer.service";
import * as i8 from "../../content-renderer/elements/generic-dialog/service/generic-dialog-module.service";
var styles_ElementButtonOpenModuleComponent = ["[_nghost-%COMP%]   .button-wrapper[_ngcontent-%COMP%] {\n    padding: .5em;\n  }"];
var RenderType_ElementButtonOpenModuleComponent = i0.ɵcrt({ encapsulation: 0, styles: styles_ElementButtonOpenModuleComponent, data: {} });
export { RenderType_ElementButtonOpenModuleComponent as RenderType_ElementButtonOpenModuleComponent };
export function View_ElementButtonOpenModuleComponent_0(_l) { return i0.ɵvid(2, [(_l()(), i0.ɵeld(0, 0, null, null, 5, "div", [["class", "button-wrapper"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 4, "button", [["appSeleniumDirective", ""], ["pButton", ""]], [[8, "disabled", 0], [4, "width", null], [8, "tabIndex", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onOpenModule() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵprd(4608, null, i1.DomHandler, i1.DomHandler, []), i0.ɵdid(3, 4341760, null, 0, i2.ButtonDirective, [i0.ElementRef], { label: [0, "label"] }, null), i0.ɵdid(4, 4341760, null, 0, i3.PButtonDirective, [i0.ElementRef], { label: [0, "label"], iconUrl: [1, "iconUrl"] }, null), i0.ɵdid(5, 4210688, null, 0, i4.SeleniumDirective, [i0.ElementRef, i0.Renderer2], { element: [0, "element"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_3 = _co.getButtonLabel(_co.element.label); _ck(_v, 3, 0, currVal_3); var currVal_4 = _co.getButtonLabel(_co.element.label); var currVal_5 = _co.getButtonImageSource(_co.element); _ck(_v, 4, 0, currVal_4, currVal_5); var currVal_6 = _co.element; _ck(_v, 5, 0, currVal_6); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.getIsDisabled(); var currVal_1 = _co.getButtonWidth(); var currVal_2 = _co.element.tabIndex; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2); }); }
export function View_ElementButtonOpenModuleComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-form-element-button-open-module", [], null, null, null, View_ElementButtonOpenModuleComponent_0, RenderType_ElementButtonOpenModuleComponent)), i0.ɵdid(1, 4964352, null, 0, i5.ElementButtonOpenModuleComponent, [i6.FormService, i0.ChangeDetectorRef, i7.FormViewerService, i8.GenericDialogModuleService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ElementButtonOpenModuleComponentNgFactory = i0.ɵccf("app-form-element-button-open-module", i5.ElementButtonOpenModuleComponent, View_ElementButtonOpenModuleComponent_Host_0, { form: "form", formGroup: "formGroup", entity: "entity", element: "element", moduleElement: "moduleElement", selectedElement: "selectedElement", showDialogAddAddress: "showDialogAddAddress", actionParams: "actionParams", editMode: "editMode" }, { selectedElementChange: "selectedElementChange", showDialogAddAddressChange: "showDialogAddAddressChange", actionParamsChange: "actionParamsChange" }, []);
export { ElementButtonOpenModuleComponentNgFactory as ElementButtonOpenModuleComponentNgFactory };
