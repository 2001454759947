import {Component, Input, ViewChild, ViewContainerRef, OnInit} from '@angular/core';
import {Questionnaire, Question} from '../../../../../services/element/questionnaire';
import {QuestionnaireComponent} from '../questionnaire.component';
import {QuestionComponentFactoryService} from '../../../../../../core/questionnaire/question-component-factory.service';

@Component({
  selector: 'app-custom-questionnaire-question',
  styleUrls: ['./questionnaire-question.component.scss'],
  templateUrl: './questionnaire-question.component.html',
  providers: [QuestionComponentFactoryService]
})
export class QuestionnaireQuestionComponent implements OnInit {

  @Input() questionnaire: Questionnaire = null;
  @Input() question: Question = null;
  @Input() questionnaireComponent: QuestionnaireComponent = null;

  @ViewChild('questionContainer', { read: ViewContainerRef, static: false }) questionContainer: ViewContainerRef;

  public constructor(
    private questionComponentFactoryService: QuestionComponentFactoryService
  ) {

  }

  public ngOnInit(): void {
    this.questionComponentFactoryService.createAndRenderComponent(
      this.question,
      this.questionnaire,
      this.questionContainer
    );
  }
}

