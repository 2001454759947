/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../services/toolbar-item-save.service";
import * as i2 from "../../../../../services/modules-state.service";
import * as i3 from "../../../../../../../core/message/message.service";
import * as i4 from "../../../../../../../core/message/message-growl.service";
import * as i5 from "@ngx-translate/core";
import * as i6 from "../../../../../../../core/executor/builder/execution-step-builder.service";
import * as i7 from "../../../../../../../core/service/toast.service";
import * as i8 from "../../../../../../../core/executor/executor.service";
import * as i9 from "../../../../../../../core/executor/service/executor-actions/executor-actions.service";
import * as i10 from "./toolbar-item-save-and-trigger-workflow.component";
import * as i11 from "../../../../../../services/generic-crud-bulk.service";
import * as i12 from "../../../../../services/component-highlight-stack.service";
import * as i13 from "../../../../../../../core/executor/factory/execution-step-factory.service";
import * as i14 from "../../../../generic-dialog/service/generic-dialog-module.service";
import * as i15 from "../../../../../../../core/service/entity-manager/entity-manager.service";
var styles_ToolbarItemSaveAndTriggerWorkflowComponent = [];
var RenderType_ToolbarItemSaveAndTriggerWorkflowComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_ToolbarItemSaveAndTriggerWorkflowComponent, data: {} });
export { RenderType_ToolbarItemSaveAndTriggerWorkflowComponent as RenderType_ToolbarItemSaveAndTriggerWorkflowComponent };
export function View_ToolbarItemSaveAndTriggerWorkflowComponent_0(_l) { return i0.ɵvid(2, [], null, null); }
export function View_ToolbarItemSaveAndTriggerWorkflowComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "app-toolbar-item-save-and-trigger-workflow", [], null, null, null, View_ToolbarItemSaveAndTriggerWorkflowComponent_0, RenderType_ToolbarItemSaveAndTriggerWorkflowComponent)), i0.ɵprd(512, null, i1.ToolbarItemSaveService, i1.ToolbarItemSaveService, [i2.ModulesStateService, i3.MessageService, i4.MessageGrowlService, i5.TranslateService, i6.ExecutionStepBuilderService, i7.ToastService]), i0.ɵprd(512, null, i8.ExecutorService, i8.ExecutorService, [i9.ExecutorActionsService]), i0.ɵdid(3, 245760, null, 0, i10.ToolbarItemSaveAndTriggerWorkflowComponent, [i11.GenericCrudBulkService, i4.MessageGrowlService, i12.ComponentService, i5.TranslateService, i3.MessageService, i1.ToolbarItemSaveService, i8.ExecutorService, i13.ExecutionStepFactoryService, i2.ModulesStateService, i14.GenericDialogModuleService, i7.ToastService, i15.EntityManagerService], null, null)], function (_ck, _v) { _ck(_v, 3, 0); }, null); }
var ToolbarItemSaveAndTriggerWorkflowComponentNgFactory = i0.ɵccf("app-toolbar-item-save-and-trigger-workflow", i10.ToolbarItemSaveAndTriggerWorkflowComponent, View_ToolbarItemSaveAndTriggerWorkflowComponent_Host_0, { params: "params" }, {}, []);
export { ToolbarItemSaveAndTriggerWorkflowComponentNgFactory as ToolbarItemSaveAndTriggerWorkflowComponentNgFactory };
