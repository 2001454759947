
import {of as observableOf, Observable} from 'rxjs';
import {ChangeDetectorRef, Component, Input, ViewChild, ViewContainerRef} from '@angular/core';
import {ExecutorService} from '../../../../../core/executor/executor.service';
import {GenericElementAbstract} from '../../generic-element-abstract.component';
import {FieldMetadataGrid} from '../../../../services/module/module-element-field-metadata-grid';
import {ModuleElement} from '../../../../services/module/module-element';
import {EntityValidator, EntityValidatorStatus} from '../../../../validators/services/entity-validator';
import {Element} from '../../../../services/element/element';
import {ToolbarItemCheckService} from '../../generic-toolbar/services/check/toolbar-item-check.service';
import {GenericElementValidationExecutionStepsFactory} from '../../../services/generic/generic-element-validation-execution-steps-factory';
import {EntityDataStoreService} from '../../../services/entity-data-store.service';
import {ModulesStateService} from '../../../services/modules-state.service';
import {ComponentService} from '../../../services/component-highlight-stack.service';
import {GenericCrudService} from '../../../../services/generic-crud.service';
import {LocalStorageDataService} from '../../../../services/local-storage-data.service';
import {LeasedEmployeeTableService} from './service/leased-employee-table.service';
import {TableColumn} from '../../../../dynamic-table/shared/table-column';
import {DynamicTableComponent} from '../../../../dynamic-table/dynamic-table.component';
import {AssignmentTableService} from './service/assignment-table.service';
import {LayoutService} from '../../../../services/layout-service';
import {Guid} from 'guid-typescript';
import {JobContainerService} from '../../../../../core/job-runner/job-container.service';
import {JobContext} from '../../../../../core/job-runner/context/job.context';
import {RunnableEventRegistry} from '../../../../../core/job-runner/type/runnable-event.registry';
import {PermissionService} from '../../../../services/permission/permission.service';
import {UserSessionService} from '../../../../../core/service/user-session.service';

@Component({
  selector: 'app-custom-assignment',
  styleUrls: ['./assignment.component.scss'],
  templateUrl: './assignment.component.html',
  providers: [
    ExecutorService,
    GenericElementValidationExecutionStepsFactory,
    LeasedEmployeeTableService,
    AssignmentTableService
  ]
})

export class AssignmentComponent extends GenericElementAbstract {
  @Input() element: Element;
  @Input() fields: Array<FieldMetadataGrid>;
  @Input() toolbarItems: any[] = [];
  @Input() statusBarItems: any[] = [];
  @Input() moduleElement: ModuleElement;
  @Input() masterEntity: any = null;
  @Input() masterField: any = null;
  @Input() isPart = false;

  @ViewChild('leasedEmployeeTable', {static: false}) leasedEmployeeTable: DynamicTableComponent;
  @ViewChild('assignmentTable', {static: false}) assignmentTable: DynamicTableComponent;

  public orderPositionsOptions: any[] = [];

  public leasedEmployeeColumns: TableColumn[] = [];
  public leasedEmployeeEntities: any[] = [];
  public leasedEmployeeIsLoadingData = false;
  public leasedEmployeeTotalCount = 0;

  public assignmentColumns: TableColumn[] = [];
  public assignmentEntities: any[] = [];
  public assignmentIsLoadingData = false;
  public assignmentTotalCount = 0;

  public toolbarContextName = 'assignmentComponent';

  protected orderEntity = null;
  protected orderPositionAssignments = {};
  protected selectedOrderPosition = null;

  public constructor(
    protected componentService: ComponentService,
    protected viewContainerRef: ViewContainerRef,
    protected modulesStateService: ModulesStateService,
    protected genericCrudService: GenericCrudService,
    protected entityDataStoreService: EntityDataStoreService,
    protected executorService: ExecutorService,
    protected genericElementValidationExecutionStepsFactory: GenericElementValidationExecutionStepsFactory,
    protected entityValidator: EntityValidator,
    protected userSession: UserSessionService,
    protected toolbarItemCheckService: ToolbarItemCheckService,
    protected leasedEmployeeTableService: LeasedEmployeeTableService,
    protected assignmentTableService: AssignmentTableService,
    protected layoutService: LayoutService,
    protected jobContainerService: JobContainerService,
    protected permissionService: PermissionService,
    public cdr: ChangeDetectorRef
  ) {
    super(componentService, viewContainerRef, entityDataStoreService, modulesStateService, executorService,
      genericElementValidationExecutionStepsFactory, entityValidator, genericCrudService, userSession, permissionService,
      cdr);
  }

  public ngOnInit() {
    super.ngOnInit();

    this.onComponentInit();
  }

  public ngOnDestroy() {
    super.ngOnDestroy();

    this.onDestroyComponent();
  }

  public onComponentInit(): void {
    this.leasedEmployeeColumns = this.leasedEmployeeTableService.getColumns();
    this.assignmentColumns = this.assignmentTableService.getColumns();

    this.layoutService.layoutSizeChanged$.subscribe(() => {
      setTimeout(() => {
        this.setTableScrollHeightAndWidth(this.leasedEmployeeTable);
        this.setTableScrollHeightAndWidth(this.assignmentTable, true);
      }, 50);
    });

    if (this.masterEntity && this.masterEntity.id) {
      this.genericCrudService.getEntity('phoenix/orders', this.masterEntity.id).subscribe((orderEntity) => {
        this.orderEntity = orderEntity;

        this.loadOrderPositions();
      });
    }
  }

  public onDestroyComponent(): void {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  public getSelectedEntity(): any {
    return this.selectedMasterEntity || null;
  }

  public recheckToolbarItems(): void {
    this.toolbarItemCheckService.check(this);
  }

  public onSave(): Observable<any> {
    return observableOf(null);
  }

  public hasChanges(checkEmbedded: boolean = false): boolean {
    return false;
  }

  public onAfterSave(): Observable<any> {
    return observableOf(null);
  }

  public onChange(): Observable<any> {
    return observableOf(null);
  }

  public doValidate(): Observable<EntityValidatorStatus> {
    return observableOf({
      entity: null,
      isValid: true,
      error: '',
      errorFields: []
    });
  }

  public loadOrderPositions(): void {
    this.genericCrudService
      .getEntities('phoenix/orderpositions/offset/0/limit/50/orderby/id/asc?order=' + this.masterEntity.id)
      .subscribe((paginated: any) => {
        this.orderPositionsOptions = [];

        for (const orderPosition of paginated.data) {
          this.orderPositionsOptions = [...this.orderPositionsOptions, {
            label: orderPosition.name || orderPosition.jobPrintText,
            value: orderPosition
          }];
        }
      });
  }

  public onRefresh(): Observable<any> {
    return observableOf(null);
  }

  public onSelectOrderPosition(orderPosition): void {
    this.selectedOrderPosition = orderPosition.value;

    if (!this.orderPositionAssignments.hasOwnProperty(this.selectedOrderPosition.uniqueId)) {
      this.orderPositionAssignments[this.selectedOrderPosition.uniqueId] = [];
      this.assignmentTableService.setParams({
        first: 0,
        rows: 50,
        sortField: 'id',
        sortOrder: 1
      });
      this.assignmentTableService.setFilter('orderPosition', this.selectedOrderPosition.id);
      this.assignmentTableService.loadData().subscribe((paginatedData) => {
        this.orderPositionAssignments[this.selectedOrderPosition.uniqueId] = paginatedData.data;

        this.assignmentEntities = paginatedData.data;
      });
    } else {
      this.assignmentEntities = this.orderPositionAssignments[this.selectedOrderPosition.uniqueId];
    }
  }

  public onLeasedEmployeesFilter(event): void {
    const value = event.originalEvent.target.value;

    this.leasedEmployeeTableService.setFilter(event.column.key, `contains:${value}`);

    this.onLeasedEmployeesLazyLoad(null);
  }

  public onEntityTransfer(transferedEntity): void {
    if (this.selectedOrderPosition) {
      const assignment = {
        uniqueId: Guid.create().toString(),
        leasedEmployee: transferedEntity,
        orderPosition: this.selectedOrderPosition,
        customer: this.orderEntity.customer,
        qualification: this.selectedOrderPosition.qualification,
        order: this.orderEntity,
        collectiveAgreement: this.orderEntity.collectiveAgreement,
        job: this.selectedOrderPosition.job,
        address: this.selectedOrderPosition.address,
        vat: this.orderEntity.vat,
        assignmentDuration: this.selectedOrderPosition.assignmentDuration,
        paymentCondition: this.orderEntity.paymentCondition,
        invoiceInterval: this.orderEntity.invoiceInterval
      };

      this.genericCrudService.customPost('phoenix/assignments', assignment).subscribe((assignmentEntity) => {
        this.assignmentEntities = [assignmentEntity].concat(this.assignmentEntities);
        this.orderPositionAssignments[this.selectedOrderPosition.uniqueId].push(assignmentEntity);
        this.assignmentTable.selectedEntity = assignmentEntity;

        const context = new JobContext();
        context.component = this;
        context.event = RunnableEventRegistry.PostAssignmentAdded;
        context.identifier = Guid.create().toString();

        this.jobContainerService.runRelevantJobs(context);
      });
    }
  }

  public onLeasedEmployeesLazyLoad(event): void {
    this.leasedEmployeeIsLoadingData = true;

    if (event) {
      this.leasedEmployeeTableService.setParams(event);
    }

    this.leasedEmployeeTableService
      .loadData()
      .subscribe((paginated: any) => {
        this.leasedEmployeeEntities = paginated.data;
        this.leasedEmployeeTotalCount = paginated.total;

        this.setTableScrollHeightAndWidth(this.leasedEmployeeTable);

        this.leasedEmployeeIsLoadingData = false;
      });
  }

  protected setTableScrollHeightAndWidth(table: DynamicTableComponent, isAssignmentTable: boolean = false): void {
    const containerHeight = document.getElementById('content-renderer-container').clientHeight;

    if (table && containerHeight) {
      let height = containerHeight;

      height -= 6;

      if (isAssignmentTable) {
        height -= 30;
      }

      table.height = height;
    }
  }
}
