/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./toolbar-item-open-quick-link.component";
import * as i2 from "../../../../../../services/location.service";
var styles_ToolbarItemOpenQuickLinkComponent = [];
var RenderType_ToolbarItemOpenQuickLinkComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_ToolbarItemOpenQuickLinkComponent, data: {} });
export { RenderType_ToolbarItemOpenQuickLinkComponent as RenderType_ToolbarItemOpenQuickLinkComponent };
export function View_ToolbarItemOpenQuickLinkComponent_0(_l) { return i0.ɵvid(2, [], null, null); }
export function View_ToolbarItemOpenQuickLinkComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-toolbar-item-open-quick-link", [], null, null, null, View_ToolbarItemOpenQuickLinkComponent_0, RenderType_ToolbarItemOpenQuickLinkComponent)), i0.ɵdid(1, 245760, null, 0, i1.ToolbarItemOpenQuickLinkComponent, [i2.LocationService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ToolbarItemOpenQuickLinkComponentNgFactory = i0.ɵccf("app-toolbar-item-open-quick-link", i1.ToolbarItemOpenQuickLinkComponent, View_ToolbarItemOpenQuickLinkComponent_Host_0, { params: "params" }, {}, []);
export { ToolbarItemOpenQuickLinkComponentNgFactory as ToolbarItemOpenQuickLinkComponentNgFactory };
