import {ViewRef} from '@angular/core';

export class ChangeDetectorRefHelper {

  public static detectChanges(component): void {
    if (component && component.cdr && !(component.cdr as ViewRef).destroyed) {
      component.cdr.detectChanges();
    }
  }

  public static markForCheck(component): void {
    if (component && component.cdr && !(component.cdr as ViewRef).destroyed) {
      component.cdr.markForCheck();
    }
  }
}
