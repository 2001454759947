
import {of as observableOf, Observable} from 'rxjs';
import {AbstractExecutionStep} from '../../../../../core/executor/abstract-execution-step';
import {ExecutionStepStatus} from '../../../../../core/executor/execution-step-status';

export class OpenAddressEmailWindowExecutionStep extends AbstractExecutionStep {

  doExecute(): Observable<ExecutionStepStatus> {
    const payload = this.getPayload(),
      entity = payload.getValue().entity;

    if (!entity) {
      return this.getFailObservable('You need to pass entity as part of Payload value!');
    }

    return this.doOpen(entity);
  }

  private doOpen(entity): Observable<ExecutionStepStatus> {
    let email = '';
    if (entity.email) {
      email = entity.email;
    } else if (entity.mainAddress) {
      email = entity.mainAddress.email;
    } else {
      email = '';
    }

    if (email) {
      const a = document.createElement('a');
      a.target = '_blank';
      a.href = `mailto:${email}`;
      a.click();
      a.remove();
    }

    return observableOf({status: true, content: null});
  }
}
