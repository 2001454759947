/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./toolbar-item-show-or-hide-contract-of-employment-exit-information.component";
import * as i2 from "../../../../../../form-viewer/form.service";
var styles_ToolbarItemShowOrHideContractOfEmploymentExitInformationComponent = [];
var RenderType_ToolbarItemShowOrHideContractOfEmploymentExitInformationComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_ToolbarItemShowOrHideContractOfEmploymentExitInformationComponent, data: {} });
export { RenderType_ToolbarItemShowOrHideContractOfEmploymentExitInformationComponent as RenderType_ToolbarItemShowOrHideContractOfEmploymentExitInformationComponent };
export function View_ToolbarItemShowOrHideContractOfEmploymentExitInformationComponent_0(_l) { return i0.ɵvid(2, [], null, null); }
export function View_ToolbarItemShowOrHideContractOfEmploymentExitInformationComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-toolbar-item-show-or-hide-contract-of-employment-exit-reason", [], null, null, null, View_ToolbarItemShowOrHideContractOfEmploymentExitInformationComponent_0, RenderType_ToolbarItemShowOrHideContractOfEmploymentExitInformationComponent)), i0.ɵdid(1, 245760, null, 0, i1.ToolbarItemShowOrHideContractOfEmploymentExitInformationComponent, [i2.FormService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ToolbarItemShowOrHideContractOfEmploymentExitInformationComponentNgFactory = i0.ɵccf("app-toolbar-item-show-or-hide-contract-of-employment-exit-reason", i1.ToolbarItemShowOrHideContractOfEmploymentExitInformationComponent, View_ToolbarItemShowOrHideContractOfEmploymentExitInformationComponent_Host_0, { params: "params" }, {}, []);
export { ToolbarItemShowOrHideContractOfEmploymentExitInformationComponentNgFactory as ToolbarItemShowOrHideContractOfEmploymentExitInformationComponentNgFactory };
