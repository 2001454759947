<p-table
  #dt
  [value]="expertSearches"
  selectionMode="single"
  [(selection)]="selectedExpertSearch"
  (onRowSelect)="onExpertSearchSelect($event)"
  [loading]="isDataLoading"
  scrollable="true"
  styleClass="srollable-datatable-layout"
  [rows]="20" [paginator]="true" [pageLinks]="4" [rowsPerPageOptions]="[20,50,100]"
>
  <ng-template pTemplate="caption">
    <div class="header">
      <div class="datatable-header-left">
        <div class="datatable-header-text"><span>Expert Search</span></div>
      </div>

      <button type="button" pButton pTooltip="{{'COMMON.ADD' | translate}}" tooltipPosition="top" iconUrl="https://demoapi.hr-puzzle.eu/assets/hr-puzzle/images/icons/add.png" (click)="onAddExpertSearch($event)"></button>
      <button type="button" [disabled]="!selectedExpertSearch || !selectedExpertSearch.id" pButton pTooltip="{{'COMMON.DELETE' | translate}}" tooltipPosition="top" iconUrl="https://demoapi.hr-puzzle.eu/assets/hr-puzzle/images/icons/cross.png" (click)="onRemoveExpertSearch()"></button>
    </div>
  </ng-template>
  <ng-template pTemplate="header">
    <tr>
      <th pResizableColumn [pSortableColumn]="'name'">
        Name
        <p-sortIcon [field]="'name'"></p-sortIcon>
      </th>
      <th pResizableColumn>
        Datamodel
        <p-sortIcon [field]="'datamodel'"></p-sortIcon>
      </th>
    </tr>
    <tr>
      <th pResizableColumn>
        <input type="search" pInputText placeholder="Contains" (keyup)="dt.filterGlobal($event.target.value, 'contains')" style="background-color: rgb(255, 255, 186);">
      </th>
      <th pResizableColumn>
        <input type="search" pInputText placeholder="Contains" (keyup)="dt.filterGlobal($event.target.value, 'contains')" style="background-color: rgb(255, 255, 186);">
      </th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-expertSearch>
    <tr [pSelectableRow]="expertSearch">
      <td>{{expertSearch.name}}</td>
      <td>{{expertSearch.datamodel.name}}</td>
    </tr>
  </ng-template>
</p-table>
