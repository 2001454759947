<a (click)="onItemClick(item)" *ngIf="!item.isSeparator && item.isCustomMenuItem" [pTooltip]="item.tooltip" [tooltipPosition]="menuPosition == 'right' ? 'left' : 'right'">
  <div class="item-container item-text-container">
    {{item.label}}
  </div>
</a>
<p-dialog
  *ngIf="isDialogVisible"
  [(visible)]="isDialogVisible"
  header="Rechnungen"
  showEffect="fade"
  [width]="500"
  [height]="400"
  appendTo="body"
>

  <p-toolbar styleClass="no-round-corner">
    <div class="buttons-container">
      <div class="item-spacer"></div>
      <div class="item-right">
        <button
          type="button"
          pButton
          (click)="onExport($event)"
          label="Export"
        ></button>
      </div>
    </div>
  </p-toolbar>
  <p-listbox
    [options]="invoices"
    [(ngModel)]="selectedInvoices"
    appSeleniumDirective
    [checkbox]="checkbox"
    filter="filter"
    id="bdmExportInvoices"
    multiple="multiple"
    [listStyle]="{ 'max-height': '100px' }"
  ></p-listbox>
</p-dialog>
