import { Observable } from 'rxjs';
import { AbstractGenericElementActionHandler } from './abstract-generic-element-action-handler';
import { PermissionService } from '../../permission/permission.service';
import { GenericElementEvent } from '../event/generic-element-event';
import { ActionHandlerSubscribeResponse } from '../../../../core/events/interfaces/action-handler-subscribe-response';
import { ToolbarItem } from '../../element/toolbar-item';
import { GenericElementAbstract } from '../../../content-renderer/elements/generic-element-abstract.component';

export class GenericElementInitToolbarItemsActionHandler extends AbstractGenericElementActionHandler {

    public constructor(
        private permissionService: PermissionService
    ) {
        super();
    }
    
    protected doAction(event: GenericElementEvent): Observable<ActionHandlerSubscribeResponse> {
        let component = event.getComponent();

        if (component instanceof GenericElementAbstract) {

        }

        return this.getObservable(true);
    }
    
}
