import {
  Component, Input
} from '@angular/core';
import {SimpleSearchField} from '../../../../services/simple-search/simple-search';
import {AbstractGenericGridComponent} from '../../abstract-generic-grid.component';

@Component({
  selector: 'app-generic-grid-simple-search-field',
  templateUrl: './generic-grid-simple-search-field.component.html',
  styleUrls: ['./generic-grid-simple-search-field.component.scss']
})
export class GenericGridSimpleSearchFieldComponent {
  @Input() public field: SimpleSearchField = null;
  @Input() public component: AbstractGenericGridComponent = null;

  public isDistance(field: SimpleSearchField): boolean {
    return field.meta.entityFieldName === 'postalCode' &&
      (
        field.meta.associationEndpoint === 'phoenix/addresses' ||
        field.meta.associationEndpoint === 'phoenix/localities'
      );
  }
}
