/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./toolbar-item-show-without-master-filter-field.component";
import * as i2 from "../../../../../../../core/service/user-session.service";
import * as i3 from "../../../../generic-grid/services/generic-grid-global-filter.service";
import * as i4 from "../../../../../services/component-highlight-stack.service";
var styles_ToolbarItemShowWithoutMasterFilterField = [];
var RenderType_ToolbarItemShowWithoutMasterFilterField = i0.ɵcrt({ encapsulation: 2, styles: styles_ToolbarItemShowWithoutMasterFilterField, data: {} });
export { RenderType_ToolbarItemShowWithoutMasterFilterField as RenderType_ToolbarItemShowWithoutMasterFilterField };
export function View_ToolbarItemShowWithoutMasterFilterField_0(_l) { return i0.ɵvid(2, [], null, null); }
export function View_ToolbarItemShowWithoutMasterFilterField_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "toolbar-item-show-without-master-filter-field", [], null, null, null, View_ToolbarItemShowWithoutMasterFilterField_0, RenderType_ToolbarItemShowWithoutMasterFilterField)), i0.ɵdid(1, 245760, null, 0, i1.ToolbarItemShowWithoutMasterFilterField, [i2.UserSessionService, i3.GenericGridGlobalFilterService, i4.ComponentService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ToolbarItemShowWithoutMasterFilterFieldNgFactory = i0.ɵccf("toolbar-item-show-without-master-filter-field", i1.ToolbarItemShowWithoutMasterFilterField, View_ToolbarItemShowWithoutMasterFilterField_Host_0, { params: "params" }, {}, []);
export { ToolbarItemShowWithoutMasterFilterFieldNgFactory as ToolbarItemShowWithoutMasterFilterFieldNgFactory };
