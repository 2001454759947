import { Pipe, PipeTransform } from '@angular/core';
import * as webcrypto from 'webcrypto';

@Pipe({
  name: 'genHash',
  pure: true
})
export class GenerateHashPipe implements PipeTransform {
  transform(hashData = 'HashIT', algorithm = 'sha512', digestEncoding = 'hex', asNumber = false): string | number {
    const hash = webcrypto
      .createHash(algorithm)
      .update(hashData)
      .digest(digestEncoding);

    return (asNumber ? +hash : hash);
  }
}
