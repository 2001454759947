import {ChangeDetectionStrategy, Component} from '@angular/core';
import {ToolbarItemAbstract} from '../toolbar-item-abstract.component';
import {AbstractGenericGridComponent} from '../../../../abstract-generic-grid.component';
import {ModuleElementFieldCrudService} from '../../../../../../services/module/module-element-field.crud.service';
import {RequestCachingService} from '../../../../../../services/request-caching.service';
import {cloneDeep} from 'lodash';
import {FieldMetadataGrid} from '../../../../../../services/module/module-element-field-metadata-grid';
import {Constants} from '../../../../../../../constants';
import {GenericCrudService} from '../../../../../../services/generic-crud.service';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-toolbar-item-work-hour-monthly-grid-recalculate',
  template: ``
})
export class ToolbarItemWorkHourMonthlyGridRecalculateComponent extends ToolbarItemAbstract {

  public constructor(
    protected requestCachingService: RequestCachingService,
    protected genericCrudService: GenericCrudService
  ) {
    super();
  }

  public click() {
    const component = this.getComponent();

    if (component instanceof AbstractGenericGridComponent && component.selectedMasterEntity) {
      this.genericCrudService.customPut(
        'phoenix/workhourmonthlyentries/recalculate/' + component.selectedMasterEntity.id, {}
        ).subscribe(result => {
          this.requestCachingService.removeBundleRequestResponsesWithApi(`api/${component.getElementDataModelApiRoute()}`);
          component.loadEntities();
        });
    }
  }
}
