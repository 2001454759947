import {Component, OnInit, OnDestroy, Input, ChangeDetectorRef} from '@angular/core';
import {AbstractGenericGridComponent} from '../content-renderer/elements/abstract-generic-grid.component';

@Component({
  selector: 'company-search',
  templateUrl: './company-search.component.html',
  styleUrls: ['./company-search.component.scss']
})
export class CompanySearch implements OnInit, OnDestroy {

  @Input() gridComponent: AbstractGenericGridComponent = null;
  @Input() customerImportEndpoint = 'phoenix/customers';

  public context = 'grid';
  public company: any;

  constructor(
    public cdr: ChangeDetectorRef
  ) {}

  ngOnInit() {}

  ngOnDestroy() {}
}
