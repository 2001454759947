/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "../../../../../../node_modules/primeng/components/progressbar/progressbar.ngfactory";
import * as i3 from "primeng/components/progressbar/progressbar";
import * as i4 from "../../../../../../node_modules/primeng/components/toast/toast.ngfactory";
import * as i5 from "primeng/components/toast/toast";
import * as i6 from "primeng/components/common/messageservice";
import * as i7 from "primeng/components/common/shared";
import * as i8 from "./progress-bar-toast.component";
import * as i9 from "@ngx-translate/core";
var styles_ProgressBarToastComponent = ["[_nghost-%COMP%]     .ui-toast-message-content {\n      background: white;\n      padding: 10px;\n    }\n\n      .ui-dialog-mask {\n      z-index: 4999;\n    }"];
var RenderType_ProgressBarToastComponent = i0.ɵcrt({ encapsulation: 0, styles: styles_ProgressBarToastComponent, data: {} });
export { RenderType_ProgressBarToastComponent as RenderType_ProgressBarToastComponent };
function View_ProgressBarToastComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "h3", [], null, null, null, null, null)), (_l()(), i0.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var currVal_0 = _v.parent.context.$implicit.summary; _ck(_v, 1, 0, currVal_0); }); }
function View_ProgressBarToastComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i0.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var currVal_0 = _v.parent.context.$implicit.detail; _ck(_v, 1, 0, currVal_0); }); }
function View_ProgressBarToastComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 5, "div", [["style", "text-align: center"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 0, "i", [["class", "pi pi-exclamation-info"], ["style", "font-size: 3em"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ProgressBarToastComponent_2)), i0.ɵdid(3, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ProgressBarToastComponent_3)), i0.ɵdid(5, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵeld(6, 0, null, null, 3, "div", [], null, null, null, null, null)), (_l()(), i0.ɵeld(7, 0, null, null, 2, "p-progressBar", [["mode", "indeterminate"]], null, null, null, i2.View_ProgressBar_0, i2.RenderType_ProgressBar)), i0.ɵdid(8, 49152, null, 0, i3.ProgressBar, [], { style: [0, "style"], mode: [1, "mode"] }, null), i0.ɵpod(9, { "height": 0 })], function (_ck, _v) { var currVal_0 = _v.context.$implicit.summary; _ck(_v, 3, 0, currVal_0); var currVal_1 = _v.context.$implicit.detail; _ck(_v, 5, 0, currVal_1); var currVal_2 = _ck(_v, 9, 0, "10px"); var currVal_3 = "indeterminate"; _ck(_v, 8, 0, currVal_2, currVal_3); }, null); }
export function View_ProgressBarToastComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, "p-toast", [["key", "progressBar"], ["position", "top-center"]], null, null, null, i4.View_Toast_0, i4.RenderType_Toast)), i0.ɵdid(1, 1294336, null, 1, i5.Toast, [i6.MessageService], { key: [0, "key"], baseZIndex: [1, "baseZIndex"], position: [2, "position"], modal: [3, "modal"] }, null), i0.ɵqud(603979776, 1, { templates: 1 }), (_l()(), i0.ɵand(0, null, null, 1, null, View_ProgressBarToastComponent_1)), i0.ɵdid(4, 16384, [[1, 4]], 0, i7.PrimeTemplate, [i0.TemplateRef], { name: [0, "name"] }, null)], function (_ck, _v) { var currVal_0 = "progressBar"; var currVal_1 = 5000; var currVal_2 = "top-center"; var currVal_3 = true; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3); var currVal_4 = "message"; _ck(_v, 4, 0, currVal_4); }, null); }
export function View_ProgressBarToastComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-progress-bar-toast", [], null, null, null, View_ProgressBarToastComponent_0, RenderType_ProgressBarToastComponent)), i0.ɵdid(1, 114688, null, 0, i8.ProgressBarToastComponent, [i9.TranslateService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ProgressBarToastComponentNgFactory = i0.ɵccf("app-progress-bar-toast", i8.ProgressBarToastComponent, View_ProgressBarToastComponent_Host_0, { message: "message", toastService: "toastService" }, {}, []);
export { ProgressBarToastComponentNgFactory as ProgressBarToastComponentNgFactory };
