import {Injectable} from '@angular/core';
import {QuestionnaireComponent} from '../questionnaire.component';
import {ToolbarItem} from '../../../../../services/element/toolbar-item';
import {PermissionService} from '../../../../../services/permission/permission.service';

@Injectable()
export class QuestionnaireLayoutService {

  public questionnaireComponent: QuestionnaireComponent;

  public constructor(
    private permissionService: PermissionService
  ) {

  }

  public setQuestionsContainerHeight(): void {
    if (this.questionnaireComponent.elementRef) {
      const questionsContainer = this.questionnaireComponent.elementRef.nativeElement.querySelector('.ui-dataview-content');

      if (questionsContainer) {
        const genericElementContainer = questionsContainer.closest('.generic-element-container');

        let height = genericElementContainer.clientHeight - 43;

        if (this.questionnaireComponent.toolbarItems.length > 0) {
          height -= 34;
        }

        if (this.questionnaireComponent.statusBarItems.length > 0) {
          height -= 34;
        }

        questionsContainer.style.height = `${height}px`;
      }
    }
  }
}
