import {ChangeDetectorRef, Component, ElementRef, OnInit} from '@angular/core';
import {AbstractGridColumnComponent} from '../abstract-grid-column.component';
import {FormViewerComponent} from '../../../../../form-viewer/form-viewer.component';
import {GenericTurboGridComponent} from '../../../generic-turbo-grid/generic-turbo-grid.component';
import {ModulesStateService} from '../../../../services/modules-state.service';
import {EntityManagerService} from '../../../../../../core/service/entity-manager/entity-manager.service';
import {ChangeDetectorRefHelper} from '../../../../../helpers/change-detector-ref.helper';
import {EntityStatus} from '../../../../../services/entity/entity-status';

@Component({
  selector: 'app-printer-selection-column',
  template: `
    <p-checkbox
      pInputCheckbox
      binary="true"
      (onChange)="onChange($event)"
      [ngModel]="isSelected()"
    ></p-checkbox>
  `
})
export class PrinterSelectionColumnComponent extends AbstractGridColumnComponent implements OnInit {

  protected formComponent: FormViewerComponent = null;
  protected masterComponent: GenericTurboGridComponent = null;

  constructor(
    public cdr: ChangeDetectorRef,
    protected elementRef: ElementRef,
    protected modulesStateService: ModulesStateService,
    protected entityManager: EntityManagerService
  ) {
    super(elementRef)
  }

  ngOnInit() {
    this.initComponents();
  }

  onChange(event) {
    if (event) {
      this.selectEntity(this.entity, this.node);
    } else {
      this.unSelectEntity(this.entity, this.node);
    }
  }

  private initComponents(): void {
    const components = this.modulesStateService.getCurrent().getComponents();

    let formComponent = null,
    masterComponent = null;

    for (const component of components) {
      if (component instanceof FormViewerComponent) {
        formComponent = component;
      }

      if (component.elementContext.isMaster) {
        masterComponent = component;
      }
    }

    if (formComponent) {
      this.formComponent = formComponent;

      formComponent.entityChange.subscribe((aEntity) => {
        ChangeDetectorRefHelper.detectChanges(this);
      })
    }

    if (masterComponent) {
      this.masterComponent = masterComponent;
    }
  }

  private selectEntity(entity, node?: TreeNode) {
    if (this.formComponent && this.formComponent.entity) {
      if (typeof this.formComponent.entity.users === 'undefined') {
        this.formComponent.entity.users = [];
      }
      this.formComponent.entity.users = [...this.formComponent.entity.users, entity];
    }

    if (this.masterComponent) {
      this.masterComponent.setSelectedEntity(this.formComponent.entity);

      this.entityManager.persistMore(this.masterComponent.getSelectedEntity(), [
        {property: EntityStatus.ENTITY_CHANGED_FLAG, newValue: true, force: true},
        {property: 'users', newValue: this.formComponent.entity.users, force: true}
      ]);

      ChangeDetectorRefHelper.detectChanges(this.masterComponent);
    }
  }

  private unSelectEntity(entity, node?: TreeNode) {
    if (this.formComponent && this.formComponent.entity) {
      const usersIndex = this.formComponent.entity.users.findIndex((aUser) => {
        return aUser && entity && aUser.id === entity.id;
      })

      this.formComponent.entity.users.splice(usersIndex, 1)
    }

    if (this.masterComponent) {
      this.masterComponent.setSelectedEntity(this.formComponent.entity);

      this.entityManager.persistMore(this.masterComponent.getSelectedEntity(), [
        {property: EntityStatus.ENTITY_CHANGED_FLAG, newValue: true, force: true},
        {property: 'users', newValue: this.formComponent.entity.users, force: true}
      ]);

      ChangeDetectorRefHelper.detectChanges(this.masterComponent);
    }
  }

  public isSelected(): boolean {
    return this.entity && this.entity.id && this.formComponent && this.formComponent.entity &&
      this.formComponent.entity.users &&
      this.formComponent.entity.users instanceof Array &&
      this.formComponent.entity.users.findIndex((aEntity) => {
        return aEntity && aEntity.id === this.entity.id;
      }) !== -1;
  }
}
