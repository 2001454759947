import {FormViewerComponent} from '../../../../../../../form-viewer/form-viewer.component';
import {FormService} from '../../../../../../../form-viewer/form.service';
import {Observable, of as observableOf} from 'rxjs';

export class ContractOfEmploymentFormEldaService {

  private form: FormViewerComponent = null;
  private formService: FormService = null;

  public setForm(form: FormViewerComponent): this {
    this.form = form;
    return this;
  }

  public setFormService(formService: FormService): this {
    this.formService = formService;
    return this;
  }

  public hasEldaData(): Observable<any> {
    const entity = this.form.getSelectedEntity();

    if (entity && entity.id) {
      return this.formService.getGenericCrudService().get('phoenix/eldaexports', {contractOfEmployment: entity.id});
    }
    return observableOf(false);
  }
}
