import {Component, OnInit} from '@angular/core';
import {AbstractGridFilter} from '../../../generic-grid/filters/abstract-grid-filter';
import {GenericGridSimpleSearchFieldAssociationComponent} from './generic-grid-simple-search-field-association.component';

@Component({
  selector: 'app-generic-grid-simple-search-field-association-many',
  template: `
    <div class="ui-g">
      <div class="ui-g ui-g-10">
        <p-autoComplete
          field="label"
          [(ngModel)]="selectedValues"
          [multiple]="true"
          [suggestions]="dropdownOptions"
          (completeMethod)="onCompleteMethod($event)"
          (onKeyUp)="onKeyUp($event)"
          (onSelect)="onSelect($event)"
          (onUnselect)="onUnSelect($event)"
          [dropdown]="true"
          [style]="{'width': '100%'}"
          [size]="30"
          [minLength]="2"
          placeholder="---"
          appendTo="body"
        ></p-autoComplete>
      </div>

      <div class="ui-g ui-g-2">
        <p-toggleButton
          [(ngModel)]="fieldComparator"
          (onChange)="onComparatorChange()"
          onLabel="Any"
          offLabel="All"
          onIcon="pi pi-check"
          offIcon="pi pi-times"
          [style]="{'width':'50px'}"
        ></p-toggleButton>
      </div>
    </div>
  `,
  styles: [`
    p-autocomplete ::ng-deep .ui-autocomplete-multiple-container  {
      width: 300px;
    }

    .ui-g {
      margin: 0;
      padding: 0;
    }
  `]
})
export class GenericGridSimpleSearchFieldAssociationManyComponent extends GenericGridSimpleSearchFieldAssociationComponent
  implements OnInit {

  public fieldComparator;

  public ngOnInit(): void {
    if (!this.field.comparator) {
      this.field.comparator = AbstractGridFilter.MATCH_MODE_MANY_IN;
    }

    this.initEdit();
  }

  public onComparatorChange(): void {
    this.field.comparator = this.fieldComparator ? AbstractGridFilter.MATCH_MODE_MANY_IN : AbstractGridFilter.MATCH_MODE_EXACT_IN;
  }

  protected initEdit(): void {
    super.initEdit();

    this.fieldComparator = this.field.comparator === AbstractGridFilter.MATCH_MODE_MANY_IN;
  }
}
