/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./company-product-grid/ksv-company-product-grid.component.ngfactory";
import * as i2 from "./company-product-grid/ksv-company-product-grid.component";
import * as i3 from "@ngx-translate/core";
import * as i4 from "../../../core/message/message-growl.service";
import * as i5 from "../../services/generic-crud.service";
import * as i6 from "./company-product-details/ksv-company-product-details.component.ngfactory";
import * as i7 from "./company-product-details/ksv-company-product-details.component";
import * as i8 from "primeng/components/dom/domhandler";
import * as i9 from "primeng/components/button/button";
import * as i10 from "../../p-button/p-button.directive";
import * as i11 from "./company-search-grid/ksv-company-search-grid.component.ngfactory";
import * as i12 from "./company-search-grid/ksv-company-search-grid.component";
import * as i13 from "@angular/common";
import * as i14 from "./ksv-company-search.component";
var styles_KsvCompanySearchComponent = [];
var RenderType_KsvCompanySearchComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_KsvCompanySearchComponent, data: {} });
export { RenderType_KsvCompanySearchComponent as RenderType_KsvCompanySearchComponent };
function View_KsvCompanySearchComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "app-ksv-company-product-grid", [], null, [[null, "onProductChanged"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("onProductChanged" === en)) {
        var pd_0 = (_co.onProductChanged($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i1.View_KsvCompanyProductGridComponent_0, i1.RenderType_KsvCompanyProductGridComponent)), i0.ɵdid(2, 114688, null, 0, i2.KsvCompanyProductGridComponent, [i3.TranslateService, i4.MessageGrowlService, i5.GenericCrudService, i0.ChangeDetectorRef], { company: [0, "company"] }, { onProductChanged: "onProductChanged" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.company; _ck(_v, 2, 0, currVal_0); }, null); }
function View_KsvCompanySearchComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "app-ksv-company-product-details", [], null, null, null, i6.View_KsvCompanyProductDetailsComponent_0, i6.RenderType_KsvCompanyProductDetailsComponent)), i0.ɵdid(2, 114688, null, 0, i7.KsvCompanyProductDetailsComponent, [i3.TranslateService, i4.MessageGrowlService, i5.GenericCrudService, i0.ChangeDetectorRef], { company: [0, "company"], product: [1, "product"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.company; var currVal_1 = _co.product; _ck(_v, 2, 0, currVal_0, currVal_1); }, null); }
function View_KsvCompanySearchComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 7, "div", [["class", "ui-grid-row"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 6, "div", [["class", "ui-grid-col-1"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 5, "button", [["pButton", ""], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onGoBack() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵprd(4608, null, i8.DomHandler, i8.DomHandler, []), i0.ɵdid(4, 4341760, null, 0, i9.ButtonDirective, [i0.ElementRef], { label: [0, "label"] }, null), i0.ɵpid(131072, i3.TranslatePipe, [i3.TranslateService, i0.ChangeDetectorRef]), i0.ɵdid(6, 4341760, null, 0, i10.PButtonDirective, [i0.ElementRef], { label: [0, "label"] }, null), i0.ɵpid(131072, i3.TranslatePipe, [i3.TranslateService, i0.ChangeDetectorRef])], function (_ck, _v) { var currVal_0 = i0.ɵinlineInterpolate(1, "", i0.ɵunv(_v, 4, 0, i0.ɵnov(_v, 5).transform("COMMON.BACK")), ""); _ck(_v, 4, 0, currVal_0); var currVal_1 = i0.ɵinlineInterpolate(1, "", i0.ɵunv(_v, 6, 0, i0.ɵnov(_v, 7).transform("COMMON.BACK")), ""); _ck(_v, 6, 0, currVal_1); }, null); }
export function View_KsvCompanySearchComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 10, "div", [["class", "ui-grid ui-grid-responsive ui-fluid"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 7, "div", [], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 2, "div", [], [[8, "hidden", 0]], null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 1, "app-ksv-company-search-grid", [], null, [[null, "onCompanyChanged"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("onCompanyChanged" === en)) {
        var pd_0 = (_co.onCompanyChanged($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i11.View_KsvCompanySearchGridComponent_0, i11.RenderType_KsvCompanySearchGridComponent)), i0.ɵdid(4, 114688, null, 0, i12.KsvCompanySearchGridComponent, [i3.TranslateService, i4.MessageGrowlService, i5.GenericCrudService, i0.ChangeDetectorRef], null, { onCompanyChanged: "onCompanyChanged" }), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_KsvCompanySearchComponent_1)), i0.ɵdid(6, 16384, null, 0, i13.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_KsvCompanySearchComponent_2)), i0.ɵdid(8, 16384, null, 0, i13.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_KsvCompanySearchComponent_3)), i0.ɵdid(10, 16384, null, 0, i13.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; _ck(_v, 4, 0); var currVal_1 = (_co.context === "company-product-grid"); _ck(_v, 6, 0, currVal_1); var currVal_2 = (_co.context === "company-product-details"); _ck(_v, 8, 0, currVal_2); var currVal_3 = ((_co.context === "company-product-grid") || (_co.context === "company-product-details")); _ck(_v, 10, 0, currVal_3); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.context !== "company-search-grid"); _ck(_v, 2, 0, currVal_0); }); }
export function View_KsvCompanySearchComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-ksv-company-search", [], null, null, null, View_KsvCompanySearchComponent_0, RenderType_KsvCompanySearchComponent)), i0.ɵdid(1, 49152, null, 0, i14.KsvCompanySearchComponent, [i3.TranslateService, i4.MessageGrowlService, i0.ChangeDetectorRef], null, null)], null, null); }
var KsvCompanySearchComponentNgFactory = i0.ɵccf("app-ksv-company-search", i14.KsvCompanySearchComponent, View_KsvCompanySearchComponent_Host_0, { gridComponent: "gridComponent" }, {}, []);
export { KsvCompanySearchComponentNgFactory as KsvCompanySearchComponentNgFactory };
