import { ExecutionStep } from "app/core/executor/execution-step";
import { ExecutionStepStatus } from "app/core/executor/execution-step-status";

export abstract class ExecutionStatus {

    executionStep: ExecutionStep = null;
    executionStepStatus: ExecutionStepStatus;

    public abstract isSuccess();

    public constructor(executionStepStatus: ExecutionStepStatus, executionStep: ExecutionStep) {
        this.executionStepStatus = executionStepStatus;
        this.executionStep = executionStep;
    }

    public getStep(): ExecutionStep {
        return this.executionStep;
    }

    public getStepContent() {
        return this.executionStepStatus.content;
    }

    public getStepStatus(): boolean {
        return this.executionStepStatus.status;
    }

    public getStatus(): ExecutionStepStatus|null {
        return this.executionStepStatus;
    }
}
