
import {of as observableOf,  Observable } from 'rxjs';
import { ValidationParam, ValidationStatus } from '../services/validation';
import { ModuleElementField } from '../../services/module/module-element-field';
import { Element } from '../../form-viewer/models/element';
import {EntityHydrator} from '../../services/entity-hydrator.service';
import {GenericElementAbstract} from '../../content-renderer/elements/generic-element-abstract.component';

export interface Validator {
  isValid(value: any): boolean;
  getErrorTranslateKey(value: any): string;
  getErrorTranslateParams(value: any): any;
}

export abstract class AbstractValidator implements Validator {

  private params: any[] = [];
  private entity: any = null;
  private value: any = null;
  private field: ModuleElementField|Element = null;
  private context: string = '';
  protected entityHydrator: EntityHydrator;
  protected component: GenericElementAbstract = null;

  constructor(
    params: any[]
  ) {
    this.params = params;
  }

  public abstract isValid(value: any): boolean;
  public abstract getErrorTranslateKey(value: any): string;
  public abstract getErrorTranslateParams(value: any): any;

  public validate(): Observable<ValidationStatus> {
    return observableOf({
      isValid: this.isValid(this.getEntityFieldValue()),
      errorTranslated: '',
      errorTranslateKey: this.getErrorTranslateKey(this.getEntityFieldValue()),
      errorTranslateParams: this.getErrorTranslateParams(this.getEntityFieldValue()),
      field: this.getField(),
      value: this.getEntityFieldValue()
    });
  }

  public getComponent(): GenericElementAbstract|null {
    return this.component;
  }

  public setComponent(component: GenericElementAbstract): this {
    this.component = component;
    return this;
  }

  protected getParams(): ValidationParam[] {
    return this.params || [];
  }

  protected getParam(key: string): ValidationParam|null {
    let foundParam = null;

    for (let param of this.params) {
      if (param.key === key) {
        foundParam = param;
        break;
      }
    }

    return foundParam;
  }

  protected getParamValue(key: string): any|null {
    let value = null,
      param = this.getParam(key);

    return param && typeof param.value !== 'undefined' && param.value !== null ? param.value : null;
  }

  protected getEntity(): any|null {
    return this.entity;
  }

  public setEntity(entity: any): this {
    this.entity = entity;
    return this;
  }

  public getField(): any|null {
    return this.field;
  }

  public getFieldName(): string {
    if (this.getContext() === 'grid') {
      return this.getField().id;
    } else {
      return this.getField().datamodelField || this.getField().label;
    }
  }

  public setField(field: ModuleElementField|Element): this {
    this.field = field;
    return this;
  }

  protected getEntityFieldValue(): any|null {
    let value = null;

    if (this.getEntity() && this.getField()) {
      if (this.getContext() === 'grid') {
        value = this.entityHydrator.getEntityPropertyValue(this.getEntity(),this.getField().name);
      } else {
        value = this.getEntity()[this.getField().datamodelField];
      }
    }

    return value;
  }

  public setContext(context: string): this {
    this.context = context;
    return this;
  }

  public getContext(): string {
    return this.context;
  }

  public setEntityHydrator(entityHydrator: EntityHydrator): this {
    this.entityHydrator = entityHydrator;
    return this;
  }

  public getEntityHydrator(): EntityHydrator {
    return this.entityHydrator;
  }
}
