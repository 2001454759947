import {ChangeDetectionStrategy, Component, Input, OnInit} from '@angular/core';
import { ToolbarItemAbstract } from '../toolbar-item-abstract.component';
import { ToolbarItemPrintService } from '../../services/toolbar-item-print.service';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'toolbar-item-print',
  templateUrl: './toolbar-item-print.component.html',
  providers: [ToolbarItemPrintService]
})
export class ToolbarItemPrint extends ToolbarItemAbstract {

  constructor(
    private toolbarItemPrintService: ToolbarItemPrintService
  ) {
    super();
  }

  public click() {
    if (this.getParam('printSectionId')) {
      this.doPrint(this.getParam('printSectionId'));
    }
  }

  private doPrint(printSectionId) {
    let popupWinindow
    let innerContents = document.getElementById(printSectionId).innerHTML;
    popupWinindow = window.open('', '_blank', 'width=600,height=700,scrollbars=no,menubar=no,toolbar=no,location=no,status=no,titlebar=no');
    popupWinindow.document.open();
    popupWinindow.document.write('<html><head><link rel="stylesheet" type="text/css" href="node_modules/primeng/resources/primeng.min.css" /></head><body onload="window.print()">' + innerContents + '</html>');
    popupWinindow.document.close();
  }
}
