
import {Observable} from 'rxjs';

import {map, switchMap} from 'rxjs/operators';
import {AbstractExecutionStep} from '../../../../core/executor/abstract-execution-step';
import {ExecutionStepStatus} from '../../../../core/executor/execution-step-status';
import {MessageGrowlService} from '../../../../core/message/message-growl.service';
import {TOAST_LIFE_INFINITE, ToastComponentsRegistry, ToastService} from '../../../../core/service/toast.service';
import {TranslateService} from '@ngx-translate/core';

export class InvoiceToolbarItemCreateInvoiceCreditExecutionStep extends AbstractExecutionStep {

  public doExecute(): Observable<ExecutionStepStatus> {
    const payload = this.getPayload(),
      component = payload.getValue().component ? payload.getValue().component : payload.getValue();
    return this.doCreate(component);
  }

  private doCreate(component): Observable<ExecutionStepStatus> {
    const entity = component.getSelectedEntity();

    this.getToast().custom(ToastComponentsRegistry.PROGRESS_BAR, {
      severity: 'info',
      life: TOAST_LIFE_INFINITE,
      closable: false,
      summary: this.getTranslate().instant('COMMON.SAVING_PLEASE_WAIT')
    });
    return this.genericCrudService.editEntity(`phoenix/invoices/createcredit/${entity.id}`, {}).pipe(
      switchMap((invoice) => {
        this.getToast().clear(ToastComponentsRegistry.PROGRESS_BAR, 300);
        this.getGrowl().showDataSaved();

        return this.getSuccessObservable();
      }));
  }

  private getGrowl(): MessageGrowlService|null {
    return this.injector.get(MessageGrowlService, null);
  }

  private getToast(): ToastService|null {
    return this.injector.get(ToastService, null);
  }

  private getTranslate(): TranslateService|null {
    return this.injector.get(TranslateService, null);
  }
}
