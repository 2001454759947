import {ArticleTypeRegistry} from './article-type.registry';

export class ArticleTypeLockFieldsRegistry {

  public static readonly FIELD_CODE = 'code';
  public static readonly FIELD_ARTICLE = 'article';
  public static readonly FIELD_SALARY = 'salaryHour';
  public static readonly FIELD_SURCHARGE = 'surcharge';
  public static readonly FIELD_BILL = 'invoiceHour';
  public static readonly FIELD_PERIOD = 'preCalculationArticlePeriod.name';
  public static readonly FIELD_IS_INCLUDED = 'isIncludedInNormalWorkhours';
  public static readonly FIELD_WEEKLY_HOURS = 'weeklyHours';
  public static readonly FIELD_AMOUNT = 'amount';
  public static readonly FIELD_NOTICE = 'notice';

  public static getAllFields(): string[] {
    return [
      ArticleTypeLockFieldsRegistry.FIELD_CODE,
      ArticleTypeLockFieldsRegistry.FIELD_ARTICLE,
      ArticleTypeLockFieldsRegistry.FIELD_SALARY,
      ArticleTypeLockFieldsRegistry.FIELD_SURCHARGE,
      ArticleTypeLockFieldsRegistry.FIELD_BILL,
      ArticleTypeLockFieldsRegistry.FIELD_PERIOD,
      ArticleTypeLockFieldsRegistry.FIELD_IS_INCLUDED,
      ArticleTypeLockFieldsRegistry.FIELD_WEEKLY_HOURS,
      ArticleTypeLockFieldsRegistry.FIELD_AMOUNT,
      ArticleTypeLockFieldsRegistry.FIELD_NOTICE
    ];
  }

  public static getFieldsForLock(type: string): string[] {
    let fields = [];

    switch (type) {
      case ArticleTypeRegistry.TYPE_ALLOWANCE:
        fields = [
          ArticleTypeLockFieldsRegistry.FIELD_SURCHARGE,
          ArticleTypeLockFieldsRegistry.FIELD_AMOUNT,
          ArticleTypeLockFieldsRegistry.FIELD_PERIOD
        ];
        break;
      case ArticleTypeRegistry.TYPE_OVERTIME:
        fields = [
          ArticleTypeLockFieldsRegistry.FIELD_AMOUNT,
          ArticleTypeLockFieldsRegistry.FIELD_IS_INCLUDED,
          ArticleTypeLockFieldsRegistry.FIELD_WEEKLY_HOURS,
          ArticleTypeLockFieldsRegistry.FIELD_PERIOD
        ];
        break;
      case ArticleTypeRegistry.TYPE_EXTRA_PAY:
        fields = [
          ArticleTypeLockFieldsRegistry.FIELD_SURCHARGE,
          ArticleTypeLockFieldsRegistry.FIELD_WEEKLY_HOURS,
          ArticleTypeLockFieldsRegistry.FIELD_PERIOD
        ];
        break;
      case ArticleTypeRegistry.TYPE_OTHER:
        fields = [
          ArticleTypeLockFieldsRegistry.FIELD_SURCHARGE,
          ArticleTypeLockFieldsRegistry.FIELD_AMOUNT,
          ArticleTypeLockFieldsRegistry.FIELD_WEEKLY_HOURS
        ];
        break;
    }

    return fields;
  }

}
