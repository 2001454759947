
    <div id="autocomplete-container" class="autocomplete-container">
      <div class="icon-container ui-g ui-g-1" *ngIf="((element?.icon | isDefined) && (element?.icon?.relativePath | isDefined))">
        <img height="16" width="16"
             [ngClass]="{
          'cur-pointer': isValid(),
          'cur-disabled': !isValid(),
          'disabled': !isValid()
        }"
             [src]="iconBaseUrl + '/' + element?.icon?.relativePath"
             [alt]="element?.icon?.name"
             (click)="onClickIcon()"
        />
      </div>
      <div *ngIf="element.hasCustomButton" class="custom-button-container ui-g ui-g-1">
        <i
          (click)="onCustomButtonClick($event)"
          [class]="isReadOnlyOrDisabled() ? 'fa fa-plus fa-disabled' : 'fa fa-plus'"
          aria-hidden="true"
        >
        </i>
      </div>
    </div>
  