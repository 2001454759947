import {Component, ElementRef} from '@angular/core';
import {AbstractGridColumnComponent} from '../abstract-grid-column.component';
import {GenericTreeGridComponent} from '../../../generic-tree-grid/generic-tree-grid.component';

@Component({
  selector: 'app-tree-node-add-child-column',
  template: `    
    <button 
      pButton
      icon="fa fa-plus"
      [disabled]="!entity || !entity.id"
      pTooltip="{{ 'COMMON.ADD_CHILD_NODE' | translate }}"
      (click)="onAddNode()">
    </button>
  `,
})
export class TreeAddChildNodeComponent extends AbstractGridColumnComponent {

  public constructor(
    protected elementRef: ElementRef
  ) {
    super(elementRef);
  }

  public onAddNode(): void {
    if (this.component instanceof GenericTreeGridComponent) {
      this.component.selectedNode = this.node;
      this.component.selectedEntity = this.entity;
      this.component.startNewEntityAdd(this.component.emptyEntity);
    }
  }
}
