import {Observable, of as observableOf} from 'rxjs';

import {map} from 'rxjs/operators';
import {AbstractExecutionStep} from '../../../../core/executor/abstract-execution-step';
import {ExecutionStepStatus} from '../../../../core/executor/execution-step-status';
import {MessageGrowlService} from '../../../../core/message/message-growl.service';
import {TOAST_LIFE_INFINITE, ToastComponentsRegistry, ToastService} from '../../../../core/service/toast.service';
import {TranslateService} from '@ngx-translate/core';
import {FormViewerComponent} from '../../../form-viewer/form-viewer.component';
import {RequestCachingService} from '../../request-caching.service';
import {Router} from '@angular/router';
import {AbstractGenericGridComponent} from '../../../content-renderer/elements/abstract-generic-grid.component';
import {Entity} from '../../../helpers/entity';

export class SyncDatamodelsExecutionStep extends AbstractExecutionStep {

  public doExecute(): Observable<ExecutionStepStatus> {
    const payload = this.getPayload(),
      component = payload.getValue();

    if (!(component instanceof FormViewerComponent)) {
      return this.getFailObservable('You need to pass FormViewerComponent or GridComponent as Payload value!');
    }

    this.doSync(component);
  }

  private doSync(component: FormViewerComponent): Observable<ExecutionStepStatus> {
    const entity = component.getSelectedEntity();

    this.getToast().custom(ToastComponentsRegistry.PROGRESS_BAR, {
      severity: 'info',
      life: TOAST_LIFE_INFINITE,
      closable: false,
      summary: this.getTranslate().instant('COMMON.SAVING_PLEASE_WAIT')
    });

    const data = {
      'name': Entity.getValue(entity, 'datamodel.name') || Entity.getValueInEmbedded(entity, 'datamodel.name')
    };

    this.genericCrudService.customPost(
      `app/syncservices/execute/sync`, data
    ).subscribe(() => {
      this.getToast().clear(ToastComponentsRegistry.PROGRESS_BAR, 300);
      this.getGrowl().showDataSaved();
    });
    return observableOf({status: true, content: null});
  }

  private getGrowl(): MessageGrowlService|null {
    return this.injector.get(MessageGrowlService, null);
  }

  private getToast(): ToastService|null {
    return this.injector.get(ToastService, null);
  }

  private getTranslate(): TranslateService|null {
    return this.injector.get(TranslateService, null);
  }
}
