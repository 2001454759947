import {Component, ElementRef} from '@angular/core';
import {AbstractGridColumnComponent} from '../../../abstract-grid-column.component';
import {EntityManagerService} from "../../../../../../../../core/service/entity-manager/entity-manager.service";

@Component({
  selector: 'app-comparator-column',
  template: `
    <p-dropdown
        *ngIf="isEditMode()"
        [(ngModel)]="entity.comparator"
        [options]="comparatorsOptions"
        placeholder="---"
        (onChange)="onComparatorChange()"
        [style]="{'width': '100%'}"
        appendTo="body"
    ></p-dropdown>

    <div *ngIf="!isEditMode()">
      {{ entity.comparator }}
    </div>
  `,
  styles: [`
    :host {
      width: 100%;
    }
  `]
})
export class ConditionTreeComparatorColumnComponent extends AbstractGridColumnComponent {

  public comparatorsOptions: SelectItem[] = [
    { value: '<', label: '<' },
    { value: '<=', label: '<=' },
    { value: '=', label: '=' },
    { value: '>=', label: '>=' },
    { value: '>', label: '>' }
  ];

  public constructor(
    protected elementRef: ElementRef,
    protected entityManager: EntityManagerService
  ) {
    super(elementRef);
  }

  public onComparatorChange(): void {
    this.component.flagEntityForBulkSave(this.entity).subscribe(() => {
      this.entityManager.persist(this.entity as any, {property: 'comparator', oldValue: '', newValue: this.entity['comparator'] });
    });
  }
}
