// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.
import { disableDebugTools } from '@angular/platform-browser';
import { enableProdMode, ApplicationRef } from '@angular/core';

//export const BASE_URL = 'https://itkwapi.hr-puzzle.eu';
export const BASE_URL = '/backend';
export const DOWNLOAD_URL = BASE_URL;
export const UPLOAD_URL = `${BASE_URL}/assets/uploads`;
export const API_URL = `${BASE_URL}/api`;
export const PDF_VIEWER_URL = `/pdfviewer/api/pdfviewer`;

const DUMMY_USERNAME = 'dev@hr-puzzle.dev';
const DUMMY_USERPASS = 'W1vovVby';

export const environment: Environment = {
  production: true,
  apiUrl: API_URL,
  baseUrl: BASE_URL,
  downloadUrl: DOWNLOAD_URL,
  memoModuleId: 560,
  memoWithPastRemindersModuleId: 561,
  memoWithResponsibleUserGroupsModuleId: 563,
  uploadUrl: UPLOAD_URL,
  pdfViewerUrl: PDF_VIEWER_URL,
  consoleErrorReporting: false,
  froalaKey: 'CA4B3A2C2zE2A1A9A7A9A1B1A5E1B4mzaB3ecmbuC-7I2yh==',
  dummyUser: <App.User.Dummy>{
    email: DUMMY_USERNAME,
    password: DUMMY_USERPASS
  },
  key: 'itkw',
  logging: true,
  algoliaAppId: 'plKT9Z8C1W5X',
  algoliaApiKey: 'f981d394eb4e45f76a94416d87d30348'
};

// @todo Do we still need this?
let _decorateModuleRef = function identity<T>(value: T): T { return value; };
_decorateModuleRef = (modRef: any) => {

  disableDebugTools();
  enableProdMode();
  const _ng = (<any>window).ng;
  (<any>window).ng.probe = _ng.probe;
  (<any>window).ng.coreTokens = _ng.coreTokens;
  return modRef;
};

export const decorateModuleRef = _decorateModuleRef;
export const env: Environment = environment; // Just an alias for shorter code
