import {AbstractCondition} from '../../../core/job-runner/condition/abstract.condition';
import {JobContext} from '../../../core/job-runner/context/job.context';
import {RunnableEventRegistry} from '../../../core/job-runner/type/runnable-event.registry';
import {GenericTreeGridComponent} from '../../content-renderer/elements/generic-tree-grid/generic-tree-grid.component';

export class SetPrecalculationArticleOtherEmbeddedCondition extends AbstractCondition {
  isApplicable(context: JobContext): boolean {
    const component = context.component;

    return context.event === RunnableEventRegistry.ContextCreated
      && component instanceof GenericTreeGridComponent
      && component.getElementDatamodelEntityName() === 'PhoenixBundle\\Entity\\PreCalculationOtherArticle';
  }
}
