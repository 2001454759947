import { Injectable } from '@angular/core';
import { AuthenticationService } from '../../core/authentication/authentication.service';
import { environment } from '../../../environments/environment';

class HttpRequestResponse {

  request: HttpRequest<any>;
  response: HttpResponse<any>;

  public constructor(request: HttpRequest<any>, response: HttpResponse<any>) {
    this.request = request;
    this.response = response;
  }

  public getRequest(): HttpRequest<any> {
    return this.request;
  }

  public getResponse(): HttpResponse<any> {
    return this.response;
  }
}

import {
  HttpRequest,
  HttpResponse
} from '@angular/common/http';

@Injectable()
export class RequestCachingService {

  public static readonly POOL_LIMIT = 5;

  public poolDictionary: { [urlWithParams: string]: HttpRequestResponse; } = {};

  /**
   *
   * @param request
   * @param response
   * @returns {RequestCachingService}
   */
  public add(request: HttpRequest<any>, response: HttpResponse<any>): RequestCachingService {

    if (this.poolLimitPassed()) {
      const firstUrlWithParams = Object.keys(this.poolDictionary)[0];

      // First in first out
      this.remove(firstUrlWithParams);

      // console.warn(`RequestCachingService pool exceeded the limit of ${RequestCachingService.POOL_LIMIT} request!
      //          Removing first request with url ${firstUrlWithParams}
      //      `);
    }

    if (!this.exists(request.urlWithParams)) {
      this.poolDictionary[request.urlWithParams] = new HttpRequestResponse(request, response);
    }

    return this;
  }

  /**
   *
   * @param urlWithParams
   * @returns {HttpRequestResponse|null}
   */
  public findOne(urlWithParams: string): HttpRequestResponse | null {
    let found = null;

    if (this.exists(urlWithParams)) {
      found = this.poolDictionary[urlWithParams];
    }

    return found;
  }

  public findBundleRequests(urlWithParams: string): HttpRequestResponse[] {
    let me = this,
      found = [];

    const bundleApiRoute = this.getBundleApiRoute(urlWithParams);

    if ('' !== bundleApiRoute) {
      Object.keys(this.poolDictionary).forEach(function(poolUrlWithParams) {
        if (poolUrlWithParams.indexOf(bundleApiRoute) !== -1) {
          found.push(me.poolDictionary[poolUrlWithParams]);
        }
      });
    }

    return found;
  }

  public removeBundleRequestResponsesWithApi(urlWithParams: string): void {
    let bundleRequests = this.findBundleRequests(urlWithParams);

    if (bundleRequests.length > 0) {
      this.removeRequestResponses(bundleRequests);
    }
  }

  /**
   *
   * @param requestResponses
   * @returns {RequestCachingService}
   */
  public removeRequestResponses(requestResponses: HttpRequestResponse[]): RequestCachingService {
    for (let requestResponse of requestResponses) {
      this.remove(requestResponse.getRequest().urlWithParams);
    }

    return this;
  }

  /**
   *
   * @returns {RequestCachingService}
   */
  public removeAllRequestResponses(): RequestCachingService {
    this.poolDictionary = {};

    return this;
  }

  /**
   *
   * @param urlWithParams
   * @returns {boolean}
   */
  public exists(urlWithParams: string): boolean {
    return typeof this.poolDictionary[urlWithParams] !== 'undefined' && this.poolDictionary[urlWithParams] !== null;
  }

  public removeByExpression(urlPart: string): RequestCachingService {
    for (const urlKey of Object.keys(this.poolDictionary)) {
      if (urlKey.indexOf(urlPart) !== -1) {
        this.remove(urlKey);
      }
    }

    return this;
  }

  /**
   * @returns {boolean}
   */
  private poolLimitPassed(): boolean {
    return Object.keys(this.poolDictionary).length > RequestCachingService.POOL_LIMIT;
  }

  /**
   *
   * @param urlWithParams
   * @returns {RequestCachingService}
   */
  private remove(urlWithParams: string): RequestCachingService {

    if (this.exists(urlWithParams)) {
      delete this.poolDictionary[urlWithParams];
    }

    return this;
  }

  /**
   * @description return bundle url based on urlWithParams string
   * @param urlWithParams
   * @returns {string}
   */
  private getBundleApiRoute(urlWithParams: string): string {
    let bundleApiRoute = '';
    const splitUrlWithParams = urlWithParams.split('api/');

    if (splitUrlWithParams.length > 0) {
      const apiRoute = splitUrlWithParams[1];
      let apiRouteSeparator = '?';

      if (apiRoute.indexOf('/') !== -1) {
        apiRouteSeparator = '/';
      }

      const splitApiRoute = apiRoute.split(apiRouteSeparator);

      bundleApiRoute = splitApiRoute[0];
    }

    return bundleApiRoute;
  }
}
