/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./toolbar-item-unlock.component";
import * as i2 from "../../../../../services/component-highlight-stack.service";
import * as i3 from "../../../../../../../core/message/message-growl.service";
import * as i4 from "../../../../../../../core/message/message.service";
import * as i5 from "../../../../../../services/generic-crud.service";
var styles_ToolbarItemUnlockComponent = [];
var RenderType_ToolbarItemUnlockComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_ToolbarItemUnlockComponent, data: {} });
export { RenderType_ToolbarItemUnlockComponent as RenderType_ToolbarItemUnlockComponent };
export function View_ToolbarItemUnlockComponent_0(_l) { return i0.ɵvid(2, [], null, null); }
export function View_ToolbarItemUnlockComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "toolbar-item-unlock", [], null, null, null, View_ToolbarItemUnlockComponent_0, RenderType_ToolbarItemUnlockComponent)), i0.ɵdid(1, 245760, null, 0, i1.ToolbarItemUnlockComponent, [i2.ComponentService, i3.MessageGrowlService, i4.MessageService, i5.GenericCrudService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ToolbarItemUnlockComponentNgFactory = i0.ɵccf("toolbar-item-unlock", i1.ToolbarItemUnlockComponent, View_ToolbarItemUnlockComponent_Host_0, { params: "params" }, {}, []);
export { ToolbarItemUnlockComponentNgFactory as ToolbarItemUnlockComponentNgFactory };
