import { AbstractValidator } from './abstract-validator';

const visaRegex = '^(?:4[0-9]{12})(?:[0-9]{3})?$';
const americanExpressRegex = '^(?:3[47][0-9]{13})$';
const maestroRegex = '^(?:(?:5[0678]\\d\\d|6304|6390|67\\d\\d)\\d{8,15})$';
const jcbRegex = '^(?:(?:2131|1800|35\\d{3})\\d{11})$';
const discoverRegex = '^(?:6(?:011|5[0-9]{2})(?:[0-9]{12}))$';
const dinersclubRegex = '^(?:3(?:0[0-5]|[68][0-9])[0-9]{11})$';
const mastercardRegex = '^(?:5[1-5][0-9]{2}|222[1-9]|22[3-9][0-9]|2[3-6][0-9]{2}|27[01][0-9]|2720)[0-9]{12}$';

export class CreditCartValidator extends AbstractValidator {

  public isValid(value: any): boolean {
    
      if (typeof value === 'undefined' || value === null) {
        value = '';
      }

      return new RegExp(americanExpressRegex + '|' +
          visaRegex + '|' +
          maestroRegex + '|' +
          jcbRegex + '|' +
          discoverRegex + '|' +
          mastercardRegex + '|' +
          dinersclubRegex
      ).test(value.toString());
    };

    public getErrorTranslateKey(value: any): string {
      return 'CREDIT_CARD_FORMAT_IS_NOT_VALID';
    }

    public getErrorTranslateParams(value: any): any {
      return null;
    }
}
