export class ArticleTypeRegistry {

  public static readonly TYPE_ALLOWANCE = 'allowance';
  public static readonly TYPE_GENERAL = 'general';
  public static readonly TYPE_OVERTIME = 'overtime';
  public static readonly TYPE_EXTRA_PAY = 'extra_pay';
  public static readonly TYPE_OTHER = 'other';

  private static getRegistry() {
    const registry = {};

    registry[ArticleTypeRegistry.TYPE_ALLOWANCE] = ['ZULAGE'];
    registry[ArticleTypeRegistry.TYPE_GENERAL] = ['NORM'];
    registry[ArticleTypeRegistry.TYPE_OVERTIME] = ['Ü50', 'Ü100'];
    registry[ArticleTypeRegistry.TYPE_EXTRA_PAY] = ['AUFW'];
    registry[ArticleTypeRegistry.TYPE_OTHER] = ['DIVERSE'];

    return registry;
  }

  public static isOfType(type: string, articleType: string): boolean {
    const registry = ArticleTypeRegistry.getRegistry();

    return registry[type] && registry[type].includes(articleType);
  }

  public static getType(articleType: string): string {
    const registry = ArticleTypeRegistry.getRegistry();

    for (const type in registry) {
      if (ArticleTypeRegistry.isOfType(type, articleType)) {
        return type;
      }
    }

    return null;
  }
}
