import { Injectable } from '@angular/core';
import { ComponentService } from '../../../../services/component-highlight-stack.service';
import { ElementsStackService } from '../../../../services/elements-stack.service';
import { ModulesStateService } from '../../../../services/modules-state.service';
import {CustomButtonCheckInterface} from './custom-button-check-factory.service';
import {AbstractGenericGridComponent} from '../../../abstract-generic-grid.component';


@Injectable()
export class CustomButtonAbstractCheckService implements CustomButtonCheckInterface {
  protected column: any;
  protected entity: any;
  protected component: AbstractGenericGridComponent;

  constructor(
    protected elementStackService: ElementsStackService,
    protected componentService: ComponentService,
    protected modulesStateService: ModulesStateService
  ) {

  }

  setColumn(column: any): CustomButtonCheckInterface {
    this.column = column;
    return this;
  }

  setEntity(entity: any): CustomButtonCheckInterface {
    this.entity = entity;
    return this;
  }

  setComponent(component: AbstractGenericGridComponent): CustomButtonCheckInterface {
    this.component = component;

    return this;
  }

  isVisible(): boolean {
    return false;
  }
}
