import { Element, ElementLayout } from './element';

export class ElementLayoutTabs extends ElementLayout {
  constructor(
    public children: any[] = []
  ) {
    super();
    this.typeElement = 'tabs';
    this.canHaveChildren = true;
  }
}

export class ElementLayoutTabsItem extends ElementLayout {
  constructor(
    public children: any[] = []
  ) {
    super();
    this.typeElement = 'tabs-item';
    this.label = '';
    this.canHaveChildren = true;
  }
}

