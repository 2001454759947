import { ElementInput } from './element';

export class ElementInputRadio extends ElementInput {

  selectedVal: string;

  group: string;

  constructor() {
    super();
    this.typeElement = 'radio';
    this.selectedVal = '';
    this.group = 'default';
  }
}
