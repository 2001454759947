import {Injectable} from '@angular/core';
import {FieldMetadataGrid} from 'app/shared/services/module/module-element-field-metadata-grid';
import {AbstractInlineEditor} from './inline-editor/abstract-inline-editor';
import {DateInlineEditor} from './inline-editor/date-inline-editor';
import {TimeInlineEditor} from './inline-editor/time-inline-editor';
import {DropdownInlineEditor} from './inline-editor/dropdown-inline-editor';
import {MultiselectInlineEditor} from './inline-editor/multiselect-inline-editor';
import {CheckboxInlineEditor} from './inline-editor/checkbox-inline-editor';
import {TextInlineEditor} from './inline-editor/text-inline-editor';
import {DecimalInlineEditor} from './inline-editor/decimal-inline-editor';
import {AutocompleteInlineEditor} from './inline-editor/autocomplete-inline-editor';
import {GenericCrudService} from 'app/shared/services/generic-crud.service';
import {AbstractGenericGridComponent} from '../../elements/abstract-generic-grid.component';
import {LinkInlineEditor} from './inline-editor/link-inline-editor';
import {TriCheckboxInlineEditor} from './inline-editor/tricheckbox-inline-editor';
import {PermissionInformationInlineEditor} from './inline-editor/permission-information-inline-editor';
import {OpenmoduleInlineEditor} from './inline-editor/openmodule-inline-editor';
import {DoubleClickService} from '../double-click.service';
import {Constants} from '../../../../constants';
import {GenericDialogModuleService} from '../../elements/generic-dialog/service/generic-dialog-module.service';
import {ColorInlineEditor} from './inline-editor/color-inline-editor';
import {DateTimeInlineEditor} from './inline-editor/date-time-inline-editor';
import {DocumentDownloadInlineEditor} from './inline-editor/document-download-inline-editor';
import {ExternalLinkInlineEditor} from './inline-editor/external-link-inline-editor';
import {LeasedEmployeeAbscenceDateInlineEditor} from './inline-editor/leased-employee-abscence-date-inline-editor';
import {EldaDownloadInlineEditor} from './inline-editor/elda-download-inline-editor';
import {Observable, of} from 'rxjs';
import {GenericTurboGridComponent} from '../../elements/generic-turbo-grid/generic-turbo-grid.component';
import {EntityStatus} from '../../../services/entity/entity-status';
import {ChangeDetectorRefHelper} from '../../../helpers/change-detector-ref.helper';
import {BranchOfficeInlineEditor} from './inline-editor/branch-office-inline-editor';

@Injectable()
export class GenericElementInlineEditorService {

  private component: AbstractGenericGridComponent = null;

  public constructor(
    private genericCrudService: GenericCrudService,
    private doubleClickService: DoubleClickService,
    private genericDialogModuleService: GenericDialogModuleService
  ) {

  }

  public getValue(entity: any, column: any): any {
    let inlineEditor = this.getInlineEditor(column, entity),
      value = null;

    if (null !== inlineEditor) {
      value = inlineEditor.getValue(entity);
    }

    return value;
  }

  public onEdit(event: any, entity: any, column: any) {
    const inlineEditor = this.getInlineEditor(column, entity);

    if (null !== inlineEditor) {
      inlineEditor.onEdit(entity, event);
    }
  }

  public onSearch(event: any, entity: any, column: any): Observable<any> {
    const inlineEditor = this.getInlineEditor(column, entity);

    if (null !== inlineEditor && inlineEditor instanceof AutocompleteInlineEditor) {
      return inlineEditor.onSearch(entity, event);
    }

    return of(null);
  }

  public onBlur(event: any, entity: any, column: any) {
    const inlineEditor = this.getInlineEditor(column, entity);

    if (null !== inlineEditor) {
      inlineEditor.onBlur(entity, event);
    }
  }

  public onFocus(event: any, entity: any, column: any): Observable<any> {
    const inlineEditor = this.getInlineEditor(column, entity);

    if (null !== inlineEditor) {
      return inlineEditor.onFocus(entity, event);
    }

    return of(null);
  }

  public isEditDisabled(entity: any, column: any): boolean {
    let disabled = false,
      inlineEditor = this.getInlineEditor(column, entity);

    if (null !== inlineEditor) {
      disabled = inlineEditor.isEditDisabled(entity, column);
    }

    return disabled;
  }

  public isEditCellTextEditor(entity: any, column: any): boolean {
    return column.fieldType !== FieldMetadataGrid.TYPE_DECIMAL &&
      column.fieldType !== FieldMetadataGrid.TYPE_ASSOCIATION_SINGLE &&
      column.fieldType !== FieldMetadataGrid.TYPE_ASSOCIATION_MANY &&
      column.fieldType !== FieldMetadataGrid.TYPE_DATE &&
      column.fieldType !== FieldMetadataGrid.TYPE_DATE_MONTH_AND_YEAR &&
      column.fieldType !== FieldMetadataGrid.TYPE_TIME &&
      column.fieldType !== FieldMetadataGrid.TYPE_WORK_HOUR_TIME &&
      column.fieldType !== FieldMetadataGrid.DATE_TIME_TYPE &&
      column.fieldType !== FieldMetadataGrid.TYPE_LINK &&
      column.fieldType !== FieldMetadataGrid.TYPE_PERMISSION_INFORMATION &&
      column.fieldType !== FieldMetadataGrid.TYPE_CHECKBOX &&
      column.fieldType !== FieldMetadataGrid.TYPE_TRICHECKBOX &&
      column.fieldType !== FieldMetadataGrid.TYPE_OPENMODULE &&
      column.fieldType !== FieldMetadataGrid.TYPE_COLOR &&
      column.fieldType !== FieldMetadataGrid.TYPE_IMAGE &&
      column.fieldType !== FieldMetadataGrid.TYPE_LOCK_STATE &&
      column.fieldType !== 'moduleElementColumn' &&
      column.fieldType !== 'association' &&
      column.fieldType !== Constants.MODULE_ELEMENT_COLUMN_NAME &&
      column.fieldType !== FieldMetadataGrid.TYPE_LEASED_EMPLOYEE_ABSCENCE_DATE;
  }

  public setComponent(component: AbstractGenericGridComponent): GenericElementInlineEditorService {
    this.component = component;
    return this;
  }

  public getComponent(): AbstractGenericGridComponent | null {
    return this.component;
  }

  public openNextEntityEditableColumnCellEditor() {
    const next = this.findNextEntityEditableColumn();
    this.switchCellToEditMode(next);
  }

  public openNextEntityEditableColumnCellEditorAtIndex(index) {
    const next = this.findNextEntityEditableColumnAtIndex(index);
    this.switchCellToEditMode(next);
  }

  public openNextEntitySameEditableColumnCellEditor() {
    const next = this.findNextEntitySameEditableColumn();
    this.switchCellToEditMode(next);
  }

  public openPreviousEditableColumnCellEditor(): void {
    const previous = this.findPreviousEditableColumn();

    if (previous) {
      this.switchCellToEditMode(previous);
    }
  }

  public openNextEditableColumnCellEditor(): void {
    const next = this.findNextEditableColumn();

    if (next) {
      this.switchCellToEditMode(next);
    }
  }

  public markForCheck(entity): void {
    if (this.component && this.component instanceof GenericTurboGridComponent && this.component.readColumnComponents) {
      for (const readColumnComponent of this.component.readColumnComponents) {
        if (entity && readColumnComponent.entity && entity[EntityStatus.ENTITY_DRAFT_FLAG] ===
          readColumnComponent.entity[EntityStatus.ENTITY_DRAFT_FLAG]
        ) {
          ChangeDetectorRefHelper.markForCheck(readColumnComponent);
        }
      }
    }
  }

  public findNextEntityEditableColumn(): any {
    let nextEditingCell = null;

    const editingCell = this.component.grid.editingCell;

    if (editingCell) {
      const tr = editingCell.closest('tr'),
        nextTr = tr.nextElementSibling;

      if (nextTr && nextTr.getElementsByTagName('td')) {
        nextEditingCell = nextTr.getElementsByTagName('td')[0];
      }
    }

    return nextEditingCell;
  }

  public findNextEntitySameEditableColumn(): any {
    let nextEditingCell = null;

    const editingCell = this.component.grid.editingCell;

    if (editingCell) {
      const tr = editingCell.closest('tr'),
        nextTr = tr.nextElementSibling;

      const currentTds = Array.prototype.slice.call(tr.getElementsByTagName('td')),
        columnIndex = currentTds.indexOf(editingCell);

      if (nextTr && nextTr.getElementsByTagName('td')) {
        nextEditingCell = nextTr.getElementsByTagName('td')[columnIndex];
      }
    }

    return nextEditingCell;
  }

  public findNextEntityEditableColumnAtIndex(columnIndex): any {
    let nextEditingCell = null;

    const editingCell = this.component.grid.editingCell;

    if (editingCell) {
      const tr = editingCell.closest('tr'),
        nextTr = tr.nextElementSibling;

      if (nextTr && nextTr.getElementsByTagName('td')) {
        nextEditingCell = nextTr.getElementsByTagName('td')[columnIndex];
      }
    }

    return nextEditingCell;
  }

  public findPreviousEditableColumn(): any {
    let previous = null;

    const editingCell = this.component.grid.editingCell;

    // xcentric was, this.component instanceof DataTable
    if (editingCell && this.component.grid) {
      previous = this.component.grid.findPreviousEditableColumn(editingCell);

      this.component.grid.closeCell();

      if (previous) {
        const isPreviousDisabled = previous.getElementsByClassName(Constants.UI_CELL_DISABLED_FLAG).length > 0;

        if (isPreviousDisabled) {
          this.component.grid.editingCell = previous;

          previous = this.findPreviousEditableColumn();
        }
      }
    }

    return previous;
  }

  public findNextEditableColumn(): any {
    let next = null;

    const editingCell = this.component.grid.editingCell;

    // xcentric was, this.component instanceof DataTable
    if (editingCell && this.component.grid) {
      next = this.component.grid.findNextEditableColumn(editingCell);

      this.component.grid.closeCell();

      if (next) {
        const isNextDisabled = next.getElementsByClassName(Constants.UI_CELL_DISABLED_FLAG).length > 0;

        if (isNextDisabled) {
          this.component.grid.editingCell = next;

          next = this.findNextEditableColumn();
        }
      }
    }

    return next;
  }

  public switchCellToEditMode(element: any): void {
    if (element) {
      // close previous cell
      this.component.grid.closeCell();

      // open new
      element.click();
    }
  }

  private getInlineEditor(column: any, entity: any): AbstractInlineEditor {
    const field: FieldMetadataGrid = column.field;

    let editor = null;

    switch (field.fieldType) {
      case FieldMetadataGrid.TYPE_STRING:
      case FieldMetadataGrid.TYPE_EMAIL:
      case FieldMetadataGrid.TYPE_COMPANY_SETTING_VALUE:
      case FieldMetadataGrid.TYPE_COMPANY_SETTING:
      case 'text':
      case '':
        editor = new TextInlineEditor();
        break;
      case FieldMetadataGrid.TYPE_DATE:
      case FieldMetadataGrid.TYPE_DATE_MONTH_AND_YEAR:
        editor = new DateInlineEditor();
        break;
      case FieldMetadataGrid.TYPE_TIME:
      case FieldMetadataGrid.TYPE_WORK_HOUR_TIME:
        editor = new TimeInlineEditor();
        break;
      case FieldMetadataGrid.DATE_TIME_TYPE:
        editor = new DateTimeInlineEditor();
        break;
      case FieldMetadataGrid.TYPE_ASSOCIATION_SINGLE:
      case 'dropdown':
        // the f? well it works like this in grid... :S
        if (column.filterType === FieldMetadataGrid.FILTER_TYPE_AUTOCOMPLETE) {
          editor = new AutocompleteInlineEditor();
        } else {
          editor = new DropdownInlineEditor();
        }
        break;
      case FieldMetadataGrid.TYPE_ASSOCIATION_MANY:
        editor = new MultiselectInlineEditor();
        break;
      case FieldMetadataGrid.TYPE_CHECKBOX:
        editor = new CheckboxInlineEditor();
        break;
      case FieldMetadataGrid.TYPE_TRICHECKBOX:
        editor = new TriCheckboxInlineEditor();
        break;
      case FieldMetadataGrid.TYPE_NUMBER:
        editor = new TextInlineEditor();
        break;
      case FieldMetadataGrid.TYPE_DECIMAL:
        editor = new DecimalInlineEditor();
        break;
      case FieldMetadataGrid.TYPE_LINK:
        editor = new LinkInlineEditor();
        break;
      case FieldMetadataGrid.TYPE_DOCUMENT_DOWNLOAD:
        editor = new DocumentDownloadInlineEditor();
        break;
      case FieldMetadataGrid.TYPE_ELDA_DOWNLOAD:
        editor = new EldaDownloadInlineEditor();
        break;
      case FieldMetadataGrid.TYPE_EXTERNAL_LINK:
        editor = new ExternalLinkInlineEditor();
        break;
      case FieldMetadataGrid.TYPE_PERMISSION_INFORMATION:
        editor = new PermissionInformationInlineEditor();
        break;
      case FieldMetadataGrid.TYPE_OPENMODULE:
        editor = new OpenmoduleInlineEditor();
        break;
      case FieldMetadataGrid.TYPE_LEASED_EMPLOYEE_ABSCENCE_DATE:
        editor = new LeasedEmployeeAbscenceDateInlineEditor();
        break;
      case 'color':
        editor = new ColorInlineEditor();
        break;
      case FieldMetadataGrid.TYPE_CURRENT_BRANCHOFFICE:
        editor = new BranchOfficeInlineEditor();
        break;
    }

    if (null === editor) {
      switch (column.fieldType) {
        case FieldMetadataGrid.TYPE_PERMISSION_INFORMATION:
          editor = new PermissionInformationInlineEditor();
          break;
      }
    }

    if (editor instanceof AbstractInlineEditor) {
      editor.setComponent(this.component)
        .setColumn(column)
        .setEntity(entity)
        .setGenericCrudService(this.genericCrudService)
        .setDoubleClickService(this.doubleClickService)
        .setGenericDialogModuleService(this.genericDialogModuleService)
        .setInlineEditorService(this);
    }

    return editor;
  }
}
