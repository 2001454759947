import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FlexLayoutModule } from '@angular/flex-layout';
import { TranslateModule } from '@ngx-translate/core';

import {AutoCompleteModule, ToolbarModule} from 'primeng/primeng';
import { DataGridModule } from 'primeng/primeng';
import { DropdownModule } from 'primeng/primeng';

import * as Prime from 'primeng/primeng';
const PrimeDialogModule = Prime.DialogModule;
const PrimeButtonModule = Prime.ButtonModule;

import { ButtonModule } from '../p-button/p-button.directive';

import { GenericCrudService } from '../services/generic-crud.service';

import { DialogDefaultComponent } from './dialog-default/dialog-default.component';
import { DialogAddAddressComponent } from './dialog-add-address/dialog-add-address.component';
import { DialogSelectIconComponent } from './dialog-select-icon/dialog-select-icon.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    TranslateModule,
    AutoCompleteModule,
    DataGridModule,
    DropdownModule,
    PrimeDialogModule,
    PrimeButtonModule,
    ButtonModule,
  ],
  declarations: [
    DialogDefaultComponent,
    DialogAddAddressComponent,
    DialogSelectIconComponent
  ],
  exports: [
    DialogDefaultComponent,
    DialogAddAddressComponent,
    DialogSelectIconComponent
  ],
  providers: [
    GenericCrudService,
  ]
})
export class DialogModule { }
