<div class="ui-g">
  <div class="ui-g-12">
    <div class="ui-g-3">
      <div class="ui-g-12">
        <app-custom-staff-cockpit-user-profit-stat></app-custom-staff-cockpit-user-profit-stat>
      </div>

      <div class="ui-g-12">
        <app-custom-staff-cockpit-stat-count-chart-all></app-custom-staff-cockpit-stat-count-chart-all>
      </div>

      <div class="ui-g-12">
        <app-custom-staff-cockpit-stat-count-charts></app-custom-staff-cockpit-stat-count-charts>
      </div>
    </div>
    <div class="ui-g-3">
      <div class="ui-g-12">
        <app-custom-staff-cockpit-stat-summed-count-chart></app-custom-staff-cockpit-stat-summed-count-chart>
      </div>
      <div class="ui-g-12">
        <app-custom-staff-cockpit-buttons></app-custom-staff-cockpit-buttons>
      </div>
      <div class="ui-g-12">
        <app-custom-staff-cockpit-user-birthday></app-custom-staff-cockpit-user-birthday>
      </div>
      <!--<div class="ui-g-12">
        <app-custom-staff-cockpit-simple-todo></app-custom-staff-cockpit-simple-todo>
      </div>-->

    </div>
    <div class="ui-g-6">
      <div class="ui-g-12">
        <app-custom-staff-cockpit-user-weekly-stat></app-custom-staff-cockpit-user-weekly-stat>
      </div>
      <div class="ui-g-12">
        <app-custom-user-activity-details-report-cockpit></app-custom-user-activity-details-report-cockpit>
      </div>
      <!--<div class="ui-g-12">
        <app-custom-staff-cockpit-open-todo-count></app-custom-staff-cockpit-open-todo-count>
      </div>-->
    </div>
  </div>
</div>


