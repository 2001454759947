
import {of as observableOf, Observable} from 'rxjs';
import {AbstractExecutionStep} from '../../../../../../core/executor/abstract-execution-step';
import {ExecutionStepStatus} from '../../../../../../core/executor/execution-step-status';
import {WizardComponent} from '../../../../../content-renderer/elements/custom/wizard/wizard.component';
import {WizardElement} from '../../../../element/wizard';
import {WizardElementDetails} from '../../../../../content-renderer/elements/custom/wizard/service/wizard.service';

export class WizardPrecalculationBeforeElementTwoExecutionStep extends AbstractExecutionStep {

  public doExecute(): Observable<ExecutionStepStatus> {
    const payloadValue = this.getPayload().getValue();

    const wizardComponent = payloadValue.wizardComponent,
      previousElement = payloadValue.previousWizardElement,
      wizardElement = payloadValue.wizardElement;

    if (!wizardComponent || !wizardElement) {
      return this.getFailObservable('You need to pass WizardComponent as Payload value!');
    }

    return this.doSet(wizardComponent, previousElement, wizardElement);
  }

  protected doSet(wizardComponent: WizardComponent, previousElement: WizardElement, wizardElement: WizardElement): Observable<ExecutionStepStatus> {
    const elementDetails: WizardElementDetails = wizardComponent.wizardService.getElementDetails(wizardElement),
      previousElementDetails: WizardElementDetails = wizardComponent.wizardService.getElementDetails(previousElement);

    const preCalculation = previousElementDetails.inputDetails.entity;

    elementDetails.inputDetails.entity = preCalculation;
    elementDetails.inputDetails.masterFilterField = 'preCalculation';
    elementDetails.inputDetails.masterFilterValue = preCalculation.id;
    elementDetails.inputDetails.masterEntityField = 'preCalculation';

    return observableOf({status: true, content: null});
  }
}
