import {Component, OnInit, Input} from '@angular/core';
import {Menu} from '../../../../../../shared/services/menu/menu';
import {TranslateService} from '@ngx-translate/core';
import {GenericCrudService} from '../../../../../../shared/services/generic-crud.service';
import {MessageGrowlService} from '../../../../../message/message-growl.service';
import {RequestCachingService} from '../../../../../../shared/services/request-caching.service';
import {Constants} from '../../../../../../constants';
import {Subscription} from 'rxjs/Rx';
import {map} from 'rxjs/operators';
import {File} from '../../../../../../shared/models/file';
import {ChangeDetectorRefHelper} from '../../../../../../shared/helpers/change-detector-ref.helper';
import {forkJoin, Observable, of} from 'rxjs';
import {MessageService} from '../../../../../message/message.service';

@Component({
  selector: 'app-cost-overview-import-menu-item',
  templateUrl: './cost-overview-import-menu-item.component.html',
  styleUrls: ['./cost-overview-import-menu-item.component.scss']
})
export class CostOverviewImportMenuItemComponent implements OnInit {

  @Input() iconsPath = '';
  @Input() item: Menu;
  @Input() menuPosition = 'left';

  public isDialogVisible = false;

  periodsDropdown: any[] = [];
  selectedPeriod = null;
  costOverviewPeriod: any = null;
  protected subscriptions: Subscription[] = [];
  public isDataLoading = false;

  constructor(
    protected translateService: TranslateService,
    protected genericCrudService: GenericCrudService,
    protected messageGrowlService: MessageGrowlService,
    protected messageService: MessageService,
    protected requestCachingService: RequestCachingService
  ) {
  }

  ngOnInit() {
  }

  public onItemClick(item) {
    this.isDialogVisible = true;

    if (this.periodsDropdown.length <= 0) {
      this.loadPeriods();
    }

    return false;
  }

  public onUpload({files}: { files: File[] }): void {
    if (this.costOverviewPeriod === true) {
      this.messageService.confirm({
        header: this.translateService.instant('COMMON.VALIDATION_HEADER'),
        message: 'Es gibt schon Einträge mit dem Period in datenbank!',
        acceptVisible: true,
        rejectVisible: true,
        accept: () => {
          this.upload(files).subscribe(() => {

          });
        },
        reject: () => {
          this.dialogHide();
        }
      });
    }
    if (this.costOverviewPeriod === false) {
      this.upload(files).subscribe(() => {

      });
    }
  }

  public upload(files): Observable<any> {
    const observables = [];

    this.isDataLoading = true;
    if (files.length === 0) {
      console.error('No files set for upload!');
      return of(null);
    }

    const parsedFiles = [];
    for (const file of files) {
      const fileEntity = {
        file: file,
        name: file.name,
        fileType: file.type,
        fileSize: file.size,
      }

      parsedFiles.push(fileEntity);
    }
    const params = {
      period: this.selectedPeriod
    };

    observables.push(this.genericCrudService.uploadMany(`intranet/costoverviews/import?`, parsedFiles, params));
    return forkJoin(observables)
      .pipe(
        map(() => {
          this.dialogHide();
          ChangeDetectorRefHelper.detectChanges(this);
        }));
  }

  dialogHide() {
    this.isDialogVisible = false;
    this.isDataLoading = false;
  }

  private loadPeriods() {
    this.subscriptions.push(
      this.genericCrudService.getEntities(`${Constants.PHOENIX_API_ROUTE}/periods`, '', {embedded: 'none'}).subscribe((periods) => {
        periods.unshift({ name: this.translateService.instant('COMMON.PLEASE_SELECT'), value: 0 });

        for (const period of periods) {
          if (period.isMonthly && period.startDate >= '2021-11-01') {
            this.periodsDropdown.push({ label: period.name, value: period.id, startDate: period.startDate });
          }
        }
        this.sortPeriods();
      })
    )
  }

  private sortPeriods() {
    return this.periodsDropdown.sort((a, b) => {
      return <any>new Date(b.startDate) - <any>new Date(a.startDate);
    });
  }

  public checkPeriod() {
    this.genericCrudService.getEntities(`${Constants.INTRANET_API_ROUTE}/costoverviews/${this.selectedPeriod}/getperiod`).subscribe((response) => {
      if (response === true) {
        this.costOverviewPeriod = true;
      } else if (response === false) {
        this.costOverviewPeriod = false;
      }
    });
  }
}
