import {Component, OnInit} from '@angular/core';
import {GenericCrudService} from '../../services/generic-crud.service';
import {PollingService} from '../../../core/service/polling/polling.service';
import {environment} from '../../../../environments';
import {LocationService} from '../../services/location.service';
import {User} from '../../services/user/user';
import {ModulesStateService} from '../../content-renderer/services/modules-state.service';
import {LoggerService} from '../../content-renderer/services/logger/logger.service';
import {Branch} from '../../services/branch/branch';
import {UserSessionService} from '../../../core/service/user-session.service';
import {AuthenticationService} from '../../../core/authentication/authentication.service';
import {catchError} from 'rxjs/operators';
import {throwError as observableThrowError} from 'rxjs/internal/observable/throwError';

class DocumentsCounterData {
  countNew = 0;
  countMy = 0;
  countGroup = 0;
}

export enum DocumentsCounterContext {
  countNew = 1,
  countMy = 2,
  countGroup = 3
}

@Component({
  selector: 'app-documents-counter',
  templateUrl: './documents-counter.component.html',
  styleUrls: ['./documents-counter.component.scss']
})
export class DocumentsCounterComponent implements OnInit {

  public documentCounterData: DocumentsCounterData = new DocumentsCounterData();
  public user: User = null;
  public dmsUser = null;

  public constructor(
    protected readonly polling: PollingService,
    protected readonly genericCrudService: GenericCrudService,
    protected readonly locationService: LocationService,
    protected readonly modulesStateService: ModulesStateService,
    protected readonly logger: LoggerService,
    protected readonly userSession: UserSessionService,
    protected readonly authenticationService: AuthenticationService
  ) {
  }

  public ngOnInit(): void {
    setTimeout(this.startPolling.bind(this), 1000);
  }

  public openModule(context: DocumentsCounterContext): void {
    const value = this.getTotalValue(context),
      documentUrl = this.getModuleUrl(context);

    if (documentUrl === null) {
      this.logger.error(`Module not defined for memo counter context ${context}`);
    }

    if (value !== 0 && documentUrl !== null) {
      this.doOpenModule(documentUrl);
    }
  }

  private doOpenModule(moduleUrl: string): void {
    this.locationService.getRouter().navigate([moduleUrl]).then();
  }

  private getModuleUrl(context: DocumentsCounterContext): string {
    let documentUrl = null;

    switch (context) {
      case DocumentsCounterContext.countNew:
        documentUrl = environment.newDocumentsModuleUrl || null;
        break;
      case DocumentsCounterContext.countMy:
        documentUrl = environment.myDocumentsModuleUrl || null;
        break;
      case DocumentsCounterContext.countGroup:
        documentUrl = environment.groupDocumentsModuleUrl || null;
        break;
    }

    return documentUrl;
  }

  private getTotalValue(context: DocumentsCounterContext): number {
    let totalValue = 0;

    switch (context) {
      case DocumentsCounterContext.countNew:
        totalValue = this.documentCounterData.countNew;
        break;
      case DocumentsCounterContext.countMy:
        totalValue = this.documentCounterData.countMy;
        break;
      case DocumentsCounterContext.countGroup:
        totalValue = this.documentCounterData.countGroup;
        break;
    }

    return totalValue;
  }

  private getBackendUrl(context: DocumentsCounterContext): string {
    let documentUrl = null;

    switch (context) {
      case DocumentsCounterContext.countNew:
        documentUrl = environment.newDocumentsBackendUrl || null;
        break;
      case DocumentsCounterContext.countMy:
        documentUrl = environment.myDocumentsBackendUrl || null;
        break;
      case DocumentsCounterContext.countGroup:
        documentUrl = environment.groupDocumentsBackendUrl || null;
        break;
    }

    return this.parseApiParameters(documentUrl) + '&onlyCount=1';
  }

  private startPolling(): void {
    this.checkDmsUser();
    const urls = [
      'countNew',
      'countMy',
      'countGroup'
    ];

    for (const url of urls) {
      this.polling
        .start(
          url,
          {
            observable: this.genericCrudService.get(this.getBackendUrl(DocumentsCounterContext[url])),
            seconds: 60
          }
        )
        .subscribe((data) => {
          this.documentCounterData[url] = data.total;
        });
    }
  }

  private parseApiParameters(api: string): string {
    let parsedApi = api;

    if (api.indexOf('__CURENT_USER__')) {
      parsedApi = parsedApi.replace('__CURENT_USER__', this.authenticationService.currentUser.id.toString());
    }

    if (api.indexOf('__CURRENT_BRANCH_OFFICE_ID__') && this.userSession.get(Branch.LOCAL_STORAGE_NAME).id) {
      parsedApi = parsedApi.replace('__CURRENT_BRANCH_OFFICE_ID__', this.userSession.get(Branch.LOCAL_STORAGE_NAME).id.toString());
    }

    return parsedApi;
  }

  private checkDmsUser() {
    this.genericCrudService.getEntity('dms/users', this.authenticationService.currentUser.id, '', {
      embedded: 'none'
    }).pipe(
      catchError((response) => {
        return observableThrowError(response);
      })
    ).subscribe((response) => {
      this.dmsUser = response;
    });
  }
}

