<div class="ui-inputgroup">
  <button
    (click)="onEditField($event)"
    pButton
    type="button"
    label="{{field.fieldKey}} {{field.comparator}} {{field.labelValue}}"
  ></button>
  <button
    (click)="onRemoveField($event)"
    pButton
    icon="fa fa-close"
    class="remove-field-button"
  ></button>
</div>
