
import {of as observableOf, Observable} from 'rxjs';

import {ChangeDetectorRef, Component, Input, ViewContainerRef} from '@angular/core';
import {ExecutorService} from '../../../../../core/executor/executor.service';
import {GenericElementAbstract} from '../../generic-element-abstract.component';
import {FieldMetadataGrid} from '../../../../services/module/module-element-field-metadata-grid';
import {ModuleElement} from '../../../../services/module/module-element';
import {EntityValidator, EntityValidatorStatus} from '../../../../validators/services/entity-validator';
import {Element} from '../../../../services/element/element';
import {ToolbarItemCheckService} from '../../generic-toolbar/services/check/toolbar-item-check.service';
import {GenericElementValidationExecutionStepsFactory} from '../../../services/generic/generic-element-validation-execution-steps-factory';
import {EntityDataStoreService} from '../../../services/entity-data-store.service';
import {ModulesStateService} from '../../../services/modules-state.service';
import {ComponentService} from '../../../services/component-highlight-stack.service';
import {GenericCrudService} from '../../../../services/generic-crud.service';
import {LayoutService} from '../../../../services/layout-service';
import {JobContainerService} from '../../../../../core/job-runner/job-container.service';
import {ElementsStackService} from '../../../services/elements-stack.service';
import {ElementsStateService} from '../../../services/elements-state.service';
import {GenericDialogModuleService} from '../../generic-dialog/service/generic-dialog-module.service';
import {ExecutorActionsService} from '../../../../../core/executor/service/executor-actions/executor-actions.service';
import {PermissionService} from '../../../../services/permission/permission.service';
import {UserSessionService} from '../../../../../core/service/user-session.service';
import {GenericElementFilterService} from '../../../services/generic/filter/generic-element-filter.service';
import {TableColumn} from '../../../../dynamic-table/shared/table-column';
import {ChangeDetectorRefHelper} from '../../../../helpers/change-detector-ref.helper';
import {takeUntil} from 'rxjs/operators';

@Component({
  selector: 'app-custom-employee-cost-report',
  styleUrls: ['./employee-cost-report.component.scss'],
  templateUrl: './employee-cost-report.component.html',
  providers: [
    ExecutorService,
    GenericElementValidationExecutionStepsFactory,
    GenericElementFilterService
  ]
})
export class EmployeeCostReportComponent extends GenericElementAbstract {
  @Input() element: Element;
  @Input() fields: Array<FieldMetadataGrid>;
  @Input() toolbarItems: any[] = [];
  @Input() statusBarItems: any[] = [];
  @Input() moduleElement: ModuleElement;
  @Input() masterEntity: any = null;
  @Input() masterField: any = null;
  @Input() isPart = false;

  public module = null;

  public toolbarContextName = 'employeeCostReportComponent';

  public entities = [];
  public cells = [];
  public isLoading = false;
  public columns: TableColumn[] = [];
  public gridHeight = 500;

  public selectedCostCenter = null;
  public selectedYear = null;

  public constructor(
    protected componentService: ComponentService,
    protected viewContainerRef: ViewContainerRef,
    protected modulesStateService: ModulesStateService,
    protected genericCrudService: GenericCrudService,
    protected entityDataStoreService: EntityDataStoreService,
    protected executorService: ExecutorService,
    protected genericElementValidationExecutionStepsFactory: GenericElementValidationExecutionStepsFactory,
    protected entityValidator: EntityValidator,
    protected userSession: UserSessionService,
    protected toolbarItemCheckService: ToolbarItemCheckService,
    protected layoutService: LayoutService,
    protected jobContainerService: JobContainerService,
    protected elementsStackService: ElementsStackService,
    protected elementStateService: ElementsStateService,
    protected dialogService: GenericDialogModuleService,
    protected executorActionsService: ExecutorActionsService,
    protected permissionService: PermissionService,
    public cdr: ChangeDetectorRef,
    protected genericElementFilterService: GenericElementFilterService
  ) {
    super(componentService, viewContainerRef, entityDataStoreService, modulesStateService, executorService,
      genericElementValidationExecutionStepsFactory, entityValidator, genericCrudService, userSession, permissionService,
      cdr);
  }

  public ngOnInit() {
    super.ngOnInit();

    this.layoutService.layoutSizeChanged$
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(() => {
        this.setGridScrollHeightAndWidth();
      })
  }

  public ngOnDestroy() {
    super.ngOnDestroy();
  }

  public getSelectedEntity(): any {
    return this.selectedMasterEntity || null;
  }

  public recheckToolbarItems(): void {
    this.toolbarItemCheckService.check(this);
  }

  public onSave(): Observable<any> {
    return observableOf(null);
  }

  public hasChanges(checkEmbedded: boolean = false): boolean {
    return false;
  }

  public onAfterSave(): Observable<any> {
    return observableOf(null);
  }

  public onChange(): Observable<any> {
    return observableOf(null);
  }

  public doValidate(): Observable<EntityValidatorStatus> {
    return observableOf({
      entity: null,
      isValid: true,
      error: '',
      errorFields: []
    });
  }

  public onRefresh(): Observable<any> {
    return observableOf(null);
  }

  public onCostCenterChange(event): void {
    this.selectedCostCenter = event;
    if (this.selectedYear) {
      this.search();
    }
  }

  public onYearChanged(value): void {
    this.selectedYear = value.value;

    if (this.selectedYear && this.selectedCostCenter) {
      this.search();
    }
  }

  public search(): void {
    this.isLoading = true;
    this.columns = [{
      key: 'title',
      header: 'Titel'
    }, {
      key: 'sub_note',
      header: ''
    }, {
      key: 'transfer',
      header: 'Übertrag',
      style: {
        textAlign: 'right'
      }
    }];

    this.genericCrudService.getEntities('phoenix/periods/foryear/' + this.selectedYear, '')
      .pipe(
        takeUntil(this.unsubscribe)
      ).subscribe((periods) => {
      for (const period of periods) {
        this.columns = [...this.columns, {
          key: period.code,
          header: period.name,
          style: {
            textAlign: 'right'
          }
        }]
      }

      ChangeDetectorRefHelper.detectChanges(this);

      const params: Record<string, any> = {
        period_id: periods.map((period) => period.id)
      }

      params.year = this.selectedYear;

      if (this.selectedCostCenter) {
        params.cost_center = this.selectedCostCenter.id;
      }

      this.genericCrudService.getEntities('intranet/babs/report', '', params)
        .pipe(
          takeUntil(this.unsubscribe)
        )
        .subscribe((entities) => {
          this.entities = [...entities];
          for (const entity of entities) {
            this.cells = [...this.cells, {
              getStyle: (aEntity, { key }) => {
                if (key === 'title') {
                  return {
                    color: aEntity.title_color,
                    backgroundColor: aEntity.background_color || 'white'
                  }
                }

                if (key === 'sub_note') {
                  return {
                    color: aEntity.sub_note_color,
                    backgroundColor: aEntity.background_color || 'white'
                  }
                }

                const value = parseFloat(aEntity[key]);

                return {
                  color: value >= 0 ? aEntity.positive_color : aEntity.negative_color,
                  backgroundColor: aEntity.background_color || 'white'
                };
              }
            }];
          }
          this.isLoading = false;
          ChangeDetectorRefHelper.detectChanges(this);
        })
    });
  }

  protected setGridScrollHeightAndWidth() {
    this.gridHeight = window.innerHeight - 165;
  }
}
