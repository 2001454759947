
import {of as observableOf, Observable} from 'rxjs';
import {AbstractExecutionStep} from '../../../../../core/executor/abstract-execution-step';
import {ExecutionStepStatus} from '../../../../../core/executor/execution-step-status';
import {WizardComponent} from '../../../../content-renderer/elements/custom/wizard/wizard.component';
import {WizardElement} from '../../../element/wizard';
import {WizardElementDetails} from '../../../../content-renderer/elements/custom/wizard/service/wizard.service';
import {AbstractGenericGridComponent} from '../../../../content-renderer/elements/abstract-generic-grid.component';
import {GenericDialogModuleService} from '../../../../content-renderer/elements/generic-dialog/service/generic-dialog-module.service';
import {ToolbarItemSavePrecalculationArticleService} from '../../../../content-renderer/elements/generic-toolbar/services/save/toolbar-item-save-precalculation-article.service';
import {ModuleState} from '../../../../content-renderer/services/module-state';
import {GenericElementAbstract} from '../../../../content-renderer/elements/generic-element-abstract.component';
import {FormViewerComponent} from '../../../../form-viewer/form-viewer.component';
import {GenericTreeGridComponent} from '../../../../content-renderer/elements/generic-tree-grid/generic-tree-grid.component';

export class WizardSavePrecalculationFinishExecutionStep extends AbstractExecutionStep {

  public doExecute(): Observable<ExecutionStepStatus> {
    const payloadValue = this.getPayload().getValue();

    const wizardComponent = payloadValue.wizardComponent,
      wizardElement = payloadValue.wizardElement;

    if (!(wizardComponent instanceof WizardComponent)) {
      return this.getFailObservable('You need to pass WizardComponent as Payload value!');
    }

    return this.doSet(wizardComponent, wizardElement);
  }

  protected doSet(wizardComponent: WizardComponent, wizardElement: WizardElement): Observable<ExecutionStepStatus> {
    const elementDetails: WizardElementDetails = wizardComponent.wizardService.getElementDetails(wizardElement),
      wizardElementModuleState = elementDetails.moduleState,
      masterComponent = this.getMasterElementContextGridComponent(wizardComponent);

    const precalculationSaveService = this.injector.get(ToolbarItemSavePrecalculationArticleService, null);

    if (masterComponent && precalculationSaveService) {
      const formComponent: any = this.getFormComponent(wizardElementModuleState);
      this.firstSaveValues(formComponent, precalculationSaveService).subscribe((result) => {
        const dialogService = this.injector.get(GenericDialogModuleService);
        dialogService.persistHide();

        masterComponent.sortDirection = 'desc';
        masterComponent.sortField = 'id';

        masterComponent.loadEntities().subscribe(() => {
          const preCalculation = elementDetails.inputDetails.entity;

          if (preCalculation && masterComponent.findEntity(preCalculation)) {
            masterComponent.selectEntity(preCalculation);
          }
        });
      });
    }

    return observableOf({status: true, content: null});
  }

  private firstSaveValues(
    component: FormViewerComponent,
    preCalculationArticleSaveService: ToolbarItemSavePrecalculationArticleService): Observable<any> {
    let observable = observableOf({status: false});
    for (const slaveContext of component.getElementContext().getSlaveElementContexts()) {
      const genericArticlesGrid = slaveContext.component;
      let otherArticlesGrid = null;

      for (const subSlaveContext of genericArticlesGrid.getElementContext().getSlaveElementContexts()) {
        if (subSlaveContext.component instanceof GenericTreeGridComponent) {
          otherArticlesGrid = subSlaveContext.component;
          break;
        }
      }

      if (genericArticlesGrid instanceof AbstractGenericGridComponent && otherArticlesGrid instanceof GenericTreeGridComponent) {
        observable = preCalculationArticleSaveService.save(genericArticlesGrid, otherArticlesGrid);
        break;
      }
    }

    return observable;
  }

  private getMasterElementContextGridComponent(wizardComponent: WizardComponent): AbstractGenericGridComponent|null {
    if (wizardComponent.masterElementContext && wizardComponent.masterElementContext.component &&
      wizardComponent.masterElementContext.component instanceof AbstractGenericGridComponent
    ) {
      return wizardComponent.masterElementContext.component;
    }

    return null;
  }

  private getFormComponent(moduleState: ModuleState): GenericElementAbstract|FormViewerComponent|null {
    return moduleState.getComponents().find((aComponent) => {
      return aComponent instanceof FormViewerComponent;
    });
  }

  public onSuccess(wizardComponent: WizardComponent, elementDetails: WizardElementDetails, entity: any): void {

  }
}
