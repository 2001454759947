import { OnInit, Component, Input, Output, EventEmitter } from "@angular/core";
import { Element } from './../../../services/element/element';
import { GenericGridComponent } from '../generic-grid/generic-grid.component';
import {AbstractGenericGridComponent} from '../abstract-generic-grid.component';
@Component({
  selector: 'app-generic-detailed-overview',
  templateUrl: './generic-detailed-overview.component.html',
  styles: [
    `
      ::ng-deep #overview-form {
        overflow-y: scroll;
        height: 100%;
      }
    `
  ]
})
export class GenericDetailedOverviewComponent implements OnInit {
  @Input() element: Element = null;
  @Input() grid: AbstractGenericGridComponent = null;
  @Output() close = new EventEmitter();
  entity: any;
  properties : any;

  constructor() { }

  ngOnInit(): void {
    this.setEntity(this.grid.getSelectedEntity());
  }

  onClose() {
    this.close.emit();
  }

  onPrevious() {
    this.grid.getEntityDataStore().getPrevious(this.element.datamodel.apiRoute, this.entity).subscribe((entity) => {
      this.setEntity(entity);
    })
  }

  onNext() {
    this.grid.getEntityDataStore().getNext(this.element.datamodel.apiRoute, this.entity).subscribe((entity) => {
      this.setEntity(entity);
    })
  }

  isComplex(value) {
    return Array.isArray(value) || typeof value === 'object';
  }

  private setEntity(entity) {
    this.entity = entity;
    this.properties = this.getProperties(entity);
  }

  private getProperties(value) {
    return Object.keys(value).filter(k => !k.startsWith('_'));
  }
}
