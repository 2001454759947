import {ComponentFactoryResolver, ComponentRef, Injectable, ViewContainerRef} from '@angular/core';
import {Message, MessageService} from 'primeng/api';
import {take} from 'rxjs/operators';
import {Subject, Observable} from 'rxjs';
import {ProgressBarToastComponent} from '../../shared/components/toast/progress-bar/progress-bar-toast.component';

export interface CustomMessageResponse {
  type: string;
  message?: any;
}

export const TOAST_LIFE_INFINITE = 9 * 999999;

export class ToastComponentsRegistry {
  static PROGRESS_BAR = 'progressBar';
}

@Injectable()
export class ToastService {

  public viewRefIndex: { [key: string]: number} = {
    'progressBar': 0
  };

  private responseSource = new Subject<CustomMessageResponse>();
  private response$ = this.responseSource.asObservable().pipe(take(1));

  private viewContainerRef: ViewContainerRef = null;

  private readonly customComponents: { [name: string]: any; } = {};

  public constructor(
    private componentFactoryResolver: ComponentFactoryResolver,
    private messageService: MessageService
  ) {
    this.customComponents[ToastComponentsRegistry.PROGRESS_BAR] = ProgressBarToastComponent;
  }

  public add(message: Message): void {
    this.messageService.add(message);
  }

  public success(message: Message): void {
    message.severity = 'success';

    this.messageService.add(message);
  }

  public error(message: Message): void {
    message.severity = 'error';

    this.messageService.add(message);
  }

  public clear(key: string, timeout: number = 0): void {
    setTimeout(() => {
      this.messageService.clear(key);

      const viewRefIndex = this.getViewRefIndex(key);
      if (typeof viewRefIndex !== 'undefined') {
        this.viewContainerRef.remove(viewRefIndex);
      }
    }, timeout);
  }

  public custom(key: string, message: Message): Observable<any> {
    const factory = this.componentFactoryResolver.resolveComponentFactory(this.customComponents[key]);

    const component: ComponentRef<any> = factory.create(this.viewContainerRef.parentInjector);

    message.key = key;
    component.instance.message = message;
    component.instance.toastService = this;

    // this.viewContainerRef.clear(); //this was removing generic dialog without triggering dialog methods onClose and onAfterClose
    this.viewContainerRef.insert(component.hostView, this.getViewRefIndex(key));

    return this.response$;
  }

  public response(key: string, response: CustomMessageResponse): void {
    this.clear(key);

    this.responseSource.next(response);
  }

  public setViewContainerRef(viewContainerRef: ViewContainerRef): this {
    this.viewContainerRef = viewContainerRef;
    return this;
  }

  private getViewRefIndex(key: string): number {
    return this.viewRefIndex[key];
  }

}
