/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./staff-cockpit-buttons.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "primeng/components/dom/domhandler";
import * as i3 from "primeng/components/button/button";
import * as i4 from "../../../../../p-button/p-button.directive";
import * as i5 from "./staff-cockpit-buttons.component";
import * as i6 from "../../../../../services/generic-crud.service";
import * as i7 from "../../../generic-dialog/service/generic-dialog-module.service";
var styles_StaffCockpitButtonsComponent = [i0.styles];
var RenderType_StaffCockpitButtonsComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_StaffCockpitButtonsComponent, data: {} });
export { RenderType_StaffCockpitButtonsComponent as RenderType_StaffCockpitButtonsComponent };
export function View_StaffCockpitButtonsComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "button", [["class", "module-opener-button"], ["pButton", ""]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onOpenModule() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵprd(4608, null, i2.DomHandler, i2.DomHandler, []), i1.ɵdid(2, 4341760, null, 0, i3.ButtonDirective, [i1.ElementRef], null, null), i1.ɵdid(3, 4341760, null, 0, i4.PButtonDirective, [i1.ElementRef], null, null), (_l()(), i1.ɵted(-1, null, ["Mitarbeiter\u00FCbersicht"]))], null, null); }
export function View_StaffCockpitButtonsComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-custom-staff-cockpit-buttons", [], null, null, null, View_StaffCockpitButtonsComponent_0, RenderType_StaffCockpitButtonsComponent)), i1.ɵdid(1, 180224, null, 0, i5.StaffCockpitButtonsComponent, [i1.ChangeDetectorRef, i6.GenericCrudService, i7.GenericDialogModuleService], null, null)], null, null); }
var StaffCockpitButtonsComponentNgFactory = i1.ɵccf("app-custom-staff-cockpit-buttons", i5.StaffCockpitButtonsComponent, View_StaffCockpitButtonsComponent_Host_0, {}, {}, []);
export { StaffCockpitButtonsComponentNgFactory as StaffCockpitButtonsComponentNgFactory };
