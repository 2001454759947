import { Observable } from 'rxjs/Observable';
import {AbstractExecutionStep} from '../../../../core/executor/abstract-execution-step';
import {ExecutionStepStatus} from '../../../../core/executor/execution-step-status';
import {CostCenterInvoiceSplitComponent} from '../../../content-renderer/elements/custom/cost-center-invoice-split/cost-center-invoice-split.component';
import {map, switchMap, tap} from 'rxjs/operators';
import {forkJoin} from 'rxjs';
import {EntityManagerService} from '../../../../core/service/entity-manager/entity-manager.service';
import {EntityStatus} from '../../entity/entity-status';
import {MessageGrowlService} from '../../../../core/message/message-growl.service';
import {TranslateService} from '@ngx-translate/core';

export class SaveCostCenterDmsFileExecutionStep extends AbstractExecutionStep {

  public doExecute(): Observable<ExecutionStepStatus> {
    const payloadValue = this.getPayload().getValue(),
      component = payloadValue;

    if (!(component instanceof CostCenterInvoiceSplitComponent)) {
      return this.getFailObservable('You need to pass CostCenterInvoiceSplitComponent as Payload value!');
    }

    return this.doSave(component);
  }

  private doSave(component: CostCenterInvoiceSplitComponent): Observable<ExecutionStepStatus> {
    const isExisting = !!component.costCenterDmsFile.id;

    return this.saveCostCenterDmsFile(component, isExisting).pipe(
      switchMap((entity) => {
        return this.saveCostCenterDmsFileItems(entity, component.entities.filter((aEntity) => !aEntity.isTotal), isExisting)
      }),
      tap(() => {
        this.injector.get<MessageGrowlService>(MessageGrowlService).success(
          this.injector.get<TranslateService>(TranslateService).instant('COMMON.DATA_SAVED'),
          this.injector.get<TranslateService>(TranslateService).instant('COMMON.SUCCESS')
        );
      }),
      map(() => {
        return { status: true, content: null }
      })
    )
  }

  private saveCostCenterDmsFile(component: CostCenterInvoiceSplitComponent, isExisting: boolean): Observable<any> {
    const costCenterDmsFile: any = component.costCenterDmsFile;

    this.injector.get(EntityManagerService, null).persistMore(costCenterDmsFile, [
      {property: EntityStatus.ENTITY_CHANGED_FLAG, newValue: true, force: true},
      {property: 'amount', newValue: costCenterDmsFile.amount, force: true},
      {property: 'splitAmount', newValue: costCenterDmsFile.splitAmount, force: true},
      {property: 'fixedAmount', newValue: costCenterDmsFile.fixedAmount, force: true},
      {property: 'uniqueId', newValue: costCenterDmsFile.uniqueId, force: true},
      {property: 'type', newValue: costCenterDmsFile.type, force: true},
      {property: 'branchOfficeCostCenter', newValue: costCenterDmsFile.branchOfficeCostCenter, force: true},
    ])

    if (isExisting) {
      return this.genericCrudService.editEntity(`phoenix/costcenterdmsfiles/${costCenterDmsFile.id}`, costCenterDmsFile).pipe(
        tap((entity) => {
          component.costCenterDmsFile = entity
        })
      )
    }
    return this.genericCrudService.createEntity('phoenix/costcenterdmsfiles', costCenterDmsFile).pipe(
      tap((entity) => {
        component.costCenterDmsFile = entity
      })
    )
  }

  private saveCostCenterDmsFileItems(costCenterDmsFile: any, entities: any[], isExisting: boolean): Observable<any> {
    const observables = [];
    const entityManager = this.injector.get(EntityManagerService, null);

    for (const entity of entities.filter((aEntity) => aEntity[EntityStatus.ENTITY_CHANGED_FLAG])) {
      const aEntity = {
        ...entity,
        ...{
          costCenterDmsFile: costCenterDmsFile
        }
      }

      entityManager.persistMore(aEntity, [
        {property: EntityStatus.ENTITY_CHANGED_FLAG, newValue: true, force: true},
        {property: 'costCenterDmsFile', newValue: costCenterDmsFile, force: true},
        {property: 'amount', newValue: aEntity.amount, force: true},
        {property: 'totalAmount', newValue: aEntity.totalAmount, force: true},
        {property: 'defaultValue', newValue: aEntity.defaultValue, force: true},
        {property: 'uniqueId', newValue: aEntity.uniqueId, force: true},
        {property: 'type', newValue: aEntity.type, force: true},
        {property: 'branchOfficeCostCenter', newValue: aEntity.branchOfficeCostCenter, force: true},
      ])

      if (aEntity.IS_NEW) {
        observables.push(
          this.genericCrudService.createEntity(`phoenix/costcenterdmsfileitems`, aEntity)
        )
      } else {
        observables.push(
          this.genericCrudService.editEntity(`phoenix/costcenterdmsfileitems/${entity.id}`, aEntity)
        )
      }
    }

    return forkJoin(observables);
  }
}
