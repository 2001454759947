import {Component} from '@angular/core';
import {AbstractGenericGridSimpleSearchFieldComponent} from './abstract-generic-grid-simple-search-field.component';

@Component({
  selector: 'app-generic-grid-simple-search-field-number',
  template: ` 
    <p-spinner
      size="30"
      [(ngModel)]="this.field.value"
    ></p-spinner>
  `
})
export class GenericGridSimpleSearchFieldNumberComponent extends AbstractGenericGridSimpleSearchFieldComponent {

}
