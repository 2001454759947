import {ExecutorService} from '../../../../../../core/executor/executor.service';
import {Component} from '@angular/core';
import {GenericElementValidationExecutionStepsFactory} from '../../../../services/generic/generic-element-validation-execution-steps-factory';
import {GenericElementFilterService} from '../../../../services/generic/filter/generic-element-filter.service';
import {UserActivityDetailsReportComponent} from '../../user-activity-details-report/user-activity-details-report.component';
import {takeUntil} from 'rxjs/operators';
import {ChangeDetectorRefHelper} from '../../../../../helpers/change-detector-ref.helper';

@Component({
  selector: 'app-custom-user-activity-details-report-cockpit',
  styleUrls: ['./user-activity-details-report-cockpit.component.scss'],
  templateUrl: './user-activity-details-report-cockpit.component.html',
  providers: [
    ExecutorService,
    GenericElementValidationExecutionStepsFactory,
    GenericElementFilterService
  ]
})
export class UserActivityDetailsReportCockpitComponent extends UserActivityDetailsReportComponent {

  public dataEmployee: any;
  public dataCustomer: any;

  public search(): void {
    if (!this.selectedWeek || !this.selectedYear || !this.selectedBranchOffice) {
      return;
    }
    this.isLoading = true;

    const params: Record<string, string> = {
      branch_office_id: this.selectedBranchOffice,
      year: this.selectedYear,
      week: this.selectedWeek
    }

    this.genericCrudService.getEntities('intranet/userweeklyactivitystatsdetails/load-customer-cockpit', '', params)
      .pipe(
        takeUntil(this.unsubscribe)
      )
      .subscribe((tables) => {
        this.dataCustomer = [];
        for (const table of tables) {
          this.cellsCustomer = [];
          this.customerActivityColumns = [];
          this.customerActivityEntities = [...table];

          for (const entity of table) {
            this.cellsCustomer = [...this.cellsCustomer, {
              getStyle: (aEntity, {key}) => {
                let color = 'white';

                if (key.indexOf('plan') > -1) {
                  color = aEntity.color_plan;
                } else if (key.indexOf('real_year') > -1) {
                  color = aEntity.color_real_year;
                } else if (key.indexOf('real') > -1) {
                  color = aEntity.color_real;
                }

                return {
                  backgroundColor: color || 'white'
                };
              }
            }];
          }

          const keys = Object.keys(table[0]);
          for (const key of keys) {
            if (key.indexOf('color_') > -1) {
              continue;
            }
            this.customerActivityColumns.push({header: '', key: key});
          }

          this.customerActivityColumns = [...this.customerActivityColumns];
          this.dataCustomer.push({customerActivityColumns: this.customerActivityColumns, cellsCustomer: this.cellsCustomer, customerActivityEntities: this.customerActivityEntities});
        }
        this.isLoading = false;
        ChangeDetectorRefHelper.detectChanges(this);
      });

    this.genericCrudService.getEntities('intranet/userweeklyactivitystatsdetails/load-employee-cockpit', '', params)
      .pipe(
        takeUntil(this.unsubscribe)
      )
      .subscribe((tables) => {
        this.dataEmployee = [];
        for (const table of tables) {
          this.cellsEmployee = [];
          this.employeeActivityColumns = [];
          this.employeeActivityEntities = [...table];

          for (const entity of table) {
            this.cellsEmployee = [...this.cellsEmployee, {
              getStyle: (aEntity, {key}) => {
                let color = 'white';

                if (key.indexOf('plan') > -1) {
                  color = aEntity.color_plan;
                } else if (key.indexOf('real_year') > -1) {
                  color = aEntity.color_real_year;
                } else if (key.indexOf('real') > -1) {
                  color = aEntity.color_real;
                }

                return {
                  backgroundColor: color || 'white'
                };
              }
            }];
          }

          const keys = Object.keys(table[0]);
          for (const key of keys) {
            if (key.indexOf('color_') > -1) {
              continue;
            }
            this.employeeActivityColumns.push({header: '', key: key});
          }

          this.employeeActivityColumns = [...this.employeeActivityColumns];
          this.dataEmployee.push({employeeActivityColumns: this.employeeActivityColumns, cellsEmployee: this.cellsEmployee, employeeActivityEntities: this.employeeActivityEntities});

        }
        this.isLoading = false;
        ChangeDetectorRefHelper.detectChanges(this);

      });
  }
}
