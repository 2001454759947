import {Observable} from 'rxjs/Observable';
import {map, switchMap} from 'rxjs/operators';
import {Entity} from '../../../../../helpers/entity';
import {UserSessionService} from '../../../../../../core/service/user-session.service';
import {AuthenticationService} from '../../../../../../core/authentication/authentication.service';
import {GenericCrudService} from '../../../../../services/generic-crud.service';
import {Branch} from '../../../../../services/branch/branch';
import {DateHelper} from '../../../../../helpers/date.helper';

export class UserProfitStatService {

  public constructor(
    private userSession: UserSessionService,
    private authenticationService: AuthenticationService,
    private genericCrudService: GenericCrudService
  ) {

  }

  public getStats(): Observable<any[]> {
    const currentBranchOffice = this.userSession.get(Branch.LOCAL_STORAGE_NAME);

    return this.genericCrudService.getEntity('app/branchoffices', currentBranchOffice.id, '', {
      embedded: 'none'
    }).pipe(
      switchMap((currBranchOffice) => {
        const params = {
          embedded: 'user',
          currentYear: new Date().getFullYear(),
          branchOffice: currBranchOffice.id,
          currentWeek: DateHelper.getCurrentWeek(new Date()),
        }
        return this.genericCrudService.getEntities('phoenix/userbranchofficeprofitstats', '', params)
      }),
      map((stats) => {
        const groupedStats = this.groupStatsByUser(stats);
        groupedStats.push({
          IS_SUMMARY: true,
          plannedValue: this.calculateSum(stats, 'plannedValue'),
          realValue: this.calculateSum(stats, 'realValue'),
          ill: this.calculateSum(stats, 'ill'),
          holiday: this.calculateSum(stats, 'holiday'),
          noAssignment: this.calculateSum(stats, 'noAssignment'),
          value: this.calculateAverage(stats, 'value'),
          plannedValueNextWeek: this.calculateSum(stats, 'plannedValueNextWeek'),
        });

        return groupedStats.map((stat) => Entity.extractEmbedded(stat));
      })
    )
  }

  private groupStatsByUser(stats: any[]): any[] {
    const groupedStats = {};

    for (const stat of stats) {
      const groupKey = Entity.getValue(stat, 'user.id') || Entity.getValueInEmbedded(stat, 'user.id');

      if (groupKey) {
        if (!groupedStats[groupKey]) {
          groupedStats[groupKey] = {
            ...stat,
            ...{
              value: 0,
              plannedValue: 0,
              realValue: 0,
              ill: 0,
              holiday: 0,
              noAssignment: 0
            }
          };
        }

        groupedStats[groupKey].value += +stat.value;
        groupedStats[groupKey].plannedValue += +stat.plannedValue;
        groupedStats[groupKey].realValue += +stat.realValue;
        groupedStats[groupKey].ill += +stat.ill;
        groupedStats[groupKey].holiday += +stat.holiday;
        groupedStats[groupKey].noAssignment += +stat.noAssignment;
      }
    }

    return Object.values(groupedStats);
  }

  private calculateSum(stats: any[], key: string) {
    return stats.reduce((accumulator, stat) => {
      return accumulator + +stat[key];
    }, 0)
  }

  private calculateAverage(stats: any[], key: string) {
    const statsCount = stats.length;

    return stats.reduce((accumulator, stat) => {
      return accumulator + +stat[key];
    }, 0) / statsCount
  }
}
