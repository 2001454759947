import {AbstractAssociatedInlineEditor} from './abstract-associated-inline-editor';
import {FieldMetadataGrid} from '../../../../services/module/module-element-field-metadata-grid';
import {map, takeUntil} from 'rxjs/operators';
import {Observable} from 'rxjs';
import {ChangeDetectorRefHelper} from '../../../../helpers/change-detector-ref.helper';

export class DropdownInlineEditor extends AbstractAssociatedInlineEditor {

    public getValue(entity: any) {
        let entityEmbeddedEntity = entity[this.getEntityColumnProperty()];

        if (!entityEmbeddedEntity && entity._embedded) {
            entityEmbeddedEntity = entity._embedded[this.getEntityColumnProperty()];
        }

        return entityEmbeddedEntity ? entityEmbeddedEntity : null;
    }

    public onEdit(entity: any, event: any) {
        const value = event.value;

        const entityValue = this.getEntityFromAssociatedOptions(value);

        if (entityValue) {
            this.changeEntityColumnProperty(entityValue);
        }
    }

    public onBlur(entity: any, event: any) {

    }

    public onFocus(entity: any, event: any): Observable<any> {

      return this.component.getColumnBuilder()
        .loadOptions(this.field)
        .pipe(
          takeUntil(this.component.unsubscribe),
          map((options) => {
          this.component.associatedOptions[this.field.entityName] = [];

          if (this.field.filterType === FieldMetadataGrid.FILTER_TYPE_DROPDOWN) {
            this.component.associatedOptions[this.field.entityName] = [{ label: '---', value: null }];
          }

          ChangeDetectorRefHelper.detectChanges(this.component);

          this.component.associatedOptions[this.field.entityName] = this.component.associatedOptions[this.field.entityName].concat(options);
          ChangeDetectorRefHelper.detectChanges(this.component);
        }));
    }
}
