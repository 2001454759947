/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./documents-counter.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@ngx-translate/core";
import * as i3 from "@angular/common";
import * as i4 from "@angular/flex-layout/extended";
import * as i5 from "@angular/flex-layout/core";
import * as i6 from "./documents-counter.component";
import * as i7 from "../../../core/service/polling/polling.service";
import * as i8 from "../../services/generic-crud.service";
import * as i9 from "../../services/location.service";
import * as i10 from "../../content-renderer/services/modules-state.service";
import * as i11 from "../../content-renderer/services/logger/logger.service";
import * as i12 from "../../../core/service/user-session.service";
import * as i13 from "../../../core/authentication/authentication.service";
var styles_DocumentsCounterComponent = [i0.styles];
var RenderType_DocumentsCounterComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_DocumentsCounterComponent, data: {} });
export { RenderType_DocumentsCounterComponent as RenderType_DocumentsCounterComponent };
function View_DocumentsCounterComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 22, "span", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "span", [["style", "color: black;"]], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", ":"])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(4, 0, null, null, 4, "span", [["style", "color: black;"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.openModule(1) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵprd(512, null, i3.ɵNgClassImpl, i3.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(6, 278528, null, 0, i3.NgClass, [i3.ɵNgClassImpl], { ngClass: [0, "ngClass"] }, null), i1.ɵdid(7, 933888, null, 0, i4.DefaultClassDirective, [i1.ElementRef, i5.StyleUtils, i5.MediaMarshaller, i3.ɵNgClassImpl, [6, i3.NgClass]], { ngClass: [0, "ngClass"] }, null), (_l()(), i1.ɵted(8, null, [" ", " "])), (_l()(), i1.ɵeld(9, 0, null, null, 1, "span", [["style", "color: black;"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["/"])), (_l()(), i1.ɵeld(11, 0, null, null, 4, "span", [["style", "color: black;"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.openModule(2) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵprd(512, null, i3.ɵNgClassImpl, i3.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(13, 278528, null, 0, i3.NgClass, [i3.ɵNgClassImpl], { ngClass: [0, "ngClass"] }, null), i1.ɵdid(14, 933888, null, 0, i4.DefaultClassDirective, [i1.ElementRef, i5.StyleUtils, i5.MediaMarshaller, i3.ɵNgClassImpl, [6, i3.NgClass]], { ngClass: [0, "ngClass"] }, null), (_l()(), i1.ɵted(15, null, [" ", " "])), (_l()(), i1.ɵeld(16, 0, null, null, 1, "span", [["style", "color: black;"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["/"])), (_l()(), i1.ɵeld(18, 0, null, null, 4, "span", [["style", "color: black;"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.openModule(3) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵprd(512, null, i3.ɵNgClassImpl, i3.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(20, 278528, null, 0, i3.NgClass, [i3.ɵNgClassImpl], { ngClass: [0, "ngClass"] }, null), i1.ɵdid(21, 933888, null, 0, i4.DefaultClassDirective, [i1.ElementRef, i5.StyleUtils, i5.MediaMarshaller, i3.ɵNgClassImpl, [6, i3.NgClass]], { ngClass: [0, "ngClass"] }, null), (_l()(), i1.ɵted(22, null, [" ", " "]))], function (_ck, _v) { var _co = _v.component; var currVal_1 = ((_co.documentCounterData.countNew === 0) ? "disabled" : ""); _ck(_v, 6, 0, currVal_1); var currVal_2 = ((_co.documentCounterData.countNew === 0) ? "disabled" : ""); _ck(_v, 7, 0, currVal_2); var currVal_4 = ((_co.documentCounterData.countMy === 0) ? "disabled" : ""); _ck(_v, 13, 0, currVal_4); var currVal_5 = ((_co.documentCounterData.countMy === 0) ? "disabled" : ""); _ck(_v, 14, 0, currVal_5); var currVal_7 = ((_co.documentCounterData.countGroup === 0) ? "disabled" : ""); _ck(_v, 20, 0, currVal_7); var currVal_8 = ((_co.documentCounterData.countGroup === 0) ? "disabled" : ""); _ck(_v, 21, 0, currVal_8); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 2, 0, i1.ɵnov(_v, 3).transform("MODULEMANAGEMENT.DOCUMENTS")); _ck(_v, 2, 0, currVal_0); var currVal_3 = _co.documentCounterData.countNew.toString(); _ck(_v, 8, 0, currVal_3); var currVal_6 = _co.documentCounterData.countMy.toString(); _ck(_v, 15, 0, currVal_6); var currVal_9 = _co.documentCounterData.countGroup.toString(); _ck(_v, 22, 0, currVal_9); }); }
export function View_DocumentsCounterComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_DocumentsCounterComponent_1)), i1.ɵdid(1, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.dmsUser && _co.dmsUser.isActive); _ck(_v, 1, 0, currVal_0); }, null); }
export function View_DocumentsCounterComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-documents-counter", [], null, null, null, View_DocumentsCounterComponent_0, RenderType_DocumentsCounterComponent)), i1.ɵdid(1, 114688, null, 0, i6.DocumentsCounterComponent, [i7.PollingService, i8.GenericCrudService, i9.LocationService, i10.ModulesStateService, i11.LoggerService, i12.UserSessionService, i13.AuthenticationService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var DocumentsCounterComponentNgFactory = i1.ɵccf("app-documents-counter", i6.DocumentsCounterComponent, View_DocumentsCounterComponent_Host_0, {}, {}, []);
export { DocumentsCounterComponentNgFactory as DocumentsCounterComponentNgFactory };
