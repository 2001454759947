import { AbstractValidator } from './abstract-validator';
import { FormViewerComponent } from '../../form-viewer/form-viewer.component';
export class UserPasswordRepeatValidator extends AbstractValidator {
    constructor() {
        super(...arguments);
        this.passwordControls = [];
    }
    isValid(value) {
        const form = this.getComponent();
        if (form instanceof FormViewerComponent) {
            const passwordControls = this.getPasswordControls(form);
            return this.areValuesTheSame(passwordControls);
        }
        return true;
    }
    getErrorTranslateKey(value) {
        return 'USER_PASSWORD_REPEAT_IS_NOT_VALID';
    }
    getErrorTranslateParams(value) {
        return null;
    }
    areValuesTheSame(passwordControls) {
        if (passwordControls.length === 2) {
            return passwordControls[0].value === passwordControls[1].value;
        }
        return true;
    }
    getPasswordControls(form) {
        const passwordControls = [];
        if (this.passwordControls.length === 2) {
            return this.passwordControls;
        }
        if (form instanceof FormViewerComponent) {
            for (const formControlKey in form.formGroup.controls) {
                if (form.formGroup.controls.hasOwnProperty(formControlKey)) {
                    if (formControlKey.includes('plainPassword')) {
                        passwordControls.push(form.formGroup.get(formControlKey));
                    }
                }
            }
        }
        this.passwordControls = passwordControls;
        return this.passwordControls;
    }
}
