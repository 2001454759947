
  <div class="container" [formGroup]="formGroup">
      <input *ngIf="!hasFormat() && !hasMask()"
        [type]="getInputType()"
        appSeleniumDirective
        [element]="element"
        pInputText
        [formControlName]="formControlName"
        [placeholder]="element.placeholder"
        [readonly]="isReadOnly()"
        [required]="checkRequired()"
        [style.padding-right]="getInputInnerPadding()"
        [style.font-weight]="getFontWeight()"
        (keyup)="keyUp.next($event)"
        (blur)="blur.next($event)"
        tabindex="{{element.tabIndex}}"
        #inputElement
      />
      <input *ngIf="hasFormat() && !hasMask()"
        [type]="getInputType()"
        appSeleniumDirective
        [element]="element"
        pInputText
        [formControlName]="formControlName"
        [placeholder]="element.placeholder"
        [readonly]="isReadOnly()"
        [required]="checkRequired()"
        [style.padding-right]="getInputInnerPadding()"
        [style.font-weight]="getFontWeight()"
        (keyup)="keyUp.next($event)"
        (blur)="blur.next($event)"
        tabIndex="{{element.tabIndex}}"
        #inputElement
      />
      <p-inputMask
      [type]="getInputType()"
      *ngIf="hasMask()"
      appSeleniumDirective
      [element]="element"
      mask="{{this.element.inputFormat.mask}}"
      [formControlName]="formControlName"
      [placeholder]="element.placeholder"
      [readonly]="isReadOnly()"
      [required]="checkRequired()"
      [style.padding-right]="getInputInnerPadding()"
      [style.font-weight]="getFontWeight()"
      (keyup)="keyUp.next($event)"
      (blur)="blur.next($event)"
      [tabIndex]="element.tabIndex"
      ></p-inputMask>
    <div class="icon-container" fxFlex="20" *ngIf="((element?.icon | isDefined) && (element?.icon?.relativePath | isDefined))">
      <img height="16" width="16"
        [ngClass]="{
          'cur-pointer': isValid(),
          'cur-disabled': !isValid(),
          'disabled': !isValid()
        }"
        [src]="iconBaseUrl + '/' + element?.icon?.relativePath"
        [alt]="element?.icon?.name"
        (click)="onClickIcon()"
      />
    </div>
    <div class="icon-container" fxFlex="20" *ngIf="hasUrlValidation() || hasEmailValidation()">
      <i class="fa fa-globe" aria-hidden="true" *ngIf="hasUrlValidation()"
        [ngClass]="{
          'cur-pointer': isValid(),
          'cur-disabled': !isValid(),
          'disabled': !isValid()
        }"
        (click)="onClickOpenUrl()"
      ></i>
      <i class="fa fa-envelope" aria-hidden="true" *ngIf="hasEmailValidation()"
        [ngClass]="{
          'cur-pointer': isValid(),
          'cur-disabled': !isValid(),
          'disabled': !isValid()
        }"
        (click)="onClickOpenEmail()"
      ></i>
    </div>
  </div>
  