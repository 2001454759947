import {ChangeDetectionStrategy, Component} from '@angular/core';
import {ToolbarItemAbstract} from '../toolbar-item-abstract.component';
import {GenericElementAbstract} from '../../../../generic-element-abstract.component';
import {ToolbarItem} from '../../../../../../services/element/toolbar-item';
import {LocationService} from '../../../../../../services/location.service';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-toolbar-item-open-quick-link',
  template: ''
})
export class ToolbarItemOpenQuickLinkComponent extends ToolbarItemAbstract {

  public constructor(
    private readonly locationService: LocationService
  ) {
    super();
  }

  public click() {
    const component = this.getComponent(),
      toolbarItem = this.getToolbarItem();

    if (component instanceof GenericElementAbstract) {
      this.handleOpenQuickLink(component, toolbarItem);
    }
  }

  private handleOpenQuickLink(component: GenericElementAbstract, toolbarItem: ToolbarItem): void {
    if (toolbarItem.url) {
      const parts = toolbarItem.url.split('?'),
        apiRoute = parts[0],
        params = parts[1] ? parts[1] : '';

      let queryParams = {};
      if (params) {
        queryParams = this.locationService.getQueryParamsFromString(params);
      }

      this.locationService.getRouter().navigate([apiRoute], {
        queryParams: queryParams
      }).then();
    }
  }
}
