
import {map} from 'rxjs/operators';
import {Observable} from 'rxjs';
import {AbstractExecutionStep} from '../../../../../core/executor/abstract-execution-step';
import {ExecutionStepStatus} from '../../../../../core/executor/execution-step-status';
import {MessageGrowlService} from '../../../../../core/message/message-growl.service';
import {TOAST_LIFE_INFINITE, ToastComponentsRegistry, ToastService} from '../../../../../core/service/toast.service';
import {EntityStatus} from '../../../entity/entity-status';
import {AbstractGenericGridComponent} from '../../../../content-renderer/elements/abstract-generic-grid.component';

export class ContextMenuGenerateInvoiceByWorkHourPeriodEntryExecutionStep extends AbstractExecutionStep {

  public doExecute(): Observable<ExecutionStepStatus> {
    const payload = this.getPayload(),
      component = payload.getValue();

    const selectedEntity = component.getSelectedEntity(),
      index = component.findEntityIndex(selectedEntity);

    const entities = [];
    for (let i = 0; i <= index; i++) {
      entities.push(component.entities[i]);
    }

    if (entities.length === 0) {
      return this.getFailObservable('No entities to calculate');
    }

    return this.doCalculate(component, entities);
  }

  protected doCalculate(component: AbstractGenericGridComponent, entities: any[] = []): Observable<ExecutionStepStatus> {
    const data = {
      workhourEntryIds: []
    };
    for (const selectedEntity of entities) {
      if (!selectedEntity.isSummary) {
        data.workhourEntryIds.push(selectedEntity.id);
      }
    }

    const employee = this.getCurrentFilterValue(component);
    const year = this.getFilterValue(component, 'year');
    const month = this.getFilterValue(component, 'month');

    this.getToastService().custom(ToastComponentsRegistry.PROGRESS_BAR, {
      severity: 'info',
      life: TOAST_LIFE_INFINITE,
      closable: false,
      summary: 'Calculating...'
    });

    return this.genericCrudService.customPost('phoenix/workhours/invoicesettled/byworkhourperiod?year=' + (year || '') + '&month=' + (month || '') + '&leasedEmployee=' + (employee ? employee.id : ''), data).pipe(
      map(
      (response) => {
        this.getToastService().clear(ToastComponentsRegistry.PROGRESS_BAR);

        return {status: true, content: null};
      },
      errorResponse => {
        this.getToastService().clear(ToastComponentsRegistry.PROGRESS_BAR);
        this.getMessageGrowlService().error(errorResponse.error.error);

        return {status: false, content: errorResponse.error.error};
      }
    ));
  }

  private getFilterValue(component: AbstractGenericGridComponent, item: string): string|null {
    return component.gridFilters[item] && component.gridFilters[item].value ? component.gridFilters[item].value : null;
  }

  private getCurrentFilterValue(component: AbstractGenericGridComponent): any|null {
    return component.gridFilters.leasedEmployee && component.gridFilters.leasedEmployee.value &&
    component.gridFilters.leasedEmployee.value[0] ?
      component.gridFilters.leasedEmployee.value[0] :
      null;
  }

  private getMessageGrowlService(): MessageGrowlService {
    return this.injector.get(MessageGrowlService, null);
  }

  private getToastService(): ToastService {
    return this.injector.get(ToastService, null);
  }

}
