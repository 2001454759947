import {DynamicDialogConfig, DynamicDialogRef} from 'primeng/api';
import {Component} from '@angular/core';

@Component({
  selector: 'app-workflow-action-parameter-form',
  templateUrl: './workflow-action-parameter-form.component.html',
})
export class WorkflowActionParameterFormComponent {

  public parameters = {};

  constructor(
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig
  ) { }

  ngOnInit() {
    this.parameters = this.config.data.parameters;
    for (const conf of this.config.data.config) {
      if (typeof this.parameters[conf.code] === 'undefined') {
        this.parameters[conf.code] = null;
      }
    }
  }

  onSave() {
    this.ref.close(this.parameters);
  }

}
