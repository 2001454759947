import { ElementInput } from './element';

export class ElementInputDate extends ElementInput {

  defaultValue: Date;
  placeholder: string;
  showIcon: boolean;
  monthNavigator: boolean;
  yearNavigator: boolean;
  showTime: boolean;
  dateFormat: string;

  constructor() {
    super();
    this.typeElement = 'date';
    this.defaultValue = undefined;
    this.placeholder = undefined;
    this.showIcon = true;
    this.monthNavigator = true;
    this.yearNavigator = true;
    this.showTime = false;
    this.dateFormat = 'date';

    this.inputFormat = {
      mask: '',
      date: {
        format: 'dd.mm.yy',
        hourFormatPmAm: false
      },
      prefix: '',
      suffix: ''
    };
  }
}


