<div class="container" *ngIf="this.cronJob.arguments.length > 0" fxLayout="column" fxLayoutAlign="space-between stretch">

  <div fxFlex="30px">
    Arguments
  </div>

  <div fxFlex="30px" fxLayout="row" fxLayoutAlign="space-between stretch" *ngFor="let argument of this.cronJob.arguments">

    <div fxFlex="30">
      {{argument.name}}
    </div>

    <div fxFlex="70">
      <input [required]="argument.isRequired" type="text" [(ngModel)]="argument.value" pInputText (keyup)="onKeyUp($event)" />
    </div>

  </div>

</div>
