import {ChangeDetectionStrategy, Component} from '@angular/core';
import {ToolbarItemAbstract} from '../toolbar-item-abstract.component';
import {GenericCrudService} from '../../../../../../services/generic-crud.service';
import {AbstractGenericGridComponent} from '../../../../abstract-generic-grid.component';

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'app-toolbar-item-csv-export',
    template: ''
})
export class ToolbarItemCsvExportComponent extends ToolbarItemAbstract {

    public static readonly CLAUSES_URL = 'phoenix/reportclauses';
    public static readonly REPORT_EXPORT_URL = 'phoenix/reports/export';
    public static readonly REPORT_GET_DATA_URL = 'phoenix/reports/jsonexport';
    public static readonly PARAMETER_NAME = 'report';

    public isDialogVisible = false;
    public isDataLoading = false;

    public selectedEntity: any;
    public clauses;

    public clauseValues = {};
    constructor(
        protected genericCrudService: GenericCrudService
    ) {
        super();
    }

    public click() {
      const grid = this.getComponent();
      if (grid instanceof AbstractGenericGridComponent) {
        const url = 'app/csvexport' + '/orderby/'
          + grid.sortField + '/' + grid.sortDirection.toLowerCase();

        const urlParams = {};
        urlParams['filters'] = grid.getRemoteDynamicFilters();
        urlParams['entityFqn'] = grid.getElementDatamodelEntityName();
        urlParams['visibleColumns'] = {};
        for (const column of grid.columns) {
          urlParams['visibleColumns'][column.id] = {
            title: column.header,
            property: column.property,
            displayConfig: column.field.displayConfig,
            isAssociatedField: column.isAssociatedField,
            entityName: column.entityName,
            entityFieldName: column.entityFieldName,
            fieldType: column.fieldType
          };
        }

        this.genericCrudService.customPost(url, urlParams).subscribe(data => {
          const a = document.createElement('a');
          document.body.appendChild(a);
          a.style.setProperty('display', 'none');
          const blob = new Blob([data], { type: 'text/csv' });
          const url = window.URL.createObjectURL(blob);

          a.href = url;
          a.download = grid.getElementDatamodelEntityName() + '_' + (new Date()).toISOString() + '.csv';
          a.click();
          window.URL.revokeObjectURL(url);
        });
      }
    }
}
