import {Injectable} from '@angular/core';
import {GenericCrudService} from '../../../../../services/generic-crud.service';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {FormViewerComponent} from '../../../../../form-viewer/form-viewer.component';
import {Entity} from '../../../../../helpers/entity';

@Injectable()
export class CostCenterAutocompleteHandlerService {

  public constructor(
    private genericCrudService: GenericCrudService,
    private masterComponent?: FormViewerComponent
  ) {

  }

  public getData(query: string): Observable<any[]> {
    const route = `phoenix/customercostcenters/offset/0/limit/30/orderby/id/asc?search=${query}`,
      requestParams = {clause: 'orWhere', embedded: 'none'};

    if (this.masterComponent.entity) {
      const customer = Entity.getValue(this.masterComponent.entity, 'customer') || Entity.getValueInEmbedded(this.masterComponent.entity, 'customer');
      if (customer) {
        requestParams['customer'] = customer;
      }
    }

    return this.genericCrudService.getEntities(route, '', requestParams)
      .pipe(map((paginated: {data: any[]}) => {
        const data = paginated.data;

        for (const costCenter of data) {
          costCenter.label = costCenter.name;
        }

        return data;
      }));
  }
}
