import {Input, OnInit} from '@angular/core';
import {Question, Questionnaire, QuestionParameter} from '../../../../../shared/services/element/questionnaire';

export abstract class AbstractAdminQuestionComponent implements OnInit {
  @Input() question: Question = null;
  @Input() questionnaire: Questionnaire = null;

  public abstract onComponentInit();

  public ngOnInit(): void {
    this.question.questionParameters = this.question.questionParameters || [];

    this.onComponentInit();
  }

  public getParam(key: string): QuestionParameter|null {
    let foundParam = null;

    for (const param of this.question.questionParameters) {
      if (param.name === key) {
        foundParam = param;
        break;
      }
    }

    return foundParam;
  }

  public getOrCreateParam(key: string): QuestionParameter {
    let param = this.getParam(key);

    if (!param) {
      param = {
        name: key,
        value: null
      };

      this.question.questionParameters.push(param);
    }

    return param;
  }

  public getParamValue(key: string): any|null {
    const param = this.getParam(key);

    return param && param.value ? param.value : null;
  }
}
