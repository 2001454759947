
import {of as observableOf, Observable} from 'rxjs';
import {Component, ElementRef, OnInit} from '@angular/core';
import {DoubleClickService} from '../../../../services/double-click.service';
import {Module} from '../../../../../services/module/module';
import {GenericCrudService} from '../../../../../services/generic-crud.service';
import {AbstractGridColumnComponent} from '../abstract-grid-column.component';
import {JobContainerService} from '../../../../../../core/job-runner/job-container.service';
import {GenericElementAbstract} from '../../../generic-element-abstract.component';
import {GenericDialogModuleService} from '../../../generic-dialog/service/generic-dialog-module.service';
import {ModulesStateService} from '../../../../services/modules-state.service';
import {TranslateService} from '@ngx-translate/core';
import {EntityHydrator} from '../../../../../services/entity-hydrator.service';
import {ExecutionStatusSuccess} from '../../../../../../core/executor/execution-status-success';
import {FormViewerComponent} from '../../../../../form-viewer/form-viewer.component';
import {AbstractGenericGridComponent} from '../../../abstract-generic-grid.component';
import {ExecutionStatusError} from '../../../../../../core/executor/execution-status-error';
import {ComponentValidationExecutionStep} from '../../../../../services/execution-step/validation/component-validation-execution-step';
import {EntityValidationExecutionStep} from '../../../../../services/execution-step/validation/entity-validation-execution-step';
import {ExecutionStepBuilderService} from '../../../../../../core/executor/builder/execution-step-builder.service';
import {MessageService} from '../../../../../../core/message/message.service';
import {ExecutorService} from '../../../../../../core/executor/executor.service';
import {ExecutionStatus} from '../../../../../../core/executor/execution-status';
import {map} from 'rxjs/operators';


@Component({
  selector: 'app-disposition-column',
  templateUrl: './disposition-action-column.component.html'
})
export class DispositionActionColumnComponent extends AbstractGridColumnComponent implements OnInit {
  protected preCalculationType: any = null;

  constructor(
    protected elementRef: ElementRef,
    private doubleClickService: DoubleClickService,
    private genericCrudService: GenericCrudService,
    private jobContainerService: JobContainerService,
    private genericDialogModuleService: GenericDialogModuleService,
    private translateService: TranslateService,
    private modulesStateService: ModulesStateService,
    private executionStepBuilderService: ExecutionStepBuilderService,
    private executorService: ExecutorService,
    private messageService: MessageService,
    private hydrator: EntityHydrator
  ) {
    super(elementRef);
  }

  ngOnInit() {
  }

  isHidden(type) {
    return false;
  }

  isDisabled() {
    let isDisabled = true;
    const dispoState = this.hydrator.getEntityPropertyValue(this.entity, 'dispoState.code');

    switch (dispoState) {
      case 'CONFIRMED':
        isDisabled = false;
        break;
      default:
        isDisabled = true;
    }
    return isDisabled;
  }

  getButtonLabel() {
    return this.translateService.instant('DISPOSITION.OPEN_DETAILS');
  }

  openDetails(event) {
    this.saveModule().pipe(
      map((status: ExecutionStatus) => {
        const entity = this.component.getElementContext().getMasterElementContext().component.entity;
        this.genericCrudService.getEntity('phoenix/inquiries', entity.inquiry.id, '', {embedded: 'preCalculationType'}).subscribe((result) => {
          if (result && result.preCalculationType.code === 'VER' && status.isSuccess()) {
            this.doOpenMediation();
          } else if (status.isSuccess()) {
            this.doOpenDetails();
          } else {
            this.onExecutorFailure(status);
          }
        });
      })
    ).subscribe();
  }

  private doOpenDetails() {
    if (this.column && this.column.config) {
      const configModule = this.findConfig('open_details');
      if (configModule) {
        const module = new Module();
        module.id = configModule.value;

        this.doubleClickService
          .openModuleInNewTabCustom(module, 'assignments', 'PhoenixBundle\\Entity\\Assignment', this.component.moduleElement, this.entity.id);
      }
    }
  }

  private doOpenMediation() {
    if (this.column && this.column.config) {
      const mediationModule = this.findConfig('mediation_details');
      if (mediationModule) {
        const module = new Module();
        module.id = mediationModule.value;

        this.genericCrudService.customPost(`phoenix/mediations/${this.entity.id}/create-from-assignment`, {}, {embedded: 'none'}).subscribe((result) => {
          this.doubleClickService
            .openModuleInNewTabCustom(module, 'mediations', 'PhoenixBundle\\Entity\\Mediation', this.component.moduleElement, result.id);
        });
      }
    }
  }

  getButtonWidth() {
    return '100%';
  }

  private saveModule(): Observable<ExecutionStatusSuccess> {
    const mainModule = this.modulesStateService.getCurrent();

    if (null !== mainModule && this.hasChanges()) {
      const executionSteps = this.executionStepBuilderService.createExecutionSteps(mainModule);

      for (const step of executionSteps) {
        this.executorService.addStep(step);
      }

      return this.executorService.execute();
    } else {
      return observableOf(new ExecutionStatusSuccess({status: true, content: null}, null));
    }
  }

  protected hasChanges(): boolean {
    let hasChange = false,
      componentsOnPage = [];
    const moduleState = this.modulesStateService.getCurrent();

    if (moduleState) {
      componentsOnPage = moduleState.getAllComponents();
    }

    for (const component of componentsOnPage) {
      if (component instanceof AbstractGenericGridComponent) {
        hasChange = this.componentEntityHasBeenChanged(component);
      }

      if (component instanceof FormViewerComponent) {
        hasChange = this.componentEntityHasBeenChanged(component);
      }

      if (hasChange) {
        break;
      }
    }

    return hasChange;
  }

  private componentEntityHasBeenChanged(component: GenericElementAbstract): boolean {
    let entityHasBeenChanged = false;

    // Now a little twist - in case there are really no changes, check if we are in a grid AND there are changes to be saved:
    if (component && component instanceof AbstractGenericGridComponent) {
      entityHasBeenChanged = component.getUpdatedEntities().length > 0 || component.getCreatedEntities().length > 0 || component.getDraftDeletedEntities().length > 0;
    }

    if (component && component instanceof FormViewerComponent) {
      entityHasBeenChanged = component.isEntityDirty();
    }

    return entityHasBeenChanged;
  }

  public onExecutorFailure(status: ExecutionStatusError): void {
    if (status.getStep() instanceof ComponentValidationExecutionStep ||
      status.getStep() instanceof EntityValidationExecutionStep
    ) {
      this.onComponentValidationFailure(status);
    }
  }

  public onComponentValidationFailure(status: ExecutionStatusError): void {
    this.messageService.confirm({
      acceptVisible: true,
      rejectVisible: false,
      header: this.translateService.instant('COMMON.ERROR'),
      message: status.getStepContent(),
      accept: () => { }
    });
  }
}
