import {Component, OnInit} from '@angular/core';
import {AbstractGenericGridSimpleSearchFieldComponent} from './abstract-generic-grid-simple-search-field.component';
import {AbstractGridFilter} from '../../../generic-grid/filters/abstract-grid-filter';

@Component({
  selector: 'app-generic-grid-simple-search-field-date',
  template: `
    <div class="ui-g">
      <div class="ui-g-2">
        {{ 'COMMON.FROM' | translate }}:
      </div>
      <div class="ui-g-4">
        <app-generic-calendar
          (onInputChangeValid)="onFilterFrom($event)"
          (onSelect)="onFilterFrom($event)"
          [value]="this.field.value ? this.field.value.from : null"
          [yearRange]="'1950:2050'"
          [mask]="'99.99.9999'"
          [dateFormat]="'dd.mm.yy'"
          [showOnFocus]="true"
        ></app-generic-calendar>
      </div>

      <div class="ui-g-2">
        {{ 'COMMON.TO' | translate }}:
      </div>
      <div class="ui-g-4">
        <app-generic-calendar
          (onInputChangeValid)="onFilterTo($event)"
          (onSelect)="onFilterTo($event)"
          [value]="this.field.value ? this.field.value.to : null"
          [yearRange]="'1950:2050'"
          [mask]="'99.99.9999'"
          [dateFormat]="'dd.mm.yy'"
          [showOnFocus]="true"
        ></app-generic-calendar>
      </div>
    </div>
  `
})
export class GenericGridSimpleSearchFieldDateComponent extends AbstractGenericGridSimpleSearchFieldComponent implements OnInit {

  public ngOnInit(): void {
    this.field.comparator = AbstractGridFilter.MATCH_MODE_GREATER_THAN;
  }

  public onFilterFrom(date: Date) {
    this.field.value = this.field.value || {};
    this.field.value.from = date;

    this.createValue();
  }

  public onFilterTo(date: Date) {
    this.field.value = this.field.value || {};
    this.field.value.to = date;

    this.createValue();
  }

  public createValue(): void {
    this.field.comparator = this.getComparator();
    this.field.searchValue = this.getSearchValue();

    if (this.field.searchValue === null) {
      this.field.value = null;
    }
  }

  private getComparator(): string|null {
    let comparator = null;

    if (this.field.value.from && !this.field.value.to) {
      comparator = AbstractGridFilter.MATCH_MODE_GREATER_THAN;
    }

    if (this.field.value.from && this.field.value.to) {
      comparator = AbstractGridFilter.MATCH_MODE_RANGE;
    }

    if (!this.field.value.from && this.field.value.to) {
      comparator = AbstractGridFilter.MATCH_MODE_LOWER_THAN;
    }

    return comparator;
  }

  private getSearchValue(): string {
    let value = null;

    if (this.field.value.from && !this.field.value.to) {
      value = this.field.value.from.toISOString();
    }

    if (this.field.value.from && this.field.value.to) {
      value += this.field.value.from.toISOString() + '|' + this.field.value.to.toISOString();
    }

    if (!this.field.value.from && this.field.value.to) {
      value = this.field.value.to.toISOString();
    }

    return value;
  }

  private stringDMYToDate(value) {
    const filterValueParts = value.split('.'),
      dateValue = new Date(parseInt(filterValueParts[2]), parseInt(filterValueParts[1]) - 1, parseInt(filterValueParts[0]), 0, 0, 0, 0);

    return dateValue.toISOString().replace(/\.\d+Z/, 'Z');
  }
}
