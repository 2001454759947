import {Component, ElementRef} from '@angular/core';
import {AbstractGridColumnComponent} from '../abstract-grid-column.component';
import {GenericTreeGridComponent} from '../../../generic-tree-grid/generic-tree-grid.component';
import {AbstractGenericGridComponent} from '../../../abstract-generic-grid.component';
import {DoubleClickService} from '../../../../services/double-click.service';

@Component({
  selector: 'app-curriculum-action-column',
  templateUrl: `curriculum-action-column.component.html`,
})
export class CurriculumActionColumnComponent extends AbstractGridColumnComponent {

  public constructor(
    protected elementRef: ElementRef,
    protected doubleClickService: DoubleClickService
  ) {
    super(elementRef);
  }

  public onAddNode(): void {
    if (this.component instanceof GenericTreeGridComponent) {
      this.component.selectedNode = this.node;
      this.component.selectedEntity = this.entity;
      this.component.startNewEntityAdd(this.component.emptyEntity);
    }
  }

  public getColor(): string | null {
    if (this.entity && this.entity.fqn === 'PhoenixBundle\\Entity\\Curriculum') {
      if (
        (typeof this.entity.changeReason === 'undefined' || this.entity.changeReason === '')
        && (typeof this.entity.description === 'undefined' || this.entity.description === '')
      ) {
        return '#FFFF00';
      } else {
        return '#008000';
      }
    }

    return null;
  }

  public doOpenModule() {
    this.component.triggerEditEntityActions();
    const targetModule = this.getConfigValue('targetModule'),
      height = this.getConfigValue('dialogHeight'),
      width = this.getConfigValue('dialogWidth');

    this.doubleClickService.setComponent(this.component)
      .setDialogOptions({
        height: height || 300,
        width: width || 600,
        isModal: true,
        maximized: false
      })
      .handleOpenModule(this.component, 'dialog', targetModule, this.entity);
  }
}
