import {Component, ElementRef, OnInit} from '@angular/core';
import {DoubleClickService} from '../../../../services/double-click.service';
import {GenericCrudService} from '../../../../../services/generic-crud.service';
import {AbstractGridColumnComponent} from '../abstract-grid-column.component';

@Component({
  selector: 'app-collective-agreement-valid-to-column',
  templateUrl: './collective-agreement-valid-to-column.component.html'
})
export class CollectiveAgreementValidToColumnComponent extends AbstractGridColumnComponent implements OnInit {

  public collectiveAgreementValidTo: any = null;

  constructor(
    protected elementRef: ElementRef,
    private doubleClickService: DoubleClickService,
    private genericCrudService: GenericCrudService
  ) {
    super(elementRef);
  }

  ngOnInit() {
    let collectiveAgreement = this.genericCrudService.getEntityHydrator().getEntityPropertyValue(this.entity,'collectiveAgreement'),
      customer = this.genericCrudService.getEntityHydrator().getEntityPropertyValue(this.entity,'customer');

    if(this.entity && this.entity.fqn === 'PhoenixBundle\\Entity\\CollectiveAgreement'){
      collectiveAgreement = this.entity;
    }
    collectiveAgreement = collectiveAgreement['id'] || collectiveAgreement;
    customer = customer['id'] || customer;

    this.genericCrudService.getEntities(`phoenix/collectiveagreements/${collectiveAgreement}/customer/${customer}/validto`).subscribe((validTo) => {
      this.collectiveAgreementValidTo = validTo;
    });
  }
}
