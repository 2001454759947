import { AbstractInlineEditor } from './abstract-inline-editor';
import {Entity} from '../../../../helpers/entity';
import {Observable, of} from 'rxjs';

export class CheckboxInlineEditor extends AbstractInlineEditor {

  public getValue(entity: any) {
    let value = Entity.getValue(entity, this.getEntityColumnProperty()) ||
      Entity.getValueInEmbedded(entity, this.getEntityColumnProperty());

    if (value == null && entity.id == null) {
      value = this.field['defaultValue'];
      this.changeEntityColumnProperty(value);
    }

    return value ? value : null;
  }

  public onEdit(entity: any, event: any) {
    this.changeEntityColumnProperty(event);
  }

  public onBlur(entity: any, event: any) {

  }

  public onFocus(entity: any, event: any): Observable<any> {
    return of(null);
  }

  protected changeAssociatedEntityColumnProperty(associatedEntity, property, value) {
    associatedEntity[property] = value;

    if (associatedEntity._embedded && associatedEntity._embedded[property]) {
      associatedEntity._embedded[property] = value;
    }
  }
}
