<span>
  <span style="color: black;">Aufgaben:</span>
  <span style="color: black;"
    (click)="openModule(1)"
    [ngClass]="todoCounterData.countMy === 0 ? 'disabled' : ''"
  >
    {{todoCounterData.countMy.toString()}}
  </span>
  <span style="color: black;">/</span>
  <span style="color: black;"
    (click)="openModule(2)"
    [ngClass]="todoCounterData.countGroup === 0 ? 'disabled' : ''"
  >
    {{todoCounterData.countGroup.toString()}}
  </span>
  <span style="color: black;">/</span>
  <span style="color: black;"
        (click)="openModule(3)"
        [ngClass]="todoCounterData.countICreated === 0 ? 'disabled' : ''"
  >
    {{todoCounterData.countICreated.toString()}}
  </span>
  <span style="color: black;">/</span>
  <span style="color: black;"
    (click)="openModule(4)"
    [ngClass]="todoCounterData.countBranchOffice === 0 ? 'disabled' : ''"
  >
    {{todoCounterData.countBranchOffice.toString()}}
  </span>
</span>
