/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@ngx-translate/core";
import * as i2 from "../../../generic-calendar/generic-calendar.component.ngfactory";
import * as i3 from "../../../generic-calendar/generic-calendar.component";
import * as i4 from "./generic-grid-simple-search-field-date.component";
var styles_GenericGridSimpleSearchFieldDateComponent = [];
var RenderType_GenericGridSimpleSearchFieldDateComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_GenericGridSimpleSearchFieldDateComponent, data: {} });
export { RenderType_GenericGridSimpleSearchFieldDateComponent as RenderType_GenericGridSimpleSearchFieldDateComponent };
export function View_GenericGridSimpleSearchFieldDateComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 12, "div", [["class", "ui-g"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 2, "div", [["class", "ui-g-2"]], null, null, null, null, null)), (_l()(), i0.ɵted(2, null, [" ", ": "])), i0.ɵpid(131072, i1.TranslatePipe, [i1.TranslateService, i0.ChangeDetectorRef]), (_l()(), i0.ɵeld(4, 0, null, null, 2, "div", [["class", "ui-g-4"]], null, null, null, null, null)), (_l()(), i0.ɵeld(5, 0, null, null, 1, "app-generic-calendar", [], null, [[null, "onInputChangeValid"], [null, "onSelect"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("onInputChangeValid" === en)) {
        var pd_0 = (_co.onFilterFrom($event) !== false);
        ad = (pd_0 && ad);
    } if (("onSelect" === en)) {
        var pd_1 = (_co.onFilterFrom($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i2.View_GenericCalendarComponent_0, i2.RenderType_GenericCalendarComponent)), i0.ɵdid(6, 114688, null, 0, i3.GenericCalendarComponent, [], { showOnFocus: [0, "showOnFocus"], dateFormat: [1, "dateFormat"], yearRange: [2, "yearRange"], mask: [3, "mask"], value: [4, "value"] }, { onInputChangeValid: "onInputChangeValid", onSelect: "onSelect" }), (_l()(), i0.ɵeld(7, 0, null, null, 2, "div", [["class", "ui-g-2"]], null, null, null, null, null)), (_l()(), i0.ɵted(8, null, [" ", ": "])), i0.ɵpid(131072, i1.TranslatePipe, [i1.TranslateService, i0.ChangeDetectorRef]), (_l()(), i0.ɵeld(10, 0, null, null, 2, "div", [["class", "ui-g-4"]], null, null, null, null, null)), (_l()(), i0.ɵeld(11, 0, null, null, 1, "app-generic-calendar", [], null, [[null, "onInputChangeValid"], [null, "onSelect"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("onInputChangeValid" === en)) {
        var pd_0 = (_co.onFilterTo($event) !== false);
        ad = (pd_0 && ad);
    } if (("onSelect" === en)) {
        var pd_1 = (_co.onFilterTo($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i2.View_GenericCalendarComponent_0, i2.RenderType_GenericCalendarComponent)), i0.ɵdid(12, 114688, null, 0, i3.GenericCalendarComponent, [], { showOnFocus: [0, "showOnFocus"], dateFormat: [1, "dateFormat"], yearRange: [2, "yearRange"], mask: [3, "mask"], value: [4, "value"] }, { onInputChangeValid: "onInputChangeValid", onSelect: "onSelect" })], function (_ck, _v) { var _co = _v.component; var currVal_1 = true; var currVal_2 = "dd.mm.yy"; var currVal_3 = "1950:2050"; var currVal_4 = "99.99.9999"; var currVal_5 = (_co.field.value ? _co.field.value.from : null); _ck(_v, 6, 0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5); var currVal_7 = true; var currVal_8 = "dd.mm.yy"; var currVal_9 = "1950:2050"; var currVal_10 = "99.99.9999"; var currVal_11 = (_co.field.value ? _co.field.value.to : null); _ck(_v, 12, 0, currVal_7, currVal_8, currVal_9, currVal_10, currVal_11); }, function (_ck, _v) { var currVal_0 = i0.ɵunv(_v, 2, 0, i0.ɵnov(_v, 3).transform("COMMON.FROM")); _ck(_v, 2, 0, currVal_0); var currVal_6 = i0.ɵunv(_v, 8, 0, i0.ɵnov(_v, 9).transform("COMMON.TO")); _ck(_v, 8, 0, currVal_6); }); }
export function View_GenericGridSimpleSearchFieldDateComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-generic-grid-simple-search-field-date", [], null, null, null, View_GenericGridSimpleSearchFieldDateComponent_0, RenderType_GenericGridSimpleSearchFieldDateComponent)), i0.ɵdid(1, 114688, null, 0, i4.GenericGridSimpleSearchFieldDateComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var GenericGridSimpleSearchFieldDateComponentNgFactory = i0.ɵccf("app-generic-grid-simple-search-field-date", i4.GenericGridSimpleSearchFieldDateComponent, View_GenericGridSimpleSearchFieldDateComponent_Host_0, { field: "field" }, {}, []);
export { GenericGridSimpleSearchFieldDateComponentNgFactory as GenericGridSimpleSearchFieldDateComponentNgFactory };
