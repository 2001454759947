
import {debounceTime} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { DateHelper } from '../helpers/date.helper';
import { Subject } from 'rxjs';

@Injectable()
export class LayoutService {

    private layoutSizeChanged = new Subject<any>();

    layoutSizeChanged$ = this.layoutSizeChanged.asObservable().pipe(debounceTime(2));

    onLayoutSizeChanged() {
        this.layoutSizeChanged.next();
    }
}
