
import {forkJoin as observableForkJoin, of as observableOf, Observable} from 'rxjs';

import {catchError} from 'rxjs/operators';
import {AbstractExecutionStep} from '../../../../../core/executor/abstract-execution-step';
import {ExecutionStepStatus} from '../../../../../core/executor/execution-step-status';
import {ExpertSearch, ExpertSearchField} from '../../../../services/expert-search/expert-search';
import {EntityStatus} from '../../../../services/entity/entity-status';

export class DeleteExpertSearchContainerFieldsExecutionStep extends AbstractExecutionStep {

  public doExecute(): Observable<ExecutionStepStatus> {
    const expertSearch: ExpertSearch = this.getPayload().getValue().expertSearch;

    if (!expertSearch) {
      return this.getFailObservable('ExpertSearch not found!');
    }

    return this.doSave(expertSearch);
  }

  public doSave(expertSearch: ExpertSearch): Observable<ExecutionStepStatus> {
    const observables = [];

    for (const aContainer of expertSearch.containers) {
      const isContainerFlaggedForDelete = aContainer[EntityStatus.ENTITY_DRAFT_BACKEND_DELETE_FLAG];

      for (const aField of aContainer.fields) {
        if (aField.id && aField[EntityStatus.ENTITY_DRAFT_BACKEND_DELETE_FLAG] && !isContainerFlaggedForDelete) {
          observables.push(
            this.genericCrudService
              .deleteEntity(`app/expertsearchcontainerfields/${aField.id}`)
          );
        }
      }
    }

    if (observables.length === 0) {
      return observableOf({status: true, content: null});
    }

    return Observable.create((observer) => {
      observableForkJoin(observables).pipe(
        catchError((response: any) => {
          return observableOf(response);
        }))
        .subscribe((aFields: ExpertSearchField[] = []) => {
          observer.next({status: true, content: null});
          observer.complete();
        });
    });
  }
}
