<div *ngIf="expertSearch" fxLayout="column" fxLayoutAlign="start stretch">
  <ng-template ngFor let-container [ngForOf]="expertSearch.containers">
    <div *ngIf="!container.isFlaggedForBackendDelete">
      <app-search-management-edit-container
        [expertSearch]="expertSearch"
        [container]="container"
        (onChange)="this.onChange.emit($event)"
      ></app-search-management-edit-container>
    </div>
  </ng-template>

  <div class="add-container-wrapper">
    <button
      (click)="onAddContainer($event)"
      pButton
      label="{{ 'COMMON.OR' | translate }}"
      class="add-container-button"
      icon="fa fa-arrow-down"
    ></button>
  </div>
</div>

