import { Injectable } from '@angular/core';
import { Location } from '@angular/common';
import { Router, ActivatedRoute } from '@angular/router';

@Injectable()
export class LocationService {

  constructor(
    private location: Location,
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) {
  }

  public setWindowLocationParam(param: string, value: any): string {
    let route = window.location.pathname;

    if (this.windowLocationHasParam(param)) {
      const searchValue = param + '/' + this.getWindowLocationParam(param),
        replaceValue = param + '/' + value;

      route = route.replace(searchValue, replaceValue);

      this.location.replaceState(route);
    }

    return route;
  }

  public getWindowLocationParam(param): any {
    const route = window.location.pathname;

    let value = route.substring(route.indexOf(param) + param.length + 1);

    if (value.indexOf('/') !== -1) {
      value = value.substr(0, value.indexOf('/'));
    }

    return value;
  }

  public setParam(param: string, value: any): string {
    let route = this.router.url.substr(1);

    if (!this.routerHasParam(param)) {
      route = window.location.pathname;
    }

    if (this.hasParam(param)) {
      const searchValue = param + '/' + this.getParam(param),
        replaceValue = param + '/' + value;

      route = route.replace(searchValue, replaceValue);

      this.location.replaceState(route);
    }

    return route;
  }

  public hasParam(param): boolean {
    const hasParam = this.getRouter().url.indexOf(param) !== -1;

    if (!hasParam) {
      return window.location.pathname.indexOf(param) !== -1;
    }

    return hasParam;
  }

  public windowLocationHasParam(param): boolean {
    return window.location.pathname.indexOf(param) !== -1;
  }

  public routerHasParam(param): boolean {
    return this.getRouter().url.indexOf(param) !== -1;
  }

  public getParam(param): any {
    const route = this.router.url.substr(1);

    let value = route.substring(route.indexOf(param) + param.length + 1);

    if (value.indexOf('/') !== -1) {
      value = value.substr(0, value.indexOf('/'));
    }

    return value;
  }

  public getQueryParams(): Object {
    return this.getQueryParamsFromString(location.search.slice(1))
  }

  public getQueryParamsFromString(urlString: string): Object {
    let params = {};

    if (urlString.indexOf('?') > -1) {
      urlString = urlString.split('?')[1];
    }

    urlString
      .split("&")
      .forEach(function (item) {
        let tmp = item.split("=");

        if (tmp[0] && tmp[1]) {
            params[tmp[0]] = decodeURIComponent(tmp[1]);
        }
      });

    return params;
  }

  public getLocation(): Location {
      return this.location;
  }

  public getRouter(): Router {
      return this.router;
  }

  public getActivatedRoute(): ActivatedRoute {
      return this.activatedRoute;
  }

  public getWindowLocation() {
      return location;
  }

  public getRouterUrl(): string {
    let routerUrl = location.pathname,
      baseHref = this.getLocation().prepareExternalUrl('');

    // remove base href
    if (routerUrl.indexOf(baseHref) > -1) {
      routerUrl = routerUrl.replace(baseHref, "");
    }

    return routerUrl;
  }
}
