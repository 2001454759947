<a (click)="onItemClick(item)" *ngIf="!item.isSeparator && item.isCustomMenuItem" [pTooltip]="item.tooltip" [tooltipPosition]="menuPosition == 'right' ? 'left' : 'right'">
  <div class="item-container item-text-container">
    {{item.label}}
  </div>
</a>
<p-dialog
  *ngIf="isDialogVisible"
  [(visible)]="isDialogVisible"
  header="Vertragsdaten export"
  showEffect="fade"
  [width]="500"
  [height]="400"
  appendTo="body"
>

  <p-toolbar styleClass="no-round-corner">
    <div class="buttons-container">
      <div class="item-spacer"></div>
      <div class="item-right">
        <button
          type="button"
          pButton
          (click)="onExport($event)"
          label="Export"
        ></button>
      </div>
    </div>
  </p-toolbar>

  <p-fieldset class="fieldset" legend="Export-Art">
    <div fxLayout="row wrap" class="editor-sidebar-inner">
      <div fxFlex="100">
        <div class="input-group">
          <div class="input-group-input">
            <p-radioButton [(ngModel)]="exportMode" value="latest" label="Neue Vertragsdaten"></p-radioButton>
          </div>
        </div>
      </div>
      <div fxFlex="100">
        <div class="input-group">
          <div class="input-group-input">
            <p-radioButton [(ngModel)]="exportMode" value="all" label="Alle Vertragsdaten"></p-radioButton>
          </div>
        </div>
      </div>
    </div>
  </p-fieldset>
</p-dialog>
