/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./element-audit.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../content-renderer/elements/generic-loading/generic-loading.component.ngfactory";
import * as i3 from "../../content-renderer/elements/generic-loading/generic-loading.component";
import * as i4 from "./tree/element-audit-tree.component.ngfactory";
import * as i5 from "./tree/element-audit-tree.component";
import * as i6 from "../../services/generic-crud.service";
import * as i7 from "../../../../../node_modules/primeng/components/tabview/tabview.ngfactory";
import * as i8 from "primeng/components/tabview/tabview";
import * as i9 from "@angular/common";
import * as i10 from "./element-audit.component";
var styles_ElementAuditComponent = [i0.styles];
var RenderType_ElementAuditComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ElementAuditComponent, data: {} });
export { RenderType_ElementAuditComponent as RenderType_ElementAuditComponent };
function View_ElementAuditComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-generic-loading", [], null, null, null, i2.View_GenericLoadingComponent_0, i2.RenderType_GenericLoadingComponent)), i1.ɵdid(1, 114688, null, 0, i3.GenericLoadingComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
function View_ElementAuditComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-element-audit-tree", [], null, null, null, i4.View_ElementAuditTreeComponent_0, i4.RenderType_ElementAuditTreeComponent)), i1.ɵdid(1, 49152, null, 0, i5.ElementAuditTreeComponent, [i1.ChangeDetectorRef, i6.GenericCrudService], { masterDatamodel: [0, "masterDatamodel"], datamodel: [1, "datamodel"], masterAudit: [2, "masterAudit"], audit: [3, "audit"], entity: [4, "entity"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.audit.datamodel; var currVal_1 = _v.parent.context.$implicit.datamodel; var currVal_2 = _co.audit; var currVal_3 = _v.parent.context.$implicit; var currVal_4 = _co.entity; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4); }, null); }
function View_ElementAuditComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 16777216, null, null, 4, "p-tabPanel", [], null, null, null, i7.View_TabPanel_0, i7.RenderType_TabPanel)), i1.ɵdid(1, 1228800, [[1, 4]], 1, i8.TabPanel, [i1.ViewContainerRef], { header: [0, "header"], selected: [1, "selected"] }, null), i1.ɵqud(603979776, 2, { templates: 1 }), (_l()(), i1.ɵand(16777216, null, 0, 1, null, View_ElementAuditComponent_5)), i1.ɵdid(4, 16384, null, 0, i9.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _v.context.$implicit.datamodel.name; var currVal_1 = (_v.context.index === _co.tabIndex); _ck(_v, 1, 0, currVal_0, currVal_1); var currVal_2 = (_v.context.index === _co.tabIndex); _ck(_v, 4, 0, currVal_2); }, null); }
function View_ElementAuditComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [["class", "ui-g-12"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 4, "p-tabView", [], null, [[null, "onChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("onChange" === en)) {
        var pd_0 = (_co.onTabChange($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i7.View_TabView_0, i7.RenderType_TabView)), i1.ɵdid(2, 1097728, null, 1, i8.TabView, [i1.ElementRef], null, { onChange: "onChange" }), i1.ɵqud(603979776, 1, { tabPanels: 1 }), (_l()(), i1.ɵand(16777216, null, 0, 1, null, View_ElementAuditComponent_4)), i1.ɵdid(5, 278528, null, 0, i9.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.audit.subAudits; _ck(_v, 5, 0, currVal_0); }, null); }
function View_ElementAuditComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [["class", "ui-g"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "div", [["class", "ui-g-12"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "app-element-audit-tree", [], null, null, null, i4.View_ElementAuditTreeComponent_0, i4.RenderType_ElementAuditTreeComponent)), i1.ɵdid(3, 49152, null, 0, i5.ElementAuditTreeComponent, [i1.ChangeDetectorRef, i6.GenericCrudService], { datamodel: [0, "datamodel"], audit: [1, "audit"], entity: [2, "entity"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ElementAuditComponent_3)), i1.ɵdid(5, 16384, null, 0, i9.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.element.datamodel; var currVal_1 = _co.audit; var currVal_2 = _co.entity; _ck(_v, 3, 0, currVal_0, currVal_1, currVal_2); var currVal_3 = (_co.audit.subAudits && (_co.audit.subAudits.length > 0)); _ck(_v, 5, 0, currVal_3); }, null); }
export function View_ElementAuditComponent_0(_l) { return i1.ɵvid(2, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_ElementAuditComponent_1)), i1.ɵdid(1, 16384, null, 0, i9.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ElementAuditComponent_2)), i1.ɵdid(3, 16384, null, 0, i9.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.isLoadingData; _ck(_v, 1, 0, currVal_0); var currVal_1 = (_co.audit && !_co.isLoadingData); _ck(_v, 3, 0, currVal_1); }, null); }
export function View_ElementAuditComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-element-audit", [], null, null, null, View_ElementAuditComponent_0, RenderType_ElementAuditComponent)), i1.ɵdid(1, 114688, null, 0, i10.ElementAuditComponent, [i1.ChangeDetectorRef, i6.GenericCrudService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ElementAuditComponentNgFactory = i1.ɵccf("app-element-audit", i10.ElementAuditComponent, View_ElementAuditComponent_Host_0, { element: "element", audit: "audit", entity: "entity" }, {}, []);
export { ElementAuditComponentNgFactory as ElementAuditComponentNgFactory };
