
import {of as observableOf, Observable} from 'rxjs';

import {map} from 'rxjs/operators';
import {AbstractExecutionStep} from '../../../../core/executor/abstract-execution-step';
import {ExecutionStepStatus} from '../../../../core/executor/execution-step-status';
import {MessageGrowlService} from '../../../../core/message/message-growl.service';
import {TOAST_LIFE_INFINITE, ToastComponentsRegistry, ToastService} from '../../../../core/service/toast.service';
import {TranslateService} from '@ngx-translate/core';
import {FormViewerComponent} from '../../../form-viewer/form-viewer.component';
import {Entity} from '../../../helpers/entity';
import {GenericDialogModuleService} from '../../../content-renderer/elements/generic-dialog/service/generic-dialog-module.service';
import {ExecutionStepFactoryService} from '../../../../core/executor/factory/execution-step-factory.service';
import {ComponentRefreshExecutionStep} from '../component-refresh-execution-step';
import {GenericGridComponent} from '../../../content-renderer/elements/generic-grid/generic-grid.component';

export class ToolbarItemCheckDuplicateEntityExecutionStep extends AbstractExecutionStep {

  public doExecute(): Observable<ExecutionStepStatus> {
    const payload = this.getPayload(),
      component = payload.getValue().component ? payload.getValue().component : payload.getValue();
    if (component) {
      return this.doSet(component);
    }

    return observableOf({status: false, content: 'Not configurated correctly!' });
  }

  private doSet(component: GenericGridComponent): Observable<ExecutionStepStatus> {
    this.getToast().custom(ToastComponentsRegistry.PROGRESS_BAR, {
      severity: 'info',
      life: TOAST_LIFE_INFINITE,
      closable: false,
      summary: this.getTranslate().instant('COMMON.SAVING_PLEASE_WAIT')
    });
    console.log(component.selectedMasterEntity.id);
    return this.genericCrudService.customPost(
      `app/duplicateentityconditions/${component.selectedMasterEntity.id}/changeshoudlduplicate`, {}
    ).pipe(
    map((entity) => {

      component.setSelectedEntity(entity);
      component.onRefresh().subscribe();

      return {status: true, content: null };
    }));
  }

  private getToast(): ToastService|null {
    return this.injector.get(ToastService, null);
  }

  private getTranslate(): TranslateService|null {
    return this.injector.get(TranslateService, null);
  }
}
