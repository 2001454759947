import { ElementInputAutocompleteComponent } from './element-input-autocomplete.component';
import places from 'places.js';
import { environment } from '../../../../environments';
export class ElementInputAlgoliaPlacesComponent extends ElementInputAutocompleteComponent {
    constructor() {
        super(...arguments);
        this.selectedSuggestion = null;
        this.placesAutocomplete = null;
    }
    ngOnInit() {
        super.ngOnInit();
        this.placesAutocomplete = places({
            appId: environment.algoliaAppId,
            apiKey: environment.algoliaApiKey,
            container: this.elementRef.nativeElement.querySelector('#address-input')
        });
        this.placesAutocomplete.on('change', e => {
            this.selectedSuggestion = e.suggestion;
            this.setValue({
                value: e.suggestion.name,
                label: e.suggestion[this.element.datamodelField]
            });
        });
        this.placesAutocomplete.on('clear', () => {
            this.selectedSuggestion = null;
            this.setValue(null);
        });
    }
    setupValue(ignoreResetCollection = false) {
        const entity = this.element.getEntity(), label = this.getEntityLabel(entity), value = this.getEntityValue();
        if (value && !ignoreResetCollection) {
            let selectedOptionEntity = null;
            if (this.element.getEntity() && this.element.getEntity()._embedded &&
                this.element.getEntity()._embedded[this.element.datamodelField]) {
                selectedOptionEntity = this.element.getEntity()._embedded[this.element.datamodelField];
            }
            const selectedOption = {
                label: label,
                value: value,
                entity: selectedOptionEntity
            };
            this.suggestions = [...this.suggestions, selectedOption];
            this.setValue(selectedOption, false).getSelectedOption(selectedOption);
            if (event && event.type !== 'load') {
                this.setValue(selectedOption, false);
            }
        }
        else if (!value && this.element.defaultValue) {
            this.setDefaultValue();
        }
        else if (!value && !this.element.defaultValue && this.element.selectIfSingleValue) {
            this.setFirstValue();
        }
        else if (value === null || value === '' || typeof value === 'undefined') {
            this.setValue(null, false);
        }
    }
    setDefaultValue() {
        console.log('Not yet implemented...');
    }
    setFirstValue() {
        console.log('Not yet implemented...');
    }
    setValue(selectedOption, triggerChange = true, entityValueEmpty = false) {
        const entityValue = this.getEntityValueFromChange(selectedOption, entityValueEmpty);
        this.setAutocompleteValue(entityValue);
        super.setValue(selectedOption, triggerChange, entityValueEmpty);
        return this;
    }
    setAutocompleteValue(value) {
        if (this.placesAutocomplete) {
            this.placesAutocomplete.setVal(value !== 'null' ? value : '');
            this.placesAutocomplete.close();
        }
    }
}
