/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../directives/selenium.directive";
import * as i2 from "./element-input-link.component";
import * as i3 from "../form.service";
import * as i4 from "../form-viewer.service";
import * as i5 from "../actions/services/form-actions.service";
import * as i6 from "@ngx-translate/core";
import * as i7 from "../../services/datamodel/datamodel.crud.service";
var styles_ElementInputLinkComponent = ["[_nghost-%COMP%] {\n      height: 100%;\n    }"];
var RenderType_ElementInputLinkComponent = i0.ɵcrt({ encapsulation: 0, styles: styles_ElementInputLinkComponent, data: {} });
export { RenderType_ElementInputLinkComponent as RenderType_ElementInputLinkComponent };
export function View_ElementInputLinkComponent_0(_l) { return i0.ɵvid(2, [i0.ɵqud(671088640, 1, { inputElement: 0 }), (_l()(), i0.ɵeld(1, 0, null, null, 2, "a", [["appSeleniumDirective", ""], ["target", "_blank"]], [[8, "href", 4]], null, null, null, null)), i0.ɵdid(2, 4210688, null, 0, i1.SeleniumDirective, [i0.ElementRef, i0.Renderer2], { element: [0, "element"] }, null), (_l()(), i0.ɵted(3, null, ["", " tabindex=\"", "\" "]))], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.element; _ck(_v, 2, 0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵinlineInterpolate(1, "", _co.element.url, ""); _ck(_v, 1, 0, currVal_0); var currVal_2 = _co.element.urlText; var currVal_3 = _co.element.tabIndex; _ck(_v, 3, 0, currVal_2, currVal_3); }); }
export function View_ElementInputLinkComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "app-form-element-input-link", [], null, null, null, View_ElementInputLinkComponent_0, RenderType_ElementInputLinkComponent)), i0.ɵdid(1, 6012928, null, 1, i2.ElementInputLinkComponent, [i3.FormService, i0.ChangeDetectorRef, i4.FormViewerService, i5.FormActionsService, i6.TranslateService, i7.DatamodelCrudService], null, null), i0.ɵqud(603979776, 1, { insideNgContent: 0 })], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ElementInputLinkComponentNgFactory = i0.ɵccf("app-form-element-input-link", i2.ElementInputLinkComponent, View_ElementInputLinkComponent_Host_0, { form: "form", formGroup: "formGroup", entity: "entity", element: "element", moduleElement: "moduleElement", selectedElement: "selectedElement", showDialogAddAddress: "showDialogAddAddress", actionParams: "actionParams", editMode: "editMode" }, { selectedElementChange: "selectedElementChange", showDialogAddAddressChange: "showDialogAddAddressChange", actionParamsChange: "actionParamsChange" }, []);
export { ElementInputLinkComponentNgFactory as ElementInputLinkComponentNgFactory };
