import {ChangeDetectionStrategy, Component} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {ToolbarItemAbstract} from '../toolbar-item-abstract.component';
import {GenericCrudService} from '../../../../../../services/generic-crud.service';
import {MessageGrowlService} from '../../../../../../../core/message/message-growl.service';
import {ConfirmationService} from 'primeng/api';
import {RequestCachingService} from '../../../../../../services/request-caching.service';

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'app-toolbar-item-add-pre-calculation-article',
    template: ''
})
export class ToolbarItemReleasePreCalculationComponent extends ToolbarItemAbstract {

  constructor(
    protected genericCrudService: GenericCrudService,
    protected messageGrowlService: MessageGrowlService,
    protected translate: TranslateService,
    protected confirmationService: ConfirmationService,
    protected requestCachingService: RequestCachingService
  ) {
    super();
  }

  public click() {
    const component = this.getComponent();

    if (component.getSelectedEntity()) {
      this.requestCachingService.removeByExpression('precalculations');
      this.genericCrudService.get('phoenix/precalculations/' + component.getSelectedEntity().id + '/valid')
        .subscribe((result) => {
          if (!result.result) {
            this.confirmationService.confirm({
              acceptVisible: true,
              header: this.translate.instant('DIALOG_MESSAGES.MINIMAL_FACTOR_UNDERMINED_HEADER'),
              message: this.translate.instant('DIALOG_MESSAGES.MINIMAL_FACTOR_UNDERMINED_BODY'),
              icon: 'fa fa-update',
              accept: () => {
                this.updatePreCalculation(this.translate.instant('DIALOG_MESSAGES.PRECALCULATION_RELEASE_ASKED'));
              },
              reject: () => {
                console.log('rejected');
              }
            });
          } else {
            this.updatePreCalculation(this.translate.instant('DIALOG_MESSAGES.PRECALCULATION_RELEASE_DONE'));
          }
        });
    }
  }

  protected updatePreCalculation(message: string) {
    this.genericCrudService.get('phoenix/precalculations/' + this.getComponent().getSelectedEntity().id + '/release')
      .subscribe(result => {
        this.messageGrowlService.success(
          message,
          this.translate.instant('COMMON.SUCCESS')
        );
      });
  }
}
