
import {of as observableOf, Observable} from 'rxjs';
import {AbstractExecutionStep} from '../../executor/abstract-execution-step';
import {ExecutionStepStatus} from '../../executor/execution-step-status';
import {LocalStorageDataService} from '../../../shared/services/local-storage-data.service';
import {TranslateService} from '@ngx-translate/core';
import {HeaderComponent} from '../header.component';
import {LanguageService} from '../../../shared/services/language/language.service';

export class SetLocalStorageLanguageExecutionStep extends AbstractExecutionStep {

  public doExecute(): Observable<ExecutionStepStatus> {
    const countryCode = this.getPayload().getValue().countryCode,
      headerComponent: HeaderComponent = this.getPayload().getValue().component;

    if (!countryCode) {
      console.error('CountryCode not found!');
    }

    const languageCode = headerComponent.languageCodes[headerComponent.countryCodes[countryCode]];
    this.getLanguageService().selectLanguage(languageCode).setLocalStorageLanguage(languageCode);

    headerComponent.userCountryCode = countryCode;

    this.getTranslateService().use(languageCode);

    return observableOf({status: true, content: null});
  }

  private getLocalStorage(): LocalStorageDataService {
    return this.injector.get(LocalStorageDataService, null);
  }

  private getTranslateService(): TranslateService {
    return this.injector.get(TranslateService, null);
  }

  private getLanguageService(): LanguageService {
    return this.injector.get(LanguageService, null);
  }
}
