import { AbstractExecutionStep } from '../../../core/executor/abstract-execution-step';
import { Observable } from 'rxjs/Observable';
import { ExecutionStepStatus } from '../../../core/executor/execution-step-status';
import { GenericElementAbstract } from '../../content-renderer/elements/generic-element-abstract.component';
import {AbstractGenericGridComponent} from '../../content-renderer/elements/abstract-generic-grid.component';
import {FormViewerComponent} from '../../form-viewer/form-viewer.component';

export class StateMasterComponentRefreshExecutionStep extends AbstractExecutionStep {

    public doExecute(): Observable<ExecutionStepStatus> {
        const payload = this.getPayload();

        if (!(payload.getValue() instanceof GenericElementAbstract)) {
            return this.getFailObservable('You need to pass GenericElementAbstract as Payload value!');
        }

        return this.doRefresh(payload.getValue());
    }

    private doRefresh(component: GenericElementAbstract): Observable<ExecutionStepStatus> {

      const masterComponent = component.masterElementContext.masterElementContext ? component.masterElementContext.masterElementContext.component : component.masterElementContext.component;
      if (masterComponent instanceof AbstractGenericGridComponent) {
        return new Observable<ExecutionStepStatus>(observer => {
          masterComponent.onRefreshEntities(masterComponent.entities)
            .subscribe(() => {
              this.entityDraftStoreService.clear();
              observer.next({
                status: true,
                content: null
              });
              observer.complete();
            });
        });
      } else if (masterComponent instanceof FormViewerComponent) {
        return new Observable<ExecutionStepStatus>(observer => {
          masterComponent.onRefresh()
            .subscribe(() => {
              observer.next({
                status: true,
                content: null
              });
              observer.complete();
            });
        });
      }
    }
}
