<a (click)="onItemClick(item)" *ngIf="!item.isSeparator && item.isCustomMenuItem" [pTooltip]="item.tooltip" [tooltipPosition]="menuPosition == 'right' ? 'left' : 'right'">
  <div class="item-container item-text-container">
    {{item.label}}
  </div>
</a>
<p-dialog *ngIf="isDialogVisible" (onAfterHide)="dialogHide()" appendTo="body"
          [(visible)]="isDialogVisible" showEffect="fade" header="ZA Import"
          [modal]="true" [width]="400" [height]="250">
  <div class="ui-grid ui-grid-responsive ui-fluid">
    <div class="ui-grid-row">
      <div class="ui-grid-col-4">
        <label for="periodId">
          Datum:
        </label>
      </div>
      <div class="ui-grid-col-8">
        <p-calendar
          [(ngModel)]="selectedDate"
          id="periodId" showButtonBar="true"
          [monthNavigator]="true" [yearNavigator]="true"
          yearRange="2000:2030"
          [style]="{'width':'100%'}"
          dateFormat="dd.mm.yy">
        </p-calendar>
      </div>
    </div>
    <div class="ui-grid-row">
      <div class="ui-grid-col-12">
        <p-fileUpload
          name="file[]"
          multiple="multiple"
          withCredentials="true"
          customUpload="true"
          (uploadHandler)="onUpload($event)"
          accept=".csv"
          maxFileSize="100000000"
          chooseLabel="{{ 'COMMON.CHOOSE' | translate }}"
          cancelLabel="{{ 'COMMON.CANCEL' | translate }}"
          uploadLabel="{{ 'COMMON.UPLOAD' | translate }}"
          [disabled]="!selectedDate"
        >
        </p-fileUpload></div>
    </div>
  </div>
  <div class="ui-grid-row">
    <p-progressBar *ngIf="isDataLoading" mode="indeterminate" [style]="{'height': '6px', 'margin-top': '5px'}"></p-progressBar>
  </div>
</p-dialog>
