
import { Injectable } from '@angular/core';
import { AbstractFormActionHandler } from '../abstract-form-action-handler';
import { FormService } from '../../../form.service';
import { HandleCountryPhoneActionHandler } from './handle-country-phone-action-handler';

@Injectable()
export class HandleCountryMobileActionHandler extends HandleCountryPhoneActionHandler {

    protected getPhoneCountryCodeDatamodelField(): string {
        return 'mobileCountryCode';
    }

    protected getPhoneAreaCodeDatamodelField(): string {
        return 'mobileAreaCode';
    }

    protected getPhoneNumberDatamodelField(): string {
        return 'mobileNumber';
    }
}
