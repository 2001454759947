
import {throwError as observableThrowError,  Observable } from 'rxjs';

import {catchError} from 'rxjs/operators';
import {Component, OnInit, OnDestroy, Input, ChangeDetectorRef} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { HttpErrorResponse } from '@angular/common/http';
import {MessageGrowlService} from '../../../core/message/message-growl.service';
import {GenericCrudService} from '../../services/generic-crud.service';
import {ApiBuilderService} from '../../services/api.builder.service';
import {RequestCachingService} from '../../services/request-caching.service';
import {AuthenticationService} from '../../../core/authentication/authentication.service';
import {ChangeDetectorRefHelper} from '../../helpers/change-detector-ref.helper';

@Component({
  selector: 'user-grid-component',
  templateUrl: './user-grid.component.html',
  styles: [
    `
      ::ng-deep .ui-fluid .ui-dropdown {
        width: 20px;
      }
    `
  ]
})
export class UserGridComponent implements OnInit, OnDestroy {

  public totalRecords = 0;

  public userSearch = '';

  public users: any[] = [];
  public selectedUsers: any[] = [];

  public isDataLoading = false;
  public loadingMessage = '';

  constructor(
    protected translate: TranslateService,
    protected messageGrowlService: MessageGrowlService,
    protected genericCrudService: GenericCrudService,
    protected apiBuilderService: ApiBuilderService,
    protected requestCachingService: RequestCachingService,
    protected authenticationService: AuthenticationService,
    public cdr: ChangeDetectorRef
  ) {

  }

  ngOnInit() {
  }

  ngOnDestroy() {
    // prevent memory leak when component destroyed
  }

  private getSearchUrl(): string {
    let searchUrl = 'app/users';

    if (this.userSearch) {
      searchUrl += '?';
    }

    return searchUrl;
  }

  public onSearch() {
    this.isDataLoading = true;
    this.loadingMessage = this.translate.instant('COMMON.SEARCHING_DATA');

    // First empty the previous searches
    this.requestCachingService.removeByExpression('app/users');

    this.loadSearchResults({
      first: 0,
      rows: 10
    });
  }

  public onImport() {
    this.genericCrudService.customPost('app/users/import/phoenix_user', {
      ids: this.selectedUsers.map(user => user.id)
    }).subscribe();
  }

  public loadSearchResults(event): void {
    this.loadingMessage = this.translate.instant('COMMON.FETCHING_DATA');

    const order = (event.sortOrder === -1) ? 'desc' : 'asc';
    const field = (event.sortField === undefined) ? 'firstName' : event.sortField;

    const searchParams = {
        apiRoute: 'app/users',
        offset: event.first,
        limit: event.rows,
        orderBy: field,
        orderDirection: order
      };

    const apiUrl = this.apiBuilderService.getPaginateApiRoute(searchParams);

    const apiParams = this.userSearch ? {
      search: this.userSearch,
      clause: 'orWhere',
      embedded: 'none'
    } : {};

    this.genericCrudService.getPaginated(apiUrl, apiParams).subscribe((data) => {
      this.users = data['data'];
      this.totalRecords = data['total'];

      this.isDataLoading = false;

      if (this.users.length === 0) {
        this.messageGrowlService.info('Info', 'Keine Benutzer gefunden.');
      }

      ChangeDetectorRefHelper.detectChanges(this);
    });
  }
}
