import {of as observableOf, Observable} from 'rxjs';
import {AbstractExecutionStep} from '../../../../core/executor/abstract-execution-step';
import {ExecutionStepStatus} from '../../../../core/executor/execution-step-status';
import {AbstractGenericGridComponent} from '../../../content-renderer/elements/abstract-generic-grid.component';
import {MessageGrowlService} from '../../../../core/message/message-growl.service';
import {TOAST_LIFE_INFINITE, ToastComponentsRegistry, ToastService} from '../../../../core/service/toast.service';
import {TranslateService} from '@ngx-translate/core';
import {FormViewerComponent} from '../../../form-viewer/form-viewer.component';
import {GenericDialogModuleService} from '../../../content-renderer/elements/generic-dialog/service/generic-dialog-module.service';
import {DoubleClickService} from '../../../content-renderer/services/double-click.service';

export class CreateMemoForEmployeeInvitationExecutionStep extends AbstractExecutionStep {

  public doExecute(): Observable<ExecutionStepStatus> {
    const component = this.getPayload().getValue() || this.getPayload().getValue().component;

    if (!(component instanceof AbstractGenericGridComponent)) {
      return this.getFailObservable('You need to pass AbstractGenericGridComponent as Payload value!');
    }

    return this.doSet(component);
  }

  protected doSet(component: AbstractGenericGridComponent): Observable<ExecutionStepStatus> {
    const selectedEntity = {
      entityId: component.getSelectedEntity().leasedEmployee.id,
      entityFqn: component.getSelectedEntity().leasedEmployee.fqn
    }
    if (selectedEntity) {
      this.getToast().custom(ToastComponentsRegistry.PROGRESS_BAR, {
        severity: 'info',
        life: TOAST_LIFE_INFINITE,
        closable: false,
        summary: this.getTranslate().instant('COMMON.LOADING_PLEASE_WAIT')
      });
      this.genericCrudService.customPost('phoenix/memos/create-from-leased-employee', selectedEntity).subscribe((memo: Record<string, any>) => {
        this.getToast().clear(ToastComponentsRegistry.PROGRESS_BAR, 300);
        this.showSuccess();

        this.genericCrudService.getEntity('superadmin/modules', 325).subscribe((module) => {
          this.getDoubleClickService().openModuleInNewTabCustom(module, 'memos', 'PhoenixBundle\\Entity\\Memo', component.moduleElement, memo.id);
        });
      });
    }
    return observableOf({status: true, content: null});
  }

  protected showSuccess(): void {
    this.getGrowl().success(
      this.getTranslate().instant('COMMON.MEMO_IS_CREATED'),
      this.getTranslate().instant('COMMON.SUCCESS')
    );
  }

  private getGrowl(): MessageGrowlService|null {
    return this.injector.get(MessageGrowlService, null);
  }

  private getToast(): ToastService|null {
    return this.injector.get(ToastService, null);
  }

  private getTranslate(): TranslateService|null {
    return this.injector.get(TranslateService, null);
  }

  private getDoubleClickService(): DoubleClickService|null {
    return this.injector.get(DoubleClickService, null);
  }
}
