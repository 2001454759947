/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./generic-menu.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./menu-item/generic-menu-item.component.ngfactory";
import * as i3 from "./menu-item/generic-menu-item.component";
import * as i4 from "@angular/router";
import * as i5 from "../../../shared/services/generic-crud.service";
import * as i6 from "../../../shared/content-renderer/elements/generic-dialog/service/generic-dialog-module.service";
import * as i7 from "../../../shared/content-renderer/services/modules-state.service";
import * as i8 from "../../../shared/content-renderer/services/navigation/module-close.service";
import * as i9 from "@angular/common";
import * as i10 from "./generic-menu.component";
var styles_GenericMenuComponent = [i0.styles];
var RenderType_GenericMenuComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_GenericMenuComponent, data: {} });
export { RenderType_GenericMenuComponent as RenderType_GenericMenuComponent };
function View_GenericMenuComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "ul", [["app-generic-menu-item", ""]], [[8, "className", 0]], null, null, i2.View_GenericMenuItemComponent_0, i2.RenderType_GenericMenuItemComponent)), i1.ɵdid(1, 245760, null, 0, i3.GenericMenuItemComponent, [i4.Router, i1.ElementRef, i5.GenericCrudService, i6.GenericDialogModuleService, i7.ModulesStateService, i8.ModuleCloseService], { items: [0, "items"], isFirstLevel: [1, "isFirstLevel"], iconsPath: [2, "iconsPath"], menuPosition: [3, "menuPosition"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.items; var currVal_2 = true; var currVal_3 = _co.iconsPath; var currVal_4 = _co.menuPosition; _ck(_v, 1, 0, currVal_1, currVal_2, currVal_3, currVal_4); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵinlineInterpolate(1, "", _co.menuPosition, ""); _ck(_v, 0, 0, currVal_0); }); }
export function View_GenericMenuComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "nav", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_GenericMenuComponent_1)), i1.ɵdid(2, 16384, null, 0, i9.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (((_co.items == null) ? null : _co.items.length) > 0); _ck(_v, 2, 0, currVal_0); }, null); }
export function View_GenericMenuComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-generic-menu", [], null, null, null, View_GenericMenuComponent_0, RenderType_GenericMenuComponent)), i1.ɵdid(1, 114688, null, 0, i10.GenericMenuComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var GenericMenuComponentNgFactory = i1.ɵccf("app-generic-menu", i10.GenericMenuComponent, View_GenericMenuComponent_Host_0, { items: "items", iconsPath: "iconsPath", menuPosition: "menuPosition" }, {}, []);
export { GenericMenuComponentNgFactory as GenericMenuComponentNgFactory };
