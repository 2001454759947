
import {publishReplay, catchError, refCount} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AppHttpService } from '../../../app-http.service';

import { Datamodel } from './datamodel';
import { DataModelField } from './data-model-field';
import { GenericCrudService } from '../generic-crud.service';

@Injectable()
export class DataModelFieldCrudService extends AppHttpService {

  private dataModelFieldsUrl = 'superadmin/datamodels/:dataModelId/fields';

  constructor(private genericCrudService: GenericCrudService) {
    super();
  }

  /**
   * @argument {string} format Optional. If not set, then the entities are returned as is.
   *                            Selectable formats: formenu (MenuItem), fortree (TreeNode).
   */
  getDatamodelFields(dataModel: Datamodel, format?: string): Observable<any[]> {
    if (!dataModel || !dataModel.id) {
      throw new Error('DataModel object or DataModel id not given.');
    }

    const url = this.createUrl(dataModel) + (format ? `/${format}` : '');

    return this.genericCrudService.get(url).pipe(
      publishReplay(1),
      refCount(),
      catchError(this.handleError));
  }

  /**
   *
   * @param dataModel
   * @returns {Observable<R>}
   */
  getDataModelFilters(dataModel: Datamodel): Observable<DataModelField[]> {
    if (!dataModel || !dataModel.id) {
      throw new Error('DataModel object or DataModel id not given.');
    }

    const url = this.createUrl(dataModel);

    return this.genericCrudService.get(url).pipe(
      publishReplay(1),
      refCount(),
      catchError(this.handleError));
  }

  getAssociatedFields(dataModel: Datamodel): Observable<any> {
    if (!dataModel || !dataModel.id) {
      throw new Error('DataModel object or DataModel id not given.');
    }

    const url = this.createUrl(dataModel) + '/associations';

    return this.genericCrudService.get(url).pipe(
      publishReplay(1),
      refCount(),
      catchError(this.handleError));
  }

  private createUrl(dataModel: Datamodel) {
    const re = /:dataModelId/gi;

    return this.dataModelFieldsUrl.replace(re, dataModel.id.toString());
  }
}
