
import {of as observableOf, Observable} from 'rxjs';
import {AbstractExecutionStep} from '../../../../core/executor/abstract-execution-step';
import {ExecutionStepStatus} from '../../../../core/executor/execution-step-status';
import {AbstractGenericGridComponent} from '../../../content-renderer/elements/abstract-generic-grid.component';
import {ElementContext,ElementType} from '../../../content-renderer/services/ElementContext';
import {ModuleState} from '../../../content-renderer/services/module-state';

export class DetailsViewMasterEntitySetupExecutionStep extends AbstractExecutionStep {

  public doExecute(): Observable<ExecutionStepStatus> {
    const payload = this.getPayload();

    const moduleState = payload.getValue();

    if (!(moduleState instanceof ModuleState)) {
      return observableOf({status: false, content: 'Payload should be modulestate, not something else.'});
    }

    if (!moduleState.entity) {
      return observableOf({status: false, content: 'Module state entity is not set.'});
    }

    return this.prepareEntity(moduleState, moduleState.entity);
  }

  protected prepareEntity(moduleState: ModuleState, entity): any {
    const masterModuleComponents = this.moduleStateInspectorService.getModuleComponents(moduleState, true, true, true);
    for (const masterModuleComponent of masterModuleComponents) {
      if (masterModuleComponent instanceof AbstractGenericGridComponent) {
        this.parseComponent(masterModuleComponent, entity);
      }
    }

    const masterNoSlavesModuleComponents = this.moduleStateInspectorService.getModuleComponents(moduleState, true, true, false);
    for (const masterNoSlavesModuleComponent of masterNoSlavesModuleComponents) {
      if (masterNoSlavesModuleComponent instanceof AbstractGenericGridComponent) {
        this.parseComponent(masterNoSlavesModuleComponent, entity);
      }
    }

    for (const partModuleState of moduleState.getParts()) {
      this.prepareEntity(partModuleState, entity);
    }

    return observableOf({status: true, content: null});
  }

  protected parseComponent(component: AbstractGenericGridComponent, entity) {
    const changedEntities = [],
      collectionName = this.getCollectionPropertyName(component),
      context: ElementContext = component.getElementContext();

    if (collectionName && (context.type === ElementType.Grid || context.type === ElementType.Tree)) {
      for (const createdEntity of component.getCreatedEntities(true)) {
        this.pushToMasterEntityCollection(entity, collectionName, createdEntity);
      }

      for (const updatedEntity of component.getUpdatedEntities(true)) {
        this.pushToMasterEntityCollection(entity, collectionName, updatedEntity);
      }

      for (const embeddedUpdatedEntity of component.getEmbeddedEntitiesChanged()) {
        this.pushToMasterEntityCollection(entity, collectionName, embeddedUpdatedEntity);
      }
    }
  }
}
