import {FormViewerComponent} from '../../../../../../../form-viewer/form-viewer.component';
import {Element, ElementInput, ElementLayoutFieldset} from '../../../../../../../form-viewer/models';
import {Entity} from '../../../../../../../helpers/entity';
import {isEmpty} from 'lodash';
import {FormService} from '../../../../../../../form-viewer/form.service';

const USER_FIELD = 'user';
const BRANCH_OFFICE_FIELD = 'branchOffice';

export class ContractOfEmploymentContactInformationService {

  private form: FormViewerComponent = null;
  private formService: FormService = null;

  public setForm(form: FormViewerComponent): this {
    this.form = form;
    return this;
  }

  public setFormService(formService: FormService): this {
    this.formService = formService;
    return this;
  }

  public showOrHideContactInformation(): void {
    const entity = this.form.getSelectedEntity();

    if (entity && entity.id) {
      const user = Entity.getValue(entity, USER_FIELD) ||
          Entity.getValueInEmbedded(entity, USER_FIELD),
        branchOffice = Entity.getValue(entity, BRANCH_OFFICE_FIELD) ||
          Entity.getValueInEmbedded(entity, BRANCH_OFFICE_FIELD);

      const show = !isEmpty(user) || !isEmpty(branchOffice);

      this.showOrHideContactInformationFieldsetElement(show);
    } else if (entity && !entity.id) {
      this.showOrHideContactInformationFieldsetElement(false);
    }
  }

  public showOrHideContactInformationFieldsetElement(doShow: boolean): void {
    const element = this.getContactInformationFieldsetElement();

    if (element instanceof ElementLayoutFieldset) {
      element.isHidden = !doShow;
    }

    this.markElementForCheck(element);
  }

  public getContactInformationFieldsetElement(): Element|null {
    const exitReasonElement = this.getUserElement();

    if (typeof exitReasonElement !== 'undefined' && exitReasonElement !== null) {
      return exitReasonElement.parent;
    }

    return null;
  }

  public getUserElement(): ElementInput|null {
    return this.form.inputElements.find((aElement: ElementInput) => {
      return aElement.datamodelField === USER_FIELD;
    });
  }

  public markElementForCheck(element: Element): void {
    if (element) {
      const elementComponent = this.formService.getComponentByElement(element);

      if (elementComponent) {
        elementComponent.getChangeDetectorRef().markForCheck();
      }
    }
  }
}
