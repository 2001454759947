import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import { ElementContext } from './ElementContext';

@Injectable()
export class ElementContextInspectorService {
 
    public isContextMasterAndSlavesAreDirty(context: ElementContext): boolean {
        const isMaster = context.isMaster;

        let isContextMasterAndSlavesAreDirty = false;

        if (isMaster) {
            const slaves = context.getSlaveElementContexts();

            for (const slave of slaves) {
                if (slave.component.hasChanges(false)) {
                    isContextMasterAndSlavesAreDirty = true;
                    break;
                }
            }
        }

        return isContextMasterAndSlavesAreDirty;
    }

}
