import { ElementInput } from './element';

export class ElementInputLink extends ElementInput {

  public url = '';
  public urlText = '';

  constructor(
    public typeElement = 'link'
  ) {
    super();
  }
}
