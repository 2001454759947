import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

import { Subject } from 'rxjs';

if (environment.production) {
  enableProdMode();
}

// @todo Move it out from here
Array.prototype.insert = function(i, ...rest) {
  this.splice(i, 0, ...rest);
  return this;
};

Array.prototype.sortText = function(key, sequence, caseSensitive) {
  if (
    !key &&
    (typeof (sequence) !== 'undefined' || typeof (sequence) !== 'string') &&
    (typeof (caseSensitive) !== 'undefined' || typeof (caseSensitive) !== 'boolean')
  ) {
    return this;
  }

  if (typeof (sequence) === 'string') {
    sequence = sequence.toLowerCase();
  }

  sequence = sequence !== 'asc' && sequence !== 'desc' ? 'asc' : sequence.toLowerCase();
  caseSensitive = typeof (caseSensitive) !== 'boolean' ? false : caseSensitive;

  this.sort(function(a, b) {
    if (!a[key] || !b[key] || typeof (a[key]) !== 'string' || typeof (b[key]) !== 'string') {
      return 0;
    }

    const nameA = (caseSensitive ? a[key] : a[key].toLowerCase());
    const nameB = (caseSensitive ? b[key] : b[key].toLowerCase());

    if (nameA < nameB) {
      return (sequence === 'asc' ? -1 : 1);
    }
    if (nameA > nameB) {
      return (sequence === 'asc' ? 1 : -1);
    }
    return 0;
  });
  return this;
};

File.prototype.toDataURL = function() {
  const obsDataURL = new Subject<string>();
  const reader: FileReader = new FileReader();

  reader.onload = function(e) {
    const result: any = reader.result;
    obsDataURL.next(result);
  };
  reader.readAsDataURL(this);

  return obsDataURL;
};

const appObj = {

  appRef: undefined,

  start() {
      platformBrowserDynamic().bootstrapModule(AppModule)
        .then((appRef) => {
          this.appRef = appRef;
        })
      .catch(err => console.error(err));
  },

  restart() {
    if (this.appRef) {
      // removed for now, as the page doesn't get loaded for some reason..
      // if it gives the trouble location.reload() will be used here :)
      // this.appRef.destroy();
    }

    this.start();
  }

};

export const app = appObj;

appObj.start();


