import { ModuleElement } from "../../services/module/module-element";
import { ElementType } from "./ElementContext";
import {EntityStatus} from '../../services/entity/entity-status';
import {StaticGridFilterField} from '../elements/abstract-generic-grid.component';

export class ElementState {

    /**
     * the module element id which is a unique id of the element.
     */
    id: number;

    /**
     * the element type - either form or grid.
     */
    elementType: ElementType;

    /**
     * if the element is currently somehow in a readonly state
     */
    readonly: boolean;

    /**
     * the current page in the pagination
     */
    currentPage?: number;

    /**
     * the grid page size
     */
    pageSize?: number;

    /**
     * the current filters used in the grid - if present
     */
    filters?: any = {};

    /**
     * grid data was loaded
     */
    dataLoaded?: boolean;

    /**
     * the selected entity - either in the grid or in the form
     */
    selectedEntity?: any;

    /**
     * the module element attached to the element
     */
    moduleElement?: ModuleElement;

  /**
   * all the newly created elements for the component (grid).
   */
  createdElements?: any[];

  /**
   * all the updated entities (grid).
   */
  updatedElements?: any[];

  simpleSearchFilters?: StaticGridFilterField[] = [];

  sortField?: string;
  sortDirection?: any;

  /**
   *
   * @param {number} id
   * @param {ElementType} elementType
   * @param {ModuleElement} moduleElement
   * @param {boolean} readonly
   * @param {number} currentPage
   * @param {number} pageSize
   * @param {any} filters
   * @param selectedEntity
   * @param dataLoaded
   */
    constructor(id: number,
                elementType: ElementType,
                moduleElement: ModuleElement,
                readonly?: boolean,
                currentPage?: number,
                pageSize?: number,
                filters?: any,
                selectedEntity?: any,
                dataLoaded?: boolean,
                simpleSearchFilters?: StaticGridFilterField[],
                sortField?: string,
                sortDirection?: any) {
        this.id = id;
        this.elementType = elementType;
        this.moduleElement = moduleElement;

        this.readonly = readonly;
        this.currentPage = currentPage;
        this.pageSize = pageSize;
        this.filters = filters || {};
        this.selectedEntity = selectedEntity;
        this.createdElements = [];
        this.updatedElements = [];
        this.dataLoaded = dataLoaded;
        this.simpleSearchFilters = simpleSearchFilters || [];
        this.sortField = sortField;
        this.sortDirection = sortDirection;
    }

    /**
     *
     * @param selectedEntity
     * @returns {ElementState}
     */
    public setSelectedEntity(selectedEntity: any): ElementState {
        this.selectedEntity = selectedEntity;

        return this;
    }

    public addCreatedElement(createdElement: any): ElementState {
      this.createdElements.push(createdElement);

      return this;
    }

    public removeCreatedElement(createdElement: any): ElementState {
      let index = this.createdElements.findIndex(element => {
        return element[EntityStatus.ENTITY_DRAFT_FLAG] == createdElement[EntityStatus.ENTITY_DRAFT_FLAG];
      });
      if (index != -1) {
        this.createdElements.splice(index, 1);
      }
      return this;
    }

    public getCreatedElements(): any[] {
      return this.createdElements;
    }

    public clearCreatedElements(): ElementState {
      this.createdElements = [];

      return this;
    }

    public addUpdatedElement(updatedElement: any): ElementState {
      this.updatedElements.push(updatedElement);

      return this;
    }

    public removeUpdatedElement(updatedElement: any): ElementState {
      let index = this.updatedElements.findIndex(element => {
        return element[EntityStatus.ENTITY_DRAFT_FLAG] == updatedElement[EntityStatus.ENTITY_DRAFT_FLAG];
      });
      if (index != -1) {
        this.updatedElements.splice(index, 1);
      }
      return this;
    }

    public getUpdatedElements(): any[] {
      return this.updatedElements;
    }

    public clearUpdatedElements(): ElementState {
      this.updatedElements = [];

      return this;
    }
}
