import { AbstractFormActionHandler } from './abstract-form-action-handler';
import { Injectable } from '@angular/core';

@Injectable()
export class RemoveFieldValue extends AbstractFormActionHandler {

    public handleAction(): void {
        let form = this.getForm();

        if (this.isActionValid()) {
            let formElementHashId = this.getParamValue('formElement'),
                fieldElement = this.formService.getElementBy(form, 'objectHashId', formElementHashId);

            let formControlField = this.getFormGroup().get(fieldElement.datamodelField + '_h_r_f_e_' + formElementHashId);

            if (fieldElement && formControlField) {
                fieldElement.getEntity()[fieldElement.datamodelField] = null;
                fieldElement.setValue(null);
                formControlField.setValue(null);
            }
        }
    }

    private isActionValid(): boolean {
        return this.getAction().params['formElement'];
    }
}
