import {ChangeDetectionStrategy, Component, ViewChild} from '@angular/core';
import { ToolbarItemAbstract } from '../toolbar-item-abstract.component';
import { GenericCrudService } from '../../../../../../services/generic-crud.service';
import { MessageGrowlService } from '../../../../../../../core/message/message-growl.service';
import { TranslateService } from '@ngx-translate/core';
import { CompanyCommunicationService } from '../../../../../../company-search/services/company-communication.service';
import { Subscription } from 'rxjs';
import { AbstractGenericGridComponent } from 'app/shared/content-renderer/elements/abstract-generic-grid.component';
import { Element } from '../../../../../../services/element/element';
import {Dialog} from 'primeng/dialog';
import {CompanySearchGrid} from '../../../../../../company-search/company-search-grid/company-search-grid.component';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'toolbar-item-company-search',
  templateUrl: './toolbar-item-company-search.component.html',
  styles: [
    `
    ::ng-deep .dialog-close-button-container {
      float: right;
      cursor: pointer;
      margin-top: -28px;
    }

    ::ng-deep .ui-dialog-titlebar-maximize {
      margin-right: 13px;
    }
    `
  ]
})
export class ToolbarItemCompanySearch extends ToolbarItemAbstract {

  public gridComponent: AbstractGenericGridComponent = null;

  public isDialogVisible = false;

  public customerImportEndpoint = CompanySearchGrid.API_ROUTE;

  @ViewChild('dialog', {static: false}) dialog;

  private companyAddedSubscription: Subscription;

  constructor(
    protected genericCrudService: GenericCrudService,
    protected messageGrowlService: MessageGrowlService,
    protected translate: TranslateService,
    protected companyCommunicationService: CompanyCommunicationService
  ) {
    super();
  }

  public click() {
    if (this.isInCurrentContext(ToolbarItemAbstract.CONTEXT_GRID)) {
      this.gridComponent = this.getParam('gridComponent');

      this.handleClickInGridContext();
    }
  }

  public onCloseDialog(event) {
    this.doClose();
  }

  public doClose() {
    this.isDialogVisible = false;
  }

  public onDialogMaximize(event) {
    this.dialog.toggleMaximize(event);

    setTimeout(() => {
      window.dispatchEvent(new Event('resize'));
    }, 500);
  }

  ngOnDestroy() {
    this.unsubscribeFromCompanyAdded();
  }

  ngOnInit() {
    super.ngOnInit();

    this.customerImportEndpoint = this.getToolbarItem().parameter || CompanySearchGrid.API_ROUTE;
  }

  protected unsubscribeFromCompanyAdded() {
    if (this.companyAddedSubscription) {
      this.companyAddedSubscription.unsubscribe();
    }

    return this;
  }

  protected subscribeToCompanyAdded() {
    this.companyAddedSubscription = this.companyCommunicationService.companyAdded$.subscribe((company) => {
      this.isDialogVisible = false;

      const gridComponent = this.getComponent();

      if (gridComponent instanceof AbstractGenericGridComponent) {
        gridComponent.sortDirection = Element.SORT_DIRECTION_DESC;
        gridComponent.sortField = 'id';
        gridComponent.currentPage = 1;

        gridComponent.loadEntities().subscribe(() => {
          gridComponent.selectedEntity = gridComponent.entities[0];
          gridComponent.reselectEntity();
        });
      }
    });
  }

  protected handleClickInGridContext() {
    this.unsubscribeFromCompanyAdded().subscribeToCompanyAdded();

    this.isDialogVisible = true;
  }
}
