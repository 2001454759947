
    <div fxLayout="row">
        <div
          appSeleniumDirective
          [element]="element"
          fxFlex="80" [style.font-weight]="getFontWeight()"
        >
            {{collectiveAgreementName}}
        </div>
    </div>
  