
    <div [formGroup]="formGroup">
      <p-inputSwitch
        appSeleniumDirective
        [element]="element"
        [disabled]="isReadOnly()"
        [formControlName]="formControlName"
        onLabel="element.onLabel"
        offLabel="element.offLabel"
        (onChange)="onValueChange($event)"
        [tabIndex]="element.tabIndex"
      ></p-inputSwitch>
    </div>
  