import {
  Component, EventEmitter, Input, Output
} from '@angular/core';
import {
  ExpertSearch,
  ExpertSearchField,
  ExpertSearchFieldsContainer
} from '../../../../../../../services/expert-search/expert-search';
import {EntityStatus} from '../../../../../../../services/entity/entity-status';

@Component({
  selector: 'app-search-management-inline-container-field',
  templateUrl: './search-management-inline-container-field.component.html',
  styles: [`
    .ui-button {
      margin: 2px;
      padding: 4px;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    
    .ui-button-text-only .ui-button-text {
      padding: 0;
    }
    
    .remove-field-button {
      margin-left: -4px;
    }
  `]
})
export class SearchManagementInlineContainerFieldComponent {
  @Input() public expertSearch: ExpertSearch = null;
  @Input() public container: ExpertSearchFieldsContainer = null;
  @Input() public field: ExpertSearchField = null;

  @Output() public onFieldRemove: EventEmitter<ExpertSearchField> = new EventEmitter();
  @Output() public onOpenEditField: EventEmitter<ExpertSearchField> = new EventEmitter();
  @Output() public onContainerRemove: EventEmitter<ExpertSearchFieldsContainer> = new EventEmitter();

  public onEditField(event: any): void {
    this.onOpenEditField.emit(this.field);
  }

  public onRemoveField(event: any): void {
    this.removeField();

    if (this.container.fields.length === 0 || this.areAllFieldsDeleted()) {
      this.removeContainer();
    }
  }

  private areAllFieldsDeleted(): boolean {
    let areAllDeleted = true;

    for (const field of this.container.fields) {
      if (!field[EntityStatus.ENTITY_DRAFT_BACKEND_DELETE_FLAG]) {
        areAllDeleted = false;
        break;
      }
    }

    return areAllDeleted;
  }

  private removeField(): void {
    const index = this.container.fields.indexOf(this.field);

    if (index !== -1) {
      const field = this.container.fields[index];

      field[EntityStatus.ENTITY_DRAFT_BACKEND_DELETE_FLAG] = true;

      this.onFieldRemove.emit(field);
    }
  }

  private removeContainer(): void {
    const containerIndex = this.expertSearch.containers.indexOf(this.container);

    if (containerIndex !== -1) {
      const container = this.expertSearch.containers[containerIndex];

      container[EntityStatus.ENTITY_DRAFT_BACKEND_DELETE_FLAG] = true;

      this.onContainerRemove.emit(container);
    }
  }
}
