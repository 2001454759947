/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./generic-context-menu.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../../node_modules/primeng/components/listbox/listbox.ngfactory";
import * as i3 from "primeng/components/listbox/listbox";
import * as i4 from "@angular/forms";
import * as i5 from "../../../../core/executor/executor.service";
import * as i6 from "../../../../core/executor/service/executor-actions/executor-actions.service";
import * as i7 from "./generic-context-menu.component";
import * as i8 from "../../../services/generic-crud.service";
import * as i9 from "../../services/generic/generic-element-context-menu.service";
var styles_GenericContextMenuComponent = [i0.styles];
var RenderType_GenericContextMenuComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_GenericContextMenuComponent, data: {} });
export { RenderType_GenericContextMenuComponent as RenderType_GenericContextMenuComponent };
export function View_GenericContextMenuComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "p-listbox", [["optionLabel", "name"]], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "ngModelChange"], [null, "onChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("ngModelChange" === en)) {
        var pd_0 = ((_co.selectedContextMenu = $event) !== false);
        ad = (pd_0 && ad);
    } if (("onChange" === en)) {
        var pd_1 = (_co.onContextMenuClick() !== false);
        ad = (pd_1 && ad);
    } return ad; }, i2.View_Listbox_0, i2.RenderType_Listbox)), i1.ɵdid(1, 1097728, null, 3, i3.Listbox, [i1.ElementRef, i1.ChangeDetectorRef], { optionLabel: [0, "optionLabel"], options: [1, "options"] }, { onChange: "onChange" }), i1.ɵqud(603979776, 1, { headerFacet: 0 }), i1.ɵqud(603979776, 2, { footerFacet: 0 }), i1.ɵqud(603979776, 3, { templates: 1 }), i1.ɵprd(1024, null, i4.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i3.Listbox]), i1.ɵdid(6, 671744, null, 0, i4.NgModel, [[8, null], [8, null], [8, null], [6, i4.NG_VALUE_ACCESSOR]], { model: [0, "model"] }, { update: "ngModelChange" }), i1.ɵprd(2048, null, i4.NgControl, null, [i4.NgModel]), i1.ɵdid(8, 16384, null, 0, i4.NgControlStatus, [[4, i4.NgControl]], null, null)], function (_ck, _v) { var _co = _v.component; var currVal_7 = "name"; var currVal_8 = _co.contextMenus; _ck(_v, 1, 0, currVal_7, currVal_8); var currVal_9 = _co.selectedContextMenu; _ck(_v, 6, 0, currVal_9); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 8).ngClassUntouched; var currVal_1 = i1.ɵnov(_v, 8).ngClassTouched; var currVal_2 = i1.ɵnov(_v, 8).ngClassPristine; var currVal_3 = i1.ɵnov(_v, 8).ngClassDirty; var currVal_4 = i1.ɵnov(_v, 8).ngClassValid; var currVal_5 = i1.ɵnov(_v, 8).ngClassInvalid; var currVal_6 = i1.ɵnov(_v, 8).ngClassPending; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); }); }
export function View_GenericContextMenuComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "app-generic-context-menu", [], null, null, null, View_GenericContextMenuComponent_0, RenderType_GenericContextMenuComponent)), i1.ɵprd(512, null, i5.ExecutorService, i5.ExecutorService, [i6.ExecutorActionsService]), i1.ɵdid(2, 114688, null, 0, i7.GenericContextMenuComponent, [i8.GenericCrudService, i9.GenericElementContextMenuService, i5.ExecutorService, i1.ChangeDetectorRef], null, null)], function (_ck, _v) { _ck(_v, 2, 0); }, null); }
var GenericContextMenuComponentNgFactory = i1.ɵccf("app-generic-context-menu", i7.GenericContextMenuComponent, View_GenericContextMenuComponent_Host_0, { component: "component", overlayPanel: "overlayPanel" }, {}, []);
export { GenericContextMenuComponentNgFactory as GenericContextMenuComponentNgFactory };
