import {Component, OnInit, ChangeDetectionStrategy, OnDestroy, ChangeDetectorRef} from '@angular/core';
import {TableHeader} from '../../../../../dynamic-table/shared/table-header';
import {TableColumn} from '../../../../../dynamic-table/shared/table-column';
import {CockpitUserBirthdayService} from '../services/cockpit-user-birthday.service';
import {Subject} from 'rxjs';
import {map, takeUntil} from 'rxjs/operators';

@Component({
  selector: 'app-custom-staff-cockpit-user-birthday',
  templateUrl: './staff-cockpit-user-birthday.component.html',
  styleUrls: ['./staff-cockpit-user-birthday.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    CockpitUserBirthdayService
  ]
})
export class StaffCockpitUserBirthdayComponent implements OnInit, OnDestroy {

  public isLoading = true;
  public isLeasedEmployeeLoading = true;
  public unsubscribe = new Subject<void>();
  public data: Record<string, any>[] = [];
  public leasedEmployeeData: Record<string, any>[] = [];
  public header: TableHeader = {
    title: 'Interne Mitarbeiter'
  }
  public leasedEmployeeHeader: TableHeader = {
    title: 'Externe Mitarbeiter'
  }
  public columns: TableColumn[] = [];
  public leasedEmployeeColumns: TableColumn[] = [];

  constructor(
    private cockpitUserBirthdayService: CockpitUserBirthdayService,
    private cdr: ChangeDetectorRef,
  ) { }

  ngOnInit() {
    this.cockpitUserBirthdayService.getUsersData().pipe(
      takeUntil(this.unsubscribe),
      map((entities) => {
        this.columns = this.cockpitUserBirthdayService.getUsersColumns();
        this.data = entities;
        this.isLoading = false;
        this.cdr.detectChanges();
      })
    ).subscribe();

    this.cockpitUserBirthdayService.getLeasedEmployeeData().pipe(
      takeUntil(this.unsubscribe),
      map((entities) => {
        this.leasedEmployeeColumns = this.cockpitUserBirthdayService.getLeasedEmployeeColumns();
        this.leasedEmployeeData = entities;
        this.isLeasedEmployeeLoading = false;
        this.cdr.detectChanges();
      })
    ).subscribe();
  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

}
