/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./field-action-set-value-to-another-field-from-entity.component";
import * as i2 from "../../../../../services/generic-crud.service";
import * as i3 from "../../../../services/double-click.service";
import * as i4 from "../../../../../../core/service/entity-manager/entity-manager.service";
var styles_FieldActionSetValueToAnotherFieldFromEntityComponent = [];
var RenderType_FieldActionSetValueToAnotherFieldFromEntityComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_FieldActionSetValueToAnotherFieldFromEntityComponent, data: {} });
export { RenderType_FieldActionSetValueToAnotherFieldFromEntityComponent as RenderType_FieldActionSetValueToAnotherFieldFromEntityComponent };
export function View_FieldActionSetValueToAnotherFieldFromEntityComponent_0(_l) { return i0.ɵvid(0, [], null, null); }
export function View_FieldActionSetValueToAnotherFieldFromEntityComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "field-action-set-value-to-another-field-from-entity", [], null, null, null, View_FieldActionSetValueToAnotherFieldFromEntityComponent_0, RenderType_FieldActionSetValueToAnotherFieldFromEntityComponent)), i0.ɵdid(1, 245760, null, 0, i1.FieldActionSetValueToAnotherFieldFromEntityComponent, [i2.GenericCrudService, i3.DoubleClickService, i4.EntityManagerService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var FieldActionSetValueToAnotherFieldFromEntityComponentNgFactory = i0.ɵccf("field-action-set-value-to-another-field-from-entity", i1.FieldActionSetValueToAnotherFieldFromEntityComponent, View_FieldActionSetValueToAnotherFieldFromEntityComponent_Host_0, { action: "action", field: "field", grid: "grid" }, {}, []);
export { FieldActionSetValueToAnotherFieldFromEntityComponentNgFactory as FieldActionSetValueToAnotherFieldFromEntityComponentNgFactory };
