/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./toolbar-item-import-multiselected-to-master.component";
import * as i2 from "../../../../../../../core/message/message-growl.service";
import * as i3 from "../../../../../../../core/message/message.service";
import * as i4 from "../../../../../../services/generic-crud.service";
import * as i5 from "../../../../../services/entity-data-store.service";
import * as i6 from "../../../../../services/double-click.service";
import * as i7 from "../../../../../services/component-highlight-stack.service";
import * as i8 from "../../../../../services/modules-state.service";
import * as i9 from "../../../../../services/navigation/module-navigation.service";
var styles_ToolbarItemImportMultiselectedToMasterComponent = [];
var RenderType_ToolbarItemImportMultiselectedToMasterComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_ToolbarItemImportMultiselectedToMasterComponent, data: {} });
export { RenderType_ToolbarItemImportMultiselectedToMasterComponent as RenderType_ToolbarItemImportMultiselectedToMasterComponent };
export function View_ToolbarItemImportMultiselectedToMasterComponent_0(_l) { return i0.ɵvid(2, [], null, null); }
export function View_ToolbarItemImportMultiselectedToMasterComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "toolbar-item-import-multiselected-to-master", [], null, null, null, View_ToolbarItemImportMultiselectedToMasterComponent_0, RenderType_ToolbarItemImportMultiselectedToMasterComponent)), i0.ɵdid(1, 245760, null, 0, i1.ToolbarItemImportMultiselectedToMasterComponent, [i2.MessageGrowlService, i3.MessageService, i4.GenericCrudService, i5.EntityDataStoreService, i6.DoubleClickService, i7.ComponentService, i8.ModulesStateService, i9.ModuleNavigationService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ToolbarItemImportMultiselectedToMasterComponentNgFactory = i0.ɵccf("toolbar-item-import-multiselected-to-master", i1.ToolbarItemImportMultiselectedToMasterComponent, View_ToolbarItemImportMultiselectedToMasterComponent_Host_0, { params: "params" }, {}, []);
export { ToolbarItemImportMultiselectedToMasterComponentNgFactory as ToolbarItemImportMultiselectedToMasterComponentNgFactory };
