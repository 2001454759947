/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/flex-layout/flex";
import * as i2 from "@angular/flex-layout/core";
import * as i3 from "../../directives/selenium.directive";
import * as i4 from "angular-pipes";
import * as i5 from "@angular/common";
import * as i6 from "./element-label-output-datamodel-text.component";
import * as i7 from "../form.service";
import * as i8 from "../form-viewer.service";
var styles_ElementLabelOutputDatamodelTextComponent = ["[_nghost-%COMP%] {\n      width: 100%;\n      padding: .334rem 0;\n    }"];
var RenderType_ElementLabelOutputDatamodelTextComponent = i0.ɵcrt({ encapsulation: 0, styles: styles_ElementLabelOutputDatamodelTextComponent, data: {} });
export { RenderType_ElementLabelOutputDatamodelTextComponent as RenderType_ElementLabelOutputDatamodelTextComponent };
function View_ElementLabelOutputDatamodelTextComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "div", [["fxFlex", "20"]], null, null, null, null, null)), i0.ɵdid(1, 671744, null, 0, i1.DefaultFlexDirective, [i0.ElementRef, i2.StyleUtils, i2.LAYOUT_CONFIG, i1.FlexStyleBuilder, i2.MediaMarshaller], { fxFlex: [0, "fxFlex"] }, null), (_l()(), i0.ɵeld(2, 0, null, null, 1, "img", [["appSeleniumDirective", ""], ["height", "16"], ["width", "16"]], [[8, "src", 4], [8, "alt", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onClickIcon() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵdid(3, 4210688, null, 0, i3.SeleniumDirective, [i0.ElementRef, i0.Renderer2], { element: [0, "element"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "20"; _ck(_v, 1, 0, currVal_0); var currVal_3 = _co.element; _ck(_v, 3, 0, currVal_3); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = ((_co.iconBaseUrl + "/") + ((_co.element == null) ? null : ((_co.element.icon == null) ? null : _co.element.icon.relativePath))); var currVal_2 = ((_co.element == null) ? null : ((_co.element.icon == null) ? null : _co.element.icon.name)); _ck(_v, 2, 0, currVal_1, currVal_2); }); }
export function View_ElementLabelOutputDatamodelTextComponent_0(_l) { return i0.ɵvid(2, [i0.ɵpid(0, i4.IsDefinedPipe, []), (_l()(), i0.ɵeld(1, 0, null, null, 9, "div", [["fxLayout", "row"]], null, null, null, null, null)), i0.ɵdid(2, 671744, null, 0, i1.DefaultLayoutDirective, [i0.ElementRef, i2.StyleUtils, [2, i1.LayoutStyleBuilder], i2.MediaMarshaller], { fxLayout: [0, "fxLayout"] }, null), (_l()(), i0.ɵeld(3, 0, null, null, 3, "div", [["appSeleniumDirective", ""], ["fxFlex", "80"]], null, null, null, null, null)), i0.ɵdid(4, 671744, null, 0, i1.DefaultFlexDirective, [i0.ElementRef, i2.StyleUtils, i2.LAYOUT_CONFIG, i1.FlexStyleBuilder, i2.MediaMarshaller], { fxFlex: [0, "fxFlex"] }, null), i0.ɵdid(5, 4210688, null, 0, i3.SeleniumDirective, [i0.ElementRef, i0.Renderer2], { element: [0, "element"] }, null), (_l()(), i0.ɵted(6, null, [" ", " "])), (_l()(), i0.ɵand(16777216, null, null, 3, null, View_ElementLabelOutputDatamodelTextComponent_1)), i0.ɵdid(8, 16384, null, 0, i5.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), i0.ɵppd(9, 1), i0.ɵppd(10, 1), (_l()(), i0.ɵeld(11, 0, null, null, 0, "span", [["class", "hidden-content"]], null, null, null, null, null))], function (_ck, _v) { var _co = _v.component; var currVal_0 = "row"; _ck(_v, 2, 0, currVal_0); var currVal_1 = "80"; _ck(_v, 4, 0, currVal_1); var currVal_2 = _co.element; _ck(_v, 5, 0, currVal_2); var currVal_4 = (i0.ɵunv(_v, 8, 0, _ck(_v, 9, 0, i0.ɵnov(_v, 0), ((_co.element == null) ? null : _co.element.icon))) && i0.ɵunv(_v, 8, 0, _ck(_v, 10, 0, i0.ɵnov(_v, 0), ((_co.element == null) ? null : ((_co.element.icon == null) ? null : _co.element.icon.relativePath))))); _ck(_v, 8, 0, currVal_4); }, function (_ck, _v) { var _co = _v.component; var currVal_3 = _co.getTemplateText(); _ck(_v, 6, 0, currVal_3); }); }
export function View_ElementLabelOutputDatamodelTextComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "app-form-element-label-output-datamodel-text", [], null, null, null, View_ElementLabelOutputDatamodelTextComponent_0, RenderType_ElementLabelOutputDatamodelTextComponent)), i0.ɵdid(1, 6012928, null, 1, i6.ElementLabelOutputDatamodelTextComponent, [i7.FormService, i0.ChangeDetectorRef, i8.FormViewerService], null, null), i0.ɵqud(603979776, 1, { insideNgContent: 0 })], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ElementLabelOutputDatamodelTextComponentNgFactory = i0.ɵccf("app-form-element-label-output-datamodel-text", i6.ElementLabelOutputDatamodelTextComponent, View_ElementLabelOutputDatamodelTextComponent_Host_0, { form: "form", formGroup: "formGroup", entity: "entity", element: "element", moduleElement: "moduleElement", selectedElement: "selectedElement", showDialogAddAddress: "showDialogAddAddress", actionParams: "actionParams", editMode: "editMode" }, { selectedElementChange: "selectedElementChange", showDialogAddAddressChange: "showDialogAddAddressChange", actionParamsChange: "actionParamsChange" }, []);
export { ElementLabelOutputDatamodelTextComponentNgFactory as ElementLabelOutputDatamodelTextComponentNgFactory };
