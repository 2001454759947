import { of as observableOf } from 'rxjs';
import { AbstractExecutionStep } from '../../../../core/executor/abstract-execution-step';
import { AbstractGenericGridComponent } from '../../../content-renderer/elements/abstract-generic-grid.component';
export class FilterInquiryAutocompletesInGridStep extends AbstractExecutionStep {
    constructor() {
        super(...arguments);
        this.filterableFields = ['workplace', 'preCalculation', 'selectedWorkingTimeModel'];
    }
    doExecute() {
        const payload = this.getPayload();
        let component = payload.getValue();
        if (payload instanceof Object && payload.getValue().component) {
            component = payload.getValue().component;
        }
        if (!(component instanceof AbstractGenericGridComponent)) {
            return this.getFailObservable('You need to pass GenericElementAbstract as Payload value!');
        }
        return this.doFilter(component);
    }
    doFilter(component) {
        const masterEntity = component.selectedMasterEntity;
        if (!masterEntity || masterEntity.fqn !== 'PhoenixBundle\\Entity\\Inquiry') {
            return this.getFailObservable('You need to pass Inquiry as Master entity!');
        }
        for (const column of component.columns) {
            if (column.isAssociatedField && this.filterableFields.indexOf(column.entityName) !== -1) {
                column.customAutocompleteFilters = [{ 'name': 'customer', 'value': masterEntity.customer.id }];
            }
        }
        return observableOf({ status: true, content: '' });
    }
}
