import { Component, OnInit} from '@angular/core';
import { LoggerService } from 'app/shared/content-renderer/services/logger/logger.service';
import { Log } from 'app/shared/content-renderer/services/logger/logger';
import {
  HttpRequest
} from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-log',
  styleUrls: ['./log.component.scss'],
  templateUrl: './log.component.html'
})
export class LogComponent implements OnInit {

  public info: any = null;
  public logs: Log[] = [];

  public constructor(
    private translateService: TranslateService,
    private logger: LoggerService
  ) {

  }

  public ngOnInit() {
    this.logs = this.logger.getAll();
  }

  public getLevel(log: Log): string {
    return this.translateService.instant(`LOG.LEVEL.${log.level}`);
  }

  public openInfo(log: Log): void {
    let info = log.message,
      context = log.context;
    
    if (context['request']) {
      info += this.getRequestLog(context['request']);
    }

    if (context['entity']) {
      info += JSON.stringify(context['entity']);
    }

    this.info = info;
  }

  private getRequestLog(request: HttpRequest<any>): string {
    return `Route: ${request.url} \n Method: ${request.method} \n`; 
  }

}
