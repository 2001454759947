
import {of as observableOf, Observable} from 'rxjs';
import {AbstractExecutionStep} from '../../executor/abstract-execution-step';
import {ExecutionStepStatus} from '../../executor/execution-step-status';
import {AuthenticationService} from '../../authentication/authentication.service';

export class IsUserAuthenticatedExecutionStep extends AbstractExecutionStep {

  public doExecute(): Observable<ExecutionStepStatus> {
    const payload = this.getPayload().getValue(),
      authenticationService: AuthenticationService = payload.authenticationService;

    return observableOf({status: authenticationService.authenticated(), content: null});
  }
}
