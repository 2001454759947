import { AbstractExecutionStep } from '../../../core/executor/abstract-execution-step';
import { Observable } from 'rxjs/Observable';
import { ExecutionStepStatus } from '../../../core/executor/execution-step-status';
import { GenericElementAbstract } from '../../content-renderer/elements/generic-element-abstract.component';
import {AbstractGenericGridComponent} from '../../content-renderer/elements/abstract-generic-grid.component';

export class MasterComponentRefreshExecutionStep extends AbstractExecutionStep {

    public doExecute(): Observable<ExecutionStepStatus> {
        const payload = this.getPayload();

        if (!(payload.getValue() instanceof GenericElementAbstract)) {
            return this.getFailObservable('You need to pass GenericElementAbstract as Payload value!');
        }

        return this.doRefresh(payload.getValue());
    }

    private doRefresh(component: GenericElementAbstract): Observable<ExecutionStepStatus> {

      const masterComponent = component.masterElementContext ? component.masterElementContext.component
        : component.elementContext.masterElementContext.component;


      return new Observable<ExecutionStepStatus>(observer => {
        if (masterComponent instanceof AbstractGenericGridComponent) {
          masterComponent.onRefreshEntities(masterComponent.entities)
            .subscribe(() => {
              this.entityDraftStoreService.clear();
              observer.next({
                status: true,
                content: null
              });
              observer.complete();
            });
        } else {
          observer.next({
            status: true,
            content: null
          });
          observer.complete();
        }

      });
    }
}
