import { DynamicTableColumn } from '../../../../content-renderer/services/dynamic-table-column';
import { InquiryPositionSalaryType } from './inquiry-position-salary-type';
import { InquiryPositionMatrixItem } from './inquiry-position-salary-type-matrix';
import { FieldMetadataGrid } from '../../../module/module-element-field-metadata-grid';
import { InquiryPositionSalaryTypeParser } from './inquiry-position-salary-type-parser';
import { GenericDynamicTreeOldComponent } from '../../../../content-renderer/elements/generic-dynamic-tree-old/generic-dynamic-tree-old.component';

export interface GeneratedInquiryPositionSalaryTypesTableData {
    nodes: TreeNode[];
    columns: any[];
}

export class InquiryPositionSalaryTypeTableGenerator {

    private component: GenericDynamicTreeOldComponent = null;

    private parser = new InquiryPositionSalaryTypeParser();

    public setComponent(component: GenericDynamicTreeOldComponent): this {
        this.component = component;
        return this;
    }

    public generateTable(entities: any[] = []): GeneratedInquiryPositionSalaryTypesTableData {
        const tableNodes = this.generateTreeNodes(entities),
          columns = this.generateColumns(entities);

        return {
            nodes: tableNodes,
            columns: columns
        };
    }

    private generateTreeNodes(entities: InquiryPositionSalaryType[] = []): TreeNode[] {
        return this.parser.getMatrixItemsFromInquiryPositionSalaryTypes(entities);
    }

    private generateColumns(entities: any[] = []): DynamicTableColumn[] {
        let columns = [];

        const inquiryPositions = this.getInquiryPositions(entities);

        if (inquiryPositions.length > 0) {
            columns = [...columns, {
                id: 'salaryType.name',
                property: 'salaryType.name',
                header: 'Name',
                Style: {
                  textAlign: 'left'
                },
                getValue: (node: TreeNode) => {
                  return node.data.salaryType.name;
                },
                fieldType: FieldMetadataGrid.TYPE_STRING,
                field: {
                    id: 0,
                    name: 'Name',
                    visible: true,
                    visibleInColumnManagement: true,
                    fieldType: FieldMetadataGrid.TYPE_STRING,
                    isReadOnly: true
                }
            }];
        }

        for (const inquiryPosition of inquiryPositions) {
            columns = [...columns, {
                key: inquiryPosition.id,
                header: inquiryPosition.jobPrintText,
                renderType: 'checkbox',
                Style: {
                    textAlign: 'center'
                },
                fieldType: FieldMetadataGrid.TYPE_CHECKBOX,
                getValue: (node: TreeNode) => {
                  return node.data.inquiryPositions[inquiryPosition.id].isRequired;
                },
                field: {
                    id: inquiryPosition.id,
                    name: inquiryPosition.id,
                    visible: true,
                    visibleInColumnManagement: true,
                    fieldType: FieldMetadataGrid.TYPE_CHECKBOX
                }
            }];
        }

        if (columns.length > 0) {
            columns = [...columns, {
                key: '*',
                header: 'Für alle',
                renderType: 'checkbox',
                Style: {
                    textAlign: 'center'
                },
                fieldType: FieldMetadataGrid.TYPE_CHECKBOX,
                getValue: (node: TreeNode) => {
                    let isChecked = true;

                    for (const inquiryPositionId in node.data.inquiryPositions) {
                        const inquiryPositionItem: InquiryPositionMatrixItem = node.data.inquiryPositions[inquiryPositionId];

                        if (!inquiryPositionItem.isRequired) {
                            isChecked = false;
                            break;
                        }
                    }

                    return isChecked;
                },
                field: {
                    id: 0,
                    name: '*',
                    visible: true,
                    visibleInColumnManagement: true,
                    fieldType: FieldMetadataGrid.TYPE_CHECKBOX
                }
            }];
        }

        return columns;
    }

    private getInquiryPositions(entities: any[] = []): any[] {
        const inquiryPositions = [];

        for (const entity of entities) {
            const isAdded = inquiryPositions.findIndex((aInquiryPosition) => {
              return aInquiryPosition.id === entity.inquiryPosition.id;
            }) !== -1;

            if (!isAdded) {
                inquiryPositions.push(entity.inquiryPosition);
            }
        }

        return inquiryPositions;
    }

    private getSalaryTypes(inquiryPositionsSalaryTypes: InquiryPositionSalaryType[] = []): any[] {
        const salaryTypes = [];

        for (const inquiryPositionSalaryType of inquiryPositionsSalaryTypes) {
            const isAdded = salaryTypes.findIndex((aSalaryType) => {
              return aSalaryType.id === inquiryPositionSalaryType.salaryType.id;
            }) !== -1;

            if (!isAdded) {
                salaryTypes.push(inquiryPositionSalaryType.salaryType);
            }
        }

        return salaryTypes;
    }

}
