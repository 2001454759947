/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./element.component.ngfactory";
import * as i2 from "@angular/forms";
import * as i3 from "./element.component";
import * as i4 from "../form.service";
import * as i5 from "../form-viewer.service";
import * as i6 from "@ngx-translate/core";
import * as i7 from "../../services/datamodel/datamodel.crud.service";
import * as i8 from "@angular/flex-layout/flex";
import * as i9 from "@angular/flex-layout/core";
import * as i10 from "../../../../../node_modules/primeng/components/fieldset/fieldset.ngfactory";
import * as i11 from "primeng/components/fieldset/fieldset";
import * as i12 from "@angular/common";
import * as i13 from "./element-layout-fieldset.component";
var styles_ElementLayoutFieldsetComponent = ["[_nghost-%COMP%]    > div[_ngcontent-%COMP%] {\n      padding: .5em 0;\n    }\n\n    [_nghost-%COMP%]    > div[_ngcontent-%COMP%]    > p-fieldset[_ngcontent-%COMP%] {\n      width: 100%;\n    }\n\n    [_nghost-%COMP%]    > div[_ngcontent-%COMP%]    >   p-fieldset fieldset {\n      border-radius: 15px;\n    }"];
var RenderType_ElementLayoutFieldsetComponent = i0.ɵcrt({ encapsulation: 0, styles: styles_ElementLayoutFieldsetComponent, data: {} });
export { RenderType_ElementLayoutFieldsetComponent as RenderType_ElementLayoutFieldsetComponent };
function View_ElementLayoutFieldsetComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 5, null, null, null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 4, "app-form-element", [], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "selectedElementChange"], [null, "actionParamsChange"], [null, "showDialogAddAddressChange"], [null, "submit"], [null, "reset"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("submit" === en)) {
        var pd_0 = (i0.ɵnov(_v, 2).onSubmit($event) !== false);
        ad = (pd_0 && ad);
    } if (("reset" === en)) {
        var pd_1 = (i0.ɵnov(_v, 2).onReset() !== false);
        ad = (pd_1 && ad);
    } if (("selectedElementChange" === en)) {
        var pd_2 = ((_co.selectedElement = $event) !== false);
        ad = (pd_2 && ad);
    } if (("actionParamsChange" === en)) {
        var pd_3 = ((_co.actionParams = $event) !== false);
        ad = (pd_3 && ad);
    } if (("showDialogAddAddressChange" === en)) {
        var pd_4 = ((_co.showDialogAddAddress = $event) !== false);
        ad = (pd_4 && ad);
    } return ad; }, i1.View_ElementComponent_0, i1.RenderType_ElementComponent)), i0.ɵdid(2, 540672, null, 0, i2.FormGroupDirective, [[8, null], [8, null]], { form: [0, "form"] }, null), i0.ɵprd(2048, null, i2.ControlContainer, null, [i2.FormGroupDirective]), i0.ɵdid(4, 16384, null, 0, i2.NgControlStatusGroup, [[4, i2.ControlContainer]], null, null), i0.ɵdid(5, 4964352, null, 0, i3.ElementComponent, [i4.FormService, i0.ChangeDetectorRef, i5.FormViewerService, i6.TranslateService, i7.DatamodelCrudService], { form: [0, "form"], formGroup: [1, "formGroup"], entity: [2, "entity"], element: [3, "element"], moduleElement: [4, "moduleElement"], selectedElement: [5, "selectedElement"], showDialogAddAddress: [6, "showDialogAddAddress"], actionParams: [7, "actionParams"], editMode: [8, "editMode"] }, { selectedElementChange: "selectedElementChange", showDialogAddAddressChange: "showDialogAddAddressChange", actionParamsChange: "actionParamsChange" })], function (_ck, _v) { var _co = _v.component; var currVal_7 = _co.formGroup; _ck(_v, 2, 0, currVal_7); var currVal_8 = _co.form; var currVal_9 = _co.formGroup; var currVal_10 = _co.entity; var currVal_11 = _v.context.$implicit; var currVal_12 = _co.moduleElement; var currVal_13 = _co.selectedElement; var currVal_14 = _co.showDialogAddAddress; var currVal_15 = _co.actionParams; var currVal_16 = _co.editMode; _ck(_v, 5, 0, currVal_8, currVal_9, currVal_10, currVal_11, currVal_12, currVal_13, currVal_14, currVal_15, currVal_16); }, function (_ck, _v) { var currVal_0 = i0.ɵnov(_v, 4).ngClassUntouched; var currVal_1 = i0.ɵnov(_v, 4).ngClassTouched; var currVal_2 = i0.ɵnov(_v, 4).ngClassPristine; var currVal_3 = i0.ɵnov(_v, 4).ngClassDirty; var currVal_4 = i0.ɵnov(_v, 4).ngClassValid; var currVal_5 = i0.ɵnov(_v, 4).ngClassInvalid; var currVal_6 = i0.ɵnov(_v, 4).ngClassPending; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); }); }
export function View_ElementLayoutFieldsetComponent_0(_l) { return i0.ɵvid(2, [(_l()(), i0.ɵeld(0, 0, null, null, 5, "div", [["fxLayout", "row"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onClickElement($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵdid(1, 671744, null, 0, i8.DefaultLayoutDirective, [i0.ElementRef, i9.StyleUtils, [2, i8.LayoutStyleBuilder], i9.MediaMarshaller], { fxLayout: [0, "fxLayout"] }, null), (_l()(), i0.ɵeld(2, 0, null, null, 3, "p-fieldset", [], null, null, null, i10.View_Fieldset_0, i10.RenderType_Fieldset)), i0.ɵdid(3, 49152, [["elementItem", 4]], 0, i11.Fieldset, [i0.ElementRef], { legend: [0, "legend"], styleClass: [1, "styleClass"] }, null), (_l()(), i0.ɵand(16777216, null, 1, 1, null, View_ElementLayoutFieldsetComponent_1)), i0.ɵdid(5, 278528, null, 0, i12.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "row"; _ck(_v, 1, 0, currVal_0); var currVal_1 = _co.element.label; var currVal_2 = (!_co.element.showLabel ? "hide-fieldset-label" : ""); _ck(_v, 3, 0, currVal_1, currVal_2); var currVal_3 = _co.element.children; _ck(_v, 5, 0, currVal_3); }, null); }
export function View_ElementLayoutFieldsetComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-form-element-layout-fieldset", [], null, null, null, View_ElementLayoutFieldsetComponent_0, RenderType_ElementLayoutFieldsetComponent)), i0.ɵdid(1, 4964352, null, 0, i13.ElementLayoutFieldsetComponent, [i4.FormService, i0.ChangeDetectorRef, i5.FormViewerService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ElementLayoutFieldsetComponentNgFactory = i0.ɵccf("app-form-element-layout-fieldset", i13.ElementLayoutFieldsetComponent, View_ElementLayoutFieldsetComponent_Host_0, { form: "form", formGroup: "formGroup", entity: "entity", element: "element", moduleElement: "moduleElement", selectedElement: "selectedElement", showDialogAddAddress: "showDialogAddAddress", actionParams: "actionParams", editMode: "editMode" }, { selectedElementChange: "selectedElementChange", showDialogAddAddressChange: "showDialogAddAddressChange", actionParamsChange: "actionParamsChange" }, []);
export { ElementLayoutFieldsetComponentNgFactory as ElementLayoutFieldsetComponentNgFactory };
