/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../services/toolbar-item-print.service";
import * as i2 from "./toolbar-item-print.component";
var styles_ToolbarItemPrint = [];
var RenderType_ToolbarItemPrint = i0.ɵcrt({ encapsulation: 2, styles: styles_ToolbarItemPrint, data: {} });
export { RenderType_ToolbarItemPrint as RenderType_ToolbarItemPrint };
export function View_ToolbarItemPrint_0(_l) { return i0.ɵvid(2, [], null, null); }
export function View_ToolbarItemPrint_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "toolbar-item-print", [], null, null, null, View_ToolbarItemPrint_0, RenderType_ToolbarItemPrint)), i0.ɵprd(512, null, i1.ToolbarItemPrintService, i1.ToolbarItemPrintService, []), i0.ɵdid(2, 245760, null, 0, i2.ToolbarItemPrint, [i1.ToolbarItemPrintService], null, null)], function (_ck, _v) { _ck(_v, 2, 0); }, null); }
var ToolbarItemPrintNgFactory = i0.ɵccf("toolbar-item-print", i2.ToolbarItemPrint, View_ToolbarItemPrint_Host_0, { params: "params" }, {}, []);
export { ToolbarItemPrintNgFactory as ToolbarItemPrintNgFactory };
