<div #container fxLayout="column" fxLayoutAlign="start stretch" class="container">

  <div
    [ngStyle]="{'display': inState('edit') ? 'flex' : 'none'}"
    fxFlex="30px" fxLayout="row" fxLayoutAlign="start stretch"
  >
    <div fxFlex="80">
      <app-search-management-dropdown-list
        #dropdown
        [datamodel]="component.element.datamodel"
        (onExpertSearchOptionChanged)="onExpertSearchOptionChanged($event)"
      ></app-search-management-dropdown-list>
    </div>

    <div class="common-button-wrapper" fxFlex="20">
      <button
        (click)="onAddExpertSearch()"
        pButton
        label="{{ 'COMMON.NEW' | translate }}"
        icon="fa fa-plus"
        class="common-button"
      ></button>
    </div>
  </div>

  <div *ngIf="inState('add')" fxFlex="30px" fxLayout="row" fxLayoutAlign="start stretch">
    <div fxFlex="80">
      <input
        [style.height.px]="23"
        placeholder="{{ 'COMMON.NAME' | translate }}"
        id="name"
        pInputText type="text"
        [(ngModel)]="expertSearch.name"
      >
    </div>
    <div class="common-button-wrapper" fxFlex="20">
      <button
        (click)="onCancelAdd()"
        pButton
        class="common-button"
      >{{ 'COMMON.CANCEL' | translate }}</button>
    </div>
  </div>

  <div class="list-container">
    <app-search-management-edit-container-list
      [(expertSearch)]="expertSearch"
    ></app-search-management-edit-container-list>
  </div>

  <div fxFlex="30px">
    <div fxFlex="80" *ngIf="expertSearch" class="common-button-wrapper">
      <button
        [disabled]="!expertSearch || !expertSearch.name"
        (click)="onSaveFilter($event)"
        pButton
        label="{{ 'COMMON.SAVE' | translate }}"
        class="common-button"
        icon="fa fa-save"
      ></button>
    </div>

    <div fxFlex="20" *ngIf="expertSearch" class="common-button-wrapper">
      <button
        (click)="onFilter($event)"
        pButton
        label="{{ 'COMMON.SEARCH' | translate }}"
        class="common-button"
        icon="fa fa-search"
      ></button>
    </div>
  </div>
</div>
