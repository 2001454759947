export interface FroalaToolbarConfigurationInterface {
  label: string;
  value: string;
  toolbarButtons: string[];
  customButtons: { icon: string, name: string, labelKey: string }[];
}

export class FroalaToolbarConfiguration {
  configuration: FroalaToolbarConfigurationInterface[] = [
    { value: 'default', label: 'Default', toolbarButtons: [
        'fullscreen', 'bold', 'italic', 'underline',
        '|', 'fontFamily', 'fontSize', 'color',
        '|', 'paragraphFormat', 'align', 'formatOL', 'formatUL',
        '-', 'embedly', 'insertTable',
        '|', 'insertHR'], customButtons: [] },
    { value: 'defaultWithModuleButton', label: 'Module Button',  toolbarButtons: [
        'fullscreen', 'bold', 'italic', 'underline',
        '|', 'fontFamily', 'fontSize', 'color',
        '|', 'paragraphFormat', 'align', 'formatOL', 'formatUL',
        '-', 'embedly', 'insertTable',
        '|', 'insertHR'
      ], customButtons: [{name: 'moduleButton', icon: 'info', labelKey: 'CUSTOM_BUTTON.OPEN_MODULE'}] }
  ];

  constructor(protected uniqueKey) {
  }

  getConfiguration(key: string): FroalaToolbarConfigurationInterface {
    for (const configuration of this.configuration) {
      if (configuration.value === key) {
        return this.getUniqueConfiguration(configuration);
      }
    }

    return null;
  }

  private getUniqueConfiguration(configuration: FroalaToolbarConfigurationInterface): FroalaToolbarConfigurationInterface {
    const buttons = configuration.customButtons;

    for (const button of buttons) {
      button.name = `${button.name}${this.uniqueKey}`;
      configuration.toolbarButtons.push(button.name);
    }

    return configuration;
  }
}
