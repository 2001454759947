
import {of as observableOf,  Observable } from 'rxjs';
import { AbstractExecutionStep } from '../../../../core/executor/abstract-execution-step';
import { ExecutionStepStatus } from '../../../../core/executor/execution-step-status';
import { AbstractGenericGridComponent } from '../../../content-renderer/elements/abstract-generic-grid.component';
import { ElementInputAutocompleteComponent } from '../../../form-viewer/element/element-input-autocomplete.component';
import { FormViewerComponent } from '../../../form-viewer/form-viewer.component';
import { FieldMetadataGrid } from '../../module/module-element-field-metadata-grid';
import { EntityStatus } from '../../entity/entity-status';
import {MessageGrowlService} from '../../../../core/message/message-growl.service';
import {TranslateService} from '@ngx-translate/core';
import {Entity} from "../../../helpers/entity";

export class BaseSetValueFromDialogStep extends AbstractExecutionStep {

    public doExecute(): Observable<ExecutionStepStatus> {
        const payload = this.getPayload();

        if (!payload.getValue().fromComponent || !payload.getValue().toComponent) {
            return this.getFailObservable('You need to pass from and to component!');
        }

        return this.doSetValue(payload.getValue().fromComponent, payload.getValue().toComponent, payload.getValue().field);
    }

    protected doSetValue(fromComponent: FormViewerComponent|AbstractGenericGridComponent, toComponent: any, field: FieldMetadataGrid): Observable<ExecutionStepStatus> {
        let entity = null;

        if (fromComponent instanceof AbstractGenericGridComponent) {
          entity = fromComponent.getSelectedEntity();
        }

        if (fromComponent instanceof FormViewerComponent) {
          entity = fromComponent.entity;
        }

        if (toComponent instanceof AbstractGenericGridComponent) {
            this.setValueToGridAutocomplete(entity, toComponent, field);
        }

        if (toComponent instanceof ElementInputAutocompleteComponent) {
            this.setValueToFormAutocomplete(entity, toComponent);
        }

        return observableOf({
            status: true,
            content: null
        });
    }

    protected setValueToGridAutocomplete(entity: any, toComponent: AbstractGenericGridComponent, field: FieldMetadataGrid): void {
        const toEntity = toComponent.getSelectedEntity(),
          column = toComponent.getColumnBuilder().findColumnBy(field.id, 'property');

        const oldValue = Entity.getValue(toEntity, field.entityName);

        toEntity[field.entityName] = entity;
        toEntity._embedded = toEntity._embedded || {};
        toEntity._embedded[field.entityName] = entity;

        toComponent.flagEntityForBulkSave(toEntity, toEntity[field.entityName], oldValue).subscribe((canBeChanged: boolean) => {
          if (canBeChanged) {
            toComponent.changeEntityValue(toEntity, column, toEntity[field.entityName], oldValue);
          }
        });
    }

    protected setValueToFormAutocomplete(entity: any, toComponent: ElementInputAutocompleteComponent): void {
        const selectedOption = {
            label: toComponent.figureOutLabel(entity),
            value: entity[EntityStatus.ENTITY_DRAFT_FLAG],
            entity: entity
        };

        toComponent
            .addOption(selectedOption)
            .setValue(selectedOption)
            .getSelectedOption(selectedOption);

        toComponent.markElementForCheck(toComponent.element);
    }

    protected showSuccess(): void {
      this.getGrowl().success(
        this.getTranslate().instant('COMMON.DATA_SAVED'),
        this.getTranslate().instant('COMMON.SUCCESS')
      );
    }

    private getGrowl(): MessageGrowlService {
      return this.injector.get(MessageGrowlService, null);
    }

    private getTranslate(): TranslateService {
      return this.injector.get(TranslateService, null);
    }
}
