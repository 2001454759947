<a (click)="onItemClick(item)" *ngIf="!item.isSeparator && item.isCustomMenuItem" [pTooltip]="item.tooltip" [tooltipPosition]="menuPosition == 'right' ? 'left' : 'right'">
  <div class="item-container item-text-container">
    {{item.label}}
  </div>
</a>
<p-dialog
  *ngIf="isDialogVisible"
  [(visible)]="isDialogVisible"
  header="BDM internes Personal export"
  [width]="700"
  [height]="400"
  appendTo="body"
  draggable="false"
  modal="true"
>

  <p-toolbar styleClass="no-round-corner">
    <div class="buttons-container">
      <div class="item-spacer"></div>
      <div class="item-right">
        <button
          [disabled]="!selectedPeriod"
          type="button"
          pButton
          (click)="onExport($event)"
          label="Export"
        ></button>
      </div>
    </div>
  </p-toolbar>

  <p-fieldset class="fieldset" legend="Export-Art">
    <div class="ui-grid-row">
      <div class="ui-grid-col-4">
        <label for="branchOfficeId">
          {{ 'COMMON.BRANCH_OFFICE' | translate }}:
        </label>
      </div>
      <div class="ui-grid-col-8">
        <p-dropdown [options]="branchOfficeDropdown" [(ngModel)]="selectedBranchOffice" id="branchOfficeId" filter="filter" [style]="{'width':'100%'}" required></p-dropdown>
      </div>
    </div>
    <div class="ui-grid-row">
      <div class="ui-grid-col-4">
        <label for="periodId">
          {{ 'COST_CENTER.PERIOD' | translate }}:
        </label>
      </div>
      <div class="ui-grid-col-8">
        <p-dropdown [options]="periodsDropdown" [(ngModel)]="selectedPeriod" id="periodId" filter="filter" [style]="{'width':'100%'}" required></p-dropdown>
      </div>
    </div>
  </p-fieldset>
</p-dialog>
