/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../../../../../../../../node_modules/primeng/components/togglebutton/togglebutton.ngfactory";
import * as i2 from "@angular/forms";
import * as i3 from "primeng/components/togglebutton/togglebutton";
import * as i4 from "../../../../../../../../core/executor/executor.service";
import * as i5 from "../../../../../../../../core/executor/service/executor-actions/executor-actions.service";
import * as i6 from "./checkbox-question.component";
import * as i7 from "../../../../../../../services/generic-crud.service";
import * as i8 from "../../../../../../../../core/executor/factory/execution-step-factory.service";
var styles_CheckboxQuestionComponent = ["[_nghost-%COMP%] {\n      width: 100%;\n    }"];
var RenderType_CheckboxQuestionComponent = i0.ɵcrt({ encapsulation: 0, styles: styles_CheckboxQuestionComponent, data: {} });
export { RenderType_CheckboxQuestionComponent as RenderType_CheckboxQuestionComponent };
export function View_CheckboxQuestionComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 7, "div", [["class", "ui-g"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "div", [["class", "ui-g-8"]], null, null, null, null, null)), (_l()(), i0.ɵted(2, null, [" ", " "])), (_l()(), i0.ɵeld(3, 0, null, null, 4, "div", [["class", "ui-g-8"]], null, null, null, null, null)), (_l()(), i0.ɵeld(4, 0, null, null, 3, "p-toggleButton", [["offIcon", "pi pi-times"], ["onIcon", "pi pi-check"]], null, [[null, "onChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("onChange" === en)) {
        var pd_0 = (_co.onChange($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i1.View_ToggleButton_0, i1.RenderType_ToggleButton)), i0.ɵprd(5120, null, i2.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i3.ToggleButton]), i0.ɵdid(6, 4243456, null, 0, i3.ToggleButton, [], { onLabel: [0, "onLabel"], offLabel: [1, "offLabel"], onIcon: [2, "onIcon"], offIcon: [3, "offIcon"], style: [4, "style"] }, { onChange: "onChange" }), i0.ɵpod(7, { "width": 0 })], function (_ck, _v) { var _co = _v.component; var currVal_1 = i0.ɵinlineInterpolate(1, "", _co.trueLabel, ""); var currVal_2 = i0.ɵinlineInterpolate(1, "", _co.falseLabel, ""); var currVal_3 = "pi pi-check"; var currVal_4 = "pi pi-times"; var currVal_5 = _ck(_v, 7, 0, "150px"); _ck(_v, 6, 0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.label; _ck(_v, 2, 0, currVal_0); }); }
export function View_CheckboxQuestionComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "app-questionnaire-question-checkbox", [], null, null, null, View_CheckboxQuestionComponent_0, RenderType_CheckboxQuestionComponent)), i0.ɵprd(512, null, i4.ExecutorService, i4.ExecutorService, [i5.ExecutorActionsService]), i0.ɵdid(2, 114688, null, 0, i6.CheckboxQuestionComponent, [i7.GenericCrudService, i4.ExecutorService, i8.ExecutionStepFactoryService], null, null)], function (_ck, _v) { _ck(_v, 2, 0); }, null); }
var CheckboxQuestionComponentNgFactory = i0.ɵccf("app-questionnaire-question-checkbox", i6.CheckboxQuestionComponent, View_CheckboxQuestionComponent_Host_0, { question: "question", questionnaire: "questionnaire", questionnaireComponent: "questionnaireComponent" }, {}, []);
export { CheckboxQuestionComponentNgFactory as CheckboxQuestionComponentNgFactory };
