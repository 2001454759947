
import {of as observableOf, Observable} from 'rxjs';
import {AbstractExecutionStep} from '../../../../core/executor/abstract-execution-step';
import {ExecutionStepStatus} from '../../../../core/executor/execution-step-status';
import {AbstractGenericGridComponent} from '../../../content-renderer/elements/abstract-generic-grid.component';
import {WorkHourCalculatorService} from './Calculator/work-hour-calculator-service';
import {EntityManagerService} from '../../../../core/service/entity-manager/entity-manager.service';

export class WorkHourFrontendCalculateTimesStep extends AbstractExecutionStep {

  protected calculationRelevantFields = [
    'workStartIndustry',
    'workEndIndustry',
    'workStart2Industry',
    'workEnd2Industry',
    'workBreakIndustry',
    'workLengthIndustry',
    'workStart',
    'workEnd',
    'workStart2',
    'workEnd2',
    'workBreak',
    'workLength',
    'workLengthPlanned',
    'workLengthPlannedIndustry'
  ];

  public doExecute(): Observable<ExecutionStepStatus> {
    const payload = this.getPayload(),
      component = payload.getValue().component;

    if (component instanceof AbstractGenericGridComponent
      && payload.getValue().entityDataChangeMeta
      && payload.getValue().entityDataChangeMeta.entity
      && payload.getValue().entityDataChangeMeta.gridField
      && this.calculationRelevantFields.indexOf(payload.getValue().entityDataChangeMeta.gridField.id) !== -1) {

      return this.doCalculate(component, payload.getValue().entityDataChangeMeta.entity, payload.getValue().entityDataChangeMeta.gridField);
    }

    return this.getFailObservable('You need to pass AbstractGenericGridComponent or FormViewerComponent as Payload value!');
  }

  protected doCalculate(component: AbstractGenericGridComponent, entity, field) {
    const workHourCalculatorService: WorkHourCalculatorService = this.injector.get(WorkHourCalculatorService, null),
      em: EntityManagerService = this.injector.get(EntityManagerService, null);

    if (field && (field.id === 'workLengthIndustry' || field.id === 'workLength')) {
      workHourCalculatorService.calculateWorkEnd(
        component.apiRoutAdditionalParameters['entryType'],
        component.apiRoutAdditionalParameters['industrialTime'],
        entity,
        field.id
      );
    }

    const calculatedEntity = workHourCalculatorService.calculateFields(
      component.apiRoutAdditionalParameters['entryType'],
      component.apiRoutAdditionalParameters['industrialTime'],
      entity
    );

    for (const aField of this.calculationRelevantFields) {
      em.persist(entity, {property: aField, newValue: calculatedEntity[aField], force: true});
    }

    return observableOf({status: true, content: []});
  }

}
