
import {of as observableOf,  Observable } from 'rxjs';
import { AbstractExecutionStep } from '../../../../../core/executor/abstract-execution-step';
import { ExecutionStepStatus } from '../../../../../core/executor/execution-step-status';
import { AbstractGenericGridComponent } from '../../../../content-renderer/elements/abstract-generic-grid.component';
import { FormViewerComponent } from '../../../../form-viewer/form-viewer.component';
import { ElementContext } from '../../../../content-renderer/services/ElementContext';
import { Entity } from '../../../../helpers/entity';
import { FieldMetadataGrid } from '../../../module/module-element-field-metadata-grid';

interface ConditionMethodStrategy {
    fields: string[];
    show: Function;
    hide: Function;
}

export class PrecalculationDetailsToggleColumnsVisibilityStep extends AbstractExecutionStep {

    private static readonly MONTH = 'Monat';
    private static readonly HOUR = 'Stunde';

    private conditions: ConditionMethodStrategy[] = [
        {
            fields: ['salarySchema', 'salaryAdvancement', 'advancementAfter'],
            show: this.showSalaryAndAdvancement.bind(this),
            hide: this.hideSalaryAndAdvancement.bind(this)
        },
        {
            fields: ['salaryMonth', 'selfCostsMonth'],
            show: this.showSalaryMonths.bind(this),
            hide: this.hideSalaryMonths.bind(this)
        },
        {
            fields: ['invoiceMonth', 'targetInvoiceMonth'],
            show: this.showInvoiceMonths.bind(this),
            hide: this.hideInvoiceMonths.bind(this)
        },
        {
            fields: ['salaryHour', 'selfCostsHour'],
            show: this.showSalaryHours.bind(this),
            hide: this.hideSalaryHours.bind(this)
        },
        {
            fields: ['invoiceHour', 'targetInvoiceHour'],
            show: this.showInvoiceHours.bind(this),
            hide: this.hideInvoiceHours.bind(this)
        },
        {
            fields: ['globalFields'],
            show: this.showGlobalFields.bind(this),
            hide: this.hideGlobalFields.bind(this)
        }
    ];

    doExecute(): Observable<ExecutionStepStatus> {
        const payload = this.getPayload();

        let component = payload.getValue();

        if (payload instanceof Object && payload.getValue().component) {
            component = payload.getValue().component;
        }

        if (!(component instanceof AbstractGenericGridComponent)) {
            return this.getFailObservable('You need to pass AbstractGenericGridComponent as Payload value!');
        }

        const gridContext: ElementContext = component.getElementContext();

        const formContext: ElementContext = gridContext.getMasterElementContext();

        if (!formContext || !(formContext.component instanceof FormViewerComponent)) {
            return this.getFailObservable('Form not found, grid has to have master of form!');
        }

        return this.doToggleVisibility(formContext.component, component);
    }

    protected getPreCalculation(form: FormViewerComponent): any {
      return form.entity;
    }

    protected doToggleVisibility(form: FormViewerComponent, grid: AbstractGenericGridComponent): Observable<ExecutionStepStatus> {
        grid.isDataLoading = true;

        this.applyConditions(form, grid);

        grid.isDataLoading = false;

        return observableOf({ status: true, content: null });
    }

    protected applyConditions(form: FormViewerComponent, grid: AbstractGenericGridComponent): void {
        for (const condition of this.conditions) {

            if (condition.show(form, grid)) {
                this.showFields(grid, condition.fields);
            }

            if (condition.hide(form, grid)) {
                this.hideFields(grid, condition.fields);
            }
        }

        grid.initColumns();
    }

    private showSalaryAndAdvancement(form: FormViewerComponent, grid: AbstractGenericGridComponent): boolean {
        return grid.entities.length > 1;
    }

    private hideSalaryAndAdvancement(form: FormViewerComponent, grid: AbstractGenericGridComponent): boolean {
        return grid.entities.length === 0 || grid.entities.length === 1;
    }

    private getSalaryUnitNameValue(entity: any): any|null {
        const propertyName = 'salaryUnit.name';

        let propertyValue = Entity.getValue(entity, propertyName) || Entity.getValueForcePropertyName(entity, propertyName);

        if (!propertyValue) {
            propertyValue = Entity.getValueInEmbedded(entity, propertyName);
        }

        return propertyValue;
    }

    private getBillingUnitNameValue(entity: any): any|null {
        const propertyName = 'billingUnit.name';

        let propertyValue = Entity.getValue(entity, propertyName) || Entity.getValueForcePropertyName(entity, propertyName);

        if (!propertyValue) {
            propertyValue = Entity.getValueInEmbedded(entity, propertyName);
        }

        return propertyValue;
    }

    private showSalaryMonths(form: FormViewerComponent, grid: AbstractGenericGridComponent): boolean {
        return !this.hideSalaryMonths(form, grid);
    }

    private hideSalaryMonths(form: FormViewerComponent, grid: AbstractGenericGridComponent): boolean {
        const salaryUnitNameValue = this.getSalaryUnitNameValue(this.getPreCalculation(form));

        return salaryUnitNameValue === PrecalculationDetailsToggleColumnsVisibilityStep.HOUR;
    }

    private showSalaryHours(form: FormViewerComponent, grid: AbstractGenericGridComponent): boolean {
        return !this.hideSalaryHours(form, grid);
    }

    private hideSalaryHours(form: FormViewerComponent, grid: AbstractGenericGridComponent): boolean {
        const salaryUnitNameValue = this.getSalaryUnitNameValue(this.getPreCalculation(form));

        return salaryUnitNameValue === PrecalculationDetailsToggleColumnsVisibilityStep.MONTH;
    }

    private showInvoiceMonths(form: FormViewerComponent, grid: AbstractGenericGridComponent): boolean {
        return !this.hideInvoiceMonths(form, grid);
    }

    private hideInvoiceMonths(form: FormViewerComponent, grid: AbstractGenericGridComponent): boolean {
        const billingUnitNameValue = this.getBillingUnitNameValue(this.getPreCalculation(form));

        return billingUnitNameValue === PrecalculationDetailsToggleColumnsVisibilityStep.HOUR;
    }

    private showInvoiceHours(form: FormViewerComponent, grid: AbstractGenericGridComponent): boolean {
        return !this.hideInvoiceHours(form, grid);
    }

    private hideInvoiceHours(form: FormViewerComponent, grid: AbstractGenericGridComponent): boolean {
        const billingUnitNameValue = this.getBillingUnitNameValue(this.getPreCalculation(form));

        return billingUnitNameValue === PrecalculationDetailsToggleColumnsVisibilityStep.MONTH;
    }

    private showGlobalFields(form: FormViewerComponent, grid: AbstractGenericGridComponent): boolean {
        return !this.hideGlobalFields(form, grid);
    }

    private hideGlobalFields(form: FormViewerComponent, grid: AbstractGenericGridComponent): boolean {
      const entity = this.getPreCalculation(form);

      return entity && entity.hasCompanyAgreement === true;
    }

    private showFields(grid: AbstractGenericGridComponent, fields: string[] = []): void {
        for (const field of fields) {
            const gridField: FieldMetadataGrid = this.findFieldByString(grid, field);

            if (gridField) {
                gridField.visible = true;

                if (gridField.moduleElementColumn) {
                  gridField.moduleElementColumn.visible = true;
                }
            }
        }
    }

    private hideFields(grid: AbstractGenericGridComponent, fields: string[] = []): void {
        for (const field of fields) {
            const gridField: FieldMetadataGrid = this.findFieldByString(grid, field);

            if (gridField) {
                gridField.visible = false;

              if (gridField.moduleElementColumn) {
                gridField.moduleElementColumn.visible = false;
              }
            }
        }
    }

    private findFieldByString(grid: AbstractGenericGridComponent, fieldName: string): FieldMetadataGrid|null {
        for (const field of grid.fields) {
            if (typeof field.id === 'string' && field.id.indexOf(fieldName) !== -1) {
                return field;
            }

            if (typeof field.name === 'string' && field.name.indexOf(fieldName) !== -1) {
              return field;
            }
        }

        return null;
    }
}
