import { Injectable } from '@angular/core';
import { ToolbarItemDisabledCheckInterface } from './toolbar-item-disabled-check-factory.service';
import {ToolbarItemSaveDisabledCheckService} from './toolbar-item-save-disabled-check.service';
import {ElementContext, ElementType} from '../../../../services/ElementContext';
import {GenericElementAbstract} from '../../../generic-element-abstract.component';
import {WizardComponent} from '../../../custom/wizard/wizard.component';
import {AbstractGenericGridComponent} from '../../../abstract-generic-grid.component';
import {FormViewerComponent} from '../../../../../form-viewer/form-viewer.component';
import {MemoFieldDefinitionValueComponent} from '../../../custom/memo-field-definition-value/memo-field-definition-value.component';

@Injectable()
export class ToolbarItemSavePrecalculationDisabledCheckService extends ToolbarItemSaveDisabledCheckService implements ToolbarItemDisabledCheckInterface {
  isDisabled(): boolean {
    const isDisabled = this.component && this.component.getSelectedEntity() && this.component.getSelectedEntity().isLocked;
    const isSuperDisabled = super.isDisabled();

    return isDisabled || isSuperDisabled;
  }

  protected isDisabledInModuleContext(): boolean {
    const isDisabledInModuleContext = super.isDisabledInModuleContext();
    let isDisabledInWizard = false,
      componentsOnPage = []

    const moduleState = this.modulesStateService.getCurrent();

    if (moduleState) {
      componentsOnPage = moduleState.getAllComponents();
    }

    const wizardComponent: WizardComponent = componentsOnPage.find((aComponent: GenericElementAbstract) => {
      return aComponent.elementContext && aComponent.elementContext.type === ElementType.Wizard
    });

    if (wizardComponent) {
      const componentsWizardOnPage = wizardComponent.wizardService.getCurrentWizardElementDetailsComponents();

      for (const component of componentsWizardOnPage) {
        if (component instanceof AbstractGenericGridComponent) {
          isDisabledInWizard = this.isDisabledInComponentContext(component.selectedEntity, component);
        }

        if (component instanceof FormViewerComponent) {
          isDisabledInWizard = this.isDisabledInComponentContext(component.entity, component);
        }

        if (component instanceof MemoFieldDefinitionValueComponent) {
          isDisabledInWizard = !component.hasChanges();
        }

        if (!isDisabledInWizard) {
          break;
        }
      }
    }

    return isDisabledInModuleContext || isDisabledInWizard;
  }
}
