import { ElementLabel } from './element';
import {Icon} from "../../models/Icon";

export class ElementLabelOutputDatamodelText extends ElementLabel {

  templateText: string;
  icon: Icon;

  constructor() {
    super();
    this.type = 'output';
    this.typeElement = 'datamodel-text';
    this.templateText = '';
    this.isCollectiveAgreementFromLocalStorage = false;
    this.icon = undefined;
  }
}
