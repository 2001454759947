import {AbstractCondition} from '../../../../core/job-runner/condition/abstract.condition';
import {JobContext} from '../../../../core/job-runner/context/job.context';
import {AbstractGenericGridComponent} from '../../../content-renderer/elements/abstract-generic-grid.component';

export class SetupWorkHourPeriodEntryGridComponentCondition extends AbstractCondition {
  isApplicable(context: JobContext): boolean {
    return context.component instanceof AbstractGenericGridComponent
      && context.component.moduleElement
      && context.component.moduleElement.element
      && context.component.moduleElement.element.datamodel
      && context.component.moduleElement.element.datamodel.name === 'PhoenixBundle.WorkHourPeriodEntry'
      && this.hasFilters(context.component);
  }

  hasFilters(component: AbstractGenericGridComponent): boolean {
    const filters = component.gridFilters;
    return typeof filters['leasedEmployee'] !== 'undefined' && filters['leasedEmployee'].value;
  }
}
