import { Injectable } from '@angular/core';
import { ToolbarItem } from "../../../../../services/element/toolbar-item";
import { ToolbarItemDisabledCheckInterface } from "./toolbar-item-disabled-check-factory.service";
import { ToolbarItemAbstractDisabledCheckService } from "./toolbar-item-abstract-disabled-check.service";

@Injectable()
export class ToolbarItemNavigationDisabledCheckService extends ToolbarItemAbstractDisabledCheckService implements ToolbarItemDisabledCheckInterface {

  isDisabled(): boolean {
    return this.isDisabledGlobally() || !((this.toolbarItem.contextTarget === ToolbarItem.CONTEXT_TARGET_MODULE) ? this.isDisabledInModuleContext()
      : this.isDisabledInComponentContext(this.entity, this.component));
  }
}
