import {Injectable} from '@angular/core';
import {GenericElementAbstract} from '../../elements/generic-element-abstract.component';
import {OverlayPanel} from 'primeng/primeng';
import {ContextMenu, ContextMenuActionStep} from '../../../services/element/context-menu';
import {ExecutionStepFactoryService} from '../../../../core/executor/factory/execution-step-factory.service';
import {ExecutorService} from '../../../../core/executor/executor.service';
import {Observable} from 'rxjs';
import {ExecutionStatus} from '../../../../core/executor/execution-status';
import {ExecutionStepPayload} from '../../../../core/executor/execution-step-payload';
import {ExecutionStepParameter} from '../../../../core/executor/execution-step-parameter';
import {ExecutionStepEventParameter} from '../../../../core/executor/execution-step-event-parameter';
import {JsonHelper} from '../../../helpers/json.helper';
import {ChangeDetectorRefHelper} from '../../../helpers/change-detector-ref.helper';

export interface ContextMenuActionMeta {
 contextMenu: ContextMenu;
 component: GenericElementAbstract;
}

@Injectable()
export class GenericElementContextMenuService {

  private executor: ExecutorService = null;

  public constructor(
    private readonly stepFactory: ExecutionStepFactoryService
  ) {

  }

  public showOverlay(event: MouseEvent, overlayPanel: OverlayPanel, component: GenericElementAbstract): void {
    overlayPanel.hide();

    setTimeout(() => {
      overlayPanel.show(event);
      ChangeDetectorRefHelper.detectChanges(component);
    }, 10);

    event.preventDefault();
  }

  public hideOverlay(overlayPanel: OverlayPanel): void {
    overlayPanel.hide();
  }

  public execute(meta: ContextMenuActionMeta): Observable<ExecutionStatus> {
    let payload = null;

    // meh
    switch (meta.contextMenu.action) {
      case ContextMenuActionStep.COMPONENT_REFRESH:
      case ContextMenuActionStep.MASTER_COMPONENT_REFRESH:
      case ContextMenuActionStep.COMPONENT_SAVE:
      case ContextMenuActionStep.COMPONENT_ADD_NEW:
      case ContextMenuActionStep.COMPONENT_OPEN_MODULE:
      case ContextMenuActionStep.WORK_HOUR_CALCULATE_SALARY:
      case ContextMenuActionStep.WORK_HOUR_CALCULATE_WEEKLY:
      case ContextMenuActionStep.GENERATE_INVOICE_BY_WORK_HOUR_PERIOD_ENTRY:
      case ContextMenuActionStep.CALCULATE_WORK_HOUR_ENTRIES:
      case ContextMenuActionStep.DELETE_WORK_HOUR_ENTRIES:
      case ContextMenuActionStep.WORK_HOUR_SET_COST_CENTER:
      case ContextMenuActionStep.WORK_HOUR_OPEN_SUB_ASSIGNMENT_INFO:
      case ContextMenuActionStep.WORK_HOUR_OPEN_SUB_ASSIGNMENT_AND_MONTHLY_INFO:
        payload = meta.component;
        break;
    }

    const step = this.stepFactory.createFromString(meta.contextMenu.action, new ExecutionStepPayload(payload));

    const parameters = [];
    for (const parameter of meta.contextMenu.parameters) {
      const value = JsonHelper.isJsonString(parameter.value) ? JSON.parse(parameter.value) : parameter.value;

      parameters.push(new ExecutionStepParameter(parameter.name, value));
    }
    step.setParameters(parameters);

    return this.executor.setSteps([step]).execute();
  }

  public setExecutor(executor: ExecutorService): this {
    this.executor = executor;
    return this;
  }
}
