
    <div class="ui-g-6">
      <input
        pInputText
        type="text"
        placeholder="---"
        [(ngModel)]="field.value.value"
        (keyup)="onPostalCodeChange($event)"
      />
    </div>
  