/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./toolbar-item-sync-salary-types.component";
import * as i2 from "../../../../../../../services/generic-crud.service";
import * as i3 from "../../../../../../../../core/message/message-growl.service";
import * as i4 from "@ngx-translate/core";
var styles_ToolbarItemSyncSalaryTypesComponent = [];
var RenderType_ToolbarItemSyncSalaryTypesComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_ToolbarItemSyncSalaryTypesComponent, data: {} });
export { RenderType_ToolbarItemSyncSalaryTypesComponent as RenderType_ToolbarItemSyncSalaryTypesComponent };
export function View_ToolbarItemSyncSalaryTypesComponent_0(_l) { return i0.ɵvid(2, [], null, null); }
export function View_ToolbarItemSyncSalaryTypesComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-toolbar-item-sync-salary-types", [], null, null, null, View_ToolbarItemSyncSalaryTypesComponent_0, RenderType_ToolbarItemSyncSalaryTypesComponent)), i0.ɵdid(1, 245760, null, 0, i1.ToolbarItemSyncSalaryTypesComponent, [i2.GenericCrudService, i3.MessageGrowlService, i4.TranslateService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ToolbarItemSyncSalaryTypesComponentNgFactory = i0.ɵccf("app-toolbar-item-sync-salary-types", i1.ToolbarItemSyncSalaryTypesComponent, View_ToolbarItemSyncSalaryTypesComponent_Host_0, { params: "params" }, {}, []);
export { ToolbarItemSyncSalaryTypesComponentNgFactory as ToolbarItemSyncSalaryTypesComponentNgFactory };
