import { Injectable } from '@angular/core';
import {Entity} from '../../../../../helpers/entity';
import {EntityStatus} from '../../../../../services/entity/entity-status';

@Injectable()
export class ConditionTreeService {

  public static isOrConditionBasedOnParent(entity): boolean {
    const parent = Entity.getValueInEmbedded(entity, 'parent');

    return parent && parent.fqn.indexOf('Condition') !== -1;
  }

  public static isCondition(entity): boolean {
    return entity && entity.fqn.indexOf('Condition') !== -1 && entity.fqn.indexOf('OrCondition') === -1;
  }

  public static isOrCondition(entity): boolean {
    return entity && entity.fqn.indexOf('OrCondition') !== -1;
  }

  public static getApiRoute(entity, apiRoute: string): string {
    if (this.isOrConditionBasedOnParent(entity)) {
      apiRoute = apiRoute.replace('conditions', 'conditionorconditions');
    }

    return apiRoute;
  }

  public static getParentFqn(entity): string {
    let fqn = entity.fqn;

    if (this.isOrCondition(entity)) {
      fqn = fqn.replace('OrCondition', '');
    } else {
      fqn = fqn.replace('Condition', '');
    }

    return fqn;
  }

  public static buildCondition(entity: any, parent: any, masterParent: any): any {
    if (ConditionTreeService.isCondition(parent)) {
      entity.fqn += 'OrCondition';
    }

    if (ConditionTreeService.isCondition(entity) && !parent && masterParent) {
      parent = {
        id: masterParent.id ? masterParent.id : masterParent,
        fqn: ConditionTreeService.getParentFqn(entity)
      };
      parent[EntityStatus.ENTITY_CHANGED_FLAG] = true;
    }

    entity._embedded = entity._embedded || {};
    entity.parent = parent;
    entity._embedded.parent = parent;
  }
}
