import {of as observableOf, Observable} from 'rxjs';
import {AbstractExecutionStep} from '../../executor/abstract-execution-step';
import {ExecutionStepStatus} from '../../executor/execution-step-status';
import {GenericDialogModuleService} from '../../../shared/content-renderer/elements/generic-dialog/service/generic-dialog-module.service';

export class HideDialogExecutionStep extends AbstractExecutionStep {

  public doExecute(): Observable<ExecutionStepStatus> {
    this.getGenericDialogModuleService().hideDialog();

    return observableOf({status: true, content: null});
  }

  private getGenericDialogModuleService(): GenericDialogModuleService {
    return this.injector.get(GenericDialogModuleService, null);
  }
}
