<div class="ui-g">
  <div class="ui-g-2">
    <div class="ui-g-4">
      {{ 'LEASED_EMPLOYEE_COSTS.PERIOD' | translate }}:
    </div>

    <div class="ui-g-8">
      <p-dropdown
        (onClick)="loadPeriods()"
        (onChange)="onPeriodChanged($event)"
        placeholder=" --- Bitte wählen --- "
        [options]="periodOptions"
        filter="true"
        [style]="{'width':'100%'}"
      ></p-dropdown>
    </div>
  </div>

  <div class="ui-g-2">
    <div class="ui-g-4">
      {{ 'LEASED_EMPLOYEE_COSTS.BRANCH_OFFICE' | translate }}:
    </div>

    <div class="ui-g-8">
      <p-dropdown
        (onClick)="loadBranchOffices()"
        (onChange)="onBranchOfficeChanged($event)"
        placeholder=" --- Bitte wählen --- "
        [options]="branchOfficeOptions"
        filter="true"
        [style]="{'width':'100%'}"
      ></p-dropdown>
    </div>
  </div>

  <div class="ui-g-12">
    <shared-dynamic-table
      #leasedEmployeeCostsTable
      [columns]="columns"
      [cells]="cells"
      [totalCount]="totalCount"
      [rowsCount]="50"
      [entities]="entities"
      [isLoadingData]="isLoading"
      [showFilters]="false"
      [paginator]="true"
      [height]="gridHeight"
      (onLazyLoad)="onLazyLoad($event)"
    ></shared-dynamic-table>
  </div>
</div>
