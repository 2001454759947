import * as i0 from "@angular/core";
import * as i1 from "./language-crud.service";
import * as i2 from "../../../core/service/user-session.service";
import * as i3 from "../../../core/authentication/authentication.service";
export class LanguageService {
    constructor(languageCrudService, userSession, authentication) {
        this.languageCrudService = languageCrudService;
        this.userSession = userSession;
        this.authentication = authentication;
    }
    selectLanguage(language) {
        this.selectedLanguage = language;
        return this;
    }
    setLocalStorageLanguage(language) {
        this.userSession.set(LanguageService.LOCAL_STORAGE_LANGUAGE_NAME, {
            value: language
        });
        return this;
    }
    getSelectedLanguage() {
        return this.selectedLanguage;
    }
    getLocalStorageLanguage() {
        if (!this.authentication.authenticated()) {
            return 'de';
        }
        return this.userSession.get(LanguageService.LOCAL_STORAGE_LANGUAGE_NAME) || 'de';
    }
    getLanguages() {
        this.languages = this.languageCrudService.getLanguages();
        return this.languages;
    }
    getLanguageById(languageId = 0) {
        return this.languageCrudService.getLanguage(languageId);
    }
}
LanguageService.LOCAL_STORAGE_LANGUAGE_NAME = 'user-lang';
LanguageService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function LanguageService_Factory() { return new LanguageService(i0.ɵɵinject(i1.LanguageCrudService), i0.ɵɵinject(i2.UserSessionService), i0.ɵɵinject(i3.AuthenticationService)); }, token: LanguageService, providedIn: "root" });
