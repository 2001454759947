import {ChangeDetectionStrategy, Component} from '@angular/core';
import {MessageGrowlService} from '../../../../../../../core/message/message-growl.service';
import {GenericCrudService} from '../../../../../../services/generic-crud.service';
import {ToolbarItemAdd} from '../add/toolbar-item-add.component';
import {Module} from '../../../../../../services/module/module';
import {Guid} from 'guid-typescript';
import {GenericDialogModuleService} from '../../../../generic-dialog/service/generic-dialog-module.service';
import {ElementContext} from '../../../../../services/ElementContext';
import {TranslateService} from '@ngx-translate/core';
import {EntityManagerService} from '../../../../../../../core/service/entity-manager/entity-manager.service';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'toolbar-item-add-pre-calculation',
  templateUrl: '../add/toolbar-item-add.component.html'
})
export class ToolbarItemAddPreCalculationComponent extends ToolbarItemAdd {

  public constructor(
    protected messageGrowlService: MessageGrowlService,
    protected genericCrudService: GenericCrudService,
    private genericDialogModuleService: GenericDialogModuleService,
    private translateService: TranslateService,
    private entityManager: EntityManagerService
  ) {
    super(messageGrowlService, genericCrudService);
  }

  public click() {
    const toolbarItemLabel = this.getToolbarItem().label;

    if (this.getToolbarItem().actionModuleId && this.getCustomerId()) {
      this.getToolbarItem().label = this.translateService.instant('COMMON.LOADING') + '...';

      this.genericCrudService.get('superadmin/modules/' + this.getToolbarItem().actionModuleId).subscribe((module: Module) => {
        this.genericCrudService.get('phoenix/customers/' + this.getCustomerId(), {embedded: 'customerCollectiveContract.collectiveAgreement,preCalculationType'}).subscribe((customer) => {
          this.doOpenInDialog(module, customer);

          this.getToolbarItem().label = toolbarItemLabel;
        });
      });
    } else {
      this.messageGrowlService.error('Toolbar Item configuration wrong, Customer or Wizard module missing.');
    }
  }

  protected getCustomerId(): number|null {
    const elementContext: ElementContext = this.getComponent().getElementContext(),
      id = elementContext.getMasterEntityValue('customer');

    return +id || null;
  }

  protected doOpenInDialog(module: Module, masterEntity: any): void {
    const gridComponent = this.getComponent(),
      entity = gridComponent.getSelectedEntity(),
      dialogWidth = 1280,
      dialogHeight = 920,
      emptyEntityFqn = 'PhoenixBundle\\Entity\\PreCalculation',
      emptyRoute = 'phoenix/precalculations';

    this.genericDialogModuleService.showDialog(module, {
      height: dialogHeight,
      width: dialogWidth,
      entity: this.prepareEmptyEntity(emptyEntityFqn, emptyRoute, entity, masterEntity),
      masterEntity: masterEntity,
      masterField: 'customer',
      masterFilterField: 'customer',
      masterFilterValue: masterEntity.id,
      masterElementContext: gridComponent.getElementContext(),
      isAutocompleteModuleState: false,
      maximized: true,
      afterClose: () => {
        gridComponent.onRefresh().subscribe();
      }
    });
  }

  private prepareEmptyEntity(emptyEntityFqn: string, emptyEntityRoute: string, entity: any, masterEntity: any) {
    const emptyEntity = {
      uniqueId: Guid.create().toString(),
      fqn: emptyEntityFqn || null,
      _links: null,
      _embedded: {
        customer: masterEntity,
        collectiveAgreement: typeof masterEntity['customerCollectiveContract'] !== 'undefined' ? masterEntity['customerCollectiveContract']['collectiveAgreement'] : null,
        preCalculationType: masterEntity['preCalculationType']
      }
    };

    if (masterEntity) {
      this.entityManager.persist(emptyEntity, {property: 'customer', newValue: masterEntity, force: true });
      if (typeof masterEntity['customerCollectiveContract'] !== 'undefined') {
        this.entityManager.persist(emptyEntity, {property: 'collectiveAgreement', newValue: masterEntity['customerCollectiveContract']['collectiveAgreement'], force: true });
      }
      this.entityManager.persist(emptyEntity, {property: 'preCalculationType', newValue: masterEntity['preCalculationType'], force: true });
    }

    if (entity) {
      this.entityManager.persist(emptyEntity, {property: 'salaryClassification', newValue: entity['assignmentCollectiveAgreementClassification'], force: true });
      this.entityManager.persist(emptyEntity, {property: 'secondarySalaryClassification', newValue: entity['employeeCollectiveAgreementClassification'], force: true });
      this.entityManager.persist(emptyEntity, {property: 'workplace', newValue: entity['workplace'], force: true });
      this.entityManager.persist(emptyEntity, {property: 'workingMode', newValue: entity['workingMode'], force: true });
      this.entityManager.persist(emptyEntity, {property: 'salaryClassification', newValue: entity['assignmentCollectiveAgreementClassification'], force: true });

      emptyEntity['_embedded']['salaryClassification'] = entity['assignmentCollectiveAgreementClassification'];
      emptyEntity['_embedded']['secondarySalaryClassification'] = entity['employeeCollectiveAgreementClassification'];
      emptyEntity['_embedded']['workplace'] = entity['workplace'];
      emptyEntity['_embedded']['workingMode'] = entity['workingMode'];
    }

    if (emptyEntityRoute) {
      emptyEntity._links = {
        self: {
          href: emptyEntityRoute
        }
      };
    }

    return emptyEntity;
  }

}
