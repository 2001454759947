import {JobInterface} from './job.interface';
import {ConditionInterface} from '../condition/condition.interface';

export class ExecutableJob {
  job: JobInterface;
  condition: ConditionInterface;

  constructor(job: JobInterface, condition: ConditionInterface) {
    this.job = job;
    this.condition = condition;
  }
}
