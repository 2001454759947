
    <p-confirmDialog
      [acceptLabel]="confirmationDefaults.acceptLabel"
      [rejectLabel]="confirmationDefaults.rejectLabel"
    ></p-confirmDialog>
    <p-growl
      [value]="growlMessages"
      [sticky]="growlOptions.sticky"
      [life]="growlOptions.life"
    ></p-growl>
  