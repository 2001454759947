import {ElementContext} from '../../content-renderer/services/ElementContext';
import {ToolbarItemOpenModuleUseMasterEntityExecutionStep} from './toolbar-item-open-module-use-master-entity-execution-step';
import {Entity} from '../../helpers/entity';

export class ToolbarItemOpenDistributionKeysValueModuleUseMasterEntityExecutionStep extends ToolbarItemOpenModuleUseMasterEntityExecutionStep {
  protected getMasterFilterField(context: ElementContext) {
    return 'dmsFile';
  }

  protected getMasterFilterValue(context: ElementContext) {
    return Entity.getValue(this.getEntity(context), 'id');
  }
}
