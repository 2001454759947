/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./toolbar-entity-preview.component";
import * as i2 from "../../../../../../services/component-highlight-stack.service";
import * as i3 from "../../../../../../../../core/message/message-growl.service";
import * as i4 from "../../../../../../../../core/message/message.service";
import * as i5 from "../../../../../../../services/generic-crud.service";
import * as i6 from "../../../../../../../services/api.builder.service";
import * as i7 from "@ngx-translate/core";
import * as i8 from "../../../../../../services/entity-data-store.service";
import * as i9 from "../../../../../../services/logger/logger.service";
import * as i10 from "../../../../../../../../core/executor/executor.service";
import * as i11 from "../../../../../../../../core/service/toast.service";
var styles_ToolbarEntityPreviewComponent = [];
var RenderType_ToolbarEntityPreviewComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_ToolbarEntityPreviewComponent, data: {} });
export { RenderType_ToolbarEntityPreviewComponent as RenderType_ToolbarEntityPreviewComponent };
export function View_ToolbarEntityPreviewComponent_0(_l) { return i0.ɵvid(2, [], null, null); }
export function View_ToolbarEntityPreviewComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "toolbar-item-entity-preview", [], null, null, null, View_ToolbarEntityPreviewComponent_0, RenderType_ToolbarEntityPreviewComponent)), i0.ɵdid(1, 245760, null, 0, i1.ToolbarEntityPreviewComponent, [i2.ComponentService, i3.MessageGrowlService, i4.MessageService, i5.GenericCrudService, i6.ApiBuilderService, i7.TranslateService, i8.EntityDataStoreService, i9.LoggerService, i10.ExecutorService, i11.ToastService, i7.TranslateService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ToolbarEntityPreviewComponentNgFactory = i0.ɵccf("toolbar-item-entity-preview", i1.ToolbarEntityPreviewComponent, View_ToolbarEntityPreviewComponent_Host_0, { params: "params" }, {}, []);
export { ToolbarEntityPreviewComponentNgFactory as ToolbarEntityPreviewComponentNgFactory };
