import {catchError, refCount, publishReplay, map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { ElementType } from './element-type';
import { AppHttpService } from '../../../app-http.service';
import {Observable} from 'rxjs/Rx';
import {HttpClient} from '@angular/common/http';

@Injectable()
export class ElementTypeCrudService extends AppHttpService {

    private elementTypesUrl: string = '/elementtypes';
    private url: string = '';

    constructor(private http: HttpClient) {
        super();
        this.url = this.getApiUrl() + this.elementTypesUrl;
    }

    /**
     *
     * @param format
     * @returns {Observable<R>}
     */
    getElementTypes(format = ''): Observable<ElementType[]> {
        const url = this.url + (format ? `/${format}` : '');
        return this.http.get(url).pipe(
            map(this.mapResponseAsJson),
            publishReplay(1),
            refCount(),
            catchError(this.handleError));
    }
}
