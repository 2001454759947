export class ArticleFieldRegistry {
  public static readonly FIELD_NAME_SALARY_HOUR = 'salaryHour';
  public static readonly FIELD_NAME_SALARY_MONTH = 'salaryMonth';
  public static readonly FIELD_NAME_INVOICE_HOUR = 'invoiceHour';
  public static readonly FIELD_NAME_INVOICE_MONTH = 'invoiceMonth';
  public static readonly FIELD_NAME_TARGET_INVOICE_HOUR = 'targetInvoiceHour';
  public static readonly FIELD_NAME_TARGET_INVOICE_MONTH = 'targetInvoiceMonth';
  public static readonly FIELD_NAME_MONTHLY_DIVIDER = 'standardHoursDivider';
  public static readonly FIELD_NAME_FACTOR_TARGET_INVOICE = 'factorTargetInvoice';
  public static readonly FIELD_NAME_FACTOR = 'factor';
  public static readonly FIELD_NAME_AMOUNT = 'amount';
  public static readonly FIELD_NAME_DESCRIPTION = 'description';
  public static readonly FIELD_NAME_IS_INVOICE_MANUALLY_CHANGED = 'isManuallyChangedInvoice';
  public static readonly FIELD_NAME_IS_USING_FACTOR_INCREMENT = 'isUsingFactorIncrement';
  public static readonly FIELD_NAME_IS_ACTIVE = 'isActive';
  public static readonly FIELD_NAME_WEEKLY_HOURS = 'weeklyHours';
  public static readonly FIELD_NAME_IS_INCLUDED_IN_NORMAL_WORKHOURS = 'isIncludedInNormalWorkhours';
  public static readonly FIELD_NAME_IS_OCCASIONAL = 'isOccasional';
  public static readonly FIELD_NAME_ADVANCEMENT_AFTER = 'advancementAfter';
}
