/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./generic-image-viewer.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "../../../../core/executor/executor.service";
import * as i4 from "../../../../core/executor/service/executor-actions/executor-actions.service";
import * as i5 from "../../services/generic/generic-element-validation-execution-steps-factory";
import * as i6 from "../../../../core/executor/factory/execution-step-factory.service";
import * as i7 from "./generic-image-viewer.component";
import * as i8 from "../../services/component-highlight-stack.service";
import * as i9 from "../../services/entity-data-store.service";
import * as i10 from "../../services/modules-state.service";
import * as i11 from "../../../validators/services/entity-validator";
import * as i12 from "../../../services/generic-crud.service";
import * as i13 from "../../../../core/service/user-session.service";
import * as i14 from "../../../services/permission/permission.service";
import * as i15 from "../../services/elements-stack.service";
var styles_GenericImageViewerComponent = [i0.styles];
var RenderType_GenericImageViewerComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_GenericImageViewerComponent, data: {} });
export { RenderType_GenericImageViewerComponent as RenderType_GenericImageViewerComponent };
function View_GenericImageViewerComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "img", [], [[8, "src", 4]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.src; _ck(_v, 1, 0, currVal_0); }); }
export function View_GenericImageViewerComponent_0(_l) { return i1.ɵvid(2, [i1.ɵqud(671088640, 1, { gridContainer: 0 }), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_GenericImageViewerComponent_1)), i1.ɵdid(2, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.src; _ck(_v, 2, 0, currVal_0); }, null); }
export function View_GenericImageViewerComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 16777216, null, null, 3, "app-image-viewer", [], null, [[null, "mouseover"], [null, "mouseleave"]], function (_v, en, $event) { var ad = true; if (("mouseover" === en)) {
        var pd_0 = (i1.ɵnov(_v, 3).onMouseOver($event) !== false);
        ad = (pd_0 && ad);
    } if (("mouseleave" === en)) {
        var pd_1 = (i1.ɵnov(_v, 3).onMouseLeave($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, View_GenericImageViewerComponent_0, RenderType_GenericImageViewerComponent)), i1.ɵprd(512, null, i3.ExecutorService, i3.ExecutorService, [i4.ExecutorActionsService]), i1.ɵprd(512, null, i5.GenericElementValidationExecutionStepsFactory, i5.GenericElementValidationExecutionStepsFactory, [i6.ExecutionStepFactoryService]), i1.ɵdid(3, 245760, null, 0, i7.GenericImageViewerComponent, [i8.ComponentService, i1.ViewContainerRef, i9.EntityDataStoreService, i10.ModulesStateService, i3.ExecutorService, i5.GenericElementValidationExecutionStepsFactory, i11.EntityValidator, i12.GenericCrudService, i13.UserSessionService, i14.PermissionService, i1.ElementRef, i1.ChangeDetectorRef, i15.ElementsStackService], null, null)], function (_ck, _v) { _ck(_v, 3, 0); }, null); }
var GenericImageViewerComponentNgFactory = i1.ɵccf("app-image-viewer", i7.GenericImageViewerComponent, View_GenericImageViewerComponent_Host_0, { masterElementContext: "masterElementContext", moduleElement: "moduleElement", element: "element", parentComponent: "parentComponent", isDialog: "isDialog", isPart: "isPart", toolbarItems: "toolbarItems", statusBarItems: "statusBarItems", selectedMasterEntity: "selectedMasterEntity", additional: "additional", entity: "entity" }, { componentInstantiated: "componentInstantiated" }, []);
export { GenericImageViewerComponentNgFactory as GenericImageViewerComponentNgFactory };
