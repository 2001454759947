import { Injectable } from '@angular/core';
import { AppHttpService } from '../../app-http.service';
import { AuthenticationService } from '../../core/authentication/authentication.service';
import { LocalStorageDataService } from './local-storage-data.service';
import { Organisation } from './organisation/organisation';
import { Branch } from './branch/branch';

import {
  HttpParams,
} from '@angular/common/http';
import {Group} from './group/group';
import {UserSessionService} from '../../core/service/user-session.service';

interface FilterMap {
  [key: string]: any;
}

@Injectable()
export class GenericCrudRequestOptions extends AppHttpService {

  private globalFiltersEnabled = true;

  public static readonly SHOW_DELETED = 'showDeleted';
  public static readonly SHOW_EXPIRED = 'showExpired';
  public static readonly IGNORE_BRANCH_OFFICE = 'ignoreBranchOffice';

  constructor(
    private authenticationService: AuthenticationService,
    private localStorage: LocalStorageDataService,
    private userSession: UserSessionService
  ) {
    super();
  }

  public getHttpParams(urlParams?: any): HttpParams {
    let params = new HttpParams();

    // global filters
    if (this.globalFiltersEnabled) {
      params = params.set(GenericCrudRequestOptions.SHOW_DELETED, 'false');
      params = params.set(GenericCrudRequestOptions.SHOW_EXPIRED, 'false');
      params = params.set(GenericCrudRequestOptions.IGNORE_BRANCH_OFFICE, 'false');

      const organisation = this.getGlobalFilterValue(Organisation.LOCAL_STORAGE_NAME);
      if (organisation) {
        params = params.set('organisationId', organisation.id);
      }

      const branch = this.getGlobalFilterValue(Branch.LOCAL_STORAGE_NAME);
      if (branch && branch.id) {
        params = params.set('branchOfficeId', branch.id);
      }

      const group = this.getGlobalFilterValue(Group.LOCAL_STORAGE_NAME);
      if (group && group.id) {
        params = params.set('groupId', group.id);
      }
    }

    // request custom filters
    if (urlParams instanceof Object) {
      Object.keys(urlParams).forEach((param: string) => {
        if (param === GenericCrudRequestOptions.SHOW_EXPIRED && null === urlParams[param]) {
          urlParams[param] = false;
        }

        if (param === GenericCrudRequestOptions.SHOW_DELETED && null === urlParams[param]) {
          urlParams[param] = false;
        }

        if (param === GenericCrudRequestOptions.IGNORE_BRANCH_OFFICE && null === urlParams[param]) {
          urlParams[param] = false;
        }

        params = params.set(param, urlParams[param]);
      });
    }

    return params;
  }

  private getGlobalFilterValue(name: string) {
    let value = null;

    if (this.authenticationService.authenticated()) {
      value = this.userSession.get(name);
    }

    return value;
  }

  public enableGlobalFilters() {
    this.globalFiltersEnabled = true;
    return this;
  }

  public disableGlobalFilters() {
    this.globalFiltersEnabled = false;
    return this;
  }

}
