/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../../../node_modules/primeng/components/autocomplete/autocomplete.ngfactory";
import * as i2 from "primeng/components/autocomplete/autocomplete";
import * as i3 from "@angular/forms";
import * as i4 from "../../directives/autocomplete.directive";
import * as i5 from "./customer-autocomplete.component";
import * as i6 from "../../services/generic-crud.service";
var styles_CustomerAutocompleteComponent = ["p-autocomplete[_ngcontent-%COMP%]     .ui-autocomplete-input {\n      width: calc(100% - 26px); // dropdown button\n    }"];
var RenderType_CustomerAutocompleteComponent = i0.ɵcrt({ encapsulation: 0, styles: styles_CustomerAutocompleteComponent, data: {} });
export { RenderType_CustomerAutocompleteComponent as RenderType_CustomerAutocompleteComponent };
export function View_CustomerAutocompleteComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 7, "p-autoComplete", [["appAutocomplete", ""], ["appendTo", "body"], ["field", "label"]], [[2, "ui-inputwrapper-filled", null], [2, "ui-inputwrapper-focus", null], [2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "ngModelChange"], [null, "onKeyUp"], [null, "completeMethod"], [null, "onDropdownClickNoQuery"], [null, "onSelect"], [null, "keydown"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("keydown" === en)) {
        var pd_0 = (i0.ɵnov(_v, 7).onResize($event) !== false);
        ad = (pd_0 && ad);
    } if (("ngModelChange" === en)) {
        var pd_1 = ((_co.selectedOption = $event) !== false);
        ad = (pd_1 && ad);
    } if (("onKeyUp" === en)) {
        var pd_2 = (_co.onKeyUp($event) !== false);
        ad = (pd_2 && ad);
    } if (("completeMethod" === en)) {
        var pd_3 = (_co.onSearch($event) !== false);
        ad = (pd_3 && ad);
    } if (("onDropdownClickNoQuery" === en)) {
        var pd_4 = (_co.onSearch($event) !== false);
        ad = (pd_4 && ad);
    } if (("onSelect" === en)) {
        var pd_5 = (_co.onCustomerChanged($event) !== false);
        ad = (pd_5 && ad);
    } return ad; }, i1.View_AutoComplete_0, i1.RenderType_AutoComplete)), i0.ɵdid(1, 9879552, null, 1, i2.AutoComplete, [i0.ElementRef, i0.Renderer2, i0.ChangeDetectorRef, i0.IterableDiffers], { placeholder: [0, "placeholder"], appendTo: [1, "appendTo"], field: [2, "field"], dropdown: [3, "dropdown"], suggestions: [4, "suggestions"] }, { completeMethod: "completeMethod", onSelect: "onSelect", onKeyUp: "onKeyUp" }), i0.ɵqud(603979776, 1, { templates: 1 }), i0.ɵprd(1024, null, i3.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i2.AutoComplete]), i0.ɵdid(4, 671744, null, 0, i3.NgModel, [[8, null], [8, null], [8, null], [6, i3.NG_VALUE_ACCESSOR]], { model: [0, "model"] }, { update: "ngModelChange" }), i0.ɵprd(2048, null, i3.NgControl, null, [i3.NgModel]), i0.ɵdid(6, 16384, null, 0, i3.NgControlStatus, [[4, i3.NgControl]], null, null), i0.ɵdid(7, 4210688, null, 0, i4.AutocompleteDirective, [[4, i2.AutoComplete], i0.ElementRef, i0.Renderer], null, { onDropdownClickNoQuery: "onDropdownClickNoQuery" })], function (_ck, _v) { var _co = _v.component; var currVal_9 = "---"; var currVal_10 = "body"; var currVal_11 = "label"; var currVal_12 = true; var currVal_13 = _co.options; _ck(_v, 1, 0, currVal_9, currVal_10, currVal_11, currVal_12, currVal_13); var currVal_14 = _co.selectedOption; _ck(_v, 4, 0, currVal_14); }, function (_ck, _v) { var currVal_0 = i0.ɵnov(_v, 1).filled; var currVal_1 = (i0.ɵnov(_v, 1).focus && !i0.ɵnov(_v, 1).disabled); var currVal_2 = i0.ɵnov(_v, 6).ngClassUntouched; var currVal_3 = i0.ɵnov(_v, 6).ngClassTouched; var currVal_4 = i0.ɵnov(_v, 6).ngClassPristine; var currVal_5 = i0.ɵnov(_v, 6).ngClassDirty; var currVal_6 = i0.ɵnov(_v, 6).ngClassValid; var currVal_7 = i0.ɵnov(_v, 6).ngClassInvalid; var currVal_8 = i0.ɵnov(_v, 6).ngClassPending; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8); }); }
export function View_CustomerAutocompleteComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-customer-autocomplete", [], null, null, null, View_CustomerAutocompleteComponent_0, RenderType_CustomerAutocompleteComponent)), i0.ɵdid(1, 49152, null, 0, i5.CustomerAutocompleteComponent, [i6.GenericCrudService, i0.ChangeDetectorRef], null, null)], null, null); }
var CustomerAutocompleteComponentNgFactory = i0.ɵccf("app-customer-autocomplete", i5.CustomerAutocompleteComponent, View_CustomerAutocompleteComponent_Host_0, { params: "params", apiRoute: "apiRoute", selectedOption: "selectedOption" }, { onCustomerOptionChanged: "onCustomerOptionChanged" }, []);
export { CustomerAutocompleteComponentNgFactory as CustomerAutocompleteComponentNgFactory };
