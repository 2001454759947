import { StatusBarItem } from './status-bar-item';
import { ElementType } from './element-type';
import { ToolbarItem } from './toolbar-item';
import { DataModelFilter } from './data-model-filter';
import { Datamodel } from './../datamodel/datamodel';
import { ToolbarSetItem } from '../toolbar-set/toolbar.set.item';
import { Docuware } from './docuware';
import {Wizard} from './wizard';
import {Questionnaire} from './questionnaire';
import {FilterItem} from './filter-item';
import {ElementAudit} from './element-audit';
import {DataSource} from '../data-source/data-source';
import {ModuleView} from '../module-view/module-view';

interface ObjectWithId {
  id: number;
}

interface EmbeddedEntities {
  elementType?: ObjectWithId;
  docuware?: Docuware;
  datamodel?: Datamodel;
  dataSource?: DataSource;
  moduleView?: ModuleView;
  wizard?: Wizard;
  questionnaire?: Questionnaire;
  toolbarItems?: ToolbarItem[];
  statusBarItems?: StatusBarItem[];
  additionalToolbarItems?: ToolbarSetItem[];
  actions?: any[];
}

export class Element {

  public static readonly SORT_DIRECTION_ASC = 'ASC';
  public static readonly SORT_DIRECTION_DESC = 'DESC';

  id;
  name: string;
  label: string;
  content: string;
  datamodel: Datamodel;
  dataSource: DataSource;
  moduleView: ModuleView;
  datamodelId: number;
  elementType: ElementType;
  elementTypeId: number;
  docuware: Docuware;
  docuwareId: number;
  wizard: Wizard;
  questionnaire: Questionnaire;
  toolbarItems: ToolbarItem[];
  filterItems: FilterItem[];
  leftToolbarItems: ToolbarItem[];
  rightToolbarItems: ToolbarItem[];
  topToolbarItems: ToolbarItem[];
  additionalToolbarItems: ToolbarSetItem[];
  statusBarItems: StatusBarItem[];
  datamodelFilters: DataModelFilter[];
  isLocked: boolean;
  hasQuickLinks: boolean;
  isInlineEditable: number;
  isFilterContainerAlwaysVisible: number;
  autoloadData: boolean;
  isPaginable?: number;
  pageSize?: number;
  isDoubleClickable: number;
  sortField: string;
  sortDirection: string;
  elementSubType: string;
  embedded = 'self';
  contextMenuCount: number;
  mergedStatusBarItems?: any;
  elementAudit?: ElementAudit;
  _embedded?: EmbeddedEntities;

  constructor() {
    this.id = undefined;
    this.name = undefined;
    this.label = undefined;
    this.content = undefined;
    this.datamodel = undefined;
    this.dataSource = undefined;
    this.datamodelId = undefined;
    this.elementType = undefined;
    this.elementTypeId = undefined;
    this.docuware = undefined;
    this.docuwareId = undefined;
    this.wizard = undefined;
    this.questionnaire = undefined;
    this.toolbarItems = undefined;
    this.filterItems = undefined;
    this.leftToolbarItems = undefined;
    this.rightToolbarItems = undefined;
    this.topToolbarItems = undefined;
    this.additionalToolbarItems = undefined;
    this.statusBarItems = undefined;
    this.datamodelFilters = undefined;
    this.isInlineEditable = undefined;
    this.isFilterContainerAlwaysVisible = undefined;
    this.autoloadData = true;
    this.isPaginable = undefined;
    this.pageSize = undefined;
    this.isDoubleClickable = undefined;
    this.sortField = null;
    this.sortDirection = Element.SORT_DIRECTION_ASC;
    this.elementSubType = '';
    this.elementAudit = undefined;
    this._embedded = undefined;
  }
}
