import { Component } from '@angular/core';
import { FieldActionAbstractComponent } from '../field-action-abstract.component';
import { GenericCrudService } from '../../../../../services/generic-crud.service';
import { DoubleClickService } from '../../../../services/double-click.service';
import { ModuleElement } from '../../../../../services/module/module-element';
import { GenericDialogModuleService } from '../../../generic-dialog/service/generic-dialog-module.service';
import { Module } from '../../../../../services/module/module';
import { GenericElementAbstract } from '../../../generic-element-abstract.component';
import { ExecutionStepPayload } from '../../../../../../core/executor/execution-step-payload';
import { ExecutionStep } from 'app/core/executor/execution-step';
import { ExecutorRegistry } from '../../../../../../core/executor/service/executor.registry';
import { ExecutionStepFactoryService } from '../../../../../../core/executor/factory/execution-step-factory.service';
import { ExecutionStatus } from '../../../../../../core/executor/execution-status';

@Component({
  selector: 'field-action-open-module',
  template: ''
})
export class FieldActionOpenModuleComponent extends FieldActionAbstractComponent {

  protected executorRegistry: ExecutorRegistry = new ExecutorRegistry();

  public constructor(
    protected genericCrudService: GenericCrudService,
    protected doubleClickService: DoubleClickService,
    protected genericDialogModuleService: GenericDialogModuleService,
    protected executionStepFactoryService: ExecutionStepFactoryService
  ) {
    super();
  }

  public fire() {
      const gridComponent = this.getGrid(),
        entity = gridComponent.getSelectedEntity();

    let moduleId = this.getActionParamValue('moduleId'),
      target = this.getActionParamValue('target') || ModuleElement.DOUBLE_CLICK_TARGET_NEW_TAB;

    if (null !== moduleId) {
      if (target === ModuleElement.DOUBLE_CLICK_TARGET_DIALOG) {
        this.openInGenericDialog(moduleId);
      } else {
        this.doubleClickService.handleOpenModule(
          gridComponent,
          target,
          moduleId,
          entity
        );
      }
    }
  }

  protected openInGenericDialog(moduleId: number): void {
    this.genericCrudService
      .getEntities(`superadmin/modules/${moduleId}`)
      .subscribe((aModule: Module) => {
        this.doOpenInDialog(aModule);
      });
  }

  protected doOpenInDialog(module: Module): void {
    const isAutocompleteModuleState = this.getActionParamValue('isAutocompleteView') || false,
      onCloseStep = this.getActionParamValue('onClose') || null;

    this.genericDialogModuleService.showDialog(module, {
      height: this.getActionParamValue('height') || 920,
      width: this.getActionParamValue('width') || 1280,
      maximized: this.getActionParamValue('maximized') || false,
      isAutocompleteModuleState: isAutocompleteModuleState,
      onClose: (closeDialogCallerComponent: GenericElementAbstract) => {

        if (closeDialogCallerComponent && onCloseStep) {
          this.executeOnCloseStep(onCloseStep, closeDialogCallerComponent);
        }

        return true;
      }
    });
  }

  protected executeOnCloseStep(onCloseStep: string, closeDialogCallerComponent: GenericElementAbstract): void {
    const executorService = closeDialogCallerComponent.getExecutor(),
      executionStep = this.executionStepFactoryService.createFromString(onCloseStep, new ExecutionStepPayload({
        fromComponent: closeDialogCallerComponent,
        toComponent: this.getGrid(),
        field: this.getField()
      }));

    executorService
      .resetSteps()
      .addStep(executionStep)
      .execute()
      .subscribe((status: ExecutionStatus) => {
        this.grid.recheckToolbarItems();
      });
  }
}
