import {Element} from './element';
import {EntityStatus} from '../entity/entity-status';

export class ContextMenuActionStep {
  public static COMPONENT_REFRESH = 'ComponentRefreshExecutionStep';
  public static MASTER_COMPONENT_REFRESH = 'MasterComponentRefreshExecutionStep';
  public static COMPONENT_SAVE = 'ComponentSaveExecutionStep';
  public static COMPONENT_ADD_NEW = 'ComponentAddNewEntityExecutionStep';
  public static COMPONENT_OPEN_MODULE = 'OpenModuleExecutionStep';
  public static WORK_HOUR_CALCULATE_SALARY = 'ContextMenuWorkHourCalculateSalaryExecutionStep';
  public static WORK_HOUR_CALCULATE_WEEKLY = 'ContextMenuWorkHourCalculateWeeklyExecutionStep';
  public static GENERATE_INVOICE_BY_WORK_HOUR_PERIOD_ENTRY = 'ContextMenuGenerateInvoiceByWorkHourPeriodEntryExecutionStep';
  public static CALCULATE_WORK_HOUR_ENTRIES = 'WorkHourPeriodCalculateWorkHourEntriesExecutionStep';
  public static DELETE_WORK_HOUR_ENTRIES = 'WorkHourDeleteEntriesExecutionStep';
  public static WORK_HOUR_SET_COST_CENTER = 'ContextMenuWorkHourSetCostCenterExecutionStep';
  public static WORK_HOUR_OPEN_SUB_ASSIGNMENT_INFO = 'ContextMenuWorkHourOpenSubAssignmentInfoExecutionStep';
  public static WORK_HOUR_OPEN_SUB_ASSIGNMENT_AND_MONTHLY_INFO = 'ContextMenuWorkHourOpenMonthlyAndSubAssignmentInfoExecutionStep';
}

export class ContextMenuParameter {
  public id: number;
  public name: string;
  public value: any;
  public contextMenu: ContextMenu = undefined;

  public constructor(
    id: number = null,
    name: string = '',
    value: string = '',
    contextMenu?: ContextMenu
  ) {
    this.id = id;
    this.name = name;
    this.value = value;
    this.contextMenu = contextMenu;
  }
}

export class ContextMenu {
  public id: number;
  public name: string;
  public description: string;
  public action: string;
  public element: Element;
  public parameters: ContextMenuParameter[] = [];

  public constructor(
    id: number = null,
    name: string = '',
    description: string = '',
    action: string = null,
    element: Element = null,
    parameters: ContextMenuParameter[] = []
  ) {
    this.id = id;
    this.name = name;
    this.description = description;
    this.action = action;
    this.element = element;
    this.parameters = parameters;
  }

  public addParameter(parameter: ContextMenuParameter): ContextMenu {
    parameter.contextMenu = this;
    this.parameters.push(parameter);
    return this;
  }

  public getParam(key: string): ContextMenuParameter|null {
    let foundParam = null;

    for (const param of this.parameters) {
      if (param.name === key) {
        foundParam = param;
        break;
      }
    }

    return foundParam;
  }

  public getParamValue(key: string): any|null {
    const param = this.getParam(key);

    return param && param.value ? param.value : null;
  }

  public setParamValue(key: string, value): ContextMenuParameter {
    const param = this.getParam(key);
    if (!param) {
      this.parameters.push(new ContextMenuParameter(null, key, value));
    } else {
      param[EntityStatus.ENTITY_CHANGED_FLAG] = true;
      param.value = value;
    }

    return param;
  }
}
