/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./toolbar-item-work-hour-monthly-grid-selection.component";
import * as i2 from "../../../../../../services/request-caching.service";
import * as i3 from "../../../../../../services/generic-crud.service";
import * as i4 from "../../../../../../../core/executor/executor.service";
import * as i5 from "../../../../../../../core/executor/factory/execution-step-factory.service";
var styles_ToolbarItemWorkHourMonthlyGridSelectionComponent = [];
var RenderType_ToolbarItemWorkHourMonthlyGridSelectionComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_ToolbarItemWorkHourMonthlyGridSelectionComponent, data: {} });
export { RenderType_ToolbarItemWorkHourMonthlyGridSelectionComponent as RenderType_ToolbarItemWorkHourMonthlyGridSelectionComponent };
export function View_ToolbarItemWorkHourMonthlyGridSelectionComponent_0(_l) { return i0.ɵvid(0, [], null, null); }
export function View_ToolbarItemWorkHourMonthlyGridSelectionComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-toolbar-item-work-hour-monthly-grid-selection", [], null, null, null, View_ToolbarItemWorkHourMonthlyGridSelectionComponent_0, RenderType_ToolbarItemWorkHourMonthlyGridSelectionComponent)), i0.ɵdid(1, 245760, null, 0, i1.ToolbarItemWorkHourMonthlyGridSelectionComponent, [i2.RequestCachingService, i3.GenericCrudService, i4.ExecutorService, i5.ExecutionStepFactoryService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ToolbarItemWorkHourMonthlyGridSelectionComponentNgFactory = i0.ɵccf("app-toolbar-item-work-hour-monthly-grid-selection", i1.ToolbarItemWorkHourMonthlyGridSelectionComponent, View_ToolbarItemWorkHourMonthlyGridSelectionComponent_Host_0, { params: "params" }, {}, []);
export { ToolbarItemWorkHourMonthlyGridSelectionComponentNgFactory as ToolbarItemWorkHourMonthlyGridSelectionComponentNgFactory };
