import {ChangeDetectionStrategy, Component} from '@angular/core';
import {ToolbarItemAbstract} from '../toolbar-item-abstract.component';
import {GenericCrudService} from '../../../../../../services/generic-crud.service';
import {MessageGrowlService} from '../../../../../../../core/message/message-growl.service';
import {TranslateService} from '@ngx-translate/core';
import {AbstractGenericGridComponent} from '../../../../abstract-generic-grid.component';
import {FormViewerComponent} from '../../../../../../form-viewer/form-viewer.component';
import {EntityStatus} from '../../../../../../services/entity/entity-status';
import {GenericDialogModuleService} from '../../../../generic-dialog/service/generic-dialog-module.service';
import {TOAST_LIFE_INFINITE, ToastComponentsRegistry, ToastService} from '../../../../../../../core/service/toast.service';
import {ConfirmationService} from 'primeng/primeng';
import {Organisation} from '../../../../../../services/organisation/organisation';

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'app-toolbar-item-run-cronjob',
    template: ''
})
export class ToolbarItemRunCronjobComponent extends ToolbarItemAbstract {

  constructor(
    protected genericCrudService: GenericCrudService,
    protected messageGrowlService: MessageGrowlService,
    protected translate: TranslateService,
    protected genericDialogModuleService: GenericDialogModuleService,
    protected toastService: ToastService,
    protected confirmationService: ConfirmationService
  ) {
    super();
  }

  public click() {
    const component = this.getComponent();

    let entity = null

    if ((component instanceof AbstractGenericGridComponent || component instanceof FormViewerComponent) && component.getSelectedEntity()) {
      entity = component.getSelectedEntity();
    }

    this.doRun(entity);
  }

  protected doRun(entity?: any) {
    this.toastService.custom(ToastComponentsRegistry.PROGRESS_BAR, {
      severity: 'info',
      life: TOAST_LIFE_INFINITE,
      closable: false,
      summary: this.translate.instant('COMMON.LOADING_PLEASE_WAIT')
    });

    const data = {
      id: entity ? entity.id : null,
      entity: entity ? entity : ''
    };
    this.genericCrudService.customPost(`app/commands/run/${this.getToolbarItem().parameter}`, data)
      .subscribe(() => {
        this.toastService.clear(ToastComponentsRegistry.PROGRESS_BAR, 300);
        const component = this.getComponent();
        if (component instanceof FormViewerComponent) {
          this.genericDialogModuleService.persistHide();
        }

        if (component instanceof AbstractGenericGridComponent) {
          this.confirmationService.confirm({
            acceptVisible: true,
            header: this.translate.instant('DIALOG_MESSAGES.CRONJOB_RUN'),
            message: this.translate.instant('DIALOG_MESSAGES.CRONJOB_LOAD'),
            icon: 'fa fa-tasks',
            accept: () => {
              component.loadEntities().subscribe();
              this.messageGrowlService.success(this.translate.instant('COMMON.SUCCESS') + '.');
            },
            reject: () => {
              this.messageGrowlService.success(this.translate.instant('COMMON.SUCCESS') + '.');
            }
          });
        } else {
          this.messageGrowlService.success(this.translate.instant('COMMON.SUCCESS') + '.');
        }
    });
  }
}
