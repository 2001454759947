import {ChangeDetectionStrategy, Component, Input, OnInit} from '@angular/core';
import { ToolbarItemAbstract } from '../toolbar-item-abstract.component';
import { ToolbarItemFormEntityService } from '../../services/toolbar-item-form-entity.service';
import { EntityDataStoreService } from '../../../../../services/entity-data-store.service';
import { GenericElementAbstract } from '../../../../generic-element-abstract.component';

interface TextAware {
  getText();
}

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'toolbar-item-form-counter',
  templateUrl: './toolbar-item-form.component.html',
  providers: [
    ToolbarItemFormEntityService
  ]
})
export class ToolbarItemFormCounter extends ToolbarItemAbstract implements TextAware {

  constructor(
    protected toolbarItemFormEntityService: ToolbarItemFormEntityService
  ) {
    super();
  }

  public click() {

  }

  public getText() {
    if (this.isInCurrentContext(ToolbarItemAbstract.CONTEXT_FORM_VIEWER)) {
      if (this.toolbarItemFormEntityService.isToolbarItemValid(this)) {
        return this.getItemText(this.getParam('formViewerComponent'));
      }
    } else if (this.isInCurrentContext(ToolbarItemAbstract.CONTEXT_GRID)) {
      return this.getGridCounterText(this.getParam('gridComponent'));
    }
  }

  protected getCounterTextFromDataProvider(entityApiRoute, entity, component: GenericElementAbstract) {
    let index = component.getEntityDataStore().getIndex(entityApiRoute, entity) + 1,
      count = component.getEntityDataStore().getCount(entityApiRoute);

    if (index === null || isNaN(index)) {
      return '';
    }

    return `${index}/${count}`;
  }

  protected getGridCounterText(gridComponent) {
    const entity = gridComponent.getSelectedEntity();

    let text = '';

    if (entity) {
      const entityApiRoute = this.toolbarItemFormEntityService.getEntityApiRoute(entity);

      if (entityApiRoute !== '') {
        text = this.getCounterTextFromDataProvider(entityApiRoute, entity, gridComponent);
      }
    }

    return text;
  }

  protected getItemText(formViewer) {
    const entityApiRoute = this.toolbarItemFormEntityService.getEntityApiRoute(formViewer.entity);

    return this.getCounterTextFromDataProvider(entityApiRoute, formViewer.entity, formViewer);
  }

}
