import { ElementInput } from './element';

export class ElementInputImageUpload extends ElementInput {
  constructor() {
    super();
    this.typeElement = 'image-upload';
  }
}


