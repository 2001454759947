import {of as observableOf, Observable} from 'rxjs';
import {AbstractExecutionStep} from '../../../../core/executor/abstract-execution-step';
import {ExecutionStepStatus} from '../../../../core/executor/execution-step-status';
import {AbstractGenericGridComponent} from '../../../content-renderer/elements/abstract-generic-grid.component';
import {ElementsStateService} from '../../../content-renderer/services/elements-state.service';
import {map} from 'rxjs/operators';
import {ElementState} from '../../../content-renderer/services/element-state';
import {ElementType} from '../../../content-renderer/services/ElementContext';
import {cloneDeep} from 'lodash';
import {ChangeDetectorRefHelper} from '../../../helpers/change-detector-ref.helper';

export class SetupWorkHourFiltersExecutionStep extends AbstractExecutionStep {

  public doExecute(): Observable<ExecutionStepStatus> {
    const payload = this.getPayload(),
      component = payload.getValue().component ? payload.getValue().component : payload.getValue();

    if (!(component instanceof AbstractGenericGridComponent)) {
      return this.getFailObservable('You need to pass AbstractGenericGridComponent as Payload value!');
    }

    return this.setupFilters(component);
  }

  private setupFilters(grid: AbstractGenericGridComponent): Observable<ExecutionStepStatus> {
    const state = this.injector.get(ElementsStateService).findById(grid.moduleElement.id) || this.createState(grid);
    const element = this.getOtherElementState(state);

    if (element && element.filters) {
      const { filters } = element;

      state.filters = {
        leasedEmployee: filters.leasedEmployee,
      };

      for (const fieldName of ['workYear', 'year']) {
        if (grid.element.filterItems.find((aFilter) => aFilter.fieldName === fieldName)) {
          state.filters[fieldName] = filters.workYear || filters.year;
        }
      }

      for (const fieldName of ['workMonth', 'month']) {
        if (grid.element.filterItems.find((aFilter) => aFilter.fieldName === fieldName)) {
          state.filters[fieldName] = filters.workMonth || filters.month;
        }
      }

      grid.gridFilters = state.filters;
      grid.componentState = state;
      grid.filterComponent.componentState = cloneDeep(grid.componentState);
      ChangeDetectorRefHelper.detectChanges(grid);

      return grid.loadEntities().pipe(
        map(() => {
          return {status: true, content: null}
        })
      );
    } else if (!state.filters || Object.keys(state.filters).length === 0) {
      const now = new Date();
      const year = now.getFullYear();
      const month = now.getDate() < 15 ? now.getMonth() : now.getMonth() + 1;
      state.filters = {
        'workYear': {
          value: year
        },
        'year': {
          value: year
        },
        'workMonth': {
          value: month,
          labelValue: month
        },
        'month': {
          value: month,
          labelValue: month
        }
      }

      grid.gridFilters = state.filters;
      grid.componentState = state;
      grid.filterComponent.componentState = cloneDeep(grid.componentState);
      ChangeDetectorRefHelper.detectChanges(grid);
    }

    return observableOf({status: true, content: null});
  }

  private getOtherElementState(currentState: ElementState|null) {
    const elements = this.injector.get(ElementsStateService).getAll();

    for (const element of elements) {
      if (element.filters && (element.filters.year && element.filters.month || element.filters.workYear && element.filters.workMonth) && element.filters.leasedEmployee &&
        element.id !== currentState.id
      ) {
        return element;
      }
    }

    return null;
  }

  private createState(grid: AbstractGenericGridComponent): ElementState {
    const componentState =
      new ElementState(grid.moduleElement.id, ElementType.Tree,
        grid.moduleElement, false, Math.floor(grid.currentOffset / grid.defaultPageSize),
        grid.defaultPageSize, grid.gridFilters, grid.selectedEntity, grid.dataLoaded, grid.simpleSearchFilters,
        grid.sortField, grid.sortDirection);

    this.injector.get(ElementsStateService).add(componentState);

    return componentState;
  }
}
