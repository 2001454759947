import {Injectable} from '@angular/core';
import {GenericElementAbstract} from '../../../../../generic-element-abstract.component';
import {Observable} from 'rxjs';
import {ToolbarItemAbstract} from '../../toolbar-item-abstract.component';
import {ModulesStateService} from '../../../../../../services/modules-state.service';
import {LocationService} from '../../../../../../../services/location.service';
import {ExecutorService} from '../../../../../../../../core/executor/executor.service';
import {ExecutionStepFactoryService} from '../../../../../../../../core/executor/factory/execution-step-factory.service';
import {ComponentRefreshExecutionStep} from '../../../../../../../services/execution-step/component-refresh-execution-step';
import {ExecutionStepPayload} from '../../../../../../../../core/executor/execution-step-payload';
import {DetailsViewAfterDeleteExecutionStep} from '../../../../../../../services/execution-step/details-view/details-view-after-delete-execution.step';
import {ExecutionStatus} from '../../../../../../../../core/executor/execution-status';
import {RemoveSelectedEntityStep} from '../../../../../../../services/execution-step/grid/remove-selected-entity-step';

@Injectable()
export class ToolbarItemAfterDeleteService {

  private executorService: ExecutorService = null;

  public constructor(
    private moduleStateService: ModulesStateService,
    private locationService: LocationService,
    private executionStepFactoryService: ExecutionStepFactoryService
  ) {

  }

  public afterDelete(context: any, component: GenericElementAbstract): Observable<ExecutionStatus> {
    const steps = [];

    if (context === ToolbarItemAbstract.CONTEXT_GRID || context === ToolbarItemAbstract.CONTEXT_FORM_VIEWER ||
      context === ToolbarItemAbstract.CONTEXT_TREE
    ) {
      if (context === ToolbarItemAbstract.CONTEXT_GRID || context === ToolbarItemAbstract.CONTEXT_TREE) {
        steps.push(
          this.executionStepFactoryService.create(RemoveSelectedEntityStep,
            new ExecutionStepPayload(component)
          )
        );
      }

      steps.push(
        this.executionStepFactoryService.create(ComponentRefreshExecutionStep,
          new ExecutionStepPayload(component)
        )
      );
    }

    if (context === ToolbarItemAbstract.CONTEXT_MODULE) {
      const currentModuleState = this.moduleStateService.getByComponent(component);

      if (currentModuleState.isDetailsView) {
        steps.push(
          this.executionStepFactoryService.create(DetailsViewAfterDeleteExecutionStep,
            new ExecutionStepPayload(component)
          )
        );
      } else {
        steps.push(
          this.executionStepFactoryService.create(ComponentRefreshExecutionStep,
            new ExecutionStepPayload(component)
          )
        );
      }
    }

    for (const step of steps) {
      this.executorService.addStep(step);
    }

    return this.executorService.execute();
  }

  public setExecutorService(executorService: ExecutorService): this {
    this.executorService = executorService;
    return this;
  }

}
