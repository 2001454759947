import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';


import { AppHttpService } from '../../../app-http.service';
import { GenericCrudService } from '../generic-crud.service';

@Injectable()
export class CacheCrudService extends AppHttpService {

  private cachesUrl: string = 'cache/caches';

  constructor(private genericCrudService: GenericCrudService) {
    super();
  }

  deleteCache(cacheKey: string): Observable<Object> {
    if (!cacheKey) {
      throw new Error('Invalid module id given.');
    }
    return this.genericCrudService.deleteEntity(`${this.cachesUrl}/${cacheKey}`);
  }

  buildCache(): Observable<any> {
    return this.genericCrudService.customPost(`${this.cachesUrl}/build`, {test: 1});
  }
}
