import {Component, Input, OnInit, AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef} from '@angular/core';
import {ElementInputComponent} from './element-input.component';
import {ElementInputSwitch} from '../models';
import {FormService} from '../form.service';
import {FormViewerService} from '../form-viewer.service';
import {TranslateService} from '@ngx-translate/core';
import {FormElementAction} from '../models/form';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-form-element-input-switch',
  template: `
    <div [formGroup]="formGroup">
      <p-inputSwitch
        appSeleniumDirective
        [element]="element"
        [disabled]="isReadOnly()"
        [formControlName]="formControlName"
        onLabel="element.onLabel"
        offLabel="element.offLabel"
        (onChange)="onValueChange($event)"
        [tabIndex]="element.tabIndex"
      ></p-inputSwitch>
    </div>
  `
})
export class ElementInputSwitchComponent extends ElementInputComponent implements OnInit, AfterViewInit {
  @Input() element: ElementInputSwitch;

  constructor(
    protected formService: FormService,
    public cdr: ChangeDetectorRef,
    protected formViewerService: FormViewerService,
    protected translateService?: TranslateService
  ) {
    super(formService, cdr, formViewerService, translateService);
  }

  onComponentInit() {
    this.setFormControlName();
    this.filterActionAndHandleIt('oninit');

    // now let's see how to do this:
    if (this.element) {
        if (this.element.value) {
          this.onActionSelect();
        } else {
          this.onActionUnselect();
        }
    }
  }

  ngAfterViewInit() {
  }

  onFormElementAction(action: FormElementAction) {

  }

  isReadOnly() {
    return this.element.readOnly || !this.element.isGranted || this.element.disabled;
  }

  onValueChange(event) {
    const isChecked = event.checked;

    this.setValue(isChecked);
    this.filterActionAndHandleIt('onInputValueChanged');
  }
}
