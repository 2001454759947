/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./toolbar-item-ca-workhour-flatten.component";
import * as i2 from "../../../../../../../../core/message/message-growl.service";
import * as i3 from "../../../../../../../services/generic-crud.service";
import * as i4 from "../../../../../../../../core/executor/factory/execution-step-factory.service";
import * as i5 from "../../../../../../../../core/message/message.service";
import * as i6 from "@ngx-translate/core";
import * as i7 from "../../../../../../../../core/executor/executor.service";
var styles_ToolbarItemCaWorkhourFlattenComponent = [];
var RenderType_ToolbarItemCaWorkhourFlattenComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_ToolbarItemCaWorkhourFlattenComponent, data: {} });
export { RenderType_ToolbarItemCaWorkhourFlattenComponent as RenderType_ToolbarItemCaWorkhourFlattenComponent };
export function View_ToolbarItemCaWorkhourFlattenComponent_0(_l) { return i0.ɵvid(2, [], null, null); }
export function View_ToolbarItemCaWorkhourFlattenComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "toolbar-item-ca-workhour-flatten", [], null, null, null, View_ToolbarItemCaWorkhourFlattenComponent_0, RenderType_ToolbarItemCaWorkhourFlattenComponent)), i0.ɵdid(1, 245760, null, 0, i1.ToolbarItemCaWorkhourFlattenComponent, [i2.MessageGrowlService, i3.GenericCrudService, i4.ExecutionStepFactoryService, i5.MessageService, i6.TranslateService, i7.ExecutorService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ToolbarItemCaWorkhourFlattenComponentNgFactory = i0.ɵccf("toolbar-item-ca-workhour-flatten", i1.ToolbarItemCaWorkhourFlattenComponent, View_ToolbarItemCaWorkhourFlattenComponent_Host_0, { params: "params" }, {}, []);
export { ToolbarItemCaWorkhourFlattenComponentNgFactory as ToolbarItemCaWorkhourFlattenComponentNgFactory };
