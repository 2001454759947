<p-autoComplete
  [componentState]="component.componentState"
  [dropdown]="true"
  [ngModel]="component.gridFilters.assignment && component.gridFilters.assignment.value ? component.gridFilters.assignment.value[0] : null"
  [suggestions]="options"
  [style]="{'width':'100%', 'white-space': 'nowrap'}"
  (onKeyUp)="onFilterKeyUp($event)"
  (onSelect)="onFilter($event)"
  (onUnselect)="onFilter($event)"
  (completeMethod)="onFilterAutocompleteSearch($event)"
  (onDropdownClick)="onFilterAutocompleteSearch($event)"
  appendTo="body"
  field="label"
  gridFilter
  #inputElement
  [column]="{property:filterItem.fieldName}"
  styleClass="ui-column-filter ui-column-filter-autocomplete"
></p-autoComplete>
