import { ExecutionStatus } from './execution-status';

export interface ExecutionStatusErrorAware {
    onError(error: ExecutionStatusError);
}

export class ExecutionStatusError extends ExecutionStatus {

    public isSuccess(): boolean {
        return false;
    }
}
