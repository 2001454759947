import {catchError, refCount, publishReplay} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Product } from './product';
import { AppHttpService } from '../../../app-http.service';
import { GenericCrudService } from '../generic-crud.service';

@Injectable()
export class ProductCrudService extends AppHttpService {

  private productsUrl: string = 'superadmin/products';

  constructor(private genericCrudService: GenericCrudService) {
    super();
  }

  /**
   * @argument {string} format Optional. If not set, then the entities are returned as is.
   *                            Selectable formats: formenu (MenuItem), fortree (TreeNode).
   */
  getProducts(format = ''): Observable<Product[]> {
    const url = this.productsUrl + (format ? `/${format}` : '');
    return this.genericCrudService.get(url).pipe(
      publishReplay(1),
      refCount(),
      catchError(this.handleError));
  }

  /**
   * @argument {number} productId The id of the product to request.
   * @argument {string} format Optional. If not set, then the entities are returned as is.
   *                            Selectable formats: formenu (MenuItem), fortree (TreeNode).
   */
  getProduct(id: number, format = ''): Observable<Product> {
    const url = this.productsUrl + `/${id}` + (format ? `/${format}` : '');
    return this.genericCrudService.get(url).pipe(
      publishReplay(1),
      refCount(),
      catchError(this.handleError));
  }

  getUserDefaultProduct(email): Observable<Product> {
    return this.genericCrudService.get('app/users/defaultproduct/' + email).pipe(
      publishReplay(1),
      refCount(),
      catchError(this.handleError));
  }

  deleteProduct(productId: number): Observable<any> {
    if (productId > 0) {
      return this.genericCrudService.deleteEntity(`${this.productsUrl}/${productId}`).pipe(catchError(this.handleError));
    }
    throw new Error('Invalid product id given.');
  }

  createProduct(product: Product): Observable<Product> {
    if (product) {
      return this.genericCrudService.createEntity(this.productsUrl, product).pipe(
        catchError(this.handleError));
    } else {
      throw new Error('No product given.');
    }
  }

  editProduct(product: Product): Observable<Product> {
    if (product) {
      return this.genericCrudService.editEntity(`${this.productsUrl}/${product.id}`, product).pipe(
        catchError(this.handleError));
    }
    throw new Error('No product given.');
  }
}
