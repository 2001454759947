import {Directive, Self, AfterViewInit, ChangeDetectorRef, Input} from '@angular/core';
import {ColorPicker} from 'primeng/primeng';
import {ChangeDetectorRefHelper} from '../helpers/change-detector-ref.helper';

@Directive({
  selector: '[appColorPicker]'
})
export class ColorPickerDirective implements AfterViewInit {

  private readonly colorPicker: ColorPicker = null;
  @Input() public showOnInit = false;
  @Input() public set value(value: any) {
    this.detectChanges();
  }

  public constructor(@Self() colorPicker: ColorPicker, public cdr: ChangeDetectorRef) {
    this.colorPicker = colorPicker;

    if (!(this.colorPicker instanceof ColorPicker)) {
      console.error('Table works only with p-colorPicker component!');
    }
  }

  public ngAfterViewInit(): void {
    this.detectChanges();

    if (this.showOnInit) {
      this.colorPicker.show();
    }
  }

  private detectChanges(): void {
    setTimeout(() => {
      ChangeDetectorRefHelper.detectChanges(this);
    }, 10);
  }
}
