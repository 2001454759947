<ng-container *ngIf="!element.datamodel">
    <div class="error-container">
        <div class="error-text">
            <div class="error-message">
                <b><span class="text-red">{{element.label}} has no datamodel assigned</span></b>
            </div>
        </div>
    </div>
</ng-container>

<div
    *ngIf="element.datamodel"
    #gridContainer
    (click)="highlight()"
    [class]="isHighlighted() ? 'highlighted grid-container' : 'grid-container'">

    <app-generic-filter *ngIf="isFilterContainerVisible || element.isFilterContainerAlwaysVisible" [component]="this"></app-generic-filter>

    <div *ngIf="element.topToolbarItems && element.topToolbarItems.length > 0" class="header ui-datatable-header ui-widget-header ng-star-inserted buttons-container ng-star-inserted">
        <app-generic-toolbar [params]="getToolbarExtraParams()" [entity]="selectedEntity" [items]="element.topToolbarItems" [additionalItems]="element.additionalToolbarItems"></app-generic-toolbar>
    </div>

    <shared-dynamic-table
        #table
        [columns]="columns"
        [rows]="rows"
        [totalCount]="totalCount"
        [rowsCount]="defaultPageSize"
        [entities]="entities"
        [isLoadingData]="isLoadingData"
        (onFilter)="onFilter($event)"
        (onCellEdit)="onCellEdit($event)"
        (onLazyLoad)="onLazyLoad($event)"
        [selectFirstEntityWhenNothingIsSelected]="moduleElement.selectFirst"
    ></shared-dynamic-table>

    <app-generic-detailed-overview [grid]="this" [element]="element" (close)="onGoBackToTable()" *ngIf="moduleElement.onDoubleClick && moduleElement.onDoubleClick === 'details' && showDetailedOverview"></app-generic-detailed-overview>
</div>
