import { AbstractFormActionHandler } from './abstract-form-action-handler';
import { Injectable } from '@angular/core';
import { DatamodelCrudService } from '../../../services/datamodel/datamodel.crud.service';
import {AuthenticationService} from '../../../../core/authentication/authentication.service';
import {Branch} from '../../../services/branch/branch';
import {UserSessionService} from '../../../../core/service/user-session.service';

@Injectable()
export class SetEntityFieldWithSelectedBranchofficeActionHandler extends AbstractFormActionHandler {

    public constructor(
        private datamodelCrudService: DatamodelCrudService,
        private authenticationService: AuthenticationService,
        private userSession: UserSessionService
    ) {
        super();
    }

    public handleAction(): void {
        if (this.isActionValid()) {
            const branchOffice = this.userSession.get(Branch.LOCAL_STORAGE_NAME);

            this.getEntity()['branchOffice'] = branchOffice;
            this.getEntity()['_embedded']['branchOffice'] = branchOffice;
        }
    }

    private isActionValid(): boolean {
        return this.authenticationService.authenticated() && this.getEntity();
    }
}
