import { Injectable } from '@angular/core';
import { EventRegistry } from './event-registry';
import { EventHandlerService } from './event-handler.service';
import { Event } from 'app/core/events/interfaces/event';
import { AbstractActionHandler } from 'app/core/events/action/abstract-action-handler';

export class EventActionHandlerFactory {

    public constructor(
        private eventHandlerService: EventHandlerService
    ) {

    }
    
    public getActionsHandler(event: Event, additionalActionHandlers: AbstractActionHandler[] = []): AbstractActionHandler[] {
        let actionHandlers = additionalActionHandlers;

        for (let actionHandler of this.eventHandlerService.getRegistry().getActionHandlersForEvent(event)) {
            actionHandler.setEvent(event);
            
            actionHandlers.push(actionHandler);
        }

        return actionHandlers;
    }
}
