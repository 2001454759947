import { Injectable } from '@angular/core';
import {
  HttpErrorResponse
} from '@angular/common/http';

@Injectable()
export class HttpErrorResponseService {

    public getErrors(response: HttpErrorResponse): string[] {
        let error = response.error,
            stringErrors = [];

        if (typeof error === 'string') {
            error = JSON.parse(error);
        }

        const backendError = error['errors']

        /*if (backendError && backendError['message'] && backendError['message'] instanceof Array) {
            stringErrors = [...stringErrors, backendError['message']];
        }*/

        if (backendError instanceof Array) {
            for (let error of backendError) {
                stringErrors = [...stringErrors, error];
            }
        }

        if (backendError instanceof Object) {
          for (const errorKey in backendError) {
            if (backendError.hasOwnProperty(errorKey)) {
              stringErrors = [...stringErrors, backendError[errorKey]];
            }
          }
        }

        return stringErrors;
    }

    public getStacktrace(response: HttpErrorResponse): string[] {
        let stacktrace = [];

        if (response.error && response.error.stacktrace) {

            for (const stacktraceItem of response.error.stacktrace) {
                let error = stacktraceItem.class || stacktraceItem.file;

                if (stacktraceItem.args && stacktraceItem.args instanceof Array) {
                    for (const arg of stacktraceItem.args) {
                        if (typeof arg === 'string') {
                            error += '\n' + arg;
                        }
                    }
                }

                stacktrace = [...stacktrace, error.toString()];
            }

        }

        return stacktrace;
    }

}
