import {ChangeDetectionStrategy, Component} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {ToolbarItemAbstract} from '../toolbar-item-abstract.component';
import {GenericCrudService} from '../../../../../../services/generic-crud.service';
import {MessageGrowlService} from '../../../../../../../core/message/message-growl.service';
import {FormViewerComponent} from '../../../../../../form-viewer/form-viewer.component';
import {EntityDirtyStoreService} from '../../../../../services/entity-dirty-store.service';
import {AbstractGenericGridComponent} from '../../../../abstract-generic-grid.component';

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'app-toolbar-item-reset-pre-calculation-article',
    template: ''
})
export class ToolbarItemResetPreCalculationArticleComponent extends ToolbarItemAbstract {

  constructor(
    protected genericCrudService: GenericCrudService,
    protected messageGrowlService: MessageGrowlService,
    protected translate: TranslateService,
    protected entityDirtyStore: EntityDirtyStoreService
  ) {
    super();
  }

  public click() {
    const component = this.getComponent();

    if (component instanceof FormViewerComponent) {
      const entity = component.getSelectedEntity();

      this.genericCrudService.get('phoenix/precalculations/' + entity.id + '/reset').subscribe((res) => {
        this.messageGrowlService.success(
          this.translate.instant('COMMON.DATA_RESET'),
          this.translate.instant('COMMON.SUCCESS')
        );
        const gridComponent = this.getGeneralArticlesGrid(component);

        if (gridComponent instanceof AbstractGenericGridComponent) {
          gridComponent.loadEntities().subscribe();

          const otherArticlesGrid = this.getOtherArticlesGrid(gridComponent);
          if (otherArticlesGrid instanceof AbstractGenericGridComponent) {
            otherArticlesGrid.loadEntities().subscribe();
          }
        }
      });
    }
  }

  protected getGeneralArticlesGrid(form: FormViewerComponent): AbstractGenericGridComponent {
    return form.getElementContext().getSlaveElementContexts()[0].component;
  }

  protected getOtherArticlesGrid(generalArticlesGrid: AbstractGenericGridComponent): AbstractGenericGridComponent {
    return generalArticlesGrid.getElementContext().getSlaveElementContexts()[0].component;
  }
}
