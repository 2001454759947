import { Injectable } from '@angular/core';
import { GenericGridComponent } from '../generic-grid.component';
import { LocalStorageDataService } from '../../../../services/local-storage-data.service';
import { GenericCrudRequestOptions } from '../../../../services/generic-crud-request-options.service';
import { Router } from '@angular/router';
import { ModulesStateService } from '../../../services/modules-state.service';
import { ContentRendererComponent } from '../../../content-renderer.component';
import { FormViewerComponent } from '../../../../form-viewer/form-viewer.component';
import { GenericTreeGridComponent } from 'app/shared/content-renderer/elements/generic-tree-grid/generic-tree-grid.component';
import { AbstractGenericGridComponent } from '../../abstract-generic-grid.component';
import {UserSessionService} from '../../../../../core/service/user-session.service';
import {AuthenticationService} from '../../../../../core/authentication/authentication.service';
import {Branch} from '../../../../services/branch/branch';


@Injectable()
export class GenericGridGlobalFilterService {

  private grid: AbstractGenericGridComponent = null;
  private form: FormViewerComponent = null;

  constructor(
    private userSession: UserSessionService,
    private modulesStateService: ModulesStateService,
    private authenticationService: AuthenticationService,
    private localStorageDataService: LocalStorageDataService
  ) {
  }

  /**
   * @param filterKey showExpired|showDeleted
   * @param moduleId
   * @param apiRoute
   * @returns Object
   */
  public getGridFilters(apiRoute: string): Object {
    let filters = {},
      moduleId = this.getModuleId();

    filters[GenericCrudRequestOptions.SHOW_DELETED] = this.getGlobalFilterValue(GenericCrudRequestOptions.SHOW_DELETED, moduleId, apiRoute);
    filters[GenericCrudRequestOptions.SHOW_EXPIRED] = this.getGlobalFilterValue(GenericCrudRequestOptions.SHOW_EXPIRED, moduleId, apiRoute);
    filters[GenericCrudRequestOptions.IGNORE_BRANCH_OFFICE] = this.getGlobalFilterValue(GenericCrudRequestOptions.IGNORE_BRANCH_OFFICE, moduleId, apiRoute);

    filters = {...filters, ...this.getDatamodelFilters()};

    return filters;
  }

  private getModuleId(): number {
    let moduleId = this.modulesStateService.getCurrent() !== null ? this.modulesStateService.getCurrent().id : null;

    if (null === moduleId && null !== this.getGrid()) {
      moduleId = this.getGrid().moduleElement.moduleId;
    } else if (null === moduleId && null !== this.getForm()) {
      moduleId = this.getForm().moduleElement.moduleId;
    }

    return moduleId;
  }

  /**
   * @param filterKey showExpired|showDeleted|showWithoutMasterFilter
   */
  public getFilterKeyInModuleContext(filterKey: string): string {
    return `${this.getModuleId()}.${filterKey}`;
  }

  /**
   * @param filterKey showExpired|showDeleted|showWithoutMasterFilter
   */
  public getFilterKeyInGridContext(filterKey: string, apiRoute: string): string {
    return `${this.getModuleId()}.grid.${apiRoute}.${filterKey}`;
  }

  /**
   * @param filterKey showExpired|showDeleted|showWithoutMasterFilter
   */
  public getFilterKeyInFormContext(filterKey: string, apiRoute: string): string {
    return `${this.getModuleId()}.form.${apiRoute}.${filterKey}`;
  }

  /**
   * @description return null if key is not set in localStorage
   * @param filterKey showExpired|showDeleted
   * @param moduleId
   * @returns boolean|null
   */
  private getFilterValueInModuleContext(filterKey: string, moduleId: number): boolean | null {
    return this.userSession.get(this.getFilterKeyInModuleContext(filterKey));
  }

  /**
   * @description return null if key is not set in localStorage
   * @param filterKey showExpired|showDeleted
   * @param moduleId
   * @param apiRoute
   * @returns boolean|null
   */
  private getFilterValueInGridContext(filterKey: string, moduleId: number, apiRoute: string): boolean | null {
    return this.userSession.get(this.getFilterKeyInGridContext(filterKey, apiRoute));
  }

  /**
   * @param filterKey showExpired|showDeleted
   * @param moduleId
   * @param apiRoute
   * @returns boolean|null
   */
  public getGlobalFilterValue(filterKey: string, moduleId: number = null, apiRoute: string): boolean | null {

    if (!moduleId) {
      moduleId = this.getModuleId();
    }

    const moduleFilterValue = this.getFilterValueInModuleContext(filterKey, moduleId),
      gridFilterValue = this.getFilterValueInGridContext(filterKey, moduleId, apiRoute);

    return gridFilterValue !== null ? gridFilterValue : moduleFilterValue;
  }

  public setGrid(grid: AbstractGenericGridComponent): this {
    this.grid = grid;
    return this;
  }

  public getGrid(): AbstractGenericGridComponent | null {
    return this.grid || null;
  }

  public setForm(form: FormViewerComponent): this {
    this.form = form;
    return this;
  }

  public getForm(): FormViewerComponent {
    return this.form;
  }

  private getDatamodelFilters(): any{
    const filters = {};

    if (this.getGrid() && this.getGrid().element && this.getGrid().element.datamodelFilters) {
      for (const filter of this.getGrid().element.datamodelFilters) {
        filters[filter.field] = this.resoleFilterValue(filter.value);
      }
    }

    return filters;
  }

  private resoleFilterValue(value) {
    switch (value) {
      case 'me':
        value = this.authenticationService.currentUser.id;
        break;
      case 'selectedBranchOffice':
        const selectedBranchOffice = this.localStorageDataService.getItem(Branch.LOCAL_STORAGE_NAME);
        value = selectedBranchOffice ? selectedBranchOffice.id : 0;
        break;
    }
    return value;
  }
}
