export class RgbColorAdapter {

  public static adapt(rgb, defaultColor: 'white'|'black'): 'white'|'black' {
    if (rgb.match(/^rgb/)) {
      const a = rgb.match(/^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d+(?:\.\d+)?))?\)$/),
        r = a[1],
        g = a[2],
        b = a[3];

      const hsp = Math.sqrt(
        0.299 * (r * r) +
        0.587 * (g * g) +
        0.114 * (b * b)
      );

      if (hsp > 127.5) {
        return 'black';
      }

      return 'white';
    }

    return defaultColor;
  }

}
