
    <div class="input-element"
      [ngClass]="getElementInputCssClass()"
      [fxLayout]="direction()"
      fxLayoutAlign="start center"
    >
      <label
        [fxFlex]="getLabelWidth()"
        [ngClass]="getLabelCssClass()"
        [ngStyle]="getLabelStyle()"
        appSeleniumDirective
        [element]="element"
        *ngIf="(element.showLabel && element.type !== 'placeholder') || element.type === 'placeholder'"
      >
        <ng-container *ngIf="element.showLabel">
          {{element.label}}
        </ng-container>

        <span class="hidden-content"></span>

        <ng-container *ngIf="checkRequired()"> *</ng-container>
      </label>
      <div *ngIf="insideNgContent"
        class="input-item"
        style="position: relative;"
        [fxFlex]="getInputWidth()"
        [class.input-item-required]="checkRequired()"
        [style.padding]="getInputPadding()"
        [style.align-self]="(element.textOnly ? 'center' : '')"
        [style.height]="getInputHeight()"
        (click)="onClickElement($event)"
      >
        <ng-content></ng-content>
      </div>
    </div>
  