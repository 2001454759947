import {AbstractFormActionHandler} from '../abstract-form-action-handler';
import {Entity} from '../../../../helpers/entity';

export class HandleLeasedEmployeeDrivingLicenceActionHandler extends AbstractFormActionHandler {

  private mapping = {
    'hasDrivingLicenceA': [
      'drivingLicenceAValidTo'
    ],
    'hasDrivingLicenceB': [
      'drivingLicenceBValidTo'
    ],
    'hasDrivingLicenceC': [
      'drivingLicenceCValidTo',
      'drivingLicenceCQualifications',
      'drivingLicenceCQualificationValidTo',
      'drivingLicenceCDriverCard',
      'drivingLicenceCDriverCardValidTo',
      'drivingLicenceCAdr',
      'drivingLicenceCAdrValidTo'
    ],
    'hasDrivingLicenceC1': [
      'drivingLicenceC1ValidTo'
    ],
    'hasDrivingLicenceD': [
      'drivingLicenceDValidTo',
      'drivingLicenceDQualifications',
      'drivingLicenceDQualificationValidTo',
      'drivingLicenceDDriverCard',
      'drivingLicenceDDriverCardValidTo'
    ],
    'hasDrivingLicenceBE': [
      'drivingLicenceBEValidTo'
    ],
    'hasDrivingLicenceCE': [
      'drivingLicenceCEValidTo',
      'drivingLicenceCEQualifications',
      'drivingLicenceCEQualificationValidTo',
      'drivingLicenceCeDriverCard',
      'drivingLicenceCeDriverCardValidTo',
      'drivingLicenceCeAdr',
      'drivingLicenceCeAdrValidTo'
    ],
    'hasDrivingLicenceDE': [
      'drivingLicenceDEValidTo',
      'drivingLicenceDEQualifications',
      'drivingLicenceDEQualificationValidTo',
      'drivingLicenceDeDriverCard',
      'drivingLicenceDeDriverCardValidTo'
    ],
    'hasDrivingLicenceF': [
      'drivingLicenceFValidTo'
    ],
    'hasDrivingLicenceC95': [
      'drivingLicenceC95ValidTo'
    ],
    'hasDrivingLicenceD95': [
      'drivingLicenceD95ValidTo'
    ]
  };

  public handleAction(): void {
    const leasedEmployee = this.getEntity();

    if (this.isValid()) {
      for (const hasLicenceDatamodelField in this.mapping) {
        if (this.mapping.hasOwnProperty(hasLicenceDatamodelField)) {
          if (Entity.getValue(leasedEmployee, hasLicenceDatamodelField) === true) {
            this.showFields(this.mapping[hasLicenceDatamodelField]);
          } else {
            this.hideFields(this.mapping[hasLicenceDatamodelField]);
          }
        }
      }
    }
  }

  private isValid(): boolean {
    return this.getEntity();
  }

  private showFields(datamodelFieldNames: string[] = []): void {
    for (const datamodelFieldName of datamodelFieldNames) {
      const element = this.formService.getElementBy(this.getForm(), 'datamodelField', datamodelFieldName);

      if (element) {
        element.isHidden = false;
        this.markElementForCheck(element);
      }
    }
  }

  private hideFields(datamodelFieldNames: string[] = []): void {
    for (const datamodelFieldName of datamodelFieldNames) {
      const element = this.formService.getElementBy(this.getForm(), 'datamodelField', datamodelFieldName);

      if (element) {
        element.isHidden = true;
        this.markElementForCheck(element);
      }
    }
  }
}
