<div class="container" fxLayout="column" fxLayoutAlign="start stretch">

  <div fxFlex="30px" fxLayout="row">
    <div fxFlex="20">
      Datamodel
    </div>

    <div fxFlex="80">
      <app-datamodel-dropdown
        (onOptionChanged)="onDatamodelOptionChanged($event)"
        [selectedDatamodelOption]="selectedDatamodelOption"
        [forceSelectionOnLoad]="true"
      >
      </app-datamodel-dropdown>
    </div>
  </div>

  <div fxFlex="100" fxLayout="row">
    <div fxFlex="20">
      Display Config
    </div>

    <div fxFlex="80">
      <app-display-config
        [configuration]="this.getParameterValue('displayConfiguration')"
        [datamodel]="this.getParameterValue('datamodel')"
        (onSave)="onDisplayConfigSave($event)"
      ></app-display-config>
    </div>
  </div>

  <div fxFlex="30px">
    <div fxFlex="20">
      Value
    </div>

    <div fxFlex="80">
      <p-autoComplete
        [multiple]="true"
        field="label"
        [(ngModel)]="selectedFieldValue"
        [suggestions]="dropdownOptions"
        (completeMethod)="onCompleteMethod($event)"
        (onSelect)="onAutoCompleteValueSelected($event)"
        (onUnselect)="onAutoCompleteValueUnSelected($event)"
        [dropdown]="true"
        [disabled]="!this.getParameterValue('datamodel')"
        [style]="{'width': '100%'}"
        [size]="30"
        [minLength]="2"
        placeholder="---"
        appendTo="body"
      ></p-autoComplete>
    </div>
  </div>

</div>
