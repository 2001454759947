import {Component, OnInit, ViewChild} from '@angular/core';
import {PermissionService} from '../../../../../services/permission/permission.service';
import {AbstractCustomFilterClassComponent} from '../abstract-custom-filter-class.component';
import {SelectItem} from 'primeng/api';
import {FieldMetadataGrid} from '../../../../../services/module/module-element-field-metadata-grid';
import {EntityDataStoreService} from '../../../../services/entity-data-store.service';
import {GenericCrudService} from '../../../../../services/generic-crud.service';

@Component({
  selector: 'app-workhour-assignment-autocomplete',
  templateUrl: './workhour-assignment-autocomplete.component.html'
})
export class WorkhourAssignmentAutocompleteComponent extends AbstractCustomFilterClassComponent implements OnInit {

  @ViewChild('inputElement', {static: false}) inputElement;

  public options: SelectItem[];

  constructor(
    protected permissionService: PermissionService,
    protected entityDataStore: EntityDataStoreService,
    protected genericCrudService: GenericCrudService
  ) {
    super();
  }

  ngOnInit() {
    this.filterItem.fieldName = this.filterItem.fieldName || 'assignment.code';
  }

  public onFilterKeyUp(event): void {
    if (!event.target.value) {
      delete this.component.gridFilters['assignment'];
    }
  }

  public onFilter(event: any): void {
    const value = event || null;

    this.component.dataLoaded = false;
    this.component.gridFilters['assignment'] = { value: [value], matchMode: '', type: 'autocomplete' };
  }

  public onFilterAutocompleteSearch(event: any): void {
    let field = this.component.fields.find((field) => {
      return field.name === 'assignment.code';
    });

    if (!field) {
      field = {
        id: 'assignment.code',
        name: 'assignment.code',
        visible: true,
        visibleInColumnManagement: true,
        fieldType: 'association'
      };

      field['displayConfig'] = [
        {fieldName: 'name', preChacarter: '', postCharacter: ''}
      ];
    }

    let query = event.query,
      params = {},
      apiRoute = 'phoenix/workhours/assignments/all';

    if(this.component.gridFilters && this.component.gridFilters['workMonth']){
      params['workMonth'] = `${this.component.gridFilters['workMonth'].value}`;
    }
    if(this.component.gridFilters && this.component.gridFilters['workYear']){
      params['workYear'] = `${this.component.gridFilters['workYear'].value}`;
    }
    if(this.component.gridFilters && this.component.gridFilters['leasedEmployee'] && this.component.gridFilters['leasedEmployee'].value &&
      this.component.gridFilters['leasedEmployee'].value[0]
    ){
      params['leasedEmployee'] = `${this.component.gridFilters['leasedEmployee'].value[0].id}`;
    }
    params['search'] = query;

    this.genericCrudService.getEntities(apiRoute, '', params).subscribe((entries) => {
      this.options = [];

      entries.map((entry) => {
        entry.label = FieldMetadataGrid.getOptionLabel(entry, field);

        if (entry.label) {
          this.options.push(entry);
        }
      });

      this.options = [...this.options];
      if(!this.component.autocompleteFilterColumnValues['assignment']) {
        this.component.autocompleteFilterColumnValues['assignment'] = [];
      }
      if(this.options.length === 1){
        this.inputElement.selectItem(this.options[0]);
        this.inputElement.hide();
      }
    });
  }
}
