import {
  Component, ChangeDetectorRef, Input, Output, HostBinding, ElementRef, SimpleChanges,
  ChangeDetectionStrategy, EventEmitter, Renderer, OnDestroy, OnChanges
} from '@angular/core';

import { SplitAreaDirective } from './splitArea.directive';
import { Constants } from '../../constants';

export interface IAreaData {
  component: SplitAreaDirective;
  sizeUser: number | null;
  size: number;
  orderUser: number | null;
  order: number;
  minPixel: number;
  pixelSize: number;
  initialPixelSize: number;
}

interface Point {
  x: number;
  y: number;
}


@Component({
  selector: 'split',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styles: [`
        :host {
            display: flex;
            flex-wrap: nowrap;
            justify-content: flex-start;
        }

        split-gutter {
            flex-grow: 0;
            flex-shrink: 0;
            flex-basis: 10px;
            height: 100%;
            background-color: #eeeeee;
            background-position: 50%;
            background-repeat: no-repeat;
        }
        .sidebar-toggle {
            position: relative;
        }
        .fa {
            cursor: pointer;
            position: absolute;
            top: calc(50% - .5rem);
            z-index: 950;
            opacity: .4;
            padding: 0 .5rem;
            -webkit-transition: opacity .6s, padding .6s, font-size .6s;
            -moz-transition: opacity .6s, padding .6s, font-size .6s;
            -o-transition: opacity .6s, padding .6s, font-size .6s;
            transition:  opacity .6s, padding .6s, font-size .6s;
        }

        .fa-caret-up, .fa-caret-down {
          left: calc(50% - .5rem);
        }

        .fa:hover {
            font-size: 4rem;
            top: calc(50% - 1.5rem);
            opacity: .8;
            padding: 0 1rem;
        }
        .rightL {
            right: -5px;
        }
        .rightR {
            right: 0;
        }
        .leftR {
            left: -5px;
        }
    `],
  template: `
        <ng-content></ng-content>
        <ng-template ngFor let-area [ngForOf]="areas" let-index="index" let-last="last">
            <div class="sidebar-toggle" [style.order]="index+1" *ngIf="area.component.toggleDirection">
                <i class="fa fa-lg" [style.padding]="area.component.visible ? '0' : ''"
                    [ngClass]="{
                        'rightL': (area.component.toggleDirection === collapseDirectionLeft && area.component.visible),
                        'rightR': (area.component.toggleDirection === collapseDirectionRight && !area.component.visible),
                        'leftR': (area.component.toggleDirection === collapseDirectionRight && area.component.visible),
                        'fa-caret-left':
                            (area.component.toggleDirection === collapseDirectionLeft && area.component.visible) ||
                                (area.component.toggleDirection === collapseDirectionRight && !area.component.visible),
                        'fa-caret-right':
                            (area.component.toggleDirection === collapseDirectionRight && area.component.visible) ||
                                (area.component.toggleDirection === collapseDirectionLeft && !area.component.visible),
                        'fa-caret-up':
                            (area.component.toggleDirection === collapseDirectionUp && area.component.visible) ||
                                (area.component.toggleDirection === collapseDirectionDown && !area.component.visible),
                        'fa-caret-down':
                            (area.component.toggleDirection === collapseDirectionDown && area.component.visible) ||
                                (area.component.toggleDirection === collapseDirectionUp && !area.component.visible)
                    }"
                    (click)="togglePanel($event, area.component.toggleDirection, index+1)">
                </i>
            </div>
            <split-gutter *ngIf="last === false && area.component.visible === true && !isLastVisibleArea(area)"
                          [order]="index*2+1"
                          [direction]="direction"
                          [size]="gutterSize"
                          [disabled]="disabled"
                          (mousedown)="startDragging($event, index*2+1)"
                          (touchstart)="startDragging($event, index*2+1)"></split-gutter>
        </ng-template>`,
})
export class SplitComponent implements OnChanges, OnDestroy {
  @Input() direction: string = 'horizontal';
  @Input() toggleDirection: string = null;
  @Input() width: number;
  @Input() height: number;
  @Input() gutterSize: number = 10;
  @Input() disabled: boolean = false;

  public readonly collapseDirectionUp: string = Constants.COLLAPSE_DIRECTION_UP;
  public readonly collapseDirectionDown: string = Constants.COLLAPSE_DIRECTION_DOWN;
  public readonly collapseDirectionLeft: string = Constants.COLLAPSE_DIRECTION_LEFT;
  public readonly collapseDirectionRight: string = Constants.COLLAPSE_DIRECTION_RIGHT; 

  @Output() dragStart = new EventEmitter<Array<number>>(false);
  @Output() dragProgress = new EventEmitter<Array<number>>(false);
  @Output() dragEnd = new EventEmitter<Array<number>>(false);
  @Output() areaCollapsed = new EventEmitter<any>(false);

  @HostBinding('style.flex-direction') get styleFlexDirection() {
    return this.direction === 'horizontal' ? 'row' : 'column';
  }

  @HostBinding('style.width') get styleWidth() {
    return (this.width && !isNaN(this.width) && this.width > 0) ? this.width + 'px' : '100%';
  }

  @HostBinding('style.height') get styleHeight() {
    return (this.height && !isNaN(this.height) && this.height > 0) ? this.height + 'px' : '100%';
  }

  private get visibleAreas(): IAreaData[] {
    return this.areas.filter(a => a.component.visible);
  }

  private get visibleAreasWithPixelSize(): IAreaData[] {
    return this.visibleAreas.filter(a => a.pixelSize !== null && a.pixelSize > 0);
  }

  private get nbGutters(): number {
    return this.visibleAreas.length - 1;
  }

  private minPercent: number = 5;
  public areas: Array<IAreaData> = [];
  private isDragging: boolean = false;
  private containerSize: number = 0;
  private areaASize: number = 0;
  private areaBSize: number = 0;
  private eventsDragFct: Array<Function> = [];

  constructor(private cdRef: ChangeDetectorRef,
    private elementRef: ElementRef,
    private renderer: Renderer) { }

  public ngOnChanges(changes: SimpleChanges) {
    if (changes['gutterSize'] || changes['disabled']) {
      this.refresh();
    }
  }

  public setCollapsedByDefault(): void {
    const areas = this.areas;

    if (areas.length > 0) {
      for (let i = 0; i < areas.length; i++) {
        const area = areas[i];

        if (area.component.toggleDirection && area.component.isCollapsed) {
          this.togglePanel({}, area.component.toggleDirection, i + 1)
        }
      }
    }
  }

  public addArea(component: SplitAreaDirective, orderUser: number | null, sizeUser: number | null, minPixel: number, pixelSize: number = null, initialPixelSize: number = null) {
    this.areas.push({
      component,
      orderUser,
      order: -1,
      sizeUser,
      size: -1,
      minPixel,
      pixelSize,
      initialPixelSize
    });

    this.refresh();
  }

  public updateArea(component: SplitAreaDirective, orderUser: number | null, sizeUser: number | null, minPixel: number) {
    const item = this.areas.find(a => a.component === component);

    if (item) {
      item.orderUser = orderUser;
      item.sizeUser = sizeUser;
      item.minPixel = minPixel;

      this.refresh();
    }
  }

  public removeArea(area: SplitAreaDirective) {
    const item = this.areas.find(a => a.component === area);

    if (item) {
      const index = this.areas.indexOf(item);
      this.areas.splice(index, 1);
      this.areas.forEach((a, i) => a.order = i * 2);

      this.refresh();
    }
  }

  public hideArea(area: SplitAreaDirective) {
    const item = this.areas.find(a => a.component === area);

    if (item) {
      this.refresh();
    }
  }

  public showArea(area: SplitAreaDirective) {
    const item = this.areas.find(a => a.component === area);

    if (item) {
      this.refresh();
    }
  }

  public isLastVisibleArea(area: IAreaData) {
    const visibleAreas = this.visibleAreas;
    return visibleAreas.length > 0 ? area === visibleAreas[visibleAreas.length - 1] : false;
  }

  private refresh() {
    this.stopDragging();

    const visibleAreas = this.visibleAreas;
    const visibleAreasWithPixelSize = this.visibleAreasWithPixelSize;

    // ORDERS: Set css 'order' property depending on user input or added order
    const nbCorrectOrder = this.areas.filter(a => a.orderUser !== null && !isNaN(a.orderUser)).length;
    if (nbCorrectOrder === this.areas.length) {
      this.areas.sort((a, b) => +a.orderUser - +b.orderUser);
    }

    this.areas.forEach((a, i) => {
      a.order = i * 2;
      a.component.setStyle('order', a.order);
    });

    // SIZES: Set css 'flex-basis' property depending on user input or equal sizes
    const totalSize = visibleAreas.map(a => a.sizeUser).reduce((acc, s) => acc + s, 0);
    const nbCorrectSize = visibleAreas.filter(a => a.sizeUser !== null && !isNaN(a.sizeUser) && a.sizeUser >= this.minPercent).length;

    if (totalSize < 99.99 || totalSize > 100.01 || nbCorrectSize !== visibleAreas.length) {
      const size = Number((100 / (visibleAreas.length - visibleAreasWithPixelSize.length)).toFixed(3));
      visibleAreas.forEach(a => a.size = size);
    } else {
      visibleAreas.forEach(a => a.size = Number(a.sizeUser));
    }

    this.refreshStyleSizes();
    this.cdRef.markForCheck();
  }

  private refreshStyleSizes() {
    const visibleAreas = this.visibleAreas;
    const visibleAreasWithPixelSize = this.visibleAreasWithPixelSize;
    const totalGutterSize = this.gutterSize * this.nbGutters;
    const f = totalGutterSize / visibleAreas.length;

    visibleAreas.forEach(function(a) {
      let flexBasis = '';
      if (a.pixelSize !== null && a.pixelSize > 0) {
        flexBasis = `${a.pixelSize}px`;
      } else {
        flexBasis = `calc( ${a.size}% - ${f}px )`; // no areas with fixed pixels existing
        if (visibleAreasWithPixelSize.length > 0) {
          const fixedPixelSum = visibleAreasWithPixelSize.map(a => a.pixelSize).reduce((acc, s) => acc + s, 0);
          flexBasis = `calc((100% - ${fixedPixelSum}px - ${totalGutterSize}px) * ${a.size} / 100)`;
        }
      }
      a.component.setStyle('flex-basis', flexBasis);
    });

  }

  private calculateVisibleAreasSizesToHaveSameFlex() {
    const visibleAreas = this.visibleAreas;
    const totalGutterSize = this.gutterSize * this.nbGutters;
    const f = totalGutterSize / visibleAreas.length;

    const calc = 100 / this.visibleAreas.length;

    visibleAreas.forEach(function(a) {
      let flexBasis = `calc( ${calc}% - ${f}px )`;
        
      a.component.setStyle('flex-basis', flexBasis);
    });
  }

  public startDragging(startEvent: MouseEvent | TouchEvent, gutterOrder: number) {
    startEvent.preventDefault();

    if (this.disabled) {
      return;
    }

    const areaA = this.areas.find(a => a.order === gutterOrder - 1);
    const areaB = this.areas.find(a => a.order === gutterOrder + 1);
    if (!areaA || !areaB) {
      return;
    }

    const prop = (this.direction === 'horizontal') ? 'offsetWidth' : 'offsetHeight';
    this.containerSize = this.elementRef.nativeElement[prop];
    this.areaASize = this.containerSize * areaA.size / 100;
    this.areaBSize = this.containerSize * areaB.size / 100;

    areaA.initialPixelSize = areaA.pixelSize;
    areaB.initialPixelSize = areaB.pixelSize;

    let start: Point;
    if (startEvent instanceof MouseEvent) {
      start = {
        x: startEvent.screenX,
        y: startEvent.screenY
      };
    }
    else if (startEvent instanceof TouchEvent) {
      start = {
        x: startEvent.touches[0].screenX,
        y: startEvent.touches[0].screenY
      };
    }
    else {
      return;
    }

    this.eventsDragFct.push(this.renderer.listenGlobal('document', 'mousemove', e => this.dragEvent(e, start, areaA, areaB)));
    this.eventsDragFct.push(this.renderer.listenGlobal('document', 'touchmove', e => this.dragEvent(e, start, areaA, areaB)));
    this.eventsDragFct.push(this.renderer.listenGlobal('document', 'mouseup', e => this.stopDragging()));
    this.eventsDragFct.push(this.renderer.listenGlobal('document', 'touchend', e => this.stopDragging()));
    this.eventsDragFct.push(this.renderer.listenGlobal('document', 'touchcancel', e => this.stopDragging()));

    areaA.component.lockEvents();
    areaB.component.lockEvents();

    this.isDragging = true;
    this.notify('start');
  }

  private dragEvent(event: MouseEvent | TouchEvent, start: Point, areaA: IAreaData, areaB: IAreaData) {
    if (!this.isDragging) {
      return;
    }

    let end: Point;
    if (event instanceof MouseEvent) {
      end = {
        x: event.screenX,
        y: event.screenY
      };
    }
    else if (event instanceof TouchEvent) {
      end = {
        x: event.touches[0].screenX,
        y: event.touches[0].screenY
      };
    }
    else {
      return;
    }

    this.drag(start, end, areaA, areaB);
  }

  private drag(start: Point, end: Point, areaA: IAreaData, areaB: IAreaData) {
    const offsetPixel = (this.direction === 'horizontal') ? (start.x - end.x) : (start.y - end.y);

    const newSizePixelA = this.areaASize - offsetPixel;
    const newSizePixelB = this.areaBSize + offsetPixel;

    if (newSizePixelA <= areaA.minPixel && newSizePixelB < areaB.minPixel) {
      return;
    }

    let newSizePercentA = newSizePixelA / this.containerSize * 100;
    let newSizePercentB = newSizePixelB / this.containerSize * 100;

    if (newSizePercentA <= this.minPercent) {
      newSizePercentA = this.minPercent;
      newSizePercentB = areaA.size + areaB.size - this.minPercent;
    } else if (newSizePercentB <= this.minPercent) {
      newSizePercentB = this.minPercent;
      newSizePercentA = areaA.size + areaB.size - this.minPercent;
    } else {
      newSizePercentA = Number(newSizePercentA.toFixed(3));
      newSizePercentB = Number((areaA.size + areaB.size - newSizePercentA).toFixed(3));
    }

    if (areaA.pixelSize !== null && areaA.pixelSize > 0) {
      areaA.pixelSize = areaA.initialPixelSize - offsetPixel;
    } else {
      areaB.size = newSizePercentB;
    }

    if (areaB.pixelSize !== null && areaB.pixelSize > 0) {
      areaB.pixelSize = areaB.initialPixelSize + offsetPixel;
    } else {
      areaA.size = newSizePercentA;
    }

    this.refreshStyleSizes();
    this.notify('progress');
  }

  private stopDragging() {
    if (!this.isDragging) {
      return;
    }

    this.areas.forEach(a => a.component.unlockEvents());

    while (this.eventsDragFct.length > 0) {
      const fct = this.eventsDragFct.pop();
      if (fct) {
        fct();
      }
    }

    this.containerSize = 0;
    this.areaASize = 0;
    this.areaBSize = 0;

    this.isDragging = false;
    this.notify('end');
  }

  public togglePanel(event, toggleDirection: string, gutterOrder: number) {
    event.preventDefault;

    if (this.disabled) {
      return;
    }

    const areaA = this.areas.find(a => a.order === gutterOrder - 1);
    const areaB = this.areas.find(a => a.order === gutterOrder + 1);
    if (!areaA || !areaB) {
      return;
    }

    if (toggleDirection === this.collapseDirectionLeft || toggleDirection === this.collapseDirectionUp) {
      if (areaA.component.visible) {
        areaA.component.visible = false;
      } else {
        areaA.component.visible = true;
      }
    }

    if (toggleDirection === this.collapseDirectionRight || toggleDirection === this.collapseDirectionDown) {
      if (areaB.component.visible) {
        areaB.component.visible = false;
      } else {
        areaB.component.visible = true;
      }
    }

    const onlyVisibleAreasWithDefinedPixesSizeLeft = this.visibleAreasWithPixelSize.length === this.visibleAreas.length;
    if (onlyVisibleAreasWithDefinedPixesSizeLeft) {
      this.calculateVisibleAreasSizesToHaveSameFlex();
    }

    this.areaCollapsed.emit();
  }

  private notify(type: string) {
    const data: Array<number> = this.visibleAreas.map(a => a.size);

    switch (type) {
      case 'start':
        return this.dragStart.emit(data);

      case 'progress':
        return this.dragProgress.emit(data);

      case 'end':
        return this.dragEnd.emit(data);
    }
  }

  public ngOnDestroy() {
    this.stopDragging();
  }
}
