
        <ng-content></ng-content>
        <ng-template ngFor let-area [ngForOf]="areas" let-index="index" let-last="last">
            <div class="sidebar-toggle" [style.order]="index+1" *ngIf="area.component.toggleDirection">
                <i class="fa fa-lg" [style.padding]="area.component.visible ? '0' : ''"
                    [ngClass]="{
                        'rightL': (area.component.toggleDirection === collapseDirectionLeft && area.component.visible),
                        'rightR': (area.component.toggleDirection === collapseDirectionRight && !area.component.visible),
                        'leftR': (area.component.toggleDirection === collapseDirectionRight && area.component.visible),
                        'fa-caret-left':
                            (area.component.toggleDirection === collapseDirectionLeft && area.component.visible) ||
                                (area.component.toggleDirection === collapseDirectionRight && !area.component.visible),
                        'fa-caret-right':
                            (area.component.toggleDirection === collapseDirectionRight && area.component.visible) ||
                                (area.component.toggleDirection === collapseDirectionLeft && !area.component.visible),
                        'fa-caret-up':
                            (area.component.toggleDirection === collapseDirectionUp && area.component.visible) ||
                                (area.component.toggleDirection === collapseDirectionDown && !area.component.visible),
                        'fa-caret-down':
                            (area.component.toggleDirection === collapseDirectionDown && area.component.visible) ||
                                (area.component.toggleDirection === collapseDirectionUp && !area.component.visible)
                    }"
                    (click)="togglePanel($event, area.component.toggleDirection, index+1)">
                </i>
            </div>
            <split-gutter *ngIf="last === false && area.component.visible === true && !isLastVisibleArea(area)"
                          [order]="index*2+1"
                          [direction]="direction"
                          [size]="gutterSize"
                          [disabled]="disabled"
                          (mousedown)="startDragging($event, index*2+1)"
                          (touchstart)="startDragging($event, index*2+1)"></split-gutter>
        </ng-template>