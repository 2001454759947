import { Observable } from 'rxjs';
import { AbstractActionHandler } from '../../../../core/events/action/abstract-action-handler';
import { ActionHandlerSubscribeResponse } from '../../../../core/events/interfaces/action-handler-subscribe-response';
import { GenericElementsEvent } from '../event/generic-elements-event';

export abstract class AbstractGenericElementsActionHandler extends AbstractActionHandler {
    
    protected abstract doAction(event: GenericElementsEvent): Observable<ActionHandlerSubscribeResponse>;

    public handleAction(): Observable<ActionHandlerSubscribeResponse> {
        let event = this.getEvent();

        if (event instanceof GenericElementsEvent) {
            return this.doAction(event);
        }

        return this.getEmptyObservable();
    }
}
