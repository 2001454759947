import {ChangeDetectionStrategy, Component} from '@angular/core';
import {MessageGrowlService} from '../../../../../../../core/message/message-growl.service';
import {DoubleClickService} from '../../../../../services/double-click.service';
import {GenericDialogModuleService} from '../../../../generic-dialog/service/generic-dialog-module.service';
import {GenericCrudService} from '../../../../../../services/generic-crud.service';
import {EntityManagerService} from '../../../../../../../core/service/entity-manager/entity-manager.service';
import {ModulesStateService} from '../../../../../services/modules-state.service';
import {ToolbarItemAbstract} from "../toolbar-item-abstract.component";
import {AbstractGenericGridComponent} from "../../../../abstract-generic-grid.component";
import {FormViewerComponent} from "../../../../../../form-viewer/form-viewer.component";
import {
  TOAST_LIFE_INFINITE,
  ToastComponentsRegistry,
  ToastService
} from "../../../../../../../core/service/toast.service";
import {TranslateService} from "@ngx-translate/core";
import {RequestCachingService} from "../../../../../../services/request-caching.service";

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-toolbar-item-storno-invoice',
  template: ''
})
export class ToolbarItemStornoInvoiceComponent extends ToolbarItemAbstract {

  constructor(
    protected messageGrowlService: MessageGrowlService,
    protected doubleClickService: DoubleClickService,
    protected genericDialogModuleService: GenericDialogModuleService,
    protected genericCrudService: GenericCrudService,
    protected entityManager: EntityManagerService,
    protected modulesStateService: ModulesStateService,
    protected translateService: TranslateService,
    protected toastService: ToastService,
    protected requestCachingService: RequestCachingService
  ) {
    super();
  }

  click() {

    const entity = this.getEntity();

    if(entity && entity.fqn === "PhoenixBundle\\Entity\\Invoice"){
      this.doStorno(entity);
    }
  }

  private doStorno(invoice){

    this.toastService.custom(ToastComponentsRegistry.PROGRESS_BAR, {
      severity: 'info',
      life: TOAST_LIFE_INFINITE,
      closable: false,
      summary: this.translateService.instant('COMMON.SAVING_PLEASE_WAIT')
    });
    this.genericCrudService.customPut(`phoenix/invoices/cancel/${invoice.id}`, {}).subscribe(
      entity => {
        this.toastService.clear(ToastComponentsRegistry.PROGRESS_BAR, 300);
        this.messageGrowlService.success('Erfolgreich gespeichert.....');
        const component = this.getComponent();
        if(component instanceof FormViewerComponent){
          component.setEntity(entity);

          const parentGrid = component.getElementContext().masterElementContext.component;
          if (parentGrid instanceof AbstractGenericGridComponent) {

            this.requestCachingService.removeByExpression(parentGrid.getElementDataModelApiRoute());
            parentGrid.loadEntities().subscribe();
          }
        }
        if(component instanceof AbstractGenericGridComponent){
          this.requestCachingService.removeByExpression(component.getElementDataModelApiRoute());
          component.loadEntities().subscribe();
        }
      },
      errorResponse => {
        this.toastService.clear(ToastComponentsRegistry.PROGRESS_BAR, 300);
        this.messageGrowlService.error(errorResponse.error.errors.massage[0]);
      }
    );
  }

}
