
import {of as observableOf,  Observable } from 'rxjs';

import {map} from 'rxjs/operators';
import { BaseSetValueFromDialogStep } from '../../dialog-for-autocomplete/base-set-value-from-dialog-step';
import { FormViewerComponent } from '../../../../form-viewer/form-viewer.component';
import { FieldMetadataGrid } from '../../../module/module-element-field-metadata-grid';
import { ExecutionStepStatus } from '../../../../../core/executor/execution-step-status';
import { ElementInputAutocompleteComponent } from '../../../../form-viewer/element/element-input-autocomplete.component';

export class SetInvoicePossibilityToOfferFromDialogExecutionStep extends BaseSetValueFromDialogStep {

  protected doSetValue(fromComponent: FormViewerComponent, toComponent: any, field: FieldMetadataGrid): Observable<ExecutionStepStatus> {
    const invoicePossibility = fromComponent.entity || null,
      customer = this.getCustomer(toComponent);

    if (toComponent instanceof ElementInputAutocompleteComponent &&
      invoicePossibility !== null &&
      customer !== null
    ) {
      return this.getInvoicePossibility(customer, invoicePossibility).pipe(
        map((savedInvoicePossibility: any) => {
          toComponent.entity.invoicePossibility = savedInvoicePossibility;
          toComponent.entity._embedded = {...toComponent.entity._embedded, ...{
            invoicePossibility: savedInvoicePossibility
          }};

          this.setValueToFormAutocomplete(savedInvoicePossibility, toComponent);
          this.showSuccess();

          return {status: true, content: null};
        }));
    }

    return observableOf({
      status: true,
      content: null
    });
  }

  private getInvoicePossibility(customer: any, invoicePossibility: any): Observable<any> {
    return this.saveInvoicePossibility(customer, invoicePossibility).pipe(
      map((savedInvoicePossibility: any) => {
        return savedInvoicePossibility;
      }));
  }

  private saveInvoicePossibility(customer: any, invoicePossibility: any): Observable<any> {
    invoicePossibility.customer = customer;

    return this.genericCrudService.createEntity('phoenix/invoicepossibilities', invoicePossibility).pipe(
      map((savedInvoicePossibility: any) => {
        return savedInvoicePossibility;
      }));
  }

  private getCustomer(toComponent: any): any|null {
    return toComponent && toComponent.entity ? toComponent.entity.customer : null;
  }
}
