import {Observable} from 'rxjs/Observable';
import {AbstractExecutionStep} from '../../../../core/executor/abstract-execution-step';
import {ExecutionStepStatus} from '../../../../core/executor/execution-step-status';
import {ElementsStackService} from '../../../content-renderer/services/elements-stack.service';
import {ElementContext, ElementType} from '../../../content-renderer/services/ElementContext';
import {EMPTY} from 'rxjs';
import {MemoFieldDefinitionValueComponent} from '../../../content-renderer/elements/custom/memo-field-definition-value/memo-field-definition-value.component';
import {map, switchMap} from 'rxjs/operators';

export class SaveMemoFieldDefinitionValueEntityExecutionStep extends AbstractExecutionStep {

  public doExecute(): Observable<ExecutionStepStatus> {
    const element = this.getMemoFieldDefinitionValueComponent();

    if (element && element.component && element.component.memo) {
      return this.saveMemo(element.component).pipe(
        switchMap(() => {
          return element.component.onSave()
        }),
        map(() => {
          return { status: true, content: null };
        })
      );
    }

    return EMPTY;
  }

  getMemoFieldDefinitionValueComponent(): ElementContext|null {
    return this.injector.get(ElementsStackService).getAll().find((context: ElementContext) => {
      return context.type === ElementType.MemoFieldDefinitionValue
    })
  }

  saveMemo(component: MemoFieldDefinitionValueComponent): Observable<any> {
    if (component.memo.id) {
      return this.genericCrudService.editEntity(
        `${component.configuration.ownerApi}/${component.memo.id}`,
        component.memo
      )
    }

    return this.genericCrudService.createEntity(component.configuration.ownerApi, component.memo);
  }
}
