import { Observable } from 'rxjs';
import { AbstractGenericElementsActionHandler } from './abstract-generic-elements-action-handler';
import { GenericElementsEvent } from '../event/generic-elements-event';
import { ActionHandlerSubscribeResponse } from '../../../../core/events/interfaces/action-handler-subscribe-response';
import { FormViewerComponent } from '../../../form-viewer/form-viewer.component';
import {AbstractGenericGridComponent} from '../../../content-renderer/elements/abstract-generic-grid.component';

export class GenericElementsHaveChangesActionHandler extends AbstractGenericElementsActionHandler {

    public constructor(

    ) {
        super();
    }

    protected doAction(event: GenericElementsEvent): Observable<ActionHandlerSubscribeResponse> {
        let components = event.getComponents(),
            thereAreChanges = false;

        for (let component of components) {

            if (component instanceof AbstractGenericGridComponent) {
                thereAreChanges = component.hasChanges(false);
            }

            if (component instanceof FormViewerComponent) {
                thereAreChanges = component.isEntityDirty();
            }

            if (thereAreChanges) {
                break;
            }
        }

        return this.getObservable(thereAreChanges);
    }

}
