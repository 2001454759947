import { Component, OnInit, Input, ComponentFactoryResolver, ViewContainerRef, ComponentRef } from '@angular/core';
import { AbstractGenericGridComponent } from '../abstract-generic-grid.component';
import { ModuleElementColumn } from '../../../services/module/module-element-column';
import { ModuleElementColumnRegistry } from '../../../services/module/module-element-column.registry';
import { AbstractGridColumnComponent } from '../generic-grid/columns/abstract-grid-column.component';

@Component({
  selector: 'app-generic-column',
  templateUrl: './generic-column.component.html',
  styleUrls: ['./generic-column.component.scss']
})
export class GenericColumnComponent implements OnInit {
  @Input() node: TreeNode = null;

  @Input() entity: any = null;

  @Input() component: AbstractGenericGridComponent = null;

  @Input() column: ModuleElementColumn = null;

  private moduleElementColumnRegistry = new ModuleElementColumnRegistry();

  public constructor(
    private factoryResolver: ComponentFactoryResolver,
    private viewContainerRef: ViewContainerRef
  ) {

  }

  public ngOnInit(): void {
    const configuration = this.moduleElementColumnRegistry.getConfiguration(this.column.columnType);

    if (configuration === null) {
      console.error(`No column configuration with name ${this.column.columnType} found!`);
    }

    const genericColumnRef = this.renderComponent(configuration.columnComponent);

    const genericColumnComponent: AbstractGridColumnComponent = genericColumnRef.instance;

    genericColumnComponent.node = this.node;
    genericColumnComponent.entity = this.entity;
    genericColumnComponent.component = this.component;
    genericColumnComponent.column = this.column;
  }

  public renderComponent(component): ComponentRef<any> {
    const componentFactory = this.factoryResolver.resolveComponentFactory(component);

    return this.viewContainerRef.createComponent(componentFactory);
  }
}
