import {of as observableOf,  Observable } from 'rxjs';
import {Component, OnInit, Input, ViewContainerRef, ChangeDetectionStrategy, ChangeDetectorRef} from '@angular/core';
import { Element } from '../../../services/element/element';
import { GenericCrudService } from '../../../services/generic-crud.service';
import { GenericElementAbstract } from '../generic-element-abstract.component';
import { ComponentService } from '../../services/component-highlight-stack.service';
import { EntityDataStoreService } from '../../services/entity-data-store.service';
import { FieldMetadataGrid } from '../../../services/module/module-element-field-metadata-grid';
import { ModuleElement } from '../../../services/module/module-element';
import { DomSanitizer } from '@angular/platform-browser';
import { LocationService } from '../../../services/location.service';
import { Docuware } from '../../../services/element/docuware';
import { ModulesStateService } from '../../services/modules-state.service';
import { ExecutorService } from 'app/core/executor/executor.service';
import { GenericElementValidationExecutionStepsFactory } from 'app/shared/content-renderer/services/generic/generic-element-validation-execution-steps-factory';
import { EntityValidator, EntityValidatorStatus } from '../../../validators/services/entity-validator';
import {LocalStorageDataService} from '../../../services/local-storage-data.service';
import {PermissionService} from '../../../services/permission/permission.service';
import {ChangeDetectorRefHelper} from '../../../helpers/change-detector-ref.helper';
import {UserSessionService} from '../../../../core/service/user-session.service';

@Component({
  selector: 'app-generic-iframe',
  styles: [`
        ::ng-deep #generic-iframe {
            overflow-y: scroll;
            height: 100%;
            width: 100%;
        }`
  ],
  templateUrl: './generic-iframe.component.html',
  providers: [
    ExecutorService,
    GenericElementValidationExecutionStepsFactory
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class GenericIframeComponent extends GenericElementAbstract implements OnInit {
  @Input() element: Element;
  @Input() fields: Array<FieldMetadataGrid>;
  @Input() toolbarItems: any[] = [];
  @Input() statusBarItems: any[] = [];
  @Input() moduleElement: ModuleElement;
  @Input() masterEntity: any = null;
  @Input() masterField: any = null;
  @Input() isPart: boolean = false;

  readonly: boolean;
  docuware: Docuware = null;
  docuwareOptions: SelectItem[] = [];
  masterEntityId = null;

  protected toolbarContextName = 'iframeComponent';
  public urlConfig = {
    url: null
  };

  constructor(
    protected componentService: ComponentService,
    protected viewContainerRef: ViewContainerRef,
    protected modulesStateService: ModulesStateService,
    protected genericCrudService: GenericCrudService,
    private sanitizer: DomSanitizer,
    private locationService: LocationService,
    protected entityDataStoreService: EntityDataStoreService,
    protected executorService: ExecutorService,
    protected genericElementValidationExecutionStepsFactory: GenericElementValidationExecutionStepsFactory,
    protected entityValidator: EntityValidator,
    protected userSession: UserSessionService,
    protected permissionService: PermissionService,
    public cdr: ChangeDetectorRef
  ) {
    super(componentService, viewContainerRef, entityDataStoreService, modulesStateService, executorService,
    genericElementValidationExecutionStepsFactory, entityValidator, genericCrudService, userSession, permissionService, cdr);
  }

  public ngOnInit(): void {
    super.ngOnInit();

    this.masterEntityId = this.locationService.getParam('id');
    this.loadDocuware(this.element.docuware.id, this.masterEntityId, this.moduleElement.docuwareOnlyRead);

    this.subscriptions.push(
      this.genericCrudService.getEntities('phoenix/docuwares', '', { 'datamodel': this.element.datamodel.id }).subscribe((fields) => {
        this.fillDocuwareOptions(fields);
      })
    );
    this.readonly = true;
  }

  public recheckToolbarItems(): void {
    console.log('not needed');
  }

  public hasChanges(checkEmbedded: boolean = false): boolean {
    return false;
  }

  public getSelectedEntity(): any {
    return this.selectedMasterEntity || null;
  }

  public onSave(): Observable<any> {
    return observableOf(null);
  }

  public onAddDraft(): Observable<any> {
    return observableOf(null);
  }

  public onAfterSave(): Observable<any> {
    return observableOf(null);
  }

  public onChange(): Observable<any> {
    return observableOf(null);
  }

  public doValidate(): Observable<EntityValidatorStatus> {
    return observableOf({
      entity: null,
      isValid: true,
      error: '',
      errorFields: []
    });
  }

  public onRefresh(): Observable<any> {
    return observableOf(null);
  }

  private fillDocuwareOptions(options) {
    this.docuwareOptions = [];

    for (const option of options) {
      if (+option.id === +this.element.docuware.id) {
        this.docuware = option;
      }
      this.docuwareOptions = [...this.docuwareOptions, { label: option.name, value: option }];
    }

  }

  refresh() {
    this.loadDocuware(this.docuware.id, this.masterEntityId, this.moduleElement.docuwareOnlyRead);
  }

  private loadDocuware(docuwareId: number, entityId: number, readonly: number) {
    this.genericCrudService.get('phoenix/docuwares/links/' + docuwareId + '/entity/' + entityId + '/readonly/' + readonly)
      .subscribe(res => {
        if (this.urlConfig.url !== this.sanitizer.bypassSecurityTrustResourceUrl(res)) {
          this.urlConfig.url = this.sanitizer.bypassSecurityTrustResourceUrl(res);
          ChangeDetectorRefHelper.detectChanges(this);
        }
      }, err => {
        console.log('Failed to load url.');
      });
  }


}
