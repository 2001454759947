import { ElementInput } from './element';
import { Icon } from './../../../shared/models/Icon';

export class ElementInputText extends ElementInput {

  placeholder: string;

  icon: Icon;

  // @todo declare type inputFormat
  inputFormat: {
    mask: string,
    number: {
      active: boolean,
      prefix: string,
      suffix: string,
      allowDecimal: boolean,
      decimalLimit: number,
      integerLimit: number,
      requireDecimal: boolean,
      allowNegative: boolean
    },
    prefix: string,
    suffix: string
  };

  constructor() {
    super();
    this.typeElement = 'text';
    this.defaultValue = '';
    this.placeholder = '';
    this.icon = undefined;

    this.inputFormat = {
      mask: '',
      number: {
        active: false,
        prefix: '€',
        suffix: '',
        allowDecimal: false,
        decimalLimit: 2,
        integerLimit: null,
        requireDecimal: false,
        allowNegative: false
      },
      prefix: '',
      suffix: ''
    };
  }
}
