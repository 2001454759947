import { Observable } from 'rxjs';
import { GenericCrudService } from '../../../generic-crud.service';
import { InquiryPositionSalaryType } from './inquiry-position-salary-type';

export class InquiryPositionSalaryTypeDataGenerator {

    private genericCrudService: GenericCrudService = null;

    public setGenericCrudService(genericCrudService: GenericCrudService): this {
        this.genericCrudService = genericCrudService;
        return this;
    }

    public generateData(inquiryPositions: any[], collectiveAgrreementId: number): Observable<TreeNode[]> {
        const inquiryPositionsIds = inquiryPositions.map((inquiryPosition) => { return inquiryPosition.id; });

        return this.genericCrudService.createEntity('phoenix/inquirypositionssalarytypes/generatedata', {
                inquiryPositionsIds: inquiryPositionsIds,
                collectiveAgreement: collectiveAgrreementId
            });
    }

}
