
import { Injectable } from '@angular/core';
import { AbstractFormActionHandler } from '../abstract-form-action-handler';
import { FormService } from '../../../form.service';
import { HandleCountryPhoneActionHandler } from './handle-country-phone-action-handler';

@Injectable()
export class HandleCountryMainPhoneActionHandler extends HandleCountryPhoneActionHandler {

    protected getPhoneCountryCodeDatamodelField(): string {
        return 'mainAddress.phoneCountryCode';
    }

    protected getPhoneAreaCodeDatamodelField(): string {
        return 'mainAddress.phoneAreaCode';
    }

    protected getPhoneNumberDatamodelField(): string {
        return 'mainAddress.phoneNumber';
    }

    protected getCountryDatamodelField(): string {
        return 'mainAddress.country';
    }
}
