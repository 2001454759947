
import {throwError as observableThrowError, Observable} from 'rxjs';

import {map, catchError} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import {ExecutorService} from '../../../../core/executor/executor.service';
import {ExecutionStatus} from '../../../../core/executor/execution-status';
import {SaveExpertSearchExecutionStep} from '../edit/steps/save-expert-search-execution.step';
import {ExecutionStepPayload} from '../../../../core/executor/execution-step-payload';
import {DeleteExpertSearchContainersExecutionStep} from '../edit/steps/delete-expert-search-containers-execution.step';
import {DeleteExpertSearchContainerFieldsExecutionStep} from '../edit/steps/delete-expert-search-container-fields-execution.step';
import {SaveExpertSearchContainersExecutionStep} from '../edit/steps/save-expert-search-containers-execution.step';
import {ExecutionStepFactoryService} from '../../../../core/executor/factory/execution-step-factory.service';
import {ExpertSearch, ExpertSearchField, ExpertSearchFieldsContainer} from '../../../services/expert-search/expert-search';
import {GetExpertSearchExecutionStep} from '../edit/steps/get-expert-search-execution.step';
import {GenericCrudService} from '../../../services/generic-crud.service';
import {Datamodel} from '../../../services/datamodel/datamodel';
import {EntityStatus} from '../../../services/entity/entity-status';

@Injectable()
export class ExpertSearchService {

  public static parseExpertSearches(expertSearches: ExpertSearch[]): ExpertSearch[] {
    const parsed = [];

    for (const expertSearch of expertSearches) {
      expertSearch.containers = expertSearch.containers || [];

      for (const container of expertSearch.containers) {
        container.fields = container.fields || [];

        for (const field of container.fields) {
          field.fieldValue = JSON.parse(field.fieldValue);
          field.parameters = JSON.parse(field.parameters);
        }
      }

      parsed.push(expertSearch);
    }

    return parsed;
  }

  // todo :: really fix it, use some strategy here...
  public static parseForSearch(expertSearch: ExpertSearch): any[] {
    const params = [];

    for (const container of expertSearch.containers) {
      if (!container[EntityStatus.ENTITY_DRAFT_BACKEND_DELETE_FLAG] && !this.areAllFieldsDeleted(container)) {
        for (const field of container.fields) {
          if (!field[EntityStatus.ENTITY_DRAFT_BACKEND_DELETE_FLAG]) {
            const fieldKey = field.fieldKey;
            let value = field.fieldValue;

            if (fieldKey.indexOf('.') !== -1) {
              fieldKey.substring(0, fieldKey.indexOf('.'));
            }

            if (field.typeValue === 'autocomplete') {
              const parsed = JSON.parse(value);

              value = parsed.id;
            }

            if (field.typeValue === 'autocompleteMulti' || (field.typeValue === 'genericGrid' && field.comparator === 'manyIn')) {
              const parsedValues = JSON.parse(value);

              const multiAutocompleteValues = [];
              if (parsedValues instanceof Array) {
                for (const parsedValue of parsedValues) {
                  multiAutocompleteValues.push(parsedValue.id);
                }
              }

              value = multiAutocompleteValues.join(',');
            }

            if (field.typeValue === 'genericGrid' && field.comparator === 'equal') {
              const parsedValues = JSON.parse(value);

              if (parsedValues instanceof Array) {
                for (const parsedValue of parsedValues) {
                  params.push({
                    field: fieldKey,
                    comparator: 'equal',
                    value: parsedValue.id
                  });
                }
              }

              continue;
            }

            params.push({
              field: fieldKey,
              comparator: field.comparator,
              value: value
            });
          }
        }

        params.push({clause: 'orWhere'});
      }
    }

    return params;
  }

  public static getEmbedded(expertSearch: ExpertSearch): any[] {
    const fields = ExpertSearchService.getFields(expertSearch),
      embedded = [];

    for (const field of fields) {
      if (field.typeValue === 'autocomplete' || field.typeValue === 'autocompleteMulti' ||
        field.typeValue === 'dropdown' || field.typeValue === 'genericGrid'
      ) {
        embedded.push(field.fieldKey);
      }
    }

    return embedded;
  }

  public static getFields(expertSearch: ExpertSearch): ExpertSearchField[] {
    const fields = [];

    for (const container of expertSearch.containers) {
      if (!container[EntityStatus.ENTITY_DRAFT_BACKEND_DELETE_FLAG] && !this.areAllFieldsDeleted(container)) {
        for (const field of container.fields) {
          if (!field[EntityStatus.ENTITY_DRAFT_BACKEND_DELETE_FLAG]) {
            fields.push(field);
          }
        }
      }
    }

    return fields;
  }

  private static areAllFieldsDeleted(container: ExpertSearchFieldsContainer): boolean {
    let areAllDeleted = true;

    for (const field of container.fields) {
      if (!field[EntityStatus.ENTITY_DRAFT_BACKEND_DELETE_FLAG]) {
        areAllDeleted = false;
        break;
      }
    }

    return areAllDeleted;
  }

  public constructor(
    private stepFactory: ExecutionStepFactoryService,
    private genericCrudService: GenericCrudService
  ) {

  }

  public save(expertSearch: ExpertSearch): Observable<ExecutionStatus> {
    const executorService = new ExecutorService();

    const steps = [
      this.stepFactory.create(SaveExpertSearchExecutionStep,
        new ExecutionStepPayload({
          expertSearch: expertSearch
        })
      ),
      this.stepFactory.create(DeleteExpertSearchContainersExecutionStep,
        new ExecutionStepPayload({
          expertSearch: expertSearch
        })
      ),
      this.stepFactory.create(DeleteExpertSearchContainerFieldsExecutionStep,
        new ExecutionStepPayload({
          expertSearch: expertSearch
        })
      ),
      this.stepFactory.create(SaveExpertSearchContainersExecutionStep,
        new ExecutionStepPayload({
          expertSearch: expertSearch,
          containers: expertSearch.containers
        })
      ),
      this.stepFactory.create(GetExpertSearchExecutionStep,
        new ExecutionStepPayload({
          expertSearch: expertSearch
        })
      )
    ];

    return executorService.setSteps(steps)
      .execute();
  }

  public load(): Observable<ExpertSearch[]> {
    return this.genericCrudService.getEntities('app/expertsearches').pipe(
      catchError((error) => {

        return observableThrowError(error);
      }),
      map((expertSearches: ExpertSearch[] = []) => {
        return ExpertSearchService.parseExpertSearches(expertSearches);
      }),);
  }

  public loadByDatamodel(datamodel: Datamodel): Observable<ExpertSearch[]> {
    return this.genericCrudService
      .getEntities('app/expertsearches/offset/0/limit/50/orderby/id/asc', '', {
        'embedded': 'datamodel',
        'datamodel': datamodel.id
      }).pipe(
      map((response: {data: ExpertSearch[]}) => {
        this.genericCrudService.extractEmbeddedEntities(response.data);

        return ExpertSearchService.parseExpertSearches(response.data);
      }));
  }

}
