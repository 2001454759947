import {ChangeDetectionStrategy, Component} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {ToolbarItemAbstract} from '../../toolbar-item-abstract.component';
import {GenericCrudService} from '../../../../../../../services/generic-crud.service';
import {MessageGrowlService} from '../../../../../../../../core/message/message-growl.service';
import {AbstractGenericGridComponent} from '../../../../../abstract-generic-grid.component';
import {FormViewerComponent} from '../../../../../../../form-viewer/form-viewer.component';

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'app-toolbar-item-sync-salary-types',
    template: ''
})
export class ToolbarItemSyncSalaryTypesComponent extends ToolbarItemAbstract {

  constructor(
    protected genericCrudService: GenericCrudService,
    protected messageGrowlService: MessageGrowlService,
    protected translate: TranslateService
  ) {
    super();
  }

  public click() {
    const component = this.getComponent();

    if ((component instanceof AbstractGenericGridComponent || component instanceof FormViewerComponent) && component.getSelectedEntity()) {
      this.doSync(component.getSelectedEntity());
    }
  }

  protected doSync(entity) {
    this.genericCrudService.customPut(`phoenix/collectiveagreements/${entity.id}/syncsalarytypes`, {})
      .subscribe((collectiveAgreement) => {
      this.messageGrowlService.success(this.translate.instant('COMMON.SUCCESS') + '.');
    });
  }
}
