import {Directive, EventEmitter, OnInit, Output, Self} from '@angular/core';
import {Calendar} from 'primeng/primeng';
import {CalendarLocaleService} from '../../core/service/locale/calendar-locale.service';

@Directive({
  selector: '[appCalendar]',
  providers: [ CalendarLocaleService ]
})
export class CalendarDirective implements OnInit {

  private calendar: Calendar = null;

  @Output() onInputChangeValid: EventEmitter<Date> = new EventEmitter();

  public constructor(
    @Self() calendar: Calendar,
    private calendarLocaleService: CalendarLocaleService
  ) {
    this.calendar = calendar;

    if (calendar) {
      calendar.locale = this.calendarLocaleService.getLocale();
    }
  }

  public ngOnInit(): void {
    this.calendar.onInput.subscribe((event: any) => {
      if (this.calendar) {
        this.onInput(event);
      }
    });
  }

  public onInput(event: any): void {
    this.onInputChangeValidCheck(event);
  }

  public onInputChangeValidCheck(event: any): void {
    const inputString = event.target.value;

    const date = this.getDate(inputString);

    // skip backspace, space, delete InputEvent codes
    if (date !== null && event.data !== null && event.data !== ' ') {

      if (!this.calendar.showTime) {
        this.onInputChangeValid.emit(date);
      }

      if (this.calendar.showTime && this.getTime(inputString) !== null) {
        const time = this.getTime(inputString);

        date.setHours(time.hour);
        date.setMinutes(time.minute);

        this.onInputChangeValid.emit(date);
      }
    }

    if (this.calendar.timeOnly) {
      let time = null;

      try {
        time = this.calendar.parseTime(inputString);
      } catch (e) {

      } finally {
        if (time !== null) {
          const timeDate = new Date();

          timeDate.setHours(time.hour);
          timeDate.setMinutes(time.minute);

          this.onInputChangeValid.emit(timeDate);
        }
      }
    }
  }

  private getTime(dateString: string): {hour: number, minute: number, second: number} | null {
    let time = null;

    const splitDate = dateString.split(' ');

    if (splitDate[0] && splitDate[1]) {
      try {
        time = this.calendar.parseTime(splitDate[1]);
      } catch (e) {

      }
    }

    return time;
  }

  private getDate(dateString: string): Date|null {
    let date = null;

    try {
      date = this.calendar.parseDate(dateString, this.calendar.dateFormat);
    } catch (e) {

    }

    return date;
  }
}
