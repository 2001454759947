import { Injectable } from '@angular/core';
import { cloneDeep } from 'lodash';
import {DateHelper} from '../helpers/date.helper';

@Injectable()
export class EntityHydrator {

  private propertiesToIgnore: string[] = [
    'permissionInformation'
  ];

  private propertiesToIgnoreForEmptyEntity: string[] = [
    'createdAt', 'createdBy', 'modifiedBy', 'modifiedAt', 'deletedBy', 'deletedAt'
  ];

  constructor() {

  }

  public hydrate(entity: any) {
    if (entity instanceof Object) {
      for (let property in entity) {

        if (this.propertiesToIgnore.includes(property)) {
          delete entity[property];
        }

        if (entity.hasOwnProperty(property) && !(entity[property] instanceof Array)) {
          entity[property] = this.parseValue(entity[property], property);
        }

        if (this.isEmptyEntity(entity)) {
          entity = this.hydrateEmptyEntity(entity, property);
        }
      }

      entity = this.stripEmbedded(entity);
    }

    return entity;
  }

  public bulkHydrate(entities: any[]) {
    let hydrated = [];

    if (!(entities instanceof Array)) {
      hydrated.push(this.hydrate(entities));
    } else {
      for (let entity of entities) {
        hydrated.push(this.hydrate(entity));
      }
    }

    return hydrated;
  }

  /**
   * (GU) :: not sure if this is the correct place for this method :)
   *
   * @description based on tree level field name string get embedded entity value of the current entity
   *
   * @param any entity
   * @param string propertyName - example `customerCollectiveContract.collectiveContract.name`
   * @param boolean force - force fetch of the propertyName
   * @param boolean useEmbedded - take embedded value if exists
   */
  public getEntityPropertyValue(entity: any, propertyName: string, force: boolean = false, useEmbedded: boolean = true): string|Object|any[] {
    if (typeof entity === 'undefined' || entity === null) {
      return null;
    }

    if (propertyName.indexOf('.') === -1 || force) {
      let value = entity[propertyName];

      if ((useEmbedded || !value) && entity._embedded && entity._embedded[propertyName]) {
        value = entity._embedded[propertyName];
      }

      return value;
    }

    let splitParts = propertyName.split('.'),
        valuePart = entity;

      for (let part of splitParts) {
        let propertyPart = valuePart[part],
          entityPart = valuePart && valuePart['_embedded'] ? valuePart['_embedded'][part] : null;

        if (propertyPart) {
          valuePart = propertyPart;
        }

        if ((entityPart && useEmbedded) || !propertyPart) {
          valuePart = entityPart;
        }

        if (!propertyPart && !entityPart) {
          valuePart = null;
          break;
        }
      }

      return valuePart;
  }

  private parseValue(value, property) {
    let parsedValue = value;

    // console.log(parsedValue);

    // let dateValue = typeof parsedValue == 'string' ? DateHelper.parseDate(parsedValue) : parsedValue;

    if (parsedValue instanceof Date && !isNaN(value)) {
      parsedValue = DateHelper.toISOLocal(parsedValue).slice(0, -1); // toIso but remove miliseconds!
    }

    return parsedValue;
  }

  /**
   *
   * @param entity
   * @returns {boolean}
   */
  private isEmptyEntity(entity: any): boolean {
    return entity.fqn && !entity.id;
  }

  /**
   * @description - remove 2. level embedded entities, we never use them in api
   *
   * @param entity
   * @returns {boolean}
   */
  private stripEmbedded(entity: any): any {
    const clonedEntity = cloneDeep(entity);

    const embeddedEntities = clonedEntity._embedded;

    if (embeddedEntities instanceof Object) {
      for (const subEntityProperty in embeddedEntities) {
        const subEntity = embeddedEntities[subEntityProperty];

        if (subEntity && typeof subEntity != 'undefined') {
          delete subEntity._embedded;
        }
      }
    }

    return clonedEntity;
  }

  /**
   * @description set association properties of emptyEntity to null if not defined or if should be ignored
   *
   * @param entity
   * @param property
   * @returns {any}
   */
  private hydrateEmptyEntity(entity: any, property: any): any {
    let isHateoasProperty = property === '_embedded' || property === '_links';

    if (this.propertiesToIgnoreForEmptyEntity.includes(property)) {
      entity[property] = null;
    }

    if (!isHateoasProperty && entity[property] && entity[property].constructor === Object && !entity[property].id) {
      entity[property] = null;
    }

    return entity;
  }

}
