/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./toolbar-item-form-cancel.component";
var styles_ToolbarItemFormCancel = [];
var RenderType_ToolbarItemFormCancel = i0.ɵcrt({ encapsulation: 2, styles: styles_ToolbarItemFormCancel, data: {} });
export { RenderType_ToolbarItemFormCancel as RenderType_ToolbarItemFormCancel };
export function View_ToolbarItemFormCancel_0(_l) { return i0.ɵvid(2, [], null, null); }
export function View_ToolbarItemFormCancel_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "toolbar-item-form-cancel", [], null, null, null, View_ToolbarItemFormCancel_0, RenderType_ToolbarItemFormCancel)), i0.ɵdid(1, 245760, null, 0, i1.ToolbarItemFormCancel, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ToolbarItemFormCancelNgFactory = i0.ɵccf("toolbar-item-form-cancel", i1.ToolbarItemFormCancel, View_ToolbarItemFormCancel_Host_0, { params: "params" }, {}, []);
export { ToolbarItemFormCancelNgFactory as ToolbarItemFormCancelNgFactory };
