import {ChangeDetectionStrategy, Component} from '@angular/core';
import {ToolbarItemAbstract} from '../toolbar-item-abstract.component';
import {ComponentService} from '../../../../../services/component-highlight-stack.service';
import {MessageGrowlService} from '../../../../../../../core/message/message-growl.service';
import {MessageService} from '../../../../../../../core/message/message.service';
import {GenericCrudService} from '../../../../../../services/generic-crud.service';
import {ApiBuilderService} from '../../../../../../services/api.builder.service';
import {EntityDataStoreService} from '../../../../../services/entity-data-store.service';
import {LoggerService} from '../../../../../services/logger/logger.service';
import {AbstractGenericGridComponent} from '../../../../abstract-generic-grid.component';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'toolbar-item-un-delete',
  templateUrl: './toolbar-item-un-delete.component.html'
})
export class ToolbarItemUnDeleteComponent extends ToolbarItemAbstract {

  constructor(
      private componentService: ComponentService,
      private messageGrowlService: MessageGrowlService,
      private messageService: MessageService,
      private genericCrudService: GenericCrudService,
      private apiBuilder: ApiBuilderService,
      private entityDataStore: EntityDataStoreService,
      private logger: LoggerService
  ) {
    super();
  }

    public click() {
      const entity = this.getComponent().getSelectedEntity();

      if (!entity) {
        return this.messageGrowlService.error('Please select entity you want to un-delete.');
      }

      if (!this.entityDataStore.isEntityDeleted(entity)) {
        this.logger.info('Entity is not deleted, can not be un-deleted');
        return this.messageGrowlService.error('Entity is not deleted, can not be un-deleted');
      }

      if (entity.id) {
        this.messageService.confirmDelete(() => {
          this.doUnDeleteEntity(entity);
        });
      }

    }

    private doUnDeleteEntity(entity) {
      const deleteApiRoute = this.apiBuilder.getApiRoute(entity._links.self.href);

      this.genericCrudService.customPut(`${deleteApiRoute}/undelete/${entity.id}`, entity, {'showDeleted': true}).toPromise()
        .then((updateEntity) => {
            this.entityDataStore
                .onEntityValueChanged(this.getComponent().getElementDataModelApiRoute(), {
                  entity: updateEntity,
                  value: null,
                  oldValue: null
                });

          this.messageGrowlService.success('Success.');

          this.componentService.clearHighlighted().clearHighlightedEntity();
        }).catch((error) => {
          console.error(error);
        });
    }

}
