
import {refCount, map, catchError, publishReplay} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';


import { Module } from './module';
import { AppHttpService } from '../../../app-http.service';
import { GenericCrudService } from '../generic-crud.service';

@Injectable()
export class ModuleCrudService extends AppHttpService {

  private modulesUrl: string = 'superadmin/modules';

  constructor(private genericCrudService: GenericCrudService) {
    super();
  }

  getModules(): Observable<Module[]> {
    return this.genericCrudService.get(this.modulesUrl).pipe(
      publishReplay(1),
      refCount(),
      catchError(this.handleError));
  }

  getModule(moduleId: number): Observable<Module> {
    if (moduleId < 1) {
      throw new Error('Invalid module id given.');
    }
    return this.genericCrudService.get(`${this.modulesUrl}/${moduleId}`).pipe(
      map(this.extractEmbeddedEntities, this),
      publishReplay(1),
      refCount(),
      catchError(this.handleError));
  }

  deleteModule(moduleId: number): Observable<Object> {
    if (moduleId < 1) {
      throw new Error('Invalid module id given.');
    }
    return this.genericCrudService.deleteEntity(`${this.modulesUrl}/${moduleId}`);
  }

  createModule(module: Module): Observable<Module> {
    if (module) {
      return this.genericCrudService.createEntity(this.modulesUrl, module).pipe(
        map((json) => { return <Module>json; }));
    } else {
      throw new Error('No module given.');
    }
  }

  editModule(module: Module): Observable<Module> {
    if (module) {
      return this.genericCrudService.editEntity(`${this.modulesUrl}/${module.id}`, module).pipe(
        map((json) => { return <Module>json; }));
    } else {
      throw new Error('No module given.');
    }
  }

  transferToolbarSet(moduleId, toolbarSetId, type): Observable<Module> {
    const url = this.modulesUrl + '/' + moduleId + '/transfer/' + toolbarSetId + '/type/' + type;
    return this.genericCrudService.get(url).pipe(
      map(this.extractEmbeddedEntities, this),
      publishReplay(1),
      refCount(),
      catchError(this.handleError));
  }
}
