import {Injectable} from '@angular/core';
import {Observable} from '../../../../node_modules/rxjs';
import {FileTypeFactoryService} from './file-type-factory.service';

@Injectable()
export class FileService {

  public constructor(
    private fileTypeFactory: FileTypeFactoryService
  ) {

  }

  public getFile(entity: any, params?: any): Observable<any> {
    const fileTypeService = this.fileTypeFactory.getService(entity);

    return fileTypeService.getFile(entity, params);
  }
}
