import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit, ViewChild, ViewContainerRef} from '@angular/core';
import {ExecutorService} from '../../../../../core/executor/executor.service';
import {GenericElementAbstract} from '../../generic-element-abstract.component';
import {FieldMetadataGrid} from '../../../../services/module/module-element-field-metadata-grid';
import {ModuleElement} from '../../../../services/module/module-element';
import {Observable} from 'rxjs/Observable';
import {EntityValidator, EntityValidatorStatus} from '../../../../validators/services/entity-validator';
import {Element} from '../../../../services/element/element';
import {GenericElementValidationExecutionStepsFactory} from '../../../services/generic/generic-element-validation-execution-steps-factory';
import {ComponentService} from '../../../services/component-highlight-stack.service';
import {ModulesStateService} from '../../../services/modules-state.service';
import {GenericCrudService} from '../../../../services/generic-crud.service';
import {EntityDataStoreService} from '../../../services/entity-data-store.service';
import {LocalStorageDataService} from '../../../../services/local-storage-data.service';
import {ToolbarItemCheckService} from '../../generic-toolbar/services/check/toolbar-item-check.service';
import {LayoutService} from '../../../../services/layout-service';
import {JobContainerService} from '../../../../../core/job-runner/job-container.service';
import {GenericElementFilterService} from '../../../services/generic/filter/generic-element-filter.service';
import {ExecutorActionsService} from '../../../../../core/executor/service/executor-actions/executor-actions.service';
import {ElementContext, ElementType} from '../../../services/ElementContext';
import {LocationService} from '../../../../services/location.service';
import {ElementsStackService} from '../../../services/elements-stack.service';
import {PermissionService} from '../../../../services/permission/permission.service';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ChangeDetectorRefHelper} from '../../../../helpers/change-detector-ref.helper';
import {EntityStatus} from '../../../../services/entity/entity-status';
import {AbstractGenericGridComponent} from '../../abstract-generic-grid.component';
import {map, takeUntil} from 'rxjs/operators';
import {environment} from '../../../../../../environments';
import {EntityManagerService} from '../../../../../core/service/entity-manager/entity-manager.service';
import {of} from 'rxjs/internal/observable/of';
import {Entity} from '../../../../helpers/entity';
import {ApiBuilderService} from '../../../../services/api.builder.service';
import {TranslateService} from '@ngx-translate/core';
import {MemoAutocompleteHandler} from '../../../../../core/service/autocomplete-handler/memo.autocomplete-handler';
import {ExecutorActionEvent} from '../../../../../core/executor/service/executor-actions/executor-action-event';
import {UserSessionService} from '../../../../../core/service/user-session.service';

@Component({
  selector: 'app-custom-todo-form',
  styleUrls: ['./todo-form.component.scss'],
  templateUrl: './todo-form.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    ExecutorService,
    GenericElementValidationExecutionStepsFactory,
    GenericElementFilterService,
    MemoAutocompleteHandler
  ]
})
export class TodoFormComponent extends GenericElementAbstract implements OnInit, OnDestroy {

  @Input() element: Element;
  @Input() fields: Array<FieldMetadataGrid>;
  @Input() toolbarItems: any[] = [];
  @Input() statusBarItems: any[] = [];
  @Input() moduleElement: ModuleElement;
  @Input() masterEntity: any = null;
  @Input() masterField: any = null;
  @Input() isPart = false;
  @Input() isSlave = false;
  @Input() masterFilterField?: string;
  @Input() masterFilterValue?: string;
  private _todo: any = null;
  @Input() public set entity(todo: any) {
    if (todo) {
      this._todo = todo;

      this.loadEntity(todo).subscribe((aTodo) => {
        this._todo = aTodo;
        this.initForm();
        this.hydrateForm();

        this.recheckToolbarItems();
        ChangeDetectorRefHelper.detectChanges(this);
      });
    }
  };
  public get entity() {
    return this._todo;
  }

  public iconBaseUrl: string = environment.baseUrl;
  public users: any[] = [];
  public editorOptions = null;
  public priorityOptions: SelectItem[] = [];
  public stateOptions: SelectItem[] = [];
  public typeOptions: SelectItem[] = [];
  public elementType: ElementType = ElementType.TodoForm;

  public isDataLoading = false;

  public toolbarContextName = 'formComponent';
  public form: FormGroup = null;

  public constructor(
    public memoHandler: MemoAutocompleteHandler,
    protected componentService: ComponentService,
    protected viewContainerRef: ViewContainerRef,
    protected modulesStateService: ModulesStateService,
    protected genericCrudService: GenericCrudService,
    protected entityDataStoreService: EntityDataStoreService,
    protected executorService: ExecutorService,
    protected genericElementValidationExecutionStepsFactory: GenericElementValidationExecutionStepsFactory,
    protected entityValidator: EntityValidator,
    protected userSession: UserSessionService,
    protected toolbarItemCheckService: ToolbarItemCheckService,
    protected layoutService: LayoutService,
    protected jobContainerService: JobContainerService,
    protected genericElementFilterService: GenericElementFilterService,
    protected executorActionsService: ExecutorActionsService,
    protected locationService: LocationService,
    protected elementsStackService: ElementsStackService,
    protected permissionService: PermissionService,
    public cdr: ChangeDetectorRef,
    private readonly formBuilder: FormBuilder,
    private entityManager: EntityManagerService,
    private apiBuilderService: ApiBuilderService,
    private translate: TranslateService
  ) {
    super(componentService, viewContainerRef, entityDataStoreService, modulesStateService, executorService,
      genericElementValidationExecutionStepsFactory, entityValidator, genericCrudService, userSession, permissionService,
      cdr);
  }

  public ngOnInit(): void {
    super.ngOnInit();

    this.elementContext = this.elementsStackService.createContext(this);

    this.elementsStackService.remove(this.elementContext).add(this.elementContext);

    this.initEditorOptions();
    this.initPriorityOptions();
    this.initStateOptions();
    this.initTypeOptions();
    this.initForm();
    this.initToolbarItems();

    this.executorActionsService
      .registerModuleElementActions(this.moduleElement)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe();
  }

  public ngOnDestroy(): void {
    super.ngOnDestroy();

    this.elementsStackService.remove(this.elementContext);
  }

  public getToolbarExtraParams() {
    return {
      'formViewerComponent': this
    };
  }

  public isEntityDirty(): boolean {
    return this.entity && this.entity[EntityStatus.ENTITY_CHANGED_FLAG];
  }

  public onComponentInit() {
  }

  public onDestroyComponent() {
  }

  public getSelectedEntity(): any {
    return this._todo;
  }

  public recheckToolbarItems(): void {
    this.toolbarItemCheckService.check(null);
  }

  public loadEntity(entity): Observable<any> {
    if (entity.id) {
      return this.genericCrudService.getEntity('phoenix/todos', entity.id, '', {
        embedded: 'todoPriority,todoState,responsibleUser,owner'
      });
    }

    return of(entity);
  }

  public onSave(): Observable<any> {
    this.parseEntity();

    if (this.entity.id) {

      this.entity[EntityStatus.ENTITY_CHANGED_FLAG] = true;

      return this.genericCrudService
        .editEntity(`phoenix/${this.getUrl(this.entity)}/${this.entity.id}?embedded=none`, this.entity)
        .switchMap((savedTodo: any) => {
          return Observable.of({status: true, content: this.entity});
        });
    } else {
      return this.genericCrudService
        .createEntity(`phoenix/${this.getUrl(this.entity)}?embedded=none`, this.entity)
        .switchMap((savedTodo: any) => {
          this.entity.id = savedTodo.id;
          this.hydrateForm();

          return Observable.of({status: true, content: savedTodo});
        });
    }
  }

  public onUserSearch(event): void {
    const query = event.query,
      route = this.apiBuilderService.getPaginateApiRoute({
        apiRoute: 'phoenix/users',
        offset: 0,
        limit: 50,
        orderBy: 'id',
        orderDirection: 'asc'
      }) + '?search=' + query,
      params = {clause: 'orWhere', 'embedded': 'none'};

    this.genericCrudService.getEntities(route, '', params)
      .subscribe((response: any) => {
        const users = response.data ? response.data : response;

        for (const user of users) {
          user.label = `${Entity.getValue(user, 'firstName')} ${Entity.getValue(user, 'lastName')}`;
        }

        this.users = [...users];
        ChangeDetectorRefHelper.detectChanges(this);
      });
  }

  public onCustomerChanged(customer): void {
    this.form.get('owner').patchValue(customer);
  }

  public onLeasedEmployeeChanged(leasedEmployee): void {
    this.form.get('owner').patchValue(leasedEmployee);
  }

  public onMemoChanged(memo): void {
    this.form.get('owner').patchValue(memo);
  }

  public hasChanges(checkEmbedded: boolean = false): boolean {
    return this.isEntityDirty();
  }

  public onAfterSave(): Observable<any> {
    return Observable.of(null);
  }

  public onChange(): Observable<any> {
    return Observable.of(null);
  }

  public doValidate(): Observable<EntityValidatorStatus> {
    return Observable.of({
      entity: null,
      isValid: true,
      error: '',
      errorFields: []
    });
  }

  public onRefresh(): Observable<any> {
    const masterContext: ElementContext = this.elementContext.getMasterElementContext();

    if (masterContext && masterContext.component && masterContext.component instanceof AbstractGenericGridComponent) {
      masterContext.component.entities = [];
      masterContext.component.createdEntities = [];

      return masterContext.component.loadEntities()
        .pipe(
          map((entities: any) => {
            masterContext.component.selectEntity(this.entity);

            return entities;
          }));
    }

    return Observable.of(null);
  }

  public onEditorChange(editor): void {
    this.form.get('note').patchValue(editor.html.get());
    this.registerChange();
  }

  public onDateChange(value: Date, controlKey): void {
    this.form.get(controlKey).patchValue(value);
    this.registerChange();
  }

  public onIconClick(): void {
    this.executeAction(ExecutorActionEvent.TodoOwnerIconClick, this).subscribe();
  }

  public registerChange(): void {
    this.entity[EntityStatus.ENTITY_CHANGED_FLAG] = true;

    this.recheckToolbarItems();
  }

  public getCustomerLabel(): string {
    const customer = Entity.getValue(this.entity, 'owner') ||
      Entity.getValueInEmbedded(this.entity, 'owner');

    return customer ? `${customer.primaryName} ${customer.secondaryName}` : '';
  }

  public getLeasedEmployeeLabel(): string {
    const leasedEmployee = Entity.getValue(this.entity, 'owner') ||
      Entity.getValueInEmbedded(this.entity, 'owner');

    return leasedEmployee ? `${leasedEmployee.firstName} ${leasedEmployee.lastName}` : '';
  }

  public getMemoLabel(): string {
    const memo = Entity.getValue(this.entity, 'owner') ||
      Entity.getValueInEmbedded(this.entity, 'owner');

    return memo ? memo.name : '';
  }

  private initForm(): void {
    const subject = this.entity ? this.entity.subject : '',
      startDate = this.entity ? this.entity.startDate : '',
      executionDate = this.entity ? this.entity.executionDate : '',
      reminderDate = this.entity ? this.entity.reminderDate : '',
      todoState = this.entity ? this.entity.todoState : '',
      todoPriority = this.entity ? this.entity.todoPriority : '',
      responsibleUser = this.entity ? this.entity.responsibleUser : '',
      note = this.entity ? this.entity.note : '',
      hasReminder = this.entity ? this.entity.hasReminder : false;

    this.form = this.formBuilder.group({
      subject: this.formBuilder.control(subject, [Validators.required]),
      type: this.formBuilder.control(''),
      owner: this.formBuilder.control(null),
      startDate: this.formBuilder.control(startDate),
      executionDate: this.formBuilder.control(executionDate),
      reminderDate: this.formBuilder.control(reminderDate),
      todoState: this.formBuilder.control(todoState),
      todoPriority: this.formBuilder.control(todoPriority),
      responsibleUser: this.formBuilder.control(responsibleUser),
      note: this.formBuilder.control(note),
      hasReminder: this.formBuilder.control(hasReminder)
    });

    const me = this;
    this.form.valueChanges.subscribe(val => {
      me.registerChange();
    });
  }

  private hydrateForm(): void {
    const todoPriority = Entity.getValue(this.entity, 'todoPriority') ||
      Entity.getValueInEmbedded(this.entity, 'todoPriority'),
        todoState = Entity.getValue(this.entity, 'todoState') ||
      Entity.getValueInEmbedded(this.entity, 'todoState'),
        responsibleUser = Entity.getValue(this.entity, 'responsibleUser') ||
      Entity.getValueInEmbedded(this.entity, 'responsibleUser'),
        owner = Entity.getValue(this.entity, 'owner') ||
      Entity.getValueInEmbedded(this.entity, 'owner');

    if (responsibleUser) {
      responsibleUser.label = `${Entity.getValue(responsibleUser, 'firstName')} ${Entity.getValue(responsibleUser, 'lastName')}`;
    }

    this.form.get('subject').patchValue(this.entity.subject);
    this.form.get('owner').patchValue(owner);
    this.form.get('startDate').patchValue(new Date(this.entity.startDate));
    this.form.get('executionDate').patchValue(new Date(this.entity.executionDate));
    this.form.get('reminderDate').patchValue(new Date(this.entity.reminderDate));
    this.form.get('todoState').patchValue(todoState);
    this.form.get('todoPriority').patchValue(todoPriority);
    this.form.get('responsibleUser').patchValue(responsibleUser);
    this.form.get('note').patchValue(this.entity.note);
    this.form.get('hasReminder').patchValue(this.entity.hasReminder);
  }

  private parseEntity(): void {
    for (const controlKey in this.form.controls) {
      if (this.form.controls.hasOwnProperty(controlKey)) {
        this.entityManager.persist(this.entity, {
          property: controlKey,
          newValue: this.form.get(controlKey).value,
          force: true
        })
      }
    }
  }

  private initEditorOptions(): void {
    const me = this;

    this.editorOptions = {
      enter: 1,
      key: environment.froalaKey,
      toolbarButtons: ['fullscreen', 'bold', 'italic', 'underline',
        '|', 'fontFamily', 'fontSize', 'color',
        '|', 'paragraphFormat', 'align', 'formatOL', 'formatUL', 'outdent', 'indent',
        '-', 'embedly', 'insertTable',
        '|', 'insertHR', 'clearFormatting',
        '|', 'undo', 'redo', 'html'],
      events: {
        'froalaEditor.contentChanged': function (e, editor) {
          me.onEditorChange(editor);
        }
      }
    };
  }

  private initPriorityOptions(): void {
    this.genericCrudService.getEntities('phoenix/todopriorities')
      .subscribe((todoPriorities: any[] = []) => {
        this.priorityOptions = [];

        for (const todoPriority of todoPriorities) {
          this.priorityOptions.push({
            value: todoPriority,
            label: todoPriority.name
          })
        }

        ChangeDetectorRefHelper.detectChanges(this);
      });
  }

  private initStateOptions(): void {
    this.genericCrudService.getEntities('phoenix/todostates')
      .subscribe((todoStates: any[] = []) => {
        this.stateOptions = [];

        for (const todoState of todoStates) {
          this.stateOptions.push({
            value: todoState,
            label: todoState.name
          })
        }

        ChangeDetectorRefHelper.detectChanges(this);
      });
  }

  private initTypeOptions(): void {
    this.typeOptions = [{
      label: this.translate.instant('TODO.CUSTOMER'),
      value: 'customer'
    }, {
      label: this.translate.instant('TODO.LEASED_EMPLOYEE'),
      value: 'leasedEmployee'
    }];

    ChangeDetectorRefHelper.detectChanges(this);
  }

  private getUrl(entity): string {
    let url = null;

    const fqn = this.getFqn(entity);

    switch (fqn) {
      case 'PhoenixBundle\\Entity\\CustomerTodo':
        url = 'customertodos';
        break;
      case 'PhoenixBundle\\Entity\\LeasedEmployeeTodo':
        url = 'leasedemployeetodos';
        break;
      case 'PhoenixBundle\\Entity\\Todo':
        url = 'todos';
        break;
    }

    return url;
  }

  private getFqn(entity): string {
    if (entity.id) {
      return entity.fqn;
    }

    let fqn = '';

    const type = this.form.get('type').value;

    switch (type) {
      case 'customer':
        fqn = 'PhoenixBundle\\Entity\\CustomerTodo';
        break;
      case 'leasedEmployee':
        fqn = 'PhoenixBundle\\Entity\\LeasedEmployeeTodo';
        break;
      default:
        fqn = 'PhoenixBundle\\Entity\\Todo';
        break;
    }

    return fqn;
  }
}
