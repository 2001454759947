import {ElementInputDropdown} from './element-input-dropdown';

export class ElementInputListBox extends ElementInputDropdown {
  public inputHeight: string = undefined;

  constructor() {
    super();
    this.typeElement = 'listBox';
    this.inputHeight = undefined;
  }
}
