/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./staff-cockpit-stat-count-charts.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../generic-loading/generic-loading.component.ngfactory";
import * as i3 from "../../../generic-loading/generic-loading.component";
import * as i4 from "../../../../../../../../node_modules/primeng/components/chart/chart.ngfactory";
import * as i5 from "primeng/components/chart/chart";
import * as i6 from "@angular/common";
import * as i7 from "../../../../../keys.pipe";
import * as i8 from "../../../../../../core/service/locale/calendar-locale.service";
import * as i9 from "@ngx-translate/core";
import * as i10 from "../services/leased-employee-stat-count.service";
import * as i11 from "../../../../../../core/service/user-session.service";
import * as i12 from "../../../../../../core/authentication/authentication.service";
import * as i13 from "../../../../../services/generic-crud.service";
import * as i14 from "./staff-cockpit-stat-count-charts.component";
var styles_StaffCockpitStatCountChartsComponent = [i0.styles];
var RenderType_StaffCockpitStatCountChartsComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_StaffCockpitStatCountChartsComponent, data: {} });
export { RenderType_StaffCockpitStatCountChartsComponent as RenderType_StaffCockpitStatCountChartsComponent };
function View_StaffCockpitStatCountChartsComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-generic-loading", [], null, null, null, i2.View_GenericLoadingComponent_0, i2.RenderType_GenericLoadingComponent)), i1.ɵdid(1, 114688, null, 0, i3.GenericLoadingComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
function View_StaffCockpitStatCountChartsComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "p-chart", [["type", "line"]], null, null, null, i4.View_UIChart_0, i4.RenderType_UIChart)), i1.ɵdid(1, 4374528, null, 0, i5.UIChart, [i1.ElementRef], { type: [0, "type"], options: [1, "options"], data: [2, "data"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "line"; var currVal_1 = _co.options[_v.context.$implicit]; var currVal_2 = _co.data[_v.context.$implicit]; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2); }, null); }
function View_StaffCockpitStatCountChartsComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["class", "ui-g-12"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 2, null, View_StaffCockpitStatCountChartsComponent_3)), i1.ɵdid(2, 278528, null, 0, i6.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), i1.ɵppd(3, 1)], function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 2, 0, _ck(_v, 3, 0, i1.ɵnov(_v.parent, 0), _co.data)); _ck(_v, 2, 0, currVal_0); }, null); }
export function View_StaffCockpitStatCountChartsComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i7.KeysPipe, []), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_StaffCockpitStatCountChartsComponent_1)), i1.ɵdid(2, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_StaffCockpitStatCountChartsComponent_2)), i1.ɵdid(4, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.isLoading; _ck(_v, 2, 0, currVal_0); var currVal_1 = !_co.isLoading; _ck(_v, 4, 0, currVal_1); }, null); }
export function View_StaffCockpitStatCountChartsComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "app-custom-staff-cockpit-stat-count-charts", [], null, null, null, View_StaffCockpitStatCountChartsComponent_0, RenderType_StaffCockpitStatCountChartsComponent)), i1.ɵprd(512, null, i8.CalendarLocaleService, i8.CalendarLocaleService, [i9.TranslateService]), i1.ɵprd(512, null, i10.LeasedEmployeeStatCountService, i10.LeasedEmployeeStatCountService, [i11.UserSessionService, i12.AuthenticationService, i13.GenericCrudService]), i1.ɵdid(3, 245760, null, 0, i14.StaffCockpitStatCountChartsComponent, [i8.CalendarLocaleService, i1.ChangeDetectorRef, i10.LeasedEmployeeStatCountService], null, null)], function (_ck, _v) { _ck(_v, 3, 0); }, null); }
var StaffCockpitStatCountChartsComponentNgFactory = i1.ɵccf("app-custom-staff-cockpit-stat-count-charts", i14.StaffCockpitStatCountChartsComponent, View_StaffCockpitStatCountChartsComponent_Host_0, {}, {}, []);
export { StaffCockpitStatCountChartsComponentNgFactory as StaffCockpitStatCountChartsComponentNgFactory };
