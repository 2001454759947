import {ChangeDetectorRef, Directive, Input, OnInit, Self} from '@angular/core';
import {AutoComplete} from 'primeng/primeng';
import {Observable, Subscription} from 'rxjs';
import {ChangeDetectorRefHelper} from '../helpers/change-detector-ref.helper';

@Directive({
  selector: '[appAutoCompleteHandler]'
})
export class AutocompleteHandlerDirective implements OnInit {

  @Input() public handler: {
    getData(query): Observable<any[]>
  } = null;

  public subscriptions: Subscription[] = [];

  public autocomplete: AutoComplete = null;

  constructor(
    @Self() autocomplete: AutoComplete,
    public cdr:ChangeDetectorRef
  ) {
    this.autocomplete = autocomplete;

    if (!(this.autocomplete instanceof AutoComplete)) {
      console.error('AutoCompleteDirective works only with p-autocomplete component!');
    }
  }

  public ngOnInit(): void {
    this.autocomplete.completeMethod.subscribe((event: any) => {
      this.onComplete(event);
    });
  }

  public onComplete(event): void {
    const originalEvent = event.originalEvent,
      query = event.query;

    if (this.handler === null) {
      console.error('Handler missing in Host component, pass it as @Input()!');
    }

    this.subscriptions.push(
      this.handler
        .getData(query)
        .subscribe((data: any[]) => {
          this.autocomplete.suggestions = data;

          ChangeDetectorRefHelper.detectChanges(this);
        })
    );
  }
}
