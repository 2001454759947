import { Component, OnInit, Input } from '@angular/core';
import { TooltipModule } from 'primeng/primeng';

@Component({
  selector: 'app-generic-checkbox',
  templateUrl: './generic-checkbox.component.html',
  styleUrls: ['./generic-checkbox.component.scss']
})
export class GenericCheckboxComponent implements OnInit {

  @Input() title = '';
  @Input() imageSource = '';
  @Input() tooltipText = '';
  @Input() isChecked = false;

  constructor() { }

  ngOnInit() {

  }
}
