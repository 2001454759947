import {Observable} from 'rxjs/Observable';
import {AbstractExecutionStep} from '../../../../core/executor/abstract-execution-step';
import {ExecutionStepStatus} from '../../../../core/executor/execution-step-status';
import {of as observableOf} from 'rxjs';
import {GenericElementAbstract} from '../../../content-renderer/elements/generic-element-abstract.component';
import {WindowService} from '../../../../core/service/window.service';
import {environment} from '../../../../../environments';

const DMS_WINDOW_KEY = 'dmsFile';

export class FileOpenInNewWindowExecutionStep extends AbstractExecutionStep {
  public doExecute(): Observable<ExecutionStepStatus> {
    const component = this.getPayload().getValue();

    if (!(component instanceof GenericElementAbstract)) {
      return this.getFailObservable('You need to pass GenericElementAbstract as Payload value!');
    }

    return this.doOpen(component);
  }

  private doOpen(component: GenericElementAbstract): Observable<ExecutionStepStatus> {
    const entity = component.getSelectedEntity();

    if (entity && entity.contentUrl) {
      this.injector.get<WindowService>(WindowService).replace(DMS_WINDOW_KEY, {
        url: environment.baseUrl + '/' + entity.contentUrl,
        target: '_blank',
        features: 'toolbar=0,location=yes,menubar=0'
      })
    }

    return observableOf({status: true, content: null });
  }
}
