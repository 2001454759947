
import {forkJoin as observableForkJoin,  Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { AppHttpService } from './../../app-http.service';
import { GenericCrudService } from './generic-crud.service';

@Injectable()
export class GenericCrudBulkService extends AppHttpService {

  public static CREATE = 'create';
  public static UPDATE = 'update';
  public static DELETE = 'delete';

  constructor(private genericCrudService: GenericCrudService) {
    super();
  }

  private commitedEntities = {
    'create': {
      // /api/phoenix/severancepays => [entity1, entity2, entity3]
      // /api/phoenix/articles => [entity1, entity2]
    },
    'update': {
      // /api/phoenix/severancepays => [entity1, entity2, entity3]
      // /api/phoenix/articles => [entity1, entity2]
    },
    'delete': {
      // /api/phoenix/severancepays => [id1, id2, id3]
      // /api/phoenix/articles => [entity1, entity2]
    }
  };

  public clear() {
    this.commitedEntities[GenericCrudBulkService.CREATE] = {};
    this.commitedEntities[GenericCrudBulkService.UPDATE] = {};
    this.commitedEntities[GenericCrudBulkService.DELETE] = {};
  }

  public flush(): Observable<any> {
    var toFlush = [];

    for (var entitiesUrl in this.commitedEntities[GenericCrudBulkService.CREATE]) {
      toFlush.push(this.genericCrudService.createEntities(entitiesUrl, this.commitedEntities.create[entitiesUrl]));
    }

    for (var entitiesUrl in this.commitedEntities[GenericCrudBulkService.UPDATE]) {
      toFlush.push(this.genericCrudService.editEntities(entitiesUrl, this.commitedEntities.update[entitiesUrl]));
    }

    for (var entitiesUrl in this.commitedEntities[GenericCrudBulkService.DELETE]) {
      toFlush.push(this.genericCrudService.deleteEntities(entitiesUrl, this.commitedEntities.delete[entitiesUrl]));
    }

    return Observable.create((observer) => {
      observableForkJoin(toFlush)
        .subscribe(results => {
          this.clear();
    
          observer.next(results);
          observer.complete();
        });
    })
  }

  public getCommitedEntities(): any[] {
    let entities = [];

    for (var entitiesUrl in this.commitedEntities[GenericCrudBulkService.CREATE]) {
      for (let entity of this.commitedEntities.create[entitiesUrl]) {
        entities = [...entities, entity];
      }
    }

    for (var entitiesUrl in this.commitedEntities[GenericCrudBulkService.UPDATE]) {
      for (let entity of this.commitedEntities.update[entitiesUrl]) {
        entities = [...entities, entity];
      }
    }

    return entities;
  }

  public commit(operation, entity, apiRoute?: string) {
    const entityUrl = this.getEntityUrl(operation, entity, apiRoute);

    if (entityUrl) {
      this.prepareCommit(operation, entityUrl);

      switch (operation) {
        case GenericCrudBulkService.CREATE:
          this.doCommitCreate(entityUrl, entity);
          break;
        case GenericCrudBulkService.UPDATE:
          this.doCommitUpdate(entityUrl, entity);
          break;
        case GenericCrudBulkService.DELETE:
          this.doCommitDelete(entityUrl, entity);
          break;
        default:
      }
    }

    return this;
  }

  public uncommit(operation, entity, apiRoute?: string) {
    const entityUrl = this.getEntityUrl(operation, entity, apiRoute);

    if (entityUrl) {
      this.prepareCommit(operation, entityUrl);

      switch (operation) {
        case GenericCrudBulkService.UPDATE:
          this.doUncommitUpdate(entityUrl, entity);
          break;
        case GenericCrudBulkService.DELETE:
          this.doUncommitDelete(entityUrl, entity);
          break;
        default:
      }
    }

    return this;
  }

  private doCommitCreate(entityUrl, entity) {
      let index = this.commitedEntities[GenericCrudBulkService.CREATE][entityUrl].indexOf(entity);

      if (index !== -1) {
        this.commitedEntities[GenericCrudBulkService.CREATE][entityUrl][index] = entity;
      } else {
        this.commitedEntities[GenericCrudBulkService.CREATE][entityUrl].push(entity);
      }
  }

  private doCommitUpdate(entityUrl, entity) {
    let index = this.getEntityIndex(GenericCrudBulkService.UPDATE, entityUrl, entity);

    if (index !== -1) {
      this.commitedEntities[GenericCrudBulkService.UPDATE][entityUrl][index] = entity;;
    } else {
      this.commitedEntities[GenericCrudBulkService.UPDATE][entityUrl].push(entity);
    }
  }

  private doCommitDelete(entityUrl, entity) {
    this.commitedEntities[GenericCrudBulkService.DELETE][entityUrl].push(entity.id);
  }

  private doUncommitUpdate(entityUrl, entity) {
    if (this.commitedEntities[GenericCrudBulkService.UPDATE][entityUrl] instanceof Array) {
      this.commitedEntities[GenericCrudBulkService.UPDATE][entityUrl].forEach((commitedEntity) => {
        if (commitedEntity['id'] === entity['id']) {
          var index = this.commitedEntities[GenericCrudBulkService.UPDATE][entityUrl].indexOf(commitedEntity);

          if (index > -1) {
            this.commitedEntities[GenericCrudBulkService.UPDATE][entityUrl].splice(index, 1);
          }
        }
      });
    }
  }

  private doUncommitDelete(entityUrl, entity) {
    this.commitedEntities[GenericCrudBulkService.DELETE][entityUrl].push(entity.id);

    if (this.commitedEntities[GenericCrudBulkService.DELETE][entityUrl] instanceof Array) {
      this.commitedEntities[GenericCrudBulkService.DELETE][entityUrl].forEach((commitedEntityId) => {
        if (commitedEntityId === entity['id']) {
          var index = this.commitedEntities[GenericCrudBulkService.DELETE][entityUrl].indexOf(commitedEntityId);

          if (index > -1) {
            this.commitedEntities[GenericCrudBulkService.DELETE][entityUrl].splice(index, 1);
          }
        }
      });
    }
  }

  private getEntityIndex(context: string, entityUrl: string, entity: any) {
    return this.commitedEntities[context][entityUrl].findIndex(aEntity => aEntity.id === entity.id);
  }

  private getEntityUrl(operation, entity, apiRoute?: string): string {
    var entityUrl = '';

    if (apiRoute) {
      entityUrl = apiRoute;
    }

    if (entity && entity._links && entity._links.self.href && (operation === GenericCrudBulkService.UPDATE || operation === GenericCrudBulkService.DELETE)) {
      entityUrl = entity._links.self.href.substr(0, entity._links.self.href.lastIndexOf("/"));
    }

    entityUrl = entityUrl.replace('/api/', '');

    return entityUrl;
  }

  private prepareCommit(operation, entityUrl) {
    if (!this.commitedEntities[operation][entityUrl]) {
      this.commitedEntities[operation][entityUrl] = [];
    }
  }
}
