import {ChangeDetectionStrategy, Component, OnDestroy} from '@angular/core';
import { ToolbarItemAbstract } from '../toolbar-item-abstract.component';
import { GenericCrudService } from '../../../../../../services/generic-crud.service';
import { MessageGrowlService } from '../../../../../../../core/message/message-growl.service';
import { TranslateService } from '@ngx-translate/core';
import { CompanyCommunicationService } from '../../../../../../company-search/services/company-communication.service';
import { Subscription } from 'rxjs';
import { GenericGridComponent } from '../../../../generic-grid/generic-grid.component';
import { AbstractGenericGridComponent } from 'app/shared/content-renderer/elements/abstract-generic-grid.component';
import { Element } from '../../../../../../services/element/element';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-toolbar-item-ksv-company-search',
  templateUrl: './toolbar-item-ksv-company-search.component.html'
})
export class ToolbarItemKsvCompanySearchComponent extends ToolbarItemAbstract implements OnDestroy {

  public gridComponent: GenericGridComponent = null;

  public isDialogVisible = false;
  private companyAddedSubscription: Subscription;

  public constructor(
    protected genericCrudService: GenericCrudService,
    protected messageGrowlService: MessageGrowlService,
    protected translate: TranslateService,
    protected companyCommunicationService: CompanyCommunicationService
  ) {
    super();
  }

  public click() {
    if (this.isInCurrentContext(ToolbarItemAbstract.CONTEXT_GRID)) {
      this.gridComponent = this.getParam('gridComponent');

      this.handleClickInGridContext();
    }
  }

  public ngOnDestroy() {
    this.unsubscribeFromCompanyAdded();
  }

  protected unsubscribeFromCompanyAdded() {
    if (this.companyAddedSubscription) {
      this.companyAddedSubscription.unsubscribe();
    }

    return this;
  }

  protected subscribeToCompanyAdded() {
    this.companyAddedSubscription = this.companyCommunicationService.companyAdded$.subscribe((company) => {
      this.isDialogVisible = false;

      const gridComponent = this.getComponent();

      if (gridComponent instanceof AbstractGenericGridComponent) {
        gridComponent.sortDirection = Element.SORT_DIRECTION_DESC;
        gridComponent.sortField = 'id';
        gridComponent.currentPage = 1;

        gridComponent.loadEntities().subscribe(() => {
          gridComponent.selectedEntity = gridComponent.entities[0];
          gridComponent.reselectEntity();
        });
      }
    });
  }

  protected handleClickInGridContext() {
    this.unsubscribeFromCompanyAdded().subscribeToCompanyAdded();

    this.isDialogVisible = true;
  }
}
