
import {of as observableOf,  Observable } from 'rxjs';

import {catchError} from 'rxjs/operators';
import { AbstractAsyncValidator } from './abstract-async-validator';
import { ValidationStatus } from '../services/validation';
import { cloneDeep } from 'lodash';
import { Constants } from 'app/constants';
import { AbstractGenericGridComponent } from 'app/shared/content-renderer/elements/abstract-generic-grid.component';
import { ElementType } from 'app/shared/content-renderer/services/ElementContext';
import {EntityStatus} from '../../services/entity/entity-status';

export class UniqueFieldsValidator extends AbstractAsyncValidator {

  public getConstraint(): any {
    const postFields = this.getFieldsToCheck();

    return {
      'name': 'unique',
      'fields': postFields
    };
  }

  public isValid(value: any): boolean {
    return true;
  }

  public getErrorTranslateKey(value: any): string {
    return 'UNIQUE_FIELDS_IS_NOT_VALID';
  }

  public getErrorTranslateParams(value: any): any {
    const fields = Object.keys(value);

    return {
      'fieldNames': fields.join()
    };
  }

  public validate(): Observable<ValidationStatus> {
    let observable = observableOf({
      isValid: true,
      errorTranslated: '',
      errorTranslateKey: '',
      errorTranslateParams: null,
      field: this.getField(),
      value: this.getEntityFieldValue()
    });

    const postFields = this.getFieldsToCheck();

    if (postFields) {

      if (this.isLocalValid(postFields)) {
        observable = this.validateRemote(postFields);
      } else {
        observable = observableOf({
          isValid: false,
          errorTranslated: null, // well no translated error from backend :(
          errorTranslateKey: this.getErrorTranslateKey(this.getEntityFieldValue()),
          errorTranslateParams: this.getErrorTranslateParams(postFields),
          field: this.getField(),
          value: this.getEntityFieldValue()
        });
      }

    }

    return observable;
  }

  private isLocalValid(postFields: Object): boolean {
    let isLocalValid = true,
      entity = this.getEntity(),
      component = this.getComponentToValidate();

    if (null !== component) {
      isLocalValid = this.isCurrentDifferentToChangedEntities(entity, this.getEntitiesToCheck(component), postFields);
    }

    return isLocalValid;
  }

  private validateRemote(postFields: Object): Observable<ValidationStatus> {
      const entityToCheck = cloneDeep(this.getEntity());

      return this.getGenericCrudService().createEntity(`${Constants.APP_API_ROUTE}/validate`, {
        'constraint': this.getConstraint(),
        'entity': this.getEntityHydrator().hydrate(entityToCheck)
      }, false).pipe(catchError((response, caught) => {
        const errors = response.error.errors;

        return observableOf({
          isValid: false,
          errorTranslated: this.getErrorTranslated(errors),
          errorTranslateKey: this.getErrorTranslateKey(this.getEntityFieldValue()),
          errorTranslateParams: this.getErrorTranslateParams(postFields),
          field: this.getField(),
          value: this.getEntityFieldValue()
        });
      }));
    }

  private isCurrentDifferentToChangedEntities(entity: any, changedEntities: any[] = [], postFields: Object): boolean {
    let isCurrentDifferentToChangedEntities = true;

    if (changedEntities.length === 0) {
      return true;
    }

    for (let changedEntity of changedEntities) {
      let entitiesAreSame = true;

      for (let property in postFields) {
        if (!this.compare(entity, changedEntity, property)) {
          entitiesAreSame = false;
          break;
        }
      }

      if (entitiesAreSame) {
        isCurrentDifferentToChangedEntities = false;
        break;
      }
    }

    return isCurrentDifferentToChangedEntities;
  }

  /**
   * @description - returns changed entities, but not the one which is being validated
   * @param component
   * @returns {any[]}
   */
  private getEntitiesToCheck(component: AbstractGenericGridComponent): any[] {
    let entitiesToCheck = [],
      entity = this.getEntity();

    for (const createdEntity of component.getCreatedEntities()) {

      if (this.compare(entity, createdEntity, EntityStatus.ENTITY_DRAFT_FLAG)) {
        continue;
      }

      entitiesToCheck.push(createdEntity);
    }

    for (const updatedEntity of component.getUpdatedEntities()) {
      if ((this.compare(entity, updatedEntity, EntityStatus.ENTITY_DRAFT_FLAG)) ||
        (this.compare(entity, updatedEntity, 'id'))
      ) {
        continue;
      }

      entitiesToCheck.push(updatedEntity);
    }

    return entitiesToCheck;
  }

  /**
   *
   * @param entityOne
   * @param entityTwo
   * @param property
   * @returns {boolean}
   */
  private compare(entityOne: any, entityTwo: any, property: string): boolean {
    const valueOne = this.getEntityHydrator().getEntityPropertyValue(entityOne, property),
      valueTwo = this.getEntityHydrator().getEntityPropertyValue(entityTwo, property);

    return valueOne === valueTwo;
  }

  /**
   * @description in case of form return master grid
   * @returns {AbstractGenericGridComponent|null}
   */
  private getComponentToValidate(): AbstractGenericGridComponent|null {
    let component = null;

    if (this.component.getElementContext().type === ElementType.Form &&
      this.component.getElementContext().isSlaveContext() &&
      this.component.getElementContext().getMasterElementContext().type === ElementType.Grid
    ) {
      component = this.component.getElementContext().getMasterElementContext().component;
    }

    if (this.component.getElementContext().type === ElementType.Grid) {
      component = this.component;
    }

    return component;
  }

  /**
   * @description - unique fields to be checked
   * @returns {Object}
   */
  private getFieldsToCheck(): Object {
    let fieldsToCheck = {},
      configFields = this.getParamValue('fields')

    fieldsToCheck[this.getFieldName()] = this.getFieldName();

    // master entity fields to check
    if (this.component.getElementContext().isSlaveContext()) {
      for (let masterEntityConfig of this.component.getElementContext().getMasterEntities()) {
        if (masterEntityConfig.name) {
          fieldsToCheck[masterEntityConfig.name] = masterEntityConfig.name;
        }
      }
    }

    if (configFields) {
      for (const field of configFields) {
        fieldsToCheck[field] = field;
      }
    }

    return fieldsToCheck;
  }
}
