import {Injectable} from '@angular/core';
import {FieldMetadataGrid} from '../../../../../services/module/module-element-field-metadata-grid';
import {AbstractGenericGridComponent} from '../../../../elements/abstract-generic-grid.component';
import {ModuleElementFieldDataConfig, ModuleElementFieldFilter} from '../../../../../services/module/module-element-field';
import {MasterEntityFilterService} from './filters/master-entity-filter.service';
import {CollectiveAgreementTypeFilterService} from './filters/collective-agreement-type-filter.service';
import {InquiryPositionConditionFilterService} from './filters/inquiry-position-condition-filter.service';
import {AnotherEntityFieldFilterService} from "./filters/another-entity-field-filter.service";

@Injectable()
export class GenericColumnFilterService {

  /**
   * @param {FieldMetadataGrid} field
   * @param {AbstractGenericGridComponent} grid
   * @returns {any}
   */
  public static createFilters(field: FieldMetadataGrid, grid: AbstractGenericGridComponent): Array<ModuleElementFieldFilter> {
    const params = [];

    if (field && field.dataConfig) {
      for (const dataConfig of field.dataConfig) {
        const filter = GenericColumnFilterService.parseConfigValue(dataConfig, grid);

        if (filter) {
          params.push(filter);
        }
      }
    }

    return params;
  }

  /**
   * @param {ModuleElementFieldDataConfig} dataConfig
   * @param {AbstractGenericGridComponent} grid
   * @returns {ModuleElementFieldFilter}
   */
  protected static parseConfigValue(
    dataConfig: ModuleElementFieldDataConfig,
    grid: AbstractGenericGridComponent): ModuleElementFieldFilter|null {

    switch (dataConfig.type) {
      case FieldMetadataGrid.FILTER_GRID_FIELD_TYPE_MASTER_ENTITY:
        return MasterEntityFilterService.createFilter(dataConfig, grid);
      case FieldMetadataGrid.FILTER_GRID_FIELD_BY_ANOTHER_FIELD:
        return AnotherEntityFieldFilterService.createFilter(dataConfig, grid);
      case FieldMetadataGrid.FILTER_GRID_COLLECTIVE_AGREEMENT_CONTRACT_TYPE:
        return CollectiveAgreementTypeFilterService.createFilter(dataConfig, grid);
      case FieldMetadataGrid.FILTER_GRID_INQUIRY_POSITION_CONDITION:
        return InquiryPositionConditionFilterService.createFilter(dataConfig, grid);
      default:
        return MasterEntityFilterService.createFilter(dataConfig, grid);
    }
  }
}
