import {AbstractFormActionHandler} from '../abstract-form-action-handler';
import {GenericCrudService} from '../../../../services/generic-crud.service';
import {FormService} from '../../../form.service';
import {Entity} from '../../../../helpers/entity';
import {Element, ElementInputAssociation} from '../../../models/element';
import {EntityDirtyStoreService} from '../../../../content-renderer/services/entity-dirty-store.service';
import {EntityStatus} from '../../../../services/entity/entity-status';
import {ComponentSelectedOptionAware} from "../../../element/abstract-element.component";
import {FormViewerComponent} from "../../../form-viewer.component";
import {EntityManagerService} from "../../../../../core/service/entity-manager/entity-manager.service";
import {ElementInputAutocomplete, ElementInputDate, ElementInputText} from "../../../models";
import {TranslateService} from "@ngx-translate/core";
import {ElementInputDateComponent} from "../../../element/element-input-date.component";
import {DateHelper} from "../../../../helpers/date.helper";
import {AuthenticationService} from "../../../../../core/authentication/authentication.service";
import {LocalStorageDataService} from "../../../../services/local-storage-data.service";
import {Branch} from "../../../../services/branch/branch";
import {isObject} from "util";

interface Advance {
  createdAt: any;
  createdBy: any;
  paymentDate: any;
  period: any;
  leasedEmployee: any;
  handlingFee: any;
  amount: any;
  _embedded: any;
}

export class SetupAdvanceFormActionHandler extends AbstractFormActionHandler {

  public entityHasBeenChanged = false;

  public constructor(
    private genericCrudService: GenericCrudService,
    private entityDirtyStore: EntityDirtyStoreService,
    private entityManager: EntityManagerService,
    private translateService: TranslateService
  ) {
    super();
  }

  public handleAction(): void {
    const advance: Advance = this.getEntity();

    if (advance) {
      advance._embedded = advance._embedded || {};

      this.setupCreatedAt(advance);
      this.setupPaymentDate(advance);

      this.finallySetupPeriodAndPatchForm(advance);

    }
  }

  private changeFormValues(advance: Advance): this {
    const components = this.formService.getComponents();

    this.getComponent().patchFormGroupDisabled();
    this.getComponent().patchFormGroupValues();

    for (const component of components) {
      if (component.element && component.element.datamodelField && (
        component.element instanceof ElementInputAssociation
        || component.element instanceof ElementInputDate
        || component.element instanceof ElementInputText
        || component.element instanceof ElementInputAutocomplete
      )) {
          component.setupValue();
      }
    }

    this.entityDirtyStore.store(advance, {
      success: ((isLocked) => {
        advance[EntityStatus.ENTITY_CHANGED_FLAG] = true;

        this.getComponent().recheckToolbarItems();
      })
    });

    return this;
  }

  private finallySetupPeriodAndPatchForm(advance: Advance): this {

    if (this.canChangePeriod(advance)) {
      const paymentDate = this.parseDate(advance, 'paymentDate');

      this.genericCrudService.customPost(`phoenix/periods/fordate?embedded=none`, {'date':paymentDate}
      ).subscribe((period: any|null) => {
        if (period) {
          advance.period = period;
          advance._embedded.period = period;

          this.finallySetupHandlingFeeAndPatchForm(advance);
        }
      });
    } else if (this.entityHasBeenChanged) {
      this.finallySetupHandlingFeeAndPatchForm(advance);
    }

    return this;
  }

  private finallySetupHandlingFeeAndPatchForm(advance: Advance): this {

    if (this.canChangeHandlingFee(advance)) {
      let period = advance.period;
      if(isObject(period)){
        period = period.id;
      }
      let leasedEmployee = advance.leasedEmployee;
      if(isObject(leasedEmployee)){
        leasedEmployee = leasedEmployee.id;
      }
      this.genericCrudService.customPost(`phoenix/advances/handlingFee`, {'leasedEmployee':leasedEmployee, 'period': period}
      ).subscribe((handlingFee: any|null) => {
        if (handlingFee !== null) {
          advance.handlingFee = handlingFee;
          advance.amount = handlingFee;


          this.finallySetupCreatedByForm(advance);
        }
      });
    } else if (this.entityHasBeenChanged) {
      this.finallySetupCreatedByForm(advance);
    }

    return this;
  }

  private finallySetupCreatedByForm(advance: Advance): this {

    if (this.canChangeCreatedBy(advance)) {
      this.genericCrudService.get('app/users/me?embedded=none'
      ).subscribe((user: any|null) => {
        if (user) {
          advance.createdBy = user;
          advance._embedded.createdBy = user;

          this.changeFormValues(advance);
        }
      });
    } else if (this.entityHasBeenChanged) {
      this.changeFormValues(advance);
    }

    return this;
  }

  private canChangeCreatedBy(advance: Advance): boolean {
    let advanceCreatedBy = Entity.getValue(advance, 'createdBy');

    if (!advanceCreatedBy || !advanceCreatedBy.id) {
      return true
    }else{
      return false;
    }
  }

  private canChangeHandlingFee(advance: Advance): boolean {
    let advanceHandlingFee = Entity.getValue(advance, 'handlingFee');

    if (!advanceHandlingFee) {
      return true
    }else{
      return false;
    }
  }

  private canChangePeriod(advance: Advance): boolean {
    let period = Entity.getValue(advance, 'period');

    if (!period || !period.id) {
      return true;
    }else{
      return false;
    }
  }

  private setupCreatedAt(advance: Advance): this {

    if (this.canChangeCreatedAt(advance)) {
      advance.createdAt = new Date();
      this.entityHasBeenChanged = true;
    }

    return this;
  }

  private canChangeCreatedAt(advance: Advance): boolean {
    let advanceCreatedAt = Entity.getValue(advance, 'createdAt');

    if (!advanceCreatedAt || !(advanceCreatedAt instanceof Date || DateHelper.isIso(advanceCreatedAt))) {
      advanceCreatedAt = null;
    }else{
      return false;
    }

    const createdAt = new Date();

    return !advanceCreatedAt && !!createdAt;
  }

  private setupPaymentDate(advance: Advance): this {

    if (this.canChangePaymentDate(advance)) {
      let date = Entity.getValue(advance, 'createdAt');
      if(DateHelper.isIso(date)){
        date = new Date(date);
      }
      if(date instanceof Date){
        const WEDNESDAY = 3;
        let currentDay = date.getDate(),
          currentWeekDay = date.getDay(),
          currentMonth = date.getMonth(),
          currentYear = date.getFullYear(),
          currentHours = date.getHours(),
          newDate = new Date();

        if(currentWeekDay < WEDNESDAY || (currentWeekDay == WEDNESDAY && currentHours < 12)){
          currentDay += WEDNESDAY - currentWeekDay;
        }else if(currentWeekDay >= WEDNESDAY){
          currentDay += (WEDNESDAY + 7) - currentWeekDay;
        }

        newDate.setFullYear(currentYear, currentMonth, currentDay);

        advance.paymentDate = newDate;
        this.entityHasBeenChanged = true;
      }

    }

    return this;
  }

  private canChangePaymentDate(advance: Advance): boolean {
    let paymentDate = Entity.getValue(advance, 'paymentDate');

    if (!paymentDate) {
      return true
    }else{
      return false;
    }
  }

  private parseDate(advance: Advance, property: string): string {
    let advanceDateValue = advance[property];

    if (advanceDateValue instanceof Date) {
      advanceDateValue = advanceDateValue.toISOString().replace(/\.\d+Z/, 'Z');
    }

    return advanceDateValue;
  }
}
