import {
  Component, EventEmitter, Input, OnInit, Output
} from '@angular/core';
import {
  ExpertSearch,
  ExpertSearchField,
  ExpertSearchFieldsContainer
} from '../../../../../../../services/expert-search/expert-search';

@Component({
  selector: 'app-search-management-edit-container-field',
  templateUrl: './search-management-edit-container-field.component.html',
  styles: [`
    .container {
      height: 100%;
    }
    
    .save-button {
      width: 100%;
    }
  `]
})
export class SearchManagementEditContainerFieldComponent implements OnInit {
  @Input() public expertSearch: ExpertSearch = null;
  @Input() public container: ExpertSearchFieldsContainer = null;
  @Input() public field: ExpertSearchField = null;
  @Output() onSave: EventEmitter<ExpertSearchField> = new EventEmitter();

  public defaultComparatorOptions: SelectItem[] = [
    { value: 'equal', label: 'Equals'},
    { value: 'startsWith', label: 'Starts With' },
    { value: 'endsWith', label: 'Ends With' },
    { value: 'contains', label: 'Contains' },
    { value: 'in', label: 'In' },
    { value: 'manyIn', label: 'Many In' },
    { value: 'greaterThan', label: 'Greater than' },
    { value: 'lowerThan', label: 'Lower than' }
  ];
  public comparatorOptions = this.defaultComparatorOptions;

  public typeOptions: SelectItem[] = [
    { value: 'checkbox', label: 'Checkbox' },
    { value: 'dropdown', label: 'Dropdown' },
    { value: 'autocompleteMulti', label: 'Autocomplete Multi' },
    { value: 'autocomplete', label: 'Autocomplete' },
    { value: 'text', label: 'Text' },
    { value: 'number', label: 'Number' },
    { value: 'calendarDate', label: 'Date Calendar' },
    { value: 'genericGrid', label: 'Generic Grid' }
  ];

  public comparatorTypeAvailableOptions = {
    'checkbox': ['equal'],
    'dropdown': ['in'],
    'autocompleteMulti': ['manyIn'],
    'autocomplete': ['in'],
    'text': ['equal', 'startsWith', 'endsWith', 'contains'],
    'number': ['equal', 'greaterThan', 'lowerThan'],
    'calendarDate': ['greaterThan', 'lowerThan'],
    'genericGrid': ['manyIn', 'equal']
  };

  public ngOnInit(): void {
    this.stripAvailableComparatorOptions();
  }

  public onFieldSave(event): void {
     this.field.labelValue = this.field.labelValue || this.field.fieldValue;

     this.onSave.emit(this.field);
  }

  public onFieldTypeChange(event: {value: string}): void {
    this.stripAvailableComparatorOptions();
  }

  public onFieldChange(fieldName: string) {
    this.field.fieldKey = fieldName;
  }

  public onValueChange(changeValue: {value: any, labelValue: string}): void {
    this.field.fieldValue = changeValue.value;
    this.field.labelValue = changeValue.labelValue;
  }

  private stripAvailableComparatorOptions(): void {
    const availableOptions: string[] = this.comparatorTypeAvailableOptions[this.field.typeValue];

    this.comparatorOptions = this.defaultComparatorOptions.filter((option: SelectItem) => {
      return availableOptions.includes(option.value);
    });

    if (this.comparatorOptions.length === 1) {
      this.field.comparator = this.comparatorOptions[0].value;
    }
  }
}
