import { Observable } from 'rxjs';
import { AbstractGenericElementActionHandler } from '../abstract-generic-element-action-handler';
import { ActionHandlerSubscribeResponse } from '../../../../../core/events/interfaces/action-handler-subscribe-response';
import { GenericElementEvent } from '../../event/generic-element-event';
import { GenericTreeGridComponent } from '../../../../content-renderer/elements/generic-tree-grid/generic-tree-grid.component';
import { ElementsStackService } from '../../../../content-renderer/services/elements-stack.service';
import { ElementsStateService } from '../../../../content-renderer/services/elements-state.service';
import { EntityDirtyStoreService, StoreType } from '../../../../content-renderer/services/entity-dirty-store.service';
import { PerformedAction, ElementType } from '../../../../content-renderer/services/ElementContext';
import { ElementState } from '../../../../content-renderer/services/element-state';

export class GenericTreeLoadComponentStateActionHandler extends AbstractGenericElementActionHandler {

    public constructor(
        private elementsStateService: ElementsStateService
    ) {
        super();
    }
    
    protected doAction(event: GenericElementEvent): Observable<ActionHandlerSubscribeResponse> {
        let component = event.getComponent();

        if (component instanceof GenericTreeGridComponent) {
            this.doLoadComponentState(component);
        }
        
        return this.getObservable(true);
    }

    private doLoadComponentState(component: GenericTreeGridComponent) {
        component.componentState = this.elementsStateService.findById(component.moduleElement.id);

        if (component.componentState) {
            // component.gridState = component.componentState;
            component.defaultPageSize = component.componentState.pageSize;
            component.firstEntry = component.componentState.pageSize * component.componentState.currentPage;
            component.currentPage = component.componentState.currentPage;
            component.gridFilters = component.componentState.filters;
            // component.lastSavedEntity = component.componentState.selectedEntity;
        }
    }
    
}
