import {ChangeDetectionStrategy, ChangeDetectorRef, Component} from '@angular/core';
import { ToolbarItemAbstract } from '../toolbar-item-abstract.component';
import { GenericCrudService } from '../../../../../../services/generic-crud.service';
import { MessageGrowlService } from '../../../../../../../core/message/message-growl.service';
import { TranslateService } from '@ngx-translate/core';
import { DataModelFieldCrudService } from '../../../../../../services/datamodel/data-model-field.crud.service';
import { Datamodel } from '../../../../../../services/datamodel/datamodel';
import {forkJoin} from 'rxjs';
import {DataModelAbbrevationCrudService} from '../../../../../../services/datamodel/data-model-abbrevation.crud.service';
import {DataModelAbbrevation} from '../../../../../../services/datamodel/data-model-abbrevation';
import {TreeNode} from 'primeng/primeng';
import {ChangeDetectorRefHelper} from '../../../../../../helpers/change-detector-ref.helper';
import {ClipboardService} from 'ngx-clipboard';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'toolbar-item-word-export-configuration',
  styleUrls: ['./toolbar-item-word-export-configuration.component.scss'],
  templateUrl: './toolbar-item-word-export-configuration.component.html'
})
export class ToolbarItemWordExportConfiguration extends ToolbarItemAbstract {

  public isDialogVisible = false;
  public isDataLoading = false;

  public datamodelFields: TreeNode[] = [];
  public displayedDatamodelFields: TreeNode[] = [];
  public datamodelFieldFilter: string = '';
  public dropdownFormattedFields: SelectItem[] = [];
  public datamodelAbbrevations: DataModelAbbrevation[] = [];
  public completeEntity: any;
  public selectedAbbrevation: DataModelAbbrevation = null;
  public selectedAbbrevations: DataModelAbbrevation[] = [];

  public selectedEntity: any;
  public datamodel: Datamodel;
  public dropdownDatamodels: SelectItem[] = [];
  public comparators: SelectItem[] = [
    {label:'=', value:'='},
    {label:'<', value:'<'},
    {label:'>', value:'>'},
    {label:'<=', value:'<='},
    {label:'>=', value:'>='},
    {label:'!=', value:'!='},
    {label:'isNull', value:'isNull'},
    {label:'isNotNull', value:'isNotNull'},
    {label:'isTrue', value:'isTrue'},
    {label:'isNotTrue', value:'isNotTrue'}
  ];

  /**
   *
   * @param {GenericCrudService} genericCrudService
   * @param {MessageGrowlService} messageGrowlService
   * @param {TranslateService} translate
   * @param {DataModelFieldCrudService} datamodelFieldService
   * @param {DataModelAbbrevationCrudService} datamodelAbbrevationService
   */
  constructor(
    protected genericCrudService: GenericCrudService,
    protected messageGrowlService: MessageGrowlService,
    protected translate: TranslateService,
    protected datamodelFieldService: DataModelFieldCrudService,
    protected datamodelAbbrevationService: DataModelAbbrevationCrudService,
    protected cdr: ChangeDetectorRef,
    protected clipboardService: ClipboardService
  ) {
    super();
  }

  /**
   *
   */
  public click() {
    // If it is in module context, we do not know how to get the proper entity - so we will just not do anything.
    if (this.isInCurrentContext(ToolbarItemAbstract.CONTEXT_MODULE)) {
      return;
    }

    this.isDialogVisible = true;
    this.datamodel = this.getDatamodel();
    this.selectedEntity = this.getEntity();

    this.loadData();
  }

  public copyToClipboard(rowData) {
    this.clipboardService.copy('${' + rowData.id + '}');
    this.messageGrowlService.success('Vollständigen Pfad in die Zwischenablage kopiert', 'Kopiert');
  }

  /**
   *
   * @param event
   */
  public onAbbrevationChange(event) {
    const entity = event['data'];
    entity.isDirty = true;
  }

  /**
   *
   * @param entity
   */
  public onChange(entity) {
    entity.isDirty = true;
  }

  /**
   *
   * @param changeData
   */
  public onAbbrevationChangeInit(changeData) {
    this.selectedAbbrevation = changeData['data'];
  }

  public selectAbbrevation(event, entity): void {
    if (event && !this.selectedAbbrevations.includes(entity)) {
      this.selectedAbbrevations.push(entity);
    } else if (!event) {
      this.selectedAbbrevations.forEach((value, index) => {
        if (value === entity) {
          this.selectedAbbrevations.splice(index, 1);
        }
      })
    }
  }

  /**
   *
   */
  public onAddAbbrevation() {
    const abbrevation = new DataModelAbbrevation();
    abbrevation.datamodel = this.datamodel;
    abbrevation.comparator = this.comparators[0].label;
    this.datamodelAbbrevations = [...this.datamodelAbbrevations, abbrevation];
  }

  /**
   *
   */
  public onSaveAll() {
    for (const abbrevation of this.datamodelAbbrevations) {
      if (!abbrevation.id) {
        this.datamodelAbbrevationService.create(abbrevation).subscribe(createdAbbrevation => {
          // replace abbrevation:
          this.replaceElement(createdAbbrevation, abbrevation);
          this.messageGrowlService.success(this.translate.instant('COMMON.SUCCESS'));
        });
      }

      if (abbrevation.id && abbrevation.isDirty) {
        this.datamodelAbbrevationService.edit(abbrevation).subscribe(editedAbbrevation => {
          // replace abbrevation:
          this.replaceElement(editedAbbrevation, abbrevation);
          this.messageGrowlService.success(this.translate.instant('COMMON.SUCCESS'));
        });
      }
    }
  }

  /**
   *
   */
  public onDeleteAbbrevation() {
    for (const selectedAbbrevation of this.selectedAbbrevations) {
      this.datamodelAbbrevationService.delete(selectedAbbrevation.id).subscribe(() => {
        const index = this.datamodelAbbrevations.indexOf(selectedAbbrevation);

        if (index !== -1) {
          this.datamodelAbbrevations.splice(index);
          this.datamodelAbbrevations = [...this.datamodelAbbrevations];
        }
      });
    }
    this.messageGrowlService.success(this.translate.instant('COMMON.SUCCESS'));
  }

  public onConditionalPropertyChange(event, entity) {
    console.log(event);
  }

  public onDataModelFieldChosen(event, entity) {
    entity.conditionalProperty = event;
    this.onChange(entity);
  }

  public onChangeDatamodel(event, entity) {
    entity.conditionalProperty = '';
    entity._embedded.datamodel = entity.datamodel;
    this.onChange(entity);
  }

  public onChangeComparator(event, entity) {
    this.onChange(entity);
  }

  /**
   *
   * @param newElement
   * @param oldElement
   */
  protected replaceElement(newElement, oldElement) {
    const index = this.datamodelAbbrevations.indexOf(oldElement);
    if (index !== -1) {
      this.datamodelAbbrevations[index] = newElement;
      this.datamodelAbbrevations = [...this.datamodelAbbrevations];
    }
  }

  /**
   *
   */
  protected loadData() {
    this.isDataLoading = true;

    const completeEntity = this.genericCrudService.getEntity(this.datamodel.apiRoute, this.selectedEntity.id, null, {embedded:'none'});
    const datamodelFields = this.datamodelFieldService.getDatamodelFields(this.datamodel, 'tree/level');
    const datamodels = this.genericCrudService.getEntities('superadmin/datamodels');

    forkJoin([completeEntity, datamodelFields, datamodels]).subscribe(results => {
      this.isDataLoading = false;
      this.datamodelFields = results[1];
      this.displayedDatamodelFields = results[1];
      this.completeEntity = results[0];

      this.loadDatamodelAbbrevations();
      this.formatFieldsForDropdown(results[1]);
      this.populateDataModelsDropDown(results[2]);

      ChangeDetectorRefHelper.detectChanges(this);
    });
  }

  populateDataModelsDropDown(dataModels: Datamodel[]) {
    const emptyDataModel = new Datamodel();

    this.dropdownDatamodels = [{
      label: '---',
      value: null
    }];

    dataModels.map((dataModel: Datamodel) => {
      let datamodelElement = {
        label: dataModel.name,
        value: dataModel
      };

      this.dropdownDatamodels = [...this.dropdownDatamodels, datamodelElement];

      ChangeDetectorRefHelper.detectChanges(this);
    });
  }

  filterDatamodels(event) {
    this.datamodelFieldFilter = event.target.value;
    if (!this.datamodelFieldFilter) {
      this.displayedDatamodelFields = this.datamodelFields;
    } else {
      this.displayedDatamodelFields = this.datamodelFields.filter((item) => {
        return item['data']['id'].includes(this.datamodelFieldFilter) || item['data']['name'].includes(this.datamodelFieldFilter);
      });
    }
  }

  expandNode(event) {

    const embeddedEntity = this.genericCrudService.getEntity(this.datamodel.apiRoute, this.selectedEntity.id, null, {embedded: event.node.data.id});
    const moduleElementFields = this.genericCrudService.get(`superadmin/datamodels/${this.datamodel.id}/fields/${event.node.data.id}/tree/level`);
    forkJoin([embeddedEntity, moduleElementFields]).subscribe(results => {
      if (results[0]['_embedded'] && results[0]['_embedded'][event.node.data.id]) {
        this.completeEntity[event.node.data.id] = results[0]['_embedded'][event.node.data.id];
        if (!this.completeEntity['_embedded']) {
          this.completeEntity['_embedded'] = {};
        }
        this.completeEntity['_embedded'][event.node.data.id] = results[0]['_embedded'][event.node.data.id];
      }
      event.node.children = results[1];

      this.datamodelFields = [...this.datamodelFields];
      this.displayedDatamodelFields = [...this.displayedDatamodelFields];

      ChangeDetectorRefHelper.detectChanges(this);
    });
  }

  /**
   *
   * @param fullFieldPath
   * @returns {string}
   */
  public getEntityValue(fullFieldPath) {
    const splitParts = fullFieldPath.split('.');
    let valuePart = this.completeEntity;

    for (const part of splitParts) {
      if (valuePart && valuePart['_embedded'] && valuePart['_embedded'][part]) {
        valuePart = valuePart['_embedded'][part];
      } else if (valuePart.hasOwnProperty(part)) {
        valuePart = valuePart[part];
      }
    }

    return (typeof valuePart !== 'object') ? valuePart : '';
  }

  /**
   *
   */
  protected loadDatamodelAbbrevations() {
    this.datamodelAbbrevationService.getByDatamodel(this.datamodel).subscribe(abbrevations => {
      this.datamodelAbbrevations = abbrevations;

      ChangeDetectorRefHelper.detectChanges(this);
    });
  }

  /**
   *
   * @param fields
   */
  protected formatFieldsForDropdown(fields) {
    this.dropdownFormattedFields = [{ label: 'Bitte wählen', value: 0 }];

    const readFieldsRecursive = (datamodelField, lvl = 0, parentFieldName = '') => {
      this.dropdownFormattedFields = [...this.dropdownFormattedFields, {
        label: `${'.. '.repeat(lvl)}${parentFieldName}${datamodelField.label || ''}`,
        value: datamodelField.data.id
      }];

      if (datamodelField.children && datamodelField.children.length > 0) {
        datamodelField.children.map(
          (dataModelFieldsChildren) =>
            readFieldsRecursive(
              dataModelFieldsChildren,
              (lvl + 1),
              `${parentFieldName}${datamodelField.label || ''}.`
            )
        );
      }
    };

    fields.map((datamodelField) => readFieldsRecursive(datamodelField));
  }
}
