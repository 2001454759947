import {of as observableOf, Observable} from 'rxjs';
import {AbstractExecutionStep} from '../../../../core/executor/abstract-execution-step';
import {ExecutionStepStatus} from '../../../../core/executor/execution-step-status';
import {AbstractGenericGridComponent} from '../../../content-renderer/elements/abstract-generic-grid.component';
import {EntityDataChangeMeta} from '../../../content-renderer/services/entity-data-store.service';
import {EntityManagerService} from '../../../../core/service/entity-manager/entity-manager.service';
import {GenericTurboGridComponent} from '../../../content-renderer/elements/generic-turbo-grid/generic-turbo-grid.component';

export class WorkHourMonthlyEntryCalculateWageExecutionStep extends AbstractExecutionStep {

  protected calculateFields = [
    'durationActual',
    'salaryHour'
  ];

  public doExecute(): Observable<ExecutionStepStatus> {
    const payload = this.getPayload(),
      component = payload.getValue().component ? payload.getValue().component : payload.getValue();

    const entityChangeMetaData = payload.getValue().entityDataChangeMeta;

    if (component instanceof AbstractGenericGridComponent && entityChangeMetaData.entity &&
      entityChangeMetaData.gridField && entityChangeMetaData.gridField.id &&
      this.calculateFields.includes(entityChangeMetaData.gridField.id)
    ) {
      return this.doCalculate(component, entityChangeMetaData);
    }

    return observableOf({status: true, content: null });
  }

  private doCalculate(component: AbstractGenericGridComponent, entityChangeMetaData: EntityDataChangeMeta):
    Observable<ExecutionStepStatus> {
    const entity = entityChangeMetaData.entity,
      changeEvent = entityChangeMetaData.event && entityChangeMetaData.event.changeEvent ?
        entityChangeMetaData.event.changeEvent :
        entityChangeMetaData.event;

    const durationActual = entity.durationActual || 0,
      salaryHour = entity.salaryHour || 0,
      calculatedWage = durationActual * salaryHour;

    this.getEntityManager().persist(entity, {property: 'calculatedWage', newValue: calculatedWage, force: true });

    if (component instanceof GenericTurboGridComponent && changeEvent.name &&
      (changeEvent.name === 'inputValueChange' || changeEvent.name === 'inputBlur')
    ) {
      for (const editComponent of component.editColumnComponents) {
        if (editComponent.decimalComponent) {
          editComponent.decimalComponent.entity = entity;
          editComponent.decimalComponent.initValue();
        }

        editComponent.cdr.markForCheck();
      }
    }

    return observableOf({status: true, content: null });
  }

  private getEntityManager(): EntityManagerService {
    return this.injector.get(EntityManagerService, null);
  }
}
