import {AbstractExecutionStep} from '../../../../core/executor/abstract-execution-step';
import {Observable} from 'rxjs';
import {ExecutionStepStatus} from '../../../../core/executor/execution-step-status';
import {AbstractGenericGridComponent} from '../../../content-renderer/elements/abstract-generic-grid.component';
import {GenericCrudServiceAware} from '../../../../core/executor/type/generic-crud-service-aware';
import {EntityStatus} from '../../entity/entity-status';
import {GenericTreeGridComponent} from '../../../content-renderer/elements/generic-tree-grid/generic-tree-grid.component';

export class GridCancelChangesExecutionStep extends AbstractExecutionStep implements GenericCrudServiceAware {

    public entityDataStoreService;
    public entityDirtyStoreService;
    public requestCachingService;
    public genericCrudService;

    public doExecute(): Observable<ExecutionStepStatus> {
        const component = this.getPayload().getValue();

        if (!(component instanceof AbstractGenericGridComponent)) {
            return this.getFailObservable('You need to pass AbstractGenericGridComponent as Payload value!');
        }

        return this.doCancel(component);
    }

    private doCancel(component: AbstractGenericGridComponent) {
        return Observable.create(observer => {
            let status = true;

            if (!component.getElementDataModelApiRoute()) {
                console.log(`Component ${component} has no datamodel assigned!`);
                status = false;
            }

            const selectedEntity = component.getSelectedEntity();

            if (selectedEntity && !selectedEntity.id) {
              component.setSelectedEntity(null);

              if (component instanceof GenericTreeGridComponent) {
                component.selectedNode = null;
              }
            }

            if (component && component.getElementDataModelApiRoute() && component.hasChanges(false)) {
                this.cancelNewEntitiesChanges(component)
                    .cancelUpdatedEntitiesChanges(component)
                    .cancelEmbeddedEntitiesChanged(component);


                // Now remove the API cached calls:
                this.requestCachingService.removeByExpression(component.getElementDataModelApiRoute());
            }

            observer.next({
                status: status,
                content: ''
            });
            observer.complete();
        });
    }

    private cancelNewEntitiesChanges(gridComponent: AbstractGenericGridComponent): this {

        for (const createdEntity of gridComponent.getCreatedEntities()) {
            gridComponent.removeEntity(createdEntity);
        }

        return this;
    }

    private cancelUpdatedEntitiesChanges(gridComponent: AbstractGenericGridComponent): this {

        for (const entity of gridComponent.getUpdatedEntities()) {
            if (this.entityDirtyStoreService.isDirty(entity) || entity.isChanged) {
              this.cancelEntityChanges(gridComponent, entity);
    }
        }

        return this;
    }

    private cancelEmbeddedEntitiesChanged(gridComponent: AbstractGenericGridComponent): this {

        for (const entity of gridComponent.getEmbeddedEntitiesChanged()) {
            this.cancelEntityChanges(gridComponent, entity);
        }

        return this;
    }

    private cancelEntityChanges(gridComponent: AbstractGenericGridComponent, entity) {
        this.entityDirtyStoreService.remove(entity);
        delete entity[EntityStatus.ENTITY_DRAFT_DELETED_FLAG];
        delete entity[EntityStatus.EMBEDDED_ENTITY_CHANGED_FLAG];
        delete entity[EntityStatus.ENTITY_CHANGED_FLAG];

        gridComponent.onCancelEntityChanges(entity).subscribe();
    }
}
