import { ElementInput } from './element';

export class ElementInputSwitch extends ElementInput {

  onLabel: string;

  offLabel: string;

  constructor() {
    super();
    this.typeElement = 'switch';
    this.defaultValue = true;
    this.onLabel = 'On';
    this.offLabel = 'Off';
  }
}
