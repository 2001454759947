import {ChangeDetectorRef, Component, ElementRef, Injector, OnDestroy} from '@angular/core';
import {AbstractGridColumnComponent} from '../abstract-grid-column.component';
import {DoubleClickService} from '../../../../services/double-click.service';
import {takeUntil} from 'rxjs/operators';
import {FileHelper} from '../../../../../helpers/file-helper';
import {GenericCrudService} from '../../../../../services/generic-crud.service';
import {ChangeDetectorRefHelper} from '../../../../../helpers/change-detector-ref.helper';
import {Subject} from 'rxjs';

@Component({
  selector: 'app-download-file-column',
  templateUrl: `./download-file-column.component.html`,
  styles: [`
    @keyframes ui-progress-spinner-color {
      100%,
      0% {
        stroke: #111;
      }
    }
  `]
})
export class DownloadFileColumnComponent extends AbstractGridColumnComponent implements OnDestroy {

  public isLoading = false;
  public unsubscribe = new Subject<void>();

  public constructor(
    public cdr: ChangeDetectorRef,
    protected elementRef: ElementRef,
    protected doubleClickService: DoubleClickService,
    protected genericCrudService: GenericCrudService,
    protected injector: Injector
  ) {
    super(elementRef);
  }

  public ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  public doDownload() {
    this.isLoading = true;

    const file = this.entity.dmsFile;

    this.genericCrudService.download('phoenix/files/download/' + file.id, {
      fileType: file.fileType,
      fileClassName: file.fqn
    })
      .pipe(
        takeUntil(this.unsubscribe),
      ).subscribe((data) => {
      FileHelper.download({
        content: data,
        fileType: file.fileType,
        name: file.name
      })
      this.isLoading = false;
      ChangeDetectorRefHelper.detectChanges(this);
    });
  }
  public isDisabled(): boolean {
    return this.entity.dmsFile === undefined;
  }
}
