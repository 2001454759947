
import { FormAction, Form, FormActionCondition, FormActionConditionParam } from '../../models/form';
import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Element } from 'app/shared/form-viewer/models/element';

export interface FormActionConditionHandler {
    isValid(): boolean;
}

export abstract class AbstractFormActionConditionHandler implements FormActionConditionHandler {

  private action: FormAction;
  private condition: FormActionCondition;
  private form: Form;
  private formGroup: FormGroup;
  private entity: any;
  private element: Element;

  public abstract isValid();

  public constructor() {
    
  }

  protected getParam(key: string): FormActionConditionParam|null {
    let foundParam = null;

    for (let param of this.condition.params) {
      if (param.key === key) {
        foundParam = param;
        break; 
      }
    }

    return foundParam;
  }

  protected getParamValue(key: string): any|null {
    let value = null,
      param = this.getParam(key);

    return param && param.value ? param.value : null;
  }

  public setAction(action: FormAction): this {
    this.action = action;
    return this;
  }

  public getAction(): FormAction {
    return this.action;
  }

  public setCondition(condition: FormActionCondition): this {
    this.condition = condition;
    return this;
  }

  public getCondition(): FormActionCondition {
    return this.condition;
  }

  public setElement(element: Element): this {
    this.element = element;
    return this;
  }

  public getElement(): Element {
    return this.element;
  }

  public setForm(form: Form): this {
    this.form = form;
    return this;
  }

  public getForm(): Form {
    return this.form;
  }

  public setFormGroup(formGroup: FormGroup): this {
    this.formGroup = formGroup;
    return this;
  }

  public getFormGroup(): FormGroup {
    return this.formGroup;
  }

  public setEntity(entity: any): this {
    this.entity = entity;
    return this;
  }

  public getEntity(): any {
    return this.entity;
  }
}
