import { AbstractFormActionHandler } from './abstract-form-action-handler';
import { Injectable } from '@angular/core';

@Injectable()
export class ShowFieldActionHandler extends AbstractFormActionHandler {

  public handleAction(): void {
    const form = this.getForm();

    if (this.isActionValid()) {
      const formElementHashId = this.getParamValue('formElement'),
        formElement = this.formService.getElementBy(form, 'objectHashId', formElementHashId);

      if (formElement) {
        formElement.isHidden = false;
        this.markElementForCheck(formElement);
      }
    }
  }

  private isActionValid(): boolean {
    return this.getAction().params['formElement'];
  }
}
