import { Injectable, EventEmitter } from '@angular/core';

import { TreeNode, FileUploadModule } from 'primeng/primeng';
import { Element } from '../../../shared/form-viewer/models/index';
import { Subject } from 'rxjs';

@Injectable()
export class ElementService {

  selectedElement: Element;
  selectedElementTreeNode: TreeNode;

  private elementMetaChanged = new Subject<any>();

  public elementMetaChanged$ = this.elementMetaChanged.asObservable();

  constructor() {
  }

  public onElementMetaChanged(element: Element) {
    this.elementMetaChanged.next(element);
  }

  onSelectTreeNode(event) {
    const selectedTreeNode = event.node;
    this.selectedElementTreeNode = selectedTreeNode || undefined;
    this.selectedElement = selectedTreeNode.data || undefined;
  }

  onUnSelectTreeNode(event) {
    this.selectedElement = undefined;
  }

  getTreeJson(element: Element, withChildren = false): TreeNode {
    const node: TreeNode = {};

    if (!element) {
      return node;
    }

    node.data = element;
    node.label = element.label;

    switch (element.typeElement) {
      case 'date':
        node.icon = 'fa fa-calendar';
        break;
      case 'text':
        node.icon = 'fa fa-font';
        break;
      case 'textarea':
        node.icon = 'fa fa-align-justify';
        break;
      case 'dropdown':
        node.icon = 'fa fa-caret-square-o-down';
        break;
      case 'fieldset':
        node.expandedIcon = 'fa fa-folder-open';
        node.collapsedIcon = 'fa fa-folder';
        break;
      case 'globalFields':
        node.expandedIcon = 'fa fa-folder-open';
        node.collapsedIcon = 'fa fa-folder';
        break;
      case 'columns':
        node.icon = 'fa fa-columns';
        break;
      case 'columns-item':
        node.expandedIcon = 'fa fa-folder-open';
        node.collapsedIcon = 'fa fa-folder';
        break;
      case 'tabs':
        node.icon = 'fa fa-tags';
        break;
      case 'tabs-item':
        node.expandedIcon = 'fa fa-folder-open';
        node.collapsedIcon = 'fa fa-folder';
        break;
      case 'password':
        node.icon = 'fa fa-key';
        break;
      case 'number':
        node.icon = 'fa fa-file-excel-o';
        break;
      case 'switch':
        node.icon = 'fa fa-toggle-on';
        break;
      case 'button':
        node.icon = 'fa fa-check';
        break;
      case 'upload':
        node.icon = 'fa fa-upload';
        break;
      case 'placeholder':
        node.icon = 'fa fa-square-o';
        break;
      case 'radio':
        node.icon = 'fa fa-dot-circle-o';
        break;
      case 'checkbox':
        node.icon = 'fa fa-check-square-o';
        break;
      default:
        node.icon = 'fa fa-file-word-o';
        break;
    }

    if (element['children']) {
      node.children = element['children'];
      if (withChildren === true) {
        node.children = element['children'].map((childItem) => {
          return this.getTreeJson(childItem);
        });
      }
    }
    return node;
  }
}
