import { Component, OnInit, Input, OnDestroy, Output, EventEmitter, ViewChild } from '@angular/core';
import { Module } from '../../../services/module/module';
import { Subscription ,  Observable } from 'rxjs';
import { GenericCrudService } from '../../../services/generic-crud.service';
import { ModulesStateService } from '../../services/modules-state.service';
import {GenericDialogModuleService, GenericDialogOptions} from './service/generic-dialog-module.service';
import {LayoutService} from '../../../services/layout-service';
import {Dialog} from 'primeng/dialog';

@Component({
  selector: 'app-generic-dialog-module',
  styles: [
    `
    ::ng-deep .dialog-close-button-container {
      float: right;
      cursor: pointer;
      margin-top: -28px;
    }

    ::ng-deep .ui-dialog-titlebar-maximize {
      margin-right: 13px;
    }
    `
  ],
  templateUrl: './generic-dialog-module.component.html'
})
export class GenericDialogModuleComponent implements OnInit, OnDestroy {
  @Input() module: Module;
  @Input() isAutocompleteModuleState = false;
  @Input() dialogWidth: number;
  @Input() dialogHeight: number;
  @Output() close = new EventEmitter();
  @Input() beforeClose: Observable<boolean> = null;
  @Input() afterClose: Observable<boolean> = null;
  @Input() options: GenericDialogOptions = {};
  @Input() dialogService: GenericDialogModuleService;

  public isVisible = true;

  @ViewChild('dialog', {static: false}) dialog;

  protected subscriptions: Subscription[] = [];

  constructor(
    protected genericCrudService: GenericCrudService,
    protected modulesStateService: ModulesStateService,
    protected layoutService: LayoutService
  ) {}

  public onCloseDialog(event) {
    if (this.beforeClose) {
      this.beforeClose.subscribe((status) => {
        if (status) {
        this.doClose();
        }
      });
    } else {
      this.doClose();
    }
  }

  public onShow(event, dialog: Dialog) {
    if (this.options.maximized) {
      setTimeout(() => {
        this.dialog.maximize();
      }, 20);

      setTimeout(() => {
        window.dispatchEvent(new Event('resize'));
      }, 500);
    }
  }

  ngOnDestroy() {
    this.dialogService.removeState(this);

    for (const subscription of this.subscriptions) {
      subscription.unsubscribe();
    }
  }

  ngOnInit() {
    this.dialogService.createState(this);

    this.subscriptions.push();
  }

  public doClose() {
    this.dialogService.removeState(this);
    this.isVisible = false;

    this.close.emit();

    if (this.afterClose) {
      this.afterClose.subscribe();
    }
  }

  public onDialogMaximize(event) {
    this.dialog.toggleMaximize(event);

    setTimeout(() => {
      window.dispatchEvent(new Event('resize'));
    }, 500);
  }
}
