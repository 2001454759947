import { AbstractValidator } from '../../field-validators/abstract-validator';
import { ModuleElementField } from '../../../services/module/module-element-field';
import { ValidationValue } from '../validation';
import { RequiredValidator } from '../../field-validators/required.validator';
import { MinLengthValidator } from '../../field-validators/min-length.validator';
import { MaxLengthValidator } from '../../field-validators/max-length.validator';
import { UrlValidator } from '../../field-validators/url.validator';
import { EmailValidator } from '../../field-validators/email.validator';
import { CreditCartValidator } from '../../field-validators/credit-card.validator';
import { DateValueSmallerThanValidator } from '../../field-validators/date-value-smaller-than.validator';
import { DateValueBiggerThanValidator } from '../../field-validators/date-value-bigger-than.validator';
import { NoWhiteSpaceValidator } from '../../field-validators/no-white-space.validator';
import { PatternValidator } from '../../field-validators/pattern.validator';
import { IsNotTmpDeletedValidator } from '../../field-validators/is-not-tmp-deleted.validator';
import { UniqueFieldsValidator } from '../../field-validators/unique-fields.validator';
import { IbanFieldsValidator } from '../../field-validators/iban-fields.validator';
import { EntityValidator } from '../../field-validators/entity-validator';
import { AbstractAsyncValidator } from '../../field-validators/abstract-async-validator';
import { GenericCrudService } from '../../../services/generic-crud.service';
import { EntityHydrator } from '../../../services/entity-hydrator.service';
import { Element } from '../../../form-viewer/models/element';
import { CustomerDemandValidator } from '../../field-validators/customer-demand.validator';
import { SocialSecurityNumberValidator } from '../../field-validators/social-security-number.validator';
import { ChildrenElementsAreNotEmptyValidator } from '../../field-validators/form/children-elements-are-not-empty.validator';
import {UserOldPasswordValidator} from '../../field-validators/user-old-password.validator';
import {UserPasswordRepeatValidator} from '../../field-validators/user-password-repeat.validator';
import {MemoValidator} from '../../field-validators/memo.validator';

export class FormValidatorFactory {

    public constructor(
        private genericCrudService: GenericCrudService,
        private entityHydrator: EntityHydrator
    ) {

    }

    public createValidator(validation: Object, element: Element): AbstractValidator {
        let validator = null,
            validatorValue = validation['key'],
            validatorParams = validation['params'] || [];

        let validatorName = validatorValue.split('.')[1];

        switch (validatorName) {
            case 'entity':
                validator = new EntityValidator(validatorParams);
            break;
            case 'required':
                validator = new RequiredValidator(validatorParams);
            break;
            case 'dateSmallerThan':
              let fieldName = '',
                canBeEqual = false;

              if (validatorParams.length > 0) {
                fieldName = validatorParams[0]['value'];
              }

              if (validatorParams.length > 1) {
                canBeEqual = validatorParams[1]['value'];
              }

              validator = new DateValueSmallerThanValidator([{
                key: 'fieldName',
                value: fieldName
              }, {
                key: 'canBeEqual',
                value: canBeEqual
              }]);
            break;
            case 'minLength':
                let minLengthValue = 0;

                if (validatorParams.length === 1) {
                    minLengthValue = +validatorParams[0]['value'];
                }

                validator = new MinLengthValidator([{
                    key: 'minLength',
                    value: minLengthValue
                }]);
            break;
            case 'maxLength':
                let maxLengthValue = 0;

                if (validatorParams.length === 1) {
                maxLengthValue = +validatorParams[0]['value'];
                }

                validator = new MaxLengthValidator([{
                    key: 'maxLength',
                    value: maxLengthValue
                }]);
            break;
            case 'url':
                let urlRequiredValue = false;

                if (validatorParams.length === 1) {
                    urlRequiredValue = validatorParams[0]['value'];
                }

                validator = new UrlValidator([{
                    key: 'required',
                    value: urlRequiredValue
                }]);
            break;
            case 'email':
                let emailRequiredValue = false;

                if (validatorParams.length === 1) {
                    emailRequiredValue = validatorParams[0]['value'];
                }

                validator = new EmailValidator([{
                    key: 'required',
                    value: emailRequiredValue
                }]);
            break;
            case 'isCreditCard':
                validator = new CreditCartValidator(validatorParams);
            break;
            case 'noWhitespace':
                validator = new NoWhiteSpaceValidator(validatorParams);
            break;
          case 'noXXXX':
                validator = new MemoValidator(validatorParams);
            break;
            case 'pattern':
                let patternValue = '';

                if (validatorParams.length === 1) {
                    patternValue = validatorParams[0]['value'];
                }

                validator = new PatternValidator([{
                    key: 'pattern',
                    value: patternValue
                }]);
            break;
            case 'uniqueFields':
                let fieldParams = [];

                if (validatorParams.length > 0) {
                    fieldParams = validatorParams[0]['value'];
                }

                validator = new UniqueFieldsValidator([{
                    key: 'fields',
                    value: fieldParams
                }]);
            break;
            case 'userOldPassword':
              validator = new UserOldPasswordValidator(validatorParams);
            break;
            case 'userPasswordRepeat':
              validator = new UserPasswordRepeatValidator(validatorParams);
            break;
            case 'ibanFields':
                validator = new IbanFieldsValidator(validatorParams);
            break;
            case 'customerStateDemand':
                let statesToCheck = [];

                if (validatorParams.length > 0) {
                    statesToCheck = validatorParams[0]['value'];
                }

                validator = new CustomerDemandValidator([
                {
                    key: 'statesToCheck',
                    value: statesToCheck
                }
                ]);
            break;
            case 'socialSecurityNumber':
                let iso3 = null;

                if (validatorParams.length > 0) {
                    iso3 = validatorParams[0]['value'];
                }

                validator = new SocialSecurityNumberValidator([
                {
                    key: 'iso3',
                    value: iso3
                },
                {
                    key: 'property',
                    value: element.datamodelField
                }
                ]);
            break;
            case 'childrensNotEmpty':
                let childElementsObjectHashIds = [],
                    validWhenAllChildElementsAreEmpty = true;

                if (validatorParams.length > 0) {
                    childElementsObjectHashIds = validatorParams[0]['value'];
                    validWhenAllChildElementsAreEmpty = validatorParams[1]['value'];
                }

                validator = new ChildrenElementsAreNotEmptyValidator([{
                    key: 'childElementsObjectHashIds',
                    value: childElementsObjectHashIds
                }, {
                    key: 'validWhenAllChildElementsAreEmpty',
                    value: validWhenAllChildElementsAreEmpty
                }]);
            break;
        }

        if (validator instanceof AbstractValidator) {
            validator.setField(element)
                .setContext('form')
              .setEntityHydrator(this.entityHydrator);
        }

        if (validator instanceof AbstractAsyncValidator) {
            validator.setGenericCrudService(this.genericCrudService)
                .setEntityHydrator(this.entityHydrator)
        }

        return validator;
    }

}
