<p-dropdown
  [options]="commandsOptions"
  (onChange)="onCommandDropdownChange($event)"
  [required]="true"
  [style]="{'width': '100%'}"
  [filter]="true"
  id="command"
  [(ngModel)]="selectedCommand"
  appendTo="body"
  placeholder="---"
  [group]="true"
></p-dropdown>
