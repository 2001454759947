
import {of as observableOf, Observable} from 'rxjs';
import {map} from 'rxjs/operators';

import {AbstractExecutionStep} from '../../../../core/executor/abstract-execution-step';
import {ExecutionStepStatus} from '../../../../core/executor/execution-step-status';
import {catchError} from 'rxjs/operators';
import {TOAST_LIFE_INFINITE, ToastComponentsRegistry, ToastService} from '../../../../core/service/toast.service';
import {throwError as observableThrowError} from 'rxjs/internal/observable/throwError';
import {TranslateService} from '@ngx-translate/core';

export class SuperadminExportDataToSelectedServerExecutionStep extends AbstractExecutionStep {

  public doExecute(): Observable<ExecutionStepStatus> {
    const payload = this.getPayload(),
      component = payload.getValue().component ? payload.getValue().component : payload.getValue(),
      entity = component.getSelectedEntity();

    if (!entity) {
      return observableOf({status: true, content: 'Server not selected!' })
    }

    const url = `superadmin/exportimport/export/${entity.id}`;

    const toastService = this.injector.get(ToastService);
    const translateService = this.injector.get(TranslateService);


    toastService.custom(ToastComponentsRegistry.PROGRESS_BAR, {
      severity: 'info',
      life: TOAST_LIFE_INFINITE,
      closable: false,
      summary: translateService.instant('COMMON.LOADING_PLEASE_WAIT')
    });

    return this.genericCrudService.customPost(url, {}).pipe(
      map(() => {
        toastService.clear(ToastComponentsRegistry.PROGRESS_BAR);
        return {status: true, content: 'Done!' };
      }));
  }
}
