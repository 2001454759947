
import {of as observableOf, Observable} from 'rxjs';
import {Injectable} from '@angular/core';
import {ExecutorService} from '../../../../../../core/executor/executor.service';
import {ExecutionStepFactoryService} from '../../../../../../core/executor/factory/execution-step-factory.service';
import {ModulesStateService} from '../../../../services/modules-state.service';
import {Question, Questionnaire} from '../../../../../services/element/questionnaire';
import {QuestionnaireComponent} from '../questionnaire.component';


@Injectable()
export class QuestionnaireService {

  public questionnaireComponent: QuestionnaireComponent;
  public executorService: ExecutorService;

  public constructor(
    private stepFactory: ExecutionStepFactoryService,
    private modulesStateService: ModulesStateService
  ) {

  }

  public openFirstQuestion(): Observable<Question> {
    const questionnaire: Questionnaire = this.questionnaireComponent.questionnaire,
      question: Question = questionnaire.questions[0];

    return observableOf(question);
  }

  public openNextQuestion(): Observable<Question> {
    const questionnaire: Questionnaire = this.questionnaireComponent.questionnaire,
      currentQuestionIndex = questionnaire.questions.indexOf(this.questionnaireComponent.question);

    const question: Question = questionnaire.questions[currentQuestionIndex + 1];

    return observableOf(question);
    // return this.open(question);
  }

  public getQuestionIndex(question: Question): number {
    return this.questionnaireComponent.questionnaire.questions.indexOf(question);
  }

}
