import { AbstractInlineEditor } from './abstract-inline-editor';
import {Observable, of} from 'rxjs';

export class TextInlineEditor extends AbstractInlineEditor {

    public onEdit(entity: any, event: any) {
        const value = typeof event.target !== 'undefined' ? event.target.value : event.value;
        this.changeEntityColumnProperty(value);
    }

    public onBlur(entity: any, event: any) {

    }

    public onFocus(entity: any, event: any): Observable<any> {
        if (event.srcElement) {
            event.srcElement.select();
        }

        return of(null);
    }
}
