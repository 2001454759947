
import {ChangeDetectorRef, Component, ElementRef, OnInit} from '@angular/core';
import {GenericCrudService} from '../../../../../services/generic-crud.service';
import {AbstractGridColumnComponent} from '../abstract-grid-column.component';
import {GenericDialogModuleService} from '../../../generic-dialog/service/generic-dialog-module.service';
import {ChangeDetectorRefHelper} from '../../../../../helpers/change-detector-ref.helper';
import {DialogService} from 'primeng/api';
import {WorkflowActionParameterFormComponent} from './workflow-action-parameter-form.component';
import {Entity} from '../../../../../helpers/entity';
import {EntityManagerService} from '../../../../../../core/service/entity-manager/entity-manager.service';
import {EntityStatus} from '../../../../../services/entity/entity-status';
import {EntityDataStoreService} from '../../../../services/entity-data-store.service';


@Component({
  selector: 'app-workflow-action-parameter-column',
  templateUrl: './workflow-action-parameter-column.component.html',
  providers: [DialogService]
})
export class WorkflowActionParameterColumnComponent extends AbstractGridColumnComponent implements OnInit {

  constructor(
    protected elementRef: ElementRef,
    private genericCrudService: GenericCrudService,
    private genericDialogModuleService: GenericDialogModuleService,
    private entityManager: EntityManagerService,
    private dialogService: DialogService,
    private entityDataStore: EntityDataStoreService,
    private cdr: ChangeDetectorRef
  ) {
    super(elementRef);
  }

  ngOnInit() {
  }

  isHidden() {
    return !(this.entity.action && this.entity.action.hasParameters);
  }

  openForm(event) {
    this.genericCrudService.getEntity('workflow/simpleactions', this.entity.action.id).subscribe(action => {
      const ref = this.dialogService.open(WorkflowActionParameterFormComponent, {
        header: 'Set Parameters',
        data: {
          parameters: this.entity['parameters'] ? JSON.parse(this.entity['parameters']) : {},
          config: Entity.getValue(action, 'parameters')
        },
        width: '500px'
      });

      ref.onClose.subscribe((parameters: any[]) => {
        if (parameters) {
          const aParameters = JSON.stringify(parameters);
          this.component.selectedEntity['parameters'] = aParameters;
          this.component.selectedEntity.isChanged = true;
          this.entityManager.persist(this.component.selectedEntity, {property: EntityStatus.ENTITY_CHANGED_FLAG, newValue: true, force: true});
          this.entityManager.persist(this.component.selectedEntity, {property: 'parameters', newValue: aParameters, force: true});

          this.entityDataStore.onEntityChanged({
            entity: this.component.selectedEntity
          });
          ChangeDetectorRefHelper.detectChanges(this.component);
          this.cdr.detectChanges();
        }
      });
    });

  }
}
