import {Component, OnInit} from '@angular/core';
import {AdminDropdownQuestionComponent} from './admin-dropdown-question-component';
import {GenericCrudService} from '../../../../../shared/services/generic-crud.service';

@Component({
  selector: 'app-questionnaire-admin-question-autocomplete',
  template: `
    <div class="ui-g">
        <p-tabView>
            <p-tabPanel header="Labels" leftIcon="pi pi-calendar">
                <div class="ui-g-4">
                  Label:
                </div>
                <div class="ui-g-8">
                  <input type="text" pInputText (keyup)="onChangeLabel($event)" [ngModel]="getParamValue('label')"/>
                </div>
            </p-tabPanel>
            <p-tabPanel header="Datamodel" leftIcon="pi pi-calendar">
                <div class="ui-g-4">
                  Datamodel ID:
                </div>
                <div class="ui-g-8">
                  <p-dropdown
                    appendTo="body" 
                    (onChange)="onChangeDatamodelId($event)"
                    [style]="{'width':'100%'}" 
                    [options]="datamodelOptions" 
                    placeholder="---" 
                    [filter]="true"
                    [ngModel]="getParamValue('datamodelId')"
                  ></p-dropdown>
                </div>
                <div *ngIf="getParamValue('datamodelId')" class="ui-g-4">
                  DatamodelField
                </div>
                <div *ngIf="getParamValue('datamodelId')" class="ui-g-8">
                <app-dialog-choose-datamodel
                  [datamodel]="getParamValue('datamodelField')"
                  [datamodelId]="getParamValue('datamodelId')"
                  (datamodelChange)="onDatamodelFieldChange($event)"></app-dialog-choose-datamodel>
                </div>
            </p-tabPanel>
            <p-tabPanel header="Actions" leftIcon="pi pi-inbox">
                <div class="ui-g-4">
                  On Change:
                </div>
                <div class="ui-g-8">
                  <p-dropdown
                    appendTo="body" 
                    (onChange)="onChangeAction($event, 'onChangeAction')"
                    [style]="{'width':'100%'}" 
                    [options]="stepsOptions" 
                    placeholder="---" 
                    [filter]="true"
                    [ngModel]="getParamValue('onChangeAction')"
                  ></p-dropdown>
                </div>
            </p-tabPanel>
        </p-tabView>
    </div>
  `,
  styles: [`
    :host {
      width: 100%;
    }
  `]
})
export class AdminAutocompleteQuestionComponent extends AdminDropdownQuestionComponent implements OnInit {
  public constructor(
    protected genericCrudService: GenericCrudService
  ) {
    super(genericCrudService);
  }
}
