import {ChangeDetectorRef, Component, OnDestroy, OnInit} from '@angular/core';
import {takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs';
import {CalendarLocaleService} from '../../../../../../core/service/locale/calendar-locale.service';
import {LeasedEmployeeStatCountService} from '../services/leased-employee-stat-count.service';
import {Branch} from '../../../../../services/branch/branch';
import {UserSessionService} from '../../../../../../core/service/user-session.service';

interface ChartData {
  labels: string[];
  datasets: { label: string, data: number[], fill: boolean, borderColor: string }[]
}

interface ChartOptions {
  title: {
    display: boolean,
    text: string
  },
  legend: {
    position: 'bottom'
  },
  scales: {
    xAxis: {
      min: number
    }
  }
}

@Component({
  selector: 'app-custom-staff-cockpit-stat-count-chart-all',
  styleUrls: ['./staff-cockpit-stat-count-chart-all.component.scss'],
  templateUrl: './staff-cockpit-stat-count-chart-all.component.html',
  providers: [CalendarLocaleService, LeasedEmployeeStatCountService]
})
export class StaffCockpitStatCountChartAllComponent implements OnInit, OnDestroy {

  public isLoading = true;
  public unsubscribe = new Subject<void>();
  public data: ChartData = {
    labels: [...Array(52).keys()].map(i => (i + 1).toString()),
    datasets: [
      {
        label: 'Plan',
        data: [],
        fill: false,
        borderColor: '#3883cb'
      },
      {
        label: 'Ist',
        data: [],
        fill: false,
        borderColor: '#e09354'
      }
    ]
  };
  public options: ChartOptions = {
    title: {
      display: true,
      text: 'Mitarbeiter Plan/Ist Filiale'
    },
    legend: {
      position: 'bottom'
    },
    scales: {
      xAxis: {
        min: 0,
      }
    }
  };

  constructor(
    private calendarLocaleService: CalendarLocaleService,
    private cdr: ChangeDetectorRef,
    private leasedEmployeeStatCountService: LeasedEmployeeStatCountService,
    private userSession: UserSessionService
  ) {
  }

  ngOnInit(): void {
    this.leasedEmployeeStatCountService.getAllStats().pipe(
      takeUntil(this.unsubscribe),
    ).subscribe((stats) => {
      this.data = this.buildData(stats);
      this.options = this.getOptions();
      this.isLoading = false;
      this.cdr.detectChanges();
    })
  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  private buildData(stats): ChartData {
    const data = this.data;

    for (const stat of stats) {
      data.datasets[0].data[stat.currentWeek - 1] = data.datasets[0].data[stat.currentWeek - 1] || 0;
      data.datasets[1].data[stat.currentWeek - 1] = data.datasets[1].data[stat.currentWeek - 1] || 0;
      data.datasets[0].data[stat.currentWeek - 1] = data.datasets[0].data[stat.currentWeek - 1] + stat.plannedValue;
      data.datasets[1].data[stat.currentWeek - 1] = data.datasets[1].data[stat.currentWeek - 1] + stat.realValue;
    }

    return data;
  }

  getOptions(): ChartOptions {
    return {
      title: {
        display: true,
        text: `Mitarbeiter Plan/Ist ${this.userSession.get(Branch.LOCAL_STORAGE_NAME).name}`
      },
      legend: {
        position: 'bottom'
      },
      scales: {
        xAxis: {
          min: 0,
        }
      }
    }
  }
}

