import {TableColumn} from '../../../../../dynamic-table/shared/table-column';
import {Observable} from 'rxjs';
import {GenericCrudService} from '../../../../../services/generic-crud.service';
import {ApiBuilderService} from '../../../../../services/api.builder.service';

export abstract class AbstractTableService {

  private params: Object = {};
  private filters: Object = {};

  protected abstract getApiRoute();

  public constructor(
    protected genericCrudService: GenericCrudService,
    protected apiBuilderService: ApiBuilderService
  ) {
  }

  public loadData(): Observable<any> {
    return this.genericCrudService.getPaginated(this.apiBuilderService.getPaginateApiRoute(this.params), this.filters);
  }

  public setParams(event: any): this {
    const params = {
      apiRoute: this.getApiRoute(),
      offset: event.first,
      limit: event.rows,
      orderBy: event.sortField || 'id',
      orderDirection: event.sortOrder === 1 ? 'asc' : 'desc'
    };

    this.params = params;

    return this;
  }

  public setFilter(filterKey: string, filterValue: any): this {
    this.filters[filterKey] = filterValue;

    if (!filterValue) {
      delete this.filters[filterKey];
    }

    return this;
  }

}
