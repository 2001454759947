import { FormService } from './form.service';
import { EventEmitter, Injectable } from '@angular/core';
import { Form, Element } from './models/';


@Injectable()
export class FormViewerService {

  public inputValueChanged: EventEmitter<any> = new EventEmitter<any>();

  public formEntityChanged: EventEmitter<any> = new EventEmitter<any>();

  private areFormElementsValid: boolean = true;

  public inputChanged;

  public formEntity;

  constructor(
    private formService: FormService
  ) {
    this.inputValueChanged.subscribe((inputChanged: any) => this.inputChanged = inputChanged);
    this.formEntityChanged.subscribe((entity: any) => this.formEntity = entity);
  }

  public isValid(form: Form): boolean {
    this.areFormElementsValid = true;

    this.areFormElementChildrenValid(form.elements);

    return this.areFormElementsValid;
  }

  private areFormElementChildrenValid(elements: Element[]) {
    for (let formElement of elements) {

      if (!formElement.isValid) {
        this.areFormElementsValid = false;

        break;
      }

      this.areFormElementChildrenValid(formElement.children);
    }
  }

}
