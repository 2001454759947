import {
  Component,
  ChangeDetectionStrategy
} from '@angular/core';
import {ElementInputAutocompleteComponent, SelectItemExtended} from './element-input-autocomplete.component';
import places from 'places.js';
import {environment} from '../../../../environments';
import * as Places from 'places.js';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-form-element-input-algolia-places',
  template: `
    <div [formGroup]="formGroup" class="ui-g ui-g-12">
      <div
        [class]="element.hasCustomButton ||
        ((element?.icon | isDefined) &&
        (element?.icon?.relativePath | isDefined))? 'ui-g ui-g-11' : 'ui-g ui-g-12'"
      >
        <input
          type="text"
          id="address-input"
          placeholder="{{element.placeholder}}"
          [readonly]="isReadOnly()"
          [tabIndex]="element.tabIndex"
        />
      </div>
      <div class="icon-container ui-g ui-g-1" *ngIf="((element?.icon | isDefined) && (element?.icon?.relativePath | isDefined))">
        <img height="16" width="16"
             [ngClass]="{
          'cur-pointer': isValid(),
          'cur-disabled': !isValid(),
          'disabled': !isValid()
        }"
             [src]="iconBaseUrl + '/' + element?.icon?.relativePath"
             [alt]="element?.icon?.name"
             (click)="onClickIcon()"
        />
      </div>
      <div *ngIf="element.hasCustomButton" class="custom-button-container ui-g ui-g-1">
        <i
          (click)="onCustomButtonClick($event)"
          [class]="isReadOnlyOrDisabled() ? 'fa fa-plus fa-disabled' : 'fa fa-plus'"
          aria-hidden="true"
        >
        </i>
      </div>
    </div>
  `,
  styles: [`
      :host {
        height: 100%;
      }

      .ui-g {
        height: 100%;
      }
    `]
})
export class ElementInputAlgoliaPlacesComponent extends ElementInputAutocompleteComponent {

  public selectedSuggestion: Places.Suggestion = null;
  public placesAutocomplete: Places.PlacesInstance = null;

  public ngOnInit() {
    super.ngOnInit();

    this.placesAutocomplete = places({
      appId: environment.algoliaAppId,
      apiKey: environment.algoliaApiKey,
      container: this.elementRef.nativeElement.querySelector('#address-input')
    });

    this.placesAutocomplete.on('change', e => {
      this.selectedSuggestion = e.suggestion;
      this.setValue({
        value: e.suggestion.name,
        label: e.suggestion[this.element.datamodelField]
      })
    });

    this.placesAutocomplete.on('clear', () => {
      this.selectedSuggestion = null;
      this.setValue(null)
    });
  }

  public setupValue(ignoreResetCollection: boolean = false) {
    const entity = this.element.getEntity(),
      label = this.getEntityLabel(entity),
      value = this.getEntityValue();

    if (value && !ignoreResetCollection) {
      let selectedOptionEntity = null;

      if (this.element.getEntity() && this.element.getEntity()._embedded &&
        this.element.getEntity()._embedded[this.element.datamodelField]
      ) {
        selectedOptionEntity = this.element.getEntity()._embedded[this.element.datamodelField];
      }

      const selectedOption = {
        label: label,
        value: value,
        entity: selectedOptionEntity
      };

      this.suggestions = [...this.suggestions, selectedOption];

      this.setValue(selectedOption, false).getSelectedOption(selectedOption);

      if (event && event.type !== 'load') {
        this.setValue(selectedOption, false);
      }
    } else if (!value && this.element.defaultValue) {
      this.setDefaultValue();
    } else if (!value && !this.element.defaultValue && this.element.selectIfSingleValue) {
      this.setFirstValue();
    } else if (value === null || value === '' || typeof value === 'undefined') {
      this.setValue(null, false);
    }
  }

  protected setDefaultValue(): void {
    console.log('Not yet implemented...');
  }

  protected setFirstValue(): void {
    console.log('Not yet implemented...');
  }

  public setValue(selectedOption: SelectItemExtended, triggerChange: boolean = true, entityValueEmpty: boolean = false) {
    const entityValue = this.getEntityValueFromChange(selectedOption, entityValueEmpty);

    this.setAutocompleteValue(entityValue);

    super.setValue(selectedOption, triggerChange, entityValueEmpty);

    return this;
  }

  public setAutocompleteValue(value: any): void {
    if (this.placesAutocomplete) {
      this.placesAutocomplete.setVal(value !== 'null' ? value : '');
      this.placesAutocomplete.close();
    }
  }
}
