import { ElementButton } from './element';
import { Icon } from './../../../shared/models/Icon';

export class ElementButtonDefault extends ElementButton {

  icon: Icon;

  constructor(
    public typeElement = 'default',
    public width = 100,
    public disabled: boolean = false,
    public readOnly: boolean = false,
  ) {
    super();

    this.icon = undefined;
    this.disabled = disabled;
    this.readOnly = readOnly;
  }
}
