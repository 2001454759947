import { AbstractFormActionHandler } from '../abstract-form-action-handler';
import { Entity } from '../../../../helpers/entity';
export class SetupWorkplaceFormActionHandler extends AbstractFormActionHandler {
    constructor() {
        super(...arguments);
        this.mapping = {
            'hasAgreementAspects': [
                'agreementAspectNotes'
            ],
            'hasHolidayAgreementAspects': [
                'holidayAgreementAspectNotes'
            ],
            'isHeavyLabourWorkplace': [
                'isShiftWork',
                'isRegularHeatCold',
                'isHeavyLabour',
                'isOccupationalCare',
                'isReducedEarningCapacity'
            ],
            'hasSpecialFeatures': [
                'specialFeatureNotes'
            ],
            'hasSecurityDocuments': [
                'nightlyHeavyLabourNotes'
            ]
        };
    }
    handleAction() {
        const workplace = this.getEntity();
        if (this.isValid(workplace)) {
            this.handleVisibility(workplace);
        }
    }
    isValid(workplace) {
        return workplace && workplace.fqn &&
            workplace.fqn === 'PhoenixBundle\\Entity\\Workplace';
    }
    handleVisibility(workplace) {
        this.hideAll();
        for (const checkboxProperty in this.mapping) {
            if (this.mapping.hasOwnProperty(checkboxProperty)) {
                const isChecked = Entity.getValue(workplace, checkboxProperty);
                if (isChecked) {
                    this.showFields(this.mapping[checkboxProperty]);
                }
                else {
                    this.hideFields(this.mapping[checkboxProperty]);
                }
            }
        }
        return this;
    }
    hideAll() {
        for (const checkboxProperty in this.mapping) {
            if (this.mapping.hasOwnProperty(checkboxProperty)) {
                const formElement = this.formService.getElementBy(this.getForm(), 'datamodelField', this.mapping[checkboxProperty]);
                if (formElement) {
                    formElement.isHidden = true;
                    this.markElementForCheck(formElement);
                }
            }
        }
        return this;
    }
    showFields(datamodelFields) {
        for (const datamodelField of datamodelFields) {
            const formElement = this.formService.getElementBy(this.getForm(), 'datamodelField', datamodelField);
            if (formElement) {
                formElement.isHidden = false;
                this.markElementForCheck(formElement);
            }
        }
        return this;
    }
    hideFields(datamodelFields) {
        for (const datamodelField of datamodelFields) {
            const formElement = this.formService.getElementBy(this.getForm(), 'datamodelField', datamodelField);
            if (formElement) {
                formElement.isHidden = true;
                this.markElementForCheck(formElement);
            }
        }
        return this;
    }
}
