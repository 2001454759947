import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'toSelectItem',
  pure: true
})
export class ToSelectItemPipe implements PipeTransform {
  transform(records: any, labelKey: string, value?: any, ...args: any[]): SelectItem[] {
    const selectItems: SelectItem[] = [];

    if (records && records.length) {
      records.map((record) => {
        let sValue: any, sLabel: string;

        if (record.hasOwnProperty('label')) {
          sLabel = record['label'];
        } else {
          sLabel = record[labelKey];
        }
        if (record.hasOwnProperty('value')) {
          sValue = record['value'];
        } else {
          sValue = record;
        }

        selectItems.push({
          label: sLabel,
          value: sValue
        });
      });
    }

    return selectItems;
  }
}
