import { of as observableOf } from 'rxjs';
import { ChangeDetectorRefHelper } from '../../../../helpers/change-detector-ref.helper';
import { MEMO_EMBEDDED_ENTITIES, MemoFieldDefinitionValueComponent, MemoTypeFieldDefinitionType } from '../memo-field-definition-value/memo-field-definition-value.component';
import { map, takeUntil } from 'rxjs/operators';
import { AbstractGenericGridComponent } from '../../abstract-generic-grid.component';
import { Entity } from '../../../../helpers/entity';
export class MemoFieldDmsSearchComponent extends MemoFieldDefinitionValueComponent {
    constructor() {
        super(...arguments);
        this.toolbarItems = [];
        this.statusBarItems = [];
        this.masterEntity = null;
        this.masterField = null;
        this.isPart = false;
        this.visibleGroupedMemoTypeFieldDefinitions = [];
        this.visibleByTypeMemoTypeFieldDefinitions = [];
        this.configuration = {
            ownerApi: 'dms/dmsfiles',
            fieldDefinitionValuesApi: 'dms/memofielddefinitionvalues',
            memoTypeFieldDefinitionsApi: 'dms/memotypefielddefinitions',
            memoTypeApi: 'dms/memotypes',
            memoTypeDefinedOutside: false,
            memoTypeRelation: 'memoType',
            embeddedEntities: MEMO_EMBEDDED_ENTITIES,
            usesExternalRefresh: false,
            search: true,
        };
    }
    ngOnInit() {
        super.ngOnInit();
        this.onRefresh()
            .pipe(takeUntil(this.unsubscribe))
            .subscribe();
    }
    onMemoTypeChanged(memoType) {
        this.memoType = memoType;
        this.onRefresh()
            .pipe(takeUntil(this.unsubscribe))
            .subscribe();
    }
    getFilters() {
        const filters = super.getFilters();
        filters.push({
            name: 'ignoreBranchOffice',
            value: '1'
        });
        return filters;
    }
    getAutocompleteFilters(memoTypeFieldDefinition) {
        const filters = super.getAutocompleteFilters(memoTypeFieldDefinition);
        filters.push({
            name: 'ignoreBranchOffice',
            value: '1'
        });
        return filters;
    }
    onSearchChange(event) {
        if (this.selectedSearch === 'all') {
            this.memoType = null;
        }
        const component = this.getGridComponent();
        if (component instanceof AbstractGenericGridComponent) {
            component.clearEntities();
        }
        this.onRefresh()
            .pipe(takeUntil(this.unsubscribe))
            .subscribe();
    }
    onSearch() {
        const component = this.getGridComponent(), memoTypeFieldDefinitions = this.visibleMemoTypeFieldDefinitions
            .filter((aMemoTypeFieldDefinition) => aMemoTypeFieldDefinition.searchValue);
        if (component instanceof AbstractGenericGridComponent) {
            if (!component.embeddedFields.includes('fieldDefinitionValues')) {
                component.embeddedFields.push('fieldDefinitionValues');
            }
            component.staticFilters = [];
            if (this.memoType) {
                component.staticFilters.push({
                    field: 'memoType',
                    value: this.memoType.id
                });
            }
            if (memoTypeFieldDefinitions.length > 0) {
                const filterValues = [];
                for (const memoTypeFieldDefinition of memoTypeFieldDefinitions) {
                    if (memoTypeFieldDefinition.searchMemoTypeFieldDefinitions && memoTypeFieldDefinition.searchMemoTypeFieldDefinitions.length > 0) {
                        for (const aMemoTypeFieldDefinition of memoTypeFieldDefinition.searchMemoTypeFieldDefinitions) {
                            filterValues.push({
                                value: memoTypeFieldDefinition.searchValue,
                                fieldDefinition: aMemoTypeFieldDefinition.id
                            });
                        }
                    }
                    else {
                        filterValues.push({
                            value: memoTypeFieldDefinition.searchValue,
                            fieldDefinition: memoTypeFieldDefinition.id
                        });
                    }
                }
                component.staticFilters.push({
                    field: `fieldDefinitionValues.id`,
                    value: `dmsFieldDefinitionValue:${JSON.stringify(filterValues)}`
                });
            }
            /*if (component.staticFilters.findIndex((a) => a.field === 'id') === -1) {
              component.staticFilters.push({
                field: `id`,
                value: `dmsFilePermission:DmsBundle\\Entity\\DmsFile`
              });
            }*/
            component.loadEntities()
                .pipe(takeUntil(component.unsubscribe))
                .subscribe();
        }
    }
    onFieldDefinitionValueEdit(event, entity) {
        const dataType = entity.fieldDefinitionTemplate.dataType.code;
        let value = null;
        switch (dataType) {
            case MemoTypeFieldDefinitionType.CODE_DROPDOWN:
            case MemoTypeFieldDefinitionType.CODE_AUTOCOMPLETE:
            case MemoTypeFieldDefinitionType.CODE_BRANCH_OFFICE:
            case MemoTypeFieldDefinitionType.CODE_LEASED_EMPLOYEE:
            case MemoTypeFieldDefinitionType.CODE_CUSTOMER:
            case MemoTypeFieldDefinitionType.CODE_INTEGER:
            case MemoTypeFieldDefinitionType.CODE_DECIMAL:
                value = event.value;
                break;
            case MemoTypeFieldDefinitionType.CODE_DATETIME:
            case MemoTypeFieldDefinitionType.CODE_DATE:
            case MemoTypeFieldDefinitionType.CODE_CHECKBOX:
                value = event;
                break;
            case MemoTypeFieldDefinitionType.CODE_TEXT:
                value = event.target.value;
                break;
        }
        entity.searchValue = value;
        this.clearFilteredFields(entity, event);
        const memoFieldDefinitionValue = this.findMemoFieldDefinitionValue(entity);
        if (memoFieldDefinitionValue) {
            memoFieldDefinitionValue.value = value;
        }
        else {
            this.fieldDefinitionValues = [...this.fieldDefinitionValues, {
                    fieldDefinition: entity,
                    value
                }];
        }
        this.executeAction(10 /* EntityValueChanged */, {
            component: this,
            entityDataChangeMeta: {
                entity,
                event
            }
        }).pipe(takeUntil(this.unsubscribe))
            .subscribe();
    }
    onRefresh() {
        this.isLoadingData = true;
        this.visibleMemoTypeFieldDefinitions = [];
        return this.loadMemoTypeFieldDefinitions(this.memoType)
            .pipe(map((response) => {
            this.visibleGroupedMemoTypeFieldDefinitions = this.getGroupedMemoTypeFieldDefinitions(response);
            if (this.selectedSearch === 'all') {
                this.visibleMemoTypeFieldDefinitions = this.visibleGroupedMemoTypeFieldDefinitions.filter((aMemoTypeFieldDefinition) => {
                    return aMemoTypeFieldDefinition.fieldDefinitionTemplate.isVisibleInGlobalSearch;
                });
            }
            if (this.selectedSearch === 'type' && this.memoType) {
                this.visibleMemoTypeFieldDefinitions = response;
            }
            this.isLoadingData = false;
            this.toolbarItemCheckService.check(this);
            ChangeDetectorRefHelper.detectChanges(this);
            return { status: true, content: null };
        }));
    }
    getSelectedEntity() {
        return this.selectedMasterEntity || null;
    }
    recheckToolbarItems() {
        this.toolbarItemCheckService.check(null);
    }
    onSave() {
        return observableOf(null);
    }
    getGridComponent() {
        const context = this.elementsStackService.findByComponentDatamodelAndType('DmsBundle\\Entity\\DmsFile', 1 /* Grid */);
        return context.component;
    }
    getGroupedMemoTypeFieldDefinitions(response) {
        const grouped = [];
        if (this.selectedSearch === 'all') {
            response.sort(function (first, second) {
                return first.name <= second.name ? -1 : 1;
            });
        }
        for (const d of response) {
            const index = grouped.findIndex((memoTypeFieldDefinition) => {
                return Entity.getValueInEmbedded(memoTypeFieldDefinition, 'fieldDefinitionTemplate.id') === Entity.getValueInEmbedded(d, 'fieldDefinitionTemplate.id');
            });
            if (index === -1) {
                d.searchMemoTypeFieldDefinitions = [d];
                grouped.push(d);
            }
            else {
                grouped[index].searchMemoTypeFieldDefinitions.push(d);
            }
        }
        return grouped;
    }
}
