
import {throwError as observableThrowError, forkJoin as observableForkJoin, Observable} from 'rxjs';

import {map, catchError} from 'rxjs/operators';
import {ChangeDetectionStrategy, ChangeDetectorRef, Component} from '@angular/core';
import {ToolbarItemAbstract} from '../toolbar-item-abstract.component';
import {GenericCrudService} from '../../../../../../services/generic-crud.service';
import {MessageGrowlService} from '../../../../../../../core/message/message-growl.service';
import {TranslateService} from '@ngx-translate/core';
import {environment} from '../../../../../../../../environments';
import {Constants} from 'app/constants';
import {FileData} from '../../../../../../../core/file/file-data';
import {FileService} from '../../../../../../../core/file/file.service';
import {RequestCachingService} from '../../../../../../services/request-caching.service';
import {TOAST_LIFE_INFINITE, ToastComponentsRegistry, ToastService} from '../../../../../../../core/service/toast.service';
import {CloudPrinter} from '../../../../../../services/cloud-printer/cloud-printer';
import {ChangeDetectorRefHelper} from '../../../../../../helpers/change-detector-ref.helper';
import {AuthenticationService} from '../../../../../../../core/authentication/authentication.service';
import {ToolbarItemWordExport} from './toolbar-item-word-export.component';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-toolbar-item-word-export-invoice',
  styleUrls: ['./toolbar-item-word-export.component.scss'],
  templateUrl: './toolbar-item-word-export.component.html'
})
export class ToolbarItemWordExportInvoice extends ToolbarItemWordExport {

  public wordExportUrlAdditionalQueryParams = {entitiesFromField: 'invoicePositions'}

  protected downloadFile(fileResponse) {
    this.messageGrowlService.success(this.translate.instant('COMMON.SUCCESS'));
  }
}
