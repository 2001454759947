
import {of as observableOf, Observable} from 'rxjs';
import {AbstractExecutionStep} from '../../../core/executor/abstract-execution-step';
import {ExecutionStepStatus} from '../../../core/executor/execution-step-status';
import {AbstractGenericGridComponent} from '../../content-renderer/elements/abstract-generic-grid.component';
import {RuntimeFlagName} from '../../content-renderer/services/ElementContext';

export class ComponentFocusInlineEditorExecutionStep extends AbstractExecutionStep {

  public doExecute(): Observable<ExecutionStepStatus> {
    const payload = this.getPayload(),
      component = payload.getValue().component ? payload.getValue().component : payload.getValue();

    if (!(component instanceof AbstractGenericGridComponent)) {
      return this.getFailObservable('You need to pass AbstractGenericGridComponent as Payload value!');
    }

    return this.doFocus(component, payload.getValue().editingCell);
  }

  protected doFocus(component: AbstractGenericGridComponent, editingCell: any = null): Observable<ExecutionStepStatus> {
    editingCell = editingCell || component.grid.editingCell;

    if (editingCell) {
      const rowIndex = editingCell.parentElement.rowIndex,
        cellIndex = editingCell.cellIndex;

      component.getElementContext().addRuntimeFlag({
        name: RuntimeFlagName.TriggerSlaveDisabled,
        status: null,
        active: true
      }).addRuntimeFlag({
        name: RuntimeFlagName.ExecuteActionsDisabled,
        status: null,
        active: true
      });

      setTimeout(() => {
        const table = component.grid.tbody.closest('table');

        if (table) {
          const td = table.rows[rowIndex].cells[cellIndex];

          component.getInlineEditService().switchCellToEditMode(td);
        }

        component.getElementContext()
          .removeRuntimeFlag(RuntimeFlagName.ExecuteActionsDisabled)
          .removeRuntimeFlag(RuntimeFlagName.TriggerSlaveDisabled);
      });
    }

    return observableOf({status: true, content: null });
  }
}
