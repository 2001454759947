import {ChangeDetectionStrategy, Component} from '@angular/core';
import {Subscription} from 'rxjs';
import {TranslateService} from '@ngx-translate/core';
import {GenericElementAbstract} from 'app/shared/content-renderer/elements/generic-element-abstract.component';
import {ExecutionStepPayload} from 'app/core/executor/execution-step-payload';
import {EntityValidationExecutionStep} from 'app/shared/services/execution-step/validation/entity-validation-execution-step';
import {ExecutionStatus} from 'app/core/executor/execution-status';
import {ToolbarItemAbstract} from '../../toolbar-item-abstract.component';
import {GenericCrudBulkService} from '../../../../../../../services/generic-crud-bulk.service';
import {MessageGrowlService} from '../../../../../../../../core/message/message-growl.service';
import {ComponentService} from '../../../../../../services/component-highlight-stack.service';
import {MessageService} from '../../../../../../../../core/message/message.service';
import {ToolbarItemSaveService} from '../../../services/toolbar-item-save.service';
import {ExecutorService} from '../../../../../../../../core/executor/executor.service';
import {ExecutionStepFactoryService} from '../../../../../../../../core/executor/factory/execution-step-factory.service';
import {ModulesStateService} from '../../../../../../services/modules-state.service';
import {GenericDialogModuleService} from '../../../../../generic-dialog/service/generic-dialog-module.service';
import {TOAST_LIFE_INFINITE, ToastComponentsRegistry, ToastService} from '../../../../../../../../core/service/toast.service';
import {ComponentSaveExecutionStep} from '../../../../../../../services/execution-step/component-save-execution-step';
import {ExecutorActionEvent} from '../../../../../../../../core/executor/service/executor-actions/executor-action-event';
import {ComponentRefreshExecutionStep} from '../../../../../../../services/execution-step/component-refresh-execution-step';
import {BlankValidationExecutionStep} from '../../../../../../../services/execution-step/validation/blank-validation-execution-step';
import {ComponentValidationExecutionStep} from '../../../../../../../services/execution-step/validation/component-validation-execution-step';
import {ExecutionStep} from '../../../../../../../../core/executor/execution-step';
import {ExecutionStatusError} from '../../../../../../../../core/executor/execution-status-error';
import {ComponentValidationOnSaveExecutionStep} from '../../../../../../../services/execution-step/validation/component-validation-on-save-execution-step';
import {MemoFieldDefinitionValueComponent} from '../../../../../custom/memo-field-definition-value/memo-field-definition-value.component';
import {AbstractGenericGridComponent} from '../../../../../abstract-generic-grid.component';
import {CloseWorkflowStepExecutionStep} from '../../../../../../../services/execution-step/workflow/close-workflow-step-execution.step';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'toolbar-item-save-and-close-step',
  templateUrl: './toolbar-item-save-and-close-step.component.html',
  providers: [
    ToolbarItemSaveService,
    ExecutorService
  ]
})
export class ToolbarItemSaveAndCloseStepComponent extends ToolbarItemAbstract {

  private dialogDisplay = false;
  protected formSaveSubscription: Subscription;

  constructor(
    protected genericCrudBulkService: GenericCrudBulkService,
    protected messageGrowlService: MessageGrowlService,
    protected componentService: ComponentService,
    protected translateService: TranslateService,
    protected messageService: MessageService,
    protected toolbarItemSaveService: ToolbarItemSaveService,
    protected executorService: ExecutorService,
    protected executionStepFactoryService: ExecutionStepFactoryService,
    protected modulesStateService: ModulesStateService,
    protected genericDialogModuleService: GenericDialogModuleService,
    protected toastService: ToastService
  ) {
    super();
  }

  public click() {
    const formComponent = this.getComponent();
    const fieldValueComponent = this.getQuestionnaireComponent();
    const gridComponent = this.getGridComponent();

    if (!fieldValueComponent) {
      return;
    }

    const steps = [
      this.getComponentValidationStep(formComponent),
      this.executionStepFactoryService.create(ComponentSaveExecutionStep, new ExecutionStepPayload(formComponent)),
      this.executionStepFactoryService.create(ComponentSaveExecutionStep, new ExecutionStepPayload(fieldValueComponent)),
      this.executionStepFactoryService.create(CloseWorkflowStepExecutionStep, new ExecutionStepPayload(formComponent.getSelectedEntity())),
    ];

    steps.push(this.executionStepFactoryService.create(ComponentRefreshExecutionStep,
      new ExecutionStepPayload(formComponent)
    ));

    this.executorService
      .setSteps(steps)
      .execute()
      .subscribe((status: ExecutionStatus) => {
        if (!status.isSuccess()) {
          this.onExecutorFailure(status);
        } else {
          this.messageGrowlService.success(
            this.translateService.instant('COMMON.DATA_SAVED'),
            this.translateService.instant('COMMON.SUCCESS')
          );
          gridComponent.executeAction(ExecutorActionEvent.ComponentSaved, gridComponent).subscribe();

          if (gridComponent) {
            gridComponent.onRefresh().subscribe();
            this.executorService.setSteps([this.executionStepFactoryService.create(ComponentRefreshExecutionStep, new ExecutionStepPayload(gridComponent))]).execute().subscribe();
          }
        }
      });
  }

  protected getQuestionnaireComponent(): MemoFieldDefinitionValueComponent|null {
    for (const component of this.modulesStateService.getCurrent().components) {
      if (component instanceof MemoFieldDefinitionValueComponent) {
        return component;
      }
    }

    return null;
  }

  protected getGridComponent(): AbstractGenericGridComponent|null {
    for (const component of this.modulesStateService.getCurrent().components) {
      if (component instanceof AbstractGenericGridComponent) {
        return component;
      }
    }

    return null;
  }

  protected getComponentValidationStep(component: GenericElementAbstract): ExecutionStep {
    return (component.ignoreValidations())
      ? this.executionStepFactoryService.create(BlankValidationExecutionStep, new ExecutionStepPayload(component))
      : this.executionStepFactoryService.create(ComponentValidationExecutionStep, new ExecutionStepPayload(component));
  }

  protected refreshComponent(component: GenericElementAbstract) {
    component.onRefresh().subscribe();
  }

  public onExecutorFailure(status: ExecutionStatusError) {
    if (status.getStep() instanceof ComponentValidationExecutionStep ||
      status.getStep() instanceof EntityValidationExecutionStep ||
      status.getStep() instanceof ComponentValidationOnSaveExecutionStep
    ) {
      this.messageService.confirm({
        acceptVisible: true,
        rejectVisible: false,
        header: this.translateService.instant('COMMON.ERROR'),
        message: status.getStepContent(),
        accept: () => {

        }
      });
    }
  }
}
