<div class="{{!isChecked ? 'greyed-out' : ''}} image flex-item">
  <button
    pButton
    *ngIf="imageSource"
    class="image flex-item"
    (click)="onToggle($event)"
    [label]="title"
    [iconUrl]="imageSource"
    [pTooltip]="tooltipText"
    tooltipPosition="top"
  ></button>
</div>
