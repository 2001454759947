
import {of as observableOf, Observable} from 'rxjs';

import {map} from 'rxjs/operators';
import {ChangeDetectorRef, Component, Input, ViewContainerRef} from '@angular/core';
import {ExecutorService} from '../../../../../core/executor/executor.service';
import {GenericElementAbstract} from '../../generic-element-abstract.component';
import {FieldMetadataGrid} from '../../../../services/module/module-element-field-metadata-grid';
import {ModuleElement} from '../../../../services/module/module-element';
import {EntityValidator, EntityValidatorStatus} from '../../../../validators/services/entity-validator';
import {Element} from '../../../../services/element/element';
import {ToolbarItemCheckService} from '../../generic-toolbar/services/check/toolbar-item-check.service';
import {GenericElementValidationExecutionStepsFactory} from '../../../services/generic/generic-element-validation-execution-steps-factory';
import {EntityDataStoreService} from '../../../services/entity-data-store.service';
import {ModulesStateService} from '../../../services/modules-state.service';
import {ComponentService} from '../../../services/component-highlight-stack.service';
import {GenericCrudService} from '../../../../services/generic-crud.service';
import {LocalStorageDataService} from '../../../../services/local-storage-data.service';
import {LayoutService} from '../../../../services/layout-service';
import {JobContainerService} from '../../../../../core/job-runner/job-container.service';
import {Module} from '../../../../services/module/module';
import {ElementContext, ElementType, PerformedAction} from '../../../services/ElementContext';
import {ElementsStackService} from '../../../services/elements-stack.service';
import {ElementState} from '../../../services/element-state';
import {ElementsStateService} from '../../../services/elements-state.service';
import {GenericDialogModuleService} from '../../generic-dialog/service/generic-dialog-module.service';
import {AbstractGenericGridComponent} from '../../abstract-generic-grid.component';
import {ExpertSearchService} from '../../../../components/expert-search/service/expert-search.service';
import {ExpertSearch, ExpertSearchField} from '../../../../services/expert-search/expert-search';
import {ExecutorActionEvent} from '../../../../../core/executor/service/executor-actions/executor-action-event';
import {ExecutorActionsService} from '../../../../../core/executor/service/executor-actions/executor-actions.service';
import {SetDataForLeasedEmployeeSearchCondition} from '../../../../job-runner/condition/leased-employee/search/set-data-for-leased-employee-search.condition';
import {SetDataForLeasedEmployeeSearchJob} from '../../../../job-runner/job/leased-employee/search/set-data-for-leased-employee-search.job';
import {PermissionService} from '../../../../services/permission/permission.service';
import {UserSessionService} from '../../../../../core/service/user-session.service';

@Component({
  selector: 'app-custom-leased-employee-search',
  styleUrls: ['./leased-employee-search.component.scss'],
  templateUrl: './leased-employee-search.component.html',
  providers: [
    ExecutorService,
    GenericElementValidationExecutionStepsFactory
  ]
})

export class LeasedEmployeeSearchComponent extends GenericElementAbstract {
  @Input() element: Element;
  @Input() fields: Array<FieldMetadataGrid>;
  @Input() toolbarItems: any[] = [];
  @Input() statusBarItems: any[] = [];
  @Input() moduleElement: ModuleElement;
  @Input() masterEntity: any = null;
  @Input() masterField: any = null;
  @Input() isPart = false;

  public module = null;

  public toolbarContextName = 'leasedEmployeeComponent';

  public constructor(
    protected componentService: ComponentService,
    protected viewContainerRef: ViewContainerRef,
    protected modulesStateService: ModulesStateService,
    protected genericCrudService: GenericCrudService,
    protected entityDataStoreService: EntityDataStoreService,
    protected executorService: ExecutorService,
    protected genericElementValidationExecutionStepsFactory: GenericElementValidationExecutionStepsFactory,
    protected entityValidator: EntityValidator,
    protected userSession: UserSessionService,
    protected toolbarItemCheckService: ToolbarItemCheckService,
    protected layoutService: LayoutService,
    protected jobContainerService: JobContainerService,
    protected elementsStackService: ElementsStackService,
    protected elementStateService: ElementsStateService,
    protected dialogService: GenericDialogModuleService,
    protected executorActionsService: ExecutorActionsService,
    protected permissionService: PermissionService,
    public cdr: ChangeDetectorRef
  ) {
    super(componentService, viewContainerRef, entityDataStoreService, modulesStateService, executorService,
      genericElementValidationExecutionStepsFactory, entityValidator, genericCrudService, userSession, permissionService,
      cdr);
  }

  public ngOnInit() {
    super.ngOnInit();

    this.onComponentInit();
  }

  public ngOnDestroy() {
    super.ngOnDestroy();

    this.onDestroyComponent();
  }

  public onComponentInit(): void {
    this.executorActionsService
      .registerModuleElementActions(this.moduleElement)
      .subscribe();

    const condition = new SetDataForLeasedEmployeeSearchCondition();
    condition.setPayload({component: this});

    const job = new SetDataForLeasedEmployeeSearchJob();
    job.setIsImmutable(true);
    this.jobContainerService.registerJob(job, condition);

    this.elementContext = this.createContext();

    const componentState = this.elementStateService.findById(this.moduleElement.id);

    if (componentState) {
      this.elementContext.selectedEntity = componentState.selectedEntity;
    }

    this.executeAction(ExecutorActionEvent.Init, this).subscribe();
  }

  public runSetup(moduleId: number): Observable<void> {
    return this.loadModule(moduleId).pipe(
      map((module: Module) => {
        this.module = module;
      }));
  }

  public onSearch(): void {
    const masterElementContextComponent = this.masterElementContext.component;

    if (masterElementContextComponent instanceof AbstractGenericGridComponent) {
      this.onFilter(masterElementContextComponent);
    }

    this.dialogService.persistHide();
  }

  public onDestroyComponent(): void {
    const elementContext = this.elementsStackService.findById(this.moduleElement.id);

    if (elementContext.performedAction !== PerformedAction.Close
      && elementContext.performedAction !== PerformedAction.Back) {
      const componentState =
        new ElementState(this.moduleElement.id, ElementType.Grid,
          this.moduleElement, false);

      componentState.selectedEntity = this.elementContext.selectedEntity;

      this.elementStateService.remove(componentState).add(componentState);
    }

    this.elementsStackService.remove(elementContext);
  }

  public getSelectedEntity(): any {
    return this.selectedMasterEntity || null;
  }

  public recheckToolbarItems(): void {
    this.toolbarItemCheckService.check(this);
  }

  public onSave(): Observable<any> {
    return observableOf(null);
  }

  public hasChanges(checkEmbedded: boolean = false): boolean {
    return false;
  }

  public onAfterSave(): Observable<any> {
    return observableOf(null);
  }

  public onChange(): Observable<any> {
    return observableOf(null);
  }

  public doValidate(): Observable<EntityValidatorStatus> {
    return observableOf({
      entity: null,
      isValid: true,
      error: '',
      errorFields: []
    });
  }

  public onRefresh(): Observable<any> {
    return observableOf(null);
  }

  private loadModule(moduleId: number): Observable<Module> {
    return this.genericCrudService.getEntity('superadmin/modules', moduleId);
  }

  protected createContext() {
    const elementContext = new ElementContext(
      this.moduleElement.id,
      ElementType.Grid,
      this,
      this.moduleElement,
      true,
      false,
      true,
      false,
      false
    );
    elementContext.selectedEntity = {
      'skills': [],
      'languages': [],
      'educationTypes': []
    };

    // Now let's remove and re-add the grid context if it is already there:
    this.elementsStackService.remove(elementContext).add(elementContext);

    return elementContext;
  }

  public onFilter(masterComponent): void {
    const expertSearch = this.createExpertSearch(masterComponent),
      expertSearchFilterIndex = masterComponent.staticFilters.findIndex((aFilter) => {
        return aFilter.field === 'expertSearch';
      }),
      expertSearchFilter = {
        field: 'expertSearch',
        value: JSON.stringify(ExpertSearchService.parseForSearch(expertSearch))
      };

    const expertSearchEmbedded = ExpertSearchService.getEmbedded(expertSearch);

    for (const embedded of expertSearchEmbedded) {
      if (!masterComponent.embeddedFields.includes(embedded)) {
        masterComponent.embeddedFields.push(embedded);
      }
    }

    if (expertSearchFilterIndex !== -1) {
      masterComponent.staticFilters[expertSearchFilterIndex] = expertSearchFilter;
    } else {
      masterComponent.staticFilters.push(expertSearchFilter);
    }

    masterComponent.loadEntities().subscribe();
  }

  private createExpertSearch(masterComponent): ExpertSearch {
    const skills = this.elementContext.selectedEntity.skills,
      languages = this.elementContext.selectedEntity.languages,
      educationTypes = this.elementContext.selectedEntity.educationTypes;

    const fields: ExpertSearchField[] = [];
    for (const skill of skills) {
      fields.push({
        fieldKey: 'skills.skill',
        fieldValue: skill.id,
        comparator: 'equal',
        typeValue: 'dropdown',
        labelValue: ''
      });
    }

    for (const language of languages) {
      fields.push({
        fieldKey: 'spokenLanguages.language',
        fieldValue: language.id,
        comparator: 'equal',
        typeValue: 'dropdown',
        labelValue: ''
      });
    }

    const educationTypeIds = [];
    for (const educationType of educationTypes) {
      educationTypeIds.push(educationType.id);
    }

    if (educationTypeIds.length > 0) {
      fields.push({
        fieldKey: 'educations.educationType',
        fieldValue: educationTypeIds.join(','),
        comparator: 'in',
        typeValue: 'dropdown',
        labelValue: ''
      });
    }

    return {
      id: null,
      name: '',
      datamodel: masterComponent.element.datamodel,
      containers: [{
        expertSearch: null,
        fields: fields
      }]
    };
  }
}
