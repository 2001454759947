import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit} from '@angular/core';
import {GenericCrudService} from '../../../../../services/generic-crud.service';
import {Subject} from 'rxjs';
import {UserProfitStatService} from '../services/user-profit-stat.service';
import {takeUntil} from 'rxjs/operators';
import {Entity} from '../../../../../helpers/entity';
import {TableColumn} from '../../../../../dynamic-table/shared/table-column';
import {DecimalPipeCustom} from '../../../../../pipes/decimal-pipe';
import {TableHeader} from '../../../../../dynamic-table/shared/table-header';
import {DateHelper} from '../../../../../helpers/date.helper';

@Component({
  selector: 'app-custom-staff-cockpit-user-profit-stat',
  styleUrls: ['./staff-cockpit-user-profit-stat.component.scss'],
  templateUrl: './staff-cockpit-user-profit-stat.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [UserProfitStatService]
})
export class StaffCockpitUserProfitStatComponent implements OnInit, OnDestroy {

  public unsubscribe = new Subject<void>();
  public data: Record<string, any>[] = [];
  public isLoading = true;
  public decimalPipe = new DecimalPipeCustom();
  public header: TableHeader = {
    title: ' '
  }
  public nextWeek = DateHelper.getCurrentWeek(new Date()) + 1;
  public columns: TableColumn[] = [
    {
      header: 'KW' + DateHelper.getCurrentWeek(new Date()),
      key: 'user',
      renderer: (entity) => {
        if (entity.IS_SUMMARY) {
          return 'Filiale Gesamt';
        }

        const user = Entity.getValue(entity, 'user') ||
          Entity.getValueInEmbedded(entity, 'user');

        if (user) {
          return user.firstName + ' ' + user.lastName;
        }

        return '';
      },
      style: {
        width: '100px',
      }
    },
    {
      header: 'Plan',
      key: 'plannedValue',
      renderer: (entity) => {
        return this.decimalPipe.transform(entity.plannedValue.toString() || '0');
      },
      style: {
        textAlign: 'right',
        width: '40px',
      },
    },
    {
      header: 'Ist',
      key: 'realValue',
      renderer: (entity) => {
        return this.decimalPipe.transform(entity.realValue.toString() || '0');
      },
      style: {
        textAlign: 'right',
        width: '40px',
      },
    },
    {
      header: 'krank',
      key: 'ill',
      renderer: (entity) => {
        return this.decimalPipe.transform(entity.ill.toString() || '0');
      },
      style: {
        textAlign: 'right',
        width: '40px',
      },
    },
    {
      header: 'Urlaub',
      key: 'holiday',
      renderer: (entity) => {
        return this.decimalPipe.transform(entity.holiday.toString() || '0');
      },
      style: {
        textAlign: 'right',
        width: '40px',
      },
    },
    {
      header: 'ohne ÜL',
      key: 'noAssignment',
      renderer: (entity) => {
        return this.decimalPipe.transform(entity.noAssignment.toString() || '0');
      },
      style: {
        textAlign: 'right',
        width: '40px',
      },
    }
  ];

  constructor(
    private genericCrudService: GenericCrudService,
    private cdr: ChangeDetectorRef,
    private userProfitStatService: UserProfitStatService
  ) {
  }

  ngOnInit(): void {
    this.userProfitStatService.getStats()
      .pipe(
        takeUntil(this.unsubscribe)
      ).subscribe((stats) => {
        this.data = stats;
        this.isLoading = false;
        this.cdr.detectChanges();
      });
  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }
}

