import {Observable, of as observableOf} from 'rxjs';
import {AbstractExecutionStep} from '../../../../core/executor/abstract-execution-step';
import {ExecutionStepStatus} from '../../../../core/executor/execution-step-status';
import {GenericElementAbstract} from '../../../content-renderer/elements/generic-element-abstract.component';
import {RuntimeFlagName} from '../../../content-renderer/services/ElementContext';

export class DisableDialogCloseOnLoadRuntimeFlagExecutionStep extends AbstractExecutionStep {

  public doExecute(): Observable<ExecutionStepStatus> {
    const payloadValue = this.getPayload().getValue(),
      component = payloadValue.component ? payloadValue.component : payloadValue;

    if (!(component instanceof GenericElementAbstract)) {
      return this.getFailObservable('You need to pass GenericElementAbstract as Payload value!');
    }

    return this.doDisable(component);
  }

  protected doDisable(component: GenericElementAbstract): Observable<ExecutionStepStatus> {
    const context = component.getElementContext();

    if (context) {
      context.addRuntimeFlag({
        name: RuntimeFlagName.DisableDialogPersistHideAfterSave,
        status: null,
        active: true
      });
    }

    return observableOf({status: true, content: null});
  }
}
