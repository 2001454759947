import {Observable} from 'rxjs';
import {AbstractExecutionStep} from '../../../../core/executor/abstract-execution-step';
import {ExecutionStepStatus} from '../../../../core/executor/execution-step-status';
import {GenericDynamicTableComponent} from '../../../content-renderer/elements/generic-dynamic-table/generic-dynamic-table.component';
import {ElementContext, ElementType} from '../../../content-renderer/services/ElementContext';
import {GenericElementAbstract} from '../../../content-renderer/elements/generic-element-abstract.component';
import {InquiryPositionSalaryTypeDataGenerator} from './service/inquiry-position-salary-type-data-generator.service';
import {GenericDynamicTreeOldComponent} from '../../../content-renderer/elements/generic-dynamic-tree-old/generic-dynamic-tree-old.component';
import {InquiryPositionSalaryTypeTableGenerator} from './service/inquiry-position-salary-type-table-generator';
import {Entity} from '../../../helpers/entity';
import {ExecutorActionEvent} from '../../../../core/executor/service/executor-actions/executor-action-event';
import {ChangeDetectorRefHelper} from '../../../helpers/change-detector-ref.helper';

export class GenerateInquiryDynamicTableStep extends AbstractExecutionStep {

  private inquiryFormContext: ElementContext = null;
  private inquiryPositionGridContext: ElementContext = null;
  private gridContext: ElementContext = null;

  private dataGenerator = new InquiryPositionSalaryTypeDataGenerator();
  private tableGenerator = new InquiryPositionSalaryTypeTableGenerator();

  doExecute(): Observable<ExecutionStepStatus> {
    const payload = this.getPayload();

    let component = payload.getValue();

    if (this.isElementChangeEventAndIsNotChangingCustomerCollectiveContract(component) &&
      !this.isParentGridEntitiesChangedEvent()
    ) {
      return this.getFailObservable('Changing other fields, skip it...');
    }

    if (payload instanceof Object && payload.getValue().component) {
      component = payload.getValue().component;
    }

    if (this.isParentGridEntitiesChangedEvent()) {
      return this.doGenerate(component);
    }

    if (!(component instanceof GenericElementAbstract)) {
      return this.getFailObservable('You need to pass GenericElementAbstract as Payload value!');
    }

    return this.doGenerate(component);
  }

  private doGenerate(component: GenericElementAbstract): Observable<ExecutionStepStatus> {
    this.createContext(component);

    if (this.inquiryFormContext === null || this.inquiryPositionGridContext === null || this.gridContext === null) {
      return this.getFailObservable('Wrong configuration for GenerateRowsAndColumnsForInquiryDynamicTableStep');
    }

    const dynamicTable: GenericDynamicTreeOldComponent = this.gridContext.component,
      inquiryPositions = this.getInquiryPositionsFromGrid();

    const inquiry = this.inquiryFormContext.component.entity,
      collectiveAgreement: any = this.getCollectiveAgreement(inquiry);

    this.resetTable();

    const alreadyGeneratingData = dynamicTable.isDataLoading;

    if (!alreadyGeneratingData && inquiryPositions.length > 0 && collectiveAgreement) {
      dynamicTable.columns = [];
      dynamicTable.isDataLoading = true;

      return Observable.create((observer) => {
        this.dataGenerator
          .setGenericCrudService(this.genericCrudService)
          .generateData(inquiryPositions, collectiveAgreement.id)
          .subscribe((entities: any[]) => {
            this.generateTree(entities);

            dynamicTable.onEntitiesChanged().subscribe();

            observer.next({status: true, content: null});
            observer.complete();
          });
      });
    }

    return this.getFailObservable('Nothing to generate!');
  }

  private generateTree(entities: any[]): this {
    const dynamicTable: GenericDynamicTreeOldComponent = this.gridContext.component;

    const generated = this.tableGenerator.setComponent(dynamicTable)
      .generateTable(entities);

    dynamicTable.defaultPageSize = generated.nodes.length;
    dynamicTable.totalCount = generated.nodes.length;

    dynamicTable.columns = [...generated.columns];
    dynamicTable.nodes = [...generated.nodes];
    dynamicTable.isDataLoading = false;

    ChangeDetectorRefHelper.detectChanges(dynamicTable);

    return this;
  }

  private resetTable(): this {
    const dynamicTable: GenericDynamicTableComponent = this.gridContext.component;

    dynamicTable.rows = [];
    dynamicTable.columns = [];
    dynamicTable.entities = [];
    dynamicTable.totalCount = 0;

    return this;
  }

  private getInquiryPositionsFromGrid(): any[] {
    const inquiry = this.inquiryFormContext.component.entity,
      gridInquiryPositions = this.inquiryPositionGridContext.component.entities;

    const inquiryPositions = [];

    if (inquiry) {
      for (const inquiryPosition of gridInquiryPositions) {
        const embeddedInquiry = inquiryPosition._embedded ? inquiryPosition._embedded.inquiry : null;

        if ((embeddedInquiry && embeddedInquiry.id === inquiry.id) || !embeddedInquiry) {
          inquiryPositions.push(inquiryPosition);
        }
      }
    }

    return inquiryPositions;
  }

  private createContext(stepCallerComponent: GenericElementAbstract): void {
    const stepCallerContext: ElementContext = stepCallerComponent.getElementContext();

    let callerComponentType: ElementType = null;

    if (stepCallerContext) {
      callerComponentType = stepCallerContext.type;
    }

    switch (callerComponentType) {
      case ElementType.DynamicTree:
      case ElementType.Grid:
        this.inquiryFormContext = stepCallerContext.getMasterElementContext();
        break;
      case ElementType.Form:
        this.inquiryFormContext = stepCallerContext;
        break;
    }

    if (this.inquiryFormContext) {
      for (const childContext of this.inquiryFormContext.getSlaveElementContexts()) {
        if (childContext.type === ElementType.DynamicTree) {
          this.gridContext = childContext;
        } else {
          this.inquiryPositionGridContext = childContext;
        }
      }
    }
  }

  private isElementChangeEventAndIsNotChangingCustomerCollectiveContract(payloadValue: any): boolean {
    if (!payloadValue.entityDataChangeMeta && this.isClickEvent(event)) {
      return true;
    }

    return payloadValue.entityDataChangeMeta &&
      payloadValue.entityDataChangeMeta.element &&
      payloadValue.entityDataChangeMeta.element.datamodelField !== 'customerCollectiveContract';
  }

  private isClickEvent(event): boolean {
    return event instanceof CustomEvent && event.type === 'gridEntitySelectedMouseClick';
  }

  private getCollectiveAgreement(inquiry): any|null {
    let collectiveAgreement = null;

    const customerCollectiveContract = Entity.getValue(inquiry, 'customerCollectiveContract') ||
      Entity.getValueInEmbedded(inquiry, 'customerCollectiveContract');

    if (customerCollectiveContract !== null) {
      collectiveAgreement = Entity.getValue(customerCollectiveContract, 'collectiveAgreement') ||
        Entity.getValueInEmbedded(customerCollectiveContract, 'collectiveAgreement');
    }

    return collectiveAgreement;
  }

  private isParentGridEntitiesChangedEvent(): boolean {
    return this.getParamValue('event') === ExecutorActionEvent.EntitiesChanged;
  }
}
