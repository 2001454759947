import {Component, OnInit} from '@angular/core';
import {SelectItem} from 'primeng/api';
import {AbstractAdminQuestionComponent} from './abstract-admin-question-component';
import {Datamodel} from '../../../../../shared/services/datamodel/datamodel';
import {Observable} from 'rxjs/Observable';
import {GenericCrudService} from '../../../../../shared/services/generic-crud.service';

@Component({
  selector: 'app-questionnaire-admin-question-checkbox',
  template: `
    <div class="ui-g">
        <div class="ui-g-4">
          Datamodel ID:
        </div>
        <div class="ui-g-8">
          <p-dropdown
            appendTo="body" 
            (onChange)="onChangeDatamodelId($event)"
            [style]="{'width':'100%'}" 
            [options]="datamodelOptions" 
            placeholder="---" 
            [ngModel]="getParamValue('datamodelId')"
          ></p-dropdown>
        </div>
    </div>
  `,
  styles: [`
    :host {
      width: 100%;
    }
  `]
})
export class AdminRadioQuestionComponent extends AbstractAdminQuestionComponent implements OnInit {

  public datamodelOptions: SelectItem[] = [];

  public constructor(
    private genericCrudService: GenericCrudService
  ) {
    super();
  }

  public onComponentInit(): void {
    this.loadDatamodels()
      .subscribe((datamodels: Datamodel[] = []) => {
        this.datamodelOptions = [{
          value: null,
          label: '---'
        }];

        for (const datamodel of datamodels) {
          this.datamodelOptions = [...this.datamodelOptions, {
            value: datamodel.id,
            label: datamodel.name
          }];
        }
      });
  }

  public onChangeDatamodelId(event): void {
    const param = this.getOrCreateParam('datamodelId');

    param.value = event.value;
  }

  private loadDatamodels(): Observable<Datamodel[]> {
    return this.genericCrudService.getEntities('superadmin/datamodels');
  }
}
