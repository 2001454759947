
import {of as observableOf, Observable} from 'rxjs';
import {AbstractExecutionStep} from '../../../../core/executor/abstract-execution-step';
import {ExecutionStepStatus} from '../../../../core/executor/execution-step-status';
import {GenericDialogModuleService} from '../../../content-renderer/elements/generic-dialog/service/generic-dialog-module.service';

export class CloseModuleDialogExecutionStep extends AbstractExecutionStep {

  public doExecute(): Observable<ExecutionStepStatus> {
    this.getDialogService().persistHide();

    return observableOf({status: true, content: null});
  }

  private getDialogService(): GenericDialogModuleService {
    return this.injector.get(GenericDialogModuleService, null);
  }
}
