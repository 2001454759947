import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {
  CRON_JOB_EXECUTED_BY_USER_OPTION_NAME,
  CRON_JOB_UNIQUE_ID_OPTION_NAME,
  CronJob,
  CronJobOption
} from '../../../../../services/cron/cron-job';
import {Organisation} from '../../../../../services/organisation/organisation';
import {LocalStorageDataService} from '../../../../../services/local-storage-data.service';
import {AuthenticationService} from '../../../../../../core/authentication/authentication.service';
import {UserSessionService} from '../../../../../../core/service/user-session.service';

@Component({
  selector: 'app-custom-cron-job-command-option-list',
  templateUrl: './cron-job-command-option-list.component.html',
  styleUrls: ['./cron-job-command-option-list.component.scss']
})
export class CronJobCommandOptionListComponent implements OnInit {
  @Input() cronJob: CronJob = null;
  @Output() public onOptionChange: EventEmitter<any> = new EventEmitter();

  public currentOrganisationId = null;
  public currentUserUsername = null;

  public constructor(
    private localStorage: LocalStorageDataService,
    private authenticationService: AuthenticationService,
    private userSession: UserSessionService
  ) {

  }

  public ngOnInit(): void {
    const organisation = this.userSession.get(Organisation.LOCAL_STORAGE_NAME);

    if (organisation) {
      this.currentOrganisationId = organisation.id;
    }

    this.currentUserUsername = this.authenticationService.currentUser.username;
  }

  public isDisabled(option: CronJobOption): boolean {
    return option.name === CRON_JOB_UNIQUE_ID_OPTION_NAME || option.name === CRON_JOB_EXECUTED_BY_USER_OPTION_NAME;
  }

  public onKeyUp(event): void {
    this.onOptionChange.emit(event);
  }
}
