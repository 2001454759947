import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { Action, ActionParam } from '../../../../services/action/action';
import { GenericGridComponent } from '../generic-grid.component';
import { FieldMetadataGrid } from '../../../../services/module/module-element-field-metadata-grid';

export interface FieldActionComponentAware {
  fire(): void;
}

export abstract class FieldActionAbstractComponent implements OnInit, OnDestroy, FieldActionComponentAware {

  public abstract fire();

  protected subscriptions: Subscription[] = [];

  @Input() action: Action = null;
  @Input() field: FieldMetadataGrid = null;
  @Input() grid = null; // :GenericGridComponent type throws WARNING in Circular dependency detected: :S

  protected getAction(): Action {
    return this.action;
  }

  protected getGrid(): GenericGridComponent {
    return this.grid;
  }

  protected getField(): FieldMetadataGrid {
    return this.field;
  }

  protected getActionParam(key: string): ActionParam|null {
    let foundParam = null;

    for (let param of this.getAction().params) {
      if (param.key === key) {
        foundParam = param;
        break; 
      }
    }

    return foundParam;
  }

  protected getActionParamValue(key: string): any|null {
    let value = null,
      param = this.getActionParam(key);

    return param && param.value ? param.value : null;
  }

  ngOnInit() { }

  ngOnDestroy() {
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }

}
