
import {debounceTime} from 'rxjs/operators';
import { Injectable, ComponentFactoryResolver } from '@angular/core';
import { GenericElementAbstract } from 'app/shared/content-renderer/elements/generic-element-abstract.component';
import { Subject } from 'rxjs';

@Injectable()
export class ToolbarItemCheckService {

  public onCheckSource = new Subject<any>();
  public onCheck$ = this.onCheckSource.asObservable().pipe(debounceTime(200));

  public check(component: GenericElementAbstract) {
    this.onCheckSource.next(component);
  }

}
