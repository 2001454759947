import {Injectable} from '@angular/core';
import {CustomButtonAbstractCheckService} from './custom-button-abstract-check.service';
import {CustomButtonCheckInterface} from './custom-button-check-factory.service';


@Injectable()
export class CustomButtonGenericCheckService extends CustomButtonAbstractCheckService implements CustomButtonCheckInterface {

  isVisible(): boolean {
    return true;
  }
}
