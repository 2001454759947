import {ChangeDetectionStrategy, Component} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {ToolbarItemAbstract} from '../toolbar-item-abstract.component';
import {GenericCrudService} from '../../../../../../services/generic-crud.service';
import {MessageGrowlService} from '../../../../../../../core/message/message-growl.service';
import {FormViewerComponent} from '../../../../../../form-viewer/form-viewer.component';
import {MemoFieldDefinitionValueComponent} from '../../../../custom/memo-field-definition-value/memo-field-definition-value.component';
import {GenericElementAbstract} from '../../../../generic-element-abstract.component';
import {ElementsStackService} from '../../../../../services/elements-stack.service';
import {LocationService} from '../../../../../../services/location.service';
import {EntityDraftStoreService} from '../../../../../services/entity-draft-store.service';
import {ModulesStateService} from '../../../../../services/modules-state.service';
import {AbstractGenericGridComponent} from '../../../../abstract-generic-grid.component';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-toolbar-item-display-dms-notes',
  template: ''
})
export class ToolbarItemDisplayDmsNotesComponent extends ToolbarItemAbstract {

  constructor(
  ) {
    super();
  }

  public click() {  }

  public getText() {
    return this.getEntity() ? this.getEntity()['notes'] : '';
  }
}
