import {ChangeDetectorRef, Component, EventEmitter, OnInit, Output} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {MessageGrowlService} from '../../../../core/message/message-growl.service';
import {GenericCrudService} from '../../../services/generic-crud.service';
import {ChangeDetectorRefHelper} from '../../../helpers/change-detector-ref.helper';

@Component({
  selector: 'app-ksv-company-search-grid',
  templateUrl: './ksv-company-search-grid.component.html'
})
export class KsvCompanySearchGridComponent implements OnInit {

  private static readonly COMPANY_API_ROUTE = 'app/ksv/companies';

  @Output() public onCompanyChanged: EventEmitter<any> = new EventEmitter();

  public companyName = '';

  public companies: any[] = [];

  public isDataLoading = false;

  public constructor(
    protected translate: TranslateService,
    protected messageGrowlService: MessageGrowlService,
    protected genericCrudService: GenericCrudService,
    public cdr: ChangeDetectorRef
  ) {

  }

  public ngOnInit() {

  }

  public onSearch() {
    this.isDataLoading = true;

    this.genericCrudService
      .getEntities(`${KsvCompanySearchGridComponent.COMPANY_API_ROUTE}/${this.companyName}*`)
      .subscribe((entities) => {
        this.isDataLoading = false;
        this.companies = entities;
        ChangeDetectorRefHelper.detectChanges(this);
      });
  }

  public onRowDoubleClick(company) {
    this.onCompanyChanged.emit(company);
  }
}
