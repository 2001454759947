import { of as observableOf } from 'rxjs';
import { AbstractExecutionStep } from '../../../../../core/executor/abstract-execution-step';
import { AbstractGenericGridComponent } from '../../../../content-renderer/elements/abstract-generic-grid.component';
import { FormViewerComponent } from '../../../../form-viewer/form-viewer.component';
import { Entity } from '../../../../helpers/entity';
export class PrecalculationDetailsToggleColumnsVisibilityStep extends AbstractExecutionStep {
    constructor() {
        super(...arguments);
        this.conditions = [
            {
                fields: ['salarySchema', 'salaryAdvancement', 'advancementAfter'],
                show: this.showSalaryAndAdvancement.bind(this),
                hide: this.hideSalaryAndAdvancement.bind(this)
            },
            {
                fields: ['salaryMonth', 'selfCostsMonth'],
                show: this.showSalaryMonths.bind(this),
                hide: this.hideSalaryMonths.bind(this)
            },
            {
                fields: ['invoiceMonth', 'targetInvoiceMonth'],
                show: this.showInvoiceMonths.bind(this),
                hide: this.hideInvoiceMonths.bind(this)
            },
            {
                fields: ['salaryHour', 'selfCostsHour'],
                show: this.showSalaryHours.bind(this),
                hide: this.hideSalaryHours.bind(this)
            },
            {
                fields: ['invoiceHour', 'targetInvoiceHour'],
                show: this.showInvoiceHours.bind(this),
                hide: this.hideInvoiceHours.bind(this)
            },
            {
                fields: ['globalFields'],
                show: this.showGlobalFields.bind(this),
                hide: this.hideGlobalFields.bind(this)
            }
        ];
    }
    doExecute() {
        const payload = this.getPayload();
        let component = payload.getValue();
        if (payload instanceof Object && payload.getValue().component) {
            component = payload.getValue().component;
        }
        if (!(component instanceof AbstractGenericGridComponent)) {
            return this.getFailObservable('You need to pass AbstractGenericGridComponent as Payload value!');
        }
        const gridContext = component.getElementContext();
        const formContext = gridContext.getMasterElementContext();
        if (!formContext || !(formContext.component instanceof FormViewerComponent)) {
            return this.getFailObservable('Form not found, grid has to have master of form!');
        }
        return this.doToggleVisibility(formContext.component, component);
    }
    getPreCalculation(form) {
        return form.entity;
    }
    doToggleVisibility(form, grid) {
        grid.isDataLoading = true;
        this.applyConditions(form, grid);
        grid.isDataLoading = false;
        return observableOf({ status: true, content: null });
    }
    applyConditions(form, grid) {
        for (const condition of this.conditions) {
            if (condition.show(form, grid)) {
                this.showFields(grid, condition.fields);
            }
            if (condition.hide(form, grid)) {
                this.hideFields(grid, condition.fields);
            }
        }
        grid.initColumns();
    }
    showSalaryAndAdvancement(form, grid) {
        return grid.entities.length > 1;
    }
    hideSalaryAndAdvancement(form, grid) {
        return grid.entities.length === 0 || grid.entities.length === 1;
    }
    getSalaryUnitNameValue(entity) {
        const propertyName = 'salaryUnit.name';
        let propertyValue = Entity.getValue(entity, propertyName) || Entity.getValueForcePropertyName(entity, propertyName);
        if (!propertyValue) {
            propertyValue = Entity.getValueInEmbedded(entity, propertyName);
        }
        return propertyValue;
    }
    getBillingUnitNameValue(entity) {
        const propertyName = 'billingUnit.name';
        let propertyValue = Entity.getValue(entity, propertyName) || Entity.getValueForcePropertyName(entity, propertyName);
        if (!propertyValue) {
            propertyValue = Entity.getValueInEmbedded(entity, propertyName);
        }
        return propertyValue;
    }
    showSalaryMonths(form, grid) {
        return !this.hideSalaryMonths(form, grid);
    }
    hideSalaryMonths(form, grid) {
        const salaryUnitNameValue = this.getSalaryUnitNameValue(this.getPreCalculation(form));
        return salaryUnitNameValue === PrecalculationDetailsToggleColumnsVisibilityStep.HOUR;
    }
    showSalaryHours(form, grid) {
        return !this.hideSalaryHours(form, grid);
    }
    hideSalaryHours(form, grid) {
        const salaryUnitNameValue = this.getSalaryUnitNameValue(this.getPreCalculation(form));
        return salaryUnitNameValue === PrecalculationDetailsToggleColumnsVisibilityStep.MONTH;
    }
    showInvoiceMonths(form, grid) {
        return !this.hideInvoiceMonths(form, grid);
    }
    hideInvoiceMonths(form, grid) {
        const billingUnitNameValue = this.getBillingUnitNameValue(this.getPreCalculation(form));
        return billingUnitNameValue === PrecalculationDetailsToggleColumnsVisibilityStep.HOUR;
    }
    showInvoiceHours(form, grid) {
        return !this.hideInvoiceHours(form, grid);
    }
    hideInvoiceHours(form, grid) {
        const billingUnitNameValue = this.getBillingUnitNameValue(this.getPreCalculation(form));
        return billingUnitNameValue === PrecalculationDetailsToggleColumnsVisibilityStep.MONTH;
    }
    showGlobalFields(form, grid) {
        return !this.hideGlobalFields(form, grid);
    }
    hideGlobalFields(form, grid) {
        const entity = this.getPreCalculation(form);
        return entity && entity.hasCompanyAgreement === true;
    }
    showFields(grid, fields = []) {
        for (const field of fields) {
            const gridField = this.findFieldByString(grid, field);
            if (gridField) {
                gridField.visible = true;
                if (gridField.moduleElementColumn) {
                    gridField.moduleElementColumn.visible = true;
                }
            }
        }
    }
    hideFields(grid, fields = []) {
        for (const field of fields) {
            const gridField = this.findFieldByString(grid, field);
            if (gridField) {
                gridField.visible = false;
                if (gridField.moduleElementColumn) {
                    gridField.moduleElementColumn.visible = false;
                }
            }
        }
    }
    findFieldByString(grid, fieldName) {
        for (const field of grid.fields) {
            if (typeof field.id === 'string' && field.id.indexOf(fieldName) !== -1) {
                return field;
            }
            if (typeof field.name === 'string' && field.name.indexOf(fieldName) !== -1) {
                return field;
            }
        }
        return null;
    }
}
PrecalculationDetailsToggleColumnsVisibilityStep.MONTH = 'Monat';
PrecalculationDetailsToggleColumnsVisibilityStep.HOUR = 'Stunde';
