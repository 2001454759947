
import {of as observableOf, Observable} from 'rxjs';
import {AbstractExecutionStep} from '../../../../core/executor/abstract-execution-step';
import {ExecutionStepStatus} from '../../../../core/executor/execution-step-status';
import {AbstractGenericGridComponent} from '../../../content-renderer/elements/abstract-generic-grid.component';
import {Entity} from '../../../helpers/entity';
import {FieldMetadataGrid} from '../../module/module-element-field-metadata-grid';
import {ArticleTypeRegistry} from '../../article/article-type.registry';
import {ArticleTypeLockFieldsRegistry} from '../../article/article-type-lock-fields.registry';
import {ChangeDetectorRef} from '@angular/core';

export class PrecalculationDetailsDisableFieldsStep extends AbstractExecutionStep {

  public doExecute(): Observable<ExecutionStepStatus> {
    const payload = this.getPayload(),
      component = payload.getValue().component ? payload.getValue().component : payload.getValue();

    if (!(component instanceof AbstractGenericGridComponent)) {
      return this.getFailObservable('You need to pass AbstractGenericGridComponent as Payload value!');
    }

    return this.doDisableFields(component);
  }

  protected doDisableFields(grid: AbstractGenericGridComponent): Observable<ExecutionStepStatus> {
    const preCalculationArticle = grid.getSelectedEntity();

    this.unlockAllFields(grid);

    if (preCalculationArticle) {
      const articleType = Entity.getValue(preCalculationArticle, 'type');

      const fieldsToLock = ArticleTypeLockFieldsRegistry.getFieldsForLock(articleType);

      this.lockFields(grid, true, fieldsToLock);
    }

    return observableOf({status: true, content: null});
  }

  private unlockAllFields(grid: AbstractGenericGridComponent): void {
    const allFields = ArticleTypeLockFieldsRegistry.getAllFields();

    this.lockFields(grid, false, allFields);
  }

  private lockFields(grid: AbstractGenericGridComponent, doLock: boolean, fields: string[]): void {
    for (const field of fields) {
      const gridField: FieldMetadataGrid = grid.findField(field);

      if (gridField) {
        gridField.isReadOnly = doLock;
      }
    }
  }
}
