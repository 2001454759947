import {ChangeDetectionStrategy, Component} from '@angular/core';
import {ToolbarItemAbstract} from '../toolbar-item-abstract.component';
import {GenericCrudService} from '../../../../../../services/generic-crud.service';
import {MessageGrowlService} from '../../../../../../../core/message/message-growl.service';
import {TranslateService} from '@ngx-translate/core';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'toolbar-item-collective-contract-manage-parameters',
  styleUrls: ['./toolbar-item-collective-contract-manage-parameters.component.scss'],
  templateUrl: './toolbar-item-collective-contract-manage-parameters.component.html'
})
export class ToolbarItemCollectiveContractManageParameters extends ToolbarItemAbstract {

  public isDialogVisible: boolean = false;
  public isDataLoading: boolean = false;
  public isAddDialogVisible: boolean = false;

  public static readonly PARAMS_URL = 'superadmin/collectiveagreements/parametersettings';
  public static readonly POTENTIAL_PARAMS_URL = 'superadmin/collectiveagreements/potentialparametersettings';

  public parameters: any[] = [];
  public potentialParams: any[] = [];
  public createdParam: any = null;

  constructor(
    protected genericCrudService: GenericCrudService,
    protected messageGrowlService: MessageGrowlService,
    protected translate: TranslateService
  ) {
    super();
  }

  public click() {
    if (this.isInCurrentContext(ToolbarItemAbstract.CONTEXT_GRID)) {
      this.handleClickInGridContext();
    }
  }

  protected handleClickInGridContext() {
    this.isDialogVisible = true;

    this.fetchDefaultParameters();
    this.fetchPotentialParameterValues();
  }

  protected fetchPotentialParameterValues() {
    this.genericCrudService.getEntities(ToolbarItemCollectiveContractManageParameters.POTENTIAL_PARAMS_URL).subscribe((params) => {
      this.potentialParams = JSON.parse(params.content);
    });
  }

  protected fetchDefaultParameters() {
    this.isDataLoading = true;

    this.genericCrudService.getEntities(ToolbarItemCollectiveContractManageParameters.PARAMS_URL).subscribe((params) => {
      this.parameters = params;

      this.isDataLoading = false;
    });
  }

  public onAdd() {
    this.createdParam = {};
    this.isAddDialogVisible = true;
  }

  public doAdd() {
    this.genericCrudService.createEntity(ToolbarItemCollectiveContractManageParameters.PARAMS_URL, this.createdParam).toPromise()
      .then((param) => {

        this.messageGrowlService.success(this.translate.instant("COMMON.SUCCESS") + '.');

        this.isAddDialogVisible = false;
        this.parameters.push(param);
      }).catch((error) => {
        this.messageGrowlService.error(error);
      });
  }
}
