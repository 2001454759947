
import {forkJoin as observableForkJoin, of as observableOf, Observable, Subject, of} from 'rxjs';
import {Injectable} from '@angular/core';
import { AbstractValidator } from '../field-validators/abstract-validator';
import { ModuleElementField } from '../../services/module/module-element-field';
import {GridValidatorFactory} from './factory/grid-validator-factory';
import {GenericCrudService} from 'app/shared/services/generic-crud.service';
import {EntityHydrator} from '../../services/entity-hydrator.service';
import {Element} from '../../form-viewer/models';
import {FormValidatorFactory} from './factory/form-validator-factory';
import {ValidationValue, ValidationStatus} from './validation';
import {Constants} from '../../../constants';
import {TranslateService} from '@ngx-translate/core';
import {Md5} from 'ts-md5';
import {GenericElementAbstract} from '../../content-renderer/elements/generic-element-abstract.component';
import {EntityStatus} from '../../services/entity/entity-status';
import {AbstractAsyncValidator} from '../field-validators/abstract-async-validator';
import {debounceTime, distinctUntilChanged, takeUntil, switchMap} from 'rxjs/operators';
import isEqual from 'lodash.isequal';

export class EntityValidatorStatus {
  entity: any;
  isValid: boolean;
  errorFields: any[];
  error: string;
  component?: GenericElementAbstract;
}

export class EntityValidatorSubject {
  entity: any;
  component: GenericElementAbstract;
  entityHash: string;
  validateObservables: Observable<ValidationStatus>[];
}

@Injectable()
export class EntityValidator {

  // maybe not fqn, but fqn-moduleId?
  public validationsDictionary: { [fqn: string]: { [validatorKey: string]: AbstractValidator[]; }} = {};

  private gridValidatorFactory: GridValidatorFactory = null;
  private formValidatorFactory: FormValidatorFactory = null;
  private validationCache = {};

  private componentValidate: Subject<EntityValidatorSubject> = new Subject<EntityValidatorSubject>();
  private componentValidated: Subject<EntityValidatorStatus> = new Subject<EntityValidatorStatus>();

  public unsubscribe = new Subject<void>();

  public constructor(private genericCrudService: GenericCrudService,
                     private entityHydrator: EntityHydrator,
                     private translateService: TranslateService) {
    this.gridValidatorFactory = new GridValidatorFactory(this.genericCrudService, this.entityHydrator);
    this.formValidatorFactory = new FormValidatorFactory(this.genericCrudService, this.entityHydrator);

    this.componentValidate
      .pipe(
        debounceTime(100),
        // distinctUntilChanged((prev, curr) => {
        //   return isEqual(prev.entityHash, curr.entityHash)
        // }),
        switchMap((validatorSubject: EntityValidatorSubject) => {
          if (this.validationCache.hasOwnProperty(validatorSubject.entityHash)) {
            return observableOf(this.validationCache[validatorSubject.entityHash]);
          }

          return this.doValidate(
            validatorSubject.entity,
            validatorSubject.component,
            validatorSubject.validateObservables,
            validatorSubject.entityHash
          )
        })
      ).subscribe((status: EntityValidatorStatus) => {
        this.componentValidated.next(status)
      });
  }

  public onValidateOptimised(entity: any, component: GenericElementAbstract): Observable<EntityValidatorStatus> {
    const entityHash = Md5.hashStr(this.genericCrudService.stringify(entity)).toString();
    const fqn = entity.fqn,
      validateObservables = this.createValidateObservables(entity, fqn, component);

    this.componentValidate.next({
      entity,
      component,
      entityHash,
      validateObservables
    })

    return this.componentValidated;
  }

  public onValidate(entity: any, component: GenericElementAbstract): Observable<EntityValidatorStatus> {

    const entityHash = Md5.hashStr(this.genericCrudService.stringify(entity)).toString();

    if (this.validationCache.hasOwnProperty(entityHash)) {
      return observableOf(this.validationCache[entityHash]);
    } else {
      const fqn = entity.fqn,
        validateObservables = this.createValidateObservables(entity, fqn, component);

      component.setIsValid(true).setValidationMessage('');

      if (fqn && this.validationsDictionary[fqn] && validateObservables.length > 0) {
        return this.doValidate(entity, component, validateObservables, entityHash);
      }
    }

    return observableOf({entity: entity, isValid: true, errorFields: [], error: ''});
  }

  public doValidate(entity: any,
                    component: GenericElementAbstract,
                    validateObservables: Observable<ValidationStatus>[] = [],
                    entityHash: string): Observable<EntityValidatorStatus> {
    let isValid = true;
    const messages = [],
      errorFields = [];

    this.unsubscribe.next();
    this.unsubscribe.complete();

    this.unsubscribe = new Subject();

    return observableForkJoin(validateObservables)
      .pipe(takeUntil(component.unsubscribe))
      .switchMap((statuses: ValidationStatus[]) => {

        for (const status of statuses) {
          if (status && !status.isValid) {
            isValid = false;

            const fieldError = status.field ? status.field.validationsError : null,
              validatorError = status.errorTranslated
                || this.getTranslatedError(status.errorTranslateKey, status.errorTranslateParams, status.field);

            errorFields.push(status.field);

            if (fieldError && !messages.includes(fieldError)) {
              messages.push(fieldError);
            } else if (!fieldError) {
              messages.push(validatorError);
            }
          }
        }

        const error = this.getTranslatedErrors(messages);

        component.setIsValid(isValid)
          .setValidationMessage(error);

        const validationResult = {
          entity: entity,
          isValid: isValid,
          errorFields: errorFields,
          error: error,
          component: component,
        };

        entity[EntityStatus.ENTITY_INVALID_FLAG] = !isValid;
        entity[EntityStatus.ENTITY_VALIDATED_FLAG] = true;

        this.validationCache[entityHash] = validationResult;

        return of(validationResult)
      });
  }

  public addGridValidations(fqn: string, field: ModuleElementField) {
    const validatorKey = this.getValidatorKey(field);

    this.validationsDictionary[fqn] = this.validationsDictionary[fqn] || {};

    if (field.validations && field.validations instanceof Array && field.validations.length > 0) {
      this.validationsDictionary[fqn][validatorKey] = [];

      for (const validation of field.validations) {
        const validator = this.gridValidatorFactory.createValidator(validation, field);

        this.validationsDictionary[fqn][validatorKey].push(validator);
      }
    }
  }

  public addFormValidations(fqn: string, element: Element) {
    const validatorKey = this.getValidatorKey(element);

    this.validationsDictionary[fqn] = this.validationsDictionary[fqn] || {};

    if (element.validators && element.validators instanceof Array && element.validators.length > 0) {
      this.validationsDictionary[fqn][validatorKey] = [];

      for (const validation of element.validators) {
        const validator = this.formValidatorFactory.createValidator(validation, element);

        this.validationsDictionary[fqn][validatorKey].push(validator);
      }
    }
  }

  public removeFormValidations(fqn: string, element: Element): void {
    const validatorKey = this.getValidatorKey(element);

    this.validationsDictionary[fqn] = this.validationsDictionary[fqn] || {};

    delete this.validationsDictionary[fqn][validatorKey];
  }

  private createValidateObservables(entity: any, fqn: string, component: GenericElementAbstract): Observable<ValidationStatus>[] {
    const observables = [];

    if (!fqn) {
      return observables;
    }

    const entityValidator = this.gridValidatorFactory.createValidator({value: ValidationValue.Entity}, null)
      .setEntity(entity);

    const async = [
      entityValidator
    ];

    for (const validatorKey in this.validationsDictionary[fqn]) {
      if (this.validationsDictionary[fqn].hasOwnProperty(validatorKey)) {
        const validators = this.validationsDictionary[fqn][validatorKey];

        for (const validator of validators) {

          if (this.skipValidate(validator)) {
            continue;
          }

          validator.setComponent(component);
          validator.setEntity(entity);

          const validate = validator.validate();

          if (validator instanceof AbstractAsyncValidator) {
            async.push(validator);
          } else {
            observables.push(validate);
          }
        }
      }
    }

    // const asyncValidator = this.gridValidatorFactory.createValidator({value: ValidationValue.Async, params: async}, null)
    //   .setEntity(entity).validate();
    //
    // observables.push(asyncValidator);

    return observables;
  }

  private getValidatorKey(field: Element|ModuleElementField): string {
    return field instanceof Element ? `${field.id}-element` : `${field.id}-field`;
  }

  private skipValidate(validator: AbstractValidator): boolean {
    let skip = false;

    const validatorField = validator.getField();

    if (validatorField instanceof Element && validatorField.isHidden) {
      skip = true;
    }

    if (validatorField instanceof ModuleElementField && validatorField.visible === false) {
      skip = true;
    }

    return skip;
  }

  /** This crap bellow should be in validator, move later!!! */
  public getTranslatedError(errorKey: string, errorParams: any, field: any): string {
    return this.genericErrorMessage(field) + ' ' + this.validatorErrorMessage(errorKey, errorParams);
  }

  public getTranslatedErrors(errors): string {
    let translated = '';

    for (const error of errors) {
      translated += '\n' + error;
    }

    return translated;
  }

  public clearEntityValidations(entity: any) {
    delete this.validationsDictionary[entity['fqn']];

    const keys = [];

    for (const entityKey in this.validationCache) {
      if (this.validationCache.hasOwnProperty(entityKey)) {
        keys.push(entityKey);
      }
    }

    for (const entityKey of keys) {
      delete this.validationCache[entityKey];
    }
  }

  public clearCache(): this {
    this.validationCache = {};
    return this;
  }

  public clearDictionaries(): this {
    this.validationsDictionary = {};
    return this;
  }

  protected genericErrorMessage(field: any): string {
    const fieldName = this.getFieldName(field);

    return this.translateService.instant(`${Constants.VALIDATION_ERROR_TRANSLATIONS_PATH}.GENERIC`, {
      fieldName: fieldName
    });
  }

  protected validatorErrorMessage(isValidMessage: string, errorParams: any): string {

    if (errorParams) {
      return this.translateService.instant(`${Constants.VALIDATION_ERROR_TRANSLATIONS_PATH}.${isValidMessage}`, errorParams);
    }

    return this.translateService.instant(`${Constants.VALIDATION_ERROR_TRANSLATIONS_PATH}.${isValidMessage}`);
  }

  private getFieldName(field): string {
    let name = field.id;

    if (field instanceof Element) {
      name = field.datamodelField ? field.datamodelField : field.label;
    }

    return name;
  }
}
