
    <div [formGroup]="formGroup" class="ui-g ui-g-12">
      <div
        [class]="element.hasCustomButton ||
        ((element?.icon | isDefined) &&
        (element?.icon?.relativePath | isDefined))? 'ui-g ui-g-11' : 'ui-g ui-g-12'"
      >
        <p-autoComplete
            appSeleniumDirective
            [element]="element"
            appAutocomplete
            [formControlName]="formControlName"
            [dropdown]="!isReadOnlyOrDisabled()"
            [readonly]="isReadOnly()"
            [suggestions]="suggestions"
            (completeMethod)="onSearch($event)"
            (onSelect)="onItemSelected($event)"
            (onKeyUp)="onKeyUpSearch($event)"
            [delay]="500"
            field="label"
            [placeholder]="this.element ? this.element.placeholder : ''"
            [minLength]="1"
            [inputTabIndex]="element.tabIndex"
            appendTo="body"
            #inputElement
        >
          <ng-template let-item pTemplate="item">
            <span
              [id]="'FormElementInput-' + element.label + '-Value-' + item.label"
            >
            {{ item.label }}
          </span>
          </ng-template>
        </p-autoComplete>
      </div>
      <div class="icon-container ui-g ui-g-1" *ngIf="((element?.icon | isDefined) && (element?.icon?.relativePath | isDefined))">
        <img height="16" width="16"
             [ngClass]="{
          'cur-pointer': isValid(),
          'cur-disabled': !isValid(),
          'disabled': !isValid()
        }"
             [src]="iconBaseUrl + '/' + element?.icon?.relativePath"
             [alt]="element?.icon?.name"
             (click)="onClickIcon()"
        />
      </div>
      <div *ngIf="element.hasCustomButton" class="custom-button-container ui-g ui-g-1">
        <i
          (click)="onCustomButtonClick($event)"
          [class]="isReadOnlyOrDisabled() ? 'fa fa-plus fa-disabled' : 'fa fa-plus'"
          aria-hidden="true"
        >
        </i>
      </div>
    </div>
  