import {Component} from '@angular/core';
import {AbstractEditExpertSearchFieldValue} from '../abstract-edit-expert-search-field-value';

@Component({
  selector: 'app-search-management-edit-calendar-date-expert-search-field-value',
  templateUrl: './calendar-date-edit-expert-search-field-value.component.html'
})
export class SearchManagementCalendarDateEditExpertSearchFieldValueComponent extends AbstractEditExpertSearchFieldValue {

  public onValueChange(date: Date): void {
    this.onChange.emit({
      value: date,
      labelValue: date.toDateString()
    });
  }
}
