import {Pipe, PipeTransform} from '@angular/core';

@Pipe({ name: 'currencyEur', pure: true })
export class EurCurrencyPipe implements PipeTransform {

  transform(value: any): any {
    if (null === value || typeof value === 'undefined') {
      return '';
    }

    return Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(value);
  };
}
