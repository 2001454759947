import {AbstractValidator} from './abstract-validator';
import {FormViewerComponent} from '../../form-viewer/form-viewer.component';
import {GenericElementAbstract} from '../../content-renderer/elements/generic-element-abstract.component';
import {AbstractControl} from '@angular/forms';

export class UserPasswordRepeatValidator extends AbstractValidator {

  private passwordControls: AbstractControl[] = [];

  public isValid(value: any): boolean {
    const form: GenericElementAbstract = this.getComponent();

    if (form instanceof FormViewerComponent) {
      const passwordControls = this.getPasswordControls(form);

      return this.areValuesTheSame(passwordControls);
    }

    return true;
  }

  public getErrorTranslateKey(value: any): string {
    return 'USER_PASSWORD_REPEAT_IS_NOT_VALID';
  }

  public getErrorTranslateParams(value: any): string {
    return null;
  }

  private areValuesTheSame(passwordControls: AbstractControl[]): boolean {

    if (passwordControls.length === 2) {
      return passwordControls[0].value === passwordControls[1].value;
    }

    return true;
  }

  private getPasswordControls(form: FormViewerComponent): AbstractControl[] {
    const passwordControls = [];

    if (this.passwordControls.length === 2) {
      return this.passwordControls;
    }

    if (form instanceof FormViewerComponent) {
      for (const formControlKey in form.formGroup.controls) {
        if (form.formGroup.controls.hasOwnProperty(formControlKey)) {
          if (formControlKey.includes('plainPassword')) {
            passwordControls.push(form.formGroup.get(formControlKey));
          }
        }
      }
    }

    this.passwordControls = passwordControls;

    return this.passwordControls;
  }
}
