import {Element, ElementInput} from './element';
import {FormActionConditionParam} from './form';

export interface ElementLayoutGlobalFields extends ElementInput {
  collectiveAgreementField: string;
  isCollectiveAgreementFromLocalStorage: boolean;
  salaryClassificationField: string;
  fieldReferenceAssociationType: string;
  fieldReferenceAssociation: string;
  fieldWorkingMode: string;
  fieldHasCompanyAgreement: string;
  globalFieldWageVisible: boolean;
  globalFieldFactorVisible: boolean;
  globalFieldFactorisedWageVisible: boolean;
  globalFieldWageLabel: string;
  globalFieldFactorLabel: string;
  globalFieldFactorisedWageLabel: string;
  globalFieldSection: string;
}

export class ElementLayoutGlobalFields extends ElementInput implements ElementLayoutGlobalFields{
  constructor(
    public children: any[] = [],
    public datamodelField: string = undefined,
    public collectiveAgreement: string = undefined,
    public showLabel: boolean = true
  ) {
    super();
    this.typeElement = 'globalFields';
    this.canHaveChildren = true;
    this.collectiveAgreementField = null;
    this.isCollectiveAgreementFromLocalStorage = false;
    this.salaryClassificationField = null;
    this.fieldReferenceAssociationType = null;
    this.fieldReferenceAssociation = null;
    this.fieldWorkingMode = null;
    this.fieldHasCompanyAgreement = null;
    this.globalFieldWageVisible = false;
    this.globalFieldFactorVisible = false;
    this.globalFieldFactorisedWageVisible = false;
    this.globalFieldWageLabel = null;
    this.globalFieldFactorLabel = null;
    this.globalFieldFactorisedWageLabel = null;
    this.globalFieldSection = null;
    this.type = 'globalFields';
  }
}

export class ReferenceAssociationFactorParameters {

  public referenceAssociationType: number;
  public referenceAssociation: number;
  public workingMode: number;
  public hasCompanyAgreement: boolean;

  constructor(
  ) {
    this.referenceAssociationType = null;
    this.referenceAssociation = null;
    this.workingMode = null;
    this.hasCompanyAgreement = false;
  }
}
