import {ChangeDetectionStrategy, Component} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {ToolbarItemAbstract} from '../toolbar-item-abstract.component';
import {GenericCrudService} from '../../../../../../services/generic-crud.service';
import {AbstractGenericGridComponent} from '../../../../abstract-generic-grid.component';
import {MessageGrowlService} from '../../../../../../../core/message/message-growl.service';
import {FormViewerComponent} from '../../../../../../form-viewer/form-viewer.component';

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'app-toolbar-item-import-special-allowances',
    template: ''
})
export class ToolbarItemImportSpecialAllowancesComponent extends ToolbarItemAbstract {

  constructor(
    protected genericCrudService: GenericCrudService,
    protected messageGrowlService: MessageGrowlService,
    protected translate: TranslateService
  ) {
    super();
  }

  public click() {
    const component = this.getComponent();

    if (component instanceof AbstractGenericGridComponent && component.getSelectedEntity()) {
      const entity = component.getSelectedEntity();

      this.genericCrudService.customPost(`phoenix/precalculationarticles/allowances/special`, {articles: [entity.id]})
        .subscribe((data) => {
        this.messageGrowlService.success(this.translate.instant('COMMON.SUCCESS') + '.');
        component.loadEntities().subscribe();
      });
    }
  }
}
