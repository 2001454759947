
import {of as observableOf, Observable} from 'rxjs';

import {map} from 'rxjs/operators';
import {AbstractExecutionStep} from '../../../../core/executor/abstract-execution-step';
import {ExecutionStepStatus} from '../../../../core/executor/execution-step-status';
import {InquiryPositionSalaryTypeMatrix} from './service/inquiry-position-salary-type-matrix';
import {AbstractGenericGridComponent} from '../../../content-renderer/elements/abstract-generic-grid.component';
import {ElementType} from '../../../content-renderer/services/ElementContext';
import {ChangeDetectorRefHelper} from '../../../helpers/change-detector-ref.helper';

export class InquiryDetailsColorDynamicTableEntitiesExecutionStep extends AbstractExecutionStep {

  doExecute(): Observable<ExecutionStepStatus> {
    let component = this.getPayload().getValue();

    if (component instanceof Object && component.component) {
      component = component.component;
    }

    if (!(component instanceof AbstractGenericGridComponent) || component.getElementContext().type !== ElementType.DynamicTree) {
      return this.getFailObservable('You need to pass GenericDynamicTreeOldComponent as Payload value!');
    }

    return this.doColorEntities(component);
  }

  private doColorEntities(component: AbstractGenericGridComponent): Observable<ExecutionStepStatus> {
    const entities: InquiryPositionSalaryTypeMatrix[] = component.getEntities(),
      codes = [];

    for (const entity of entities) {
      if (entity.salaryType && entity.salaryType.articleCode && !codes.includes(entity.salaryType.articleCode)) {
        codes.push(entity.salaryType.articleCode);
      }
    }

    let codesString = '';
    if (codes.length > 0) {
      codesString = codes.join(',');

      return this.genericCrudService.getEntities(
        `phoenix/precalculationarticlecolors/offset/0/limit/50/orderby/id/asc?articleCode=in:${codesString}`
      ).pipe(map((paginated: any) => {
        const articleColors: {
          articleCode: string,
          backgroundColor: string,
          disabledColor: string
        }[] = paginated.data;

        for (const articleColor of articleColors) {
          for (const entity of entities) {
            if (entity.salaryType && entity.salaryType.articleCode === articleColor.articleCode) {
              entity.backgroundColor = articleColor.backgroundColor;
              entity.disabledColor = articleColor.disabledColor;
            }
          }
        }

        ChangeDetectorRefHelper.detectChanges(component);

        return {status: true, content: null};
      }));
    }

    return observableOf({status: false, content: 'No article codes found!'});
  }
}
