/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../../node_modules/primeng/components/confirmdialog/confirmdialog.ngfactory";
import * as i2 from "primeng/components/confirmdialog/confirmdialog";
import * as i3 from "primeng/components/common/confirmationservice";
import * as i4 from "../../../../node_modules/primeng/components/growl/growl.ngfactory";
import * as i5 from "primeng/components/growl/growl";
import * as i6 from "primeng/components/common/messageservice";
import * as i7 from "./message.component";
import * as i8 from "./message.service";
var styles_MessageComponent = [];
var RenderType_MessageComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_MessageComponent, data: {} });
export { RenderType_MessageComponent as RenderType_MessageComponent };
export function View_MessageComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "p-confirmDialog", [], null, null, null, i1.View_ConfirmDialog_0, i1.RenderType_ConfirmDialog)), i0.ɵdid(1, 180224, null, 1, i2.ConfirmDialog, [i0.ElementRef, i0.Renderer2, i3.ConfirmationService, i0.NgZone], { acceptLabel: [0, "acceptLabel"], rejectLabel: [1, "rejectLabel"] }, null), i0.ɵqud(603979776, 1, { footer: 0 }), (_l()(), i0.ɵeld(3, 0, null, null, 1, "p-growl", [], null, null, null, i4.View_Growl_0, i4.RenderType_Growl)), i0.ɵdid(4, 4636672, null, 0, i5.Growl, [i0.ElementRef, i0.IterableDiffers, [2, i6.MessageService], i0.NgZone], { life: [0, "life"], value: [1, "value"], sticky: [2, "sticky"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.confirmationDefaults.acceptLabel; var currVal_1 = _co.confirmationDefaults.rejectLabel; _ck(_v, 1, 0, currVal_0, currVal_1); var currVal_2 = _co.growlOptions.life; var currVal_3 = _co.growlMessages; var currVal_4 = _co.growlOptions.sticky; _ck(_v, 4, 0, currVal_2, currVal_3, currVal_4); }, null); }
export function View_MessageComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-messages", [], null, null, null, View_MessageComponent_0, RenderType_MessageComponent)), i0.ɵdid(1, 114688, null, 0, i7.MessageComponent, [i8.MessageService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var MessageComponentNgFactory = i0.ɵccf("app-messages", i7.MessageComponent, View_MessageComponent_Host_0, {}, {}, []);
export { MessageComponentNgFactory as MessageComponentNgFactory };
