import { AbstractValidator } from './abstract-validator';

export class MemoValidator extends AbstractValidator {

  public isValid(value: any): boolean {
    let isValid = true;

    if (typeof value === 'undefined' || value === null) {
      value = '';
    }

    let patternValue = 'XXXX';

    return !(new RegExp(patternValue).test(value.toString()));
  }

  public getErrorTranslateKey(value: any): string {
    return 'NOT_ALL_PLACEHOLDER_ARE_REPLACED';
  }

  public getErrorTranslateParams(value: any): any {
    return null;
  }
}
