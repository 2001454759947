
    <p-autoComplete
      appAutocomplete
      appendTo="body"
      field="label"
      [dropdown]="true"
      [placeholder]="'---'"
      [(ngModel)]="selectedOption"
      [suggestions]="options"
      (onKeyUp)="onKeyUp($event)"
      (completeMethod)="onSearch($event)"
      (onDropdownClickNoQuery)="onSearch($event)"
      (onSelect)="onLeasedEmployeeChanged($event)"
    ></p-autoComplete>
  