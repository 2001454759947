import {ChangeDetectionStrategy, Component, Input, OnInit} from '@angular/core';
import { GenericCrudRequestOptions } from '../../../../../../services/generic-crud-request-options.service';
import { ToolbarItemFilter } from '../toolbar-item-filter.component';
import { GenericGridGlobalFilterService } from '../../../../generic-grid/services/generic-grid-global-filter.service';
import {AbstractGenericGridComponent} from '../../../../abstract-generic-grid.component';
import {Constants} from '../../../../../../../constants';
import {ToolbarItemAbstract} from '../toolbar-item-abstract.component';
import {FormViewerComponent} from '../../../../../../form-viewer/form-viewer.component';

interface ToolbarItemCheckboxAware {
  click();
}

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'toolbar-item-show-ignore-branch-office',
  template: '',
  providers: [ GenericGridGlobalFilterService ]
})
export class ToolbarItemShowIgnoreBranchOfficeComponent extends ToolbarItemFilter {

  protected getFilterKey(): string {
    return GenericCrudRequestOptions.IGNORE_BRANCH_OFFICE;
  }

  public click() {

    event = new CustomEvent(Constants.EVENT_CLICK_FILTER_TOOLBAR_ITEM);

    this.doShowSoftDeletedModuleComponent();
  }

  protected getFilterKeyInGridContext(filterKey: string, gridComponent: AbstractGenericGridComponent): string {
    return this.getFilterKeyInModuleContext(filterKey);
  }

  protected getFilterKeyInFormContext(filterKey: string, formComponent: FormViewerComponent): string {
    return this.getFilterKeyInModuleContext(filterKey);
  }


  protected doShowSoftDeletedGridComponent(gridComponent: AbstractGenericGridComponent) {

    gridComponent.loadEntities().subscribe();
  }

  protected doShowSoftDeletedFormComponent(formComponent: FormViewerComponent) {
  }
}
