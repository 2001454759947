import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';


import { isNullOrUndefined } from "util";

export class Filter {
  id: any;
  property: string;
  filterType: string;
  filterValue: any;
  filterValueTo?: any;
}

@Injectable()
export class EntitiesFilterService {

  readonly FILTER_TYPE_DATE_RANGE = 'range_date';
  readonly FILTER_TYPE_INT_RANGE = 'range_int';
  readonly FILTER_TYPE_EQUALS = 'equals';
  readonly FILTER_TYPE_LIKE = 'like';
  readonly FILTER_TYPE_STARTS = 'starts';
  readonly FILTER_TYPE_BOOLEAN = 'boolean';

  private filters: Array<Filter> = [];

  private entities: any = [];

  constructor() {
  }

  /**
   *
   * @returns {Array}
   */
  public filterEntities() {
    let filteredEntities = [];

    let me = this;

    for (let entity of this.entities) {
      let passedFilter = true;
      for (let filter of this.filters) {
        switch (filter.filterType) {
          case this.FILTER_TYPE_BOOLEAN:
            passedFilter = passedFilter && this.checkBooleanFilter(entity[filter.property], filter.filterValue);
            break;
          case this.FILTER_TYPE_DATE_RANGE:
            passedFilter = passedFilter && this.checkDateFilter(entity[filter.property], filter.filterValue, filter.filterValueTo);
            break;
          case this.FILTER_TYPE_INT_RANGE:
            passedFilter = passedFilter && this.checkIntRangeFilter(entity[filter.property], filter.filterValue, filter.filterValueTo);
            break;
          case this.FILTER_TYPE_EQUALS:
            passedFilter = passedFilter && this.checkEqualsFilter(entity[filter.property], filter.filterValue);
            break;
          case this.FILTER_TYPE_LIKE:
            passedFilter = passedFilter && this.checkLikeFilter(entity[filter.property], filter.filterValue);
            break;
          case this.FILTER_TYPE_STARTS:
            passedFilter = passedFilter && this.checkStartsFilter(entity[filter.property], filter.filterValue);
            break;
        }
      }

      if (passedFilter) {
        filteredEntities.push(entity);
      }
    }

    return filteredEntities;
  }

  private checkDateFilter(value, from, to) {
    let filterFromDate = new Date(from);
    let filterToDate = new Date(to);

    return this.checkValue(value) && filterFromDate <= value
      && value <= filterToDate;
  }

  private checkIntRangeFilter(value, from, to) {
    return this.checkValue(value) && parseInt(from) <= value && value <= parseInt(to);
  }

  private checkEqualsFilter(value, filterValue) {
    return this.checkValue(value) && value == filterValue;
  }

  private checkLikeFilter(value, filterValue) {
    return this.checkValue(value) && value.indexOf(filterValue) >= 0;
  }

  private checkStartsFilter(value, filterValue) {
    return this.checkValue(value) && value.indexOf(filterValue) == 0;
  }

  private checkBooleanFilter(value, filterValue) {
    return this.checkValue(value) && value == filterValue;
  }

  private checkValue(value) {
    return !isNullOrUndefined(value);
  }

  /**
   *
   * @param filter
   * @returns {EntitiesFilterService}
   */
  public addFilter(filter) {
    this.filters.push(filter);

    return this;
  }

  public setFilters(filters) {
    this.filters = filters;

    return this;
  }

  /**
   *
   * @param entity
   * @returns {EntitiesFilterService}
   */
  public addEntity(entity) {
    this.entities.push(entity);

    return this;
  }

  public setEntities(entities) {
    this.entities = entities;

    return this;
  }
}
