import * as i0 from "@angular/core";
export class WindowService {
    constructor() {
        this.windows = {};
    }
    open(key, { url, target, features }) {
        this.windows[key] = window.open(url, target, features);
        return this.windows[key];
    }
    close(key) {
        if (this.windows[key]) {
            this.windows[key].close();
        }
    }
    replace(key, options) {
        if (this.windows[key] && this.windows[key].closed === false) {
            this.replaceDocument(key, options);
        }
        else if (this.windows[key] && this.windows[key].closed === true) {
            this.open(key, options);
        }
        if (!this.windows[key]) {
            this.open(key, options);
        }
    }
    replaceDocument(key, { url }) {
        this.windows[key].location.replace(url);
    }
}
WindowService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function WindowService_Factory() { return new WindowService(); }, token: WindowService, providedIn: "root" });
