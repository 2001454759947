<p-table
  *ngIf="!info"
  [value]="logs"
  resizableColumns="true"
  selectionMode="single"
  scrollable="true"
  scrollHeight="500"
>
  <ng-template pTemplate="header">
    <tr>
      <th>{{ 'LOG.LEVEL.LEVEL' | translate }}</th>
      <th>{{ 'LOG.MESSAGE' | translate }}</th>
      <th>{{ 'COMMON.MENU' | translate }}</th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-log>
    <tr [pSelectableRow]="log">
      <td>{{ getLevel(log) }}</td>
      <td>{{ log.message}}</td>
      <td><i (click)="openInfo(log)" class="fa fa-warning" aria-hidden="true" pTooltip="{{ 'LOG.INFO' | translate }}"></i></td>
    </tr>
  </ng-template>
</p-table>




<p-panel *ngIf="info">
    <p-header>
        <div class="ui-helper-clearfix">
            <i (click)="info = null" class="fa fa-close" aria-hidden="true" pTooltip="{{ 'COMMON.CLOSE' | translate }}"></i>
        </div>
    </p-header>
    {{ info }}
</p-panel>
