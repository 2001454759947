import {Component, OnInit, Input} from '@angular/core';
import {Menu} from '../../../../../../shared/services/menu/menu';
import {TranslateService} from '@ngx-translate/core';
import {GenericCrudService} from '../../../../../../shared/services/generic-crud.service';
import {MessageGrowlService} from '../../../../../message/message-growl.service';
import {RequestCachingService} from '../../../../../../shared/services/request-caching.service';
import {Constants} from '../../../../../../constants';
import {Subscription} from 'rxjs/Rx';
import {map} from 'rxjs/operators';
import {File} from '../../../../../../shared/models/file';
import {ChangeDetectorRefHelper} from '../../../../../../shared/helpers/change-detector-ref.helper';
import {forkJoin, Observable, of} from 'rxjs';
import {MessageService} from '../../../../../message/message.service';
import {environment} from '../../../../../../../environments';

@Component({
  selector: 'app-invoice-open-positions-import-menu-item',
  templateUrl: './invoice-open-positions-import-menu-item.component.html',
  styleUrls: ['./invoice-open-positions-import-menu-item.component.scss']
})
export class InvoiceOpenPositionsImportMenuItemComponent implements OnInit {

  @Input() iconsPath = '';
  @Input() item: Menu;
  @Input() menuPosition = 'left';

  public isDialogVisible = false;
  public isDataLoading = false;
  public baseUrl = '';

  constructor(
    protected translateService: TranslateService,
    protected genericCrudService: GenericCrudService,
    protected messageGrowlService: MessageGrowlService,
    protected messageService: MessageService,
    protected requestCachingService: RequestCachingService
  ) {
    this.baseUrl = environment.baseUrl;
  }

  ngOnInit() {
  }

  public onItemClick(item) {
    this.isDialogVisible = true;
    return false;
  }

  public onUpload(event): void {
    this.dialogHide();
  }

  dialogHide() {
    this.isDialogVisible = false;
    this.isDataLoading = false;
  }
}
