/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./toolbar-item-delete-filters.component";
import * as i2 from "../../../../../../../core/message/message-growl.service";
import * as i3 from "../../../../../services/component-highlight-stack.service";
var styles_ToolbarItemDeleteFilters = [];
var RenderType_ToolbarItemDeleteFilters = i0.ɵcrt({ encapsulation: 2, styles: styles_ToolbarItemDeleteFilters, data: {} });
export { RenderType_ToolbarItemDeleteFilters as RenderType_ToolbarItemDeleteFilters };
export function View_ToolbarItemDeleteFilters_0(_l) { return i0.ɵvid(2, [], null, null); }
export function View_ToolbarItemDeleteFilters_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "toolbar-item-delete-filters", [], null, null, null, View_ToolbarItemDeleteFilters_0, RenderType_ToolbarItemDeleteFilters)), i0.ɵdid(1, 245760, null, 0, i1.ToolbarItemDeleteFilters, [i2.MessageGrowlService, i3.ComponentService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ToolbarItemDeleteFiltersNgFactory = i0.ɵccf("toolbar-item-delete-filters", i1.ToolbarItemDeleteFilters, View_ToolbarItemDeleteFilters_Host_0, { params: "params" }, {}, []);
export { ToolbarItemDeleteFiltersNgFactory as ToolbarItemDeleteFiltersNgFactory };
