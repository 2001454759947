<table>
  <tr>
    <td [hidden]="isCopy() || isSummary()">
      <button pButton
              icon="fa fa-plus"
              (click)="copyWorkEntry()">
      </button>
    </td>
  </tr>
</table>
