import {AbstractFormActionHandler} from '../abstract-form-action-handler';
import {Entity} from '../../../../helpers/entity';

export class HandleJobAdvertisementActionHandler extends AbstractFormActionHandler {

  private mapping = {
    'regionalJobs': [
      'regionalJobFrom',
      'regionalJobTo'
    ],
    'steierJobs': [
      'steierJobFrom',
      'steierJobTo'
    ],
    'salzburgJobs': [
      'salzburgJobFrom',
      'salzburgJobTo'
    ],
    'rheintalJobs': [
      'rheintalJobFrom',
      'rheintalJobTo'
    ],
    'willhaben': [
      'willhabenFrom',
      'willhabenTo'
    ],
    'karriereAt': [
      'karriereFrom',
      'karriereTo'
    ],
    'stepstone': [
      'stepstoneFrom',
      'stepstoneTo'
    ]
  };

  public handleAction(): void {
    const jobAdvertisement = this.getEntity();

    if (this.isValid()) {
      for (const hasLicenceDatamodelField in this.mapping) {
        if (this.mapping.hasOwnProperty(hasLicenceDatamodelField)) {
          if (Entity.getValue(jobAdvertisement, hasLicenceDatamodelField) === true) {
            this.showFields(this.mapping[hasLicenceDatamodelField]);
          } else {
            this.hideFields(this.mapping[hasLicenceDatamodelField]);
          }
        }
      }
    }
  }

  private isValid(): boolean {
    return this.getEntity();
  }

  private showFields(datamodelFieldNames: string[] = []): void {
    for (const datamodelFieldName of datamodelFieldNames) {
      const element = this.formService.getElementBy(this.getForm(), 'datamodelField', datamodelFieldName);

      if (element) {
        element.isHidden = false;
        this.markElementForCheck(element);
      }
    }
  }

  private hideFields(datamodelFieldNames: string[] = []): void {
    for (const datamodelFieldName of datamodelFieldNames) {
      const element = this.formService.getElementBy(this.getForm(), 'datamodelField', datamodelFieldName);

      if (element) {
        element.isHidden = true;
        this.markElementForCheck(element);
      }
    }
  }
}
