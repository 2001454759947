import {
  Component,
  Input,
  OnInit,
  OnDestroy,
  ElementRef,
  HostListener,
  ViewChild,
  ChangeDetectionStrategy,
  ChangeDetectorRef
} from '@angular/core';
import { Module } from '../services/module/module';
import { GenericCrudBulkService } from '../services/generic-crud-bulk.service';
import {Subject, Subscription} from 'rxjs';
import { Template } from '../services/template/template';
import { LayoutService } from '../services/layout-service';
import { GenericCrudService } from '../services/generic-crud.service';
import {takeUntil} from 'rxjs/operators';
import {ChangeDetectorRefHelper} from '../helpers/change-detector-ref.helper';
import {ElementContext} from './services/ElementContext';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-content-renderer',
  styleUrls: ['./content-renderer.component.scss'],
  templateUrl: './content-renderer.component.html'
})
export class ContentRendererComponent implements OnInit, OnDestroy {

  private givenModule: Module;

  protected subscriptions: Subscription[] = [];
  public unsubscribe = new Subject<void>();

  @Input() elements;
  @Input() template: Template;
  @Input() entity: any;
  @Input() additional: any;
  @Input() masterField?: string;
  @Input() masterEntity?: any;
  @Input() masterEntityEditingField?: string;
  @Input() masterElementContext: ElementContext = null;
  @Input() isPreview = false;
  @Input() isPart = false;
  @Input() isDialog = false;
  @Input() masterFilterField?: string;
  @Input() masterFilterValue?: string;
  @Input() justAFilter?: any;

  private splitConfig: Object;
  private moduleElementsHavePixelSize = false;

  @ViewChild('contentRendererContainer', {static: false}) set contentRendererContainer(contentRendererContainer: ElementRef) {
    this.layoutService.onLayoutSizeChanged();
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.layoutService.onLayoutSizeChanged();
  }

  @Input() set moduleId(moduleId: number) {
    this.unsubscribe.next();
    this.unsubscribe.complete();

    this.unsubscribe = new Subject();

    this.genericCrudService.getEntity('superadmin/modules', moduleId)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(module => this.module = module);
  }

  @Input() set module(module: Module) {
    this.givenModule = module;

    if (module) {
      this.elements = (module._embedded && module._embedded.template) ? module._embedded.template.templateElements :
        module.template.templateElements;
      this.template = (module._embedded && module._embedded.template) ? module._embedded.template : module.template;

      this.recalculateSplitSize();
      ChangeDetectorRefHelper.detectChanges(this);
    }
  }

  get module(): Module {
    return this.givenModule;
  }

  public getToolbarExtraParams() {
    return {
      'moduleComponent': this,
      'printSectionId': 'module-wrapper-container'
    };
  }

  public constructor(
    private genericCrudService: GenericCrudService,
    private genericCrudBulkService: GenericCrudBulkService,
    private elementRef: ElementRef,
    private layoutService: LayoutService,
    public cdr: ChangeDetectorRef
  ) {
    this.splitConfig = {
      gutterSize: 5,
      gutterSizeSidebarLeft: 5,
      gutterSizeSidebarRight: 5,
      sidebarSizeLeft: 20,
      sidebarSizeRight: 18
    };
  }

  ngOnInit() {
    this.layoutService.layoutSizeChanged$.subscribe(() => {
      if (this.template && this.elements) {
        this.recalculateSplitSize();
      }

      ChangeDetectorRefHelper.detectChanges(this);
    });
  }

  ngOnDestroy() {
    this.subscriptions.forEach(sub => sub.unsubscribe());
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  public onSplitLayoutChanged(event) {
    window.dispatchEvent(new Event('resize'));
  }

  /**
   * @description Subtract document clientWidth|clientHeight by moduleElement pixelSize and template gutterSize
   * Use that calculated width|height to later properly render another moduleElements in percentageSize
   * @returns number
   */
  private getModuleContainerSizeForSplitCalculation(): number {
    let containerSizeInPx = this.elementRef.nativeElement.clientWidth;

    if (this.template.direction === Template.DIRECTION_VERTICAL) {
      containerSizeInPx = this.elementRef.nativeElement.clientHeight;
    }

    let i = 1;
    if (this.givenModule.moduleElements) {
      for (const moduleElement of this.givenModule.moduleElements) {

        if (!moduleElement.percentageSize && moduleElement.pixelSize) {
          this.moduleElementsHavePixelSize = true;

          containerSizeInPx -= moduleElement.pixelSize;
        }

        // (GU) TODO::Check if there is a way to use moduleElement gutterSize instead of template gutterSize,
        // for now substract template.gutterSize as this one is used in template
        if (i !== this.givenModule.moduleElements.length) {
          containerSizeInPx -= this.template.gutterSize;
        }

        i++;
      }
    }

    return containerSizeInPx;
  }

  /**
   * @description Calculate split size based on moduleElements
   * If moduleElements do have size defined in PX use pixels if not use %
   * If total percentage size of moduleElements is not more than 100% add difference to the last moduleElement.percentageSize
   */
  private recalculateSplitSize() {
    // reset
    this.moduleElementsHavePixelSize = false;

    const moduleContainerCalculatedSize = this.getModuleContainerSizeForSplitCalculation();

    let percentageSizeModuleElements = [],
      totalPercentageSize = 0;

    if (this.givenModule.moduleElements) {
      percentageSizeModuleElements = this.givenModule.moduleElements.filter((moduleElement) => { return moduleElement.percentageSize })
    }

    let i = 1;
    if (this.moduleElementsHavePixelSize) {
      for (const moduleElement of percentageSizeModuleElements) {
        moduleElement.pixelSize = null;

        totalPercentageSize += moduleElement.percentageSize;

        if (i === percentageSizeModuleElements.length && totalPercentageSize < 100) {
          moduleElement.percentageSize = moduleElement.percentageSize + (100 - totalPercentageSize);
        }

        moduleElement.percentageSize = null;
        moduleElement.pixelSize = moduleContainerCalculatedSize / 100 * moduleElement.percentageSize;
        i++;
      }
    }
  }

}
