
import {throwError as observableThrowError, of as observableOf, Observable, Subscription} from 'rxjs';

import {map, catchError, switchMap} from 'rxjs/operators';
import {
  Component,
  Input,
  ViewContainerRef,
  ElementRef,
  Renderer2,
  ChangeDetectorRef,
  AfterViewInit,
  ChangeDetectionStrategy
} from '@angular/core';
import {TreeNode, ConfirmationService} from 'primeng/primeng';
import {Element} from './../../../services/element/element';
import {GenericCrudService} from './../../../services/generic-crud.service';
import {GenericElementAbstract} from '../generic-element-abstract.component';
import {ComponentService} from '../../services/component-highlight-stack.service';
import {EntityDataStoreService, EntityData} from '../../services/entity-data-store.service';
import {ModulesStateService} from '../../services/modules-state.service';
import {PermissionService} from 'app/shared/services/permission/permission.service';
import {EventHandlerService} from '../../../../core/events/event/event-handler.service';
import {GenericTreeInitEvent} from '../../../services/event/event/tree/generic-tree-init-event';
import {EventActionsSubscribeResponse} from '../../../../core/events/interfaces/event-actions-subscribe-response';
import {GenericTreeInitActionHandler} from '../../../services/event/action-handler/tree/generic-tree-init-action-handler';
import {GenericTreeDestroyEvent} from '../../../services/event/event/tree/generic-tree-destroy-event';
import {
  GenericGridColumnBuilderService
} from 'app/shared/content-renderer/elements/generic-grid/services/generic-grid-column-builder.service';
import {ElementType} from 'app/shared/content-renderer/services/ElementContext';
import {
  GenericElementCreateContextActionHandler
} from '../../../services/event/action-handler/generic-element-create-context-action-handler';
import {LocationService} from '../../../services/location.service';
import {ElementsStackService} from '../../services/elements-stack.service';
import {
  GenericElementInitToolbarItemsActionHandler
} from '../../../services/event/action-handler/generic-element-init-toolbar-items-action-handler';
import {GenericElementInitColumnsActionHandler} from '../../../services/event/action-handler/generic-element-init-columns-action-handler';
import {EntityFactoryService} from '../../../services/entity-factory.service';
import {LayoutService} from '../../../services/layout-service';
import {HttpErrorResponseService} from 'app/shared/services/http-error-response-message.service';
import {GenericGridGlobalFilterService} from '../generic-grid/services/generic-grid-global-filter.service';
import {GenericGridRemoteFilterService} from '../generic-grid/services/generic-grid-remote-filter.service';
import {ToolbarItemCheckService} from 'app/shared/content-renderer/elements/generic-toolbar/services/check/toolbar-item-check.service';
import {ElementsStateService} from '../../services/elements-state.service';
import {cloneDeep} from 'lodash';
import {GenericTreeLayoutService} from './service/generic-tree-layout-service';
import {GenericElementInlineEditorService} from '../../services/generic/generic-element-inline-editor.service';
import {EntityHydrator} from '../../../services/entity-hydrator.service';
import {AbstractGenericGridComponent} from '../abstract-generic-grid.component';
import {EntityDirtyStoreService, StoreType} from '../../services/entity-dirty-store.service';
import {
  GenericTreeSaveComponentStateActionHandler
} from '../../../services/event/action-handler/tree/generic-tree-save-component-state-action-handler';
import {
  GenericTreeLoadComponentStateActionHandler
} from '../../../services/event/action-handler/tree/generic-tree-load-component-state-action-handler';
import {MessageGrowlService} from '../../../../core/message/message-growl.service';
import {TranslateService} from '@ngx-translate/core';
import {LoggerService} from 'app/shared/content-renderer/services/logger/logger.service';
import {GenericTreeEntityService} from '../../services/generic/entity/generic-tree-entity.service';
import {RequestCachingService} from '../../../services/request-caching.service';
import {GenericGridBulkSaveService} from '../generic-grid/services/generic-grid-bulk-save.service';
import {GenericElementFilterService} from '../../services/generic/filter/generic-element-filter.service';
import {ExecutorService} from '../../../../core/executor/executor.service';
import {
  GenericElementValidationExecutionStepsFactory
} from 'app/shared/content-renderer/services/generic/generic-element-validation-execution-steps-factory';
import {EntityValidator} from '../../../validators/services/entity-validator';
import {Guid} from 'guid-typescript';
import {CancelComponentChangesService} from 'app/shared/content-renderer/services/cancel-components-changes.service';
import {Constants} from 'app/constants';
import {FieldActionsService} from 'app/shared/content-renderer/services/field-actions.service';
import {EntityStatus} from '../../../services/entity/entity-status';
import {LocalStorageDataService} from '../../../services/local-storage-data.service';
import {ExecutorActionEvent} from '../../../../core/executor/service/executor-actions/executor-action-event';
import {ActionsEvent} from '../../../services/action/action';
import {ExecutorActionsService} from '../../../../core/executor/service/executor-actions/executor-actions.service';
import { JobContext } from '../../../../core/job-runner/context/job.context';
import { JobContainerService } from '../../../../core/job-runner/job-container.service';
import { RunnableEventRegistry } from '../../../../core/job-runner/type/runnable-event.registry';
import {ElementContext, MasterEntityConfig, PerformedAction} from '../../services/ElementContext';
import {ToolbarItem} from '../../../services/element/toolbar-item';
import {ElementState} from '../../services/element-state';
import {Debounce} from '../../../helpers/debounce';
import {ExecutionStepBuilderService} from '../../../../core/executor/builder/execution-step-builder.service';
import {DoubleClickService} from '../../services/double-click.service';
import {GenericDialogModuleService} from '../generic-dialog/service/generic-dialog-module.service';
import {GenericElementEmbeddedService} from '../../services/generic/generic-element-embedded.service';
import {GenericElementContextMenuService} from '../../services/generic/generic-element-context-menu.service';
import {EntityManagerService} from '../../../../core/service/entity-manager/entity-manager.service';
import {EventHelper} from '../../../helpers/event.helper';
import {ChangeDetectorRefHelper} from '../../../helpers/change-detector-ref.helper';
import {UserSessionService} from '../../../../core/service/user-session.service';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-generic-tree-grid',
  styleUrls: ['./generic-tree-grid.component.scss', '../generic-turbo-grid/generic-turbo-grid.component.scss'],
  templateUrl: './generic-tree-grid.component.html',
  providers: [
    CancelComponentChangesService,
    GenericGridColumnBuilderService,
    GenericGridGlobalFilterService,
    GenericGridRemoteFilterService,
    GenericTreeLayoutService,
    GenericElementInlineEditorService,
    GenericElementFilterService,
    GenericTreeEntityService,
    GenericGridBulkSaveService,
    GenericElementValidationExecutionStepsFactory,
    ExecutorService
  ]
})
export class GenericTreeGridComponent extends AbstractGenericGridComponent implements AfterViewInit {
  @Input() element: Element;
  @Input() parentComponent: GenericElementAbstract;
  @Input() masterEntity: any = null;
  @Input() isPart = false;
  @Input() isSlave = false;
  @Input() isInSubView = false;
  @Input() masterFilterField?: string;
  @Input() masterFilterValue?: string;

  public emptyEntity: any = {};
  public responseFormat = 'fortree';

  @Input() set masterField(masterField: string) {
    this.masterEntityField = masterField;
  };

  public elementType: ElementType = ElementType.Tree;

  public selectedNodes: TreeNode[] = [];
  public selectedNode: TreeNode = null;

  public subscriptions: Subscription[] = [];

  public dateRangeMinValue: number = (new Date).getFullYear() - 25;
  public dateRangeMaxValue: number = (new Date).getFullYear() + 25;
  public dateRangeValues: number[] = [this.dateRangeMinValue, this.dateRangeMaxValue];

  protected toolbarContextName = 'treeComponent';

  constructor(
    protected componentService: ComponentService,
    protected viewContainerRef: ViewContainerRef,
    protected genericCrudService: GenericCrudService,
    protected entityDataStore: EntityDataStoreService,
    protected modulesStateService: ModulesStateService,
    protected permissionService: PermissionService,
    protected eventHandlerService: EventHandlerService,
    protected genericGridColumnBuilderService: GenericGridColumnBuilderService,
    protected genericGridGlobalFilterService: GenericGridGlobalFilterService,
    protected genericGridRemoteFilterService: GenericGridRemoteFilterService,
    protected doubleClickService: DoubleClickService,
    protected locationService: LocationService,
    protected elementsStackService: ElementsStackService,
    protected entityFactory: EntityFactoryService,
    protected layoutService: LayoutService,
    protected httpErrorResponseService: HttpErrorResponseService,
    protected elementsStateService: ElementsStateService,
    protected toolbarItemCheckService: ToolbarItemCheckService,
    protected elementRef: ElementRef,
    protected renderer: Renderer2,
    protected genericTreeLayoutService: GenericTreeLayoutService,
    protected genericElementInlineEditorService: GenericElementInlineEditorService,
    protected genericElementFilterService: GenericElementFilterService,
    protected entityHydrator: EntityHydrator,
    protected entityDirtyStore: EntityDirtyStoreService,
    protected messageGrowlService: MessageGrowlService,
    protected translationService: TranslateService,
    protected logger: LoggerService,
    protected genericElementEntityService: GenericTreeEntityService,
    protected genericGridBulkSaveService: GenericGridBulkSaveService,
    protected requestCachingService: RequestCachingService,
    protected confirmationService: ConfirmationService,
    protected cancelComponentChangesService: CancelComponentChangesService,
    protected executorService: ExecutorService,
    protected genericElementValidationExecutionStepsFactory: GenericElementValidationExecutionStepsFactory,
    protected entityValidator: EntityValidator,
    protected fieldActionsService: FieldActionsService,
    protected userSession: UserSessionService,
    protected executorActionsService: ExecutorActionsService,
    protected jobContainerService: JobContainerService,
    protected executionStepBuilderService: ExecutionStepBuilderService,
    protected genericDialogModuleService: GenericDialogModuleService,
    protected embedded: GenericElementEmbeddedService,
    protected contextMenu: GenericElementContextMenuService,
    protected entityManager: EntityManagerService,
    public cdr: ChangeDetectorRef
  ) {
    super(componentService, viewContainerRef, entityDataStore, modulesStateService,
      genericGridColumnBuilderService, genericElementEntityService, genericElementInlineEditorService,
      permissionService, genericGridBulkSaveService, entityDirtyStore, entityDataStore,
      requestCachingService, toolbarItemCheckService, eventHandlerService, confirmationService, translationService,
      genericElementFilterService, cancelComponentChangesService, executorService, genericElementValidationExecutionStepsFactory,
      entityValidator, elementsStateService, genericCrudService, fieldActionsService, userSession, executionStepBuilderService,
      layoutService, genericDialogModuleService, genericGridRemoteFilterService, embedded, contextMenu, entityManager, jobContainerService,
      cdr
    );
  }

  public ngOnInit() {
    super.ngOnInit();

    this.returnState();
    this.onComponentInit();
  }

  public ngOnDestroy(): void {
    super.ngOnDestroy();

    this.removeState();

    this.subscriptions.forEach((sub) => sub.unsubscribe());
  }

  public onComponentInit() {
    this.genericElementInlineEditorService.setComponent(this);

    this.elementContext = this.createContext();
    this.runJobs(RunnableEventRegistry.ContextCreated);

    this.subscriptions.push(
      this.executorActionsService
        .registerModuleElementActions(this.moduleElement)
        .subscribe()
    );

    this.eventHandlerService.getRegistry()
      .register(GenericTreeInitEvent, new GenericTreeInitActionHandler(
        this.entityDataStore,
        this.elementsStateService,
        this.toolbarItemCheckService,
        this.layoutService
      ))
      .register(GenericTreeInitEvent, new GenericElementCreateContextActionHandler(
        this.locationService,
        this.elementsStackService
      ))
      .register(GenericTreeInitEvent, new GenericElementInitToolbarItemsActionHandler(this.permissionService))
      .register(GenericTreeInitEvent, new GenericElementInitColumnsActionHandler(this.genericGridColumnBuilderService))
      .register(GenericTreeInitEvent, new GenericTreeLoadComponentStateActionHandler(
        this.elementsStateService
      ));

    this.subscriptions.push(
      this.eventHandlerService.handle(new GenericTreeInitEvent(this))
        .subscribe((response: EventActionsSubscribeResponse) => {
        })
    );

    if (this.element.pageSize) {
      this.defaultPageSize = this.element.pageSize;
    }
    this.initToolbarItems();

    if (this.element.datamodel && !this.hasMasterElement()) {
      this.subscriptions.push(
        this.loadEntities().subscribe()
      );
    }

    const state = this.componentState;

    const stateSortField = state ? state.sortField : null;
    const stateSortDirection = state ? state.sortDirection : null;

    this.sortField = stateSortField || this.element.sortField || this.sortField;
    this.sortDirection = stateSortDirection || this.element.sortDirection || this.sortDirection;

    if (this.element.datamodel && this.hasMasterElement()) {
      const context = new JobContext();
      context.identifier = Guid.create().toString();
      context.component = this;
      context.event = RunnableEventRegistry.PostInit;

      this.jobContainerService.runRelevantJobs(context);
    }

    this.subscriptions.push(
      this.executeAction(ExecutorActionEvent.Init, this).subscribe()
    );
  }

  public onSort(event): void {
    const orderBy = event.order;

    this.sortField = event.field;
    this.sortDirection = orderBy === 1 ? Element.SORT_DIRECTION_ASC : Element.SORT_DIRECTION_DESC;

    Debounce.debounce(() => {
      this.subscriptions.push(
        this.loadEntities().subscribe()
      );
      }, 10);
  }

  public onEntityChangeInit(changeData): void {
    this.executeAction(ExecutorActionEvent.BeforeInlineEditInit, this).subscribe();
  }

  public getColumnEntityValue(column: any, node: TreeNode): any {
    if (typeof column.getValue !== 'undefined') {
      return column.getValue(node);
    }

    return node.data[column.property];
  }

  public ngAfterViewInit() {

  }

  public onGridRendered() {
    this.genericTreeLayoutService
    .setTree(this)
    .setElementRef(this.elementRef)
    .setRenderer(this.renderer)
    .setScrollHeight()
    .setScrollWidth()
    .markEntityNodes();
  }

  public getAllNodes(): TreeNode[] {
    const nodes: TreeNode[] = [];

    const getNodes = (aNodes: TreeNode[], parent: TreeNode[] = []) => {
      for (const node of aNodes) {
        parent.push(node);

        if (node.children) {
          getNodes(node.children, parent);
        }
      }
    };

    getNodes(this.entities, nodes);

    return nodes;
  }

  public onNodeSelect(node: any): void {

    Debounce.isDoubleClick((isDoubleClick: boolean) => {
      if (false === isDoubleClick) {

        if (!this.selectedNode) {
            this.onEntitySelected(null, null, true);
          } else {
            this.selectNode(this.selectedNode);
            event = new CustomEvent(Constants.EVENT_CLICK_GRID_ENTITY_SELECTED);
            this.onEntitySelected(event, null, true);
          }
      } else {
        this.selectNode(node);
      }
    }, 200);
  }

  public onNodeUnselect(event: any): void {
    this.selectedNode = null;
    this.selectedEntity = null;
  }

  public onRowDblclick(event: any) {
    this.executeAction(ExecutorActionEvent.DoubleClick, this).subscribe();
    // onDoubleClick needs some refactoring, remove field double click, it will no longer exist... will be moved to field actions
    this.doubleClickService
      .setDialogOptions({
        height: +this.moduleElement.onDoubleClickTargetDialogHeight,
        width: +this.moduleElement.onDoubleClickTargetDialogWidth,
        isModal: this.moduleElement.isDoubleClickTargetDialogModal
      })
      .setComponent(this)
      .onDoubleClick(event);

    this.triggerFieldActions(event, null, ActionsEvent.DoubleClick);
  }

  public onPageChange(event) {
    this.currentOffset = event.first;
    this.defaultPageSize = event.rows;

    this.subscriptions.push(
      this.loadEntities().subscribe()
    );
  }

  public setEmptyEntity() {
      this.entityFactory
      .buildComponentEntity(this).subscribe((entity) => {
        this.emptyEntity = entity;
        this.setEmptyEntityToSlaves(this.emptyEntity);
      });
  }

  public getToolbarExtraParams() {
    return {
      'treeComponent': this
    };
  }

  public loadEntities(): Observable<EntityData> {
    const action = this;
    let apiRoute = this.getElementDataModelApiRoute();

    if (!this.checkMasterEntities()) {
        setTimeout(function() { action.layoutService.onLayoutSizeChanged(); }, 1);
        return observableOf({ data: [], count: 0 });
    }

    if (Object.keys(this.emptyEntity).length === 0) {
      this.setEmptyEntity();
    } else {
        this.entityFactory.assignMasterEntities(this.emptyEntity, this.getElementContext().getMasterEntities());
    }

    this.isDataLoading = true;

    apiRoute = apiRoute + '/offset/' +
        this.currentOffset + '/limit/' +
        this.defaultPageSize + '/orderby/' +
        this.sortField + '/' +
        this.sortDirection.toLowerCase() + '/' +
        this.responseFormat;

    return this.executeAction(ExecutorActionEvent.BeforeLoad, this).pipe(switchMap(() => {
      return this.entityDataStore.getAll(
        apiRoute,
        this.getRemoteDynamicFilters(),
        true
      ).pipe(
        catchError((response) => {
          this.isDataLoading = false;

          return observableThrowError(response);
        }),
        map((entityData: EntityData) => {
          this.isDataLoading = false;

          this.changeGridEntities(entityData.data, entityData.count);

          this.addStateEntities();

          this.toolbarItemCheckService.check(this);

          return entityData;
        }));
    }));
  }

  public addCreatedEntityDraft(entity: any): this {
    entity[EntityStatus.ENTITY_DRAFT_FLAG] = Guid.create().toString();

    this.finishNewEntityAdd(entity);

    return this;
  }

  public changeGridEntities(data: TreeNode[], count: number) {
    this.entities = data || [];
    this.totalCount = count;

    for (const node of this.entities) {
      node.children = node.children || [];
    }

    this.notifySlaves(ExecutorActionEvent.EntitiesChanged)
      .notifyMaster(ExecutorActionEvent.EntitiesChanged);

    this.executeAction(ExecutorActionEvent.EntitiesChanged, this).subscribe();

    const context = new JobContext();
    context.identifier = Guid.create().toString();
    context.component = this;
    context.event = RunnableEventRegistry.PostLoad;

    this.jobContainerService.runRelevantJobs(context);

    this.layoutService.onLayoutSizeChanged();

    if (this.entities.length > 0 && this.moduleElement.selectFirst) {
      this.selectFirstEntity();

      this.onValidate().subscribe();
    }

    ChangeDetectorRefHelper.detectChanges(this);
  }

  public getSelectedNode() {
    return this.selectedNode;
  }

  public getSelectedEntity(): any {
    return (this.selectedNode) ? (this.selectedNode.data || null) : null;
  }

  public startNewEntityAdd(entity?: any): void {
    this.finishNewEntityAdd(this.emptyEntity);
  }

  public onEditCell(event, entity: any, column: any) {
    this.selectedEntity = entity;

    const node = this.genericElementEntityService.getTreeNode(entity);

    if (node) {
      this.selectedNode = node;
    }

    super.onEditCell(event, entity, column);
  }

  public finishNewEntityAdd(entity: any) {
    const newEntity = cloneDeep(entity);

    newEntity[EntityStatus.ENTITY_DRAFT_FLAG] = Guid.create().toString();

    this.entityFactory.assignMasterEntities(newEntity, this.getElementContext().getMasterEntities());

    const newNode = {
        data: newEntity,
        children: [],
        parent: this.selectedNode
      };

    this.genericElementEntityService.addNode(newNode, this.selectedNode).refresh();

    this.notifySlaves(ExecutorActionEvent.EntitiesChanged)
      .notifyMaster(ExecutorActionEvent.EntitiesChanged);

    this.executeAction(ExecutorActionEvent.AfterNewEntityAdded, {
      component: this,
      node: newNode,
      entity: newEntity
    }).subscribe();
    this.executeAction(ExecutorActionEvent.EntitiesChanged, this).subscribe();

    this.selectNode(newNode).onEntitySelected();

    this.toolbarItemCheckService.check(this);
  }

  public getTreeNode(entity: number): any {
    return this.genericElementEntityService.getTreeNode(entity);
  }

  public replaceTreeNode(node: TreeNode, withNode: TreeNode): any {
    return this.genericElementEntityService.replaceTreeNode(node, withNode);
  }

  public getEntityAssociatedOneEntityValue(entity: any, column: any) {
    const value = this.entityHydrator.getEntityPropertyValue(entity, column.id);

    return typeof value === 'string' ? value : '';
  }

  public selectNode(node: TreeNode): this {
    this.selectedNode = node;

    if (this.selectedNode && this.selectedNode.data) {
      this.selectEntity(this.selectedNode.data);
    }

    return this;
  }

  public reselectEntity(): void {
    if (this.selectedEntity) {
      this.resetSelectedEntity();

      this.selectNode(this.selectedNode).onEntitySelected();
    }
  }

  public selectFirstEntity(): this {

    if (this.entities.length > 0) {
      const firstNode = this.entities[0];

      this.selectNode(firstNode).onEntitySelected();
    }

    return this;
  }

  public selectEntity(entity): this {
    this.selectedEntity = entity;

    this.componentService.highlightEntity(this.selectedEntity);

    // Now let's announce this on the context as well:
    this.elementsStackService.setSelectedEntity(this.moduleElement.id, this.selectedEntity);

    return this;
  }

  public onEntitySelected(originalEvent = null, fieldKey = null, userTrigger: boolean = false): void {
    if (userTrigger) {
      event = new CustomEvent(Constants.EVENT_CLICK_GRID_ENTITY_SELECTED);

      this.executeAction(ExecutorActionEvent.Click, this).subscribe();
    }

    this.selectEntity(this.selectedEntity);

    this.triggerFieldActions(originalEvent, fieldKey, ActionsEvent.Click);
    this.triggerSlaves(this.selectedEntity);

    this.notifySlaves(ExecutorActionEvent.EntityChanged)
      .notifyMaster(ExecutorActionEvent.EntityChanged);

    this.executeAction(ExecutorActionEvent.EntityChanged, this).subscribe();
  }

  protected createContext() {
    const isSlave = this.moduleElement && typeof this.moduleElement.master !== 'undefined'
      && this.moduleElement.master !== null && this.moduleElement.master.id > 0;

    const isSubView = this.locationService.hasParam('parent-module')
      || (this.locationService.hasParam('id') && this.locationService.hasParam('master-entity'));

    const elementContext = new ElementContext(
      this.moduleElement.id,
      this.elementType,
      this,
      this.moduleElement,
      !isSlave,
      isSlave,
      !isSlave,
      isSubView,
      this.isPart,
      null,
      this.masterElementContext,
      null,
      null,
      null,
      this.isDialog,
      this.moduleElement.isMaster
    );

    if (isSubView) {
      const elementConfig = new MasterEntityConfig();
      elementConfig.value = this.locationService.getParam('id');
      elementConfig.name = this.locationService.hasParam('master-entity')
        ? this.locationService.getParam('master-entity') : this.moduleElement.masterFilterField;
      elementConfig.datamodelId = 0; // For now - no way to find out.
      elementConfig.filterType = 'subViewMasterEntity';

      elementContext.addMasterEntity(elementConfig);
    }

    // Now, some kinky stuff - parts in dialogs - hit it:
    if (this.isDialog && isSubView && this.selectedMasterEntity && this.moduleElement.masterFilterField) {
      const elementConfig = new MasterEntityConfig();
      elementConfig.value = this.selectedMasterEntity.id;
      elementConfig.name = this.moduleElement.masterFilterField;
      elementConfig.datamodelId = 0; // For now - no way to find out.
      elementConfig.filterType = 'subViewMasterEntity';

      elementContext.addMasterEntity(elementConfig);
    }

    if (this.selectedMasterEntity && this.masterEntityField) {
      const elementConfig = new MasterEntityConfig();
      elementConfig.value = this.selectedMasterEntity.id;
      elementConfig.name = this.masterEntityField;
      elementConfig.datamodelId = 0; // For now - no way to find out.
      elementConfig.filterType = 'masterEntity';

      elementContext.setSelectedMasterEntity(this.selectedMasterEntity).addMasterEntity(elementConfig);
    }

    if (this.masterFilterField && this.masterFilterValue) {
      const elementConfig = new MasterEntityConfig();
      elementConfig.value = this.masterFilterValue;
      elementConfig.name = this.masterFilterField;
      elementConfig.datamodelId = 0; // For now - no way to find out.
      elementConfig.filterType = 'masterEntity';

      elementContext.addMasterEntity(elementConfig);
    }

    // Now let's remove and re-add the grid context if it is already there:
    this.elementsStackService.remove(elementContext).add(elementContext);

    return elementContext;
  }

  private setEmptyEntityToSlaves(entity: any) {
    for (const slaveContext of this.elementContext.getSlaveElementContexts()) {
      if (slaveContext.type === ElementType.Form) {
        slaveContext.component.emptyEntity = this.emptyEntity;
      }
    }
  }

  private checkMasterEntities() {
      let masterEntityConfigThere = true;

      if (this.hasMasterElement()) {
        masterEntityConfigThere = this.getElementContext()
            .findMasterEntity(this.moduleElement.masterFilterField) != null ||
            !this.elementContext.masterElementContext.moduleElement.selectFirst;
      }

      return masterEntityConfigThere;
  }

  public getRemoteDynamicFilters(): Object {
      let remoteFilters = {};

      const showWithoutMasterFilter = this.genericGridGlobalFilterService
        .setGrid(this)
        .getGlobalFilterValue(Constants.SHOW_WITHOUT_MASTER_FILTER_FIELD_FILTER_KEY, null, this.getElementDataModelApiRoute()) || false;

      for (const masterElementConfig of this.getElementContext().getMasterEntities()) {
        // skip master filter field
        if (showWithoutMasterFilter && masterElementConfig.filterType === 'masterEntity' &&
        this.moduleElement.masterFilterField &&
          masterElementConfig.name === this.moduleElement.masterFilterField
        ) {
          continue;
        }

        remoteFilters[masterElementConfig.name] = masterElementConfig.value;
      }

      remoteFilters['embedded'] = this.embedded.getEmbeddedAsString(this);

      for (const staticFilter of this.staticFilters) {
        remoteFilters[staticFilter.field] = staticFilter.value;
      }

      for (const simpleFilter of this.simpleSearchFilters) {
        remoteFilters[simpleFilter.field] = simpleFilter.value;
      }

      const groupByFieldNames = this.genericGridColumnBuilderService.getGroupByFieldNames();
      if (groupByFieldNames.length > 0) {
        remoteFilters['groupBy'] = groupByFieldNames.join(',');
      }

      remoteFilters = Object.assign(remoteFilters, this.genericGridRemoteFilterService.setGrid(this)
        .getModuleElementFilters(this.moduleElement));
      remoteFilters = Object.assign(remoteFilters, this.genericGridRemoteFilterService.setGrid(this)
        .getFilters(this.gridFilters));
      remoteFilters = Object.assign(remoteFilters, this.genericGridGlobalFilterService.setGrid(this)
        .getGridFilters(this.getElementDataModelApiRoute()));
      remoteFilters = Object.assign(remoteFilters, {
          'parent': 'isNull:'
      });

      return remoteFilters;
  }

  public returnState(): void {
    this.componentState = this.elementStateService.findById(this.moduleElement.id);

    if (this.componentState) {
      this.defaultPageSize = this.componentState.pageSize;
      this.gridFilters = this.componentState.filters;
      this.firstEntry = this.componentState.pageSize * this.componentState.currentPage;
      this.currentPage = this.componentState.currentPage;
      this.dataLoaded = this.componentState.dataLoaded;
      this.simpleSearchFilters = this.componentState.simpleSearchFilters;
      this.sortField = this.componentState.sortField;
      this.sortDirection = this.componentState.sortDirection;
    }
  }

  private removeState(): void {
    const elementContext = this.elementsStackService.findById(this.moduleElement.id);

    if (elementContext.performedAction !== PerformedAction.Close
      && elementContext.performedAction !== PerformedAction.Back) {
      // Now create a state of the component and add it:
      const componentState =
        new ElementState(this.moduleElement.id, ElementType.Tree,
          this.moduleElement, false, Math.floor(this.currentOffset / this.defaultPageSize),
          this.defaultPageSize, this.gridFilters, this.selectedEntity, this.dataLoaded, this.simpleSearchFilters,
          this.sortField, this.sortDirection);
      const newEntities = this.entityDirtyStore.getAll(StoreType.Create, this.getElementDatamodelEntityName());
      for (const createdEntity of newEntities) {
        const node = this.genericElementEntityService.getTreeNode(createdEntity);

        if (node) {
          componentState.addCreatedElement(node);
        }
      }

      this.elementsStateService.remove(componentState).add(componentState);
    }

    this.elementsStackService.remove(elementContext);
  }

  private addStateEntities(): void {
    const componentState = this.elementsStateService.findById(this.moduleElement.id);

    if (componentState) {
      // Now let's load the newly added entities:
      for (const node of componentState.getCreatedElements()) {
        const foundNode = this.genericElementEntityService.getTreeNode(node.data);

        if (!foundNode) {

          if (node.parent) {
            const parent = this.genericElementEntityService.getTreeNode(node.parent.data);

            this.genericElementEntityService.addNode(node, parent).refresh();
          } else {
            this.genericElementEntityService.addNode(node, null).refresh();
          }
        }
      }

      componentState.clearCreatedElements();
    }
  }
}
