import {ComponentFactoryResolver, ComponentRef, Injectable, ViewContainerRef} from '@angular/core';
import {QuestionnaireQuestionTypeCodeComponentRegistry} from '../../shared/services/element/questionnaire-question-type-code-component-registry';
import {Question, Questionnaire, QuestionType} from '../../shared/services/element/questionnaire';
import {AbstractQuestionComponent} from '../../shared/content-renderer/elements/custom/questionnaire/question/types/abstract-question-component';

@Injectable()
export class QuestionComponentFactoryService {

  public registry = new QuestionnaireQuestionTypeCodeComponentRegistry();

  public constructor(
    private componentFactoryResolver: ComponentFactoryResolver
  ) {

  }

  public createAndRenderComponent(question: Question, questionnaire: Questionnaire, renderTo: ViewContainerRef): AbstractQuestionComponent {
    const questionType: QuestionType = question.questionType;

    const component = this.registry.getComponentByCode(questionType.code);

    const componentInstance = this.renderComponent(component, renderTo);

    componentInstance.question = question;
    componentInstance.questionnaire = questionnaire;

    return componentInstance;
  }

  public renderComponent(component: any, renderTo: ViewContainerRef): AbstractQuestionComponent {
    const componentRef = this.renderTo(component, renderTo);

    return componentRef.instance;
  }

  public renderTo(component, renderTo: ViewContainerRef): ComponentRef<any> {
    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(component);
    return renderTo.createComponent(componentFactory);
  }
}
