
import {of as observableOf, Observable} from 'rxjs';
import {AbstractExecutionStep} from '../../executor/abstract-execution-step';
import {ExecutionStepStatus} from '../../executor/execution-step-status';
import {HeaderComponent} from '../header.component';
import {Router} from '@angular/router';

export class LoadProductsBasedOnOrganisationExecutionStep extends AbstractExecutionStep {

  public doExecute(): Observable<ExecutionStepStatus> {
    const headerComponent: HeaderComponent = this.getPayload().getValue().component;

    if (!headerComponent) {
      console.error('HeaderComponent not found!');
    }

    headerComponent.selectedProduct = null;
    headerComponent.onChangeSelectedProduct(null);
    this.getRouter().navigate(['']).then();

    return observableOf({status: true, content: null});
  }

  private getRouter(): Router {
    return this.injector.get(Router, null);
  }
}
