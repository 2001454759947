import { Component } from '@angular/core';
import { FieldActionAbstractComponent } from '../field-action-abstract.component';
import { GenericCrudService } from '../../../../../services/generic-crud.service';
import { DoubleClickService } from '../../../../services/double-click.service';

@Component({
  selector: 'field-action-inquiry-position-set-precalculation-values',
  template: ''
})
export class FieldActionInquiryPositionSetPrecalculationValuesComponent extends FieldActionAbstractComponent {

  public constructor(
    protected genericCrudService: GenericCrudService,
    protected doubleClickService: DoubleClickService
  ) {
    super();
  }

  public fire() {
    const entity = this.getGrid().getSelectedEntity();

    if (!entity.preCalculation.hasOwnProperty('_embedded')) {
      return;
    }

    if (entity.preCalculation._embedded.hasOwnProperty('workingMode')) {
      entity.workingMode = entity.preCalculation._embedded.workingMode;
    }

    if (entity.preCalculation._embedded.hasOwnProperty('salaryClassification')) {
      entity.assignmentCollectiveAgreementClassification = entity.preCalculation._embedded.salaryClassification;
    }

    if (entity.preCalculation._embedded.hasOwnProperty('secondarySalaryClassification')) {
      entity.employeeCollectiveAgreementClassification = entity.preCalculation._embedded.secondarySalaryClassification;
    }
  }
}
