<header>
  <div class="header-container header-style" fxLayout="row wrap" fxLayoutAlign="start stretch" style="background-size: contain;">
    <div class="header-left" fxFlex style="display: inline;" [style.justify-content]="getLogoPositionHorizontal()" [style.align-items]="getLogoPositionVertical()" [ngClass]="{'no-top-padding': (getLogoPositionVertical() === 'center')}">
        <img [routerLink]="['/']" [src]="getLogoUrl()" alt="LogoImage" class="header-logo" />
        <div>
          <p-dropdown id="HeaderBranch" *ngIf="branches && branches.length > 0" [style]="{'width': '200px'}" [options]="branches | toSelectItem:'name'" [(ngModel)]="selectedBranch" (onChange)="onChangeBranch($event, true)">
            <ng-template let-item pTemplate="item">
                <span
                  [id]="'HeaderBranch-Value-' + item.label"
                >
                  {{ item.label }}
                </span>
            </ng-template>
          </p-dropdown>
        </div>
    </div>
    <div class="header-center" fxFlex style="display: flex;">
      <p-dropdown id="HeaderOrganisation" *ngIf="organisations && organisations.length > 1" [style]="{'width': '300px'}" [options]="organisations | toSelectItem:'name'" [(ngModel)]="selectedOrganisation" (onChange)="onChangeOrganisation($event)">
        <ng-template let-item pTemplate="item">
          <span
            [id]="'HeaderOrganisation-Value-' + item.label"
          >
            {{ item.label }}
          </span>
        </ng-template>
      </p-dropdown>
      <div *ngIf="organisations && organisations.length === 1 && selectedOrganisation">
          Organisation: {{ selectedOrganisation.name }}
      </div>
    </div>
    <div class="header-right text-color-right" fxFlex="nogrow">
      <p>
        {{ getDateNow() | date:"dd.MM.yyyy" }} | {{ 'HEADER.LOGGED_IN_AS' | translate }} {{authenticationService.currentUser.username}} |
        <a class="logout-link" href (click)="clickLogout($event)">Abmelden</a>
      </p>
       <p-dropdown id="HeaderProduct" [style]="{'width': '100%'}" [options]="products" [(ngModel)]="selectedProduct" (ngModelChange)="onChangeSelectedProduct($event)" (onChange)="onChangeProduct($event)">
         <ng-template let-item pTemplate="item">
          <span
            [id]="'HeaderProduct-Value-' + item.label"
          >
            {{ item.label }}
          </span>
         </ng-template>
       </p-dropdown>
      <div class="text-right">
        <a *ngFor="let key of countryCodeKeys" [id]="'HeaderLanguage' + countryCodes[key]" class="flag-icon flag-icon-{{countryCodes[key]}} link grayscale" [ngClass]="{'active': countryCodes[key] === userCountryCode}" (click)="selectLanguage($event, countryCodes[key])"></a>
      </div>
    </div>
  </div>

  <div class="navbar-container navbar-style" fxLayout="row wrap" fxLayoutAlign="center center">
    <div fxFlex>
      <div class="toolbar-menu-loading" *ngIf="menuLoading">
        <div id="squaresWaveG">
          <div id="squaresWaveG_1" class="squaresWaveG"></div>
          <div id="squaresWaveG_2" class="squaresWaveG"></div>
          <div id="squaresWaveG_3" class="squaresWaveG"></div>
        </div>
      </div>
      <!-- @todo maybe read base url where it is needed and don't make extra binding -->
      <app-generic-menu menuPosition="left" [iconsPath]="environment.baseUrl" [items]="menuItemsLeft"></app-generic-menu>
    </div>
    <div fxFlex="nogrow">
      <app-generic-menu [iconsPath]="environment.baseUrl" [items]="menuItemsRight"></app-generic-menu>
    </div>
    <!--<p-toolbar class="ui-menubar">
      <div class="ui-toolbar-group-left">
        <div class="toolbar-menu-loading" *ngIf="menuLoading">
          <div id="squaresWaveG">
            <div id="squaresWaveG_1" class="squaresWaveG"></div>
            <div id="squaresWaveG_2" class="squaresWaveG"></div>
            <div id="squaresWaveG_3" class="squaresWaveG"></div>
          </div>
        </div>
        <app-generic-menu [menuPosition]="left" [iconsPath]="environment.baseUrl" [items]="menuItemsLeft"></app-generic-menu>
      </div>
      <div class="ui-toolbar-group-right">
        <app-generic-menu [iconsPath]="environment.baseUrl" [items]="menuItemsRight"></app-generic-menu>
      </div>
    </p-toolbar>-->
  </div>
</header>
