import {Component, ElementRef, OnInit} from '@angular/core';
import {DoubleClickService} from '../../../../services/double-click.service';
import {GenericCrudService} from '../../../../../services/generic-crud.service';
import {AbstractGridColumnComponent} from '../abstract-grid-column.component';
import {EntityStatus} from '../../../../../services/entity/entity-status';
import {GenericElementEmbeddedService} from '../../../../services/generic/generic-element-embedded.service';
import {TOAST_LIFE_INFINITE, ToastComponentsRegistry, ToastService} from '../../../../../../core/service/toast.service';
import {TranslateService} from '@ngx-translate/core';


@Component({
  selector: 'app-working-hour-column',
  templateUrl: './working-hour-action-column.component.html'
})
export class WorkingHourActionColumnComponent extends AbstractGridColumnComponent implements OnInit {

  constructor(
    protected elementRef: ElementRef,
    private doubleClickService: DoubleClickService,
    private genericCrudService: GenericCrudService,
    private elementEmbeddedService: GenericElementEmbeddedService,
    private toastService: ToastService,
    private translateService: TranslateService
  ) {
    super(elementRef);
  }

  ngOnInit() {

  }

  isCopy() {
    return !!this.entity.isCopy;
  }

  isLocked() {
    return !!this.entity.isLocked;
  }

  public isSummary(): boolean {
    return !!this.entity.isSummary;
  }

  copyWorkEntry(event) {
    this.toastService.custom(ToastComponentsRegistry.PROGRESS_BAR, {
      severity: 'info',
      life: TOAST_LIFE_INFINITE,
      closable: false,
      summary: this.translateService.instant('COMMON.SAVING_PLEASE_WAIT')
    });

    let entitiesCount = 0;
    this.component.entities.filter((entity) => {
      if (entity.workDate === this.entity.workDate) {
        return entitiesCount++;
      }
    });
    this.genericCrudService.customPost(`phoenix/workhours/${this.entity.id}/copy`, {}, {embedded: this.elementEmbeddedService.getEmbeddedAsString(this.component)})
      .subscribe(res => {
        const entityIndex = this.component.findEntityIndex(this.entity);
        this.component.entities.splice(entityIndex + entitiesCount, 0, res);
        this.component.entities = [...this.component.entities];
        this.component.selectEntity(res);
        this.toastService.clear(ToastComponentsRegistry.PROGRESS_BAR, 300);
      });
  }

  removeWorkEntry(event) {
    this.component.removeEntity(this.entity);
    this.component.selectLastOrFirstEntity();

    // Delete in database
    this.genericCrudService.deleteEntity(`phoenix/workhours/${this.entity.id}`).subscribe();
  }

  lockWorkEntry(event) {
    this.entity['isLocked'] = true;
    this.entity['cssColor'] = "color-orange";

    for (const fieldToLock of this.component.fields) {
      fieldToLock.isReadOnlyByEntity = fieldToLock.isReadOnlyByEntity || {};

      fieldToLock.isReadOnlyByEntity[this.entity.id] = true;
    }

    this.genericCrudService.customPost(`phoenix/workhours/${this.entity.id}/lock`, {}, {embedded: 'none'}).subscribe(res => {
      this.entity['cssColor'] = 'color-' + res['planningWorkLengthColor'];
    });
  }

}
