/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./toolbar-item-draft-delete.component";
var styles_ToolbarItemDraftDelete = [];
var RenderType_ToolbarItemDraftDelete = i0.ɵcrt({ encapsulation: 2, styles: styles_ToolbarItemDraftDelete, data: {} });
export { RenderType_ToolbarItemDraftDelete as RenderType_ToolbarItemDraftDelete };
export function View_ToolbarItemDraftDelete_0(_l) { return i0.ɵvid(2, [], null, null); }
export function View_ToolbarItemDraftDelete_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "toolbar-item-draft-delete", [], null, null, null, View_ToolbarItemDraftDelete_0, RenderType_ToolbarItemDraftDelete)), i0.ɵdid(1, 245760, null, 0, i1.ToolbarItemDraftDelete, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ToolbarItemDraftDeleteNgFactory = i0.ɵccf("toolbar-item-draft-delete", i1.ToolbarItemDraftDelete, View_ToolbarItemDraftDelete_Host_0, { params: "params" }, {}, []);
export { ToolbarItemDraftDeleteNgFactory as ToolbarItemDraftDeleteNgFactory };
