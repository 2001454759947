import * as i0 from "@angular/core";
import * as i1 from "../../shared/services/local-storage-data.service";
import * as i2 from "../../shared/services/session-storage-data.service";
export class UserSessionService {
    constructor(localStorage, sessionStorage) {
        this.localStorage = localStorage;
        this.sessionStorage = sessionStorage;
    }
    bootstrap() {
    }
    set(key, options) {
        this.localStorage.setItem(key, options.value);
        this.sessionStorage.setItem(key, options.value);
        return this;
    }
    get(key) {
        if (this.sessionStorage.itemExists(key)) {
            return this.sessionStorage.getItem(key);
        }
        if (this.localStorage.itemExists(key)) {
            return this.localStorage.getItem(key);
        }
        return null;
    }
    remove(key) {
        this.localStorage.removeItem(key);
        this.sessionStorage.removeItem(key);
        return this;
    }
}
UserSessionService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function UserSessionService_Factory() { return new UserSessionService(i0.ɵɵinject(i1.LocalStorageDataService), i0.ɵɵinject(i2.SessionStorageDataService)); }, token: UserSessionService, providedIn: "root" });
