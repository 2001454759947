import { ElementButton } from './element';
import { Icon } from './../../../shared/models/Icon';

export class ElementButtonModule extends ElementButton {

  icon: Icon;

  staticFilterValue = '';
  openingModuleId: number = null;
  dialogWidth: number = null;
  dialogHeight: number = null;
  masterFilterField: string = '';
  staticFilterField: string = '';
  disabled: boolean = false;

  constructor(
    public typeElement = 'module',
    public datamodelField: string = undefined,
    public width = 100
  ) {
    super();

    this.icon = undefined;
  }
}
