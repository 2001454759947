import {Directive, Self, OnInit, Output, EventEmitter, HostListener} from '@angular/core';
import {Spinner} from 'primeng/primeng';

@Directive({
  selector: '[appSpinner]'
})
export class SpinnerDirective implements OnInit {

  @Output() valueChange = new EventEmitter<any>();

  private readonly spinner: Spinner = null;

  @HostListener('keyup', ['$event']) onKeyUp(event) {
    this.valueChange.emit({
      event: event,
      value: +event.target.value
    });
  }

  @HostListener('keydown', ['$event']) onKeyDown(event) {
    if (event.code === 'Enter') {
      this.valueChange.emit({
        event: event,
        value: +event.target.value
      });
    }
  }

  public constructor(@Self() spinner: Spinner) {
    this.spinner = spinner;

    if (!(this.spinner instanceof Spinner)) {
      console.error('Table works only with p-spinner component!');
    }
  }

  public ngOnInit(): void {
    this.spinner.onChange.subscribe((event: any) => {
      this.valueChange.emit({
        event: event,
        value: this.spinner.value
      });
    });
  }
}
