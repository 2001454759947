import {ChangeDetectionStrategy, Component} from '@angular/core';
import {ToolbarItemAbstract} from '../../toolbar-item-abstract.component';
import {MessageGrowlService} from '../../../../../../../../core/message/message-growl.service';
import {DoubleClickService} from '../../../../../../services/double-click.service';
import {ToolbarItem} from '../../../../../../../services/element/toolbar-item';
import {FormViewerComponent} from '../../../../../../../form-viewer/form-viewer.component';
import {Guid} from 'guid-typescript';
import {EntityHydrator} from '../../../../../../../services/entity-hydrator.service';
import {Branch} from '../../../../../../../services/branch/branch';
import {UserSessionService} from '../../../../../../../../core/service/user-session.service';
import {AuthenticationService} from '../../../../../../../../core/authentication/authentication.service';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-toolbar-item-open-module-job-advertisement',
  template: ''
})
export class ToolbarItemOpenModuleJobAdvertisementComponent extends ToolbarItemAbstract {
  protected toolbarItem: ToolbarItem;
  protected entity: any;

  constructor(
    protected messageGrowlService: MessageGrowlService,
    protected doubleClickService: DoubleClickService,
    protected entityHydrator: EntityHydrator,
    protected userSession: UserSessionService,
    protected authenticationService: AuthenticationService
  ) {
    super();
  }

  ngOnInit() {

  }

  public click() {
    const component = this.getComponent();

    if(this.getToolbarItem().actionModuleId && component instanceof FormViewerComponent) {
      this.openModuleInFormContext(component);
    }
  }

  protected openModuleInFormContext(component: FormViewerComponent) {

    const entity = component.entity;

    this.doubleClickService.setForm(component)
      .setDialogOptions({
        height: +this.getToolbarItem().targetDialogHeight,
        width: +this.getToolbarItem().targetDialogWidth
      })
      .handleOpenModule(component, this.getToolbarItem().target, this.getToolbarItem().actionModuleId, this.getNewEntity(entity));
  }

  protected getNewEntity(entity) {
    const jobAdvertisement =  {
      fqn: 'PhoenixBundle\\Entity\\JobAdvertisement',
      uniqueId: Guid.create().toString(),
      inquiryPosition: entity,
      customer: entity.inquiry.customer,
      _embedded: {
        inquiryPosition: entity,
        customer: entity.inquiry.customer
      }
    };

    if (entity['assignmentAddress']) {
      jobAdvertisement['assignmentAddress'] = entity['assignmentAddress'];
    }

    if (entity['jobPrintText']) {
      jobAdvertisement['jobTitle'] = entity['jobPrintText'];
    }

    if (entity['description']) {
      jobAdvertisement['jobSubtitle'] = entity['description'];
    }

    if (entity['validFrom']) {
      jobAdvertisement['validFrom'] = entity['validFrom'];
    }

    if (entity['validTo']) {
      jobAdvertisement['validTo'] = entity['validTo'];
    }

    if (entity['_embedded']['inquiry']['customer']) {
      jobAdvertisement['customer'] = entity['_embedded']['inquiry']['customer'];
    }

    if (entity['_embedded']['job']) {
      jobAdvertisement['inquiryPosition']['job'] = entity['_embedded']['job'];
    }

    if (entity['_embedded']['preCalculation']) {
      jobAdvertisement['inquiryPosition']['preCalculation'] = entity['_embedded']['preCalculation'];
    }

    if (entity['_embedded']['inquiry']) {
      jobAdvertisement['inquiryPosition']['inquiry'] = entity['_embedded']['inquiry'];
    }

    if (this.userSession.get(Branch.LOCAL_STORAGE_NAME)) {
      jobAdvertisement['branchOffice'] = this.userSession.get(Branch.LOCAL_STORAGE_NAME);
    }

    if (this.authenticationService.currentUser) {
      jobAdvertisement['signature'] = this.authenticationService.currentUser.signature
    }

    return jobAdvertisement;
  }
}
