import {Component, OnInit} from '@angular/core';
import {SelectItem} from 'primeng/api';
import {AbstractAdminQuestionComponent} from './abstract-admin-question-component';
import {Datamodel} from '../../../../../shared/services/datamodel/datamodel';
import {Observable} from 'rxjs/Observable';
import {GenericCrudService} from '../../../../../shared/services/generic-crud.service';
import {ExecutorRegistry} from '../../../../../core/executor/service/executor.registry';

@Component({
  selector: 'app-questionnaire-admin-question-dropdown',
  template: `
    <div class="ui-g">
        <p-tabView>
            <p-tabPanel header="Labels" leftIcon="pi pi-calendar">
                <div class="ui-g-4">
                  Label:
                </div>
                <div class="ui-g-8">
                  <input type="text" pInputText (keyup)="onChangeLabel($event)" [ngModel]="getParamValue('label')"/>
                </div>
            </p-tabPanel>
            <p-tabPanel header="Datamodel" leftIcon="pi pi-calendar">
                <div class="ui-g-4">
                  Datamodel ID:
                </div>
                <div class="ui-g-8">
                  <p-dropdown
                    appendTo="body" 
                    (onChange)="onChangeDatamodelId($event)"
                    [style]="{'width':'100%'}" 
                    [options]="datamodelOptions" 
                    placeholder="---" 
                    [filter]="true"
                    [ngModel]="getParamValue('datamodelId')"
                  ></p-dropdown>
                </div>
                <div *ngIf="getParamValue('datamodelId')" class="ui-g-4">
                  DatamodelField
                </div>
                <div *ngIf="getParamValue('datamodelId')" class="ui-g-8">
                <app-dialog-choose-datamodel
                  [datamodel]="getParamValue('datamodelField')"
                  [datamodelId]="getParamValue('datamodelId')"
                  (datamodelChange)="onDatamodelFieldChange($event)"></app-dialog-choose-datamodel>
                </div>
            </p-tabPanel>
            <p-tabPanel header="Actions" leftIcon="pi pi-inbox">
                <div class="ui-g-4">
                  On Change:
                </div>
                <div class="ui-g-8">
                  <p-dropdown
                    appendTo="body" 
                    (onChange)="onChangeAction($event, 'onChangeAction')"
                    [style]="{'width':'100%'}" 
                    [options]="stepsOptions" 
                    placeholder="---" 
                    [filter]="true"
                    [ngModel]="getParamValue('onChangeAction')"
                  ></p-dropdown>
                </div>
            </p-tabPanel>
        </p-tabView>
    </div>
  `,
  styles: [`
    :host {
      width: 100%;
    }
  `]
})
export class AdminDropdownQuestionComponent extends AbstractAdminQuestionComponent implements OnInit {

  public datamodelOptions: SelectItem[] = [];
  public stepsOptions: SelectItem[] = [];

  public constructor(
    protected genericCrudService: GenericCrudService
  ) {
    super();
  }

  public onComponentInit(): void {
    this.loadDatamodels()
      .subscribe((datamodels: Datamodel[] = []) => {
        this.datamodelOptions = [{
          value: null,
          label: '---'
        }];

        for (const datamodel of datamodels) {
          this.datamodelOptions = [...this.datamodelOptions, {
            value: datamodel.id,
            label: datamodel.name
          }];
        }
      });

    this.loadSteps()
      .subscribe((steps: any[] = []) => {
        this.stepsOptions = [{
          value: null,
          label: '---'
        }];

        for (const step of steps) {
          this.stepsOptions = [...this.stepsOptions, {
            value: step.name,
            label: step.name
          }];
        }
      });
  }

  public onChangeDatamodelId(event): void {
    const param = this.getOrCreateParam('datamodelId');

    param.value = event.value;
  }

  public onChangeLabel(event): void {
    const param = this.getOrCreateParam('label');

    param.value = event.target.value;
  }

  public onDatamodelFieldChange(value): void {
    const param = this.getOrCreateParam('datamodelField');

    param.value = value;
  }

  public onChangeAction(event, paramName): void {
    const param = this.getOrCreateParam(paramName);

    param.value = event.value;
  }

  private loadDatamodels(): Observable<Datamodel[]> {
    return this.genericCrudService.getEntities('superadmin/datamodels');
  }

  private loadSteps(): Observable<any[]> {
    const registry = new ExecutorRegistry();

    let actionTypes = [];

    for (const executorStep in registry.steps) {
        actionTypes = [...actionTypes, {
          name: executorStep
        }];
    }

    return Observable.of(actionTypes);
  }
}
