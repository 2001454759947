<div class="container" *ngIf="datamodel" fxLayout="column" fxLayoutAlign="start stretch">

  <div fxFlex="30px">
    <button pButton icon="fa fa-plus" (click)="onAddConfig()"></button>
  </div>

  <ng-container *ngFor="let config of configuration">

    <div fxFlex="30px">
      <div fxFlex="25">
        <input (keyup)="onChange($event)" [style.height.px]="24" type="text" pInputText [(ngModel)]="config.preChacarter" size="30">
      </div>

      <div fxFlex="40">
        <app-dialog-choose-datamodel
          [datamodel]="config.fieldName"
          [datamodelId]="datamodel.id"
          (datamodelChange)="onFieldChange($event, config)"
        ></app-dialog-choose-datamodel>
      </div>

      <div fxFlex="25">
        <input (keyup)="onChange($event)" [style.height.px]="24" type="text" pInputText [(ngModel)]="config.postCharacter" size="30">
      </div>

      <div fxFlex="10">
        <button type="button" pButton icon="fa fa-trash" (click)="onRemoveConfig(config)"></button>
      </div>
    </div>

  </ng-container>
</div>

<div *ngIf="!datamodel">No datamodel assigned!</div>
