<div #gridContainer class="grid-container">
  <shared-dynamic-table
    #table
    [columns]="columns"
    [header]="header"
    [entities]="entities"
    [rows]="rows"
    [showRows]="false"
    [isLoadingData]="isDataLoading"
    [rowsCount]="entities.length"
    [totalCount]="entities.length"
    [selectFirstEntityWhenNothingIsSelected]="false"
    (onCellEdit)="onCellEdit($event)"
    (onCellBlur)="onCellBlur($event)"
    (onCellFocus)="onCellFocus($event)"
  ></shared-dynamic-table>
</div>

<p-dialog
  [(visible)]="isDialogVisible"
  *ngIf="isDialogVisible"
  [width]="350"
  [maximizable]="true"
  [baseZIndex]="10000"
>
  <div class="ui-g">
    <div class="ui-g-4">{{ 'WORK_HOUR.ARTICLE' | translate }}</div>
    <div class="ui-g-6">
      <p-autoComplete
        appAutoCompleteHandler
        size="30"
        appendTo="body"
        [style]="{'width': '200px'}"
        [field]="'label'"
        [handler]="articleHandler"
        [dropdown]="true"
        [(ngModel)]="selectedArticle"
        [forceSelection]="true"
      ></p-autoComplete>
    </div>

    <div class="ui-g-4">{{ 'WORK_HOUR.INVOICE_ARTICLE' | translate }}</div>
    <div class="ui-g-6">
      <p-autoComplete
        appAutoCompleteHandler
        size="30"
        appendTo="body"
        [style]="{'width': '200px'}"
        [field]="'label'"
        [handler]="invoiceArticleHandler"
        [dropdown]="true"
        [(ngModel)]="selectedInvoiceArticle"
        [forceSelection]="true"
      ></p-autoComplete>
    </div>

    <div class="ui-g-4">{{ 'WORK_HOUR.SALARY_TYPE' | translate }}</div>
    <div class="ui-g-6">
      <p-autoComplete
        appAutoCompleteHandler
        size="30"
        appendTo="body"
        [style]="{'width': '200px'}"
        [field]="'label'"
        [handler]="salaryTypeHandler"
        [dropdown]="true"
        [(ngModel)]="selectedSalaryType"
        [forceSelection]="true"
      ></p-autoComplete>
    </div>
  </div>

  <p-footer>
    <button type="button" pButton [disabled]="!selectedArticle || !selectedInvoiceArticle || !selectedSalaryType" (click)="onAddType()" label="{{ 'COMMON.SAVE' | translate }}"></button>
    <button type="button" pButton (click)="onCancelAddType()" label="{{ 'COMMON.CANCEL' | translate }}"
            class="ui-button-secondary"></button>
  </p-footer>
</p-dialog>
