export enum ExecutionStepParameterExecutor {
  ToolbarItem = 'toolbarItem'
}

export class ExecutionStepParameter {

  protected readonly name: string;
  protected readonly value: any;

  public constructor(name: string, value: any) {
    this.name = name;
    this.value = value;
  }

  public getName(): string {
    return this.name;
  }

  public getValue(): any {
    return this.value;
  }
}
