import {
  Component, Input, ViewChild
} from '@angular/core';
import {AbstractGenericGridComponent} from '../../../content-renderer/elements/abstract-generic-grid.component';
import {ExpertSearch} from '../../../services/expert-search/expert-search';
import {ExpertSearchService} from '../service/expert-search.service';
import {MessageGrowlService} from '../../../../core/message/message-growl.service';
import {ExecutionStatus} from '../../../../core/executor/execution-status';
import {SearchManagementDropdownListComponent} from '../dropdown-list/search-management-dropdown-list.component';

const STATE_ADD = 'add';
const STATE_EDIT = 'edit';

@Component({
  selector: 'app-generic-grid-expert-search',
  templateUrl: './generic-grid-expert-search.component.html',
  styleUrls: ['./generic-grid-expert-search.component.scss'],
  providers: [ ExpertSearchService ]
})
export class GenericGridExpertSearchComponent {

  @Input() public expertSearch: ExpertSearch = null;
  @Input() public component: AbstractGenericGridComponent = null;
  @ViewChild('dropdown', {static: false}) dropdown: SearchManagementDropdownListComponent = null;

  private state = 'edit';

  public constructor(
    private expertSearchService: ExpertSearchService,
    private messageGrowlService: MessageGrowlService
  ) {

  }

  public onAddExpertSearch(): void {
    this.state = STATE_ADD;

    this.expertSearch = {
      id: null,
      name: '',
      datamodel: this.component.element.datamodel,
      containers: []
    };
  }

  public onCancelAdd(): void {
    this.state = STATE_EDIT;
    this.expertSearch = null;
  }

  public onExpertSearchOptionChanged(expertSearch: ExpertSearch): void {
    this.expertSearch = expertSearch;
  }

  public onFilter(event: any): void {
    const expertSearchFilterIndex = this.component.staticFilters.findIndex((aFilter) => {
        return aFilter.field === 'expertSearch';
      }),
      expertSearchFilter = {
        field: 'expertSearch',
        value: JSON.stringify(ExpertSearchService.parseForSearch(this.expertSearch))
      };

    const expertSearchEmbedded = ExpertSearchService.getEmbedded(this.expertSearch);

    for (const embedded of expertSearchEmbedded) {
      if (!this.component.embeddedFields.includes(embedded)) {
        this.component.embeddedFields.push(embedded);
      }
    }

    if (expertSearchFilterIndex !== -1) {
      this.component.staticFilters[expertSearchFilterIndex] = expertSearchFilter;
    } else {
      this.component.staticFilters.push(expertSearchFilter);
    }

    this.component.loadEntities().subscribe();
  }

  public onSaveFilter(event: any): void {
    this.expertSearchService
      .save(this.expertSearch)
      .subscribe((status: ExecutionStatus) => {
        if (status.isSuccess()) {
          this.expertSearch = status.getStepContent();
          this.messageGrowlService.showDataSaved();

          this.afterSaveFilter();
        }
      });
  }

  public inState(stateName: string): boolean {
    return this.state === stateName;
  }

  private afterSaveFilter(): void {
    this.state = STATE_EDIT;

    const option = {
      value: this.expertSearch,
      label: this.expertSearch.name
    };

    this.dropdown.addOption(option);
    this.dropdown.selectOption(option);
  }
}
