
import {forkJoin as observableForkJoin, of as observableOf, from as observableFrom,  Observer ,  Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { Form } from './../shared/form-viewer/models/form';
import { ElementFormCrudService } from './../shared/services/element-form/element-form.crud.service';
import { FormEditorService } from './shared/services/form-editor.service';
import { LocalDataService } from './../shared/local-data/local-data.service';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class FormEditorDetailResolve implements Resolve<[Form, any[]]> {

  constructor(
    private elementFormCrudService: ElementFormCrudService,
    private formEditorService: FormEditorService,
    private localDataService: LocalDataService,
    private translate: TranslateService
  ) { }

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<[Form, any[]]> {
    let id = route.params['formId'];
    let first: Observable<Form>;
    let second: Observable<any[]>;

    if (id) {
      if (isNaN(+id) && id.substring(0, 3) === 'LD:') {
        id = id.substring(3);

        this.localDataService.createNewInstance('form_editor_designs');

        first = this.localDataService.getItem(id, 'form_editor_designs');
        second = observableFrom([]);
      } else {
        first = this.elementFormCrudService.getElementForm(id);
        second = this.elementFormCrudService.getElementFormFields(id);
      }
    } else {
      const form: Form = new Form();

      form.title = this.translate.instant('FORMEDITOR.NEW_FORMULAR');

      first = observableOf(form);
      second = observableFrom([]);
    }

    return observableForkJoin(first, second);
  }
}
