import {Observable} from 'rxjs';
import {AbstractExecutionStep} from '../../../../core/executor/abstract-execution-step';
import {ExecutionStepStatus} from '../../../../core/executor/execution-step-status';
import {GenericElementAbstract} from '../../../content-renderer/elements/generic-element-abstract.component';
import {FormViewerComponent} from '../../../form-viewer/form-viewer.component';
import {MessageGrowlService} from '../../../../core/message/message-growl.service';
import {TOAST_LIFE_INFINITE, ToastComponentsRegistry, ToastService} from '../../../../core/service/toast.service';
import {TranslateService} from '@ngx-translate/core';

export class RegisterSendMemoMessageExecutionStep extends AbstractExecutionStep {

  doExecute(): Observable<ExecutionStepStatus> {
    const component = this.getPayload().getValue().component;

    if (!(component instanceof FormViewerComponent)) {
      return this.getFailObservable('You need to pass GenericElementAbstract as Payload value!');
    }

    return this.doRegister(component);
  }

  private doRegister(component: FormViewerComponent): Observable<ExecutionStepStatus> {
    return Observable.create((observer) => {

      const entity = component.getSelectedEntity();

      if (entity) {
        this.getToast().custom(ToastComponentsRegistry.PROGRESS_BAR, {
          severity: 'info',
          life: TOAST_LIFE_INFINITE,
          closable: false,
          summary: this.getTranslate().instant('COMMON.SENDING_PLEASE_WAIT')
        });

        this.genericCrudService.get(component.getElementDataModelApiRoute() + '/send-message/' + entity.id).subscribe(response => {
          this.getToast().clear(ToastComponentsRegistry.PROGRESS_BAR, 300);
          this.getGrowl().showDataSaved();

          observer.next({
            status: entity !== null,
            content: null
          });
          observer.complete();
        });
      }
    });
  }

  private getGrowl(): MessageGrowlService|null {
    return this.injector.get(MessageGrowlService, null);
  }

  private getToast(): ToastService|null {
    return this.injector.get(ToastService, null);
  }

  private getTranslate(): TranslateService|null {
    return this.injector.get(TranslateService, null);
  }
}
