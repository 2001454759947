import {
  Component, Output, EventEmitter, Input, OnInit
} from '@angular/core';
import {SelectItem} from 'primeng/primeng';
import {GenericCrudService} from '../../../services/generic-crud.service';
import {Datamodel} from '../../../services/datamodel/datamodel';
import {ExpertSearch} from '../../../services/expert-search/expert-search';
import {Observable} from 'rxjs';
import {ExpertSearchService} from '../service/expert-search.service';
import {RequestCachingService} from '../../../services/request-caching.service';

@Component({
  selector: 'app-search-management-dropdown-list',
  templateUrl: './search-management-dropdown-list.component.html',
  providers: [ ExpertSearchService ]
})
export class SearchManagementDropdownListComponent implements OnInit {

  @Input() public datamodel: Datamodel = null;
  @Output() public onExpertSearchOptionChanged: EventEmitter<SelectItem> = new EventEmitter();

  public selectedExpertSearchOption: SelectItem = null;
  public expertSearchOptions: SelectItem[] = [];

  public constructor(
    protected genericCrudService: GenericCrudService,
    protected expertSearchService: ExpertSearchService,
    protected requestCachingService: RequestCachingService
  ) {

  }

  public ngOnInit(): void {
    this.loadExpertSearches()
      .subscribe((expertSearches: ExpertSearch[] = []) => {
        this.expertSearchOptions = [];

        for (const expertSearch of expertSearches) {
          this.expertSearchOptions = [...this.expertSearchOptions, {
            label: expertSearch.name,
            value: expertSearch
          }];
        }
      });
  }

  public onExpertSearchChange(event: {value: any}): void {
    this.onExpertSearchOptionChanged.emit(this.selectedExpertSearchOption);
  }

  public addOption(item: SelectItem): void {
    const index = this.expertSearchOptions.findIndex((aItem: SelectItem) => {
      return aItem.value.id === item.value.id;
    });

    if (index === -1) {
      this.expertSearchOptions = [...this.expertSearchOptions, item];
    }
  }

  public selectOption(item: SelectItem): void {
    for (const aItem of this.expertSearchOptions) {
      if (aItem.value.id === item.value.id) {
        this.selectedExpertSearchOption = aItem.value;
        break;
      }
    }
  }

  private loadExpertSearches(): Observable<ExpertSearch[]> {
    this.requestCachingService.removeByExpression('expertsearches');

    return this.expertSearchService.loadByDatamodel(this.datamodel);
  }
}
