
import {throwError as observableThrowError, Observable} from 'rxjs';
import {Injectable} from '@angular/core';

import {environment} from '../environments';
import {EntityStatus} from './shared/services/entity/entity-status';
// Avoid name not found warnings
// declare var Auth0: any; extends AppService

@Injectable()
export class AppHttpService {
  public static readonly REQUEST_TIMEOUT = 15000;
  public static readonly REQUEST_TIMED_OUT_EXCEPTION = 'request_timed_out';

  protected propertyCache = [];

  constructor() { }

  /**
   * Get the API url from config
   */
  public getApiUrl(): string {
    return environment.apiUrl;
  }

  public getBaseUrl(): string {
    return environment.baseUrl;
  }

  /**
   * Get the download url from our environment config
   */
  public getDownloadUrl(): string {
    return environment.downloadUrl;
  }

  extractTokenData(res: any) {
    return res.json() && res.json().token || {};
  }

  // extractTokenData(res: Response) {
  //   return res.json() && res.json().token || {};
  // }

  mapResponseAsJson(res: any) {
    // let data = res.json() && res.json().data;
    return res || {};
  }

  extractEmbeddedEntities(data: any) {
    if (data instanceof Array) {
      data.forEach(this.extractEmbeddedEntities, this);
    }

    if (data && Array.isArray(data.children)) {
      data.children.forEach(this.extractEmbeddedEntities, this);
    }

    if (data && data._embedded instanceof Object) {
      for (const property in data._embedded) {
        if (data._embedded.hasOwnProperty(property) && typeof data._embedded[property] !== 'function') {
          const embeddedElement = data._embedded[property];
          data[property] = this.extractEmbeddedEntities(embeddedElement);
        }
      }
    }
    return data;
  }

  extractPaginatedEmbeddedEntities(data: any) {
    return {
      total: data.total,
      data: this.extractEmbeddedEntities(data.data)
    };
  }

  replaceEmebeddedEntities(key, value) {
    if (key !== '' && value != null && typeof value === 'object'
      && value.hasOwnProperty('id') && (!value.hasOwnProperty('isChanged') || !value['isChanged'])) {
      return value.id;
    }

    return value;
  }

  replaceCircularEntities(key, value) {
    if (typeof value === 'object' && value !== null) {
      if (this.isInProperyCache(value)) {
        if (value.hasOwnProperty('id')) {
          return value.id;
        }
        // Circular reference found, discard key
        return;
      }
      // Store value in our collection
      this.propertyCache.push(value);

      if (value.hasOwnProperty('id') && value['id'] && !this.isFromModule(value, 'superadmin')
        && (!value.hasOwnProperty(EntityStatus.ENTITY_CHANGED_FLAG) || !value[EntityStatus.ENTITY_CHANGED_FLAG])
        && (!value.hasOwnProperty(EntityStatus.EMBEDDED_ENTITY_CHANGED_FLAG) || !value[EntityStatus.EMBEDDED_ENTITY_CHANGED_FLAG])) {
        return {
          'id': value.id,
          'fqn': value.fqn
        };
      }
    }
    return value;
  }

  isInProperyCache(value: any): boolean {
    if (typeof value === 'object' && value !== null && value[EntityStatus.ENTITY_DRAFT_FLAG]) {
      for (const cachedProp of this.propertyCache) {
        if (cachedProp
          && typeof cachedProp === 'object'
          && cachedProp[EntityStatus.ENTITY_DRAFT_FLAG] === value[EntityStatus.ENTITY_DRAFT_FLAG]) {
          return true;
        }
      }
    }

    return this.propertyCache.indexOf(value) !== -1;
  }

  /**
   * Handle HTTP errors at services
   */
  handleError(error: any | any) {
    // @todo: we might use a remote logging infrastructure
    let errMsg: string;

    return observableThrowError(error);
  }

  /**
   * Handle HTTP promise errors
   */
  handlePromiseError(error: any): Promise<any> {
    console.error('An error occurred', error);
    return Promise.reject(error.message || error);
  }

  /**
   *
   * @param entity
   * @param moduleName
   */
  private isFromModule(entity: any, moduleName: string): boolean {
    return entity.fqn && entity.fqn.toLowerCase().indexOf(moduleName.toLowerCase()) !== -1;
  }
}
