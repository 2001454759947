
import {throwError as observableThrowError, Observable} from 'rxjs';

import {catchError, distinctUntilChanged, debounceTime} from 'rxjs/operators';
import {Component, ElementRef, OnInit} from '@angular/core';
import {AbstractGridColumnComponent} from '../abstract-grid-column.component';
import {GenericCrudService} from '../../../../../services/generic-crud.service';

@Component({
  selector: 'app-precalculation-article-global-fields-column',
  template: `
    <span *ngIf="isLoading">Calculating...</span>
    <span *ngIf="!isLoading">{{salary}}</span>
  `
})
export class PrecalculationArticleGlobalFieldsColumnComponent extends AbstractGridColumnComponent implements OnInit {

  public salary: number = 0;
  public isLoading: boolean = false;

  constructor(
    protected elementRef: ElementRef,
    private genericCrudService: GenericCrudService
  ) {
    super(elementRef);
  }

  ngOnInit() {
    if (this.entity['initialSalaryHour']) {
      this.salary = this.entity['initialSalaryHour'];

      return;
    }

    if (this.entity['calculatedSalary']) {
      this.salary = this.entity['calculatedSalary'];

      return;
    }

    const preCalculationId = this.getPreCalculationId(this.entity);

    if (preCalculationId !== null) {
      this.isLoading = true;

      this.genericCrudService
        .getEntity('phoenix/calculation/wage/precalculation', preCalculationId).pipe(
        debounceTime(500),
        distinctUntilChanged(),
        catchError(() => {
          this.isLoading = false;

          return observableThrowError(null);
        }),)
        .subscribe((calculated) => {
          this.salary = calculated.salary || 0;

          this.entity['calculatedSalary'] = this.salary;

          this.isLoading = false;
        });
    }
  }

  private getPreCalculationId(entity: any): number|null {
    let preCalculationId = null;

    if (entity && entity.preCalculation) {
      preCalculationId = entity.preCalculation;
    }

    if (preCalculationId instanceof Object && typeof preCalculationId.id !== 'undefined') {
      preCalculationId = preCalculationId.id;
    }

    return preCalculationId;
  }
}
