<p-dialog [(visible)]="visible" [modal]="true" [minWidth]="200" [responsive]="true" appendTo="body" [header]="'DIALOG.HEADER.ADD_ADDRESS' | translate" showEffect="fade" width="800" (onAfterShow)="onDialogShow($event)" (onAfterHide)="onDialogHide($event)">
  <form [formGroup]="form" (ngSubmit)="onSubmit(form.value)">
    <div class="fx-row" fxLayout="row" fxLayoutAlign="space-around center">
      <div fxFlex="20">
        {{ 'COMMON.COUNTRY' | translate }} *:
      </div>
      <div fxFlex="50">
        <p-autoComplete [dropdown]="true" [suggestions]="countries" field="name" formControlName="country" (completeMethod)="onSearch($event, 'countries')" (onDropdownClick)="onSearch($event, 'countries')" styleClass="flex-layout"></p-autoComplete>
      </div>
      <div fxFlex>
        <div class="ui-message ui-messages-error ui-corner-all" *ngIf="form.controls['country']['dirty'] && !form.controls['country']['value']">
          <i class="fa fa-close"></i> Country is required
        </div>
      </div>
    </div>
    <div class="fx-row" fxLayout="row" fxLayoutAlign="space-around center">
      <div fxFlex="20">
        {{ 'COMMON.PROVINCE' | translate }} *:
      </div>
      <div fxFlex="50">
        <p-autoComplete [disabled]="(!form.controls['country'].value || !form.controls['country'].value.name)" [dropdown]="true" [suggestions]="provinces" field="name" formControlName="province" (completeMethod)="onSearch($event, 'provinces')" (onDropdownClick)="onSearch($event, 'provinces')" styleClass="flex-layout"></p-autoComplete>
      </div>
      <div fxFlex>
        <div class="ui-message ui-messages-error ui-corner-all" *ngIf="form.controls['province']['dirty'] && !form.controls['province']['value']">
          <i class="fa fa-close"></i> Province is required
        </div>
      </div>
    </div>
    <div class="fx-row" fxLayout="row" fxLayoutAlign="space-around center">
      <div fxFlex="20">
        {{ 'COMMON.CITY' | translate }} *:
      </div>
      <div fxFlex="50">
        <p-autoComplete [disabled]="(!form.controls['province'].value || !form.controls['province'].value.name)" [dropdown]="true" [suggestions]="cities" field="communityAndCity" formControlName="city" (completeMethod)="onSearch($event, 'cities')" (onDropdownClick)="onSearch($event, 'cities')" styleClass="flex-layout"></p-autoComplete>
      </div>
      <div fxFlex>
          <div class="ui-message ui-messages-error ui-corner-all" *ngIf="form.controls['city']['dirty'] && !form.controls['city']['value']">
            <i class="fa fa-close"></i> City is required
          </div>
      </div>
    </div>
    <div class="fx-row" fxLayout="row" fxLayoutAlign="space-around center">
      <div fxFlex="20">
        {{ 'COMMON.STREET' | translate }} *:
      </div>
      <div fxFlex="50">
        <!-- check how i can disable it with reactforms instead of ugly if -->
        <p-autoComplete [disabled]="(!form.controls['city'].value || !form.controls['city'].value.city)" [dropdown]="true" [suggestions]="streets" field="street" formControlName="street" (completeMethod)="onSearch($event, 'streets')" (onDropdownClick)="onSearch($event, 'streets')" styleClass="flex-layout"></p-autoComplete>
      </div>
      <div fxFlex>
          <div class="ui-message ui-messages-error ui-corner-all" *ngIf="form.controls['street']['dirty'] && !form.controls['street']['value']">
            <i class="fa fa-close"></i> Street is required
          </div>
      </div>
    </div>
    <div class="fx-row" fxLayout="row" fxLayoutAlign="space-around center">
      <div fxFlex="20"></div>
      <div fxFlex="50">
        <button pButton type="submit" icon="fa fa-check" [label]="'COMMON.IMPORT' | translate" [disabled]="!form.valid"></button>
      </div>
      <div fxFlex></div>
    </div>
  </form>
</p-dialog>
