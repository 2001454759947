import {ChangeDetectionStrategy, Component, Input, OnInit} from '@angular/core';
import { GenericCrudRequestOptions } from '../../../../../../services/generic-crud-request-options.service';
import { ToolbarItemFilter } from '../toolbar-item-filter.component';
import { GenericGridGlobalFilterService } from '../../../../generic-grid/services/generic-grid-global-filter.service';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'toolbar-item-show-deleted',
  templateUrl: './toolbar-item-show-deleted.component.html',
  providers: [ GenericGridGlobalFilterService ]
})
export class ToolbarItemShowDeleted extends ToolbarItemFilter {

  protected getFilterKey(): string {
    return GenericCrudRequestOptions.SHOW_DELETED;
  }

}
