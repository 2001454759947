
import {of as observableOf, forkJoin as observableForkJoin,  Observable } from 'rxjs';

import {catchError} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { GenericCrudService } from '../../../../services/generic-crud.service';
import { MessageService } from '../../../../../core/message/message.service';
import { MessageGrowlService } from '../../../../../core/message/message-growl.service';
import { ComponentService } from '../../../services/component-highlight-stack.service';
import { TranslateService } from '@ngx-translate/core';
import { ElementSaveStatus } from 'app/shared/content-renderer/elements/generic-element-abstract.component';
import { EntityFactoryService } from '../../../../services/entity-factory.service';
import { AbstractGenericGridComponent } from 'app/shared/content-renderer/elements/abstract-generic-grid.component';
import { EntityValidatorStatus } from '../../../../validators/services/entity-validator';
import {EntityStatus} from '../../../../services/entity/entity-status';
import {MasterEntityConfig} from '../../../services/ElementContext';
import {clone, cloneDeep} from 'lodash';

@Injectable()
export class GenericGridBulkSaveService {

  public static readonly SAVE_MESSAGE_NOTHING_TO_SAVE = 'nothingToSave'

  protected grid: AbstractGenericGridComponent = null;

  protected commitedEntities: any[] = [];
  protected deletedEntities: any[] = [];

  private errorStatuses = [400, 409];

  constructor(
    protected genericCrudService: GenericCrudService,
    protected messageGrowlService: MessageGrowlService,
    protected componentService: ComponentService,
    protected translateService: TranslateService,
    protected messageService: MessageService,
    protected entityFactory: EntityFactoryService
  ) { }

  /**
   * Set the grid to be saved.
   * @param {AbstractGenericGridComponent} grid
   * @returns {GenericGridBulkSaveService}
   */
  public firstSetGrid(grid: AbstractGenericGridComponent): GenericGridBulkSaveService {
    this.grid = grid;
    return this;
  }

  /**
   * Commits all the grid changes to the bulk saver service.
   * @returns {GenericGridBulkSaveService}
   */
  public thenCommitChanges(): GenericGridBulkSaveService {
    this.commitedEntities = [];
    this.deletedEntities = [];

    const createdEntities = this.grid.getCreatedEntities(true);
    for (const entity of createdEntities) {
      if (!entity[EntityStatus.ENTITY_DRAFT_DELETED_FLAG]) {
        this.commitedEntities.push(entity);
      }
    }

    const updatedEntities = this.grid.getUpdatedEntities(true);
    for (const entity of updatedEntities) {
      if (!entity[EntityStatus.ENTITY_DRAFT_DELETED_FLAG]) {
        this.commitedEntities.push(entity);
      }
    }

    const embeddedUpdatedEntities = this.grid.getEmbeddedEntitiesChanged();
    for (const entity of embeddedUpdatedEntities) {
      if (!entity[EntityStatus.ENTITY_DRAFT_DELETED_FLAG]) {
        this.commitedEntities.push(entity);
      }
    }

    const deletedEntitiesDraft = this.grid.getDraftDeletedEntities();
    for (const entity of deletedEntitiesDraft) {
      this.deletedEntities.push(entity);
    }

    return this;
  }

  /**
   * Validates and flushes all the changes - if required.
   * @returns {Observable<ElementSaveStatus>}
   */
  public finallyValidateAndPush(): Observable<ElementSaveStatus> {

    return new Observable<ElementSaveStatus>((observer) => {
      this.validate()
        .subscribe((validationStatus: EntityValidatorStatus) => {

          if (validationStatus.isValid) {
            this.flushGridEntityChanges().subscribe((saveStatus) => {
              const statusMessage = this.analyzeResponse(saveStatus);

              if (statusMessage.status) {
                this.grid.clearShelvedChanges();

                if (saveStatus.message !== GenericGridBulkSaveService.SAVE_MESSAGE_NOTHING_TO_SAVE) {
                  this.messageGrowlService.success('Done.');
                }
              } else {
                if (statusMessage.message) {
                  this.messageGrowlService.error(statusMessage.message);
                }
              }

              observer.next(statusMessage);
              observer.complete();
            });
          } else {
            observer.next({
              status: false,
              content: validationStatus.error,
              message: 'error'
            });
            observer.complete();
          }
        });
    });
  }

  public validate(): Observable<EntityValidatorStatus> {
    const validations = [];

    for (const entity of this.commitedEntities) {
      validations.push(this.grid.getValidator().onValidate(entity, this.grid));
    }

    return new Observable<EntityValidatorStatus>((observer) => {

      if (validations.length === 0) {
        observer.next({
          entity: null,
          errorFields: [],
          isValid: true,
          error: ''
        });
        observer.complete();
      }

      observableForkJoin(validations).subscribe((statuses: any) => {
        const errorMessages = [];

        for (const status of statuses) {
          if (!status.isValid) {
            errorMessages.push(status.error);
          }
        }

        observer.next({
          isValid: errorMessages.length === 0,
          error: errorMessages.join('\n'),
          errorFields: [],
          entity: null
        });
        observer.complete();
      });
    });
  }

  private analyzeResponse(response: any): ElementSaveStatus {
    const analyzedStatus = {
      status: response.status === true,
      content: response.content,
      message: null
    };

    if (response.content && (this.errorStatuses.indexOf(response.content.status) !== -1 || response.content.ok === false)) {
      const errorMessages = [];

      if (response.content.error && response.content.error.errors) {
        for (const error in response.content.error.errors) {
          if (response.content.error.errors[error]) {
            errorMessages.push(error + ': ' + response.content.error.errors[error]);
          }
        }
      }

      analyzedStatus.message = errorMessages.join('\n');

      analyzedStatus.status = false;
    }

    return analyzedStatus;
  }

  protected flushGridEntityChanges(): Observable<ElementSaveStatus> {
    const observables = [];

    for (let entity of this.commitedEntities) {
      entity = this.entityFactory.assignMasterEntities(entity, this.prepareMasterEntities());

      const params = cloneDeep(this.grid.apiRoutAdditionalParameters);
      params.embedded = 'none';

      if (entity.id) {
        observables.push(this.genericCrudService.editEntity(`${this.grid.getElementDataModelApiRoute()}/${entity.id}`, entity, params));
      } else {
        observables.push(this.genericCrudService.createEntity(this.grid.getElementDataModelApiRoute(), entity, params));
      }
    }

    for (const entity of this.deletedEntities) {
      if (entity.id) {
        observables.push(this.genericCrudService.deleteEntity(`${this.grid.getElementDataModelApiRoute()}/${entity.id}`));
      }
    }

    return new Observable<ElementSaveStatus>((observer) => {

      if (observables.length === 0) {
        observer.next({
          status: true,
          content: [],
          message: GenericGridBulkSaveService.SAVE_MESSAGE_NOTHING_TO_SAVE
        });
        observer.complete();
      }

      observableForkJoin(observables).pipe(
        catchError((response: any) => {
          return observableOf(response);
        }))
        .subscribe(results => {
          observer.next({
            status: true,
            content: results,
            message: ''
          });
          observer.complete();
        });
    });
  }

  protected prepareMasterEntities(): MasterEntityConfig[]  {
    const masterEntities = this.grid.getElementContext().getMasterEntities();

    if (!this.grid.getElementContext().getMasterElementContext() || !this.grid.getElementContext().getMasterElementContext().component.getSelectedEntity()) {
      return masterEntities;
    }

    const masterSelectedEntity = this.grid.getElementContext().getMasterElementContext().component.getSelectedEntity();

    for (const masterEntity of masterEntities) {
      if (masterEntity.entity && masterEntity.entity.fqn === masterSelectedEntity.fqn && !masterEntity.value) {
        masterEntity.entity = masterSelectedEntity;
        masterEntity.value = masterSelectedEntity.id;
      }
    }

    return masterEntities;
  }

  public getGenericCrudService(): GenericCrudService {
    return this.genericCrudService;
  }
}
