<p-dialog
    *ngIf="displayCalculationResults && calculationResults"
    [(visible)]="displayCalculationResults"
    [maximizable]="false"
    [responsive]="false"
    [resizable]="false"
    [width]="500"
    [height]="750"
    [closable]="false"
    appendTo="body"
    header="Calculation Results"
    showEffect="fade"
    styleClass="module-in-dialog"
    #dialog
  >
  <div class="dialog-close-button-container ui-helper-clearfix ng-tns-c8-6">
    <a (click)="onDialogMaximize($event)" class="ng-tns-c8-6 ui-dialog-titlebar-close ui-corner-all">
      <span class="fa fa-fw fa-window-maximize"></span>
    </a>
    <a (click)="onHideCalculation($event)" class="ng-tns-c8-6 ui-dialog-titlebar-close ui-corner-all">
      <span class="dialog-close-button fa fa-fw fa-close"></span>
    </a>
  </div>

  <split direction="horizontal" [gutterSize]="5">
    <split-area [size]="100" [order]="1">
      <p-fieldset legend="Salary" [toggleable]="true">
        <div class="ui-grid ui-grid-responsive ui-fluid">

          <div class="ui-grid-row">
            <div class="ui-grid-col-4">
              <label>Wage</label>
            </div>
            <div class="ui-grid-col-8">
              <span>{{calculationResults.wage}}</span>
            </div>
          </div>

          <div class="ui-grid-row">
            <div class="ui-grid-col-4">
              <label>Monthly Wage</label>
            </div>
            <div class="ui-grid-col-8">
              <span>{{calculationResults.monthlyWage}}</span>
            </div>
          </div>

          <div class="ui-grid-row">
            <div class="ui-grid-col-4">
              <label>Hourly Wage</label>
            </div>
            <div class="ui-grid-col-8">
              <span>{{calculationResults.hourlyWage}}</span>
            </div>
          </div>
        </div>
      </p-fieldset>
      <p-fieldset legend="Weighted times" [toggleable]="true" *ngIf="calculationResults && calculationResults.weightedTimes">
        <p-fieldset legend="{{weightedTime.from}} - {{weightedTime.to}}" [toggleable]="true" *ngFor="let weightedTime of calculationResults.weightedTimes">
          <div class="ui-grid ui-grid-responsive ui-fluid">
            <div class="ui-grid-row" *ngFor="let wTime of weightedTime | keystransform">
              <div class="ui-grid-col-4">
                <label>{{ wTime.key }}</label>
              </div>
              <div class="ui-grid-col-8">
                <span>{{ wTime.value }}</span>
              </div>
            </div>
          </div>
        </p-fieldset>
      </p-fieldset>
      <p-fieldset legend="Onetime payments" [toggleable]="true" *ngIf="calculationResults.oneTimePayments && !isEmptyObject(calculationResults.oneTimePayments)">
        <div class="ui-grid ui-grid-responsive ui-fluid">
          <div class="ui-grid-row" *ngFor="let oneTimePayment of calculationResults.oneTimePayments | keystransform">
            <div class="ui-grid-col-4">
              <label>{{ oneTimePayment.key }}</label>
            </div>
            <div class="ui-grid-col-8">
              <span>{{ oneTimePayment.value }}</span>
            </div>
          </div>
        </div>
      </p-fieldset>
      <p-fieldset legend="Holiday Allowances" [toggleable]="true" *ngIf="calculationResults.holidayAllowances && !isEmptyObject(calculationResults.holidayAllowances)">
        <div class="ui-grid ui-grid-responsive ui-fluid">
          <div class="ui-grid-row" *ngFor="let holidayAllowance of calculationResults.holidayAllowances | keystransform">
            <div class="ui-grid-col-4">
              <label>{{ holidayAllowance.key }}</label>
            </div>
            <div class="ui-grid-col-8">
              <span>{{ holidayAllowance.value }}</span>
            </div>
          </div>
        </div>
      </p-fieldset>
      <p-fieldset legend="Christmas Allowances" [toggleable]="true" *ngIf="calculationResults.christmasAllowances && !isEmptyObject(calculationResults.christmasAllowances)">
        <div class="ui-grid ui-grid-responsive ui-fluid">
          <div class="ui-grid-row" *ngFor="let xmasAllowance of calculationResults.christmasAllowances | keystransform">
            <div class="ui-grid-col-4">
              <label>{{ xmasAllowance.key }}</label>
            </div>
            <div class="ui-grid-col-8">
              <span>{{ xmasAllowance.value }}</span>
            </div>
          </div>
        </div>
      </p-fieldset>
    </split-area>
  </split>
</p-dialog>
