import {of as observableOf, Observable} from 'rxjs';
import {AbstractExecutionStep} from '../../../../core/executor/abstract-execution-step';
import {ExecutionStepStatus} from '../../../../core/executor/execution-step-status';
import {AbstractGenericGridComponent, StaticGridFilterField} from '../../../content-renderer/elements/abstract-generic-grid.component';

export class SetupWorkHourDocumentsFilterExecutionStep extends AbstractExecutionStep {
  public staticFilters: StaticGridFilterField[] = [];

  public doExecute(): Observable<ExecutionStepStatus> {
    const payload = this.getPayload(),
      component = payload.getValue() ? payload.getValue() : null;

    if (!(component instanceof AbstractGenericGridComponent)) {
      return this.getFailObservable('You need to pass AbstractGenericGridComponent as Payload value!');
    }
    return this.setupFilters(component);
  }

  private setupFilters(grid: AbstractGenericGridComponent): Observable<ExecutionStepStatus> {
    const masterComponent = grid.getElementContext().getMasterElementContext().component;
    if (!masterComponent) {
      return this.getFailObservable('You need to set master component value!');
    }
    const filterComponent = masterComponent.filterComponent;
    if (filterComponent) {
        this.filterGrid(masterComponent);
        grid.staticFilters.push({
          field: 'leasedEmployee',
          value: this.staticFilters['leasedEmployee']
        });
        grid.embeddedFields = [...grid.embeddedFields, 'leasedEmployee'];
    }

    return observableOf({status: true, content: null});
  }

  private filterGrid(masterComponent) {
    if (masterComponent && masterComponent.gridFilters) {
      this.addLeasedEmployeeFilter(masterComponent);
    }
  }

  private addLeasedEmployeeFilter(masterComponent: AbstractGenericGridComponent): void {
    const leasedEmployees = masterComponent.gridFilters.leasedEmployee && masterComponent.gridFilters.leasedEmployee.value ?
      masterComponent.gridFilters.leasedEmployee.value : [];

    if (leasedEmployees.length > 0) {
      const ids = [];
      for (const leasedEmployee of leasedEmployees) {
        if (leasedEmployee.id) {
          ids.push(leasedEmployee.id);
          this.staticFilters['leasedEmployee'] = leasedEmployee.id;
        }
      }
    }
  }
}
