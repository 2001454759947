
import {throwError as observableThrowError,  Observable } from 'rxjs';

import {switchMap, catchError} from 'rxjs/operators';
import {ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { cloneDeep } from 'lodash';
import { ToolbarItemAbstract } from '../toolbar-item-abstract.component';
import { GenericTreeGridComponent } from '../../../../generic-tree-grid/generic-tree-grid.component';
import { GenericCrudService } from '../../../../../../services/generic-crud.service';
import { MessageGrowlService } from '../../../../../../../core/message/message-growl.service';
import {EntityStatus} from '../../../../../../services/entity/entity-status';
import {Element} from '../../../../../../services/element/element';
import {ElementContext} from '../../../../../services/ElementContext';
import {Entity} from '../../../../../../helpers/entity';
import {TOAST_LIFE_INFINITE, ToastComponentsRegistry, ToastService} from '../../../../../../../core/service/toast.service';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'toolbar-item-salary-type-import-dialog',
  styleUrls: ['./toolbar-item-salary-type-import-dialog.component.scss'],
  templateUrl: './toolbar-item-salary-type-import-dialog.component.html'
})
export class ToolbarItemSalaryTypeImportDialog extends ToolbarItemAbstract implements OnDestroy {

  public isDialogVisible: boolean = false;
  public salaryTypeCategoryOptions: SelectItem[] = [];
  public selectedSalaryTypeCategory: SelectItem = null;

  public nodes: TreeNode[] = [];
  public selection: TreeNode[] = [];

  public constructor(
    private genericCrudService: GenericCrudService,
    private messageGrowlService: MessageGrowlService,
    private translateService: TranslateService,
    private toastService: ToastService
  ) {
    super();
  }

  public ngOnDestroy(): void {
    this.subscriptions.forEach(sub => sub.unsubscribe());
    this.salaryTypeCategoryOptions = [];
    this.nodes = [];
  }

  public click() {
    const component = this.getComponent();

    if (component instanceof GenericTreeGridComponent) {
      this.isDialogVisible = true;

      this.loadNodes(component);

      this.loadSalaryTypeCategories()
        .subscribe((salaryTypeCategories: any[] = []) => {
          this.fillSalaryTypeCategoryOptions(salaryTypeCategories);
        });
    }
  }

  public onImport(event): void {
    const component = this.getComponent();

    if (!this.selectedSalaryTypeCategory) {
      this.messageGrowlService.error(
        this.translateService.instant('COMMON.ERROR'),
        this.translateService.instant('TOOLBARMANAGEMENT.SALARY_TYPE_CATEGORY_NOT_SELECTED')
      );
    }

    if (this.selection.length === 0) {
      this.messageGrowlService.error(
        this.translateService.instant('COMMON.ERROR'),
        this.translateService.instant('TOOLBARMANAGEMENT.SALARY_TYPES_NOT_SELECTED')
      );
    }

    if (component instanceof GenericTreeGridComponent && this.selection.length > 0 && this.selectedSalaryTypeCategory) {

      this.toastService.custom(ToastComponentsRegistry.PROGRESS_BAR, {
        severity: 'info',
        life: TOAST_LIFE_INFINITE,
        closable: false,
        summary: this.translateService.instant('COMMON.SAVING_PLEASE_WAIT')
      });

      this.import(this.selectedSalaryTypeCategory, this.selection, component).pipe(
        catchError((response) => {
          this.toastService.clear(ToastComponentsRegistry.PROGRESS_BAR, 300);

          return observableThrowError(response);
        }))
        .subscribe(() => {
          this.isDialogVisible = false;
          this.messageGrowlService.showDataSaved();

          component.sortDirection = Element.SORT_DIRECTION_DESC;
          component.sortField = 'id';
          component.currentPage = 1;

          component.loadEntities().subscribe(() => {
            component.selectedEntity = component.entities[0];
            component.reselectEntity();
          });

          this.toastService.clear(ToastComponentsRegistry.PROGRESS_BAR, 300);
        });
    }
  }

  private import(salaryTypeCategory: any, children: TreeNode[] = [], tree: GenericTreeGridComponent): Observable<any> {

    return this.saveSalaryType(salaryTypeCategory, children, tree).pipe(
      switchMap((salaryType: any) => {
        return this.saveChildren(salaryType, children);
      }));
  }

  private saveSalaryType(salaryTypeCategory: any, children: TreeNode[] = [], tree: GenericTreeGridComponent): Observable<any> {
    const salaryType = this.buildSalaryType(salaryTypeCategory, children, tree);

    return this.genericCrudService.createEntity('phoenix/salarytypes', salaryType);
  }

  private saveChildren(salaryType: any, children: TreeNode[] = [], ): Observable<any> {
    const childrenSalaryTypes = [];

    const parentSalaryTypeCategory = salaryType.salaryTypeCategory ? salaryType.salaryTypeCategory :
      salaryType._embedded.salaryTypeCategory;

    for (const node of children) {
      const childSalaryType = node.data;

      childSalaryType.parent = salaryType;
      childSalaryType.salaryTypeCategory = parentSalaryTypeCategory;

      childrenSalaryTypes.push(childSalaryType);
    }

    return this.genericCrudService.editEntities('phoenix/salarytypes', childrenSalaryTypes);
  }

  private buildSalaryType(salaryTypeCategory: any, children: TreeNode[] = [], tree: GenericTreeGridComponent): any {
    const collectiveAgreement = this.getCollectiveAgreement(tree, children);

    if (collectiveAgreement === null) {
      this.messageGrowlService.error(
        this.translateService.instant('COMMON.ERROR'),
        this.translateService.instant('COLLECTIVE_AGREEMENT.COULD_NOT_FIND_COLLECTIVE_AGREEMENT_FOR_IMPORT')
      );
    }

    const salaryType = {
      code: salaryTypeCategory.code,
      name: salaryTypeCategory.name,
      description: salaryTypeCategory.description,
      salaryTypeCategory: salaryTypeCategory,
      collectiveAgreement: collectiveAgreement,
      children: []
    };
    salaryType[EntityStatus.ENTITY_CHANGED_FLAG] = true;

    return salaryType;
  }

  private loadNodes(component): void {
    this.nodes = cloneDeep(component.entities);
  }

  private loadSalaryTypeCategories(): Observable<any[]> {
    return this.genericCrudService.getEntities('phoenix/salarytypecategories');
  }

  private fillSalaryTypeCategoryOptions(salaryTypeCategories: any[] = []): void {
    for (const salaryTypeCategory of salaryTypeCategories) {
      this.salaryTypeCategoryOptions = [...this.salaryTypeCategoryOptions, {
        value: salaryTypeCategory,
        label: salaryTypeCategory.name
      }];
    }
  }

  private getCollectiveAgreement(tree: GenericTreeGridComponent, children: TreeNode[] = []): any|null {
    const masterElementContext: ElementContext = tree.getElementContext() && tree.getElementContext().masterElementContext ?
      tree.getElementContext().masterElementContext : null;

    if (masterElementContext && masterElementContext.component && masterElementContext.component.getSelectedEntity()) {
      return masterElementContext && masterElementContext.component && masterElementContext.component.getSelectedEntity();
    }

    const firstChild = children[0].data;

    return Entity.getValue(firstChild, 'collectiveAgreement') ||
      Entity.getValueInEmbedded(firstChild, 'collectiveAgreement');
  }
}
