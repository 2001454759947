import {Input, OnInit} from '@angular/core';
import {Message} from 'primeng/api';
import {ToastService} from '../../../core/service/toast.service';

export abstract class AbstractToastComponent implements OnInit {

  @Input() public message: Message;
  @Input() public toastService: ToastService;

  public abstract getKey(): string;

  public ngOnInit(): void {
    setTimeout(() => this.toastService.add(this.message), 1);

    this.onComponentInit();
  }

  public onComponentInit(): void {
  }
}
