import {ChangeDetectionStrategy, Component} from '@angular/core';
import {ToolbarItemAbstract} from '../toolbar-item-abstract.component';
import {GenericCrudService} from '../../../../../../services/generic-crud.service';
import {MessageGrowlService} from '../../../../../../../core/message/message-growl.service';
import {TranslateService} from '@ngx-translate/core';
import {environment} from '../../../../../../../../environments';
import {TOAST_LIFE_INFINITE, ToastComponentsRegistry, ToastService} from '../../../../../../../core/service/toast.service';
import {AbstractGenericGridComponent} from '../../../../abstract-generic-grid.component';
import {ComponentService} from '../../../../../services/component-highlight-stack.service';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-toolbar-item-bdm-export',
  template: ''
})
export class ToolbarItemBdmExportComponent extends ToolbarItemAbstract {

  protected baseUrl = '';

  public constructor(
    protected genericCrudService: GenericCrudService,
    protected messageGrowlService: MessageGrowlService,
    protected translateService: TranslateService,
    protected componentService: ComponentService,
    protected toastService: ToastService
  ) {
    super();
    this.baseUrl = environment.baseUrl;
  }

  public click() {
    const component = this.getComponent();

    if (component instanceof AbstractGenericGridComponent) {
      this.handleExportInGridContext(component);
    } else if (this.isInCurrentContext(ToolbarItemAbstract.CONTEXT_MODULE)) {
      this.handleExportInModuleContext();
    }
  }

  private handleExportInGridContext(component: AbstractGenericGridComponent) {
    const entity = component.selectedEntity;

    if (!entity) {
      return this.messageGrowlService.error('Entity not selected');
    }

    this.onExportEntity(entity);
  }

  private handleExportInModuleContext() {
    const highlightedEntity = this.componentService.getHighlightedEntity();

    if (!highlightedEntity) {
      return this.messageGrowlService.error('Entity not selected');
    }

    this.onExportEntity(highlightedEntity);
  }

  public onExportEntity(entity) {
    this.toastService.custom(ToastComponentsRegistry.PROGRESS_BAR, {
      severity: 'info',
      life: TOAST_LIFE_INFINITE,
      closable: false,
      summary: this.translateService.instant('COMMON.LOADING_PLEASE_WAIT')
    });

    let url = 'phoenix';

    if (entity && entity.fqn === 'PhoenixBundle\\Entity\\Customer') {
      url += '/customers/bmdexport';
    } else if (entity && entity.fqn === 'PhoenixBundle\\Entity\\LeasedEmployee') {
      url += '/leasedemployees/bmdexport';
    } else if (entity && entity.fqn === 'PhoenixBundle\\Entity\\Assignment') {
      url += '/assignments/bmdexport';
    }

    this.genericCrudService.get(url).subscribe(() => {
      this.toastService.clear(ToastComponentsRegistry.PROGRESS_BAR);
    });
  }
}
