
import {map} from 'rxjs/operators';
import {Observable} from 'rxjs';
import {AbstractExecutionStep} from '../../../../core/executor/abstract-execution-step';
import {ExecutionStepStatus} from '../../../../core/executor/execution-step-status';
import {
  LeasedEmployeeSearchComponent
} from '../../../content-renderer/elements/custom/leased-employee-search/leased-employee-search.component';

export class SetupLeasedEmployeeSearchExecutionStep extends AbstractExecutionStep {

  public doExecute(): Observable<ExecutionStepStatus> {
    const payload = this.getPayload(),
      component = payload.getValue().component ? payload.getValue().component : payload.getValue();

    if (!(component instanceof LeasedEmployeeSearchComponent)) {
      return this.getFailObservable('You need to pass LeasedEmployeeSearchComponent as Payload value!');
    }

    return this.doSetup(component);
  }

  protected doSetup(component: LeasedEmployeeSearchComponent): Observable<ExecutionStepStatus> {
    const moduleId = this.getParamValue('module');

    return component.runSetup(moduleId).pipe(
      map(() => {
        return {status: true, content: null};
      }));
  }
}
