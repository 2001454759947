import { AbstractInlineEditor } from './abstract-inline-editor';
import {Observable, of} from 'rxjs';

export class ColorInlineEditor extends AbstractInlineEditor {

    public onEdit(entity: any, event: any) {
      let value = event.value;

      if (event instanceof KeyboardEvent) {
        const target: any = event.target;
        value = target.value;
      }

      this.changeEntityColumnProperty(value);
    }

    public onBlur(entity: any, event: any) {

    }

    public onFocus(entity: any, event: any): Observable<any> {
      return of(null);
    }
}
