import {Injectable} from '@angular/core';
import {ModuleState} from '../../module-state';
import {ModuleStateDetailsView} from './module-state-details-view';
import {ModuleStateDialogView} from './module-state-dialog-view';
import {GenericDialogModuleService} from '../../../elements/generic-dialog/service/generic-dialog-module.service';
import {ModuleStateView} from './module-state-view';
import {ModulesStateService} from '../../modules-state.service';
import {ElementsStateService} from '../../elements-state.service';
import {ElementsStackService} from '../../elements-stack.service';
import {LocationService} from '../../../../services/location.service';
import {EntityValidator} from '../../../../validators/services/entity-validator';

@Injectable()
export class ModuleStateViewFactoryService {

  public constructor(
    private readonly dialogService: GenericDialogModuleService,
    private readonly modulesStateService: ModulesStateService,
    private readonly elementsStateService: ElementsStateService,
    private readonly elementsStackService: ElementsStackService,
    private readonly locationService: LocationService,
    private readonly entityValidator: EntityValidator
  ) {

  }

  public instance(moduleState: ModuleState): ModuleStateView {
    let instance = null;

    if (moduleState.isDetailsView) {
      instance = new ModuleStateDetailsView(
        this.modulesStateService,
        this.elementsStateService,
        this.elementsStackService,
        this.locationService,
        this.entityValidator
      );
    }

    if (moduleState.isDialog) {
      instance = new ModuleStateDialogView(this.dialogService);
    }

    instance.moduleState = moduleState;

    return instance;
  }

}
