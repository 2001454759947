import { Component, OnInit, Input } from '@angular/core';
import {Menu} from '../../../../../../shared/services/menu/menu';
import {Router} from '@angular/router';

@Component({
    selector: 'app-module-opener-menu-item',
    templateUrl: './module-opener-menu-item.component.html',
    styleUrls: ['./module-opener-menu-item.component.scss']
})
export class ModuleOpenerMenuItemComponent implements OnInit {

  @Input() iconsPath: string = '';
  @Input() item: Menu;

  public moduleId = null;

  constructor(
    private router: Router
  ) { }

  ngOnInit() {

  }

  onOpenModule(event){
    this.router.navigate([this.item.routerLink[0] + '/' + this.moduleId]);
    this.moduleId = null;
  }

  onKeydown(event) {
    if (event.key === "Enter") {
      this.onOpenModule(event);
    }
  }
}
