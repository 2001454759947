import {Injectable} from '@angular/core';
import {LockingService} from '../../services/locking.service';
import {StoreType} from './entity-dirty-store.service';

export class TimeDecimalColumnSwitchState {

  moduleId: number;
  isIndustrialTime: boolean;

  public constructor(moduleId: number, isIndustrialTime: boolean) {
    this.moduleId = moduleId;
    this.isIndustrialTime = isIndustrialTime;
  }
}

@Injectable()
export class TimeDecimalColumnSwitchService {

  private states: TimeDecimalColumnSwitchState[];

  public constructor() {
    this.states = [];
  }

  public isIndustrialTime(moduleId: number): boolean {
    const state = this.getState(moduleId);
    return state ? state.isIndustrialTime : null;
  }

  private getState(moduleId: number): TimeDecimalColumnSwitchState {
    for (const state of this.states) {
      if (moduleId === state.moduleId) {
        return state;
      }
    }
    return null;
  }

  public setIndustrialTime(moduleId: number, isIndustrialTime: boolean): TimeDecimalColumnSwitchService {
    let state = this.getState(moduleId);
    if (state) {
      state.isIndustrialTime = isIndustrialTime;
    } else {
      state = {moduleId: moduleId, isIndustrialTime: isIndustrialTime};
      this.states.push(state);
    }

    return this;
  }

  public removeState(moduleId: number): TimeDecimalColumnSwitchService {
    if (this.states && this.states.length > 0) {
      const stateIndex = this.states.findIndex((aState) => {
          return moduleId === aState.moduleId;
      });

      if (stateIndex !== -1) {
          this.states.splice(stateIndex, 1);
      }
    }

    return this;
  }
}
