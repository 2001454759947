import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import {ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, Input, OnInit, Renderer2, ViewChild, ViewContainerRef} from '@angular/core';
import {Element} from '../../../services/element/element';
import {ModuleElement} from '../../../services/module/module-element';
import {CancelComponentChangesService} from '../../services/cancel-components-changes.service';
import {ExecutorService} from '../../../../core/executor/executor.service';
import {ComponentService} from '../../services/component-highlight-stack.service';
import {EntityDataStoreService} from '../../services/entity-data-store.service';
import {ModulesStateService} from '../../services/modules-state.service';
import {GenericElementValidationExecutionStepsFactory} from '../../services/generic/generic-element-validation-execution-steps-factory';
import {EntityValidator, EntityValidatorStatus} from '../../../validators/services/entity-validator';
import {GenericCrudService} from '../../../services/generic-crud.service';
import {UserSessionService} from '../../../../core/service/user-session.service';
import {PermissionService} from '../../../services/permission/permission.service';
import {Observable} from 'rxjs/Rx';
import {FullCalendar} from 'primeng/fullcalendar';
import {takeUntil, tap} from 'rxjs/operators';
import {EntityFactoryService} from '../../../services/entity-factory.service';
import {RunnableEventRegistry} from '../../../../core/job-runner/type/runnable-event.registry';
import {JobContainerService} from '../../../../core/job-runner/job-container.service';
import {ElementContext, MasterEntityConfig} from '../../services/ElementContext';
import {LocationService} from '../../../services/location.service';
import {ElementsStackService} from '../../services/elements-stack.service';
import {ExecutorActionEvent} from '../../../../core/executor/service/executor-actions/executor-action-event';
import {DoubleClickService} from '../../services/double-click.service';
import {GenericGridEntityService} from '../../services/generic/entity/generic-grid-entity.service';
import {EntityDirtyStoreService} from '../../services/entity-dirty-store.service';
import {RequestCachingService} from '../../../services/request-caching.service';
import {AbstractGenericGridComponent} from '../abstract-generic-grid.component';
import {GenericGridColumnBuilderService} from '../generic-grid/services/generic-grid-column-builder.service';
import {GenericGridBulkSaveService} from '../generic-grid/services/generic-grid-bulk-save.service';
import {ToolbarItemCheckService} from '../generic-toolbar/services/check/toolbar-item-check.service';
import {MessageGrowlService} from '../../../../core/message/message-growl.service';
import {GenericGridRemoteFilterService} from '../generic-grid/services/generic-grid-remote-filter.service';
import {FieldActionsService} from '../../services/field-actions.service';
import {GenericGridGlobalFilterService} from '../generic-grid/services/generic-grid-global-filter.service';
import {LayoutService} from '../../../services/layout-service';
import {ElementsStateService} from '../../services/elements-state.service';
import {ConfirmationService} from 'primeng/primeng';
import {TranslateService} from '@ngx-translate/core';
import {LoggerService} from '../../services/logger/logger.service';
import {HttpErrorResponseService} from '../../../services/http-error-response-message.service';
import {EventHandlerService} from '../../../../core/events/event/event-handler.service';
import {GenericElementInlineEditorService} from '../../services/generic/generic-element-inline-editor.service';
import {GenericElementFilterService} from '../../services/generic/filter/generic-element-filter.service';
import {EntityHydrator} from '../../../services/entity-hydrator.service';
import {GenericTurboGridLayoutService} from '../generic-turbo-grid/service/generic-turbo-grid-layout-service';
import {ExecutionStepBuilderService} from '../../../../core/executor/builder/execution-step-builder.service';
import {LocalStorageDataService} from '../../../services/local-storage-data.service';
import {ExecutorActionsService} from '../../../../core/executor/service/executor-actions/executor-actions.service';
import {GenericDialogModuleService} from '../generic-dialog/service/generic-dialog-module.service';
import {ExecutionStepFactoryService} from '../../../../core/executor/factory/execution-step-factory.service';
import {GenericElementEmbeddedService} from '../../services/generic/generic-element-embedded.service';
import {GenericElementContextMenuService} from '../../services/generic/generic-element-context-menu.service';
import {EntityManagerService} from '../../../../core/service/entity-manager/entity-manager.service';
import {GenericGridSingleEntitySaveService} from '../generic-grid/services/generic-grid-single-entity-save.service';
import {GenericGridLayoutService} from '../generic-grid/services/generic-grid-layout.service';

@Component({
  selector: 'app-generic-full-calendar',
  styleUrls: ['./generic-full-calendar.component.scss'],
  templateUrl: './generic-full-calendar.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    CancelComponentChangesService,
    GenericGridRemoteFilterService,
    GenericGridGlobalFilterService,
    GenericGridColumnBuilderService,
    GenericGridBulkSaveService,
    GenericGridEntityService,
    GenericElementInlineEditorService,
    GenericElementFilterService,
    GenericElementValidationExecutionStepsFactory,
    GenericTurboGridLayoutService,
    ExecutorService,
    GenericGridSingleEntitySaveService,
    GenericGridLayoutService
  ]
})
export class GenericFullCalendarComponent extends AbstractGenericGridComponent implements OnInit {

  @Input() element: Element;
  @Input() toolbarItems: any[] = [];
  @Input() statusBarItems: any[] = [];
  @Input() moduleElement: ModuleElement;
  @Input() masterEntity: any = null;
  @Input() masterField: any = null;
  @Input() isPart = false;
  @Input() entity: any = null;
  @Input() masterFilterField?: string;
  @Input() masterFilterValue?: string;

  @ViewChild(FullCalendar, {static: false}) private calendar: FullCalendar;
  @ViewChild('fullCalendar', {static: false, read: ElementRef}) private calendarEl: ElementRef;

  public options: any;

  protected toolbarContextName = 'fullCalendar';
  public emptyEntity: any = {};
  public emptyEntityInitDone = false;
  public selectedEntity: any = null;
  public entities: any[] = [];
  public updatedEntities: any[] = [];
  public createdEntities: any[] = [];
  public draftDeletedEntities: any[] = [];

  constructor(
    protected componentService: ComponentService,
    protected viewContainerRef: ViewContainerRef,
    protected modulesStateService: ModulesStateService,
    protected genericGridColumnBuilderService: GenericGridColumnBuilderService,
    protected genericGridBulkSaveService: GenericGridBulkSaveService,
    protected entityDirtyStore: EntityDirtyStoreService,
    protected entityDataStore: EntityDataStoreService,
    protected toolbarItemCheckService: ToolbarItemCheckService,
    protected requestCachingService: RequestCachingService,
    protected genericCrudService: GenericCrudService,
    protected messageGrowlService: MessageGrowlService,
    protected permissionService: PermissionService,
    protected genericGridRemoteFilterService: GenericGridRemoteFilterService,
    protected doubleClickService: DoubleClickService,
    protected fieldActionsService: FieldActionsService,
    protected genericGridGlobalFilterService: GenericGridGlobalFilterService,
    protected elementRef: ElementRef,
    protected renderer: Renderer2,
    protected layoutService: LayoutService,
    protected locationService: LocationService,
    protected elementsStackService: ElementsStackService,
    protected elementStateService: ElementsStateService,
    protected entityFactory: EntityFactoryService,
    protected confirmationService: ConfirmationService,
    protected translationService: TranslateService,
    protected logger: LoggerService,
    protected httpErrorResponseService: HttpErrorResponseService,
    protected eventHandlerService: EventHandlerService,
    protected genericElementEntityService: GenericGridEntityService,
    protected genericElementInlineEditorService: GenericElementInlineEditorService,
    protected genericElementFilterService: GenericElementFilterService,
    protected genericElementValidationExecutionStepsFactory: GenericElementValidationExecutionStepsFactory,
    protected entityHydrator: EntityHydrator,
    protected cancelComponentChangesService: CancelComponentChangesService,
    protected executorService: ExecutorService,
    protected entityValidator: EntityValidator,
    public genericGridLayoutService: GenericTurboGridLayoutService,
    protected executionStepBuilderService: ExecutionStepBuilderService,
    protected localStorage: LocalStorageDataService,
    protected executorActionsService: ExecutorActionsService,
    protected jobContainerService: JobContainerService,
    protected genericDialogModuleService: GenericDialogModuleService,
    protected stepsFactory: ExecutionStepFactoryService,
    protected embedded: GenericElementEmbeddedService,
    protected contextMenu: GenericElementContextMenuService,
    protected entityManager: EntityManagerService,
    protected genericGridSingleEntitySaveService: GenericGridSingleEntitySaveService,
    protected userSession: UserSessionService,
    public cdr: ChangeDetectorRef
  ) {
    super(componentService, viewContainerRef, entityDataStore, modulesStateService,
      genericGridColumnBuilderService, genericElementEntityService, genericElementInlineEditorService,
      permissionService, genericGridBulkSaveService, entityDirtyStore, entityDataStore,
      requestCachingService, toolbarItemCheckService, eventHandlerService, confirmationService, translationService,
      genericElementFilterService, cancelComponentChangesService, executorService, genericElementValidationExecutionStepsFactory,
      entityValidator, elementStateService, genericCrudService, fieldActionsService,
      userSession, executionStepBuilderService, layoutService,
      genericDialogModuleService, genericGridRemoteFilterService, embedded, contextMenu, entityManager, jobContainerService,
      cdr
    );
    this.genericElementEntityService.setComponent(this);
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.setEmptyEntity();
    this.elementContext = this.createContext();
    this.runJobs(RunnableEventRegistry.ContextCreated);
    this.genericCrudService.getEntities(this.getElementDataModelApiRoute()).subscribe((response) => {
      this.entities = response;
      this.cdr.detectChanges();
    })

    this.options = {
      plugins: [dayGridPlugin, timeGridPlugin, interactionPlugin],
      defaultDate: Date.now(),
      header: {
        left: 'prev,next',
        center: 'title',
        right: 'month,agendaWeek,agendaDay'
      },
      firstDay: 1,
      locale: 'de',
      displayEventTime: false,
      height: window.innerHeight - window.innerHeight * 0.2,
      eventRender: (event) => {
        this.renderer.setAttribute(event.el, 'data-id', event.event.id);
        this.renderer.setStyle(event.el.querySelector('.fc-title'), 'white-space', 'normal');
        event.el.addEventListener('dblclick', () => {
          let entity = event.event.extendedProps;
          entity = {...entity, ...{id: event.event.id}};
          this.onEventDblclick(entity);
        });
      },
      eventClick: (event: { event: any, el: any }) => {
        this.selectedEntity = {...event.event.extendedProps, ...{id: event.event.id}};
        this.calendarEl.nativeElement.querySelectorAll('.fc-day-grid-event').forEach(el => {
          this.renderer.setStyle(el, 'background', '#3788d8')
        });
        this.calendarEl.nativeElement.querySelectorAll('[data-id="' + event.event.id + '"]').forEach(el => {
          this.renderer.setStyle(el, 'background', '#005ab3')
        });
        this.cdr.detectChanges();
      }
    };
  }

  protected createContext() {
    const isSlave = this.moduleElement && typeof this.moduleElement.master !== 'undefined'
      && this.moduleElement.master !== null && this.moduleElement.master.id > 0;

    const isSubView = this.locationService.hasParam('parent-module')
      || (this.locationService.hasParam('id') && this.locationService.hasParam('master-entity'));

    const elementContext = new ElementContext(
      this.moduleElement.id,
      this.elementType,
      this,
      this.moduleElement,
      !isSlave,
      isSlave,
      !isSlave,
      isSubView,
      this.isPart,
      null,
      this.masterElementContext,
      null,
      null,
      null,
      this.isDialog,
      this.moduleElement.isMaster
    );

    if (isSubView) {
      const elementConfig = new MasterEntityConfig();
      elementConfig.value = this.locationService.getParam('id');
      elementConfig.name = this.locationService.hasParam('master-entity')
        ? this.locationService.getParam('master-entity') : this.moduleElement.masterFilterField;
      elementConfig.datamodelId = 0; // For now - no way to find out.
      elementConfig.filterType = 'subViewMasterEntity';

      elementContext.addMasterEntity(elementConfig);
    }

    // Now, some kinky stuff - parts in dialogs - hit it:
    if (this.isDialog && isSubView && this.selectedMasterEntity && this.moduleElement.masterFilterField) {
      const elementConfig = new MasterEntityConfig();
      elementConfig.value = this.selectedMasterEntity.id;
      elementConfig.name = this.moduleElement.masterFilterField;
      elementConfig.datamodelId = 0; // For now - no way to find out.
      elementConfig.filterType = 'subViewMasterEntity';

      elementContext.addMasterEntity(elementConfig);
    }

    if (this.selectedMasterEntity && this.masterEntityField) {
      const elementConfig = new MasterEntityConfig();
      elementConfig.value = this.selectedMasterEntity.id;
      elementConfig.name = this.masterEntityField;
      elementConfig.datamodelId = 0; // For now - no way to find out.
      elementConfig.filterType = 'masterEntity';

      elementContext.setSelectedMasterEntity(this.selectedMasterEntity).addMasterEntity(elementConfig);
    }

    if (this.masterFilterField && this.masterFilterValue) {
      const elementConfig = new MasterEntityConfig();
      elementConfig.value = this.masterFilterValue;
      elementConfig.name = this.masterFilterField;
      elementConfig.datamodelId = 0; // For now - no way to find out.
      elementConfig.filterType = 'masterEntity';

      elementContext.addMasterEntity(elementConfig);
    }

    // Now let's remove and re-add the grid context if it is already there:
    this.elementsStackService.remove(elementContext).add(elementContext);

    return elementContext;
  }

  doValidate(): Observable<EntityValidatorStatus> {
    return undefined;
  }

  onRefresh(): Observable<any> {
    return this.genericCrudService.getEntities(this.getElementDataModelApiRoute()).pipe(
      tap((response) => {
        this.entities = response;
        this.cdr.detectChanges();
      }), takeUntil(this.unsubscribe)
    )
  }

  public getToolbarExtraParams() {
    return {
      'gridComponent': this
    };
  }

  public setEmptyEntity() {
    if (!this.emptyEntityInitDone) {
      this.subscriptions.push(
        this.entityFactory
          .buildComponentEntity(this).pipe(
          takeUntil(this.unsubscribe))
          .subscribe((entity) => {
            this.emptyEntity = entity;
            // this.setEmptyEntityToSlaves(this.emptyEntity);
          })
      );
    }

    this.emptyEntityInitDone = true;
  }

  public onEventDblclick(selectedEntity: any) {
    this.setSelectedEntity(selectedEntity);
    this.executeAction(ExecutorActionEvent.DoubleClick, this)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe();

    if (!this.elementContext.isDialog) {
      // onDoubleClick needs some refactoring, remove field double click, it will no longer exist... will be moved to field actions
      this.doubleClickService
        .setDialogOptions({
          height: +this.moduleElement.onDoubleClickTargetDialogHeight,
          width: +this.moduleElement.onDoubleClickTargetDialogWidth,
          isModal: this.moduleElement.isDoubleClickTargetDialogModal
        })
        .setComponent(this)
        .onDoubleClick(event);
    }
  }

  public moduleElementTargetElementDialogHide() {
    this.moduleElementTargetElement = null;
  }

  public getSelectedEntity(): any {
    return this.selectedEntity || null;
  }

  public setSelectedEntity(entity: any): any {
    this.selectedEntity = entity;
  }

  public findEntity(entity: any): any {
    return this.genericElementEntityService.findEntity(entity);
  }

  public loadEntities(): Observable<import('../../services/entity-data-store.service').EntityData> {
    throw new Error('Method not implemented.');
  }

  public getRemoteDynamicFilters(): Object {
    throw new Error('Method not implemented.');
  }

  public onGridRendered() {
    throw new Error('Method not implemented.');
  }

  public addCreatedEntityDraft(entity: any) {
    throw new Error('Method not implemented.');
  }
}
