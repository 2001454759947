/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./leased-employee-costs.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@ngx-translate/core";
import * as i3 from "../../../../../../../node_modules/primeng/components/dropdown/dropdown.ngfactory";
import * as i4 from "@angular/forms";
import * as i5 from "primeng/components/dropdown/dropdown";
import * as i6 from "../../../../dynamic-table/dynamic-table.component.ngfactory";
import * as i7 from "../../../../dynamic-table/dynamic-table.component";
import * as i8 from "../../../../../core/executor/executor.service";
import * as i9 from "../../../../../core/executor/service/executor-actions/executor-actions.service";
import * as i10 from "../../../services/generic/generic-element-validation-execution-steps-factory";
import * as i11 from "../../../../../core/executor/factory/execution-step-factory.service";
import * as i12 from "../../../services/generic/filter/generic-element-filter.service";
import * as i13 from "../../../../services/generic-crud.service";
import * as i14 from "./service/leased-employee-costs-table.service";
import * as i15 from "../../../../services/api.builder.service";
import * as i16 from "./leased-employee-costs.component";
import * as i17 from "../../../services/component-highlight-stack.service";
import * as i18 from "../../../services/modules-state.service";
import * as i19 from "../../../services/entity-data-store.service";
import * as i20 from "../../../../validators/services/entity-validator";
import * as i21 from "../../../../../core/service/user-session.service";
import * as i22 from "../../generic-toolbar/services/check/toolbar-item-check.service";
import * as i23 from "../../../../services/layout-service";
import * as i24 from "../../../../../core/job-runner/job-container.service";
import * as i25 from "../../../services/elements-stack.service";
import * as i26 from "../../../services/elements-state.service";
import * as i27 from "../../generic-dialog/service/generic-dialog-module.service";
import * as i28 from "../../../../services/permission/permission.service";
var styles_LeasedEmployeeCostsComponent = [i0.styles];
var RenderType_LeasedEmployeeCostsComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_LeasedEmployeeCostsComponent, data: {} });
export { RenderType_LeasedEmployeeCostsComponent as RenderType_LeasedEmployeeCostsComponent };
export function View_LeasedEmployeeCostsComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 23, "div", [["class", "ui-g"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 9, "div", [["class", "ui-g-2"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 2, "div", [["class", "ui-g-4"]], null, null, null, null, null)), (_l()(), i1.ɵted(3, null, [" ", ": "])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(5, 0, null, null, 5, "div", [["class", "ui-g-8"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 4, "p-dropdown", [["filter", "true"], ["placeholder", " --- Bitte w\u00E4hlen --- "]], [[2, "ui-inputwrapper-filled", null], [2, "ui-inputwrapper-focus", null]], [[null, "onClick"], [null, "onChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("onClick" === en)) {
        var pd_0 = (_co.loadPeriods() !== false);
        ad = (pd_0 && ad);
    } if (("onChange" === en)) {
        var pd_1 = (_co.onPeriodChanged($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i3.View_Dropdown_0, i3.RenderType_Dropdown)), i1.ɵprd(5120, null, i4.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i5.Dropdown]), i1.ɵdid(8, 13877248, null, 1, i5.Dropdown, [i1.ElementRef, i1.Renderer2, i1.ChangeDetectorRef, i1.NgZone], { filter: [0, "filter"], style: [1, "style"], placeholder: [2, "placeholder"], options: [3, "options"] }, { onChange: "onChange", onClick: "onClick" }), i1.ɵqud(603979776, 1, { templates: 1 }), i1.ɵpod(10, { "width": 0 }), (_l()(), i1.ɵeld(11, 0, null, null, 9, "div", [["class", "ui-g-2"]], null, null, null, null, null)), (_l()(), i1.ɵeld(12, 0, null, null, 2, "div", [["class", "ui-g-4"]], null, null, null, null, null)), (_l()(), i1.ɵted(13, null, [" ", ": "])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(15, 0, null, null, 5, "div", [["class", "ui-g-8"]], null, null, null, null, null)), (_l()(), i1.ɵeld(16, 0, null, null, 4, "p-dropdown", [["filter", "true"], ["placeholder", " --- Bitte w\u00E4hlen --- "]], [[2, "ui-inputwrapper-filled", null], [2, "ui-inputwrapper-focus", null]], [[null, "onClick"], [null, "onChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("onClick" === en)) {
        var pd_0 = (_co.loadBranchOffices() !== false);
        ad = (pd_0 && ad);
    } if (("onChange" === en)) {
        var pd_1 = (_co.onBranchOfficeChanged($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i3.View_Dropdown_0, i3.RenderType_Dropdown)), i1.ɵprd(5120, null, i4.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i5.Dropdown]), i1.ɵdid(18, 13877248, null, 1, i5.Dropdown, [i1.ElementRef, i1.Renderer2, i1.ChangeDetectorRef, i1.NgZone], { filter: [0, "filter"], style: [1, "style"], placeholder: [2, "placeholder"], options: [3, "options"] }, { onChange: "onChange", onClick: "onClick" }), i1.ɵqud(603979776, 2, { templates: 1 }), i1.ɵpod(20, { "width": 0 }), (_l()(), i1.ɵeld(21, 0, null, null, 2, "div", [["class", "ui-g-12"]], null, null, null, null, null)), (_l()(), i1.ɵeld(22, 0, null, null, 1, "shared-dynamic-table", [], null, [[null, "onLazyLoad"], ["window", "keyup"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("window:keyup" === en)) {
        var pd_0 = (i1.ɵnov(_v, 23).onKeyUp($event) !== false);
        ad = (pd_0 && ad);
    } if (("onLazyLoad" === en)) {
        var pd_1 = (_co.onLazyLoad($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i6.View_DynamicTableComponent_0, i6.RenderType_DynamicTableComponent)), i1.ɵdid(23, 245760, [["leasedEmployeeCostsTable", 4]], 0, i7.DynamicTableComponent, [i1.ElementRef, i1.ChangeDetectorRef], { columns: [0, "columns"], cells: [1, "cells"], entities: [2, "entities"], totalCount: [3, "totalCount"], rowsCount: [4, "rowsCount"], paginator: [5, "paginator"], isLoadingData: [6, "isLoadingData"], height: [7, "height"], showFilters: [8, "showFilters"] }, { onLazyLoad: "onLazyLoad" })], function (_ck, _v) { var _co = _v.component; var currVal_3 = "true"; var currVal_4 = _ck(_v, 10, 0, "100%"); var currVal_5 = " --- Bitte w\u00E4hlen --- "; var currVal_6 = _co.periodOptions; _ck(_v, 8, 0, currVal_3, currVal_4, currVal_5, currVal_6); var currVal_10 = "true"; var currVal_11 = _ck(_v, 20, 0, "100%"); var currVal_12 = " --- Bitte w\u00E4hlen --- "; var currVal_13 = _co.branchOfficeOptions; _ck(_v, 18, 0, currVal_10, currVal_11, currVal_12, currVal_13); var currVal_14 = _co.columns; var currVal_15 = _co.cells; var currVal_16 = _co.entities; var currVal_17 = _co.totalCount; var currVal_18 = 50; var currVal_19 = true; var currVal_20 = _co.isLoading; var currVal_21 = _co.gridHeight; var currVal_22 = false; _ck(_v, 23, 0, currVal_14, currVal_15, currVal_16, currVal_17, currVal_18, currVal_19, currVal_20, currVal_21, currVal_22); }, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 3, 0, i1.ɵnov(_v, 4).transform("LEASED_EMPLOYEE_COSTS.PERIOD")); _ck(_v, 3, 0, currVal_0); var currVal_1 = i1.ɵnov(_v, 8).filled; var currVal_2 = i1.ɵnov(_v, 8).focused; _ck(_v, 6, 0, currVal_1, currVal_2); var currVal_7 = i1.ɵunv(_v, 13, 0, i1.ɵnov(_v, 14).transform("LEASED_EMPLOYEE_COSTS.BRANCH_OFFICE")); _ck(_v, 13, 0, currVal_7); var currVal_8 = i1.ɵnov(_v, 18).filled; var currVal_9 = i1.ɵnov(_v, 18).focused; _ck(_v, 16, 0, currVal_8, currVal_9); }); }
export function View_LeasedEmployeeCostsComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 16777216, null, null, 5, "app-custom-leased-employee-costs", [], null, [[null, "mouseover"], [null, "mouseleave"]], function (_v, en, $event) { var ad = true; if (("mouseover" === en)) {
        var pd_0 = (i1.ɵnov(_v, 5).onMouseOver($event) !== false);
        ad = (pd_0 && ad);
    } if (("mouseleave" === en)) {
        var pd_1 = (i1.ɵnov(_v, 5).onMouseLeave($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, View_LeasedEmployeeCostsComponent_0, RenderType_LeasedEmployeeCostsComponent)), i1.ɵprd(512, null, i8.ExecutorService, i8.ExecutorService, [i9.ExecutorActionsService]), i1.ɵprd(512, null, i10.GenericElementValidationExecutionStepsFactory, i10.GenericElementValidationExecutionStepsFactory, [i11.ExecutionStepFactoryService]), i1.ɵprd(512, null, i12.GenericElementFilterService, i12.GenericElementFilterService, [i13.GenericCrudService, i2.TranslateService]), i1.ɵprd(512, null, i14.LeasedEmployeeCostsTableService, i14.LeasedEmployeeCostsTableService, [i13.GenericCrudService, i15.ApiBuilderService]), i1.ɵdid(5, 245760, null, 0, i16.LeasedEmployeeCostsComponent, [i17.ComponentService, i1.ViewContainerRef, i18.ModulesStateService, i13.GenericCrudService, i19.EntityDataStoreService, i8.ExecutorService, i10.GenericElementValidationExecutionStepsFactory, i20.EntityValidator, i21.UserSessionService, i22.ToolbarItemCheckService, i23.LayoutService, i24.JobContainerService, i25.ElementsStackService, i26.ElementsStateService, i27.GenericDialogModuleService, i9.ExecutorActionsService, i28.PermissionService, i1.ChangeDetectorRef, i12.GenericElementFilterService, i14.LeasedEmployeeCostsTableService], null, null)], function (_ck, _v) { _ck(_v, 5, 0); }, null); }
var LeasedEmployeeCostsComponentNgFactory = i1.ɵccf("app-custom-leased-employee-costs", i16.LeasedEmployeeCostsComponent, View_LeasedEmployeeCostsComponent_Host_0, { masterElementContext: "masterElementContext", moduleElement: "moduleElement", element: "element", parentComponent: "parentComponent", isDialog: "isDialog", isPart: "isPart", toolbarItems: "toolbarItems", statusBarItems: "statusBarItems", selectedMasterEntity: "selectedMasterEntity", additional: "additional", fields: "fields", masterEntity: "masterEntity", masterField: "masterField" }, { componentInstantiated: "componentInstantiated" }, []);
export { LeasedEmployeeCostsComponentNgFactory as LeasedEmployeeCostsComponentNgFactory };
