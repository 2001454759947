import {Component, ElementRef} from '@angular/core';
import {DoubleClickService} from '../../../../services/double-click.service';
import {GenericCrudService} from '../../../../../services/generic-crud.service';
import {AbstractGridColumnComponent} from '../abstract-grid-column.component';
import {map, switchMap} from 'rxjs/operators';
import {Entity} from '../../../../../helpers/entity';
import {ChangeDetectorRefHelper} from '../../../../../helpers/change-detector-ref.helper';

@Component({
  selector: 'app-work-hour-monthly-action-column',
  templateUrl: './work-hour-monthly-action-column.component.html'
})
export class WorkHourMonthlyActionColumnComponent extends AbstractGridColumnComponent {

  constructor(
    protected elementRef: ElementRef,
    private doubleClickService: DoubleClickService,
    private genericCrudService: GenericCrudService
  ) {
    super(elementRef);
  }

  isCopy() {
    return !!this.entity.isCopy;
  }

  public isSummary(): boolean {
    return !!this.entity.isSummary;
  }

  copyWorkEntry() {
    this.component.isDataLoading = true;
    ChangeDetectorRefHelper.detectChanges(this.component);
    this.genericCrudService.customPost(`phoenix/workhourperiodentries`, {
        subAssignment: Entity.getValue(this.entity, 'subAssignment') || Entity.getValueInEmbedded(this.entity, 'subAssignment'),
        assignment: Entity.getValue(this.entity, 'assignment') || Entity.getValueInEmbedded(this.entity, 'assignment'),
        period: Entity.getValue(this.entity, 'period') || Entity.getValueInEmbedded(this.entity, 'period'),
        leasedEmployee: Entity.getValue(this.entity, 'leasedEmployee') || Entity.getValueInEmbedded(this.entity, 'leasedEmployee'),
        startDate: Entity.getValue(this.entity, 'startDate'),
        endDate: Entity.getValue(this.entity, 'endDate'),
        sortPosition: Entity.getValue(this.entity, 'sortPosition') + 1,
        year: this.entity.year,
        month: this.entity.month,
        // workType: aEntity.workType,
        // workTypeCode: aEntity.workTypeCode
      }, {
        embedded: 'subAssignment,assignment,assignment.customer,period,leasedEmployee'
      })
      .pipe(
        map((response) => {
          const entityIndex = this.component.findEntityIndex(this.entity);
          this.component.entities.splice(entityIndex + 1, 0, response);
          this.component.entities = [...this.component.entities];
          this.component.isDataLoading = false;
        })
      ).subscribe();
  }
}
