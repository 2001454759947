import {ChangeDetectionStrategy, Component} from '@angular/core';
import {ToolbarItemAbstract} from '../toolbar-item-abstract.component';
import {ExecutorService} from '../../../../../../../core/executor/executor.service';
import {MessageGrowlService} from '../../../../../../../core/message/message-growl.service';
import {ExecutionStepFactoryService} from '../../../../../../../core/executor/factory/execution-step-factory.service';
import {ExecutionStepPayload} from '../../../../../../../core/executor/execution-step-payload';
import {ExecutionStatus} from '../../../../../../../core/executor/execution-status';
import {ExecutionStepParameter, ExecutionStepParameterExecutor} from '../../../../../../../core/executor/execution-step-parameter';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-toolbar-item-execute-step',
  template: '',
  providers: [ ExecutorService ]
})
export class ToolbarItemExecuteStepComponent extends ToolbarItemAbstract {


  constructor(
    private executorService: ExecutorService,
    private stepFactory: ExecutionStepFactoryService,
    private messageGrowlService: MessageGrowlService
  ) {
    super();
  }

  public click() {
    const component = this.getComponent();

    if (!this.getToolbarItem().executionStep) {
      this.messageGrowlService.error('ExecutionStep not defined for this toolbar!');
    }

    this.executeStep(this.getToolbarItem().executionStep);
  }

  private executeStep(stepName: string): void {
    const step = this.stepFactory.createFromString(stepName, new ExecutionStepPayload(this.getComponent()));

    step.setParameters([
      new ExecutionStepParameter('toolbarItem', this.getToolbarItem()),
      new ExecutionStepParameter('executor', ExecutionStepParameterExecutor.ToolbarItem)
    ]);

    this.executorService
      .setSteps([step])
      .execute()
      .subscribe((status: ExecutionStatus) => {
        if (!status.isSuccess()) {
          this.messageGrowlService.error(`Execution failed with message ${status.getStepContent()}`);
        }
      });
  }
}
