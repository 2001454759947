import {Subject, Subscription} from 'rxjs';
import {ChangeDetectionStrategy, ChangeDetectorRef, Component, HostListener, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {Module} from '../shared/services/module/module';
import {GenericCrudService} from '../shared/services/generic-crud.service';
import {ModulesStateService} from '../shared/content-renderer/services/modules-state.service';
import {LocationService} from '../shared/services/location.service';
import {TranslateService} from '@ngx-translate/core';
import {EntityDirtyStoreService} from '../shared/content-renderer/services/entity-dirty-store.service';
import {ElementsStackService} from '../shared/content-renderer/services/elements-stack.service';
import {ConfirmationService} from 'primeng/primeng';
import {DataSharingService} from '../shared/content-renderer/services/data-sharing.service';
import {ModuleState, ModuleStateContext} from '../shared/content-renderer/services/module-state';
import {RequestCachingService} from '../shared/services/request-caching.service';
import {ModuleContentBlockerService} from '../core/service/module-content-blocker/module-content-blocker.service';
import {EntityValidator} from '../shared/validators/services/entity-validator';
import {ElementsStateService} from '../shared/content-renderer/services/elements-state.service';
import {
  ModuleCloseAction,
  ModuleCloseComponentAction,
  ModuleCloseService
} from '../shared/content-renderer/services/navigation/module-close.service';
import {ChangeDetectorRefHelper} from '../shared/helpers/change-detector-ref.helper';
import {ModuleChangeAction, ModuleChangeService} from '../shared/content-renderer/services/navigation/module-change.service';
import {filter} from 'rxjs/operators';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-module-content',
  styleUrls: ['./module-content.component.scss'],
  templateUrl: './module-content.component.html'
})
export class ModuleContentComponent implements OnInit, OnDestroy {
  public modulesStates: ModuleState[] = [];

  public module: Module;
  public entity: any;
  public unsubscribe = new Subject<void>();

  private subscriptions: Subscription[] = [];

  @HostListener('window:beforeunload', ['$event'])
  onClose(event) {
    if (!this.entityDirtyStore.isEmpty()) {
      const confirmMessage = this.translationService.instant('COMMON.UNSAVED_CHANGES');
      event.returnValue = confirmMessage;

      return confirmMessage;
    }
  }

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private genericCrudService: GenericCrudService,
    private modulesStateService: ModulesStateService,
    private elementsStackService: ElementsStackService,
    private elementsStateService: ElementsStateService,
    private locationService: LocationService,
    private translationService: TranslateService,
    private entityDirtyStore: EntityDirtyStoreService,
    private confirmationService: ConfirmationService,
    private dataSharingService: DataSharingService,
    private requestCachingService: RequestCachingService,
    private moduleContentBlockerService: ModuleContentBlockerService,
    private entityValidator: EntityValidator,
    private moduleCloseService: ModuleCloseService,
    private moduleChangeService: ModuleChangeService,
    public cdr: ChangeDetectorRef
  ) {

  }

  ngOnInit() {
    this.subscriptions.push(
      this.modulesStateService.moduleStateChanged$.pipe(
        filter((moduleState: ModuleState) => {
          return !moduleState.isDialog && !moduleState.isAutocompleteView && !moduleState.hasContext(ModuleStateContext.Wizard);
        })
      ).subscribe((moduleState: ModuleState) => {
          this.modulesStates = this.modulesStateService.getTabVisibleModules(moduleState.productId);

          ChangeDetectorRefHelper.detectChanges(this);
      }),
      this.route.params.subscribe((params: Params) => {
        const moduleId = +params['moduleId'],
          productId = +params['productId'];

        if (moduleId) {
          this.renderModule(params);
        }

        if (productId) {
          this.modulesStates = this.modulesStateService.getTabVisibleModules(productId);
        }

        if (!moduleId && productId) {
          this.renderFirstModule(params);
        }

        this.entityValidator.clearDictionaries().clearCache();
      })
    );
  }

  ngOnDestroy() {
    this.subscriptions.forEach(sub => sub.unsubscribe());
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  private renderFirstModule(params: Params) {
    const moduleStates = this.modulesStateService.getTabVisibleModules(+params.productId);

    if (moduleStates.length > 0) {
      this.modulesStateService.show(moduleStates[0]);
      this.module = moduleStates[0].module;
    }

    ChangeDetectorRefHelper.detectChanges(this);
  }

  private renderModule(params: Params) {
    const moduleId = +params['moduleId'],
      productId = +params['productId'],
      entityId = +this.locationService.getParam('id'),
      entityFqn = this.locationService.getParam('fqn') ?
        this.locationService.getParam('fqn').split('-').join('\\') :
        null;

    this.moduleContentBlockerService.start(this.translationService.instant('COMMON.LOADING'));

    this.unsubscribe.next();
    this.unsubscribe.complete();

    this.unsubscribe = new Subject();

    this.subscriptions.push(
      this.genericCrudService.getEntity('superadmin/modules', moduleId)
        .takeUntil(this.unsubscribe)
        .subscribe((module: Module) => {
        const isDetailsView = entityFqn !== null && !isNaN(entityId),
          entity = entityId && entityFqn ? {
            id: entityId,
            fqn: entityFqn
          } : null;

        let routePath = this.router.url;
        routePath = routePath.replace(this.locationService.getWindowLocation().search, '');

        const moduleState = new ModuleState(
          module.id,
          module,
          routePath,
          this.locationService.getQueryParams(),
          true,
          entity,
          false,
          [],
          false,
          isDetailsView,
          false, null,
          productId
        );

        this.module = moduleState.module;

        this.onTabAdd(moduleState);

        this.entity = entity;

        this.moduleContentBlockerService.stop();
        ChangeDetectorRefHelper.detectChanges(this);
      })
    );
  }

  isCurrentModule(moduleState: ModuleState) {
    return (this.module && moduleState instanceof ModuleState) ? this.module.id === moduleState.module.id : false;
  }

  onTabAdd(moduleState: ModuleState) {
    const isNewTab = this.isNewTab();

    if (!isNewTab && this.locationService.getParam('parent-module') &&
      this.modulesStateService.existsById(+this.locationService.getParam('parent-module'))
    ) {
      moduleState.setMain(this.modulesStateService.getById(+this.locationService.getParam('parent-module')));
      // this.modulesStateService.hideById(+this.locationService.getParam('parent-module'));
    }

    this.modulesStateService.add(moduleState);
  }

  onTabClose(moduleState: ModuleState) {
    this.moduleCloseService.close(moduleState, {
      componentActions: [
        ModuleCloseComponentAction.RemoveRequestCache,
        ModuleCloseComponentAction.RemoveFromElementsState,
        ModuleCloseComponentAction.RemoveFromDirtyStore,
        ModuleCloseComponentAction.SetPerformedActionClose
      ],
      moduleActions: [
        ModuleCloseAction.HideModuleDialog,
      ],
      yes: (aModuleState) => {
        this.doCloseTab(aModuleState);
      }
    });
  }

  doCloseTab(moduleState: ModuleState) {
    this.modulesStateService.remove(moduleState);

    if (moduleState.main) {
      // this.onTabClose(moduleState.main);
    }

    if (this.modulesStateService.getTabVisibleModules(moduleState.productId).length === 0) {
      this.clearTabs();
    } else {
      this.onTabChange(this.modulesStateService.getLast());
    }
  }

  onTabChange(moduleState: ModuleState) {
    this.moduleChangeService.change(moduleState, {
      moduleActions: [
        ModuleChangeAction.ClearEntityValidatorCache,
        ModuleChangeAction.HideModuleDialog,
        ModuleChangeAction.ChangeRoute
      ]
    })
  }

  clearTabs() {
    this.modulesStateService.clear();

    this.router.navigate(['']).then();
  }

  private isNewTab(): boolean {
    return this.router.getCurrentNavigation() &&
      this.router.getCurrentNavigation().extras &&
      this.router.getCurrentNavigation().extras.state &&
      this.router.getCurrentNavigation().extras.state.isNewTab === true;
  }
}
