
import {of as observableOf, Observable} from 'rxjs';
import {AbstractExecutionStep} from '../../../../core/executor/abstract-execution-step';
import {ExecutionStepStatus} from '../../../../core/executor/execution-step-status';
import {GenericDialogModuleService} from '../../../content-renderer/elements/generic-dialog/service/generic-dialog-module.service';
import {AbstractGenericGridComponent} from '../../../content-renderer/elements/abstract-generic-grid.component';
import {RuntimeFlagName} from '../../../content-renderer/services/ElementContext';

export class DisableCloseModuleDialogOnNewDialogExecutionStep extends AbstractExecutionStep {

  public doExecute(): Observable<ExecutionStepStatus> {
    const payload = this.getPayload(),
      component = payload.getValue().component ? payload.getValue().component : payload.getValue();

    if (!(component instanceof AbstractGenericGridComponent)) {
      return this.getFailObservable('You need to pass AbstractGenericGridComponent as Payload value!');
    }

    const context = component.getElementContext();

    context.addRuntimeFlag({
      name: RuntimeFlagName.DisableDialogPersistHideOnNewDialog,
      status: null,
      active: true
    });

    return observableOf({status: true, content: null});
  }

  private getDialogService(): GenericDialogModuleService {
    return this.injector.get(GenericDialogModuleService, null);
  }
}
