export interface LoggerInterface
{
    emergency(message: string, context: Object): LoggerInterface;

    alert(message: string, context: Object): LoggerInterface;

    critical(message: string, context: Object): LoggerInterface;

    error(message: string, context: Object): LoggerInterface;

    warning(message: string, context: Object): LoggerInterface;

    notice(message: string, context: Object): LoggerInterface;

    info(message: string, context: Object): LoggerInterface;

    debug(message: string, context: Object): LoggerInterface;

    log(level: any, message: string, context: Object): LoggerInterface;
}

export class Log {
    level: LogLevel;
    message: string;
    context: Object;
}

export enum LogLevel {
    Emergency = 1,
    Alert = 2,
    Critical = 3,
    Error = 4,
    Warning = 5,
    Notice = 6,
    Info = 7,
    Debug = 8
  }
