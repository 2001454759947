
    <div class="ui-g no-padding" *ngIf="field.value">
      <div class="ui-g-12">
        <p-radioButton
          label="{{ 'SIMPLE_SEARCH.REGION' | translate }}"
          name="context"
          value="region"
          [(ngModel)]="field.value.context"
        ></p-radioButton>
        <p-radioButton
          label="{{ 'SIMPLE_SEARCH.DISTANCE' | translate }}"
          name="context"
          value="distance"
          [(ngModel)]="field.value.context"
        ></p-radioButton>
        <p-radioButton
          label="{{ 'SIMPLE_SEARCH.POSTAL_CODE'| translate }}"
          name="context"
          value="postalCode"
          [(ngModel)]="field.value.context"
        ></p-radioButton>
        <p-radioButton
          *ngIf="isLeasedEmployee"
          label="{{ 'SIMPLE_SEARCH.LEASED_EMPLOYEE_REGION'| translate }}"
          name="context"
          value="leasedEmployeeRegion"
          [(ngModel)]="field.value.context"
        ></p-radioButton>
      </div>

      <div class="ui-g-12">
        <app-region-search *ngIf="field.value.context === 'region'" [component]="component" [field]="field"></app-region-search>
        <app-distance-search *ngIf="field.value.context === 'distance'" [field]="field"></app-distance-search>
        <app-postal-code-search *ngIf="field.value.context === 'postalCode'" [field]="field"></app-postal-code-search>
        <app-leased-employee-region-search
          *ngIf="field.value.context === 'leasedEmployeeRegion'"
          [field]="field"
        ></app-leased-employee-region-search>
      </div>
    </div>
  