import { AbstractExecutionStep } from '../../../core/executor/abstract-execution-step';
import { Observable } from 'rxjs';
import { ExecutionStepStatus } from '../../../core/executor/execution-step-status';
import { GenericElementAbstract } from '../../content-renderer/elements/generic-element-abstract.component';

export class ComponentRefreshExecutionStep extends AbstractExecutionStep {

    public doExecute(): Observable<ExecutionStepStatus> {
        const payload = this.getPayload();

        if (!(payload.getValue() instanceof GenericElementAbstract)) {
            return this.getFailObservable('You need to pass GenericElementAbstract as Payload value!');
        }

        return this.doRefresh(payload.getValue());
    }

    private doRefresh(component: GenericElementAbstract): Observable<ExecutionStepStatus> {

        return new Observable<ExecutionStepStatus>(observer => {
            component.onRefresh()
                .subscribe((refreshStatus: any) => {
                    this.entityDraftStoreService.clear();
                    observer.next({
                        status: true,
                        content: null
                    });
                    observer.complete();
                });
        });
    }
}
