
import {throwError as observableThrowError,  Observable } from 'rxjs';

import {tap, catchError} from 'rxjs/operators';
import { MessageGrowlService } from '../../core/message/message-growl.service';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AuthenticationService } from '../../core/authentication/authentication.service';
import { HttpErrorResponseService } from '../services/http-error-response-message.service';
import { environment } from '../../../environments';

import {
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
  HttpHandler,
  HttpEvent,
  HttpErrorResponse
} from '@angular/common/http';
import { LoggerService } from 'app/shared/content-renderer/services/logger/logger.service';
import {ToastComponentsRegistry, ToastService} from '../../core/service/toast.service';
import {DialogService} from 'primeng/api';
import {DialogErrorComponent} from '../content-renderer/elements/generic-grid/columns/dialog-error/dialog-error.component';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {

  environment: Environment;

  public constructor(
    protected messageGrowlService: MessageGrowlService,
    protected translateService: TranslateService,
    protected authenticationService: AuthenticationService,
    protected httpErrorResponseService: HttpErrorResponseService,
    protected logger: LoggerService,
    protected toastService: ToastService,
    protected dialogService: DialogService
  ) {

  }

  public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    return next
      .handle(req).pipe(
      catchError((response) => {
            if (response instanceof HttpErrorResponse) {
                if (response.status === 401) {
                  this.authenticationService.logout();
                } else if (response.status === 409 && req.url.indexOf('validate') === -1) {
                  this.handleHttpConflictResponse(response);
                } else {
                  this.handleHttpErrorResponse(req, response);
                }
            }
            return observableThrowError(response);
      }),
      tap(event => {
      }));
  }

  private handleHttpErrorResponse(req: HttpRequest<any>, response: HttpErrorResponse): void {
    const errors = this.httpErrorResponseService.getErrors(response);
    if (response.status === 512) {
      this.showErrorsDialog(errors).logErrors(req, errors);
    } else {
      this.showErrors(errors).logErrors(req, errors);
    }
  }

  private handleHttpConflictResponse(response: HttpErrorResponse): void {
    const error = response.error.errors;

    if (error instanceof Object) {
      for (const errorField in error) {
        if (error.hasOwnProperty(errorField)) {
          const fieldErrors = error[errorField];
          this.showErrors(fieldErrors);
        }
      }
    }

  }

  private showErrors(errors: string[]): this {
    this.toastService.clear(ToastComponentsRegistry.PROGRESS_BAR);
    for (const errorMessage of errors) {
      this.messageGrowlService.error(errorMessage, this.translateService.instant('COMMON.ERROR'));
    }

    return this;
  }

  private showErrorsDialog(errors: string[]): this {
    this.toastService.clear(ToastComponentsRegistry.PROGRESS_BAR);
    for (const errorMessage of errors) {
      this.dialogService.open(DialogErrorComponent, {
        header: 'Errors',
        data: {
          errorOptions: errorMessage
        },
        width: '500px'
      });
    }

    return this;
  }

  private logErrors(req: HttpRequest<any>, errors): this {

    if (environment.logging) {
      for (const errorMessage of errors) {
        this.logger.error(errorMessage.toString(), {
          'request': req
        })
      }
    }

    return this;
  }
}
