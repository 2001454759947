/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./generic-file-preview.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./types/docx/generic-file-preview-docx.component.ngfactory";
import * as i3 from "./types/docx/generic-file-preview-docx.component";
import * as i4 from "@angular/platform-browser";
import * as i5 from "./types/image/generic-file-preview-image.component.ngfactory";
import * as i6 from "./types/image/generic-file-preview-image.component";
import * as i7 from "./types/pdf/generic-file-preview-pdf.component.ngfactory";
import * as i8 from "./types/pdf/generic-file-preview-pdf.component";
import * as i9 from "./types/text/generic-file-preview-text.component.ngfactory";
import * as i10 from "./types/text/generic-file-preview-text.component";
import * as i11 from "@angular/common";
import * as i12 from "../../../../core/executor/executor.service";
import * as i13 from "../../../../core/executor/service/executor-actions/executor-actions.service";
import * as i14 from "../../services/generic/generic-element-validation-execution-steps-factory";
import * as i15 from "../../../../core/executor/factory/execution-step-factory.service";
import * as i16 from "./generic-file-preview.component";
import * as i17 from "../../services/component-highlight-stack.service";
import * as i18 from "../../services/modules-state.service";
import * as i19 from "../../../services/generic-crud.service";
import * as i20 from "../../services/entity-data-store.service";
import * as i21 from "../../../validators/services/entity-validator";
import * as i22 from "../../../../core/service/user-session.service";
import * as i23 from "../../../../core/file/file.service";
import * as i24 from "../../../services/permission/permission.service";
var styles_GenericFilePreviewComponent = [i0.styles];
var RenderType_GenericFilePreviewComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_GenericFilePreviewComponent, data: {} });
export { RenderType_GenericFilePreviewComponent as RenderType_GenericFilePreviewComponent };
function View_GenericFilePreviewComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-generic-file-preview-docx", [], null, null, null, i2.View_GenericFilePreviewDocxComponent_0, i2.RenderType_GenericFilePreviewDocxComponent)), i1.ɵdid(1, 114688, null, 0, i3.GenericFilePreviewDocxComponent, [i4.DomSanitizer], { fileData: [0, "fileData"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.fileData; _ck(_v, 1, 0, currVal_0); }, null); }
function View_GenericFilePreviewComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-generic-file-preview-image", [], null, null, null, i5.View_GenericFilePreviewImageComponent_0, i5.RenderType_GenericFilePreviewImageComponent)), i1.ɵdid(1, 114688, null, 0, i6.GenericFilePreviewImageComponent, [], { fileData: [0, "fileData"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.fileData; _ck(_v, 1, 0, currVal_0); }, null); }
function View_GenericFilePreviewComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-generic-file-preview-pdf", [], null, null, null, i7.View_GenericFilePreviewPdfComponent_0, i7.RenderType_GenericFilePreviewPdfComponent)), i1.ɵdid(1, 114688, null, 0, i8.GenericFilePreviewPdfComponent, [i4.DomSanitizer], { fileData: [0, "fileData"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.fileData; _ck(_v, 1, 0, currVal_0); }, null); }
function View_GenericFilePreviewComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-generic-file-preview-text", [], null, null, null, i9.View_GenericFilePreviewTextComponent_0, i9.RenderType_GenericFilePreviewTextComponent)), i1.ɵdid(1, 114688, null, 0, i10.GenericFilePreviewTextComponent, [], { fileData: [0, "fileData"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.fileData; _ck(_v, 1, 0, currVal_0); }, null); }
function View_GenericFilePreviewComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 10, "div", [["id", "main-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 9, "div", [["id", "file-container"]], null, null, null, null, null)), i1.ɵdid(2, 16384, null, 0, i11.NgSwitch, [], { ngSwitch: [0, "ngSwitch"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_GenericFilePreviewComponent_2)), i1.ɵdid(4, 278528, null, 0, i11.NgSwitchCase, [i1.ViewContainerRef, i1.TemplateRef, i11.NgSwitch], { ngSwitchCase: [0, "ngSwitchCase"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_GenericFilePreviewComponent_3)), i1.ɵdid(6, 278528, null, 0, i11.NgSwitchCase, [i1.ViewContainerRef, i1.TemplateRef, i11.NgSwitch], { ngSwitchCase: [0, "ngSwitchCase"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_GenericFilePreviewComponent_4)), i1.ɵdid(8, 278528, null, 0, i11.NgSwitchCase, [i1.ViewContainerRef, i1.TemplateRef, i11.NgSwitch], { ngSwitchCase: [0, "ngSwitchCase"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_GenericFilePreviewComponent_5)), i1.ɵdid(10, 278528, null, 0, i11.NgSwitchCase, [i1.ViewContainerRef, i1.TemplateRef, i11.NgSwitch], { ngSwitchCase: [0, "ngSwitchCase"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.fileData.fileType; _ck(_v, 2, 0, currVal_0); var currVal_1 = "docx"; _ck(_v, 4, 0, currVal_1); var currVal_2 = "image"; _ck(_v, 6, 0, currVal_2); var currVal_3 = "pdf"; _ck(_v, 8, 0, currVal_3); var currVal_4 = "text"; _ck(_v, 10, 0, currVal_4); }, null); }
function View_GenericFilePreviewComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Cannot find file type!\n"]))], null, null); }
export function View_GenericFilePreviewComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_GenericFilePreviewComponent_1)), i1.ɵdid(1, 16384, null, 0, i11.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_GenericFilePreviewComponent_6)), i1.ɵdid(3, 16384, null, 0, i11.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.fileData; _ck(_v, 1, 0, currVal_0); var currVal_1 = !_co.fileData.fileType; _ck(_v, 3, 0, currVal_1); }, null); }
export function View_GenericFilePreviewComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 16777216, null, null, 3, "app-generic-file-preview", [], null, [[null, "mouseover"], [null, "mouseleave"]], function (_v, en, $event) { var ad = true; if (("mouseover" === en)) {
        var pd_0 = (i1.ɵnov(_v, 3).onMouseOver($event) !== false);
        ad = (pd_0 && ad);
    } if (("mouseleave" === en)) {
        var pd_1 = (i1.ɵnov(_v, 3).onMouseLeave($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, View_GenericFilePreviewComponent_0, RenderType_GenericFilePreviewComponent)), i1.ɵprd(512, null, i12.ExecutorService, i12.ExecutorService, [i13.ExecutorActionsService]), i1.ɵprd(512, null, i14.GenericElementValidationExecutionStepsFactory, i14.GenericElementValidationExecutionStepsFactory, [i15.ExecutionStepFactoryService]), i1.ɵdid(3, 245760, null, 0, i16.GenericFilePreviewComponent, [i17.ComponentService, i1.ViewContainerRef, i18.ModulesStateService, i19.GenericCrudService, i20.EntityDataStoreService, i12.ExecutorService, i14.GenericElementValidationExecutionStepsFactory, i21.EntityValidator, i22.UserSessionService, i23.FileService, i24.PermissionService, i1.ChangeDetectorRef], null, null)], function (_ck, _v) { _ck(_v, 3, 0); }, null); }
var GenericFilePreviewComponentNgFactory = i1.ɵccf("app-generic-file-preview", i16.GenericFilePreviewComponent, View_GenericFilePreviewComponent_Host_0, { masterElementContext: "masterElementContext", moduleElement: "moduleElement", element: "element", parentComponent: "parentComponent", isDialog: "isDialog", isPart: "isPart", toolbarItems: "toolbarItems", statusBarItems: "statusBarItems", selectedMasterEntity: "selectedMasterEntity", additional: "additional", fields: "fields", masterEntity: "masterEntity", masterField: "masterField", entity: "entity", fileData: "fileData" }, { componentInstantiated: "componentInstantiated" }, []);
export { GenericFilePreviewComponentNgFactory as GenericFilePreviewComponentNgFactory };
