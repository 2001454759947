/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../../../../../../../node_modules/primeng/components/dialog/dialog.ngfactory";
import * as i2 from "primeng/components/dialog/dialog";
import * as i3 from "../../../../../../user-import/user-import.component.ngfactory";
import * as i4 from "../../../../../../user-import/user-import.component";
import * as i5 from "@ngx-translate/core";
import * as i6 from "../../../../../../../core/message/message-growl.service";
import * as i7 from "../../../../../services/entity-sharing.service";
import * as i8 from "@angular/common";
import * as i9 from "./toolbar-item-user-import.component";
import * as i10 from "../../../../../../services/generic-crud.service";
import * as i11 from "../../../../../../company-search/services/company-communication.service";
var styles_ToolbarItemUserImportComponent = [];
var RenderType_ToolbarItemUserImportComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_ToolbarItemUserImportComponent, data: {} });
export { RenderType_ToolbarItemUserImportComponent as RenderType_ToolbarItemUserImportComponent };
function View_ToolbarItemUserImportComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 5, "p-dialog", [["appendTo", "body"], ["header", ""], ["showEffect", "fade"], ["width", "1200"]], null, [[null, "visibleChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("visibleChange" === en)) {
        var pd_0 = ((_co.isDialogVisible = $event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i1.View_Dialog_0, i1.RenderType_Dialog)), i0.ɵdid(1, 180224, null, 2, i2.Dialog, [i0.ElementRef, i0.Renderer2, i0.NgZone], { visible: [0, "visible"], header: [1, "header"], modal: [2, "modal"], responsive: [3, "responsive"], appendTo: [4, "appendTo"], width: [5, "width"], minWidth: [6, "minWidth"] }, { visibleChange: "visibleChange" }), i0.ɵqud(603979776, 1, { headerFacet: 1 }), i0.ɵqud(603979776, 2, { footerFacet: 1 }), (_l()(), i0.ɵeld(4, 0, null, 1, 1, "app-user-import", [], null, null, null, i3.View_UserImportComponent_0, i3.RenderType_UserImportComponent)), i0.ɵdid(5, 245760, null, 0, i4.UserImportComponent, [i5.TranslateService, i6.MessageGrowlService, i7.EntitySharingService, i0.ChangeDetectorRef], { gridComponent: [0, "gridComponent"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.isDialogVisible; var currVal_1 = ""; var currVal_2 = true; var currVal_3 = true; var currVal_4 = "body"; var currVal_5 = "1200"; var currVal_6 = 200; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); var currVal_7 = _co.gridComponent; _ck(_v, 5, 0, currVal_7); }, null); }
export function View_ToolbarItemUserImportComponent_0(_l) { return i0.ɵvid(2, [(_l()(), i0.ɵand(16777216, null, null, 1, null, View_ToolbarItemUserImportComponent_1)), i0.ɵdid(1, 16384, null, 0, i8.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.isDialogVisible; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_ToolbarItemUserImportComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "toolbar-item-user-import", [], null, null, null, View_ToolbarItemUserImportComponent_0, RenderType_ToolbarItemUserImportComponent)), i0.ɵdid(1, 245760, null, 0, i9.ToolbarItemUserImportComponent, [i10.GenericCrudService, i6.MessageGrowlService, i5.TranslateService, i11.CompanyCommunicationService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ToolbarItemUserImportComponentNgFactory = i0.ɵccf("toolbar-item-user-import", i9.ToolbarItemUserImportComponent, View_ToolbarItemUserImportComponent_Host_0, { params: "params" }, {}, []);
export { ToolbarItemUserImportComponentNgFactory as ToolbarItemUserImportComponentNgFactory };
