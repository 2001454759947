import {Injectable} from '@angular/core';
import {TableColumn} from '../../../../../dynamic-table/shared/table-column';
import {GenericCrudService} from '../../../../../services/generic-crud.service';
import {ApiBuilderService} from '../../../../../services/api.builder.service';
import {AbstractTableService} from './abstract-table.service';
import {Entity} from '../../../../../helpers/entity';

@Injectable()
export class AssignmentTableService extends AbstractTableService {

  public constructor(
    protected genericCrudService: GenericCrudService,
    protected apiBuilderService: ApiBuilderService
  ) {
    super(genericCrudService, apiBuilderService);
  }

  protected getApiRoute(): string {
    return 'phoenix/assignments';
  }

  public getColumns(): TableColumn[] {
    return [
      {
        key: 'id',
        header: 'Id',
        filter: {},
        sort: {}
      },
      {
        key: 'leasedEmployee',
        header: 'Leased Employee',
        filter: {},
        sort: {},
        renderer: (entity) => {
          const leasedEmployee = Entity.getValue(entity, 'leasedEmployee') ||
            Entity.getValueInEmbedded(entity, 'leasedEmployee');

          if (leasedEmployee) {
            return leasedEmployee.firstName + ' ' + leasedEmployee.lastName;
          }

          return '';
        }
      },
      {
        key: 'orderPosition',
        header: 'Order Position',
        filter: {},
        sort: {},
        renderer: (entity) => {
          const orderPosition = Entity.getValue(entity, 'orderPosition') ||
            Entity.getValueInEmbedded(entity, 'orderPosition');

          if (orderPosition) {
            return orderPosition.name || orderPosition.jobPrintText;
          }

          return '';
        }
      }
    ];
  }

}
