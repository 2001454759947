import { AbstractValidator } from './abstract-validator';

export class UrlValidator extends AbstractValidator {

  public isValid(value: any): boolean {
    
    if (typeof value === 'undefined' || value === null) {
      value = '';
    }

    let isValueRequired = this.getParamValue('required') || false;

    if (!value && !isValueRequired) {
      return true;
    }

    let pattern = /^(?:(?:(?:https?|ftp):)?\/\/)?(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})?(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})).?)(?::\d{2,5})?(?:[/?#]\S*)?$/i;

    let isValid = pattern.test(value.toString());

    if (value.toString().indexOf('@') > -1) {
      isValid = false;
    }

    return isValid;
  }

  public getErrorTranslateKey(value: any): string {
    return 'URL_FORMAT_IS_NOT_VALID';
  }

  public getErrorTranslateParams(value: any): string {
    return null;
  }
}
