import {Component, OnInit} from '@angular/core';
import {AbstractPreviewFileTypeComponent} from '../abstract-preview-file-type.component';

@Component({
  selector: 'app-generic-file-preview-image',
  templateUrl: './generic-file-preview-image.component.html',
})
export class GenericFilePreviewImageComponent extends AbstractPreviewFileTypeComponent implements OnInit {

  public ngOnInit(): void {

  }
}
