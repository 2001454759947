
import {of as observableOf, forkJoin as observableForkJoin, Observable} from 'rxjs';
import {ChangeDetectionStrategy, Component} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {ToolbarItemAbstract} from '../toolbar-item-abstract.component';
import {GenericCrudService} from '../../../../../../services/generic-crud.service';
import {MessageGrowlService} from '../../../../../../../core/message/message-growl.service';
import {EntityDirtyStoreService} from '../../../../../services/entity-dirty-store.service';
import {GroupedArticle} from '../../../../../../services/precalculation/article/pre-calculation-article';
import {AbstractGenericGridComponent} from '../../../../abstract-generic-grid.component';

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'app-toolbar-item-save-grouped-article',
    template: ''
})
export class ToolbarItemSaveGroupedArticleComponent extends ToolbarItemAbstract {

  constructor(
    protected genericCrudService: GenericCrudService,
    protected messageGrowlService: MessageGrowlService,
    protected translate: TranslateService,
    protected entityDirtyStore: EntityDirtyStoreService
  ) {
    super();
  }

  public click() {
    const component = this.getComponent();

    if (component instanceof AbstractGenericGridComponent) {
      this.save(component).subscribe();
    }
  }

  public save(grid: AbstractGenericGridComponent): Observable<any> {
    const articlesToSave = [];

    for (const updatedArticle of grid.getEntities()) {
      if (updatedArticle['isChanged']) {
        articlesToSave.push(updatedArticle);
      }
    }

    return this.saveArticles(articlesToSave);
  }

  protected saveArticles(articles: GroupedArticle[]): Observable<any> {
    const updateObservers = [];

    for (const article of articles) {
      updateObservers.push(this.genericCrudService.customPut('phoenix/groupedprecalculationarticles/' + article.id, article));
    }

    if (updateObservers.length > 0) {
      return Observable.create((observer) => {
        observableForkJoin(updateObservers).subscribe(results => {
          for (const article of articles) {
            article.isChanged = false;
            this.entityDirtyStore.remove(article);
          }

          this.messageGrowlService.success(
            this.translate.instant('COMMON.DATA_SAVED'),
            this.translate.instant('COMMON.SUCCESS')
          );

          observer.next(true);
          observer.complete();
        });
      });
    }

    return observableOf({status: true, content: null});
  }
}
