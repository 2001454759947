import { AbstractFormActionHandler } from './abstract-form-action-handler';
import { Injectable } from '@angular/core';
import { DatamodelCrudService } from '../../../services/datamodel/datamodel.crud.service';
import { ElementInputDropdown } from 'app/shared/form-viewer/models/element-input-dropdown';
import { ComponentSelectedOptionAware } from 'app/shared/form-viewer/element/abstract-element.component';

@Injectable()
export class SetFieldValueFromFieldDatamodel extends AbstractFormActionHandler {

    public constructor(
        private datamodelCrudService: DatamodelCrudService
    ) {
        super();
    }

    public handleAction(): void {
        let form = this.getForm();

        if (this.isActionValid()) {
            let fromFormElementHashId = this.getParamValue('fromFormElement'),
                fromFieldElement = this.formService.getElementBy(form, 'objectHashId', fromFormElementHashId);

            let fromFieldComponent = this.getFromFieldComponent(fromFieldElement.datamodelField + '_h_r_f_e_' + fromFormElementHashId);

            if (null !== fromFieldComponent &&
                fromFieldComponent.selectedOption &&
                fromFieldComponent.selectedOption.entity
            ) {
                this.setValueFromComponent(fromFieldComponent);
            } else {
                this.setValueFromBackend();
            }
        }
    }

    private isActionValid(): boolean {
        return this.getAction().params['fromFormElement'] &&
            this.getAction().params['fromFormElement'].value &&
            this.getAction().params['toFormElement'] &&
            this.getAction().params['toFormElement'].value &&
            this.getAction().params['datamodel'] &&
            this.getAction().params['datamodelField'];
    }

    private getFromFieldComponent(formControlName: string): ComponentSelectedOptionAware|null {
        return this.formService.getComponent(formControlName);
    }

    private setValueFromComponent(component: ComponentSelectedOptionAware): void {
        let destinationField = this.getParamValue('datamodelField'),
            value = component.selectedOption.entity[destinationField];

        if (!value) {
            value = this.formService.getEntityHydrator().getEntityPropertyValue(component.selectedOption.entity, destinationField);
        }

        this.setValueToFormField(value);
    }

    private setValueFromBackend(): void {
        let fromFormElementHashId = this.getParamValue('fromFormElement'),
            datamodelId = this.getParamValue('datamodel'),
            destinationField = this.getParamValue('datamodelField'),
            fromFieldElement = this.formService.getElementBy(this.getForm(), 'objectHashId', fromFormElementHashId);

        let fromValue = this.formService.getEntityHydrator().getEntityPropertyValue(this.getEntity(), fromFieldElement.datamodelField);

        if (fromValue instanceof Object && typeof fromValue['id'] !== 'undefined') {
            fromValue = fromValue['id'];
        } else if (fromValue instanceof Object && typeof fromValue['value'] !== 'undefined') {
            fromValue = fromValue['value'];
        }

        if (datamodelId && fromValue && !(fromValue instanceof Object)) {
            this.datamodelCrudService.getDatamodelRecord(datamodelId, +fromValue).subscribe((dmRecord: any) => {
                if (dmRecord) {
                    let value = dmRecord[destinationField];

                  if (!value) {
                    value = this.formService.getEntityHydrator().getEntityPropertyValue(dmRecord, destinationField);
                  }
                    this.setValueToFormField(value);
                }
            });
        }
    }

    private setValueToFormField(value: any): void {
        let toFormElementHashId = this.getParamValue('toFormElement'),
            toFieldElement = this.formService.getElementBy(this.getForm(), 'objectHashId', toFormElementHashId);

        const prefix = toFieldElement && toFieldElement.datamodelField ? toFieldElement.datamodelField : 'no-dm';

        const toControlField = this.getFormGroup().get(prefix + '_h_r_f_e_' + toFormElementHashId);
        const toFieldComponent = this.formService.getComponent(prefix + '_h_r_f_e_' + toFormElementHashId);


        if (toFieldComponent && toControlField) {

            // element-input-dropdown.component sets values as { id: value }
            if (toFieldElement instanceof ElementInputDropdown) {
                value = { id: value };
            }

            const oldValue = toFieldElement.getValue();
            toControlField.setValue(value);

            if (toFieldComponent) {
              toFieldComponent.setValue(value, false);
            } else {
              toFieldElement.setValue(value);
            }

            if (oldValue !== value) {
              this.triggerEventOnDestinationField(toFieldElement);
            }
        }

    }

    private triggerEventOnDestinationField(element) {
      let triggerEvent = this.getParamValue('actionPropagation');

      if (triggerEvent) {
          this.formService.handleElementsAction("onInputValueChanged", element);
      }
    }
}
