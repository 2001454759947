import {Component, OnInit} from '@angular/core';
import {AbstractQuestionComponent} from '../abstract-question-component';
import {GenericCrudService} from '../../../../../../../services/generic-crud.service';
import {ExecutorService} from '../../../../../../../../core/executor/executor.service';
import {ExecutionStepFactoryService} from '../../../../../../../../core/executor/factory/execution-step-factory.service';
import {ExecutionStepPayload} from '../../../../../../../../core/executor/execution-step-payload';

@Component({
  selector: 'app-questionnaire-question-checkbox',
  template: `
    <div class="ui-g">
        <div class="ui-g-8">
          {{label}}
        </div>
        <div class="ui-g-8">
        <p-toggleButton 
          onLabel="{{trueLabel}}" 
          offLabel="{{falseLabel}}" 
          onIcon="pi pi-check" 
          offIcon="pi pi-times" 
          [style]="{'width':'150px'}"
          (onChange)="onChange($event)"
        ></p-toggleButton>
        </div>
    </div>
  `,
  styles: [`
    :host {
      width: 100%;
    }
  `],
  providers: [ ExecutorService ]
})
export class CheckboxQuestionComponent extends AbstractQuestionComponent implements OnInit {

  public label = '';
  public trueLabel = '';
  public falseLabel = '';

  public constructor(
    protected genericCrudService: GenericCrudService,
    protected executorService: ExecutorService,
    protected stepFactory: ExecutionStepFactoryService
  ) {
    super();
  }

  public ngOnInit(): void {
    super.ngOnInit();

    this.onComponentInit();
  }

  public onComponentInit(): void {
    this.label = this.getParamValue('label');
    this.trueLabel = this.getParamValue('trueLabel');
    this.falseLabel = this.getParamValue('falseLabel');
  }

  public onChange(value): void {
    const onChange = this.getParamValue('onChangeAction');

    if (onChange !== null) {
      const step = this.stepFactory.createFromString(onChange, new ExecutionStepPayload({
        questionComponent: this,
        value: value
      }));

      this.executorService.setSteps([step]).execute().subscribe();
    }
  }
}
