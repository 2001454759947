/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./toolbar-item-copy-new-external-cronjob.component";
import * as i2 from "../../../../../../../services/generic-crud.service";
import * as i3 from "../../../../../../../../core/message/message-growl.service";
import * as i4 from "@ngx-translate/core";
import * as i5 from "../../../../../generic-dialog/service/generic-dialog-module.service";
import * as i6 from "../../../../../../../../core/service/toast.service";
var styles_ToolbarItemCopyNewExternalCronjobComponent = [];
var RenderType_ToolbarItemCopyNewExternalCronjobComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_ToolbarItemCopyNewExternalCronjobComponent, data: {} });
export { RenderType_ToolbarItemCopyNewExternalCronjobComponent as RenderType_ToolbarItemCopyNewExternalCronjobComponent };
export function View_ToolbarItemCopyNewExternalCronjobComponent_0(_l) { return i0.ɵvid(2, [], null, null); }
export function View_ToolbarItemCopyNewExternalCronjobComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-toolbar-item-copy-new-external-cronjob", [], null, null, null, View_ToolbarItemCopyNewExternalCronjobComponent_0, RenderType_ToolbarItemCopyNewExternalCronjobComponent)), i0.ɵdid(1, 245760, null, 0, i1.ToolbarItemCopyNewExternalCronjobComponent, [i2.GenericCrudService, i3.MessageGrowlService, i4.TranslateService, i5.GenericDialogModuleService, i6.ToastService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ToolbarItemCopyNewExternalCronjobComponentNgFactory = i0.ɵccf("app-toolbar-item-copy-new-external-cronjob", i1.ToolbarItemCopyNewExternalCronjobComponent, View_ToolbarItemCopyNewExternalCronjobComponent_Host_0, { params: "params" }, {}, []);
export { ToolbarItemCopyNewExternalCronjobComponentNgFactory as ToolbarItemCopyNewExternalCronjobComponentNgFactory };
