import {Directive} from '@angular/core';
import {DomHandler, TTEditableColumn} from 'primeng/primeng';
import {Constants} from '../../../../../constants';

@Directive({
  selector: '[appTreeEditableColumn]',
  providers: [TTEditableColumn],
  host: {
    '(keydown)': 'onKeyDown($event)'
  },
})
export class TreeEditableColumnDirective extends TTEditableColumn {

  public onKeyDown(event: KeyboardEvent): void {
    if (this.isEnabled()) {

      // enter
      if (event.keyCode === 13) {
        this.switchToNextEntityCell(event);
        event.preventDefault();

      // escape
      } else if (event.keyCode === 27) {
        if (this.tt.isEditingCellValid()) {
          DomHandler.removeClass(this.tt.editingCell, 'ui-editing-cell');
          this.tt.editingCell = null;
          this.tt.onEditCancel.emit({ field: this.field, data: this.data });
        }

        event.preventDefault();

      // tab
      } else if (event.keyCode === 9) {
        this.tt.onEditComplete.emit({ field: this.field, data: this.data });

        if (event.shiftKey) {
          this.moveToPreviousCell(event);
        } else {
          this.moveToNextCell(event);
        }
      }
    }
  }

  findPreviousEditableColumn(cell: Element) {
    let prevCell = cell.previousElementSibling;

    if (!prevCell) {
      const previousRow = cell.parentElement.previousElementSibling;
      if (previousRow) {
        prevCell = previousRow.lastElementChild;
      }
    }

    if (prevCell) {
      if (!DomHandler.hasClass(prevCell, Constants.UI_CELL_DISABLED_FLAG) &&
        DomHandler.hasClass(prevCell, 'ui-editable-column')
      ) {
        return prevCell;
      } else {
        return this.findPreviousEditableColumn(prevCell);
      }
    } else {
      return null;
    }
  }

  findNextEditableColumn(cell: Element) {
    let nextCell = cell.nextElementSibling;

    if (!nextCell) {
      const nextRow = cell.parentElement.nextElementSibling;
      if (nextRow) {
        nextCell = nextRow.firstElementChild;
      }
    }

    if (nextCell) {
      if (!DomHandler.hasClass(nextCell, Constants.UI_CELL_DISABLED_FLAG) &&
        DomHandler.hasClass(nextCell, 'ui-editable-column')
      ) {
        return nextCell;
      } else {
        return this.findNextEditableColumn(nextCell);
      }
    } else {
      return null;
    }
  }

  private switchToNextEntityCell(event): void {
    const currentCell = this.findCell(event.target);

    if (currentCell) {
      const targetCell = this.findNextEntitySameEditableColumn(currentCell);

      if (targetCell) {
        DomHandler.invokeElementMethod(targetCell, 'click');
      }
    }
  }

  private findNextEntitySameEditableColumn(editingCell): any {
    let nextEditingCell = null;

    const tr = editingCell.closest('tr'),
      nextTr = tr.nextElementSibling;

    const currentTds = Array.prototype.slice.call( tr.getElementsByTagName('td') ),
      columnIndex = currentTds.indexOf( editingCell );

    if (nextTr && nextTr.getElementsByTagName('td')) {
      nextEditingCell = nextTr.getElementsByTagName('td')[columnIndex];
    }

    return nextEditingCell;
  }
}
