import {WorkHourCalculatorAbstract} from './work-hour-calculator-abstract';
import {DateHelper} from '../../../../../helpers/date.helper';

export class WorkHourCalculatorNormal extends WorkHourCalculatorAbstract {

  public calculateWorkEnd(calculateBy: 'workLengthIndustry'|'workLength'): any {

    if (calculateBy === 'workLengthIndustry' && !this.isIndustrialTime) {
      this.entity['workLength'] = DateHelper.convertFromDecimalToTime(this.entity['workLengthIndustry']);
    }

    const startIndustry = this.entity['workStartIndustry'] || 0,
      breakTimeIndustry = this.entity['workBreakIndustry'] || 0;

    this.entity['workEnd2Industry'] = this.calculateWorkEndIndustryNormal(
      startIndustry,
      this.entity['workLengthIndustry'] || 0,
      breakTimeIndustry
    );

    const start = this.entity['workStart'] || '00:00',
      breakTime = this.entity['workBreak'] || '00:00';

    this.entity['workEnd2'] = this.calculateWorkEndNormal(start, this.entity['workLength'] || '00:00', breakTime);

    return this.entity;
  }

  public calculateFields(): any {
    if (this.isIndustrialTime) {
      const start = this.entity['workStartIndustry'] || 0,
        end = this.entity['workEnd2Industry'] || 0,
        breakTime = this.entity['workBreakIndustry'] || 0;
      this.calculateLengthIndustryNormal(start, end, breakTime);
    } else {
      const start = this.entity['workStart'] || '00:00',
        end = this.entity['workEnd2'] || '00:00',
        breakTime = this.entity['workBreak'] || '00:00';
      this.calculateLengthNormally(start, end, breakTime);
    }

    return this.entity;
  }

  private calculateLengthIndustryNormal(start, end, breakTime) {
    if (start > end) {
      length = 24 - start + end - breakTime;
    } else {
      length = end - start - breakTime;
    }
    length = Math.round(length * 1000000) / 1000000;

    const preBreakLength = length / 2.0;

    let preBreakEnd = start + preBreakLength;
    if (preBreakEnd > 24) {
      preBreakEnd -= 24;
    }
    let postBreakStart = preBreakEnd + breakTime;
    if (postBreakStart > 24) {
      postBreakStart -= 24;
    }

    this.entity['workEnd'] = DateHelper.convertFromDecimalToTime(preBreakEnd);
    this.entity['workEndIndustry'] = preBreakEnd;
    this.entity['workStart2'] = DateHelper.convertFromDecimalToTime(postBreakStart);
    this.entity['workStart2Industry'] = postBreakStart;
    this.entity['workStartIndustry'] = start;
    this.entity['workEnd2Industry'] = end;

    this.entity['workLengthIndustry'] = length;
    this.entity['workLength'] = DateHelper.convertFromDecimalToTime(this.entity['workLengthIndustry']);

    return this;
  }


  private calculateLengthNormally(start: string, end: string, breakT: string) {
    const startTime = DateHelper.convertFromTimeToDecimal(start),
      endTime = DateHelper.convertFromTimeToDecimal(end),
    breakTime = DateHelper.convertFromTimeToDecimal(breakT);

    this.calculateLengthIndustryNormal(startTime, endTime, breakTime);

    // Override for the duration:
    this.entity['workLength'] = this.calculateLength(start, end, breakT);

    return this;
  }

  private calculateWorkEndIndustryNormal(start, workLength, breakTime): number {
    let end = start + workLength + breakTime;

    if (end > 24) {
      end -= 24;
    }

    return end;
  }

  private calculateWorkEndNormal(startTime, workLengthTime, breakTimeTime): string {
    const start = DateHelper.convertFromTimeToDecimal(startTime),
      workLength = DateHelper.convertFromTimeToDecimal(workLengthTime),
      breakTime = DateHelper.convertFromTimeToDecimal(breakTimeTime);

    let end = start + workLength + breakTime;

    if (end > 24) {
      end -= 24;
    }

    return DateHelper.convertFromDecimalToTime(end);
  }
}

