
import {of as observableOf, Observable} from 'rxjs';
import {AbstractExecutionStep} from '../../../../core/executor/abstract-execution-step';
import {ExecutionStepStatus} from '../../../../core/executor/execution-step-status';
import {AbstractGenericGridComponent} from '../../../content-renderer/elements/abstract-generic-grid.component';
import {Entity} from '../../../helpers/entity';
import {ChangeDetectorRefHelper} from '../../../helpers/change-detector-ref.helper';

export class WorkHourDisableFieldsExecutionStep extends AbstractExecutionStep {

  public doExecute(): Observable<ExecutionStepStatus> {
    const payload = this.getPayload(),
      component = payload.getValue().component ? payload.getValue().component : payload.getValue();

    if (!(component instanceof AbstractGenericGridComponent)) {
      return this.getFailObservable('You need to pass AbstractGenericGridComponent as Payload value!');
    }

    return this.colorAndDisableFields(component);
  }

  private colorAndDisableFields(grid: AbstractGenericGridComponent): Observable<ExecutionStepStatus> {
    for (const entity of grid.entities) {
      const doLock = !!Entity.getValue(entity, 'isLocked') || entity.isWageSettled || entity.isInvoiced;

      if (entity.planningWorkLengthColor) {
        entity['cssColor'] = 'color-' + entity.planningWorkLengthColor;
      }

      if (entity.isWageSettled) {
        entity['cssColor'] = 'color-darkviolet';
      }

      if (entity.isInvoiced) {
        entity['cssColor'] = 'color-gray';
      }

      if (entity.isWageSettled && entity.isInvoiced) {
        entity['cssColor'] = 'color-pink';
      }

      for (const fieldToLock of grid.fields) {
        fieldToLock.isReadOnlyByEntity = fieldToLock.isReadOnlyByEntity || {};

        fieldToLock.isReadOnlyByEntity[entity.id] = doLock;
      }
    }

    ChangeDetectorRefHelper.detectChanges(grid);

    return observableOf({status: true, content: null});
  }
}
