<p-dialog
        *ngIf="isDialogVisible"
        [(visible)]="isDialogVisible"
        header="Export"
        showEffect="fade"
        [width]="800"
        appendTo="body"
>
    <div *ngFor="let clause of clauses; let i = index">
        <div class="ui-grid-row">
            <div class="ui-grid-col-4"><label for="clause{{i}}">{{ clause.name }}</label></div>
            <div class="ui-grid-col-8"><input pInputText id="clause{{i}}" [(ngModel)]="clauseValues[clause.code]" /></div>
        </div>
    </div>

    <button type="button" pButton icon="fa fa-plus" (click)="doExport()"></button>
</p-dialog>