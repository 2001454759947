/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./generic-file-preview-text.component";
var styles_GenericFilePreviewTextComponent = [];
var RenderType_GenericFilePreviewTextComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_GenericFilePreviewTextComponent, data: {} });
export { RenderType_GenericFilePreviewTextComponent as RenderType_GenericFilePreviewTextComponent };
export function View_GenericFilePreviewTextComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵted(-1, null, ["TEXT\n"]))], null, null); }
export function View_GenericFilePreviewTextComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-generic-file-preview-text", [], null, null, null, View_GenericFilePreviewTextComponent_0, RenderType_GenericFilePreviewTextComponent)), i0.ɵdid(1, 114688, null, 0, i1.GenericFilePreviewTextComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var GenericFilePreviewTextComponentNgFactory = i0.ɵccf("app-generic-file-preview-text", i1.GenericFilePreviewTextComponent, View_GenericFilePreviewTextComponent_Host_0, { fileData: "fileData" }, {}, []);
export { GenericFilePreviewTextComponentNgFactory as GenericFilePreviewTextComponentNgFactory };
