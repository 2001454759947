import {ConditionInterface} from './condition.interface';
import {JobContext} from '../context/job.context';
import {PayloadInterface} from '../payload/payload.interface';

export abstract class AbstractCondition implements ConditionInterface {
  payload: PayloadInterface;
  identifier: string;

  getPayload() {
    return this.payload;
  }

  setPayload(payload: PayloadInterface) {
    this.payload = payload;
  }

  abstract isApplicable(context: JobContext): boolean;
}
