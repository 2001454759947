/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./questionnaire-question.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../../core/questionnaire/question-component-factory.service";
import * as i3 from "./questionnaire-question.component";
var styles_QuestionnaireQuestionComponent = [i0.styles];
var RenderType_QuestionnaireQuestionComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_QuestionnaireQuestionComponent, data: {} });
export { RenderType_QuestionnaireQuestionComponent as RenderType_QuestionnaireQuestionComponent };
function View_QuestionnaireQuestionComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(0, null, null, 0))], null, null); }
export function View_QuestionnaireQuestionComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(671088640, 1, { questionContainer: 0 }), (_l()(), i1.ɵeld(1, 0, null, null, 2, "div", [["class", "ui-g"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "div", [["class", "ui-g-12"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, [[1, 3], ["questionContainer", 2]], null, 0, null, View_QuestionnaireQuestionComponent_1))], null, null); }
export function View_QuestionnaireQuestionComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "app-custom-questionnaire-question", [], null, null, null, View_QuestionnaireQuestionComponent_0, RenderType_QuestionnaireQuestionComponent)), i1.ɵprd(512, null, i2.QuestionComponentFactoryService, i2.QuestionComponentFactoryService, [i1.ComponentFactoryResolver]), i1.ɵdid(2, 114688, null, 0, i3.QuestionnaireQuestionComponent, [i2.QuestionComponentFactoryService], null, null)], function (_ck, _v) { _ck(_v, 2, 0); }, null); }
var QuestionnaireQuestionComponentNgFactory = i1.ɵccf("app-custom-questionnaire-question", i3.QuestionnaireQuestionComponent, View_QuestionnaireQuestionComponent_Host_0, { questionnaire: "questionnaire", question: "question", questionnaireComponent: "questionnaireComponent" }, {}, []);
export { QuestionnaireQuestionComponentNgFactory as QuestionnaireQuestionComponentNgFactory };
