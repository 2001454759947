/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./toolbar-item-entity-import.component";
import * as i2 from "../../../../../../../core/message/message-growl.service";
import * as i3 from "../../../../../services/double-click.service";
import * as i4 from "../../../../../../services/generic-crud.service";
import * as i5 from "../../../../../../services/request-caching.service";
import * as i6 from "../../../../generic-dialog/service/generic-dialog-module.service";
import * as i7 from "../../../../../services/modules-state.service";
import * as i8 from "../../../../../services/navigation/module-navigation.service";
var styles_ToolbarItemEntityImport = [];
var RenderType_ToolbarItemEntityImport = i0.ɵcrt({ encapsulation: 2, styles: styles_ToolbarItemEntityImport, data: {} });
export { RenderType_ToolbarItemEntityImport as RenderType_ToolbarItemEntityImport };
export function View_ToolbarItemEntityImport_0(_l) { return i0.ɵvid(2, [], null, null); }
export function View_ToolbarItemEntityImport_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "toolbar-item-entity-import", [], null, null, null, View_ToolbarItemEntityImport_0, RenderType_ToolbarItemEntityImport)), i0.ɵdid(1, 245760, null, 0, i1.ToolbarItemEntityImport, [i2.MessageGrowlService, i3.DoubleClickService, i4.GenericCrudService, i5.RequestCachingService, i6.GenericDialogModuleService, i7.ModulesStateService, i8.ModuleNavigationService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ToolbarItemEntityImportNgFactory = i0.ɵccf("toolbar-item-entity-import", i1.ToolbarItemEntityImport, View_ToolbarItemEntityImport_Host_0, { params: "params" }, {}, []);
export { ToolbarItemEntityImportNgFactory as ToolbarItemEntityImportNgFactory };
