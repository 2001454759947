import {Injectable} from '@angular/core';
import {AbstractGenericElementEntityService} from './abstract-generic-element-entity.service';
import {EntityDirtyStoreService} from 'app/shared/content-renderer/services/entity-dirty-store.service';
import {MessageGrowlService} from 'app/core/message/message-growl.service';
import {TranslateService} from '@ngx-translate/core';
import {LoggerService} from 'app/shared/content-renderer/services/logger/logger.service';
import {EntityDataStoreService} from '../../entity-data-store.service';
import {EntityStatus} from '../../../../services/entity/entity-status';
import {EntityManagerService} from '../../../../../core/service/entity-manager/entity-manager.service';

@Injectable()
export class GenericGridEntityService extends AbstractGenericElementEntityService {

  public constructor(protected entityDirtyStore: EntityDirtyStoreService,
                     protected entityDataStore: EntityDataStoreService,
                     protected messageGrowlService: MessageGrowlService,
                     protected translationService: TranslateService,
                     protected logger: LoggerService,
                     protected entityManager: EntityManagerService) {
    super(entityDirtyStore, entityDataStore, messageGrowlService, translationService, logger, entityManager);
  }

  public addEntity(entity: any): void {
    this.component.entities = [...this.component.entities, entity];
  }

  public getEntities(): Array<any> {
    return this.component.entities;
  }

  public removeEntity(entity: any): void {
    let index = this.getEntityIndex(entity);

    if (index !== -1) {
      this.component.entities.splice(index, 1);
    }

    this.refresh();
  }

  public getUpdatedEntities(shelved: boolean = false): any[] {
    if (shelved && this.component.updatedEntities.length > 0) {
      return this.component.updatedEntities;
    }

    return this.component.entities.filter((entity) => {
      return entity['id'] && entity[EntityStatus.ENTITY_CHANGED_FLAG] && !entity.isSummary;
    });
  }

  public getCreatedEntities(shelved: boolean = false): any[] {
    if (shelved && this.component.createdEntities.length > 0) {
      return this.component.createdEntities;
    }

    return this.component.entities.filter((entity) => {
      return !entity['id'] && !entity.isSummary;
    });
  }

  public getDraftDeletedEntities(shelved: boolean = false): any[] {
    if (shelved && this.component.draftDeletedEntities.length > 0) {
      return this.component.draftDeletedEntities;
    }

    return this.component.entities.filter((entity) => {
      return entity[EntityStatus.ENTITY_DRAFT_DELETED_FLAG] && !entity.isSummary;
    });
  }


  public getEmbeddedEntitiesChanged(): any[] {
    return this.component.entities.filter((entity) => {
      return entity['id']
        && !entity[EntityStatus.ENTITY_CHANGED_FLAG]
        && entity[EntityStatus.EMBEDDED_ENTITY_CHANGED_FLAG]
        && !entity.isSummary;
    });
  }

  public findEntity(entity: any): any {
    let foundEntity = null;

    for (const componentEntity of this.component.entities) {
      if ((entity.id && entity.id === componentEntity.id)
        || (!entity.id && entity === componentEntity)
        || (!entity.id
          && entity[EntityStatus.ENTITY_DRAFT_FLAG] === componentEntity[EntityStatus.ENTITY_DRAFT_FLAG])) {
        foundEntity = componentEntity;
        break;
      }
    }

    return foundEntity;
  }

  public findEntityById(entityId: number): any {
    for (const entity of this.component.entities) {
      if (entity.id === entityId) {
        return entity;
      }
    }

    return null;
  }

  public addShelved(): void {
    for (const createdEntity of this.getCreatedEntities(true)) {
      this.replaceEntity(createdEntity);
    }

    for (const updatedEntity of this.getUpdatedEntities(true)) {
      this.replaceEntity(updatedEntity);
    }
  }
}
