import {of as observableOf, Observable} from 'rxjs';
import {AbstractExecutionStep} from '../../../../core/executor/abstract-execution-step';
import {ExecutionStepStatus} from '../../../../core/executor/execution-step-status';
import {AbstractGenericGridComponent} from '../../../content-renderer/elements/abstract-generic-grid.component';
import {SetupWorkHourPeriodEntryGridComponentCondition} from '../../../job-runner/condition/work-hour/setup-work-hour-period-entry-grid-component.condition';
import {SetupWorkHourPeriodEntryGridComponentJob} from '../../../job-runner/job/work-hour/setup-work-hour-period-entry-grid-component.job';
import {JobContext} from '../../../../core/job-runner/context/job.context';
import {ExecutionStepFactoryService} from '../../../../core/executor/factory/execution-step-factory.service';

export class SetupWorkHourPeriodGridExecutionStep extends AbstractExecutionStep {

  public doExecute(): Observable<ExecutionStepStatus> {
    const payload = this.getPayload(),
      component = payload.getValue();

    if (component instanceof AbstractGenericGridComponent) {
      return this.doSetup(component);
    }

    return this.getFailObservable('You need to pass AbstractGenericGridComponent or FormViewerComponent as Payload value!');
  }

  protected doSetup(component: AbstractGenericGridComponent) {
    component.embeddedFields = [...component.embeddedFields, ['assignment,subAssignment,period,leasedEmployee']];
    setTimeout(() => {
      const condition = new SetupWorkHourPeriodEntryGridComponentCondition();
      condition.setPayload({component: component});

      const job = new SetupWorkHourPeriodEntryGridComponentJob(component.getExecutor(), this.getStepsFactory());
      const context = new JobContext();
      context.component = component;

      if (condition.isApplicable(context)) {
        job.run(context, condition).subscribe();
      }
    }, 1000)
    return observableOf({status: true, content: []});
  }

  protected getStepsFactory(): ExecutionStepFactoryService|null {
    return this.injector.get(ExecutionStepFactoryService, null);
  }
}
