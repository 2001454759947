/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./container-field/inline/search-management-inline-container-field.component.ngfactory";
import * as i2 from "./container-field/inline/search-management-inline-container-field.component";
import * as i3 from "@angular/common";
import * as i4 from "@angular/flex-layout/flex";
import * as i5 from "@angular/flex-layout/core";
import * as i6 from "primeng/components/dom/domhandler";
import * as i7 from "primeng/components/button/button";
import * as i8 from "@ngx-translate/core";
import * as i9 from "../../../../../p-button/p-button.directive";
import * as i10 from "../../../../../../../../node_modules/primeng/components/dialog/dialog.ngfactory";
import * as i11 from "primeng/components/dialog/dialog";
import * as i12 from "./container-field/edit/search-management-edit-container-field.component.ngfactory";
import * as i13 from "./container-field/edit/search-management-edit-container-field.component";
import * as i14 from "./search-management-edit-container.component";
var styles_SearchManagementEditContainerComponent = [".ui-dialog-content {\n      height: calc(100% - 38px);\n    }\n    \n    .add-field-button[_ngcontent-%COMP%] {\n      height: 87%;\n      top: 2px;\n      left: 2px;\n    }"];
var RenderType_SearchManagementEditContainerComponent = i0.ɵcrt({ encapsulation: 0, styles: styles_SearchManagementEditContainerComponent, data: {} });
export { RenderType_SearchManagementEditContainerComponent as RenderType_SearchManagementEditContainerComponent };
function View_SearchManagementEditContainerComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "app-search-management-inline-container-field", [], null, [[null, "onOpenEditField"], [null, "onFieldRemove"], [null, "onContainerRemove"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("onOpenEditField" === en)) {
        var pd_0 = (_co.onEditField($event) !== false);
        ad = (pd_0 && ad);
    } if (("onFieldRemove" === en)) {
        var pd_1 = (_co.onFieldRemove($event) !== false);
        ad = (pd_1 && ad);
    } if (("onContainerRemove" === en)) {
        var pd_2 = (_co.onContainerRemove($event) !== false);
        ad = (pd_2 && ad);
    } return ad; }, i1.View_SearchManagementInlineContainerFieldComponent_0, i1.RenderType_SearchManagementInlineContainerFieldComponent)), i0.ɵdid(2, 49152, null, 0, i2.SearchManagementInlineContainerFieldComponent, [], { expertSearch: [0, "expertSearch"], container: [1, "container"], field: [2, "field"] }, { onFieldRemove: "onFieldRemove", onOpenEditField: "onOpenEditField", onContainerRemove: "onContainerRemove" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.expertSearch; var currVal_1 = _co.container; var currVal_2 = _v.parent.context.$implicit; _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2); }, null); }
function View_SearchManagementEditContainerComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵand(16777216, null, null, 1, null, View_SearchManagementEditContainerComponent_3)), i0.ɵdid(1, 16384, null, 0, i3.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(0, null, null, 0))], function (_ck, _v) { var currVal_0 = !_v.context.$implicit.isFlaggedForBackendDelete; _ck(_v, 1, 0, currVal_0); }, null); }
function View_SearchManagementEditContainerComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 11, "div", [["fxLayout", "row wrap"], ["fxLayoutAlign", "start stretch"]], null, null, null, null, null)), i0.ɵdid(1, 671744, null, 0, i4.DefaultLayoutDirective, [i0.ElementRef, i5.StyleUtils, [2, i4.LayoutStyleBuilder], i5.MediaMarshaller], { fxLayout: [0, "fxLayout"] }, null), i0.ɵdid(2, 671744, null, 0, i4.DefaultLayoutAlignDirective, [i0.ElementRef, i5.StyleUtils, [2, i4.LayoutAlignStyleBuilder], i5.MediaMarshaller], { fxLayoutAlign: [0, "fxLayoutAlign"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_SearchManagementEditContainerComponent_2)), i0.ɵdid(4, 278528, null, 0, i3.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i0.ɵeld(5, 0, null, null, 6, "div", [], null, null, null, null, null)), (_l()(), i0.ɵeld(6, 0, null, null, 5, "button", [["class", "add-field-button"], ["icon", "fa fa-plus"], ["pButton", ""]], [[8, "disabled", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onAddField($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵprd(4608, null, i6.DomHandler, i6.DomHandler, []), i0.ɵdid(8, 4341760, null, 0, i7.ButtonDirective, [i0.ElementRef], { label: [0, "label"], icon: [1, "icon"] }, null), i0.ɵpid(131072, i8.TranslatePipe, [i8.TranslateService, i0.ChangeDetectorRef]), i0.ɵdid(10, 4341760, null, 0, i9.PButtonDirective, [i0.ElementRef], { label: [0, "label"], icon: [1, "icon"] }, null), i0.ɵpid(131072, i8.TranslatePipe, [i8.TranslateService, i0.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_0 = "row wrap"; _ck(_v, 1, 0, currVal_0); var currVal_1 = "start stretch"; _ck(_v, 2, 0, currVal_1); var currVal_2 = _co.container.fields; _ck(_v, 4, 0, currVal_2); var currVal_4 = i0.ɵinlineInterpolate(1, "", i0.ɵunv(_v, 8, 0, i0.ɵnov(_v, 9).transform("COMMON.AND")), ""); var currVal_5 = "fa fa-plus"; _ck(_v, 8, 0, currVal_4, currVal_5); var currVal_6 = i0.ɵinlineInterpolate(1, "", i0.ɵunv(_v, 10, 0, i0.ɵnov(_v, 11).transform("COMMON.AND")), ""); var currVal_7 = "fa fa-plus"; _ck(_v, 10, 0, currVal_6, currVal_7); }, function (_ck, _v) { var _co = _v.component; var currVal_3 = !_co.container; _ck(_v, 6, 0, currVal_3); }); }
function View_SearchManagementEditContainerComponent_4(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 6, "p-dialog", [["appendTo", "body"]], null, [[null, "visibleChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("visibleChange" === en)) {
        var pd_0 = ((_co.isEditFieldVisible = $event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i10.View_Dialog_0, i10.RenderType_Dialog)), i0.ɵdid(1, 180224, null, 2, i11.Dialog, [i0.ElementRef, i0.Renderer2, i0.NgZone], { visible: [0, "visible"], modal: [1, "modal"], responsive: [2, "responsive"], appendTo: [3, "appendTo"], style: [4, "style"], width: [5, "width"] }, { visibleChange: "visibleChange" }), i0.ɵqud(603979776, 1, { headerFacet: 1 }), i0.ɵqud(603979776, 2, { footerFacet: 1 }), i0.ɵpod(4, { minWidth: 0 }), (_l()(), i0.ɵeld(5, 0, null, 1, 1, "app-search-management-edit-container-field", [], null, [[null, "onSave"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("onSave" === en)) {
        var pd_0 = (_co.onSaveField($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i12.View_SearchManagementEditContainerFieldComponent_0, i12.RenderType_SearchManagementEditContainerFieldComponent)), i0.ɵdid(6, 114688, null, 0, i13.SearchManagementEditContainerFieldComponent, [], { expertSearch: [0, "expertSearch"], container: [1, "container"], field: [2, "field"] }, { onSave: "onSave" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.isEditFieldVisible; var currVal_1 = true; var currVal_2 = true; var currVal_3 = "body"; var currVal_4 = _ck(_v, 4, 0, "500px"); var currVal_5 = 800; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5); var currVal_6 = _co.expertSearch; var currVal_7 = _co.container; var currVal_8 = _co.field; _ck(_v, 6, 0, currVal_6, currVal_7, currVal_8); }, null); }
export function View_SearchManagementEditContainerComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵand(16777216, null, null, 1, null, View_SearchManagementEditContainerComponent_1)), i0.ɵdid(1, 16384, null, 0, i3.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_SearchManagementEditContainerComponent_4)), i0.ɵdid(3, 16384, null, 0, i3.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.container && !_co.container.isFlaggedForBackendDelete); _ck(_v, 1, 0, currVal_0); var currVal_1 = _co.isEditFieldVisible; _ck(_v, 3, 0, currVal_1); }, null); }
export function View_SearchManagementEditContainerComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-search-management-edit-container", [], null, null, null, View_SearchManagementEditContainerComponent_0, RenderType_SearchManagementEditContainerComponent)), i0.ɵdid(1, 114688, null, 0, i14.SearchManagementEditContainerComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var SearchManagementEditContainerComponentNgFactory = i0.ɵccf("app-search-management-edit-container", i14.SearchManagementEditContainerComponent, View_SearchManagementEditContainerComponent_Host_0, { expertSearch: "expertSearch", container: "container", field: "field" }, { onChange: "onChange" }, []);
export { SearchManagementEditContainerComponentNgFactory as SearchManagementEditContainerComponentNgFactory };
