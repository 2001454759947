
import {publishReplay, map, refCount, catchError} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';


import { Language } from './language';
import { AppHttpService } from '../../../app-http.service';
import { Constants } from 'app/constants';
import {HttpClient} from '@angular/common/http';

@Injectable()
export class LanguageCrudService extends AppHttpService {

  private cachedLanguages = null;
  private languagesUrl: string = `/${Constants.APP_API_ROUTE}/languages`;
  private url: string = '';

  constructor(private http: HttpClient) {
    super();
    this.url = this.getApiUrl() + this.languagesUrl;
  }

  /**
   * @argument {string} format Optional. If not set, then the entities are returned as is.
   *                            Selectable formats: formenu (MenuItem), fortree (TreeNode).
   */
  getLanguages(): Observable<Language[]> {
    const url = this.url;
    return this.http.get(url).pipe(
      map(this.mapResponseAsJson),
      publishReplay(1),
      refCount(),
      catchError(this.handleError));
  }

  /**
   * @argument {number} languageId The id of the language to request.
   * @argument {string} format Optional. If not set, then the entities are returned as is.
   *                            Selectable formats: formenu (MenuItem), fortree (TreeNode).
   */
  getLanguage(id: number): Observable<Language> {
    const url = this.url + `/${id}`;
    return this.http.get(url).pipe(
      map(this.mapResponseAsJson),
      publishReplay(1),
      refCount(),
      catchError(this.handleError));
  }

}
