import {AbstractExecutionStep} from '../../../../core/executor/abstract-execution-step';
import {Observable} from 'rxjs';
import { ExecutionStepStatus } from '../../../../core/executor/execution-step-status';
import { GenericElementAbstract } from '../../../content-renderer/elements/generic-element-abstract.component';
import { AbstractGenericGridComponent } from '../../../content-renderer/elements/abstract-generic-grid.component';
import { FormViewerComponent } from '../../../form-viewer/form-viewer.component';
import { ModuleState } from '../../../content-renderer/services/module-state';

export class ComponentsHaveChangesExecutionStep extends AbstractExecutionStep {

    public doExecute(): Observable<ExecutionStepStatus> {
        const components = this.getPayload().getValue();

        if (!(components instanceof Array)) {
            return this.getFailObservable('You need to pass Components array as Payload value!');
        }

        return this.doHaveChanges(components);
    }

    private doHaveChanges(components: GenericElementAbstract[] = []) {
        return Observable.create(observer => {
            let hasChanges = false;

            for (let component of components) {

                if (component instanceof AbstractGenericGridComponent) {
                    hasChanges = component.hasChanges(false);
                }

                if (component instanceof FormViewerComponent) {
                    hasChanges = component.isEntityDirty();
                }

                if (hasChanges) {
                    break;
                }
            }

            observer.next({
                status: hasChanges,
                content: ''
            });
            observer.complete();
        });
    }


}
