import { EditComponent } from './edit/edit.component';
import { FormEditorComponent } from './form-editor.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';

import { ListComponent } from './list/list.component';

import { FormEditorDetailResolve } from './form-editor-detail-resolve.service';

const routes: Routes = [
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: []
})
export class FormEditorRoutingModule { }
