<p-dialog
  *ngIf="isDialogVisible"
  [(visible)]="isDialogVisible"
  [responsive]="true"
  [modal]="false"
  [minWidth]="200"
  width="1200"
  showEffect="fade"
  appendTo="body"
  header="{{ 'TOOLBARMANAGEMENT.EXPERT_SEARCH' | translate }}"
>
  <app-generic-grid-expert-search
    [component]="component"
  ></app-generic-grid-expert-search>
</p-dialog>
