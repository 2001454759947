/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/forms";
import * as i2 from "primeng/components/inputtext/inputtext";
import * as i3 from "./distance-search.component";
var styles_DistanceSearchComponent = [".ap-input {\n      height: 20px;\n    }\n\n    .no-padding-left-right[_ngcontent-%COMP%] {\n      padding: 2px 0;\n    }\n\n      .algolia-places-container > .algolia-places {\n      position: absolute !important;\n      width: 264px;\n    }\n\n      .ap-dropdown-menu {\n      z-index: 999999 !important;\n    }\n\n    .km-input[_ngcontent-%COMP%] {\n      padding-left: 6px;\n    }\n\n      .ui-inputgroup .ui-inputgroup-addon {\n      min-width: 4em;\n    }\n\n    .autocomplete-container[_ngcontent-%COMP%] {\n      height: 20px;\n      width: 264px;\n      position: relative;\n    }\n\n      .geoapify-autocomplete-input {\n      height: 20px;\n      width: 264px;\n      position: relative;\n    }"];
var RenderType_DistanceSearchComponent = i0.ɵcrt({ encapsulation: 0, styles: styles_DistanceSearchComponent, data: {} });
export { RenderType_DistanceSearchComponent as RenderType_DistanceSearchComponent };
export function View_DistanceSearchComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 12, "div", [["class", "ui-g-12 no-padding-left-right"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 0, "div", [["class", "ui-g-8 autocomplete-container"], ["id", "autocomplete-container"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 10, "div", [["class", "ui-g-4 no-padding km-input"]], null, null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 9, "div", [["class", "ui-inputgroup"]], null, null, null, null, null)), (_l()(), i0.ɵeld(4, 0, null, null, 6, "input", [["pInputText", ""], ["type", "text"]], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null], [2, "ui-inputtext", null], [2, "ui-corner-all", null], [2, "ui-state-default", null], [2, "ui-widget", null], [2, "ui-state-filled", null]], [[null, "keyup"], [null, "input"], [null, "blur"], [null, "compositionstart"], [null, "compositionend"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("input" === en)) {
        var pd_0 = (i0.ɵnov(_v, 5)._handleInput($event.target.value) !== false);
        ad = (pd_0 && ad);
    } if (("blur" === en)) {
        var pd_1 = (i0.ɵnov(_v, 5).onTouched() !== false);
        ad = (pd_1 && ad);
    } if (("compositionstart" === en)) {
        var pd_2 = (i0.ɵnov(_v, 5)._compositionStart() !== false);
        ad = (pd_2 && ad);
    } if (("compositionend" === en)) {
        var pd_3 = (i0.ɵnov(_v, 5)._compositionEnd($event.target.value) !== false);
        ad = (pd_3 && ad);
    } if (("input" === en)) {
        var pd_4 = (i0.ɵnov(_v, 10).onInput($event) !== false);
        ad = (pd_4 && ad);
    } if (("keyup" === en)) {
        var pd_5 = (_co.onKmChange($event) !== false);
        ad = (pd_5 && ad);
    } return ad; }, null, null)), i0.ɵdid(5, 16384, null, 0, i1.DefaultValueAccessor, [i0.Renderer2, i0.ElementRef, [2, i1.COMPOSITION_BUFFER_MODE]], null, null), i0.ɵprd(1024, null, i1.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i1.DefaultValueAccessor]), i0.ɵdid(7, 671744, null, 0, i1.NgModel, [[8, null], [8, null], [8, null], [6, i1.NG_VALUE_ACCESSOR]], { model: [0, "model"] }, null), i0.ɵprd(2048, null, i1.NgControl, null, [i1.NgModel]), i0.ɵdid(9, 16384, null, 0, i1.NgControlStatus, [[4, i1.NgControl]], null, null), i0.ɵdid(10, 278528, null, 0, i2.InputText, [i0.ElementRef, [2, i1.NgModel]], null, null), (_l()(), i0.ɵeld(11, 0, null, null, 1, "span", [["class", "ui-inputgroup-addon"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["KM"]))], function (_ck, _v) { var _co = _v.component; var currVal_12 = ((_co.field == null) ? null : ((_co.field.value == null) ? null : _co.field.value.km)); _ck(_v, 7, 0, currVal_12); _ck(_v, 10, 0); }, function (_ck, _v) { var currVal_0 = i0.ɵnov(_v, 9).ngClassUntouched; var currVal_1 = i0.ɵnov(_v, 9).ngClassTouched; var currVal_2 = i0.ɵnov(_v, 9).ngClassPristine; var currVal_3 = i0.ɵnov(_v, 9).ngClassDirty; var currVal_4 = i0.ɵnov(_v, 9).ngClassValid; var currVal_5 = i0.ɵnov(_v, 9).ngClassInvalid; var currVal_6 = i0.ɵnov(_v, 9).ngClassPending; var currVal_7 = true; var currVal_8 = true; var currVal_9 = true; var currVal_10 = true; var currVal_11 = i0.ɵnov(_v, 10).filled; _ck(_v, 4, 1, [currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8, currVal_9, currVal_10, currVal_11]); }); }
export function View_DistanceSearchComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-distance-search", [], null, null, null, View_DistanceSearchComponent_0, RenderType_DistanceSearchComponent)), i0.ɵdid(1, 114688, null, 0, i3.DistanceSearchComponent, [i0.ElementRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var DistanceSearchComponentNgFactory = i0.ɵccf("app-distance-search", i3.DistanceSearchComponent, View_DistanceSearchComponent_Host_0, { field: "field" }, {}, []);
export { DistanceSearchComponentNgFactory as DistanceSearchComponentNgFactory };
