
    <div fxLayout="row" fxLayoutAlign="start stretch" (click)="onClickElement($event)">
      <div *ngFor="let elementChild of element.children" [fxFlex]="getColumnWidth(elementChild)">
        <app-form-element-layout-columns-item
          [(actionParams)]="actionParams"
          [(selectedElement)]="selectedElement"
          [(showDialogAddAddress)]="showDialogAddAddress"
          [form]="form"
          [formGroup]="formGroup"
          [element]="elementChild"
          [moduleElement]="moduleElement"
          [entity]="entity"
          [editMode]="editMode"
        ></app-form-element-layout-columns-item>
    </div>
  </div>
  