<table>
  <tr>
    <td>
      <button pButton
              icon="fa fa-plus"
              [disabled]="!entity || !entity.id"
              pTooltip="{{ 'COMMON.ADD_CHILD_NODE' | translate }}"
              (click)="onAddNode()">
      </button>
    </td>
    <td>
      <button pButton
              [style.background-color]="getColor()"
              label="{{ 'COMMON.DETAIL' | translate }}"
              (click)="doOpenModule()">
      </button>
    </td>
  </tr>
</table>
