
import {map} from 'rxjs/operators';
import {Component, OnInit, Input, ChangeDetectorRef} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {MessageGrowlService} from '../../../../core/message/message-growl.service';
import {GenericCrudService} from '../../../services/generic-crud.service';
import {Observable} from 'rxjs';
import {ChangeDetectorRefHelper} from '../../../helpers/change-detector-ref.helper';

@Component({
  selector: 'app-ksv-company-product-details',
  templateUrl: './ksv-company-product-details.component.html'
})
export class KsvCompanyProductDetailsComponent implements OnInit {

  private static readonly COMPANY_PRODUCT_API_ROUTE = 'app/ksv/products';

  @Input() public company: {ksvNumber: number} = null;
  @Input() public product: any = null;

  public isDataLoading = false;

  public constructor(
    protected translate: TranslateService,
    protected messageGrowlService: MessageGrowlService,
    protected genericCrudService: GenericCrudService,
    public cdr: ChangeDetectorRef
  ) {

  }

  public ngOnInit() {
    this.loadProduct()
      .subscribe((product) => {
        this.product = product;
        ChangeDetectorRefHelper.detectChanges(this);
      });
  }

  public loadProduct(): Observable<any> {
    this.isDataLoading = true;

    return this.genericCrudService
      .getEntities(`${KsvCompanyProductDetailsComponent.COMPANY_PRODUCT_API_ROUTE}/${this.company.ksvNumber}/${this.product.type}`).pipe(
      map((product: {type: number} = null) => {
        this.isDataLoading = false;
        return product;
      }));
  }
}
