
import {of as observableOf, Observable} from 'rxjs';
import {AbstractExecutionStep} from '../../../../../../core/executor/abstract-execution-step';
import {ExecutionStepStatus} from '../../../../../../core/executor/execution-step-status';
import {WizardComponent} from '../../../../../content-renderer/elements/custom/wizard/wizard.component';
import {WizardElement} from '../../../../element/wizard';
import {WizardElementDetails} from '../../../../../content-renderer/elements/custom/wizard/service/wizard.service';
import {EntityStatus} from '../../../../entity/entity-status';
import {Guid} from 'guid-typescript';
import {GenericElementAbstract} from '../../../../../content-renderer/elements/generic-element-abstract.component';
import {ElementType} from '../../../../../content-renderer/services/ElementContext';

export class WizardPrecalculationBeforeElementOneExecutionStep extends AbstractExecutionStep {

  public doExecute(): Observable<ExecutionStepStatus> {
    const payloadValue = this.getPayload().getValue();

    const wizardComponent = payloadValue.wizardComponent,
      wizardElement = payloadValue.wizardElement;

    if (!wizardComponent || !wizardElement) {
      return this.getFailObservable('You need to pass WizardComponent as Payload value!');
    }

    return this.doSet(wizardComponent, wizardElement);
  }

  protected doSet(wizardComponent: WizardComponent, wizardElement: WizardElement): Observable<ExecutionStepStatus> {
    if (wizardComponent.masterEntity && wizardComponent.masterEntity.id) {
      const elementDetails: WizardElementDetails = wizardComponent.wizardService.getElementDetails(wizardElement);

      const preCalculation = wizardComponent.selectedMasterEntity;
      preCalculation.fqn = 'PhoenixBundle\\Entity\\PreCalculation';

      const detailsEntity = elementDetails.inputDetails.entity || preCalculation;

      detailsEntity.customer = wizardComponent.masterEntity;

      if(wizardComponent.masterElementContext && wizardComponent.masterElementContext.selectedEntity && wizardComponent.masterElementContext.selectedEntity['jobPrintText']){
        detailsEntity['name'] = wizardComponent.masterElementContext.selectedEntity['jobPrintText'];
      }

      this.enableWizardButtons();

      if(detailsEntity['preCalculationType'] && (!detailsEntity['preCalculationType']['preferredSalaryUnit'] || !detailsEntity['preCalculationType']['preferredBillingUnit'])){
        return Observable.create(observer => {
          this.getPreCalculationType(detailsEntity['preCalculationType'])
            .subscribe((res: any) => {
              detailsEntity['preCalculationType'] = res;
              detailsEntity['_embedded']['preCalculationType'] = res;
              elementDetails.inputDetails.entity = detailsEntity;

              observer.next({status: true, content: null});
              observer.complete();
            });
        });
      }else {
        elementDetails.inputDetails.entity = detailsEntity;
        return observableOf({status: true, content: null});
      }
    }
    return observableOf({status: true, content: null});
  }

  private getPreCalculationType(preCalculationType): Observable<any>{
    return this.genericCrudService.getEntity(
      this.getEntityApiRoute(preCalculationType),
      preCalculationType['id'],
      '',
      {embedded:'preferredSalaryUnit,preferredBillingUnit'});
  }

  private getEntityApiRoute(entity): string {
    if (entity && entity._links && entity._links.self && entity._links.self.href) {
      let fromString = entity._links.self.href;
      let apiRoute = fromString.replace('/api/', ''), // remove '/api/' part from a string
        apiRouteParts = apiRoute.split('/', 2);

      return `${apiRouteParts[0]}/${apiRouteParts[1]}`;
    }

    return '';
  }

  enableWizardButtons(): void {
    const wizardComponent: any = this.modulesStateService.getCurrent().getAllComponents().find((aComponent: GenericElementAbstract) => {
      return aComponent.elementContext && aComponent.elementContext.type === ElementType.Wizard
    });

    if (wizardComponent && wizardComponent.wizardElement) {
      const wizardElement = wizardComponent.wizardElement;
      for (const wizardElementButton of wizardElement.buttons || []) {
        wizardElementButton.isDisabled = false;
      }
    }
  }
}
