import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from './../shared/shared.module';

import { ModuleContentComponent } from './module-content.component';

import { ModuleContentRoutingModule } from './module-content-routing.module';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    ModuleContentRoutingModule
  ],
  declarations: [
    ModuleContentComponent,
  ],
  providers: [
  ],
  exports: [
  ],
})
export class ModuleContentModule { }
