/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../../../node_modules/primeng/components/dropdown/dropdown.ngfactory";
import * as i2 from "primeng/components/dropdown/dropdown";
import * as i3 from "@angular/forms";
import * as i4 from "./organisation-dropdown.component";
import * as i5 from "../../services/generic-crud.service";
var styles_OrganisationDropdownComponent = [];
var RenderType_OrganisationDropdownComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_OrganisationDropdownComponent, data: {} });
export { RenderType_OrganisationDropdownComponent as RenderType_OrganisationDropdownComponent };
export function View_OrganisationDropdownComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 7, "p-dropdown", [["appendTo", "body"], ["placeholder", "---"]], [[2, "ui-inputwrapper-filled", null], [2, "ui-inputwrapper-focus", null], [2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "ngModelChange"], [null, "onChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("ngModelChange" === en)) {
        var pd_0 = ((_co.selectedOrganisationOption = $event) !== false);
        ad = (pd_0 && ad);
    } if (("onChange" === en)) {
        var pd_1 = (_co.onOrganisationChanged($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i1.View_Dropdown_0, i1.RenderType_Dropdown)), i0.ɵdid(1, 13877248, null, 1, i2.Dropdown, [i0.ElementRef, i0.Renderer2, i0.ChangeDetectorRef, i0.NgZone], { filter: [0, "filter"], style: [1, "style"], appendTo: [2, "appendTo"], placeholder: [3, "placeholder"], disabled: [4, "disabled"], options: [5, "options"] }, { onChange: "onChange" }), i0.ɵqud(603979776, 1, { templates: 1 }), i0.ɵpod(3, { "width": 0 }), i0.ɵprd(1024, null, i3.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i2.Dropdown]), i0.ɵdid(5, 671744, null, 0, i3.NgModel, [[8, null], [8, null], [8, null], [6, i3.NG_VALUE_ACCESSOR]], { isDisabled: [0, "isDisabled"], model: [1, "model"] }, { update: "ngModelChange" }), i0.ɵprd(2048, null, i3.NgControl, null, [i3.NgModel]), i0.ɵdid(7, 16384, null, 0, i3.NgControlStatus, [[4, i3.NgControl]], null, null)], function (_ck, _v) { var _co = _v.component; var currVal_9 = true; var currVal_10 = _ck(_v, 3, 0, "100%"); var currVal_11 = "body"; var currVal_12 = "---"; var currVal_13 = _co.disabled; var currVal_14 = _co.organisationOptions; _ck(_v, 1, 0, currVal_9, currVal_10, currVal_11, currVal_12, currVal_13, currVal_14); var currVal_15 = _co.disabled; var currVal_16 = _co.selectedOrganisationOption; _ck(_v, 5, 0, currVal_15, currVal_16); }, function (_ck, _v) { var currVal_0 = i0.ɵnov(_v, 1).filled; var currVal_1 = i0.ɵnov(_v, 1).focused; var currVal_2 = i0.ɵnov(_v, 7).ngClassUntouched; var currVal_3 = i0.ɵnov(_v, 7).ngClassTouched; var currVal_4 = i0.ɵnov(_v, 7).ngClassPristine; var currVal_5 = i0.ɵnov(_v, 7).ngClassDirty; var currVal_6 = i0.ɵnov(_v, 7).ngClassValid; var currVal_7 = i0.ɵnov(_v, 7).ngClassInvalid; var currVal_8 = i0.ɵnov(_v, 7).ngClassPending; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8); }); }
export function View_OrganisationDropdownComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-organisation-dropdown", [], null, null, null, View_OrganisationDropdownComponent_0, RenderType_OrganisationDropdownComponent)), i0.ɵdid(1, 114688, null, 0, i4.OrganisationDropdownComponent, [i5.GenericCrudService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var OrganisationDropdownComponentNgFactory = i0.ɵccf("app-organisation-dropdown", i4.OrganisationDropdownComponent, View_OrganisationDropdownComponent_Host_0, { selectedOrganisationOption: "selectedOrganisationOption", forceSelectionOnLoad: "forceSelectionOnLoad", valueAsId: "valueAsId", disabled: "disabled" }, { onOptionChanged: "onOptionChanged" }, []);
export { OrganisationDropdownComponentNgFactory as OrganisationDropdownComponentNgFactory };
