<p-dialog *ngIf="isEntityDialogVisible" appendTo="body" header="{{entity ? entity.name : 'Add'}}" [(visible)]="isEntityDialogVisible" [responsive]="true" showEffect="fade" [modal]="true" [minWidth]="200">
    <div class="ui-grid ui-grid-responsive ui-fluid" *ngIf="fields">
        <ng-template ngFor let-field [ngForOf]="fields">
        <div class="ui-grid-row row-margin-5">
            <div class="ui-grid-col-4"><label for="{{field.header}}">{{field.header}}</label></div>

            <div class="ui-grid-col-8" *ngIf="isStringField(field)">
                <input class="float-right" pInputText id="{{field.header}}" [(ngModel)]="field.value" />
            </div>
            
            <div class="ui-grid-col-8" *ngIf="isCheckboxField(field)">
                <p-checkbox class="float-right" id="{{field.header}}" [(ngModel)]="field.value" binary="true"></p-checkbox>
            </div>

            <div class="ui-grid-col-8" *ngIf="isIntegerField(field)">
                <p-spinner size="30" id="{{field.header}}" [(ngModel)]="field.value" [min]="1" [max]="999999"></p-spinner>
            </div>
        </div>
        </ng-template>
    </div>
    <footer>
        <div class="ui-dialog-buttonpane ui-widget-content ui-helper-clearfix">
        <button type="button" pButton icon="fa-check" (click)="saveEntity()" label="Speichern"></button>
        </div>
    </footer>
</p-dialog>
