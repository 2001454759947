import {ChangeDetectorRef, Component, OnDestroy} from '@angular/core';
import {Subject} from 'rxjs';
import {GenericDialogModuleService} from '../../../generic-dialog/service/generic-dialog-module.service';
import {GenericElementAbstract} from '../../../generic-element-abstract.component';
import {Module} from '../../../../../services/module/module';
import {GenericCrudService} from '../../../../../services/generic-crud.service';
import {takeUntil} from 'rxjs/operators';
import {environment} from '../../../../../../../environments';

@Component({
  selector: 'app-custom-staff-cockpit-buttons',
  styleUrls: ['./staff-cockpit-buttons.component.scss'],
  templateUrl: './staff-cockpit-buttons.component.html',
})
export class StaffCockpitButtonsComponent implements OnDestroy {

  public unsubscribe = new Subject<void>();

  constructor(
    private cdr: ChangeDetectorRef,
    private genericCrudService: GenericCrudService,
    private genericDialogModuleService: GenericDialogModuleService
  ) {
  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  onOpenModule(): void {
    this.openModule(environment.additional.cockpitFirstButtonModuleId);
  }

  onOpenModuleTwo(): void {
    this.openModule(environment.additional.cockpitSecondButtonModuleId);
  }

  openModule(moduleId): void {
    this.genericCrudService.getEntity('superadmin/modules', moduleId)
      .pipe(
        takeUntil(this.unsubscribe)
      )
      .subscribe((module) => {
        this.genericDialogModuleService.showDialog(module, {
          height: 600,
          width: 1200,
          isModal: true,
          maximized: true,
          isAutocompleteModuleState: false,
          afterClose: (closeDialogCallerComponent: GenericElementAbstract) => {

            return true;
          }
        });
      });
  }
}

