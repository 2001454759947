import { ToolbarItemAbstract } from './toolbar-item-abstract.component';
import { GenericGridGlobalFilterService } from '../../../generic-grid/services/generic-grid-global-filter.service';
import {ChangeDetectionStrategy, Component} from '@angular/core';
import { ComponentService } from '../../../../services/component-highlight-stack.service';
import { FormViewerComponent } from '../../../../../form-viewer/form-viewer.component';
import { AbstractGenericGridComponent } from 'app/shared/content-renderer/elements/abstract-generic-grid.component';
import { Constants } from 'app/constants';
import {UserSessionService} from '../../../../../../core/service/user-session.service';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [GenericGridGlobalFilterService],
  template: ''
})
export class ToolbarItemFilter extends ToolbarItemAbstract {

  public filterKey = '';

  constructor(
    protected userSession: UserSessionService,
    protected genericGridGlobalFilterService: GenericGridGlobalFilterService,
    protected componentService: ComponentService
  ) {
    super();

    this.filterKey = this.getFilterKey();
  }

  public click() {
    const component = this.getComponent();

    event = new CustomEvent(Constants.EVENT_CLICK_FILTER_TOOLBAR_ITEM);

    if (this.isInCurrentContext(ToolbarItemAbstract.CONTEXT_MODULE)) {
      this.doShowSoftDeletedModuleComponent();
    } else if (component instanceof AbstractGenericGridComponent) {
      this.doShowSoftDeletedGridComponent(component);
    } else if (this.isInCurrentContext(ToolbarItemAbstract.CONTEXT_FORM_VIEWER)) {
      this.doShowSoftDeletedFormComponent(this.getParam('formViewerComponent'));
    }
  }

  public isItemCheckboxEnabled(): boolean {
    let isEnabled = false,
      component = this.getComponent();

    if (this.isInCurrentContext(ToolbarItemAbstract.CONTEXT_MODULE)) {
      isEnabled = this.userSession.get(this.getFilterKeyInModuleContext(this.filterKey));
    }

    if (component instanceof AbstractGenericGridComponent) {
      isEnabled = this.userSession.get(this.getFilterKeyInGridContext(this.filterKey, component));
    }

    if (this.isInCurrentContext(ToolbarItemAbstract.CONTEXT_FORM_VIEWER)) {
      isEnabled = this.userSession.get(this.getFilterKeyInFormContext(this.filterKey, this.getParam('formViewerComponent')));;
    }

    return isEnabled;
  }

  protected getFilterKey() {
    return '';
  }

  protected doShowSoftDeletedGridComponent(gridComponent: AbstractGenericGridComponent) {
    const filterKey = this.getFilterKeyInGridContext(this.filterKey, gridComponent);

    this.changeFilters(filterKey);

    gridComponent.loadEntities().subscribe();
  }

  protected doShowSoftDeletedFormComponent(formComponent: FormViewerComponent) {
    const filterKey = this.getFilterKeyInFormContext(this.filterKey, formComponent);

    this.changeFilters(filterKey);
  }

  protected getFilterKeyInGridContext(filterKey: string, gridComponent: AbstractGenericGridComponent): string {
    return this.genericGridGlobalFilterService.setGrid(gridComponent).getFilterKeyInGridContext(
      this.filterKey,
      gridComponent.getElementDataModelApiRoute()
    );
  }

  protected getFilterKeyInFormContext(filterKey: string, formComponent: FormViewerComponent): string {
    return this.genericGridGlobalFilterService.setForm(formComponent).getFilterKeyInFormContext(
      this.filterKey,
      formComponent.getElementDataModelApiRoute()
    );
  }

  protected changeFilters(filterKey: string) {
    const wasEnabled = this.userSession.get(filterKey);

    this.userSession.set(filterKey, {
      value: !wasEnabled
    });
  }

  protected doShowSoftDeletedModuleComponent() {
    const componentsOnPage = this.componentService.getStashed();

    const filterKey = this.getFilterKeyInModuleContext(this.filterKey);

    this.changeFilters(filterKey);

    for (const component of componentsOnPage) {
      if (component instanceof AbstractGenericGridComponent && component.element && component.element.datamodel) {
        this.doShowSoftDeletedGridComponent(component);
      } else if (component instanceof FormViewerComponent && component.element && component.element.datamodel) {
        this.doShowSoftDeletedFormComponent(component);
      }
    }
  }

  protected getFilterKeyInModuleContext(filterKey: string): string {
    return this.genericGridGlobalFilterService.getFilterKeyInModuleContext(
      this.filterKey
    );
  }
}
