import {Observable, of as observableOf} from 'rxjs';
import {ChangeDetectorRef, Component, ElementRef, Input, ViewChild, ViewContainerRef} from '@angular/core';
import {ExecutorService} from '../../../../../core/executor/executor.service';
import {GenericElementAbstract} from '../../generic-element-abstract.component';
import {FieldMetadataGrid} from '../../../../services/module/module-element-field-metadata-grid';
import {ModuleElement} from '../../../../services/module/module-element';
import {EntityValidator, EntityValidatorStatus} from '../../../../validators/services/entity-validator';
import {Element} from '../../../../services/element/element';
import {ToolbarItemCheckService} from '../../generic-toolbar/services/check/toolbar-item-check.service';
import {GenericElementValidationExecutionStepsFactory} from '../../../services/generic/generic-element-validation-execution-steps-factory';
import {EntityDataStoreService} from '../../../services/entity-data-store.service';
import {ModulesStateService} from '../../../services/modules-state.service';
import {ComponentService} from '../../../services/component-highlight-stack.service';
import {GenericCrudService} from '../../../../services/generic-crud.service';
import {JobContainerService} from '../../../../../core/job-runner/job-container.service';
import {ExecutionStepFactoryService} from '../../../../../core/executor/factory/execution-step-factory.service';
import {LayoutService} from '../../../../services/layout-service';
import {ExecutorActionsService} from '../../../../../core/executor/service/executor-actions/executor-actions.service';
import {ElementContext, ElementType} from '../../../services/ElementContext';
import {PermissionService} from '../../../../services/permission/permission.service';
import {UserSessionService} from '../../../../../core/service/user-session.service';
import {Entity} from '../../../../helpers/entity';
import {TranslateService} from '@ngx-translate/core';
import {ChangeDetectorRefHelper} from '../../../../helpers/change-detector-ref.helper';
import {TableColumn} from '../../../../dynamic-table/shared/table-column';
import {DynamicTableComponent} from '../../../../dynamic-table/dynamic-table.component';
import {environment} from '../../../../../../environments';

@Component({
  selector: 'app-custom-sql-table',
  styleUrls: ['./sql-table.component.scss'],
  templateUrl: './sql-table.component.html',
  providers: [
    ExecutorService,
    GenericElementValidationExecutionStepsFactory
  ]
})
export class SqlTableComponent extends GenericElementAbstract {
  public isLoadingData = false;
  public errorMessages = [];
  public toolbarContextName = 'sqlTableComponent';
  public columns: TableColumn[] = [];

  @Input() element: Element;
  @Input() fields: Array<FieldMetadataGrid>;
  @Input() toolbarItems: any[] = [];
  @Input() statusBarItems: any[] = [];
  @Input() moduleElement: ModuleElement;
  @Input() masterEntity: any = null;
  @Input() masterField: any = null;
  @Input() isPart = false;
  @Input() entity: any = null;

  @ViewChild(DynamicTableComponent, {static: false}) table: DynamicTableComponent;

  public totalCount = 0;
  public entities = [];
  public selectedEntity = null;
  public dataKey = '';

  private static buildColumns(data: any[]) {
    const columns = [],
      firstEntity = data[0] || null;

    if (firstEntity) {
      for (const key in firstEntity) {
        if (firstEntity.hasOwnProperty(key)) {
          columns.push({
            key: key,
            header: key,
            renderer: (entity) => {
              const entityValue = entity[key];

              if (key === 'icon') {
                const src = `${environment.baseUrl}/assets/hr-puzzle/images/icons/${entityValue}.png`;

                return `<img height="16" width="16" src="${src}" alt="${entityValue}" />`;
              }

              return entityValue;
            }
          })
        }
      }
    }

    return columns;
  }

  private static getDataKey(data: any[]) {
    const firstEntity = data[0] || null;

    if (firstEntity) {
      for (const key in firstEntity) {
        if (firstEntity.hasOwnProperty(key)) {
          return key;
        }
      }
    }

    return null;
  }

  public constructor(
    public elementRef: ElementRef,
    public executorActionsService: ExecutorActionsService,
    protected componentService: ComponentService,
    protected viewContainerRef: ViewContainerRef,
    protected modulesStateService: ModulesStateService,
    protected genericCrudService: GenericCrudService,
    protected entityDataStoreService: EntityDataStoreService,
    protected executorService: ExecutorService,
    protected genericElementValidationExecutionStepsFactory: GenericElementValidationExecutionStepsFactory,
    protected entityValidator: EntityValidator,
    protected userSession: UserSessionService,
    protected toolbarItemCheckService: ToolbarItemCheckService,
    protected jobContainerService: JobContainerService,
    protected stepFactory: ExecutionStepFactoryService,
    protected layoutService: LayoutService,
    protected permissionService: PermissionService,
    public cdr: ChangeDetectorRef,
    protected translate: TranslateService
  ) {
    super(componentService, viewContainerRef, entityDataStoreService, modulesStateService, executorService,
      genericElementValidationExecutionStepsFactory, entityValidator, genericCrudService, userSession, permissionService,
      cdr);
  }

  public ngOnInit() {
    super.ngOnInit();

    const dataSource = Entity.getValue(this.element, 'dataSource') ||
      Entity.getValueInEmbedded(this.element, 'dataSource')

    if (this.validateElementConfiguration()) {
      this.isLoadingData = true;
      this.genericCrudService.getEntities(`superadmin/datasources/${dataSource.id}/data`)
        .subscribe((response: {data: any[]}) => {
          this.columns = SqlTableComponent.buildColumns(response.data);
          this.dataKey = SqlTableComponent.getDataKey(response.data);
          this.entities = response.data;

          this.isLoadingData = false;
          this.setTableScrollHeightAndWidth();
          ChangeDetectorRefHelper.detectChanges(this);
        })
    }

    this.onComponentInit();
  }

  public ngOnDestroy() {
    super.ngOnDestroy();

    this.onDestroyComponent();
  }

  public onComponentInit(): void {
    this.elementContext = this.createContext();

    this.executorActionsService
      .registerModuleElementActions(this.moduleElement)
      .subscribe();

    this.layoutService.layoutSizeChanged$.subscribe(() => {
      setTimeout(() => {
        this.setTableScrollHeightAndWidth();
      }, 50);
    });
  }

  public onDestroyComponent(): void {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  public getSelectedEntity(): any {
    return this.selectedMasterEntity || null;
  }

  public recheckToolbarItems(): void {
    this.toolbarItemCheckService.check(this);
  }

  public onSave(): Observable<any> {
    return observableOf(null);
  }

  public hasChanges(checkEmbedded: boolean = false): boolean {
    return false;
  }

  public onAfterSave(): Observable<any> {
    return observableOf(null);
  }

  public onChange(): Observable<any> {
    return observableOf(null);
  }

  public doValidate(): Observable<EntityValidatorStatus> {
    return observableOf({
      entity: null,
      isValid: true,
      error: '',
      errorFields: []
    });
  }

  public onRefresh(): Observable<any> {
    return observableOf(null);
  }

  public getToolbarItemsExtraParams() {
    return {
      'sqlTableComponent': this
    };
  }

  private createContext(): ElementContext {
    return new ElementContext(
      this.moduleElement.id,
      ElementType.SqlTable,
      this,
      this.moduleElement,
      true,
      false,
      false,
      false,
      false
    );
  }

  private validateElementConfiguration(): boolean {
    const dataSource = Entity.getValue(this.element, 'dataSource') ||
      Entity.getValueInEmbedded(this.element, 'dataSource');

    if (!dataSource) {
      this.errorMessages = [
        this.translate.instant('SQL_TABLE.NO_DATA_SOURCE_DEFINED')
      ];
    }

    return dataSource;
  }

  private setTableScrollHeightAndWidth(): void {
    const containerHeight = document.getElementById('content-renderer-container').clientHeight;

    if (this.table && containerHeight) {
      let height = containerHeight;

      height -= 6;

      if (this.table) {
        height -= 30;
      }

      this.table.height = height;
    }
  }
}

