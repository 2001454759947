/**
 * @description if method is contantly called, run method only ONCE
 */
export class Debounce {

    private static timeout = null;

    private static clickCounter: number = 0;

    public static debounce(method, time: number = 500): void {
        
        clearTimeout(Debounce.timeout);

        Debounce.timeout = setTimeout(function () {
            return method();
        }, time);
    }

    public static isDoubleClick(callback, time: number = 1000): void {
        var me = this,
            isDoubleClick = false;

        this.clickCounter++;
        
        clearTimeout(Debounce.timeout);

        Debounce.timeout = setTimeout(function () {
            
            if (me.clickCounter >= 2) {
                isDoubleClick = true;;
            }

            // reset
            me.clickCounter = 0;

            return callback(isDoubleClick);
        }, time);
    }

}
