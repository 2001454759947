import {ChangeDetectionStrategy, Component} from '@angular/core';
import {ToolbarItemAbstract} from '../../toolbar-item-abstract.component';
import {MessageGrowlService} from '../../../../../../../../core/message/message-growl.service';
import {GenericCrudService} from '../../../../../../../services/generic-crud.service';
import {ExecutorService} from '../../../../../../../../core/executor/executor.service';
import {MessageService} from '../../../../../../../../core/message/message.service';
import {TranslateService} from '@ngx-translate/core';
import {ExecutionStepFactoryService} from '../../../../../../../../core/executor/factory/execution-step-factory.service';
import { SelectItem } from 'primeng/primeng';
import {AbstractGenericGridComponent} from '../../../../../abstract-generic-grid.component';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'toolbar-item-ca-bulk-calculate',
  templateUrl: './toolbar-item-ca-bulk-calculate.component.html'
})
export class ToolbarItemCaBulkCalculateComponent extends ToolbarItemAbstract {

  public isDialogVisible = false;

  public subassignments: SelectItem[] = [
    {
      label: '...',
      value: null
    }
  ];

  public selectedSubAssignment = null;

  constructor(
    protected messageGrowlService: MessageGrowlService,
    protected genericCrudService: GenericCrudService,
    protected executionStepFactoryService: ExecutionStepFactoryService,
    protected messageService: MessageService,
    protected translateService: TranslateService,
    protected executorService: ExecutorService
  ) {
    super();
  }

  public click() {
    const component = this.getComponent();

    if (component instanceof AbstractGenericGridComponent) {
      this.loadSubassignments(component);
    }
  }

  protected loadSubassignments(grid: AbstractGenericGridComponent) {
    const data = {
      workYear: grid.gridFilters['workYear']['value'],
      workMonth: grid.gridFilters['workMonth']['value'],
      leasedEmployee: grid.gridFilters['leasedEmployee']['value'][0]['id']
    };

    this.genericCrudService.getPaginated('phoenix/subassignments/employee/' + data.leasedEmployee + '/year/' + data.workYear + '/month/' + data.workMonth + '?embedded=none')
      .subscribe(results => {
        const assignmentsData = results.data;

        for (const assignment of assignmentsData) {
          this.subassignments = [...this.subassignments,
            {
              label: assignment.subAssignmentNumber,
              value: assignment.id
            }];
        }

        this.isDialogVisible = true;
      });
  }

  public onCancel(): void {
    this.isDialogVisible = false;
  }

  public onCalculate() {
    const grid = this.getComponent();
    if (this.selectedSubAssignment && grid instanceof AbstractGenericGridComponent) {
      const data = {
        workYear: grid.gridFilters['workYear']['value'],
        workMonth: grid.gridFilters['workMonth']['value'],
        subAssignment: this.selectedSubAssignment
      };

      this.genericCrudService.customPut('phoenix/workhours/calculate/subassignment', data).subscribe(
        results => {
          this.onCancel();
        }
      );
    }
  }
}
