
<p-toolbar styleClass="no-round-corner">
  <div class="buttons-container">
    <div class="item-spacer"></div>
    <div class="item-right">
      <button
        type="button"
        pButton
        (click)="onSave()"
        label="Add"
      ></button>
    </div>
  </div>
</p-toolbar>

<div class="ui-g">
  <div class="ui-grid ui-grid-responsive ui-fluid">
    <div class="ui-grid-row" *ngFor="let conf of config.data['config']">
      <div class="ui-grid-col-12">
        <div class="ui-grid-col-3">
          <label style="display: block;">{{ conf.name }}:</label>
        </div>
        <div class="ui-grid-col-9">
          <ng-container [ngSwitch]="conf.inputType">
            <ng-container *ngSwitchCase="'autocomplete'">
              <p-autoComplete
                appGenericAutoCompleteHandler
                [dropdown]="true"
                [(ngModel)]="parameters[conf.code]"
                [style]="{'width':'100%', 'white-space': 'nowrap'}"
                [apiRoute]="conf['inputDataModel']"
                appendTo="body"
                [labelField]="'name'"
                [valueField]="'id'"
                [field]="'label'"
                styleClass="ui-column-filter ui-column-filter-autocomplete"
              ></p-autoComplete>
            </ng-container>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</div>
