export class ExecutionStepPayload {

    private value: any;

    public constructor(value: any) {
        this.value = value;
    }

    public getValue(): any {
        return this.value;
    }
}
