import {AbstractCondition} from '../../../core/job-runner/condition/abstract.condition';
import {JobContext} from '../../../core/job-runner/context/job.context';
import {RunnableEventRegistry} from '../../../core/job-runner/type/runnable-event.registry';
import {GenericElementAbstract} from '../../content-renderer/elements/generic-element-abstract.component';

export class LoadSlaveDataCondition extends AbstractCondition {
  isApplicable(context: JobContext): boolean {
    if (context.event === RunnableEventRegistry.PostInit && context.component instanceof GenericElementAbstract && this.isSlaveComponent(context.component)) {
      return true;
    }

    return false;
  }

  private isSlaveComponent(component: GenericElementAbstract): boolean {
    const componentIsGrid = component.constructor.name === 'GenericTurboGridComponent' ||
      component.constructor.name === 'GenericTreeGridComponent';

    if (!component.getElementContext().isSlave || !componentIsGrid) {
      return false;
    }

    if (!component.getElementContext().getMasterElementContext()) {
      return false;
    }

    const payload = this.getPayload();

    return component.getElementContext().getMasterElementContext().component.moduleElement.id === payload['component'].moduleElement.id;
  }
}
