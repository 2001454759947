
import {of as observableOf, Observable} from 'rxjs';
import {AbstractExecutionStep} from '../../../core/executor/abstract-execution-step';
import {ExecutionStepStatus} from '../../../core/executor/execution-step-status';
import {AbstractGenericGridComponent} from '../../content-renderer/elements/abstract-generic-grid.component';
import {EntityStatus} from '../entity/entity-status';
import {GenericTreeGridComponent} from '../../content-renderer/elements/generic-tree-grid/generic-tree-grid.component';

export class ComponentMergeCachedEntitiesExecutionStep extends AbstractExecutionStep {

  public doExecute(): Observable<ExecutionStepStatus> {
    const payload = this.getPayload();

    if (!(payload.getValue() instanceof AbstractGenericGridComponent)) {
      return this.getFailObservable('You need to pass AbstractGenericGridComponent as Payload value!');
    }

    return this.doMerge(payload.getValue());
  }

  protected doMerge(component: AbstractGenericGridComponent): Observable<ExecutionStepStatus> {
    const nodes: TreeNode[] = component.runtimeData.cachedChangedTreeNodes || [];

    if (component instanceof GenericTreeGridComponent) {
      for (const node of nodes) {
        const treeNode: TreeNode | null = component.getTreeNode(node.data);

        if (treeNode) {
          component.replaceTreeNode(treeNode, node);
        }
      }

      component.entities = [...component.entities];

      return observableOf({status: true, content: null});
    }
  }
}
