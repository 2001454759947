
import {of as observableOf, Observable} from 'rxjs';
import {AbstractExecutionStep} from '../../../../../core/executor/abstract-execution-step';
import {ExecutionStepStatus} from '../../../../../core/executor/execution-step-status';

export class OpenAddressFaxPhoneWindowExecutionStep extends AbstractExecutionStep {

  doExecute(): Observable<ExecutionStepStatus> {
    const payload = this.getPayload(),
      entity = payload.getValue().entity;

    if (!entity) {
      return this.getFailObservable('You need to pass entity as part of Payload value!');
    }

    return this.doOpen(entity);
  }

  private doOpen(entity: {
    faxCountryCode: string,
    faxMobileAreaCode: string,
    faxMobileNumber: string,
    faxExtension: string
  }): Observable<ExecutionStepStatus> {
    const phoneCountryCode = entity.faxCountryCode || '',
      phoneAreaCode = entity.faxMobileAreaCode || '',
      phoneNumber = entity.faxMobileNumber || '',
      phoneExtension = entity.faxExtension || '';

    const fullNumber = phoneCountryCode +
      phoneAreaCode +
      phoneNumber +
      phoneExtension;

    document.location.href = `tel:${fullNumber}`;

    return observableOf({status: true, content: null});
  }
}
