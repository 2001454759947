import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit} from '@angular/core';
import {Element} from '../../services/element/element';
import {ElementAudit} from '../../services/element/element-audit';
import {GenericCrudService} from '../../services/generic-crud.service';
import {ChangeDetectorRefHelper} from '../../helpers/change-detector-ref.helper';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-element-audit',
  templateUrl: './element-audit.component.html',
  styleUrls: ['./element-audit.component.scss']
})
export class ElementAuditComponent implements OnInit {

  @Input() public element: Element;
  @Input() public audit: ElementAudit;
  @Input() public entity = null;
  public isLoadingData = true;
  public tabIndex = 0;

  public constructor(
    public cdr: ChangeDetectorRef,
    protected genericCrudService: GenericCrudService
  ) {

  }

  public ngOnInit(): void {
    this.genericCrudService.get(`superadmin/elementaudits/element/${this.element.id}`)
      .subscribe((audit: ElementAudit) => {
        this.audit = audit;
        this.isLoadingData = false;
        ChangeDetectorRefHelper.detectChanges(this);
      });
  }

  public onTabChange(event: {index: number}): void {
    this.tabIndex = event.index;
  }
}
