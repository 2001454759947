import {AbstractFormActionHandler} from './abstract-form-action-handler';
import {GenericCrudService} from '../../../services/generic-crud.service';

export class LoadEntityFromApiActionHandler extends AbstractFormActionHandler {

  public constructor(
    private genericCrudService: GenericCrudService
  ) {
    super();
  }

  public handleAction(): void {
    if (this.isActionValid()) {
      const api = this.getParamValue('api');
      this.genericCrudService.getEntities(api).subscribe((entities) => {
        const component = this.getComponent();

        if (entities && entities instanceof Array && entities[0]) {
          component.entity = entities[0];
        }

        if (entities && entities instanceof Object) {
          component.entity = entities;
        }
      });
    }
  }

  private isActionValid(): boolean {
    return this.getParamValue('api') && this.getComponent();
  }
}
