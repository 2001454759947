import {Injectable} from '@angular/core';
import {ToolbarItem} from '../../../../../services/element/toolbar-item';
import {ToolbarItemDisabledCheckInterface} from './toolbar-item-disabled-check-factory.service';
import {ToolbarItemAbstractDisabledCheckService} from './toolbar-item-abstract-disabled-check.service';


@Injectable()
export class ToolbarItemCaContractOfEmploymentDisabledCheckService extends ToolbarItemAbstractDisabledCheckService implements ToolbarItemDisabledCheckInterface {

  isDisabled(): boolean {
    return (this.toolbarItem.contextTarget === ToolbarItem.CONTEXT_TARGET_GRID) ? this.checkEntity() : true;
  }

  private checkEntity(): boolean {
    return this.isDisabledGlobally()
      || (this.entity ? (this.entity.isDirty || this.isEntityDisabled(this.entity) || this.entity.isChanged || this.entity['isIncreaseCycleComplete'] || this.entity['specialType'] !== 'worker') : false);
  }

}
