<app-generic-loading
  *ngIf="isLoadingData"
></app-generic-loading>

<div class="ui-g" *ngIf="audit && !isLoadingData">
  <div class="ui-g-12">
    <app-element-audit-tree
      [audit]="audit"
      [entity]="entity"
      [datamodel]="element.datamodel"
    ></app-element-audit-tree>
  </div>

  <div class="ui-g-12" *ngIf="audit.subAudits && audit.subAudits.length > 0">
    <p-tabView
      (onChange)="onTabChange($event)"
    >
      <p-tabPanel
        [header]="subAudit.datamodel.name"
        *ngFor="let subAudit of audit.subAudits; let i = index" [selected]="i === tabIndex"
      >
        <app-element-audit-tree
          *ngIf="i === tabIndex"
          [masterAudit]="audit"
          [audit]="subAudit"
          [entity]="entity"
          [masterDatamodel]="audit.datamodel"
          [datamodel]="subAudit.datamodel"
        ></app-element-audit-tree>
      </p-tabPanel>
    </p-tabView>
  </div>
</div>
