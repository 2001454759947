import {ChangeDetectionStrategy, Component} from '@angular/core';
import {GenericElementAbstract} from '../../generic-element-abstract.component';
import {Observable, of as observableOf} from 'rxjs';
import {EntityValidatorStatus} from '../../../../validators/services/entity-validator';
import {ExecutorService} from '../../../../../core/executor/executor.service';
import {GenericElementValidationExecutionStepsFactory} from '../../../services/generic/generic-element-validation-execution-steps-factory';

@Component({
  selector: 'app-custom-employee-overview',
  templateUrl: './employee-overview.component.html',
  styleUrls: ['./employee-overview.component.scss'],
  providers: [
    ExecutorService,
    GenericElementValidationExecutionStepsFactory
  ]
})
export class EmployeeOverviewComponent extends GenericElementAbstract {
  public toolbarContextName = 'EmployeeOverviewComponent';
  public entities: any = {};
  public legendData: {aku: string, aiigew: string, color: string}[];

  ngOnInit() {
    super.ngOnInit();

    this.genericCrudService.getEntities(
      this.element.datamodel.apiRoute + '/grouped/user'
    ).subscribe((response) => {
      this.entities = response.data;
      this.cdr.detectChanges();
    })

    this.legendData = [
      {aku: 'BG A', aiigew: 'VW I', color: 'palegreen'},
      {aku: 'BG B', aiigew: 'VW II', color: 'yellow'},
      {aku: 'BG C', aiigew: 'VW III', color: 'aquamarine'},
      {aku: 'BG D', aiigew: 'VW IV', color: 'plum'},
      {aku: 'BG E', aiigew: 'VW V', color: 'orange'},
      {aku: 'BG F', aiigew: 'VW VI', color: 'lightgray'},
      /*{aku: '', aiigew: '', color: ''},
      {aku: 'Urlaub', aiigew: '', color: 'salmon'},
      {aku: 'krank', aiigew: '', color: 'red'}*/
    ]
  }

  public onComponentInit(): void {

  }

  public onDestroyComponent(): void {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  public getSelectedEntity(): any {
    return this.selectedMasterEntity || null;
  }

  public onSave(): Observable<any> {
    return observableOf(null);
  }

  public recheckToolbarItems(): void {

  }

  public hasChanges(checkEmbedded: boolean = false): boolean {
    return false;
  }

  public onAfterSave(): Observable<any> {
    return observableOf(null);
  }

  public onChange(): Observable<any> {
    return observableOf(null);
  }

  public doValidate(): Observable<EntityValidatorStatus> {
    return observableOf({
      entity: null,
      isValid: true,
      error: '',
      errorFields: []
    });
  }

  public onRefresh(): Observable<any> {
    return observableOf(null);
  }

  public getToolbarItemsExtraParams() {
    return {
      'EmployeeOverviewComponent': this
    };
  }

  colspanLength(user: any) {
    return Object.keys(user.value.customers).length
  }
}
