import {Component} from '@angular/core';
import {AbstractEditExpertSearchFieldValue} from '../abstract-edit-expert-search-field-value';

@Component({
  selector: 'app-search-management-edit-text-edit-expert-search-field-value',
  templateUrl: './text-edit-expert-search-field-value.component.html'
})
export class SearchManagementTextEditExpertSearchFieldValueComponent extends AbstractEditExpertSearchFieldValue {

  public onKeyUp(event): void {
    this.onChange.emit({
      value: this.field.fieldValue,
      labelValue: this.field.fieldValue
    });
  }
}
