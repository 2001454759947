<div class="container" fxLayout="column" fxLayoutAlign="space-between stretch">

  <div fxFlex="30px" fxLayout="row" fxLayoutAlign="space-between stretch">
    <div fxFlex="48">
      <p-dropdown
        [options]="typeOptions"
        [(ngModel)]="this.field.typeValue"
        (onChange)="onFieldTypeChange($event)"
        [style]="{'width': '100%'}"
        appendTo="body"
        placeholder="---"
      ></p-dropdown>
    </div>

    <div fxFlex="48">
      <p-dropdown
        [options]="comparatorOptions"
        [(ngModel)]="this.field.comparator"
        [style]="{'width': '100%'}"
        appendTo="body"
        placeholder="---"
      ></p-dropdown>
    </div>
  </div>

  <div fxFlex="30px">
    <app-dialog-choose-datamodel
      class="dialog-choose-container"
      [datamodel]="this.field.fieldKey"
      [datamodelId]="expertSearch.datamodel.id"
      (datamodelChange)="onFieldChange($event)"
    ></app-dialog-choose-datamodel>
  </div>

  <div fxFlex="100" fxLayout="column" fxLayoutAlign="start stretch" [ngSwitch]="this.field.typeValue">

    <ng-template ngSwitchCase="text">
      <app-search-management-edit-text-edit-expert-search-field-value
        [expertSearch]="expertSearch"
        [container]="container"
        [field]="field"
        (onChange)="onValueChange($event)"
      >
      </app-search-management-edit-text-edit-expert-search-field-value>
    </ng-template>
    <ng-template ngSwitchCase="number">
      <app-search-management-edit-number-edit-expert-search-field-value
        [expertSearch]="expertSearch"
        [container]="container"
        [field]="field"
        (onChange)="onValueChange($event)"
      >
      </app-search-management-edit-number-edit-expert-search-field-value>
    </ng-template>
    <ng-template ngSwitchCase="checkbox">
      <app-search-management-edit-checkbox-edit-expert-search-field-value
        [expertSearch]="expertSearch"
        [container]="container"
        [field]="field"
        (onChange)="onValueChange($event)"
      >
      </app-search-management-edit-checkbox-edit-expert-search-field-value>
    </ng-template>
    <ng-template ngSwitchCase="calendarDate">
      <app-search-management-edit-calendar-date-expert-search-field-value
        [expertSearch]="expertSearch"
        [container]="container"
        [field]="field"
        (onChange)="onValueChange($event)"
      >
      </app-search-management-edit-calendar-date-expert-search-field-value>
    </ng-template>
    <ng-template ngSwitchCase="dropdown">
      <app-search-management-edit-dropdown-edit-expert-search-field-value
        [expertSearch]="expertSearch"
        [container]="container"
        [field]="field"
        (onChange)="onValueChange($event)"
      ></app-search-management-edit-dropdown-edit-expert-search-field-value>
    </ng-template>
    <ng-template ngSwitchCase="autocomplete">
      <app-search-management-edit-autocomplete-edit-expert-search-field-value
        [expertSearch]="expertSearch"
        [container]="container"
        [field]="field"
        (onChange)="onValueChange($event)"
      ></app-search-management-edit-autocomplete-edit-expert-search-field-value>
    </ng-template>
    <ng-template ngSwitchCase="autocompleteMulti">
      <app-search-management-edit-autocomplete-multi-edit-expert-search-field-value
        [expertSearch]="expertSearch"
        [container]="container"
        [field]="field"
        (onChange)="onValueChange($event)"
      ></app-search-management-edit-autocomplete-multi-edit-expert-search-field-value>
    </ng-template>
    <ng-template ngSwitchCase="genericGrid">
      <app-search-management-edit-generic-grid-edit-expert-search-field-value
        [expertSearch]="expertSearch"
        [container]="container"
        [field]="field"
        (onChange)="onValueChange($event)"
      ></app-search-management-edit-generic-grid-edit-expert-search-field-value>
    </ng-template>
  </div>

  <div fxFlex="20px">
    <button
      type="button"
      (click)="onFieldSave($event)"
      pButton
      class="save-button"
      label="Save"
      [style]="{'width': '100%'}"
    ></button>
  </div>

</div>
