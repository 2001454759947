import { Component, OnInit } from '@angular/core';
import { Message } from 'primeng/primeng';
import { MessageService, ConfirmationDefaults } from './message.service';

@Component({
  selector: 'app-messages',
  template: `
    <p-confirmDialog
      [acceptLabel]="confirmationDefaults.acceptLabel"
      [rejectLabel]="confirmationDefaults.rejectLabel"
    ></p-confirmDialog>
    <p-growl
      [value]="growlMessages"
      [sticky]="growlOptions.sticky"
      [life]="growlOptions.life"
    ></p-growl>
  `
})
export class MessageComponent implements OnInit {

  public confirmationDefaults: ConfirmationDefaults;

  public growlOptions: GrowlOptions;
  public growlMessages: Message[] = [];

  constructor(
    private messageService: MessageService
  ) { }

  ngOnInit() {
    this.growlOptions = this.messageService.growl.options;

    this.confirmationDefaults = this.messageService.confirmationDefaults;

    this.messageService.confirmationDefaultsChanged.subscribe((newConfirmationDefaults: ConfirmationDefaults) => {
      this.confirmationDefaults = newConfirmationDefaults;
    });
    this.messageService.growl.optionsChanged.subscribe((newOptions: GrowlOptions) => {
      this.growlOptions = newOptions;
    });
    this.messageService.growl.add.subscribe((message: Message) => {
      this.growlMessages = [message];
    });
  }
}
