import { GenericElementAbstract } from '../../../../shared/content-renderer/elements/generic-element-abstract.component';
import { Event } from 'app/core/events/interfaces/event';

export class GenericElementsEvent implements Event {
    
    private components: GenericElementAbstract[] = [];

    public constructor(components: GenericElementAbstract[] = []) {
        this.components = components;
    }

    public setComponent(components: GenericElementAbstract[] = []) {
        this.components = components;
        return this;
    }

    public getComponents(): GenericElementAbstract[] {
        return this.components;
    }

}
