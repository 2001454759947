<div
  *ngIf="element.datamodel"
  (click)="highlight()"
  [class]="isHighlighted() ? 'highlighted full-calendar-container' : 'full-calendar-container'"
>
  <ng-template [ngIf]="element.topToolbarItems && element.topToolbarItems.length > 0" class="header" pTemplate="caption">
    <app-generic-toolbar [params]="getToolbarExtraParams()" [items]="element.topToolbarItems" [additionalItems]="element.additionalToolbarItems" [entity]="selectedEntity"></app-generic-toolbar>
  </ng-template>

  <p-fullCalendar #fullCalendar [events]="entities" [options]="options"></p-fullCalendar>
</div>
