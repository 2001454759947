import {Datamodel} from '../datamodel/datamodel';
import {Icon} from '../../models/Icon';
import {ToolbarItem} from '../element/toolbar-item';

export class QuickLink {
  id: number;
  name: string;
  tooltip: string;
  icon: Icon;
  iconId: number;
  datamodel: Datamodel;
  datamodelId: number;
  datamodelRecord: any;
  datamodelRecordId: number;
  url: string;
  sortPosition: number;
  uniqueId: string;

  _embedded?: {
    icon: Icon;
  };

  public static toToolbarItem(quickLink: QuickLink): ToolbarItem {
    const embedded = quickLink._embedded || {
        icon: null
      },
      icon = embedded.icon;

    return {
      id: null,
      name: quickLink.name,
      label: quickLink.name,
      type: ToolbarItem.TYPE_TOOLBAR,
      onclick: 'openQuickLink',
      onmouseover: quickLink.tooltip,
      rank: quickLink.sortPosition,
      isLeft: 0,
      isText: 0,
      isSeparator: 0,
      image: '',
      icon: icon,
      uniqueId: quickLink.uniqueId,
      url: quickLink.url
    };
  }

  constructor() { }
}
