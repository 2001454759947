import {ChangeDetectionStrategy, Component} from '@angular/core';
import {ToolbarItemAbstract} from '../toolbar-item-abstract.component';
import {GenericTreeGridComponent} from '../../../../generic-tree-grid/generic-tree-grid.component';
import {GenericCrudService} from '../../../../../../services/generic-crud.service';
import {MessageGrowlService} from '../../../../../../../core/message/message-growl.service';
import {ComponentService} from '../../../../../services/component-highlight-stack.service';
import {GenericTurboGridComponent} from '../../../../generic-turbo-grid/generic-turbo-grid.component';
import {ToolbarItemCheckService} from '../../../services/check/toolbar-item-check.service';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'toolbar-item-bulk-add',
  template: ''
})
export class ToolbarItemBulkAddComponent extends ToolbarItemAbstract {

  constructor(
    protected componentService: ComponentService,
    protected messageGrowlService: MessageGrowlService,
    protected genericCrudService: GenericCrudService,
    protected toolbarItemCheckService: ToolbarItemCheckService
  ) {
    super();
  }

  public click() {
    if (this.isInCurrentContext(ToolbarItemAbstract.CONTEXT_GRID)) {
      this.handleClickBasedOnGenericGrid(this.getParam('gridComponent'));
    } else if (this.isInCurrentContext(ToolbarItemAbstract.CONTEXT_TREE)) {
      this.handleClickBasedOnGenericTree(this.getParam('treeComponent'));
    }
  }

  protected handleClickBasedOnGenericGrid(gridComponent: GenericTurboGridComponent) {
    if (!gridComponent.element.datamodel || !gridComponent.element.datamodel.apiRoute) {
      console.log('Module element has no datamodel assigned!');
    }

    this.addGenericGridNewRow(gridComponent);
  }

  protected handleClickBasedOnGenericTree(treeComponent: GenericTreeGridComponent) {
    if (!treeComponent.element.datamodel || !treeComponent.element.datamodel.apiRoute) {
      console.log('Module element has no datamodel assigned!');
    }

    this.addGenericGridNewRow(treeComponent);
  }

  protected addGenericGridNewRow(component: GenericTurboGridComponent|GenericTreeGridComponent) {

    const data = this.getEntitiesForAdding(component);

    for (const item of data) {
      const newEntity = this.cloneEntityAndReplace(component.emptyEntity, item);
      component.startNewEntityAdd(newEntity);
    }
    this.toolbarItemCheckService.check(null);

  }

  cloneEntityAndReplace(entity, replaceProperties) {
    const clone = Object.assign({}, entity);

    for (const property of replaceProperties) {
      clone[property.name] = property.value;
    }

    return clone;
  }

  private getEntitiesForAdding(component: GenericTurboGridComponent|GenericTreeGridComponent) {
    switch (component.element.datamodel.name) {
      case 'PhoenixBundle.WorkingTimeModelPosition':
        return this.getEntitiesForAddingWorkingTimeModelPosition(component);
      default:
        return [];
    }
  }

  private getEntitiesForAddingWorkingTimeModelPosition(component: GenericTurboGridComponent|GenericTreeGridComponent) {
    let sequencePosition = 0;
    for (const entity of component.entities) {
      if (entity['sequencePosition'] && entity['sequencePosition'] > sequencePosition) {
        sequencePosition = entity['sequencePosition'];
      }
    }
    return [
      [
        {'name': 'notes', 'value': 'So'},
        {'name': 'isWorkingDay', 'value': false},
        {'name': 'sequencePosition', 'value': sequencePosition + 7}
      ],
      [
        {'name': 'notes', 'value': 'Sa'},
        {'name': 'isWorkingDay', 'value': false},
        {'name': 'sequencePosition', 'value': sequencePosition + 6}
      ],
      [
        {'name': 'notes', 'value': 'Fr'},
        {'name': 'isWorkingDay', 'value': true},
        {'name': 'sequencePosition', 'value': sequencePosition + 5}
      ],
      [
        {'name': 'notes', 'value': 'Do'},
        {'name': 'isWorkingDay', 'value': true},
        {'name': 'sequencePosition', 'value': sequencePosition + 4}
      ],
      [
        {'name': 'notes', 'value': 'Mi'},
        {'name': 'isWorkingDay', 'value': true},
        {'name': 'sequencePosition', 'value': sequencePosition + 3}
      ],
      [
        {'name': 'notes', 'value': 'Di'},
        {'name': 'isWorkingDay', 'value': true},
        {'name': 'sequencePosition', 'value': sequencePosition + 2}
      ],
      [
        {'name': 'notes', 'value': 'Mo'},
        {'name': 'isWorkingDay', 'value': true},
        {'name': 'sequencePosition', 'value': sequencePosition + 1}
      ]
    ];
  }
}
