/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./user-import.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./user-grid/user-grid.component.ngfactory";
import * as i3 from "./user-grid/user-grid.component";
import * as i4 from "@ngx-translate/core";
import * as i5 from "../../core/message/message-growl.service";
import * as i6 from "../services/generic-crud.service";
import * as i7 from "../services/api.builder.service";
import * as i8 from "../services/request-caching.service";
import * as i9 from "../../core/authentication/authentication.service";
import * as i10 from "./user-import.component";
import * as i11 from "../content-renderer/services/entity-sharing.service";
var styles_UserImportComponent = [i0.styles];
var RenderType_UserImportComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_UserImportComponent, data: {} });
export { RenderType_UserImportComponent as RenderType_UserImportComponent };
export function View_UserImportComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "ui-grid ui-grid-responsive ui-fluid"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "user-grid-component", [], null, null, null, i2.View_UserGridComponent_0, i2.RenderType_UserGridComponent)), i1.ɵdid(2, 245760, null, 0, i3.UserGridComponent, [i4.TranslateService, i5.MessageGrowlService, i6.GenericCrudService, i7.ApiBuilderService, i8.RequestCachingService, i9.AuthenticationService, i1.ChangeDetectorRef], null, null)], function (_ck, _v) { _ck(_v, 2, 0); }, null); }
export function View_UserImportComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-user-import", [], null, null, null, View_UserImportComponent_0, RenderType_UserImportComponent)), i1.ɵdid(1, 245760, null, 0, i10.UserImportComponent, [i4.TranslateService, i5.MessageGrowlService, i11.EntitySharingService, i1.ChangeDetectorRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var UserImportComponentNgFactory = i1.ɵccf("app-user-import", i10.UserImportComponent, View_UserImportComponent_Host_0, { gridComponent: "gridComponent" }, {}, []);
export { UserImportComponentNgFactory as UserImportComponentNgFactory };
