
import {of as observableOf, Observable} from 'rxjs';
import {AbstractExecutionStep} from '../../../../core/executor/abstract-execution-step';
import {ExecutionStepStatus} from '../../../../core/executor/execution-step-status';
import {AbstractGenericGridComponent} from '../../../content-renderer/elements/abstract-generic-grid.component';
import {Entity} from '../../../helpers/entity';
import {FieldMetadataGrid} from '../../module/module-element-field-metadata-grid';
import {FormViewerComponent} from '../../../form-viewer/form-viewer.component';
import {tap} from 'rxjs/operators';
import {TOAST_LIFE_INFINITE, ToastComponentsRegistry, ToastService} from '../../../../core/service/toast.service';

export class ImportDefaultConditionsStep extends AbstractExecutionStep {

  public doExecute(): Observable<ExecutionStepStatus> {
    const payload = this.getPayload(),
      component = payload.getValue().component ? payload.getValue().component : payload.getValue();

    if (!(component instanceof FormViewerComponent)) {
      return this.getFailObservable('You need to pass FormViewerComponent as Payload value!');
    }
    return this.doImport(component);
  }

  doImport(formViewerComponent: FormViewerComponent): Observable<ExecutionStepStatus> {
    this.getToastService().custom(ToastComponentsRegistry.PROGRESS_BAR, {
      severity: 'info',
      life: TOAST_LIFE_INFINITE,
      closable: false,
      summary: 'Wird hinzugefügt...'
    });

    const entity = formViewerComponent.entity;
    const data = {
      'salaryTypeId': entity
    };
    this.genericCrudService.customPut(`phoenix/salarytypes/defaults/${entity.id}`, data, {embedded: 'surchargeWageGroupWageEntries'}).pipe(
      tap((salaryType) => {
        formViewerComponent.setEntity(salaryType);
        formViewerComponent.onRefresh();
        this.getToastService().clear(ToastComponentsRegistry.PROGRESS_BAR);
      })
    ).subscribe();

    return observableOf({status: true, content: null});
  }
  private getToastService(): ToastService {
    return this.injector.get(ToastService, null);
  }
}
