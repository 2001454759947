import {JobInterface} from '../../../core/job-runner/job/job.interface';
import {JobContext} from '../../../core/job-runner/context/job.context';
import {Observable} from 'rxjs/Observable';
import {JobExecutionStatus} from '../../../core/job-runner/job/job-execution-status';
import {ConditionInterface} from '../../../core/job-runner/condition/condition.interface';
import {AbstractJob} from '../../../core/job-runner/job/abstract.job';
import {GenericTreeGridComponent} from '../../content-renderer/elements/generic-tree-grid/generic-tree-grid.component';

export class SetPrecalculationArticleOtherEmbeddedJob extends AbstractJob implements JobInterface {
  run(context: JobContext, condition: ConditionInterface): Observable<JobExecutionStatus> {
    return Observable.create((observer) => {
      const component: GenericTreeGridComponent = context.component;

      component.embeddedFields = [...component.embeddedFields, 'salaryType'];

      observer.next({
        status: true,
        content: null,
        jobId: this.jobId
      });
    });
  }
}
