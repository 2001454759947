import {map, publishReplay, refCount, catchError} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';


import { AppHttpService } from '../../../app-http.service';
import { Element } from './element';
import { DataModelFilter } from './data-model-filter';
import {HttpClient} from '@angular/common/http';

@Injectable()
export class DataModelFilterCrudService extends AppHttpService {

  private dataModelFiltersUrl: string = '/superadmin/elements/:elementId/datamodelfilters';
  private url: string = '';

  constructor(private http: HttpClient) {
    super();
    this.url = this.getApiUrl() + this.dataModelFiltersUrl;
  }

  /**
   *
   * @param element
   * @returns {Observable<R>}
   */
  getDataModelFilters(element: Element): Observable<DataModelFilter[]> {
    if (!element || !element.id) {
      throw new Error('Element object or Element id not given.');
    }

    const url = this._createUrl(element);

    return this.http.get(url).pipe(
      map(this.mapResponseAsJson),
      publishReplay(1),
      refCount(),
      catchError(this.handleError));
  }


  /**
   * @param element
   * @param dataModelFilter
   * @returns {Observable<any>}
   */
  deleteToolbarItem(element: Element, dataModelFilter: DataModelFilter): Observable<any> {
    if (!dataModelFilter || !dataModelFilter.id) {
      throw new Error('DataModelFilter object or DataModelFilter id not given.');
    }

    return this.http.delete(this._createUrl(element) + '/' + dataModelFilter.id);

  }

  /**
   * @param element
   * @param dataModelFilter
   * @returns {Observable<R>}
   */
  createDataModelFilter(element: Element, dataModelFilter: DataModelFilter): Observable<any> {
    if (!dataModelFilter) {
      throw new Error('DataModelFilter object not given.');
    }

    return this.http.post(this._createUrl(element) + '', JSON.stringify(dataModelFilter)).pipe(
      map((response: any) => {
        // xcentric check this!
        return response;
      }));
  }

  /**
   *
   * @param element
   * @param dataModelFilter
   * @returns {Observable<R>}
   */
  editDataModelFilter(element: Element, dataModelFilter: DataModelFilter): Observable<DataModelFilter> {
    if (!dataModelFilter || !dataModelFilter.id) {
      throw new Error('DataModelFilter object or DataModelFilter id not given.');
    }

    return this.http.put(`${this._createUrl(element)}/${dataModelFilter.id}`, JSON.stringify(dataModelFilter)).pipe(
      map(this.mapResponseAsJson),
      catchError(this.handleError));

  }

  _createUrl(element: Element) {
    const re = /:elementId/gi;

    return this.url.replace(re, element.id);
  }
}
