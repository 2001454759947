import {ChangeDetectionStrategy, Component} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {ToolbarItemAbstract} from '../toolbar-item-abstract.component';
import {GenericCrudService} from '../../../../../../services/generic-crud.service';
import {AbstractGenericGridComponent} from '../../../../abstract-generic-grid.component';
import {MessageGrowlService} from '../../../../../../../core/message/message-growl.service';

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'app-toolbar-item-generate-work-dates',
    template: ''
})
export class ToolbarItemGenerateWorkDatesComponent extends ToolbarItemAbstract {

  constructor(
    protected genericCrudService: GenericCrudService,
    protected messageGrowlService: MessageGrowlService,
    protected translate: TranslateService
  ) {
    super();
  }

  ngOnInit() {
  }

  public click() {
    const component = this.getComponent();

    if (component instanceof AbstractGenericGridComponent && component.getSelectedEntity()) {
      const entity = component.getSelectedEntity();

      if (entity.id) {
        this.genericCrudService.customPost(`phoenix/subassignments/${entity.id}/timeentries`, {}).subscribe((timeEntries) => {
          this.messageGrowlService.success(this.translate.instant('COMMON.SUCCESS') + '.');
        });
      } else {
        return this.messageGrowlService.error('No entity has been selected!');
      }
    }
  }
}
