import {ChangeDetectionStrategy, Component} from '@angular/core';
import { cloneDeep } from 'lodash';
import {Guid} from 'guid-typescript';
import {ToolbarItemOpenModuleContractOfEmploymentComponent} from './toolbar-item-open-module-contract-of-employment.component';
import {MessageGrowlService} from '../../../../../../../../core/message/message-growl.service';
import {DoubleClickService} from '../../../../../../services/double-click.service';
import {GenericDialogModuleService} from '../../../../../generic-dialog/service/generic-dialog-module.service';
import {GenericCrudService} from '../../../../../../../services/generic-crud.service';
import {EntityDirtyStoreService} from '../../../../../../services/entity-dirty-store.service';
import {EntityManagerService} from '../../../../../../../../core/service/entity-manager/entity-manager.service';
import {ModulesStateService} from '../../../../../../services/modules-state.service';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-toolbar-item-open-module-contract-of-employment2',
  template: ''
})
export class ToolbarItemOpenModuleContractOfEmployment2Component extends ToolbarItemOpenModuleContractOfEmploymentComponent {

  constructor(
    protected messageGrowlService: MessageGrowlService,
    protected doubleClickService: DoubleClickService,
    protected genericDialogModuleService: GenericDialogModuleService,
    protected genericCrudService: GenericCrudService,
    protected entityDirtyStoreService: EntityDirtyStoreService,
    protected entityManager: EntityManagerService,
    protected modulesStateService: ModulesStateService
  ) {
    super(messageGrowlService, doubleClickService, genericDialogModuleService,
      genericCrudService, entityDirtyStoreService, entityManager, modulesStateService);
  }

  protected buildEntity(gridComponent) {
    const latestContract = this.getLatestContract(gridComponent);
    const newEntity = cloneDeep(latestContract);
    newEntity.id = null;
    newEntity.uniqueId = Guid.create().toString();
    newEntity._links = null;

    this.entityManager.persist(newEntity, {property: 'isDisabledAgreementDate', newValue: typeof latestContract['exitAgreementDate'] !== 'undefined' && latestContract['exitAgreementDate'] !== null });
    this.entityManager.persist(newEntity, {property: 'contractNumber', newValue: null, force: true });
    return newEntity;
  }

}
