/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../../../../../../../node_modules/primeng/components/dialog/dialog.ngfactory";
import * as i2 from "primeng/components/dialog/dialog";
import * as i3 from "../../../../../../../../../node_modules/primeng/components/fileupload/fileupload.ngfactory";
import * as i4 from "primeng/components/fileupload/fileupload";
import * as i5 from "@angular/platform-browser";
import * as i6 from "@angular/common/http";
import * as i7 from "@ngx-translate/core";
import * as i8 from "@angular/common";
import * as i9 from "./toolbar-item-upload-leased-employee-document.component";
import * as i10 from "../../../../../../services/request-caching.service";
import * as i11 from "../../../../../../services/local-storage-data.service";
import * as i12 from "../../../../../../../core/service/user-session.service";
var styles_ToolbarItemUploadLeasedEmployeeDocumentComponent = [".ui-button-text {\n      padding-left: 20px !important;\n    }"];
var RenderType_ToolbarItemUploadLeasedEmployeeDocumentComponent = i0.ɵcrt({ encapsulation: 0, styles: styles_ToolbarItemUploadLeasedEmployeeDocumentComponent, data: {} });
export { RenderType_ToolbarItemUploadLeasedEmployeeDocumentComponent as RenderType_ToolbarItemUploadLeasedEmployeeDocumentComponent };
function View_ToolbarItemUploadLeasedEmployeeDocumentComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 12, "p-dialog", [["appendTo", "body"], ["header", "Upload"], ["showEffect", "fade"]], null, [[null, "onAfterHide"], [null, "visibleChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("onAfterHide" === en)) {
        var pd_0 = (_co.dialogHide() !== false);
        ad = (pd_0 && ad);
    } if (("visibleChange" === en)) {
        var pd_1 = ((_co.isDialogVisible = $event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i1.View_Dialog_0, i1.RenderType_Dialog)), i0.ɵdid(1, 180224, null, 2, i2.Dialog, [i0.ElementRef, i0.Renderer2, i0.NgZone], { visible: [0, "visible"], header: [1, "header"], modal: [2, "modal"], appendTo: [3, "appendTo"], width: [4, "width"], height: [5, "height"] }, { visibleChange: "visibleChange" }), i0.ɵqud(603979776, 1, { headerFacet: 1 }), i0.ɵqud(603979776, 2, { footerFacet: 1 }), (_l()(), i0.ɵeld(4, 0, null, 1, 8, "div", [["class", "ui-grid ui-grid-responsive ui-fluid"]], null, null, null, null, null)), (_l()(), i0.ɵeld(5, 0, null, null, 7, "div", [["class", "ui-grid-row"]], null, null, null, null, null)), (_l()(), i0.ɵeld(6, 0, null, null, 6, "div", [["class", "ui-grid-col-8"]], null, null, null, null, null)), (_l()(), i0.ɵeld(7, 0, null, null, 5, "p-fileUpload", [["maxFileSize", "100000000"], ["multiple", "multiple"], ["name", "file[]"], ["withCredentials", "true"]], null, [[null, "onUpload"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("onUpload" === en)) {
        var pd_0 = (_co.onUpload($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i3.View_FileUpload_0, i3.RenderType_FileUpload)), i0.ɵdid(8, 5423104, null, 1, i4.FileUpload, [i0.ElementRef, i5.DomSanitizer, i0.NgZone, i6.HttpClient], { name: [0, "name"], url: [1, "url"], multiple: [2, "multiple"], withCredentials: [3, "withCredentials"], maxFileSize: [4, "maxFileSize"], chooseLabel: [5, "chooseLabel"], uploadLabel: [6, "uploadLabel"], cancelLabel: [7, "cancelLabel"] }, { onUpload: "onUpload" }), i0.ɵqud(603979776, 3, { templates: 1 }), i0.ɵpid(131072, i7.TranslatePipe, [i7.TranslateService, i0.ChangeDetectorRef]), i0.ɵpid(131072, i7.TranslatePipe, [i7.TranslateService, i0.ChangeDetectorRef]), i0.ɵpid(131072, i7.TranslatePipe, [i7.TranslateService, i0.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.isDialogVisible; var currVal_1 = "Upload"; var currVal_2 = true; var currVal_3 = "body"; var currVal_4 = 500; var currVal_5 = 550; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5); var currVal_6 = "file[]"; var currVal_7 = i0.ɵinlineInterpolate(3, "", _co.baseUrl, "/api/phoenix/leasedemployeedocuments/", _co.leasedEmployee.id, "/upload?organisationId=", _co.organisationId, ""); var currVal_8 = "multiple"; var currVal_9 = "true"; var currVal_10 = "100000000"; var currVal_11 = i0.ɵinlineInterpolate(1, "", i0.ɵunv(_v, 8, 5, i0.ɵnov(_v, 10).transform("COMMON.CHOOSE")), ""); var currVal_12 = i0.ɵinlineInterpolate(1, "", i0.ɵunv(_v, 8, 6, i0.ɵnov(_v, 11).transform("COMMON.UPLOAD")), ""); var currVal_13 = i0.ɵinlineInterpolate(1, "", i0.ɵunv(_v, 8, 7, i0.ɵnov(_v, 12).transform("COMMON.CANCEL")), ""); _ck(_v, 8, 0, currVal_6, currVal_7, currVal_8, currVal_9, currVal_10, currVal_11, currVal_12, currVal_13); }, null); }
export function View_ToolbarItemUploadLeasedEmployeeDocumentComponent_0(_l) { return i0.ɵvid(2, [(_l()(), i0.ɵand(16777216, null, null, 1, null, View_ToolbarItemUploadLeasedEmployeeDocumentComponent_1)), i0.ɵdid(1, 16384, null, 0, i8.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.isDialogVisible; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_ToolbarItemUploadLeasedEmployeeDocumentComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-toolbar-item-upload-leased-employee-document", [], null, null, null, View_ToolbarItemUploadLeasedEmployeeDocumentComponent_0, RenderType_ToolbarItemUploadLeasedEmployeeDocumentComponent)), i0.ɵdid(1, 245760, null, 0, i9.ToolbarItemUploadLeasedEmployeeDocumentComponent, [i10.RequestCachingService, i11.LocalStorageDataService, i12.UserSessionService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ToolbarItemUploadLeasedEmployeeDocumentComponentNgFactory = i0.ɵccf("app-toolbar-item-upload-leased-employee-document", i9.ToolbarItemUploadLeasedEmployeeDocumentComponent, View_ToolbarItemUploadLeasedEmployeeDocumentComponent_Host_0, { params: "params" }, {}, []);
export { ToolbarItemUploadLeasedEmployeeDocumentComponentNgFactory as ToolbarItemUploadLeasedEmployeeDocumentComponentNgFactory };
