import {Element, ElementAssociationData} from './element';
import {Datamodel} from "../../services/datamodel/datamodel";

export class ElementAssociationDatamodelData extends ElementAssociationData {
  constructor() {
    super();
    this.type = 'association-data';
    this.typeElement = 'association-data';
    this.orientation = 'left';
  }
}
