import {Component, OnInit} from '@angular/core';
import {AbstractPreviewFileTypeComponent} from '../abstract-preview-file-type.component';

@Component({
  selector: 'app-generic-file-preview-text',
  templateUrl: './generic-file-preview-text.component.html',
})
export class GenericFilePreviewTextComponent extends AbstractPreviewFileTypeComponent implements OnInit {

  public ngOnInit(): void {

  }
}
