import {of as observableOf,  Observable } from 'rxjs';
import { AbstractAsyncValidator } from './abstract-async-validator';
import { ValidationStatus } from '../services/validation';

export class UserOldPasswordValidator extends AbstractAsyncValidator {

  public getConstraint(): any {
    const postFields = this.getFieldsToCheck();

    return {
      'name': 'userOldPassword',
      'fields': postFields
    };
  }

  public isValid(value: any): boolean {
    return true;
  }

  public shouldValidate(entity: any): boolean {
    return entity.password;
  }

  public getErrorTranslateKey(value: any): string {
    return 'USER_OLD_PASSWORD_IS_NOT_VALID';
  }

  public getErrorTranslateParams(value: any): any {
    const fields = Object.keys(value);

    return {
      'fieldNames': fields.join()
    };
  }

  public validate(): Observable<ValidationStatus> {
    let observable = observableOf({
      isValid: true,
      errorTranslated: '',
      errorTranslateKey: '',
      errorTranslateParams: null,
      field: this.getField(),
      value: this.getEntityFieldValue()
    });

    const postFields = this.getFieldsToCheck();

    if (postFields) {

      observable = observableOf({
        isValid: false,
        errorTranslated: null, // well no translated error from backend :(
        errorTranslateKey: this.getErrorTranslateKey(this.getEntityFieldValue()),
        errorTranslateParams: this.getErrorTranslateParams(postFields),
        field: this.getField(),
        value: this.getEntityFieldValue()
      });
    }

    return observable;
  }

  /**
   * @returns {Object}
   */
  private getFieldsToCheck(): Object {
    return { 'oldPassword': this.getEntity().password };
  }
}
