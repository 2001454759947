import {ChangeDetectionStrategy, Component, Input, OnInit} from '@angular/core';
import { ToolbarItemAbstract } from '../toolbar-item-abstract.component';
import { ComponentService } from '../../../../../services/component-highlight-stack.service';
import { MessageGrowlService } from '../../../../../../../core/message/message-growl.service';
import { MessageService } from '../../../../../../../core/message/message.service';
import { GenericCrudService } from '../../../../../../services/generic-crud.service';
import { EntitySharingService } from '../../../../../services/entity-sharing.service';
import { ApiBuilderService } from '../../../../../../services/api.builder.service';
import { FormViewerComponent } from '../../../../../../form-viewer/form-viewer.component';
import { ContentRendererComponent } from '../../../../../content-renderer.component';
import {TranslateService} from "@ngx-translate/core";
import {NotificationService} from "../../../../../../services/notification.service";
import { AbstractGenericGridComponent } from '../../../../abstract-generic-grid.component';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'toolbar-item-send-sms',
  templateUrl: './toolbar-item-send-sms.component.html'
})
export class ToolbarItemSendSms extends ToolbarItemAbstract {

  constructor(
    private componentService: ComponentService,
    private messageGrowlService: MessageGrowlService,
    private messageService: MessageService,
    private genericCrudService: GenericCrudService,
    private entitySharingService: EntitySharingService,
    private apiBuilder: ApiBuilderService,
    private translationService: TranslateService,
    private notificationService: NotificationService
  ) {
    super();
  }

  public click() {
    const component = this.getComponent();    

    if (this.isInCurrentContext(ToolbarItemAbstract.CONTEXT_FORM_VIEWER)) {
      this.doSendSmsInFormViewerComponent(this.getParam('formViewerComponent'));
    } else if (this.isInCurrentContext(ToolbarItemAbstract.CONTEXT_MODULE)) {
      this.doSendSmsInModuleComponent(this.getParam('moduleComponent'));
    } else if (component instanceof AbstractGenericGridComponent) {
      this.doSendSmsInGridComponent(component);
    }
  }

  private doSendSmsInFormViewerComponent(formViewer: FormViewerComponent) {
    let entity = formViewer.entity;
    
    this.sendSms(entity);
  }

  private doSendSmsInModuleComponent(moduleComponent: ContentRendererComponent) {
    let entity = this.componentService.getHighlightedEntity();

    this.sendSms(entity);
  }

  private doSendSmsInGridComponent(gridComponent: AbstractGenericGridComponent) {
    let entity = gridComponent.selectedEntity;

    this.sendSms(entity);
  }

  private sendSms(entity) {

    if (!entity) {
      return this.messageGrowlService.error('Please select entity you want to send an sms to.');
    }

    this.notificationService.sendSms(entity).subscribe();
  }
}
