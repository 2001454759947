import {Injectable} from '@angular/core';
import {PreCalculationArticle} from '../../pre-calculation-article';
import {PreCalculationArticleCalculatorAllowanceService} from './pre-calculation-article-calculator-allowance.service';
import {PreCalculationArticleCalculatorExtraPayService} from './pre-calculation-article-calculator-extra-pay.service';
import {PreCalculationArticleCalculatorGeneralService} from './pre-calculation-article-calculator-general.service';
import {GenericCrudService} from '../../../../generic-crud.service';
import {EntityManagerService} from '../../../../../../core/service/entity-manager/entity-manager.service';

@Injectable()
export class PreCalculationArticleCalculatorFactory {
  constructor(
    protected genericCrudService: GenericCrudService,
    protected entityManager: EntityManagerService
  ) {
  }

  public static readonly TYPE_ALLOWANCE = 'allowance';
  public static readonly TYPE_GENERAL = 'general';
  public static readonly TYPE_OVERTIME = 'overtime';
  public static readonly TYPE_EXTRA_PAY = 'extra_pay';
  public static readonly TYPE_OTHER = 'other';

  public spawnCalculator(article: PreCalculationArticle) {
    switch (article.type) {
      case PreCalculationArticleCalculatorFactory.TYPE_ALLOWANCE:
        return new PreCalculationArticleCalculatorAllowanceService(this.genericCrudService, this.entityManager);
      case PreCalculationArticleCalculatorFactory.TYPE_EXTRA_PAY:
      case PreCalculationArticleCalculatorFactory.TYPE_OTHER:
      case PreCalculationArticleCalculatorFactory.TYPE_OVERTIME:
        return new PreCalculationArticleCalculatorExtraPayService(this.genericCrudService, this.entityManager);
      case PreCalculationArticleCalculatorFactory.TYPE_GENERAL:
      default:
        return new PreCalculationArticleCalculatorGeneralService(this.genericCrudService, this.entityManager);
    }
  }
}
