import {Observable} from 'rxjs';
import {AbstractExecutionStep} from '../../../core/executor/abstract-execution-step';
import {ExecutionStepStatus} from '../../../core/executor/execution-step-status';
import {SplitTemplateComponent} from '../../content-renderer/split-template/split-template.component';
import {Constants} from '../../../constants';
import {map} from 'rxjs/operators';
import {Entity} from '../../helpers/entity';
import {AuthenticationService} from '../../../core/authentication/authentication.service';
import {BeforePhoenixActivePartsLoadExecutionStep} from './before-phoenix-active-parts-load-execution.step';

export class BeforeTicketActivePartsLoadExecutionStep extends BeforePhoenixActivePartsLoadExecutionStep {

  protected getCodesFromUser(aUser): string[] {
    const ticketDepartments = Entity.getValueInEmbedded(aUser, 'ticketDepartments') || []
    return ticketDepartments.map((ticketDepartment) => {
      return ticketDepartment.code;
    })
  }

  protected doFilter(component: SplitTemplateComponent): Observable<ExecutionStepStatus> {
    const userId = this.injector.get(AuthenticationService).currentUser.id;

    return this.genericCrudService.get(`ticket/users/${userId}?embedded=ticketDepartments`).pipe(
      map((aUser: any) => {
        this.handleVisibility(aUser, component)

        return {status: true, content: null};
      }));
  }
}
