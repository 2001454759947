import { GenericGridComponent } from '../generic-grid.component';
import {AbstractGenericGridComponent} from '../../abstract-generic-grid.component';
import {FieldMetadataGrid} from '../../../../services/module/module-element-field-metadata-grid';

export interface FilterMeta {
  type: string;
  matchMode: string;
  value: any;
}

export interface GridFilter {
  getRemoteValue();
  getLocalValue();
  isValid();
}

export abstract class AbstractGridFilter implements GridFilter {

  protected meta: any;
  protected component: AbstractGenericGridComponent | null;
  protected column: any;
  protected entity: any;
  protected field: FieldMetadataGrid;

  public static readonly RANGE_FILTER_TYPE_SEPARATOR = '|';

  public static readonly MATCH_MODE_STARTS_WITH = 'startsWith';
  public static readonly MATCH_MODE_ENDS_WITH = 'endsWith';
  public static readonly MATCH_MODE_CONTAINS = 'contains';
  public static readonly MATCH_MODE_IN = 'in';
  public static readonly MATCH_MODE_DISTANCE = 'distance';
  public static readonly MATCH_MODE_LEASED_EMPLOYEE_REGION = 'leasedEmployeeRegion';
  public static readonly MATCH_MODE_MANY_IN = 'manyIn';
  public static readonly MATCH_MODE_EXACT_IN = 'exactIn';
  public static readonly MATCH_MODE_RANGE = 'range';
  public static readonly MATCH_MODE_GREATER_THAN = 'greaterThan';
  public static readonly MATCH_MODE_LOWER_THAN = 'lowerThan';

  constructor(
    meta: FilterMeta
  ) {
    this.meta = meta;
  }

  public abstract isValid(): boolean;
  public abstract parseRemoteValue();
  public abstract parseLocalValue();
  public abstract onFocus(entity: any, event: any);

  protected parseRemoteMatchMode() {
    let filterMatchMode = this.meta.matchMode || AbstractGridFilter.MATCH_MODE_STARTS_WITH;

    if (typeof this.meta.value == 'string') {
      const asteriskPosition = this.meta.value.indexOf('*');
      const asteriskCount = (this.meta.value.match(/\*/gi) || []).length;

      if (asteriskPosition != -1) {
        if (asteriskCount == 1) {
          filterMatchMode = (asteriskPosition != 0) ? AbstractGridFilter.MATCH_MODE_STARTS_WITH : AbstractGridFilter.MATCH_MODE_ENDS_WITH;
        } else {
          filterMatchMode = AbstractGridFilter.MATCH_MODE_CONTAINS;
        }
      }
    }

    return filterMatchMode;
  }

  protected parseLocalMatchMode() {
    return 'in'; // :P
  }

  public getRemoteValue(): any {
    return this.parseRemoteMatchMode() + ':' + this.parseRemoteValue();
  }

  public getLocalValue() {
    return this.parseLocalMatchMode() + ':' + this.parseLocalValue()();
  }

  public setComponent(component: AbstractGenericGridComponent): this {
    this.component = component;
    return this;
  }

  public setEntity(entity: any): this {
    this.entity = entity;
    return this;
  }

  public setColumn(column: any): this {
    this.column = column;
    this.field = this.column.field;
    return this;
  }
}
