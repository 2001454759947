import { ToolbarItemAbstract } from '../../toolbar-item-abstract.component';
export class ToolbarItemFormAddAddress extends ToolbarItemAbstract {
    constructor() {
        super(...arguments);
        this.isDialogVisible = false;
    }
    click() {
        if (this.isInCurrentContext(ToolbarItemAbstract.CONTEXT_FORM_VIEWER)) {
            this.handleClick(this.getParam('formViewerComponent'));
        }
    }
    handleClick(formViewer) {
        this.isDialogVisible = true;
    }
    onImport(data) {
        const fg = this.getParam('formViewerComponent').formGroup;
        // Let's think about this how we can make this with params cause db form fields can have different names than country etc.
        // but for now quick & dirty
        if (fg.get('country')) {
            fg.get('country').setValue(data.country);
        }
        if (fg.get('city')) {
            fg.get('city').setValue(data.city);
        }
        if (fg.get('street')) {
            fg.get('street').setValue(data.street);
        }
        if (fg.get('streetName')) {
            fg.get('streetName').setValue(data.street);
        }
        // Handle province
        // Handle dialingCode from city
        // Handle postalCode from street
    }
}
