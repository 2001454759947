
import {refCount, catchError, publishReplay, map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';


import { Datamodel } from './datamodel';
import { AppHttpService } from '../../../app-http.service';
import { GenericCrudService } from '../generic-crud.service';

@Injectable()
export class DatamodelCrudService extends AppHttpService {

  private cachedDatamodels = null;
  private datamodelsUrl = 'superadmin/datamodels';

  constructor(
    private genericCrudService: GenericCrudService) {
    super();
  }

  /**
   * @argument {string} format Optional. If not set, then the entities are returned as is.
   *                            Selectable formats: formenu (MenuItem), fortree (TreeNode).
   */
  getDatamodels(format = ''): Observable<Datamodel[]> {
    const url = this.datamodelsUrl + (format ? `/${format}` : '');
    return this.genericCrudService.get(url).pipe(
      publishReplay(1),
      refCount(),
      catchError(this.handleError));
  }

  /**
   * @argument {number} datamodelId The id of the datamodel to request.
   * @argument {string} format Optional. If not set, then the entities are returned as is.
   */
  getDatamodel(id: number, format = ''): Observable<Datamodel> {
    const url = this.datamodelsUrl + `/${id}` + (format ? `/${format}` : '');
    return this.genericCrudService.get(url).pipe(
      publishReplay(1),
      refCount(),
      catchError(this.handleError));
  }

  deleteDatamodel(datamodelId: number): Observable<any> {
    if (datamodelId > 0) {
      this.cachedDatamodels = null;
      return this.genericCrudService.deleteEntity(`${this.datamodelsUrl}/${datamodelId}`).pipe(catchError(this.handleError));
    }
    throw new Error('Invalid datamodel id given.');
  }

  createDatamodel(datamodel: Datamodel): Observable<Datamodel> {
    if (datamodel) {
      this.cachedDatamodels = null;
      return this.genericCrudService.createEntity(this.datamodelsUrl, datamodel).pipe(
        catchError(this.handleError));
    } else {
      throw new Error('No datamodel given.');
    }
  }

  editDatamodel(datamodel: Datamodel): Observable<Datamodel> {
    if (datamodel) {
      this.cachedDatamodels = null;
      return this.genericCrudService.editEntity(`${this.datamodelsUrl}/${datamodel.id}`, datamodel).pipe(
        catchError(this.handleError));
    }
    throw new Error('No datamodel given.');
  }

  getDatamodelRecords(datamodel: Datamodel): Observable<any> {
    if (datamodel) {
      let url = `${this.datamodelsUrl}/${datamodel.id}/records`;

      return this.genericCrudService.get(url).pipe(
        catchError(this.handleError));
    }
    throw new Error('No datamodel given.');
  }

  getDatamodelRecord(datamodelId: number, recordId: number): Observable<any> {
    if (!datamodelId) {
      throw new Error('No datamodel given.');
    }

    if (datamodelId && recordId) {
      const url = `${this.datamodelsUrl}/${datamodelId}/records/${recordId}`;

      return this.genericCrudService.get(url).pipe(
        catchError(this.handleError));
    }
  }

  getDatamodelRecordsPaginated(datamodel: Datamodel, limit?: number, offset?: number, orderBy?: string, direction?: string, filters?): Observable<any> {
    if (datamodel) {
      let url = `${this.datamodelsUrl}/${datamodel.id}/records`;
      if (limit && limit > 0) {
        url += '/limit/' + limit;
      }

      if (offset >= 0) {
        url += '/offset/' + offset;
      }

      if (orderBy) {
        url += '/orderby/' + orderBy + '/';
        url += (direction && (direction == 'asc' || direction == 'desc')) ? direction : 'asc';
      }

      if (filters) {
        url += '?1=1';
        for (const filter of filters) {
          url += '&' + filter.name + '=' + filter.value;
        }
      }

      return this.genericCrudService.get(url).pipe(
        map(this.extractPaginatedEmbeddedEntities, this));
    }

    throw new Error('No datamodel given.');
  }

  getDatamodelAssociations(datamodel: Datamodel): Observable<any> {
    if (datamodel) {
      return this.genericCrudService.get(`${this.datamodelsUrl}/${datamodel.id}/associations`).pipe(
        catchError(this.handleError));
    }
    throw new Error('No datamodel given.');
  }
}
