import {ChangeDetectionStrategy, Component, ElementRef, ViewChild} from '@angular/core';
import {ToolbarItemAbstract} from '../toolbar-item-abstract.component';
import {ComponentService} from '../../../../../services/component-highlight-stack.service';
import {AbstractGenericGridComponent} from '../../../../abstract-generic-grid.component';
import {MessageGrowlService} from '../../../../../../../core/message/message-growl.service';
import {GenericCrudService} from '../../../../../../services/generic-crud.service';
import {EntityHydrator} from '../../../../../../services/entity-hydrator.service';
import {ExecutorActionEvent} from '../../../../../../../core/executor/service/executor-actions/executor-action-event';
import {FormViewerComponent} from '../../../../../../form-viewer/form-viewer.component';
import {ChangeDetectorRefHelper} from '../../../../../../helpers/change-detector-ref.helper';
import {catchError} from 'rxjs/operators';
import {HttpErrorResponse} from '@angular/common/http';
import {throwError as observableThrowError} from 'rxjs/internal/observable/throwError';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'toolbar-item-open-link',
  templateUrl: './toolbar-item-update-leased-employee-by-document.component.html',
  styles: [`
    ::ng-deep .ui-widget-content {
      border: 0px;
    }

    ::ng-deep .ui-dialog-title {
      text-align: left;
    }
  `]
})
export class ToolbarItemUpdateLeasedEmployeeByDocumentComponent extends ToolbarItemAbstract {

  public isDialogVisible = false;
  public leasedEmployeeDocuments: SelectItem[] = [];
  public cvFileDataURL = '';
  public cvFileName = '';
  public customElementData = {
    id: 'cvUploadFileInput',
    type: 'file-input'
  };

  @ViewChild('dialog', {static: false}) dialog;
  @ViewChild('fileUpload', {static: false, read: ElementRef}) fileUpload: ElementRef;

  constructor(
    protected componentService: ComponentService,
    protected messageGrowlService: MessageGrowlService,
    protected genericCrudService: GenericCrudService,
    protected entityHydrator: EntityHydrator
  ) {
    super();
  }

  public click() {
    this.isDialogVisible = true;
  }

  public onCloseDialog(event) {
    this.doClose();
  }

  public doClose() {
    this.isDialogVisible = false;
  }

  public onDialogMaximize(event) {
    this.dialog.toggleMaximize(event);

    setTimeout(() => {
      window.dispatchEvent(new Event('resize'));
    }, 500);
  }

  public onSelectCV(event): void {
    const files: FileList = event.files,
      fileName = files.item(0).name;

    files.item(0).toDataURL().subscribe((dataURL) => {
      this.cvFileName = fileName;
      this.cvFileDataURL = dataURL.replace('data:application/pdf;base64,', '')
        .replace('data:application/vnd.openxmlformats-officedocument.wordprocessingml.document;base64,', '');

      ChangeDetectorRefHelper.detectChanges(this);
    });
  }

  public onUpdate(event) {
    const component = this.getComponent();
    this.fileUpload.nativeElement.querySelector('button').setAttribute('disabled', true);
    if (component.getSelectedEntity()) {
      this.genericCrudService.customPut(`phoenix/leasedemployees/updatefromcv`, {
        leasedEmployee: component.getSelectedEntity().id,
        dataURL: this.cvFileDataURL,
        fileName: this.cvFileName
      }).pipe(catchError((error: HttpErrorResponse) => {
        this.messageGrowlService.error(error.message);

        return observableThrowError(error);
      })).subscribe((result: {
        leasedEmployee: any,
        exception: any
      }) => {
        if (result.exception) {
          this.messageGrowlService.info(result.exception.message, 'Data saved with error!');
        } else {
          this.messageGrowlService.showDataSaved();
        }

        this.fileUpload.nativeElement.querySelector('button').setAttribute('disabled', false);
        this.doClose();
        for (const stashedComponent of this.componentService.getStashed()) {
          this.setComponentEntity(stashedComponent);
          stashedComponent.executeAction(ExecutorActionEvent.EntityImported, stashedComponent).subscribe();
        }
      });
    }
  }

  private setComponentEntity(component: any): void {
    if (component instanceof AbstractGenericGridComponent) {
      component.setSelectedEntity(component.getSelectedEntity());
    }

    if (component instanceof FormViewerComponent) {
      component.entity = component.getSelectedEntity();
    }
  }
}
