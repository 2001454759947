import {FormViewerComponent} from '../../../../../../../form-viewer/form-viewer.component';
import {Element, ElementInput, ElementInputDate} from '../../../../../../../form-viewer/models';
import {Entity} from '../../../../../../../helpers/entity';
import {ElementInputValidator} from '../../../../../../../form-viewer/models/element';
import {FormService} from '../../../../../../../form-viewer/form.service';

const EXIT_REASON_FIELD = 'exitReason';
const EXIT_REASON_CODE = 'EV';
const EXIT_AGREEMENT_DATE_FIELD = 'exitAgreementDate';

export class ContractOfEmploymentExitAgreementDateService {

  private form: FormViewerComponent = null;
  private formService: FormService = null;

  public setForm(form: FormViewerComponent): this {
    this.form = form;
    return this;
  }

  public setFormService(formService: FormService): this {
    this.formService = formService;
    return this;
  }

  public setupExitAgreementDate(): void {
    const entity = this.form.getSelectedEntity();

    if (entity && entity.fqn) {
      const exitReason = Entity.getValue(entity, EXIT_REASON_FIELD) ||
        Entity.getValueInEmbedded(entity, EXIT_REASON_FIELD);

      if (exitReason && exitReason.code && exitReason.code === EXIT_REASON_CODE) {
        this.setExitAgreementDateRequired(entity.fqn, true);
        this.setExitAgreementDateTimeRequired(entity.fqn, true);
        this.showOrHideDateElement(false);
        this.showOrHideDateTimeElement(true);
      } else {
        this.setExitAgreementDateRequired(entity.fqn, false);
        this.setExitAgreementDateTimeRequired(entity.fqn, false);
        this.showOrHideDateElement(true);
        this.showOrHideDateTimeElement(false);
      }
    }
  }

  public setExitAgreementDateRequired(fqn: string, isRequired: boolean): void {
    const dateElement = this.getExitAgreementDateElement();

    this.doSetDateElementRequired(dateElement, fqn, isRequired);
  }

  public setExitAgreementDateTimeRequired(fqn: string, isRequired: boolean): void {
    const dateTimeElement = this.getExitAgreementDateTimeElement();

    this.doSetDateElementRequired(dateTimeElement, fqn, isRequired);
  }

  public doSetDateElementRequired(element, fqn: string, isRequired: boolean): void {
    if (element) {
      const validatorIndex = element.validators.findIndex((aValidator: ElementInputValidator) => {
        return aValidator.key === ElementInputValidator.REQUIRED_KEY;
      });

      if (validatorIndex !== -1) {
        element.validators.splice(validatorIndex, 1);
      }

      this.form.getValidator().removeFormValidations(fqn, element);

      const fieldsetElement = this.getExitInformationFieldsetElement();

      if (isRequired && fieldsetElement !== null && !fieldsetElement.isHidden) {
        element.validators.push({
          key: ElementInputValidator.REQUIRED_KEY
        });

        this.form.getValidator().addFormValidations(fqn, element);
      }
    }
  }

  public showOrHideDateTimeElement(showOrHide: boolean): void {
    const dateTimeElement = this.getExitAgreementDateTimeElement();

    if (dateTimeElement) {
      dateTimeElement.isHidden = !showOrHide;
    }

    this.markElementForCheck(dateTimeElement);
  }

  public showOrHideDateElement(showOrHide: boolean): void {
    const dateElement = this.getExitAgreementDateElement();

    if (dateElement) {
      dateElement.isHidden = !showOrHide;
    }

    this.markElementForCheck(dateElement);
  }

  public getExitAgreementDateElement(): ElementInput|null {
    return this.form.inputElements.find((aElement: ElementInput) => {
      return aElement instanceof ElementInputDate && aElement.datamodelField === EXIT_AGREEMENT_DATE_FIELD &&
        aElement.dateFormat === 'date';
    });
  }

  public getExitAgreementDateTimeElement(): ElementInput|null {
    return this.form.inputElements.find((aElement: ElementInput) => {
      return aElement instanceof ElementInputDate && aElement.datamodelField === EXIT_AGREEMENT_DATE_FIELD &&
        aElement.dateFormat === 'date+time';
    });
  }

  public getExitInformationFieldsetElement(): Element|null {
    const exitReasonElement = this.getExitReasonElement();

    if (typeof exitReasonElement !== 'undefined' && exitReasonElement !== null) {
      return exitReasonElement.parent;
    }

    return null;
  }

  public getExitReasonElement(): ElementInput|null {
    return this.form.inputElements.find((aElement: ElementInput) => {
      return aElement.datamodelField === EXIT_REASON_FIELD;
    });
  }

  public markElementForCheck(element: Element): void {
    if (element) {
      const elementComponent = this.formService.getComponentByElement(element);

      if (elementComponent) {
        elementComponent.getChangeDetectorRef().markForCheck();
      }
    }
  }
}
