import { Observable } from 'rxjs';
import { AbstractActionHandler } from '../../../../core/events/action/abstract-action-handler';
import { ActionHandlerSubscribeResponse } from '../../../../core/events/interfaces/action-handler-subscribe-response';
import { GenericElementEvent } from '../event/generic-element-event';

export abstract class AbstractGenericElementActionHandler extends AbstractActionHandler {
    
    protected abstract doAction(event: GenericElementEvent): Observable<ActionHandlerSubscribeResponse>;

    public handleAction(): Observable<ActionHandlerSubscribeResponse> {
        let event = this.getEvent();

        if (event instanceof GenericElementEvent) {
            return this.doAction(event);
        }

        return this.getEmptyObservable();
    }
}
