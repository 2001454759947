import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {GenericCrudService} from '../../../shared/services/generic-crud.service';

@Injectable()
export class MemoAutocompleteHandler {

  public constructor(
    private genericCrudService: GenericCrudService
  ) {

  }

  public getData(query: string): Observable<any[]> {
    const route = `phoenix/memos/offset/0/limit/30/orderby/id/asc?search=${query}`,
      requestParams = {clause: 'orWhere', 'embedded': 'none'};

    return this.genericCrudService.getEntities(route, '', requestParams)
      .pipe(map((paginated: {data: any[]}) => {
        const data = paginated.data;

        for (const memo of data) {
          memo.label = `${memo.memoNumber} ${memo.subject}`;
        }

        return data;
      }));
  }
}
