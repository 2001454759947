/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./toolbar-item-storno-invoice.component";
import * as i2 from "../../../../../../../core/message/message-growl.service";
import * as i3 from "../../../../../services/double-click.service";
import * as i4 from "../../../../generic-dialog/service/generic-dialog-module.service";
import * as i5 from "../../../../../../services/generic-crud.service";
import * as i6 from "../../../../../../../core/service/entity-manager/entity-manager.service";
import * as i7 from "../../../../../services/modules-state.service";
import * as i8 from "@ngx-translate/core";
import * as i9 from "../../../../../../../core/service/toast.service";
import * as i10 from "../../../../../../services/request-caching.service";
var styles_ToolbarItemStornoInvoiceComponent = [];
var RenderType_ToolbarItemStornoInvoiceComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_ToolbarItemStornoInvoiceComponent, data: {} });
export { RenderType_ToolbarItemStornoInvoiceComponent as RenderType_ToolbarItemStornoInvoiceComponent };
export function View_ToolbarItemStornoInvoiceComponent_0(_l) { return i0.ɵvid(2, [], null, null); }
export function View_ToolbarItemStornoInvoiceComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-toolbar-item-storno-invoice", [], null, null, null, View_ToolbarItemStornoInvoiceComponent_0, RenderType_ToolbarItemStornoInvoiceComponent)), i0.ɵdid(1, 245760, null, 0, i1.ToolbarItemStornoInvoiceComponent, [i2.MessageGrowlService, i3.DoubleClickService, i4.GenericDialogModuleService, i5.GenericCrudService, i6.EntityManagerService, i7.ModulesStateService, i8.TranslateService, i9.ToastService, i10.RequestCachingService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ToolbarItemStornoInvoiceComponentNgFactory = i0.ɵccf("app-toolbar-item-storno-invoice", i1.ToolbarItemStornoInvoiceComponent, View_ToolbarItemStornoInvoiceComponent_Host_0, { params: "params" }, {}, []);
export { ToolbarItemStornoInvoiceComponentNgFactory as ToolbarItemStornoInvoiceComponentNgFactory };
