<p-dialog
  *ngIf="isDialogVisible"
  [(visible)]="isDialogVisible"
  header="Export"
  showEffect="fade"
  [width]="800"
  appendTo="body"
>

  <div *ngIf="isDataLoading">
    {{ 'COMMON.LOADING_PLEASE_WAIT' | translate }}
  </div>

  <p-table
    *ngIf="!isDataLoading"
    [value]="factors"
  >
    <ng-template pTemplate="caption">
      <div class="header">
        <button type="button" pTooltip="Export" pButton icon="fa-export" (click)="onExport()" class="button">Export</button>
      </div>
    </ng-template>
    <ng-template pTemplate="header">
      <tr>
        <th [pSortableColumn]="'name'">
          Name
          <p-sortIcon [field]="'name'"></p-sortIcon>
        </th>
        <th>Faktor</th>
        <th>FactorTargetInvoice</th>
        <th>FactorInvoiceMin</th>
        <th>FactorAncillaryWageCosts</th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-factor>
      <tr>
        <td>{{factor.name}}</td>
        <td pEditableColumn>
          <p-cellEditor>
            <ng-template pTemplate="input">
              <input pInputText type="text" [(ngModel)]="factor.factor">
            </ng-template>
            <ng-template pTemplate="output">
              {{ factor.factor}}
            </ng-template>
          </p-cellEditor>
        </td>
        <td pEditableColumn>
          <p-cellEditor>
            <ng-template pTemplate="input">
              <input pInputText type="text" [(ngModel)]="factor.factorTargetInvoice">
            </ng-template>
            <ng-template pTemplate="output">
              {{ factor.factorTargetInvoice}}
            </ng-template>
          </p-cellEditor>
        </td>
        <td pEditableColumn>
          <p-cellEditor>
            <ng-template pTemplate="input">
              <input pInputText type="text" [(ngModel)]="factor.factorInvoiceMin">
            </ng-template>
            <ng-template pTemplate="output">
              {{ factor.factorInvoiceMin}}
            </ng-template>
          </p-cellEditor>
        </td>
        <td pEditableColumn>
          <p-cellEditor>
            <ng-template pTemplate="input">
              <input pInputText type="text" [(ngModel)]="factor.factorAncillaryWageCosts">
            </ng-template>
            <ng-template pTemplate="output">
              {{ factor.factorAncillaryWageCosts}}
            </ng-template>
          </p-cellEditor>
        </td>
      </tr>
    </ng-template>
  </p-table>

</p-dialog>
