/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./toolbar-item-open-module-job-advertisement.component";
import * as i2 from "../../../../../../../../core/message/message-growl.service";
import * as i3 from "../../../../../../services/double-click.service";
import * as i4 from "../../../../../../../services/entity-hydrator.service";
import * as i5 from "../../../../../../../../core/service/user-session.service";
import * as i6 from "../../../../../../../../core/authentication/authentication.service";
var styles_ToolbarItemOpenModuleJobAdvertisementComponent = [];
var RenderType_ToolbarItemOpenModuleJobAdvertisementComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_ToolbarItemOpenModuleJobAdvertisementComponent, data: {} });
export { RenderType_ToolbarItemOpenModuleJobAdvertisementComponent as RenderType_ToolbarItemOpenModuleJobAdvertisementComponent };
export function View_ToolbarItemOpenModuleJobAdvertisementComponent_0(_l) { return i0.ɵvid(2, [], null, null); }
export function View_ToolbarItemOpenModuleJobAdvertisementComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-toolbar-item-open-module-job-advertisement", [], null, null, null, View_ToolbarItemOpenModuleJobAdvertisementComponent_0, RenderType_ToolbarItemOpenModuleJobAdvertisementComponent)), i0.ɵdid(1, 245760, null, 0, i1.ToolbarItemOpenModuleJobAdvertisementComponent, [i2.MessageGrowlService, i3.DoubleClickService, i4.EntityHydrator, i5.UserSessionService, i6.AuthenticationService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ToolbarItemOpenModuleJobAdvertisementComponentNgFactory = i0.ɵccf("app-toolbar-item-open-module-job-advertisement", i1.ToolbarItemOpenModuleJobAdvertisementComponent, View_ToolbarItemOpenModuleJobAdvertisementComponent_Host_0, { params: "params" }, {}, []);
export { ToolbarItemOpenModuleJobAdvertisementComponentNgFactory as ToolbarItemOpenModuleJobAdvertisementComponentNgFactory };
