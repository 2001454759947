export class EntityFqnHelper {

  public static getEntityFqn(entity: {name: string}): string {
    let name = '';

    if (entity) {
      const nameParts = entity.name.split('.');

      name = `${nameParts[0]}\\Entity\\${nameParts[1]}`;
    }

    return name;
  }
}
