
import {map} from 'rxjs/operators';
import {Component} from '@angular/core';
import {FieldActionAbstractComponent} from '../field-action-abstract.component';
import {GenericCrudService} from '../../../../../services/generic-crud.service';
import {DoubleClickService} from '../../../../services/double-click.service';
import {FieldMetadataGrid} from '../../../../../services/module/module-element-field-metadata-grid';
import {Observable} from 'rxjs';
import {Entity} from '../../../../../helpers/entity';

@Component({
  selector: 'app-field-action-set-extra-param-from-one-edit-to-another',
  template: ''
})
export class FieldActionSetExtraParamFromOneMultiselectEditToAnotherComponent extends FieldActionAbstractComponent {

  public constructor(
    protected genericCrudService: GenericCrudService,
    protected doubleClickService: DoubleClickService
  ) {
    super();
  }

  public fire() {
    const fromField = this.getField(),
      toFieldName = this.getActionParamValue('toFieldName'),
      fromFieldParamKey = this.getActionParamValue('fromFieldParamKey'),
      toField = this.grid.findField(toFieldName),
      entity = this.grid.getSelectedEntity();

    if (fromField && toField && fromFieldParamKey && entity) {
      // this.removeEntityToFieldValue(entity, toField);

      this.loadOptions(fromField, toField, fromFieldParamKey, entity).subscribe((options: any[] = []) => {
        this.grid.associatedOptions[toField.entityName] = options;
      });
    }
  }

  private removeEntityToFieldValue(entity: any, toField: FieldMetadataGrid): void {
    entity[toField.entityName] = [];
  }

  private loadOptions(fromField: FieldMetadataGrid, toField: FieldMetadataGrid, fromFieldParamKey: string, entity: any): Observable<any[]> {
    const params = this.grid.getColumnBuilder().parseParams(toField);
    const apiRoute = this.grid.getColumnBuilder().createApiRoute(toField);

    const entityFromValues = Entity.getValue(entity, fromField.entityName) ||
      Entity.getValueInEmbedded(entity, fromField.entityName);

    if (entityFromValues && entityFromValues instanceof Array) {
      const idValues = [];

      for (const entityFromValue of entityFromValues) {
        if (entityFromValue instanceof Object && entityFromValue.id) {
          idValues.push(entityFromValue.id);
        }

        if (typeof entityFromValue === 'number') {
          idValues.push(entityFromValue);
        }
      }

      if (idValues.length > 0) {
        params[fromFieldParamKey] = `in:${idValues.join(',')}`;
      }
    }

    return this.genericCrudService.getPaginated(apiRoute, params).pipe(
      map((combinedResponse) => {
        const options = [];

        const entityData = (combinedResponse instanceof Array) ? combinedResponse : combinedResponse.data;

        if (typeof entityData !== 'undefined') {
          for (const aEntity of entityData) {
            options.push({
              label: FieldMetadataGrid.getOptionLabel(aEntity, toField),
              value: aEntity.id,
              entity: aEntity
            });
          }
        }

        return options;
      }));
  }
}
