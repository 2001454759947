<table>
  <tr>
    <td>
      <button
        *ngIf="entity && entity.id"
        [style.marginLeft.px]="4"
        type="button" pButton
        pTooltip="{{ 'COMMON.DOWNLOAD' | translate }}" tooltipPosition="top"
        iconUrl="https://demoapi.hr-puzzle.eu/assets/hr-puzzle/images/icons/arrow_down.png"
        (click)="doDownload()"
        [disabled]="isDisabled()"
      ></button>

      <p-progressSpinner *ngIf="isLoading" [style]="{width: '12px', height: '12px'}" strokeWidth="6" fill="#EEEEEE"></p-progressSpinner>
    </td>
  </tr>
</table>
