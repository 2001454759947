import {Injectable} from '@angular/core';
import { Form, FormAction, FormActions, FormActionCondition, FormActionConditions } from '../../models/form';
import { Element } from 'app/shared/form-viewer/models/element';
import { FormActionHandler, AbstractFormActionHandler } from '../handlers/abstract-form-action-handler';
import { FormColorFromDatamodelSourceActionHandler } from '../handlers/form-color-from-datamodel-source-action-handler';
import { DatamodelCrudService } from '../../../services/datamodel/datamodel.crud.service';
import { FormViewerService } from '../../form-viewer.service';
import { AbstractFormActionConditionHandler, FormActionConditionHandler } from '../condition-handlers/abstract-form-action-condition-handler';
import { IsFormValueEmptyConditionHandler } from '../condition-handlers/is-form-value-empty-condition-handler';
import { IsFormValueNotEmptyConditionHandler } from '../condition-handlers/is-form-value-not-empty-condition-handler';
import { SetFieldValueFromFieldDatamodel } from '../handlers/set-field-value-from-field-datamodel-action-handler';
import { SetFieldValueFromFieldOptions } from '../handlers/set-field-value-from-field-options-action-handler';
import { FormService } from 'app/shared/form-viewer/form.service';
import { FormGroup } from '@angular/forms';
import { RemoveFieldValue } from '../handlers/remove-field-value-action-handler';
import { CheckFieldsMessageActionHandler } from '../handlers/check-fields-message-action-handler';
import { MessageGrowlService } from '../../../../core/message/message-growl.service';
import { TranslateService } from '@ngx-translate/core';
import { IsChangingElementConditionHandler } from '../condition-handlers/is-changing-element-condition-handler';
import { IsNotChangingElementConditionHandler } from 'app/shared/form-viewer/actions/condition-handlers/is-not-changing-element-condition-handler';
import { HandleCountryPhoneActionHandler } from '../handlers/address/handle-country-phone-action-handler';
import { HandleCountryFaxActionHandler } from '../handlers/address/handle-country-fax-action-handler';
import { HandlePhoneCountryCodeContactPersonActionHandler } from '../handlers/address-contact-person/handle-phone-country-code-contact-person-action-handler';
import { HandleSecondaryPhoneCountryCodeContactPersonActionHandler } from '../handlers/address-contact-person/handle-secondary-phone-country-code-contact-person-action-handler';
import { HandleFaxCountryCodeContactPersonActionHandler } from '../handlers/address-contact-person/handle-fax-country-code-contact-person-action-handler';
import { HandleMobileCountryCodeContactPersonActionHandler } from '../handlers/address-contact-person/handle-mobile-country-code-contact-person-action-handler';
import { HandleSecondaryMobileCountryCodeContactPersonActionHandler } from '../handlers/address-contact-person/handle-secondary-mobile-country-code-contact-person-action-handler';
import { FormActionHandlePrecalculationReferenceAssociationTypeSection } from '../handlers/precalculation/handle-precalculation-reference-association-type-section-action-handler';
import { GenericCrudService } from '../../../services/generic-crud.service';
import {LoadEntityFromApiActionHandler} from '../handlers/load-entity-from-api-action-handler';
import { LockFieldsActionHandler } from '../handlers/lock-fields-action-handler';
import { IsElementValueEqualConditionHandler } from '../condition-handlers/is-element-value-equal-action-condition-handler';
import { IsElementValueNotEqualConditionHandler } from '../condition-handlers/is-element-value-not-equal-action-condition-handler';
import { IsEntityValueEqualConditionHandler } from '../condition-handlers/is-entity-value-equal-action-condition-handler';
import { IsEntityValueNotEqualConditionHandler } from '../condition-handlers/is-entity-value-not-equal-action-condition-handler';
import { IsEntityNewConditionHandler } from '../condition-handlers/is-entity-new-action-condition-handler';
import { IsExistingEntityConditionHandler } from '../condition-handlers/is-existing-entity-action-condition-handler';
import {SetIdToUrlActionHandler} from '../handlers/set-id-to-url-action-handler';
import {LocationService} from '../../../services/location.service';
import {HandleCountryMainFaxActionHandler} from '../handlers/address/handle-country-main-fax-action-handler';
import {HandleCountryMainPhoneActionHandler} from '../handlers/address/handle-country-main-phone-action-handler';
import {CalculateReferenceAssociationFactorActionHandler} from '../handlers/calculate-reference-association-factor-action-handler';
import {HandleCountryMobileActionHandler} from '../handlers/address/handle-country-mobile-action-handler';
import {HandleCountrySecondaryMobileActionHandler} from '../handlers/address/handle-country-secondary-mobile-action-handler';
import {SetupPhoneProjectFormActionHandler} from '../handlers/setup-phone-project-form-action-handler';
import {HandleGenericPhoneActionHandler} from '../handlers/generic-phone/handle-generic-phone-action-handler';
import {SetupSubAssignmentFormActionHandler} from '../handlers/assignment/setup-sub-assignment-form-action-handler';
import {EntityDirtyStoreService} from '../../../content-renderer/services/entity-dirty-store.service';
import {SetEntityFieldWithSelectedBranchofficeActionHandler} from '../handlers/set-entity-field-with-selected-branchoffice-action-handler';
import {AuthenticationService} from '../../../../core/authentication/authentication.service';
import {LocalStorageDataService} from '../../../services/local-storage-data.service';
import {ShowFieldActionHandler} from '../handlers/show-field-action-handler';
import {HideFieldActionHandler} from '../handlers/hide-field-action-handler';
import {ModuleNavigationService} from '../../../content-renderer/services/navigation/module-navigation.service';
import {EntityManagerService} from '../../../../core/service/entity-manager/entity-manager.service';
import {SetupMemoFormActionHandler} from '../handlers/memo/setup-memo-form-action-handler';
import {HandleLeasedEmployeeDrivingLicenceActionHandler} from '../handlers/leased-employee/handle-leased-employee-driving-licence-action-handler';
import {SetupWorkplaceFormActionHandler} from '../handlers/workplace/setup-workplace-form-action-handler';
import {ExecutionStepFactoryService} from '../../../../core/executor/factory/execution-step-factory.service';
import {HandleAddressSelectionActionHandler} from '../handlers/address/handle-address-selection-action-handler';
import {HandleContractOfEmploymentFormActionHandler} from '../handlers/leased-employee/handle-contract-of-employment-form-action-handler';
import {SetupInvoiceFormActionHandler} from '../handlers/invoice/setup-invoice-form-action-handler';
import {SetupAdvanceFormActionHandler} from "../handlers/advance/setup-advance-form-action-handler";
import {SetupInvoicePossibilityFormActionHandler} from '../handlers/invoice-possibility/setup-invoice-possibility-form-action-handler';
import {SetupContactPersonFormActionHandler} from '../handlers/contact-person/setup-contact-person-form-action-handler';
import {UserSessionService} from '../../../../core/service/user-session.service';
import {HandleAlgoliaPlaceChangeActionHandler} from '../handlers/address/handle-algolia-place-change-action-handler';
import {HandleAlgoliaPlaceEntityChangedActionHandler} from '../handlers/address/handle-algolia-place-entity-changed-action-handler';
import {DuplicateEntityCheckFormActionHandler} from '../handlers/duplicate-entity-check/duplicate-entity-check-form-action-handler';
import {ModulesStateService} from '../../../content-renderer/services/modules-state.service';
import {HandlePrecalculationIsLockedActionHandler} from '../handlers/precalculation/handle-precalculation-is-locked-action-handler';
import {CalculateIntranetBundleVehicleDataDeductibleValuesActionHandler} from '../handlers/calculate-intranet-bundle-vehicle-data-deductible-values-action-handler';
import {CalculateIntranetBundleVehicleDataLeasingToActionHandler} from '../handlers/calculate-intranet-bundle-vehicle-data-leasing-to-action-handler';
import {HandleTicketBundleTicketDocumentUploadFieldsActionHandler} from '../handlers/handle-ticket-bundle-ticket-document-upload-fields-action-handler';
import {HandleOneToOneEntityFormActionHandler} from '../handlers/leased-employee/handle-one-to-one-entity-form-action-handler';
import {IgnoreValidationOnLeasedEmployeeTopFrameActionHandler} from '../handlers/ignore-validation-on-leased-employee-top-frame-action-handler.service';
import {HandleJobAdvertisementActionHandler} from '../handlers/job-advertisement/handle-job-advertisement-action-handler';
import {
  HandleJobAdvertisementContingentsActionHandler
} from '../handlers/job-advertisement/handle-job-advertisement-contingents-action-handler';
import {HandleGeoapifyAutocompleteChangeActionHandler} from '../handlers/address/handle-geoapify-autocomplete-change-action-handler';
import {HandleGeoapifyAutocompleteEntityChangedActionHandler} from '../handlers/address/handle-geoapify-autocomplete-entity-changed-action-handler';

@Injectable()
export class FormActionFactoryService {

    public constructor(
        private datamodelCrudService: DatamodelCrudService,
        private formViewerService: FormViewerService,
        private formService: FormService,
        private messageGrowlService: MessageGrowlService,
        private translateService: TranslateService,
        private genericCrudService: GenericCrudService,
        private locationService: LocationService,
        private entityDirtyStore: EntityDirtyStoreService,
        private authenticationService: AuthenticationService,
        private localStorage: LocalStorageDataService,
        private moduleNavigation: ModuleNavigationService,
        private entityManager: EntityManagerService,
        private stepFactory: ExecutionStepFactoryService,
        private userSession: UserSessionService,
        private modulesStateService: ModulesStateService
    ) {

    }

    /**
     *
     * @param {FormAction} action
     * @param {Form} form
     * @param {any} entity
     * @param {FormGroup} formGroup
     * @param element
     * @param component
     * @throws {Error} - when handler is not found
     * @returns {FormActionHandler}
     */
    public getFormActionHandler(action: FormAction, form: Form, entity: any, formGroup: FormGroup, element: Element, component): FormActionHandler {
        let handler = null;

        switch (action.className) {
            case FormActions.FORM_COLOR_FROM_DATAMODEL_SOURCE:
                handler = new FormColorFromDatamodelSourceActionHandler(this.datamodelCrudService, this.formViewerService);
                break;
            case FormActions.IGNORE_VALIDATION_EMPLOYEE_TOP_FORM:
                handler = new IgnoreValidationOnLeasedEmployeeTopFrameActionHandler(this.datamodelCrudService, this.formViewerService);
                break;
            case FormActions.SET_FIELD_VALUE_FROM_FIELD_DATAMODEL:
                handler = new SetFieldValueFromFieldDatamodel(this.datamodelCrudService);
            break;
            case FormActions.SET_ENTITY_FIELD_WITH_SELECTED_BRANCHOFFICE:
                handler = new SetEntityFieldWithSelectedBranchofficeActionHandler(this.datamodelCrudService, this.authenticationService, this.userSession);
            break;
            case FormActions.SET_FIELD_VALUE_FROM_FIELD_OPTIONS:
                handler = new SetFieldValueFromFieldOptions(this.datamodelCrudService);
            break;
            case FormActions.REMOVE_FIELD_VALUE:
                handler = new RemoveFieldValue();
            break;
            case FormActions.CHECK_FIELDS_MESSAGE:
                handler = new CheckFieldsMessageActionHandler(this.messageGrowlService, this.translateService);
            break;
            case FormActions.DUPLICATE_ENTITY_CHECK:
                handler = new DuplicateEntityCheckFormActionHandler(this.genericCrudService);
            break;
            case FormActions.HANDLE_COUNTRY_PHONE:
                handler = new HandleCountryPhoneActionHandler();
            break;
            case FormActions.HANDLE_COUNTRY_FAX:
                handler = new HandleCountryFaxActionHandler();
            break;
            case FormActions.HANDLE_COUNTRY_MOBILE:
                handler = new HandleCountryMobileActionHandler();
            break;
            case FormActions.HANDLE_COUNTRY_SECONDARY_MOBILE:
                handler = new HandleCountrySecondaryMobileActionHandler();
            break;
            case FormActions.HANDLE_MAIN_COUNTRY_PHONE:
                handler = new HandleCountryMainPhoneActionHandler();
            break;
            case FormActions.HANDLE_GENERIC_PHONE:
                handler = new HandleGenericPhoneActionHandler();
            break;
            case FormActions.HANDLE_MAIN_COUNTRY_FAX:
                handler = new HandleCountryMainFaxActionHandler();
            break;
            case FormActions.HANDLE_PHONE_COUNTRY_CODE_CONTACT_PERSON:
                handler = new HandlePhoneCountryCodeContactPersonActionHandler();
            break;
            case FormActions.HANDLE_SECONDARY_PHONE_COUNTRY_CODE_CONTACT_PERSON:
                handler = new HandleSecondaryPhoneCountryCodeContactPersonActionHandler();
            break;
            case FormActions.HANDLE_FAX_COUNTRY_CODE_CONTACT_PERSON:
                handler = new HandleFaxCountryCodeContactPersonActionHandler();
            break;
            case FormActions.HANDLE_MOBILE_COUNTRY_CODE_CONTACT_PERSON:
                handler = new HandleMobileCountryCodeContactPersonActionHandler();
            break;
            case FormActions.HANDLE_SECONDARY_MOBILE_COUNTRY_CODE_CONTACT_PERSON:
                handler = new HandleSecondaryMobileCountryCodeContactPersonActionHandler();
            break;
            case FormActions.HANDLE_PRECALCULATION_REFERENCE_ASSOCIATION_TYPE_SECTION:
                handler = new FormActionHandlePrecalculationReferenceAssociationTypeSection(this.genericCrudService);
            break;
            case FormActions.LOAD_ENTITY_FROM_API:
                handler = new LoadEntityFromApiActionHandler(this.genericCrudService);
            break;
            case FormActions.LOCK_FIELDS:
                handler = new LockFieldsActionHandler();
            break;
            case FormActions.SET_ID_URL:
                handler = new SetIdToUrlActionHandler(this.locationService, this.moduleNavigation, this.modulesStateService);
            break;
            case FormActions.CALCULATE_REFERENCE_ASSOCIATION_FACTOR:
                handler = new CalculateReferenceAssociationFactorActionHandler();
            break;
            case FormActions.CALCULATE_INTRANET_BUNDLE_VEHICLE_DATA_DEDUCTIBLE_VALUES:
              handler = new CalculateIntranetBundleVehicleDataDeductibleValuesActionHandler();
              break;
            case FormActions.CALCULATE_INTRANET_BUNDLE_VEHICLE_DATA_LEASING_TO:
              handler = new CalculateIntranetBundleVehicleDataLeasingToActionHandler();
              break;
            case FormActions.HANDLE_TICKET_BUNDLE_TICKET_DOCUMENT_UPLOAD_FIELDS:
              handler = new HandleTicketBundleTicketDocumentUploadFieldsActionHandler();
              break;
            case FormActions.SETUP_PHONE_PROJECT_FORM:
              handler = new SetupPhoneProjectFormActionHandler();
            break;
          case FormActions.SETUP_SUB_ASSIGNMENT_FORM:
            handler = new SetupSubAssignmentFormActionHandler(this.genericCrudService, this.entityDirtyStore, this.entityManager, this.translateService);
            break;
          case FormActions.SETUP_ADVANCE_FORM:
            handler = new SetupAdvanceFormActionHandler(this.genericCrudService, this.entityDirtyStore, this.entityManager, this.translateService);
            break;
          case FormActions.SETUP_ADDRESS_FORM:
            handler = new HandleAddressSelectionActionHandler(this.genericCrudService, this.entityDirtyStore, this.entityManager);
            break;
          case FormActions.HANDLE_ALGOLIA_PLACE_CHANGE:
            handler = new HandleAlgoliaPlaceChangeActionHandler(this.genericCrudService, this.entityDirtyStore, this.entityManager);
            break;
          case FormActions.HANDLE_GEOAPIFY_AUTOCOMPLETE_CHANGE:
            handler = new HandleGeoapifyAutocompleteChangeActionHandler(this.genericCrudService, this.entityDirtyStore, this.entityManager);
            break;
          case FormActions.HANDLE_ALGOLIA_PLACE_ENTITY_CHANGED:
            handler = new HandleAlgoliaPlaceEntityChangedActionHandler(this.genericCrudService, this.entityDirtyStore, this.entityManager);
            break;
          case FormActions.HANDLE_GEOAPIFY_AUTOCOMPLETE_ENTITY_CHANGED:
            handler = new HandleGeoapifyAutocompleteEntityChangedActionHandler(this.genericCrudService, this.entityDirtyStore, this.entityManager);
            break;
          case FormActions.SHOW_FIELD:
            handler = new ShowFieldActionHandler();
            break;
          case FormActions.HIDE_FIELD:
            handler = new HideFieldActionHandler();
            break;
          case FormActions.SETUP_MEMO_FORM:
            handler = new SetupMemoFormActionHandler(this.entityManager, this.stepFactory);
            break;
          case FormActions.HANDLE_LEASED_EMPLOYEE_DRIVING_LICENCE:
            handler = new HandleLeasedEmployeeDrivingLicenceActionHandler();
            break;
          case FormActions.HANDLE_JOB_ADVERTISEMENTS:
            handler = new HandleJobAdvertisementActionHandler();
            break;
          case FormActions.HANDLE_JOB_ADVERTISEMENT_CONTINGENTS:
            handler = new HandleJobAdvertisementContingentsActionHandler(this.genericCrudService, this.userSession);
            break;
          case FormActions.HANDLE_CONTRACT_OF_EMPLOYMENT:
            handler = new HandleContractOfEmploymentFormActionHandler(this.translateService);
            break;
          case FormActions.HANDLE_ONE_TO_ONE_ENTITY:
            handler = new HandleOneToOneEntityFormActionHandler(this.locationService);
            break;
          case FormActions.SETUP_WORKPLACE_FORM:
            handler = new SetupWorkplaceFormActionHandler();
            break;
          case FormActions.SETUP_INVOICE_FORM:
            handler = new SetupInvoiceFormActionHandler(this.authenticationService);
            break;
          case FormActions.SETUP_INVOICE_POSSIBILITY_FORM:
            handler = new SetupInvoicePossibilityFormActionHandler();
            break;
          case FormActions.SETUP_CONTACT_PERSON_FORM:
            handler = new SetupContactPersonFormActionHandler(this.formService);
            break;
          case FormActions.HANDLE_PRECALCULATION_IS_LOCKED_STATE:
            handler = new HandlePrecalculationIsLockedActionHandler();
            break;
        }

        if (null === handler) {
            console.log(`FormAction handler not found for action ${action.className}`);
        }

        if (handler instanceof AbstractFormActionHandler) {
            handler.setEntity(entity)
              .setAction(action)
              .setElement(element)
              .setForm(form)
              .setFormGroup(formGroup)
              .setComponent(component)
              .setFormService(this.formService);
        }

        return handler;
    }

    /**
     *
     * @param {FormAction} action
     * @param {FormActionCondition }condition
     * @param {Form} form
     * @throws {Error} - when handler is not found
     * @returns {FormActionConditionHandler}
     */
    public getFormActionConditionHandler(action: FormAction, condition: FormActionCondition, form: Form, entity: any, formGroup: FormGroup, element: Element): FormActionConditionHandler {
        let handler = null;

        switch (condition.className) {
            case FormActionConditions.IS_FORM_VALUE_EMPTY:
                handler = new IsFormValueEmptyConditionHandler(this.formService);
                break;
            case FormActionConditions.IS_FORM_VALUE_NOT_EMPTY:
                handler = new IsFormValueNotEmptyConditionHandler(this.formService);
                break;
            case FormActionConditions.IS_CHANGING_ELEMENT:
                handler = new IsChangingElementConditionHandler(this.formService);
                break;
            case FormActionConditions.IS_NOT_CHANGING_ELEMENT:
                handler = new IsNotChangingElementConditionHandler(this.formService);
                break;
            case FormActionConditions.IS_ELEMENT_VALUE_EQUAL:
                handler = new IsElementValueEqualConditionHandler(this.formService);
                break;
            case FormActionConditions.IS_ELEMENT_VALUE_NOT_EQUAL:
                handler = new IsElementValueNotEqualConditionHandler(this.formService);
                break;
            case FormActionConditions.IS_ENTITY_VALUE_EQUAL:
                handler = new IsEntityValueEqualConditionHandler();
                break;
            case FormActionConditions.IS_ENTITY_VALUE_NOT_EQUAL:
                handler = new IsEntityValueNotEqualConditionHandler();
                break;
            case FormActionConditions.IS_ENTITY_NEW:
                handler = new IsEntityNewConditionHandler();
                break;
            case FormActionConditions.IS_EXISTING_ENTITY:
                handler = new IsExistingEntityConditionHandler();
                break;
        }

        if (null === handler) {
            throw new Error(`FormActionCondition handler not found for condition ${condition.className}`);
        }

        if (handler instanceof AbstractFormActionConditionHandler) {
            handler.setEntity(entity).setAction(action).setCondition(condition).setElement(element).setForm(form).setFormGroup(formGroup);
        }

        return handler;
    }
}
