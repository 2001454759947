
import {map} from 'rxjs/operators';
import {Observable} from 'rxjs';
import {AbstractExecutionStep} from '../../../../core/executor/abstract-execution-step';
import {ExecutionStepStatus} from '../../../../core/executor/execution-step-status';
import {GenericElementAbstract} from '../../../content-renderer/elements/generic-element-abstract.component';
import {MessageGrowlService} from '../../../../core/message/message-growl.service';
import {TranslateService} from '@ngx-translate/core';
import {AbstractGenericGridComponent} from '../../../content-renderer/elements/abstract-generic-grid.component';
import {FormViewerComponent} from '../../../form-viewer/form-viewer.component';
import {PrintTemplateEntityFileTypeService} from '../../../../core/file/entity-type-file/print-template-entity-file-type.service';
import {ToolbarItemWordExport} from '../../../content-renderer/elements/generic-toolbar/items/components/word-export/toolbar-item-word-export.component';
import {TOAST_LIFE_INFINITE, ToastComponentsRegistry, ToastService} from '../../../../core/service/toast.service';
import {FormViewerService} from '../../../form-viewer/form-viewer.service';
import {FormService} from '../../../form-viewer/form.service';

export class ToolbarItemContractOfEmploymentCreateEldaExecutionStep extends AbstractExecutionStep {

  public doExecute(): Observable<ExecutionStepStatus> {
    const payload = this.getPayload(),
      component = payload.getValue().component ? payload.getValue().component : payload.getValue();

    if (!(component instanceof FormViewerComponent)) {
      return this.getFailObservable('You need to pass GenericElementAbstract as Payload value!');
    }

    const entity = component.getSelectedEntity();
    if (entity && (entity.fqn !== 'PhoenixBundle\\Entity\\ContractOfEmployment' || !entity.id)) {
      return this.getFailObservable('You need to pass existing CollectiveAgreement entity!');
    }

    this.disableButton(component);
    return this.doActivate(component);
  }

  doActivate(component: FormViewerComponent): Observable<ExecutionStepStatus> {
    const contractOfEmployment = component.getSelectedEntity();

    this.getToast().custom(ToastComponentsRegistry.PROGRESS_BAR, {
      severity: 'info',
      life: TOAST_LIFE_INFINITE,
      closable: false,
      summary: this.getTranslate().instant('COMMON.SAVING_PLEASE_WAIT')
    });

    return new Observable<ExecutionStepStatus>((observer) => {
      const url = ToolbarItemWordExport.WORD_EXPORT_URL + component.getElementDatamodel().id + '/file/'
        + contractOfEmployment.id + '/report/190/gkk';

      this.genericCrudService.customPost(url, {}).pipe(
        map((fileResponse) => {
          return this.genericCrudService.customPost(`phoenix/contractofemployments/create-elda/${contractOfEmployment.id}`, {fileName: fileResponse.fileName}).subscribe();
        })
      ).subscribe(() => {
        this.getToast().clear(ToastComponentsRegistry.PROGRESS_BAR, 300);
        this.getGrowl().success(
          this.getTranslate().instant('COMMON.DATA_SAVED'),
          this.getTranslate().instant('COMMON.SUCCESS')
        );
      });

      observer.next({
        status: true,
        content: null
      });
      observer.complete();
    });
  }

  private disableButton(component: FormViewerComponent): void {
    const mainButton = this.getFormService().getElementBy(component.form, 'label', 'Anmeldung');
    mainButton.disabled = true;
  }

  private getGrowl(): MessageGrowlService {
    return this.injector.get(MessageGrowlService, null);
  }

  private getTranslate(): TranslateService {
    return this.injector.get(TranslateService, null);
  }

  private getToast(): ToastService|null {
    return this.injector.get(ToastService, null);
  }

  private getFormService(): FormService {
    return this.injector.get(FormService, null);
  }
}
