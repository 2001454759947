import {ElementRef, Injectable, Renderer2} from '@angular/core';
import { GenericTreeGridComponent } from '../generic-tree-grid.component';
import { EntityDataStoreService } from 'app/shared/content-renderer/services/entity-data-store.service';
import {EntityStatus} from '../../../../services/entity/entity-status';

@Injectable()
export class GenericTreeLayoutService {

  private tree: GenericTreeGridComponent = null;
  private elementRef: ElementRef = null;
  private renderer: Renderer2 = null;

  private entityRowClassMappings = {
      'dirty-entity-node': EntityStatus.ENTITY_CHANGED_FLAG,
      'draft-deleted-entity-node': EntityStatus.ENTITY_DRAFT_DELETED_FLAG,
      'draft-entity-node': EntityStatus.ENTITY_DRAFT_FLAG,
      'invalid-entity-node': EntityStatus.ENTITY_INVALID_FLAG
  };

  public constructor(
    private entityDataStore: EntityDataStoreService
  ) {

  }

  public setScrollWidth(): GenericTreeLayoutService {

    if (this.tree && this.tree.element && this.tree._gridContainer) {
      const gridContainerWidth = this.tree._gridContainer.nativeElement.clientWidth;

      this.tree.scrollWidth = `${gridContainerWidth}px`;

      return this;
    }

    return this;
  }

  public setScrollHeight(): GenericTreeLayoutService {

    if (this.tree && this.tree.element && this.tree._gridContainer) {
      let gridContainerHeight = this.tree._gridContainer.nativeElement.clientHeight;

      const  dataTableBody = this.elementRef.nativeElement.querySelector('.ui-treetable-scrollable-body'),
        dataTableHeader = this.elementRef.nativeElement.querySelector('.ui-treetable-scrollable-header');

      if (dataTableHeader) {
        gridContainerHeight -= +dataTableHeader.clientHeight;
      }

      if (this.tree.element.isPaginable) {
        gridContainerHeight -= 29;
      }

      if (this.tree.element.topToolbarItems && this.tree.element.topToolbarItems.length > 0) {
        gridContainerHeight -= 30;
      }

      if (this.tree.element.statusBarItems && this.tree.element.statusBarItems.length > 0) {
        gridContainerHeight -= 30;
      }

      if (this.tree.isFilterContainerVisible || this.tree.element.isFilterContainerAlwaysVisible) {
        gridContainerHeight -= 200;
      }

      // when datatable is empty
      if (dataTableBody) {
        dataTableBody.style.height = `${gridContainerHeight}px`;
        dataTableBody.style.maxHeight = '';
      }

      this.tree.scrollHeight = `${gridContainerHeight}px`;
    }

    return this;
  }

  public markEntityNodes(): GenericTreeLayoutService {

    if (this.tree && this.tree.element && this.tree._gridContainer) {
        setTimeout(() => {
            let treeRows = this.elementRef.nativeElement.querySelectorAll('.ui-treetable-row-selectable');

            for (let row of treeRows) {
                this.setRowClass(row);
            }
        }, 500);
    }

    return this;
  }

  public getEntityCellClass(entity: any): string {
        let className = '';

        // if (this.entityDataStore.isExpired(entity)) {
        //    className += ' expired-entity-node';
        // }

        if (this.entityDataStore.isEntityDeleted(entity)) {
            className += ' deleted-entity-node';
        }

        return className;
  }

  public setTree(tree: GenericTreeGridComponent): GenericTreeLayoutService {
    this.tree = tree;
    return this;
  }

  public getTree(): GenericTreeGridComponent | null {
    return this.tree || null;
  }

  public setElementRef(elementRef: ElementRef): GenericTreeLayoutService {
    this.elementRef = elementRef;
    return this;
  }

  public getElementRef(): ElementRef|null {
      return this.elementRef;
  }

  public setRenderer(renderer: Renderer2): GenericTreeLayoutService {
    this.renderer = renderer;
    return this;
  }

  public getRenderer(): Renderer2|null {
      return this.renderer;
  }

  /**
   * @description workaround for tree styling - sadly there is no other way
   * based on generic-tree.component.html mark tree table row
   * @param row
   */
  private setRowClass(row): void {

    if (row && row.firstElementChild && row.firstElementChild.childNodes) {
        let tdSpans = row.firstElementChild.getElementsByTagName("span");

        this.removeRowClasses(row);

        if ( tdSpans instanceof HTMLCollection ) {
            for (let i = 0; i < tdSpans.length; i++) {
                this.addRowClasses(row, tdSpans.item(i));
            }
        }
    }
  }

  private addRowClasses(row, tdSpan): void {
    var me = this;

    Object.keys(this.entityRowClassMappings).forEach(function(key, index) {
        const attribute = me.entityRowClassMappings[key];

        if (tdSpan.getAttribute(attribute)) {
            me.renderer.addClass(row, key);
        }
    });
  }

  private removeRowClasses(row): void {
    var me = this;

    Object.keys(this.entityRowClassMappings).forEach(function(key, index) {
        me.renderer.removeClass(row, key);
    });
  }

}
