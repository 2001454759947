import {of as observableOf, Observable} from 'rxjs';
import {AbstractExecutionStep} from '../../../../core/executor/abstract-execution-step';
import {ExecutionStepStatus} from '../../../../core/executor/execution-step-status';
import {AbstractGenericGridComponent} from '../../../content-renderer/elements/abstract-generic-grid.component';
import {MessageGrowlService} from '../../../../core/message/message-growl.service';
import {TOAST_LIFE_INFINITE, ToastComponentsRegistry, ToastService} from '../../../../core/service/toast.service';
import {TranslateService} from '@ngx-translate/core';
import {FormViewerComponent} from '../../../form-viewer/form-viewer.component';
import {GenericDialogModuleService} from '../../../content-renderer/elements/generic-dialog/service/generic-dialog-module.service';

export class CreateMemoFromAssignmentExecutionStep extends AbstractExecutionStep {

  public doExecute(): Observable<ExecutionStepStatus> {
    const component = this.getPayload().getValue() || this.getPayload().getValue().component;

    if (!(component instanceof FormViewerComponent)) {
      return this.getFailObservable('You need to pass AbstractGenericGridComponent as Payload value!');
    }

    return this.doSet(component);
  }

  protected doSet(component: FormViewerComponent): Observable<ExecutionStepStatus> {
    const masterComponent = this.getMasterComponent(component);
    const selectedEntities = {
      leasedEmployees: masterComponent.selectedEntities.map(function (item) {return item.leasedEmployee}),
      mailTemplate: component.entity.mailTemplate
    }
    if (selectedEntities) {
      this.getToast().custom(ToastComponentsRegistry.PROGRESS_BAR, {
        severity: 'info',
        life: TOAST_LIFE_INFINITE,
        closable: false,
        summary: this.getTranslate().instant('COMMON.LOADING_PLEASE_WAIT')
      });
      this.genericCrudService.customPost('phoenix/assignments/fromLeasedEmployee/memo', selectedEntities).subscribe(() => {
        this.getToast().clear(ToastComponentsRegistry.PROGRESS_BAR, 300);
        this.showSuccess();
        this.getGenericDialogModuleService().persistHide();
      });
    }
    return observableOf({status: true, content: null});
  }

  private getMasterComponent(component: FormViewerComponent): AbstractGenericGridComponent|null {
    if (component.masterElementContext && component.masterElementContext.component &&
        component.masterElementContext.component instanceof AbstractGenericGridComponent) {
      return component.masterElementContext.component;
    }

    return null;
  }

  protected showSuccess(): void {
    this.getGrowl().success(
      this.getTranslate().instant('COMMON.EMAIL_SENT' + ' & ' + 'COMMON.MEMO_IS_CREATED'),
      this.getTranslate().instant('COMMON.SUCCESS')
    );
  }

  private getGrowl(): MessageGrowlService|null {
    return this.injector.get(MessageGrowlService, null);
  }

  private getToast(): ToastService|null {
    return this.injector.get(ToastService, null);
  }

  private getTranslate(): TranslateService|null {
    return this.injector.get(TranslateService, null);
  }

  private getGenericDialogModuleService(): GenericDialogModuleService|null {
    return this.injector.get(GenericDialogModuleService, null);
  }
}
