import {Injectable} from '@angular/core';
import {Content} from './content';

@Injectable()
export class InvoicePdfContentService {
  private _data = null;
  public setData(data): this {
    this._data = data;
    return this;
  }
  public getContent(): Content[] {
    return [
      {
        watermark: this._data.watermark ? { text: 'Entwurf', opacity: 0.1} : null,
        content: [
          {
            image: this._data.logo,
            alignment: 'right',
            width: 175
          },
          {
            columns: [
              [
                {
                  text: this._data.customer.primaryName,
                  style: 'name'
                },
                {
                  text: this._data.address.street,
                },
                {
                  text: this._data.address.postalCode + ' ' + this._data.address.city,
                },
                {
                  text: 'Österreich',
                }
              ]
            ]
          },
          {
            columns: [
              [
                {
                  text: [
                    { text: 'Rechnungsnummer: ', style: 'upRightFirst' },
                    { text: this._data.invoice.invoiceNumber ? this._data.invoice.invoiceNumber : '' },
                  ]
                },
                {
                  text: [
                    { text: 'Kundennummer: ', style: 'upRightFirst' },
                    { text: this._data.customer.customerNumber ? this._data.customer.customerNumber : '' },
                  ]
                },
                {
                  text: [
                    { text: 'Ihre UID-Nummer: ', style: 'upRightFirst' },
                    { text: this._data.customer.invoiceUidNumber ? this._data.customer.invoiceUidNumber : '' },
                  ]
                },
                {
                  text: 'Kreditorennummer: ',
                  style: 'upRightFirst'
                }
              ]
            ],
            margin: [380, 0, 0, 0]
          },
          {
            layout: 'headerLineOnly',
            table: {
              headerRows: 1,
              widths: [ '25%', '10%', '23%', '14%', '14%', '14%'],
              body: [
                [
                  { text: 'LEISTUNG', style: 'firstTableHeaderRow' },
                  { text: 'MENGE', style: 'tableHeaderRow' },
                  { text: 'BEZEICHNUNG', style: 'tableHeaderRow' },
                  { text: 'PREIS', style: 'tableHeaderRow' },
                  { text: 'BETRAG', style: 'tableHeaderRow' },
                  { text: 'GESAMT', style: 'tableHeaderRow' }
                ],
                ...this.getTableBody(),
              ]
            },
            margin: [0, 20, 0, 0]
          },
          {
            columns: [
              [
                {
                  text: 'Zahlungsziel: ' + this._data.paymentCondition,
                  margin: [0, 20, 0, 0]
                }
              ]
            ]
          },
          {
            columns: [
              [
                {
                  text: 'Bitte überweisen Sie innerhalb der Zahlungsfrist und geben Sie bei Telebanking/Überweisung im Feld Zahlungsreferenz die Rechnungsnummer an.',
                  margin: [0, 20, 0, 0]
                }
              ]
            ]
          }
        ],
        styles: {
          upRightFirst: {
            bold: true,
            color: 'blue'
          },
          tableHeaderRow: {
            bold: true,
            color: 'blue',
            alignment: 'right'
          },
          firstTableHeaderRow: {
            bold: true,
            color: 'blue'
          }
        },
        defaultStyle: {
          fontSize: 9
        }
      }
    ];
  }
  getTableBody(): any {
    const data = [];
    const emptyValues = ['', '', '', '', ''];
    for (const invoicePosition of this._data.invoicePositions) {
      data.push([invoicePosition.leasedEmployee, ...emptyValues]);
      if (invoicePosition.invoicePositionDetails instanceof Array) {
        for (let i = 0; i < invoicePosition.invoicePositionDetails.length; i++) {
          const entity = invoicePosition.invoicePositionDetails[i];
          data.push([
            '',
            {text: entity.amount, alignment: 'right'},
            {text: entity.description, alignment: 'right'},
            {text: entity.value, alignment: 'right'},
            {text: entity.total, alignment: 'right'},
            ''
          ]);
        }
      }
      data.push([...emptyValues, {text: invoicePosition.articlesTotal, alignment: 'right'}]);
    }
    data.push(['', ...emptyValues]);
    data.push(['', ...emptyValues]);
    data.push(['Nettobetrag:', '', '', '', '', {text: this._data.sum.total, alignment: 'right'}]);
    data.push([this._data.sum.vatName + ' Umsatzsteuer:', '', '', '', '', {text: this._data.sum.vat, alignment: 'right'}]);
    data.push([{text: 'Rechnungsbetrag', bold: true}, '', '', '', '', {text: this._data.sum.totalWithVat, alignment: 'right'}])
    return data;
  }

}
