import { ElementInput } from './element';

export class ElementInputFileUpload extends ElementInput {
  filePath: string;
  fileContext: string;
  constructor(
  ) {
    super();
    this.typeElement = 'file-upload'
    this.filePath = '';
    this.fileContext = '';
  }
}


