import { AbstractValidator } from './abstract-validator';
import { GenericCrudService } from '../../services/generic-crud.service';
import { EntityHydrator } from '../../services/entity-hydrator.service';

export interface AsyncValidator {
  setGenericCrudService(genericCrudService: GenericCrudService): AsyncValidator;
  getGenericCrudService(): GenericCrudService;
  setEntityHydrator(entityHydrator: EntityHydrator): AsyncValidator;
  getEntityHydrator(): EntityHydrator;
}

export abstract class AbstractAsyncValidator extends AbstractValidator implements AsyncValidator {

    protected genericCrudService: GenericCrudService;
    protected entityHydrator: EntityHydrator;

    public abstract getConstraint(): any;

    public setGenericCrudService(genericCrudService: GenericCrudService): this {
        this.genericCrudService = genericCrudService;
        return this;
    }

    public getGenericCrudService(): GenericCrudService {
        return this.genericCrudService;
    }

    public setEntityHydrator(entityHydrator: EntityHydrator): this {
        this.entityHydrator = entityHydrator;
        return this;
    }

    public getEntityHydrator(): EntityHydrator {
        return this.entityHydrator;
    }

    public getErrorTranslated(errors: any) {
        return errors[this.getFieldName()];
    }

    public shouldValidate(entity: any): boolean {
        return true;
    }
}
