import {Injectable, Injector} from '@angular/core';
import {PrintTemplateEntityFileTypeService} from './entity-type-file/print-template-entity-file-type.service';
import {DocumentEntityFileTypeService} from './entity-type-file/document-entity-file-type.service';

@Injectable()
export class FileTypeFactoryService {

  public constructor(
    private injector: Injector
  ) {

  }

  public getService(entity: any): any|null {
    const fqn = entity.fqn;

    let type = null;

    switch (fqn) {
      case 'PhoenixBundle\\Entity\\PrintTemplate':
        type = PrintTemplateEntityFileTypeService;
        break;
      case 'PhoenixBundle\\Entity\\Document':
        type = DocumentEntityFileTypeService;
        break;
    }

    return this.injector.get(type, null);
  }
}
