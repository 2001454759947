<button
  pButton
  *ngIf="column.fieldType === 'openmodule'"
  [disabled]="component.isEditCellDisabled(entity, column)"
  class="openmodule-button"
  [label]="component.getEditCellValue(entity, column) || column.header"
  (click)="component.onEditCell($event, entity, column)">
</button>

<input
  *ngIf="component.isEditCellTextEditor(entity, column)"
  type="text"
  [disabled]="component.isEditCellDisabled(entity, column)"
  (keyup)="component.onEditCell($event, entity, column)"
  pInputText
  [(ngModel)]="entity[column.property]"
  (focus)="component.onEditCellFocus($event, entity, column)"
  (blur)="component.onEditCellBlur($event, entity, column)"
/>

<span
  *ngIf="column.fieldType === 'lockState'"
>
  <i class="pi pi-lock" style="font-size: 1.5em" *ngIf="getEntityAssociatedOneEntityValue(entity, column) === true"></i>
  <i class="pi pi-lock-open" style="font-size: 1.5em" *ngIf="getEntityAssociatedOneEntityValue(entity, column) === false"></i>
</span>

<p-autoComplete
  *ngIf="column.filterType === 'autocomplete'"
  appAutocomplete
  appendTo="body"
  field="label"
  [dropdown]="true"
  [ngModel]="component.getEditCellValue(entity, column)"
  [placeholder]="getEntityAssociatedOneEntityValueLabel(entity, column)"
  [suggestions]="component.associatedOptions[column.entityName]"
  (completeMethod)="onEditCellSearch($event, entity, column)"
  (onDropdownClick)="onEditCellSearch($event, entity, column)"
  styleClass="ui-column-editor-autocomplete"
  (onSelect)="component.onEditCell($event, entity, column)"
  [disabled]="component.isEditCellDisabled(entity, column)"
>
  <ng-template let-item pTemplate="item">

<span class="ui-state-highlight" *ngIf="entity[column.entityName] == item.id">
  {{item.label}}
</span>

    <span *ngIf="entity[column.entityName] != item.id">
  {{item.label}}
</span>

  </ng-template>
</p-autoComplete>

<app-generic-calendar-field-leased-employee-absence-date
  *ngIf="!column.isAssociatedField && column.fieldType === 'leasedEmployeeAbsenceDate'"
  [entity]="entity"
  [column]="column"
  [editor]="true"
  (onEdit)="component.onEditCell($event, entity, column)"
  (onFocus)="component.onCalendarFocus($event, entity, column)"
></app-generic-calendar-field-leased-employee-absence-date>

<p-dropdown
  *ngIf="
  (column.fieldType === 'association' || column.fieldType === 'dropdown') &&
  column.filterType !== 'autocomplete'"
  appendTo="body"
  [disabled]="component.isEditCellDisabled(entity, column)"
  (onChange)="component.onEditCell($event, entity, column)"
  (onFocus)="onEditCellFocus($event, entity, column)"
  [ngModel]="component.getEditCellValue(entity, column)"
  placeholder=" --- Bitte wählen --- "
  [options]="component.associatedOptions[column.entityName]"
  [autoWidth]="false" [style]="{'width':'100%'}">
</p-dropdown>

<p-dropdown
  *ngIf="column.fieldType === 'company-setting'"
  appendTo="body"
  [disabled]="component.isEditCellDisabled(entity, column)"
  (onChange)="component.onEditCell($event, entity, column)"
  (onFocus)="onEditCellFocus($event, entity, column)"
  [ngModel]="entity[column.property]"
  placeholder=" --- Bitte wählen --- "
  [options]="settingOptions"
  [autoWidth]="false" [style]="{'width':'100%'}">
</p-dropdown>

<p-dropdown
  *ngIf="column.fieldType === 'company-setting-value'"
  appendTo="body"
  [disabled]="component.isEditCellDisabled(entity, column)"
  (onChange)="component.onEditCell($event, entity, column)"
  (onFocus)="onEditCellFocus($event, entity, column)"
  [ngModel]="entity[column.property]"
  placeholder=" --- Bitte wählen --- "
  [options]="getSettingValueOptions(entity)"
  [autoWidth]="false" [style]="{'width':'100%'}">
</p-dropdown>

<div *ngIf="column.fieldType === 'decimal'">
  <app-decimal-column
    #decimalComponent
    [entity]="entity"
    [component]="this"
    [column]="column"
    [editMode]="true"
    [disabled]="component.isEditCellDisabled(entity, column)"
    (onBlur)="component.onEditCellBlur($event, entity, column)"
    (onKeyUp)="component.onEditCell($event, entity, column)"
    (onFocus)="component.onEditCellFocus($event, entity, column)"
    (onBeforeFocus)="component.onEditCellBeforeFocus($event, entity, column)"
    (tabOrEnter)="component.onTabOrEnter($event, entity, column)"
  ></app-decimal-column>
</div>

<p-multiSelect
  *ngIf="column.fieldType === 'associationMany'"
  appendTo="body"
  (onChange)="component.onEditCell($event, entity, column)"
  (onFocus)="component.onEditCellFocus($event, entity, column)"
  [ngModel]="
      component.associatedOptionsValues[column.entityName] &&
      component.associatedOptionsValues[column.entityName][entity.uniqueId] ?
      component.associatedOptionsValues[column.entityName][entity.uniqueId] :
      []
"
  [options]="component.associatedOptions[column.entityName]"
  [style]="{'width':'100%'}"
  [disabled]="component.isEditCellDisabled(entity, column)"
></p-multiSelect>

<app-generic-calendar
  *ngIf="column.fieldType === 'date'"
  (onInputChangeValid)="component.onEditCell($event, entity, column)"
  (onSelect)="component.onEditCell($event, entity, column)"
  [disabled]="component.isEditCellDisabled(entity, column)"
  [value]="entity[column.property]"
  [yearRange]="'1950:2050'"
  [mask]="'99.99.9999'"
  [dateFormat]="'dd.mm.yy'"
  [showOnFocus]="true"
></app-generic-calendar>

<p-inputMask
  appTimeMask
  *ngIf="column.fieldType === 'workHourTime' ||
  column.fieldType === 'time'
"
  [(ngModel)]="entity[column.property]"
  (keyup)="onInputMaskKeyUp($event, entity, column)"
  (onChange)="component.onEditCell($event, entity, column)"
  [disabled]="component.isEditCellDisabled(entity, column)"
  (onFocus)="component.onEditCellFocus($event, entity, column)"
  (focus)="component.onEditCellFocus($event, entity, column)"
  [mask]="'99:99'"
></p-inputMask>

<app-generic-calendar
  *ngIf="column.fieldType === 'dateTime'"
  (onInputChangeValid)="component.onEditCell($event, entity, column)"
  (onSelect)="component.onEditCell($event, entity, column)"
  [disabled]="component.isEditCellDisabled(entity, column)"
  [value]="entity[column.property]"
  [yearRange]="'1950:2050'"
  [mask]="'99.99.9999 99:99'"
  [showTime]="true"
  [dateFormat]="'dd.mm.yy'"
  [showOnFocus]="true"
></app-generic-calendar>

<app-generic-calendar
  *ngIf="column.fieldType === 'dateMonthAndYear'"
  (onInputChangeValid)="component.onEditCell($event, entity, column)"
  (onSelect)="component.onEditCell($event, entity, column)"
  [disabled]="component.isEditCellDisabled(entity, column)"
  [value]="entity[column.property]"
  [yearRange]="'1950:2050'"
  [mask]="'99.9999'"
  [dateFormat]="'mm.yy'"
  [showOnFocus]="true"
  [view]="'month'"
></app-generic-calendar>

<a
  *ngIf="column.fieldType === 'link'"
  class="link-entity-cell"
  (click)="component.onEditCellFocus($event, entity, column)"
>{{ entity[column.property] }}</a>

<p-checkbox
  *ngIf="column.fieldType === 'checkbox'"
  [disabled]="component.isEditCellDisabled(entity, column)"
  (onChange)="component.onEditCell($event, entity, column)"
  [ngModel]="getEntityAssociatedOneEntityValue(entity, column)"
  binary="true"
></p-checkbox>

<p-triStateCheckbox
  *ngIf="column.fieldType === 'tricheckbox'"
  [disabled]="component.isEditCellDisabled(entity, column)"
  (onChange)="component.onEditCell($event, entity, column)"
  [(ngModel)]="entity[column.property]"
></p-triStateCheckbox>

<p-checkbox
  *ngIf="column.fieldType === 'permissionInformation' && entity && entity.permissionInformation && entity.permissionInformation[column.organisation]"
  [disabled]="component.isEditCellDisabled(entity, column)"
  (onChange)="component.onInstantEditCell($event, entity, column)"
  [ngModel]="entity.permissionInformation[column.organisation][column.property]"
  binary="true"
  class="ui-column-editor-checkbox"
></p-checkbox>

<div *ngIf="column.fieldType === 'image'">
  <app-image-column
    [entity]="entity"
    [component]="component"
    [column]="column"
    [editMode]="true"
  ></app-image-column>
</div>

<div *ngIf="column.fieldType === 'color'">
  <div class="generic-grid-color-picker-color">
    <p-colorPicker
      appColorPicker
      [disabled]="component.isEditCellDisabled(entity, column)"
      (onChange)="component.onEditCell($event, entity, column)"
      [ngModel]="entity[column.property]"
      [value]="entity[column.property]"
      [showOnInit]="true"
      appendTo="body"
    ></p-colorPicker>
  </div>
  <div class="generic-grid-color-picker-input">
    <input
      appColorPickerInput
      type="text"
      [disabled]="component.isEditCellDisabled(entity, column)"
      (keyup)="component.onEditCell($event, entity, column)"
      pInputText
      [ngModel]="entity[column.property]"
      (focus)="component.onEditCellFocus($event, entity, column)"
      [focusOnInit]="true"
    />
  </div>
</div>

<span *ngIf="column.fieldType === 'moduleElementColumn'">
    <app-generic-column [entity]="entity" [component]="component" [column]="column.moduleElementColumn"></app-generic-column>
</span>
