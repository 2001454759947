import {ChangeDetectionStrategy, Component} from '@angular/core';
import { ToolbarItemAbstract } from '../toolbar-item-abstract.component';
import { GenericCrudService } from '../../../../../../services/generic-crud.service';
import { MessageGrowlService } from '../../../../../../../core/message/message-growl.service';
import { environment } from '../../../../../../../../environments';
import { AuthenticationService } from '../../../../../../../core/authentication/authentication.service';
import { AbstractGenericGridComponent } from 'app/shared/content-renderer/elements/abstract-generic-grid.component';
import {RequestCachingService} from '../../../../../../services/request-caching.service';
import {PhoneProjectGroupEntity} from './phone-project-group.entity';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'toolbar-item-phone-project-creator',
  templateUrl: './toolbar-item-phone-project-creator.component.html'
})
export class ToolbarItemPhoneProjectCreatorComponent extends ToolbarItemAbstract {

  public isDialogVisible = false;

  public phoneProjectGroup: PhoneProjectGroupEntity = null;

  public baseUrl = '';

  public questionnaires = [];

  public customersFile: any = null;
  public customersFileDataUrl = null;

  public phoneProjectFilesDataUrl: any = null;

  constructor(
    protected authenticationService: AuthenticationService,
    protected messageGrowlService: MessageGrowlService,
    protected requestCachingService: RequestCachingService,
    protected genericCrudService: GenericCrudService
  ) {
    super();
    this.baseUrl = environment.baseUrl;
  }

  public click() {
    const component = this.getComponent();

    if (component instanceof AbstractGenericGridComponent) {
      this.loadQuestionnaires();
      this.phoneProjectGroup = new PhoneProjectGroupEntity();
      this.isDialogVisible = true;
    }

  }

  public onChangeFile(event) {
    this.customersFile = event.srcElement.files[0];

    this.subscriptions.push(event.srcElement.files[0].toDataURL().subscribe((dataURL) => {
      this.customersFileDataUrl = dataURL;
    }));
  }

  protected loadQuestionnaires() {
    this.subscriptions.push(
      this.genericCrudService.getPaginated('superadmin/questionnaires/offset/0/limit/100/orderby/id/asc', {}).subscribe((entities) => {
        for (const questionnaire of entities.data) {
          this.questionnaires.push({
            label: questionnaire.name,
            value: questionnaire
          });
        }
      })
    );
  }

  public onCreate(event): void {
    const component = this.getComponent();

    this.phoneProjectGroup.phoneProjectFilesDataUrl = this.customersFileDataUrl;

    this.subscriptions.push(
      this.genericCrudService.createEntity('phoenix/phoneprojectgroups', this.phoneProjectGroup).subscribe((newEntity) => {
        if (component instanceof AbstractGenericGridComponent) {
          this.subscriptions.push(
            component.loadEntities().subscribe((result) => {
              this.dialogHide();
            })
          );
        }
      })
    );
  }

  public onCancel($event): void {
    this.dialogHide();
  }

  public onUpload(event) {
    const component = this.getComponent();

    if (component instanceof AbstractGenericGridComponent) {
      this.requestCachingService.removeByExpression(component.getElementDataModelApiRoute());
      component.loadEntities().subscribe();
    }

    this.dialogHide();
  }

  dialogHide() {
    for (const subscription of this.subscriptions) {
      subscription.unsubscribe();
    }
    this.isDialogVisible = false;
  }
}
