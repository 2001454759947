<div style="width: 100%; height: 29px">
  <ul  class="tabrow">
    <li [class]="'selected'">
      <a appSeleniumDirective > Auswertungen </a>
      <a (click)="onTabClose()"><i class="fa fa-close"></i></a>
    </li>
  </ul>
</div>
<div id="container" class="fx-row" fxLayoutAlign="start stretch" fxLayout="column">
    <div fxFlex="10">
      <div class="fx-row toolbar-container">
        <!--<app-generic-toolbar [params]="getToolbarExtraParams()" [items]="toolbarItems" [additionalItems]="toolbarItems"></app-generic-toolbar>-->
      </div>
      <div class="fx-row" fxLayout="row" fxLayoutAlign="start">
        <div fxFlex="20" *ngFor="let clause of clauses; let i = index">
          <div fxFlex="30"><label for="clause{{i}}">{{ clause.name }}</label></div>
          <div [ngSwitch]="clause.columnType.code">
            <ng-template ngSwitchCase="number">
              <div fxFlex="70">
                <input
                  pInputText
                  id="clause{{i}}"
                  [(ngModel)]="clauseValues[clause.code]"
                  [textMask]="textMask"
                  [style]="{'width':'100%'}"
                /></div>
            </ng-template>
            <ng-template ngSwitchCase="date">
              <div fxFlex="70">
                <p-calendar
                  appCalendar
                  id="clause{{i}}"
                  [dateFormat]="'dd.mm.yy'"
                  [(ngModel)]="clauseValues[clause.code]"
                  [showIcon]="true"
                  [style]="{'width':'100%'}"
                ></p-calendar>
              </div>
            </ng-template>
            <ng-template ngSwitchCase="branchOffice">
              <div fxFlex="70">
                <p-dropdown
                  [(ngModel)]="clauseValues[clause.code]"
                  (onClick)="loadBranchOffices()"
                  (onChange)="onBranchOfficeChanged($event)"
                  placeholder=" --- Bitte wählen --- "
                  [options]="branchOffices"
                  filter="true"
                  [style]="{'width':'100%'}"
                ></p-dropdown>
              </div>
            </ng-template>
            <ng-template ngSwitchDefault>
              <div fxFlex="70"><input pInputText id="clause{{i}}" [(ngModel)]="clauseValues[clause.code]" /></div>
            </ng-template>
          </div>
        </div>
        <div fxFlex="10">
          <button style="width: 70%" type="button" pButton label="{{ 'COMMON.EXECUTE' | translate }}" (click)="loadData()"></button>
        </div>
      </div>
    </div>
    <div
      *ngIf="isReportTableVisible"
      fxFlex="90"
      #gridContainer
    ><shared-dynamic-table
        #table
        [rowsCount]="25"
        [columns]="columns"
        [rows]="[]"
        [totalCount]="totalCount"
        [entities]="visibleValues"
        [isLoadingData]="isLoadingReportData"
        (onFilter)="onFilter($event)"
        (onLazyLoad)="onLazyLoad($event)"
        [paginator]="true"
        [isPaginatorRightVisible]="true"
        [paginatorItems]="exportButtons"
        [selectFirstEntityWhenNothingIsSelected]="false"
      ></shared-dynamic-table>
    </div>
</div>
