/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../../content-renderer/content-renderer.component.ngfactory";
import * as i2 from "../../../../content-renderer/content-renderer.component";
import * as i3 from "../../../../services/generic-crud.service";
import * as i4 from "../../../../services/generic-crud-bulk.service";
import * as i5 from "../../../../services/layout-service";
import * as i6 from "../../../../directives/selenium.directive";
import * as i7 from "@angular/common";
import * as i8 from "./duplicate-entities-grid.component";
import * as i9 from "primeng/components/dynamicdialog/dynamicdialog-ref";
import * as i10 from "primeng/components/dynamicdialog/dynamicdialog-config";
import * as i11 from "../../../../content-renderer/services/modules-state.service";
import * as i12 from "../../../../content-renderer/services/elements-state.service";
var styles_DuplicateEntitiesGridComponent = [];
var RenderType_DuplicateEntitiesGridComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_DuplicateEntitiesGridComponent, data: {} });
export { RenderType_DuplicateEntitiesGridComponent as RenderType_DuplicateEntitiesGridComponent };
function View_DuplicateEntitiesGridComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "app-content-renderer", [["appSeleniumDirective", ""]], null, [["window", "resize"]], function (_v, en, $event) { var ad = true; if (("window:resize" === en)) {
        var pd_0 = (i0.ɵnov(_v, 1).onResize($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i1.View_ContentRendererComponent_0, i1.RenderType_ContentRendererComponent)), i0.ɵdid(1, 245760, null, 0, i2.ContentRendererComponent, [i3.GenericCrudService, i4.GenericCrudBulkService, i0.ElementRef, i5.LayoutService, i0.ChangeDetectorRef], { entity: [0, "entity"], isDialog: [1, "isDialog"], masterFilterField: [2, "masterFilterField"], masterFilterValue: [3, "masterFilterValue"], justAFilter: [4, "justAFilter"], module: [5, "module"] }, null), i0.ɵdid(2, 4210688, null, 0, i6.SeleniumDirective, [i0.ElementRef, i0.Renderer2], { module: [0, "module"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.entity; var currVal_1 = true; var currVal_2 = _co.masterFilterField; var currVal_3 = _co.masterFilterValue; var currVal_4 = _co.justAFilter; var currVal_5 = _co.module; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5); var currVal_6 = _co.module; _ck(_v, 2, 0, currVal_6); }, null); }
export function View_DuplicateEntitiesGridComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "div", [["class", "dialog-close-button-container ui-helper-clearfix ng-tns-c8-6"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "a", [["class", "ng-tns-c8-6 ui-dialog-titlebar-close ui-corner-all"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onCloseDialog($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 0, "span", [["class", "dialog-close-button fa fa-fw fa-close"]], null, null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 2, "div", [["class", "module-in-dialog"], ["style", "height: 100%"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_DuplicateEntitiesGridComponent_1)), i0.ɵdid(5, 16384, null, 0, i7.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.module; _ck(_v, 5, 0, currVal_0); }, null); }
export function View_DuplicateEntitiesGridComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-duplicate-entities-grid", [], null, null, null, View_DuplicateEntitiesGridComponent_0, RenderType_DuplicateEntitiesGridComponent)), i0.ɵdid(1, 114688, null, 0, i8.DuplicateEntitiesGridComponent, [i9.DynamicDialogRef, i10.DynamicDialogConfig, i11.ModulesStateService, i12.ElementsStateService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var DuplicateEntitiesGridComponentNgFactory = i0.ɵccf("app-duplicate-entities-grid", i8.DuplicateEntitiesGridComponent, View_DuplicateEntitiesGridComponent_Host_0, {}, {}, []);
export { DuplicateEntitiesGridComponentNgFactory as DuplicateEntitiesGridComponentNgFactory };
