import {Input} from '@angular/core';
import {AbstractGenericGridComponent} from '../../abstract-generic-grid.component';
import {FilterItem} from '../../../../services/element/filter-item';
import {GenericFilterComponent} from '../generic-filter.component';

export abstract class AbstractCustomFilterClassComponent  {

  @Input() component: AbstractGenericGridComponent = null;

  @Input() filterItem: FilterItem = null;

  @Input() genericFilterComponent: GenericFilterComponent = null;
}
