import {DynamicDialogConfig, DynamicDialogRef} from 'primeng/api';
import {Component} from '@angular/core';
import {ModulesStateService} from '../../../../content-renderer/services/modules-state.service';
import {ElementsStateService} from '../../../../content-renderer/services/elements-state.service';
import {Module} from '../../../../services/module/module';

@Component({
  selector: 'app-duplicate-entities-grid',
  templateUrl: './duplicate-entities-grid.component.html',
})
export class DuplicateEntitiesGridComponent {

  public module;
  public entity;
  public masterFilterField?: string;
  public masterFilterValue?: any;
  public justAFilter?: any;

  constructor(
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    protected modulesStateService: ModulesStateService,
    protected elementsStateService: ElementsStateService,
  ) { }

  ngOnInit() {
    this.module = this.config.data.module;
    this.entity = this.config.data.entity;
    this.masterFilterField = this.config.data.masterFilterField;
    this.masterFilterValue = this.config.data.masterFilterValue;
    this.justAFilter = this.config.data.justAFilter;
  }

  public onCloseDialog(event) {
    this.ref.close();
    this.removeModuleState(this.module);
    this.removeElementState(this.module);
  }

  public removeModuleState(module): void {
    const moduleState = this.modulesStateService.getCurrent();

    if (moduleState.id === module.id) {
      this.modulesStateService.remove(moduleState);
    }
  }

  public removeElementState(module: Module): void {
    for (const moduleElement of module.moduleElements) {
      this.elementsStateService.removeById(moduleElement.id);
    }
  }
}
