/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./toolbar-item-work-hour-monthly-grid-recalculate.component";
import * as i2 from "../../../../../../services/request-caching.service";
import * as i3 from "../../../../../../services/generic-crud.service";
var styles_ToolbarItemWorkHourMonthlyGridRecalculateComponent = [];
var RenderType_ToolbarItemWorkHourMonthlyGridRecalculateComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_ToolbarItemWorkHourMonthlyGridRecalculateComponent, data: {} });
export { RenderType_ToolbarItemWorkHourMonthlyGridRecalculateComponent as RenderType_ToolbarItemWorkHourMonthlyGridRecalculateComponent };
export function View_ToolbarItemWorkHourMonthlyGridRecalculateComponent_0(_l) { return i0.ɵvid(2, [], null, null); }
export function View_ToolbarItemWorkHourMonthlyGridRecalculateComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-toolbar-item-work-hour-monthly-grid-recalculate", [], null, null, null, View_ToolbarItemWorkHourMonthlyGridRecalculateComponent_0, RenderType_ToolbarItemWorkHourMonthlyGridRecalculateComponent)), i0.ɵdid(1, 245760, null, 0, i1.ToolbarItemWorkHourMonthlyGridRecalculateComponent, [i2.RequestCachingService, i3.GenericCrudService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ToolbarItemWorkHourMonthlyGridRecalculateComponentNgFactory = i0.ɵccf("app-toolbar-item-work-hour-monthly-grid-recalculate", i1.ToolbarItemWorkHourMonthlyGridRecalculateComponent, View_ToolbarItemWorkHourMonthlyGridRecalculateComponent_Host_0, { params: "params" }, {}, []);
export { ToolbarItemWorkHourMonthlyGridRecalculateComponentNgFactory as ToolbarItemWorkHourMonthlyGridRecalculateComponentNgFactory };
