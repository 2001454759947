<div class="ui-g-3" style="padding: 0px;">
  Industriezeit:
  <p-checkbox
    [(ngModel)]="industrialTime"
    (onChange)="onChangeIndustrialTime()"
    binary="true"
  ></p-checkbox>
</div>
<div *ngIf="industrialTime === false" class="ui-g-4" style="padding: 0px;">
  Dauer in Industriezeit:
  <p-checkbox
    [(ngModel)]="workLengthIndustrialTime"
    (onChange)="onChangeWorkLengthIndustrialTime()"
    binary="true"
  ></p-checkbox>
</div>
<div class="ui-g-5" style="padding: 0px;">
  <p-dropdown
    [options]="entryTypeOptions | orderBy: {property: 'label', direction: '1'}"
    [style]="{'width':'100%'}"
    [(ngModel)]="entryType"
    (onChange)="onChangeEntryType()"
    appendTo="body"
  ></p-dropdown>
</div>
