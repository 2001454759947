
import {of as observableOf, Observable} from 'rxjs';
import {AbstractExecutionStep} from '../../../core/executor/abstract-execution-step';
import {ExecutionStepStatus} from '../../../core/executor/execution-step-status';
import {AbstractGenericGridComponent} from '../../content-renderer/elements/abstract-generic-grid.component';

export class ComponentClearCachedEntitiesExecutionStep extends AbstractExecutionStep {

  public doExecute(): Observable<ExecutionStepStatus> {
    const payload = this.getPayload();

    if (!(payload.getValue() instanceof AbstractGenericGridComponent)) {
      return this.getFailObservable('You need to pass AbstractGenericGridComponent as Payload value!');
    }

    return this.doClear(payload.getValue());
  }

  protected doClear(component: AbstractGenericGridComponent): Observable<ExecutionStepStatus> {
    component.runtimeData.cachedChangedTreeNodes = [];
    component.runtimeData.cachedChangedEntities = [];

    return observableOf({status: true, content: null });
  }
}
