
import {throwError as observableThrowError, Observable} from 'rxjs';

import {catchError, map} from 'rxjs/operators';
import {Component, OnInit, ViewChild} from '@angular/core';
import {AbstractEditExpertSearchFieldValue} from '../abstract-edit-expert-search-field-value';
import {GenericCrudService} from '../../../../../../../../../services/generic-crud.service';
import {EntityStatus} from '../../../../../../../../../services/entity/entity-status';
import {GenericGridComponent} from '../../../../../../../../../content-renderer/elements/generic-grid/generic-grid.component';
import {ModuleElement} from '../../../../../../../../../services/module/module-element';
import {FieldMetadataGrid} from '../../../../../../../../../services/module/module-element-field-metadata-grid';

interface ParamValue {
  id: number;
  name: string;
}

const MODULE_PARAM = 'module';
const MODULE_ELEMENT_PARAM = 'moduleElement';

@Component({
  selector: 'app-search-management-edit-generic-grid-edit-expert-search-field-value',
  templateUrl: './generic-grid-edit-expert-search-field-value.component.html',
  styles: [`
    :host {
      height: 100%;
      width: 600px;
    }
    
    .container {
      height: 100%;
      overflow: hidden;
    }
  `]
})
export class SearchManagementGenericGridEditExpertSearchFieldValueComponent extends AbstractEditExpertSearchFieldValue implements OnInit {

  public isLoadingModules = false;
  public isLoadingModuleElements = false;

  public selectedEntities: {id: number}[] = [];
  public moduleElement: ModuleElement = null;

  public moduleOptions: SelectItem[] = [];
  public selectedModuleOption: SelectItem = null;

  public moduleElementOptions: SelectItem[] = [];
  public selectedModuleElementOption: SelectItem = null;

  @ViewChild('grid', {static: false}) public grid: GenericGridComponent = null;

  public constructor(
    private readonly genericCrudService: GenericCrudService
  ) {
    super();
  }

  public ngOnInit(): void {
    this.loadModuleOptions().subscribe((options: SelectItem[] = []) => {
      this.moduleOptions = options;
    });

    if (this.field && this.field[EntityStatus.ENTITY_DRAFT_FLAG]) {
      this.initEdit();
    }
  }

  public onModuleOptionChange(event): void {
    this.setParameter(MODULE_PARAM, {
      id: event.value.id,
      name: event.value.label
    });

    this.setParameter(MODULE_ELEMENT_PARAM, []);

    this.loadModuleElementOptions()
      .subscribe((options: SelectItem[] = []) => {
        this.moduleElementOptions = options;
      });
  }

  public onModuleElementOptionChange(event): void {
    this.setParameter(MODULE_ELEMENT_PARAM, {
      id: event.value.id,
      name: event.value.label
    });

    this.setModuleElement(event.value);
  }

  public onSelectionChanged(entities: any[] = []): void {
    const values = [],
      labelValues = [];

    if (entities instanceof Array) {
      for (const entity of entities) {
        const label = entity.name || entity.code; // todo :: this needs to be configured with display config

        values.push({
          id: entity.id,
          name: label,
          code: label
        });
        labelValues.push(label);
      }
    }

    this.onChange.emit({
      value: JSON.stringify(values),
      labelValue: labelValues.join(', ')
    });
  }

  private loadModuleOptions(): Observable<SelectItem[]> {
    return this.genericCrudService.getEntities('superadmin/modules').pipe(
      catchError((response) => {
        return observableThrowError(response);
      }),
      map((dmRecords: any) => {
        const options = [],
          records = (dmRecords.data) ? dmRecords.data : dmRecords;

        for (const record of records) {
          options.push({
            label: record.name,
            value: record
          });
        }

        return options;
      }),);
  }

  private loadModuleElementOptions(): Observable<SelectItem[]> {
    const module = this.getParameterValue(MODULE_PARAM),
      apiRoute = `superadmin/modules/${module.id}/elements`;

    return this.genericCrudService.getEntities(apiRoute).pipe(
      catchError((response) => {
        return observableThrowError(response);
      }),
      map((dmRecords: any) => {
        const options = [],
          records = (dmRecords.data) ? dmRecords.data : dmRecords;

        for (const record of records) {
          options.push({
            label: record.name,
            value: record
          });
        }

        return options;
      }),);
  }

  private loadModuleElement(moduleId: number, moduleElementId: number): Observable<ModuleElement> {
    const apiRoute = `superadmin/modules/${moduleId}/elements/${moduleElementId}`;

    return this.genericCrudService.get(apiRoute).pipe(
      catchError((response) => {
        return observableThrowError(response);
      }),
      map((entity: any) => {
        return entity;
      }),);
  }

  private initEdit(): void {
    const module: ParamValue = this.getParameterValue(MODULE_PARAM);

    if (module && module.id) {
      this.isLoadingModules = true;

      this.loadModuleOptions()
        .subscribe((options: SelectItem[] = []) => {
          this.moduleOptions = options;
          this.isLoadingModules = false;

          this.selectModuleOption();

          this.isLoadingModuleElements = true;
          this.loadModuleElementOptions()
            .subscribe((moduleOptions: SelectItem[] = []) => {
              this.moduleElementOptions = moduleOptions;
              this.isLoadingModuleElements = false;

              this.selectModuleElementOption();

              if (this.selectedModuleElementOption) {
                this.setModuleElement(this.selectedModuleElementOption);
                this.selectFieldValue();
              }
            });
        });
    }
  }

  private selectFieldValue(): void {
    const values = this.field.fieldValue ? JSON.parse(this.field.fieldValue) : [];

    if (values && values instanceof Array) {
      this.selectedEntities = values;
    }
  }

  private selectModuleOption(): void {
    const module: ParamValue = this.getParameterValue(MODULE_PARAM);

    if (module) {
      for (const option of this.moduleOptions) {
        if (option.value.id === +module.id) {
          this.selectedModuleOption = option.value;
          break;
        }
      }
    }
  }

  private selectModuleElementOption(): void {
    const moduleElement: ParamValue = this.getParameterValue(MODULE_ELEMENT_PARAM);

    if (moduleElement) {
      for (const option of this.moduleElementOptions) {
        if (option.value.id === +moduleElement.id) {
          this.selectedModuleElementOption = option.value;
          break;
        }
      }
    }
  }

  private setModuleElement(moduleElement: SelectItem|ModuleElement|any): void {
    moduleElement.element.filterItems = [];

    this.moduleElement = moduleElement;
  }
}
