<div class="ui-grid ui-grid-responsive ui-fluid">

  <div>
    <!-- hidden as we want to have state of the search grid when we go back to it -->
    <div [hidden]="context !== 'company-search-grid'">
      <app-ksv-company-search-grid (onCompanyChanged)="onCompanyChanged($event)"></app-ksv-company-search-grid>
    </div>

    <div *ngIf="context === 'company-product-grid'">
      <app-ksv-company-product-grid (onProductChanged)="onProductChanged($event) "[company]="company"></app-ksv-company-product-grid>
    </div>

    <div *ngIf="context === 'company-product-details'">
      <app-ksv-company-product-details [company]="company" [product]="product"></app-ksv-company-product-details>
    </div>
  </div>

  <div class="ui-grid-row" *ngIf="context === 'company-product-grid' || context === 'company-product-details'">
    <div class="ui-grid-col-1">
      <button pButton type="button" (click)="onGoBack()" label="{{ 'COMMON.BACK' | translate }}"></button>
    </div>
  </div>

</div>
