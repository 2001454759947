import {RunnableEventRegistry} from '../../../../core/job-runner/type/runnable-event.registry';
import {AbstractCondition} from '../../../../core/job-runner/condition/abstract.condition';
import {JobContext} from '../../../../core/job-runner/context/job.context';
import {ElementInputDropdownComponent} from '../../../form-viewer/element/element-input-dropdown.component';

export class SetElementFiltersCondition extends AbstractCondition {
  isApplicable(context: JobContext): boolean {
    const element = context.component;
    if (context.event === RunnableEventRegistry.PostInit
      && element instanceof ElementInputDropdownComponent
      && element.element.datamodelField === this.getPayload()['component']) {
      return true;
    }

    return false;
  }
}
