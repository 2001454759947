/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./toolbar-item-set-order-offer-send-state.component";
import * as i2 from "../../../../../../../core/message/message-growl.service";
import * as i3 from "../../../../../../../core/message/message.service";
import * as i4 from "../../../../../../services/generic-crud.service";
import * as i5 from "../../../../../services/entity-data-store.service";
import * as i6 from "../../../../../services/component-highlight-stack.service";
import * as i7 from "@ngx-translate/core";
var styles_ToolbarItemSetOrderOfferSendStateComponent = [];
var RenderType_ToolbarItemSetOrderOfferSendStateComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_ToolbarItemSetOrderOfferSendStateComponent, data: {} });
export { RenderType_ToolbarItemSetOrderOfferSendStateComponent as RenderType_ToolbarItemSetOrderOfferSendStateComponent };
export function View_ToolbarItemSetOrderOfferSendStateComponent_0(_l) { return i0.ɵvid(2, [], null, null); }
export function View_ToolbarItemSetOrderOfferSendStateComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-toolbar-item-set-order-offer-send-state", [], null, null, null, View_ToolbarItemSetOrderOfferSendStateComponent_0, RenderType_ToolbarItemSetOrderOfferSendStateComponent)), i0.ɵdid(1, 245760, null, 0, i1.ToolbarItemSetOrderOfferSendStateComponent, [i2.MessageGrowlService, i3.MessageService, i4.GenericCrudService, i5.EntityDataStoreService, i6.ComponentService, i7.TranslateService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ToolbarItemSetOrderOfferSendStateComponentNgFactory = i0.ɵccf("app-toolbar-item-set-order-offer-send-state", i1.ToolbarItemSetOrderOfferSendStateComponent, View_ToolbarItemSetOrderOfferSendStateComponent_Host_0, { params: "params" }, {}, []);
export { ToolbarItemSetOrderOfferSendStateComponentNgFactory as ToolbarItemSetOrderOfferSendStateComponentNgFactory };
