import {Observable} from 'rxjs/Observable';
import {AbstractExecutionStep} from '../../../../core/executor/abstract-execution-step';
import {ExecutionStepStatus} from '../../../../core/executor/execution-step-status';
import {ElementsStackService} from '../../../content-renderer/services/elements-stack.service';
import {ElementContext, ElementType} from '../../../content-renderer/services/ElementContext';
import {EMPTY} from 'rxjs';
import {ChangeDetectorRefHelper} from '../../../helpers/change-detector-ref.helper';

export class CalculateGrossAmountExecutionStep extends AbstractExecutionStep {

  public doExecute(): Observable<ExecutionStepStatus> {
    const element = this.getMemoFieldDefinitionValueComponent();

    if (element && element.component && element.component.memo) {
      const value = this.getPayload().getValue();

      const vatMemoFieldDefinition = element.component.findMemoFieldDefinitionByName('Steuersatz');
      const grossValueDefinition = element.component.findMemoFieldDefinitionByName('Bruttobetrag');

      if (vatMemoFieldDefinition && grossValueDefinition) {
        const vatMemoFieldDefinitionValue = element.component.findMemoFieldDefinitionValue(vatMemoFieldDefinition);

        if (vatMemoFieldDefinitionValue && vatMemoFieldDefinitionValue.value) {
          this.genericCrudService.getEntity('phoenix/vats', vatMemoFieldDefinitionValue.value, null, {embedded: 'none'}).subscribe(vat => {
            element.component.registerEntityFieldValueChange(grossValueDefinition, value * (100 + vat.value) / 100);
            ChangeDetectorRefHelper.markForCheck(element.component);
          });
        }
      }
    }

    return EMPTY;
  }

  getMemoFieldDefinitionValueComponent(): ElementContext|null {
    return this.injector.get(ElementsStackService).getAll().find((context: ElementContext) => {
      return context.type === ElementType.MemoFieldDefinitionValue
    })
  }
}
