import { AbstractValidator } from './abstract-validator';

export class MaxLengthValidator extends AbstractValidator {

  public isValid(value: any): boolean {

      if (typeof value === 'undefined' || value === null) {
        value = '';
      }

      let valueLength = value.toString().length,
        paramValue = this.getParamValue('maxLength');

      return valueLength < paramValue;
  }

  public getErrorTranslateKey(value: any): string {
    return 'MAX_LENGTH_IS_NOT_VALID';
  }

  public getErrorTranslateParams(value: any): any {
    return null;
  }
}
