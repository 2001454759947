import {AbstractFormActionHandler} from '../abstract-form-action-handler';
import {Entity} from '../../../../helpers/entity';
import {AuthenticationService} from '../../../../../core/authentication/authentication.service';
import {FormViewerComponent} from '../../../form-viewer.component';
import {ElementContext} from '../../../../content-renderer/services/ElementContext';
import {ChangeDetectorRefHelper} from '../../../../helpers/change-detector-ref.helper';

export class SetupInvoiceFormActionHandler extends AbstractFormActionHandler {

  public constructor(
    private authenticationService: AuthenticationService
  ) {
    super();
  }

  public handleAction(): void {
    const entity = this.getEntity(),
      invoiceNumber = Entity.getValue(entity, 'invoiceNumber');

    if (entity) {
      let lock = invoiceNumber !== null && invoiceNumber !== '';

      if (lock) {
        lock = !this.authenticationService.currentUser.isSuperUser;
      }

      this.doLockFields(lock);
      // this.doLockToolbarItems(lock);
      this.doLockTable(lock);

      if (!lock) {
        // this.doLockFieldsWhichAreNonEmpty(entity);
      }
    }
  }

  private doLockFields(doLock: boolean): void {
    const formGroup = this.getFormGroup(),
      controls = formGroup.controls;

    for (const key in controls) {
      if (doLock) {
        formGroup.controls[key].disable();
      } else {
        formGroup.controls[key].enable();
      }
    }
  }

  private doLockToolbarItems(doLock: boolean): void {
    const component = this.getComponent();

    if (component instanceof FormViewerComponent) {
      component.isToolbarDisabled = doLock;

      if (component.element.topToolbarItems instanceof Array) {
        for (const toolbarItem of component.element.topToolbarItems) {
          toolbarItem.disabled = doLock;
        }
      }

      if (component.element.statusbarItems instanceof Array) {
        for (const statusBarItem of component.element.statusbarItems) {
          statusBarItem.disabled = doLock;
        }
      }

      ChangeDetectorRefHelper.detectChanges(component);
    }
  }

  private doLockTable(doLock: boolean): void {
    const component = this.getComponent();

    if (component instanceof FormViewerComponent) {
      const slaves = component.getElementContext().getSlaveElementContexts();

      if (slaves instanceof Array) {
        const invoiceTableContext: ElementContext = slaves[0];

        if (invoiceTableContext && invoiceTableContext.component) {
          invoiceTableContext.component.isEditable = !doLock;
          invoiceTableContext.component.isToolbarDisabled = doLock;
          invoiceTableContext.component.initColumns();
        }
      }
    }
  }

  private doLockFieldsWhichAreNonEmpty(entity): void {
    const formGroup = this.getFormGroup(),
      fields = [
        'invoiceDate',
        'invoiceState',
        'invoiceType',
        'period',
        'sentAt'
      ];

    for (const field of fields) {
      const element = this.formService.getElementBy(this.getForm(), 'datamodelField', field),
        doLock = Entity.getValue(entity, field) !== null && entity.id;

      if (element) {
        const formControl = FormViewerComponent.getFormControlName(element.objectHashId, field);

        if (formControl) {
          if (doLock) {
            formGroup.controls[formControl].disable();
          } else {
            formGroup.controls[formControl].enable();
          }
        }
      }
    }
  }
}
