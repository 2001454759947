/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "primeng/components/dom/domhandler";
import * as i2 from "primeng/components/button/button";
import * as i3 from "primeng/components/tooltip/tooltip";
import * as i4 from "@ngx-translate/core";
import * as i5 from "../../../../../p-button/p-button.directive";
import * as i6 from "./tree-add-child-node.component";
var styles_TreeAddChildNodeComponent = [];
var RenderType_TreeAddChildNodeComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_TreeAddChildNodeComponent, data: {} });
export { RenderType_TreeAddChildNodeComponent as RenderType_TreeAddChildNodeComponent };
export function View_TreeAddChildNodeComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 5, "button", [["icon", "fa fa-plus"], ["pButton", ""]], [[8, "disabled", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onAddNode() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵprd(4608, null, i1.DomHandler, i1.DomHandler, []), i0.ɵdid(2, 4341760, null, 0, i2.ButtonDirective, [i0.ElementRef], { icon: [0, "icon"] }, null), i0.ɵdid(3, 4341760, null, 0, i3.Tooltip, [i0.ElementRef, i0.NgZone], { text: [0, "text"] }, null), i0.ɵpid(131072, i4.TranslatePipe, [i4.TranslateService, i0.ChangeDetectorRef]), i0.ɵdid(5, 4341760, null, 0, i5.PButtonDirective, [i0.ElementRef], { icon: [0, "icon"] }, null)], function (_ck, _v) { var currVal_1 = "fa fa-plus"; _ck(_v, 2, 0, currVal_1); var currVal_2 = i0.ɵinlineInterpolate(1, "", i0.ɵunv(_v, 3, 0, i0.ɵnov(_v, 4).transform("COMMON.ADD_CHILD_NODE")), ""); _ck(_v, 3, 0, currVal_2); var currVal_3 = "fa fa-plus"; _ck(_v, 5, 0, currVal_3); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = (!_co.entity || !_co.entity.id); _ck(_v, 0, 0, currVal_0); }); }
export function View_TreeAddChildNodeComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-tree-node-add-child-column", [], null, null, null, View_TreeAddChildNodeComponent_0, RenderType_TreeAddChildNodeComponent)), i0.ɵdid(1, 49152, null, 0, i6.TreeAddChildNodeComponent, [i0.ElementRef], null, null)], null, null); }
var TreeAddChildNodeComponentNgFactory = i0.ɵccf("app-tree-node-add-child-column", i6.TreeAddChildNodeComponent, View_TreeAddChildNodeComponent_Host_0, { node: "node", entity: "entity", component: "component", column: "column" }, {}, []);
export { TreeAddChildNodeComponentNgFactory as TreeAddChildNodeComponentNgFactory };
