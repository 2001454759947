import {JobInterface} from '../../../core/job-runner/job/job.interface';
import {JobContext} from '../../../core/job-runner/context/job.context';
import {Observable} from 'rxjs';
import {JobExecutionStatus} from '../../../core/job-runner/job/job-execution-status';
import {ConditionInterface} from '../../../core/job-runner/condition/condition.interface';
import {AbstractJob} from '../../../core/job-runner/job/abstract.job';
import {AbstractGenericGridComponent} from '../../content-renderer/elements/abstract-generic-grid.component';

export class ReloadAssignmentsGridJob extends AbstractJob implements JobInterface {
  run(context: JobContext, condition: ConditionInterface): Observable<JobExecutionStatus> {
    return Observable.create((observer) => {
      const component = condition.getPayload().component;

      if (component instanceof AbstractGenericGridComponent) {
        component.loadEntities().subscribe();

        observer.next({
          status: true,
          content: null,
          jobId: this.jobId
        });

        observer.complete();
      } else {
        observer.next({
          status: false,
          content: 'wrong component or entity not set',
          jobId: this.jobId
        });

        observer.complete();
      }
    });
  }
}
