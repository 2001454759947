
import {map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';


import { ModuleElement } from './module-element';
import { AppHttpService } from '../../../app-http.service';
import { GenericCrudService } from '../generic-crud.service';

@Injectable()
export class ModuleElementCrudService extends AppHttpService {

  private moduleElementsUrl: string = 'superadmin/modules/:moduleId/elements';

  constructor(private genericCrudService: GenericCrudService) {
    super();
  }

  /**
   * @argument {number} moduleId The id of the selected module.
   * @argument {number} moduleElementId The id of the selected module element.
   * @argument {string} format Optional. If not set, then the entities are returned as is.
   *                            Selectable formats: formenu (MenuItem), fortree (TreeNode).
   */
  getModuleElement(moduleId: number, moduleElementId: number, format = ''): Observable<ModuleElement> {
    format = format ? `/${format}` : '';
    const urlWithIdandFormat = this.moduleElementsUrl.replace(/\:moduleId/g, String(moduleId)) + '/' + moduleElementId + format;
    return this.genericCrudService.get(urlWithIdandFormat);
  }

  getModuleElements(moduleId: number, format = '', parentId = ''): Observable<ModuleElement[]> {
    format = format ? `/${format}` : '';
    parentId = parentId ? `/${parentId}` : '';
    const urlWithIdandFormat = this.moduleElementsUrl.replace(/\:moduleId/g, String(moduleId)) + format + parentId;
    return this.genericCrudService.get(urlWithIdandFormat).pipe(
      map((moduleElements: Array<any>) => {
        return <Array<ModuleElement>>moduleElements;
      }));
  }

  getAllModuleModuleElements(moduleId: number): Observable<ModuleElement[]> {
    const url = 'superadmin/modules/' + moduleId.toString() + '/all/elements';
    return this.genericCrudService.get(url).pipe(
      map((moduleElements: Array<any>) => {
        return <Array<ModuleElement>>moduleElements;
      }));
  }

  getModuleElementSlaves(moduleId: number, masterId: number): Observable<any> {
    const urlWithIdandFormat = this.moduleElementsUrl.replace(/\:moduleId/g, String(moduleId)) + '/' + masterId + '/slaves';
    return this.genericCrudService.get(urlWithIdandFormat).pipe(
      map((moduleElements: Array<any>) => {
        return <Array<ModuleElement>>moduleElements;
      }));
  }

  deleteModuleElement(moduleId: number, moduleElementId: number): Observable<Object> {
    if (moduleId > 0 && moduleElementId > 0) {
      const urlWithId = this.moduleElementsUrl.replace(/\:moduleId/g, String(moduleId));
      return this.genericCrudService.deleteEntity(urlWithId + '/' + moduleElementId);
    } else {
      throw new Error('Invalid module element id given.');
    }
  }

  createModuleElement(moduleId: number, moduleElement: ModuleElement, parentModuleElement: ModuleElement, format: string = null): Observable<ModuleElement> {
    if (moduleId > 0 && moduleElement) {
      if (parentModuleElement) {
        moduleElement.parentId = parentModuleElement.id;
      }

      delete moduleElement._embedded;

      format = format ? `/${format}` : '';
      const urlWithIdandFormat = this.moduleElementsUrl.replace(/\:moduleId/g, String(moduleId)) + format;
      return this.genericCrudService.createEntity(urlWithIdandFormat, moduleElement);
    } else {
      throw new Error(`
        Invalid module element given.
        Module id: ${moduleId};
        new module: ${moduleElement};
        parent module element: ${parentModuleElement}
      `);
    }
  }

  editModuleElement(moduleId: number, moduleElement: ModuleElement, format: string = null): Observable<ModuleElement> {
    if (moduleId > 0 && moduleElement) {

      delete moduleElement._embedded;

      format = format ? `/${format}` : '';
      const urlWithIdandFormat = this.moduleElementsUrl.replace(/\:moduleId/g, String(moduleId)) + '/' + moduleElement.id + format;

      return this.genericCrudService.editEntity(urlWithIdandFormat, moduleElement).pipe(
        map((json) => { return <ModuleElement>json; }));
    } else {
      throw new Error('No module given.');
    }
  }
}
