/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./log.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@ngx-translate/core";
import * as i3 from "primeng/components/table/table";
import * as i4 from "primeng/components/tooltip/tooltip";
import * as i5 from "../../../../node_modules/primeng/components/table/table.ngfactory";
import * as i6 from "primeng/components/common/shared";
import * as i7 from "../../../../node_modules/primeng/components/panel/panel.ngfactory";
import * as i8 from "primeng/components/panel/panel";
import * as i9 from "../../../../node_modules/primeng/components/common/shared.ngfactory";
import * as i10 from "@angular/common";
import * as i11 from "./log.component";
import * as i12 from "../content-renderer/services/logger/logger.service";
var styles_LogComponent = [i0.styles];
var RenderType_LogComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_LogComponent, data: {} });
export { RenderType_LogComponent as RenderType_LogComponent };
function View_LogComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 9, "tr", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "th", [], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", ""])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(4, 0, null, null, 2, "th", [], null, null, null, null, null)), (_l()(), i1.ɵted(5, null, ["", ""])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(7, 0, null, null, 2, "th", [], null, null, null, null, null)), (_l()(), i1.ɵted(8, null, ["", ""])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef])], null, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 2, 0, i1.ɵnov(_v, 3).transform("LOG.LEVEL.LEVEL")); _ck(_v, 2, 0, currVal_0); var currVal_1 = i1.ɵunv(_v, 5, 0, i1.ɵnov(_v, 6).transform("LOG.MESSAGE")); _ck(_v, 5, 0, currVal_1); var currVal_2 = i1.ɵunv(_v, 8, 0, i1.ɵnov(_v, 9).transform("COMMON.MENU")); _ck(_v, 8, 0, currVal_2); }); }
function View_LogComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 9, "tr", [], [[2, "ui-selectable-row", null], [2, "ui-state-highlight", null], [1, "tabindex", 0]], [[null, "click"], [null, "touchend"], [null, "keydown"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).onClick($event) !== false);
        ad = (pd_0 && ad);
    } if (("touchend" === en)) {
        var pd_1 = (i1.ɵnov(_v, 1).onTouchEnd($event) !== false);
        ad = (pd_1 && ad);
    } if (("keydown" === en)) {
        var pd_2 = (i1.ɵnov(_v, 1).onKeyDown($event) !== false);
        ad = (pd_2 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 212992, null, 0, i3.SelectableRow, [i3.Table, i3.TableService], { data: [0, "data"] }, null), (_l()(), i1.ɵeld(2, 0, null, null, 1, "td", [], null, null, null, null, null)), (_l()(), i1.ɵted(3, null, ["", ""])), (_l()(), i1.ɵeld(4, 0, null, null, 1, "td", [], null, null, null, null, null)), (_l()(), i1.ɵted(5, null, ["", ""])), (_l()(), i1.ɵeld(6, 0, null, null, 3, "td", [], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 2, "i", [["aria-hidden", "true"], ["class", "fa fa-warning"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.openInfo(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(8, 4341760, null, 0, i4.Tooltip, [i1.ElementRef, i1.NgZone], { text: [0, "text"] }, null), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef])], function (_ck, _v) { var currVal_3 = _v.context.$implicit; _ck(_v, 1, 0, currVal_3); var currVal_6 = i1.ɵinlineInterpolate(1, "", i1.ɵunv(_v, 8, 0, i1.ɵnov(_v, 9).transform("LOG.INFO")), ""); _ck(_v, 8, 0, currVal_6); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵnov(_v, 1).isEnabled(); var currVal_1 = i1.ɵnov(_v, 1).selected; var currVal_2 = (i1.ɵnov(_v, 1).isEnabled() ? 0 : undefined); _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2); var currVal_4 = _co.getLevel(_v.context.$implicit); _ck(_v, 3, 0, currVal_4); var currVal_5 = _v.context.$implicit.message; _ck(_v, 5, 0, currVal_5); }); }
function View_LogComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "p-table", [["resizableColumns", "true"], ["scrollHeight", "500"], ["scrollable", "true"], ["selectionMode", "single"]], null, null, null, i5.View_Table_0, i5.RenderType_Table)), i1.ɵprd(512, null, i3.TableService, i3.TableService, []), i1.ɵdid(2, 5488640, null, 1, i3.Table, [i1.ElementRef, i1.NgZone, i3.TableService, i1.ChangeDetectorRef], { selectionMode: [0, "selectionMode"], scrollable: [1, "scrollable"], scrollHeight: [2, "scrollHeight"], resizableColumns: [3, "resizableColumns"], value: [4, "value"] }, null), i1.ɵqud(603979776, 1, { templates: 1 }), (_l()(), i1.ɵand(0, null, null, 1, null, View_LogComponent_2)), i1.ɵdid(5, 16384, [[1, 4]], 0, i6.PrimeTemplate, [i1.TemplateRef], { name: [0, "name"] }, null), (_l()(), i1.ɵand(0, null, null, 1, null, View_LogComponent_3)), i1.ɵdid(7, 16384, [[1, 4]], 0, i6.PrimeTemplate, [i1.TemplateRef], { name: [0, "name"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "single"; var currVal_1 = "true"; var currVal_2 = "500"; var currVal_3 = "true"; var currVal_4 = _co.logs; _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4); var currVal_5 = "header"; _ck(_v, 5, 0, currVal_5); var currVal_6 = "body"; _ck(_v, 7, 0, currVal_6); }, null); }
function View_LogComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 9, "p-panel", [], null, null, null, i7.View_Panel_0, i7.RenderType_Panel)), i1.ɵdid(1, 49152, null, 1, i8.Panel, [i1.ElementRef], null, null), i1.ɵqud(603979776, 2, { footerFacet: 0 }), (_l()(), i1.ɵeld(3, 0, null, 0, 5, "p-header", [], null, null, null, i9.View_Header_0, i9.RenderType_Header)), i1.ɵdid(4, 49152, null, 0, i6.Header, [], null, null), (_l()(), i1.ɵeld(5, 0, null, 0, 3, "div", [["class", "ui-helper-clearfix"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 2, "i", [["aria-hidden", "true"], ["class", "fa fa-close"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = ((_co.info = null) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(7, 4341760, null, 0, i4.Tooltip, [i1.ElementRef, i1.NgZone], { text: [0, "text"] }, null), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵted(9, 1, [" ", "\n"]))], function (_ck, _v) { var currVal_0 = i1.ɵinlineInterpolate(1, "", i1.ɵunv(_v, 7, 0, i1.ɵnov(_v, 8).transform("COMMON.CLOSE")), ""); _ck(_v, 7, 0, currVal_0); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.info; _ck(_v, 9, 0, currVal_1); }); }
export function View_LogComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_LogComponent_1)), i1.ɵdid(1, 16384, null, 0, i10.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_LogComponent_4)), i1.ɵdid(3, 16384, null, 0, i10.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.info; _ck(_v, 1, 0, currVal_0); var currVal_1 = _co.info; _ck(_v, 3, 0, currVal_1); }, null); }
export function View_LogComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-log", [], null, null, null, View_LogComponent_0, RenderType_LogComponent)), i1.ɵdid(1, 114688, null, 0, i11.LogComponent, [i2.TranslateService, i12.LoggerService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var LogComponentNgFactory = i1.ɵccf("app-log", i11.LogComponent, View_LogComponent_Host_0, {}, {}, []);
export { LogComponentNgFactory as LogComponentNgFactory };
