
import {of as observableOf, Observable} from 'rxjs';

import {catchError, switchMap} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {ExecutionStep} from './execution-step';
import {ExecutionStepStatus} from './execution-step-status';
import { ExecutionStatusSuccess } from './execution-status-success';
import { ExecutionStatusError, ExecutionStatusErrorAware } from './execution-status-error';
import { ExecutorActionsService } from './service/executor-actions/executor-actions.service';
import { ExecutorActionEvent } from './service/executor-actions/executor-action-event';
import { SkipConditionsAware } from './type/skip-conditions-aware';
import { ConditionExecutor } from './condition-executor';

@Injectable()
export class ExecutorService {

  private steps: ExecutionStep[] = [];

  public constructor(
    private executorActionsService?: ExecutorActionsService
  ) {

  }

  public execute(): Observable<ExecutionStatusSuccess> {

    if (this.steps.length === 0) {
      return observableOf(new ExecutionStatusSuccess({status: true, content: null}, null));
    }

    return this.executeStep(0);
  }

  public addStep(step: ExecutionStep): ExecutorService {
    this.steps.push(step);

    return this;
  }

  public setSteps(steps: ExecutionStep[] = []): ExecutorService {
    this.steps = steps;
    return this;
  }

  public fire(moduleElementId: number, event: ExecutorActionEvent, payload: any): Observable<ExecutionStatusSuccess> {
    const steps = this.executorActionsService.getSteps(moduleElementId, event, payload);

    return this.setSteps(steps).execute();
  }

  public removeStep(step: ExecutionStep): ExecutorService {
    const index = this.steps.indexOf(step);

    if (index === -1) {
      throw new Error(`Tried to step ${step} which is not found!`);
    }

    this.steps.splice(index, 1);

    return this;
  }

  public resetSteps(): ExecutorService {
    this.steps = [];
    return this;
  }

  private executeStep(stepIndex: number): Observable<any> {
    const step = this.steps[stepIndex],
      nextStepIndex = stepIndex + 1;

    return this.areSkipConditionsValid(step).pipe(
      switchMap((areSkipConditionsValid: boolean) => {

        if (areSkipConditionsValid === false) {

          if (!this.stepExists(nextStepIndex + 1)) {
            this.resetSteps();
            return observableOf(new ExecutionStatusSuccess({status: true, content: null}, step));
          }

          return this.executeStep(nextStepIndex + 1);
        }

        return this.getStepExecuteObservable(step, nextStepIndex);
      }));
  }

  private getStepExecuteObservable(step: ExecutionStep, nextStepIndex: number): Observable<any> {
    return step.execute().pipe(
      catchError((status) => {
        return observableOf({executionStepStatus: {status: false, content: null}, executionStep: step});
      }), switchMap((executionStepStatus: ExecutionStepStatus) => {
        if (executionStepStatus.status && this.stepExists(nextStepIndex)) {
          /**
           * @type {ExecutionStep}
           */
          const nextStep = this.steps[nextStepIndex];
          nextStep.setPreviousExecutionPayload(executionStepStatus);
          return this.executeStep(nextStepIndex);
        }

        if (executionStepStatus.status && !this.stepExists(nextStepIndex)) {
          this.resetSteps();
          return observableOf(new ExecutionStatusSuccess(executionStepStatus, step));
        }

        if (!executionStepStatus.status) {
          this.resetSteps();

          const error = new ExecutionStatusError(executionStepStatus, step);

          if (this.isExecutionStatusErrorAware(step)) {
            step.onError(error);
          }

          return observableOf(new ExecutionStatusError(executionStepStatus, step));
        }
      }));
  }

  private stepExists(stepIndex: number): boolean {
    return typeof this.steps[stepIndex] !== 'undefined';
  }

  private areSkipConditionsValid(step: ExecutionStep): Observable<boolean> {
    const conditionsExecutor = new ConditionExecutor();

    if (this.isSkipConditionsAware(step)) {
      return conditionsExecutor.execute(step, step.getSkipConditions());
    }

    return observableOf(true);
  }

  private isExecutionStatusErrorAware(component: any): component is ExecutionStatusErrorAware {
    return (<ExecutionStatusErrorAware>component).onError !== undefined;
  }

  private isSkipConditionsAware(component: any): component is SkipConditionsAware {
    return (<SkipConditionsAware>component).getSkipConditions !== undefined;
  }

}
