
import {of as observableOf, Observable} from 'rxjs';
import {AbstractExecutionStep} from '../../../../core/executor/abstract-execution-step';
import {ExecutionStepStatus} from '../../../../core/executor/execution-step-status';
import {AbstractGenericGridComponent} from '../../../content-renderer/elements/abstract-generic-grid.component';
import {Entity} from '../../../helpers/entity';
import {EntityManagerService} from '../../../../core/service/entity-manager/entity-manager.service';

export class OnLeasedEmployeeJobEntityChangeExecutionStep extends AbstractExecutionStep {

  public doExecute(): Observable<ExecutionStepStatus> {
    const payload = this.getPayload(),
      component = payload.getValue().component ? payload.getValue().component : payload.getValue();

    if (!(component instanceof AbstractGenericGridComponent)) {
      return this.getFailObservable('You need to pass AbstractGenericGridComponent as Payload value!');
    }

    return this.doChange(component);
  }

  protected doChange(component: AbstractGenericGridComponent):
    Observable<ExecutionStepStatus> {

    const entity = component.getSelectedEntity();

    if (!entity.id && Entity.getValue(entity, 'job.id')) {
      this.injector.get(EntityManagerService)
        .persist(entity, {
          property: 'isWanted',
          newValue: true,
          oldValue: null,
          force: true
        });

      component.getInlineEditService().markForCheck(entity);
    }

    return observableOf({status: true, content: null});
  }
}
