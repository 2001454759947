
    <div [formGroup]="formGroup">
      <input
        appSeleniumDirective
        [element]="element"
        type="password"
        pPassword
        [formControlName]="formControlName"
        [readonly]="isReadOnly()"
        [placeholder]="element.placeholder"
        (keyup)="onInputKeyUp($event)"
        tabindex="{{element.tabIndex}}"
        #elementItem
      />
    </div>
  