import {Injectable} from '@angular/core';
import {GenericElementAbstract} from '../../elements/generic-element-abstract.component';
import {ElementContext} from '../ElementContext';
import {AbstractGenericGridComponent} from '../../elements/abstract-generic-grid.component';
import {FormViewerComponent} from '../../../form-viewer/form-viewer.component';
import {ModuleState} from '../module-state';
import {ModulesStateService} from '../modules-state.service';

@Injectable()
export class GenericElementEmbeddedService {

  public constructor(
    private readonly modulesState: ModulesStateService
  ) {

  }

  public getEmbedded(component: GenericElementAbstract): string[] {
    const context: ElementContext = component.getElementContext(),
      moduleState: ModuleState = this.modulesState.getCurrent();

    let embedded = this.getComponentEmbedded(component);

    // based on element context
    if (context && context.component instanceof FormViewerComponent) {
      const slaves = context.getSlaveElementContexts();

      for (const slave of slaves) {
        if (this.areComponentsSharingEntity(component, slave.component)) {
          embedded = [...embedded, ...this.getComponentEmbedded(slave.component)];
        }
      }

      const parent = context.getMasterElementContext();

      if (parent && parent.component && this.areComponentsSharingEntity(component, parent.component)) {
        embedded = [...embedded, ...this.getComponentEmbedded(parent.component)];
      }
    }

    // based on module state
    if (moduleState && component instanceof FormViewerComponent) {
      const components = moduleState.getAllComponents();

      for (const aComponent of components) {
        if (this.areComponentsSharingEntity(component, aComponent)) {
          embedded = [...embedded, ...this.getComponentEmbedded(aComponent)];
        }
      }
    }

    return embedded;
  }

  public getEmbeddedAsString(component: GenericElementAbstract): string {
    const embedded = this.getEmbedded(component);

    return embedded.join(',');
  }

  private getComponentEmbedded(component: GenericElementAbstract): string[] {
    let embeddedFields = [];

    if (component instanceof AbstractGenericGridComponent) {
      embeddedFields = component.embeddedFields || ['self'];
    }

    if (component instanceof FormViewerComponent) {
      const embedded = component.moduleElement.element.embedded || 'self';

      return embedded.split(',');
    }

    return embeddedFields;
  }

  private areComponentsSharingEntity(component: GenericElementAbstract, secondComponent: GenericElementAbstract): boolean {
    return component.getElementDatamodelEntityName() === secondComponent.getElementDatamodelEntityName();
  }
}
