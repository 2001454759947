
import {map} from 'rxjs/operators';
import {Component} from '@angular/core';
import {FieldActionAbstractComponent} from '../field-action-abstract.component';
import {GenericCrudService} from '../../../../../services/generic-crud.service';
import {DoubleClickService} from '../../../../services/double-click.service';
import {FieldMetadataGrid} from '../../../../../services/module/module-element-field-metadata-grid';
import {Observable} from 'rxjs';

@Component({
  selector: 'app-field-action-set-extra-param-from-one-multiselect-to-another',
  template: ''
})
export class FieldActionSetExtraParamFromOneMultiSelectFilterToAnotherComponent extends FieldActionAbstractComponent {

  public constructor(
    protected genericCrudService: GenericCrudService,
    protected doubleClickService: DoubleClickService
  ) {
    super();
  }

  public fire() {
    const fromField = this.getField(),
      toFieldName = this.getActionParamValue('toFieldName'),
      fromFieldParamKey = this.getActionParamValue('fromFieldParamKey'),
      toField = this.grid.findField(toFieldName);

    if (fromField && toField && fromFieldParamKey) {
      this.loadOptions(fromField, toField, fromFieldParamKey).subscribe((options: any[] = []) => {
        this.grid.associatedFilterOptions[toField.entityName] = options;
      });
    }
  }

  private loadOptions(fromField: FieldMetadataGrid, toField: FieldMetadataGrid, fromFieldParamKey: string): Observable<any[]> {
    const params = this.grid.getColumnBuilder().parseParams(toField);
    const apiRoute = this.grid.getColumnBuilder().createApiRoute(toField);

    const fromFieldFilterValues = this.grid.gridFilters[fromField.entityName].value || [];

    if (fromFieldFilterValues.length > 0) {
      params[fromFieldParamKey] = `in:${fromFieldFilterValues.join(',')}`;
    }

    return this.genericCrudService.getPaginated(apiRoute, params).pipe(
      map((combinedResponse) => {
        const options = [];

        const entityData = (combinedResponse instanceof Array) ? combinedResponse : combinedResponse.data;

        if (typeof entityData !== 'undefined') {
          for (const entity of entityData) {
            options.push({
              label: FieldMetadataGrid.getOptionLabel(entity, toField),
              value: entity.id,
              entity: entity
            });
          }
        }

        return options;
      }));
  }
}
