
    <div class="container" [formGroup]="formGroup">
      <input type="hidden" [formControlName]="formControlName" />

      <app-generic-calendar
        appSeleniumDirective
        [element]="element"
        [value]="entityValue"
        (onInputChangeValid)="onInputChange($event)"
        (onSelect)="onSelect($event)"
        [mask]="this.element.inputFormat.mask"
        [dateFormat]="element.inputFormat.date.format"
        [showTime]="showTime()"
        [timeOnly]="timeOnly()"
        [placeholder]="element.placeholder"
        [showIcon]="element.showIcon"
        [disabled]="isReadOnlyOrDisabled() || !entity"
        [yearRange]="element.yearNavigator ? '1950:2050' : ''"
        [monthNavigator]="element.monthNavigator"
        [yearNavigator]="element.yearNavigator"
        [tabIndex]="element.tabIndex"
        [hourFormat]="element.inputFormat['date']['hourFormatPmAm'] ? 12 : 24"
        #inputElement
      ></app-generic-calendar>
    </div>
  