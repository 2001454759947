<p-treeTable
  [value]="nodes"
  [loading]="isLoading"
  scrollHeight="400px"
  [paginator]="true"
  [rows]="20"
  [lazy]="true"
  (onLazyLoad)="onLazyLoad($event)"
  [totalRecords]="totalCount"
>
  <ng-template pTemplate="header">
    <tr>
      <th
        [style.width.px]="100"
      >
        {{ 'AUDIT.TYPE' | translate }}
      </th>
      <th
        [style.width.px]="130"
      >
        {{ 'AUDIT.OBJECT_ID' | translate }}
      </th>
      <th
        [style.width.px]="180"
      >
        {{ 'AUDIT.BLAME_USER' | translate }}
      </th>
      <th
        [style.width.px]="140"
      >
        {{ 'AUDIT.CREATED_AT' | translate }}
      </th>
      <th>{{ 'AUDIT.PROPERTY_NAME' | translate }}</th>
      <th>{{ 'AUDIT.OLD_VALUE' | translate }}</th>
      <th>{{ 'AUDIT.NEW_VALUE' | translate }}</th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-rowNode let-rowData="rowData">
    <tr
      [ttRow]="rowNode"
    >
      <td>
        <p-treeTableToggler [rowNode]="rowNode"></p-treeTableToggler>
        {{ rowData.type }}
      </td>
      <td>
        {{rowData.object_id}}
      </td>
      <td
        [style.textOverflow]="'ellipsis'"
        [style.whiteSpace]="'nowrap'"
        [style.overflow]="'hidden'"
      >
        {{rowData.blame_user}}
      </td>
      <td>
        {{rowData.created_at | date:'dd.MM.yyyy' }}
      </td>
      <td
        [style.textOverflow]="'ellipsis'"
        [style.whiteSpace]="'nowrap'"
        [style.overflow]="'hidden'"
      >
        {{rowData.propertyName }}
      </td>
      <td
        [style.textOverflow]="'ellipsis'"
        [style.whiteSpace]="'nowrap'"
        [style.overflow]="'hidden'"
      >
        {{rowData.oldValue }}
      </td>
      <td
        [style.textOverflow]="'ellipsis'"
        [style.whiteSpace]="'nowrap'"
        [style.overflow]="'hidden'"
      >
        {{rowData.newValue }}
      </td>
    </tr>
  </ng-template>
</p-treeTable>
