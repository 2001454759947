<p-dialog
        header="Unterüberlassung kalkulieren"
        [(visible)]="isDialogVisible"
        *ngIf="isDialogVisible"
        [width]="350"
        [maximizable]="true"
        [baseZIndex]="10000"
>
    <div class="ui-g">
        <div class="ui-grid-col-10">
            <p-dropdown
                    [style]="{'width': '200px'}"
                    [filter]="true"
                    [(ngModel)]="selectedSubAssignment"
                    [options]="subassignments"
            ></p-dropdown>
        </div>
    </div>

    <p-footer>
        <button type="button" pButton (click)="onCalculate()" label="Kalkulieren"></button>
        <button type="button" pButton (click)="onCancel()" label="{{ 'COMMON.CANCEL' | translate }}"
                class="ui-button-secondary"></button>
    </p-footer>
</p-dialog>
