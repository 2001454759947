import { Component } from '@angular/core';
import { FieldActionAbstractComponent } from '../field-action-abstract.component';
import { GenericCrudService } from '../../../../../services/generic-crud.service';
import { DoubleClickService } from '../../../../services/double-click.service';
import {FieldMetadataGrid} from '../../../../../services/module/module-element-field-metadata-grid';

@Component({
  selector: 'field-action-disable-another-field',
  template: ''
})
export class FieldActionDisableAnotherFieldComponent extends FieldActionAbstractComponent {

  public constructor(
    protected genericCrudService: GenericCrudService,
    protected doubleClickService: DoubleClickService,
  ) {
    super();
  }

  public fire() {
    const gridComponent = this.getGrid(),
      entity = gridComponent.getSelectedEntity();

    let columnToDisable = this.getActionParamValue('column'),
      fieldToDisable: FieldMetadataGrid = this.findField(columnToDisable),
      field = this.getField();

    if(entity[field.name]){
      if(typeof fieldToDisable.isReadOnlyByEntity === 'undefined'){
        fieldToDisable.isReadOnlyByEntity = {};
      }
      fieldToDisable.isReadOnlyByEntity[entity.id] = true;

    }else{
      if(typeof fieldToDisable.isReadOnlyByEntity === 'undefined'){
        fieldToDisable.isReadOnlyByEntity = {};
      }
      fieldToDisable.isReadOnlyByEntity[entity.id] = false;
    }
  }


  public findField(fieldId: string): any|null {
    for (const field of this.getGrid().fields) {
      if (field.id == fieldId) {
        return field;
      }
    }

    return null;
  }
}
