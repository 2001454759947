
import {of as observableOf, Observable} from 'rxjs';
import {AbstractExecutionStep} from '../../../../core/executor/abstract-execution-step';
import {ExecutionStepStatus} from '../../../../core/executor/execution-step-status';
import {AbstractGenericGridComponent} from '../../../content-renderer/elements/abstract-generic-grid.component';
import {EntityManagerService} from '../../../../core/service/entity-manager/entity-manager.service';
import {MessageGrowlService} from '../../../../core/message/message-growl.service';
import {DatePipe} from '../../../pipes/date.pipe';
import {map} from 'rxjs/operators';

export class LeasedEmployeeAbsenceCalculateTimesStep extends AbstractExecutionStep {

  protected calculationRelevantFields = [
    'validFrom',
    'validTo',
    'isTimeEnabledInFrom',
    'isTimeEnabledInTo',
    'calculatedDays',
    'calculatedHours'
  ];

  public doExecute(): Observable<ExecutionStepStatus> {
    const payload = this.getPayload(),
      component = payload.getValue().component;

    if (component instanceof AbstractGenericGridComponent
      && payload.getValue().entityDataChangeMeta
      && payload.getValue().entityDataChangeMeta.entity
      && payload.getValue().entityDataChangeMeta.gridField
      && this.calculationRelevantFields.indexOf(payload.getValue().entityDataChangeMeta.gridField.id) !== -1) {

      return this.doCalculate(component, payload.getValue().entityDataChangeMeta.entity);
    }

    return this.getFailObservable('You need to pass AbstractGenericGridComponent or FormViewerComponent as Payload value!');
  }

  protected doCalculate(component: AbstractGenericGridComponent, entity) {
    // If to and from have time, but no value set - just ignore:
    if ((entity.isTimeEnabledInFrom && !entity.validFrom) || (entity.isTimeEnabledInTo && !entity.validTo) || (!entity.validFrom || !entity.validTo)) {
      return observableOf({status: true, content: []});
    }

    if (!entity.subAssignment) {
      this.getMessageGrowlService().error('Unterüberlassung muss ausgewählt werden!');
    }

    this.getCalculated(component, entity).subscribe(() => {
      component.getInlineEditService().markForCheck(entity);
    });
    return observableOf({status: true, content: []});
  }

  private getCalculated(component: AbstractGenericGridComponent, entity): Observable<any> {
    component.apiRoutAdditionalParameters['embedded'] = 'none';
    return this.genericCrudService.editEntity(
      `phoenix/leasedemployeeabsences/${entity.id ? entity.id : 0}/calculatetime`,
      entity,
      component.apiRoutAdditionalParameters
    ).pipe(
      map((calculatedEntity => {
        const editingEntityIndex = component.entities.findIndex((aEntity) => {
          return aEntity.uniqueId === entity.uniqueId;
        });

        const editingEntity = component.entities[editingEntityIndex];

        if (calculatedEntity.validFrom && calculatedEntity.isValidFromChangedByCalculation && editingEntity.isTimeEnabledInFrom) {
          const datePipe = new DatePipe();

          this.getMessageGrowlService().error(`Valid From cannot set before ${datePipe.transform(calculatedEntity.validFrom, 'H:i', true, true)}`);
        }

        if (calculatedEntity.validTo && calculatedEntity.isValidToChangedByCalculation && editingEntity.isTimeEnabledInTo) {
          const datePipe = new DatePipe();

          this.getMessageGrowlService().error(`Valid To cannot be set after ${datePipe.transform(calculatedEntity.validTo, 'H:i', true, true)}`);
        }

        for (const field of this.calculationRelevantFields) {
          editingEntity[field] = calculatedEntity[field];
        }

        for (const aField of this.calculationRelevantFields) {
          this.getEntityManagerService().persist(editingEntity, {property: aField, oldValue: null, newValue: calculatedEntity[aField], force: true});
        }
      }))
    );
  }

  private getMessageGrowlService(): MessageGrowlService {
    return this.injector.get(MessageGrowlService, null);
  }

  private getEntityManagerService(): EntityManagerService|null {
    return this.injector.get(EntityManagerService, null);
  }

}
