
import {of as observableOf,  Observable } from 'rxjs';
import { AbstractExecutionStep } from '../../../../core/executor/abstract-execution-step';
import { ExecutionStepStatus } from '../../../../core/executor/execution-step-status';
import { GenericElementAbstract } from '../../../content-renderer/elements/generic-element-abstract.component';
import { ValidationStatus } from '../../../validators/services/validation';
import { cloneDeep } from 'lodash';
import { EntityHydrator } from '../../entity-hydrator.service';
import { EntityValidator } from '../../../validators/field-validators/entity-validator';
import { ExecutionStatusError, ExecutionStatusErrorAware } from '../../../../core/executor/execution-status-error';
import {EntityStatus} from '../../entity/entity-status';

export class EntityValidationExecutionStep extends AbstractExecutionStep implements ExecutionStatusErrorAware {

    public doExecute(): Observable<ExecutionStepStatus> {
        const payload = this.getPayload();

        if (!(payload.getValue() instanceof Object)) {
            return this.getFailObservable("You need to pass entity as Payload value!");
        }

        return this.doValidation(payload.getValue());
    }

    public onError(executionStatusError: ExecutionStatusError) {
        // console.log(executionStatusError);
    }

    protected doValidation(entity: any): Observable<ExecutionStepStatus> {

        if (entity[EntityStatus.ENTITY_DRAFT_DELETED_FLAG]) {
            return observableOf({status: true, content: null});
        }

        return Observable.create(observer => {
            const validator = new EntityValidator([]);

            validator.setGenericCrudService(this.genericCrudService)
                .setEntityHydrator(this.entityHydrator)
                .setEntity(entity);

            validator.validate()
                .subscribe((validationStatus: ValidationStatus) => {
                    let status = {
                        status: true,
                        content: null
                    };

                    delete entity[EntityStatus.ENTITY_INVALID_FLAG];

                    if (validationStatus) {
                        entity[EntityStatus.ENTITY_INVALID_FLAG] = !validationStatus.isValid;

                        status = {
                            status: validationStatus.isValid,
                            content: validationStatus.errorTranslated
                        };
                    }

                    observer.next(status);
                    observer.complete();
                });
        });
    }
}
