/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../services/toolbar-item-form-entity.service";
import * as i2 from "../../../../../../services/api.builder.service";
import * as i3 from "./toolbar-item-form-first.component";
import * as i4 from "../../../../../../services/location.service";
import * as i5 from "../../../../../services/elements-stack.service";
import * as i6 from "../../../../../services/entity-draft-store.service";
import * as i7 from "../../../../../services/modules-state.service";
import * as i8 from "../../../../../services/navigation/module-navigation.service";
import * as i9 from "../../../../../services/navigation/module-close.service";
var styles_ToolbarItemFormFirst = [];
var RenderType_ToolbarItemFormFirst = i0.ɵcrt({ encapsulation: 2, styles: styles_ToolbarItemFormFirst, data: {} });
export { RenderType_ToolbarItemFormFirst as RenderType_ToolbarItemFormFirst };
export function View_ToolbarItemFormFirst_0(_l) { return i0.ɵvid(2, [], null, null); }
export function View_ToolbarItemFormFirst_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "app-toolbar-item-form-first", [], null, null, null, View_ToolbarItemFormFirst_0, RenderType_ToolbarItemFormFirst)), i0.ɵprd(512, null, i1.ToolbarItemFormEntityService, i1.ToolbarItemFormEntityService, [i2.ApiBuilderService]), i0.ɵdid(2, 245760, null, 0, i3.ToolbarItemFormFirst, [i1.ToolbarItemFormEntityService, i4.LocationService, i5.ElementsStackService, i6.EntityDraftStoreService, i7.ModulesStateService, i8.ModuleNavigationService, i9.ModuleCloseService], null, null)], function (_ck, _v) { _ck(_v, 2, 0); }, null); }
var ToolbarItemFormFirstNgFactory = i0.ɵccf("app-toolbar-item-form-first", i3.ToolbarItemFormFirst, View_ToolbarItemFormFirst_Host_0, { params: "params" }, {}, []);
export { ToolbarItemFormFirstNgFactory as ToolbarItemFormFirstNgFactory };
