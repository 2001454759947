import {of as observableOf, Observable} from 'rxjs';
import {ChangeDetectionStrategy, Component} from '@angular/core';
import {AbstractGenericGridComponent} from '../../../../../abstract-generic-grid.component';
import {ToolbarItemAbstract} from '../../toolbar-item-abstract.component';
import {MessageGrowlService} from '../../../../../../../../core/message/message-growl.service';
import {GenericCrudService} from '../../../../../../../services/generic-crud.service';
import {ExecutionStatusSuccess} from '../../../../../../../../core/executor/execution-status-success';
import {GenericElementAbstract} from '../../../../../generic-element-abstract.component';
import {ExecutionStatusError} from '../../../../../../../../core/executor/execution-status-error';
import {ComponentValidationExecutionStep} from '../../../../../../../services/execution-step/validation/component-validation-execution-step';
import {EntityValidationExecutionStep} from '../../../../../../../services/execution-step/validation/entity-validation-execution-step';
import {ExecutorService} from '../../../../../../../../core/executor/executor.service';
import {MessageService} from '../../../../../../../../core/message/message.service';
import {TranslateService} from '@ngx-translate/core';
import {ExecutionStatus} from '../../../../../../../../core/executor/execution-status';
import {ExecutionStepFactoryService} from '../../../../../../../../core/executor/factory/execution-step-factory.service';
import {ExecutionStepPayload} from '../../../../../../../../core/executor/execution-step-payload';
import {ExecutionStep} from '../../../../../../../../core/executor/execution-step';
import {EntitySaveExecutionStep} from '../../../../../../../services/execution-step/entity-save-execution-step';
import {ComponentRefreshEntitiesExecutionStep} from '../../../../../../../services/execution-step/component-refresh-entities-execution-step';
import {EntityStatus} from '../../../../../../../services/entity/entity-status';


@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'toolbar-item-ca-wage-calculation',
  templateUrl: './toolbar-item-ca-wage-calculation.component.html'
})
export class ToolbarItemCaWageCalculationComponent extends ToolbarItemAbstract {

  protected entity: any;

  constructor(
    protected messageGrowlService: MessageGrowlService,
    protected genericCrudService: GenericCrudService,
    protected executionStepFactoryService: ExecutionStepFactoryService,
    protected messageService: MessageService,
    protected translateService: TranslateService,
    protected executorService: ExecutorService
  ) {
    super();
  }

  public click() {
    const component = this.getComponent();

    if (component instanceof AbstractGenericGridComponent) {
      this.saveEntities(component).subscribe((status: ExecutionStatus) => {
        if (status.isSuccess()) {
          this.handleCalculate(component);
        } else {
          this.onExecutorFailure(status);
        }
      });
    }
  }

  protected handleCalculate(grid: AbstractGenericGridComponent) {
    if (grid.selectedEntities) {
      const data = {
        workhourEntryIds: []
      };
      for (const selectedEntity of grid.selectedEntities){
        data.workhourEntryIds.push(selectedEntity.id);
      }
      this.genericCrudService.customPut(`phoenix/calculateworkhours`, data).subscribe(
        (response) => {
          const calculatedValues = response['calculated'] || [],
            errors: {message: string, timeEntry: {id: number}}[] = response['errors'] || [];

          this.handleCalculated(calculatedValues, grid);
          this.handleErrors(errors, grid);
        },
        errorResponse => {
          console.log(errorResponse);
          this.messageGrowlService.error(errorResponse.error.error);
        }
      );
    }
  }

  private handleCalculated(calculatedValues, grid): void {
    for (const calculatedValue of calculatedValues){

      const entity = grid.entities.find((gridEntity) => {
        return gridEntity.id === calculatedValue['timeEntryId'];
      });
      if (entity) {
        if (!entity._embedded) {
          entity['_embedded'] = {};
        }
        entity.calculatedWage = calculatedValue['data']['wage'];
      }
      if (grid.selectedEntity.id === entity.id) {
        grid.reselectEntity();
      }
    }

    this.messageGrowlService.success('Erfolgreich berechnet. Success.');
  }

  private handleErrors(errors, grid): void {
    const errorMessages = [];
    for (const error of errors) {
      const message = error.message,
        entryId = error.timeEntry.id;

      const entity = grid.entities.find((aEntity) => {
        return aEntity.id === entryId;
      });

      errorMessages.push(message);

      if (entity) {
        entity[EntityStatus.ENTITY_INVALID_FLAG] = true;
      }
    }

    if (errorMessages.length > 0) {
      this.messageGrowlService.error(errorMessages.join(','));
    }
  }

  private saveEntities(grid: AbstractGenericGridComponent): Observable<ExecutionStatusSuccess> {
    if (this.componentEntityHasBeenChanged(grid)) {
      const executionSteps: ExecutionStep[] = [];

      executionSteps.push(
        this.executionStepFactoryService.create(ComponentValidationExecutionStep, new ExecutionStepPayload(grid))
      );

      const changedEntities = [];
      for (const entity of grid.selectedEntities) {
        if (entity.isDirty || entity.isChanged) {
          changedEntities.push(entity);
          executionSteps.push(
            this.executionStepFactoryService.create(EntitySaveExecutionStep, new ExecutionStepPayload({
              entity: entity
            }))
          );
        }
      }
      executionSteps.push(
        this.executionStepFactoryService.create(ComponentRefreshEntitiesExecutionStep, new ExecutionStepPayload({'grid':grid, 'entities': changedEntities}))
      );

      for (const step of executionSteps) {
        this.executorService.addStep(step);
      }

      return this.executorService.execute();
    } else {
      return observableOf(new ExecutionStatusSuccess({status: true, content: null}, null));
    }
  }


  private componentEntityHasBeenChanged(component: GenericElementAbstract): boolean {
    let entityHasBeenChanged = false;

    // Now a little twist - in case there are really no changes, check if we are in a grid AND there are changes to be saved:
    if (component && component instanceof AbstractGenericGridComponent) {
      entityHasBeenChanged = component.getUpdatedEntities().length > 0 || component.getCreatedEntities().length > 0 || component.getDraftDeletedEntities().length > 0;
    }

    return entityHasBeenChanged;
  }

  public onExecutorFailure(status: ExecutionStatusError): void {
    if (status.getStep() instanceof ComponentValidationExecutionStep ||
      status.getStep() instanceof EntityValidationExecutionStep
    ) {
      this.onComponentValidationFailure(status);
    }
  }

  public onComponentValidationFailure(status: ExecutionStatusError): void {
    this.messageService.confirm({
      acceptVisible: true,
      rejectVisible: false,
      header: this.translateService.instant('COMMON.ERROR'),
      message: status.getStepContent(),
      accept: () => { }
    });
  }

}
