
    <p-dropdown
      *ngIf="isEditMode()"
      [(ngModel)]="entity.parameter"
      [options]="parametersOptions"
      placeholder="---"
      (onFocus)="onDropDownFocus()"
      (onChange)="onParameterChange($event)"
      [style]="{'width': '100%'}"
      appendTo="body"
    ></p-dropdown>
    
    <div *ngIf="!isEditMode()">
      {{ entity.parameter }}
    </div>
  