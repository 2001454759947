/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./toolbar-item-bulk-add.component";
import * as i2 from "../../../../../services/component-highlight-stack.service";
import * as i3 from "../../../../../../../core/message/message-growl.service";
import * as i4 from "../../../../../../services/generic-crud.service";
import * as i5 from "../../../services/check/toolbar-item-check.service";
var styles_ToolbarItemBulkAddComponent = [];
var RenderType_ToolbarItemBulkAddComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_ToolbarItemBulkAddComponent, data: {} });
export { RenderType_ToolbarItemBulkAddComponent as RenderType_ToolbarItemBulkAddComponent };
export function View_ToolbarItemBulkAddComponent_0(_l) { return i0.ɵvid(2, [], null, null); }
export function View_ToolbarItemBulkAddComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "toolbar-item-bulk-add", [], null, null, null, View_ToolbarItemBulkAddComponent_0, RenderType_ToolbarItemBulkAddComponent)), i0.ɵdid(1, 245760, null, 0, i1.ToolbarItemBulkAddComponent, [i2.ComponentService, i3.MessageGrowlService, i4.GenericCrudService, i5.ToolbarItemCheckService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ToolbarItemBulkAddComponentNgFactory = i0.ɵccf("toolbar-item-bulk-add", i1.ToolbarItemBulkAddComponent, View_ToolbarItemBulkAddComponent_Host_0, { params: "params" }, {}, []);
export { ToolbarItemBulkAddComponentNgFactory as ToolbarItemBulkAddComponentNgFactory };
