import { ToolbarItemAbstract } from '../toolbar-item-abstract.component';
import { AbstractGenericGridComponent } from '../../../../abstract-generic-grid.component';
export class ToolbarItemExpertSearchComponent extends ToolbarItemAbstract {
    constructor() {
        super(...arguments);
        this.isDialogVisible = true;
        this.component = null;
    }
    click() {
        const component = this.getComponent();
        if (component instanceof AbstractGenericGridComponent) {
            this.component = component;
            this.isDialogVisible = true;
        }
    }
}
