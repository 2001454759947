/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../generic-grid/services/generic-grid-global-filter.service";
import * as i2 from "../../../../../../core/service/user-session.service";
import * as i3 from "../../../../services/modules-state.service";
import * as i4 from "../../../../../../core/authentication/authentication.service";
import * as i5 from "../../../../../services/local-storage-data.service";
import * as i6 from "./toolbar-item-filter.component";
import * as i7 from "../../../../services/component-highlight-stack.service";
var styles_ToolbarItemFilter = [];
var RenderType_ToolbarItemFilter = i0.ɵcrt({ encapsulation: 2, styles: styles_ToolbarItemFilter, data: {} });
export { RenderType_ToolbarItemFilter as RenderType_ToolbarItemFilter };
export function View_ToolbarItemFilter_0(_l) { return i0.ɵvid(2, [], null, null); }
export function View_ToolbarItemFilter_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "ng-component", [], null, null, null, View_ToolbarItemFilter_0, RenderType_ToolbarItemFilter)), i0.ɵprd(512, null, i1.GenericGridGlobalFilterService, i1.GenericGridGlobalFilterService, [i2.UserSessionService, i3.ModulesStateService, i4.AuthenticationService, i5.LocalStorageDataService]), i0.ɵdid(2, 245760, null, 0, i6.ToolbarItemFilter, [i2.UserSessionService, i1.GenericGridGlobalFilterService, i7.ComponentService], null, null)], function (_ck, _v) { _ck(_v, 2, 0); }, null); }
var ToolbarItemFilterNgFactory = i0.ɵccf("ng-component", i6.ToolbarItemFilter, View_ToolbarItemFilter_Host_0, { params: "params" }, {}, []);
export { ToolbarItemFilterNgFactory as ToolbarItemFilterNgFactory };
