import {RunnableEventRegistry} from '../type/runnable-event.registry';

export class JobContext {
  protected _identifier: string;

  protected _component: any;

  private _event: RunnableEventRegistry;

  /**
   * @param {string} identifier
   */
  set identifier(identifier: string) {
    this._identifier = identifier;
  }

  /**
   * @returns {string}
   */
  get identifier(): string {
    return this._identifier;
  }

  /**
   * @param component
   */
  set component(component: any) {
    this._component = component;
  }

  /**
   * @returns {any}
   */
  get component(): any {
    return this._component;
  }

  /**
   * @returns {RunnableEventRegistry}
   */
  get event(): RunnableEventRegistry {
    return this._event;
  }

  /**
   * @param {RunnableEventRegistry} value
   */
  set event(value: RunnableEventRegistry) {
    this._event = value;
  }
}
