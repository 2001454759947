import {EventEmitter, Input, Output} from '@angular/core';

import {
  ExpertSearch,
  ExpertSearchField,
  ExpertSearchFieldsContainer,
  ExpertSearchFieldParameter
} from '../../../../../../../../services/expert-search/expert-search';
import {QuestionParameter} from '../../../../../../../../services/element/questionnaire';

export abstract class AbstractEditExpertSearchFieldValue {
  @Input() public expertSearch: ExpertSearch = null;
  @Input() public container: ExpertSearchFieldsContainer = null;
  @Input() public field: ExpertSearchField = null;

  @Output() onChange: EventEmitter<{value: any, labelValue: string}> = new EventEmitter();

  public setParameters(parameters: ExpertSearchFieldParameter[]): this {
    this.field.parameters = parameters;
    return this;
  }

  public getParameters(parameters: ExpertSearchFieldParameter[]) {
    return this.field.parameters;
  }

  public getParameter(key: string): ExpertSearchFieldParameter|null {
    for (const parameter of this.field.parameters) {
      if (key === parameter.name) {
        return parameter;
      }
    }

    return null;
  }

  protected setParameter(key: string, value: any): QuestionParameter {
    let param = this.getParameter(key);

    if (!param) {
      param = {
        name: key,
        value: value
      };

      this.field.parameters.push(param);
    } else {
      param.value = value;
    }

    return param;
  }

  public getParameterValue(key: string): any|null {
    const parameter = this.getParameter(key);

    return parameter ? parameter.value : null;
  }

  public removeParameter(parameter: ExpertSearchFieldParameter): this {
    if (parameter && this.field.parameters.length > 0) {
      const parameterIndex = this.field.parameters.findIndex((aParameter) => {
        return parameter.name === aParameter.name;
      });

      if (parameterIndex !== -1) {
        this.field.parameters.splice(parameterIndex, 1);
      }
    }

    return this;
  }
}
