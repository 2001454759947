import {Directive, Self, AfterViewInit, Input} from '@angular/core';
import {InputText} from 'primeng/primeng';

@Directive({
  selector: '[appColorPickerInput]'
})
export class ColorPickerInputDirective implements AfterViewInit {

  private readonly input: InputText = null;
  @Input() public focusOnInit = false;

  public constructor(@Self() input: InputText) {
    this.input = input;

    if (!(this.input instanceof InputText)) {
      console.error('Table works only with pInputText component!');
    }
  }

  public ngAfterViewInit(): void {
    if (this.focusOnInit && this.input && this.input.el && this.input.el.nativeElement) {
      setTimeout(() => {
        this.input.el.nativeElement.focus();
      }, 100);
    }
  }
}
