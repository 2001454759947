
import {map} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {WizardComponent} from '../wizard.component';
import {WizardElement} from '../../../../../services/element/wizard';
import {ExecutionStatus} from '../../../../../../core/executor/execution-status';
import {ExecutionStepPayload} from '../../../../../../core/executor/execution-step-payload';
import {ExecutorService} from '../../../../../../core/executor/executor.service';
import {ExecutionStepFactoryService} from '../../../../../../core/executor/factory/execution-step-factory.service';
import {Observable} from 'rxjs';
import {ModuleState, ModuleStateContext} from '../../../../services/module-state';
import {ModulesStateService} from '../../../../services/modules-state.service';

export interface WizardElementInputDetails {
  entity?: any;
  masterEntity?: any;
  masterFilterField?: string;
  masterFilterValue?: any;
  masterEntityEditingField?: any;
  masterEntityField?: any;
}

export interface WizardElementDetails {
  wizardElement: WizardElement;
  inputDetails: WizardElementInputDetails;
  moduleState: ModuleState;
}

@Injectable()
export class WizardService {

  public wizardComponent: WizardComponent;
  public executorService: ExecutorService;

  public wizardElementDetails: WizardElementDetails[] = [];
  public currentWizardElementDetails: WizardElementDetails | undefined = undefined;

  public constructor(
    private stepFactory: ExecutionStepFactoryService,
    private modulesStateService: ModulesStateService
  ) {

  }

  public openNext(): Observable<WizardElementDetails> {
    const wizard = this.wizardComponent.wizard,
      currentWizardElementIndex = wizard.wizardElements.indexOf(this.wizardComponent.wizardElement);

    const wizardElement = wizard.wizardElements[currentWizardElementIndex + 1];

    return this.open(wizardElement);
  }

  public openPrevious(): Observable<WizardElementDetails> {
    const wizard = this.wizardComponent.wizard,
      currentWizardElementIndex = wizard.wizardElements.indexOf(this.wizardComponent.wizardElement);

    const wizardElement = wizard.wizardElements[currentWizardElementIndex - 1];

    return this.open(wizardElement);
  }

  public openFirst(): Observable<WizardElementDetails> {
    const wizard = this.wizardComponent.wizard;

    const wizardElement = wizard.wizardElements[0];

    return this.open(wizardElement);
  }

  public openLast(): Observable<WizardElementDetails> {
    const wizard = this.wizardComponent.wizard;

    const wizardElement = wizard.wizardElements[wizard.wizardElements.length - 1];

    return this.open(wizardElement);
  }

  public open(wizardElement: WizardElement): Observable<WizardElementDetails> {
    return this.runBeforeChangeAction(wizardElement, this.wizardComponent.wizardElement).pipe(
      map(() => {
        return this.afterChange(wizardElement);
      }));
  }

  public runBeforeChangeAction(wizardElement: WizardElement, previousWizardElement: WizardElement = null): Observable<ExecutionStatus> {
    this.executorService
      .resetSteps();

    const index = this.getElementIndex(wizardElement);
    if (!this.wizardElementDetails[index]) {
      this.wizardElementDetails[index] = {
        wizardElement: wizardElement,
        inputDetails: {},
        moduleState: null
      };
    }

    if (wizardElement.beforeChangeAction) {
      const step = this.stepFactory.createFromString(wizardElement.beforeChangeAction, new ExecutionStepPayload({
        wizardComponent: this.wizardComponent,
        previousWizardElement: previousWizardElement,
        wizardElement: wizardElement
      }));

      this.executorService.addStep(step);
    }

    return this.executorService.execute();
  }

  public afterChange(wizardElement: WizardElement): WizardElementDetails {
    const index = this.getElementIndex(wizardElement),
      moduleState = new ModuleState(
        wizardElement.module.id,
        wizardElement.module,
        '',
        {},
        false
      ).addContext(ModuleStateContext.WizardElement);

    this.wizardElementDetails[index].moduleState = moduleState;
    this.currentWizardElementDetails = this.wizardElementDetails[index];

    return this.wizardElementDetails[index];
  }

  public getElementIndex(wizardElement: WizardElement): number {
    return this.wizardComponent.wizard.wizardElements.indexOf(wizardElement);
  }

  public getElementDetails(wizardElement: WizardElement): WizardElementDetails {
    const index = this.getElementIndex(wizardElement);

    return this.wizardElementDetails[index];
  }

  public getCurrentWizardElementDetailsComponents() {
    if (this.currentWizardElementDetails && this.currentWizardElementDetails.moduleState) {
      return this.currentWizardElementDetails.moduleState.getAllComponents();
    }

    return [];
  }
}
