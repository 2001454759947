
import {of as observableOf,  Observable } from 'rxjs';
import { EntityValidationExecutionStep } from '../validation/entity-validation-execution-step';
import { ExecutionStepStatus } from '../../../../core/executor/execution-step-status';
import { ModuleState } from '../../../content-renderer/services/module-state';
import { ExecutionStatusErrorAware, ExecutionStatusError } from '../../../../core/executor/execution-status-error';
import { MessageServiceAware } from 'app/core/executor/type/message-service-aware';
import { MessageService } from '../../../../core/message/message.service';
import { EntityStatus } from '../../entity/entity-status';

export class DetailsViewMasterEntityValidationExecutionStep extends EntityValidationExecutionStep implements ExecutionStatusErrorAware, MessageServiceAware {

    public messageService: MessageService;

    public onError(executionStatusError: ExecutionStatusError) {
        
    }

    public doExecute(): Observable<ExecutionStepStatus> {
        const payload = this.getPayload();

        const moduleState = payload.getValue();

        if (!(moduleState instanceof ModuleState)) {
            return observableOf({status: false, content: 'Payload should be modulestate, not something else.'});
        }

        if (!moduleState.entity) {
            return observableOf({status: false, content: 'Module state entity is not set.'});
        }

        if (moduleState.entity[EntityStatus.ENTITY_VALIDATED_FLAG] && !moduleState.entity[EntityStatus.ENTITY_INVALID_FLAG]) {
          return observableOf({status: true, content: null});
        }

        return this.doValidation(moduleState.entity);
    }
}
