
    <div fxLayout="row" (click)="onClickElement($event)">
      <p-tabView>
        <p-tabPanel
          appSeleniumDirective
          [element]="element"
          *ngFor="let elementChild of element.children; let i = index;" [selected]="i === 0" header="{{elementChild.label}}">
          <app-form-element-layout-tabs-item
            [(selectedElement)]="selectedElement"
            [(showDialogAddAddress)]="showDialogAddAddress"
            [(actionParams)]="actionParams"
            [form]="form"
            [formGroup]="formGroup"
            [element]="elementChild"
            [moduleElement]="moduleElement"
            [entity]="entity"
            [editMode]="editMode"
          ></app-form-element-layout-tabs-item>
          <div class="clearfix"></div>
        </p-tabPanel>
      </p-tabView>
    </div>
  