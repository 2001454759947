import {ChangeDetectionStrategy, Component, ViewChild} from '@angular/core';
import {AbstractGenericGridComponent} from '../../../../../abstract-generic-grid.component';
import {ToolbarItemAbstract} from '../../toolbar-item-abstract.component';
import {MessageGrowlService} from '../../../../../../../../core/message/message-growl.service';
import {ComponentService} from '../../../../../../services/component-highlight-stack.service';
import {GenericCrudService} from '../../../../../../../services/generic-crud.service';
import {EntityHydrator} from '../../../../../../../services/entity-hydrator.service';
import {LayoutService} from '../../../../../../../services/layout-service';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'toolbar-item-calculation-result',
  templateUrl: './toolbar-item-calculation-result.component.html'
})
export class ToolbarItemCalculationResultComponent extends ToolbarItemAbstract {

  @ViewChild('dialog', {static: false}) dialog;

  public calculationResults: any;
  public displayCalculationResults = false;

  constructor(
    protected componentService: ComponentService,
    protected messageGrowlService: MessageGrowlService,
    protected genericCrudService: GenericCrudService,
    protected entityHydrator: EntityHydrator,
    protected layoutService: LayoutService
  ) {
    super();
  }

  public click() {
    const component = this.getComponent();

    if (component instanceof AbstractGenericGridComponent) {
      this.showCalculationResult(component);
    }
  }

  protected showCalculationResult(grid: AbstractGenericGridComponent) {
    if(grid.selectedEntity){
      this.calculationResults = this.entityHydrator.getEntityPropertyValue(grid.selectedEntity,'calculationResults', false,true);
      if(this.calculationResults) {
        this.displayCalculationResults = true;
      }
    }
  }

  public onHideCalculation(event) {
    this.displayCalculationResults = false;
  }

  public onDialogMaximize(event) {
    this.dialog.toggleMaximize(event);
    window.dispatchEvent(new Event('resize'));
  }

  public isEmptyObject(obj) {
    return (Object.keys(obj).length === 0);
  }
}
