
import { Injectable } from '@angular/core';
import { AbstractFormActionHandler } from '../abstract-form-action-handler';
import { FormService } from '../../../form.service';
import { HandleCountryPhoneActionHandler } from './handle-country-phone-action-handler';

@Injectable()
export class HandleCountryFaxActionHandler extends HandleCountryPhoneActionHandler {

    protected getPhoneCountryCodeDatamodelField(): string {
        return 'faxCountryCode';
    }

    protected getPhoneAreaCodeDatamodelField(): string {
        return 'faxAreaCode';
    }

    protected getPhoneNumberDatamodelField(): string {
        return 'faxNumber';
    }
}
