import {AbstractFormActionHandler} from '../abstract-form-action-handler';
import {ContractOfEmploymentExitInformationService} from '../../../../content-renderer/elements/generic-toolbar/items/components/leased-employee/service/contract-of-employment-exit-information.service';
import {FormActionsEvents} from '../../../models/form';
import {
  ContractOfEmploymentExitAgreementDateService
} from '../../../../content-renderer/elements/generic-toolbar/items/components/leased-employee/service/contract-of-employment-exit-agreement-date.service';
import {FormViewerComponent} from '../../../form-viewer.component';
import {ContractOfEmploymentContactInformationService} from '../../../../content-renderer/elements/generic-toolbar/items/components/leased-employee/service/contract-of-employment-contact-information.service';
import {ContractOfEmploymentGradeNotesService} from '../../../../content-renderer/elements/generic-toolbar/items/components/leased-employee/service/contract-of-employment-grade-notes.service';
import {ContractOfEmploymentValidFromService} from '../../../../content-renderer/elements/generic-toolbar/items/components/leased-employee/service/contract-of-employment-valid-from.service';
import {TranslateService} from '@ngx-translate/core';
import {RuntimeFlagName} from '../../../../content-renderer/services/ElementContext';
import {ContractOfEmploymentFormEldaService} from '../../../../content-renderer/elements/generic-toolbar/items/components/leased-employee/service/contract-of-employment-form-elda.service';
import {Element} from '../../../models';

export class HandleContractOfEmploymentFormActionHandler extends AbstractFormActionHandler {

  public constructor(
    private translate: TranslateService
  ) {
    super();
  }

  public handleAction(): void {
    const action = this.getAction(),
      component = this.getComponent();

    if (action && action.command === FormActionsEvents.ON_ENTITY_CHANGED) {
      this.doShowOrHideExitInformation(component);
      this.doShowOrHideExitAgreementDate(component);
      this.doSetGradeNotesRequired(component);
      this.doValidateValidFrom(component);
      this.validateEldaButton(component);

      const context = component.getElementContext();

      context.addRuntimeFlag({
        name: RuntimeFlagName.DisableDialogPersistHideAfterSave,
        status: null,
        active: true
      });
    }

    if (action && action.command === FormActionsEvents.ON_VALUE_CHANGED) {
      this.doShowOrHideExitAgreementDate(component);
      this.doSetGradeNotesRequired(component);
      this.doValidateValidFrom(component);

      const employmentType = this.formService.getElementBy(this.getForm(), 'datamodelField', 'employmentType');
      if (employmentType.value.code === 'ANG') {
        this.doShowOrHideOverPaymentFields(action);
      }

      if (component instanceof FormViewerComponent) {
        component.getValidator().clearCache();
        component.onValidate().subscribe();
      }
    }
  }

  private doShowOrHideExitInformation(component): void {
    const contractOfEmploymentVisibilityService = new ContractOfEmploymentExitInformationService();

    contractOfEmploymentVisibilityService.setForm(component)
      .setFormService(this.formService)
      .showOrHideExitInformation();
  }

  private doShowOrHideContactInformation(component): void {
    const contractOfEmploymentVisibilityService = new ContractOfEmploymentContactInformationService();

    contractOfEmploymentVisibilityService.setForm(component)
      .setFormService(this.formService)
      .showOrHideContactInformation();
  }

  private doShowOrHideExitAgreementDate(component): void {
    const contractOfEmploymentValidatorService = new ContractOfEmploymentExitAgreementDateService();

    contractOfEmploymentValidatorService.setForm(component)
      .setFormService(this.formService)
      .setupExitAgreementDate();
  }

  private doSetGradeNotesRequired(component): void {
    const gradeNotesService = new ContractOfEmploymentGradeNotesService();

    gradeNotesService.setForm(component)
      .setupGradeNotes();
  }

  private doValidateValidFrom(component): void {
    const gradeNotesService = new ContractOfEmploymentValidFromService();

    gradeNotesService.setForm(component)
      .setTranslate(this.translate)
      .setupValidation();
  }

  private doShowOrHideOverPaymentFields(action): void {
    const condition = action.conditions[0],
      formElement = this.formService.getElementBy(this.getForm(), 'objectHashId', condition.params[0].value);

    if (formElement.value === true) {
      const elements = this.getElementsByField('datamodelField');

      for (const element of elements) {
        if (element) {
          element.isHidden = false;
          this.markElementForCheck(element);
        }
      }
    }
  }

  private getElementsByField(key): Element[]|null {
    const elements = [];

    elements.push(
      this.formService.getElementBy(this.getForm(), key, 'overtimeRate'),
      this.formService.getElementBy(this.getForm(), key, 'overtimeRateHours'),
      this.formService.getElementBy(this.getForm(), key, 'baseSalary')
    );

    return elements;
  }

  private validateEldaButton(component): void {
    const eldaService = new ContractOfEmploymentFormEldaService();
    eldaService.setForm(component)
      .setFormService(this.formService);

    eldaService.hasEldaData().subscribe((eldaExport) => {
      if (eldaExport.length === 0) {
        return;
      }
      const eldaButton = this.formService.getElementBy(this.getForm(), 'label', 'Anmeldung');
      eldaButton.disabled = true;
      this.markElementForCheck(eldaButton);
    });
  }
}
