import { GenericElementAbstract } from '../../../../shared/content-renderer/elements/generic-element-abstract.component';
import { Event } from 'app/core/events/interfaces/event';

export class GenericElementEvent implements Event {
    
    private component: GenericElementAbstract = null;

    public constructor(component: GenericElementAbstract) {
        this.component = component;
    }

    public setComponent(component: GenericElementAbstract) {
        this.component = component;
        return this;
    }

    public getComponent(): GenericElementAbstract {
        return this.component;
    }

}
