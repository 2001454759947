
import { Injectable } from '@angular/core';
import { AbstractFormActionHandler } from '../abstract-form-action-handler';
import { FormService } from '../../../form.service';
import { HandleCountryPhoneActionHandler } from './handle-country-phone-action-handler';

@Injectable()
export class HandleCountryMainFaxActionHandler extends HandleCountryPhoneActionHandler {

    protected getPhoneCountryCodeDatamodelField(): string {
        return 'mainAddress.faxCountryCode';
    }

    protected getPhoneAreaCodeDatamodelField(): string {
        return 'mainAddress.faxAreaCode';
    }

    protected getPhoneNumberDatamodelField(): string {
        return 'mainAddress.faxNumber';
    }

    protected getCountryDatamodelField(): string {
        return 'mainAddress.country';
    }
}
