import { AbstractInlineEditor } from './abstract-inline-editor';
import {Observable, of} from 'rxjs';

export class DecimalInlineEditor extends AbstractInlineEditor {

    public onEdit(entity: any, event: any) {
        this.changeEntityColumnProperty(event.target.value);
    }

    public onBlur(entity: any, event: any) {
      this.changeEntityColumnProperty(event.target.value);
    }

    public onFocus(entity: any, event: any): Observable<any> {
      const originalEvent = event.originalEvent;

      if (originalEvent && originalEvent.target) {
        originalEvent.target.select();
      }

      return of(null);
    }
}
