
import {of as observableOf, Observable} from 'rxjs';
import {AbstractExecutionStep} from '../../../../core/executor/abstract-execution-step';
import {ExecutionStepStatus} from '../../../../core/executor/execution-step-status';
import {PlanningComponent} from '../../../content-renderer/elements/custom/planning/planning.component';

export class SetupLeasedEmployeePlanningComponentExecutionStep extends AbstractExecutionStep {

  public doExecute(): Observable<ExecutionStepStatus> {
    const payload = this.getPayload();

    if (!(payload.getValue() instanceof PlanningComponent)) {
      return this.getFailObservable('You need to pass PlanningComponent as Payload value!');
    }

    return this.doSetup(payload.getValue());
  }

  protected doSetup(component: PlanningComponent): Observable<ExecutionStepStatus> {
    const date = new Date();

    component.selectedYearFilter = date.getFullYear();
    component.selectedMonthFilter = date.getMonth() + 1;

    const leasedEmployeeId = this.getLeasedEmployeeId(component);

    if (leasedEmployeeId !== null) {
      component.staticFilters['leasedEmployee'] = leasedEmployeeId;
      component.isMasterLeasedEmployeeDefined = true;
    }

    component.onSearch().subscribe();

    return observableOf({status: true, content: null});
  }

  protected getLeasedEmployeeId(component: PlanningComponent): number|null {
    let leasedEmployeeId = null;

    if (component.getElementContext().getMasterEntity('leasedEmployee') &&
      component.getElementContext().getMasterEntity('leasedEmployee').value
    ) {
      leasedEmployeeId = +component.getElementContext().getMasterEntity('leasedEmployee').value;
    }

    return leasedEmployeeId;
  }
}
