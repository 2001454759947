import {of as observableOf, Observable, of} from 'rxjs';
import {AbstractExecutionStep} from '../../../../core/executor/abstract-execution-step';
import {ExecutionStepStatus} from '../../../../core/executor/execution-step-status';
import {GenericElementAbstract} from '../../../content-renderer/elements/generic-element-abstract.component';
import {FormViewerComponent} from '../../../form-viewer/form-viewer.component';
import {AbstractGenericGridComponent} from '../../../content-renderer/elements/abstract-generic-grid.component';
import {ExecutionStatusError, ExecutionStatusErrorAware} from '../../../../core/executor/execution-status-error';
import {EntityStatus} from '../../entity/entity-status';
import {takeUntil} from 'rxjs/operators';
import {EntityValidatorStatus} from '../../../validators/services/entity-validator';

export class ComponentValidationExecutionStep extends AbstractExecutionStep implements ExecutionStatusErrorAware {

    public doExecute(): Observable<ExecutionStepStatus> {
        const payload = this.getPayload();

        if (!(payload.getValue() instanceof GenericElementAbstract)) {
            return this.getFailObservable('You need to pass GenericElementAbstract as Payload value!');
        }

        return this.doValidation(payload.getValue());
    }

    public onError(executionStatusError: ExecutionStatusError) {
        // console.log(executionStatusError);
    }

    protected doValidation(component: GenericElementAbstract): Observable<ExecutionStepStatus> {
        const entity = this.getEntity(component);

        if (null === entity || entity[EntityStatus.ENTITY_DRAFT_DELETED_FLAG]) {
            return observableOf({status: true, content: null});
        }

      return component.getValidator().onValidate(entity, component)
        .pipe(
          takeUntil(component.unsubscribe)
        )
        .switchMap((validationStatus: EntityValidatorStatus) => {
          this.flagEntityValid(entity, validationStatus.isValid);

          return of({
            status: validationStatus.isValid,
            content: validationStatus.error
          })
        });
    }

    protected flagEntityValid(entity: any, isValid: boolean) {
        entity[EntityStatus.ENTITY_INVALID_FLAG] = !isValid;
    }

    protected getEntity(component: GenericElementAbstract): any {
        let entity = null;

        if (component instanceof AbstractGenericGridComponent && component.hasChanges(false)) {
          const changedEntities = component.getChangedEntities();

          if (changedEntities.length > 0) {
            entity = changedEntities[0];
          }
        }

        if (component instanceof FormViewerComponent) {
            entity = component.entity;
        }

        return entity;
    }
}
