/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./cost-center-report.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@ngx-translate/core";
import * as i3 from "../../../../components/generic-dropdown/generic-dropdown.component.ngfactory";
import * as i4 from "../../../../components/generic-dropdown/generic-dropdown.component";
import * as i5 from "../../../../services/generic-crud.service";
import * as i6 from "../../../../dynamic-table/dynamic-table.component.ngfactory";
import * as i7 from "../../../../dynamic-table/dynamic-table.component";
import * as i8 from "../../../../../core/executor/executor.service";
import * as i9 from "../../../../../core/executor/service/executor-actions/executor-actions.service";
import * as i10 from "../../../services/generic/generic-element-validation-execution-steps-factory";
import * as i11 from "../../../../../core/executor/factory/execution-step-factory.service";
import * as i12 from "../../../services/generic/filter/generic-element-filter.service";
import * as i13 from "./cost-center-report.component";
import * as i14 from "../../../services/component-highlight-stack.service";
import * as i15 from "../../../services/modules-state.service";
import * as i16 from "../../../services/entity-data-store.service";
import * as i17 from "../../../../validators/services/entity-validator";
import * as i18 from "../../../../../core/service/user-session.service";
import * as i19 from "../../generic-toolbar/services/check/toolbar-item-check.service";
import * as i20 from "../../../../services/layout-service";
import * as i21 from "../../../../../core/job-runner/job-container.service";
import * as i22 from "../../../services/elements-stack.service";
import * as i23 from "../../../services/elements-state.service";
import * as i24 from "../../generic-dialog/service/generic-dialog-module.service";
import * as i25 from "../../../../services/permission/permission.service";
var styles_CostCenterReportComponent = [i0.styles];
var RenderType_CostCenterReportComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_CostCenterReportComponent, data: {} });
export { RenderType_CostCenterReportComponent as RenderType_CostCenterReportComponent };
export function View_CostCenterReportComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 17, "div", [["class", "ui-g"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 6, "div", [["class", "ui-g-2"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 2, "div", [["class", "ui-g-4"]], null, null, null, null, null)), (_l()(), i1.ɵted(3, null, [" ", ": "])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(5, 0, null, null, 2, "div", [["class", "ui-g-8"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 1, "app-generic-dropdown", [], null, [[null, "optionChanged"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("optionChanged" === en)) {
        var pd_0 = (_co.onCostCenterChange($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i3.View_GenericDropdownComponent_0, i3.RenderType_GenericDropdownComponent)), i1.ɵdid(7, 114688, null, 0, i4.GenericDropdownComponent, [i5.GenericCrudService, i1.ChangeDetectorRef], { api: [0, "api"], labelKey: [1, "labelKey"], showEmptyOption: [2, "showEmptyOption"] }, { optionChanged: "optionChanged" }), (_l()(), i1.ɵeld(8, 0, null, null, 6, "div", [["class", "ui-g-2"]], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 2, "div", [["class", "ui-g-4"]], null, null, null, null, null)), (_l()(), i1.ɵted(10, null, [" ", ": "])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(12, 0, null, null, 2, "div", [["class", "ui-g-8"]], null, null, null, null, null)), (_l()(), i1.ɵeld(13, 0, null, null, 1, "app-generic-dropdown", [], null, [[null, "optionChanged"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("optionChanged" === en)) {
        var pd_0 = (_co.onYearChanged($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i3.View_GenericDropdownComponent_0, i3.RenderType_GenericDropdownComponent)), i1.ɵdid(14, 114688, null, 0, i4.GenericDropdownComponent, [i5.GenericCrudService, i1.ChangeDetectorRef], { api: [0, "api"], labelKey: [1, "labelKey"], showEmptyOption: [2, "showEmptyOption"] }, { optionChanged: "optionChanged" }), (_l()(), i1.ɵeld(15, 0, null, null, 2, "div", [["class", "ui-g-12"]], null, null, null, null, null)), (_l()(), i1.ɵeld(16, 0, null, null, 1, "shared-dynamic-table", [], null, [["window", "keyup"]], function (_v, en, $event) { var ad = true; if (("window:keyup" === en)) {
        var pd_0 = (i1.ɵnov(_v, 17).onKeyUp($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i6.View_DynamicTableComponent_0, i6.RenderType_DynamicTableComponent)), i1.ɵdid(17, 245760, null, 0, i7.DynamicTableComponent, [i1.ElementRef, i1.ChangeDetectorRef], { lazy: [0, "lazy"], selectFirstEntityWhenNothingIsSelected: [1, "selectFirstEntityWhenNothingIsSelected"], columns: [2, "columns"], cells: [3, "cells"], entities: [4, "entities"], parentComponent: [5, "parentComponent"], rowsCount: [6, "rowsCount"], paginator: [7, "paginator"], isLoadingData: [8, "isLoadingData"], height: [9, "height"], dataKey: [10, "dataKey"], showFilters: [11, "showFilters"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = "intranet/costcenters/unique"; var currVal_2 = "name"; var currVal_3 = true; _ck(_v, 7, 0, currVal_1, currVal_2, currVal_3); var currVal_5 = "intranet/babs/years"; var currVal_6 = "label"; var currVal_7 = true; _ck(_v, 14, 0, currVal_5, currVal_6, currVal_7); var currVal_8 = false; var currVal_9 = _co.moduleElement.selectFirst; var currVal_10 = _co.columns; var currVal_11 = _co.cells; var currVal_12 = _co.entities; var currVal_13 = _co; var currVal_14 = 9999; var currVal_15 = false; var currVal_16 = _co.isLoading; var currVal_17 = _co.gridHeight; var currVal_18 = "title"; var currVal_19 = false; _ck(_v, 17, 1, [currVal_8, currVal_9, currVal_10, currVal_11, currVal_12, currVal_13, currVal_14, currVal_15, currVal_16, currVal_17, currVal_18, currVal_19]); }, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 3, 0, i1.ɵnov(_v, 4).transform("COST_CENTER.COST_CENTER")); _ck(_v, 3, 0, currVal_0); var currVal_4 = i1.ɵunv(_v, 10, 0, i1.ɵnov(_v, 11).transform("COST_CENTER.YEAR")); _ck(_v, 10, 0, currVal_4); }); }
export function View_CostCenterReportComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 16777216, null, null, 4, "app-custom-cost-center-report", [], null, [[null, "mouseover"], [null, "mouseleave"]], function (_v, en, $event) { var ad = true; if (("mouseover" === en)) {
        var pd_0 = (i1.ɵnov(_v, 4).onMouseOver($event) !== false);
        ad = (pd_0 && ad);
    } if (("mouseleave" === en)) {
        var pd_1 = (i1.ɵnov(_v, 4).onMouseLeave($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, View_CostCenterReportComponent_0, RenderType_CostCenterReportComponent)), i1.ɵprd(512, null, i8.ExecutorService, i8.ExecutorService, [i9.ExecutorActionsService]), i1.ɵprd(512, null, i10.GenericElementValidationExecutionStepsFactory, i10.GenericElementValidationExecutionStepsFactory, [i11.ExecutionStepFactoryService]), i1.ɵprd(512, null, i12.GenericElementFilterService, i12.GenericElementFilterService, [i5.GenericCrudService, i2.TranslateService]), i1.ɵdid(4, 245760, null, 0, i13.CostCenterReportComponent, [i14.ComponentService, i1.ViewContainerRef, i15.ModulesStateService, i5.GenericCrudService, i16.EntityDataStoreService, i8.ExecutorService, i10.GenericElementValidationExecutionStepsFactory, i17.EntityValidator, i18.UserSessionService, i19.ToolbarItemCheckService, i20.LayoutService, i21.JobContainerService, i22.ElementsStackService, i23.ElementsStateService, i24.GenericDialogModuleService, i9.ExecutorActionsService, i25.PermissionService, i1.ChangeDetectorRef, i12.GenericElementFilterService], null, null)], function (_ck, _v) { _ck(_v, 4, 0); }, null); }
var CostCenterReportComponentNgFactory = i1.ɵccf("app-custom-cost-center-report", i13.CostCenterReportComponent, View_CostCenterReportComponent_Host_0, { masterElementContext: "masterElementContext", moduleElement: "moduleElement", element: "element", parentComponent: "parentComponent", isDialog: "isDialog", isPart: "isPart", toolbarItems: "toolbarItems", statusBarItems: "statusBarItems", selectedMasterEntity: "selectedMasterEntity", additional: "additional", fields: "fields", masterEntity: "masterEntity", masterField: "masterField" }, { componentInstantiated: "componentInstantiated" }, []);
export { CostCenterReportComponentNgFactory as CostCenterReportComponentNgFactory };
