<p-dialog
  [(visible)]="isDialogVisible"
  *ngIf="isDialogVisible"
  [modal]="true"
  [width]="600"
  [height]="200"
>
  <p-toolbar styleClass="no-round-corner">
    <div class="buttons-container">
      <div class="item-spacer"></div>
      <div class="item-right">
        <button
          type="button"
          pButton
          (click)="onRunCycle($event)"
          label="{{ 'COMMON.EXECUTE' | translate }}"
        ></button>
      </div>
    </div>
  </p-toolbar>

  <div fxLayout="column" fxLayoutAlign="right left" class="input-block">

    <div class="fx-row" fxLayout="row" fxLayoutAlign="center stretch">
      <div fxFlex="30">KV*:</div>
      <div fxFlex="60">
        <p-autoComplete
          [(ngModel)]="selectedCollectiveAgreement"
          [dropdown]="true"
          [suggestions]="collectiveAgreementOptions"
          (completeMethod)="onSearchCollectiveAgreements($event)"
          [minLength]="1"
          field="name"
          appendTo="body"
          styleClass="flex-layout"
        ></p-autoComplete>
      </div>
    </div>

  </div>
</p-dialog>
