
import {throwError as observableThrowError, Observable} from 'rxjs';

import {map, catchError} from 'rxjs/operators';
import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {AbstractGenericGridSimpleSearchFieldComponent} from './abstract-generic-grid-simple-search-field.component';
import {FieldMetadataGrid} from '../../../../../services/module/module-element-field-metadata-grid';
import {GenericCrudService} from '../../../../../services/generic-crud.service';
import {AbstractGridFilter} from '../../../generic-grid/filters/abstract-grid-filter';
import {ChangeDetectorRefHelper} from '../../../../../helpers/change-detector-ref.helper';

@Component({
  selector: 'app-generic-grid-simple-search-field-association',
  template: `
  <p-autoComplete
    field="label"
    [(ngModel)]="selectedValues"
    [multiple]="true"
    [suggestions]="dropdownOptions"
    (completeMethod)="onCompleteMethod($event)"
    (onKeyUp)="onKeyUp($event)"
    (onSelect)="onSelect($event)"
    (onUnselect)="onUnSelect($event)"
    [dropdown]="true"
    [style]="{'width': '100%'}"
    [size]="30"
    [minLength]="2"
    placeholder="---"
    appendTo="body"
  ></p-autoComplete>
  `,
  styles: [`
    p-autocomplete ::ng-deep .ui-autocomplete-multiple-container {
      width: calc(100% - 25px); // dropdown button
    }
  `]
})
export class GenericGridSimpleSearchFieldAssociationComponent extends AbstractGenericGridSimpleSearchFieldComponent implements OnInit {
  public selectedValues: any[] = [];
  public dropdownOptions: SelectItem[] = [];

  public constructor(
    private readonly genericCrudService: GenericCrudService,
    public cdr: ChangeDetectorRef
  ) {
    super();
  }

  public ngOnInit(): void {
    this.field.comparator = AbstractGridFilter.MATCH_MODE_IN;

    this.initEdit();
  }

  public onCompleteMethod(event: {query: string}): void {

    this.loadDropdownOptions(event.query)
      .subscribe((options: SelectItem[] = []) => {
        this.dropdownOptions = options;

        ChangeDetectorRefHelper.detectChanges(this);
      });
  }

  public onKeyUp(event): void {
    const value = event.target.value;

    if (value === '') {
      this.field.value = null;
      this.field.searchValue = null;
      this.selectedValues = null;
    }
  }

  public onSelect(event: any): void {
    this.changeFieldValue(this.selectedValues);
  }

  public onUnSelect(event: any): void {
    this.changeFieldValue(this.selectedValues);
  }

  protected changeFieldValue(fieldValues): void {
    const searchValues = [];

    if (fieldValues instanceof Array) {
      for (const fieldValue of fieldValues) {
        searchValues.push(fieldValue.value.id);
      }
    }

    this.field.value = fieldValues;
    this.field.searchValue = searchValues;

    if (searchValues.length === 0) {
      this.field.value = undefined;
      this.field.searchValue = undefined;
    }
  }

  protected addOption(option: SelectItem): void {
    const index = this.dropdownOptions.findIndex((aOption: SelectItem) => {
      return aOption.value.id === option.value.id;
    });

    if (index === -1) {
      this.dropdownOptions = [...this.dropdownOptions, option];
    }
  }

  protected loadDropdownOptions(query: string = ''): Observable<SelectItem[]> {
    const apiRoute = this.field.meta.associationEndpoint + `/offset/0/limit/50/orderby/id/asc?search=${query}`,
      requestParams = {clause: 'orWhere'};

    return this.genericCrudService.getEntities(apiRoute, '', requestParams).pipe(
      catchError((response) => {
        return observableThrowError(response);
      }),
      map((dmRecords: any) => {
        const options = [],
          records = (dmRecords.data) ? dmRecords.data : dmRecords;

        for (const record of records) {
          options.push({
            label: FieldMetadataGrid.getOptionLabel(
              record,
              this.field.meta
            ),
            value: record
          });
        }

        return options;
      }));
  }

  protected initEdit(): void {

    if (this.field.meta.associationEndpoint && this.field.value) {

      this.loadDropdownOptions()
        .subscribe((options: SelectItem[] = []) => {
          ChangeDetectorRefHelper.markForCheck(this);

          this.dropdownOptions = [...options];
          this.selectedValues = [...this.field.value];

          ChangeDetectorRefHelper.detectChanges(this);
        });
    }
  }
}
