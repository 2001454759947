import {ChangeDetectionStrategy, Component} from '@angular/core';
import {environment} from '../../../../../../../../environments';
import {ToolbarItemAbstract} from '../toolbar-item-abstract.component';
import {AbstractGenericGridComponent} from 'app/shared/content-renderer/elements/abstract-generic-grid.component';
import {RequestCachingService} from '../../../../../../services/request-caching.service';
import {LocalStorageDataService} from '../../../../../../services/local-storage-data.service';
import {Organisation} from '../../../../../../services/organisation/organisation';
import {UserSessionService} from '../../../../../../../core/service/user-session.service';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-toolbar-item-upload-document',
  templateUrl: './toolbar-item-upload-document.component.html',
  styles: [`
    ::ng-deep .ui-button-text {
      padding-left: 20px !important;
    }
  `]
})
export class ToolbarItemUploadDocument extends ToolbarItemAbstract {

  public isDialogVisible = false;

  public selectedEntity: any = null;

  public baseUrl = '';
  public organisationId = '';

  constructor(
    protected requestCachingService: RequestCachingService,
    protected localStorage: LocalStorageDataService,
    protected userSession: UserSessionService
  ) {
    super();
    this.baseUrl = environment.baseUrl;
  }

  public click() {
    const component = this.getComponent(),
      organisation = this.userSession.get(Organisation.LOCAL_STORAGE_NAME);

    this.organisationId = organisation.id;

    if (component instanceof AbstractGenericGridComponent) {
      this.selectedEntity = component.selectedEntity;

      this.isDialogVisible = true;
    }

  }

  public onUpload(event): void {
    const component = this.getComponent();

    if (component instanceof AbstractGenericGridComponent) {
      component.onChange({
        entity: this.selectedEntity,
        value: null,
        oldValue: null
      }).subscribe(() => {
        component.onRefresh().subscribe();
      });
    }

    this.dialogHide();
  }

  dialogHide() {
    this.isDialogVisible = false;
  }
}
