/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./todo-counter.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "@angular/flex-layout/extended";
import * as i4 from "@angular/flex-layout/core";
import * as i5 from "./todo-counter.component";
import * as i6 from "../../../core/service/polling/polling.service";
import * as i7 from "../../services/generic-crud.service";
import * as i8 from "../../../core/job-runner/job-container.service";
import * as i9 from "../../services/location.service";
import * as i10 from "../../content-renderer/services/modules-state.service";
import * as i11 from "../../content-renderer/services/logger/logger.service";
import * as i12 from "../../../core/service/user-session.service";
import * as i13 from "../../../core/authentication/authentication.service";
var styles_TodoCounterComponent = [i0.styles];
var RenderType_TodoCounterComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_TodoCounterComponent, data: {} });
export { RenderType_TodoCounterComponent as RenderType_TodoCounterComponent };
export function View_TodoCounterComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 28, "span", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "span", [["style", "color: black;"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Aufgaben:"])), (_l()(), i1.ɵeld(3, 0, null, null, 4, "span", [["style", "color: black;"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.openModule(1) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(5, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { ngClass: [0, "ngClass"] }, null), i1.ɵdid(6, 933888, null, 0, i3.DefaultClassDirective, [i1.ElementRef, i4.StyleUtils, i4.MediaMarshaller, i2.ɵNgClassImpl, [6, i2.NgClass]], { ngClass: [0, "ngClass"] }, null), (_l()(), i1.ɵted(7, null, [" ", " "])), (_l()(), i1.ɵeld(8, 0, null, null, 1, "span", [["style", "color: black;"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["/"])), (_l()(), i1.ɵeld(10, 0, null, null, 4, "span", [["style", "color: black;"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.openModule(2) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(12, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { ngClass: [0, "ngClass"] }, null), i1.ɵdid(13, 933888, null, 0, i3.DefaultClassDirective, [i1.ElementRef, i4.StyleUtils, i4.MediaMarshaller, i2.ɵNgClassImpl, [6, i2.NgClass]], { ngClass: [0, "ngClass"] }, null), (_l()(), i1.ɵted(14, null, [" ", " "])), (_l()(), i1.ɵeld(15, 0, null, null, 1, "span", [["style", "color: black;"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["/"])), (_l()(), i1.ɵeld(17, 0, null, null, 4, "span", [["style", "color: black;"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.openModule(3) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(19, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { ngClass: [0, "ngClass"] }, null), i1.ɵdid(20, 933888, null, 0, i3.DefaultClassDirective, [i1.ElementRef, i4.StyleUtils, i4.MediaMarshaller, i2.ɵNgClassImpl, [6, i2.NgClass]], { ngClass: [0, "ngClass"] }, null), (_l()(), i1.ɵted(21, null, [" ", " "])), (_l()(), i1.ɵeld(22, 0, null, null, 1, "span", [["style", "color: black;"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["/"])), (_l()(), i1.ɵeld(24, 0, null, null, 4, "span", [["style", "color: black;"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.openModule(4) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(26, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { ngClass: [0, "ngClass"] }, null), i1.ɵdid(27, 933888, null, 0, i3.DefaultClassDirective, [i1.ElementRef, i4.StyleUtils, i4.MediaMarshaller, i2.ɵNgClassImpl, [6, i2.NgClass]], { ngClass: [0, "ngClass"] }, null), (_l()(), i1.ɵted(28, null, [" ", " "]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_co.todoCounterData.countMy === 0) ? "disabled" : ""); _ck(_v, 5, 0, currVal_0); var currVal_1 = ((_co.todoCounterData.countMy === 0) ? "disabled" : ""); _ck(_v, 6, 0, currVal_1); var currVal_3 = ((_co.todoCounterData.countGroup === 0) ? "disabled" : ""); _ck(_v, 12, 0, currVal_3); var currVal_4 = ((_co.todoCounterData.countGroup === 0) ? "disabled" : ""); _ck(_v, 13, 0, currVal_4); var currVal_6 = ((_co.todoCounterData.countICreated === 0) ? "disabled" : ""); _ck(_v, 19, 0, currVal_6); var currVal_7 = ((_co.todoCounterData.countICreated === 0) ? "disabled" : ""); _ck(_v, 20, 0, currVal_7); var currVal_9 = ((_co.todoCounterData.countBranchOffice === 0) ? "disabled" : ""); _ck(_v, 26, 0, currVal_9); var currVal_10 = ((_co.todoCounterData.countBranchOffice === 0) ? "disabled" : ""); _ck(_v, 27, 0, currVal_10); }, function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.todoCounterData.countMy.toString(); _ck(_v, 7, 0, currVal_2); var currVal_5 = _co.todoCounterData.countGroup.toString(); _ck(_v, 14, 0, currVal_5); var currVal_8 = _co.todoCounterData.countICreated.toString(); _ck(_v, 21, 0, currVal_8); var currVal_11 = _co.todoCounterData.countBranchOffice.toString(); _ck(_v, 28, 0, currVal_11); }); }
export function View_TodoCounterComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-todo-counter", [], null, null, null, View_TodoCounterComponent_0, RenderType_TodoCounterComponent)), i1.ɵdid(1, 114688, null, 0, i5.TodoCounterComponent, [i6.PollingService, i7.GenericCrudService, i8.JobContainerService, i9.LocationService, i10.ModulesStateService, i11.LoggerService, i12.UserSessionService, i13.AuthenticationService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var TodoCounterComponentNgFactory = i1.ɵccf("app-todo-counter", i5.TodoCounterComponent, View_TodoCounterComponent_Host_0, {}, {}, []);
export { TodoCounterComponentNgFactory as TodoCounterComponentNgFactory };
