import {ChangeDetectionStrategy, Component} from '@angular/core';
import {AbstractGenericGridComponent} from '../../../../../abstract-generic-grid.component';
import {ToolbarItemAbstract} from '../../toolbar-item-abstract.component';
import {MessageGrowlService} from '../../../../../../../../core/message/message-growl.service';
import {ComponentService} from '../../../../../../services/component-highlight-stack.service';
import {GenericCrudService} from '../../../../../../../services/generic-crud.service';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'toolbar-item-ca-contract-of-employment',
  template: ''
})
export class ToolbarItemCaContractOfEmploymentComponent extends ToolbarItemAbstract {

  protected entity: any;

  constructor(
    protected componentService: ComponentService,
    protected messageGrowlService: MessageGrowlService,
    protected genericCrudService: GenericCrudService
  ) {
    super();
  }

  public click() {
    const component = this.getComponent();

    if (component instanceof AbstractGenericGridComponent) {
      this.handleEmploymentDataChanges(component);
    }
  }

  protected handleEmploymentDataChanges(grid: AbstractGenericGridComponent) {
    if (grid.selectedEntity) {

      this.genericCrudService.customPut(`phoenix/collectiveagreements/${grid.selectedEntity.id}/employmentdatachanges`, {}).subscribe(
        collectiveAgreement => {
          let entityIndex = grid.entities.findIndex((entity) => {
            return entity.id === collectiveAgreement['id'];
          });
          if(entityIndex) {
            grid.entities[entityIndex]['isIncreaseCycleComplete'] = collectiveAgreement['isIncreaseCycleComplete'];
            grid.entities = [...grid.entities];
            grid.selectedEntity = collectiveAgreement;
          }
          this.messageGrowlService.success('Erfolgreich gespeichert.....');
        },
        errorResponse => {
          console.log(errorResponse);
          this.messageGrowlService.error(errorResponse.error.error);
        }
      );
    }
  }

}
