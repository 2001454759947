import {Observable} from 'rxjs/Rx';
import {AbstractExecutionStep} from '../../../../../core/executor/abstract-execution-step';
import {ExecutionStepStatus} from '../../../../../core/executor/execution-step-status';
import {MessageGrowlService} from '../../../../../core/message/message-growl.service';
import {TOAST_LIFE_INFINITE, ToastComponentsRegistry, ToastService} from '../../../../../core/service/toast.service';
import {EntityStatus} from '../../../entity/entity-status';
import {EntityManagerService} from '../../../../../core/service/entity-manager/entity-manager.service';
import {StoreType} from '../../../../content-renderer/services/entity-dirty-store.service';
import {AbstractGenericGridComponent} from '../../../../content-renderer/elements/abstract-generic-grid.component';

export class ContextMenuWorkHourSetCostCenterExecutionStep extends AbstractExecutionStep {

  public doExecute(): Observable<ExecutionStepStatus> {
    const payload = this.getPayload(),
      component = payload.getValue();

    const selectedEntity = component.getSelectedEntity();

    if (!selectedEntity) {
      return this.getFailObservable('No entities to calculate');
    }

    return this.doSetCostCenter(component, selectedEntity);
  }

  protected doSetCostCenter(component: AbstractGenericGridComponent, selectedEntity: any): Observable<ExecutionStepStatus> {

      this.getToastService().custom(ToastComponentsRegistry.PROGRESS_BAR, {
        severity: 'info',
        life: TOAST_LIFE_INFINITE,
        closable: false,
        summary: 'Updating...'
      });

      let customerCostCenter = this.entityHydrator.getEntityPropertyValue(selectedEntity, 'customerCostCenter');

      return this.genericCrudService.customPut(`phoenix/workhours/${selectedEntity.id}/costcentertotheendofmonth/${customerCostCenter['id']}`, {})
        .map(
        (response) => {
          const errors: {message: string, timeEntry: {id: number}}[] = response['errors'] || [];

          this.handleChangedEntities(response, component);
          this.handleErrors(errors, component);

          this.getToastService().clear(ToastComponentsRegistry.PROGRESS_BAR);

          return {status: true, content: null};
        },
        errorResponse => {
          this.getToastService().clear(ToastComponentsRegistry.PROGRESS_BAR);
          this.getMessageGrowlService().error(errorResponse.error.error);

          return {status: false, content: errorResponse.error.error};
        }
      );
  }

  private handleChangedEntities(values, component): void {
    for (const value of values){

      const entity = component.entities.find((aEntity) => {
        return aEntity.id === value['id'];
      });
      if (entity) {
        if (!entity._embedded) {
          entity['_embedded'] = {};
        }
        let customerCostCenter = this.entityHydrator.getEntityPropertyValue(value, 'customerCostCenter');
        entity._embedded['customerCostCenter'] = customerCostCenter;
        entity['customerCostCenter'] = customerCostCenter;
        let changeSetIndex = this.getEntityManagerService().getChangeSetContainer().getChangeSet(entity).findIndex((aValue) => { return aValue.property === 'customerCostCenter'; });
        if(changeSetIndex !== -1){
          this.getEntityManagerService().getChangeSetContainer().getChangeSet(entity).splice(changeSetIndex,1);
          if(this.getEntityManagerService().getChangeSetContainer().getChangeSet(entity).length === 0){
            entity.isChanged = false;
            this.entityDirtyStoreService.doRemove(entity, StoreType.Update);
          }
        }

      }
      if (component.selectedEntity.id === entity.id) {
        component.reselectEntity();
      }
    }

    this.getMessageGrowlService().success('Erfolgreich berechnet. Success.');
  }

  private handleErrors(errors, component): void {
    const errorMessages = [];
    for (const error of errors) {
      const message = error.message,
        entryId = error.timeEntry.id;

      const entity = component.entities.find((aEntity) => {
        return aEntity.id === entryId;
      });

      errorMessages.push(message);

      if (entity) {
        entity[EntityStatus.ENTITY_INVALID_FLAG] = true;
      }
    }

    if (errorMessages.length > 0) {
      this.getMessageGrowlService().error(errorMessages.join(','));
    }
  }

  private getMessageGrowlService(): MessageGrowlService {
    return this.injector.get(MessageGrowlService, null);
  }

  private getToastService(): ToastService {
    return this.injector.get(ToastService, null);
  }

  private getEntityManagerService(): EntityManagerService {
    return this.injector.get(EntityManagerService, null);
  }

}
