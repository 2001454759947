
import {of as observableOf, Observable } from 'rxjs';
import {AbstractExecutionStep} from '../../../../core/executor/abstract-execution-step';
import {ExecutionStepStatus } from '../../../../core/executor/execution-step-status';
import {GenericElementAbstract} from '../../../content-renderer/elements/generic-element-abstract.component';

export class DetailsViewAfterDeleteExecutionStep extends AbstractExecutionStep {

  public doExecute(): Observable<ExecutionStepStatus> {
    const payload = this.getPayload();

    if (!(payload.getValue() instanceof GenericElementAbstract)) {
      return this.getFailObservable('You need to pass GenericElementAbstract as Payload value!');
    }

    return this.afterDelete(payload.getValue());
  }

  private afterDelete(component: GenericElementAbstract): Observable<ExecutionStepStatus> {
    const currentModuleState = this.modulesStateService.getCurrent();

    let mainModuleId: any = currentModuleState.main && currentModuleState.main.id ? currentModuleState.main.id : null;

    if (currentModuleState.isDialog) {
      // todo
    } else {
      if (mainModuleId !== null) {
        this.modulesStateService.remove(currentModuleState);

        this.locationService
          .getLocation()
          .back();
      } else {
        mainModuleId = this.locationService.getParam('parent-module');

        this.locationService
          .getRouter()
          .navigate([`phoenix/18/module/${mainModuleId}`]);
      }
    }

    return observableOf({status: true, content: null});
  }
}
