
import {of as observableOf, Observable} from 'rxjs';
import {AbstractExecutionStep} from '../../../../core/executor/abstract-execution-step';
import {ExecutionStepStatus} from '../../../../core/executor/execution-step-status';
import {AbstractGenericGridComponent} from '../../../content-renderer/elements/abstract-generic-grid.component';
import {ElementContext} from '../../../content-renderer/services/ElementContext';
import {EntityManagerService} from '../../../../core/service/entity-manager/entity-manager.service';

export class SetupDispoLeasedEmployeeSearchTabOneExecutionStep extends AbstractExecutionStep {

  public doExecute(): Observable<ExecutionStepStatus> {
    const payload = this.getPayload(),
      component = payload.getValue().component ? payload.getValue().component : payload.getValue();

    if (!(component instanceof AbstractGenericGridComponent)) {
      return this.getFailObservable('You need to pass AbstractGenericGridComponent as Payload value!');
    }

    const masterElementContext: ElementContext = component.masterElementContext;

    if (!masterElementContext || !masterElementContext.component || !(masterElementContext.component instanceof AbstractGenericGridComponent)) {
      return this.getFailObservable('You need to pass AbstractGenericGridComponent as Payload value!');
    }

    return this.doSetup(component, masterElementContext.component);
  }

  protected doSetup(component: AbstractGenericGridComponent, assignmentGridComponent: AbstractGenericGridComponent):
    Observable<ExecutionStepStatus> {
      const masterEntity: any = {leasedEmployees: []},
        orderIds = [];
      
      for (const entity of assignmentGridComponent.entities) {
        if (entity['leasedEmployee']) {
          masterEntity.leasedEmployees.push(entity['leasedEmployee']);
          orderIds.push(entity['leasedEmployee'].id);

          const entityManager = this.injector.get(EntityManagerService, null);
          entityManager.persist(masterEntity, {property: 'leasedEmployees', oldValue: null, newValue: masterEntity.leasedEmployees});
        }
      }

      component.masterEntity = masterEntity;
      component.masterEntityEditingField = 'leasedEmployees';
      component.selectedEntities = undefined;
      component.sortField = 'Selection';
      component.staticFilters = [...component.staticFilters, {
        field: 'orderType',
        value: orderIds.join(',')
      }];

      return observableOf({status: true, content: null});
  }
}
