import {
  AfterViewInit,
  ChangeDetectorRef,
  Component, EventEmitter, Input, OnDestroy, OnInit, Output
} from '@angular/core';
import {AbstractGenericGridComponent} from '../abstract-generic-grid.component';
import {SimpleSearchField} from '../../../services/simple-search/simple-search';
import {SimpleSearchService} from '../../../services/simple-search/simple-search.service';
import {ExecutorActionEvent} from '../../../../core/executor/service/executor-actions/executor-action-event';
import {ChangeDetectorRefHelper} from '../../../helpers/change-detector-ref.helper';

export interface SimpleSearchChangeMeta {
  searchFields: {fieldKey: string, fieldValue: string}[];
  embeddedFields: string[];
}

@Component({
  selector: 'app-generic-grid-simple-search',
  templateUrl: './generic-grid-simple-search.component.html',
  styleUrls: ['./generic-grid-simple-search.component.scss']
})
export class GenericGridSimpleSearchComponent implements OnInit, OnDestroy, AfterViewInit {

  @Output() public onSearch: EventEmitter<SimpleSearchChangeMeta> = new EventEmitter();

  @Input() public component: AbstractGenericGridComponent = null;
  @Input() public simpleSearchFields: SimpleSearchField[] = null;

  public constructor(
    private readonly simpleSearch: SimpleSearchService,
    private readonly cdr: ChangeDetectorRef
  ) {

  }

  public ngOnInit(): void {
    this.simpleSearchFields = this.simpleSearch.getCache().get(this.component.moduleElement.id);

    if (!this.simpleSearch.getCache().isHit(this.component.moduleElement.id)) {
      this.simpleSearchFields = SimpleSearchService.getFields(this.component);
    }
  }

  public ngAfterViewInit(): void {
    ChangeDetectorRefHelper.detectChanges(this);
  }

  public ngOnDestroy(): void {
    this.simpleSearch.getCache().add(this.component.moduleElement.id, this.simpleSearchFields);
  }

  public onSearchButtonClick(event: any): void {
    const searchFields = SimpleSearchService.parseForSearch(this.simpleSearchFields),
      embeddedFields = SimpleSearchService.getEmbedded(this.simpleSearchFields);

    this.component.executeAction(ExecutorActionEvent.BeforeSimpleSearch, {
        component: this.component,
        searchFields: searchFields,
        embeddedFields: embeddedFields
      })
      .subscribe(() => {
        this.onSearch.emit({
          searchFields: searchFields,
          embeddedFields: embeddedFields
        });
      });
  }
}
