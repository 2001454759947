/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../../generic-grid/services/generic-grid-global-filter.service";
import * as i2 from "../../../../../../../core/service/user-session.service";
import * as i3 from "../../../../../services/modules-state.service";
import * as i4 from "../../../../../../../core/authentication/authentication.service";
import * as i5 from "../../../../../../services/local-storage-data.service";
import * as i6 from "./toolbar-item-show-deleted.component";
import * as i7 from "../../../../../services/component-highlight-stack.service";
var styles_ToolbarItemShowDeleted = [];
var RenderType_ToolbarItemShowDeleted = i0.ɵcrt({ encapsulation: 2, styles: styles_ToolbarItemShowDeleted, data: {} });
export { RenderType_ToolbarItemShowDeleted as RenderType_ToolbarItemShowDeleted };
export function View_ToolbarItemShowDeleted_0(_l) { return i0.ɵvid(2, [], null, null); }
export function View_ToolbarItemShowDeleted_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "toolbar-item-show-deleted", [], null, null, null, View_ToolbarItemShowDeleted_0, RenderType_ToolbarItemShowDeleted)), i0.ɵprd(512, null, i1.GenericGridGlobalFilterService, i1.GenericGridGlobalFilterService, [i2.UserSessionService, i3.ModulesStateService, i4.AuthenticationService, i5.LocalStorageDataService]), i0.ɵdid(2, 245760, null, 0, i6.ToolbarItemShowDeleted, [i2.UserSessionService, i1.GenericGridGlobalFilterService, i7.ComponentService], null, null)], function (_ck, _v) { _ck(_v, 2, 0); }, null); }
var ToolbarItemShowDeletedNgFactory = i0.ɵccf("toolbar-item-show-deleted", i6.ToolbarItemShowDeleted, View_ToolbarItemShowDeleted_Host_0, { params: "params" }, {}, []);
export { ToolbarItemShowDeletedNgFactory as ToolbarItemShowDeletedNgFactory };
