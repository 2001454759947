
import {of as observableOf, Observable} from 'rxjs';
import {AbstractExecutionStep} from '../../../../core/executor/abstract-execution-step';
import {ExecutionStepStatus} from '../../../../core/executor/execution-step-status';
import {SkipConditionsAware} from '../../../../core/executor/type/skip-conditions-aware';
import {AbstractExecutionStepCondition} from '../../../../core/executor/abstract-execution-step-condition';
import {IsInAutoCompleteViewModuleState} from '../conditions/is-in-autocomplete-view-module-state-condition';
import {AbstractGenericGridComponent} from '../../../content-renderer/elements/abstract-generic-grid.component';
import {FormViewerComponent} from '../../../form-viewer/form-viewer.component';
import {ModuleState} from '../../../content-renderer/services/module-state';
import {GenericElementAbstract} from '../../../content-renderer/elements/generic-element-abstract.component';
import {Entity} from '../../../helpers/entity';

export class FilterGridByCustomerStep extends AbstractExecutionStep implements SkipConditionsAware {

  public getSkipConditions(): AbstractExecutionStepCondition[] {
    return [
      new IsInAutoCompleteViewModuleState()
    ];
  }

  doExecute(): Observable<ExecutionStepStatus> {
    const payload = this.getPayload();

    let component = payload.getValue();

    if (payload instanceof Object && payload.getValue().component) {
      component = payload.getValue().component;
    }

    if (!(component instanceof AbstractGenericGridComponent)) {
      return this.getFailObservable('You need to pass AbstractGenericGridComponent as Payload value!');
    }

    return this.doFilter(component);
  }

  private doFilter(component: AbstractGenericGridComponent): Observable<ExecutionStepStatus> {
    const parentModuleState = this.getParentModuleState(component);

    if (parentModuleState !== null) {
      const customerId = this.getParentFormCustomerId(parentModuleState);

      if (customerId === null) {
        return this.getFailObservable('CustomerID empty in parent master, something is wrong with configuration!');
      }

      component.gridFilters['customer.id'] = { value: customerId, matchMode: 'contains' };
      if (!component.embeddedFields.includes('customer')) {
        component.embeddedFields.push('customer');
      }
    }

    return observableOf({ status: true, content: '' });
  }

  private getParentFormCustomerId(moduleState: ModuleState|null): string|null {
    const form = this.getFormInComponents(moduleState.getAllComponents());

    let customerId = null;

    if (form !== null) {
      customerId = Entity.getValue(form.entity, 'customer.id') || Entity.getValueInEmbedded(form.entity, 'customer.id');
    }

    return customerId;
  }

  private getFormInComponents(components: GenericElementAbstract[]): FormViewerComponent|null {
    let found = null;

    for (const component of components) {
      if (component instanceof FormViewerComponent) {
        found = component;
        break;
      }
    }

    return found;
  }

  private getParentModuleState(component: AbstractGenericGridComponent): ModuleState|null {
    const moduleState: ModuleState = component.getModuleState().getCurrent();

    if (moduleState && moduleState.parent) {
      return moduleState.parent;
    }

    return null;
  }
}
