
    <div fxLayout="row" (click)="onClickElement($event)">
      <p-fieldset [legend]="element.label" [styleClass]="!element.showLabel ? 'hide-fieldset-label' : ''" #elementItem>
        <ng-container *ngFor="let elementChild of element.children">
          <app-form-element
            [(selectedElement)]="selectedElement"
            [(actionParams)]="actionParams"
            [(showDialogAddAddress)]="showDialogAddAddress"
            [form]="form"
            [formGroup]="formGroup"
            [element]="elementChild"
            [moduleElement]="moduleElement"
            [entity]="entity"
            [editMode]="editMode"
          ></app-form-element>
        </ng-container>
      </p-fieldset>
    </div>
  