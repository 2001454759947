import {map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AppHttpService } from '../../../app-http.service';
import { ToolbarSetItem } from './toolbar.set.item';
import { GenericCrudService } from '../generic-crud.service';

@Injectable()
export class ToolbarSetItemCrudService extends AppHttpService {

  private toolbarSetItemsUrl: string = 'superadmin/toolbarsets/:toolbarSetId/items';

  /**
   *
   * @param http
     */
  constructor(
    private genericCrudService: GenericCrudService) {
    super();
  }

  getToolbarSetItem(toolbarSetId: number, toolbarSetItemId: number): Observable<ToolbarSetItem> {
    const urlWithId = this.toolbarSetItemsUrl.replace(/\:toolbarSetId/g, String(toolbarSetId)) + '/' + toolbarSetItemId;
    return this.genericCrudService.get(urlWithId);
  }

  getToolbarSetItems(toolbarSetId: number): Observable<ToolbarSetItem[]> {
    const urlWithId = this.toolbarSetItemsUrl.replace(/\:toolbarSetId/g, String(toolbarSetId));
    return this.genericCrudService.get(urlWithId).pipe(
      map((toolbarSetItems: Array<any>) => {
        return <Array<ToolbarSetItem>>toolbarSetItems;
      }));
  }

  deleteToolbarSetItem(toolbarSetId: number, toolbarSetItemId: number): Observable<Object> {
    if (toolbarSetId > 0 && toolbarSetItemId > 0) {
      const urlWithId = this.toolbarSetItemsUrl.replace(/\:toolbarSetId/g, String(toolbarSetId));
      return this.genericCrudService.deleteEntity(urlWithId + '/' + toolbarSetItemId);
    } else {
      throw new Error('Invalid toolbar set or set item id given.');
    }
  }

  createToolbarSetItem(toolbarSetId: number, toolbarSetItem: ToolbarSetItem): Observable<ToolbarSetItem> {
    if (toolbarSetId > 0 && toolbarSetItem) {
      const urlWithId = this.toolbarSetItemsUrl.replace(/\:toolbarSetId/g, String(toolbarSetId));
      return this.genericCrudService.createEntity(urlWithId, toolbarSetItem);
    } else {
      throw new Error(`
        Invalid data given.
        Toolbar set id: ${toolbarSetId};
        new toolbar set item: ${toolbarSetItem};
      `);
    }
  }

  editToolbarSetItem(toolbarSetId: number, toolbarSetItem: ToolbarSetItem): Observable<ToolbarSetItem> {
    if (toolbarSetId > 0 && toolbarSetItem) {
      const urlWithId = this.toolbarSetItemsUrl.replace(/\:toolbarSetId/g, String(toolbarSetId)) + '/' + toolbarSetItem.id;

      return this.genericCrudService.editEntity(urlWithId, toolbarSetItem).pipe(
        map((json) => { return <ToolbarSetItem>json; }));
    } else {
      throw new Error('No toolbar item set given.');
    }
  }
}
