import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

import { Menu } from '../../../shared/services/menu/menu';

@Injectable()
export class MenuCommunicationService {

  // Observable string sources
  private menuOpenDialogSource = new Subject<Menu>();
  private menuOpenTabSource = new Subject<Menu>();

  // Observable string streams
  menuOpenDialog$ = this.menuOpenDialogSource.asObservable();
  menuOpenTab$ = this.menuOpenTabSource.asObservable();

  // Service message commands
  onSelectMenuOpenWindow(menu: Menu) {
    this.menuOpenDialogSource.next(menu);
  }

  onSelectMenuOpenTab(menu: Menu) {
    this.menuOpenTabSource.next(menu);
  }

}
