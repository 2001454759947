import {Injectable} from '@angular/core';
import {PreCalculation, PreCalculationArticle} from '../pre-calculation-article';

@Injectable()
export class PreCalculationArticleRecalculateService {
  /**
   *
   * @param generalArticle
   * @param articles
   */
  public recalculateArticles(generalArticle: PreCalculationArticle, articles: PreCalculationArticle[], precalculation: PreCalculation): void {
    for (const otherArticle of articles) {
      if (otherArticle.factorSalary && otherArticle.factorSalary !== 0) {
        otherArticle.salaryHour = this.getSalaryAmount(generalArticle, otherArticle);
        otherArticle.salaryMonth = otherArticle.salaryHour * precalculation.standardHoursDivider
          * this.getAdditionalCalculationFactor(precalculation);
        otherArticle.targetInvoiceHour = this.getInvoiceAmount(generalArticle, otherArticle);
        otherArticle.targetInvoiceMonth = otherArticle.targetInvoiceHour * precalculation.standardHoursDivider
          * this.getAdditionalCalculationFactor(precalculation);
        otherArticle.invoiceHour = otherArticle.targetInvoiceHour;
        otherArticle.invoiceMonth = otherArticle.invoiceHour * precalculation.standardHoursDivider
          * this.getAdditionalCalculationFactor(precalculation);
        otherArticle.isChanged = true;
      }
    }
  }

  /**
   *
   * @param generalArticle
   * @param otherArticle
   */
  protected getSalaryAmount(generalArticle: PreCalculationArticle, otherArticle: PreCalculationArticle): number {
    let factor = otherArticle.factorSalary;

    if (otherArticle.isUsingFactorIncrement) {
      factor += 1;
    }

    return factor * generalArticle.salaryHour;
  }

  /**
   *
   * @param generalArticle
   * @param otherArticle
   */
  protected getInvoiceAmount(generalArticle: PreCalculationArticle, otherArticle: PreCalculationArticle): number {
    let result = otherArticle.salaryHour * otherArticle.factorTargetInvoice;

    if (otherArticle.isUsingFactorIncrement) {
      result = generalArticle.invoiceHour * otherArticle.factor;
    }

    return result;
  }

  protected getAdditionalCalculationFactor(precalculation: PreCalculation): number {
    if (precalculation.defaultWeeklyWorkingHours && precalculation.weeklyWorkingHours) {
      return precalculation.weeklyWorkingHours / precalculation.defaultWeeklyWorkingHours;
    }
    return 1;
  }
}
