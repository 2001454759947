import { Observable } from 'rxjs';
import { AbstractExecutionStep } from '../../../../core/executor/abstract-execution-step';
import { GenericElementAbstract } from '../../../content-renderer/elements/generic-element-abstract.component';
import { InquiryPositionSalaryTypeDataGenerator } from './service/inquiry-position-salary-type-data-generator.service';
import { InquiryPositionSalaryTypeTableGenerator } from './service/inquiry-position-salary-type-table-generator';
import { Entity } from '../../../helpers/entity';
import { ChangeDetectorRefHelper } from '../../../helpers/change-detector-ref.helper';
export class GenerateInquiryDynamicTableStep extends AbstractExecutionStep {
    constructor() {
        super(...arguments);
        this.inquiryFormContext = null;
        this.inquiryPositionGridContext = null;
        this.gridContext = null;
        this.dataGenerator = new InquiryPositionSalaryTypeDataGenerator();
        this.tableGenerator = new InquiryPositionSalaryTypeTableGenerator();
    }
    doExecute() {
        const payload = this.getPayload();
        let component = payload.getValue();
        if (this.isElementChangeEventAndIsNotChangingCustomerCollectiveContract(component) &&
            !this.isParentGridEntitiesChangedEvent()) {
            return this.getFailObservable('Changing other fields, skip it...');
        }
        if (payload instanceof Object && payload.getValue().component) {
            component = payload.getValue().component;
        }
        if (this.isParentGridEntitiesChangedEvent()) {
            return this.doGenerate(component);
        }
        if (!(component instanceof GenericElementAbstract)) {
            return this.getFailObservable('You need to pass GenericElementAbstract as Payload value!');
        }
        return this.doGenerate(component);
    }
    doGenerate(component) {
        this.createContext(component);
        if (this.inquiryFormContext === null || this.inquiryPositionGridContext === null || this.gridContext === null) {
            return this.getFailObservable('Wrong configuration for GenerateRowsAndColumnsForInquiryDynamicTableStep');
        }
        const dynamicTable = this.gridContext.component, inquiryPositions = this.getInquiryPositionsFromGrid();
        const inquiry = this.inquiryFormContext.component.entity, collectiveAgreement = this.getCollectiveAgreement(inquiry);
        this.resetTable();
        const alreadyGeneratingData = dynamicTable.isDataLoading;
        if (!alreadyGeneratingData && inquiryPositions.length > 0 && collectiveAgreement) {
            dynamicTable.columns = [];
            dynamicTable.isDataLoading = true;
            return Observable.create((observer) => {
                this.dataGenerator
                    .setGenericCrudService(this.genericCrudService)
                    .generateData(inquiryPositions, collectiveAgreement.id)
                    .subscribe((entities) => {
                    this.generateTree(entities);
                    dynamicTable.onEntitiesChanged().subscribe();
                    observer.next({ status: true, content: null });
                    observer.complete();
                });
            });
        }
        return this.getFailObservable('Nothing to generate!');
    }
    generateTree(entities) {
        const dynamicTable = this.gridContext.component;
        const generated = this.tableGenerator.setComponent(dynamicTable)
            .generateTable(entities);
        dynamicTable.defaultPageSize = generated.nodes.length;
        dynamicTable.totalCount = generated.nodes.length;
        dynamicTable.columns = [...generated.columns];
        dynamicTable.nodes = [...generated.nodes];
        dynamicTable.isDataLoading = false;
        ChangeDetectorRefHelper.detectChanges(dynamicTable);
        return this;
    }
    resetTable() {
        const dynamicTable = this.gridContext.component;
        dynamicTable.rows = [];
        dynamicTable.columns = [];
        dynamicTable.entities = [];
        dynamicTable.totalCount = 0;
        return this;
    }
    getInquiryPositionsFromGrid() {
        const inquiry = this.inquiryFormContext.component.entity, gridInquiryPositions = this.inquiryPositionGridContext.component.entities;
        const inquiryPositions = [];
        if (inquiry) {
            for (const inquiryPosition of gridInquiryPositions) {
                const embeddedInquiry = inquiryPosition._embedded ? inquiryPosition._embedded.inquiry : null;
                if ((embeddedInquiry && embeddedInquiry.id === inquiry.id) || !embeddedInquiry) {
                    inquiryPositions.push(inquiryPosition);
                }
            }
        }
        return inquiryPositions;
    }
    createContext(stepCallerComponent) {
        const stepCallerContext = stepCallerComponent.getElementContext();
        let callerComponentType = null;
        if (stepCallerContext) {
            callerComponentType = stepCallerContext.type;
        }
        switch (callerComponentType) {
            case 5 /* DynamicTree */:
            case 1 /* Grid */:
                this.inquiryFormContext = stepCallerContext.getMasterElementContext();
                break;
            case 2 /* Form */:
                this.inquiryFormContext = stepCallerContext;
                break;
        }
        if (this.inquiryFormContext) {
            for (const childContext of this.inquiryFormContext.getSlaveElementContexts()) {
                if (childContext.type === 5 /* DynamicTree */) {
                    this.gridContext = childContext;
                }
                else {
                    this.inquiryPositionGridContext = childContext;
                }
            }
        }
    }
    isElementChangeEventAndIsNotChangingCustomerCollectiveContract(payloadValue) {
        if (!payloadValue.entityDataChangeMeta && this.isClickEvent(event)) {
            return true;
        }
        return payloadValue.entityDataChangeMeta &&
            payloadValue.entityDataChangeMeta.element &&
            payloadValue.entityDataChangeMeta.element.datamodelField !== 'customerCollectiveContract';
    }
    isClickEvent(event) {
        return event instanceof CustomEvent && event.type === 'gridEntitySelectedMouseClick';
    }
    getCollectiveAgreement(inquiry) {
        let collectiveAgreement = null;
        const customerCollectiveContract = Entity.getValue(inquiry, 'customerCollectiveContract') ||
            Entity.getValueInEmbedded(inquiry, 'customerCollectiveContract');
        if (customerCollectiveContract !== null) {
            collectiveAgreement = Entity.getValue(customerCollectiveContract, 'collectiveAgreement') ||
                Entity.getValueInEmbedded(customerCollectiveContract, 'collectiveAgreement');
        }
        return collectiveAgreement;
    }
    isParentGridEntitiesChangedEvent() {
        return this.getParamValue('event') === 12 /* EntitiesChanged */;
    }
}
