import { ToolbarItemWordExport } from './toolbar-item-word-export.component';
export class ToolbarItemWordExportInvoice extends ToolbarItemWordExport {
    constructor() {
        super(...arguments);
        this.wordExportUrlAdditionalQueryParams = { entitiesFromField: 'invoicePositions' };
    }
    downloadFile(fileResponse) {
        this.messageGrowlService.success(this.translate.instant('COMMON.SUCCESS'));
    }
}
