import { FieldActionOpenElementComponent } from '../../content-renderer/elements/generic-grid/field-actions/open-element-action/field-action-open-element.component';
import { FieldActionOpenModuleComponent } from '../../content-renderer/elements/generic-grid/field-actions/open-module-action/field-action-open-module.component';
import { FieldActionSetValueToAnotherFieldComponent } from '../../content-renderer/elements/generic-grid/field-actions/set-value-to-another-field-action/field-action-set-value-to-another-field.component';
import { FieldActionSetExtraParamFromAnotherFieldComponent } from '../../content-renderer/elements/generic-grid/field-actions/set-extra-param-from-another-field-action/field-action-set-extra-param-from-another-field.component';
import { FieldActionSetOverallWorkingTimeComponent } from "../../content-renderer/elements/generic-grid/field-actions/set-overall-working-time/field-action-set-overall-working-time.component";
import { FieldActionSetPlannedWorkingTimeComponent } from "../../content-renderer/elements/generic-grid/field-actions/set-planned-working-time/field-action-set-planned-working-time.component";
import { FieldActionDisableAnotherFieldComponent } from '../../content-renderer/elements/generic-grid/field-actions/disable-another-field-action/field-action-disable-another-field.component';
import { FieldActionDisableSelfBaseOnAnotherFieldComponent } from '../../content-renderer/elements/generic-grid/field-actions/disable-self-base-on-another-field/field-action-disable-self-base-on-another-field.component';
import { FieldActionDisableSelfBasedOnOpenQuantityComponent } from '../../content-renderer/elements/generic-grid/field-actions/disable-self-based-on-open-quantity/field-action-disable-self-based-on-open-quantity.component';
import { FieldActionGetCollectiveAgreementValidToComponent } from '../../content-renderer/elements/generic-grid/field-actions/CollectiveAgreement/field-action-get-collective-agreement-valid-to.component';
import { FieldActionOpenConditionModuleComponent } from '../../content-renderer/elements/generic-grid/field-actions/open-module-action/field-action-open-condition-module.component';
import { FieldActionExecuteStepComponent } from '../../content-renderer/elements/generic-grid/field-actions/execute-step/field-action-execute-step.component';
import { FieldActionSetExtraParamFromOneMultiSelectFilterToAnotherComponent } from '../../content-renderer/elements/generic-grid/field-actions/set-extra-param-from-one-multiselect-filter-to-another/field-action-set-extra-param-from-one-multiselect-filter-to-another.component';
import { FieldActionSetExtraParamFromOneMultiselectEditToAnotherComponent } from '../../content-renderer/elements/generic-grid/field-actions/set-extra-param-from-one-multiselect-edit-to-another/field-action-set-extra-param-from-one-multiselect-edit-to-another.component';
import { FieldActionCalculateSalaryComponent } from "../../content-renderer/elements/generic-grid/field-actions/workhour-calculate-salary/field-action-calculate-salary.component";
import { FieldActionSetValueToAnotherFieldFromEntityComponent } from "../../content-renderer/elements/generic-grid/field-actions/set-value-to-another-field-action/field-action-set-value-to-another-field-from-entity.component";
import { FieldActionInquiryPositionSetPrecalculationValuesComponent } from '../../content-renderer/elements/generic-grid/field-actions/inquiry-position/field-action-inquiry-position-set-precalculation-values.component';
export class ActionRegistryService {
    constructor() {
        this.actions = [];
        this.events = [
            1 /* DoubleClick */,
            2 /* IconClick */,
            3 /* Click */,
            4 /* EntityValueChange */,
            5 /* CustomButtonClick */,
            6 /* PhoneIconClick */,
            7 /* FilterValueChange */,
            8 /* BeforeFocus */,
            9 /* Focus */
        ];
        this.actions = [...this.actions,
            {
                'action': 1 /* OpenElement */,
                'component': FieldActionOpenElementComponent
            }, {
                'action': 2 /* OpenModule */,
                'component': FieldActionOpenModuleComponent
            }, {
                'action': 11 /* OpenConditionModule */,
                'component': FieldActionOpenConditionModuleComponent
            }, {
                'action': 3 /* SetValueToAnotherField */,
                'component': FieldActionSetValueToAnotherFieldComponent
            }, {
                'action': 16 /* SetValueToAnotherFieldFromEntity */,
                'component': FieldActionSetValueToAnotherFieldFromEntityComponent
            }, {
                'action': 4 /* SendExtraParamFromAnotherField */,
                'component': FieldActionSetExtraParamFromAnotherFieldComponent
            }, {
                'action': 5 /* SetOverallWorkingTime */,
                'component': FieldActionSetOverallWorkingTimeComponent
            }, {
                'action': 6 /* SetPlannedWorkingTime */,
                'component': FieldActionSetPlannedWorkingTimeComponent
            }, {
                'action': 15 /* FieldActionCalculateSalaryComponent */,
                'component': FieldActionCalculateSalaryComponent
            }, {
                'action': 7 /* DisableAnotherField */,
                'component': FieldActionDisableAnotherFieldComponent
            }, {
                'action': 8 /* DisableSelfBaseOnAnotherField */,
                'component': FieldActionDisableSelfBaseOnAnotherFieldComponent
            }, {
                'action': 10 /* GetCollectiveAgreementValidTo */,
                'component': FieldActionGetCollectiveAgreementValidToComponent
            }, {
                'action': 9 /* DisableSelfBasedOnOpenQuantity */,
                'component': FieldActionDisableSelfBasedOnOpenQuantityComponent
            }, {
                'action': 12 /* ExecuteStep */,
                'component': FieldActionExecuteStepComponent
            }, {
                'action': 13 /* SetExtraParamFromOneMultiSelectFilterToAnother */,
                'component': FieldActionSetExtraParamFromOneMultiSelectFilterToAnotherComponent
            }, {
                'action': 14 /* SetExtraParamFromOneMultiSelectEditToAnother */,
                'component': FieldActionSetExtraParamFromOneMultiselectEditToAnotherComponent
            }, {
                'action': 17 /* SetInquiryPositionPreCalculationFields */,
                'component': FieldActionInquiryPositionSetPrecalculationValuesComponent
            }];
    }
    getActions() {
        return this.actions;
    }
    getEvents() {
        return this.events;
    }
}
