import { InquiryPositionSalaryTypeMatrix, InquiryPositionMatrixItem } from './inquiry-position-salary-type-matrix';
import { InquiryPositionSalaryType } from './inquiry-position-salary-type';
import {Criterion} from '../../../saved-search/criterion';
import {TreeNode} from 'primeng/primeng';

export class InquiryPositionSalaryTypeParser {

    private nodes: TreeNode[] = [];

    public getInquiryPositionSalaryTypesFromMatrix(matrixItems: InquiryPositionSalaryTypeMatrix[]): InquiryPositionSalaryType[] {
        const inquiryPositionSalaryTypes: InquiryPositionSalaryType[] = [];

        for (const updatedMatrixItem of matrixItems) {
            for (const inquiryPositionId in updatedMatrixItem.inquiryPositions) {
                const inquiryPositionItem: InquiryPositionMatrixItem = updatedMatrixItem.inquiryPositions[inquiryPositionId],
                    inquiryPosition = inquiryPositionItem.inquiryPosition,
                    isRequired = inquiryPositionItem.isRequired,
                    inquiryPositionSalaryTypeId = inquiryPositionItem.inquiryPositionSalaryType.id;

                const inquiryPositionSalaryType = {
                    id: inquiryPositionSalaryTypeId,
                    salaryType: updatedMatrixItem.salaryType,
                    inquiryPosition: inquiryPosition,
                    isRequired: isRequired
                };

                inquiryPositionSalaryTypes.push(inquiryPositionSalaryType);
            }
        }

        return inquiryPositionSalaryTypes;
    }

    public getMatrixItemsFromInquiryPositionSalaryTypes(inquiryPositionsSalaryTypes: InquiryPositionSalaryType[] = []): TreeNode[] {
        const onlyParents = [];

        for (const inquiryPositionSalaryType of inquiryPositionsSalaryTypes) {
          if (!inquiryPositionSalaryType.parent) {
            onlyParents.push(inquiryPositionSalaryType);
          }
        }

        this.createTree(onlyParents, this.nodes = []);

        return this.nodes;
    }

    private createTree(inquiryPositionsSalaryTypes: InquiryPositionSalaryType[] = [], tree: any[] = []) {

      for (const inquiryPositionSalaryType of inquiryPositionsSalaryTypes) {
        let exists = tree.find((aSalaryType: any) => {
          return inquiryPositionSalaryType.salaryType.id === aSalaryType.data.id;
        });

        if (!exists) {
          exists = {
            data: {
              id: inquiryPositionSalaryType.salaryType.id,
              salaryType: inquiryPositionSalaryType.salaryType,
              inquiryPositions: {}
            }
          };

          exists.data.inquiryPositions[inquiryPositionSalaryType.inquiryPosition.id] = {
            isRequired: inquiryPositionSalaryType.isRequired,
            inquiryPosition: inquiryPositionSalaryType.inquiryPosition,
            inquiryPositionSalaryType: inquiryPositionSalaryType
          };

          tree.push(exists);
        } else {
          exists.data.inquiryPositions[inquiryPositionSalaryType.inquiryPosition.id] = {
            isRequired: inquiryPositionSalaryType.isRequired,
            inquiryPosition: inquiryPositionSalaryType.inquiryPosition,
            inquiryPositionSalaryType: inquiryPositionSalaryType
          };
        }

        if (inquiryPositionSalaryType.children && inquiryPositionSalaryType.children.length > 0) {
          exists.children = exists.children || [];

          exists.expanded = true;

          this.createTree(inquiryPositionSalaryType.children, exists.children);
        }
      }

      return tree;
    }
}
