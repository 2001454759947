/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./image-column.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "../../../../../generate-hash.pipe";
import * as i4 from "./image-column.component";
import * as i5 from "@angular/platform-browser";
import * as i6 from "../../../../../services/permission/permission.service";
import * as i7 from "../../../../../services/entity-hydrator.service";
var styles_ImageColumnComponent = [i0.styles];
var RenderType_ImageColumnComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ImageColumnComponent, data: {} });
export { RenderType_ImageColumnComponent as RenderType_ImageColumnComponent };
function View_ImageColumnComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "label", [], [[8, "htmlFor", 0]], null, null, null, null)), i1.ɵppd(1, 1), (_l()(), i1.ɵeld(2, 0, null, null, 0, "span", [["class", "fa fa-fw fa-upload"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "span", [["class", "upload-text"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Bild hochladen"]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵinlineInterpolate(1, "", i1.ɵunv(_v, 0, 0, _ck(_v, 1, 0, i1.ɵnov(_v.parent.parent, 0), _co.hashIdForUpload)), ""); _ck(_v, 0, 0, currVal_0); }); }
function View_ImageColumnComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [["class", "form-element-input-image-upload"], ["style", "width: 100px; height: 120px;"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 4, "div", [["class", "simple-upload"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "input", [["type", "file"]], [[8, "readOnly", 0], [8, "id", 0]], [[null, "change"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("change" === en)) {
        var pd_0 = (_co.onChangeImage($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵppd(3, 1), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ImageColumnComponent_2)), i1.ɵdid(5, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_2 = !_co.isReadOnly(); _ck(_v, 5, 0, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.isReadOnly(); var currVal_1 = i1.ɵinlineInterpolate(1, "", i1.ɵunv(_v, 2, 1, _ck(_v, 3, 0, i1.ɵnov(_v.parent, 0), _co.hashIdForUpload)), ""); _ck(_v, 2, 0, currVal_0, currVal_1); }); }
function View_ImageColumnComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "image-action"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onRemoveImage($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, [" entfernen "]))], null, null); }
function View_ImageColumnComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["class", "form-element-input-image-preview"], ["style", "width: 100px; height: 120px; display: grid; position: relative;"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "img", [["alt", "Bild Vorschau"], ["style", "max-height: 100%; max-width: 100%;"]], [[8, "src", 4]], null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ImageColumnComponent_4)), i1.ɵdid(3, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = !_co.isReadOnly(); _ck(_v, 3, 0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.imageSrc; _ck(_v, 1, 0, currVal_0); }); }
export function View_ImageColumnComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i3.GenerateHashPipe, []), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ImageColumnComponent_1)), i1.ɵdid(2, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ImageColumnComponent_3)), i1.ɵdid(4, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.imageSrc; _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.imageSrc; _ck(_v, 4, 0, currVal_1); }, null); }
export function View_ImageColumnComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-image-column", [], null, null, null, View_ImageColumnComponent_0, RenderType_ImageColumnComponent)), i1.ɵdid(1, 114688, null, 0, i4.ImageColumnComponent, [i5.DomSanitizer, i6.PermissionService, i7.EntityHydrator], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ImageColumnComponentNgFactory = i1.ɵccf("app-image-column", i4.ImageColumnComponent, View_ImageColumnComponent_Host_0, { entity: "entity", component: "component", column: "column", editMode: "editMode", disabled: "disabled" }, { onKeyUp: "onKeyUp", onBlur: "onBlur", onFocus: "onFocus", onBeforeFocus: "onBeforeFocus", valueChange: "valueChange", tabOrEnter: "tabOrEnter" }, []);
export { ImageColumnComponentNgFactory as ImageColumnComponentNgFactory };
