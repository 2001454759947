import {Injectable} from '@angular/core';
import {TableColumn} from '../../../../../dynamic-table/shared/table-column';
import {GenericCrudService} from '../../../../../services/generic-crud.service';
import {ApiBuilderService} from '../../../../../services/api.builder.service';
import {AbstractTableService} from '../../assignment/service/abstract-table.service';
import {Entity} from '../../../../../helpers/entity';

@Injectable()
export class LeasedEmployeeCostsTableService extends AbstractTableService {

  public constructor(
    protected genericCrudService: GenericCrudService,
    protected apiBuilderService: ApiBuilderService
  ) {
    super(genericCrudService, apiBuilderService);
  }

  protected getApiRoute(): string {
    return 'intranet/leasedemployeecosts';
  }

  public getColumns(): TableColumn[] {
    return [
      {
        key: 'period',
        header: 'Periode',
        renderer: (entity) => {
          return Entity.getValue(entity, 'period.description') ||
            Entity.getValueInEmbedded(entity, 'period.description')
        }
      },
      {
        key: 'branchOffice',
        header: 'Filiale',
        renderer: (entity) => {
          return Entity.getValue(entity, 'branchOffice.name') ||
            Entity.getValueInEmbedded(entity, 'branchOffice.name');
        }
      },
      {
        key: 'personalNumber',
        header: 'Personalnummer',
        property: 'personalNumber'
      },
      {
        key: 'name',
        header: 'Name',
        property: 'name'
      },
      {
        key: 'totalGross',
        header: 'Brutto Gesamt',
        property: 'totalGross',
        renderType: 'decimal'
      },
      {
        key: 'grossSocialInsurance',
        header: 'Brutto SZ',
        property: 'grossSocialInsurance',
        renderType: 'decimal'
      },
      {
        key: 'uel',
        header: 'UEL',
        property: 'uel',
        renderType: 'decimal'
      },
      {
        key: 'totalDga',
        header: 'Summe DGA',
        property: 'totalDga',
        renderType: 'decimal'
      },
      {
        key: 'specialPaymentLastMonth',
        header: 'Sonzerzahlung letzer Monat',
        property: 'specialPaymentLastMonth',
        renderType: 'decimal'
      },
      {
        key: 'specialPaymentNow',
        header: 'Sonderzahlung jetzt',
        property: 'specialPaymentNow',
        renderType: 'decimal'
      },
      {
        key: 'specialPaymentChange',
        header: 'Sonderzahlung unterschied',
        property: 'specialPaymentChange',
        renderType: 'decimal'
      },
      {
        key: 'vacationPaymentLastMonth',
        header: 'Urlaubzahlung letzter Monat',
        property: 'vacationPaymentLastMonth',
        renderType: 'decimal'
      },
      {
        key: 'vacationPaymentNow',
        header: 'Urlaubzahlung jetzt',
        property: 'vacationPaymentNow',
        renderType: 'decimal'
      },
      {
        key: 'vacationPaymentChange',
        header: 'Urlaubzahlung unterschied',
        property: 'vacationPaymentChange',
        renderType: 'decimal'
      },
      {
        key: 'timeEqualizationLastMonth',
        header: 'Gutstunden letzter Monat',
        property: 'timeEqualizationLastMonth'
      },
      {
        key: 'timeEqualizationNow',
        header: 'Gutstunden jetzt',
        property: 'timeEqualizationNow'
      },
      {
        key: 'timeEqualizationChange',
        header: 'Gutstunden unterschied',
        property: 'timeEqualizationChange'
      },
      {
        key: 'remainingBuak',
        header: 'BUAK RST',
        property: 'remainingBuak',
        renderType: 'decimal'
      },
      {
        key: 'kuaAdvancement',
        header: 'KUA Förderungen',
        property: 'kuaAdvancement',
        renderType: 'decimal'
      },
      {
        key: 'amsAdvancement',
        header: 'AMS Förderungen',
        property: 'amsAdvancement',
        renderType: 'decimal'
      },
      {
        key: 'totalCostsNoRst',
        header: 'Gesamtkosten ohne RST',
        property: 'totalCostsNoRst',
        renderType: 'decimal'
      },
      {
        key: 'invoice',
        header: 'Faktura',
        property: 'invoice',
        renderType: 'decimal'
      },
      {
        key: 'roe',
        header: 'ROE',
        property: 'roe',
        renderType: 'decimal'
      },
      {
        key: 'profit',
        header: 'Gewinn/Ma.',
        property: 'profit',
        renderType: 'decimal'
      },
      {
        key: 'percentagesRoe',
        header: 'Prozentwerte des ROE:',
        property: 'percentagesRoe',
        renderType: 'decimal'
      },
      {
        key: 'gkkPunishAndLawyerCosts',
        header: 'GKK Strafen und RAW-Kosten',
        property: 'gkkPunishAndLawyerCosts',
        renderType: 'decimal'
      },
      {
        key: 'roomAndToolCosts',
        header: 'Zimmer- und Werkzeugkosten',
        property: 'roomAndToolCosts',
        renderType: 'decimal'
      },
      {
        key: 'buakInvoice',
        header: 'BUAK Rechnungen',
        property: 'buakInvoice',
        renderType: 'decimal'
      },
      {
        key: 'expenses',
        header: 'Auslagen',
        property: 'expenses',
        renderType: 'decimal'
      },
      {
        key: 'hourIll',
        header: 'Std. Krank',
        property: 'hourIll'
      },
      {
        key: 'hourVacation',
        header: 'Std. Urlaub',
        property: 'hourVacation'
      },
      {
        key: 'hourStand',
        header: 'Std. Steh',
        property: 'hourStand'
      },
      {
        key: 'hourFullTime',
        header: 'Std. FT',
        property: 'hourFullTime'
      },
      {
        key: 'timeEqualizationRight',
        header: 'ZA Ansp',
        property: 'timeEqualizationRight'
      },
      {
        key: 'timeEqualizationPayout',
        header: 'ZA Ausz',
        property: 'timeEqualizationPayout'
      },
      {
        key: 'hourWage',
        header: 'Std. L',
        property: 'hourWage'
      },
      {
        key: 'hourFull',
        header: 'Std. F',
        property: 'hourFull'
      }
    ];
  }

}
