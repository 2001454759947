
import {of as observableOf, Observable} from 'rxjs';
import {AbstractExecutionStep} from '../../../core/executor/abstract-execution-step';
import {ExecutionStepStatus} from '../../../core/executor/execution-step-status';
import {AbstractGenericGridComponent} from '../../content-renderer/elements/abstract-generic-grid.component';
import {FormViewerComponent} from '../../form-viewer/form-viewer.component';
import {ElementType} from '../../content-renderer/services/ElementContext';

export class MasterEntityImportSubEntitiesExecutionStep extends AbstractExecutionStep {

  public doExecute(): Observable<ExecutionStepStatus> {
    const payload = this.getPayload(),
      masterComponent = payload.getValue();

    let masterComponentEntity = null;

    if (masterComponent instanceof AbstractGenericGridComponent) {
      this.importIntoGridEntities(masterComponent);
      masterComponentEntity = masterComponent.getSelectedEntity();
    } else if (masterComponent instanceof FormViewerComponent) {
      this.importIntoFormEntity(masterComponent);
      masterComponentEntity = masterComponent.entity;
    }

    if (masterComponentEntity && this.isMasterInCurrentModuleState(masterComponent)) {
      this.modulesStateService.getCurrent().entity = masterComponentEntity;
    }

    return observableOf({status: true, content: null});
  }

  private importIntoFormEntity(masterComponent: FormViewerComponent) {
    for (const slaveElementContext of masterComponent.getElementContext().getSlaveElementContexts()) {
      if (slaveElementContext.component instanceof AbstractGenericGridComponent) {
        const masterEntityField = this.getMasterEntityField(slaveElementContext);
        const collectionEntityName = this.getCollectionPropertyName(slaveElementContext.component);

        if (collectionEntityName && masterEntityField) {
          masterComponent.entity[collectionEntityName] = [];

          for (const updatedEntity of slaveElementContext.component.getUpdatedEntities(true)) {
            this.pushToMasterEntityCollection(
              masterComponent.entity,
              collectionEntityName,
              updatedEntity
            );
          }

          for (const createdEntity of slaveElementContext.component.getCreatedEntities(true)) {
            this.pushToMasterEntityCollection(
              masterComponent.entity,
              collectionEntityName,
              createdEntity
            );
          }

          for (const embeddedUpdatedEntity of slaveElementContext.component.getEmbeddedEntitiesChanged()) {
            this.pushToMasterEntityCollection(
              masterComponent.entity,
              collectionEntityName,
              embeddedUpdatedEntity
            );
          }
        }
      }

      if (slaveElementContext.type === ElementType.MemoFieldDefinitionValue) {
        const collectionEntityName = 'fieldDefinitionValues';

        if (collectionEntityName) {
          for (const createdEntity of slaveElementContext.component.getCreatedEntities(true)) {
            this.pushToMasterEntityCollection(
              masterComponent.entity,
              collectionEntityName,
              createdEntity
            );
          }

          for (const updatedEntity of slaveElementContext.component.getUpdatedEntities(true)) {
            this.pushToMasterEntityCollection(
              masterComponent.entity,
              collectionEntityName,
              updatedEntity
            );
          }
        }
      }

    }
  }

  private importIntoGridEntities(masterComponent: AbstractGenericGridComponent) {
    for (const slaveElementContext of masterComponent.getElementContext().getSlaveElementContexts()) {

      if (slaveElementContext.type === ElementType.MemoFieldDefinitionValue) {
        const collectionEntityName = 'fieldDefinitionValues';

        if (collectionEntityName) {
          for (const createdEntity of slaveElementContext.component.getCreatedEntities(true)) {
            this.pushToMasterEntityCollection(
              masterComponent.getSelectedEntity(),
              collectionEntityName,
              createdEntity
            );
          }

          for (const updatedEntity of slaveElementContext.component.getUpdatedEntities(true)) {
            this.pushToMasterEntityCollection(
              masterComponent.getSelectedEntity(),
              collectionEntityName,
              updatedEntity
            );
          }
        }
      }

      if (slaveElementContext.component instanceof AbstractGenericGridComponent) {
        const masterEntityField = this.getMasterEntityField(slaveElementContext);
        const collectionEntityName = this.getCollectionPropertyName(slaveElementContext.component);

        if (collectionEntityName && masterEntityField) {
          for (const updatedEntity of slaveElementContext.component.getUpdatedEntities(true)) {
            this.pushToMasterEntityCollection(
              this.findMasterEntity(masterComponent, this.getMasterEntity(updatedEntity, masterEntityField) || masterComponent.selectedEntity),
              collectionEntityName,
              updatedEntity
            );
          }
          for (const updatedEntity of slaveElementContext.component.getUpdatedEntities(false)) {
            this.pushToMasterEntityCollection(
              this.findMasterEntity(masterComponent, this.getMasterEntity(updatedEntity, masterEntityField) || masterComponent.selectedEntity),
              collectionEntityName,
              updatedEntity
            );
          }

          for (const createdEntity of slaveElementContext.component.getCreatedEntities(true)) {
            this.pushToMasterEntityCollection(
              this.findMasterEntity(masterComponent, this.getMasterEntity(createdEntity, masterEntityField) || masterComponent.selectedEntity),
              collectionEntityName,
              createdEntity
            );
          }
          for (const createdEntity of slaveElementContext.component.getCreatedEntities(false)) {
            this.pushToMasterEntityCollection(
              this.findMasterEntity(masterComponent, this.getMasterEntity(createdEntity, masterEntityField) || masterComponent.selectedEntity),
              collectionEntityName,
              createdEntity
            );
          }

          for (const embeddedUpdatedEntity of slaveElementContext.component.getEmbeddedEntitiesChanged()) {
            this.pushToMasterEntityCollection(
              this.findMasterEntity(masterComponent, this.getMasterEntity(embeddedUpdatedEntity, masterEntityField) || masterComponent.selectedEntity),
              collectionEntityName,
              embeddedUpdatedEntity
            );
          }
        }
      }
    }
  }

  private findMasterEntity(masterComponent: AbstractGenericGridComponent, entity: any): any {
    return (entity instanceof Object) ? (masterComponent.findEntity(entity) || entity) : masterComponent.findEntityById(entity);
  }

  private isMasterInCurrentModuleState(masterComponent: AbstractGenericGridComponent): boolean {
    const currentModuleState = this.modulesStateService.getCurrent();

    return currentModuleState && masterComponent && currentModuleState.id === masterComponent.moduleElement.moduleId;
  }
}
