
import {forkJoin as observableForkJoin, of as observableOf, Observable} from 'rxjs';

import {catchError} from 'rxjs/operators';
import {AbstractExecutionStep} from '../../../../core/executor/abstract-execution-step';
import {ExecutionStepStatus} from '../../../../core/executor/execution-step-status';
import {ModuleState} from '../../../content-renderer/services/module-state';
import {GenericElementAbstract} from '../../../content-renderer/elements/generic-element-abstract.component';
import {AbstractGenericGridComponent} from '../../../content-renderer/elements/abstract-generic-grid.component';
import {FormViewerComponent} from '../../../form-viewer/form-viewer.component';
import {ElementContext, ElementType} from '../../../content-renderer/services/ElementContext';
import {GenericIframeComponent} from '../../../content-renderer/elements/generic-iframe/generic-iframe.component';
import {PlanningComponent} from '../../../content-renderer/elements/custom/planning/planning.component';

export class DetailsViewMasterRefreshExecutionStep extends AbstractExecutionStep {

  public doExecute(): Observable<ExecutionStepStatus> {
    const payload = this.getPayload();

    const moduleState = payload.getValue();

    if (!(moduleState instanceof ModuleState)) {
      return observableOf({status: false, content: 'Payload should be modulestate, not something else.'});
    }

    return this.doRefresh(moduleState);
  }

  protected parseComponents(moduleState: ModuleState): GenericElementAbstract[] {
    const components: GenericElementAbstract[] = [];
    for (const component of moduleState.getAllComponents()) {
      if (this.shouldRefresh(component)) {
        components.push(component);
      }
    }

    return components;
  }

  protected doRefresh(moduleState: ModuleState): Observable<ExecutionStepStatus> {
    const observables = [];

    for (const component of this.parseComponents(moduleState)) {

      if (component.getElementContext().isDetailsViewMaster) {
        this.setModuleStateEntityToMasterComponent(component, moduleState.entity);
      }

      observables.push(component.onRefresh());
    }

    return Observable.create((observer) => {

      if (observables.length === 0) {
        observer.next({
          status: true,
          content: null,
        });
        observer.complete();
      }

      observableForkJoin(observables).pipe(
        catchError((response: any) => {
          return observableOf(response);
        }))
        .subscribe(results => {
          this.entityDraftStoreService.clear();
          observer.next({
            status: true,
            content: results
          });
          observer.complete();
        });
    });
  }

  private setModuleStateEntityToMasterComponent(component: GenericElementAbstract, entity: any): void {

    if (component instanceof AbstractGenericGridComponent) {
      component.setSelectedEntity(entity);
    }

    if (component instanceof FormViewerComponent) {
      component.setEntity(entity);
    }
  }

  private shouldRefresh(component: GenericElementAbstract): boolean {
    if (component instanceof GenericIframeComponent) {
      return false;
    }
    if (component instanceof PlanningComponent) {
      return true;
    }
    const slaveFormContext: ElementContext = component.getElementContext().getFirstSlaveElementContextsByType(ElementType.Form);

    let shouldRefresh = component.hasChanges(true);

    if (component instanceof AbstractGenericGridComponent &&
      component.getCreatedEntities().length === 0 && // do not refresh grids only in case we don't have created entries
      slaveFormContext &&
      slaveFormContext.component &&
      slaveFormContext.component instanceof FormViewerComponent &&
      slaveFormContext.component.hasChanges(true)
    ) {
      shouldRefresh = false;
    }

    return shouldRefresh;
  }
}
