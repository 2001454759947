import { Observable } from 'rxjs';
import { GenericElementEvent } from '../../../../../services/event/event/generic-element-event';
import { ActionHandlerSubscribeResponse } from '../../../../../../core/events/interfaces/action-handler-subscribe-response';
import { AbstractGenericElementActionHandler } from '../../../../../services/event/action-handler/abstract-generic-element-action-handler';

export class GenericGridInitActionHandler extends AbstractGenericElementActionHandler {

    protected doAction(event: GenericElementEvent): Observable<ActionHandlerSubscribeResponse> {
        const component = event.getComponent(); // grid

        return this.getObservable(true);
    }

}
