<table>
  <tr>
    <td [hidden]="isHidden('pdf')">
      <button
        pTooltip="Export PDF"
        pButton
        icon="fa fa-print"
        (click)="print('pdf')"
        label="PDF"
      ></button>
    </td>
    <td [hidden]="isHidden('docx')">
      <button
        pTooltip="Export DOCX"
        pButton
        icon="fa fa-print"
        (click)="print('docx')"
        label="DOCX"
      ></button>
    </td>
  </tr>
</table>
