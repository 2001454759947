
import {map} from 'rxjs/operators';
import {Observable} from 'rxjs';
import {AbstractExecutionStep} from '../../../../../core/executor/abstract-execution-step';
import {ExecutionStepStatus} from '../../../../../core/executor/execution-step-status';
import {MessageGrowlService} from '../../../../../core/message/message-growl.service';
import {TOAST_LIFE_INFINITE, ToastComponentsRegistry, ToastService} from '../../../../../core/service/toast.service';

export class ContextMenuWorkHourCalculateWeeklyExecutionStep extends AbstractExecutionStep {

  public doExecute(): Observable<ExecutionStepStatus> {
    const payload = this.getPayload(),
      component = payload.getValue();

    const selectedEntity = component.getSelectedEntity();

    return this.doCalculate(selectedEntity);
  }

  protected doCalculate(data: any): Observable<ExecutionStepStatus> {
      this.getToastService().custom(ToastComponentsRegistry.PROGRESS_BAR, {
        severity: 'info',
        life: TOAST_LIFE_INFINITE,
        closable: false,
        summary: 'Calculating...'
      });

      return this.genericCrudService.customPut(`phoenix/calculate/weekly/workhours`, data).pipe(
        map(
        () => {
          this.getToastService().clear(ToastComponentsRegistry.PROGRESS_BAR);
          return {status: true, content: null};
        },
        errorResponse => {
          this.getToastService().clear(ToastComponentsRegistry.PROGRESS_BAR);
          this.getMessageGrowlService().error(errorResponse.error.error);

          return {status: false, content: errorResponse.error.error};
        }
      ));
  }

  private getMessageGrowlService(): MessageGrowlService {
    return this.injector.get(MessageGrowlService, null);
  }

  private getToastService(): ToastService {
    return this.injector.get(ToastService, null);
  }

}
