import {ChangeDetectionStrategy, ChangeDetectorRef, Component} from '@angular/core';
import {ToolbarItemAbstract} from '../toolbar-item-abstract.component';
import {AbstractGenericGridComponent} from '../../../../abstract-generic-grid.component';
import {SimpleSearchChangeMeta} from '../../../../generic-grid-simple-search/generic-grid-simple-search.component';
import {ChangeDetectorRefHelper} from '../../../../../../helpers/change-detector-ref.helper';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-toolbar-item-simple-search',
  templateUrl: './toolbar-item-simple-search.component.html'
})
export class ToolbarItemSimpleSearchComponent extends ToolbarItemAbstract {

  public isDialogVisible = true;
  public component: AbstractGenericGridComponent = null;

  public constructor(
    public cdr:ChangeDetectorRef
  ) {
    super();
  }

  public click() {
    const component = this.getComponent();

    if (component instanceof AbstractGenericGridComponent) {
      this.component = component;
      this.isDialogVisible = true;
    }
  }

  public onSearch(meta: SimpleSearchChangeMeta): void {
    for (const embedded of meta.embeddedFields) {
      if (!this.component.embeddedFields.includes(embedded)) {
        this.component.embeddedFields.push(embedded);
      }
    }

    this.component.simpleSearchFilters = [];
    for (const fieldMeta of meta.searchFields) {
      this.component.simpleSearchFilters.push({
        field: fieldMeta.fieldKey,
        value: fieldMeta.fieldValue
      });
    }

    this.component.loadEntities().subscribe();
    this.isDialogVisible = false;

    ChangeDetectorRefHelper.detectChanges(this);
  }
}
