/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "./collective-agreement-valid-to-column.component";
import * as i3 from "../../../../services/double-click.service";
import * as i4 from "../../../../../services/generic-crud.service";
var styles_CollectiveAgreementValidToColumnComponent = [];
var RenderType_CollectiveAgreementValidToColumnComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_CollectiveAgreementValidToColumnComponent, data: {} });
export { RenderType_CollectiveAgreementValidToColumnComponent as RenderType_CollectiveAgreementValidToColumnComponent };
export function View_CollectiveAgreementValidToColumnComponent_0(_l) { return i0.ɵvid(0, [i0.ɵpid(0, i1.DatePipe, [i0.LOCALE_ID]), (_l()(), i0.ɵeld(1, 0, null, null, 2, "span", [], null, null, null, null, null)), (_l()(), i0.ɵted(2, null, [" ", "\n"])), i0.ɵppd(3, 2)], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵunv(_v, 2, 0, _ck(_v, 3, 0, i0.ɵnov(_v, 0), _co.collectiveAgreementValidTo, "dd.MM.yyyy")); _ck(_v, 2, 0, currVal_0); }); }
export function View_CollectiveAgreementValidToColumnComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-collective-agreement-valid-to-column", [], null, null, null, View_CollectiveAgreementValidToColumnComponent_0, RenderType_CollectiveAgreementValidToColumnComponent)), i0.ɵdid(1, 114688, null, 0, i2.CollectiveAgreementValidToColumnComponent, [i0.ElementRef, i3.DoubleClickService, i4.GenericCrudService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var CollectiveAgreementValidToColumnComponentNgFactory = i0.ɵccf("app-collective-agreement-valid-to-column", i2.CollectiveAgreementValidToColumnComponent, View_CollectiveAgreementValidToColumnComponent_Host_0, { node: "node", entity: "entity", component: "component", column: "column" }, {}, []);
export { CollectiveAgreementValidToColumnComponentNgFactory as CollectiveAgreementValidToColumnComponentNgFactory };
