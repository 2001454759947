
import {forkJoin as observableForkJoin, of as observableOf, Observable} from 'rxjs';

import {catchError} from 'rxjs/operators';
import {AbstractExecutionStep} from '../../../../../core/executor/abstract-execution-step';
import {ExecutionStepStatus} from '../../../../../core/executor/execution-step-status';
import {ExpertSearch, ExpertSearchFieldsContainer} from '../../../../services/expert-search/expert-search';
import {EntityStatus} from '../../../../services/entity/entity-status';

export class DeleteExpertSearchContainersExecutionStep extends AbstractExecutionStep {

  public doExecute(): Observable<ExecutionStepStatus> {
    const expertSearch: ExpertSearch = this.getPayload().getValue().expertSearch;

    if (!expertSearch) {
      return this.getFailObservable('ExpertSearch not found!');
    }

    return this.doSave(expertSearch);
  }

  public doSave(expertSearch: ExpertSearch): Observable<ExecutionStepStatus> {
    const observables = [];

    for (const aContainer of expertSearch.containers) {
      if (aContainer.id && aContainer[EntityStatus.ENTITY_DRAFT_BACKEND_DELETE_FLAG]) {
        observables.push(
          this.genericCrudService
            .deleteEntity(`app/expertsearchcontainers/${aContainer.id}`)
        );
      }
    }

    if (observables.length === 0) {
      return observableOf({status: true, content: null});
    }

    return Observable.create((observer) => {
      observableForkJoin(observables).pipe(
        catchError((response: any) => {
          return observableOf(response);
        }))
        .subscribe((aContainers: ExpertSearchFieldsContainer[] = []) => {
          observer.next({status: true, content: null});
          observer.complete();
        });
    });
  }
}
