/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../directives/selenium.directive";
import * as i2 from "@angular/common";
import * as i3 from "./generic-split-button.component";
var styles_GenericSplitButtonComponent = [];
var RenderType_GenericSplitButtonComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_GenericSplitButtonComponent, data: {} });
export { RenderType_GenericSplitButtonComponent as RenderType_GenericSplitButtonComponent };
function View_GenericSplitButtonComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 0, "img", [], [[8, "src", 4]], null, null, null, null))], null, function (_ck, _v) { var currVal_0 = i0.ɵinlineInterpolate(1, "", _v.parent.context.$implicit.iconPath, ""); _ck(_v, 0, 0, currVal_0); }); }
function View_GenericSplitButtonComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 8, "ul", [["class", "ui-menu-list ui-helper-reset"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 7, "li", [["appSeleniumDirective", ""], ["class", "ui-menuitem ui-widget ui-corner-all"], ["role", "menuitem"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onItemClick(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵdid(2, 4210688, null, 0, i1.SeleniumDirective, [i0.ElementRef, i0.Renderer2], { toolbarItem: [0, "toolbarItem"] }, null), (_l()(), i0.ɵeld(3, 0, null, null, 5, "a", [["class", "ui-menuitem-link ui-corner-all"], ["icon", "fa"]], null, null, null, null, null)), (_l()(), i0.ɵeld(4, 0, null, null, 2, "span", [["class", "ui-menuitem-icon fa fa-fw"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_GenericSplitButtonComponent_3)), i0.ɵdid(6, 16384, null, 0, i2.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵeld(7, 0, null, null, 1, "span", [["class", "ui-menuitem-text"]], null, null, null, null, null)), (_l()(), i0.ɵted(8, null, ["", ""]))], function (_ck, _v) { var currVal_0 = _v.context.$implicit; _ck(_v, 2, 0, currVal_0); var currVal_1 = _v.context.$implicit.iconPath; _ck(_v, 6, 0, currVal_1); }, function (_ck, _v) { var currVal_2 = _v.context.$implicit.label; _ck(_v, 8, 0, currVal_2); }); }
function View_GenericSplitButtonComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "div", [["align", "left"], ["class", "ui-menu ui-menu-dynamic ui-widget ui-widget-content ui-corner-all ui-helper-clearfix ui-shadow"], ["style", "display:block; visibility: visible; top: 21px; left: -102px; opacity: 1.16; z-index: 1002;"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_GenericSplitButtonComponent_2)), i0.ɵdid(2, 278528, null, 0, i2.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.items; _ck(_v, 2, 0, currVal_0); }, null); }
export function View_GenericSplitButtonComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 6, "div", [["class", "ui-splitbutton ui-buttonset ui-widget"], ["style", "white-space: nowrap;"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 3, "button", [["class", "ui-splitbutton-menubutton ui-button ui-widget ui-state-default ui-corner-right ui-button-icon-only"], ["cornerstyleclass", "ui-corner-right"], ["icon", "fa-caret-down"], ["pbutton", ""], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.toogleDropDownVisibility() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 0, "span", [["class", "ui-button-icon-left ui-c fa fa-fw fa-caret-down"]], null, null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 1, "span", [["class", "ui-button-text ui-c"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["ui-button"])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_GenericSplitButtonComponent_1)), i0.ɵdid(6, 16384, null, 0, i2.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.isDropDownVisible; _ck(_v, 6, 0, currVal_0); }, null); }
export function View_GenericSplitButtonComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-generic-split-button", [], null, null, null, View_GenericSplitButtonComponent_0, RenderType_GenericSplitButtonComponent)), i0.ɵdid(1, 114688, null, 0, i3.GenericSplitButtonComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var GenericSplitButtonComponentNgFactory = i0.ɵccf("app-generic-split-button", i3.GenericSplitButtonComponent, View_GenericSplitButtonComponent_Host_0, { items: "items" }, {}, []);
export { GenericSplitButtonComponentNgFactory as GenericSplitButtonComponentNgFactory };
