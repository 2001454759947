import {Injectable} from '@angular/core';
import {ToolbarItemColoringInterface} from './toolbar-item-coloring-factory.service';
import {ToolbarItemAbstractColoringService} from './toolbar-item-abstract-coloring.service';

@Injectable()
export class ToolbarItemEditColoringService extends ToolbarItemAbstractColoringService implements ToolbarItemColoringInterface {

  getColor(): string | null {
    if (this.entity && this.entity.fqn === 'PhoenixBundle\\Entity\\Curriculum') {
      if (typeof this.entity.changeReason === 'undefined' || typeof this.entity.description === 'undefined') {
        return '#FFFF00';
      } else {
        return '#008000';
      }
    }

    return super.getColor();
  }
}
