/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../../../services/cancel-components-changes.service";
import * as i2 from "@ngx-translate/core";
import * as i3 from "primeng/components/common/confirmationservice";
import * as i4 from "../../../../../services/entity-data-store.service";
import * as i5 from "../../../../../../../core/executor/factory/execution-step-factory.service";
import * as i6 from "../../../../../services/modules-state.service";
import * as i7 from "../../../services/check/toolbar-item-check.service";
import * as i8 from "../../../../generic-dialog/service/generic-dialog-module.service";
import * as i9 from "../../../../../../../core/executor/executor.service";
import * as i10 from "../../../../../../../core/executor/service/executor-actions/executor-actions.service";
import * as i11 from "./toolbar-item-cancel.component";
var styles_ToolbarItemCancel = [];
var RenderType_ToolbarItemCancel = i0.ɵcrt({ encapsulation: 2, styles: styles_ToolbarItemCancel, data: {} });
export { RenderType_ToolbarItemCancel as RenderType_ToolbarItemCancel };
export function View_ToolbarItemCancel_0(_l) { return i0.ɵvid(2, [], null, null); }
export function View_ToolbarItemCancel_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "toolbar-item-cancel", [], null, null, null, View_ToolbarItemCancel_0, RenderType_ToolbarItemCancel)), i0.ɵprd(512, null, i1.CancelComponentChangesService, i1.CancelComponentChangesService, [i2.TranslateService, i3.ConfirmationService, i4.EntityDataStoreService, i5.ExecutionStepFactoryService, i6.ModulesStateService, i7.ToolbarItemCheckService, i8.GenericDialogModuleService]), i0.ɵprd(512, null, i9.ExecutorService, i9.ExecutorService, [i10.ExecutorActionsService]), i0.ɵdid(3, 245760, null, 0, i11.ToolbarItemCancel, [i6.ModulesStateService, i1.CancelComponentChangesService, i9.ExecutorService], null, null)], function (_ck, _v) { _ck(_v, 3, 0); }, null); }
var ToolbarItemCancelNgFactory = i0.ɵccf("toolbar-item-cancel", i11.ToolbarItemCancel, View_ToolbarItemCancel_Host_0, { params: "params" }, {}, []);
export { ToolbarItemCancelNgFactory as ToolbarItemCancelNgFactory };
