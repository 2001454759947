import { Component, EventEmitter, OnInit, OnDestroy, Input, Output } from '@angular/core';
import { Subscription } from 'rxjs';

/**
 * @description
 * @export
 * @class DialogDefaultComponent
 * @implements {OnInit}
 * @implements {OnDestroy}
 */
@Component({
  selector: 'app-dialog-default',
  templateUrl: './dialog-default.component.html',
  styleUrls: []
})
export class DialogDefaultComponent implements OnInit, OnDestroy {

  private _visible: boolean;

  @Input() get visible(): boolean {
    return this._visible;
  }

  @Output() visibleChange: EventEmitter<boolean> = new EventEmitter();
  set visible(val: boolean) {
    this._visible = val;
    this.visibleChange.emit(this._visible);
  }

  @Output() onShow: EventEmitter<boolean> = new EventEmitter();

  @Output() onHide: EventEmitter<boolean> = new EventEmitter();

  subscription: Subscription[] = [];

  constructor() { }

  ngOnInit() {
  }

  onDialogShow(event) {
    this.onShow.emit(true);
  }

  onDialogHide(event) {
    event.preventDefault();
    this.onHide.emit(true);
  }

  /**
   * @description On destroy we unsubscribe to subscriptions if there are any
   * @memberof DialogDefaultComponent
   */
  ngOnDestroy() {
    this.subscription.map(s => s.unsubscribe());
  }
}
