
import {from as observableFrom,  Observable } from 'rxjs';
import { Injectable, Inject } from '@angular/core';

import * as localForage from 'localforage';
import * as webcrypto from 'webcrypto';

import { AuthenticationService } from './../../core/authentication/authentication.service';

export interface LocalForageItem {
  key: string;
  value: any;
};

@Injectable()
export class LocalDataService {

  private instancePrefix: string;
  private instances: any[] = [];

  constructor(
    private authenticationService: AuthenticationService
  ) {
    // Create instance prefix from username
    let ldHash = 'HashIT';

    if (authenticationService.currentUser) {
      ldHash = authenticationService.currentUser.username;
    }

    webcrypto.createHash('sha512').update(ldHash).digest('hex');

    this.instances[this.getInstanceHash()] = localForage.createInstance({
      name: this.getInstanceHash()
    });
  }

  getInstanceHash(instanceName = 'default') {
    return webcrypto.createHash('sha512').update(this.instancePrefix + instanceName).digest('hex');
  }

  createNewInstance(instanceName: string): LocalDataService {
    // no name given
    if (!instanceName) {
      return this;
    }
    // instance already created
    if (this.instances[this.getInstanceHash(instanceName)]) {
      return this;
    }
    this.instances[this.getInstanceHash(instanceName)] = localForage.createInstance({
      name: this.getInstanceHash(instanceName)
    });

    return this;
  }

  getItem(key: string, instanceName = 'default'): Observable<any> {
    // let promise = this.localForage.getItem(key);
    const promise = this.instances[this.getInstanceHash(instanceName)].getItem(key);
    return observableFrom(promise);
  }

  setItem(item: LocalForageItem, instanceName = 'default'): Observable<any> {
    const promise = this.instances[this.getInstanceHash(instanceName)].setItem(item.key, item.value);
    return observableFrom(promise);
  }

  removeItem(key: string, instanceName = 'default'): Observable<any> {
    const promise = this.instances[this.getInstanceHash(instanceName)].removeItem(key);
    return observableFrom(promise);
  }

  clear(instanceName = 'default'): Observable<any> {
    const promise = this.instances[this.getInstanceHash(instanceName)].clear();
    return observableFrom(promise);
  }

  length(instanceName = 'default'): Observable<any> {
    const promise = this.instances[this.getInstanceHash(instanceName)].length();
    return observableFrom(promise);
  }

  key(index: number, instanceName = 'default'): Observable<any> {
    const promise = this.instances[this.getInstanceHash(instanceName)].key(index);
    return observableFrom(promise);
  }

  keys(instanceName = 'default'): Observable<any> {
    const promise = this.instances[this.getInstanceHash(instanceName)].keys();
    return observableFrom(promise);
  }
}
