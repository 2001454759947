import {ToolbarItemAdd} from '../add/toolbar-item-add.component';
import {ChangeDetectionStrategy, Component} from '@angular/core';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-toolbar-item-add-when-component-is-not-changed',
  templateUrl: '../add/toolbar-item-add.component.html'
})
export class ToolbarItemAddWhenComponentIsNotChangedComponent extends ToolbarItemAdd {

}
