import { Routes } from '@angular/router';
import { AuthenticationGuard } from './core/authentication/authentication.guard';
import { HomeComponent } from './core/home/home.component';
import { LoginComponent } from './core/login/login.component';
import { PageNotFoundComponent } from './core/page-not-found/page-not-found.component';
const ɵ0 = () => import("./super-admin/super-admin.module.ngfactory").then(m => m.SuperAdminModuleNgFactory), ɵ1 = () => import("./module-content/module-content.module.ngfactory").then(m => m.ModuleContentModuleNgFactory);
const routes = [
    { path: '', component: HomeComponent, canActivate: [AuthenticationGuard] },
    { path: 'login', component: LoginComponent },
    {
        path: 'superadmin',
        canActivate: [AuthenticationGuard],
        canActivateChild: [AuthenticationGuard],
        loadChildren: ɵ0
    },
    // /** Generic Content Rendering Routes */
    {
        path: ':product',
        canActivate: [AuthenticationGuard],
        canActivateChild: [AuthenticationGuard],
        loadChildren: ɵ1
    },
    { path: '**', component: PageNotFoundComponent }
];
export class AppRoutingModule {
}
export { ɵ0, ɵ1 };
