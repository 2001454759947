
import { Observable } from 'rxjs/Observable';
import {AbstractExecutionStep} from '../../../../core/executor/abstract-execution-step';
import {ExecutionStepStatus} from '../../../../core/executor/execution-step-status';
import {FormViewerComponent} from '../../../form-viewer/form-viewer.component';
import {forkJoin as observableForkJoin, of as observableOf} from 'rxjs';
import {Entity} from '../../../helpers/entity';
import {GenericDialogModuleService} from '../../../content-renderer/elements/generic-dialog/service/generic-dialog-module.service';
import {catchError} from 'rxjs/operators';

export class DmsFileOnUploadSuccessExecutionStep extends AbstractExecutionStep {

  public doExecute(): Observable<ExecutionStepStatus> {
    const payloadValue = this.getPayload().getValue(),
      component = payloadValue.component,
      entity = payloadValue.entity,
      files = payloadValue.event

    if (!(component instanceof FormViewerComponent)) {
      return this.getFailObservable('You need to pass FormViewer as Payload value!');
    }

    return this.doRefresh(component, entity, files);
  }

  private doRefresh(component: FormViewerComponent, entity: Record<string, unknown>, {files}): Observable<ExecutionStepStatus> {
    if (entity) {
      const memoTypeValue = Entity.getValue(entity, 'memoType');
      if (memoTypeValue) {
        const observables = [];
        const uploadFile = {
          memoType: memoTypeValue
        };
        for (const file of files) {
          observables.push(this.genericCrudService.editEntity('dms/dmsfiles/' + file.id, uploadFile));
        }

        if (observables.length === 0) {
          return observableOf({status: true, content: null});
        }
        return new Observable((observer) => {
          observableForkJoin(observables).pipe(
            catchError((response: any) => {
              return observableOf(response);
            }))
            .subscribe(() => {
              this.injector.get<GenericDialogModuleService>(GenericDialogModuleService).persistHide();
              observer.next({status: true, content: null});
              observer.complete();
            });
        })
      }
    }

    return observableOf({status: true, content: null });
  }
}
