import {Component, OnInit} from '@angular/core';
import {SelectItem} from 'primeng/api';
import {Datamodel} from '../../../../../../../services/datamodel/datamodel';
import {GenericCrudService} from '../../../../../../../services/generic-crud.service';
import {DropdownQuestionComponent} from '../dropdown/dropdown-question.component';
import {ExecutionStepPayload} from '../../../../../../../../core/executor/execution-step-payload';
import {ExecutorService} from '../../../../../../../../core/executor/executor.service';
import {ExecutionStepFactoryService} from '../../../../../../../../core/executor/factory/execution-step-factory.service';

@Component({
  selector: 'app-questionnaire-question-autocomplete',
  template: `
    <div class="ui-g">
        <div class="ui-g-4">
          {{label}}:
        </div>
        <div class="ui-g-8">
          <p-autoComplete 
            appendTo="body"
            [(ngModel)]="brand" 
            [suggestions]="options" 
            (completeMethod)="onSearch($event)" 
            [size]="30"
            [style]="{'width':'100%'}" 
            [minLength]="1" 
            placeholder="---" 
            [dropdown]="true"
            field="label"
          >
          </p-autoComplete>
        </div>
    </div>
  `,
  styles: [`
    :host {
      width: 100%;
    }
    
    p-autocomplete ::ng-deep .ui-autocomplete-input {
      width: calc(100% - 26px); // dropdown button
    }
  `],
  providers: [ ExecutorService ]
})
export class AutocompleteQuestionComponent extends DropdownQuestionComponent implements OnInit {

  public datamodel: Datamodel = null;
  public options: SelectItem[] = [];
  public selectedOption: SelectItem = null;
  public label = '';
  public brand: any;

  public constructor(
    protected genericCrudService: GenericCrudService,
    protected executorService: ExecutorService,
    protected stepFactory: ExecutionStepFactoryService
  ) {
    super(genericCrudService, executorService, stepFactory);
  }

  public ngOnInit(): void {
    super.ngOnInit();

    this.onComponentInit();
  }

  public onComponentInit(): void {
    const datamodelId = this.getParam('datamodelId');

    this.label = this.getParamValue('label');

    this.loadDatamodel()
      .subscribe((datamodel: Datamodel) => {
        this.loadOptions().subscribe();
      });
  }

  public onSearch(event): void {
    const query = event.query;

    this.loadOptions({
      clause: 'orWhere',
      search: query
    }).subscribe();
  }

  public onChange(event): void {
    const value = event.value;

    const onChange = this.getParamValue('onChangeAction');

    if (onChange !== null) {
      const step = this.stepFactory.createFromString(onChange, new ExecutionStepPayload({
        questionComponent: this,
        value: value
      }));

      this.executorService.setSteps([step]).execute().subscribe();
    }
  }
}
