import {AbstractGenericGridComponent} from '../../../elements/abstract-generic-grid.component';
import {EntityDirtyStoreService, StoreType} from '../../entity-dirty-store.service';
import {MessageGrowlService} from '../../../../../core/message/message-growl.service';
import {TranslateService} from '@ngx-translate/core';
import {LoggerService} from '../../logger/logger.service';
import {EntityDataStoreService} from '../../entity-data-store.service';
import {EntityStatus} from '../../../../services/entity/entity-status';
import {Observable} from "rxjs";
import {EntityManagerService} from '../../../../../core/service/entity-manager/entity-manager.service';
import {GenericFullCalendarComponent} from '../../../elements/generic-full-calendar/generic-full-calendar.component';

export abstract class AbstractGenericElementEntityService {

  protected component: AbstractGenericGridComponent | GenericFullCalendarComponent = null;

  public abstract addEntity(entity: any): void;

  public abstract getEntities(): Array<any>;

  public abstract addShelved(): void;

  public abstract removeEntity(entity: any): void;

  public abstract getCreatedEntities(shelved: boolean): any[];

  public abstract getUpdatedEntities(shelved: boolean): any[];

  public abstract getDraftDeletedEntities(shelved: boolean): any[];

  public abstract getEmbeddedEntitiesChanged(): any[];

  public abstract findEntity(entity: any): any;

  public abstract findEntityById(entityId: number): any;

  public constructor(protected entityDirtyStore: EntityDirtyStoreService,
                     protected entityDataStore: EntityDataStoreService,
                     protected messageGrowlService: MessageGrowlService,
                     protected translationService: TranslateService,
                     protected logger: LoggerService,
                     protected entityManager: EntityManagerService) { }

  public flagEntityForBulkSave(entity: any, currentValue?: any, oldValue?: any): Observable<boolean> {

    /*if (currentValue === oldValue) {
      return Observable.of(false);
    }*/

    return new Observable((obs) => {

      if (this.entityDataStore.isEntityDeleted(entity)) {
        this.messageGrowlService.error(
          this.translationService.instant('DIALOG_MESSAGES.IMPOSSIBLE_TO_EDIT_ENTITY_BODY'),
          this.translationService.instant('DIALOG_MESSAGES.NOT_ABLE_TO_CHANGE_HEADER')
        );

        obs.next(false);
        obs.complete();
      } else {
        this.entityDirtyStore.forceStore(entity);

        if (entity) {
          entity[EntityStatus.ENTITY_CHANGED_FLAG] = true;
        }

        obs.next(true);
        obs.complete();
      }
    });
  }

  public embedEntity(entity: any, entityToEmbed: any, collectionName: string) {
    const collectionEntity = this.findEntity(entity);

    if (collectionEntity) {
      if (!collectionEntity[collectionName]) {
        collectionEntity[collectionName] = [];
      }

      if (!this.inCollection(collectionEntity[collectionName], entityToEmbed)) {
        collectionEntity[collectionName].push(entityToEmbed);
        collectionEntity[EntityStatus.EMBEDDED_ENTITY_CHANGED_FLAG] = true;
      }

      this.entityManager.persist(collectionEntity, {property: collectionName, oldValue: null, newValue: collectionEntity[collectionName] });
    }
  }

  public clearShelvedChanges() {
    this.component.createdEntities = [];
    this.component.updatedEntities = [];
    this.component.draftDeletedEntities = [];
  }

  public hasChanges(checkEmbedded: boolean = false): boolean {
    const fqn = this.component.emptyEntity ? this.component.emptyEntity.fqn : null;

    return !this.entityDirtyStore.fqnClean(StoreType.Create, fqn)
      || !this.entityDirtyStore.fqnClean(StoreType.Update, fqn)
      || this.getUpdatedEntities(false).length > 0
      || this.getCreatedEntities(false).length > 0
      || this.getDraftDeletedEntities(false).length > 0
      || (checkEmbedded && this.getEmbeddedEntitiesChanged().length > 0);
  }

  public hasShelvedChanges(): boolean {
    return this.component.updatedEntities.length > 0 || this.component.createdEntities.length > 0;
  }

  public setComponent(component: AbstractGenericGridComponent | GenericFullCalendarComponent): AbstractGenericElementEntityService {
    this.component = component;
    return this;
  }

  public getComponent(): AbstractGenericGridComponent | GenericFullCalendarComponent | null {
    return this.component;
  }

  public refresh(): AbstractGenericElementEntityService {
    this.component.entities = [...this.component.entities];

    return this;
  }

  public replaceEntity(entity: any, forceReplace: boolean = false) {
    if (entity) {
      const index = this.getEntityIndex(entity);

      if (index !== -1) {
        if ((this.component.entities[index].id && entity.id) || !this.component.entities[index].id || forceReplace) {
          // This is the updated version - replace:
          this.component.entities[index] = entity;
        }
      } else {
        // Commented out but not sure if it's correct
        //this.component.entities.push(entity);
      }

      this.refresh();
    }
  }

  public mergeEntities() {
    if (this.component.getElementContext().isSlaveContext() && this.component.element
      && this.component.element.datamodel && this.component.element.datamodel.entityCollectionName
      && this.component.getElementContext().getMasterElementContext()) {
      const masterEntity = this.component.getElementContext().getMasterElementContext().component.getSelectedEntity();
      const entityCollectionName = this.component.element.datamodel.entityCollectionName;

      if (masterEntity && masterEntity[entityCollectionName] instanceof Array) {
        for (const entity of masterEntity[entityCollectionName]) {
          this.replaceEntity(entity);
        }
      }
    }
  }

  public getEntityIndex(entity: any): number {
    let index = -1;

    if (entity && entity.id) {
      index = this.component.entities.findIndex(aEntity => aEntity.id === entity.id);
    }

    if (index === -1 && entity && entity[EntityStatus.ENTITY_DRAFT_FLAG]) {
      index = this.component.entities
        .findIndex(aEntity =>
          aEntity[EntityStatus.ENTITY_DRAFT_FLAG] === entity[EntityStatus.ENTITY_DRAFT_FLAG]
        );
    }

    return index;
  }

  protected inCollection(collection: Array<any>, entity: any): boolean {
    for (const collectionEntity of collection) {
      if (collectionEntity[EntityStatus.ENTITY_DRAFT_FLAG] === entity[EntityStatus.ENTITY_DRAFT_FLAG]) {
        return true;
      }
    }

    return false;
  }
}
