import {Component, OnInit} from '@angular/core';
import {AbstractPreviewFileTypeComponent} from '../abstract-preview-file-type.component';
import {environment} from '../../../../../../../environments';
import {DomSanitizer, SafeResourceUrl} from '@angular/platform-browser';

@Component({
  selector: 'app-generic-file-preview-docx',
  templateUrl: './generic-file-preview-docx.component.html',
  styles: [
    `
    :host {
      height: 100%;
      width: 100%;
    }
    
    #iframe {
      height: 100%;
      width: 100%;
    }
    `
  ]
})
export class GenericFilePreviewDocxComponent extends AbstractPreviewFileTypeComponent implements OnInit {

  public url: SafeResourceUrl = null;
  public environment: Environment = environment;
  public isIFrameLoaded = false;

  private iFrameLoadInterval;

  public constructor(
    private sanitizer: DomSanitizer
  ) {
    super();
  }

  public ngOnInit(): void {
    this.iFrameLoadInterval = setInterval(
      this.updateIFrame.bind(this), 2000
    );
  }

  public onLoad(): void {
    this.isIFrameLoaded = true;
    clearInterval(this.iFrameLoadInterval);
  }

  public onError(): void {
    this.updateIFrame();
  }

  private updateIFrame(): void {
    const fileUrl = this.environment.baseUrl + '/' + this.fileData.filePath,
      url = `http://docs.google.com/gview?url=${fileUrl}&embedded=true`;

    this.url = this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }
}
