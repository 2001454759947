<div *ngIf="!isValid()" [pTooltip]="getInputTooltip()" [tooltipDisabled]="isValid()" tooltipPosition="top" style="position: absolute; top: 0; left: .5em; width: 5px; height: calc(100% - 1em); z-index: 9; top: .5em;"></div>

<div class="prefix-item" fxFlex="nogrow" *ngIf="element.inputFormat && element.inputFormat.prefix">{{element.inputFormat.prefix}}</div>
<div class="middle-item" [ngClass]="{
      'remove-input-border-radius-left': element.inputFormat.prefix,
      'remove-input-border-radius-right': element.inputFormat.suffix
    }">
  <ng-container [ngSwitch]="true" *ngIf="!element.textOnly">
    <app-form-element-input-text *ngSwitchCase="element.typeElement === 'text'" [moduleElement]="moduleElement" [entity]="entity" [form]="form" [formGroup]="formGroup" [element]="element"></app-form-element-input-text>
    <app-form-element-input-textarea *ngSwitchCase="element.typeElement === 'textarea'" [moduleElement]="moduleElement" [entity]="entity" [form]="form" [formGroup]="formGroup" [element]="element"></app-form-element-input-textarea>
    <app-form-element-input-password *ngSwitchCase="element.typeElement === 'password'" [moduleElement]="moduleElement" [entity]="entity" [form]="form" [formGroup]="formGroup" [element]="element"></app-form-element-input-password>
    <app-form-element-input-number *ngSwitchCase="element.typeElement === 'number'" [moduleElement]="moduleElement" [entity]="entity" [form]="form" [formGroup]="formGroup" [element]="element"></app-form-element-input-number>
    <app-form-element-input-checkbox *ngSwitchCase="element.typeElement === 'checkbox'" [moduleElement]="moduleElement" [entity]="entity" [form]="form" [formGroup]="formGroup" [element]="element"></app-form-element-input-checkbox>
    <app-form-element-input-radio *ngSwitchCase="element.typeElement === 'radio'" [moduleElement]="moduleElement" [entity]="entity" [form]="form" [formGroup]="formGroup" [element]="element"></app-form-element-input-radio>
    <app-form-element-input-switch *ngSwitchCase="element.typeElement === 'switch'" [moduleElement]="moduleElement" [entity]="entity" [form]="form" [formGroup]="formGroup" [element]="element"></app-form-element-input-switch>
    <app-form-element-input-date *ngSwitchCase="element.typeElement === 'date'" [moduleElement]="moduleElement" [entity]="entity" [form]="form" [formGroup]="formGroup" [element]="element"></app-form-element-input-date>
    <app-form-element-input-dropdown *ngSwitchCase="element.typeElement === 'dropdown'" [moduleElement]="moduleElement" [entity]="entity" [form]="form" [formGroup]="formGroup" [element]="element"></app-form-element-input-dropdown>
    <app-form-element-input-upload *ngSwitchCase="element.typeElement === 'upload'" [moduleElement]="moduleElement" [entity]="entity" [form]="form" [formGroup]="formGroup" [element]="element"></app-form-element-input-upload>
    <app-form-element-input-image-upload *ngSwitchCase="element.typeElement === 'image-upload'" [moduleElement]="moduleElement" [entity]="entity" [form]="form" [formGroup]="formGroup" [element]="element"></app-form-element-input-image-upload>
    <app-form-element-input-file-upload *ngSwitchCase="element.typeElement === 'file-upload'" [moduleElement]="moduleElement" [entity]="entity" [form]="form" [formGroup]="formGroup" [element]="element"></app-form-element-input-file-upload>
    <app-form-element-input-file-multi-upload *ngSwitchCase="element.typeElement === 'file-multi-upload'" [moduleElement]="moduleElement" [entity]="entity" [form]="form" [formGroup]="formGroup" [element]="element"></app-form-element-input-file-multi-upload>
    <app-form-element-input-autocomplete *ngSwitchCase="element.typeElement === 'autocomplete'" [moduleElement]="moduleElement" [entity]="entity" [form]="form" [formGroup]="formGroup" [element]="element"></app-form-element-input-autocomplete>
    <app-form-element-input-multi-autocomplete *ngSwitchCase="element.typeElement === 'multi-autocomplete'" [moduleElement]="moduleElement" [entity]="entity" [form]="form" [formGroup]="formGroup" [element]="element"></app-form-element-input-multi-autocomplete>
    <app-form-element-input-list-box *ngSwitchCase="element.typeElement === 'listBox'" [moduleElement]="moduleElement" [entity]="entity" [form]="form" [formGroup]="formGroup" [element]="element"></app-form-element-input-list-box>
    <app-form-element-input-region-chooser *ngSwitchCase="element.typeElement === 'region-chooser'" [moduleElement]="moduleElement" [entity]="entity" [form]="form" [formGroup]="formGroup" [element]="element"></app-form-element-input-region-chooser>
    <app-form-element-input-link *ngSwitchCase="element.typeElement === 'link'" [moduleElement]="moduleElement" [entity]="entity" [form]="form" [formGroup]="formGroup" [element]="element"></app-form-element-input-link>
    <app-form-element-input-algolia-places *ngSwitchCase="element.typeElement === 'algoliaPlaces'" [moduleElement]="moduleElement" [entity]="entity" [form]="form" [formGroup]="formGroup" [element]="element"></app-form-element-input-algolia-places>
    <app-form-element-input-geoapify-autocomplete *ngSwitchCase="element.typeElement === 'geoapifyAutocomplete'" [moduleElement]="moduleElement" [entity]="entity" [form]="form" [formGroup]="formGroup" [element]="element"></app-form-element-input-geoapify-autocomplete>
    <div *ngSwitchDefault="">Not implemented yet (element-input.component)... -{{element.typeElement}}-</div>
  </ng-container>
  <ng-container *ngIf="element.textOnly">
    <app-form-element-input-text-only [moduleElement]="moduleElement" [entity]="entity" [form]="form" [formGroup]="formGroup" [element]="element"></app-form-element-input-text-only>
  </ng-container>
</div>
<div class="suffix-item" fxFlex="nogrow" *ngIf="element.inputFormat && element.inputFormat.suffix">{{element.inputFormat.suffix}}</div>
