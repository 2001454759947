import {ChangeDetectionStrategy, Component} from '@angular/core';
import {ToolbarItemAbstract} from '../toolbar-item-abstract.component';
import {ExecutorService} from '../../../../../../../core/executor/executor.service';
import {CancelComponentChangesService} from 'app/shared/content-renderer/services/cancel-components-changes.service';
import {ModulesStateService} from '../../../../../services/modules-state.service';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'toolbar-item-cancel',
  templateUrl: './toolbar-item-cancel.component.html',
  providers: [ CancelComponentChangesService, ExecutorService ]
})
export class ToolbarItemCancel extends ToolbarItemAbstract {

  constructor(
    private modulesStateService: ModulesStateService,
    private cancelComponentChangesService: CancelComponentChangesService,
    private executorService: ExecutorService
  ) {
    super();
  }

  public click() {
    const context = this.figureOutContext(),
        components = this.getComponents(context);

    this.cancelComponentChangesService
        .setExecutorService(this.executorService)
        .cancel(components);
  }

  private getComponents(context: string) {
    let components = [];

    switch (context) {
      case ToolbarItemAbstract.CONTEXT_FORM_VIEWER:
        components = [this.getParam('formViewerComponent')];
        break;
      case ToolbarItemAbstract.CONTEXT_GRID:
        components = [this.getParam('gridComponent')];
        break;
      case ToolbarItemAbstract.CONTEXT_MODULE:
        components = this.modulesStateService.getByComponent(this.getComponent()).getAllComponents();
        break;
    }

    return components;
  }
}
