import {AbstractExecutionStep} from '../../../core/executor/abstract-execution-step';
import {Observable} from 'rxjs';
import {ExecutionStepStatus} from '../../../core/executor/execution-step-status';
import {EntityStatus} from '../entity/entity-status';
import {GenericElementEmbeddedService} from '../../content-renderer/services/generic/generic-element-embedded.service';

export class EntitySaveExecutionStep extends AbstractExecutionStep {

  public doExecute(): Observable<ExecutionStepStatus> {
    const payload = this.getPayload().getValue(),
      entity = payload.entity,
      component = payload.component;

    return this.doSave(component, entity);
  }

  private doSave(component: any, entity: any): Observable<ExecutionStepStatus> {
    let embeddedString = 'none';
    if (component !== undefined) {
      embeddedString = this.getElementEmbeddedService().getEmbeddedAsString(component);
    }
    return new Observable<ExecutionStepStatus>((observer) => {
      let observable: Observable<any>;
      if (entity.id) {
        observable = this.genericCrudService.customPut(this.constructUrl(entity), entity, {embedded: embeddedString});
      } else {
        observable = this.genericCrudService.customPost(this.constructUrl(entity), entity, {embedded: embeddedString});
      }

      observable.subscribe((savedEntity) => {
        observer.next({status: true, content: [savedEntity]});
        observer.complete();
      });
    });
  }

  private constructUrl(entity: any): string {
    let url = entity['_links']['self']['href'];

    url = url.replace('/api/', '');

    return url;
  }

  private getElementEmbeddedService(): GenericElementEmbeddedService|null {
    return this.injector.get(GenericElementEmbeddedService, null);
  }
}
