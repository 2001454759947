import {Injectable} from '@angular/core';
import {WorkHourCalculatorFactory} from './work-hour-calculator-factory';
import {EntityManagerService} from '../../../../../core/service/entity-manager/entity-manager.service';

@Injectable()
export class WorkHourCalculatorService {

  public calculateFields(entryType, industrialTime, entity): any {
    const calculator = WorkHourCalculatorFactory.getCalculator(entryType);
    calculator.isIndustrialTime = industrialTime;
    calculator.entity = entity;

    return calculator.calculateFields();
  }

  public calculateWorkEnd(entryType, industrialTime, entity, calculateBy: 'workLengthIndustry'|'workLength'): any {
    const calculator = WorkHourCalculatorFactory.getCalculator(entryType);
    calculator.isIndustrialTime = industrialTime;
    calculator.entity = entity;

    return calculator.calculateWorkEnd(calculateBy);
  }
}
