import { AbstractValidator } from './../abstract-validator';
import { Element } from '../../../form-viewer/models/element';

export class ChildrenElementsAreNotEmptyValidator extends AbstractValidator {

  public isValid(value: any): boolean {
    let isValid = true,
      childElementsObjectHashIds = this.getParamValue('childElementsObjectHashIds') || [],
      validWhenAllChildElementsAreEmpty = this.getParamValue('validWhenAllChildElementsAreEmpty');

    if (this.getField() instanceof Element && childElementsObjectHashIds instanceof Array) {
      isValid = !this.childInputElementsAreEmpty(childElementsObjectHashIds);
    }

    // now fallback, if all are empty, it's also valid
    if (validWhenAllChildElementsAreEmpty && !isValid) {
      isValid = this.allChildInputElementsAreEmpty();
    }

    return isValid;
  }

  public getErrorTranslateKey(value: any): string {
    return 'CHILDREN_ELEMENTS_ERROR';
  }

  public getErrorTranslateParams(value: any): any {
    return { 'elementName': this.getFieldName() };
  }

  private getAllChildInputElements(): Element[] {
    let elements = [],
      field = this.getField();

    const readElementsRecursive = (element: Element) => {

      if (element.type === 'input') {
        elements.push(element);
      }

      if (element.children && element.children instanceof Array) {
        element.children.map((element) => {
            readElementsRecursive(element);
        });
      }
    };

    if (field instanceof Element) {
      field.children.map((element) => {
          readElementsRecursive(element);
      });
    }

    return elements;
  }

  private getChildInputElements(objectHashIds: string[]): Element[] {
    let elements = [],
      field = this.getField();

    const readElementsRecursive = (element: Element) => {

      if (element.type === 'input' && objectHashIds.includes(element.objectHashId)) {
        elements.push(element);
      }

      if (element.children && element.children instanceof Array) {
        element.children.map((element) => {
            readElementsRecursive(element);
        });
      }
    };

    if (field instanceof Element) {
      field.children.map((element) => {
          readElementsRecursive(element);
      });
    }

    return elements;
  }

  private allChildInputElementsAreEmpty(): boolean {
    let areEmpty = true,
      childInputElements = this.getAllChildInputElements();

    for (let childInputElement of childInputElements) {
      let entityValue = this.getEntity()[childInputElement.datamodelField];

      if (entityValue) {
        areEmpty = (entityValue.constructor === Object) ? Object.keys(entityValue).length === 0 : false;
        break;
      }
    }

    return areEmpty;
  }

  private childInputElementsAreEmpty(objectHashIds: string[]): boolean {
    let areEmpty = false,
      childInputElements = this.getChildInputElements(objectHashIds);

    for (let element of childInputElements) {
        let entityValue = this.getEntity()[element.datamodelField];

        if (!entityValue || (entityValue.constructor === Object && Object.keys(entityValue).length === 0)) {
          areEmpty = true;
          break;
        }
    }

    return areEmpty;
  }
}
