import { of as observableOf, forkJoin as observableForkJoin, Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AbstractExecutionStep } from '../../../../core/executor/abstract-execution-step';
import { AbstractGenericGridComponent } from 'app/shared/content-renderer/elements/abstract-generic-grid.component';
import { InquiryPositionSalaryTypeParser } from './service/inquiry-position-salary-type-parser';
import { EntityStatus } from '../../entity/entity-status';
export class SaveInquiryDynamicTableStep extends AbstractExecutionStep {
    constructor() {
        super(...arguments);
        this.parser = new InquiryPositionSalaryTypeParser();
    }
    doExecute() {
        const component = this.getPayload().getValue();
        if (!(component instanceof AbstractGenericGridComponent)) {
            return this.getFailObservable('You need to pass AbstractGenericGridComponent as Payload value!');
        }
        return this.doSave(component);
    }
    doSave(component) {
        const matrix = component.entities, updatedMatrixItems = component.getUpdatedEntities();
        const toUpdateEntities = this.parser.getInquiryPositionSalaryTypesFromMatrix(updatedMatrixItems);
        return this.saveUpdated(toUpdateEntities);
    }
    saveUpdated(toUpdateEntities = []) {
        const observables = [];
        for (const updatedEntity of toUpdateEntities) {
            updatedEntity[EntityStatus.ENTITY_CHANGED_FLAG] = true;
            observables.push(this.genericCrudService.editEntity(`phoenix/inquirypositionssalarytypes/${updatedEntity.id}`, updatedEntity));
        }
        return Observable.create((observer) => {
            if (observables.length === 0) {
                observer.next({
                    status: true,
                    content: null,
                });
                observer.complete();
            }
            observableForkJoin(observables).pipe(catchError((response) => {
                return observableOf(response);
            }))
                .subscribe(results => {
                observer.next({
                    status: true,
                    content: results
                });
                observer.complete();
            });
        });
    }
}
