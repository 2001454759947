<!--<div class="button" *ngIf="tooltipText !== ''" pTooltip="{{tooltipText}}" tooltipPosition="top">
  <img *ngIf="imageSource" class="image" src="{{imageSource}}" alt="imageSource"/>
  <input type="checkbox" [checked]="isChecked" />
  <span class="text">{{title ? title : ''}}</span>
</div>

<div class="button" *ngIf="tooltipText === ''">
  <img *ngIf="imageSource" class="image" src="{{imageSource}}" alt="imageSource"/>
  <input type="checkbox" [checked]="isChecked" />
  <span class="text">{{title ? title : ''}}</span>
</div>-->

<!-- with bindings [] we dont need to ask for tooltipText -->
<div class="checkbox-button" [pTooltip]="tooltipText" tooltipPosition="top">
  <!-- show image if defined -->
  <img *ngIf="imageSource" class="{{!isChecked ? 'greyed-out' : ''}} image flex-item" [src]="imageSource" [alt]="imageSource" />

  <!-- otherwise show default checkbox -->
  <p-checkbox *ngIf="!imageSource" [label]="title" [(ngModel)]="isChecked" class="flex-item" binary="true"></p-checkbox>
</div>
