/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./salary-type-split.component";
import * as i2 from "../../../../../../services/generic-crud.service";
import * as i3 from "../../../../../../../core/message/message-growl.service";
import * as i4 from "@ngx-translate/core";
var styles_ToolbarItemSalaryTypeSplit = [];
var RenderType_ToolbarItemSalaryTypeSplit = i0.ɵcrt({ encapsulation: 2, styles: styles_ToolbarItemSalaryTypeSplit, data: {} });
export { RenderType_ToolbarItemSalaryTypeSplit as RenderType_ToolbarItemSalaryTypeSplit };
export function View_ToolbarItemSalaryTypeSplit_0(_l) { return i0.ɵvid(2, [], null, null); }
export function View_ToolbarItemSalaryTypeSplit_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "toolbar-item-salary-type-split", [], null, null, null, View_ToolbarItemSalaryTypeSplit_0, RenderType_ToolbarItemSalaryTypeSplit)), i0.ɵdid(1, 245760, null, 0, i1.ToolbarItemSalaryTypeSplit, [i2.GenericCrudService, i3.MessageGrowlService, i4.TranslateService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ToolbarItemSalaryTypeSplitNgFactory = i0.ɵccf("toolbar-item-salary-type-split", i1.ToolbarItemSalaryTypeSplit, View_ToolbarItemSalaryTypeSplit_Host_0, { params: "params" }, {}, []);
export { ToolbarItemSalaryTypeSplitNgFactory as ToolbarItemSalaryTypeSplitNgFactory };
