import {Component, OnInit} from '@angular/core';
import {AbstractAdminQuestionComponent} from './abstract-admin-question-component';
import {GenericCrudService} from '../../../../../shared/services/generic-crud.service';
import {SelectItem} from 'primeng/api';
import {Observable} from 'rxjs/Observable';
import {ExecutorRegistry} from '../../../../../core/executor/service/executor.registry';

@Component({
  selector: 'app-questionnaire-admin-question-checkbox',
  template: `
    <div class="ui-g">
        <p-tabView>
            <p-tabPanel header="Labels" leftIcon="pi pi-calendar">
                <div class="ui-g-4">
                  Label:
                </div>
                <div class="ui-g-8">
                  <input type="text" pInputText (keyup)="onChangeLabel($event, 'label')" [ngModel]="getParamValue('label')"/>
                </div>
                <div class="ui-g-4">
                  True Label:
                </div>
                <div class="ui-g-8">
                  <input type="text" pInputText (keyup)="onChangeLabel($event, 'trueLabel')" [ngModel]="getParamValue('trueLabel')"/>
                </div>
                <div class="ui-g-4">
                  False Label:
                </div>
                <div class="ui-g-8">
                  <input type="text" pInputText (keyup)="onChangeLabel($event, 'falseLabel')" [ngModel]="getParamValue('falseLabel')"/>
                </div>
            </p-tabPanel>
            <p-tabPanel header="Actions" leftIcon="pi pi-inbox">
                <div class="ui-g-4">
                  On Change:
                </div>
                <div class="ui-g-8">
                  <p-dropdown
                    appendTo="body" 
                    (onChange)="onChangeAction($event, 'onChangeAction')"
                    [style]="{'width':'100%'}" 
                    [options]="stepsOptions" 
                    placeholder="---" 
                    [filter]="true"
                    [ngModel]="getParamValue('onChangeAction')"
                  ></p-dropdown>
                </div>
            </p-tabPanel>
        </p-tabView>
    </div>
  `,
  styles: [`
    :host {
      width: 100%;
    }
  `]
})
export class AdminCheckboxQuestionComponent extends AbstractAdminQuestionComponent implements OnInit {

  public stepsOptions: SelectItem[] = [];

  public constructor(
    private genericCrudService: GenericCrudService
  ) {
    super();
  }

  public onComponentInit(): void {
    this.loadSteps()
      .subscribe((steps: any[] = []) => {
        this.stepsOptions = [{
          value: null,
          label: '---'
        }];

        for (const step of steps) {
          this.stepsOptions = [...this.stepsOptions, {
            value: step.name,
            label: step.name
          }];
        }
      });
  }

  public onChangeLabel(event, labelParamKey: string): void {
    const param = this.getOrCreateParam(labelParamKey);

    param.value = event.target.value;
  }

  public onChangeAction(event, paramName): void {
    const param = this.getOrCreateParam(paramName);

    param.value = event.value;
  }

  private loadSteps(): Observable<any[]> {
    const registry = new ExecutorRegistry();

    let actionTypes = [];

    for (const executorStep in registry.steps) {
      actionTypes = [...actionTypes, {
        name: executorStep
      }];
    }

    return Observable.of(actionTypes);
  }
}
