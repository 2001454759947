import {ChangeDetectionStrategy, Component, Input, OnInit} from '@angular/core';
import { ToolbarItemAbstract } from '../toolbar-item-abstract.component';
import { ToolbarItemFormEntityService } from '../../services/toolbar-item-form-entity.service';
import { FormViewerComponent } from '../../../../../../form-viewer/form-viewer.component';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'toolbar-item-form-new',
  templateUrl: './toolbar-item-form.component.html',
  providers: [
    ToolbarItemFormEntityService
  ]
})
export class ToolbarItemFormNew extends ToolbarItemAbstract {

  emptyEntity: any = {};

  constructor(
    private toolbarItemFormEntityService: ToolbarItemFormEntityService
  ) {
    super();
  }

  public click() {

    if (this.getParam('formViewerComponent') && this.getParam('formViewerComponent').entity) {
      this.handleClick(this.getParam('formViewerComponent'));
    }
  }

  protected handleClick(formViewer: FormViewerComponent) {
    const entity = formViewer.entity,
      newEntity = {
        '_links': {
          'self': {
            'href': entity.href
          }
        }
      };

    Object.keys(entity).forEach(function(key, index) {

      const type = typeof entity[key];

      let value;

      if (type != 'object') {
        switch (type) {
          case 'number':
            value = 0;
            break;
          case 'string':
            value = 'Platzhalter';
            break;
          case 'boolean':
            value = false;
            break;
          default:
            value = 'Platzhalter';
        }

        newEntity[key] = value;
      }
    });

    if (entity.id) {
      delete newEntity['id']

      newEntity._links.self.href = this.toolbarItemFormEntityService.getEntityApiRoute(entity);
    }

    formViewer.getEntityDataStore().onEntityChanged({
      entity: newEntity
    });
  }
}
