
import {of as observableOf,  Observable } from 'rxjs';
import { AbstractExecutionStep } from '../../../../../core/executor/abstract-execution-step';
import { ExecutionStepStatus } from '../../../../../core/executor/execution-step-status';
import { GenericElementAbstract } from '../../../../content-renderer/elements/generic-element-abstract.component';
import { MasterEntityConfig } from '../../../../content-renderer/services/ElementContext';
import { AbstractGenericGridComponent } from '../../../../content-renderer/elements/abstract-generic-grid.component';
import { Constants } from '../../../../../constants';
import {EntityManagerService} from '../../../../../core/service/entity-manager/entity-manager.service';

export abstract class AbstractGenerateWorkplaceEntitiesStep extends AbstractExecutionStep {

    public doExecute(): Observable<ExecutionStepStatus> {
        const payload = this.getPayload();

        if (!(payload.getValue() instanceof AbstractGenericGridComponent)) {
            return this.getFailObservable('You need to pass GenericElementAbstract as Payload value!');
        }

        return this.doGenerate(payload.getValue());
    }

    protected doGenerate(component: AbstractGenericGridComponent): Observable<ExecutionStepStatus> {
        const customerId = this.getMasterConfigValue(component, 'customer'),
            workplaceId = this.getMasterConfigValue(component, 'workplace');

        if (!customerId) {
            return this.getFailObservable('Customer ID not found');
        }

        if (workplaceId !== null) {
            return this.getFailObservable('Master entity is not new');
        }

        const entityManager = this.injector.get(EntityManagerService, null);

        this.genericCrudService.createEntity(`${Constants.APP_API_ROUTE}/entities/generate`, {
            datamodelId: component.element.datamodel.id,
            selectCriteria: {
                customer: customerId
            }
        }).subscribe((entities: any[] = []) => {

            for (const entity of entities) {
                entityManager.persist(entity, {entire: true});

                component.addCreatedEntityDraft(entity);
            }

            component.recheckToolbarItems();
        });

        return observableOf({status: true, content: []});
    }

    private getMasterConfigValue(component: GenericElementAbstract, configName: string): string|null {
        let masterConfigValue = null;

        if (component && component.getElementContext() && component.getElementContext().getMasterEntities()) {
            const masterConfig = component.getElementContext().getMasterEntity(configName);

            if (masterConfig !== null) {
                masterConfigValue = masterConfig.value;
            }
        }

        return masterConfigValue;
    }
}
