import {Directive, ElementRef, AfterViewInit, Self, Renderer, HostListener, Input, Output, EventEmitter} from '@angular/core';
import {AutoComplete} from 'primeng/primeng';

@Directive({
  selector: '[appAutocomplete]'
})
export class AutocompleteDirective implements AfterViewInit {

  private autoComplete: AutoComplete = null;

  @Output() public onDropdownClickNoQuery: EventEmitter<any> = new EventEmitter();
  @Input() searchOnFocus = false;
  @Input() inputTabIndex = -1;

  @HostListener('keydown', ['$event'])
  onResize(event) {
    if (event.code === 'ArrowDown') {
      if (this.autoComplete.overlayVisible === false && this.autoComplete.dropdownButton) {
        this.autoComplete.dropdownButton.nativeElement.click();
      }
    }
  }

  public constructor(
    @Self() autoComplete: AutoComplete,
    private readonly elementRef: ElementRef,
    private readonly renderer: Renderer
  ) {

    if (autoComplete) {
      this.autoComplete = autoComplete;

      this.autoComplete.onFocus.subscribe(() => {
        this.handleOnFocus();
      });

      this.autoComplete.onDropdownClick.subscribe((event) => {
        event.query = '';
        this.onDropdownClickNoQuery.emit(event);
      });
    }
  }

  public ngAfterViewInit() {
    const dropdownButton = this.elementRef.nativeElement.querySelector('.ui-autocomplete-dropdown');
    const input = this.elementRef.nativeElement.querySelector('.ui-inputtext')

    if (dropdownButton) {
      this.renderer.setElementAttribute(dropdownButton, 'tabIndex', '-1');
    }

    if (input && this.inputTabIndex) {
      this.renderer.setElementAttribute(input, 'tabIndex', this.inputTabIndex.toString());
    }
  }

  private handleOnFocus(): void {
    if (this.searchOnFocus) {
      this.autoComplete.dropdownButton.nativeElement.click();
    }
  }
}
