/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./toolbar-item-save-pre-calculation-article.component";
import * as i2 from "../../../../../../services/generic-crud.service";
import * as i3 from "../../../../../../../core/message/message-growl.service";
import * as i4 from "@ngx-translate/core";
import * as i5 from "../../../services/save/toolbar-item-save-precalculation-article.service";
import * as i6 from "../../../../../services/entity-dirty-store.service";
import * as i7 from "../../../../../../../core/executor/factory/execution-step-factory.service";
import * as i8 from "../../../../../../../core/executor/executor.service";
import * as i9 from "../../../../../services/modules-state.service";
import * as i10 from "../../../../../../../core/service/toast.service";
var styles_ToolbarItemSavePreCalculationArticleComponent = [];
var RenderType_ToolbarItemSavePreCalculationArticleComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_ToolbarItemSavePreCalculationArticleComponent, data: {} });
export { RenderType_ToolbarItemSavePreCalculationArticleComponent as RenderType_ToolbarItemSavePreCalculationArticleComponent };
export function View_ToolbarItemSavePreCalculationArticleComponent_0(_l) { return i0.ɵvid(2, [], null, null); }
export function View_ToolbarItemSavePreCalculationArticleComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-toolbar-item-add-pre-calculation-article", [], null, null, null, View_ToolbarItemSavePreCalculationArticleComponent_0, RenderType_ToolbarItemSavePreCalculationArticleComponent)), i0.ɵdid(1, 245760, null, 0, i1.ToolbarItemSavePreCalculationArticleComponent, [i2.GenericCrudService, i3.MessageGrowlService, i4.TranslateService, i5.ToolbarItemSavePrecalculationArticleService, i6.EntityDirtyStoreService, i7.ExecutionStepFactoryService, i8.ExecutorService, i9.ModulesStateService, i10.ToastService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ToolbarItemSavePreCalculationArticleComponentNgFactory = i0.ɵccf("app-toolbar-item-add-pre-calculation-article", i1.ToolbarItemSavePreCalculationArticleComponent, View_ToolbarItemSavePreCalculationArticleComponent_Host_0, { params: "params" }, {}, []);
export { ToolbarItemSavePreCalculationArticleComponentNgFactory as ToolbarItemSavePreCalculationArticleComponentNgFactory };
