
import {catchError} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';

import {AppHttpService} from '../../app-http.service';
import { Constants } from 'app/constants';
import {HttpClient} from '@angular/common/http';

@Injectable()
export class NotificationService extends AppHttpService {

    private notificationUrl: string = `/${Constants.APP_API_ROUTE}/notification`;
    private url: string = '';

    constructor(private http: HttpClient) {
        super();
        this.url = this.getApiUrl() + this.notificationUrl;
    }

    /**
     *
     * @param entity
     * @returns {Observable<any>}
     */
    sendSms(entity: any): Observable<any> {
        const postData = {
            fqn: entity.fqn,
            entityId: entity.id,
            notificationType: 'sms'
        };

        return this.http.post(`${this.url}`, JSON.stringify(postData)).pipe(
            catchError(this.handleError));
    }
}
