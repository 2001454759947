import { AbstractFormActionHandler } from './abstract-form-action-handler';
import { Injectable } from '@angular/core';
import { Datamodel } from '../../../services/datamodel/datamodel';
import { DatamodelCrudService } from '../../../services/datamodel/datamodel.crud.service';
import { FormViewerService } from '../../form-viewer.service';
import {ChangeDetectorRefHelper} from '../../../helpers/change-detector-ref.helper';
import {Entity} from '../../../helpers/entity';
import {FormService} from '../../form.service';

@Injectable()
export class IgnoreValidationOnLeasedEmployeeTopFrameActionHandler extends AbstractFormActionHandler {

    public constructor(
        private datamodelCrudService: DatamodelCrudService,
        private formViewerService: FormViewerService
    ) {
        super();
    }

    public handleAction(): void {
        let action = this.getAction(),
            entity = this.getEntity(),
          employeeState = Entity.getValue(entity, 'employeeState');
        this.getComponent().moduleElement.ignoreValidations = employeeState && employeeState.code === 'AMS';
    }
}
