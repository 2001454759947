import {DynamicDialogConfig, DynamicDialogRef} from 'primeng/api';
import {Component} from '@angular/core';
import {random} from 'lodash';

@Component({
  selector: 'app-dialog-error',
  templateUrl: './dialog-error.component.html',
})
export class DialogErrorComponent {

  public parameters = {};
  public errorOptions: SelectItem [] = [];

  constructor(
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig
  ) { }

  ngOnInit() {
    for (const dialogOption of this.config.data.errorOptions) {
      this.errorOptions.push({label: dialogOption, value: random()})
    }
  }

  onSave() {
    this.ref.close(this.parameters);
  }

}
