
import {forkJoin as observableForkJoin, Observable} from 'rxjs';
import {Component, ElementRef, Renderer2, ViewContainerRef, OnInit, ChangeDetectorRef} from '@angular/core';
import {ExecutorService} from '../../../../../core/executor/executor.service';
import {GenericElementValidationExecutionStepsFactory} from '../../../services/generic/generic-element-validation-execution-steps-factory';
import {GenericElementFilterService} from '../../../services/generic/filter/generic-element-filter.service';
import {GenericTreeGridComponent} from '../../generic-tree-grid/generic-tree-grid.component';
import {CancelComponentChangesService} from '../../../services/cancel-components-changes.service';
import {GenericGridColumnBuilderService} from '../../generic-grid/services/generic-grid-column-builder.service';
import {GenericGridGlobalFilterService} from '../../generic-grid/services/generic-grid-global-filter.service';
import {GenericGridRemoteFilterService} from '../../generic-grid/services/generic-grid-remote-filter.service';
import {GenericTreeLayoutService} from '../../generic-tree-grid/service/generic-tree-layout-service';
import {GenericElementInlineEditorService} from '../../../services/generic/generic-element-inline-editor.service';
import {GenericTreeEntityService} from '../../../services/generic/entity/generic-tree-entity.service';
import {GenericGridBulkSaveService} from '../../generic-grid/services/generic-grid-bulk-save.service';
import {Constants} from '../../../../../constants';
import {ElementSaveStatus} from '../../generic-element-abstract.component';
import {ConditionTreeGridBulkSaveService} from './services/condition-tree-grid-bulk-save-service';
import {ComponentService} from '../../../services/component-highlight-stack.service';
import {GenericCrudService} from '../../../../services/generic-crud.service';
import {EntityDataChangeMeta, EntityDataStoreService} from '../../../services/entity-data-store.service';
import {ModulesStateService} from '../../../services/modules-state.service';
import {PermissionService} from '../../../../services/permission/permission.service';
import {EventHandlerService} from '../../../../../core/events/event/event-handler.service';
import {DoubleClickService} from '../../../services/double-click.service';
import {LocationService} from '../../../../services/location.service';
import {ElementsStackService} from '../../../services/elements-stack.service';
import {EntityFactoryService} from '../../../../services/entity-factory.service';
import {LayoutService} from '../../../../services/layout-service';
import {HttpErrorResponseService} from '../../../../services/http-error-response-message.service';
import {ElementsStateService} from '../../../services/elements-state.service';
import {ToolbarItemCheckService} from '../../generic-toolbar/services/check/toolbar-item-check.service';
import {EntityHydrator} from '../../../../services/entity-hydrator.service';
import {EntityDirtyStoreService} from '../../../services/entity-dirty-store.service';
import {MessageGrowlService} from '../../../../../core/message/message-growl.service';
import {TranslateService} from '@ngx-translate/core';
import {LoggerService} from '../../../services/logger/logger.service';
import {RequestCachingService} from '../../../../services/request-caching.service';
import {ConfirmationService} from 'primeng/api';
import {EntityValidator} from '../../../../validators/services/entity-validator';
import {FieldActionsService} from '../../../services/field-actions.service';
import {LocalStorageDataService} from '../../../../services/local-storage-data.service';
import {ExecutorActionsService} from '../../../../../core/executor/service/executor-actions/executor-actions.service';
import {JobContainerService} from '../../../../../core/job-runner/job-container.service';
import {ExecutionStepBuilderService} from '../../../../../core/executor/builder/execution-step-builder.service';
import {GenericDialogModuleService} from '../../generic-dialog/service/generic-dialog-module.service';
import {EntityStatus} from '../../../../services/entity/entity-status';
import {ExecutionStatus} from '../../../../../core/executor/execution-status';
import {ExecutorActionEvent} from '../../../../../core/executor/service/executor-actions/executor-action-event';
import {Entity} from '../../../../helpers/entity';
import {ConditionTreeService} from './services/condition-tree.service';
import {Guid} from 'guid-typescript';
import {GenericElementEmbeddedService} from '../../../services/generic/generic-element-embedded.service';
import {GenericElementContextMenuService} from '../../../services/generic/generic-element-context-menu.service';
import {EntityManagerService} from '../../../../../core/service/entity-manager/entity-manager.service';
import {UserSessionService} from '../../../../../core/service/user-session.service';

@Component({
  selector: 'app-custom-condition-tree-grid',
  styleUrls: ['./../../generic-tree-grid/generic-tree-grid.component.scss'],
  templateUrl: './../../generic-tree-grid/generic-tree-grid.component.html',
  providers: [
    CancelComponentChangesService,
    GenericGridColumnBuilderService,
    GenericGridGlobalFilterService,
    GenericGridRemoteFilterService,
    GenericTreeLayoutService,
    GenericElementInlineEditorService,
    GenericElementFilterService,
    GenericTreeEntityService,
    GenericGridBulkSaveService,
    GenericElementValidationExecutionStepsFactory,
    ExecutorService,
    ConditionTreeGridBulkSaveService
  ]
})
export class ConditionTreeGridComponent extends GenericTreeGridComponent implements OnInit {

  public responseFormat = 'condition';

  constructor(
    protected componentService: ComponentService,
    protected viewContainerRef: ViewContainerRef,
    protected genericCrudService: GenericCrudService,
    protected entityDataStore: EntityDataStoreService,
    protected modulesStateService: ModulesStateService,
    protected permissionService: PermissionService,
    protected eventHandlerService: EventHandlerService,
    protected genericGridColumnBuilderService: GenericGridColumnBuilderService,
    protected genericGridGlobalFilterService: GenericGridGlobalFilterService,
    protected genericGridRemoteFilterService: GenericGridRemoteFilterService,
    protected doubleClickService: DoubleClickService,
    protected locationService: LocationService,
    protected elementsStackService: ElementsStackService,
    protected layoutService: LayoutService,
    protected httpErrorResponseService: HttpErrorResponseService,
    protected elementsStateService: ElementsStateService,
    protected entityFactoryService: EntityFactoryService,
    protected toolbarItemCheckService: ToolbarItemCheckService,
    protected elementRef: ElementRef,
    protected renderer: Renderer2,
    protected genericTreeLayoutService: GenericTreeLayoutService,
    protected genericElementInlineEditorService: GenericElementInlineEditorService,
    protected genericElementFilterService: GenericElementFilterService,
    protected entityHydrator: EntityHydrator,
    protected entityDirtyStore: EntityDirtyStoreService,
    protected messageGrowlService: MessageGrowlService,
    protected translationService: TranslateService,
    protected logger: LoggerService,
    protected genericElementEntityService: GenericTreeEntityService,
    protected genericGridBulkSaveService: ConditionTreeGridBulkSaveService,
    protected requestCachingService: RequestCachingService,
    protected confirmationService: ConfirmationService,
    protected cancelComponentChangesService: CancelComponentChangesService,
    protected executorService: ExecutorService,
    protected genericElementValidationExecutionStepsFactory: GenericElementValidationExecutionStepsFactory,
    protected entityValidator: EntityValidator,
    protected fieldActionsService: FieldActionsService,
    protected userSession: UserSessionService,
    protected executorActionsService: ExecutorActionsService,
    protected jobContainerService: JobContainerService,
    protected executionStepBuilderService: ExecutionStepBuilderService,
    protected genericDialogModuleService: GenericDialogModuleService,
    protected embedded: GenericElementEmbeddedService,
    protected contextMenu: GenericElementContextMenuService,
    protected entityManager: EntityManagerService,
    public cdr: ChangeDetectorRef
  ) {
    super(
      componentService,
      viewContainerRef,
      genericCrudService,
      entityDataStore,
      modulesStateService,
      permissionService,
      eventHandlerService,
      genericGridColumnBuilderService,
      genericGridGlobalFilterService,
      genericGridRemoteFilterService,
      doubleClickService,
      locationService,
      elementsStackService,
      entityFactoryService,
      layoutService,
      httpErrorResponseService,
      elementsStateService,
      toolbarItemCheckService,
      elementRef,
      renderer,
      genericTreeLayoutService,
      genericElementInlineEditorService,
      genericElementFilterService,
      entityHydrator,
      entityDirtyStore,
      messageGrowlService,
      translationService,
      logger,
      genericElementEntityService,
      genericGridBulkSaveService,
      requestCachingService,
      confirmationService,
      cancelComponentChangesService,
      executorService,
      genericElementValidationExecutionStepsFactory,
      entityValidator,
      fieldActionsService,
      userSession,
      executorActionsService,
      jobContainerService,
      executionStepBuilderService,
      genericDialogModuleService,
      embedded,
      contextMenu,
      entityManager,
      cdr
    );
  }

  public ngOnInit() {
    super.ngOnInit();

    this.entityDataStore.entityValueChanged$.subscribe((entityDataChangeMeta: EntityDataChangeMeta) => {
      const isOrCondition = this.getElementDatamodelEntityName()  + 'OrCondition' === entityDataChangeMeta.entity['fqn'];

      if (isOrCondition &&
        (!this.selectedEntity || entityDataChangeMeta.entity[EntityStatus.ENTITY_DRAFT_FLAG]
          === this.selectedEntity[EntityStatus.ENTITY_DRAFT_FLAG])) {

        this.onChange(entityDataChangeMeta).subscribe((entity) => {
          this.onValidate().subscribe((status: ExecutionStatus) => {
            this.executeAction(ExecutorActionEvent.EntityValidated, {
              component: this,
              entityValidationStatus: status
            }).subscribe();
          });
        });
      }
    });
  }

  public onSave(): Observable<ElementSaveStatus> {
    return this.genericGridBulkSaveService.firstSetGrid(this).thenCommitChanges().finallyValidateAndPush();
  }

  protected reloadChangedEntities(): Observable<any> {
    return new Observable<any>((observer) => {
      const observables = [];

      for (const createdEntity of this.getCreatedEntities(true)) {
        const apiRoute = ConditionTreeService.getApiRoute(createdEntity, this.getElementDataModelApiRoute());

        observables.push(
          this.getGenericCrudService()
            .getEntityBy(
              apiRoute,
              EntityStatus.ENTITY_DRAFT_FLAG,
              createdEntity[EntityStatus.ENTITY_DRAFT_FLAG]
            )
        );
      }

      for (const updatedEntity of this.getUpdatedEntities(true)) {
        const apiRoute = ConditionTreeService.getApiRoute(updatedEntity, this.getElementDataModelApiRoute());

        observables.push(
          this.getGenericCrudService()
            .getEntity(
              apiRoute,
              updatedEntity.id
            )
        );
      }

      for (const embeddedUpdatedEntity of this.getEmbeddedEntitiesChanged()) {
        const apiRoute = ConditionTreeService.getApiRoute(embeddedUpdatedEntity, this.getElementDataModelApiRoute());

        observables.push(
          this.getGenericCrudService()
            .getEntity(
              apiRoute,
              embeddedUpdatedEntity.id
            )
        );
      }

      for (const draftDeletedEntity of this.getDraftDeletedEntities(true)) {
        delete draftDeletedEntity[EntityStatus.ENTITY_DRAFT_DELETED_FLAG];
        this.removeEntity(draftDeletedEntity);
      }

      if (observables.length === 0) {
        observer.next();
        observer.complete();
      }

      observableForkJoin(observables).subscribe((results) => {
        for (const resultingEntity of results) {
          this.replaceEntity(resultingEntity, true);
        }
        observer.next();
        observer.complete();
      });
    });
  }

  public changeGridEntities(data: TreeNode[], count: number) {
    super.changeGridEntities(data, count);

    const entities = this.getEntities();

    for (let entity of entities) {
      entity = Entity.extractEmbedded(entity);
    }
  }

  public finishNewEntityAdd(newEntity: any) {
    const masterParent = this.getParentFromMasterEntity(),
      parent = this.getSelectedEntity();

    super.finishNewEntityAdd(newEntity);

    const entity = this.getSelectedEntity();

    if (this.validateNewConditionAdd(entity, parent)) {
      ConditionTreeService.buildCondition(entity, parent, masterParent);
    }
  }

  public getRemoteDynamicFilters(): Object {
    const remoteFilters = super.getRemoteDynamicFilters();

    const showWithoutMasterFilter = this.genericGridGlobalFilterService
      .setGrid(this)
      .getGlobalFilterValue(Constants.SHOW_WITHOUT_MASTER_FILTER_FIELD_FILTER_KEY, null, this.getElementDataModelApiRoute()) || false;

    for (const masterElementConfig of this.getElementContext().getMasterEntities()) {
      // skip master filter field
      if (showWithoutMasterFilter && masterElementConfig.filterType === 'masterEntity' &&
        this.moduleElement.masterFilterField &&
        masterElementConfig.name === this.moduleElement.masterFilterField
      ) {
        continue;
      }

      remoteFilters[masterElementConfig.name] = masterElementConfig.value;
    }

    return remoteFilters;
  }



  private validateNewConditionAdd(entity: any, parent: any): boolean {
    let isValid = true;

    if (parent && ConditionTreeService.isOrConditionBasedOnParent(parent)) {
      this.removeEntity(entity);

      this.messageGrowlService.error(
        this.translationService.instant('COLLECTIVE_AGREEMENT.CONDITION_ADD.OR_CONDITIONS_CANNOT_HAVE_CONDITIONS'),
        this.translationService.instant('COMMON.ERROR')
      );

      isValid = false;
    }

    if (ConditionTreeService.isCondition(parent) && !parent.id) {
      this.removeEntity(entity);

      this.messageGrowlService.error(
        this.translationService.instant('COLLECTIVE_AGREEMENT.CONDITION_ADD.SAVE_PARENT_FIRST'),
        this.translationService.instant('COMMON.ERROR')
      );

      isValid = false;
    }

    return isValid;
  }

  private getParentFromMasterEntity(): any {
    let parent = null;

    for (const masterElementConfig of this.getElementContext().getMasterEntities()) {
      if (masterElementConfig.name === 'parent') {
        parent = masterElementConfig.value;
        break;
      }
    }

    return parent;
  }
}
