import {ChangeDetectorRef, Component, Input} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { MessageGrowlService } from '../../../core/message/message-growl.service';
import {AbstractGenericGridComponent} from '../../content-renderer/elements/abstract-generic-grid.component';
import {ChangeDetectorRefHelper} from '../../helpers/change-detector-ref.helper';

@Component({
  selector: 'app-ksv-company-search',
  templateUrl: './ksv-company-search.component.html'
})
export class KsvCompanySearchComponent {

  @Input() public gridComponent: AbstractGenericGridComponent = null;

  public company: any = null;
  public product: any = null;
  public context = 'company-search-grid';

  public constructor(
    protected translate: TranslateService,
    protected messageGrowlService: MessageGrowlService,
    public cdr: ChangeDetectorRef
  ) {

  }

  public onCompanyChanged(company: any): void {
    this.company = company;
    this.context = 'company-product-grid';
  }

  public onProductChanged(data: {company: any, product: any}) {
    this.company = data.company;
    this.product = data.product;

    this.context = 'company-product-details';
  }

  public onGoBack(): void {
    if (this.context === 'company-product-grid') {
      this.context = 'company-search-grid';
    }

    if (this.context === 'company-product-details') {
      this.context = 'company-product-grid';
    }

    ChangeDetectorRefHelper.detectChanges(this);
  }
}
