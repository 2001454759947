/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./toolbar-item-download-bulk-docuware.component";
var styles_ToolbarItemDownloadBulkDocuwareComponent = [];
var RenderType_ToolbarItemDownloadBulkDocuwareComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_ToolbarItemDownloadBulkDocuwareComponent, data: {} });
export { RenderType_ToolbarItemDownloadBulkDocuwareComponent as RenderType_ToolbarItemDownloadBulkDocuwareComponent };
export function View_ToolbarItemDownloadBulkDocuwareComponent_0(_l) { return i0.ɵvid(2, [], null, null); }
export function View_ToolbarItemDownloadBulkDocuwareComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "toolbar-item-download-bulk-docuware", [], null, null, null, View_ToolbarItemDownloadBulkDocuwareComponent_0, RenderType_ToolbarItemDownloadBulkDocuwareComponent)), i0.ɵdid(1, 245760, null, 0, i1.ToolbarItemDownloadBulkDocuwareComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ToolbarItemDownloadBulkDocuwareComponentNgFactory = i0.ɵccf("toolbar-item-download-bulk-docuware", i1.ToolbarItemDownloadBulkDocuwareComponent, View_ToolbarItemDownloadBulkDocuwareComponent_Host_0, { params: "params" }, {}, []);
export { ToolbarItemDownloadBulkDocuwareComponentNgFactory as ToolbarItemDownloadBulkDocuwareComponentNgFactory };
