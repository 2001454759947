import { Datamodel } from './../../services/datamodel/datamodel';
import { ElementInput, ElementInputAssociation } from './element';
import {ModuleElement} from "../../services/module/module-element";

export interface ElementInputDropdown extends ElementInput {
  options: ElementInputDropdownOptions[];
  placeholder: string;
  // source: ElementInputDropdownSourceDatamodel;
  datamodel: Datamodel;
  dropdownFieldLabel: string;
  dropdownFieldLabelValue: string
  tooltipValue: string
  dropdownFieldValue: string;
  filterField: string;

  staticFilterField: string;
  staticFilterValue: string;
  masterFilterField: string;
  masterFilterFieldValue?: string;
  masterFilterFieldReal?: string;
  isManyInMasterFilterField?: boolean;
  isInversedManyInMasterFilterField?: boolean;
  isEmbeddedNone?: boolean;
  embeddedFields?: string;
  firstSortingField?: string;
  firstSortingDirectionField?: string;
  firstSortingTypeField?: string;
  secondSortingField?: string;
  secondSortingDirectionField?: string;
  secondSortingTypeField?: string;
  datamodelFieldFilter: string;
  datamodelFieldFilterValue: string;
  isEmptyValueAllowed: boolean;
  selectIfSingleValue: boolean;
  customApiRoute: string;
  // labelKey: string;
  // valueKey: string;
}

export interface ElementInputDropdownOptions {
  label: string;
  value: any;
}

export interface ElementInputDropdownSourceDatamodel {
  datamodel: Datamodel;
  labelKey: string;
  valueKey: string;
}

export class ElementInputDropdown extends ElementInputAssociation implements ElementInputDropdown {

  public assoc: any[];

  constructor() {
    super();
    this.typeElement = 'dropdown';
    this.options = [];
    this.placeholder = undefined;
    this.datamodel = undefined;
    this.dropdownFieldLabel = undefined;
    this.dropdownFieldValue = undefined;
    this.tooltipValue = undefined;
    this.filterField = undefined;
    this.assoc = [];
    this.staticFilterField = null;
    this.staticFilterValue = null;
    this.masterFilterField = null;
    this.masterFilterFieldValue = null;
    this.isManyInMasterFilterField = null;
    this.isInversedManyInMasterFilterField = null;
    this.isEmbeddedNone = null;
    this.embeddedFields = null;
    this.firstSortingField = null;
    this.firstSortingDirectionField = ModuleElement.SORTING_DIRECTION_ASCENDING;
    this.firstSortingTypeField = null;
    this.secondSortingField = null;
    this.secondSortingDirectionField = ModuleElement.SORTING_DIRECTION_ASCENDING;
    this.secondSortingTypeField = null;
    this.datamodelFieldFilter = null;
    this.datamodelFieldFilterValue = null;
    this.isEmptyValueAllowed = false;
    this.selectIfSingleValue = false;
    this.customApiRoute = '';
  }
}

export class ElementInputDropdownOptions implements ElementInputDropdownOptions { }


