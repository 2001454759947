import { AbstractFormActionHandler } from './abstract-form-action-handler';
import { Injectable } from '@angular/core';
import {Entity} from '../../../helpers/entity';
import {NumberHelper} from '../../../helpers/number.helper';

const EUR_DATAMODEL_FIELD = 'deductibleInEuro';
const PERCENT_DATAMODEL_FIELD = 'deductibleInPrecent';
const PRICE_DATAMODEL_FIELD = 'purchasePrice';

@Injectable()
export class CalculateIntranetBundleVehicleDataDeductibleValuesActionHandler extends AbstractFormActionHandler {

  public handleAction(): void {
    if (this.isActionValid()) {
     this.doHandleAction();
    }
  }

  private doHandleAction(): void {
    const entity = this.getEntity();
    const total = Entity.getValue(entity, PRICE_DATAMODEL_FIELD),
      deductibleInPercent = Entity.getValue(entity, PERCENT_DATAMODEL_FIELD),
      deductibleInEuro = Entity.getValue(entity, EUR_DATAMODEL_FIELD);

    if (this.isChangingEuro()) {
      const percentComponent = this.getComponentByDatamodelField(PERCENT_DATAMODEL_FIELD);

      percentComponent.setValue(NumberHelper.formatNumber(String(100 * (deductibleInEuro / total)), ',', '.', 2), false)
    }

    if (this.isChangingPercent()) {
      const euroComponent = this.getComponentByDatamodelField(EUR_DATAMODEL_FIELD);

      euroComponent.setValue(NumberHelper.formatNumber(String(total * (deductibleInPercent / 100)), ',', '.', 2), false)
    }
  }

  private isActionValid(): boolean {
    const form = this.getForm();

    return this.formService.getElementBy(form, 'datamodelField', PERCENT_DATAMODEL_FIELD) !== null &&
      this.formService.getElementBy(form, 'datamodelField', EUR_DATAMODEL_FIELD) !== null &&
      this.formService.getElementBy(form, 'datamodelField', PRICE_DATAMODEL_FIELD) !== null;
  }

  private isChangingEuro(): boolean {
    return this.getElement().datamodelField === EUR_DATAMODEL_FIELD;
  }

  private isChangingPercent(): boolean {
    return this.getElement().datamodelField === PERCENT_DATAMODEL_FIELD;
  }
}
