/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../../../../../dialog/dialog-add-address/dialog-add-address.component.ngfactory";
import * as i2 from "../../../../../../../dialog/dialog-add-address/dialog-add-address.component";
import * as i3 from "@angular/forms";
import * as i4 from "../../../../../../../services/generic-crud.service";
import * as i5 from "@angular/common";
import * as i6 from "./toolbar-item-form-add-address.component";
var styles_ToolbarItemFormAddAddress = [];
var RenderType_ToolbarItemFormAddAddress = i0.ɵcrt({ encapsulation: 2, styles: styles_ToolbarItemFormAddAddress, data: {} });
export { RenderType_ToolbarItemFormAddAddress as RenderType_ToolbarItemFormAddAddress };
function View_ToolbarItemFormAddAddress_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-dialog-add-address", [], null, [[null, "visibleChange"], [null, "onAdd"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("visibleChange" === en)) {
        var pd_0 = ((_co.isDialogVisible = $event) !== false);
        ad = (pd_0 && ad);
    } if (("onAdd" === en)) {
        var pd_1 = (_co.onImport($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i1.View_DialogAddAddressComponent_0, i1.RenderType_DialogAddAddressComponent)), i0.ɵdid(1, 245760, null, 0, i2.DialogAddAddressComponent, [i3.FormBuilder, i4.GenericCrudService], { visible: [0, "visible"] }, { visibleChange: "visibleChange", onAdd: "onAdd" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.isDialogVisible; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_ToolbarItemFormAddAddress_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵand(16777216, null, null, 1, null, View_ToolbarItemFormAddAddress_1)), i0.ɵdid(1, 16384, null, 0, i5.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.isDialogVisible; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_ToolbarItemFormAddAddress_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "toolbar-item-form-add-address", [], null, null, null, View_ToolbarItemFormAddAddress_0, RenderType_ToolbarItemFormAddAddress)), i0.ɵdid(1, 245760, null, 0, i6.ToolbarItemFormAddAddress, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ToolbarItemFormAddAddressNgFactory = i0.ɵccf("toolbar-item-form-add-address", i6.ToolbarItemFormAddAddress, View_ToolbarItemFormAddAddress_Host_0, { params: "params" }, {}, []);
export { ToolbarItemFormAddAddressNgFactory as ToolbarItemFormAddAddressNgFactory };
