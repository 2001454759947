import { GenericCrudService } from './../../services/generic-crud.service';
import { Component, OnInit, OnDestroy, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

import { DialogDefaultComponent } from './../dialog-default/dialog-default.component';
import { Constants } from 'app/constants';

/**
 * @description A simple dialog with a simple address form. You can get the values by the (onAdd)="callback(form.value)" callback
 * or by the [(formData)] two way binding.
 * @example `
 * <app-dialog-add-address
 *    [(formData)]="{ country: null, city: null, street: null, number: null, province: null }"
 *    [closeAfterSubmit]="<boolean>true"
 *    (onAdd)="callback(<App.Form.Data.AddAddress>$event)"
 * ></app-dialog-add-address>
 * `
 * @export
 * @class DialogAddAddressComponent
 * @extends {DialogDefaultComponent}
 * @implements {OnInit}
 * @implements {OnDestroy}
 */
@Component({
  selector: 'app-dialog-add-address',
  templateUrl: './dialog-add-address.component.html',
  styleUrls: []
})
export class DialogAddAddressComponent extends DialogDefaultComponent implements OnInit, OnDestroy {

  /**
   * @todo rename to onSubmit
   * @description Callback function after submitting the form. Use it with (onAdd)="".
   * @type {EventEmitter<App.Form.Data.AddAddress>}
   * @memberof DialogAddAddressComponent
   */
  @Output() onAdd: EventEmitter<App.Form.Data.AddAddress> = new EventEmitter();

  /**
   * @description Object where we store the formData
   * @private
   * @type {App.Form.Data.AddAddress}@memberof DialogAddAddressComponent
   */
  private formDataObj: App.Form.Data.AddAddress;

  /**
   * @description It's our [formData] binding.
   * @readonly
   * @type {App.Form.Data.AddAddress}
   * @memberof DialogAddAddressComponent
   */
  @Input() get formData(): App.Form.Data.AddAddress {
    return this.formDataObj;
  }

  /**
   * @description To get two way binding [(formData)] we alse have an output emitter.
   * @type {EventEmitter<App.Form.Data.AddAddress>}@memberof DialogAddAddressComponent
   */
  @Output() formDataChange: EventEmitter<App.Form.Data.AddAddress> = new EventEmitter();
  set formData(val: App.Form.Data.AddAddress) {
    this.formDataObj = val;
    this.formDataChange.emit(this.formDataObj);
  }

  /**
   * @todo nonsense.... remove it
   * @description Close dialog after submit
   * @memberof DialogAddAddressComponent
   */
  @Input() closeAfterSubmit = true;

  /**
   * @description Angular FormGroup instance.
   * @type {FormGroup}
   * @memberof DialogAddAddressComponent
   */
  public form: FormGroup;

  /**
   * @description Countries we've found for autocomplete
   * @memberof DialogAddAddressComponent
   */
  public countries = [];

  /**
   * @description Cities we've found for autocomplete
   * @memberof DialogAddAddressComponent
   */
  public cities = [];

  /**
   * @description Streets we've found for autocomplete
   * @memberof DialogAddAddressComponent
   */
  public streets = [];

  /**
   * @description Province we've found for autocomplete
   * @memberof DialogAddAddressComponent
   */
  public provinces = [];

  /**
   * Creates an instance of DialogAddAddressComponent.
   * @param {FormBuilder} formBuilder Angular FormBuilder instance
   * @param {GenericCrudService} genericCrudService Generic crud service to get neccessary data.
   * @memberof DialogAddAddressComponent
   */
  constructor(
    private formBuilder: FormBuilder,
    private genericCrudService: GenericCrudService
  ) {
    super();
  }

  /**
   * @description Init function to initialize values and build a form group.
   * @memberof DialogAddAddressComponent
   */
  ngOnInit() {
    // If no form data object is present we will create a new one
    if (!this.formData) {
      this.formData = {
        city: null,
        street: null,
        number: null,
        country: null,
        province: null
      };
    }

    // Build our form
    this.form = this.formBuilder.group({
      country: [this.formData.country, Validators.required],
      city: [this.formData.city, Validators.required],
      street: [this.formData.street, Validators.required],
      number: [this.formData.number, Validators.pattern('^(0|[1-9][0-9]*)$')],
      province: [this.formData.province, Validators.required]
    });

    // To update formData easy we subscribe to the form for value changes.
    // @todo normally this should do the form builder
    this.form.valueChanges.subscribe((value: App.Form.Data.AddAddress) => {
      this.formData.city = value.city || null;
      this.formData.country = value.country || null;
      this.formData.street = value.street || null;
      this.formData.number = value.number || null;
      this.formData.province = value.province || null;
    });
  }

  /**
   * @description Based on option create query
   * @param {string} optionsProperty countries|provinces|cities|streets
   * @param {string} query
   * @memberof DialogAddAddressComponent
   * @returns string
   */
  private getQueryApiRoute(optionsProperty: string, query: string): string {
    let queryString = '';

    switch (optionsProperty) {
      case 'provinces':
        queryString = `${Constants.APP_API_ROUTE}/${optionsProperty}/search/${this.formData.country.name}+${query}`;
        break;
      case 'cities':
        queryString = `${Constants.APP_API_ROUTE}/${optionsProperty}/search/${this.formData.province.name}+${query}`;
        break;
      case 'streets':
        queryString = `${Constants.APP_API_ROUTE}/${optionsProperty}/search/${this.formData.city.city}+${query}`;
        break;
      default:
        queryString = `${Constants.APP_API_ROUTE}/${optionsProperty}/search/${query}`;
    }

    return `${queryString}*`;
  }

  private fillOptionsEntries(optionsProperty: string, entries: any[]): void {

    if (optionsProperty === 'cities') {
      entries.map((entry) => {
        const provinceName = entry.provinceName ? `, ${entry.provinceName}` : '';
        entry.communityAndCity = `${entry.city}${provinceName} `;
      });
    }

    this[optionsProperty] = entries;
  }

  /**
   * @description Calling this function from our autocomplete inputs to filter the server request.
   * @param {any} event
   * @param {string} optionsProperty What to get: countries|provinces|cities|streets
   * @memberof DialogAddAddressComponent
   */
  onSearch(event, optionsProperty: string) {
    const query = event.query || '*';

    this.genericCrudService.getEntities(this.getQueryApiRoute(optionsProperty, query)).subscribe((entries) => {
      this.fillOptionsEntries(optionsProperty, entries);
    });
  }

  /**
   * @description Form submit function
   * @param {App.Form.Data.AddAddress} formData Value to emit as callback value for onAdd.
   * @memberof DialogAddAddressComponent
   */
  onSubmit(formData: App.Form.Data.AddAddress) {
    this.onAdd.emit(formData);
    if (this.closeAfterSubmit) {
      this.visible = false;
    }
  }

  /**
   * @description On destroy we unsubscribe to subscriptions if there are any
   * @memberof DialogAddAddressComponent
   */
  ngOnDestroy() {
    this.subscription.map(s => s.unsubscribe());
  }
}
