import { Injectable } from '@angular/core';
import { Module } from "../../services/module/module";
import { GenericElementAbstract } from 'app/shared/content-renderer/elements/generic-element-abstract.component';

@Injectable()
export class ComponentService {

  private highlightedComponent: GenericElementAbstract;
  private highlightedComponentEntity: any;

  private stashedComponents: GenericElementAbstract[] = [];

  public addToStash(component: GenericElementAbstract) {
    this.stashedComponents.push(component);

    return this;
  }

  public removeFromStash(component: GenericElementAbstract) {
    let index = this.stashedComponents.indexOf(component);

    if (index === -1) {
      throw new Error(`Tried to remove ${component} which is not found in stash!`);
    }

    this.stashedComponents.splice(index, 1);
  }

  public highlight(component: GenericElementAbstract) {
    this.highlightedComponent = component;

    return this;
  }

  public highlightEntity(entity) {
    this.highlightedComponentEntity = entity;

    return this;
  }

  public clearStashed() {
    this.stashedComponents = [];

    return this;
  }

  public clearHighlighted() {
    this.highlightedComponent = null;

    return this;
  }

  public clearHighlightedEntity() {
    this.highlightedComponentEntity = null;

    return this;
  }

  public clear() {
    this.clearHighlighted();
    this.clearHighlightedEntity();
    this.clearStashed();

    return this;
  }

  public isHighlighted(component) {
    return this.highlightedComponent === component;
  }

  public isHighlightedEntity(entity) {
    return this.highlightedComponentEntity === entity;
  }

  public getStashed() {
    return this.stashedComponents;
  }

  public getHighlighted() {
    return this.highlightedComponent;
  }

  public getHighlightedEntity() {
    return this.highlightedComponentEntity;
  }

}
