
    <div class="ui-g">
        <div class="ui-g-8">
          {{label}}
        </div>
        <div class="ui-g-8">
        <p-toggleButton 
          onLabel="{{trueLabel}}" 
          offLabel="{{falseLabel}}" 
          onIcon="pi pi-check" 
          offIcon="pi pi-times" 
          [style]="{'width':'150px'}"
          (onChange)="onChange($event)"
        ></p-toggleButton>
        </div>
    </div>
  