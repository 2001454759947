import {WorkHourCalculatorAbstract} from './work-hour-calculator-abstract';
import {DateHelper} from '../../../../../helpers/date.helper';

export class WorkHourCalculatorAdvanced extends WorkHourCalculatorAbstract {

  public calculateFields(): any {
    if (this.isIndustrialTime) {
      const preBreakStart = this.entity['workStartIndustry'] || 0,
        preBreakEnd = this.entity['workEndIndustry'] || 0,
        postBreakStart = this.entity['workStart2Industry'] || 0,
        postBreakEnd = this.entity['workEnd2Industry'] || 0;
      this.calculateDurationAndBreakIndustry(preBreakStart, preBreakEnd, postBreakStart, postBreakEnd);
    } else {
      const preBreakStartTime = this.entity['workStart'] || '00:00',
        preBreakEndTime = this.entity['workEnd'] || '00:00',
        postBreakStartTime = this.entity['workStart2'] || '00:00',
        postBreakEndTime = this.entity['workEnd2'] || '00:00';
      this.calculateLengthNormally(preBreakStartTime, preBreakEndTime, postBreakStartTime, postBreakEndTime);
    }
    return this.entity;
  }

  private calculateDurationAndBreakIndustry(preBreakStart, preBreakEnd, postBreakStart, postBreakEnd) {
    let preBreakLength = preBreakEnd - preBreakStart;
    if (preBreakStart > preBreakEnd) {
      preBreakLength = 24 - preBreakStart + preBreakEnd;
    }

    let postBreakLength = postBreakEnd - postBreakStart;
    if (postBreakStart > postBreakEnd) {
      postBreakLength = 24 - postBreakStart + postBreakEnd;
    }

    let breakTime = postBreakStart - preBreakEnd;
    if (preBreakEnd > postBreakStart) {
      breakTime = 24 - preBreakEnd + postBreakStart;
    }

    this.entity['workLengthIndustry'] = preBreakLength + postBreakLength;
    this.entity['workLength'] = DateHelper.convertFromDecimalToTime(this.entity['workLengthIndustry']);
    this.entity['workBreakIndustry'] = breakTime;
    this.entity['workBreak'] = DateHelper.convertFromDecimalToTime(this.entity['workBreakIndustry']);
    return this;
  }


  private calculateLengthNormally(preBreakStartTime, preBreakEndTime, postBreakStartTime, postBreakEndTime) {
    const preBreakStart = DateHelper.convertFromTimeToDecimal(preBreakStartTime),
      preBreakEnd = DateHelper.convertFromTimeToDecimal(preBreakEndTime),
      postBreakStart = DateHelper.convertFromTimeToDecimal(postBreakStartTime),
      postBreakEnd = DateHelper.convertFromTimeToDecimal(postBreakEndTime);

    return this.calculateDurationAndBreakIndustry(preBreakStart, preBreakEnd, postBreakStart, postBreakEnd);
  }
}
