import {
  Component,
  ChangeDetectionStrategy
} from '@angular/core';
import {ElementInputAutocompleteComponent, SelectItemExtended} from './element-input-autocomplete.component';
import {environment} from '../../../../environments';
import {GeocoderAutocomplete} from '@geoapify/geocoder-autocomplete';
import {GeoapifyProperties} from '../models/geoapify-properties';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-form-element-input-geoapify-autocomplete',
  template: `
    <div id="autocomplete-container" class="autocomplete-container">
      <div class="icon-container ui-g ui-g-1" *ngIf="((element?.icon | isDefined) && (element?.icon?.relativePath | isDefined))">
        <img height="16" width="16"
             [ngClass]="{
          'cur-pointer': isValid(),
          'cur-disabled': !isValid(),
          'disabled': !isValid()
        }"
             [src]="iconBaseUrl + '/' + element?.icon?.relativePath"
             [alt]="element?.icon?.name"
             (click)="onClickIcon()"
        />
      </div>
      <div *ngIf="element.hasCustomButton" class="custom-button-container ui-g ui-g-1">
        <i
          (click)="onCustomButtonClick($event)"
          [class]="isReadOnlyOrDisabled() ? 'fa fa-plus fa-disabled' : 'fa fa-plus'"
          aria-hidden="true"
        >
        </i>
      </div>
    </div>
  `,
  styles: [`
      :host {
        height: 100%;
      }

      .autocomplete-container {
        position: relative;
      }
    `]
})
export class ElementInputGeoapifyAutocompleteComponent extends ElementInputAutocompleteComponent {
  public selectedSuggestion: GeoapifyProperties = null;
  public autocomplete: GeocoderAutocomplete = null;

  public ngOnInit() {
    super.ngOnInit();

    this.autocomplete = new GeocoderAutocomplete(
      this.elementRef.nativeElement.querySelector('#autocomplete-container'),
      environment.geoapifyApiKey
    );
    this.changeAutocompleteStyle();

    this.autocomplete.on('select', (location) => {
      if (location === null) {
        this.selectedSuggestion = null;
        this.setValue(null);
      }

      if (location) {
        this.selectedSuggestion = location.properties;
        this.setValue({
          value: location.properties.address_line1,
          label: location.properties[this.element.datamodelField]
        });
      }
    });
  }

  public setValue(selectedOption: SelectItemExtended, triggerChange: boolean = true, entityValueEmpty: boolean = false) {
    super.setValue(selectedOption, triggerChange, entityValueEmpty);
    return this;
  }

  public setAutocompleteValue(value: any): void {
    if (this.autocomplete) {
      this.autocomplete.setValue(value !== 'null' ? value : '');
      this.autocomplete.close();
    }
  }

  private changeAutocompleteStyle(): void {
    const inputElement = this.elementRef.nativeElement.querySelector('.geoapify-autocomplete-input');
    if (inputElement) {
      inputElement.setAttribute('placeholder', 'Geben Sie hier eine Adresse ein!');
    }
  }
}
