
import {debounceTime} from 'rxjs/operators';
import {
  Component,
  Input,
  AfterViewInit,
  OnInit, OnDestroy, ChangeDetectionStrategy, ChangeDetectorRef
} from '@angular/core';
import {Subject} from 'rxjs';
import {environment} from './../../../../environments/environment';
import {ElementInputComponent} from './element-input.component';
import {ElementInputText} from '../models/index';
import {FormService} from './../form.service';
import {FormViewerService} from './../form-viewer.service';
import {TranslateService} from '@ngx-translate/core';
import {FormActionsService} from '../actions/services/form-actions.service';
import {DatamodelCrudService} from '../../services/datamodel/datamodel.crud.service';
import {NumberHelper} from '../../helpers/number.helper';
import {Entity} from '../../helpers/entity';
import {EntityStatus} from '../../services/entity/entity-status';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-form-element-input-text',
  template: `
  <div class="container" [formGroup]="formGroup">
      <input *ngIf="!hasFormat() && !hasMask()"
        [type]="getInputType()"
        appSeleniumDirective
        [element]="element"
        pInputText
        [formControlName]="formControlName"
        [placeholder]="element.placeholder"
        [readonly]="isReadOnly()"
        [required]="checkRequired()"
        [style.padding-right]="getInputInnerPadding()"
        [style.font-weight]="getFontWeight()"
        (keyup)="keyUp.next($event)"
        (blur)="blur.next($event)"
        tabindex="{{element.tabIndex}}"
        #inputElement
      />
      <input *ngIf="hasFormat() && !hasMask()"
        [type]="getInputType()"
        appSeleniumDirective
        [element]="element"
        pInputText
        [formControlName]="formControlName"
        [placeholder]="element.placeholder"
        [readonly]="isReadOnly()"
        [required]="checkRequired()"
        [style.padding-right]="getInputInnerPadding()"
        [style.font-weight]="getFontWeight()"
        (keyup)="keyUp.next($event)"
        (blur)="blur.next($event)"
        tabIndex="{{element.tabIndex}}"
        #inputElement
      />
      <p-inputMask
      [type]="getInputType()"
      *ngIf="hasMask()"
      appSeleniumDirective
      [element]="element"
      mask="{{this.element.inputFormat.mask}}"
      [formControlName]="formControlName"
      [placeholder]="element.placeholder"
      [readonly]="isReadOnly()"
      [required]="checkRequired()"
      [style.padding-right]="getInputInnerPadding()"
      [style.font-weight]="getFontWeight()"
      (keyup)="keyUp.next($event)"
      (blur)="blur.next($event)"
      [tabIndex]="element.tabIndex"
      ></p-inputMask>
    <div class="icon-container" fxFlex="20" *ngIf="((element?.icon | isDefined) && (element?.icon?.relativePath | isDefined))">
      <img height="16" width="16"
        [ngClass]="{
          'cur-pointer': isValid(),
          'cur-disabled': !isValid(),
          'disabled': !isValid()
        }"
        [src]="iconBaseUrl + '/' + element?.icon?.relativePath"
        [alt]="element?.icon?.name"
        (click)="onClickIcon()"
      />
    </div>
    <div class="icon-container" fxFlex="20" *ngIf="hasUrlValidation() || hasEmailValidation()">
      <i class="fa fa-globe" aria-hidden="true" *ngIf="hasUrlValidation()"
        [ngClass]="{
          'cur-pointer': isValid(),
          'cur-disabled': !isValid(),
          'disabled': !isValid()
        }"
        (click)="onClickOpenUrl()"
      ></i>
      <i class="fa fa-envelope" aria-hidden="true" *ngIf="hasEmailValidation()"
        [ngClass]="{
          'cur-pointer': isValid(),
          'cur-disabled': !isValid(),
          'disabled': !isValid()
        }"
        (click)="onClickOpenEmail()"
      ></i>
    </div>
  </div>
  `,
  styles: [`
    :host {
      height: 100%;
    }

    .container {
      height: 100%;
    }

    .ui-inputtext {
      height: 100%;
    }

    input[readonly].ui-widget-header .ui-inputtext.ui-state-default,
    input[readonly].ui-widget-content .ui-inputtext.ui-state-default,
    input[readonly].ui-inputtext.ui-state-default,
    input[readonly].ui-widget-header .ui-inputtext.ui-state-default:focus:not(:active):not(:hover),
    input[readonly].ui-widget-content .ui-inputtext.ui-state-default:focus:not(:active):not(:hover),
    input[readonly].ui-inputtext.ui-state-default:focus:not(:active):not(:hover),
    input[readonly].ui-inputtext.ui-state-focus, input[readonly].ui-inputtext:focus,
    input[readonly].ui-inputtext, input[readonly].ui-inputtext{
      border-color: lightgrey;
      background-color: lightgrey;
      color: black;
      box-shadow: none;
      -moz-box-shadow: none;
      -webkit-box-shadow: none;
    }
    :host > div {
      position: relative;
    }

    :host > div > .icon-container {
      position: absolute;
      right: 8px;
      top: 4px;
    }
  `]
})
export class ElementInputTextComponent extends ElementInputComponent implements OnInit, OnDestroy, AfterViewInit {

  @Input() element: ElementInputText;

  public keyUp = new Subject<any>();
  public blur = new Subject<any>();

  iconBaseUrl: string = environment.baseUrl;

  private decimalSymbol = ',';
  private thousandsSeparatorSymbol = '.';

  constructor(
    protected formService: FormService,
    public cdr: ChangeDetectorRef,
    protected formViewerService: FormViewerService,
    protected formActionsService: FormActionsService,
    protected translateService: TranslateService,
    protected datamodelCrudService: DatamodelCrudService
  ) {
    super(formService, cdr, formViewerService, translateService, datamodelCrudService);
  }

  onComponentInit() {
    this.setFormControlName();

    this.keyUp.pipe(
      debounceTime(this.hasFormat() ? 1500 : 500))
      .subscribe((event: any) => {
        if (this.hasMask()) {
          this.onInputMaskKeyUp(event);
        } else {
          this.onInputKeyUp(event);
        }
      });

    this.blur.pipe(
      debounceTime(1))
      .subscribe((event: any) => {
        this.onInputBlur(event);
      });

    this.filterActionAndHandleIt('oninit');
  }

  onComponentChanges(): void {
    if (!this.formControlName) {
      this.setFormControlName();
    }

    this.setupValue();
  }

  onComponentAfterViewInit() {
  }

  public hasFormat(): boolean {
    return this.element.inputFormat && this.element.inputFormat.number && this.element.inputFormat.number.active;
  }

  public hasMask(): boolean {
    return typeof this.element.inputFormat.mask !== 'undefined' && this.element.inputFormat.mask !== null &&
      this.element.inputFormat.mask !== '';
  }

  getInputType() {
    if (this.element.validators && this.element.validators.length > 0) {
      const html5Validator = this.element.validators.find((validator) => {
        return (validator.key.toLowerCase() === 'html5.url' || validator.key.toLowerCase() === 'html5.email');
      });

      if (html5Validator) {
        return html5Validator.key.split('.')[1];
      }
    }
    return 'text';
  }

  getInputInnerPadding() {
    let padding = '.25em';

    if (this.hasUrlValidation()) {
      padding = '4px';
    }

    if (this.hasUrlValidation() && padding === '.25em') {
      padding = '4px';
    } else if (this.hasUrlValidation() && padding !== '.25em') {
      padding = '8px';
    }

    return padding;
  }

  public setupValue(): void {
    if (this.entity && this.entityHasValue(this.element.datamodelField, this.entity)) {
      this.setupValueFromEntity();
    } else if (this.entity && !this.entity[this.element.datamodelField]) {
      this.setupDefaultValue();
    }
  }

  protected entityHasValue(datamodelField: string, entity: any) {
    return Entity.getValue(entity, datamodelField) !== null;
  }

  protected setupValueFromEntity(): void {
    const value = Entity.getValue(this.entity, this.element.datamodelField);

    this.setValue(value, false);
  }

  public onInputKeyUp(event) {
    if (!this.hasFormat()) {
      const value = this.getEntityValueForChange(event.target.value);

      this.setValue(value);
    }

    if (this.hasFormat()) {
      const value = event.target.value,
        entityValue = typeof value === 'string' ? this.getNumberValue(value) : value;

      this.formService.onFormElementValueChange({
        formControlValue: event.target.value,
        element: this.element,
        entityValue: entityValue,
        formControlName: this.formControlName,
        formControlOptions: {},
        triggerChange: true,
        entity: this.entity,
        component: this,
        updateFormComponent: false
      });
    }
  }

  public onInputMaskKeyUp(event): void {
    const value = event.target.value;

    this.setValue(value, true, {}, false);
  }

  public onInputBlur(event): void {
    const value = this.getEntityValueForChange(event.target.value);

    this.setValue(value, Entity.getValue(this.entity, EntityStatus.ENTITY_CHANGED_FLAG) === true);
  }

  setValue(value: any, triggerChange: boolean = true, options: Object = {}, updateFormComponent = true) {
    value = this.checkMinMaxLimits(value);
    const formValue = this.getFormValue(value);

    if (typeof (value) !== 'undefined') {
      this.formService.onFormElementValueChange({
        formControlValue: formValue,
        element: this.element,
        entityValue: value,
        formControlName: this.formControlName,
        formControlOptions: options,
        triggerChange: triggerChange,
        entity: this.entity,
        component: this,
        updateFormComponent: updateFormComponent
      });
    }

    return this;
  }

  protected setupDefaultValue(): void {
    this.setValue(this.getEntityValueForChange(this.element.defaultValue), false);
  }

  private getNumberValue(value){
    return NumberHelper.getNumberFromString(
      value,
      this.decimalSymbol,
      this.thousandsSeparatorSymbol,
      this.element.inputFormat.number.suffix,
      this.element.inputFormat.number.prefix
    );
  }

  private getNumberString(value){
    return NumberHelper.formatNumber(
      value,
      this.decimalSymbol,
      this.thousandsSeparatorSymbol,
      this.element.inputFormat.number.allowDecimal ? this.element.inputFormat.number.decimalLimit : 0,
      this.element.inputFormat.number.suffix,
      this.element.inputFormat.number.prefix
    );
  }

  private getDecimalLimit(): number {
    return this.element.inputFormat.number.decimalLimit || 2;
  }

  private getFormValue(value): string {
    if (this.hasFormat()) {
      const entityValue = typeof value === 'string' ? this.getNumberValue(value) : value;

      value = this.getNumberString(entityValue);
    }

    return value;
  }

  private getEntityValueForChange(value): any {
    if (this.hasFormat()) {
      value = this.getNumberValue(value);
    }

    return value;
  }

  private checkMinMaxLimits(value): any {
    if (this.hasFormat()) {
      if(this.element.inputFormat.number['minValue'] && this.element.inputFormat.number['minValue'] > value){
        value = this.element.inputFormat.number['minValue'];
      }
      if(this.element.inputFormat.number['maxValue'] && this.element.inputFormat.number['maxValue'] < value){
        value = this.element.inputFormat.number['maxValue'];
      }
    }

    return value;
  }
}
