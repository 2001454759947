import {HandleAlgoliaPlaceChangeActionHandler} from './handle-algolia-place-change-action-handler';

export class HandleAlgoliaPlaceEntityChangedActionHandler extends HandleAlgoliaPlaceChangeActionHandler {

  public handleAction(): void {
    const entity = this.getEntity();

    if (entity) {
      setTimeout(() => {
        this.changeAlgoliaValue(entity);
      }, 10);
    }
  }
}
