import {ChangeDetectionStrategy, Component} from '@angular/core';
import { ToolbarItemAbstract } from '../toolbar-item-abstract.component';
import { MessageGrowlService } from '../../../../../../../core/message/message-growl.service';
import { MessageService } from '../../../../../../../core/message/message.service';
import { GenericCrudService } from '../../../../../../services/generic-crud.service';
import { ComponentService } from '../../../../../services/component-highlight-stack.service';
import {EntityDataStoreService} from '../../../../../services/entity-data-store.service';
import {TranslateService} from '@ngx-translate/core';
import {FormViewerComponent} from '../../../../../../form-viewer/form-viewer.component';
import {EntityStatus} from '../../../../../../services/entity/entity-status';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-toolbar-item-set-order-offer-send-state',
  templateUrl: './toolbar-item-set-order-offer-send-state.component.html'
})
export class ToolbarItemSetOrderOfferSendStateComponent extends ToolbarItemAbstract {

  constructor(
    protected messageGrowlService: MessageGrowlService,
    protected messageService: MessageService,
    protected genericCrudService: GenericCrudService,
    private entityDataStore: EntityDataStoreService,
    protected componentService: ComponentService,
    protected translateService: TranslateService
  ) {
    super();
  }

  public click() {
    const component = this.getComponent();

    if (component instanceof FormViewerComponent) {
      const entity = component.entity;
      const customEntity = {
        'fqn': entity.fqn,
        'uniqueId': entity.uniqueId,
        'isChanged': true
      };
      if (entity.fqn === 'PhoenixBundle\\Entity\\Offer') {
        this.genericCrudService.getEntityBy('phoenix/offerstates', 'code', 'TEILVERH').subscribe((offerState) => {
          customEntity['offerState'] = offerState;
          this.genericCrudService.editEntity('phoenix/offers/' + entity.id, customEntity).subscribe((offer) => {
            entity['cssColor'] = offer['cssColor'];
            entity['offerState'] = offer._embedded['offerState'];
            entity._embedded['offerState'] = offer._embedded['offerState'];
            this.messageGrowlService.success('Done.');
          });
        });
      }else if (entity.fqn === 'PhoenixBundle\\Entity\\Order') {
        this.genericCrudService.getEntityBy('phoenix/orderstates', 'code', 'TWOFF').subscribe((orderState) => {
          customEntity['orderState'] = orderState;
          this.genericCrudService.editEntity('phoenix/orders/' + entity.id, customEntity).subscribe((order) => {
            entity['cssColor'] = order['cssColor'];
            entity['orderState'] = order._embedded['orderState'];
            entity._embedded['orderState'] = order._embedded['orderState'];
            this.messageGrowlService.success('Done.');
          });
        });
      }
    }
  }
}
