import {AbstractFormActionHandler} from '../abstract-form-action-handler';
import {Entity} from '../../../../helpers/entity';
import {FormViewerComponent} from '../../../form-viewer.component';
import {ElementContext, RuntimeFlagName} from '../../../../content-renderer/services/ElementContext';
import {EntityManagerService} from '../../../../../core/service/entity-manager/entity-manager.service';
import {ExecutorService} from '../../../../../core/executor/executor.service';
import {ExecutionStepFactoryService} from '../../../../../core/executor/factory/execution-step-factory.service';
import {
  SetupMemoFieldDefinitionValueComponentExecutionStep
} from '../../../../services/execution-step/memo/setup-memo-field-definition-value-component-execution.step';
import {ExecutionStepPayload} from '../../../../../core/executor/execution-step-payload';
import {EntityStatus} from '../../../../services/entity/entity-status';

export class SetupMemoFormActionHandler extends AbstractFormActionHandler {

  public constructor(
    private entityManager: EntityManagerService,
    private stepFactory: ExecutionStepFactoryService
  ) {
    super();
  }

  public handleAction(): void {
    const memoTypeMapping = {
      'createInAssignment': [
        'assignment'
      ],
      'createInOrder': [
        'order'
      ],
      'createInOffer': [
        'offer'
      ],
      'createInAccomodation': [
        'accommodation'
      ],
      'createInPreCalculation': [
        'preCalculation'
      ],
      'doUseCustomerAddress': [
        'customerAddress',
        'customerAddress.primaryEmail'
      ],
      'doUseCustomerContactPerson': [
        'contactPerson',
        'contactPerson.email',
        'contactPerson.fullMobileNumber'
      ],
      'doUseCustomerCollectiveContract': [
        'collectiveAgreement'
      ],
      'doUseEmployeeAddress': [
        'employeeAddress',
        'employeeAddress.primaryEmail',
        'employeeAddress.fullMobileNumber'
      ]
    },
      memo = this.getEntity();
    if (!this.isValid(memo)) {
      this.hideAll(memoTypeMapping);
    }
    const memoFormType = this.findMemoFormType(memo);
    if (memoFormType === null) {
      const buttons = [
        'An:',
        'Sms An:',
        'Mail senden',
        'SMS senden'
      ];
      for (const label of buttons) {
        const button = this.formService.getElementBy(this.getForm(), 'label', label);

        button.isHidden = true;
        this.markElementForCheck(button);
      }
    } else {
      this.handleButtons(memoFormType);
    }
    if (memo) {
      this.handleMemoCategory(memo);
      if (memo.hasTodo) {
        this.handleTodo(memo);
      }
    }
    if (this.isValid(memo)) {
      const memoType = Entity.getValue(memo, 'memoType') ||
        Entity.getValueInEmbedded(memo, 'memoType');

      this.handleText(memo, memoType);
      this.handleContext(memo, memoType, memoTypeMapping);
      this.handleMemoComponent();
    }
  }

  private handleText(memo, memoType): this {
    const formComponent = this.getComponent();
    if (formComponent instanceof FormViewerComponent && formComponent.getElementContext() && memoType.text) {
      const formContext: ElementContext = formComponent.getElementContext(),
        textFormElement = this.formService.getElementBy(this.getForm(), 'datamodelField', 'text'),
        textFormControl = this.getFormGroup().get(textFormElement.datamodelField + '_h_r_f_e_' + textFormElement.objectHashId);

      if (formContext.getRuntimeFlag(RuntimeFlagName.IsMemoTypeTextDefinedOnLoad) === null) {
        formContext.addRuntimeFlag({
          name: RuntimeFlagName.IsMemoTypeTextDefinedOnLoad,
          status: Entity.getValue(memo, 'text') !== null,
          active: true
        });
      }
      if (textFormElement && textFormControl && formContext.getRuntimeFlagStatus(RuntimeFlagName.IsMemoTypeTextDefinedOnLoad) === false && memoType.id !== formContext.getRuntimeFlagValue(RuntimeFlagName.IsMemoTypeTextDefinedOnLoad)) {
        textFormElement.setValue(memoType.text);
        textFormControl.setValue(memoType.text);
        this.entityManager.persist(memo, {property: 'text', newValue: memoType.text, force: true});
        formContext.setRuntimeFlagValue(RuntimeFlagName.IsMemoTypeTextDefinedOnLoad, memoType.id);
      }
    }

    return this;
  }

  private handleContext(memo, memoType, memoTypeMapping): this {
    this.hideAll(memoTypeMapping)
      .showBasedOnMemo(memo, memoType, memoTypeMapping);

    return this;
  }

  private handleMemoComponent(): this {
    const executorService = new ExecutorService();

    executorService.setSteps([
      this.stepFactory.create(SetupMemoFieldDefinitionValueComponentExecutionStep, new ExecutionStepPayload(
        this.getComponent()
      ))
    ])
    .execute()
    .subscribe();

    return this;
  }

  private handleMemoCategory(memo): this {
    const memoCategoryMapping = {
      'isCustomer': [
        'customer'
      ],
      'isEmployee': [
        'leasedEmployee'
      ]
    };

    if (!this.isCategoryValid(memo)) {
      this.hideAll(memoCategoryMapping);
    }

    if (this.isCategoryValid(memo)) {
      const memoCategory = Entity.getValue(memo, 'memoCategory') ||
        Entity.getValueInEmbedded(memo, 'memoCategory');

      for (const memoCategoryProperty in memoCategoryMapping) {
        if (memoCategoryMapping.hasOwnProperty(memoCategoryProperty)) {
          const values = memoCategoryMapping[memoCategoryProperty];
          for (const entity of values) {
            const formElement = this.formService.getElementBy(this.getForm(), 'datamodelField', entity),
              value = Entity.getValue(memoCategory, memoCategoryProperty);
            if (formElement) {
              formElement.isHidden = value !== true;
              this.markElementForCheck(formElement);
            }
          }
        }
      }
    }

    return this;
  }

  private hideAll(memoTypeMapping): this {
    for (const memoTypeProperty in memoTypeMapping) {
      if (memoTypeMapping.hasOwnProperty(memoTypeProperty)) {
        const masterEntity = memoTypeMapping[memoTypeProperty];
        for (const entity of masterEntity) {
          const formElement = this.formService.getElementBy(this.getForm(), 'datamodelField', entity);
          if (formElement) {
            formElement.isHidden = true;
            this.markElementForCheck(formElement);
          }
        }
      }
    }

    return this;
  }

  private showBasedOnMemo(memo, memoType, memoTypeMapping): this {
    for (const memoTypeProperty in memoTypeMapping) {
      if (memoTypeMapping.hasOwnProperty(memoTypeProperty) && memoType.hasOwnProperty(memoTypeProperty)) {
        const masterEntity = memoTypeMapping[memoTypeProperty];

        for (const entity of masterEntity) {
          const value = Entity.getValue(memoType, memoTypeProperty),
            formElement = this.formService.getElementBy(this.getForm(), 'datamodelField', entity);

          if (formElement) {
            formElement.isHidden = value !== true;
            this.markElementForCheck(formElement);
          }
        }
      }
    }

    return this;
  }

  private findMemoFormType(memo): string {
    let code = null;
    if (memo) {
      if (memo.memoFormTyp) {
        const formType = memo.memoFormTyp;
        if (formType.code === 'SMS') {
          code = formType.code;
        } else if (formType.code === 'MAIL') {
          code = formType.code;
        }
      }
    }
    return code;
  }

  private handleTodo(memo) {
    if (memo) {
      const isPersonResponsible = Entity.getValue(memo, 'isPersonResponsible'),
        isBranchOfficeResponsible = Entity.getValue(memo, 'isBranchOfficeResponsible');

      const responsibleUser = this.formService.getElementBy(this.getForm(), 'datamodelField', 'responsibleUser'),
        responsibleBranchOffice = this.formService.getElementBy(this.getForm(), 'datamodelField', 'responsibleBranchOffice');

      if (isPersonResponsible) {
        responsibleUser.isHidden = false;
        responsibleBranchOffice.isHidden = true;
      } else if (isBranchOfficeResponsible) {
        responsibleUser.isHidden = true;
        responsibleBranchOffice.isHidden = false;
      }
      this.markElementForCheck(responsibleUser);
      this.markElementForCheck(responsibleBranchOffice);
    }
  }

  private handleButtons(memoFormType: string): void {
    if (memoFormType === 'MAIL') {
      const email = this.formService.getElementBy(this.getForm(), 'datamodelField', 'email');
      const sms = this.formService.getElementBy(this.getForm(), 'datamodelField', 'smsTo');
      const mailButton = this.formService.getElementBy(this.getForm(), 'label', 'Mail senden');
      const smsButton = this.formService.getElementBy(this.getForm(), 'label', 'SMS senden');
      email.isHidden = false;
      sms.isHidden = true;
      mailButton.isHidden = false;
      smsButton.isHidden = true;
      mailButton.disabled = this.getEntity().id === null;

      this.markElementForCheck(email);
      this.markElementForCheck(sms);
      this.markElementForCheck(mailButton);
      this.markElementForCheck(smsButton);
    } else if (memoFormType === 'SMS') {
      const email = this.formService.getElementBy(this.getForm(), 'datamodelField', 'email');
      const sms = this.formService.getElementBy(this.getForm(), 'datamodelField', 'smsTo');
      const smsButton = this.formService.getElementBy(this.getForm(), 'label', 'SMS senden');
      const mailButton = this.formService.getElementBy(this.getForm(), 'label', 'Mail senden');
      email.isHidden = true;
      sms.isHidden = false;
      smsButton.isHidden = false;
      mailButton.isHidden = true;
      smsButton.disabled = this.getEntity().id === null;

      this.markElementForCheck(email);
      this.markElementForCheck(sms);
      this.markElementForCheck(smsButton);
      this.markElementForCheck(mailButton);
    }
  }

  private isValid(memo): boolean {
    let isValid = false;

    if (memo) {
      const memoType = Entity.getValue(memo, 'memoType') ||
        Entity.getValueInEmbedded(memo, 'memoType');

      if (memoType) {
        isValid = true;
      }
    }

    return isValid;
  }

  private isCategoryValid(memo): boolean {
    let isCategoryValid = false;

    const memoCategory = Entity.getValue(memo, 'memoCategory') ||
      Entity.getValueInEmbedded(memo, 'memoCategory');

    if (memoCategory) {
      isCategoryValid = true;
    }

    return isCategoryValid;
  }
}
