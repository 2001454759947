
import {of as observableOf, Observable} from 'rxjs';

import {map} from 'rxjs/operators';
import {AbstractExecutionStep} from '../../../../core/executor/abstract-execution-step';
import {ExecutionStepStatus} from '../../../../core/executor/execution-step-status';
import {GenericTreeGridComponent} from '../../../content-renderer/elements/generic-tree-grid/generic-tree-grid.component';
import {EntityManagerService} from '../../../../core/service/entity-manager/entity-manager.service';

export class CurriculumTreeAddEntryPositionToAddedEntityExecutionStep extends AbstractExecutionStep {

  protected entityManager: EntityManagerService = null;

  public doExecute(): Observable<ExecutionStepStatus> {
    const payload = this.getPayload(),
      component = payload.getValue().component ? payload.getValue().component : payload.getValue();

    this.entityManager = this.injector.get(EntityManagerService, null);

    const node: TreeNode = payload.getValue().node,
      entity = payload.getValue().entity;

    if (!(component instanceof GenericTreeGridComponent)) {
      return this.getFailObservable('You need to pass GenericTreeGridComponent as Payload value!');
    }

    if (entity && entity.leasedEmployee && node) {
      return this.doSetup(component, entity, node);
    }

    return this.getFailObservable('Configuration is wrong!');
  }

  protected doSetup(component: GenericTreeGridComponent, entity?: any, node?: TreeNode): Observable<ExecutionStepStatus> {
    let route = `phoenix/curriculums/nextentryposition/${entity.leasedEmployee}`;

    if (node && node.parent && node.parent.data && node.parent.data.id) {
      route += `/${node.parent.data.id}`;
    }

    if (this.parentIsNew(node)) {
      const newNodesCount = this.getNewNodesCount(component, node);

      this.assignEntryPosition(entity, node, newNodesCount);

      return observableOf({status: true, content: null});
    } else {
      const newNodesCount = this.getNewNodesCount(component, node) - 1;

      return this.genericCrudService.get(
        route
      ).pipe(
        map((nextEntryPosition) => {
          const calculatedEntryPosition = nextEntryPosition + newNodesCount;

          this.assignEntryPosition(entity, node, calculatedEntryPosition);

          return {status: true, content: null};
        }));
    }
  }

  private getNewNodesCount(component: GenericTreeGridComponent, node: TreeNode): number {

    if (node.parent && node.parent.data && node.parent.data.uniqueId) {
      return this.getNewNodesCountInParentNode(node.parent);
    }

    return this.getNewNodesCountInFirstLevel(component);
  }

  private getNewNodesCountInFirstLevel(component): number {
    const nodes: TreeNode[] = component.entities;

    let count = 0;
    for (const node of nodes) {
      if (node && node.data && !node.data.id) {
        count++;
      }
    }

    return count;
  }

  private getNewNodesCountInParentNode(parent: TreeNode): number {
    let count = 0;

    if (parent.children) {
      for (const node of parent.children) {
        if (node && node.data && !node.data.id) {
          count++;
        }
      }
    }

    return count;
  }

  private parentIsNew(node: TreeNode): boolean {
    return node && node.parent && node.parent.data && !node.parent.data.id;
  }

  private assignEntryPosition(entity, node: TreeNode, entryPosition): void {
    this.entityManager.persist(entity, {property: 'entryPosition', newValue: entryPosition, force: true});

    if (node.data) {
      this.entityManager.persist(node.data, {property: 'entryPosition', newValue: entryPosition, force: true});
    }
  }
}
