export interface ChangeSetEntity {
  fqn: string;
  uniqueId?: string;
}

export interface ChangeSetMeta {
  property?: string;
  oldValue?: any;
  newValue?: any;
  force?: boolean;
  entire?: boolean;
  exclude?: string[];
}

export class ChangeSet {
  public property = '';
  public oldValue: any = null;
  public newValue: any = null;

  public constructor(property: string, oldValue: any, newValue: any) {
    this.property = property;
    this.oldValue = oldValue;
    this.newValue = newValue;
  }
}
