/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./toolbar-item-send-sms.component";
import * as i2 from "../../../../../services/component-highlight-stack.service";
import * as i3 from "../../../../../../../core/message/message-growl.service";
import * as i4 from "../../../../../../../core/message/message.service";
import * as i5 from "../../../../../../services/generic-crud.service";
import * as i6 from "../../../../../services/entity-sharing.service";
import * as i7 from "../../../../../../services/api.builder.service";
import * as i8 from "@ngx-translate/core";
import * as i9 from "../../../../../../services/notification.service";
var styles_ToolbarItemSendSms = [];
var RenderType_ToolbarItemSendSms = i0.ɵcrt({ encapsulation: 2, styles: styles_ToolbarItemSendSms, data: {} });
export { RenderType_ToolbarItemSendSms as RenderType_ToolbarItemSendSms };
export function View_ToolbarItemSendSms_0(_l) { return i0.ɵvid(2, [], null, null); }
export function View_ToolbarItemSendSms_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "toolbar-item-send-sms", [], null, null, null, View_ToolbarItemSendSms_0, RenderType_ToolbarItemSendSms)), i0.ɵdid(1, 245760, null, 0, i1.ToolbarItemSendSms, [i2.ComponentService, i3.MessageGrowlService, i4.MessageService, i5.GenericCrudService, i6.EntitySharingService, i7.ApiBuilderService, i8.TranslateService, i9.NotificationService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ToolbarItemSendSmsNgFactory = i0.ɵccf("toolbar-item-send-sms", i1.ToolbarItemSendSms, View_ToolbarItemSendSms_Host_0, { params: "params" }, {}, []);
export { ToolbarItemSendSmsNgFactory as ToolbarItemSendSmsNgFactory };
