import {AbstractFormActionHandler} from '../abstract-form-action-handler';
import {Entity} from '../../../../helpers/entity';

export class SetupWorkplaceFormActionHandler extends AbstractFormActionHandler {

  private mapping = {
    'hasAgreementAspects': [
      'agreementAspectNotes'
    ],
    'hasHolidayAgreementAspects': [
      'holidayAgreementAspectNotes'
    ],
    'isHeavyLabourWorkplace': [
      'isShiftWork',
      'isRegularHeatCold',
      'isHeavyLabour',
      'isOccupationalCare',
      'isReducedEarningCapacity'
    ],
    'hasSpecialFeatures': [
      'specialFeatureNotes'
    ],
    'hasSecurityDocuments': [
      'nightlyHeavyLabourNotes'
    ]
  };

  public handleAction(): void {
    const workplace = this.getEntity();

    if (this.isValid(workplace)) {
      this.handleVisibility(workplace);
    }
  }

  private isValid(workplace): boolean {
    return workplace && workplace.fqn &&
      workplace.fqn === 'PhoenixBundle\\Entity\\Workplace';
  }

  private handleVisibility(workplace): this {
    this.hideAll();

    for (const checkboxProperty in this.mapping) {
      if (this.mapping.hasOwnProperty(checkboxProperty)) {
        const isChecked = Entity.getValue(workplace, checkboxProperty);

        if (isChecked) {
          this.showFields(this.mapping[checkboxProperty]);
        } else {
          this.hideFields(this.mapping[checkboxProperty]);
        }
      }
    }

    return this;
  }

  private hideAll(): this {
    for (const checkboxProperty in this.mapping) {
      if (this.mapping.hasOwnProperty(checkboxProperty)) {
        const formElement = this.formService.getElementBy(this.getForm(), 'datamodelField', this.mapping[checkboxProperty]);

        if (formElement) {
          formElement.isHidden = true;
          this.markElementForCheck(formElement);
        }
      }
    }

    return this;
  }

  private showFields(datamodelFields): this {
    for (const datamodelField of datamodelFields) {
      const formElement = this.formService.getElementBy(this.getForm(), 'datamodelField', datamodelField);

      if (formElement) {
        formElement.isHidden = false;
        this.markElementForCheck(formElement);
      }
    }

    return this;
  }

  private hideFields(datamodelFields): this {
    for (const datamodelField of datamodelFields) {
      const formElement = this.formService.getElementBy(this.getForm(), 'datamodelField', datamodelField);

      if (formElement) {
        formElement.isHidden = true;
        this.markElementForCheck(formElement);
      }
    }

    return this;
  }
}
