import {JobInterface} from '../../../../core/job-runner/job/job.interface';
import {JobContext} from '../../../../core/job-runner/context/job.context';
import {Observable} from 'rxjs';
import {JobExecutionStatus} from '../../../../core/job-runner/job/job-execution-status';
import {ConditionInterface} from '../../../../core/job-runner/condition/condition.interface';
import {AbstractJob} from '../../../../core/job-runner/job/abstract.job';
import {GenericTurboGridComponent} from '../../../content-renderer/elements/generic-turbo-grid/generic-turbo-grid.component';
import {ExecutionStepPayload} from '../../../../core/executor/execution-step-payload';
import {ExecutorService} from '../../../../core/executor/executor.service';
import {ExecutionStepFactoryService} from '../../../../core/executor/factory/execution-step-factory.service';
import {WorkHourPeriodGenerateEntriesExecutionStep} from '../../../services/execution-step/work-hour/work-hour-period-generate-entries-execution-step';

export class SetupWorkHourPeriodEntryGridComponentJob extends AbstractJob implements JobInterface {
  public constructor(
    protected executorService: ExecutorService,
    protected stepsFactory: ExecutionStepFactoryService
  ) {
    super();
  }
  run(context: JobContext, condition: ConditionInterface): Observable<JobExecutionStatus> {
    return new Observable<JobExecutionStatus>((observer) => {
      const component = context.component;

      if (component instanceof GenericTurboGridComponent) {
        this.executorService
          .setSteps([
            this.stepsFactory.create(WorkHourPeriodGenerateEntriesExecutionStep, new ExecutionStepPayload({component: component}))
          ])
          .execute()
          .subscribe();

        observer.next({
          status: true,
          content: null,
          jobId: this.jobId
        });

        observer.complete();
      } else {
        observer.next({
          status: false,
          content: 'Wrong component set',
          jobId: this.jobId
        });

        observer.complete();
      }
    });
  }
}
