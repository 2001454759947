
import { FormAction, Form } from '../../models/form';
import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Element } from 'app/shared/form-viewer/models/element';
import {FormViewerComponent} from '../../form-viewer.component';
import {FormService} from '../../form.service';

export interface FormActionHandler {
    handleAction(): void;
}

export abstract class AbstractFormActionHandler implements FormActionHandler {

  private action: FormAction;
  private form: Form;
  private formGroup: FormGroup;
  private entity: any;
  private element: Element;
  private component: any;
  protected formService: FormService = null;

  public abstract handleAction();

  public constructor() {

  }

  public getParamValue(key: string): any|null {
    let value = null;

    if (this.action.params) {
      let paramObject = this.action.params[key];

      if (typeof paramObject !== 'undefined' && null !== paramObject && paramObject instanceof Object) {
        value = paramObject.value || null;
      }
    }

    return value;
  }

  public setAction(action: FormAction): this {
    this.action = action;
    return this;
  }

  public getAction(): FormAction {
    return this.action;
  }

  public setForm(form: Form): this {
    this.form = form;
    return this;
  }

  public getForm(): Form {
    return this.form;
  }

  public setFormGroup(formGroup: FormGroup): this {
    this.formGroup = formGroup;
    return this;
  }

  public getFormGroup(): FormGroup {
    return this.formGroup;
  }

  public setEntity(entity: any): this {
    this.entity = entity;
    return this;
  }

  public getEntity(): any {
    return this.entity;
  }

  public setElement(element: Element): this {
    this.element = element;
    return this;
  }

  public getElement(): Element {
    return this.element;
  }

  public setComponent(component): this {
    this.component = component;
    return this;
  }

  public getComponent(): any {
    return this.component;
  }

  public setFormService(formService): any {
    this.formService = formService;
  }

  public markElementForCheck(element: Element): void {
    if (element) {
      const formElementToHideFieldName = FormViewerComponent.getFormControlName(
        element.objectHashId,
        element.datamodelField
        );

      let elementComponent = this.formService.getComponent(formElementToHideFieldName);

      if (!elementComponent) {
        elementComponent = this.formService.getComponentByElement(element);
      }

      if (elementComponent) {
        elementComponent.getChangeDetectorRef().markForCheck();
      }
    }
  }

  public markFormControlForCheck(formControlName: string): void {

    const elementComponent = this.formService.getComponent(formControlName);

    if (elementComponent) {
      elementComponent.getChangeDetectorRef().markForCheck();
    }
  }

  protected getComponentByDatamodelField(field: string) {
    const form = this.getForm(),
      element = this.formService.getElementBy(form, 'datamodelField', field);

    if (element) {
      return this.formService.getComponentByElement(element);
    }

    return null;
  }
}
