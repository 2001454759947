import {AbstractExecutionStep} from '../../../core/executor/abstract-execution-step';
import {Observable} from 'rxjs';
import {ExecutionStepStatus} from '../../../core/executor/execution-step-status';
import {GenericElementAbstract} from '../../content-renderer/elements/generic-element-abstract.component';
import {tap} from 'rxjs/operators';
import {GenericDialogModuleService} from '../../content-renderer/elements/generic-dialog/service/generic-dialog-module.service';

export class ComponentSaveAndCloseDialogExecutionStep extends AbstractExecutionStep {

  public doExecute(): Observable<ExecutionStepStatus> {
    const payload = this.getPayload();

    if (!(payload.getValue() instanceof GenericElementAbstract)) {
      return this.getFailObservable('You need to pass GenericElementAbstract as Payload value!');
    }

    return this.doSave(payload.getValue());
  }

  private doSave(component: GenericElementAbstract): Observable<ExecutionStepStatus> {
    return component.onSave().pipe(
      tap(() => {
        this.injector.get<GenericDialogModuleService>(GenericDialogModuleService).persistHide();
      })
    )
  }
}
