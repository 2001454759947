<div [ngSwitch]="true"
   appSeleniumDirective
   [element]="element"
    class="
      element-input-container
      {{editMode && element && selectedElement && element.id === selectedElement.id ? 'editor-element-selected' : ''}}
    "
    [hidden]="element.isHidden"
    (click)="onSelectElement()"
>
  <ng-container *ngSwitchCase="element.type === 'input'">
    <app-form-element-label [form]="form" [formGroup]="formGroup" [element]="element" [entity]="entity" [moduleElement]="moduleElement" [(actionParams)]="actionParams" [(showDialogAddAddress)]="showDialogAddAddress">
      <app-form-element-input #insideNgContent [form]="form" [formGroup]="formGroup" [element]="element" [entity]="entity" [moduleElement]="moduleElement" [(actionParams)]="actionParams" [(showDialogAddAddress)]="showDialogAddAddress"></app-form-element-input>
    </app-form-element-label>
  </ng-container>
  <ng-container *ngSwitchCase="element.type === 'layout'">
    <app-form-element-layout-fieldset
      *ngIf="element.typeElement === 'fieldset'"
      appSeleniumDirective
      [form]="form" [formGroup]="formGroup" [moduleElement]="moduleElement" [element]="element" [entity]="entity" [(selectedElement)]="selectedElement" [(actionParams)]="actionParams" [(showDialogAddAddress)]="showDialogAddAddress" [editMode]="editMode"></app-form-element-layout-fieldset>
    <app-form-element-layout-column
      *ngIf="element.typeElement === 'columns'"
      appSeleniumDirective
      [form]="form" [formGroup]="formGroup" [moduleElement]="moduleElement" [element]="element" [entity]="entity" [(selectedElement)]="selectedElement" [(actionParams)]="actionParams" [(showDialogAddAddress)]="showDialogAddAddress" [editMode]="editMode"></app-form-element-layout-column>
    <app-form-element-layout-tabs
      *ngIf="element.typeElement === 'tabs'"
      appSeleniumDirective
      [form]="form" [formGroup]="formGroup" [moduleElement]="moduleElement" [element]="element" [entity]="entity" [(selectedElement)]="selectedElement" [(actionParams)]="actionParams" [(showDialogAddAddress)]="showDialogAddAddress" [editMode]="editMode"></app-form-element-layout-tabs>
  </ng-container>
  <ng-container *ngSwitchCase="element.typeElement === 'module'">
    <app-form-element-button-open-module [moduleElement]="moduleElement" [element]="element" [entity]="entity" [(actionParams)]="actionParams"></app-form-element-button-open-module>
  </ng-container>
  <ng-container *ngSwitchCase="element.typeElement === 'globalFields'">
    <app-form-element-layout-global-fields *ngIf="element.typeElement === 'globalFields'" [form]="form" [formGroup]="formGroup" [moduleElement]="moduleElement" [element]="element" [entity]="entity" [(selectedElement)]="selectedElement" [(actionParams)]="actionParams" [(showDialogAddAddress)]="showDialogAddAddress" [editMode]="editMode"></app-form-element-layout-global-fields>
  </ng-container>
  <ng-container *ngSwitchCase="element.type === 'button'">
    <app-form-element-button-default *ngIf="element.typeElement === 'default'" [form]="form" [formGroup]="formGroup" [moduleElement]="moduleElement" [element]="element" [entity]="entity" [(actionParams)]="actionParams" [(showDialogAddAddress)]="showDialogAddAddress"></app-form-element-button-default>
  </ng-container>
  <ng-container *ngSwitchCase="element.type === 'placeholder'">
    <app-form-element-label [form]="form" [formGroup]="formGroup" [moduleElement]="moduleElement" [element]="element" [entity]="entity" [(actionParams)]="actionParams" [(showDialogAddAddress)]="showDialogAddAddress"></app-form-element-label>
  </ng-container>
  <ng-container *ngSwitchCase="element.type === 'module-element'">
    <app-form-element-label [form]="form" [formGroup]="formGroup" [moduleElement]="moduleElement" [element]="element" [entity]="entity" [(actionParams)]="actionParams" [(showDialogAddAddress)]="showDialogAddAddress">
      <app-form-element-module-element-grid #insideNgContent *ngIf="element.typeElement === 'grid'" [form]="form" [formGroup]="formGroup" [moduleElement]="moduleElement" [element]="element" [entity]="entity" [(actionParams)]="actionParams" [(showDialogAddAddress)]="showDialogAddAddress"></app-form-element-module-element-grid>
    </app-form-element-label>
  </ng-container>
  <ng-container *ngSwitchCase="element.type === 'association-data'">
    <app-form-element-label [form]="form" [formGroup]="formGroup" [moduleElement]="moduleElement" [element]="element" [entity]="entity" [(actionParams)]="actionParams" [(showDialogAddAddress)]="showDialogAddAddress">
      <app-form-element-association-datamodel-data #insideNgContent [form]="form" [formGroup]="formGroup" [moduleElement]="moduleElement" [element]="element" [entity]="entity" [(actionParams)]="actionParams" [(showDialogAddAddress)]="showDialogAddAddress"></app-form-element-association-datamodel-data>
    </app-form-element-label>
  </ng-container>
  <ng-container *ngSwitchCase="element.type === 'output'">
    <app-form-element-label [form]="form" [formGroup]="formGroup" [moduleElement]="moduleElement" [element]="element" [entity]="entity" [(actionParams)]="actionParams" [(showDialogAddAddress)]="showDialogAddAddress">
      <app-form-element-label-output-datamodel-text #insideNgContent *ngIf="element.typeElement === 'datamodel-text'" [form]="form" [formGroup]="formGroup" [moduleElement]="moduleElement" [element]="element" [entity]="entity" [(actionParams)]="actionParams" [(showDialogAddAddress)]="showDialogAddAddress"></app-form-element-label-output-datamodel-text>
    </app-form-element-label>
  </ng-container>
  <ng-container *ngSwitchCase="element.type === 'collectiveAgreementAku'">
    <app-form-element-label [form]="form" [formGroup]="formGroup" [moduleElement]="moduleElement" [element]="element" [entity]="entity" [(actionParams)]="actionParams" [(showDialogAddAddress)]="showDialogAddAddress">
      <app-form-element-label-output-collective-agreement-aku-text #insideNgContent *ngIf="element.typeElement === 'collective-agreement-aku-text'" [form]="form" [formGroup]="formGroup" [moduleElement]="moduleElement" [element]="element" [entity]="entity" [(actionParams)]="actionParams" [(showDialogAddAddress)]="showDialogAddAddress"></app-form-element-label-output-collective-agreement-aku-text>
    </app-form-element-label>
  </ng-container>
  <ng-container *ngSwitchDefault="">
    Not implemented yet (element.component)... -{{element.label}}-{{element.type}}-{{element.typeElement}}-
  </ng-container>
</div>
