
import {of as observableOf, forkJoin as observableForkJoin, Observable} from 'rxjs';
import {Injectable} from '@angular/core';
import {GenericCrudService} from '../../../../../services/generic-crud.service';
import {GenericTreeGridComponent} from '../../../generic-tree-grid/generic-tree-grid.component';
import {PreCalculationArticle} from '../../../../../services/precalculation/article/pre-calculation-article';
import {AbstractGenericGridComponent} from '../../../abstract-generic-grid.component';

@Injectable()
export class ToolbarItemSavePrecalculationArticleService {
  constructor(protected genericCrudService: GenericCrudService) {}

  public save(genericArticlesGrid: AbstractGenericGridComponent, otherArticlesTreeGrid: GenericTreeGridComponent): Observable<any> {
    const articlesToSave = [];

    for (const updatedArticle of genericArticlesGrid.getEntities()) {
      if (updatedArticle['isChanged']) {
        articlesToSave.push(updatedArticle);
      }
    }

    for (const updatedOtherArticle of otherArticlesTreeGrid.getEntities()) {
      if (updatedOtherArticle['isChanged']) {
        articlesToSave.push(updatedOtherArticle);
      }
    }

    return this.saveArticles(articlesToSave);
  }

  public saveArticles(articles: PreCalculationArticle[]): Observable<any> {
    const updateObservers = [];

    for (const article of articles) {
      updateObservers.push(this.genericCrudService.customPut(this.getUrl(article) + '/' + article.id, this.prepareArticle(article)));
    }

    if (updateObservers.length > 0) {
      return Observable.create((observer) => {
        observableForkJoin(updateObservers).subscribe(results => {
          for (const article of articles) {
            article.isChanged = false;
          }

          observer.next(true);
          observer.complete();
        });
      });
    }

    return observableOf({status: true, content: null});
  }

  protected prepareArticle(article: PreCalculationArticle): any {
    const preppedArticle = {
      id: article.id,
      fqn: article.fqn,
      description: article.description,
      notice: article.notice,
      isActive: article.isActive,
      isChanged: article.isChanged,
      invoiceHour: article.invoiceHour,
      salaryHour: article.salaryHour,
      factor: article.factor,
      factorTargetInvoice: article.factorTargetInvoice,
      salaryMonth: article.salaryMonth,
      invoiceMonth: article.invoiceMonth,
      targetInvoiceHour: article.targetInvoiceHour,
      targetInvoiceMonth: article.targetInvoiceMonth,
      selfCostsHour: article.selfCostsHour,
      selfCostsMonth: article.selfCostsMonth,
      selfCostsCalculatedHour: article.selfCostsCalculatedHour,
      selfCostsCalculatedMonth: article.selfCostsCalculatedMonth,
      orderDb: article.orderDb,
      cssColor: article.cssColor,
      factorSalary: article.factorSalary,
      weeklyHours: article.weeklyHours,
      isOccasional: article.isOccasional,
      isIncludedInNormalWorkhours: article.isIncludedInNormalWorkhours,
      isManuallyChangedInvoice: article.isManuallyChangedInvoice,
      isFactorUnderMinimal: article.isFactorUnderMinimal,
      amount: article.amount
    };

    if (article.preCalculationArticlePeriod && article.preCalculationArticlePeriod.id) {
      preppedArticle['preCalculationArticlePeriod'] = article.preCalculationArticlePeriod.id;
      preppedArticle['_embedded'] = {
        preCalculationArticlePeriod:
        {
          id: article.preCalculationArticlePeriod.id,
          fqn: article.preCalculationArticlePeriod.fqn
        }
      };
    }

    return preppedArticle;
  }

  protected getUrl(article: PreCalculationArticle): string {
    if (article.fqn.toLowerCase().indexOf('other') !== -1) {
      return 'phoenix/precalculationotherarticles';
    }

    return 'phoenix/precalculationarticles';
  }
}
