import {AbstractExecutionStep} from '../../../core/executor/abstract-execution-step';
import {Observable, of} from 'rxjs';
import {ExecutionStepStatus} from '../../../core/executor/execution-step-status';
import {AbstractGenericGridComponent} from '../../content-renderer/elements/abstract-generic-grid.component';

export class ComponentFileGridSetMasterFilterBeforeLoadExecutionStep extends AbstractExecutionStep {

  public doExecute(): Observable<ExecutionStepStatus> {

    const payload = this.getPayload();

    if (!(payload.getValue() instanceof AbstractGenericGridComponent)) {
      return this.getFailObservable('You need to pass AbstractGenericGridComponent as Payload value!');
    }

    return this.doFilter(payload.getValue());
  }

  private doFilter(component: AbstractGenericGridComponent): Observable<ExecutionStepStatus> {
    if (component.getElementDatamodelEntityName().endsWith('File')) {
      const masterEntities = component.getElementContext().getMasterEntities()

      for (const masterEntity of masterEntities) {
        if (masterEntity.filterType === 'subViewMasterEntity' && masterEntity.value) {
          component.staticFilters.push({
            field: 'owner',
            value: masterEntity.value
          });
        }
      }
    }

    return of({status: true, content: null})
  }
}
