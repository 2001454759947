import {Component, OnInit, OnDestroy, Input, ChangeDetectorRef} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { MessageGrowlService } from '../../core/message/message-growl.service';
import { EntitySharingService } from '../content-renderer/services/entity-sharing.service';
import {AbstractGenericGridComponent} from '../content-renderer/elements/abstract-generic-grid.component';

@Component({
  selector: 'app-user-import',
  templateUrl: './user-import.component.html',
  styleUrls: ['./user-import.component.scss']
})
export class UserImportComponent implements OnInit, OnDestroy {

  @Input() gridComponent: AbstractGenericGridComponent = null;

  constructor(
    protected translate: TranslateService,
    protected messageGrowlService: MessageGrowlService,
    protected entitySharingService: EntitySharingService,
    public cdr: ChangeDetectorRef
  ) {}

  ngOnInit() {}

  ngOnDestroy() {}
}
