import {
  Component,
  Input,
  AfterViewInit,
  OnInit, OnDestroy, ChangeDetectionStrategy, ChangeDetectorRef
} from '@angular/core';
import {environment} from '../../../../environments';
import {ElementInputComponent} from './element-input.component';
import {FormService} from '../form.service';
import {FormViewerService} from '../form-viewer.service';
import {TranslateService} from '@ngx-translate/core';
import {FormActionsService} from '../actions/services/form-actions.service';
import {DatamodelCrudService} from '../../services/datamodel/datamodel.crud.service';
import {ElementInputLink} from '../models/element-input-link';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-form-element-input-link',
  template: `
    <a
      appSeleniumDirective
      [element]="element"
      href="{{element.url}}" target="_blank">{{element.urlText}}
      tabindex="{{element.tabIndex}}"
    </a>
  `,
  styles: [`
    :host {
      height: 100%;
    }
  `]
})
export class ElementInputLinkComponent extends ElementInputComponent implements OnInit, OnDestroy, AfterViewInit {

  @Input() element: ElementInputLink;

  iconBaseUrl: string = environment.baseUrl;

  constructor(
    protected formService: FormService,
    public cdr: ChangeDetectorRef,
    protected formViewerService: FormViewerService,
    protected formActionsService: FormActionsService,
    protected translateService: TranslateService,
    protected datamodelCrudService: DatamodelCrudService
  ) {
    super(formService, cdr, formViewerService, translateService, datamodelCrudService);
  }

  onComponentInit() {
  }

  onComponentChanges(): void {
  }

  onComponentAfterViewInit() {
  }
}
