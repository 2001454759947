<app-generic-loading *ngIf="isLoading"></app-generic-loading>

<div class="ui-g-12" *ngIf="!isLoading">
  <ng-template
    ngFor
    let-key
    [ngForOf]="data | keys"
  >
    <div *ngIf="data[key] as stat" class="ui-g-12 stat-container">
      <div class="ui-g-10">
        <strong>Personalberater {{ stat.user.firstName }} {{ stat.user.lastName }}</strong>
      </div>
      <div class="ui-g-2">

      </div>

      <div class="ui-g-12">
        <strong>PLAN / IST (KW {{ stat.weekNumber }})</strong>
      </div>

      <div class="ui-g-6"></div>
      <div class="ui-g-3 stat-item">
        Plan: <strong>{{ stat.plan }}</strong>
      </div>
      <div class="ui-g-3 stat-item">
        Ist: <strong>{{ stat.real }}</strong>
      </div>
      <div class="ui-g-6 stat-item">
        Krank: <strong>{{ stat.ill }}</strong>
      </div>
      <div class="ui-g-3 stat-item">
        Urlaub: <strong>{{ stat.holiday }}</strong>
      </div>
      <div class="ui-g-3 stat-item">
        Ohne ÜL: <strong>{{ stat.noAssignment }}</strong>
      </div>
    </div>


  </ng-template>
</div>

