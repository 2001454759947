/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./toolbar-item-open-module-depending-on-order-is-mediation-value.component";
import * as i2 from "../../../../../../../../core/message/message-growl.service";
import * as i3 from "../../../../../../services/double-click.service";
import * as i4 from "../../../../../generic-dialog/service/generic-dialog-module.service";
import * as i5 from "../../../../../../../services/generic-crud.service";
import * as i6 from "../../../../../../../../core/service/entity-manager/entity-manager.service";
var styles_ToolbarItemOpenModuleDependingOnOrderIsMediationValueComponent = [];
var RenderType_ToolbarItemOpenModuleDependingOnOrderIsMediationValueComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_ToolbarItemOpenModuleDependingOnOrderIsMediationValueComponent, data: {} });
export { RenderType_ToolbarItemOpenModuleDependingOnOrderIsMediationValueComponent as RenderType_ToolbarItemOpenModuleDependingOnOrderIsMediationValueComponent };
export function View_ToolbarItemOpenModuleDependingOnOrderIsMediationValueComponent_0(_l) { return i0.ɵvid(2, [], null, null); }
export function View_ToolbarItemOpenModuleDependingOnOrderIsMediationValueComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-toolbar-item-open-module-depending-on-order-is-mediation-value", [], null, null, null, View_ToolbarItemOpenModuleDependingOnOrderIsMediationValueComponent_0, RenderType_ToolbarItemOpenModuleDependingOnOrderIsMediationValueComponent)), i0.ɵdid(1, 245760, null, 0, i1.ToolbarItemOpenModuleDependingOnOrderIsMediationValueComponent, [i2.MessageGrowlService, i3.DoubleClickService, i4.GenericDialogModuleService, i5.GenericCrudService, i6.EntityManagerService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ToolbarItemOpenModuleDependingOnOrderIsMediationValueComponentNgFactory = i0.ɵccf("app-toolbar-item-open-module-depending-on-order-is-mediation-value", i1.ToolbarItemOpenModuleDependingOnOrderIsMediationValueComponent, View_ToolbarItemOpenModuleDependingOnOrderIsMediationValueComponent_Host_0, { params: "params" }, {}, []);
export { ToolbarItemOpenModuleDependingOnOrderIsMediationValueComponentNgFactory as ToolbarItemOpenModuleDependingOnOrderIsMediationValueComponentNgFactory };
