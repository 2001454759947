
import {of as observableOf, Observable} from 'rxjs';
import {AbstractExecutionStep} from '../../../../core/executor/abstract-execution-step';
import {ExecutionStepStatus} from '../../../../core/executor/execution-step-status';
import {ModuleState} from '../../../content-renderer/services/module-state';
import {GenericElementAbstract} from '../../../content-renderer/elements/generic-element-abstract.component';
import {AbstractGenericGridComponent} from '../../../content-renderer/elements/abstract-generic-grid.component';
import {FormViewerComponent} from '../../../form-viewer/form-viewer.component';
import {ElementContext} from '../../../content-renderer/services/ElementContext';
import {GenericDialogModuleService} from "../../../content-renderer/elements/generic-dialog/service/generic-dialog-module.service";

export class DetailsViewMasterCloseDialogExecutionStep extends AbstractExecutionStep {

  public doExecute(): Observable<ExecutionStepStatus> {
    const payload = this.getPayload();

    const moduleState = payload.getValue();

    if (!(moduleState instanceof ModuleState)) {
      return observableOf({status: false, content: 'Payload should be modulestate, not something else.'});
    }

    return this.close(moduleState);
  }

  protected close(moduleState: ModuleState): Observable<ExecutionStepStatus> {
    let masterComponent = null;

    for (const component of moduleState.getComponents()) {

      if (component.getElementContext().isDetailsViewMaster) {
        masterComponent = component;
        break;
      }
    }

    if (masterComponent) {
      return this.doClose(masterComponent);
    }

    return observableOf({status: false, content: 'Could not find masterComponent to perform close dialog action!'});
  }

  private doClose(component: GenericElementAbstract): Observable<ExecutionStepStatus> {
    const masterElementContext: ElementContext = component.masterElementContext,
      moduleState: ModuleState = this.modulesStateService.getCurrent();

    if (component instanceof FormViewerComponent &&
      masterElementContext && masterElementContext.component instanceof AbstractGenericGridComponent &&
      moduleState.isDialog
    ) {
      masterElementContext.component.moduleElementTargetElementDialogHide();

      const dialogService = this.injector.get(GenericDialogModuleService, null);
      dialogService.persistHide();

      if (component.getElementDatamodelEntityName() === masterElementContext.component.getElementDatamodelEntityName()) {
        const entity = masterElementContext.component.findEntity(component.getSelectedEntity());

        if (entity) {
          masterElementContext.component.setSelectedEntity(entity);
        }
      }

      masterElementContext.component.onAfterSave().subscribe(() => {
        masterElementContext.component.onRefresh().subscribe();
      });
    }

    return observableOf({status: true, content: null});
  }
}
