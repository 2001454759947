import {Component} from '@angular/core';
import {ToolbarItemAbstract} from '../toolbar-item-abstract.component';
import {AbstractGenericGridComponent} from '../../../../abstract-generic-grid.component';
import {PermissionService} from '../../../../../../services/permission/permission.service';
import {EntityDataStoreService} from '../../../../../services/entity-data-store.service';
import {LocalStorageDataService} from '../../../../../../services/local-storage-data.service';
import {TimeDecimalColumnSwitchService} from '../../../../../services/time-decimal-column-switch-service';
import {WorkTimeModelColumnManipulatorData} from '../../../../../../web-worker/work-time-model-column.worker';

@Component({
  selector: 'app-toolbar-item-working-model-time-switch',
  template: ``,
  providers: []
})
export class ToolbarItemWorkingModelTimeDecimalSwitchComponent extends ToolbarItemAbstract {

  protected isDecimalView = false;
  protected component: AbstractGenericGridComponent = null;

  public managingColumns: any = {
    startTime:                  {index: 1, industrialTime: false},
    startTimeDecimal:           {index: 2, industrialTime: true},
    endTime:                    {index: 3, industrialTime: false},
    endTimeDecimal:             {index: 4, industrialTime: true},
    breakHours:                 {index: 5, industrialTime: false},
    breakHoursDecimal:          {index: 6, industrialTime: true},
    plannedWorkingHours:        {index: 7, industrialTime: false},
    plannedWorkingHoursDecimal: {index: 8, industrialTime: true}
  };

  constructor(
    protected permissionService: PermissionService,
    protected entityDataStore: EntityDataStoreService,
    protected localStorage: LocalStorageDataService,
    protected timeDecimalColumnSwitchService: TimeDecimalColumnSwitchService
  ) {
    super();
  }

  public click() {

    const component = this.getComponent();
    if (component instanceof AbstractGenericGridComponent) {
      this.component = component;
      this.isDecimalView = this.isDecimalViewStored();
      const promise = this.createPromise();

      promise.then((runnerData: WorkTimeModelColumnManipulatorData) => {
        this.onRunnerResolved(runnerData);
      });
    }
  }

  private onRunnerResolved(data: WorkTimeModelColumnManipulatorData): void {

    setTimeout(() => {
      this.component.columns = data.newColumns;
      this.managingColumns = data.managingColumns;

      this.component.isDataLoading = false;
      this.component.getChangeDetectorRef().detectChanges();
    }, 1);
  }

  private createPromise(): Promise<any> {
    return new Promise((resolve, reject) => {
      const worker = new Worker('../../../../../../web-worker/work-time-model-column.worker', { type: 'module' });

      worker.onmessage = ({ data }) => {
        resolve(data);
        worker.terminate(); // Terminate the worker when done
      };

      worker.onerror = (error) => {
        reject(error);
        worker.terminate(); // Ensure worker is terminated on error
      };

      worker.postMessage({
        columns: this.component.columns,
        newColumns: [],
        fields: this.component.fields,
        managingColumns: this.managingColumns,
        visibleColumns: [],
        industrialTime: this.isDecimalView
      });
    });
  }

  protected isDecimalViewStored(): boolean {
    const isDecimalView = !this.timeDecimalColumnSwitchService.isIndustrialTime(this.component.moduleElement.id);

    this.timeDecimalColumnSwitchService.setIndustrialTime(this.component.moduleElement.id, isDecimalView);
    return isDecimalView;
  }
}
