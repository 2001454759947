/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/forms";
import * as i2 from "primeng/components/inputtext/inputtext";
import * as i3 from "../../../../../../../../../node_modules/primeng/components/checkbox/checkbox.ngfactory";
import * as i4 from "primeng/components/checkbox/checkbox";
import * as i5 from "../../../../../../../../../node_modules/primeng/components/spinner/spinner.ngfactory";
import * as i6 from "primeng/components/spinner/spinner";
import * as i7 from "@angular/common";
import * as i8 from "../../../../../../../../../node_modules/primeng/components/dialog/dialog.ngfactory";
import * as i9 from "primeng/components/dialog/dialog";
import * as i10 from "primeng/components/dom/domhandler";
import * as i11 from "primeng/components/button/button";
import * as i12 from "../../../../../../p-button/p-button.directive";
import * as i13 from "./toolbar-item-add-when-component-is-not-changed.component";
import * as i14 from "../../../../../../../core/message/message-growl.service";
import * as i15 from "../../../../../../services/generic-crud.service";
var styles_ToolbarItemAddWhenComponentIsNotChangedComponent = [];
var RenderType_ToolbarItemAddWhenComponentIsNotChangedComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_ToolbarItemAddWhenComponentIsNotChangedComponent, data: {} });
export { RenderType_ToolbarItemAddWhenComponentIsNotChangedComponent as RenderType_ToolbarItemAddWhenComponentIsNotChangedComponent };
function View_ToolbarItemAddWhenComponentIsNotChangedComponent_4(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 7, "div", [["class", "ui-grid-col-8"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 6, "input", [["class", "float-right"], ["pInputText", ""]], [[8, "id", 0], [2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null], [2, "ui-inputtext", null], [2, "ui-corner-all", null], [2, "ui-state-default", null], [2, "ui-widget", null], [2, "ui-state-filled", null]], [[null, "ngModelChange"], [null, "input"], [null, "blur"], [null, "compositionstart"], [null, "compositionend"]], function (_v, en, $event) { var ad = true; if (("input" === en)) {
        var pd_0 = (i0.ɵnov(_v, 2)._handleInput($event.target.value) !== false);
        ad = (pd_0 && ad);
    } if (("blur" === en)) {
        var pd_1 = (i0.ɵnov(_v, 2).onTouched() !== false);
        ad = (pd_1 && ad);
    } if (("compositionstart" === en)) {
        var pd_2 = (i0.ɵnov(_v, 2)._compositionStart() !== false);
        ad = (pd_2 && ad);
    } if (("compositionend" === en)) {
        var pd_3 = (i0.ɵnov(_v, 2)._compositionEnd($event.target.value) !== false);
        ad = (pd_3 && ad);
    } if (("input" === en)) {
        var pd_4 = (i0.ɵnov(_v, 7).onInput($event) !== false);
        ad = (pd_4 && ad);
    } if (("ngModelChange" === en)) {
        var pd_5 = ((_v.parent.context.$implicit.value = $event) !== false);
        ad = (pd_5 && ad);
    } return ad; }, null, null)), i0.ɵdid(2, 16384, null, 0, i1.DefaultValueAccessor, [i0.Renderer2, i0.ElementRef, [2, i1.COMPOSITION_BUFFER_MODE]], null, null), i0.ɵprd(1024, null, i1.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i1.DefaultValueAccessor]), i0.ɵdid(4, 671744, null, 0, i1.NgModel, [[8, null], [8, null], [8, null], [6, i1.NG_VALUE_ACCESSOR]], { model: [0, "model"] }, { update: "ngModelChange" }), i0.ɵprd(2048, null, i1.NgControl, null, [i1.NgModel]), i0.ɵdid(6, 16384, null, 0, i1.NgControlStatus, [[4, i1.NgControl]], null, null), i0.ɵdid(7, 278528, null, 0, i2.InputText, [i0.ElementRef, [2, i1.NgModel]], null, null)], function (_ck, _v) { var currVal_13 = _v.parent.context.$implicit.value; _ck(_v, 4, 0, currVal_13); _ck(_v, 7, 0); }, function (_ck, _v) { var currVal_0 = i0.ɵinlineInterpolate(1, "", _v.parent.context.$implicit.header, ""); var currVal_1 = i0.ɵnov(_v, 6).ngClassUntouched; var currVal_2 = i0.ɵnov(_v, 6).ngClassTouched; var currVal_3 = i0.ɵnov(_v, 6).ngClassPristine; var currVal_4 = i0.ɵnov(_v, 6).ngClassDirty; var currVal_5 = i0.ɵnov(_v, 6).ngClassValid; var currVal_6 = i0.ɵnov(_v, 6).ngClassInvalid; var currVal_7 = i0.ɵnov(_v, 6).ngClassPending; var currVal_8 = true; var currVal_9 = true; var currVal_10 = true; var currVal_11 = true; var currVal_12 = i0.ɵnov(_v, 7).filled; _ck(_v, 1, 1, [currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8, currVal_9, currVal_10, currVal_11, currVal_12]); }); }
function View_ToolbarItemAddWhenComponentIsNotChangedComponent_5(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 6, "div", [["class", "ui-grid-col-8"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 5, "p-checkbox", [["binary", "true"], ["class", "float-right"]], [[8, "id", 0], [2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "ngModelChange"]], function (_v, en, $event) { var ad = true; if (("ngModelChange" === en)) {
        var pd_0 = ((_v.parent.context.$implicit.value = $event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i3.View_Checkbox_0, i3.RenderType_Checkbox)), i0.ɵdid(2, 49152, null, 0, i4.Checkbox, [i0.ChangeDetectorRef], { binary: [0, "binary"] }, null), i0.ɵprd(1024, null, i1.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i4.Checkbox]), i0.ɵdid(4, 671744, null, 0, i1.NgModel, [[8, null], [8, null], [8, null], [6, i1.NG_VALUE_ACCESSOR]], { model: [0, "model"] }, { update: "ngModelChange" }), i0.ɵprd(2048, null, i1.NgControl, null, [i1.NgModel]), i0.ɵdid(6, 16384, null, 0, i1.NgControlStatus, [[4, i1.NgControl]], null, null)], function (_ck, _v) { var currVal_8 = "true"; _ck(_v, 2, 0, currVal_8); var currVal_9 = _v.parent.context.$implicit.value; _ck(_v, 4, 0, currVal_9); }, function (_ck, _v) { var currVal_0 = i0.ɵinlineInterpolate(1, "", _v.parent.context.$implicit.header, ""); var currVal_1 = i0.ɵnov(_v, 6).ngClassUntouched; var currVal_2 = i0.ɵnov(_v, 6).ngClassTouched; var currVal_3 = i0.ɵnov(_v, 6).ngClassPristine; var currVal_4 = i0.ɵnov(_v, 6).ngClassDirty; var currVal_5 = i0.ɵnov(_v, 6).ngClassValid; var currVal_6 = i0.ɵnov(_v, 6).ngClassInvalid; var currVal_7 = i0.ɵnov(_v, 6).ngClassPending; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7); }); }
function View_ToolbarItemAddWhenComponentIsNotChangedComponent_6(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 6, "div", [["class", "ui-grid-col-8"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 5, "p-spinner", [["size", "30"]], [[8, "id", 0], [2, "ui-inputwrapper-filled", null], [2, "ui-inputwrapper-focus", null], [2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "ngModelChange"]], function (_v, en, $event) { var ad = true; if (("ngModelChange" === en)) {
        var pd_0 = ((_v.parent.context.$implicit.value = $event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i5.View_Spinner_0, i5.RenderType_Spinner)), i0.ɵdid(2, 4243456, null, 0, i6.Spinner, [i0.ElementRef, i0.ChangeDetectorRef], { min: [0, "min"], max: [1, "max"], size: [2, "size"] }, null), i0.ɵprd(1024, null, i1.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i6.Spinner]), i0.ɵdid(4, 671744, null, 0, i1.NgModel, [[8, null], [8, null], [8, null], [6, i1.NG_VALUE_ACCESSOR]], { model: [0, "model"] }, { update: "ngModelChange" }), i0.ɵprd(2048, null, i1.NgControl, null, [i1.NgModel]), i0.ɵdid(6, 16384, null, 0, i1.NgControlStatus, [[4, i1.NgControl]], null, null)], function (_ck, _v) { var currVal_10 = 1; var currVal_11 = 999999; var currVal_12 = "30"; _ck(_v, 2, 0, currVal_10, currVal_11, currVal_12); var currVal_13 = _v.parent.context.$implicit.value; _ck(_v, 4, 0, currVal_13); }, function (_ck, _v) { var currVal_0 = i0.ɵinlineInterpolate(1, "", _v.parent.context.$implicit.header, ""); var currVal_1 = i0.ɵnov(_v, 2).filled; var currVal_2 = i0.ɵnov(_v, 2).focus; var currVal_3 = i0.ɵnov(_v, 6).ngClassUntouched; var currVal_4 = i0.ɵnov(_v, 6).ngClassTouched; var currVal_5 = i0.ɵnov(_v, 6).ngClassPristine; var currVal_6 = i0.ɵnov(_v, 6).ngClassDirty; var currVal_7 = i0.ɵnov(_v, 6).ngClassValid; var currVal_8 = i0.ɵnov(_v, 6).ngClassInvalid; var currVal_9 = i0.ɵnov(_v, 6).ngClassPending; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8, currVal_9); }); }
function View_ToolbarItemAddWhenComponentIsNotChangedComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 9, "div", [["class", "ui-grid-row row-margin-5"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 2, "div", [["class", "ui-grid-col-4"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 1, "label", [], [[8, "htmlFor", 0]], null, null, null, null)), (_l()(), i0.ɵted(3, null, ["", ""])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ToolbarItemAddWhenComponentIsNotChangedComponent_4)), i0.ɵdid(5, 16384, null, 0, i7.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ToolbarItemAddWhenComponentIsNotChangedComponent_5)), i0.ɵdid(7, 16384, null, 0, i7.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ToolbarItemAddWhenComponentIsNotChangedComponent_6)), i0.ɵdid(9, 16384, null, 0, i7.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.isStringField(_v.context.$implicit); _ck(_v, 5, 0, currVal_2); var currVal_3 = _co.isCheckboxField(_v.context.$implicit); _ck(_v, 7, 0, currVal_3); var currVal_4 = _co.isIntegerField(_v.context.$implicit); _ck(_v, 9, 0, currVal_4); }, function (_ck, _v) { var currVal_0 = i0.ɵinlineInterpolate(1, "", _v.context.$implicit.header, ""); _ck(_v, 2, 0, currVal_0); var currVal_1 = _v.context.$implicit.header; _ck(_v, 3, 0, currVal_1); }); }
function View_ToolbarItemAddWhenComponentIsNotChangedComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "div", [["class", "ui-grid ui-grid-responsive ui-fluid"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ToolbarItemAddWhenComponentIsNotChangedComponent_3)), i0.ɵdid(2, 278528, null, 0, i7.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.fields; _ck(_v, 2, 0, currVal_0); }, null); }
function View_ToolbarItemAddWhenComponentIsNotChangedComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 11, "p-dialog", [["appendTo", "body"], ["showEffect", "fade"]], null, [[null, "visibleChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("visibleChange" === en)) {
        var pd_0 = ((_co.isEntityDialogVisible = $event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i8.View_Dialog_0, i8.RenderType_Dialog)), i0.ɵdid(1, 180224, null, 2, i9.Dialog, [i0.ElementRef, i0.Renderer2, i0.NgZone], { visible: [0, "visible"], header: [1, "header"], modal: [2, "modal"], responsive: [3, "responsive"], appendTo: [4, "appendTo"], minWidth: [5, "minWidth"] }, { visibleChange: "visibleChange" }), i0.ɵqud(603979776, 1, { headerFacet: 1 }), i0.ɵqud(603979776, 2, { footerFacet: 1 }), (_l()(), i0.ɵand(16777216, null, 1, 1, null, View_ToolbarItemAddWhenComponentIsNotChangedComponent_2)), i0.ɵdid(5, 16384, null, 0, i7.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵeld(6, 0, null, 1, 5, "footer", [], null, null, null, null, null)), (_l()(), i0.ɵeld(7, 0, null, null, 4, "div", [["class", "ui-dialog-buttonpane ui-widget-content ui-helper-clearfix"]], null, null, null, null, null)), (_l()(), i0.ɵeld(8, 0, null, null, 3, "button", [["icon", "fa-check"], ["label", "Speichern"], ["pButton", ""], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.saveEntity() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵprd(4608, null, i10.DomHandler, i10.DomHandler, []), i0.ɵdid(10, 4341760, null, 0, i11.ButtonDirective, [i0.ElementRef], { label: [0, "label"], icon: [1, "icon"] }, null), i0.ɵdid(11, 4341760, null, 0, i12.PButtonDirective, [i0.ElementRef], { label: [0, "label"], icon: [1, "icon"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.isEntityDialogVisible; var currVal_1 = i0.ɵinlineInterpolate(1, "", (_co.entity ? _co.entity.name : "Add"), ""); var currVal_2 = true; var currVal_3 = true; var currVal_4 = "body"; var currVal_5 = 200; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5); var currVal_6 = _co.fields; _ck(_v, 5, 0, currVal_6); var currVal_7 = "Speichern"; var currVal_8 = "fa-check"; _ck(_v, 10, 0, currVal_7, currVal_8); var currVal_9 = "Speichern"; var currVal_10 = "fa-check"; _ck(_v, 11, 0, currVal_9, currVal_10); }, null); }
export function View_ToolbarItemAddWhenComponentIsNotChangedComponent_0(_l) { return i0.ɵvid(2, [(_l()(), i0.ɵand(16777216, null, null, 1, null, View_ToolbarItemAddWhenComponentIsNotChangedComponent_1)), i0.ɵdid(1, 16384, null, 0, i7.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.isEntityDialogVisible; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_ToolbarItemAddWhenComponentIsNotChangedComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-toolbar-item-add-when-component-is-not-changed", [], null, null, null, View_ToolbarItemAddWhenComponentIsNotChangedComponent_0, RenderType_ToolbarItemAddWhenComponentIsNotChangedComponent)), i0.ɵdid(1, 245760, null, 0, i13.ToolbarItemAddWhenComponentIsNotChangedComponent, [i14.MessageGrowlService, i15.GenericCrudService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ToolbarItemAddWhenComponentIsNotChangedComponentNgFactory = i0.ɵccf("app-toolbar-item-add-when-component-is-not-changed", i13.ToolbarItemAddWhenComponentIsNotChangedComponent, View_ToolbarItemAddWhenComponentIsNotChangedComponent_Host_0, { params: "params" }, {}, []);
export { ToolbarItemAddWhenComponentIsNotChangedComponentNgFactory as ToolbarItemAddWhenComponentIsNotChangedComponentNgFactory };
