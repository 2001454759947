import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component, ElementRef,
  Input, ViewChild,
  ViewContainerRef
} from '@angular/core';
import {ComponentService} from '../../services/component-highlight-stack.service';
import {ModulesStateService} from 'app/shared/content-renderer/services/modules-state.service';
import {EntityDataChangeMeta, EntityDataStoreService} from '../../services/entity-data-store.service';
import {GenericCrudService} from '../../../services/generic-crud.service';
import {PermissionService} from '../../../services/permission/permission.service';
import {ExecutorService} from 'app/core/executor/executor.service';
// tslint:disable-next-line:max-line-length
import {GenericElementValidationExecutionStepsFactory} from 'app/shared/content-renderer/services/generic/generic-element-validation-execution-steps-factory';
import {EntityValidator, EntityValidatorStatus} from '../../../validators/services/entity-validator';
import {Element} from '../../../services/element/element';
import {ModuleElement} from '../../../services/module/module-element';
import {UserSessionService} from '../../../../core/service/user-session.service';
import {ElementSaveStatus, GenericElementAbstract} from '../generic-element-abstract.component';
import {Observable, of as observableOf} from 'rxjs';
import {ElementType} from '../../services/ElementContext';
import {ElementsStackService} from '../../services/elements-stack.service';
import {ChangeDetectorRefHelper} from '../../../helpers/change-detector-ref.helper';

@Component({
  selector: 'app-image-viewer',
  styleUrls: ['./generic-image-viewer.component.scss'],
  templateUrl: './generic-image-viewer.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    GenericElementValidationExecutionStepsFactory,
    ExecutorService
  ]
})
export class GenericImageViewerComponent extends GenericElementAbstract {

  @Input() element: Element;
  @Input() moduleElement: ModuleElement;

  @ViewChild('gridContainer', {static: false}) set gridContainer(container: ElementRef) {
    this._container = container;

    if (this._container && this._container.nativeElement) {
    }
  }
  public _container: ElementRef = null;

  protected toolbarContextName: string;
  public elementType: ElementType = ElementType.ImageViewer;

  @Input()
  get entity() {
    return this.entityObj;
  }

  set entity(entity) {
    this.entityObj = entity;
    this.src = this.getSrc();
    ChangeDetectorRefHelper.detectChanges(this);
  }

  public src: any;
  private entityObj: any;

  constructor(
    protected componentService: ComponentService,
    protected viewContainerRef: ViewContainerRef,
    protected entityDataStore: EntityDataStoreService,
    protected modulesStateService: ModulesStateService,
    protected executorService: ExecutorService,
    protected genericElementValidationExecutionStepsFactory: GenericElementValidationExecutionStepsFactory,
    protected entityValidator: EntityValidator,
    protected genericCrudService: GenericCrudService,
    protected userSession: UserSessionService,
    protected permissionService: PermissionService,
    protected elementRef: ElementRef,
    public cdr: ChangeDetectorRef,
    protected elementsStackService: ElementsStackService
  ) {
    super(componentService, viewContainerRef, entityDataStore, modulesStateService,
      executorService, genericElementValidationExecutionStepsFactory,
      entityValidator, genericCrudService,
      userSession, permissionService, cdr
    );
  }

  onAfterSave(): Observable<any> {
    return observableOf(null);
  }

  onChange(changeMeta: EntityDataChangeMeta): Observable<any> {
    return observableOf(null);
  }

  public ngOnInit() {
    super.ngOnInit();
    this.elementContext = this.elementsStackService.createContext(this);
    this.elementsStackService.remove(this.elementContext).add(this.elementContext);
  }

  public ngOnDestroy() {
    super.ngOnDestroy();
  }

  doValidate(): Observable<EntityValidatorStatus> {
    return observableOf(null);
  }

  getSelectedEntity(): any {
  }

  hasChanges(checkEmbedded: boolean): boolean {
    return false;
  }

  onRefresh(): Observable<any> {
    return observableOf(null);
  }

  onSave(): Observable<ElementSaveStatus> {
    return observableOf(null);
  }

  recheckToolbarItems(): void {
  }

  public getSrc(): string {
    if (this.additional) {
      return this.additional.event ? this.additional.event.file.link : this.additional.file.link;
    }

    if (this.entityObj) {
      return this.entityObj.link;
    }

    return '';
  }
}
