/** modules **/
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../shared/shared.module';

import { FormEditorRoutingModule } from './form-editor-routing.module';

/*** 3rd party libs ***/

/** editor */
/** components **/
import { FormEditorComponent } from './form-editor.component';
import { ListComponent } from './list/list.component';
import { EditComponent } from './edit/edit.component';
import { SelectElementComponent } from './edit/select-element/select-element.component';
import { FormPropertiesComponent } from './edit/form-properties/form-properties.component';
import { FormActionsComponent } from "./edit/form-actions/form-actions.component";
import { IndexTreeComponent } from './edit/index-tree/index-tree.component';
import { ElementPropertiesComponent } from './edit/element-properties/element-properties.component';
import { ElementFormatComponent } from './edit/element-format/element-format.component';
import { ElementDataComponent } from './edit/element-data/element-data.component';
import { ElementActionsComponent } from './edit/element-actions/element-actions.component';
import { ElementValidationsComponent } from './edit/element-validations/element-validations.component';
import { MappingConfigComponent } from './edit/element-actions/mapping-config.component';

/*** services ***/
import { FormService } from './../shared/form-viewer/form.service';
import { FormEditorService } from './shared/services/form-editor.service';
import { ElementService } from './shared/services/element.service';
import { FormEditorDetailResolve } from './form-editor-detail-resolve.service';
import { ElementDisplayConfigComponent } from "./edit/element-display-config/element-display-config.component";
import { ElementActionTakeValueFromAnotherFieldsParamsComponent } from './edit/element-actions/action-params/element-action-take-value-from-another-fields-params.component';
import { ElementActionSetValueToAnotherFieldParamsComponent } from 'app/form-editor/edit/element-actions/action-params/element-action-set-value-to-another-field-params.component';
import { FormActionConditionsComponent } from './edit/form-actions/form-action-conditions/form-action-conditions.component';
import { FormActionConditionParamValueEmptyComponent } from './edit/form-actions/form-action-conditions/form-action-condition-params/form-action-condition-param-value-empty.component';
import { CustomerStateDemandNotEmptyValidationParamsComponent } from './edit/element-validations/validation-params/customer-state-demand-not-empty-validation-params.component';
import { SocialSecurityNumberValidationParamsComponent } from './edit/element-validations/validation-params/social-security-number-validation-params.comonent';
import { FormActionConditionParamIsChangingElementComponent } from './edit/form-actions/form-action-conditions/form-action-condition-params/form-action-condition-param-is-changing-element.component';
import { FormActionConditionParamIsElementValueEqualComponent } from './edit/form-actions/form-action-conditions/form-action-condition-params/form-action-condition-param-is-element-value-equal.component';
import { FormActionConditionParamIsEntityValueEqualComponent } from './edit/form-actions/form-action-conditions/form-action-condition-params/form-action-condition-param-is-entity-value-equal.component';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    FormEditorRoutingModule,
  ],
  declarations: [
    FormEditorComponent,
    ListComponent,
    EditComponent,
    SelectElementComponent,
    FormPropertiesComponent,
    FormActionsComponent,
    FormActionConditionsComponent,
    FormActionConditionParamValueEmptyComponent,
    FormActionConditionParamIsChangingElementComponent,
    FormActionConditionParamIsElementValueEqualComponent,
    FormActionConditionParamIsEntityValueEqualComponent,
    ElementFormatComponent,
    IndexTreeComponent,
    ElementPropertiesComponent,
    ElementValidationsComponent,
    CustomerStateDemandNotEmptyValidationParamsComponent,
    SocialSecurityNumberValidationParamsComponent,
    ElementActionsComponent,
    ElementActionTakeValueFromAnotherFieldsParamsComponent,
    ElementActionSetValueToAnotherFieldParamsComponent,
    MappingConfigComponent,
    ElementDataComponent,
    ElementDisplayConfigComponent
  ],
  exports: [
    ListComponent,
    EditComponent,
    SelectElementComponent,
    FormPropertiesComponent,
    FormActionsComponent,
    FormActionConditionsComponent,
    FormActionConditionParamValueEmptyComponent,
    FormActionConditionParamIsChangingElementComponent,
    FormActionConditionParamIsElementValueEqualComponent,
    FormActionConditionParamIsEntityValueEqualComponent,
    IndexTreeComponent,
    ElementPropertiesComponent
  ],
  providers: [
    FormEditorDetailResolve,
    FormEditorService,
    FormService,
    ElementService
  ]
})
export class FormEditorModule { }
