/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./toolbar-item-storno-contract-of-employment.component";
import * as i2 from "../../../../../../../../core/message/message-growl.service";
import * as i3 from "../../../../../../services/double-click.service";
import * as i4 from "../../../../../generic-dialog/service/generic-dialog-module.service";
import * as i5 from "../../../../../../../services/generic-crud.service";
import * as i6 from "../../../../../../services/entity-dirty-store.service";
import * as i7 from "../../../../../../../../core/service/entity-manager/entity-manager.service";
import * as i8 from "../../../../../../services/modules-state.service";
import * as i9 from "@ngx-translate/core";
import * as i10 from "../../../../../../../../core/service/toast.service";
import * as i11 from "../../../../../../../services/request-caching.service";
var styles_ToolbarItemStornoContractOfEmploymentComponent = [];
var RenderType_ToolbarItemStornoContractOfEmploymentComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_ToolbarItemStornoContractOfEmploymentComponent, data: {} });
export { RenderType_ToolbarItemStornoContractOfEmploymentComponent as RenderType_ToolbarItemStornoContractOfEmploymentComponent };
export function View_ToolbarItemStornoContractOfEmploymentComponent_0(_l) { return i0.ɵvid(2, [], null, null); }
export function View_ToolbarItemStornoContractOfEmploymentComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-toolbar-item-storno-contract-of-employment", [], null, null, null, View_ToolbarItemStornoContractOfEmploymentComponent_0, RenderType_ToolbarItemStornoContractOfEmploymentComponent)), i0.ɵdid(1, 245760, null, 0, i1.ToolbarItemStornoContractOfEmploymentComponent, [i2.MessageGrowlService, i3.DoubleClickService, i4.GenericDialogModuleService, i5.GenericCrudService, i6.EntityDirtyStoreService, i7.EntityManagerService, i8.ModulesStateService, i9.TranslateService, i10.ToastService, i11.RequestCachingService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ToolbarItemStornoContractOfEmploymentComponentNgFactory = i0.ɵccf("app-toolbar-item-storno-contract-of-employment", i1.ToolbarItemStornoContractOfEmploymentComponent, View_ToolbarItemStornoContractOfEmploymentComponent_Host_0, { params: "params" }, {}, []);
export { ToolbarItemStornoContractOfEmploymentComponentNgFactory as ToolbarItemStornoContractOfEmploymentComponentNgFactory };
