/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./toolbar-item-open-module-assignments.component";
import * as i2 from "../../../../../../../../core/message/message-growl.service";
import * as i3 from "../../../../../../services/double-click.service";
import * as i4 from "../../../../../../../../core/job-runner/job-container.service";
var styles_ToolbarItemOpenModuleAssignmentsComponent = [];
var RenderType_ToolbarItemOpenModuleAssignmentsComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_ToolbarItemOpenModuleAssignmentsComponent, data: {} });
export { RenderType_ToolbarItemOpenModuleAssignmentsComponent as RenderType_ToolbarItemOpenModuleAssignmentsComponent };
export function View_ToolbarItemOpenModuleAssignmentsComponent_0(_l) { return i0.ɵvid(0, [], null, null); }
export function View_ToolbarItemOpenModuleAssignmentsComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "toolbar-item-open-module-assignments", [], null, null, null, View_ToolbarItemOpenModuleAssignmentsComponent_0, RenderType_ToolbarItemOpenModuleAssignmentsComponent)), i0.ɵdid(1, 245760, null, 0, i1.ToolbarItemOpenModuleAssignmentsComponent, [i2.MessageGrowlService, i3.DoubleClickService, i4.JobContainerService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ToolbarItemOpenModuleAssignmentsComponentNgFactory = i0.ɵccf("toolbar-item-open-module-assignments", i1.ToolbarItemOpenModuleAssignmentsComponent, View_ToolbarItemOpenModuleAssignmentsComponent_Host_0, { params: "params" }, {}, []);
export { ToolbarItemOpenModuleAssignmentsComponentNgFactory as ToolbarItemOpenModuleAssignmentsComponentNgFactory };
