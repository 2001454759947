import {Component} from '@angular/core';
import {FieldActionAbstractComponent} from '../field-action-abstract.component';
import {ExecutionStepFactoryService} from '../../../../../../core/executor/factory/execution-step-factory.service';
import {ExecutionStepPayload} from '../../../../../../core/executor/execution-step-payload';

@Component({
  selector: 'app-field-action-execute-step',
  template: ''
})
export class FieldActionExecuteStepComponent extends FieldActionAbstractComponent {

  public constructor(
    private stepFactory: ExecutionStepFactoryService
  ) {
    super();
  }

  public fire() {
    const gridComponent = this.getGrid(),
      entity = gridComponent.getSelectedEntity();

    const stepName = this.getActionParamValue('step'),
      step = this.stepFactory.createFromString(stepName, new ExecutionStepPayload({
        component: gridComponent,
        entity: entity
      }));

    gridComponent
      .getExecutor()
      .setSteps([step])
      .execute()
      .subscribe();
  }
}
