import {ChangeDetectionStrategy, Component, Input, OnInit} from '@angular/core';
import { GenericCrudRequestOptions } from '../../../../../../services/generic-crud-request-options.service';
import { ToolbarItemFilter } from '../toolbar-item-filter.component';
import { GenericGridComponent } from '../../../../generic-grid/generic-grid.component';
import { ToolbarItemAbstract } from '../toolbar-item-abstract.component';
import { Constants } from '../../../../../../../constants';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'toolbar-item-show-without-master-filter-field',
  template: '',
  providers: [  ]
})
export class ToolbarItemShowWithoutMasterFilterField extends ToolbarItemFilter {

  protected getFilterKey(): string {
    return Constants.SHOW_WITHOUT_MASTER_FILTER_FIELD_FILTER_KEY;
  }

}
