import { Language } from '../language/language';
import { Organisation } from '../organisation/organisation';
import { User } from '../user/user';
import { Country } from '../country/country';
import { Employee } from '../employee/employee';

interface ObjectWithId {
  id: number;
}

interface EmbeddedEntities {
  defaultLanguage?: Language;
  country?: Country;
  organisation?: Organisation;
}

export class Branch {

  public static readonly LOCAL_STORAGE_NAME = 'branch-local-storage';

  id: number;
  name: string;
  street: string;
  zip: string;
  city: string;
  signature: string;
  status: boolean = true;
  defaultRole: string;
  currentlyUpdatedStart: Date;
  country: Country;
  defaultLanguage: Language;
  languages: Language[];
  organisation: Organisation;
  employees: Employee[];
  currentlyUpdatedBy: User;

  _embedded?: EmbeddedEntities;

  constructor(
    id?: number,
    name?: string,
    street?: string,
    zip?: string,
    city?: string,
    signature?: string,
    status?: boolean,
    defaultRole?: string,
    currentlyUpdatedStart?: Date,
    country?: Country,
    defaultLanguage?: Language,
    languages?: Language[],
    organisation?: Organisation,
    employees?: Employee[],
    currentlyUpdatedBy?: User
  ) { }
}
