import {Pipe, PipeTransform} from '@angular/core';
import {Calendar} from 'primeng/primeng';
import {cloneDeep} from 'lodash';

@Pipe({ name: 'appDate' })
export class DatePipe implements PipeTransform {

  public static dateToString(calendar: Calendar, dateValue: Date|string): string {
    let dateString = '';

    let date = cloneDeep(dateValue);

    if (typeof date === 'string' && date !== '' && date !== null) {
      if (date.includes('+')) {
        date = date.substring(0, date.indexOf('+')); // remove after '+' (remove timezone)
      }

      date = new Date(date);
    }

    if (calendar.dateFormat) {
      dateString = calendar.formatDate(date, calendar.dateFormat);
    }

    if (calendar.dateFormat && calendar.showTime) {
      dateString = calendar.formatDateTime(date);
    }

    if (calendar.timeOnly) {
      dateString = calendar.formatTime(date);
    }

    return dateString;
  }

  transform(date: Date|string, format = 'dd.mm.yy', showTime = false, timeOnly = false): any {

    const calendar = new Calendar(null, null, null, null);
    calendar.showTime = showTime;
    calendar.timeOnly = timeOnly;
    calendar.dateFormat = format;

    return DatePipe.dateToString(calendar, date);
  }
}
