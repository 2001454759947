import {Injectable} from '@angular/core';
import {TableColumn} from '../../../../../dynamic-table/shared/table-column';
import {GenericCrudService} from '../../../../../services/generic-crud.service';
import {ApiBuilderService} from '../../../../../services/api.builder.service';
import {AbstractTableService} from './abstract-table.service';

@Injectable()
export class LeasedEmployeeTableService extends AbstractTableService {

  public constructor(
    protected genericCrudService: GenericCrudService,
    protected apiBuilderService: ApiBuilderService
  ) {
    super(genericCrudService, apiBuilderService);
  }

  protected getApiRoute(): string {
    return 'phoenix/leasedemployees';
  }

  public getColumns(): TableColumn[] {
    return [
      {
        key: 'id',
        header: 'Id',
        filter: {},
        sort: {}
      },
      {
        key: 'firstName',
        header: 'First Name',
        filter: {},
        sort: {}
      },
      {
        key: 'lastName',
        header: 'Last Name',
        filter: {},
        sort: {}
      }
    ];
  }

}
