<app-generic-loading *ngIf="isLoadingData"></app-generic-loading>

<div class="chart-container" *ngIf="!isLoadingData" fxLayoutAlign="start stretch" fxLayout="column">

  <div fxFlex="none" *ngIf="element && element.topToolbarItems && element.topToolbarItems.length > 0">
    <p-toolbar styleClass="no-round-corner">
      <app-generic-toolbar [params]="getToolbarItemsExtraParams()" [isDisabled]="isToolbarDisabled" [entity]="entity" [items]="element.topToolbarItems" [additionalItems]="element.additionalToolbarItems"></app-generic-toolbar>
    </p-toolbar>
  </div>

  <ng-container *ngIf="errorMessages.length > 0">
    <div class="error-container">
      <div class="error-text">
        <div class="error-message">
          <b><span *ngFor="let errorMessage of errorMessages" class="text-red">{{ errorMessage }}</span></b>
        </div>
      </div>
    </div>
  </ng-container>

  <div class="chart-container" *ngIf="errorMessages.length === 0">
    <p-chart [type]="element.elementSubType | lowercase" [data]="chartData" [height]="'100%'"></p-chart>
  </div>

  <div fxFlex="none" *ngIf="element && element.statusBarItems && element.statusBarItems.length > 0">
    <p-toolbar styleClass="no-round-corner">
      <app-generic-toolbar [params]="getToolbarItemsExtraParams()" [isDisabled]="isToolbarDisabled" [entity]="entity" [items]="element.statusBarItems"></app-generic-toolbar>
    </p-toolbar>
  </div>
</div>

