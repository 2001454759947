import {ChangeDetectionStrategy, Component} from '@angular/core';
import {ToolbarItemAbstract} from '../toolbar-item-abstract.component';
import {GenericCrudService} from '../../../../../../services/generic-crud.service';
import {environment} from '../../../../../../../../environments';
import {RequestCachingService} from '../../../../../../services/request-caching.service';
import {TOAST_LIFE_INFINITE, ToastComponentsRegistry, ToastService} from '../../../../../../../core/service/toast.service';
import {TranslateService} from '@ngx-translate/core';
import {forkJoin, Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {ChangeDetectorRefHelper} from '../../../../../../helpers/change-detector-ref.helper';
import {AbstractGenericGridComponent} from '../../../../abstract-generic-grid.component';

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'app-toolbar-item-elba-csv-export',
    template: ''
})
export class ToolbarItemElbaCsvExportComponent extends ToolbarItemAbstract {
    public baseUrl = environment.baseUrl;

    constructor(
        protected genericCrudService: GenericCrudService,
        protected requestCachingService: RequestCachingService,
        protected toastService: ToastService,
        protected translateService: TranslateService
    ) {
        super();
    }

    public click() {
      this.createFile().subscribe(() => {});
    }

    createFile(): Observable<any> {
      const observables = [];
      const elbaUrl = 'phoenix/export/elba/elba';

      this.toastService.custom(ToastComponentsRegistry.PROGRESS_BAR, {
        severity: 'info',
        life: TOAST_LIFE_INFINITE,
        closable: false,
        summary: this.translateService.instant('COMMON.PRINTING_PLEASE_WAIT')
      });

      observables.push(this.genericCrudService.get(elbaUrl));

      return forkJoin(observables)
        .pipe(
          map((fileName) => {
            this.requestCachingService.removeByExpression('phoenix');
            this.toastService.clear(ToastComponentsRegistry.PROGRESS_BAR, 300);

            const component = this.getComponent();
            if (component instanceof AbstractGenericGridComponent) {
              this.refreshComponent(component);
            }

            this.downloadFile(fileName);

            ChangeDetectorRefHelper.detectChanges(this);
          }));
    }

    private refreshComponent(component: AbstractGenericGridComponent) {
      component.loadEntities().subscribe();
    }

    private downloadFile(fileName) {
      const a = document.createElement('a');
      document.body.appendChild(a);
      a.style.setProperty('display', 'none');
      a.href = this.baseUrl + '/assets/exports/elba' + '/' + fileName;
      a.download = fileName;
      a.click();
    }
}
