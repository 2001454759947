import {ChangeDetectionStrategy, Component, Input, OnInit} from '@angular/core';
import { ToolbarItemAbstract } from '../toolbar-item-abstract.component';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'toolbar-item-form-cancel',
  templateUrl: './toolbar-item-form.component.html'
})
export class ToolbarItemFormCancel extends ToolbarItemAbstract {

  public click() {
    this.handleClick(this.getParam('formViewerComponent'));
  }

  protected handleClick(formViewer) {
    formViewer.onFormViewerCancel();
  }
}
