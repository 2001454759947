/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./toolbar-item-release-pre-calculation.component";
import * as i2 from "../../../../../../services/generic-crud.service";
import * as i3 from "../../../../../../../core/message/message-growl.service";
import * as i4 from "@ngx-translate/core";
import * as i5 from "primeng/components/common/confirmationservice";
import * as i6 from "../../../../../../services/request-caching.service";
var styles_ToolbarItemReleasePreCalculationComponent = [];
var RenderType_ToolbarItemReleasePreCalculationComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_ToolbarItemReleasePreCalculationComponent, data: {} });
export { RenderType_ToolbarItemReleasePreCalculationComponent as RenderType_ToolbarItemReleasePreCalculationComponent };
export function View_ToolbarItemReleasePreCalculationComponent_0(_l) { return i0.ɵvid(2, [], null, null); }
export function View_ToolbarItemReleasePreCalculationComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-toolbar-item-add-pre-calculation-article", [], null, null, null, View_ToolbarItemReleasePreCalculationComponent_0, RenderType_ToolbarItemReleasePreCalculationComponent)), i0.ɵdid(1, 245760, null, 0, i1.ToolbarItemReleasePreCalculationComponent, [i2.GenericCrudService, i3.MessageGrowlService, i4.TranslateService, i5.ConfirmationService, i6.RequestCachingService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ToolbarItemReleasePreCalculationComponentNgFactory = i0.ɵccf("app-toolbar-item-add-pre-calculation-article", i1.ToolbarItemReleasePreCalculationComponent, View_ToolbarItemReleasePreCalculationComponent_Host_0, { params: "params" }, {}, []);
export { ToolbarItemReleasePreCalculationComponentNgFactory as ToolbarItemReleasePreCalculationComponentNgFactory };
