import {map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';


import { AppHttpService } from '../../../app-http.service';
import { Menu } from './menu';
import { GenericCrudService } from '../generic-crud.service';

@Injectable()
export class MenuCrudService extends AppHttpService {

  private productsUrl: string = 'superadmin/products/:productId/menus';

  constructor(private genericCrudService: GenericCrudService) {
    super();
  }

  /**
   * @argument {number} productId The id of the selected product.
   * @argument {number} menuId The id of the selected menu.
   * @argument {string} format Optional. If not set, then the entities are returned as is.
   *                            Selectable formats: formenu (MenuItem), fortree (TreeNode).
   */
  getMenu(productId: number, menuId: number, format = ''): Observable<Menu> {
    format = format ? `/${format}` : '';
    const urlWithIdandFormat = this.productsUrl.replace(/\:productId/g, String(productId)) + '/' + menuId + format;
    return this.genericCrudService.get(urlWithIdandFormat);
  }

  /**
   * @argument {number} productId The id of the selected product.
   * @argument {string} format Optional. If not set, then the entities are returned as is.
   *                            Selectable formats: formenu (MenuItem), fortree (TreeNode).
   */
  getMenus(productId: number, format = '', parentId = ''): Observable<Menu[]> {
    format = format ? `/${format}` : '';
    parentId = parentId ? `/${parentId}` : '';
    const urlWithIdandFormat = this.productsUrl.replace(/\:productId/g, String(productId)) + format + parentId;
    return this.genericCrudService.get(urlWithIdandFormat).pipe(
      map(this.extractEmbeddedEntities, this),
      // TODO: is next map necessary? products are already included by this.extractEmbeddedEntities
      map((products: Array<any>) => {
        return <Array<Menu>>products;
      }),);
  }

  deleteMenu(productId: number, menuId: number): Observable<Object> {
    if (productId > 0 && menuId > 0) {
      const urlWithId = this.productsUrl.replace(/\:productId/g, String(productId));
      return this.genericCrudService.deleteEntity(urlWithId + '/' + menuId);
    } else {
      throw new Error('Invalid menu id given.');
    }
  }

  /**
   * @argument {number} productId The id of the selected product.
   * @argument {Menu} newMenu The new menu to create.
   * @argument {Menu} parentMenu Optional. The parent menu to add to.
   * @argument {string} format Optional. If not set, then the entities are returned as is.
   *                            Selectable formats: formenu (MenuItem), fortree (TreeNode).
   */
  createMenu(productId: number, newMenu: Menu, parentMenu?: Menu, format: string = null): Observable<any> {
    if (productId > 0 && newMenu) {
      if (parentMenu) {
        newMenu.parentId = parentMenu.id;
      }
      format = format ? `/${format}` : '';
      const urlWithIdandFormat = this.productsUrl.replace(/\:productId/g, String(productId)) + format;
      return this.genericCrudService.createEntity(urlWithIdandFormat, newMenu);
    } else {
      throw new Error('Invalid menu given. Product id: ' + productId + '; new menu: ' + newMenu + '; parentMenu: ' + parentMenu);
    }
  }

  /**
   * @argument {number} productId The id of the selected product.
   * @argument {Menu} menu The new menu to create.
   * @argument {string} format Optional. If not set, then the entities are returned as is.
   *                            Selectable formats: formenu (MenuItem), fortree (TreeNode).
   */
  editMenu(productId: number, menu: Menu, format: string = null): Observable<any> {
    if (productId > 0 && menu) {
      format = format ? `/${format}` : '';
      const urlWithIdandFormat = this.productsUrl.replace(/\:productId/g, String(productId)) + '/' + menu.id + format;
      return this.genericCrudService.editEntity(urlWithIdandFormat, menu);
    } else {
      throw new Error('Invalid menu given. Product id: ' + productId + '; new menu: ' + menu);
    }
  }

}
