import {Component, OnInit} from '@angular/core';
import {AbstractPreviewFileTypeComponent} from '../abstract-preview-file-type.component';
import {environment} from '../../../../../../../environments';
import {DomSanitizer, SafeResourceUrl} from '@angular/platform-browser';

@Component({
  selector: 'app-generic-file-preview-pdf',
  templateUrl: './generic-file-preview-pdf.component.html',
})
export class GenericFilePreviewPdfComponent extends AbstractPreviewFileTypeComponent implements OnInit {

  public url: SafeResourceUrl = null;
  public environment: Environment = environment;

  public constructor(
    private sanitizer: DomSanitizer
  ) {
    super();
  }

  public ngOnInit(): void {
    const fileUrl = this.environment.baseUrl + '/' + this.fileData.filePath;

    this.url = this.sanitizer.bypassSecurityTrustResourceUrl(fileUrl);
  }
}
