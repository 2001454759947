import { Component } from '@angular/core';
import { FieldActionAbstractComponent } from '../field-action-abstract.component';
import { GenericCrudService } from '../../../../../services/generic-crud.service';
import { DoubleClickService } from '../../../../services/double-click.service';
import {DateHelper} from "../../../../../helpers/date.helper";
import {EntityManagerService} from '../../../../../../core/service/entity-manager/entity-manager.service';

@Component({
  selector: 'field-action-set-overall-working-time',
  template: ''
})
export class FieldActionSetOverallWorkingTimeComponent extends FieldActionAbstractComponent {

  public constructor(
    protected genericCrudService: GenericCrudService,
    protected doubleClickService: DoubleClickService,
    protected entityManager: EntityManagerService
  ) {
    super();
  }

  public fire() {
    const entity = this.getGrid().getSelectedEntity();
    if (entity.fqn === 'PhoenixBundle\\Entity\\WorkplaceWorkingTimeModelDay'
      || entity.fqn === 'PhoenixBundle\\Entity\\SelectedWorkingTimeModelDay') {

      this.updateField(this.getField().name);

      const start = DateHelper.getDateFromTimeString(entity.start),
        end = DateHelper.getDateFromTimeString(entity.end);

      if (end.getTime() < start.getTime()) {
        end.setDate(end.getDate() + 1);
      }

      let overallWorkingTime = end.getTime() - start.getTime();

      if (!entity.isPaidBreak) {
        const breakTime = DateHelper.getDateFromTimeString(entity.break),
          zeroTime = DateHelper.getDateFromTimeString('00:00');

        overallWorkingTime -= breakTime.getTime() - zeroTime.getTime();
      }

      const hours = Math.floor(overallWorkingTime / 1000 / 60 / 60);
      overallWorkingTime -= hours * (1000 * 60 * 60);
      const minutes = Math.floor(overallWorkingTime / 1000 / 60);
      overallWorkingTime -= minutes * (1000 * 60);
      const seconds = Math.floor(overallWorkingTime / 1000);

      const overallWorkingDateTime = new Date(0, 0, 0, hours, minutes, seconds);
      entity.overallWorkingTime = DateHelper.formatTime(overallWorkingDateTime);
      entity.overallWorkingTimeDecimal = DateHelper.convertFromTimeToDecimal(entity.overallWorkingTime);
    }
  }

  private updateField(fieldName) {
    const entity = this.getGrid().getSelectedEntity();
    switch (fieldName) {
      case 'startTime':
        this.entityManager.persist(entity, {
          property: 'startTimeDecimal',
          newValue: DateHelper.convertFromTimeToDecimal(entity.startTime),
          oldValue: entity.startTimeDecimal,
          force: true
        });
        break;
      case 'startTimeDecimal':
        this.entityManager.persist(entity, {
          property: 'startTime',
          newValue: DateHelper.convertFromDecimalToTime(entity.startTimeDecimal),
          oldValue: entity.startTime,
          force: true
        });
        break;
      case 'start':
        this.entityManager.persist(entity, {
          property: 'startDecimal',
          newValue: DateHelper.convertFromTimeToDecimal(entity.start),
          oldValue: entity.startDecimal,
          force: true
        });
        break;
      case 'startDecimal':
        this.entityManager.persist(entity, {
          property: 'start',
          newValue: DateHelper.convertFromDecimalToTime(entity.startDecimal),
          oldValue: entity.start,
          force: true
        });
        break;
      case 'endTime':
        this.entityManager.persist(entity, {
          property: 'endTimeDecimal',
          newValue: DateHelper.convertFromTimeToDecimal(entity.endTime),
          oldValue: entity.endTimeDecimal,
          force: true
        });
        break;
      case 'endTimeDecimal':
        this.entityManager.persist(entity, {
          property: 'endTime',
          newValue: DateHelper.convertFromDecimalToTime(entity.endTimeDecimal),
          oldValue: entity.endTime,
          force: true
        });
        break;
      case 'end':
        this.entityManager.persist(entity, {
          property: 'endDecimal',
          newValue: DateHelper.convertFromTimeToDecimal(entity.endTime),
          oldValue: entity.endDecimal,
          force: true
        });
        break;
      case 'endDecimal':
        this.entityManager.persist(entity, {
          property: 'end',
          newValue: DateHelper.convertFromDecimalToTime(entity.endDecimal),
          oldValue: entity.end,
          force: true
        });
        break;
      case 'breakHours':
        this.entityManager.persist(entity, {
          property: 'breakHoursDecimal',
          newValue: DateHelper.convertFromTimeToDecimal(entity.breakHours),
          oldValue: entity.breakHoursDecimal,
          force: true
        });
        break;
      case 'breakHoursDecimal':
        this.entityManager.persist(entity, {
          property: 'breakHours',
          newValue: DateHelper.convertFromDecimalToTime(entity.breakHoursDecimal),
          oldValue: entity.breakHours,
          force: true
        });
        break;
      case 'break':
        this.entityManager.persist(entity, {
          property: 'breakDecimal',
          newValue: DateHelper.convertFromTimeToDecimal(entity.break),
          oldValue: entity.breakDecimal,
          force: true
        });
        break;
      case 'breakDecimal':
        this.entityManager.persist(entity, {
          property: 'break',
          newValue: DateHelper.convertFromDecimalToTime(entity.breakDecimal),
          oldValue: entity.break,
          force: true
        });
        break;
      case 'plannedWorkingHours':
        this.entityManager.persist(entity, {
          property: 'plannedWorkingHoursDecimal',
          newValue: DateHelper.convertFromTimeToDecimal(entity.plannedWorkingHours),
          oldValue: entity.plannedWorkingHoursDecimal,
          force: true
        });
        break;
      case 'plannedWorkingHoursDecimal':
        this.entityManager.persist(entity, {
          property: 'plannedWorkingHours',
          newValue: DateHelper.convertFromDecimalToTime(entity.plannedWorkingHoursDecimal),
          oldValue: entity.plannedWorkingHours,
          force: true
        });
        break;
      case 'plannedWorkingTime':
        this.entityManager.persist(entity, {
          property: 'plannedWorkingTimeDecimal',
          newValue: DateHelper.convertFromTimeToDecimal(entity.plannedWorkingTime),
          oldValue: entity.plannedWorkingTimeDecimal,
          force: true
        });
        break;
      case 'plannedWorkingTimeDecimal':
        this.entityManager.persist(entity, {
          property: 'plannedWorkingTime',
          newValue: DateHelper.convertFromDecimalToTime(entity.plannedWorkingTimeDecimal),
          oldValue: entity.plannedWorkingTime,
          force: true
        });
        break;
    }
  }

}
