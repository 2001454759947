import {ChangeDetectionStrategy, Component, Input, OnInit} from '@angular/core';
import { ToolbarItemAdd } from '../add/toolbar-item-add.component';
import { ComponentService } from '../../../../../services/component-highlight-stack.service';
import { MessageGrowlService } from '../../../../../../../core/message/message-growl.service';
import { GenericCrudService } from '../../../../../../services/generic-crud.service';

/**
 * DEPRECATED!
 */
@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'toolbar-item-edit',
  templateUrl: '../add/toolbar-item-add.component.html'
})
export class ToolbarItemEdit extends ToolbarItemAdd {

  public entity: any;

  constructor(
    protected componentService: ComponentService,
    protected messageGrowlService: MessageGrowlService,
    protected genericCrudService: GenericCrudService
  ) {
    super(messageGrowlService, genericCrudService);
  }


  getBackgroundColor(): string {
    return '#FFFFFF';
  }

  protected doSaveFormViewerComponent(formViewerComponent) {
    if (!formViewerComponent._entity) {
      return this.messageGrowlService.error('Edit called but no entity has been selected!');
    }

    formViewerComponent.onSave();
  }

  protected handleClickBasedOnGenericGrid(gridComponent) {
    if (!gridComponent.element.datamodel || !gridComponent.element.datamodel.apiRoute) {
      console.log('Module element has no datamodel assigned!');
    }

    this.showGenericGridEntityForm(gridComponent);
  }

  protected showGenericGridEntityForm(gridComponent) {
    this.entity = gridComponent.selectedEntity;

    if (!this.entity) {
      return this.messageGrowlService.error('Entity not selected.');
    }

    if (gridComponent.columns instanceof Array) {
      gridComponent.columns.forEach((column) => {
        if (this.propertiesToIgnore.indexOf(column.property) === -1) {
          column.value = this.entity[column.property];

          this.fields.push(column);
        }
      });
    }

    this.isEntityDialogVisible = true;
  }

  public createRequestBody(fields): Object {
    const requestBody: Object = this.entity;

    for (const field of fields) {
      requestBody[field.property] = field.value;
    }
    return requestBody;
  }

  public createResponse() {
    const apiRoute = this.entity._links.self.href.replace('/api/', '');

    return this.genericCrudService.editEntity(apiRoute, this.createRequestBody(this.fields));
  }
}
