import {Observable} from 'rxjs/Observable';
import {AbstractExecutionStep} from '../../../../core/executor/abstract-execution-step';
import {ExecutionStepStatus} from '../../../../core/executor/execution-step-status';
import {FormViewerComponent} from '../../../form-viewer/form-viewer.component';
import {Guid} from 'guid-typescript';
import {switchMap} from 'rxjs/operators';
import {GenericDialogModuleService} from '../../../content-renderer/elements/generic-dialog/service/generic-dialog-module.service';
import {GenericDmsViewComponent} from '../../../content-renderer/elements/generic-dms-view/generic-dms-view.component';
import {MemoTypeFieldDefinition} from '../../../content-renderer/elements/custom/memo-field-definition-value/memo-field-definition-value.component';

export class DmsViewFileOnUploadSuccessExecutionStep extends AbstractExecutionStep {

  public doExecute(): Observable<ExecutionStepStatus> {

    const payloadValue = this.getPayload().getValue(),
      component = payloadValue.component,
      entity = payloadValue.event.files[0]

    if (!(component instanceof FormViewerComponent)) {
      return this.getFailObservable('You need to pass FormViewer as Payload value!');
    }
    const parentEntity = this.modulesStateService.getCurrent().parent.entity;
    const memoType = component.getSelectedEntity().memoType;

    this.genericCrudService.getEntities(
      'dms/memotypefielddefinitions',
      '',
      {embedded: 'memoType,fieldDefinitionTemplate,fieldDefinitionTemplate.dataType', memoType: memoType}
    ).pipe(
      switchMap((memoFieldDefinitions) => {
        const newMemoFieldDefinitionValues = [];
        for (const memoFieldDefinition of memoFieldDefinitions) {
          newMemoFieldDefinitionValues.push({
            id: null,
            fieldDefinition: memoFieldDefinition,
            value: this.getValue(memoFieldDefinition, parentEntity),
            fqn: 'DmsBundle\\Entity\\MemoFieldDefinitionValue',
            isChanged: true,
            _embedded: {},
            uniqueId: Guid.create().toString(),
            owner: entity
          });
        }
        return this.updateFile(newMemoFieldDefinitionValues, memoType, entity);
      }),
      switchMap(fileResponse => {
        return this.openMemoFieldDefinitionValueComponent(fileResponse, component.masterElementContext.component);
      }),
      switchMap(() => {
        return component.masterElementContext.component.search();
      })
    ).subscribe();
  }

  private openMemoFieldDefinitionValueComponent(fileResponse: any, component: GenericDmsViewComponent): Observable<any> {
    this.getGenericDialogModuleService().persistHide();

    return new Observable<ExecutionStepStatus>((observer) => {
      if (component.moduleElement && component.moduleElement.onDoubleClickTargetModuleId) {
        this.genericCrudService.getEntity('superadmin/modules', component.moduleElement.onDoubleClickTargetModuleId).subscribe((targetModule) => {
          this.getGenericDialogModuleService().showDialog(targetModule, {
            height: +component.moduleElement.onDoubleClickTargetDialogHeight,
            width: +component.moduleElement.onDoubleClickTargetDialogWidth,
            entity: fileResponse,
            onClose: () => {
              return true;
            }
          });
        });
      }
    })
  }

  private updateFile(newMemoFieldDefinitionValues: any[], memoType: any, entity: any): Observable<any> {
    return this.genericCrudService.editEntity(
      `dms/dmsfiles/` + entity.id,
      {memoType: memoType, fieldDefinitionValues: newMemoFieldDefinitionValues},
      {embedded: 'memoType,fieldDefinitionValues'});
  }
  private getValue(memoFieldDefinition: MemoTypeFieldDefinition, parentEntity: any): number|null {
    if (
      memoFieldDefinition.fieldDefinitionTemplate
      && memoFieldDefinition.fieldDefinitionTemplate.dataType
      && (memoFieldDefinition.fieldDefinitionTemplate.dataType.code === 'leasedEmployee'
        || memoFieldDefinition.fieldDefinitionTemplate.dataType.code === 'customer')) {
      return parentEntity.id;
    }
    return null;
  }

  private getGenericDialogModuleService(): GenericDialogModuleService|null {
    return this.injector.get(GenericDialogModuleService, null);
  }
}
