
import {of as observableOf,  Observable } from 'rxjs';

import {switchMap, catchError} from 'rxjs/operators';
import { AbstractExecutionStepCondition } from './abstract-execution-step-condition';
import { AbstractExecutionStep } from './abstract-execution-step';
import { ExecutionStep } from 'app/core/executor/execution-step';

export class ConditionExecutor {

    private conditions: AbstractExecutionStepCondition[] = [];

    public execute(step: ExecutionStep, conditions: AbstractExecutionStepCondition[] = []): Observable<boolean> {
        this.conditions = conditions;

        if (this.conditions.length === 0) {
            return observableOf(true);
        }

        return this.executeCondition(step, 0);
    }

    public resetConditions(): this {
        this.conditions = [];
        return this;
    }

    private executeCondition(executionStep: ExecutionStep, conditionIndex: number): Observable<boolean> {
        const condition = this.conditions[conditionIndex],
            nextConditionIndex = conditionIndex + 1;

        return condition
            .isValid(executionStep).pipe(
            catchError((status) => {
                return observableOf(false);
            }),switchMap((isConditionValid: boolean) => {
            if (isConditionValid && this.conditionExists(nextConditionIndex)) {
                /**
                 * @type {ExecutionStep}
                 */
                const nextCondition = this.conditions[nextConditionIndex];

                return this.executeCondition(executionStep, nextConditionIndex);
            }

            if (isConditionValid && !this.conditionExists(nextConditionIndex)) {
                this.resetConditions();
                return observableOf(true);
            }

            if (isConditionValid === false) {
                this.resetConditions();
                
                return observableOf(false);
            }
        }),);
    }

    private conditionExists(stepIndex: number): boolean {
        return typeof this.conditions[stepIndex] !== 'undefined';
    }

}
